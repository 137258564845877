import { ComplianceResults } from "types/auditPlanningTypes";
import { IComplianceQuestionProps, IComplianceScore } from "../auditScoreCalculator";

class CLMScoreCalculator {
  private ignoredCLMAnswerCodes = ["NRA", "NOP", "EXM"];
  private clmComplianceGrades = [
    // Each item here is (>=CAT1Score, >=CAT2Score, Result).
    // Example: If cat1 score is .84 and cat2 score is .69, search this list for the first item
    // where cat1 score >= Item1 && cat2 score >= Item2. This should find "NonCompliance".
    { cat1ScoreMin: .9, cat2ScoreMin: .8, result: ComplianceResults.HighCompliance },
    { cat1ScoreMin: .8, cat2ScoreMin: .7, result: ComplianceResults.BasicCompliance },
    { cat1ScoreMin: .7, cat2ScoreMin: .6, result: ComplianceResults.NonCompliance },
    { cat1ScoreMin: 0, cat2ScoreMin: 0, result: ComplianceResults.HighNonCompliance },
  ];

  calculateComplianceScore(questions: IComplianceQuestionProps[]): IComplianceScore {
    const ltoQuestions = questions
      .filter(x => x.isLto
        && (x.category === "Category 1"
          || x.category === "Category 2"));

    const cat1Questions = questions
      .filter(x => x.category === "Category 1"
        && !this.ignoredCLMAnswerCodes.some(i => i === x.answer));
    const cat2Questions = questions
      .filter(x => x.category === "Category 2"
        && !this.ignoredCLMAnswerCodes.some(i => i === x.answer));
    const answeredLtoQuestions = questions
      .filter(x => x.isLto
        && x.answer !== undefined
        && (x.category === "Category 1"
          || x.category === "Category 2")
        && !this.ignoredCLMAnswerCodes.some(i => i === x.answer));
    const nonCompliantLtoQuestions = answeredLtoQuestions
      .filter(x => x.answer !== "ALW"
        && x.answer !== "MOT");

    const cat1Score = this.getCompliance(cat1Questions);
    const cat2Score = this.getCompliance(cat2Questions);
    const overallScore = this.getCompliance(cat1Questions.concat(cat2Questions));
    const ltoScore = this.getCompliance(answeredLtoQuestions);

    const result: IComplianceScore = {
      overallCompliance: undefined,
      overallPercentage: overallScore,
      cat1Percentage: cat1Score,
      cat2Percentage: cat2Score,
      ltoCompliance: ltoScore,
      numLTONonCompliantQuestions: nonCompliantLtoQuestions.length,
      numLTOQuestions: ltoQuestions.length,
    };

    result.overallCompliance = result.overallPercentage === undefined
      ? undefined
      : this.clmComplianceGrades
        .find(x => (cat1Score === undefined || cat1Score >= x.cat1ScoreMin)
          && (cat2Score === undefined || cat2Score >= x.cat2ScoreMin))
        ?.result;

    return result;
  };

  private getCompliance(questions: IComplianceQuestionProps[]) {
    const answeredQuestions = questions
      .filter(x => x.answer !== undefined);

    if (!answeredQuestions.length) {
      return undefined;
    }

    const numAlways = answeredQuestions.filter(x => x.answer === "ALW").length;
    const numMostly = answeredQuestions.filter(x => x.answer === "MOT").length;

    // // (#ALW + (0.5 * #MOT)) / (#Questions - (NRA+NOP+EXM))
    return (numAlways + (0.5 * numMostly)) / answeredQuestions.length;
  }
}

export default CLMScoreCalculator;