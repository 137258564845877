import { CollectionView } from "@mescius/wijmo";
import * as wjcGrid from "@mescius/wijmo.grid";
import { FlexGrid as wjFlexGrid } from "@mescius/wijmo.grid";
import { FlexGridXlsxConverter } from "@mescius/wijmo.grid.xlsx";
import { authGetJson } from "auth/authFetches";
import React, { useCallback, useEffect } from "react";
import { renderToString } from 'react-dom/server';
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { finishGenerateGridExportFile } from "store/grid/GridSlice";
import { useAppDispatch, useAppSelector } from "store/store";

interface IWijmoGridExporterProps {
  /** The id of the grid to handle exports for. */
  gridId: string,
}

/**
 * Handles the redux action for exporting grids, checks if the grid is equal to the one passed in as
 * prop, and then exports that grid to XLSX.
 */
const WijmoGridExporter = React.forwardRef<wjFlexGrid, IWijmoGridExporterProps>((props, forwardedRef) => {
  const gridId = props.gridId;
  const gridIdToExport = useAppSelector(store => store.grid.generateExportFileOp?.data);
  const dispatch = useAppDispatch();

  const exportGridToExcel = useCallback(async () => {
    const grid = (forwardedRef as any)?.current;
    if (grid) {
      // Get the current, unpaged odata url from the grid's data source.
      const currOdataUrl = grid.collectionView.getOdataUrl(false);

      // Query the api using the odata url and assign the data to the grid.
      const values = (await (await authGetJson(currOdataUrl)).json()).value;

      try {
        // Make a cloned grid.
        const clonedGrid = getClonedGrid(grid, values, false);

        // Export to excel.
        return FlexGridXlsxConverter.saveAsync(clonedGrid,
          {
            includeColumnHeaders: true,
            includeStyles: true,
            formatItem: (args) => {
              if (args.panel.cellType === wjcGrid.CellType.ColumnHeader) {
                args.xlsxCell.style = args.xlsxCell.style || {};
                args.xlsxCell.style.font = args.xlsxCell.style.font || {};
                args.xlsxCell.style.font.bold = true;
              }

              if (args.panel.cellType === wjcGrid.CellType.Cell) {
                let xlsxCell = args.xlsxCell;
                xlsxCell.value = args.cell.textContent;
              }
            }
          },
          `${gridId}_Grid_Exported.xlsx`);
      } catch (err) {
        dispatch(showErrorToast(getResponseErrorMessage(err)));
      }
    }
  }, [gridId, forwardedRef, dispatch]);

  useEffect(() => {
    const asyncExport = async () => {
      if (gridIdToExport === gridId) {
        try {
          await exportGridToExcel();
        } catch (err) {
          dispatch(showErrorToast(getResponseErrorMessage(err)));
        } finally {
          dispatch(finishGenerateGridExportFile());
        }
      }
    };

    asyncExport();
  }, [gridIdToExport, gridId, exportGridToExcel, dispatch]);

  // Don't render anything to the screen.
  return null;
});

export default WijmoGridExporter;

function getClonedGrid(grid: any, sourceCollection: any[], cloneFilter: boolean) {
  const collViewSettings = {
    sortDescriptions: grid.collectionView.sortDescriptions,
    groupDescriptions: grid.collectionView.groupDescriptions,
    filter: cloneFilter ? grid.collectionView.filter : undefined,
  };

  if (!cloneFilter) {
    delete collViewSettings.filter;
  }

  var sourceData = new CollectionView(sourceCollection, collViewSettings);

  var clone = new wjcGrid.FlexGrid(document.createElement('div'), {
    itemsSource: sourceData,
    columnLayout: grid.columnLayout
  });

  grid.columns.forEach((col: any) => {
    if (col.dataMap) {
      clone.columns[col.index].dataMap = col.dataMap;
    }
  });

  clone.formatItem.addHandler((s, e) => {
    if (s.cells !== e.panel) {
      return;
    }

    let templ = grid.cells.columns[e.col]['$__cellTemplCell'];
    if (templ) {
      // compile templates
      let context = {
        row: e.row,
        col: e.col,
        item: e.panel.rows[e.row].dataItem,
        cell: {
          row: e.row,
          col: e.col,
          item: e.panel.rows[e.row].dataItem,
        }
      };

      let insTemp = renderToString(templ.template(context));
      e.cell.innerHTML = insTemp;
    }
    // if itemFormatter, apply its binding
    if (grid.itemFormatter) {
      grid.itemFormatter(e.panel, e.row, e.col, e.cell);
    }
  });

  clone.invalidate();

  return clone;
}