import { Action } from "@reduxjs/toolkit";
import AuditsApi from "api/auditing/AuditsApi";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { finishViewingEvidence, viewEvidence } from "./QuestionEvidenceSlice";

export default function* questionEvidenceSagas() {
  yield all([
    viewEvidenceAsync(),
  ]);
}

function* viewEvidenceAsync() {
  yield takeEvery(viewEvidence, function* (action: Action) {
    if (!viewEvidence.match(action)) {
      return;
    }

    try {
      // Try to get the url from the server.
      const url: string = yield call(AuditsApi.getQuestionEvidenceUrl,
        action.payload.auditId,
        action.payload.auditQuestionId,
        action.payload.evidenceId);

      // Then open a window to show it.
      window.open(url, "_blank");
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      yield put(finishViewingEvidence());
    }
  });
}