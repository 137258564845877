import { authGetJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { ICustomSearchListItem } from "types/customSearchTypes";

class CustomSearchApi {
  public async getCustomSearchList(): Promise<{
    auditList: ICustomSearchListItem[],
    actionList: ICustomSearchListItem[],
  }> {
    const response = await authGetJson(config.endpoints.customSearch.getCustomSearchList);

    await throwIfResponseError(response);

    const obj = await response.json();

    return {
      auditList: obj.savedAuditFilters,
      actionList: obj.savedActionFilters,
    };
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new CustomSearchApi();
