import { ComplianceResults } from "types/auditPlanningTypes";
import { IComplianceQuestionProps, IComplianceScore } from "../auditScoreCalculator";

class HSEScoreCalculator {
  calculateComplianceScore(questions: IComplianceQuestionProps[]): IComplianceScore {
    const ltoQuestions = questions
      .filter(x => x.isLto
        && (x.category === "Category 1"
          || x.category === "Category 2")
      );

    const complianceData = this.getCompliance(questions);
    const ltoComplianceData = this.getCompliance(ltoQuestions);

    const result: IComplianceScore = {
      overallCompliance: complianceData?.compliance,
      overallPercentage: complianceData?.overallPercentage,
      cat1Percentage: complianceData?.cat1Percentage,
      cat2Percentage: complianceData?.cat2Percentage,
      ltoCompliance: ltoComplianceData?.overallPercentage,
      numLTONonCompliantQuestions: ltoQuestions.filter(x => x.answer === "N").length,
      numLTOQuestions: ltoQuestions.length,
    };

    return result;
  };

  private getCompliance(questions: IComplianceQuestionProps[]) {
    const cat1qs = questions.filter(x => x.category === "Category 1");
    const cat2qs = questions.filter(x => x.category === "Category 2");

    const cat1YesCount = cat1qs.filter(x => x.answer === "Y").length;
    const cat1NoCount = cat1qs.filter(x => x.answer === "N").length;

    const cat2YesCount = cat2qs.filter(x => x.answer === "Y").length;
    const cat2NoCount = cat2qs.filter(x => x.answer === "N").length;

    const cat1Total = cat1YesCount + cat1NoCount;
    const cat2Total = cat2YesCount + cat2NoCount;

    const overallTotal = cat1Total + cat2Total;

    if (overallTotal === 0) {
      return undefined;
    }

    const overallPercentage = overallTotal === 0
      ? undefined
      : (cat1YesCount + cat2YesCount) / overallTotal;
    const cat1Percentage = cat1Total === 0
      ? undefined
      : cat1YesCount / cat1Total;
    const cat2Percentage = cat2Total === 0
      ? undefined
      : cat2YesCount / cat2Total;

    if (cat1Total > 0) {
      if (cat1NoCount > 0) {
        return {
          compliance: ComplianceResults.NonCompliance,
          overallPercentage,
          cat1Percentage,
          cat2Percentage,
        };
      } else if (cat2NoCount > 0) {
        return {
          compliance: ComplianceResults.BasicCompliance,
          overallPercentage,
          cat1Percentage,
          cat2Percentage,
        };
      } else {
        return {
          compliance: ComplianceResults.FullCompliance,
          overallPercentage,
          cat1Percentage,
          cat2Percentage,
        };
      }
    } else {
      if (cat2NoCount === 0) {
        return {
          compliance: ComplianceResults.FullCompliance,
          overallPercentage,
          cat1Percentage,
          cat2Percentage,
        };
      } else {
        return {
          compliance: ComplianceResults.BasicCompliance,
          overallPercentage,
          cat1Percentage,
          cat2Percentage,
        };
      }
    }
  }
}

export default HSEScoreCalculator;