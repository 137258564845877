import React from "react";
import BusinessFunctionPicker from "shared/components/controls/pickers/BusinessFunctionPicker";
import { setAssignAuditorByAssociationsModal } from "store/audit-summary/AuditSummarySlice";
import { useAppDispatch, useAppSelector } from "store/store";
import HasNoMetaMessage from "./HasNoMetaMessage";

const BusinessFunctionTabContent: React.FC = () => {
  const metaIdsInAudit = useAppSelector(store => store.audit.audit?.businessFunctions.map(x => x.id)) || [];
  const questionsInAudit = useAppSelector(store => store.audit.questions);
  const selectedItems = useAppSelector(store => store.auditSummary.assignAuditorByAssociationsModal.selectedBusinessFunctions);
  const dispatch = useAppDispatch();

  const availableMetaIds = metaIdsInAudit
    .filter(mId => questionsInAudit
      .some(q => q.businessFunctions
        .some(bf => bf.id === mId)));

  if (!availableMetaIds.length) {
    return <HasNoMetaMessage />;
  }

  return (
    <BusinessFunctionPicker
      onApply={items => dispatch(setAssignAuditorByAssociationsModal({
        selectedBusinessFunctions: items,
      }))}
      selectedItems={selectedItems}
      renderMode="list"
      showSuggestions={false}
      showSelectedItems={false}
      allowMultiselect
      isItemDisabledMapper={item => !availableMetaIds.includes(item.id)}
      hideUnselectableBranches={true}
      hideLabelAboveSearch={true}
    />
  );
};

export default BusinessFunctionTabContent;