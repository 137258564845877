import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { IAuditTopic } from "types/auditMasterDataTypes";

export interface IAuditTopicRearrangeState {
  loadOp?: IOperation<IAuditTopicRearrangeWorkspace>,
  saveOp?: IOperation<void>,
  original: IAuditTopicRearrangeWorkspace,
  current: IAuditTopicRearrangeWorkspace,
  isDirty: boolean,
}

export interface IAuditTopicRearrangeWorkspace {
  auditTopics: IAuditTopic[],
}

const initialState: IAuditTopicRearrangeState = {
  loadOp: undefined,
  saveOp: undefined,
  original: { auditTopics: [], },
  current: { auditTopics: [], },
  isDirty: false,
};

export const AuditTopicRearrangeSlice = createSlice({
  name: "auditTopicRearrange",
  initialState,
  reducers: {
    /** Runs the initial page load procedure for Audit Topic Rearrange page. */
    runInitialPageLoad: state => {
      state.loadOp = { isWorking: true };
    },

    /** Dispatched automatically when the initial page load procedure completes. */
    finishInitialPageLoad: (state, action: PayloadAction<IOperation<IAuditTopicRearrangeWorkspace>>) => {
      if (action.payload.errorMessage) {
        state.loadOp = action.payload;
        return;
      }

      if (action.payload.data) {
        state.original = cloneDeep(action.payload.data);
        state.current = cloneDeep(action.payload.data);
      }

      state.loadOp = undefined;
      state.isDirty = false;
    },

    /** Saves a rearranged item. Called when the user drops an AuditTopic into a new spot. */
    rearrangeItem: (state, action: PayloadAction<{ oldIndex: number, newIndex: number }>) => {
      let newArray: (IAuditTopic | undefined)[] = state.current.auditTopics.slice();
      const itemToMove = newArray[action.payload.oldIndex];
      newArray[action.payload.oldIndex] = undefined;
      newArray.splice(action.payload.newIndex, 0, itemToMove);
      state.current.auditTopics = newArray.filter(isNotUndefined);

      state.current.auditTopics.forEach((item, ix) => {
        item.sortOrder = ix + 1;
      });

      state.isDirty = !!getDirtyAuditTopics(state).length;
    },

    /** Saves any changes made by the user to the audit topic order. */
    saveChanges: state => {
      state.saveOp = { isWorking: true };
    },

    /** Dispatched automatically when the saveChanges operation completes. */
    finishSaveChanges: (state, action: PayloadAction<IOperation<void>>) => {
      if (action.payload.errorMessage) {
        state.saveOp = undefined;
        return;
      }

      state.original = cloneDeep(state.current);
      state.isDirty = false;
      state.saveOp = undefined;
    },
  },
});

export const {
  runInitialPageLoad,
  finishInitialPageLoad,
  rearrangeItem,
  saveChanges,
  finishSaveChanges,
} = AuditTopicRearrangeSlice.actions;

/** Finds all AuditTopics that now have a different SortOrder. */
export function getDirtyAuditTopics(state: IAuditTopicRearrangeState): IAuditTopic[] {
  return state.current.auditTopics.filter(newItem =>
    state.original.auditTopics.find(x => x.id === newItem.id)?.sortOrder !== newItem.sortOrder);
}