import { authDelete, authFetch, authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IQuestion, IQuestionVersionHistoryItem } from "types/questionTypes";
import { formatQuestionDetails, formatQuestionVersionHistoryItem, formatSaveQuestionRequest } from "./formatters/questionFormatters";

class QuestionApi {
  /** Gets a single question from the API by its Id. */
  public async getQuestionDetails(id: number, abortSignal?: AbortSignal): Promise<IQuestion> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/questions/${id}`,
      abortSignal);
    await throwIfResponseError(response);
    const respObj = await response.json();
    return formatQuestionDetails(respObj);
  }

  /** Marks the specified question as inactive. */
  public async deactivateQuestion(id: number,
    comment: string,
    autoReactivationTimestamp?: number,
    abortSignal?: AbortSignal): Promise<number> {
    const response = await authDelete(`${config.endpoints.baseUrl}/questions/${id}`,
      {
        comment,
        autoReactivationTimestamp: autoReactivationTimestamp
          ? new Date(autoReactivationTimestamp)
          : undefined,
      },
      abortSignal);

    await throwIfResponseError(response);
    return Number(await response.json());
  }

  /** Marks the specified question as active. */
  public async reactivateQuestion(id: number,
    comment: string,
    autoDeactivationTimestamp?: number,
    abortSignal?: AbortSignal): Promise<number> {
    const response = await authPutJson(`${config.endpoints.baseUrl}/questions/${id}/reactivate`,
      {
        comment,
        autoDeactivationTimestamp: autoDeactivationTimestamp
          ? new Date(autoDeactivationTimestamp)
          : undefined,
      },
      abortSignal);

    await throwIfResponseError(response);
    return Number(await response.json());
  }

  /**
   * Creates a new question (if `question.Id` is 0) or creates a new version of an existing question.
   * @param question
   * @param abortSignal
   * @returns The Id of the newly created question or question version.
   */
  public async saveQuestion(question: IQuestion,
    payload: {
      saveAsNewCopy: boolean,
      isCLM: boolean,
      comment: string
    },
    abortSignal?: AbortSignal): Promise<number> {
    const requestObj = formatSaveQuestionRequest(question, payload.isCLM, payload.comment);

    // If the question has no id number, consider it a creation. Else, update.
    const response = !question.id || payload.saveAsNewCopy
      ? await authPostJson(`${config.endpoints.baseUrl}/questions`,
        requestObj,
        abortSignal)
      : await authPutJson(`${config.endpoints.baseUrl}/questions/${question.id}`,
        requestObj,
        abortSignal);

    await throwIfResponseError(response);
    return Number(await response.json());
  }

  /**
   * Saves changes to a question's additional associations. All other data provided will be ignored.
   * @param question
   * @param abortSignal
   * @returns The Id of the newly created question version.
   */
  public async saveQuestionAdditionalAssociations(question: IQuestion,
    abortSignal?: AbortSignal): Promise<number> {
    const requestObj = formatSaveQuestionRequest(question);

    // If the question has no id number, consider it a creation. Else, update.
    const response = await authPutJson(`${config.endpoints.baseUrl}/questions/${question.id}/additionalAssociation`,
      requestObj,
      abortSignal);

    await throwIfResponseError(response);
    return Number(await response.json());
  }

  /**
   * Retrieves a single question history log item by its id--including the DiffDetails json.
   * @param logId
   * @param abortSignal
   */
  public async getQuestionHistoryLogItem(logId: number, abortSignal?: AbortSignal): Promise<IQuestionVersionHistoryItem> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/questionHistory/${logId}`,
      abortSignal);

    throwIfResponseError(response);

    return formatQuestionVersionHistoryItem(await response.json());
  }

  /**
   * Create and update questions by bulk
   * @param files
   * @param abortSignal
   */
  public async bulkUploadQuestions(
    files: File[],
    abortSignal?: AbortSignal,
  ): Promise<any> {
    let formData = new FormData();

    let fileIndex = 0;
    let limitFiles = 5;

    for (let i = 0; i < files.length && i <= limitFiles; i++) {
      fileIndex += 1;
      formData.append(`File${fileIndex}`, files[i]);
    }

    let response = await authFetch(
      "POST",
      `${config.endpoints.baseUrl}/questions/importer`,
      formData,
      abortSignal,
    );

    await throwIfResponseError(response);
    return response.blob();
  }

  /**
   * Create and update questions by bulk
   * @param abortSignal
   */
  public async bulkQuestionsTemplateDownload(abortSignal?: AbortSignal): Promise<Blob> {
    const exporturl = `${config.endpoints.baseUrl}/questions/template`;
    const response = await authGetJson(exporturl, abortSignal);
    await throwIfResponseError(response);
    return await response.blob();
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new QuestionApi();
