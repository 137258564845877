import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Modal from "shared/components/layout/modal/Modal";
import { savePlanningView, setSaveViewModal } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const SaveViewModal: React.FC = () => {
  const saveViewModal = useAppSelector(store => store.auditPlanning.saveViewModal);

  const dispatch = useAppDispatch();

  let modalTitle = `Save View${saveViewModal.saveType === "saveAs" ? " As" : ""}`

  const onCancel = () => dispatch(setSaveViewModal({
    isOpen: false,
  }));

  return (
    <Modal
      header={modalTitle}
      isOpen={saveViewModal.isOpen}
      buttons={[{
        key: "cancel",
        text: "Cancel",
        className: "secondary",
        onClick: onCancel,
      }, {
        key: "save",
        text: "Save",
        className: "primary",
        disabled: !saveViewModal.viewName.trim(),
        onClick: () => dispatch(savePlanningView({
          viewName: saveViewModal.viewName,
          saveType: saveViewModal.saveType,
        })),
      }]}
      showCloseButton
      onCloseButtonClicked={onCancel}
    >
      <LabeledControl
        label="View Name"
        isRequired
      >
        <input
          type="text"
          value={saveViewModal.viewName}
          onChange={e => dispatch(setSaveViewModal({ viewName: e.currentTarget.value }))}
          maxLength={100}
        />
      </LabeledControl>
    </Modal>
  );
};

export default SaveViewModal;