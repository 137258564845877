import AuditsApi from "api/auditing/AuditsApi";
import { takeLatest, put, call } from "redux-saga/effects";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { IAuditInfo } from "types/auditingTypes";
import { loadAudit, finishLoadAudit } from "../AuditSlice";

export function* loadAuditAsync() {
  yield takeLatest(loadAudit, function* (action) {
    if (!loadAudit.match(action)) {
      return;
    }

    try {
      const auditInfo: IAuditInfo = yield call(AuditsApi.getAuditInfo, action.payload);

      yield put(finishLoadAudit({
        isWorking: false,
        data: auditInfo,
      }));
    } catch (err) {
      yield put(finishLoadAudit({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

