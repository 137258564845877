import DatePicker, { DatePickerSelectionMode } from "shared/components/controls/date-picker/DatePicker";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { setSearchPropValue } from "store/search/audits/SearchAuditsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const ClosureDate: React.FC = () => {
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(store => store.searchAudits.closureDateStart);
  const endDate = useAppSelector(store => store.searchAudits.closureDateEnd);

  return (
    <LabeledControl
      label="Closure Date"
    >
      <DatePicker
        selectionMode={DatePickerSelectionMode.range}
        value={startDate}
        startDate={startDate ? new Date(startDate) : undefined}
        endDate={endDate ? new Date(endDate) : undefined}
        onChange={dateRange => {
          if (Array.isArray(dateRange)) {
            dispatch(setSearchPropValue({
              closureDateStart: dateRange[0] === null
                ? undefined
                : dateRange[0].getTime(),
              closureDateEnd: dateRange[1] === null
                ? undefined
                : dateRange[1].getTime(),
            }));
          }
        }}
        isClearable
        placeholderText="Select"
      />
    </LabeledControl>
  );
};

export default ClosureDate;