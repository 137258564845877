import ActionItemsApi from "api/actionItems/ActionItemsApi";
import React, { useState } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { getTextboxMaxLengthHint, tooltipMessage } from "shared/utilities/stringUtilities";
import { useAppDispatch } from "store/store";
import { IActionItemHistoryItem } from "types/actionItemTypes";
import SendNotificationCheckbox from "../send-notification-checkbox/SendNotificationCheckbox";
import "./CommentModal.scoped.scss";

interface ICommentModalProps {
  /** The ActionItem id to comment on. */
  actionItemId: number,
  /** The function to call when the user clicks the close button. */
  onClose(): void,
  /** The function to call after the new comment has been successfully added. */
  onActionItemCommentAdded(event: {
    actionItemId: number,
    newHistoryItem: IActionItemHistoryItem,
  }): void,
  /** The default state of the check box for send notification */
  defaultSendNotification: boolean,
}

const CommentModal: React.FC<ICommentModalProps> = ({
  actionItemId,
  onClose,
  onActionItemCommentAdded,
  defaultSendNotification,
}) => {
  const [comment, setComment] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [isSendNotificationSelected, setSendEmailNotification] = useState(defaultSendNotification);

  const dispatch = useAppDispatch();

  const saveComment = async () => {
    try {
      setIsWorking(true);
      const newHistoryItem = await ActionItemsApi.addActionItemComment(actionItemId, comment, isSendNotificationSelected);
      setIsWorking(false);

      dispatch(showSuccessToast("Comment saved successfully."));

      onActionItemCommentAdded({
        actionItemId,
        newHistoryItem,
      });

      onClose();
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
      setIsWorking(false);
    }
  };

  return (
    <Modal
      header="Add Action Item Comment"
      isOpen={true}
      buttons={[{
        key: "CANCEL",
        text: "Cancel",
        className: "secondary",
        onClick: onClose,
      }, {
        key: "Submit",
        text: "Submit",
        className: "primary",
        disabled: !comment.trim(),
        title: tooltipMessage(!comment.trim()),
        onClick: saveComment,
      }]}
      showCloseButton={true}
      onCloseButtonClicked={onClose}
      bottomLeftNode={
        <SendNotificationCheckbox
          isSendNotificationSelected={isSendNotificationSelected}
          setSendEmailNotification={setSendEmailNotification}
        />
      }
    >
      <FlexCol>
        <LabeledControl
          isRequired={true}
          label="Comment"
          hint={getTextboxMaxLengthHint(1000)}
        >
          <textarea
            value={comment}
            onChange={e => setComment(e.currentTarget.value)}
            maxLength={1000}
          />
        </LabeledControl>
      </FlexCol>

      {isWorking && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default CommentModal;
