import { sortBy } from "lodash";
import React, { useMemo } from "react";
import { useAppSelector } from "store/store";
import { IAuditCreationItem } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import CreateAuditModal from "../../create-audit-modal/CreateAuditModal";
import { IPlanningItem } from "../../planning-grid/PlanningGrid";

const PlanResultsCreateAuditModal: React.FC = () => {
  const planIds = useAppSelector(store => store.auditPlanResults.createAuditModal.planIds);
  const plans = useAppSelector(store => store.auditPlanning.planResultsPlans);
  const yAxisData = useAppSelector(store => store.auditPlanning.planApprovalsResultsYAxisData);
  const xAxisData = useAppSelector(store => store.auditPlanning.xAxisData);

  const items = useMemo(() => {
    const planItems = plans
      .filter(x => planIds.indexOf(x.id) > -1)
      .map(plan => {
        const req = xAxisData
          .find(x => x.id === plan.requirementDimensionId
            && x.type === plan.requirementDimensionType);

        const parentData = recursiveFindParentItem(plan.parentDimensionType,
          plan.parentDimensionId,
          yAxisData,
          []);

        let childItem: IPlanningItem | undefined;

        if (parentData?.item
          && plan.childDimensionId) {
          childItem = parentData?.item.children.find(x => x.id === plan.childDimensionId
            && x.type === plan.childDimensionType);
        }

        let geoItem: IPlanningItem | undefined;


        if (parentData?.path
          && parentData.path.length > 0) {
          geoItem = parentData.path[parentData.path.length - 1];
        }

        const item: IAuditCreationItem = {
          plan: {
            ...plan,
            leadAuditorEmail: plan.leadAuditorEmail,
            parentDimensionSubType: parentData?.item.subType,
            childDimensionSubType: childItem?.subType,
          },
          requirementName: req?.text,
          requirementType: req?.type,
          requirementId: req?.id || 0,
          parentName: parentData?.item.text,
          childName: childItem?.text,
          geoName: geoItem?.text,
        };

        return item;
      });

    sortBy(planItems, ["geoName", "parentName", "childName", "requirementName"]);

    return planItems;
  }, [plans, planIds, xAxisData, yAxisData]);

  return (
    <CreateAuditModal
      plans={items}
    />
  );
};

export default PlanResultsCreateAuditModal;

function recursiveFindParentItem(type: MetaDataTypes,
  id: number,
  yAxisData: IPlanningItem[],
  searchPath: IPlanningItem[]): {
    item: IPlanningItem,
    path: IPlanningItem[],
  } | undefined {
  for (let i = 0; i < yAxisData.length; i++) {
    if (yAxisData[i].type === type
      && yAxisData[i].id === id) {
      return {
        item: yAxisData[i],
        path: searchPath,
      };
    }

    if (yAxisData[i].children.length) {
      let foundItem = recursiveFindParentItem(type, id, yAxisData[i].children, [...searchPath, yAxisData[i]]);

      if (foundItem) {
        return foundItem;
      }
    }
  }
}
