import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback, useState } from "react";
import ErrorWithRetry from "shared/components/common/error-with-retry/ErrorWithRetry";
import Spinner from "shared/components/common/spinner/Spinner";
import TabSelection from "shared/components/layout/tab-selection/TabSelection";
import { IOperation } from "shared/types/operationTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";
import { setCurrentFilters } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAuditType } from "types/auditMasterDataTypes";
import "./AuditTypeSelector.scoped.scss";

const AuditPlanSelector: React.FC = () => {
  const selectedAuditType = useAppSelector(store => store.auditPlanning.currentFilters.auditType);

  const [loadOperation, setLoadOperation] = useState<IOperation<IAuditType[]>>({
    isWorking: false,
  });

  const dispatch = useAppDispatch();

  const isWorking = loadOperation?.isWorking;
  const errorMsg = loadOperation?.errorMessage;
  const loadedAuditTypes = loadOperation.data;

  const loadAction = useCallback(async (aborted: boolean) => {
    if (loadedAuditTypes
      && selectedAuditType) {
      return;
    }

    if (aborted) return;
    setLoadOperation({
      isWorking: true,
    });

    try {
      const auditTypes = await MasterDataApi.getAuditTypes();

      if (!selectedAuditType
        && auditTypes.length) {
        // Automatically select the first available audit type.
        dispatch(setCurrentFilters({
          auditType: auditTypes[0],
        }));
      }

      if (aborted) return;
      setLoadOperation({
        isWorking: false,
        data: auditTypes,
      });
    } catch (err) {
      if (aborted) return;
      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      });
    }
  }, [setLoadOperation, dispatch, selectedAuditType, loadedAuditTypes]);

  useAsyncEffect(async (aborted) => {
    loadAction(aborted);
  }, [loadAction]);

  if (isWorking) {
    return (
      <Spinner
        className="icon-small"
      />
    );
  } else if (errorMsg) {
    return (
      <ErrorWithRetry
        tooltip={errorMsg}
        onRetryClicked={() => loadAction(false)}
      />
    );
  }

  return (
    <div
      className="type-tabs"
    >
      <TabSelection

        tabs={(loadOperation.data || []).map(x => ({
          className: "audit-type-title",
          key: x.id,
          node: x.name,
          isSelected: x.id === selectedAuditType?.id,
        }))}
        onTabClicked={key => {
          const item = (loadOperation.data || []).find(x => x.id === key);
          if (item) {
            dispatch(setCurrentFilters({
              auditType: item,
            }));
          }
        }}
      />
    </div>
  );
};

export default AuditPlanSelector;