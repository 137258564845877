import { IAuditSchedulerItemData, IQISchedulerItem } from "shared/components/controls/scheduler/qiSchedulerTypes";
import SchedulerAuditCompliance from "../scheduler-audit-compliance/SchedulerAuditCompliance";

export default function AuditCalendarItem({ item }: {
  item: IQISchedulerItem<IAuditSchedulerItemData>,
}) {
  if (!item.itemData) {
    return null;
  }

  const itemData = item.itemData;

  return (
    <>
      <div className={`audit-type rounded ${itemData.type}`}>
        {getAuditTypeString(itemData.auditTypeName)}
      </div>

      {
        !!itemData.auditCompliance && (
          <SchedulerAuditCompliance
            compliance={itemData.auditCompliance}
          />
        )
      }

      {
        itemData.requirementDimensionTexts.map(x =>
          <span
            className="topic"
            key={x}
          >
            {x}
          </span>
        )
      }
    </>
  );
}

function getAuditTypeString(auditTypeName: string) {
  if (auditTypeName.includes("1st Party")
    || auditTypeName.includes("First")) {
    return "FP";
  } else if (auditTypeName.includes("Self")) {
    return "SA";
  }

  return auditTypeName.substring(0, 3);
}
