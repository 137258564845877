import { matchPath } from "react-router-dom";

export interface IRoutes {
  Root: IRoute,
  NotImplemented: IRoute,

  // Audits ////////////////////////////////////////////////////////
  MyAudits: IRoute,
  NewAudit: IRoute,
  EditAudit: IRoute,
  AuditSummary: IRoute,
  AuditExecuteAll: IRoute,
  AuditExecuteQuestion: IRoute,
  AuditEvidence: IRoute,
  AuditFindings: IRoute,
  AuditFindingsShowFinding: IRoute,
  AuditRWP: IRoute,
  AuditResult: IRoute,
  AuditNonConformance: IRoute,
  AuditReport: IRoute,

  // Actions ////////////////////////////////////////////////////////
  MyActions: IRoute,

  // Admin ////////////////////////////////////////////////////////
  AuditAdmin: IRoute,
  QuestionLibrary: IRoute,
  NewQuestion: IRoute,
  EditQuestion: IRoute,
  ViewQuestion: IRoute,
  AdminUserManagement: IRoute,
  AdminAuditors: IRoute,
  AdminTopics: IRoute,
  AdminTopicsRearrange: IRoute,
  AdminTemplates: IRoute,
  AdminVerificationMethods: IRoute,
  AdminQuestionTypes: IRoute,
  AdminOwnerGroups: IRoute,
  AdminQuestionCategories: IRoute,
  AdminAuditGroups: IRoute,
  AdminAuditTypes: IRoute,
  AdminCausalFactors: IRoute,
  AdminHSEMSSubElements: IRoute,
  AdminActionItemTypes: IRoute,
  AdminActionItemPrioritiesName: IRoute,
  AdminAPIs: IRoute,
  AdminISOs: IRoute,
  AdminAuditPlanning: IRoute,
  AdminFacilityAttribute: IRoute,
  AdminPlanApproval: IRoute,

  // Planning ////////////////////////////////////////////////////////
  PlanApprovals: IRoute,
  PlanVsResults: IRoute,
  FacilityAttributes: IRoute,
  PlanCalendar: IRoute,

  // Search ////////////////////////////////////////////////////////
  Search: IRoute,
  SearchAudits: IRoute,
  SearchActions: IRoute,
  AuditSearchResults: IRoute,
  ActionSearchResults: IRoute,
  LegacyChecklistMappings: IRoute,
  LegacyNodeMapping: IRoute,

  //PowerBi Report
  PowerBiReportMapping: IRoute,
}

export interface IRoute {
  urlTemplate: string,
  label: string,
}

const UrlRoutes: IRoutes = {
  Root: {
    urlTemplate: "/",
    label: "/",
  },

  NotImplemented: {
    urlTemplate: "/not-implemented",
    label: "Not Implemented",
  },

  // Audits ////////////////////////////////////////////////////////
  MyAudits: {
    urlTemplate: "/audits",
    label: "My Audits",
  },

  NewAudit: {
    urlTemplate: "/audits/new",
    label: "New Audit",
  },

  EditAudit: {
    urlTemplate: "/audits/:auditId/edit",
    label: "Edit Audit",
  },

  AuditSummary: {
    urlTemplate: "/audits/:auditId/summary",
    label: "Audit Summary",
  },

  AuditExecuteAll: {
    urlTemplate: "/audits/:auditId/execute",
    label: "Audit Questions",
  },

  AuditExecuteQuestion: {
    urlTemplate: "/audits/:auditId/execute/:auditQuestionId",
    label: "Audit Question",
  },

  AuditEvidence: {
    urlTemplate: "/audits/:auditId/evidences",
    label: "Audit Evidence",
  },

  AuditFindings: {
    urlTemplate: "/audits/:auditId/findings",
    label: "Findings",
  },

  AuditFindingsShowFinding: {
    urlTemplate: "/audits/:auditId/findings/:findingId",
    label: "Findings",
  },

  AuditRWP: {
    urlTemplate: "/audits/:auditId/rwp",
    label: "Audit Remedial Work Plan",
  },

  AuditResult: {
    urlTemplate: "/audits/:auditId/auditResult",
    label: "Audit Results",
  },

  AuditNonConformance: {
    urlTemplate: "/audits/:auditId/auditNonConformance",
    label: "Audit NonConformances",
  },

  AuditReport: {
    urlTemplate: "/audits/:auditId/report",
    label: "Audit Reports",
  },

  // Actions ////////////////////////////////////////////////////////
  MyActions: {
    urlTemplate: "/actions",
    label: "My Actions",
  },

  // Admin ////////////////////////////////////////////////////////
  AuditAdmin: {
    urlTemplate: "/admin",
    label: "Audit Administration",
  },

  QuestionLibrary: {
    urlTemplate: "/admin/questions",
    label: "Audit Question Library",
  },

  NewQuestion: {
    urlTemplate: "/admin/questions/new",
    label: "New Question",
  },

  EditQuestion: {
    urlTemplate: "/admin/questions/:id/edit",
    label: "Question Details",
  },

  ViewQuestion: {
    urlTemplate: "/admin/questions/:id/view",
    label: "Question Details",
  },

  AdminUserManagement: {
    urlTemplate: "/admin/users",
    label: "User Management",
  },

  AdminAuditors: {
    urlTemplate: "/admin/auditors",
    label: "Lead Auditor/Auditor",
  },

  AdminTopics: {
    urlTemplate: "/admin/topics",
    label: "Audit Topics & Sub-Topics",
  },

  AdminTopicsRearrange: {
    urlTemplate: "/admin/topics/rearrange",
    label: "Rearrange Audit Topics",
  },

  AdminTemplates: {
    urlTemplate: "/admin/templates",
    label: "Audit Templates",
  },

  AdminVerificationMethods: {
    urlTemplate: "/admin/verificationMethods",
    label: "Verification Methods",
  },

  AdminQuestionTypes: {
    urlTemplate: "/admin/questionTypes",
    label: "Question Types",
  },

  AdminOwnerGroups: {
    urlTemplate: "/admin/ownerGroups",
    label: "Owner Groups",
  },

  AdminQuestionCategories: {
    urlTemplate: "/admin/questionCategories",
    label: "Question Categories",
  },

  AdminAuditGroups: {
    urlTemplate: "/admin/auditGroups",
    label: "Audit Groups",
  },

  AdminAuditTypes: {
    urlTemplate: "/admin/auditTypes",
    label: "Audit Types",
  },

  AdminCausalFactors: {
    urlTemplate: "/admin/causalFactors",
    label: "Causal Factors",
  },

  AdminHSEMSSubElements: {
    urlTemplate: "/admin/HSEMSSubElements",
    label: "HSE Management System Sub-Elements",
  },

  AdminActionItemTypes: {
    urlTemplate: "/admin/actionItemTypes",
    label: "Action Item Types",
  },

  AdminActionItemPrioritiesName: {
    urlTemplate: "/admin/actionItemPrioritiesName",
    label: "Action Item Priorities - Name",
  },

  AdminAPIs: {
    urlTemplate: "/admin/APIs",
    label: "APIs",
  },

  AdminISOs: {
    urlTemplate: "/admin/ISOs",
    label: "ISOs",
  },

  AdminFacilityAttribute: {
    urlTemplate: "/admin/planning/facility",
    label: "Facility Attributes Admin"
  },

  AdminAuditPlanning: {
    urlTemplate: "/admin/planning/facility",
    label: "Audit Planning Administration"
  },

  AdminPlanApproval: {
    urlTemplate: "/admin/planning/plan",
    label: "Plan Approval Admin"
  },

  // Planning ////////////////////////////////////////////////////////
  PlanApprovals: {
    urlTemplate: "/planning/plan-approvals",
    label: "Audit Plan Approval",
  },

  PlanVsResults: {
    urlTemplate: "/planning/plan-vs-results",
    label: "Audit Plan & Results",
  },

  FacilityAttributes: {
    urlTemplate: "/planning/facility-attributes",
    label: "Facility Attributes",
  },

  PlanCalendar: {
    urlTemplate: "/planning/calendar",
    label: "Calendar",
  },

  // Search ////////////////////////////////////////////////////////
  Search: {
    urlTemplate: "/search",
    label: "Search",
  },

  SearchAudits: {
    urlTemplate: "/search/audits",
    label: "Search Audits",
  },

  SearchActions: {
    urlTemplate: "/search/actions",
    label: "Search Actions",
  },

  AuditSearchResults: {
    urlTemplate: "/search/auditResults",
    label: "Audit Search Results",
  },

  ActionSearchResults: {
    urlTemplate: "/search/actionResults",
    label: "Action Search Results",
  },

  // Legacy ////////////////////////////////////////////////////////
  LegacyChecklistMappings: {
    urlTemplate: "/legacy/checklistMappings",
    label: "QUEST Checklist / QI Topic Mappings",
  },

  LegacyNodeMapping: {
    urlTemplate: "/legacy/nodeMappings",
    label: "Legacy Node Mapping",
  },

  PowerBiReportMapping: {
    urlTemplate: "/powerbi/report",
    label: "PowerBi Report",
  },
};

export default UrlRoutes;

export interface IRouteParameters {
  [paramName: string]: string,
}

/**
 * Replaces all :parameters in a route with values provided in an object. If any parameters in the route are not represented
 * by values in the parameters object, an error will be logged to the console.
 *
 * For example, to format the EditAudit route ("/audits/:auditId/edit"), pass Routes.EditAudit and { auditId: "7721" }.
 * If you do not provide an auditId property in the parameters object, an error will be logged to the console.
 * @param route The route to replace templated parameters in.
 * @param parameters An object whose properties match the parameter names and whose values are the values to put into the url.
 * @returns The formatted url.
 */
 export function formatRoute(urlTemplate: IRoute, parameters: IRouteParameters) {
  let filledRoute = urlTemplate.urlTemplate;

  Object
    .entries(parameters)
    .forEach(entry => {
      filledRoute = filledRoute.replace(new RegExp(`:${entry[0]}`, "g"), entry[1]);
    });

  let remainingParameters = filledRoute.match(/(:[a-zA-Z]+)/g);

  if (remainingParameters) {
    console.error(`Route template has unfilled parameters: ${remainingParameters.join(', ')}`);
  }

  return filledRoute;
}

export function getMatchingRoute(url: string): IRoute | undefined {
  for (const entry of Object.entries(UrlRoutes)) {
    if (matchPath(entry[1].urlTemplate, url)) {
      return entry[1] as IRoute;
    }
  }
}
