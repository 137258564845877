import React from "react";
import FlowLayout from "../FlowLayout";
import "./Page.scss";
import PageBar, { IPageHeaderControl } from "./PageBar";
import PageFooter from "./PageFooter";

export interface IPageProps {
  /** The title to show on the page header bar. Optional. */
  title?: string,
  /** The route to visit when the user clicks the back button. If not specified, the back button does not appear. Optional. */
  backButtonRoute?: string,
  /** The elements to show on the right of the page header bar. Optional. */
  headerControls?: IPageHeaderControl[],
  /** The content to show in the footer bar to show at the bottom of the screen. If not specified, no footer appears. Optional. */
  footer?: React.ReactNode,
  /** The content to show between the title and header controls in the page header bar. Optional. */
  centerNode?: React.ReactNode,
  /** The max number of character of the title to show before it becomes truncated. Default = 40. */
  maxTitleLength?: number,
  /** An optional props to pass children */
  children?: React.ReactNode,
  /** Optional. An extra classname applied to the page body component. */
  className?: string,
  /** Optional. If true, breadcrumbs will be shown above the title in its own row. */
  showBreadcrumbs?: boolean,
}

/** The standard layout for a page within the app. */
const Page: React.FC<IPageProps> = ({
  title,
  backButtonRoute,
  headerControls,
  children,
  footer,
  centerNode,
  maxTitleLength = 40,
  className,
  showBreadcrumbs,
}) =>
  <FlowLayout
    classNames={{
      header: "page-header",
      body: `page-body ${className || ""}`,
      layout: "page-layout"
    }}
    header={title
      ? (
        <PageBar
          title={title}
          backButtonRoute={backButtonRoute}
          headerControls={headerControls}
          centerNode={centerNode}
          maxTitleLength={maxTitleLength}
          showBreadcrumbs={showBreadcrumbs}
        />
      ) : undefined
    }
    footer={footer === undefined
      ? undefined
      : (
        <PageFooter>
          {footer}
        </PageFooter>
      )
    }
  >
    <div
      className="page-min-layout"
    >
      {children}
    </div>
  </FlowLayout>;

export default Page;