import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { cloneDeep, isEqual } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IOwnerGroup } from "types/auditingTypes";
import { IVerificationMethod } from "types/auditMasterDataTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import { IQuestion, IQuestionMetaDataItem } from "types/questionTypes";

export interface IQuestionDetailsMasterData {
  /** The list of owner groups loaded from the server. Used to show the user the OwnerGroup of the selected topic. */
  ownerGroups: IOwnerGroup[],
  /** The list of available verification methods the user may choose from while editing the question. */
  verificationMethods: IVerificationMethod[],
}

export interface IQuestionActiveChangeModal {
  isOpen: boolean,
  reason: string,
  revertOnTimestamp?: number,
}

export interface IQuestionDetailsState {
  /** The current question data the user is editing. */
  question: Partial<IQuestion> | undefined,
  /** If editing a question, this will be a copy of the original values. */
  originalQuestion: IQuestion | undefined,
  /** If true, the user has made some change to the loaded question. */
  isDirty: boolean,
  /** Determines if the Save Question comment window is open or not. */
  isSaveConfirmOpen: boolean,
  /** The currently opened QuestionLogId. If undefined, the Question Diff Details modal will not be visible. */
  openedQuestionLogId: number | undefined,
  /** The state of the modal used when activating or deactivating a question. */
  questionActiveModal: IQuestionActiveChangeModal,

  /** Master data options the user may choose from for various input fields. */
  masterData: IQuestionDetailsMasterData,

  /** An operation tracking the loading of the question data. */
  loadOp: IOperation<void> | undefined,
  /** An operation tracking the saving of the question data. */
  saveOp: IOperation<void> | undefined,
}

const initialState: IQuestionDetailsState = {
  question: undefined,
  originalQuestion: undefined,
  isDirty: false,
  isSaveConfirmOpen: false,
  openedQuestionLogId: undefined,
  questionActiveModal: {
    isOpen: false,
    reason: "",
    revertOnTimestamp: undefined,
  },

  masterData: {
    ownerGroups: [],
    verificationMethods: [],
  },

  loadOp: undefined,
  saveOp: undefined,
};

export const questionDetailsSlice = createSlice({
  name: "questionDetails",
  initialState,
  reducers: {
    /** Begins the procedure to load a question by its id number. */
    loadPageData: (state, _: PayloadAction<number | undefined>) => {
      state.loadOp = {
        isWorking: true,
      };
    },

    /** Finishes the procedure of loading a question. */
    finishLoadPageData: (state, action: PayloadAction<IOperation<{
      question: IQuestion | undefined,
      masterData: IQuestionDetailsMasterData,
    }>>) => {
      if (action.payload.errorMessage
        || !action.payload.data) {
        state.loadOp = {
          isWorking: false,
          errorMessage: action.payload.errorMessage,
        };
        return;
      }

      state.loadOp = undefined;

      if (action.payload.data.question) {
        // Save the loaded question and make a copy to compare against
        // as the user makes changes.
        state.question = action.payload.data.question;
        state.originalQuestion = cloneDeep(action.payload.data.question);
      } else {
        // Start a new partial question for the user to edit and there is
        // no original to compare against.
        state.question = {};
        state.originalQuestion = undefined;
      }

      state.masterData = action.payload.data.masterData;
      state.isDirty = false;
    },

    /** Resets all values of the state back to the initial. */
    resetState: state => {
      Object.assign(state, cloneDeep(initialState));
    },

    /** Sets any properties of the question. Do not use this to set specific metadata unless overwriting the entire metadata array. */
    setQuestionProperties: (state, action: PayloadAction<Partial<IQuestion>>) => {
      if (state.question) {
        if (action.payload.auditTopic && action.payload.auditTopic?.scoringSystem !== AuditScoringSystems.HSE) {
          state.question.hseMgmtSysSubElement = undefined;
        }

        Object.assign(state.question, action.payload);

        updateIsDirty(state);
      }
    },

    replaceQuestionMetaDataOfType: (state, action: PayloadAction<{
      items: IQuestionMetaDataItem[],
      metaDataType: MetaDataTypes,
      isAdditionalAssociation: boolean,
    }>) => {
      if (!state.question) {
        return;
      }

      if (!state.question.metaData) {
        state.question.metaData = [];
      }

      state.question.metaData = state.question.metaData
        .filter(x => !(x.masterDataType === action.payload.metaDataType
          && x.isAdditionalAssociation === action.payload.isAdditionalAssociation))
        .concat(action.payload.items.slice());

      updateIsDirty(state);
    },

    /** Performs a list of actions--either adding to or removing from the question's meta data list each item in the list. */
    setQuestionMetaData: (state, action: PayloadAction<IQuestionMetaDataAction[]>) => {
      if (!state.question) {
        return;
      }

      if (!state.question.metaData) {
        state.question.metaData = [];
      }

      for (const metaAction of cloneDeep(action.payload)) {
        if (metaAction.action === "remove") {
          // It should be removed.
          state.question.metaData = state
            .question
            .metaData
            ?.filter(x => !(x.masterDataType === metaAction.metaData.masterDataType
              && x.masterDataId === metaAction.metaData.masterDataId
              && x.isAdditionalAssociation === metaAction.metaData.isAdditionalAssociation));
        } else if (metaAction.action === "add") {
          // Find all of the meta data items that match (both true and additional associations).
          const matches = state
            .question
            .metaData
            ?.filter(x => x.masterDataType === metaAction.metaData.masterDataType
              && x.masterDataId === metaAction.metaData.masterDataId);

          const isAlreadyInTrueMeta = matches.some(x => !x.isAdditionalAssociation);
          const isAlreadyInAddtlAssoc = matches.some(x => x.isAdditionalAssociation);

          if (metaAction.metaData.isAdditionalAssociation
            && !isAlreadyInAddtlAssoc
            && !isAlreadyInTrueMeta) {
            // User is adding Additional Association item.
            // Item is not in the additional association or the true meta. Add it.
            state.question.metaData?.push(metaAction.metaData);
          } else if (!metaAction.metaData.isAdditionalAssociation
            && !isAlreadyInTrueMeta) {
            // User is adding True meta item and it's not already in the True meta list.
            if (!isAlreadyInAddtlAssoc) {
              // Item is not in the additional association list. Add it.
              state.question.metaData?.push(metaAction.metaData);
            } else {
              // Item is in Addtl Assoc. Need to upgrade it to true meta instead.
              const addtlItemIx = state.question.metaData
                .findIndex(x => x.masterDataType === metaAction.metaData.masterDataType
                  && x.masterDataId === metaAction.metaData.masterDataId);

              if (addtlItemIx > -1) {
                state.question.metaData[addtlItemIx].isAdditionalAssociation = false;
              }
            }
          }
        }
      }

      updateIsDirty(state);
    },

    /** Sets the visibility of the Save Question comment modal. */
    toggleSaveConfirmation: (state, action: PayloadAction<boolean>) => {
      state.isSaveConfirmOpen = action.payload;
    },

    /** Starts an operation to toggle the question's "Active"/Deleted flag. The payload is an object
     * that contains the new "Active" property value as well as a comment entered by the user. */
    toggleQuestionActive: (state, _: PayloadAction<{
      isActive: boolean,
      comment: string,
      revertOnTimestamp?: number,
    }>) => {
      state.saveOp = {
        isWorking: true,
      };
    },

    /** Finishes the current operation to toggle the question's Active/Deleted flag. */
    finishToggleQuestionActive: state => {
      state.saveOp = undefined;

      // After toggling a question's active flag, the site should automatically forward the user to a
      // different url which will cause a new LOAD QUESTION action to be dispatched.

      // So, to prevent the prompt that prevents the user from leaving the page,
      // set isDirty = false.
      state.isDirty = false;
      state.questionActiveModal.isOpen = false;
    },

    /** Begins saving the currently loaded question details. A comment entered by the user should
     * be the payload for this action and will be sent to the server via the Saga. */
    saveQuestion: (state, _: PayloadAction<{
      saveAsNewCopy: boolean,
      comment: string,
      isCLM: boolean,
    }>) => {
      state.saveOp = {
        isWorking: true,
      };
    },

    /** Finishes the current save operation and replaces the question data with the payload. */
    finishSaveQuestion: state => {
      state.saveOp = undefined;

      // Since saving a question always creates a new version instead, there is nothing
      // to update here. Instead, the site should automatically forward the user to a
      // different url which will cause a new LOAD QUESTION action to be dispatched.

      // So, to prevent the prompt that prevents the user from leaving the page,
      // set isDirty = false.
      state.isDirty = false;
    },

    /** Sets the currently opened Question Log Id. If undefined, no modal is visible. */
    setOpenedQuestionLogId: (state, action: PayloadAction<number | undefined>) => {
      state.openedQuestionLogId = action.payload;
    },

    /** Sets any properties of the question active modal. */
    setQuestionActiveModal: (state, action: PayloadAction<Partial<IQuestionActiveChangeModal>>) => {
      Object.assign(state.questionActiveModal, action.payload);
    },

    /** Begins saving the question's additional associations. */
    saveQuestionAdditionalAssociations: state => {
      state.saveOp = { isWorking: true, };
    },
  },
});

export const {
  loadPageData,
  finishLoadPageData,
  resetState,
  setQuestionProperties,
  replaceQuestionMetaDataOfType,
  setQuestionMetaData,
  toggleSaveConfirmation,
  saveQuestion,
  finishSaveQuestion,
  toggleQuestionActive,
  finishToggleQuestionActive,
  setOpenedQuestionLogId,
  setQuestionActiveModal,
  saveQuestionAdditionalAssociations,
} = questionDetailsSlice.actions;

export interface IQuestionMetaDataAction {
  metaData: IQuestionMetaDataItem,
  action: "add" | "remove",
}

function updateIsDirty(state: WritableDraft<IQuestionDetailsState>) {
  if (!state.question) {
    return;
  }

  const {
    question,
    originalQuestion: original = {} as Partial<IQuestion>,
  } = state;

  const meta = question.metaData || [];
  const originalMeta = original.metaData || [];

  let clonedQuestion = cloneDeep(question);
  let clonedOriginal = cloneDeep(original);

  delete clonedQuestion.guidance;
  delete clonedOriginal.guidance;

  state.isDirty =
    // Compare the question to its original state without comparing the metadata arrays.
    !isEqual({
      ...clonedQuestion,
      metaData: [],
    }, {
      ...clonedOriginal,
      metaData: [],
    })

    // Check if there are any items in the meta list that are NOT in the original list.
    || meta.some(m =>
      !originalMeta.some(o => o.masterDataId === m.masterDataId
        && o.masterDataType === m.masterDataType))

    // Check if there are any items in the original list that are NOT in the meta list.
    || originalMeta.some(o =>
      !meta.some(m => m.masterDataId === o.masterDataId
        && m.masterDataType === o.masterDataType));

  state.isDirty = state.isDirty || (original.guidance !== question.guidance);
}

export function getDisabledSaveReason(question: Partial<IQuestion>,
  questionScoringSystem: string | undefined): string | undefined {
  let missingFields: string[] = [];

  if (!question.questionText?.trim()) {
    missingFields.push("Question Text");
  }

  if (!question.questionType) {
    missingFields.push("Question Type");
  }
  if (!question.category) {
    missingFields.push("Category");
  }

  if (!question.auditGroup) {
    missingFields.push("Audit Group");
  }

  if (!question.auditTopic) {
    missingFields.push("Topic");
  }

  if (!question.guidance?.trim()) {
    missingFields.push("Guidance Text");
  }

  if (!(question.metaData || []).some(x => x.masterDataType === "VerMethod")) {
    missingFields.push("Verification Method");
  }

  if (questionScoringSystem === AuditScoringSystems.HSE
    && !question.hseMgmtSysSubElement) {
    missingFields.push("HSE MS Sub-Element");
  }

  if (questionScoringSystem === AuditScoringSystems.QMS
    && !(question.metaData || []).some(x => x.masterDataType === "QMSElement")) {
    missingFields.push("Quality Management System Sub-Element");;
  }

  if (questionScoringSystem === AuditScoringSystems.CLM
    && !(question.metaData || []).some(x => x.masterDataType === "CLMWhomToCheck")) {
    missingFields.push("With Whom to Check");;
  }

  if (questionScoringSystem === AuditScoringSystems.CLM
    && !question.clmFunction) {
    missingFields.push("CLM Function");;
  }
  if (missingFields.length === 0
    && !questionScoringSystem) {
    return "Required fields are missing.";
  }

  return missingFields.length === 0
    ? undefined
    : `The following fields are required: ${missingFields.join(", ")}.`;
}
