import React, { useEffect, useState } from "react";
import Hint from "shared/components/common/hint/Hint";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { useAppDispatch } from "store/store";
import { IPlanChildDimension, IPlanParentDimension, IPlanRequirementDimension } from "types/auditPlanningTypes";

const maxCommentLength = 500;

interface IRemovePlanProps {
  /**
   * Specifies if the modal is open or not. If not open, it does not render.
   */
  isOpen: boolean,
  /**
   * The action to perform when the user clicks the cancel button in the modal.
   */
  onCancel(): void,
  /**
   * The action to perform when the user clicks the save button in the modal.
   */
  onRemove(comment: string): void,
  /**
   * The details of the parent dimension for the plan.
   */
  parentDimension?: IPlanParentDimension,
  /**
   * The details of the parent dimension for the plan.
   */
  childDimension?: IPlanChildDimension,
  /**
   * The details of the child dimension for the plan.
   */
  requirementDimension?: IPlanRequirementDimension,
  /**
   * The details of the requirement dimension for the plan.
   */
}

const RemovePlanModal: React.FC<IRemovePlanProps> = ({
  isOpen,
  onCancel,
  onRemove,
  parentDimension,
  childDimension,
  requirementDimension,
}) => {
  const [comment, setComment] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    setComment("");
  }, [isOpen]);

  const onRemoveClicked = () => {
    if (!comment.trim()) {
      dispatch(showErrorToast("Comment is required."));
      return;
    }

    onRemove(comment);
  };

  let planDisplayText = [
    parentDimension?.text,
    childDimension?.text,
  ].filter(x => !!x)
    .join(" + ");

  return (
    <Modal
      isOpen={isOpen}
      header={"Remove Plan"}
      buttons={[{
        key: "cancel",
        text: "Cancel",
        className: "secondary",
        onClick: onCancel,
      }, {
        key: "removePlan",
        text: "Submit Removal",
        className: "primary",
        onClick: onRemoveClicked,
      }]}
    >
      <FlexCol>
        <span>
          Would you like to approve the REMOVAL of
          the <b>{requirementDimension?.text}</b> Audit Plan from <b>{planDisplayText}</b>?
        </span>

        <LabeledControl
          isRequired={true}
          label={`Why is ${requirementDimension?.text} not required this year?`}
          flexChildrenAs="column"
          hint={getTextboxMaxLengthHint(maxCommentLength)}
        >
          <textarea
            value={comment}
            onChange={e => setComment(e.currentTarget.value)}
            maxLength={maxCommentLength}
          />
          <Hint>
            {maxCommentLength - comment.length}/{maxCommentLength} characters remaining
          </Hint>
        </LabeledControl>
      </FlexCol>
    </Modal>
  );
};

export default RemovePlanModal;