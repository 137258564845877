import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PickerList from "shared/components/controls/picker/list/PickerList";
import AuditTopicPicker from "shared/components/controls/pickers/AuditTopicPicker";
import BusinessFunctionPicker from "shared/components/controls/pickers/BusinessFunctionPicker";
import BusinessViewPicker from "shared/components/controls/pickers/BusinessViewPicker";
import FlexCol from "shared/components/layout/flex/FlexCol";
import { IPickerItem } from "shared/types/pickerTypes";
import { getChildrenRecursively, getTopicChildrenRecursively, toPickerItem } from "shared/utilities/hierarchyUtilities";
import { canUserAccessItem } from "shared/utilities/permissionUtilities";
import { createAttribute, setCreateAttributeModal } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";
import { AuditTopicSearchTypes, IAuditTopic } from "types/auditMasterDataTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IBusinessFunction, IBusinessView, MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";
import "./CreateAttributeModal.scoped.scss";

const CreateAttributeModal: React.FC = () => {
  const createAttributeModal = useAppSelector(store => store.facilityAttributes.createAttributeModal);
  const defaultMetaData = useAppSelector(store => store.facilityAttributes.defaultMetaData);
  const availableTemplates = useAppSelector(store => store.facilityAttributes.contextPlanProfileAttributeModal.availableTemplates);
  const availableTopics = useAppSelector(store => store.facilityAttributes.contextPlanProfileAttributeModal.availableTopics);
  const mandatoryItems = useAppSelector(store => store.facilityAttributes.contextPlanProfileAttributeModal.mandatoryItems);

  const [selectedExtraBusinessViews, setSelectedBusinessViews] = useState<IBusinessView[]>([]);
  const [selectedExtraBusinessFunctions, setSelectedBusinessFunctions] = useState<IBusinessFunction[]>([]);
  const [selectedExtraSubTopics, setSelectedSubTopics] = useState<IAuditTopic[]>([]);
  const [selectedDetailedTemplateItems, setSelectedAuditTemplates] = useState<(IDetailedTemplate | IDetailedTemplateChildren)[]>(createAttributeModal.defaultSelectedDetailedTemplateItems);

  const derivedMetaRestrictions = useAppSelector(store => store.auth.activeUserProfile.derivedMetaRestrictions);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const dispatch = useDispatch();

  const isCreateFromAuditTemplate = createAttributeModal.requirement.type === MetaDataTypes.AuditTemplate;
  const defaultSelectedDetailedTemplateItems = createAttributeModal.defaultSelectedDetailedTemplateItems;
  const defaultBusinessViews = defaultMetaData.savedExtraBusinessViewItems;
  const defaultBusinessFunctions = defaultMetaData.savedExtraBusinessFunctionItems;

  const allTemplates: (string | number)[] = [];
  const allTopics: (string | number)[] = [];

  const businessViewFilterByCode = createAttributeModal.row.type === MetaDataTypes.BusinessView
    ? [createAttributeModal.row.text, "MSP"]
    : ["MSP"];

  const businessFunctionFilterByCode = createAttributeModal.row.type === MetaDataTypes.BusinessFunction
    ? [createAttributeModal.row.text]
    : [];

  const addRecursively = (item: IDetailedTemplate | IDetailedTemplateChildren, allItems: (string | number)[]) => {
    allItems.push(item.key);

    item.children.forEach(c => {
      addRecursively(c, allItems);
    });
  };

  // Prepare available templates
  availableTemplates.forEach(x => {
    addRecursively(x, allTemplates);
  });

  // Prepare available audit topics
  availableTopics.forEach(x => {
    addRecursively(x, allTopics);
  });

  useEffect(() => {
    const selBusFuncs = defaultBusinessFunctions
      .filter(x => canUserAccessItem({ id: x.id, type: MetaDataTypes.BusinessFunction }, derivedMetaRestrictions));

    setSelectedBusinessFunctions(selBusFuncs);

    const selBusView = defaultBusinessViews
      .filter(x => canUserAccessItem({ id: x.id, type: MetaDataTypes.BusinessView }, derivedMetaRestrictions));

    setSelectedBusinessViews(selBusView);

    setSelectedAuditTemplates(defaultSelectedDetailedTemplateItems);
  }, [defaultSelectedDetailedTemplateItems,
    defaultBusinessFunctions,
    defaultBusinessViews,
    derivedMetaRestrictions,
    setSelectedBusinessFunctions,
    setSelectedSubTopics,
    setSelectedBusinessViews,
    setSelectedAuditTemplates,
    isCreateFromAuditTemplate]);

  return (
    createAttributeModal.isOpen
      ? (
        <ConfirmModal
          header="Add Requirement"
          noButtonOptions={{ text: "Cancel" }}
          yesButtonOptions={{ text: "Apply" }}
          message={
            <div>
              <FlexCol>
                <span>
                  Would you like to ADD the <b>{createAttributeModal.requirement.text}</b> Audit Requirement to <b>{createAttributeModal.row.text}</b>?
                </span>

                {
                  isCreateFromAuditTemplate && <>
                    <hr className="divider" />
                    <FlexCol className="template-picker">
                      <span>
                        <b>Templates</b>
                      </span>
                      <PickerList<IDetailedTemplate | IDetailedTemplateChildren>
                        items={availableTemplates.map(x => toPickerItem(allTemplates, x, true, availableTemplates))}
                        renderItem={(item) => item.name}
                        allowMultiSelect={true}
                        noItemsMessage={"No suggestions available."}
                        selectedItems={selectedDetailedTemplateItems.map(x => toPickerItem(allTemplates, x))}
                        displayMode={"tree"}
                        notShowPlusMinusSign={true}
                        onItemSelected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
                          if (item.item) {
                            const items = getChildrenRecursively(item.item, [], false, 'Both');
                            setSelectedAuditTemplates(selectedDetailedTemplateItems.concat(items));
                          }
                        }}
                        onItemDeselected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
                          if (item.item) {
                            const items = getChildrenRecursively(item.item, [], false, 'Both');
                            const idsToRemove = items.map(i => i.key);
                            const onlySelectedItems = selectedDetailedTemplateItems.filter(({ key }) => !idsToRemove.includes(key));
                            setSelectedAuditTemplates(onlySelectedItems);
                          }
                        }}
                        isDisabledMapper={(item: (IDetailedTemplate | IDetailedTemplateChildren), ancestorPath: (IDetailedTemplate | IDetailedTemplateChildren)[]) => {
                          const child = mandatoryItems.filter(x => x.key === item.key);
                          if (child.length > 0) {
                            return true;
                          }
                          return false;
                        }}
                      />
                    </FlexCol>
                    <hr className="divider" />
                    <FlexCol className="template-picker">
                      <span>
                        <b>Topics/SubTopics</b>
                      </span>
                      <PickerList<IDetailedTemplate | IDetailedTemplateChildren>
                        items={availableTopics.map(x => toPickerItem(allTopics, x, true, availableTopics))}
                        renderItem={(item) => item.name}
                        allowMultiSelect={true}
                        noItemsMessage={"No suggestions available."}
                        selectedItems={selectedDetailedTemplateItems.map(x => toPickerItem(allTopics, x))}
                        displayMode={"tree"}
                        notShowPlusMinusSign={true}
                        onItemSelected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
                          if (item.item) {
                            const items = getTopicChildrenRecursively(item.item, []);
                            setSelectedAuditTemplates(selectedDetailedTemplateItems.concat(items));
                          }
                        }}
                        onItemDeselected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
                          if (item.item) {
                            const items = getTopicChildrenRecursively(item.item, []);
                            const idsToRemove = items.map(i => i.key);
                            setSelectedAuditTemplates(selectedDetailedTemplateItems.filter(({ key }) => !idsToRemove.includes(key)));
                          }
                        }}

                        isDisabledMapper={(item: (IDetailedTemplate | IDetailedTemplateChildren), ancestorPath: (IDetailedTemplate | IDetailedTemplateChildren)[]) => {
                          const child = mandatoryItems.filter(x => x.key === item.key);
                          if (child.length > 0) {
                            return true;
                          }
                          return false;
                        }}
                      />
                    </FlexCol>
                  </>
                }

                <div className="extra-metadata-title">
                  Extra Metadata
                </div>

                <FlexCol className="template-picker">
                  {!isCreateFromAuditTemplate && <LabeledControl
                    label="Sub Topics"
                  >
                    <AuditTopicPicker
                      selectedItems={selectedExtraSubTopics}
                      isRequired
                      onApply={(items) => {
                        let openSubTopics = items.filter(x =>
                          // Check if the user can access this subtopic.
                          canUserAccessItem({ id: x.id, type: MetaDataTypes.AuditTopic }, derivedMetaRestrictions)
                          // Or if they can access its parent.
                          || (x.parentId && canUserAccessItem({ id: x.parentId, type: MetaDataTypes.AuditTopic }, derivedMetaRestrictions))
                          // Or if they can access the Owner Group.
                          || (x.ownerGroupId && canUserAccessItem({ id: x.ownerGroupId, type: MetaDataTypes.OwnerGroup }, derivedMetaRestrictions)));
                        setSelectedSubTopics(openSubTopics);
                      }}
                      renderMode="picker"
                      allowMultiselect
                      auditTopicSearch={{
                        auditTopicSearchIds: [createAttributeModal.requirement.id],
                        auditTopicSearchType: AuditTopicSearchTypes.OnlySubTopicsFromIds
                      }}
                    />
                  </LabeledControl>
                  }
                  <LabeledControl
                    label="Business View (Divisions)"
                  >
                    <BusinessViewPicker
                      selectedItems={selectedExtraBusinessViews}
                      allowMultiselect={true}
                      showSuggestions={true}
                      userProfileMetaRestrictions={editRestriction === EditRestriction.EditAllRestrict
                        ? derivedMetaRestrictions
                        : undefined
                      }
                      onApply={setSelectedBusinessViews}
                      isItemDisabledMapper={createAttributeModal.row.type === MetaDataTypes.BusinessView
                        ? (item, ancestorPath) => !ancestorPath.some(x => businessViewFilterByCode.includes(x.code))
                          && item.code !== "MSP"
                        : undefined}
                      codesToFilter={createAttributeModal.row.type === MetaDataTypes.BusinessView
                        ? businessViewFilterByCode
                        : []
                      }
                    />
                  </LabeledControl>
                  <LabeledControl
                    label="Business Function (Functions)"
                  >
                    <BusinessFunctionPicker
                      selectedItems={selectedExtraBusinessFunctions}
                      allowMultiselect={true}
                      showSuggestions={true}
                      userProfileMetaRestrictions={editRestriction === EditRestriction.EditAllRestrict
                        ? derivedMetaRestrictions
                        : undefined
                      }
                      onApply={setSelectedBusinessFunctions}
                      isItemDisabledMapper={createAttributeModal.row.type === MetaDataTypes.BusinessFunction
                        ? (item, ancestorPath) => !ancestorPath.some(x => businessFunctionFilterByCode.includes(x.code))
                          && item.code !== "MSP"
                        : undefined}
                      codesToFilter={businessFunctionFilterByCode}
                    />
                  </LabeledControl>
                </FlexCol>
              </FlexCol>
            </div>
          }
          onNoClicked={() => dispatch(setCreateAttributeModal({
            isOpen: false,
          }))}
          onYesClicked={() => dispatch(createAttribute({
            row: createAttributeModal.row,
            requirement: createAttributeModal.requirement,
            profileId: createAttributeModal.profileId,
            profileStandardTemplateSelectedItems: cloneDeep(selectedDetailedTemplateItems),
            selectedExtraBusinessViews,
            selectedExtraBusinessFunctions,
            selectedExtraSubTopics,
            subGeoUnitId: createAttributeModal.subGeoUnitId,
          }))}
        />
      ) : null
  );
};

export default CreateAttributeModal;
