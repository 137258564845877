import { formatAuditTopic } from "api/masterdata/AuditTopicApi";
import { formatAuditGroup, formatHSESubElement, formatQuestionCategory, formatQuestionType } from "api/masterdata/formatters/masterDataFormatters";
import { parseStringToDate } from "shared/utilities/dateFormatters";
import { IDiffDetailItem, IQuestion, IQuestionMetaDataItem, IQuestionVersionHistoryItem } from "types/questionTypes";
import { ISaveQuestionRequest } from "../questionApiTypes";

export function formatQuestionDetails(item: any): IQuestion {
  if (item === undefined) {
    throw Error("Invalid data to format into question details.");
  }

  return {
    id: Number(item.id),
    allowNA: Boolean(item.allowNA),
    auditGroup: formatAuditGroup(item.auditGroup),
    auditTopic: formatAuditTopic(item.auditTopic),
    category: formatQuestionCategory(item.category),
    guidance: item.guidance?.toString() || "",
    guidanceHTML: item.guidanceHTML?.toString() || "",
    isDeleted: Boolean(item.isDeleted),
    isGlobal: Boolean(item.isGlobal),
    isLatestVersion: Boolean(item.isLatestVersion),
    legacyQId: Number(item.legacyQId),
    licenseToOperate: Boolean(item.licenseToOperate),
    metaData: Array.isArray(item.metaData)
      ? item.metaData.map(formatQuestionMetaDataItem)
      : [],
    number: Number(item.number),
    questionText: item.questionText?.toString() || "",
    questionType: formatQuestionType(item.questionType),
    recommendedRWP: item.recommendedRWP?.toString() || "",
    version: Number(item.version),
    hseMgmtSysSubElement: item.hseMgmtSysSubElement
      ? formatHSESubElement(item.hseMgmtSysSubElement)
      : undefined,
    queuedActionType: item.queuedActionType?.toString(),
    queuedActionTargetTimestamp: item.queuedActionTargetTimestamp
      ? parseStringToDate(item.queuedActionTargetTimestamp).getTime()
      : undefined,
    clmFunction: item.clmFunction
      ? formatAuditGroup(item.clmFunction)
      : undefined,
    quid: item.quid?.toString() || "",
    clause: item.clause?.toString() || "",
    sortOrder: Number(item.sortOrder) === 0 ? undefined : Number(item.sortOrder),
  };
}

export function formatQuestionMetaDataItem(item: any): IQuestionMetaDataItem {
  if (typeof (item.masterDataItem) !== "object") {
    throw Error(`Invalid masterDataItem encountered while parsing question details.`);
  }

  return {
    masterDataId: Number(item.masterDataId),
    masterDataType: item.masterDataType?.toString() || "",
    // The masterDataItem can be any object from the server. There's no way to format it.
    // Better hope the server knows what it's doing.
    masterDataItem: item.masterDataItem,
    isAdditionalAssociation: Boolean(item.isAdditionalAssociation),
  };
}

export function formatSaveQuestionRequest(item: Partial<IQuestion>, isCLM: boolean = false, comment?: string): ISaveQuestionRequest {
  return {
    id: item.id,
    categoryId: item.category?.id,
    questionText: item.questionText,
    guidance: item.guidance,
    guidanceHTML: item.guidanceHTML,
    recommendedRWP: item.recommendedRWP,
    allowNA: item.allowNA,
    auditGroupId: item.auditGroup?.id,
    auditTopicLevel1Id: item.auditTopic?.id,
    hseMgmtSysSubElementId: item.hseMgmtSysSubElement?.id,
    licenseToOperate: item.licenseToOperate,
    questionTypeId: item.questionType?.id,
    metaData: item.metaData,
    comment,
    isGlobal: item.isGlobal,
    clmFunctionId: item.clmFunction?.id,
    quid: item.quid,
    clause: item.clause,
    sortOrder: item.sortOrder === 0 ? undefined : item.sortOrder,
    isCLM,
  };
}

export function formatQuestionVersionHistoryItem(obj: any): IQuestionVersionHistoryItem {
  let diffDetails: IDiffDetailItem[] | undefined = undefined;

  try {
    if (obj.diffDetails) {
      const arr = JSON.parse(obj.diffDetails);
      diffDetails = Array.isArray(arr)
        ? arr.map(formatDiffDetailItem)
        : undefined;
    }
  } catch {
    diffDetails = undefined;
  }

  return {
    action: obj.action?.toString() || "",
    id: Number(obj.id),
    modifiedBy: obj.modifiedBy?.toString() || "",
    modifiedByName: obj.modifiedByName?.toString() || "",
    modifiedOn: parseStringToDate(obj.modifiedOn).getTime(),
    number: Number(obj.number),
    questionId: Number(obj.questionId),
    version: Number(obj.version),
    diffDetails,
    fieldsModified: obj.fieldsModified?.toString(),
  };
}

export function formatDiffDetailItem(obj: any): IDiffDetailItem {
  return {
    action: (obj.action || obj.Action)?.toString(),
    prop: (obj.prop || obj.Prop)?.toString(),
    rawProp: (obj.rawProp || obj.RawProp)?.toString(),
    value: obj.value || obj.Value,
    label: (obj.label || obj.Label)?.toString(),
  }
}
