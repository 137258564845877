import { all } from "@redux-saga/core/effects";
import authSagas from "shared/store/auth/AuthSagas";
import calendarDatesSagas from "shared/store/calendar-dates/CalendarDatesSagas";
import toastSagas from "shared/store/toast/ToastSagas";
import adminFacilityAttributeSagas from "./admin/planning-administration/facility-attributes/AdminFacilityAttributesSagas";
import { bulkUploadQuestionsSaga } from "./admin/questions/bulk-upload-questions/BulkUploadQuestionsSagas";
import { auditEvidencesSaga } from "./audit-evidences/AuditEvidencesSagas";
import auditExecutionSagas from "./audit-execution/AuditExecutionSagas";
import auditNonConformancesSagas from "./audit-non-conformances/AuditNonConformancesSagas";
import auditPageAuthSagas from "./audit-page-auth/AuditPageAuthSagas";
import auditPlanApprovalsSagas from "./audit-plan-approvals/AuditPlanApprovalsSagas";
import auditPlanCalendarSagas from "./audit-plan-calendar/AuditPlanCalendarSagas";
import auditPlanResultsSagas from "./audit-plan-results/AuditPlanResultsSagas";
import auditPlanningSagas from "./audit-planning-shared/AuditPlanningSagas";
import auditReportSaga from "./audit-report/AuditReportSaga";
import attachmentModalSagas from "./audit-report/attachment-modal/AttachmentModalSagas";
import auditSummarySagas from "./audit-summary/AuditSummarySagas";
import { auditTemplatesSagas } from "./audit-templates/AuditTemplatesSagas";
import { auditTopicRearrangeSagas } from "./audit-topic-rearrange/AuditTopicRearrangeSagas";
import auditSagas from "./audit/AuditSagas";
import facilityAttributesSagas from "./facility-attributes/FacilityAttributesSagas";
import helpSagas from "./help/HelpSagas";
import manageActionItemSagas from "./manage-action-item/ManageActionItemSagas";
import manageAuditSagas from "./manage-audit/ManageAuditSagas";
import myActionsSagas from "./my-actions/MyActionsSagas";
import { myAuditsSagas } from "./my-audits/MyAuditsSagas";
import { questionDetailsSagas } from "./question-details/QuestionDetailsSagas";
import questionEvidenceSagas from "./question-evidence/QuestionEvidenceSagas";
import customSearchSagas from "./search/CustomSearchSagas";
import searchActionsSagas from "./search/actions/SearchActionsSagas";
import searchAuditsSagas from "./search/audits/SearchAuditsSagas";
import userManagementSagas from "./user-management/UserManagementSagas";
import auditorSagas from "./auditors/AuditorSagas";
import auditTopicSagas from "./audit-topics/AuditTopicSagas";

export default function* rootSaga() {
  yield all([
    auditSagas(),
    manageAuditSagas(),
    auditSummarySagas(),
    auditExecutionSagas(),
    attachmentModalSagas(),
    questionEvidenceSagas(),
    myAuditsSagas(),
    auditEvidencesSaga(),
    manageActionItemSagas(),
    auditNonConformancesSagas(),
    toastSagas(),
    auditPlanApprovalsSagas(),
    auditPlanResultsSagas(),
    auditReportSaga(),
    authSagas(),
    calendarDatesSagas(),
    auditPageAuthSagas(),
    myActionsSagas(),
    helpSagas(),
    searchAuditsSagas(),
    searchActionsSagas(),
    facilityAttributesSagas(),
    auditPlanCalendarSagas(),
    customSearchSagas(),
    adminFacilityAttributeSagas(),
    questionDetailsSagas(),
    userManagementSagas(),
    auditTemplatesSagas(),
    bulkUploadQuestionsSaga(),
    auditTopicRearrangeSagas(),
    auditPlanningSagas(),
    auditorSagas(),
    auditTopicSagas(),
  ]);
}