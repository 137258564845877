import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import privacyPolicy from 'shared/media/dls/Privacy-Notice.svg';
import "./PrivacyPolicyLink.scoped.scss";

const PrivacyPolicyLink: React.FC = () => {
  return (
    <TooltipElement
      tooltipString="Privacy Policy"
    >
      <a
        className="user-help-icon-link"
        href="https://slb001.sharepoint.com/sites/IntegrityPortal/Shared%20Documents/Forms/AllItems.aspx?id=%2fsites%2fIntegrityPortal%2fShared%20Documents%2fIGD1509%2D20%2Demployee%2Dpersonal%2Dinformation%2Dnotice%2Epdf&parent=%2fsites%2fIntegrityPortal%2fShared%20Documents"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Privacy Policy"
          src={privacyPolicy}
          className="user-help-icon icon-medium"
        />
      </a>
    </TooltipElement>
  );
};

export default PrivacyPolicyLink;