import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import { deleteAudit, dismissDeleteConfirmation } from "store/manage-audit/ManageAuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const ConfirmDeleteAuditModal = () => {
  const {
    askDeleteAuditId,
    deleteAuditOperation,
  } = useAppSelector(store => store.manageAudit);
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();

  if (deleteAuditOperation?.isWorking) {
    return (
      <ModalSpinner />
    );
  } else if (askDeleteAuditId) {
    return (
      <ConfirmModal
        header="Delete Audit?"
        message="Are you sure you want to delete this audit?"
        onYesClicked={() => {
          dispatch(deleteAudit(askDeleteAuditId));
          appInsights.trackEvent({
            name: "Edit Audit | Delete Audit | Yes",
            properties: {
              auditId: askDeleteAuditId,
            },
          });
        }}
        onNoClicked={() => {
          dispatch(dismissDeleteConfirmation());
          appInsights.trackEvent({
            name: "Edit Audit | Delete Audit | No",
            properties: {
              auditId: askDeleteAuditId,
            },
          });
        }}
      />
    );
  }

  return null;
};

export default ConfirmDeleteAuditModal;