import AuditsApi from "api/auditing/AuditsApi";
import { takeLatest, put, call } from "redux-saga/effects";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { IActionItem } from "types/actionItemTypes";
import { loadActionItems, finishLoadActionItems } from "../AuditSlice";

export function* loadActionItemsAsync() {
  yield takeLatest(loadActionItems, function* (action) {
    if (!loadActionItems.match(action)) {
      return;
    }

    try {
      const actionItems: IActionItem[] = yield call(AuditsApi.getAllActionItemsForAudit, action.payload);

      yield put(finishLoadActionItems({
        isWorking: false,
        data: actionItems,
      }));
    } catch (err) {
      yield put(finishLoadActionItems({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));

      yield put(showErrorToast("Failed to load action items: " + getResponseErrorMessage(err)));
    }
  });
}

