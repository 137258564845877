import SummaryBlock, { ISummaryBlockItem } from "components/audits/common/SummaryBlock";
import { groupBy } from "lodash";
import React, { useMemo } from "react";
import Button from "shared/components/controls/buttons/button/Button";
import Card from "shared/components/layout/card/Card";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { setOpenAssignFindingToQuestions } from "store/audit-finding/AuditFindingSlice";
import { FindingLinkType } from "store/audit/reducers/findingReducers";
import { useAppDispatch, useAppSelector } from "store/store";
import "./FindingsOverviewCard.scoped.scss";

interface IFindingsOverviewCardProps {
  isEditable: boolean,
}

const FindingsOverviewCard: React.FC<IFindingsOverviewCardProps> = ({
  isEditable,
}) => {
  const findings = useAppSelector(store => store.audit.findings);
  const findingTypes = useAppSelector(store => store.audit.findingTypes);
  const questions = useAppSelector(store => store.audit.questions);
  const audit = useAppSelector(store => store.audit.audit);

  const dispatch = useAppDispatch();

  const hasAnyQuestionsWithoutFindings = questions.some(question =>
    question.responses
      .some(y => y.auditQuestionId === question.auditQuestionId
        && y.auditStatus === audit?.status
        && y.answer !== undefined)
    && !findings
      .some(f => f.links.some(l => l.linkType === FindingLinkType.AuditQuestion
        && l.linkId === question.auditQuestionId)));

  // Group the findings by their finding type and map each group into
  // a summary block item showing the finding type name and the number
  // of findings with that type.
  const summaryItems: ISummaryBlockItem[] = useMemo(() => [{
    key: "Total",
    label: "Total",
    node: findings.length,
  }].concat(
    Object.entries(
      groupBy(
        findings
          .map(x => findingTypes.find(ft => ft.id === x.findingTypeId))
          .filter(isNotUndefined),
        x => x.id)
    ).filter(group => group[1].length)
      .sort((a, b) => a[1][0].sortOrder < b[1][0].sortOrder ? -1 : 1)
      .map(group => ({
        key: group[0],
        label: group[1][0].name,
        node: group[1].length,
      }))),
    [findings, findingTypes]);

  return (
    <Card>
      <FlexRow
        className="main-row"
      >
        <span className="heading-label">
          Findings
        </span>

        <FlexRow
        >
          <SummaryBlock
            items={summaryItems}
            className="finding-types-summary"
          />

          {isEditable && (
            <Button
              buttonType="primary"
              imgPlacement="right"
              className="assign-findings-button"
              onClick={() => dispatch(setOpenAssignFindingToQuestions(true))}
              isDisabled={!hasAnyQuestionsWithoutFindings}
              tooltip={!hasAnyQuestionsWithoutFindings
                ? "All questions are already assigned findings."
                : undefined
              }
            >
              Assign Findings
            </Button>
          )}
        </FlexRow>
      </FlexRow>
    </Card>
  );
};

export default FindingsOverviewCard;