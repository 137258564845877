import CreateAuditModal from "components/audits/planning/create-audit-modal/CreateAuditModal";
import React, { useMemo } from "react";
import { useAppSelector } from "store/store";
import { IAuditCreationItem } from "types/auditingTypes";

interface ISchedulerCreateAuditModalProps {
  afterAuditCreation: (newAuditId: number) => void,
}

const SchedulerCreateAuditModal: React.FC<ISchedulerCreateAuditModalProps> = ({
  afterAuditCreation,
}) => {
  const basinYAxisData = useAppSelector(store => store.auditPlanCalendar.basinYAxisData);
  const schedulerItems = useAppSelector(store => store.auditPlanCalendar.schedulerItems);
  const selectedPlanIds = useAppSelector(store => store.auditPlanCalendar.selectedPlanIds);

  const selectedPlans = useMemo(() => {
    return schedulerItems.filter(x => selectedPlanIds.includes(x.planId));
  }, [schedulerItems, selectedPlanIds]);

  let plans: IAuditCreationItem[] = selectedPlans.map((p): IAuditCreationItem => ({
    plan: {
      id: p.planId,
      planYear: p.planYear,
      weekOfYear: p.planWeekOfYear,
      leadAuditorEmail: p.leadAuditorEmail,
      parentDimensionType: p.parentDimensionType,
      parentDimensionSubType: p.parentDimensionSubType,
      childDimensionSubType: p.childDimensionSubType,
      childDimensionType: p.childDimensionType,
    },

    requirementName: p.requirementDimensionText,
    requirementType: p.requirementDimensionType,
    requirementId: p.requirementDimensionId,

    parentName: p.parentDimensionText,

    childName: p.childDimensionText,
    geoName: p.subGeoUnitId
      ? basinYAxisData.subGeoUnits.find(x => x.id === p.subGeoUnitId)?.name
      : undefined,
  }));

  return (
    <CreateAuditModal
      plans={plans}
      afterAuditCreation={afterAuditCreation}
    />
  );
};

export default SchedulerCreateAuditModal;
