import { groupBy } from "lodash";
import { BusinessFunctionType, IBusinessFunction } from "types/masterDataTypes";

/**
 * Groups and sorts BusinessFunctions for display.
 * @param items BFs to group and sort.
 * @returns An array of groupings, each with a title, items, and pre-mapped labels.
 */
export function getGroupedBusinessFunctions(items?: IBusinessFunction[]): IGroupedBusinessFunctions[] {
  if (!items) {
    return [];
  }

  return Object.entries(groupBy(items, x => x.type))
    .map((group): IGroupedBusinessFunctions => ({
      type: group[1][0].type,
      title: bfTypeLabelMappings[group[1][0].type].singular,
      pluralTitle: bfTypeLabelMappings[group[1][0].type].plural,
      items: group[1],
      labels: group[1].map(x => `${x.code} - ${x.name}`).sort((a, b) => a < b ? -1 : 1),
    })).sort((a, b) => bfSortOrder[a.type] < bfSortOrder[b.type] ? -1 : 1);
}

const bfSortOrder: Record<BusinessFunctionType, number> = {
  "FunctionCategory": 1,
  "Function": 2,
  "SubFunction": 3,
};

const bfTypeLabelMappings: Record<BusinessFunctionType, { singular: string, plural: string; }> = {
  "FunctionCategory": { singular: "Function Category", plural: "Function Categories" },
  "Function": { singular: "Function", plural: "Functions" },
  "SubFunction": { singular: "Sub-Function", plural: "Sub-Functions" },
};

export interface IGroupedBusinessFunctions {
  /** The BusinessFunction.type property that was used to group by. */
  type: BusinessFunctionType,
  /** The title of this group. Corresponds to "Function Category", "Function" or "Sub-Function". */
  title: string,
  /** The title of this group. Corresponds to "Function Categories", "Functions" or "Sub-Functions". */
  pluralTitle: string,
  /** The items constituting this group. */
  items: IBusinessFunction[],
  /** Each item, pre-sorted, and mapped into the format `CODE - NAME`. */
  labels: string[],
}