import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import MobileSitePicker from "shared/components/controls/pickers/MobileSitePicker";
import { setSearchPropValue } from "store/search/actions/SearchActionsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const MobileSites: React.FC = () => {
  const mobileSites = useAppSelector(store => store.searchActions.mobileSites);
  const dispatch = useAppDispatch();

  return (
    <LabeledControl
      label="Mobile Site"
    >
      <MobileSitePicker
        onSelectionChange={items => dispatch(setSearchPropValue({
          mobileSites: items,
        }))}
        selectedItems={mobileSites}
        selectionMode="Multiple"
        showSuggestions={false}
      />
    </LabeledControl>
  );
};

export default MobileSites;