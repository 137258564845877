import React from "react";
import Switch from "react-switch";
import "./ToggleSwitch.scoped.scss";

interface IToggleSwitchProps {
  /** Determines if the switch is toggled on or not. */
  isChecked: boolean,
  /** Called when the user changes the toggled state. */
  onChange(isChecked: boolean): void,
  /** Optional. The label to display alongside the toggle switch. The user can click the label to toggle the switch. */
  label?: string | null,
  /** Optional. If true, the control is non-interactive. Default = false. */
  isDisabled?: boolean,
}

const ToggleSwitch: React.FC<IToggleSwitchProps> = ({
  isChecked,
  onChange,
  label = null,
  isDisabled = false,
}: IToggleSwitchProps) => {
  return (
    <label
      className="toggle-switch-label"
    >
      <Switch
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
        uncheckedIcon={false}
        checkedIcon={false}
        height={16}
        width={32}
        handleDiameter={14}
        onColor={"#0014dc"}
        offColor={"#505575"}
      />

      {label}
    </label>
  );
};

export default ToggleSwitch;