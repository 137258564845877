import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import SearchBox from "shared/components/controls/search-box/SearchBox";
import { finishLoadingAudit, loadAudit, setSearchPropValue } from "store/search/audits/SearchAuditsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "./AuditId.scoped.scss";

const AuditId: React.FC = () => {
  const targetPropId = "auditId";
  const id = useAppSelector(store => store.searchAudits[targetPropId]);
  const loadAuditOperation = useAppSelector(store => store.searchAudits.loadAuditOperation);

  const dispatch = useAppDispatch();

  return (
    <div
      className="id-search"
    >
      <LabeledControl
        label="Audit ID"
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            dispatch(loadAudit(Number(id)));
          }}
        >
          <SearchBox
            inputProps={{
              placeholder: "Search",
              onChange: e => {
                if (e.currentTarget.value.match(/^\d*$/)) {
                  dispatch(setSearchPropValue({ [targetPropId]: e.currentTarget.value }));
                }
                // Clear out the existing error message, if any.
                dispatch(finishLoadingAudit({
                  isWorking: false,
                }));
              },
              autoFocus: true,
              value: id,
            }}
            showButton={true}
            onButtonClick={() => {
              dispatch(loadAudit(Number(id)));
            }}
          />
        </form>
      </LabeledControl>

      {!!loadAuditOperation?.errorMessage && (
        <Banner
          type={BannerType.error}
        >
          {loadAuditOperation.errorMessage}
        </Banner>
      )}

      {loadAuditOperation?.isWorking && (
        <ModalSpinner />
      )}
    </div>
  )
}

export default AuditId;