import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback } from "react";
import { IAuditGroup } from "types/auditMasterDataTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import NonReduxPickerDropdown from "./non-redux-dropdown-picker/NonReduxDropdownPicker";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IAuditGroupPickerProps {
  /** Optional. Determines the picker's mode. Default = Picker. */
  renderMode?: PickerMode,
}

const AuditGroupPicker: React.FC<IAuditGroupPickerProps & ICommonPickerProps<IAuditGroup>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  onRenderPicker,
  isDisabled = false,
  renderMode = "picker",
  userProfileMetaRestrictions,
}) => {
  const renderItem = (item: IAuditGroup) => item.name;

  const loadItems = useCallback(async (_: string | undefined, abortSignal: AbortSignal) =>
    await MasterDataApi.getAuditGroups(abortSignal),
    []);

  const restrictedToItems = userProfileMetaRestrictions
    ?.filter(x => x.type === MetaDataTypes.AuditGroup) || [];

  if (renderMode === "picker") {
    return (
      <NonReduxPicker<IAuditGroup>
        itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title="Audit Groups"
        allowMultiSelect={allowMultiselect}
        displayMode="list"
        renderListItem={renderItem}
        searchOptions={{
          filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
        }}
        onRenderPicker={onRenderPicker}
        isDisabled={isDisabled}
        isDisabledMapper={!restrictedToItems.length
          ? undefined
          : item => !restrictedToItems.some(x => x.id === item.id)
        }
      />
    );
  } else if (renderMode === "dropdown") {
    return (
      <NonReduxPickerDropdown<IAuditGroup>
        itemFormatter={renderItem}
        keyMapper={item => item.id}
        onLoadItems={loadItems}
        onSelectionChanged={onApply}
        selectedItems={selectedItems}
        isDisabled={isDisabled}
        placeholder="Select"
        selectMode={allowMultiselect ? "multiple" : "single"}
        isDisabledMapper={!restrictedToItems.length
          ? undefined
          : item => !restrictedToItems.some(x => x.id === item.id)
        }
      />
    )
  } else {
    return <>Render mode "{renderMode}" is not supported.</>
  }
}

export default AuditGroupPicker;