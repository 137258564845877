import React from "react";
import { IPickerItem } from "shared/types/pickerTypes";
import "./PickerItem.scoped.scss";

interface IPickerItemProps<T> {
  item: IPickerItem<T>,
  isSelected: boolean,
  type: "checkbox" | "radio",
  renderItem?: (item: T) => string | React.ReactNode,
  onSelect(): void,
  onDeselect(): void,
  onToggle?(): void,
  isDisabled?: boolean,
  renderTotal?: (item: T) => string | React.ReactNode,
  showOnlySelectedItems?: boolean
  hiddenSelectOption?: boolean
}

const PickerItem = <T,>({
  item,
  isSelected,
  type,
  renderItem,
  onSelect,
  onDeselect,
  onToggle,
  isDisabled = false,
  renderTotal,
  showOnlySelectedItems,
  hiddenSelectOption,
}: IPickerItemProps<T>) =>
  <span
    className="picker-item"
  >
    {onToggle !== undefined && (
      <button
      >
        +
      </button>
    )}
    <label>
      {!hiddenSelectOption &&
        <input
          type={type}
          disabled={item.disabled || isDisabled}
          value={item.key}
          checked={isSelected}
          onChange={(e) => type === "checkbox"
            ? (
              e.target.checked
                ? onSelect()
                : onDeselect()
            ) : onSelect()
          }
        />
      }
      <span>
        <span
          className={item.disabled || isDisabled ? "disabled" : ""}
        >
          {renderItem && item.item
            ? renderItem(item.item)
            : item.text
          }
        </span>

        {renderTotal
          && item.item
          && <>
            <b>
              &nbsp;
              {renderTotal(item.item)}
            </b>
          </>
        }
      </span>
    </label>
  </span>;

export default PickerItem;
