import UrlRoutes from "components/routing/UrlRoutes";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonType } from "shared/components/controls/buttons/button/Button";
import SplitButton from "shared/components/controls/split-button/SplitButton";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { loadCustomSearchList, setFiltersName } from "store/search/CustomSearchSlice";
import { loadActionFilters } from "store/search/actions/SearchActionsSlice";
import { loadAuditFilters } from "store/search/audits/SearchAuditsSlice";
import { useAppSelector } from "store/store";
import "./SearchPageElements.scoped.scss";

const SearchPageElements: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customSearchAuditList = useAppSelector(store => store.customSearch.customSearchAuditList);
  const filtersNameAudit = useAppSelector(store => store.customSearch.filtersNameAudit);
  const filtersNameAction = useAppSelector(store => store.customSearch.filtersNameAction);
  const customSearchActionList = useAppSelector(store => store.customSearch.customSearchActionList);

  let auditButtonClass: ButtonType = "secondary";
  let actionButtonClass: ButtonType = "secondary";

  useEffect(() => {
    dispatch(loadCustomSearchList());
  }, [dispatch]);

  return (
    <div>
      <FlexCol
        className="elements"
      >
        <span
          className="title"
        >
          What do you want to Search for?
        </span>

        <FlexRow>
          <SplitButton
            display={filtersNameAudit !== undefined ? filtersNameAudit : 'Audits'}
            mode="button"
            buttonType={auditButtonClass}
            items={customSearchAuditList?.map(x => ({ id: x.id.toString(), text: x.name })) ?? []}
            onClick={() => navigate(UrlRoutes.SearchAudits.urlTemplate)}
            onItemClick={(itemId: string) => {
              const filter = customSearchAuditList?.find(x => x.id.toString() === itemId);

              if (!filter) {
                return;
              }

              dispatch(setFiltersName({ name: filter.name, type: "audit" }));
              dispatch(loadAuditFilters(Number(itemId)));
              navigate(UrlRoutes.SearchAudits.urlTemplate);
            }}
          />

          <SplitButton
            display={filtersNameAction !== undefined ? filtersNameAction : 'Actions'}
            mode="button"
            buttonType={actionButtonClass}
            items={customSearchActionList?.map(x => ({ id: x.id.toString(), text: x.name })) ?? []}
            onClick={() => navigate(UrlRoutes.SearchActions.urlTemplate)}
            onItemClick={(itemId: string) => {
              const filter = customSearchActionList?.find(x => x.id.toString() === itemId);

              if (!filter) {
                return;
              }

              dispatch(setFiltersName({ name: filter.name, type: "action" }));
              dispatch(loadActionFilters(Number(itemId)));
              navigate(UrlRoutes.SearchActions.urlTemplate);
            }}
          />
        </FlexRow>
      </FlexCol>
    </div >
  );
};

export default SearchPageElements;
