import { authGetJson, authPostJson } from "auth/authFetches";
import config from "config";
import { QISchedulerItemType } from "shared/components/controls/scheduler/qiSchedulerTypes";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IAuditPlanningFilters } from "store/audit-planning-shared/reducers/planningFiltersReducers";
import IAuditPlanCalendarBasinYAxisData from "types/audit-plan-calendar/IAuditPlanCalendarBasinYAxisData";
import AuditPlanCalendarItemTypes from "types/audit-plan-calendar/IAuditPlanCalendarItemTypes";
import IAuditPlanCalendarLeadAuditor from "types/audit-plan-calendar/IAuditPlanCalendarLeadAuditor";
import IExtraAuditPlanCalendarItemInfo from "types/audit-plan-calendar/IExtraAuditPlanCalendarItemInfo";
import { AuditSources } from "types/auditingTypes";
import { IGetAuditPlanCalendarItemRequest, IUpdateAuditPlanCalendarItemRequest } from "./auditPlanCalendarApiTypes";
import { convertAppliedAuditPlanCalendarFiltersToRequestObj, convertAppliedAuditPlanCalendarLeadAuditorFiltersToRequestObj, formatCalendarResourcesResponse, formatExtraAuditPlanCalendarItemInfo, formatGetCalendarItemsResponse, formatIAuditPlanCalendarLeadAuditorResponse } from "./auditPlanCalendarFormatters";

class AuditPlanCalendarApi {
  public async getPlanCalendarBasinYAxisData(appliedFilters: IAuditPlanningFilters): Promise<IAuditPlanCalendarBasinYAxisData> {
    const response = await authPostJson(config.endpoints.auditPlanning.planCalendarResources,
      convertAppliedAuditPlanCalendarFiltersToRequestObj(appliedFilters));
    await throwIfResponseError(response);

    return formatCalendarResourcesResponse(await response.json());
  }

  public async getPlanCalendarLeadAuditorYAxisData(appliedFilters: IAuditPlanningFilters,
    calendarStartDate: number,
    calendarEndDate: number): Promise<IAuditPlanCalendarLeadAuditor[]> {
    const response = await authPostJson(config.endpoints.auditPlanning.planCalendarLeadAuditors,
      convertAppliedAuditPlanCalendarLeadAuditorFiltersToRequestObj(appliedFilters,
        calendarStartDate,
        calendarEndDate));
    await throwIfResponseError(response);

    return formatIAuditPlanCalendarLeadAuditorResponse(await response.json());
  }

  public async getCalendarItems(request: IGetAuditPlanCalendarItemRequest,
    abortSignal?: AbortSignal) {
    const response = await authPostJson(`${config.endpoints.baseUrl}/AuditPlanCalendarItems/GetCalendarItems`,
      request,
      abortSignal);

    await throwIfResponseError(response);

    return formatGetCalendarItemsResponse(await response.json());
  }

  public async getExtraCalendarItemInfo(id: number,
    lookupType: QISchedulerItemType,
    auditSource: AuditSources | undefined,
    abortSignal: AbortSignal): Promise<IExtraAuditPlanCalendarItemInfo> {
    const response = await authGetJson(config.endpoints.auditPlanning.planCalendarItemExtraInfo
      .replace(":lookupType", lookupType === AuditPlanCalendarItemTypes.Plan
        ? "Plan"
        : (auditSource === AuditSources.QoF
          ? "QoFAudit"
          : "LegacyAudit"))
      .replace(":id", id.toString()),
      abortSignal);

    await throwIfResponseError(response);

    return formatExtraAuditPlanCalendarItemInfo(await response.json());
  }

  public async updateCalendarItem(request: IUpdateAuditPlanCalendarItemRequest[]): Promise<IUpdateAuditPlanCalendarItemRequest[]> {
    const response = await authPostJson(config.endpoints.auditPlanning.updatePlanCalendarItem, request);

    await throwIfResponseError(response);

    return (await response.json()) as IUpdateAuditPlanCalendarItemRequest[];
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditPlanCalendarApi();

