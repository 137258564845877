import { authDelete, authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IUserPlanningView, IUserPlanningViewValue } from "types/audit-planning-shared/UserPlanningViews";
import { formatDetailsPlanningViewResponse } from "./formatters/formatDetailsPlanningViewResponse";
import { formatLoadPlanningViewsResponse } from "./formatters/formatLoadPlanningViewsResponse";
import { formatUpsertPlanningViewResponse } from "./formatters/formatUpsertPlanningView";
import { IUserPlanningViewUpsertRequestDto, IUserPlanningViewUpsertResponseDto } from "./types/UserPlanningViewDtos";

class UserViewApi {
  public async savePlanningView(request: IUserPlanningViewUpsertRequestDto, abortSignal?: AbortSignal): Promise<IUserPlanningViewUpsertResponseDto> {
    const response = await authPostJson(`${config.endpoints.baseUrl}/userPlanningViews`,
      request,
      abortSignal);

    await throwIfResponseError(response);

    return formatUpsertPlanningViewResponse(await response.json());
  }

  public async updatePlanningView(request: IUserPlanningViewUpsertRequestDto, abortSignal?: AbortSignal): Promise<IUserPlanningViewUpsertResponseDto> {
    const response = await authPutJson(`${config.endpoints.baseUrl}/userPlanningViews/${request.id}`,
      request,
      abortSignal);

    await throwIfResponseError(response);

    return formatUpsertPlanningViewResponse(await response.json());
  }

  public async getPlanningViewValues(id: number, abortSignal?: AbortSignal): Promise<IUserPlanningViewValue[]> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/userPlanningViews/${id}/values`,
      abortSignal);

    await throwIfResponseError(response);

    return formatDetailsPlanningViewResponse(await response.json());
  }

  public async deletePlanningView(id: number, abortSignal?: AbortSignal): Promise<void> {
    const response = await authDelete(`${config.endpoints.baseUrl}/userPlanningViews/${id}`,
      abortSignal);

    await throwIfResponseError(response);
  }

  public async loadPlanningViews(abortSignal?: AbortSignal): Promise<IUserPlanningView[]> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/userPlanningViews`,
      abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatLoadPlanningViewsResponse(obj));
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new UserViewApi();
