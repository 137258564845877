import UsersApi from "api/users/UsersApi";
import React, { useCallback } from "react";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { userToString } from "shared/utilities/userUtilities";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";

export enum AzureUserPickerSuggestionMode {
  Interviewees = "Interviewees",
}

interface IAzureUserPickerProps {
  onApply: (selectedItems: IAzureADUser[]) => void,
  selectedItems: IAzureADUser[],
  allowMultiselect?: boolean,
  /**
   * Optional. If specified, the picker will render itself using this function. The returned React.ReactNode should
   * call the provided `openPicker` function when clicked to open the picker.
   */
  onRenderPicker?: (openPicker: () => void) => React.ReactNode,
  /** Optional. If true, the user cannot change selections. */
  isDisabled?: boolean,
  /** Optional. Tooltip when hovering the component. */
  tooltip?: string,
  /** Optional. Custom title. Default = "Users". */
  title?: string,
  /** Optional. Custom class name for picker holder div. */
  className?: string,
  /** Optional. Defines which suggestions to load and suggest to the user. If not specified, suggestions will not be shown. */
  suggestionMode?: AzureUserPickerSuggestionMode,
}

/** A picker for selecting a user from Azure AD. This is NOT the same as LeadAuditor. For LeadAuditors, use the
 * LeadAuditorPicker instead.
 */
const AzureUserPicker: React.FC<IAzureUserPickerProps> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  onRenderPicker,
  isDisabled = false,
  tooltip,
  title,
  className,
  suggestionMode,
}) => {
  const onLoadSuggestedItems = useCallback(async (abortSignal: AbortSignal) => {
    if (suggestionMode === AzureUserPickerSuggestionMode.Interviewees) {
      return await UsersApi.getIntervieweeSuggestions();
    }

    return [];
  }, [suggestionMode]);

  return (
    <NonReduxPicker<IAzureADUser>
      itemSorter={(a, b) => userToString(a) < userToString(b) ? -1 : 1}
      keyMapper={x => x.email}
      onApply={onApply}
      onLoadItems={async (searchTerm, abortSignal) => await UsersApi.searchAzureADUsers(searchTerm || "", abortSignal)}
      renderSelectedItem={userToString}
      selectedItems={selectedItems}
      title={title ?? "Users"}
      allowMultiSelect={allowMultiselect}
      renderListItem={userToString}
      searchOptions={{
        filterItem: (item, search) => userToString(item).toLowerCase().includes(search.toLowerCase()),
        behavior: "async",
        asyncMinChars: 2,
      }}
      onRenderPicker={onRenderPicker}
      isDisabled={isDisabled}
      tooltip={tooltip}
      className={className}
      showSuggestedItems={suggestionMode !== undefined}
      onLoadSuggestedItems={suggestionMode !== undefined
        ? onLoadSuggestedItems
        : undefined
      }
    />
  );
};

export default AzureUserPicker;