import { IPickerItem } from "shared/types/pickerTypes";

/**
 * Converts a generic object to a picker item object.
 * @param item The generic object to be converted to a picker item.
 * @param expandedKeys An array that contains the expanded picker items key
 * to be possible to set the currently item as exapended or not.
 * @param keyMapper A mapper function to get the picker item key from the generic object.
 * @param childrenMapper (optional) A mapper function to get the object children to be rendered.
 * @param includeChildren (optional) A flag used to get the children from the object or not.
 * @param allItems (optional) All the items of a collection used to search for the children.
 * @returns A picker item that contains the generic object.
 */
export const convertToPickerItem = <T,>(item: T,
    expandedKeys: (string | number)[],
    keyMapper: (item: T) => string | number,
    childrenMapper?: (parentItem: T, allItems: T[]) => T[], 
    includeChildren?: boolean, 
    allItems?: T[]): IPickerItem<T> => {
  return {
    key: keyMapper(item),
    item: item,
    children: includeChildren
      && allItems
      && childrenMapper
      ? childrenMapper(item, allItems).map(x => convertToPickerItem(x, expandedKeys, keyMapper, childrenMapper, includeChildren, allItems))
      : [],
    isExpanded: expandedKeys.some(x => x === keyMapper(item)),
  };
};

/**
 * Converts a picker item to a generic type object.
 * @param pickerItem The picker item to be converted.
 * @returns The generic object converted from the picker item.
 */
export const fromPickerItem = <T,>(pickerItem: IPickerItem<T>): T => {
  return pickerItem.item as T;
};
