import { IMyActionsGridRowObject } from "components/actions/MyActionsPage";
import UrlRoutes from "components/routing/UrlRoutes";
import AppliedFilters from "components/search/filters-tooltip/AppliedFilters";
import SearchTabs from "components/search/tabs/SearchTabs";
import config from "config";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CircleImgButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import Card from "shared/components/layout/card/Card";
import DataGrid from "shared/components/layout/grid/DataGrid";
import DataGridExportButton from "shared/components/layout/grid/export-button/DataGridExportButton";
import FittedPage from "shared/components/layout/page/fitted-page/FittedPage";
import filterIcon from "shared/media/dls/filter.svg";
import formatDate from "shared/utilities/dateFormatters";
import { loadAndInspectActionItem } from "store/search/actions/SearchActionsSlice";
import ActionItemInspector from "../action-item-inspector/ActionItemInspector";
import "./ActionSearchResultsPage.scoped.scss";
import FiltersTooltip from "./filters-tooltip/FiltersTooltip";
import useActionSearchValues from "./useActionSearchValues";

const ActionSearchResultsPage: React.FC = () => {
  const dispatch = useDispatch();
  const searchValues = useActionSearchValues();

  const url = config.endpoints.actionItems.searchActions + searchValues.queryString;

  const tooltipRef = useRef<any>();

  const onActionItemClicked = (id: number) => {
    tooltipRef?.current?.close?.();
    dispatch(loadAndInspectActionItem(id));
  };

  const actionTemplate = (row: IMyActionsGridRowObject) => {
    return (<span
      className="action-required"
      onClick={() => onActionItemClicked(row.id)}
    >
      {row.actionRequired}
    </span>);
  };

  return (
    <FittedPage
      title="Action Search"
    >
      <div className="search-results-header-buttons">
        <SearchTabs />

        <div className="right-side">
          <DataGridExportButton
            gridId="ActionSearchResults"
          />

          <AppliedFilters
            numAppliedFilters={searchValues.filterCount}
            tooltipContent={<FiltersTooltip />}
            maxTooltipHeight={350}
          />

          <Link
            to={UrlRoutes.SearchActions.urlTemplate}
          >
            <CircleImgButton
              alt="Filters"
              icon={filterIcon}
              type="primary"
            />
          </Link>
        </div>
      </div>

      <Card
        title="Action Items"
        className="search-results-card"
      >
        <DataGrid
          id="ActionSearchResults"
          dataSource="OData"
          showColumnChooser={true}
          dataUrl={url}
          allowExcelExport={true}
          recordClick={row => onActionItemClicked(row.id)}
          gridColumns={[
            {
              uid: "parentId",
              field: "parentId",
              headerText: "Parent Id",
              width: 150,
              template: x => x.parentId.toString(),
              type: "number",
            },
            {
              uid: "parentType",
              field: "parentType",
              headerText: "Parent Type",
              width: 150,
              type: "string",
            },
            {
              uid: "parentName",
              field: "parentName",
              headerText: "Parent Name",
              width: 250,
              type: "string",
            },
            {
              uid: "id",
              field: "id",
              headerText: "Action Item Id",
              width: 180,
              template: x => x.id.toString(),
              type: "number",
            },
            {
              uid: "type",
              field: "type",
              headerText: "Action Item Type",
              width: 250,
              type: "string",
            },
            {
              uid: "actionRequired",
              field: "actionRequired",
              headerText: "Action Required",
              width: 250,
              type: "string",
              showInColumnChooser: false,
              template: actionTemplate,
            },
            {
              uid: "assignedToName",
              field: "assignedToName",
              headerText: "Assigned To",
              width: 250,
              type: "string",
            },
            {
              uid: "createdByName",
              field: "createdByName",
              headerText: "Created By",
              width: 250,
              type: "string",
            },
            {
              uid: "priority",
              field: "priority",
              headerText: "Priority",
              width: 250,
              type: "string",
            },
            {
              uid: "dueDate",
              field: "dueDate",
              headerText: "Due Date",
              width: 200,
              type: "date",
              template: (row: IMyActionsGridRowObject) => formatDate(row.dueDate),
            },
            {
              uid: "status",
              field: "status",
              headerText: "Status",
              width: 200,
              type: "string",
            },
            {
              uid: "isValidated",
              field: "isValidated",
              headerText: "Validated?",
              width: 160,
              type: "boolean",
            }]}
        />
      </Card>

      <ActionItemInspector />
    </FittedPage>
  );
};

export default ActionSearchResultsPage;
