import React, { useEffect, useRef } from "react";
import searchIcon from "shared/media/dls/search.svg";
import Button from "../buttons/button/Button";
import "./SearchBox.scoped.scss";

interface ISearchBoxProps {
  inputProps?: React.HTMLProps<HTMLInputElement>,
  wrapperProps?: React.HTMLProps<HTMLDivElement>,
  showButton?: boolean,
  onButtonClick?: () => void,
}

const SearchBox: React.FC<ISearchBoxProps> = (props) => {
  const autofocus = props.inputProps?.autoFocus;
  const searchBoxRef = useRef<HTMLInputElement | null>(null);
  const showButton = props.showButton ?? false;

  useEffect(() => {
    if (searchBoxRef.current
      && autofocus) {
      window.setTimeout(() => searchBoxRef.current?.focus(), 1);
    }
  }, [autofocus]);

  return (
    <div
      className="search-box"
      style={props.wrapperProps?.style}
    >
      <input
        type="text"
        {...props.inputProps}
        className={`search-box-input ${props.inputProps?.className}`}
        style={{
          ...props.inputProps?.style,
          backgroundImage: !showButton
            ? `url(${searchIcon})`
            : props.inputProps?.style?.backgroundImage,
        }}
        ref={searchBoxRef}
      />
      {showButton
        && props.onButtonClick &&
        <Button
          onClick={props.onButtonClick}
          buttonType="secondary"
          img={searchIcon}
          imgAlt="Search"
        />
      }
    </div >
  );
};

export default SearchBox;