import AuditTopicApi from "api/audit-topic/AuditTopicApi";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { refreshGrid } from "store/grid/GridSlice";
import { RootState } from "store/store";
import { finishRevertAuditTopicStatus, finishSaveAuditTopic, IAuditTopicGridRowObject, startRevertAuditTopicStatus, startSaveAuditTopic, stopSaveAuditTopic } from "./AuditTopicSlice";
import { IAuditTopic } from "types/auditMasterDataTypes";

export default function* auditTopicSagas() {
  yield all([
    startSaveAuditTopicAsync(),
    startRevertAuditTopicStatusAsync(),
  ]);
}

function* startSaveAuditTopicAsync() {
  yield takeLatest(startSaveAuditTopic, function* () {
    const managerValues: Partial<IAuditTopic>
      = yield select((store: RootState) => store.auditTopics.managerValues);

    if (!managerValues.name) {
      yield put(showErrorToast("Audit topic name is required."));
      yield put(stopSaveAuditTopic());
      return;
    }

    if (!managerValues.shortName) {
      yield put(showErrorToast("Audit topic shortname is required."));
      yield put(stopSaveAuditTopic());
      return;
    }

    if (!managerValues.ownerGroupId) {
      yield put(showErrorToast("Owner Group is required."));
      yield put(stopSaveAuditTopic());
      return;
    }

    if (!managerValues.auditGroupId) {
      yield put(showErrorToast("Audit Group is required."));
      yield put(stopSaveAuditTopic());
      return;
    }

    yield call(AuditTopicApi.saveAuditTopic, managerValues);

    try {
      const opType = managerValues.id
        ? "updated"
        : "created";

      yield put(showSuccessToast(`Topic/Sub-Topic ${opType} successfully.`));
      yield put(finishSaveAuditTopic(true));
      yield put(refreshGrid({ gridId: "AuditTopicsGrid" }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishSaveAuditTopic(false));
    }
  });
}

function* startRevertAuditTopicStatusAsync() {
  yield takeLatest(startRevertAuditTopicStatus, function* (action) {
    try {

      if (!startRevertAuditTopicStatus.match(action)) {
        return;
      }

      const auditTopicRow: IAuditTopicGridRowObject
        = yield select((store: RootState) => store.auditTopics.auditTopicRow);

      const result: IAuditTopic = yield call(AuditTopicApi.revertStatus, Number(auditTopicRow.id));
      const opType = !result.isDeleted
        ? "activated"
        : "deactivated";

      yield put(showSuccessToast(`Topic/Sub-Topic ${opType} successfully.`));
      yield put(finishRevertAuditTopicStatus(true));
      yield put(refreshGrid({ gridId: "AuditTopicsGrid" }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishRevertAuditTopicStatus(false));
    }
  });
}
