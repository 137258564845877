import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React, { useEffect } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { setApprovalModal, startApprovePlans } from "store/audit-plan-approvals/AuditPlanApprovalsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IPlanChildDimension, IPlanParentDimension } from "types/auditPlanningTypes";
import ApproveAuditPlansTable from "./ApproveAuditPlansTable";

interface IAuditApprovalPlanProps {
  /**
   * Specifies if the modal is open or not. If not open, it does not render.
   */
  isOpen: boolean,
  /**
   * The details of the parent dimension for the plan.
   */
  parentDimension?: IPlanParentDimension,
  /**
    * The details of the child dimension for the plan.
    */
  childDimension?: IPlanChildDimension,
  /**
   * The audit type for the plan.
   */
  auditTypeName: string,
}

const ApprovalModal: React.FC<IAuditApprovalPlanProps> = ({
  isOpen,
  parentDimension,
  childDimension,
  auditTypeName,
}) => {
  const {
    approvalModal: {
      approveOperation
    },
  } = useAppSelector(store => store.auditPlanApprovals);
  const dispatch = useAppDispatch();

  let headerText = `Approve Audit Plan for ${parentDimension?.text}`;

  if (childDimension) {
    headerText += ` - ${childDimension?.text}`
  }

  headerText += ` - ${auditTypeName}`;

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: `Plan Approvals - Approve Plan`,
    });
  }, [appInsights]);

  return (
    <Modal
      isOpen={isOpen}
      showCloseButton={true}
      onCloseButtonClicked={() => dispatch(setApprovalModal({
        isOpen: false,
      }))}
      header={headerText}
      buttons={[
        {
          key: "cancel",
          text: "Cancel",
          onClick: () => dispatch(setApprovalModal({
            isOpen: false,
          })),
          className: "secondary",
        },
        {
          key: "approve",
          text: "Approve",
          onClick: () => dispatch(startApprovePlans()),
          className: "primary",
        }
      ]}
    >
      <FlexCol>
        <span>
          Would you like to approve these Audit Plans for {parentDimension?.text} ?
        </span>

        <ApproveAuditPlansTable />
      </FlexCol>

      {approveOperation?.isWorking && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default ApprovalModal;