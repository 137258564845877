import { formatAuditTopic } from "api/masterdata/AuditTopicApi";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { ITemplate } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren, ITemplateChildrenRequest, ITemplateRequest } from "types/templateApiTypes";
import { v4 as uuidv4 } from "uuid";

export function formatTemplate(obj: any): ITemplate {
  return {
    id: obj.id,
    name: obj.name,
    isDeleted: obj.deleted,
    ownerGroupId: obj.ownerGroupId,
  };
}

export function formatDetailedTemplate(obj: any): IDetailedTemplate {
  const template = obj.template;
  const children = obj.children;

  return {
    id: Number(template.id),
    key: uuidv4(),
    name: template.name,
    auditGroupId: Number(template.auditGroupId),
    ownerGroupId: Number(template.ownerGroupId),
    scoringSystem: template.scoringSystem,
    isDeleted: Boolean(template.deleted),
    createdBy: template.createdBy,
    createdOn: new Date(template.createdOn).getTime(),
    modifiedBy: template.modifiedBy,
    modifiedOn: new Date(template.modifiedOn).getTime(),
    isPlannable: Boolean(template.plannable),
    children: (children || []).map(formatDetailedTemplateChildren),
  };
}

export function formatDetailedTemplateChildren(item: any): IDetailedTemplateChildren {
  let mdObject: ITemplate | IAuditTopic;

  if (item.masterDataType === MetaDataTypes.AuditTemplate) {
    mdObject = formatTemplate(item.masterDataObject);
  } else if (item.masterDataType === MetaDataTypes.AuditTopic) {
    mdObject = formatAuditTopic(item.masterDataObject);
  } else {
    throw new Error(`Unrecognized template child type for template child Id ${item.templateChildId}: ${item.masterDataType}/${item.masterDataId}`);
  }

  return {
    id: item.templateChildId,
    key: uuidv4(),
    templateId: item.templateId,
    masterDataId: item.masterDataId,
    masterDataType: item.masterDataType,
    masterDataObject: mdObject,
    label: item.label,
    name: item.label,
    isSelectable: item.isSelectable,
    isRequired: item.isRequired,
    children: (item.children || []).map(formatDetailedTemplateChildren),
  }
}

export function formatSaveTemplateRequest(item: ITemplateRequest): ITemplateRequest {
  return {
    name: item.name,
    auditGroupId: Number(item.auditGroupId),
    ownerGroupId: Number(item.ownerGroupId),
    plannable: Boolean(item.plannable),
    children: item.children.map(x => formatSaveTemplateChildrenRequest(x)),
  };
}

export function formatSaveTemplateChildrenRequest(children: ITemplateChildrenRequest) {
  return {
    id: children.id,
    type: children.type,
    isRequired: children.isRequired
  }
}
