import React, { useState } from "react";
import Button from "shared/components/controls/buttons/button/Button";
import Card from "shared/components/layout/card/Card";
import FlexRow from "shared/components/layout/flex/FlexRow";
import CloseIcon from "shared/media/dls/close.svg";
import FullscreenIcon from "shared/media/dls/expand-1.svg";
import AuditTypeSelector from "../../audit-type-selector/AuditTypeSelector";
import PlanYearSelector from "../../plan-year-selector/PlanYearSelector";
import UserPlanningViewPicker from "../../user-planning-view-picker/UserPlanningViewPicker";
import "./GridCard.scoped.scss";

interface IGridCardProps {
  /**
   * If set to false, the maximize button will not be shown.
   */
  allowMaximize?: boolean,
  /** Any extra react node to be rendered to the left of the year selection. */
  centerNode?: React.ReactNode,
  /** An optional props to pass children */
  children?: React.ReactNode,
  /** Determines on which page this component is being displayed. */
  page: "FacilityAttributes" | "PlanApprovals" | "PlanResults",
}

const GridCard: React.FC<IGridCardProps> = ({
  allowMaximize,
  centerNode,
  children,
  page,
}) => {
  const [isMaximized, setIsMaximized] = useState(false);

  return (
    <div
      className={`grid-card-wrapper ${isMaximized ? "maximized" : ""}`}
    >
      <Card
        className="grid-card"
        showHeader={true}
        headerElement={(
          <FlexRow
            className="header"
          >
            <FlexRow
              className="audit-types"
            >
              <AuditTypeSelector />
            </FlexRow>

            {centerNode}

            <FlexRow
              className="years"
            >
              <PlanYearSelector />
            </FlexRow>

            <UserPlanningViewPicker
              page={page}
            />

            {allowMaximize !== false && (
              <Button
                buttonType="secondary"
                imgPlacement="right"
                img={isMaximized
                  ? CloseIcon
                  : FullscreenIcon}
                onClick={() => setIsMaximized(!isMaximized)}
              />
            )}
          </FlexRow>
        )}
      >
        {children}
      </Card>
    </div>
  );
};

export default GridCard;