export function compareInsensitive(str1: string | undefined,
  str2: string | undefined,
  trimStrings?: boolean) {
  if ((str1 === undefined) !== (str2 === undefined)) {
    return false;
  }

  if (trimStrings) {
    return str1?.toLowerCase().trim() === str2?.toLowerCase().trim();
  } else {
    return str1?.toLowerCase() === str2?.toLowerCase();
  }
}

export function getTextboxMaxLengthHint(maxLength: number): string {
  return `(max. ${maxLength} chars)`;
}

export function tooltipMessage(isDisabled: boolean, messageDisabled?: string, messageEnabled?: string) {
  return isDisabled
    ? messageDisabled ?? "No changes."
    : messageEnabled ?? "";
}

/** Adds spaces before every capital letter that is followed by a non-capital letter. (example: 'ChangeMyString' becomes 'Change My String') */
export function toTitleCase(str: string | undefined): string | undefined {
  if (!str) {
    return str;
  }

  return str.replace(/([A-Z])([^A-Z])/g, " $1$2").trimStart().trimEnd();
}

/** Remove all html tags and returns a textplain */
export function removeHTML(str: string | undefined): string {
  return !str
    ? ''
    : str.replace(/<[^>]*>/g, '')
}