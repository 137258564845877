import ActionItemsApi from "api/actionItems/ActionItemsApi";
import MasterDataApi from "api/masterdata/MasterDataApi";
import SearchActionApi from "api/search/action/SearchActionApi";
import SearchAuditorsApi from "api/users/SearchAuditorsApi";
import { Action } from "redux";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IPickerItem } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { isNumber } from "shared/utilities/typeCheck";
import { mapBusinessFunctionToPickerItem, mapBusinessTeamToPickerItem, mapBusinessViewToPickerItem } from "store/common/sagas/pickerSagas";
import { RootState } from "store/store";
import { IActionItem, IActionItemPriority, IActionItemType } from "types/actionItemTypes";
import { IAuditGroup, IAuditType, ICausalFactor } from "types/auditMasterDataTypes";
import { AuditorSearchTypes } from "types/auditingTypes";
import { ICustomSearchFilterMetaDataType } from "types/customSearchTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICountry, MetaDataTypes } from "types/masterDataTypes";
import { ICustomActionSearch, ICustomActionSearchCreateRequest } from "types/searchActionTypes";
import { addCustomSearchItem, removeCustomSearchItem, setFiltersName } from "../CustomSearchSlice";
import { ISearchActionsState, SearchActionsPickerKeys, clearFilters, deleteFilters, finishDeleteFilters, finishLoadActionFilters, finishLoadingActionItem, finishSavingFilters, finishUpdateFilters, loadActionFilters, loadAndInspectActionItem, loadPickerItems, saveFilters, setPickerError, setPickerItems, setSelectedPickerItems, updateFilters } from "./SearchActionsSlice";

export default function* searchActionsSagas() {
  yield all([
    loadPickerItemsAsync(),
    loadAndInspectActionItemAsync(),
    watchSaveFilters(),
    loadActionFilterAsync(),
    watchUpdateFilterAsync(),
    watchDeleteFilterAsync(),
  ]);
}

function* loadPickerItemsAsync() {
  yield takeEvery(loadPickerItems, function* (action: Action) {
    if (!loadPickerItems.match(action)) {
      return;
    }

    const {
      pickerKey,
      searchValue,
    } = action.payload;

    try {
      switch (pickerKey) {
        case SearchActionsPickerKeys.auditTypes:
          yield retrieveAndPutPickerData(MasterDataApi.getAuditTypes,
            (item): IPickerItem<IAuditType> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey);
          break;
        case SearchActionsPickerKeys.actionItemTypes:
          yield retrieveAndPutPickerData(MasterDataApi.getActionItemTypes,
            (item): IPickerItem<IActionItemType> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey);
          break;
        case SearchActionsPickerKeys.countries:
          yield retrieveAndPutPickerData((searchTerm) => MasterDataApi.getCountries(searchTerm),
            (item): IPickerItem<ICountry> => ({
              key: item.id,
              disabled: false,
              text: item.name,
            }),
            pickerKey,
            searchValue);
          break;
        case SearchActionsPickerKeys.businessViews:
          yield retrieveAndPutPickerData(MasterDataApi.getBusinessViews,
            (item): IPickerItem<IBusinessView> => mapBusinessViewToPickerItem(item),
            pickerKey,
            searchValue);
          break;
        case SearchActionsPickerKeys.businessTeams:
          // Auto expand global ops.
          const globalOpsId = 84;

          yield retrieveAndPutPickerData(() => MasterDataApi.getBusinessTeams(),
            (item): IPickerItem<IBusinessTeam> => {
              const pickerItem = mapBusinessTeamToPickerItem(item);
              pickerItem.isExpanded = item.id === globalOpsId;
              return pickerItem;
            },
            pickerKey,
            searchValue);
          break;
        case SearchActionsPickerKeys.businessFunctions:
          yield retrieveAndPutPickerData(() => MasterDataApi.getBusinessFunctions(),
            (item): IPickerItem<IBusinessFunction> => mapBusinessFunctionToPickerItem(item),
            pickerKey,
            searchValue);
          break;
        case SearchActionsPickerKeys.leadAuditors:
        case SearchActionsPickerKeys.assignedTo:
        case SearchActionsPickerKeys.createdBy:
          yield retrieveUsers(pickerKey, searchValue || "", AuditorSearchTypes.Auditor);
          break;
        case SearchActionsPickerKeys.priorities:
          yield retrieveAndPutPickerData(MasterDataApi.getActionItemPriorities,
            (item): IPickerItem<IActionItemPriority> => ({
              key: item.id,
              disabled: false,
              item: item,
            }),
            pickerKey,
            searchValue);
          break;
        case SearchActionsPickerKeys.causalFactors:
          yield retrieveAndPutPickerData(MasterDataApi.getCausalFactors,
            (item): IPickerItem<ICausalFactor> => ({
              key: item.id,
              disabled: false,
              item: item,
            }),
            pickerKey,
            searchValue);
          break;
        default:
          throw new Error(`Picker '${pickerKey}' has no associated saga for loading items!`);
      }
    } catch (err) {
      yield put(setPickerError({
        pickerKey: pickerKey,
        errorMessage: getResponseErrorMessage(err),
        stopLoading: true,
      }));
    }
  });
}

function* watchSaveFilters() {
  yield takeLatest(saveFilters, function* (action: Action) {
    if (!saveFilters.match(action)) {
      return;
    }

    try {
      const state: ISearchActionsState = yield select((store: RootState) => store.searchActions);

      let metaDataList: ICustomSearchFilterMetaDataType[] = createMetaDataType(state);

      const newId: number = yield call(SearchActionApi.saveActionFilters,
        action.payload.filtersName,
        action.payload.searchType,
        createFilter(state, metaDataList));

      yield put(finishSavingFilters({
        isWorking: false,
        data: newId
      }));

      yield put(addCustomSearchItem({
        type: "action",
        savedFilters: {
          id: newId,
          name: action.payload.filtersName,
        },
      }));

      yield put(setFiltersName({
        name: action.payload.filtersName,
        type: "action"
      }));

      yield put(showSuccessToast("Filter saved successfully ."));
    } catch (err) {
      let msg = getResponseErrorMessage(err);

      yield put(finishSavingFilters({
        isWorking: false,
        errorMessage: msg,
      }));
    }
  });
}

function* loadActionFilterAsync() {
  yield takeLatest(loadActionFilters, function* (action: Action) {
    if (!loadActionFilters.match(action)) {
      return;
    }

    try {
      const filters: ICustomActionSearch = yield call(SearchActionApi.getActionFilters, action.payload);

      yield put(clearFilters());

      yield loadAuditType(filters.auditTypes);
      yield loadActionItemTypes(filters.actionItemTypes);
      yield loadAssignedTo(filters.assignedTo);
      yield loadCreatedBy(filters.createdBy);
      yield loadContries(filters.countries);
      yield loadBusinessViews(filters.businessViews);
      yield loadBusinessTeams(filters.businessTeams);
      yield loadBusinessFunctions(filters.businessFunctions);
      yield loadLeadAuditors(filters.leadAuditors);
      yield loadPriorities(filters.priorities);
      yield loadCausalFactors(filters.causalFactors);

      yield put(finishLoadActionFilters({
        isWorking: false,
        data: filters
      }));

      yield put(showSuccessToast("Filter loaded successfully ."));
    } catch (err) {
      yield put(finishLoadActionFilters({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* watchUpdateFilterAsync() {
  yield takeLatest(updateFilters, function* (action: Action) {
    if (!updateFilters.match(action)) {
      return;
    }

    try {
      const state: ISearchActionsState = yield select((store: RootState) => store.searchActions);

      const metaDataList: ICustomSearchFilterMetaDataType[] = createMetaDataType(state);

      yield call(SearchActionApi.updateActionFilters,
        state.id!,
        createFilter(state, metaDataList));

      yield put(finishUpdateFilters({
        isWorking: false
      }));

      yield put(showSuccessToast("Filter saved successfully ."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishUpdateFilters({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* watchDeleteFilterAsync() {
  yield takeLatest(deleteFilters, function* (action: Action) {
    if (!deleteFilters.match(action)) {
      return;
    }

    try {
      const state: ISearchActionsState = yield select((store: RootState) => store.searchActions);

      yield call(SearchActionApi.deleteActionFilters,
        state.id!);

      yield put(removeCustomSearchItem({
        type: "action",
        id: state.id!,
      }));

      yield put(finishDeleteFilters({
        isWorking: false
      }));

      yield put(showSuccessToast("Filter deleted successfully ."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishDeleteFilters({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* retrieveAndPutPickerData<T>(apiMethod: (searchValue?: string | undefined) => Promise<T[]>,
  itemMapper: (item: T) => IPickerItem<T>,
  pickerKey: string,
  searchValue?: string) {
  const rawItems: T[] = yield call(apiMethod, searchValue);
  const items = rawItems.map(itemMapper);

  yield put(setPickerItems({
    pickerKey,
    items,
  }));

  yield put(setPickerError({
    pickerKey,
    errorMessage: "",
    stopLoading: true,
  }));
}

function* retrieveUsers(pickerKey: string, searchValue: string, searchType: AuditorSearchTypes) {
  let auditTypeId = 1;

  const rawItems: IAzureADUser[] = yield call(SearchAuditorsApi.searchAuditors,
    searchValue,
    auditTypeId,
    searchType);

  const items = rawItems.map((item): IPickerItem<IAzureADUser> => ({
    key: item.email,
    disabled: false,
    item: item,
  }));

  yield put(setPickerItems({
    pickerKey,
    items,
  }));

  yield put(setPickerError({
    pickerKey,
    errorMessage: "",
    stopLoading: true,
  }));
}

function* loadAndInspectActionItemAsync() {
  yield takeLatest(loadAndInspectActionItem, function* (action) {
    if (!loadAndInspectActionItem.match(action)) {
      return;
    }

    try {
      if (isNaN(action.payload)) {
        throw new Error("The provided value is not a valid action item ID.");
      }

      // Query the server to get the action item.
      const actionItem: IActionItem = yield call(ActionItemsApi.getActionItem, action.payload);

      yield put(finishLoadingActionItem({
        isWorking: false,
        data: actionItem,
      }));
    } catch (err) {
      let msg = getResponseErrorMessage(err);

      if (msg === "The specified Id was not found.") {
        msg = "No Results.";
      }

      yield put(finishLoadingActionItem({
        isWorking: false,
        errorMessage: msg,
      }));
    }
  });
}

function* loadAuditType(auditTypes: IAuditType[]) {
  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.auditTypes,
    selectedItems: auditTypes
      .map((auditType): IPickerItem<IAuditGroup> => ({
        key: auditType.id,
        disabled: false,
        text: auditType.name,
      }))
  }));
}

function* loadActionItemTypes(actionItemTypes: IActionItemType[]) {
  const items = actionItemTypes
    .map((actionItemType): IPickerItem<ICountry> => ({
      key: actionItemType.id,
      disabled: false,
      text: actionItemType.name,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.actionItemTypes,
    selectedItems: items
  }));
}

function* loadAssignedTo(assignedTo: IAzureADUser[]) {
  const items = assignedTo
    .map((to): IPickerItem<IAzureADUser> => ({
      key: to.email,
      disabled: false,
      text: to.name,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.assignedTo,
    selectedItems: items
  }));
}

function* loadCreatedBy(createdBy: IAzureADUser[]) {
  const items = createdBy
    .map((by): IPickerItem<IAzureADUser> => ({
      key: by.email,
      disabled: false,
      text: by.name,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.createdBy,
    selectedItems: items
  }));
}

function* loadContries(contries: ICountry[]) {
  const items = contries
    .map((country): IPickerItem<ICountry> => ({
      key: country.id,
      disabled: false,
      text: country.name,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.countries,
    selectedItems: items
  }));
}

function* loadBusinessViews(businessViews: IBusinessView[]) {
  const items = businessViews
    .map((businessView): IPickerItem<IBusinessView> => ({
      key: businessView.id,
      disabled: false,
      text: businessView.name,
      item: businessView,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.businessViews,
    selectedItems: items
  }));
}

function* loadBusinessTeams(businessTeams: IBusinessTeam[]) {
  const items = businessTeams
    .map((businessTeam): IPickerItem<IBusinessTeam> => ({
      key: businessTeam.id,
      disabled: false,
      text: businessTeam.name,
      item: businessTeam,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.businessTeams,
    selectedItems: items
  }));
}

function* loadBusinessFunctions(businessFunctions: IBusinessFunction[]) {
  const items = businessFunctions
    .map((func): IPickerItem<IBusinessFunction> => ({
      key: func.id,
      disabled: false,
      text: func.name,
      item: func,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.businessFunctions,
    selectedItems: items
  }));
}

function* loadLeadAuditors(leadAuditors: IAzureADUser[]) {
  const items = leadAuditors
    .map((leadAuditor): IPickerItem<IAzureADUser> => ({
      key: leadAuditor.email,
      disabled: false,
      text: leadAuditor.name,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.leadAuditors,
    selectedItems: items
  }));
}

function* loadPriorities(priorities: IActionItemPriority[]) {
  const items = priorities
    .map((prioritie): IPickerItem<IActionItemPriority> => ({
      key: prioritie.id,
      disabled: false,
      text: prioritie.name,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.priorities,
    selectedItems: items
  }));
}

function* loadCausalFactors(causalFactors: ICausalFactor[]) {
  const items = causalFactors
    .map((causalFactor): IPickerItem<ICausalFactor> => ({
      key: causalFactor.id,
      disabled: false,
      text: causalFactor.name,
    }))

  yield put(setSelectedPickerItems({
    pickerKey: SearchActionsPickerKeys.causalFactors,
    selectedItems: items
  }));
}

function createMetaDataType(state: ISearchActionsState): ICustomSearchFilterMetaDataType[] {
  let metaDataList: ICustomSearchFilterMetaDataType[] = [];

  metaDataList
    .push(
      ...state.facilities
        .map(x => ({ id: x.id, metaDataType: MetaDataTypes.Facility }))
    );
  metaDataList
    .push(
      ...state.pickerData.countries.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.Country }))
    );
  metaDataList
    .push(
      ...state.pickerData.businessViews.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.BusinessView }))
    );
  metaDataList
    .push(
      ...state.pickerData.businessTeams.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.BusinessTeam }))
    );
  metaDataList
    .push(
      ...state.pickerData.businessFunctions.selectedItems
        .map(x => x.key)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.BusinessFunction }))
    );
  metaDataList
    .push(
      ...state.mobileSites
        .map(x => x.id)
        .filter(isNumber)
        .map(id => ({ id, metaDataType: MetaDataTypes.MobileSite }))
    );

  return metaDataList
}

function createFilter(state: ISearchActionsState, metaDataList: ICustomSearchFilterMetaDataType[]): ICustomActionSearchCreateRequest {
  return {
    dueDateStart: state.dueDateStart !== undefined
      ? new Date(state.dueDateStart)
      : undefined,
    dueDateEnd: state.dueDateEnd !== undefined
      ? new Date(state.dueDateEnd)
      : undefined,
    actionItemTypeIds: state.pickerData.actionItemTypes.selectedItems
      .map(x => Number(x.key)),
    auditTypeId: Number(state.pickerData.auditTypes.selectedItems[0]?.key),
    actionRequired: state.actionRequired,
    assignedTo: state.pickerData.assignedTo.selectedItems
      .map(x => x.key.toString()),
    createdBy: state.pickerData.createdBy.selectedItems
      .map(x => x.key.toString()),
    statuses: state.statuses,
    parentTypes: state.parentTypes,
    parentId: state.parentId,
    parentName: state.parentName,
    leadAuditorsEmail: state.pickerData.leadAuditors.selectedItems
      .map(x => x.key.toString()),
    prioritiesIds: state.pickerData.priorities.selectedItems
      .map(x => Number(x.key)),
    isValidated: state.isValidated,
    creationDateStart: state.creationDateStart !== undefined
      ? new Date(state.creationDateStart)
      : undefined,
    creationDateEnd: state.creationDateEnd !== undefined
      ? new Date(state.creationDateEnd)
      : undefined,
    closureDateStart: state.closureDateStart !== undefined
      ? new Date(state.closureDateStart)
      : undefined,
    closureDateEnd: state.closureDateEnd !== undefined
      ? new Date(state.closureDateEnd)
      : undefined,
    causalFactors: state.pickerData.causalFactors.selectedItems
      .map(x => x.key.toString()),
    metaDataList: metaDataList
  }
}