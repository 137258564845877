import { formatAuditTopic } from "api/masterdata/AuditTopicApi";
import { formatBusinessFunction, formatBusinessView } from "api/masterdata/formatters/masterDataFormatters";
import { IPlanProfile, IPlanProfileAttribute, IProfileStandardMetaDataSelection } from "components/audits/planning/facility-attributes/facilityAttributeTypes";
import { isArray } from "lodash";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { parseStringToDate } from "shared/utilities/dateFormatters";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { AuditComplianceScoreIncludedQuestions, AuditSources, AuditStatuses } from "types/auditingTypes";
import { IAuditTopic } from "types/auditMasterDataTypes";
import {
  AuditPlanApprovalStatuses, ComplianceResults,
  IApprovePlanRequest, IAuditPlanScore,
  IAuditPlanView,
  ICenterAuditAttribute,
  ICreatePlanResponse,
  IPlanChildDimension,
  IPlanFiltersRequest,
  IPlanGridXAxesData,
  IPlanGridYAxesData,
  IPlanHierarchyItem,
  IPlanParentDimension,
  IPlanProfileAttributeInfo,
  IPlanProfileAttributeInfoFilterRequest,
  IPlanRequirementDimension,
  IPlanTemplateHierarchyInfo,
  IPlanXFiltersRequest,
  IPlanYFiltersRequest,
  IProfileParentChildMatrixItem,
  IRemovePlanResponse
} from "types/auditPlanningTypes";
import { IBusinessFunction, IBusinessView, MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplateChildren } from "types/templateApiTypes";

export function formatAuditPlanDimensionType(status: any): MetaDataTypes {
  if (!Object.values(MetaDataTypes).includes(status)) {
    throw Error(`Unrecognized status (${status}) while formatting Audit.`);
  }

  return (MetaDataTypes as any)[status];
}

export function formatAuditComplianceScoreIncludedQuestions(status: any): AuditComplianceScoreIncludedQuestions {
  if (!Object.values(AuditComplianceScoreIncludedQuestions).includes(status)) {
    throw Error(`Unrecognized status (${status}) while formatting Audi.`);
  }

  return (AuditComplianceScoreIncludedQuestions as any)[status];
}

export function formatPlanningXFiltersQueryString(filters: IPlanXFiltersRequest): string {
  // Convert all defined properties into query string values.
  const qsPairs: string[] = [
    `AuditGroupId=${filters.auditGroupId}`,
  ];

  if (filters.ownerGroupId) {
    qsPairs.push(`OwnerGroupId=${filters.ownerGroupId}`);
  }

  return qsPairs.join('&');
}

export function formatPlanningYFiltersQueryString(filters: IPlanYFiltersRequest): string {
  // Convert all defined properties into query string values.
  const qsPairs: string[] = [];

  qsPairs.push(`perspectiveXAxisId=${filters.perspectiveXAxisId}`);

  if (filters.divisionIds?.some(x => !!x)) {
    qsPairs.push(...filters.divisionIds.filter(x => !!x).map(x => `DivisionIds=${x}`));
  }

  if (filters.basinIds?.some(x => !!x)) {
    qsPairs.push(...filters.basinIds.filter(x => !!x).map(x => `BasinIds=${x}`));
  }

  if (filters.facilityIds?.some(x => !!x)) {
    qsPairs.push(...filters.facilityIds.filter(x => !!x).map(x => `FacilityIds=${x}`));
  }

  qsPairs.push(`IncludeEmptyBusinessTeams=${filters.includeEmptyBusinessTeams}`);

  return qsPairs.join('&');
}

export function formatPlanningFiltersQueryString(filters: IPlanFiltersRequest): string {
  // Convert all defined properties into query string values.
  const qsPairs: string[] = [
    `PlanYear=${filters.planYear}`,
    `AuditGroupId=${filters.auditGroupId}`,
    `AuditTypeId=${filters.auditTypeId}`,
  ];

  if (filters.ownerGroupId) {
    qsPairs.push(`OwnerGroupId=${filters.ownerGroupId}`);
  }

  if (filters.divisionIds?.some(x => !!x)) {
    qsPairs.push(...filters.divisionIds.filter(x => !!x).map(x => `DivisionIds=${x}`));
  }

  if (filters.basinIds?.some(x => !!x)) {
    qsPairs.push(...filters.basinIds.filter(x => !!x).map(x => `BasinIds=${x}`));
  }

  if (filters.facilityIds?.some(x => !!x)) {
    qsPairs.push(...filters.facilityIds.filter(x => !!x).map(x => `FacilityIds=${x}`));
  }

  if (filters.deletedPlans !== undefined) {
    qsPairs.push(`Deleted=${filters.deletedPlans}`);
  }

  if (filters.approvalStatus?.some(x => !!x)) {
    qsPairs.push(...filters.approvalStatus.filter(x => !!x).map(x => `ApprovalStatuses=${x}`));
  }

  if (filters.auditStatuses?.some(x => !!x)) {
    qsPairs.push(...filters.auditStatuses.filter(x => !!x).map(x => `AuditStatuses=${x}`));
  }

  if (filters.leadAuditorEmails?.some(isNotUndefined)) {
    qsPairs.push(...filters.leadAuditorEmails.filter(isNotUndefined).map(x => `LeadAuditorEmails=${x}`));
  }

  return qsPairs.join('&');
}

export function formatGetAuditPlansResponse(x: any): IAuditPlanView[] {
  const data = isArray(x)
    ? x
      .filter(x => !!x)
      .map(x => formatAuditPlanView(x))
    : [];

  return data;
}

export function formatAuditPlanView(x: any): IAuditPlanView {
  let plan: IAuditPlanView = {
    id: Number(x.id),
    parentDimensionType: x.parentDimensionType as MetaDataTypes,
    parentDimensionId: Number(x.parentDimensionId),
    childDimensionType: x.childDimensionType
      ? x.childDimensionType as MetaDataTypes
      : undefined,
    childDimensionId: x.childDimensionId
      ? Number(x.childDimensionId)
      : undefined,
    planYear: Number(x.planYear),
    requirementDimensionType: x.requirementDimensionType as MetaDataTypes,
    requirementDimensionId: Number(x.requirementDimensionId),
    auditGroupId: Number(x.auditGroupId),
    auditTypeId: Number(x.auditTypeId),
    approvalStatus: x.approvalStatus as AuditPlanApprovalStatuses,
    deleted: x.deleted === true,
    source: x.source,
    comment: x.comment,
    weekOfYear: !!x.weekOfYear
      ? Number(x.weekOfYear)
      : undefined,
    leadAuditorName: x.leadAuditorName,
    leadAuditorEmail: x.leadAuditorEmail,
    modifiedBy: x.modifiedBy?.toString() || "",
    modifiedOn: x.modifiedOn
      ? new Date(x.modifiedOn).getTime()
      : new Date().getTime(),
    modifiedByName: x.modifiedByName?.toString(),

    auditSource: x.auditSource
      ? x.auditSource as AuditSources
      : undefined,
    legacyAuditNumber: x.legacyAuditNumber?.toString(),
    auditId: !!x.auditId
      ? Number(x.auditId)
      : undefined,
    auditName: x.auditName,
    auditRecap: x.auditRecap,
    auditStatus: !!x.auditStatus
      ? x.auditStatus as AuditStatuses
      : undefined,
    auditStartDate: x.auditStartDate
      ? new Date(x.auditStartDate).getTime()
      : undefined,
    auditLeadAuditorName: x.auditLeadAuditorName,
    auditLeadAuditorEmail: x.auditLeadAuditorEmail,
    auditComplianceResult: x.auditComplianceResult as ComplianceResults,
    auditComplianceScore: !!x.auditComplianceScore
      ? Number(x.auditComplianceScore)
      : undefined,
    auditStartWeekNumber: x.auditStartWeekNumber
      ? Number(x.auditStartWeekNumber)
      : undefined,
    auditCompletionWeek: x.auditCompletionWeekNumber
      ? Number(x.auditCompletionWeekNumber)
      : undefined,
    auditClosureWeek: x.auditClosureWeekNumber
      ? Number(x.auditClosureWeekNumber)
      : undefined,
    auditCompletionDate: x.auditCompletionDate
      ? new Date(x.auditCompletionDate).getTime()
      : undefined,
    auditClosureDate: x.auditClosureDate
      ? new Date(x.auditClosureDate).getTime()
      : undefined,
    requiredCLMSelfAssessmentType: x.requiredCLMSelfAssessmentType?.toString(),
    userSelectedCLMSelfAssessmentType: x.userSelectedCLMSelfAssessmentType?.toString(),
  };

  return plan;
}

export function formatGetAuditPlanScoresResponse(x: any): IAuditPlanScore[] {
  return isArray(x)
    ? x
      .filter(x => !!x)
      .map(x => formatAuditPlanScore(x))
    : [];
}

export function formatAuditPlanScore(x: any): IAuditPlanScore {
  return {
    parentDimensionType: x.parentDimensionType as MetaDataTypes,
    parentDimensionId: Number(x.parentDimensionId),
    childDimensionType: x.childDimensionType
      ? x.childDimensionType as MetaDataTypes
      : undefined,
    childDimensionId: x.childDimensionId
      ? Number(x.childDimensionId)
      : undefined,
    planYear: Number(x.planYear),
    requirementDimensionType: x.requirementDimensionType as MetaDataTypes,
    requirementDimensionId: Number(x.requirementDimensionId),
    auditGroupId: Number(x.auditGroupId),
    auditTypeId: Number(x.auditTypeId),
    complianceScore: !x.complianceResult
      ? undefined
      : x.complianceResult as ComplianceResults,
    auditId: x.auditId
      ? Number(x.auditId)
      : undefined,
    auditDate: x.auditDate
      ? new Date(x.auditDate).getTime()
      : undefined,
    isLegacyAudit: x.isLegacyAudit !== undefined
      ? x.isLegacyAudit === true
      : undefined,
    legacyAuditReportNbr: !!x.legacyAuditReportNbr
      ? x.legacyAuditReportNbr.toString()
      : undefined,
  };
}

export function formatPlanGridXAxesResponse(x: any): IPlanGridXAxesData {
  return {
    requirementItems: isArray(x.requirementItems)
      ? x.requirementItems.map((z: any): IPlanRequirementDimension => ({
        id: Number(z.id),
        text: z.text,
        type: z.type,
        auditTopicLevel: z.auditTopicLevel,
        masterDataItem: z.masterDataItem
      }))
      : [],
  };
}

export function formatPlanGridYAxesResponse(x: any): IPlanGridYAxesData {
  return {
    hierarchyItems: isArray(x.hierarchyItems)
      ? x.hierarchyItems.map(formatPlanHierarchyItem)
      : [],
    planItemHierarchy: isArray(x.planItemHierarchy)
      ? x.planItemHierarchy.map((z: any): IPlanParentDimension => ({
        id: Number(z.id),
        text: z.text.toString(),
        type: z.type.toString() as MetaDataTypes,
        subType: z.subType?.toString(),
        subGeoUnitId: z.subGeoUnitId
          ? Number(z.subGeoUnitId)
          : undefined,
        children: isArray(z.childPlanItems)
          ? z.childPlanItems.map((y: any): IPlanChildDimension => ({
            id: Number(y.id),
            text: y.text.toString(),
            type: y.type.toString(),
            subType: y.subType?.toString(),
          }))
          : [],
      }))
      : [],
  };
}

function formatPlanHierarchyItem(item: any): IPlanHierarchyItem {
  return {
    id: Number(item.id),
    text: item.text,
    type: item.type,
    children: isArray(item.children)
      ? item.children.map(formatPlanHierarchyItem)
      : [],
  };
}

export function formatCreateAuditFromPlansRequest(planIds: number[],
  startDate: Date,
  endDate: Date,
  leadAuditor: IAzureADUser): any {
  return {
    AuditPlanIds: planIds,
    AuditStartDate: startDate,
    AuditEndDate: endDate,
    LeadAuditorName: leadAuditor.name,
    LeadAuditorEmail: leadAuditor.email,
  };
}

export function formatApprovePlansRequest(approvals: IApprovePlanRequest[]): any {
  return approvals.map(x => ({
    AuditPlanId: x.auditPlanId,
    LeadAuditorName: x.leadAuditorName,
    LeadAuditorEmail: x.leadAuditorEmail,
    WeekNumber: x.weekNumber,
  }));
}

export function formatPlanProfile(obj: any): IPlanProfile {
  return {
    id: Number(obj.id),
    parentId: obj.parentId ? Number(obj.parentId) : undefined,
    masterDataId: Number(obj.masterDataId),
    masterDataType: obj.masterDataType?.toString() || "",
    masterDataSubType: obj.masterDataSubType ? obj.masterDataSubType.toString() : undefined,
    deleted: Boolean(obj.deleted),
  };
}

export function formatPlanAttribute(obj: any): IPlanProfileAttribute {
  return {
    id: Number(obj.id),
    profileId: Number(obj.profileId),
    masterDataId: Number(obj.masterDataId),
    masterDataType: obj.masterDataType,
    deleted: Boolean(obj.deleted),
    comment: obj.comment?.toString(),
    reviewedByEmail: obj.reviewedByEmail?.toString(),
    reviewedByName: obj.reviewedByName?.toString(),
    reviewedOn: !!obj.reviewedOn
      ? parseStringToDate(obj.reviewedOn).getTime()
      : undefined,
    deletedByEmail: obj.deletedByEmail?.toString(),
    deletedByName: obj.deletedByName?.toString(),
    deletedOn: !!obj.deletedOn
      ? parseStringToDate(obj.deletedOn).getTime()
      : undefined,
  };
}

export function formatRemovePlanResponse(obj: any): IRemovePlanResponse {
  return {
    id: Number(obj.id),
    approvalStatus: obj.approvalStatus as AuditPlanApprovalStatuses,
    comment: obj.comment?.toString() || "",
    deleted: Boolean(obj.deleted),
    modifiedBy: obj.modifiedBy?.toString() || "",
    modifiedByName: obj.modifiedByName?.toString() || "",
    modifiedOn: obj.modifiedOn
      ? parseStringToDate(obj.modifiedOn).getTime()
      : new Date().getTime(),
  };
}

export function formatCreatePlanResponse(obj: any): ICreatePlanResponse {
  return {
    id: Number(obj.id),
    approvalStatus: obj.approvalStatus as AuditPlanApprovalStatuses,
    source: obj.source?.toString() || "",
    comment: obj.comment?.toString() || "",
    deleted: Boolean(obj.deleted),
    modifiedBy: obj.modifiedBy?.toString() || "",
    modifiedByName: obj.modifiedByName?.toString() || "",
    modifiedOn: obj.modifiedOn
      ? parseStringToDate(obj.modifiedOn).getTime()
      : new Date().getTime(),
  };
}

export function formatPlanProfileAttributeInfo(obj: any): IPlanProfileAttributeInfo {
  return {
    id: Number(obj.id),
    profileId: Number(obj.profileId),
    masterDataId: Number(obj.masterDataId),
    masterDataType: obj.masterDataType,
    deleted: Boolean(obj.deleted),
    comment: obj.comment?.toString(),
    reviewedByEmail: obj.reviewedByEmail?.toString(),
    reviewedByName: obj.reviewedByName?.toString(),
    reviewedOn: !!obj.reviewedOn
      ? parseStringToDate(obj.reviewedOn).getTime()
      : undefined,
    deletedByEmail: obj.deletedByEmail?.toString(),
    deletedByName: obj.deletedByName?.toString(),
    deletedOn: !!obj.deletedOn
      ? parseStringToDate(obj.deletedOn).getTime()
      : undefined,
    extraBusinessViews: obj.extraBusinessViews?.map((b: any) =>
      formatBusinessView(b)),
    extraBusinessFunctions: obj.extraBusinessFunctions?.map((b: any) =>
      formatBusinessFunction(b)),
    extraSubTopics: obj.extraSubTopics?.map((b: any) =>
      formatAuditTopic(b)),
    subTopics: obj.subTopics?.map((b: any) =>
      formatAuditTopic(b)),
    templates: obj.templates?.map((b: any) =>
      formatAuditTopic(b)),
  };
}

export function formatProfileMetaDataResponse(obj: any): Partial<IPlanProfileAttributeInfo> {
  return {
    extraBusinessViews: obj.extraBusinessViews?.map((b: any) =>
      formatBusinessView(b)),
    extraBusinessFunctions: obj.extraBusinessFunctions?.map((b: any) =>
      formatBusinessFunction(b)),
    extraSubTopics: obj.extraSubTopics?.map((b: any) =>
      formatAuditTopic(b)),
  };
}

export function formatProfileStandardTemplateSelectedItemsRequest(attributeId: number, selectedItems: IDetailedTemplateChildren[]): any {
  return {
    ProfileStandardTemplateChildSelections: selectedItems.map(x => ({
      Id: 0,
      ProfileStandardsId: attributeId,
      TemplateChildId: x.id,
      MasterDataId: x.masterDataId,
      MasterDataType: x.masterDataType,
      Deleted: false,
      IsSelected: true
    })),
    ProfileStandardsId: attributeId
  };
}

export function formatProfileStandardMetaDataResponse(obj: any): IProfileStandardMetaDataSelection {
  return {
    id: Number(obj.id),
    profileStandardsId: Number(obj.ProfileStandardsId),
    masterDataId: Number(obj.masterDataId),
    masterDataType: obj.masterDataType,
    deleted: Boolean(obj.deleted),
  };
}

export function formatProfileMetaDataSelectedItems(profileStandardsId: number, type: MetaDataTypes, selectedItems: (IBusinessView | IBusinessFunction | IAuditTopic)[]): IProfileStandardMetaDataSelection[] {
  return selectedItems.map(x => ({
    id: 0,
    profileStandardsId: profileStandardsId,
    masterDataId: x.id,
    masterDataType: type,
    deleted: x.isDeleted,
  }));
}

export function formatProfileMetaDataSelectedItemsRequest(attributeId: number, selectedItems: IProfileStandardMetaDataSelection[]): any {
  return {
    ProfileStandardMetaDataSelections: selectedItems.map(x => ({
      Id: x.id,
      ProfileStandardsId: x.profileStandardsId,
      MasterDataId: x.masterDataId,
      MasterDataType: x.masterDataType,
      Deleted: x.deleted,
    })),
    ProfileStandardsId: attributeId
  };
}

export function formatGetPlanTemplateHierarchyInfoResponse(obj: any) {
  return formatPlanTemplateHierarchyInfo(obj);
}

export function formatPlanTemplateHierarchyInfo(obj: any): IPlanTemplateHierarchyInfo {
  return {
    planId: Number(obj.planId),
    selectedProfileStandardIds: obj.selectedProfileStandardIds?.map((b: any) =>
      Number(b)) || [],
    extraBusinessViews: obj.extraBusinessViews?.map((b: any) =>
      formatBusinessView(b)) || [],
    extraBusinessFunctions: obj.extraBusinessFunctions?.map((b: any) =>
      formatBusinessFunction(b)) || [],
    extraSubTopics: obj.extraSubTopics?.map((b: any) =>
      formatAuditTopic(b)) || [],
  };
}

export function formatGetDefaultProfileStandardMetaData(profileIds: number[]) {
  const qsPairs: string[] = [];

  if (profileIds.some(x => !!x)) {
    qsPairs.push(...profileIds.filter(x => !!x).map(x => `ProfileIds=${x}`));
  }

  return qsPairs.join('&');
}

export function formatPlanProfileAttributeInfoFilterRequest(filters: IPlanProfileAttributeInfoFilterRequest): string {
  // Convert all defined properties into query string values.
  const qsPairs: string[] = [
  ];

  if (filters.includeBusinessViews) {
    qsPairs.push(`includeBusinessViews=true`);
  }
  if (filters.includeBusinessFunctions) {
    qsPairs.push(`includeBusinessFunctions=true`);
  }
  if (filters.includeSubTopics) {
    qsPairs.push(`includeSubTopics=true`);
  }
  return qsPairs.join('&');
}

export function formatProfileParentChildMatrixItems(responseObj: any): IProfileParentChildMatrixItem[] {
  return Array.isArray(responseObj?.value)
    ? responseObj?.value.map((obj: any): IProfileParentChildMatrixItem => ({
      id: Number(obj.id),
      parentType: obj.parentType?.toString() || "",
      parentSubType: obj.parentSubType?.toString(),
      childType: obj.childType?.toString() || "",
      childSubType: obj.childSubType?.toString(),
    })) : [];
}

export function formatGetCenterAuditAttributes(responseObj: any): ICenterAuditAttribute[] {
  return Array.isArray(responseObj)
    ? responseObj
      .map((obj: any): ICenterAuditAttribute => ({
        profileId: 0,
        profileStandardId: 0,
        masterDataId: 0,
        masterDataType: "",
        topicId: Number(obj.topicId),
        clmDocRefName: obj.clmDocRefName.toString() || "",
        clmDocRefDms: obj.clmDocRefDms?.toString() || "",
        auditPackage: obj.auditPackage.toString() || "",
        applicable: obj.applicable,
        implemented: obj.implemented,
        exempted: obj.exempted,
        questExemption: obj.questExemption?.toString() || "",
        comments: obj.comments?.toString() || ""
      })) : [];
}