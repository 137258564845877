import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import pickerHandlers from "shared/store/picker/pickerReducerHandlers";
import { IOperation } from "shared/types/operationTypes";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { IFinding } from "store/audit/reducers/findingReducers";
import { IAnswer, IAuditTopic, ICausalFactor, IVerificationMethod } from "types/auditMasterDataTypes";
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";

export enum AfterSaveActions {
  "next",
  "prev",
  "exit",
  "filters",
}

export interface IAuditExecutionState {
  /**
   * The current queue of Audit Question Ids the user is iterating through.
   */
  queueAQIds: number[],
  /**
   * The current index of the queueAQIds. If no queue, then -1.
   */
  currQueueIndex: number,
  pickerData: {
    auditees: IPickerState<IAzureADUser>,
    answerFilter: IPickerState<IAnswer>,
    topicFilter: IPickerState<IAuditTopic>,
    auditorFilter: IPickerState<IAzureADUser>,
    auditeeFilter: IPickerState<IAzureADUser>,
  },
  /**
   * Properties related to the filter modal.
   */
  filterModal: {
    /**
     * Determines filter modal visibility.
     */
    isOpen: boolean,
    questionTextFilter: string,
    questionNumberFilter: string,
  },
  /** The currently focused workspace data. */
  workspace?: IExecutionWorkspace,
  /** The operation representing saving the workspace to the api. */
  saveWorkspaceOp?: IOperation<void>,
  /** Determines if the Clear Response window is open or not. */
  isClearingConfirmationOpen: boolean,
  /** Control the state of the finding modal */
}

export interface IExecutionWorkspace {
  auditQuestionId: number,
  current?: IExecutionWorkspaceData,
  original?: IExecutionWorkspaceData,
  isDirty: boolean,
}

export interface IExecutionWorkspaceData {
  answer?: string,
  causalFactor?: ICausalFactor,
  notes?: string,
  selectedVerificationMethods?: IVerificationMethod[],
  interviewees?: IAzureADUser[],
  finding?: IFinding,
}

export enum AuditExecutionPickerKeys {
  causalFactors = "causalFactors",
  auditees = "auditees",
  answerFilter = "answerFilter",
  topicFilter = "topicFilter",
  auditorFilter = "auditorFilter",
  auditeeFilter = "auditeeFilter",
}

const initialState: IAuditExecutionState = {
  queueAQIds: [],
  currQueueIndex: -1,
  pickerData: {
    auditees: {
      key: AuditExecutionPickerKeys.auditees,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    answerFilter: {
      key: AuditExecutionPickerKeys.answerFilter,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    topicFilter: {
      key: AuditExecutionPickerKeys.topicFilter,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    auditorFilter: {
      key: AuditExecutionPickerKeys.auditorFilter,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    auditeeFilter: {
      key: AuditExecutionPickerKeys.auditeeFilter,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
  },
  filterModal: {
    isOpen: false,
    questionTextFilter: "",
    questionNumberFilter: "",
  },
  workspace: undefined,
  saveWorkspaceOp: undefined,
  isClearingConfirmationOpen: false,
};

export const auditExecutionSlice = createSlice({
  name: "audit-execution",
  initialState,
  reducers: {
    ...pickerHandlers,
    /** Advances or reverses along the queue the specified number of items. */
    traverseQueue: (state, action: PayloadAction<number>) => {
      if (!state.queueAQIds.length) {
        state.currQueueIndex = -1;
        return;
      }

      state.currQueueIndex = (state.currQueueIndex + action.payload) % state.queueAQIds.length;

      if (state.currQueueIndex < 0) {
        state.currQueueIndex = state.queueAQIds.length - 1;
      }
    },

    /** Sets the current execution workspace data. */
    setWorkspace: (state, action: PayloadAction<IExecutionWorkspace | undefined>) => {
      state.workspace = action.payload;

      if (state.workspace) {
        state.workspace.isDirty = !isEqual(state.workspace.current, state.workspace.original);
      }
    },

    /** If a workspace is currently loaded, set the current workspace data. */
    setCurrentWorkspace: (state, action: PayloadAction<Partial<IExecutionWorkspaceData>>) => {
      if (!state.workspace?.current) {
        return;
      }

      Object.assign(state.workspace.current, action.payload);
      state.workspace.isDirty = !isEqual(state.workspace.current, state.workspace.original);
    },

    /** Saves the specified workspace data to the api. */
    saveWorkspace: (state, _: PayloadAction<{
      workspace: IExecutionWorkspace,
      afterSaveAction: AfterSaveActions,
    }>) => {
      state.saveWorkspaceOp = {
        isWorking: true,
      };
    },

    /** Finishes saving, removes the save operation, updates the workspace with the new data. */
    finishSaveWorkspace: (state, action: PayloadAction<IOperation<IExecutionWorkspace>>) => {
      if (!action.payload.errorMessage) {
        state.workspace = action.payload.data;
      }

      state.saveWorkspaceOp = undefined;
      if (state.workspace) {
        state.workspace.isDirty = !isEqual(state.workspace.current, state.workspace.original);
      }
    },

    /** Sets the visibility of the Clear Response modal. */
    toggleClearingConfirmation: (state, action: PayloadAction<boolean>) => {
      state.isClearingConfirmationOpen = action.payload;
    },

    /** Starts deleting question response on the api via a Saga. */
    deleteWorkspaceResponse: (state, _: PayloadAction<{
      auditId: number,
      auditQuestionId: number,
      workspace: IExecutionWorkspace,
      findingToDelete: IFinding | undefined,
    }>) => {
      state.saveWorkspaceOp = {
        isWorking: true,
      };
    },

    clearWorkspace: (state) => {
      state.workspace = undefined;
    },

    openFilters: state => {
      state.filterModal.isOpen = true;
      state.filterModal.questionNumberFilter = "";
      state.filterModal.questionTextFilter = "";
      state.pickerData.topicFilter.selectedItems = [];
      state.pickerData.answerFilter.selectedItems = [];
      state.pickerData.auditeeFilter.selectedItems = [];
      state.pickerData.auditorFilter.selectedItems = [];
    },
    closeFilters: (state) => {
      state.filterModal.isOpen = false;
    },
    setFilterQuestionNumText: (state, action: PayloadAction<string>) => {
      state.filterModal.questionNumberFilter = action.payload;
    },
    setFilterQuestionText: (state, action: PayloadAction<string>) => {
      state.filterModal.questionTextFilter = action.payload;
    },
    goToQuestion: (state, action: PayloadAction<{
      auditQuestionId: number,
      aqIdQueue: number[],
    }>) => {
      state.queueAQIds = action.payload.aqIdQueue;
      state.filterModal.isOpen = false;

      state.currQueueIndex = state.queueAQIds.indexOf(action.payload.auditQuestionId);

      if (state.currQueueIndex === -1) {
        state.currQueueIndex = 0;
      }
    },
    applyFilters: (state, action: PayloadAction<number[]>) => {
      state.queueAQIds = action.payload;
      state.currQueueIndex = 0;
      state.filterModal.isOpen = false;
      state.workspace = undefined;
    },
    setQueueAndIndex: (state, action: PayloadAction<{
      queueAQIds: number[],
      currQueueIndex: number,
    }>) => {
      state.queueAQIds = action.payload.queueAQIds;
      state.currQueueIndex = action.payload.currQueueIndex;
    },
    updateAnswerFilterOptions: (state, action: PayloadAction<{
      questionsInAudit: IAuditQuestion[],
      auditStatus: AuditStatuses | undefined,
      allAnswers: IAnswer[],
    }>) => {
      const {
        questionsInAudit,
        auditStatus,
        allAnswers,
      } = action.payload;

      // Get the distinct list of submitted answers inside this audit for its current status.
      const usedAnswerCodes = Array.from(new Set(
        questionsInAudit.reduce((acc, curr) => {
          const questionResponse = curr.responses.find(x => x.auditStatus === auditStatus);
          if (questionResponse) {
            acc.push(questionResponse.answer);
          }
          return acc;
        }, [] as string[])));

      state.pickerData.answerFilter.items = usedAnswerCodes
        .map((answerCode): IPickerItem<IAnswer> => {
          const answer = allAnswers.find(x => x.code === answerCode);

          return {
            text: answer?.name ?? answerCode,
            key: answerCode,
            item: answer,
          };
        });
    },

    // Finding
    setFindingToDelete: (state, action: PayloadAction<IFinding>) => {
      if(state.workspace?.current !== undefined) {
        state.workspace.current.finding = undefined;
      }
    }
}});

export const {
  traverseQueue,
  setWorkspace,
  setCurrentWorkspace,
  saveWorkspace,
  finishSaveWorkspace,
  openFilters,
  closeFilters,
  setFilterQuestionNumText,
  setFilterQuestionText,
  goToQuestion,
  applyFilters,
  setQueueAndIndex,
  toggleClearingConfirmation,
  deleteWorkspaceResponse,
  clearWorkspace,

  // Picker handlers
  openPicker,
  closePicker,
  loadPickerItems,
  setPickerError,
  setPickerItems,
  setPickerState,
  setSelectedPickerItems,
  expandPickerItem,
  collapsePickerItem,
  loadSuggestedPickerItems,
  setSuggestedPickerItems,
  updateAnswerFilterOptions,

  // Findings
  setFindingToDelete,
} = auditExecutionSlice.actions;
