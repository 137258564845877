import React from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import formatDate from "shared/utilities/dateFormatters";
import { userToString } from "shared/utilities/userUtilities";
import { ComplianceResults, IAuditPlanScore, IAuditPlanView } from "types/auditPlanningTypes";
import "./GridCellTooltip.scoped.scss";

interface IGridCellTooltipProps {
  score?: IAuditPlanScore,
  plan?: IAuditPlanView,
  statusText?: string,
  /** An optional props to pass children */
  children?: React.ReactNode,
}

const GridCellTooltip: React.FC<IGridCellTooltipProps> = ({
  score,
  plan,
  statusText,
  children,
}) =>
  <FlexCol
    className="cell-tooltip"
  >
    <span
      className="title"
    >
      Audit History
    </span>

    {score
      ? (
        <>
          {outputLine("Compliance", score.complianceScore?.replace(/([A-Z])/g, " $1").trim() || "Missing")}

          {!!score.auditDate && outputLine("Date", formatDate(new Date(score.auditDate)))}

          {!!score.auditId && outputLine("Audit #", (score.isLegacyAudit
            && score.legacyAuditReportNbr
            ? score.legacyAuditReportNbr
            : score.auditId) + (score.isLegacyAudit
              ? " (Legacy Quest)"
              : ""))}
        </>
      ) : (
        <span
          className="compliance-score"
        >
          Not Required By Facility Attributes
        </span>
      )}

    <hr />

    <span
      className="title"
    >
      Audit Plan
    </span>

    {plan
      ? getPlanInfo(plan, statusText)
      : (
        <span
          className="status"
        >
          {score?.complianceScore === ComplianceResults.FullCompliance ? (
            <>Not Required</>
          ) : (
            <>Missing</>
          )}
        </span>
      )}
    {children}
  </FlexCol>

export default GridCellTooltip;

function getPlanInfo(plan: IAuditPlanView, statusText: string | undefined): React.ReactNode {
  return (
    <>
      {outputLine("Status", statusText)}

      {!!plan.leadAuditorName && outputLine("Lead Auditor", plan.leadAuditorName)}

      {!!plan.weekOfYear && (
        outputLine("Planned Week", plan.weekOfYear?.toString())
      )}

      {!plan.deleted
        && plan.source === "Manual"
        && (
          <>
            {outputLine("Reason for addition", plan.comment || "--")}
            {outputLine("Added by", userToString({ email: plan.modifiedBy, name: plan.modifiedByName }))}
            {outputLine("Added on", formatDate(new Date(plan.modifiedOn)))}
          </>
        )}

      {plan.deleted && (
        <>
          {outputLine("Reason for removal", plan.comment || "--")}
          {outputLine("Removed by", userToString({ email: plan.modifiedBy, name: plan.modifiedByName }))}
          {outputLine("Removed on", formatDate(new Date(plan.modifiedOn)))}
        </>
      )}
    </>
  );
}


function outputLine(label: string, value?: string) {
  return (
    <span>
      <label>{label}:</label> <span className="value">{value || "--"}</span>
    </span>
  );
}