import { IProfile, IProfileMetaData, IRestrictedMetaData, ISaveProfileMetaDataRequest, ISaveProfileRequest, IUserProfile } from "shared/types/userProfileTypes";

export function formatUserProfiles(obj: any): IUserProfile {
  return {
    id: obj?.userProfile?.activeProfile?.id,
    user: {
      name: obj?.userProfile?.user.employeeName,
      email: obj?.userProfile?.user.email,
      jobTitle: obj?.userProfile?.user.jobTitle,
      photo: obj?.userProfile?.user.photoURL ? obj?.user.photoURL : undefined,
    },
    activeProfile: {
      profileId: obj?.userProfile?.activeProfile?.id,
      moduleName: obj?.userProfile?.activeProfile?.moduleName,
    },
    profiles: formatProfiles(obj?.userProfile?.profiles),
    derivedMetaRestrictions: Array.isArray(obj?.derivedMetaRestrictions)
      ? obj.derivedMetaRestrictions.map((x: any): IRestrictedMetaData => ({
        id: Number(x?.id),
        type: x?.type?.toString() || "",
      }))
      : [],
  };
};

export function formatUserProfileWithoutDerivedMetaData(obj: any): IUserProfile {
  return {
    id: obj?.activeProfile?.id,
    user: {
      name: obj?.user.employeeName,
      email: obj?.user.email,
      jobTitle: obj?.user.jobTitle,
      photo: obj?.user.photoURL ? obj?.userProfile?.user.photoURL : undefined,
    },
    activeProfile: {
      profileId: obj?.activeProfile?.id,
      moduleName: obj?.activeProfile?.moduleName,
    },
    profiles: formatProfiles(obj?.profiles),
    derivedMetaRestrictions: [],
  };
};

function formatProfiles(obj: any): IProfile[] {
  return obj?.map((profile: any) => {
    return formatProfile(profile);
  });
};

export function formatProfile(obj: any): IProfile {
  return {
    id: obj?.id,
    userId: obj?.userId,
    module: {
      name: obj?.moduleName,
      description: obj?.moduleDesc,
    },
    role: {
      name: obj?.roleName,
      description: obj?.roleDesc,
    },
    profileMetaData: formatProfileMetaData(obj?.profileMetaData)
  }
}

function formatProfileMetaData(obj: any): IProfileMetaData[] {
  return obj?.map((profileMetaData: any) => {
    return {
      type: profileMetaData?.type,
      description: profileMetaData?.description,
      masterDataId: profileMetaData?.masterDataId,
      masterDataCode: profileMetaData?.masterDataCode,
      masterDataName: profileMetaData?.masterDataName,
    }
  });
}

export function formatSaveProfileRequest(obj: any): ISaveProfileRequest {
  return {
    module: {
      name: obj?.module.name,
      description: obj?.module.description,
    },
    role: {
      name: obj?.role.name,
      description: obj?.role.description,
    },
    profileMetaData: formatSaveProfileMetaDataRequest(obj?.profileMetaData)
  }
}

function formatSaveProfileMetaDataRequest(obj: any): ISaveProfileMetaDataRequest[] {
  return obj?.map((profileMetaData: any) => {
    return {
      type: profileMetaData?.type,
      masterDataId: profileMetaData?.masterDataId,
    }
  });
}