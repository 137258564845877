declare global {
  interface Window {
    __settings: {
      authConfig: {
        tenantId: string,
        clientId: string,
        redirectUri: string,
        appScope: string,
      },
      endpoints: {
        baseUrl: string,
        auditMasterData: {
          auditTypes: string,
          auditGroups: string,
          auditTopics: string,
          reorderAuditTopics: string,
          suggestedAuditTopics: string,
          causalFactors: string,
          help: string,
          verificationMethods: string,
          hseSubElements: string,
          qmsElements: string,
        },
        auditPlanning: {
          plans: string,
          planScores: string,
          approvePlans: string,
          gridXAxis: string,
          gridYAxis: string,
          createAuditFromPlans: string,
          planProfiles: string,
          planAttributes: string,
          auditPlanningConfig: string
          planCalendarItems: string,
          planCalendarResources: string,
          planCalendarLeadAuditors: string,
          planCalendarItemExtraInfo: string,
          updatePlanCalendarItem: string,
          profileStandardTemplateSelection: string,
          profileStandardTemplateSelectionView: string,
          profileStandardMetaData: string,
          profileParentChildMatrixItems: string,
          centerAuditAttributes: string,
        },
        masterData: {
          businessViews: string,
          suggestedBusinessViews: string,
          businessFunctions: string,
          suggestedBusinessFunctions: string,
          businessTeams: string,
          suggestedBusinessTeams: string,
          countries: string,
          suggestedCountries: string,
          facilities: string,
          suggestedFacilities: string,
          actionItemPriorities: string,
          actionItemTypes: string,
          calendarWeeks: string,
          mobileSites: string,
          suggestedMobileSites: string,
        },
        auditing: {
          audits: string,
          auditInfo: string,
          auditees: string,
          startAudit: string,
          completeAudit: string,
          closeAudit: string,
          templates: string,
          ownerGroups: string,
          myAudits: string,
          questionEvidence: string,
          questionResponse: string,
          questionHistory: string,
          exportAudit: string,
          nonConformances: string,
          auditReport: string
          auditEvidences: string,
          uploadAttachment: string,
          uploadLink: string,
          auditAttachment: string,
          auditLink: string,
          rwpData: string,
          auditAllImagesEvidences: string,
          auditGenerateReport: string,
          auditComplianceScores: string,
          getAllActionItemsInAudit: string,
          getSuggestedInterviewees: string,
          notifyTeam: string,
          searchAudits: string,
        },
        users: {
          searchAzureAD: string,
          searchAuditors: string,
          suggestedAuditors: string,
        },
        actionItems: {
          actionItems: string,
          actionItemEvidenceCollection: string,
          actionItemEvidenceFile: string,
          getActionItemEvidenceFileBlobUrl: string,
          search: string,
          comments: string,
          validate: string,
          myActions: string,
          searchActions: string,
          actionItemEvidenceLink: string,
          actionItemAccess: string
        },
        security: {
          userProfile: string,
        },
        legacyQuest: {
          audit: string,
          legacyNodeMappings: string,
          checklistMappings: string,
        },
        customSearch: {
          getCustomSearchList: string,
          getAuditFilters: string,
          saveAuditFilters: string,
          updateAuditFilters: string,
          getActionFilters: string,
          saveActionFilters: string,
          updateActionFilters: string,
          deleteFilters: string,
        }
        admin: {
          planningAdministration: {
            planningPeriods: string,
            planningConfig: string,
          },
        },
      },
      executionConfig: {
        noAnswerRequiresActionItem: boolean,
      },
      serviceWorker: {
        isEnabled: boolean,
      },
      appInsights: {
        connectionString: string,
      },
      appInfo: {
        buildNumber: string,
        env: string,
      },
      licenseKeys: {
        wijmo: string,
      },
      moduleName: string,
      userSentimentSurvey: {
        scope: string,
        secretKey: string,
        checkFeedbackUrl: string,
      },
      auditPlanningValidatePlanYear: {
        validationPlanYearEnabled: boolean,
      },
      prodUrl: string,
      powerBiUrl: string,
    },
  }
}

const config = window.__settings || {};

// Parse the values that are not strings.
config.serviceWorker.isEnabled = parseAsBoolean(config.serviceWorker.isEnabled, "serviceWorker.isEnabled");
config.auditPlanningValidatePlanYear.validationPlanYearEnabled = parseAsBoolean(config.auditPlanningValidatePlanYear.validationPlanYearEnabled, "auditPlanningValidatePlanYear.validationPlanYearEnabled");

// Use the recursive validateObjNoPlaceholders to get the names of all the settings
// that are placeholders and are missing from local.settings.json.
let invalidPlaceholders: string[] = [];
validateObjNoPlaceholders(config, 'config', invalidPlaceholders);
if (invalidPlaceholders.length) {
  throw new Error(`The setting(s) named ${JSON.stringify(invalidPlaceholders)} in settings.json are placeholders and must be overridden in local.settings.json.`);
}

function validateObjNoPlaceholders(obj: any, keyPath: string, badPropNames: string[]) {
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj)
      .forEach(key => {
        validateObjNoPlaceholders(obj[key], keyPath + `.${key}`, badPropNames);
      });
  } else if (obj !== null
    && typeof obj === "string"
    && obj.toString().indexOf('#{') === 0) {
    badPropNames.push(keyPath);
  }
}

function parseAsBoolean(value: any, settingName: string) {
  if (value === ""
    || value === undefined
    || value === null) {
    throw new Error(`The setting named '${settingName}' is empty, null, or undefined. Please check settings.json and your local.settings.json file.`);
  }

  return value.toLowerCase() === "true";
}

export default config;
