import { authGetJson } from "auth/authFetches";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IAllAuditScoreResults } from "types/audit-scores/auditScoreTypes";
import { formatGetAuditScoresOptionsToUrl, formatGetAuditScoresResponse } from "./formatters/auditScoreFormatters";
import { IGetAuditScoresOptions } from "./types/auditScoresApiTypes";

class AuditScoresApi {
  public async getAuditScores(options: IGetAuditScoresOptions,
    abortSignal?: AbortSignal): Promise<IAllAuditScoreResults> {
    const response = await authGetJson(formatGetAuditScoresOptionsToUrl(options),
      abortSignal);

    await throwIfResponseError(response);

    const respObj = await response.json();

    return formatGetAuditScoresResponse(respObj);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditScoresApi();
