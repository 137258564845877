import React from "react";
import { useDispatch } from "react-redux";
import DatePicker from "shared/components/controls/date-picker/DatePicker";
import Card from "shared/components/layout/card/Card";
import { setAutoDate } from "store/admin/planning-administration/facility-attributes/AdminFacilityAttributesSlice";
import { useAppSelector } from "store/store";
import IAdminPlanPeriodExtraCard from "../IAdminPlanPeriodExtraCard";
import "./PlanAutoDateCard.scoped.scss";

interface IPlanAutoDateCardProps extends IAdminPlanPeriodExtraCard { }

const PlanAutoDateCard: React.FC<IPlanAutoDateCardProps> = ({
  hasEditPermission,
}) => {
  const dispatch = useDispatch();

  const autoDate = useAppSelector(store => store.adminFacilityAttributes.autoDate);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <Card
      title="Audit Plan Auto Push Date"
    >
      <div
        className="auto-date-row"
      >
        <span
          className="description"
        >
          All recommended but unapproved and non-removed Audit Plan Requirements will be pushed to the Annual Audit Plan on the Date:
        </span>

        <DatePicker
          customCssClass="calendar"
          value={autoDate}
          minDate={new Date(currentYear, 0, 1)}
          maxDate={new Date(currentYear, 11, 31)}
          isDisabled={!hasEditPermission}
          dateFormat="MM/dd"
          fixedHeight
          onChange={(date) => {
            if (!date) {
              return;
            }

            const dateValue = Array.isArray(date)
              && date[0]
              ? date[0]
              : (!Array.isArray(date)
                && date
                ? date
                : null);

            if (!dateValue) {
              return;
            }

            dispatch(setAutoDate(dateValue.getTime()));
          }}
        />
      </div>
    </Card>
  );
};

export default PlanAutoDateCard;