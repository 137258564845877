import { Action } from "@reduxjs/toolkit";
import AuditsApi from "api/auditing/AuditsApi";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IAuditEvidenceResponse } from "types/auditingTypes";
import { IAuditEvidencesState, IFinishLoadingLinkPayload, IFinishUploadingAttachmentPayload, appendUploadedAttachment, deleteAttachment, deleteLink, finishDeletingAttachment, finishDeletingLink, finishLoadingAuditEvidences, finishUpdatingAttachment, finishUpdatingLink, finishUploadingAttachments, finishUploadingLink, finishViewingAuditEvidence, loadAttachment, loadAuditEvidences, setLoadFileData, updateAttachment, updateLink, uploadAttachment, uploadLink, viewAuditEvidence } from "./AuditEvidencesSlice";


export function* auditEvidencesSaga() {
  yield all([
    uploadAttachmentAsync(),
    uploadLinkAsync(),
    updateLinkAsync(),
    updateAttachmentAsync(),
    watchLoadAuditEvidences(),
    deleteAttachmentAsync(),
    deleteLinkAsync(),
    loadAttachmentAsync(),
    viewEvidenceAsync(),
  ]);
}

function* watchLoadAuditEvidences() {
  yield takeLatest(loadAuditEvidences, loadAuditEvidencesAsync);
}

function* loadAuditEvidencesAsync(action: Action) {
  if (!loadAuditEvidences.match(action)) {
    return;
  }

  try {
    const evidences: IAuditEvidenceResponse = yield call(AuditsApi.getAuditEvidences,
      action.payload.id);
    yield put(finishLoadingAuditEvidences({
      isWorking: false,
      data: evidences,
    }));
  } catch (err: any) {
    // Get error message.
    yield put(finishLoadingAuditEvidences({
      isWorking: false,
      errorMessage: getResponseErrorMessage(err),
    }));
  }
}

function* uploadAttachmentAsync() {
  yield takeEvery(uploadAttachment, function* (action: Action) {
    if (!uploadAttachment.match(action)) {
      return;
    }

    const state: IAuditEvidencesState = yield select((store: RootState) => store.auditEvidences);

    try {
      for (let i = 0; i < action.payload.files.length; i++) {
        const file = action.payload.files.item(i);

        if (!file) {
          continue;
        }

        const createdEvidence: IFinishUploadingAttachmentPayload = yield call(AuditsApi.uploadAuditAttachment,
          action.payload.auditId,
          action.payload.description,
          file);

        yield put(showSuccessToast("Attachment created successfully."));

        // Save this result in the attachment list.
        yield put(appendUploadedAttachment({
          auditId: state.auditId,
          id: createdEvidence.id,
          description: createdEvidence.notes,
          filename: createdEvidence.filename,
          parent: createdEvidence.parent,
          size: createdEvidence.size,
          uploadDate: new Date(),
          uploadedBy: createdEvidence.createdBy,
          url: createdEvidence.url,
          parentId: createdEvidence.parentid,
          auditStatus: state.auditStatus,
        }));
      }
    } catch (err) {
      yield put(showErrorToast(`Failed to upload attachment: ${getResponseErrorMessage(err)}`));
      yield put(finishUploadingAttachments({ closeModal: false }));
      return;
    }

    yield put(finishUploadingAttachments({ closeModal: true }));
  });
}

function* uploadLinkAsync() {
  yield takeEvery(uploadLink, function* (action: Action) {
    if (!uploadLink.match(action)) {
      return;
    }

    const state: IAuditEvidencesState = yield select((store: RootState) => store.auditEvidences);

    try {
      const createdLink: IFinishLoadingLinkPayload = yield call(AuditsApi.uploadAuditLink,
        action.payload.auditId,
        action.payload.description,
        action.payload.link);

      yield put(showSuccessToast("Link created successfully."));

      yield put(finishUploadingLink({
        isWorking: false,
        data: {
          auditId: state.auditId,
          auditStatus: state.auditStatus,
          id: createdLink.id,
          description: createdLink.notes,
          url: createdLink.link,
          uploadDate: createdLink.modifiedOn,
          uploadedBy: createdLink.createdBy,
          parent: 'Audit',
          type: 'Audit',
        },
      }));

    } catch (err) {
      yield put(showErrorToast(`Failed to upload link: ${getResponseErrorMessage(err)}`));

      yield put(finishUploadingLink({
        isWorking: false,
        errorMessage: `Failed to upload link: ${getResponseErrorMessage(err)}`,
      }));
    }
  });
}

function* updateLinkAsync() {
  yield takeEvery(updateLink, function* (action: Action) {
    if (!updateLink.match(action)) {
      return;
    }

    try {
      let auditId: number = action.payload.auditId;

      if (action.payload.type === "Audit") {
        yield call(AuditsApi.updateAuditLink,
          action.payload.auditId,
          action.payload.description,
          action.payload.linkId,
          action.payload.link);
      } else if (action.payload.type === "Question") {
        yield call(AuditsApi.saveQuestionLink,
          action.payload.id,
          action.payload.auditId,
          action.payload.auditQuestionId!,
          action.payload.description,
          action.payload.link);
      }

      yield put(showSuccessToast("Link updated successfully."));

      yield put(finishUpdatingLink({
        isWorking: false,
        data: {
          id: action.payload.id,
          auditId: auditId,
          description: action.payload.description,
          link: action.payload.link,
          linkId: action.payload.linkId,
          type: action.payload.type,
        },
      }));

    } catch (err) {
      yield put(showErrorToast(`Failed to upload link: ${getResponseErrorMessage(err)}`));

      yield put(finishUpdatingLink({
        isWorking: false,
        errorMessage: `Failed to update link: ${getResponseErrorMessage(err)}`,
      }));
    }
  });
}

function* updateAttachmentAsync() {
  yield takeEvery(updateAttachment, function* (action: Action) {
    if (!updateAttachment.match(action)) {
      return;
    }

    const state: IAuditEvidencesState = yield select((store: RootState) => store.auditEvidences);

    try {
      const updatedAttachment: IFinishUploadingAttachmentPayload = yield call(AuditsApi.updateAuditAttachment,
        action.payload.auditId,
        action.payload.id,
        action.payload.parentId,
        action.payload.description,
        action.payload.filename);

      yield put(showSuccessToast("Attachment updated successfully."));

      yield put(finishUpdatingAttachment({
        isWorking: false,
        data: {
          id: updatedAttachment.id,
          description: updatedAttachment.notes,
          filename: updatedAttachment.filename,
          parent: "",
          auditId: state.auditId,
          auditStatus: state.auditStatus,
        },
      }));

    } catch (err) {
      yield put(showErrorToast(`Failed to upload attachment: ${getResponseErrorMessage(err)}`));

      yield put(finishUpdatingAttachment({
        isWorking: false,
        errorMessage: `Failed to update attachment: ${getResponseErrorMessage(err)}`,
      }));
    }
  });
}

function* deleteAttachmentAsync() {
  yield takeEvery(deleteAttachment, function* (action: Action) {
    if (!deleteAttachment.match(action)) {
      return;
    }

    try {
      // Send the deletion to the server.
      yield call(AuditsApi.deleteAuditAttachment,
        action.payload.auditId,
        action.payload.id,
        action.payload.parentId
      );

      // Then remove it from attachment list.
      yield put(finishDeletingAttachment({
        isWorking: false,
        data: action.payload,
      }));


      yield put(showSuccessToast("Attachment deleted successfully."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

function* deleteLinkAsync() {
  yield takeEvery(deleteLink, function* (action: Action) {
    if (!deleteLink.match(action)) {
      return;
    }

    try {
      if (action.payload.type === "Audit") {
        // Send the deletion to the server.
        yield call(AuditsApi.deleteAuditLink,
          action.payload.auditId,
          action.payload.itemId);
      } else {
        yield call(AuditsApi.deleteQuestionLink,
          action.payload.itemId)
      }

      // Then remove it from link list.
      yield put(finishDeletingLink({
        isWorking: false,
        data: action.payload,
      }));


      yield put(showSuccessToast("Link deleted successfully."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

function* loadAttachmentAsync() {
  yield takeEvery(loadAttachment, function* (action) {
    if (!loadAttachment.match(action)) {
      return;
    }

    const {
      auditId,
      auditQuestionId,
      evidenceId,
    } = action.payload;

    try {
      let url: string | undefined = undefined;

      if (auditQuestionId) {
        // Load question evidence url.
        url = yield call(AuditsApi.getQuestionEvidenceUrl, auditId, auditQuestionId, evidenceId);
      } else {
        // Load audit evidence url.
        url = yield call(AuditsApi.getAuditEvidenceUrl, auditId, evidenceId);
      }

      window.open(url);
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      yield put(setLoadFileData(undefined));
    }
  });
}

function* viewEvidenceAsync() {
  yield takeEvery(viewAuditEvidence, function* (action: Action) {
    if (!viewAuditEvidence.match(action)) {
      return;
    }

    try {
      // Try to get the url from the server.
      const url: string = yield call(AuditsApi.getAuditEvidenceUrl,
        action.payload.auditId,
        action.payload.evidenceId);

      // Then open a window to show it.
      window.open(url, "_blank");
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      yield put(finishViewingAuditEvidence());
    }
  });
}