import React from "react";
import { getGroupedBusinessFunctions } from "shared/utilities/businessFunctionUtilities";
import { getGroupedBusinessTeams } from "shared/utilities/businessTeamUtilities";
import { getGroupedBusinessViews } from "shared/utilities/businessViewUtilities";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICountry } from "types/masterDataTypes";

interface IQuestionAssociationsModalProps {
  countries?: ICountry[],
  businessFunctions?: IBusinessFunction[],
  businessTeams?: IBusinessTeam[],
  businessViews?: IBusinessView[],
  subTopics?: IAuditTopic[],
}

const QuestionAssociationsModal: React.FC<IQuestionAssociationsModalProps> = ({
  countries,
  businessFunctions,
  businessTeams,
  businessViews,
  subTopics,
}: IQuestionAssociationsModalProps) => {
  const groupedBFs = getGroupedBusinessFunctions(businessFunctions);
  const groupedBVs = getGroupedBusinessViews(businessViews);
  const groupedBTs = getGroupedBusinessTeams(businessTeams);

  return (
    <>
      {returnIfHasItems(subTopics, <AssocSection title="Sub-Topics" items={arrayToSortedNames(subTopics)} />)}
      {returnIfHasItems(countries, <AssocSection title="Countries" items={arrayToSortedNames(countries)} />)}
      {groupedBVs.map(group => (
        <>
          <AssocSection
            title={group.pluralTitle}
            items={group.labels}
            key={group.pluralTitle}
          />
        </>
      ))}
      {groupedBTs.map(group => (
        <>
          <AssocSection
            title={group.pluralTitle}
            items={group.labels}
            key={group.pluralTitle}
          />
        </>
      ))}
      {groupedBFs.map(group => (
        <>
          <AssocSection
            title={group.pluralTitle}
            items={group.labels}
            key={group.pluralTitle}
          />
        </>
      ))}
    </>
  );
};

export default QuestionAssociationsModal;

function returnIfHasItems(array: undefined | any[], node: React.ReactNode) {
  return array === undefined
    || array.length === 0
    ? undefined
    : node;
}

function AssocSection(props: { title: string, items: string[]; }) {
  return (
    <div>
      <b>{props.title}</b>
      <ul>
        {props.items.map(x =>
          <li key={x}>
            {x}
          </li>
        )}
      </ul>
    </div>
  );
}

function arrayToSortedNames(array?: { name: string; }[]) {
  return array?.map(x => x.name).sort((a, b) => a < b ? -1 : 1) ?? [];
}
