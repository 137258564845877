import AuditPlanCalendarApi from "api/planning/calendar/AuditPlanCalendarApi";
import { IUpdateAuditPlanCalendarItemRequest } from "api/planning/calendar/auditPlanCalendarApiTypes";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { IAuditSchedulerItemData } from "shared/components/controls/scheduler/qiSchedulerTypes";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IPickerItem } from "shared/types/pickerTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { getCalendarItems, loadAuditPlanCalendarData } from "store/audit-planning-shared/helpers/loadPlanningDataGenerators";
import { commonLoadPickerItemsAsync, commonLoadSuggestedPickerItemsAsync, getSuggestedLeadAuditors, retrieveAuditors } from "store/common/sagas/pickerSagas";
import { RootState } from "store/store";
import { IAuditType } from "types/auditMasterDataTypes";
import { AuditorSearchTypes } from "types/auditingTypes";
import { finishLoadCalendarResources, finishSaveCalendarItem, loadPickerItems, loadSuggestedPickerItems, refreshAuditPlanCalendar, saveCalendarItem, setCalendarDateRange, setCalendarMode, setLoadDataOp, setPickerError, setPickerItems, setSelectedPickerItems, setSuggestedPickerItems } from "./AuditPlanCalendarSlice";

export default function* auditPlanCalendarSagas() {
  yield all([
    loadPickerItemsAsync(),
    loadSuggestedPickerItemsAsync(),
    saveCalendarItemAsync(),
    setCalendarDateRangeAsync(),
    setCalendarModeAsync(),
    refreshAuditPlanCalendarAsync(),
  ]);
}

function* loadPickerItemsAsync() {
  yield takeEvery(loadPickerItems, function* (action) {
    if (action.payload.pickerKey === "editorLeadAuditors") {
      const auditType: IAuditType = yield select((store: RootState) => store
        .auditPlanCalendar
        .editItemModal
        ?.item.auditTypeName);

      let isFirstPartyAudit = auditType.id === 2;

      yield retrieveAuditors(action.payload.pickerKey,
        action.payload.searchValue || "",
        isFirstPartyAudit,
        AuditorSearchTypes.LeadAuditor,
        setPickerItems,
        setPickerError);
    } else {
      const selAuditType: IPickerItem<IAuditType> | undefined = yield select((store: RootState) => store
        .auditPlanCalendar
        .pickerData
        .calendarAuditTypes
        .selectedItems?.[0]);

      yield commonLoadPickerItemsAsync(action,
        setPickerItems,
        setSelectedPickerItems,
        setPickerError,
        selAuditType?.key === 2);
    }
  });
}

function* loadSuggestedPickerItemsAsync() {
  yield takeEvery(loadSuggestedPickerItems, function* (action) {
    if (action.payload.pickerKey === "editorLeadAuditors") {
      const auditType: IAuditType = yield select((store: RootState) => store
        .auditPlanCalendar
        .editItemModal
        ?.item.auditTypeName);

      yield getSuggestedLeadAuditors(auditType.id === 2,
        action.payload.pickerKey,
        setSuggestedPickerItems);
    } else {
      yield commonLoadSuggestedPickerItemsAsync(action,
        setSuggestedPickerItems,
        false);
    }
  });
}

function* saveCalendarItemAsync() {
  yield takeLatest(saveCalendarItem, function* (action) {
    try {
      const item = action.payload.item;

      const requestData: IUpdateAuditPlanCalendarItemRequest[] = [{
        id: Number(item.type === "Audit" ? item.auditId : item.planId),
        type: item.type,
        auditSource: item.auditSource,
        startDate: new Date(action.payload.startDate),
        endDate: new Date(action.payload.endDate),
        leadAuditorEmail: action.payload.leadAuditor?.email,
        leadAuditorName: action.payload.leadAuditor?.name,
      }];

      yield call(AuditPlanCalendarApi.updateCalendarItem, requestData);

      yield put(finishSaveCalendarItem({ wasSuccessful: true }));

      // Now reload the data for the calendar.
      yield call(loadAuditPlanCalendarData);
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishSaveCalendarItem({ wasSuccessful: false }));
    }
  });
}

/** Called when the user changes the date range of the scheduler component. */
function* setCalendarDateRangeAsync() {
  yield takeLatest(setCalendarDateRange, function* () {
    yield put(setLoadDataOp({
      isWorking: true,
    }));

    try {
      const schedulerItemsData: IAuditSchedulerItemData[] = yield call(getCalendarItems);

      yield put(finishLoadCalendarResources({
        isWorking: false,
        data: {
          items: schedulerItemsData,
        },
      }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(setLoadDataOp({
        isWorking: false,
      }));
    }
  });
}

function* setCalendarModeAsync() {
  yield takeLatest(setCalendarMode, function* () {
    yield call(loadAuditPlanCalendarData);
  });
}

function* refreshAuditPlanCalendarAsync() {
  yield takeLatest(refreshAuditPlanCalendar, function* () {
    yield call(loadAuditPlanCalendarData);
  });
}