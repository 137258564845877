import React from "react";
import Hint from "shared/components/common/hint/Hint";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import "./LabeledControl.scoped.scss";

export interface ILabeledControlProps {
  /**
   * The text to show.
   */
  label: string,
  /**
   * An optional small gray text to show after the label and its required asterisk.
   */
  hint?: React.ReactNode,
  /**
   * Determines whether to show the required indicator or not in the label.
   */
  isRequired?: boolean,
  className?: string,
  /**
   * If true, the label will show up in a lighter gray color.
   */
  isLight?: boolean,
  flexChildrenAs?: "column" | "row",
  tooltip?: string,
  /** An Optional props top pass children  */
  children?: React.ReactNode,
  /** An optional class for labels*/
  labelExtraClass?: string,

  /** An optional class for labels*/
  labelContentExtraClass?: string,
}

const LabeledControl: React.FC<ILabeledControlProps> = ({
  label,
  hint,
  isRequired,
  className,
  isLight,
  children,
  flexChildrenAs,
  tooltip,
  labelExtraClass,
  labelContentExtraClass,
}) =>
  <div
    className={`labeled-control ${className || ""} ${isLight ? "light" : ""}`}
    title={tooltip}
  >
    <label
      className={`${labelExtraClass ? labelExtraClass : ""}`}
    >
      {label || <>&nbsp;</>}
      {isRequired &&
        <span
          className="required"
        >
          *
        </span>
      }

      {!!hint &&
        <Hint>
          {hint}
        </Hint>
      }
    </label>

    {!flexChildrenAs && (
      <div
        title={tooltip}
        className={`${labelContentExtraClass ? labelContentExtraClass : ""}`}
      >
        {children}
      </div>
    )}

    {flexChildrenAs === "column" && (
      <FlexCol>
        {children}
      </FlexCol>
    )}

    {flexChildrenAs === "row" && (
      <FlexRow>
        {children}
      </FlexRow>
    )}
  </div>;

export default LabeledControl;
