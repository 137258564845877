import ActionItemsApi from "api/actionItems/ActionItemsApi";
import IEvidenceItem from "components/evidence/IEvidenceItem";
import EvidenceListModal from "components/evidence/list/EvidenceListModal";
import { cloneDeep } from "lodash";
import React from "react";
import { IActionItemEvidence } from "types/actionItemTypes";

export interface IActionItemEvidenceModalProps {
  /** The Id of the action item whose evidence should be loaded. */
  actionItemId: number,
  /** The list of evidence items to show if getEvidenceOnLoad is false. */
  evidenceItems?: IActionItemEvidence[],
  /** If true, the modal will query the API for evidence items when it loads. */
  getEvidenceOnLoad: boolean,
  /** If true, the ADD EVIDENCE button is available, else disabled. */
  allowAdd: boolean,
  /** If true, the EDIT EVIDENCE button is available, else disabled. */
  allowEdit: boolean,
  /** If true, the DELETE EVIDENCE button is available, else disabled. */
  allowDelete: boolean,
  /** The function to call when the user closes the modal. */
  onClose(): void,
  /** The function to call after the user successfully uploads an evidence item. */
  onEvidenceAdded(item: IEvidenceItem): void,
  /** The function to call after the user successfully deletes an evidence item. */
  onEvidenceDeleted(item: IEvidenceItem): void,
  /** The function to call after the user successfully updates an evidence item. */
  onEvidenceUpdated(item: IEvidenceItem): void,
}

const ActionItemEvidenceModal: React.FC<IActionItemEvidenceModalProps> = ({
  actionItemId,
  evidenceItems,
  getEvidenceOnLoad,
  allowAdd,
  allowDelete,
  allowEdit,
  onClose,
  onEvidenceAdded,
  onEvidenceDeleted,
  onEvidenceUpdated,
}) => {
  const getEvidenceItems = async (abortSignal: AbortSignal) => {
    const items = await ActionItemsApi.getEvidenceItems(actionItemId, abortSignal);

    return items.map((x): IEvidenceItem => ({
      filename: x.filename,
      id: Number(x.id),
      notes: x.notes,
    }));
  };

  const deleteEvidenceItem = async (item: IEvidenceItem) => {
    item.type === "Evidence"
      ? await ActionItemsApi.deleteActionItemEvidenceFile(actionItemId, item.id)
      : await ActionItemsApi.deleteActionItemEvidenceLink(actionItemId, item.id);
    onEvidenceDeleted(item);
  };

  const getActionItemEvidenceFileBlobUrl = async (item: IEvidenceItem) => {
    return await ActionItemsApi.getActionItemEvidenceFileBlobUrl(actionItemId, item.filename!);
  };

  const saveEvidenceItem = async (item: IEvidenceItem, content?: File | string) => {
    if (item.type === "Link") {
      const evidenceLink = await ActionItemsApi.saveActionItemEvidenceLink(actionItemId,
        item.id,
        item.link || "",
        item.notes);

      item.id > 0
        ? onEvidenceUpdated(cloneDeep(item))
        : onEvidenceAdded(evidenceLink);

      return item.id > 0
        ? item.id
        : evidenceLink.id;

    } else if (item.id === 0
      && content !== undefined) {
      // Create the item if it doesn't have an id and has a file.
      const createdItem = await ActionItemsApi.uploadEvidenceItem(actionItemId,
        content as File,
        item.notes);

      onEvidenceAdded(createdItem);

      return createdItem.id;
    } else {
      // Otherwise, try to update the existing item in the api.
      await ActionItemsApi.updateEvidenceNotes(actionItemId, item.id, item.notes);

      onEvidenceUpdated(cloneDeep(item));

      return item.id;
    }
  };

  return (
    <EvidenceListModal
      allowAdd={allowAdd}
      allowDelete={allowDelete}
      allowEdit={allowEdit}
      header={`Evidence for Action Item #${actionItemId}`}
      onClose={onClose}
      getEvidenceItems={getEvidenceOnLoad
        ? getEvidenceItems
        : async () => evidenceItems || []
      }
      getActionItemEvidenceFileBlobUrl={getActionItemEvidenceFileBlobUrl}
      deleteEvidenceItem={deleteEvidenceItem}
      saveEvidenceItem={saveEvidenceItem}
    />
  );
};

export default ActionItemEvidenceModal;