import { groupBy } from "lodash";
import React from "react";
import QISelect from "shared/components/controls/select/QISelect";
import pluralize from "shared/utilities/pluralize";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { userToString } from "shared/utilities/userUtilities";
import { IAuditor } from "types/auditingTypes";
import "./AuditorDropdown.scoped.scss";

interface IAuditorDropdownProps {
  auditors: IAuditor[],
  selectedAuditors: (IAuditor | undefined)[],
  onChange: (auditor?: IAuditor) => void,
  maxWidth?: string,
  disabled: boolean,
  className?: string,
  allowClearButton: boolean,
}

const AuditorDropdown: React.FC<IAuditorDropdownProps> = ({
  auditors,
  selectedAuditors,
  onChange,
  maxWidth,
  disabled,
  className,
  allowClearButton,
}) => {
  const onSelectValueChanged = (newVal: string) => {
    if (newVal === "Unassigned") {
      onChange(undefined);
    } else {
      const auditor = auditors.find(x => x.email === newVal);

      if (!auditor) {
        return;
      }

      onChange(auditor);
    }
  };

  const unqSelAuditors = groupBy(selectedAuditors, x => x?.email);
  const auditorEntries = Object.entries(unqSelAuditors);
  const unassignedCount = auditorEntries.find(x => x[0] === "undefined")?.[1]?.length ?? 0;
  const assignedCount = auditorEntries.filter(x => x[0] !== "undefined")?.length ?? 0;
  const allAreAssignedTo = auditorEntries.length === 1
    && auditorEntries[0][0] !== "undefined"
    ? auditorEntries[0][1]?.[0]
    : undefined;

  let displayOverride = "Unassigned";

  if (allAreAssignedTo) {
    displayOverride = userToString(allAreAssignedTo);
  } else if (assignedCount) {
    // At least 1 is assigned.
    displayOverride = `${assignedCount} ${pluralize(assignedCount, "auditor", "auditors")}`;

    if (unassignedCount) {
      // Also has some unassigned.
      displayOverride += ` +${unassignedCount} unassg.`;
    }
  }

  return (
    <QISelect
      items={auditors.map(x => ({
        id: x.email,
        text: userToString(x),
      }))}
      placeholder="Unassigned"
      allowClearButton={allowClearButton}
      selectedItemIds={Array.from(new Set(selectedAuditors.filter(isNotUndefined).map(x => x.email)))}
      selectMode="single"
      onChange={selectedItems => onSelectValueChanged(selectedItems[0]?.id?.toString() ?? "Unassigned")}
      className={`auditee-dropdown ${className || ""}`}
      isDisabled={disabled}
      style={maxWidth !== undefined
        ? {
          maxWidth: maxWidth,
        } : undefined
      }
      displayOverride={displayOverride}
    />
  );
};

export default AuditorDropdown;