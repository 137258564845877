import React from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import editIcon from "shared/media/dls/edit-1.svg";
import deleteIcon from "shared/media/dls/delete.svg";
import { IPlanChildDimension, IPlanParentDimension, IPlanRequirementDimension } from "types/auditPlanningTypes";

interface IConfirmEditOrRemovePlanModal {
  /**
   * Specifies if the modal is open or not. If not open, it does not render.
   */
  isOpen: boolean,
  /**
   * The action to perform when the user clicks the cancel button in the modal.
   */
  onCancel(): void,
  /**
   * The action to perform when the user clicks the edit button in the modal.
   */
  onEdit(): void,
  /**
 * The action to perform when the user clicks the edit button in the modal.
 */
  onRemove(): void,
  /**
   * The details of the parent dimension for the plan.
   */
  parentDimension?: IPlanParentDimension,
  /**
   * The details of the parent dimension for the plan.
   */
  childDimension?: IPlanChildDimension,
  /**
   * The details of the child dimension for the plan.
   */
  requirementDimension?: IPlanRequirementDimension,
  /**
   * The details of the requirement dimension for the plan.
   */
}

const ConfirmEditOrRemovePlanModal: React.FC<IConfirmEditOrRemovePlanModal> = ({
  isOpen,
  onCancel,
  onEdit,
  onRemove,
  parentDimension,
  childDimension,
  requirementDimension,
}) => {

  let planDisplayText = [
    parentDimension?.text,
    childDimension?.text,
  ].filter(x => !!x)
    .join(" + ");

  return (
    <Modal
      isOpen={isOpen}
      header={"Edit or Remove Plan"}
      buttons={[{
        key: "Edit",
        text: "Edit Plan",
        className: "secondary",
        img: editIcon,
        onClick: onEdit
      }, {
        key: "Remove",
        text: "Remove Plan",
        className: "red",
        img: deleteIcon,
        onClick: onRemove
      }, {
        key: "Cancel",
        text: "Cancel",
        className: "secondary",
        onClick: onCancel,
      }]}
    >
      <FlexCol>
        <span>
          Would you like to EDIT OR REMOVE the <b>{requirementDimension?.text}</b> Audit Plan from <b>{planDisplayText}</b>?
        </span>
      </FlexCol>
    </Modal>
  );
};

export default ConfirmEditOrRemovePlanModal;