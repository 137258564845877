import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { userToString } from "shared/utilities/userUtilities";
import { closePicker, loadPickerItems, openPicker, setSelectedPickerItems } from "store/search/audits/SearchAuditsSlice";
import { useAppSelector } from "store/store";

const LeadAuditors: React.FC = () => {
  const leadAuditors = useAppSelector(store => store.searchAudits.pickerData.leadAuditors);

  return (
    <LabeledControl
      label="Lead Auditor"
      className="lead-auditor"
    >
      <Picker<IAzureADUser>
        title="Lead Auditor"
        renderListItem={(item) => userToString(item)}
        renderSelectedItem={(item) => userToString(item, "")}
        pickerState={leadAuditors}
        preserveItems={true}
        allowMultiSelect={true}
        searchOptions={{
          asyncMinChars: 1,
          behavior: "async",
        }}
        openAction={openPicker}
        closeAction={closePicker}
        loadAction={loadPickerItems}
        setSelectedItemsAction={setSelectedPickerItems}
        noItemsMessage={"Please begin typing a search term to execute a search."}
        itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
      />
    </LabeledControl>
  );
};

export default LeadAuditors;