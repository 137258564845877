import { uniq } from "lodash";
import React from "react";
import "./AuditHierarchyDisplay.scoped.scss";

interface ISortSettings {
  [key: string]: number,
}

interface IHierarchyItem {
  id: number,
  display: React.ReactNode,
  type: string,
}

interface IAuditHierarchyDisplayProps {
  items: IHierarchyItem[],
  typeSortOrder: ISortSettings,
}

const AuditHierarchyDisplay: React.FC<IAuditHierarchyDisplayProps> = ({
  items,
  typeSortOrder,
}) => {
  const types = uniq(items.map(x => x.type))
    .sort((a, b) => typeSortOrder[a] < typeSortOrder[b] ? -1 : 1);

  return (
    <div className="items-list">
      {types.map(type =>
        <div className="items-section" key={type}>
          <h4>{type}s</h4>
          <ul>
            {items
              .filter(x => x.type === type)
              .map(x =>
                <li key={x.id}>
                  {x.display}
                </li>
              )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AuditHierarchyDisplay;