import { FlexGrid } from "@mescius/wijmo.grid";
import React from "react";
import { IDataGridProps } from "./types/dataGridTypes";
import WijmoGrid from "./wijmo/WijmoGrid";

/** Renders a data grid. */
const DataGrid = React.forwardRef<FlexGrid, IDataGridProps>((props, ref) => {
  return (
    <WijmoGrid
      {...props}
      allowExcelExport={props.allowExcelExport ?? false}
      enablePersistence={props.enablePersistence ?? false}
      allowPaging={props.allowPaging ?? true}
      pageSize={props.pageSize ?? 30}
      ref={ref}
      allowResizing={true}
    />
  );
});

export default DataGrid;