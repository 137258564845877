import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { IAzureADUser, IProfileMetaData } from "shared/types/userProfileTypes";
import { Roles } from "types/authTypes";

export interface IUserManagementSliceState {
  /** Determines the visibility of the profile manager modal. */
  isManagerOpen: boolean,
  /** The currently held values of the profile manager. */
  managerValues: Partial<IProfileManagerValues>,
  /** Determines the visibility of the delete profile confirmation modal. */
  isDeleteConfirmationOpen: boolean,

  /** Represents the save profile operation. */
  saveOp?: IOperation<void>,
  /** Represents the load profile operation. */
  loadOp?: IOperation<void>,
  /** Represents the delete profile operation. */
  deleteOp?: IOperation<void>,
}

export interface IProfileManagerValues {
  profileId: number,
  user: IAzureADUser,
  module: string,
  role: Roles,
  metaData: IProfileMetaData[],
}

const initialState: IUserManagementSliceState = {
  isManagerOpen: false,
  managerValues: {
    module: "Auditing",
  },
  isDeleteConfirmationOpen: false,

  saveOp: undefined,
  loadOp: undefined,
  deleteOp: undefined,
}

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    /** Sets a value in the redux state. */
    setValue: (state, action: PayloadAction<Partial<IUserManagementSliceState>>) => {
      Object.assign(state, action.payload);
    },

    /** Sets a value in the profile manager. */
    setProfileManagerValue: (state, action: PayloadAction<Partial<IProfileManagerValues>>) => {
      Object.assign(state.managerValues, action.payload);
      if (action.payload.role === Roles.SystemAdmin) {
        state.managerValues.module = "All";
      } else {
        state.managerValues.module = "Auditing";
      }
    },

    /** Starts saving a profile to the api via a Saga. */
    stopSaveProfile: state => {
      state.saveOp = {
        isWorking: false,
      };
    },

    /** Starts saving a profile to the api via a Saga. */
    startSaveProfile: state => {
      state.saveOp = {
        isWorking: true,
      };
    },

    /** Clears the save profile operation. */
    finishSaveProfile: (state, action: PayloadAction<boolean>) => {
      state.saveOp = undefined;

      if (action.payload) {
        state.isManagerOpen = false;
      }
    },

    /** Starts loading a profile from the api via a Saga. */
    startLoadProfile: (state, _: PayloadAction<{
      module: string,
      email: string,
      profileId: number,
    }>) => {
      state.loadOp = {
        isWorking: true,
      };
    },

    /** Finishes loading a profile and puts the profile values into the state. */
    finishLoadProfile: (state, action: PayloadAction<IProfileManagerValues | undefined>) => {
      state.loadOp = undefined;

      if (!action.payload) {
        return;
      }

      state.managerValues = action.payload;
      state.isManagerOpen = true;
    },

    /** Adds some selected meta data to the manager values if they are not already there. */
    addMetaData: (state, action: PayloadAction<IProfileMetaData[]>) => {
      if (!state.managerValues.metaData) {
        state.managerValues.metaData = [];
      }

      action.payload.forEach(m => {
        if (!state
          .managerValues
          .metaData
          ?.some(x => x.masterDataId === m.masterDataId
            && x.type === m.type)) {
          state.managerValues.metaData?.push(m);
        }
      });
    },

    /** Removes a specific meta data from the list. */
    removeMetaData: (state, action: PayloadAction<{ id: number, type: string }>) => {
      state.managerValues.metaData = state
        .managerValues
        .metaData
        ?.filter(x => !(x.masterDataId === action.payload.id
          && x.type === action.payload.type));
    },

    /** Starts deleting a profile on the api via a Saga. */
    startDeleteProfile: (state, _: PayloadAction<{
      email: string,
      profileId: number,
    }>) => {
      state.deleteOp = {
        isWorking: true,
      };
    },

    /** Finishes deleting a profile. If the deletion was successful, send `true` in the payload. */
    finishDeleteProfile: (state, action: PayloadAction<boolean>) => {
      state.deleteOp = undefined;

      if (action.payload) {
        state.isManagerOpen = false;
      }
    },

    /** Determines the visibility of the delete confirmation window. */
    toggleDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.isDeleteConfirmationOpen = action.payload;
    },
  },
});

export const {
  setValue,
  setProfileManagerValue,

  startSaveProfile,
  stopSaveProfile,
  finishSaveProfile,

  startLoadProfile,
  finishLoadProfile,

  startDeleteProfile,
  finishDeleteProfile,

  addMetaData,
  removeMetaData,

  toggleDeleteConfirmation,
} = userManagementSlice.actions;