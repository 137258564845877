import React from "react";
import { IAuditPlanScore, IAuditPlanView, IPlanRequirementDimension } from "types/auditPlanningTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import "./PlanningGrid.scoped.scss";
import GridRow from "./grid-row/GridRow";
import HeaderCell from "./header-cell/HeaderCell";

interface IPlanningGridProps {
  rows: IPlanningItem[],
  requirements: IPlanRequirementDimension[],
  options: IPlanningGridOptions,
}

export interface IPlanningGridOptions {
  /** Optional. This function will be called before rending the cell and its output will be
   * passed to each cell's render method in the param named `computedRowData`. */
  computeRowData?: (row: IPlanningItem, parentRow?: IPlanningItem) => any,
  /** Optional. Options for the left command cell. */
  leftCommandCellOptions?: {
    isVisible: boolean,
    isSticky?: boolean,
    renderCell(args: IRenderCommandCellArgs, computedRowData?: any): {
      node?: React.ReactNode,
      cellProps?: React.HTMLAttributes<HTMLTableCellElement>,
    },
  },
  /** Optional. Options for the right command cell. */
  rightCommandCellOptions?: {
    isVisible: boolean,
    isSticky?: boolean,
    renderCell(args: IRenderCommandCellArgs, computedRowData?: any): {
      node?: React.ReactNode,
      cellProps?: React.HTMLAttributes<HTMLTableCellElement>,
    },
  },
  /**
   * This method should return the react node to place into this grid cell.
   * @param args The requirement and planningItem dimensions of this cell.
   */
  renderDataCell(args: IRenderCellArgs, computedRowData?: any): {
    node?: React.ReactNode,
    cellProps?: React.HTMLAttributes<HTMLTableCellElement>,
  },
}

export interface IRenderCellArgs {
  row: IPlanningItem,
  parentRow?: IPlanningItem,
  requirement: IPlanRequirementDimension,
  depth: number,
}

export interface IRenderCommandCellArgs {
  depth: number,
  row: IPlanningItem,
  parentRow?: IPlanningItem,
}

export interface IPlanningGridCellClickArgs {
  row: IPlanningItem,
  parentRow?: IPlanningItem,
  plan?: IAuditPlanView,
  score?: IAuditPlanScore,
  requirement: IPlanRequirementDimension,
}

export interface IPlanningGridApproveClickArgs {
  row: IPlanningItem,
  parentRow?: IPlanningItem,
  plans: IAuditPlanView[],
  requirements: IPlanRequirementDimension[],
}

export interface IPlanningItem {
  id: number,
  text: string,
  type: MetaDataTypes,
  subType: string | undefined,
  children: IPlanningItem[],
  isPlannable: boolean,
  isExpanded: boolean,
  doesUserHavePermission: boolean,
  isWithinPlanningRange: boolean,
  subGeoUnitId: number,
}

const PlanningGrid: React.FC<IPlanningGridProps> = ({
  requirements,
  rows,
  options,
}) => {
  return (
    <div
      className="grid-wrapper"
    >
      <table>
        <thead>
          <tr>
            {options.leftCommandCellOptions && (
              <th
                className={options.leftCommandCellOptions.isSticky
                  ? "left-sticky-cell"
                  : undefined
                }
              >
                Audit Requirements
              </th>
            )}
            {requirements.map(req => (
              <HeaderCell
                key={`${req.type}-${req.id}`}
                requirement={req}
              />
            ))}
            {options.rightCommandCellOptions && (
              <th
                className="right-sticky-cell"
              ></th>
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map(item => (
            <GridRow
              requirements={requirements}
              row={item}
              parentRow={undefined}
              depth={0}
              key={`${item.type}-${item.id}`}
              options={options}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PlanningGrid;
