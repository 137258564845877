import React from "react";
import { IAppInfo } from "shared/types/appInfoTypes";
import "./Version.scoped.scss";

export interface IVersionProps {
  appInfo?: IAppInfo,
}

const Version: React.FC<IVersionProps> = ({
  appInfo,
}) => {
  const displayString = [
    appInfo?.buildNumber,
    appInfo?.env
  ].filter(x => !!x)
    .join('-');

  if (!displayString) {
    return null;
  }

  return (
    <p>{displayString}</p>
  );
}

export default Version;
