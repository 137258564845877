import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Modal from "shared/components/layout/modal/Modal";
import { sendAuditNotifications, toggleNotifyTeamModal } from "store/audit-report/AuditReportSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditNotificationRecipients } from "types/auditingTypes";
import "./NotifyTeamModal.scoped.scss";

interface INotifyTeamModalProps {
  auditId: number,
}

const NotifyTeamModal: React.FC<INotifyTeamModalProps> = ({ auditId }) => {
  const op = useAppSelector(store => store.auditReport.sendNotificationsOp);
  const [recipients, setRecipients] = useState<AuditNotificationRecipients[]>([AuditNotificationRecipients.Auditors]);
  const dispatch = useAppDispatch();

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: "AR - Notify Team",
    });
  }, [appInsights]);

  return (
    <Modal
      isOpen={true}
      header="Notify Team"
      buttons={[{
        key: "cancel",
        text: "Cancel",
        className: "secondary",
        onClick: () => dispatch(toggleNotifyTeamModal(false)),
      }, {
        key: "submit",
        text: "submit",
        className: "primary",
        disabled: recipients.length === 0,
        onClick: () => dispatch(sendAuditNotifications({
          auditId,
          recipients,
        })),
      }]}
      onCloseButtonClicked={() => dispatch(toggleNotifyTeamModal(false))}
      showCloseButton={true}
    >
      <p>Would you like to Send a Notification E-mail?</p>
      <p>
        Include:

        {Object.entries(AuditNotificationRecipients).map(item => (
          <label
            key={item[1]}
          >
            <input
              type="checkbox"
              value={item[1]}
              checked={recipients.indexOf(item[1]) > -1}
              onChange={e => e.currentTarget.checked
                ? setRecipients(recipients.concat(item[1]))
                : setRecipients(recipients.filter(x => x !== item[1]))
              }
            />
            {item[1]}
          </label>
        ))}
      </p>

      {op?.isWorking && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default NotifyTeamModal;