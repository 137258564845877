import UrlRoutes from "components/routing/UrlRoutes";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Prompt from "shared/components/common/prompt/Prompt";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import Button from "shared/components/controls/buttons/button/Button";
import Card from "shared/components/layout/card/Card";
import Page from "shared/components/layout/page/Page";
import { runInitialPageLoad, saveChanges } from "store/audit-topic-rearrange/AuditTopicRearrangeSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import AuditTopicItem from "./audit-topic-item/AuditTopicItem";

const AuditTopicsRearrangePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const loadOp = useAppSelector(store => store.auditTopicRearrange.loadOp);
  const saveOp = useAppSelector(store => store.auditTopicRearrange.saveOp);
  const currentWorkspace = useAppSelector(store => store.auditTopicRearrange.current);
  const isDirty = useAppSelector(store => store.auditTopicRearrange.isDirty);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(runInitialPageLoad());
  }, [dispatch]);

  let spinnerNode: React.ReactNode;

  if (loadOp?.isWorking) {
    spinnerNode = <Spinner />
  } else if (loadOp?.errorMessage) {
    spinnerNode = <Banner type={BannerType.error}>{loadOp.errorMessage}</Banner>
  }

  return (
    <Page
      title="Rearrange Audit Topics"
      showBreadcrumbs={true}
      backButtonRoute={UrlRoutes.AuditAdmin.urlTemplate}
      headerControls={[
        {
          key: "Cancel",
          element:
            <Button
              buttonType="secondary"
              onClick={() => navigate(UrlRoutes.AuditAdmin.urlTemplate)}
            >
              Cancel
            </Button>,
        },
        {
          key: "Save",
          element:
            <Button
              buttonType="primary"
              isDisabled={!isDirty}
              onClick={() => dispatch(saveChanges())}
            >
              Save
            </Button>,
        }
      ]}
    >
      {spinnerNode
        ? spinnerNode
        : (
          <Card
            title="Audit Topics"
          >
            <div className="draggable-audit-topics">
              {currentWorkspace.auditTopics.map((item, ix) =>
                <AuditTopicItem
                  key={item.id}
                  item={item}
                  index={ix}
                />
              )}
            </div>
          </Card>
        )}

      {saveOp?.isWorking && <ModalSpinner />}

      <Prompt
        isDirty={isDirty}
        message="Any unsaved changes will be lost. Are you sure you want to leave?"
      />
    </Page>
  );
};

export default AuditTopicsRearrangePage;