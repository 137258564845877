import { useAppDispatch, useAppSelector } from "store/store";
import Spinner from "shared/components/common/spinner/Spinner";
import { useEffect } from "react";
import { loadQmsResultsItems } from "store/audit-non-conformances/AuditNonConformancesSlice";
import QmsResultCard from "./QmsResultCard";
import { MetaDataTypes } from "types/masterDataTypes";

const AuditResultQms: React.FC = () => {
  const questions = useAppSelector(store => store.audit.questions);
  const qmsElements = useAppSelector(store => store.auditNonConformance.qmsElements);
  const apis = useAppSelector(store => store.auditNonConformance.apis);
  const loadQmsResultsItemsOperation = useAppSelector(store => store.auditNonConformance.loadQmsResultsItemsOperation);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadQmsResultsItems(questions[0].questionId));
  }, [dispatch, questions])

  let qmsElementItems = qmsElements.map(x => ({
    id: x.id,
    name: x.name,
    mainItem: x.mainElement,
    subItem: x.subElement
  }));

  let qmsApiItems = apis.filter(x => x.spec === "Q2").map(x => ({
    id: x.id,
    name: x.name,
    mainItem: x.mainSection,
    subItem: x.subSection
  }));

  const isLoading = loadQmsResultsItemsOperation?.isWorking;

  return (
    <>
      {isLoading
        ? <Spinner />
        : (
          <>
            <QmsResultCard
              items={qmsElementItems}
              metaDataType={MetaDataTypes.QMSElement}
              header="Audit Results by QMS Elements & Subelements"
            />
            <QmsResultCard
              items={qmsApiItems}
              metaDataType={MetaDataTypes.API}
              header="Audit Results by API Q2 Elements & Subelements"
            />
          </>
        )
      }
    </>
  )
}

export default AuditResultQms;