import AuditScoresApi from "api/auditing/AuditScoresApi";
import AuditsApi from "api/auditing/AuditsApi";
import FindingApi from "api/findings/FindingApi";
import MasterDataApi from "api/masterdata/MasterDataApi";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { IActionItem } from "types/actionItemTypes";
import { IAllAuditScoreResults } from "types/audit-scores/auditScoreTypes";
import { IAnswer } from "types/auditMasterDataTypes";
import { IAuditInfo, IAuditQuestion } from "types/auditingTypes";
import { finishLoadAllData, loadAllData } from "./AuditSlice";
import { IFinding, IFindingType } from "./reducers/findingReducers";
import { loadActionItemsAsync } from "./sagas/actionItemSagas";
import { loadAuditAsync } from "./sagas/auditInfoSagas";
import { exportAuditAsync } from "./sagas/exportSagas";
import { loadQuestionsAsync } from "./sagas/questionSagas";
import { saveChangesAsync } from "./sagas/saveChangesSagas";
import { loadScoresAsync } from "./sagas/scoresSagas";
import { revertStatusAsync, updateStatusAsync } from "./sagas/statusSagas";

export default function* auditSagas() {
  yield all([
    loadAuditAsync(),
    loadQuestionsAsync(),
    updateStatusAsync(),
    revertStatusAsync(),
    saveChangesAsync(),
    exportAuditAsync(),
    loadActionItemsAsync(),
    loadAllDataAsync(),
    loadScoresAsync(),
  ]);
}

export function* loadAllDataAsync() {
  yield takeLatest(loadAllData, function* (action) {
    if (!loadAllData.match(action)) {
      return;
    }

    try {
      const [
        auditInfo,
        auditQuestions,
        actionItems,
        answers,
        allAuditScoreResults,
        findings,
      ]: [IAuditInfo, IAuditQuestion[], IActionItem[], IAnswer[], IAllAuditScoreResults | undefined, IFinding[]] = yield all([
        call(AuditsApi.getAuditInfo, action.payload.auditId),
        call(AuditsApi.getAuditQuestions, action.payload.auditId),
        call(AuditsApi.getAllActionItemsForAudit, action.payload.auditId),
        call(MasterDataApi.getAnswers),
        action.payload.includeAuditScores
          ? call(AuditScoresApi.getAuditScores, {
            auditId: action.payload.auditId,
            includeByAuditStatus: true,
          }) : undefined,
        call(FindingApi.getFindingsInAudit, action.payload.auditId),
      ]);

      const findingTypes: IFindingType[] = action.payload.loadFindingTypes
        && auditQuestions.length
        ? yield call(FindingApi.getFindingTypes, auditQuestions[0].scoringSystem)
        : [];

      yield put(finishLoadAllData({
        isWorking: false,
        data: {
          auditInfo,
          auditQuestions,
          actionItems,
          answers,
          findings,
          findingTypes,
          auditScores: allAuditScoreResults,
        },
      }));
    } catch (err) {
      yield put(finishLoadAllData({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}