import AuditorSearchMessage from "components/audits/common/auditor-search-message/AuditorSearchMessage";
import React, { useMemo, useState } from "react";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import Button from "shared/components/controls/buttons/button/Button";
import Picker from "shared/components/controls/picker/Picker";
import Card from "shared/components/layout/card/Card";
import TabSelection from "shared/components/layout/tab-selection/TabSelection";
import addIcon from "shared/media/dls/add.svg";
import { IPickerItem } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { userToString } from "shared/utilities/userUtilities";
import { closePicker, loadPickerItems, openPicker, setSelectedAuditorTab, setSelectedPickerItems } from "store/audit-summary/AuditSummarySlice";
import { setAudit } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses, IAuditee } from "types/auditingTypes";
import AuditeeRow from "./AuditeeRow";

const AuditeesCard: React.FC = () => {
  const audit = useAppSelector(store => store.audit.audit);

  const selectedAuditorTab = useAppSelector(store => store.auditSummary.selectedAuditorTab);
  const pickerData = useAppSelector(store => store.auditSummary.pickerData);
  //Restriction applied
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const isEnableToEdit = auditPageAuth.editRestriction !== EditRestriction.EditNone;

  const auditees = audit?.auditees;

  const dispatch = useAppDispatch();
  const [confirmRemoveEmail, setConfirmRemoveEmail] = useState("");

  const selectedAuditees = useMemo(() => {
    return (auditees || [])
      .map((item): IPickerItem<IAzureADUser> => ({
        key: item.email,
        disabled: false,
        item: item,
      }));
  }, [auditees]);

  if (!audit) {
    // No audit is loaded. Don't show the card.
    return null;
  }

  return (
    <Card
      cardStateId={`auditSummaryAuditorsAuditeesCard`}
      defaultCollapsedState={false}
      className={"auditees-card"}
      headerElement={(
        <>
          <div
            className="tab-holder"
          >
            <TabSelection
              className="leftJustified"
              onTabClicked={(key: string | number) => {
                dispatch(setSelectedAuditorTab(key as "Auditors" | "Auditees"));
              }}
              tabs={[{
                key: "Auditors",
                node: "Auditors",
                isLink: false,
                isSelected: selectedAuditorTab === 'Auditors',
                linkUrl: undefined,
              }, {
                key: "Auditees",
                node: "Auditees",
                isLink: false,
                isSelected: selectedAuditorTab === 'Auditees',
                linkUrl: undefined,
              }]}
            />
          </div>
          {isEnableToEdit
            && audit?.status !== AuditStatuses.Completed
            && audit?.status !== AuditStatuses.Closed
            ? (
              <Picker<IAuditee>
                title="Auditees"
                subTitle={<AuditorSearchMessage />}
                renderListItem={(item) => userToString(item)}
                renderSelectedItem={(item) => userToString(item)}
                pickerState={{
                  ...pickerData.auditees,
                  selectedItems: selectedAuditees as IPickerItem<IAuditee>[],
                }}
                preserveItems={true}
                allowMultiSelect={true}
                searchOptions={{
                  asyncMinChars: 1,
                  behavior: "async",
                }}
                openAction={openPicker}
                closeAction={closePicker}
                loadAction={loadPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                noItemsMessage={"Please begin typing a search term to execute a search."}
                pickerNode={(
                  <Button
                    buttonType="secondary"
                    onClick={() => dispatch(openPicker({
                      pickerKey: pickerData.auditees.key,
                    }))}
                    img={addIcon}
                  >
                    Add Auditee
                  </Button>
                )}
                itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
              />
            ) : undefined
          }
        </>
      )}
      footer={
        <table>
          <tbody>
            <tr>
              <td>
                {`${(auditees || []).length} Auditees`}
              </td>
            </tr>
          </tbody>
        </table>
      }
    >
      <div
        className="card-body"
      >
        <table>
          <thead>
            <tr>
              <th
                className="name"
              >
                Auditees
              </th>
              <th
                className="centered"
              >
              </th>
              <th
                className="centered"
              >
              </th>
            </tr>
          </thead>
          <tbody>
            {(auditees || [])
              .slice()
              .sort((a, b) => {
                if (a.isLeader) {
                  return -1;
                } else if (b.isLeader) {
                  return 1;
                }

                return a.name < b.name ? -1 : 1;
              }).map(auditee => (
                <AuditeeRow
                  key={auditee.email}
                  auditee={auditee}
                  isEnabledToEdit={audit?.status === AuditStatuses.Completed
                    ? false
                    : isEnableToEdit}
                  onRemoveClick={audit?.status !== AuditStatuses.Completed
                    && audit?.status !== AuditStatuses.Closed && isEnableToEdit
                    ? email => setConfirmRemoveEmail(email)
                    : undefined
                  }
                />
              ))}
          </tbody>
        </table>
      </div>

      {confirmRemoveEmail && (
        <ConfirmModal
          header="Remove Auditee?"
          message={`Are you sure you want to remove ${confirmRemoveEmail}?`}
          onYesClicked={() => {
            const newAuditeeList: IAuditee[] = audit.auditees?.filter(x => x.email !== confirmRemoveEmail);

            let newUpdatedList = newAuditeeList
              .map((i): IAuditee => ({
                name: i.name,
                email: i.email,
                isLeader: i.isLeader
              }))
              .sort((a, b) => a.name < b.name ? -1 : 0);

            if (newUpdatedList.length) {
              const leader = newUpdatedList.find(x => x.isLeader);
              if (!leader) {
                newUpdatedList[0].isLeader = true;
              }
            }

            dispatch(setAudit({
              audit: {
                auditees: newUpdatedList,
                auditTopicAuditees: (audit?.auditTopicAuditees || [])
                  .filter(x => x.auditeeEmail.toLowerCase() !== confirmRemoveEmail.toLowerCase()),
              },
            }));

            setConfirmRemoveEmail("");
          }}
          onNoClicked={() => setConfirmRemoveEmail("")}
        />
      )}
    </Card>
  );
};

export default AuditeesCard;
