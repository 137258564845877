import { IActionItemPriority, IActionItemType } from "types/actionItemTypes";
import { IAnswer, IApi, IAuditGroup, IAuditType, ICausalFactor, IHSESubElement, IIso, IQMSElement, IQuestionCategory, IQuestionType, IVerificationMethod } from "types/auditMasterDataTypes";
import { IBusinessEpic, IBusinessFunction, IBusinessTeam, IBusinessView, ICalendarWeek, ICLMDocument, ICLMFunction, ICountry, IFacility, IIPMProject, IMobileSite, ISupplier, ICLMWhomToCheck } from "types/masterDataTypes";

export function formatAuditType(obj: any): IAuditType {
  return {
    id: obj.id,
    name: obj.name,
    isDeleted: obj.deleted,
  };
}

export function formatAuditGroup(obj: any): IAuditGroup {
  return {
    id: obj.id,
    name: obj.name,
    isDeleted: obj.deleted,
  };
}

export function formatAuditorType(obj: any): IAuditGroup {
  return {
    id: obj.id,
    name: obj.name,
    isDeleted: obj.deleted,
  };
}


export function formatBusinessView(obj: any): IBusinessView {
  return {
    id: Number(obj.id),
    code: obj.code?.toString(),
    name: obj.name?.toString(),
    type: obj.type?.toString(),
    isDeleted: Boolean(obj.deleted),
    children: (obj.children || []).map((child: any) => formatBusinessView(child)),
  };
}

export function formatBusinessFunction(obj: any): IBusinessFunction {
  return {
    id: Number(obj.id),
    code: obj.code?.toString(),
    name: obj.name?.toString(),
    type: obj.type?.toString(),
    isDeleted: Boolean(obj.deleted),
    children: (obj.children || []).map((child: any) => formatBusinessFunction(child)),
  };
}

export function formatBusinessTeam(obj: any): IBusinessTeam {
  return {
    id: Number(obj.id),
    code: obj.code?.toString(),
    name: obj.name?.toString(),
    type: obj.type?.toString(),
    isDeleted: Boolean(obj.deleted),
    children: (obj.children || []).map((child: any) => formatBusinessTeam(child)),
  };
}

export function formatCountry(obj: any): ICountry {
  return {
    id: obj.id,
    name: obj.name,
  };
}

export function formatFacility(obj: any): IFacility {
  return {
    id: obj.id,
    commonId: obj.commonId,
    name: obj.name,
    isDeleted: obj.deleted,
    subGeoUnitId: obj.subGeoUnitId ?
      Number(obj.subGeoUnitId)
      : undefined,
  };
}

export function formatQuestionCategory(obj: any): IQuestionCategory {
  return {
    id: obj.id,
    name: obj.name,
    isDeleted: obj.deleted,
  };
}

export function formatAnswer(obj: any): IAnswer {
  return {
    key: obj.key?.toString() || "",
    name: obj.name?.toString() || "",
    code: obj.code?.toString() || "",
    requiresRWP: Boolean(obj.requiresRWP),
    isNA: Boolean(obj.isna),
    canBeUpdated: Boolean(obj.canBeUpdated),
    canBeUpdatedTo: Boolean(obj.canBeUpdatedTo),
    displayColor: obj.displayColor?.toString(),
    scoringSystem: obj.scoringSystem?.toString() || "",
    sortOrder: Number(obj.sortOrder),
  };
}

export function formatCausalFactor(obj: any): ICausalFactor {
  return {
    id: obj.id,
    name: obj.name,
    isDeleted: obj.deleted,
  };
}

export function formatActionItemType(obj: any): IActionItemType {
  return {
    id: obj.id,
    name: obj.name,
    allowCausalFactor: !!obj.allowCausalFactor,
    isDeleted: obj.deleted,
  };
}

export function formatActionItemPriority(obj: any): IActionItemPriority {
  return {
    id: obj.id,
    name: obj.name,
    addsDeadlineDays: Number(obj.addsDeadlineDays),
    sortOrder: Number(obj.sortOrder),
    isDeleted: obj.deleted,
  };
}

export function formatBusinessTeamFlatViewsIntoBusinessTeam(flatViews: any[]): IBusinessTeam[] {
  if (!flatViews) {
    return [];
  }

  let topLevels: IBusinessTeam[] = [];

  flatViews.forEach(flatView => {
    let lvl1 = findOrCreateBusinessTeam(topLevels,
      "1",
      flatView,
      r => topLevels.push(r));

    let lvl2 = findOrCreateBusinessTeam(topLevels,
      "2",
      flatView,
      r => lvl1.children.push(r));

    let lvl3 = findOrCreateBusinessTeam(topLevels,
      "3",
      flatView,
      r => lvl2.children.push(r));

    findOrCreateBusinessTeam(topLevels,
      "4",
      flatView,
      r => lvl3.children.push(r));
  });

  return topLevels;
}

function findOrCreateBusinessTeam(searchList: IBusinessTeam[],
  level: string,
  flatView: any,
  addBusinessTeam: (businessTeam: IBusinessTeam) => void): IBusinessTeam {
  let existingItem = searchList.find(x => x.id === flatView[`level${level}Id`]);

  if (!existingItem) {
    // Level 1 not found in list. Make it.
    existingItem = formatBusinessTeam({
      id: flatView[`level${level}Id`],
      code: flatView[`level${level}Code`],
      name: flatView[`level${level}Name`],
      type: flatView[`level${level}Type`],
      isDeleted: flatView[`level${level}Deleted`],
      children: [],
    });

    addBusinessTeam(existingItem);
  }

  return existingItem;
}

export function formatBusinessViewFlatViewIntoBusinessViews(flatViews: any[]): IBusinessView[] {
  if (!flatViews) {
    return [];
  }

  let topLevels: IBusinessView[] = [];

  flatViews.forEach(flatView => {
    let lvl1 = findOrCreateBusinessView(topLevels,
      "1",
      flatView,
      r => topLevels.push(r));

    let lvl2 = findOrCreateBusinessView(topLevels,
      "2",
      flatView,
      r => lvl1.children.push(r));

    findOrCreateBusinessView(topLevels,
      "3",
      flatView,
      r => lvl2.children.push(r));
  });

  return topLevels;
}

function findOrCreateBusinessView(searchList: IBusinessView[],
  level: string,
  flatView: any,
  addBusinessTeam: (businessTeam: IBusinessView) => void): IBusinessView {
  let existingItem = searchList.find(x => x.id === flatView[`level${level}Id`]);

  if (!existingItem) {
    // Level 1 not found in list. Make it.
    existingItem = formatBusinessView({
      id: flatView[`level${level}Id`],
      code: flatView[`level${level}Code`],
      name: flatView[`level${level}Name`],
      type: flatView[`level${level}Type`],
      isDeleted: flatView[`level${level}Deleted`],
      children: [],
    });

    addBusinessTeam(existingItem);
  }

  return existingItem;
}

export function formatCalendarWeeks(data: any): ICalendarWeek[] {
  if (!data
    || !Array.isArray(data)) {
    return [];
  }

  return data.map(formatCalendarWeek);
}

export function formatCalendarWeek(item: any): ICalendarWeek {
  return {
    endDateTimestamp: new Date(item.weekEnd).getTime(),
    startDateTimestamp: new Date(item.weekStart).getTime(),
    week: Number(item.weekNumber),
    year: Number(item.year),
  }
}

export function formatMobileSite(data: any): IMobileSite {
  return {
    id: Number(data.id),
    country: data.country?.toString() || "",
    owner: data.owner?.toString() || "",
    rigKey: Number(data.rigKey),
    rigName: data.rigName?.toString() || "",
    drillingContractor: data.drillingContractor?.toString() || "",
    rigType: data.rigType?.toString() || "",
  };
}

export function formatVerificationMethod(data: any): IVerificationMethod {
  return {
    id: Number(data.id),
    isDeleted: Boolean(data.deleted),
    name: data.name?.toString() || "",
  };
}

export function formatApi(data: any): IApi {
  return {
    id: Number(data.id),
    isDeleted: Boolean(data.deleted),
    name: data.name?.toString() || "",
    spec: data.spec?.toString() || "",
    mainSection: data.mainSection?.toString() || "",
    subSection: data.subSection?.toString() || "",
    sortOrder: Number(data.sortOrder),
  };
}

export function formatIso(data: any): IIso {
  return {
    id: Number(data.id),
    isDeleted: Boolean(data.deleted),
    name: data.name?.toString() || "",
    spec: data.spec?.toString() || "",
    sortOrder: Number(data.sortOrder),
  };
}

export function formatHSESubElement(data: any): IHSESubElement {
  return {
    id: Number(data.id),
    isDeleted: Boolean(data.deleted),
    name: data.name?.toString() || "",
    mss: data.mss?.toString() || "",
    sortOrder: Number(data.sortOrder),
  };
}

export function formatQMSElement(data: any): IQMSElement {
  return {
    id: Number(data.id),
    isDeleted: Boolean(data.deleted),
    name: data.name?.toString() || "",
    mainElement: data.mainElement?.toString() || "",
    subElement: data.subElement?.toString() || "",
    sortOrder: Number(data.sortOrder),
  };
}

export function formatQuestionType(obj: any): IQuestionType {
  return {
    id: Number(obj.id),
    name: obj.name?.toString() || "",
    isDeleted: Boolean(obj.deleted),
  };
}

export function formatBusinessEpicType(obj: any): IBusinessEpic {
  return {
    id: Number(obj.id),
    code: obj.code?.toString(),
    name: obj.name?.toString(),
    type: obj.type?.toString(),
    isDeleted: Boolean(obj.deleted),
  };
}

export function formatIPMProject(obj: any): IIPMProject {
  return {
    id: Number(obj.id),
    code: obj.code?.toString(),
    name: obj.name?.toString(),
    type: obj.type?.toString() as ("IPMAsset" | "IPMProject" | "Other"),
    deleted: Boolean(obj.deleted),
    subGeoUnitCode: obj.subGeoUnitCode?.toString(),
    subGeoUnitId: Number(obj.subGeoUnitId),
    businessLineName: obj.businessLineName?.toString(),
    businessLineId: Number(obj.businessLineId),
    countryName: obj.countryName?.toString(),
  };
}

export function formatSupplier(obj: any): ISupplier {
  return {
    id: Number(obj.id),
    aslNumber: Number(obj.aslNumber),
    name: obj.name?.toString(),
    deleted: Boolean(obj.deleted),
  };
}

export function formatClmDocRef(obj: any): ICLMDocument {
  return {
    id: obj.id,
    name: obj.name,
  } as ICLMDocument;
}

export function formatClmFunction(obj: any): ICLMFunction {
  return {
    id: obj.id,
    name: obj.name,
  } as ICLMFunction;
}

export function formatClmWhomToCheck(obj: any): ICLMWhomToCheck {
  return {
    id: obj.id,
    name: obj.name,
  } as ICLMWhomToCheck;
}