import React from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import formatDate from "shared/utilities/dateFormatters";
import { IAuditPlanView } from "types/auditPlanningTypes";
import { AuditComplianceScoreIncludedQuestions, AuditSources, AuditStatuses, IAuditComplianceScore } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import "./GridCellTooltip.scoped.scss";

interface IGridCellTooltipProps {
  plan: IAuditPlanView,
  isLoadingScores: boolean,
  extraScores: IAuditComplianceScore[] | null,
  /** An optional props to pass children */
  children?: React.ReactNode,
}

const GridCellTooltip: React.FC<IGridCellTooltipProps> = ({
  plan,
  isLoadingScores,
  extraScores,
}) => {
  let tooltipContents: React.ReactNode | undefined;
  let auditSource = plan.auditSource === AuditSources.LegacyQuest
    ? "QUEST"
    : (plan.auditSource
      ? "Audit #"
      : undefined);

  if (plan.auditId
    && (plan.auditStatus === AuditStatuses.Closed
      || plan.auditStatus === AuditStatuses.Completed)) {
    // Closed or Completed audit.
    let compliance = plan.auditComplianceResult;
    let score = plan.auditComplianceScore;
    let leader = plan.auditLeadAuditorName;
    let date = plan.auditClosureDate || plan.auditCompletionDate;

    if (!compliance
      && !leader
      && !date) {
      return null;
    }

    tooltipContents = (
      <>
        {getTitle("Audit")}
        {!!auditSource
          && getLine(plan.legacyAuditNumber || plan.auditId?.toString(), auditSource)
        }
        {getAuditStatus(plan.auditStatus)}
        {getLeader(leader)}
        {date !== undefined ? getDate(date, "Date") : null}
        {!!compliance && (
          <span
            className="compliance-score"
          >
            Compliance: {compliance?.toString().replace(/([a-z])([A-Z])/g, "$1 $2")} {score !== undefined ? `(${Math.floor(score * 100)}%)` : ""}
          </span>
        )}
      </>
    );
  } else if (plan.auditId !== undefined
    && plan.auditStatus !== undefined) {
    // Open audit.
    let leader = plan.auditLeadAuditorName;
    let startDate = plan.auditStartDate;

    tooltipContents = (
      <>
        {getTitle("Audit")}
        {!!auditSource
          && getLine(plan.legacyAuditNumber || plan.auditId?.toString(), auditSource)
        }
        {getAuditStatus(plan.auditStatus)}
        {getLeader(leader)}
        {getDate(startDate, "Planned Date")}
      </>
    );
  } else {
    // No audit yet.
    let leader = plan.leadAuditorName;
    let week = plan.weekOfYear;

    tooltipContents = (
      <>
        {getTitle("Audit Plan")}
        <span>Status: {plan.approvalStatus.toString().replace("FinalApproval", "Approved")}</span>
        {getLeader(leader)}
        {getWeekNum(week)}
      </>
    );
  }

  return (
    <FlexCol
      className="cell-tooltip"
    >
      {tooltipContents}
      {isLoadingScores ? (
        <>
          <hr />
          <Spinner className="icon-medium" />
        </>
      ) : (
        <>
          {!!extraScores?.length && (
            <>
              <hr />
              {extraScores.map((score) => (
                <div
                  key={score.id}
                >
                  {getScoreLabel(score, plan)}: {score.complianceResult.replace(/([a-z])([A-Z])/g, "$1 $2")} ({Math.floor(score.complianceScore * 100)}%)
                </div>
              ))}
            </>
          )}
        </>
      )}
    </FlexCol>
  );
};

export default GridCellTooltip;

function getTitle(text: string) {
  return (
    <span
      className="title"
    >
      {text}
    </span>
  );
}

function getLine(data: string | undefined, title: string) {
  if (data === undefined) {
    return;
  }

  return (
    <span
      className="tooltipe-line"
    >
      {title}: {data}
    </span>
  )
}

function getLeader(leader: string | undefined) {
  if (!leader) {
    return null;
  }

  return (
    <span
      className="lead-auditor"
    >
      Lead Auditor: {leader}
    </span>
  );
}

function getWeekNum(weekNum: number | undefined) {
  if (!weekNum) {
    return null;
  }

  return (
    <span
      className="week"
    >
      Week: {weekNum}
    </span>
  );
}

function getDate(date: number | undefined, title: string) {
  if (!date) {
    return null;
  }

  return (
    <span
      className="date"
    >
      {title}: {formatDate(new Date(date))}
    </span>
  );
}

function getAuditStatus(auditStatus: AuditStatuses) {
  return (
    <span>Status: {auditStatus.toString().replace("Planned", "Planned by Lead Auditor").replace(/([a-z])([A-Z])/g, "$1 $2")}</span>
  );
}

function getScoreLabel(score: IAuditComplianceScore, plan: IAuditPlanView): string {
  if (plan.requirementDimensionType === MetaDataTypes.AuditTemplate) {
    // Template-type plans show different score labels. Instead of "Corporate",
    // they instead show the short names of the audit topics.
    return score.shortName || score.includedQuestions;
  } else {
    if (score.includedQuestions === AuditComplianceScoreIncludedQuestions.GlobalOnly) {
      return "Corporate";
    } else if (score.includedQuestions === AuditComplianceScoreIncludedQuestions.ChildDimensionOnly) {
      return (score.childDimensionType || "ChildDimension").replace(/([a-z])([A-Z])/g, "$1 $2");
    } else {
      return score.includedQuestions.toString();
    }
  }
}