import ActionSearchResultsPage from "components/search/actions/search-actions-results/ActionSearchResultsPage";
import AuditSearchResultsPage from "components/search/audits/search-audits-results/AuditSearchResultsPage";
import SearchPage from "components/search/SearchPage";
import React from "react";
import { Route } from "react-router-dom";
import UrlRoutes from "../UrlRoutes";

interface ISearchRoutesProps {
}

const SearchRoutes: React.FC<ISearchRoutesProps> = () =>
  <>
    <Route
      path={UrlRoutes.Search.urlTemplate + "/*"}
      element={<SearchPage />}
    />
    <Route
      path={UrlRoutes.AuditSearchResults.urlTemplate}
      element={<AuditSearchResultsPage />}
    />
    <Route
      path={UrlRoutes.ActionSearchResults.urlTemplate}
      element={<ActionSearchResultsPage />}
    />
  </>;

export default SearchRoutes;