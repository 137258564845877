import React from "react";
import { v4 as uuidv4 } from "uuid";

interface IMultilineTextProps {
  text?: string,
}

const MultilineText: React.FC<IMultilineTextProps> = ({ text }) =>
  text === undefined
    ? null
    : (
      <>
        {text.split('\n').map((line, ix) => (
          <div
            key={uuidv4()}
          >
            {line}
          </div>
        ))}
      </>
    );

export default MultilineText;