import { PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IActionItem } from "types/actionItemTypes";
import { IAllAuditScoreResults } from "types/audit-scores/auditScoreTypes";
import { IAnswer } from "types/auditMasterDataTypes";
import { IAuditInfo, IAuditQuestion } from "types/auditingTypes";
import { IAuditState } from "../AuditSlice";
import { IFinding, IFindingType } from "./findingReducers";

export interface IAuditSharedReducersState {
  loadAllDataOp?: IOperation<{
    auditInfo: IAuditInfo,
    auditQuestions: IAuditQuestion[],
    actionItems: IActionItem[],
    findings: IFinding[],
  }>,
}

export const initialAuditSharedReducers: IAuditSharedReducersState = {
  loadAllDataOp: undefined,
};

const auditSharedReducers = {
  /** Loads all the audit and related data for the specified auditId. */
  loadAllData: (state: IAuditState, _: PayloadAction<{
    auditId: number,
    includeAuditScores: boolean,
    loadFindingTypes: boolean,
  }>) => {
    state.loadAllDataOp = {
      isWorking: true,
    };
  },

  /** If the operation has an error message, it is updated in the loadAuditOp.
   * Otherwise, the loadAuditOp is cleared and the payload audit info is put
   * into audit and a copy is put into originalAudit.
   */
  finishLoadAllData: (state: IAuditState, action: PayloadAction<IOperation<{
    auditInfo: IAuditInfo,
    auditQuestions: IAuditQuestion[],
    actionItems: IActionItem[],
    answers: IAnswer[],
    findings: IFinding[],
    findingTypes: IFindingType[],
    auditScores?: IAllAuditScoreResults,
  }>>) => {
    if (action.payload.errorMessage) {
      state.loadAllDataOp = action.payload;
      return;
    }

    if (!action.payload.data) {
      state.loadAllDataOp = {
        ...action.payload,
        errorMessage: "No data was returned.",
      };
      return;
    }

    const {
      auditInfo,
      actionItems,
      auditQuestions,
      answers,
      findings,
      findingTypes,
      auditScores,
    } = action.payload.data;

    if (!auditInfo) {
      state.loadAllDataOp = {
        ...action.payload,
        errorMessage: "Audit data was not returned.",
      };
      return;
    }

    state.loadAllDataOp = undefined;

    state.audit = auditInfo;
    state.originalAudit = cloneDeep(auditInfo);
    state.isAuditDirty = false;

    state.questions = auditQuestions;
    state.originalQuestions = cloneDeep(auditQuestions);
    state.areQuestionsDirty = false;
    state.answers = answers;
    state.actionItems = actionItems;

    state.findings = findings;
    state.findingTypes = findingTypes;
    state.scores = auditScores;
  },
};

export default auditSharedReducers;