import config from "config";
import React from "react";
import { DataGridFilterOperator, IDataGridColumn } from "shared/components/layout/grid/types/dataGridTypes";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { IIPMProject } from "types/masterDataTypes";
import ODataPicker from "../odata-picker/ODataPicker";

interface IIPMProjectPickerProps {
  /** Optional. Used to filter projects by their subgeounit. */
  subGeoUnitIds?: number[],
  /** The callback used when the user applies their selection in the picker. */
  onSelectionChange: (items: IIPMProject[]) => void,
  /** The list of already-selected items. */
  selectedItems: IIPMProject[],
  /** Optional.
   * If true, deleted/inactive projects will be included in the search results.
   * Default = false. */
  includeDeleted?: boolean,
  /** Determines if the picker is disabled. Default = false. */
  isDisabled?: boolean,
  /** Determines which type of IPMProjects to show in the grid. */
  ipmProjectType: "IPMAsset" | "IPMProject",
  /** Optional. The selection mode (single or multiple). Default = "Single" */
  selectMode?: "Single" | "Multiple",
}

const ipmProjectGridColumns: IDataGridColumn[] = [
  { uid: "id", field: "id", headerText: "Id", type: "number", width: 130, visible: false, },
  { uid: "code", field: "code", headerText: "Code", type: "string", width: 130, },
  { uid: "name", field: "name", headerText: "Name", type: "string", width: 300, },
  { uid: "type", field: "type", headerText: "Type", type: "string", width: 180, visible: false, },
  { uid: "subGeoUnitCode", field: "subGeoUnitCode", headerText: "SubGeoUnit Code", type: "string", width: 130, },
  { uid: "subGeoUnitId", field: "subGeoUnitId", headerText: "SubGeoUnit Id", type: "string", width: 130, visible: false, },
  { uid: "businessLineName", field: "businessLineName", headerText: "Business Line", type: "string", width: 250, },
  { uid: "countryName", field: "countryName", headerText: "Country", type: "string", width: 250, },
  { uid: "deleted", field: "deleted", headerText: "Is Deleted", type: "boolean", width: 150, visible: false, },
];

const IPMProjectPicker: React.FC<IIPMProjectPickerProps> = (props) => {
  let queryString = '';

  if (props.subGeoUnitIds?.length) {
    queryString = "?" + props.subGeoUnitIds.map((id, ix) => `subGeoUnitIds[${ix}]=${id}`);
  }

  let pickerTitle = "";

  if (props.ipmProjectType === "IPMProject") {
    pickerTitle = "IPM Projects";
  } else if (props.ipmProjectType === "IPMAsset") {
    pickerTitle = "IPM Assets";
  } else {
    pickerTitle = "IPM Projects";
  }

  return (
    <ODataPicker<IIPMProject>
      keyMapper={item => item.id}
      oDataUrl={`${config.endpoints.baseUrl}/odata/ipmprojects${queryString}`}
      onSelectionChange={items => props.onSelectionChange(items)}
      selectedItems={props.selectedItems}
      renderSelectedItem={item => `${item.code} - ${item.name}`}
      selectMode={props.selectMode || "Single"}
      title={pickerTitle}
      sortSelectedItems={(a, b) => a.code < b.code ? -1 : 1}
      sortSettings={{
        columns: [{
          field: "code",
          direction: "Ascending",
        }]
      }}
      gridColumns={ipmProjectGridColumns}
      className="ipmproject-grid-picker"
      filterSettings={{
        columns: [
          {
            uid: "type",
            field: "type",
            operator: DataGridFilterOperator.Equals,
            value: props.ipmProjectType,
          },
          !props.includeDeleted
            ? {
              uid: "deleted",
              field: "deleted",
              operator: DataGridFilterOperator.Equals,
              value: false,
            } : undefined
        ].filter(isNotUndefined)
      }}
      isDisabled={props.isDisabled}
      maxSelectedItemsVisible={1}
    />
  );

};

export default IPMProjectPicker;