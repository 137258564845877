import { IRestrictedMetaData } from "shared/types/userProfileTypes";

/**
 * Check if the user meta data allows access to the provided item
 * @param item The item to check for 
 * @param userDerivedMetaData The user derived meta data that comes from the profile
 * @returns True if the user can access False if the user cant access
 */
export function canUserAccessItem(item: {id: number, type: string}, userDerivedMetaData: IRestrictedMetaData[]): boolean {
  return !userDerivedMetaData.some(x => x.type === item.type)
    || userDerivedMetaData.some(x => x.type === item.type && x.id === item.id);
}