import React from "react";
import Button from "shared/components/controls/buttons/button/Button";
import { clearFilters } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch } from "store/store";

const ClearFiltersButton: React.FC = () => {
  const dispatch = useAppDispatch();
  
  return (
    <Button
      buttonType="secondary"
      onClick={() => dispatch(clearFilters())}
    >
      Clear Filters
    </Button>
  );
};

export default ClearFiltersButton;