import React from "react";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import pluralize from "shared/utilities/pluralize";
import { IFinding } from "store/audit/reducers/findingReducers";
import ViewFindingBody from "../manage-finding-modal/modal-bodies/ViewFindingBody";

interface IViewFindingsModalProps {
  findings: IFinding[],
  onClose(): void,
}

const ViewFindingsModal: React.FC<IViewFindingsModalProps> = ({
  findings,
  onClose,
}) => {
  const modalHeader = findings.length > 1
    ? `${findings.length} ${pluralize(findings.length, "Finding", "Findings")}`
    : `Finding #${findings[0].id}`;

  return (
    <Modal
      header={modalHeader}
      showCloseButton
      onCloseButtonClicked={onClose}
      isOpen={true}
      buttons={[{
        key: "CLOSE",
        text: "Close",
        className: "primary",
        onClick: onClose,
      }]}
    >
      <FlexCol
        style={{ gap: "1em" }}
      >
        {findings.map(finding => (
          <Card
            key={finding.id}
            title={`Finding #${finding.id}`}
          >
            <FlexCol
              style={{ gap: "1em" }}
            >
              <ViewFindingBody
                finding={finding}
              />
            </FlexCol>
          </Card>
        ))}
      </FlexCol >
    </Modal >
  );
};

export default ViewFindingsModal;