import CLMApi from "api/masterdata/CLMApi";
import ComplianceResult from "components/audits/common/compliance-result/ComplianceResult";
import React, { useMemo, useState } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import Card from "shared/components/layout/card/Card";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { IColumnItem } from "shared/types/columnTypes";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";
import { userToString } from "shared/utilities/userUtilities";
import { NonConformanceTabs } from "store/audit-non-conformances/AuditNonConformancesSlice";
import { useAppSelector } from "store/store";
import { IAllAuditScoreResults, IAuditScoreResult } from "types/audit-scores/auditScoreTypes";
import { ComplianceResults } from "types/auditPlanningTypes";
import { AuditStatuses, IAuditInfo, IAuditQuestion } from "types/auditingTypes";
import ICLMDocRef from "types/clm/ICLMDocRef";
import { MetaDataTypes } from "types/masterDataTypes";

const CLMProcedureResultsCard: React.FC = () => {
  const auditComplianceScores = useAppSelector(store => store.audit.scores);
  const selectedComplianceScore = useAppSelector(store => store.auditNonConformance.selectedComplianceTab);
  const questions = useAppSelector(store => store.audit.questions);
  const auditInfo = useAppSelector(store => store.audit.audit);

  const [clmDocRefs, setClmDocRefs] = useState<ICLMDocRef[]>([]);
  const [isLoadingClmDocRefs, setIsLoadingClmDocRefs] = useState<boolean>(false);

  const auditSubTopicIds =
    auditInfo?.auditTopics
      .filter(x => !!x.parentId)
      .map(x => x.id) ?? [];

  // Get the CLM Doc Refs for this Audit from the server.
  useAsyncEffect(async () => {
    try {
      if (auditSubTopicIds.length === 0) {
        setClmDocRefs([]);
      } else {
        setIsLoadingClmDocRefs(true);
        setClmDocRefs(await CLMApi.getCLMDocRefs(auditSubTopicIds));
      }
    } finally {
      setIsLoadingClmDocRefs(false);
    }
  }, [setClmDocRefs, setIsLoadingClmDocRefs, JSON.stringify(auditSubTopicIds)]);

  // Get only the subtopic scores.
  const tableRows = useMemo(() => getTableRows(auditComplianceScores, selectedComplianceScore, auditInfo, questions, clmDocRefs),
    [auditComplianceScores, selectedComplianceScore, auditInfo, questions, clmDocRefs]);

  return (
    <Card
      title="Audit Results By Procedure (Subtopics)"
      cardStateId={`AuditResultNonConformance_CLMProcedureResults`}
    >
      {isLoadingClmDocRefs
        ? <Spinner />
        : (
          <Table<ICLMResultRow, keyof ICLMResultRow, void>
            data={tableRows}
            columns={getTableColumns()}
            keyProp="clmDocRefId"
            theme={TableThemes.compact}
          />
        )}
    </Card>
  );
};

export default CLMProcedureResultsCard;

interface ICLMResultRow {
  clmDocRefId: number,
  pdmsNum: string,
  clmDocRef: string,
  auditor: string,
  auditPackages: string,
  percentUnanswered: number | undefined,
  percentAnswerNRA: number | undefined,
  percentAnswerNOP: number | undefined,
  percentAnswerEXM: number | undefined,
  compliance: ComplianceResults | undefined,
  cat1Percent: number | undefined,
  cat2Percent: number | undefined,
}

function getTableRows(auditComplianceScores: IAllAuditScoreResults | undefined,
  selectedComplianceScore: NonConformanceTabs,
  auditInfo: IAuditInfo | undefined,
  questions: IAuditQuestion[] | undefined,
  clmDocRefs: ICLMDocRef[]): ICLMResultRow[] {
  if (!clmDocRefs?.length
    || !auditInfo
    || !questions?.length) {
    return [];
  }

  let visibleComplianceScores: IAuditScoreResult[] | undefined = undefined;
  let auditStatusFilter: AuditStatuses = auditInfo.status;

  if (selectedComplianceScore === NonConformanceTabs.current) {
    visibleComplianceScores = auditComplianceScores?.currentScores?.metaScores;
  } else if (selectedComplianceScore === NonConformanceTabs.complete) {
    visibleComplianceScores = auditComplianceScores?.completedScores?.metaScores;
    auditStatusFilter = AuditStatuses.Completed;
  } else if (selectedComplianceScore === NonConformanceTabs.closed) {
    visibleComplianceScores = auditComplianceScores?.closedScores?.metaScores;
    auditStatusFilter = AuditStatuses.Closed;
  }

  // Since a subtopic score is generated for the "global" dimension (with no child)
  // as well as every child dimension in the audit, and all subtopic scores are
  // identical because they include ALL subtopic questions regardless, just grab
  // the global ones (no child) since we don't want to show duplicates.
  // Note: If the audit has >1 parent dimension, then there will be duplicates.
  const subtopicScores = visibleComplianceScores
    ?.filter(x => x.scoreByType === MetaDataTypes.AuditTopic
      && x.includedQuestions === "AllSubtopicQuestions"
      && x.childDimensionId === undefined
      && x.childDimensionType === undefined)
    ?? [];

  return clmDocRefs
    .map((clmDocRef): ICLMResultRow => {
      // Find the subtopic score.
      const score = subtopicScores
        .find(x => x.scoreById === clmDocRef.auditTopicId);

      const subtopicQuestionsOnly =
        questions
          .filter(q => q.subTopics?.some(st => st.id === clmDocRef.auditTopicId ?? 0));

      const unansweredCount = subtopicQuestionsOnly
        .filter(q =>
          !q.responses.some(r => r.auditStatus === auditStatusFilter)
        ).length;

      return {
        clmDocRefId: clmDocRef.id,
        pdmsNum: clmDocRef.dms,
        clmDocRef: clmDocRef.name,
        auditor: getDistinctAuditorsFromQuestions(subtopicQuestionsOnly)
          .join(", "),
        auditPackages: clmDocRef.auditPackage,
        percentUnanswered: subtopicQuestionsOnly.length
          ? unansweredCount / subtopicQuestionsOnly.length
          : undefined,
        percentAnswerNRA: getAnswerPercent("NRA", subtopicQuestionsOnly, auditStatusFilter),
        percentAnswerNOP: getAnswerPercent("NOP", subtopicQuestionsOnly, auditStatusFilter),
        percentAnswerEXM: getAnswerPercent("EXM", subtopicQuestionsOnly, auditStatusFilter),
        compliance: score?.complianceResult,
        cat1Percent: score?.cat1Score,
        cat2Percent: score?.cat2Score,
      };
    }).sort((a, b) => {
      if (a.auditPackages === b.auditPackages) {
        return a.clmDocRef < b.clmDocRef ? -1 : 1;
      } else {
        return a.auditPackages < b.auditPackages ? -1 : 1;
      }
    });
}

function getDistinctAuditorsFromQuestions(questions: IAuditQuestion[] | undefined) {
  if (!questions?.length) {
    return [];
  }

  const distinctEmails = Array.from(new Set(questions.map(q => q.auditorEmail)));

  return distinctEmails
    .map(email => ({
      email,
      name: questions.find(q => q.auditorEmail === email)?.auditorName ?? "",
    })).sort((a, b) => a.name < b.name ? -1 : 1)
    .map(x => userToString(x));
}

function getAnswerPercent(answer: string,
  questions: IAuditQuestion[],
  auditStatus: AuditStatuses) {
  if (questions.length === 0) {
    return undefined;
  }

  let numQuestionsWithThisResponse = questions
    .flatMap(x => x.responses.filter(r => r.auditStatus === auditStatus
      && r.answer === answer)).length;

  return numQuestionsWithThisResponse / questions.length;
}

export function getTableColumns(): IColumnItem<ICLMResultRow, keyof ICLMResultRow, void>[] {
  return [
    {
      key: "pdmsNum",
      header: "PDMS#",
      width: 100,
      maxHeight: "4em",
      property: "pdmsNum",
    },
    {
      key: "clmDocRef",
      header: "CLM Document Reference",
      width: 250,
      maxHeight: "4em",
      property: "clmDocRef",
    },
    {
      key: "auditor",
      header: "Auditor",
      width: 150,
      maxHeight: "4em",
      property: "auditor",
    },
    {
      key: "auditPackages",
      header: "Audit Packages",
      width: 150,
      maxHeight: "4em",
      property: "auditPackages",
    },
    {
      key: "percentUnanswered",
      header: "Unanswered",
      width: 100,
      maxHeight: "4em",
      property: "percentUnanswered",
      customRender: row => row.percentUnanswered !== undefined
        ? Math.floor(row.percentUnanswered * 100).toString() + "%"
        : "0%",
    },
    {
      key: "numAnswerNRA",
      header: "Not req. N/A",
      width: 100,
      maxHeight: "4em",
      property: "percentAnswerNRA",
      customRender: row => row.percentAnswerNRA !== undefined
        ? Math.floor(row.percentAnswerNRA * 100).toString() + "%"
        : "0%",
    },
    {
      key: "numAnswerNOP",
      header: "No Opportunity",
      width: 100,
      maxHeight: "4em",
      property: "percentAnswerNOP",
      customRender: row => row.percentAnswerNOP !== undefined
        ? Math.floor(row.percentAnswerNOP * 100).toString() + "%"
        : "0%",
    },
    {
      key: "numAnswerEXM",
      header: "Exempted",
      width: 100,
      maxHeight: "4em",
      property: "percentAnswerEXM",
      customRender: row => row.percentAnswerEXM !== undefined
        ? Math.floor(row.percentAnswerEXM * 100).toString() + "%"
        : "0%",
    },
    {
      key: "compliance",
      header: "Compliance Grade",
      width: 125,
      maxHeight: "4em",
      property: "compliance",
      customRender: x => x.compliance !== undefined
        ? (
          <ComplianceResult
            complianceResult={x.compliance}
          />
        ) : "--",
    },
    {
      key: "cat1Percent",
      header: "Cat. 1",
      width: 100,
      maxHeight: "4em",
      property: "cat1Percent",
      customRender: x => x.cat1Percent !== undefined
        ? Math.floor(x.cat1Percent * 100).toString() + "%"
        : "--",
    },
    {
      key: "cat2Percent",
      header: "Cat. 2",
      width: 100,
      maxHeight: "4em",
      property: "cat2Percent",
      customRender: x => x.cat2Percent !== undefined
        ? Math.floor(x.cat2Percent * 100).toString() + "%"
        : "--",
    },
  ];
}