import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import pluralize from "shared/utilities/pluralize";
import { ITopicActionItemData } from "../AuditRWPPage";
import ItemTable from "../item-table/ItemTable";
import "./TopicCard.scoped.scss";

interface ITopicCardProps {
  title: string,
  actionItems: ITopicActionItemData[],
  numQuestions: number,
  topicId: number,
}

const TopicCard: React.FC<ITopicCardProps> = ({
  title,
  actionItems,
  numQuestions,
  topicId,
}) =>
  <Card
    title={title}
    cardStateId={`auditRWPTopicCard_${topicId}`}
    defaultCollapsedState={false}
    headerElement={(
      <div
        className="labels"
      >
        {numQuestions !== undefined && (
          <LabeledControl
            label="# of Questions"
            isLight={true}
          >
            {numQuestions} {pluralize(numQuestions, "question", "questions")}
          </LabeledControl>
        )}

        <LabeledControl
          label="# of Action Items"
          isLight={true}
        >
          {actionItems.length} {pluralize(actionItems.length, "item", "items")}
        </LabeledControl>
      </div>
    )}
  >
    <ItemTable
      actionItems={actionItems}
      linkTypeToRemove="AuditQuestion"
    />
  </Card>;

export default TopicCard;