import { groupBy } from "lodash";
import React, { useMemo } from "react";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { findActionItemsByParentItems } from "shared/utilities/actionItemUtilities";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { useAppSelector } from "store/store";
import { ActionItemLinkTypes, IActionItem } from "types/actionItemTypes";
import { IAuditScoreResult } from "types/audit-scores/auditScoreTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { IAuditInfo, IAuditQuestion } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import { hseOnlyColumns } from "./AuditResultCard";
import { IScoreTableItem } from "./auditResultsTypes";
import { getAuditResultsTableColumns } from "./sharedTableColumns";

interface ITopicAssociationsCardProps {
  /** The list of Audit Scores specific to this topic. */
  topicScores: IAuditScoreResult[],
  /** The scoring system of thte parent audit. */
  scoringSystem: string,
}

const TopicAssociationsCard: React.FC<ITopicAssociationsCardProps> = ({
  topicScores,
  scoringSystem,
}: ITopicAssociationsCardProps) => {
  const auditInfo = useAppSelector(store => store.audit.audit);
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const allQuestions = useAppSelector(store => store.audit.questions);

  const auditMeta: ITypeIdAndName[] = useMemo(() => {
    return auditInfo
      ? convertAuditInfoToMetaList(auditInfo)
      : [];
  }, [auditInfo]);

  if (!auditInfo) {
    return null;
  }

  let tableRows = topicScores
    .map(item => formatScoreToTableItem(item, auditMeta, actionItems, allQuestions))
    .filter(isNotUndefined);

  const scoreGroups = Object.entries(groupBy(tableRows, "type"))
    .sort((a, b) => typeToSortOrder(a[0]) < typeToSortOrder(b[0]) ? -1 : 1);

  const globalAndSubtopicGroups = scoreGroups
    .filter(x => x[0] === "GlobalTopic"
      || x[0] === "SubTopic");

  const metaDataGroups = Object.entries(groupBy(scoreGroups
    .find(x => x[0] === "MetaData")
    ?.[1] ?? [],
    "requirementType"));

  return (
    <Card
      title="Associations"
    >
      <FlexCol>
        {globalAndSubtopicGroups
          .map(group => {
            let tableColumns = getAuditResultsTableColumns([]).filter(x => x.key !== "toggle");

            if (scoringSystem === AuditScoringSystems.QMS) {
              tableColumns = tableColumns.filter(x => x.key !== "toggle"
                && !hseOnlyColumns.includes(x.key));
            } else if (scoringSystem === AuditScoringSystems.CLM) {
              tableColumns = tableColumns.filter(x => !hseOnlyColumns.includes(x.key));
            }

            const reqCol = tableColumns.find(x => x.key === "requirement");

            if (reqCol) {
              reqCol.header =
                <span>
                  {group[0] === "GlobalTopic"
                    ? "Global"
                    : (group[0] === "SubTopic"
                      ? "Sub-Topics"
                      : group[0])}
                </span>;
            }

            return (
              <Table<IChildTableRow, keyof IChildTableRow, void>
                data={group[1].slice().sort((a, b) => a.requirementName < b.requirementName ? -1 : 1)}
                columns={tableColumns}
                keyProp="requirementKey"
                theme={TableThemes.compact}
                key={group[0]}
              />
            );
          })}

        {metaDataGroups
          .filter(x => x[0] === MetaDataTypes.BusinessFunction
            || x[0] === MetaDataTypes.BusinessTeam
            || x[0] === MetaDataTypes.BusinessView
            || x[0] === MetaDataTypes.Country)
          .map(group => {
            let columns = getAuditResultsTableColumns([])
              .filter(x => x.key !== "toggle");

            const reqCol = columns.find(x => x.key === "requirement");

            if (reqCol) {
              reqCol.header =
                <span>
                  {mapMetaTypeToLabel(group[0])}
                </span>;
            }

            return (
              <Table<IChildTableRow, keyof IChildTableRow, void>
                data={group[1].slice().sort((a, b) => a.requirementName < b.requirementName ? -1 : 1)}
                columns={columns}
                keyProp="requirementKey"
                theme={TableThemes.compact}
                key={group[0]}
              />
            );
          })}
      </FlexCol>
    </Card>
  );
};

export default TopicAssociationsCard;

function formatScoreToTableItem(score: IAuditScoreResult,
  auditMeta: ITypeIdAndName[],
  allActionItems: IActionItem[],
  allQuestions: IAuditQuestion[]): IChildTableRow | undefined {
  // Figure out what to set the label as.
  let reqId: number | undefined = undefined;
  let reqType: string | undefined = undefined;
  let reqName: string | undefined = undefined;
  let reqKey: string | undefined = undefined;
  let type: "GlobalTopic" | "SubTopic" | "MetaData" | undefined = undefined;

  if (score.includedQuestions === "ChildDimensionOnly"
    && score.childDimensionId
    && score.childDimensionType) {
    reqId = score.childDimensionId;
    reqType = score.childDimensionType;
    reqName = auditMeta
      .find(x => x.id === score.childDimensionId
        && x.type === score.childDimensionType)?.name
      ?? `${score.childDimensionType} ${score.childDimensionId}`;
    reqKey = `${score.childDimensionType}_${score.childDimensionId}`;
    type = "MetaData";
  } else if (score.includedQuestions === "GlobalOnly"
    || score.includedQuestions === "AllSubtopicQuestions") {
    reqId = score.scoreById;
    reqType = score.scoreByType;
    reqName = score.includedQuestions === "GlobalOnly"
      ? "Global"
      : auditMeta
        .find(x => x.id === score.scoreById
          && x.type === score.scoreByType)?.name
      ?? `${score.scoreByType} ${score.scoreById}`;
    reqKey = `${score.scoreByType}_${score.scoreById}`;
    type = score.includedQuestions === "GlobalOnly"
      ? "GlobalTopic"
      : "SubTopic";
  }

  // If the label couldn't be determined, skip this item.
  if (reqId === undefined
    || reqType === undefined
    || reqName === undefined
    || reqKey === undefined
    || type === undefined) {
    return undefined;
  }

  // Format the item into a displayable row.
  return {
    numActionItems: countActionItems(score, allQuestions, allActionItems),
    complianceResult: score.complianceResult,
    complianceScore: score.complianceScore,
    cat1Score: score.cat1Score !== undefined
      ? score.cat1Score
      : undefined,
    cat2Score: score.cat2Score !== undefined
      ? score.cat2Score
      : undefined,
    nonCompliantQuestions: score.numNonComplianceQuestions,
    requirementId: reqId,
    requirementType: reqType,
    requirementKey: reqKey,
    requirementName: reqName,
    totalQuestions: score.numQuestionsTotal,
    hasChildItems: false,
    isChildRow: false,
    type: type,
    ltoCompliance: score.ltoComplianceScore,
    numLTONonCompliantQuestions: score.numLTONonCompliantQuestions,
    numLTOQuestions: score.numLTOQuestions,
  };
}

interface ITypeIdAndName {
  id: number,
  type: string,
  name: string,
}

function convertAuditInfoToMetaList(auditInfo: IAuditInfo): ITypeIdAndName[] {
  return auditInfo.businessFunctions.map(x => mapItem(x, MetaDataTypes.BusinessFunction))
    .concat(auditInfo.businessTeams.map(x => mapItem(x, MetaDataTypes.BusinessTeam)))
    .concat(auditInfo.businessViews.map(x => mapItem(x, MetaDataTypes.BusinessView)))
    .concat(auditInfo.ipmProjects.map(x => mapItem(x, MetaDataTypes.IPMProject)))
    .concat(auditInfo.countries.map(x => mapItem(x, MetaDataTypes.Country)))
    .concat(auditInfo.mobileSites.map(x => mapItem({ id: x.id, name: x.rigName }, MetaDataTypes.MobileSite)))
    .concat(auditInfo.suppliers.map(x => mapItem(x, MetaDataTypes.Supplier)))
    .concat(auditInfo.auditTopics.map(x => mapItem(x, MetaDataTypes.AuditTopic)));
}

function mapItem(item: { id: number, name: string, }, type: string): ITypeIdAndName {
  return {
    id: item.id,
    name: item.name,
    type,
  };
}

interface IChildTableRow extends IScoreTableItem {
  type: "GlobalTopic" | "SubTopic" | "MetaData",
}

function typeToSortOrder(type: string): number {
  switch (type) {
    case "GlobalTopic": return 0;
    case "SubTopic": return 1;
    case "MetaData": return 2;
    default: return 999;
  }
}

function mapMetaTypeToLabel(type: string) {
  return type.replaceAll(/([a-z])([A-Z])/g, "$1 $2");
}

function countActionItems(score: IAuditScoreResult, allQuestions: IAuditQuestion[], allActionItems: IActionItem[]): number {
  const {
    childDimensionId,
    childDimensionType,
    scoreById,
    includedQuestions,
  } = score;

  const isGlobal = childDimensionType === undefined
    && childDimensionId === undefined;

  const isSubTopic = includedQuestions === "AllSubtopicQuestions";

  let filteredQuestions: IAuditQuestion[] = [];

  if (isSubTopic) {
    // If this is a subtopic row, find questions that have this subtopic.
    filteredQuestions = allQuestions
      .filter(x => x.subTopics.some(st => st.id === scoreById));
  } else if (isGlobal) {
    // If this is the global row, find only the global questions.
    filteredQuestions = allQuestions.filter(x => x.isGlobal
      && x.topicId === scoreById);
  } else if (!!childDimensionType
    && !!childDimensionId) {
    // If this is a child dimension row (country, business view, etc.), find the matching questions.
    filteredQuestions = allQuestions.filter(x => !x.isGlobal
      && isQuestionChildMatch(x, childDimensionType, childDimensionId));
  }

  return findActionItemsByParentItems(
    allActionItems,
    filteredQuestions.map(x => ({
      linkType: ActionItemLinkTypes.AuditQuestion,
      linkId: x.auditQuestionId,
    }))
  ).length;
}

function isQuestionChildMatch(question: IAuditQuestion, type: string, id: number) {
  switch (type as MetaDataTypes) {
    case MetaDataTypes.BusinessFunction:
      return question.businessFunctions.some(x => x.id === id);
    case MetaDataTypes.BusinessTeam:
      return question.businessTeams.some(x => x.id === id);
    case MetaDataTypes.BusinessView:
      return question.businessViews.some(x => x.id === id);
    case MetaDataTypes.Country:
      return question.countries.some(x => x.id === id);
    default:
      return false;
  }
}
