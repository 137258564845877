import { IVerificationMethod } from "types/auditMasterDataTypes";
import "./VerMethodInput.scoped.scss";

interface IVerMethodInput {
  uniqueId: number,
  verMethods: IVerificationMethod[],
  selectedVerMethodIds: number[],
  onVerMethodChanged(verMethod: IVerificationMethod, isSelected: boolean): void,
  divProps?: React.HTMLProps<HTMLDivElement>,
  tooltip?: string,
  isDisabled: boolean,
}

const VerMethodInput: React.FC<IVerMethodInput> = ({
  uniqueId,
  verMethods,
  selectedVerMethodIds,
  onVerMethodChanged,
  divProps,
  tooltip,
  isDisabled,
}) =>
  <div
    {...divProps}
  ><div className="method-list">
    {verMethods.map(verMethod => (
      <label
        key={verMethod.id}
        className="method-item">
        <input
          type="checkbox"
          name={`question_verMethod_${uniqueId}`}
          value={verMethod.id}
          onChange={e => onVerMethodChanged(verMethod, e.currentTarget.checked)}
          checked={selectedVerMethodIds.indexOf(verMethod.id) > -1}
          disabled={isDisabled}
          title={tooltip}
        />
        {verMethod.name}
      </label>
    ))}
    </div>
  </div>;

export default VerMethodInput;
