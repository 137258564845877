import UrlRoutes from "components/routing/UrlRoutes";
import config from "config";
import React from "react";
import { useDispatch } from "react-redux";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Button from "shared/components/controls/buttons/button/Button";
import DataGrid from "shared/components/layout/grid/DataGrid";
import Page from "shared/components/layout/page/Page";
import { useAppSelector } from "store/store";
import AuditTopicManagerModal from "./modals/AuditTopicManagerModal";
import { setValue, IAuditTopicGridRowObject, confirmRevertAuditTopicStatus } from "store/audit-topics/AuditTopicSlice";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { formatOwnerGroup } from "api/auditing/OwnerGroupsApi";
import { formatAuditGroup } from "api/masterdata/formatters/masterDataFormatters";
import { formatAuditTopic } from "api/masterdata/AuditTopicApi";
import ToggleSwitch from "shared/components/controls/toggle-switch/ToggleSwitch";
import { cloneDeep } from "lodash";

const UM_GRID_COL_WIDTHS = {
  XSMALL: 75,
  SMALL: 175,
  MEDIUM: 200,
  LARGE: 250,
  XLARGE: 350,
};

const AuditTopicsPage: React.FC = () => {
  const dispatch = useDispatch();
  const isManagerOpen = useAppSelector(store => store.auditTopicManagement.isManagerOpen);
  const isRevertConfirmationOpen = useAppSelector(store => store.auditTopicManagement.isRevertConfirmationOpen);
  const loadOp = useAppSelector(store => store.auditTopicManagement.loadOp);
  const deleteOp = useAppSelector(store => store.auditTopicManagement.deleteOp);
  const saveOp = useAppSelector(store => store.auditTopicManagement.saveOp);

  const openManager = (auditTopic: Partial<IAuditTopic>) => {
    dispatch(setValue({
      isManagerOpen: true,
      managerValues: auditTopic,
      original: cloneDeep(auditTopic),
      isDirty: false,
    }));
  };

  const onRowDoubleClick = (rowData: any) => {
    const auditTopic: IAuditTopic = {
      id: Number(rowData.id ?? 0),
      name: rowData.name,
      shortName: rowData.shortName,
      parentId: Number(rowData.parentId ?? 0),
      parent: formatAuditTopic({
        id: Number(rowData.parentId ?? 0),
        name: rowData.parentName,
        sortOrder: 0,
        level: 0,
        firstPartyFrequency: 0,
        selfAssessmentFrequency: 0
      }),
      isPlannable: Boolean(rowData.plannable ?? false),
      isDeleted: Boolean(rowData.deleted ?? false),
      ownerGroupId: Number(rowData.ownerGroupId ?? 0),
      ownerGroupName: rowData.ownerGroup,
      ownerGroup: formatOwnerGroup({
        id: Number(rowData.ownerGroupId ?? 0),
        name: rowData.ownerGroup,
        deleted: false
      }),
      auditGroupId: Number(rowData.auditGroupId ?? 0),
      auditGroupName: rowData.auditGroup,
      auditGroup: formatAuditGroup({
        id: Number(rowData.auditGroupId ?? 0),
        name: rowData.auditGroup, deleted: false
      }),
      firstPartyFrequency: Number(rowData.firstPartyFrequency ?? 0),
      selfAssessmentFrequency: Number(rowData.selfAssessmentFrequency ?? 0),
      level: 0,
      sortOrder: 0,
      scoringSystem: "",
    }
    openManager(auditTopic);
  };

  return (
    <Page
      title="Audit Topics & Sub-Topics"
      showBreadcrumbs={true}
      backButtonRoute={UrlRoutes.AuditAdmin.urlTemplate}
      headerControls={[
        {
          element: (
            <Button
              buttonType="primary"
              onClick={() => openManager({})}
            >
              ADD TOPIC/ SUB-TOPIC
            </Button>
          ),
          key: "ADD TOPIC/ SUB-TOPIC",
        },
      ]}
    >
      <DataGrid
        id="AuditTopicsGrid"
        dataSource="OData"
        dataUrl={`${config.endpoints.baseUrl}/odata/auditTopics`}
        showColumnChooser={true}
        allowExcelExport={true}
        sortSettings={{
          columns: [{
            field: "name",
            direction: "Ascending",
          }],
        }}
        gridColumns={[
          {
            uid: "id",
            field: "id",
            headerText: "Id",
            type: "string",
            width: UM_GRID_COL_WIDTHS.SMALL,
            visible: false,
          },
          {
            uid: "name",
            field: "name",
            headerText: "Topic/ Sub-Topic Name",
            type: "string",
            width: UM_GRID_COL_WIDTHS.XLARGE,
          },
          {
            uid: "shortName",
            field: "shortName",
            headerText: "Short Name",
            type: "string",
            width: UM_GRID_COL_WIDTHS.XLARGE,
          },
          {
            uid: "parentName",
            field: "parentName",
            headerText: "Parent Name",
            type: "string",
            width: UM_GRID_COL_WIDTHS.XLARGE,
          },
          {
            uid: "auditGroup",
            field: "auditGroup",
            headerText: "Audit Group",
            type: "string",
            width: UM_GRID_COL_WIDTHS.MEDIUM,
          },
          {
            uid: "ownerGroup",
            field: "ownerGroup",
            headerText: "Owner Group",
            type: "string",
            width: UM_GRID_COL_WIDTHS.LARGE,
          },
          {
            uid: "plannable",
            field: "plannable",
            headerText: "Is Plannable",
            type: "boolean",
            width: UM_GRID_COL_WIDTHS.SMALL,
          },
          {
            uid: "firstPartyFrequency",
            field: "firstPartyFrequency",
            headerText: "1st Party Frequency (Months)",
            type: "number",
            width: UM_GRID_COL_WIDTHS.XLARGE,
          },
          {
            uid: "selfAssessmentFrequency",
            field: "selfAssessmentFrequency",
            headerText: "Self Assessment Frequency (Months)",
            type: "number",
            width: UM_GRID_COL_WIDTHS.XLARGE,
          },
          {
            uid: "deleted",
            type: "unbound",
            width: UM_GRID_COL_WIDTHS.XSMALL,
            allowFiltering: false,
            allowSorting: false,
            showInColumnChooser: false,
            template: (row: IAuditTopicGridRowObject) =>
              <ToggleSwitch
                key={row.id}
                isChecked={!Boolean(row.deleted)}
                onChange={() => dispatch(confirmRevertAuditTopicStatus(row))}
                label=""
              />
          },
        ]}
        recordDoubleClick={onRowDoubleClick}
      />

      {(isManagerOpen || isRevertConfirmationOpen) && <AuditTopicManagerModal />}

      {(loadOp?.isWorking
        || saveOp?.isWorking
        || deleteOp?.isWorking) && (
          <ModalSpinner />
        )}

    </Page>
  );
};

export default AuditTopicsPage;
