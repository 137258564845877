export interface IBusinessView {
  id: number,
  code: string,
  name: string,
  type: BusinessViewType,
  isDeleted: boolean,
  children: IBusinessView[],
}

export type BusinessViewType = "Division" | "BusinessLine" | "SubBusinessLine" | "Support";

export interface IBusinessFunction {
  id: number,
  code: string,
  name: string,
  type: BusinessFunctionType,
  isDeleted: boolean,
  children: IBusinessFunction[],
}

export type BusinessFunctionType = "Function" | "SubFunction" | "FunctionCategory";

export interface IBusinessTeam {
  id: number,
  code: string,
  name: string,
  type: BusinessTeamType,
  isDeleted: boolean,
  children: IBusinessTeam[],
}

export type BusinessTeamType = "Basin" | "GeoUnit" | "SubGeoUnit" | "GeoLevel1";

export interface ICountry {
  id: number,
  name: string,
}

export interface IFacility {
  id: number,
  commonId: number,
  name: string,
  isDeleted: boolean,
  subGeoUnitId?: number,
}

export interface ICalendarWeek {
  year: number,
  week: number,
  startDateTimestamp: number,
  endDateTimestamp: number,
}

export interface IMobileSite {
  id: number,
  rigType: string,
  owner: string,
  rigName: string,
  drillingContractor: string,
  rigKey: number,
  country: string,
}

export interface IBusinessEpic {
  id: number,
  code: string,
  name: string,
  type: BusinessEpicType,
  isDeleted: boolean,
}

export enum BusinessEpicType {
  ProductFamily = "ProductFamily",
  PLTechnology = "PLTechnology",
  Brand = "Brand",
  EquipmentCode = "EquipmentCode"
};

export enum MetaDataTypes {
  API = "API",
  ISO = "ISO",
  AuditType = "AuditType",
  BusinessView = "BusinessView",
  Country = "Country",
  AuditTopic = "AuditTopic",
  VerMethod = "VerMethod",
  Facility = "Facility",
  BusinessTeam = "BusinessTeam",
  BusinessFunction = "BusinessFunction",
  OwnerGroup = "OwnerGroup",
  AuditTemplate = "AuditTemplate",
  AuditGroup = "AuditGroup",
  MobileSite = "MobileSite",
  HSESubElement = "HSESubElement",
  QMSElement = "QMSElement",
  QuestionCategory = "QuestionCategory",
  QuestionType = "QuestionType",
  BusinessEpic = "BusinessEpic",
  IPMProject = "IPMProject",
  Supplier = "Supplier",
  Perspective = "Perspective",
  PerspectiveXAxes = "PerspectiveXAxes",
  ClmDocument = "CLMDocument",
  ClmWhomToCheck = "CLMWhomToCheck",
  ClmFunction = "CLMFunction",
}

export interface IIPMProject {
  id: number,
  code: string,
  name: string,
  type: "IPMAsset" | "IPMProject" | "Other",
  deleted: boolean,
  subGeoUnitCode: string,
  subGeoUnitId: number,
  businessLineName: string,
  businessLineId: number,
  countryName: string,
}

export interface ISupplier {
  id: number,
  aslNumber: number,
  name: string,
  deleted: boolean,
}

export interface ICLMDocument {
  id: number,
  name: string,
}

export interface ICLMWhomToCheck {
  id: number,
  name: string,
}

export interface ICLMFunction {
  id: number,
  name: string,
}