import React, { useState } from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditorPicker from "shared/components/controls/pickers/AuditorPicker";
import WeekPickerModal from "shared/components/controls/week-picker/WeekPickerModal";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { setApprovalItemLeadAuditor, setApprovalItemWeekNumber } from "store/audit-plan-approvals/AuditPlanApprovalsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditorSearchTypes } from "types/auditingTypes";
import "./AuditPlansCard.scoped.scss";

const ApproveAuditPlansTable: React.FC = () => {
  const items = useAppSelector(store => store.auditPlanApprovals.approvalModal.items);
  const auditType = useAppSelector(store => store.auditPlanning.appliedFilters?.auditType);

  const [currWeekPlanId, setCurrWeekPlanId] = useState<number | undefined>(undefined);
  const [isWeekPickerVisible, setIsWeekPickerVisible] = useState(false);
  const dispatch = useAppDispatch();

  const currWeekPlan = items.find(x => x.plan.id === currWeekPlanId);

  return (
    <div
      className={"sticky-wrapper"}
    >
      <table
        className={"auditPlan-table"}
      >
        <thead>
          <tr>
            <th>
              Requirement
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr
              key={item.plan.id}
            >
              <td>
                {item.requirementDimension?.text
                  || `${item.plan.requirementDimensionType} ${item.plan.requirementDimensionId}`}
              </td>
              <td>
                <div
                  className="card-row"
                >
                  <LabeledControl
                    label="Audit Week"
                  >
                    <button
                      className="secondary audit-week-button"
                      onClick={() => {
                        setCurrWeekPlanId(item.plan.id);
                        setIsWeekPickerVisible(true);
                      }}
                    >
                      {item.weekNumber
                        ? item.weekNumber
                        : "--"
                      }
                    </button>
                  </LabeledControl>
                  <LabeledControl
                    label="Lead Auditor"
                    className="lead-auditor"
                  >
                    <FlexRow>
                      <AuditorPicker
                        auditType={auditType}
                        auditorType={AuditorSearchTypes.LeadAuditor}
                        onApply={values => dispatch(setApprovalItemLeadAuditor({
                          leader: values.length
                            ? values[0]
                            : undefined,
                          planId: item.plan.id,
                        }))}
                        selectedItems={item.leadAuditor
                          ? [item.leadAuditor]
                          : []
                        }
                        allowMultiselect={false}
                        showSuggestions
                      />
                    </FlexRow>
                  </LabeledControl>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isWeekPickerVisible
        && currWeekPlan && (
          <WeekPickerModal
            onCancel={() => setIsWeekPickerVisible(false)}
            selectedWeek={currWeekPlan.weekNumber}
            year={currWeekPlan.plan.planYear}
            onApply={week => {
              dispatch(setApprovalItemWeekNumber({
                planId: currWeekPlan.plan.id,
                weekNumber: week?.week,
              }));
              setIsWeekPickerVisible(false);
            }}
          />
        )}
    </div>
  );
};

export default ApproveAuditPlansTable;
