import { useContext } from "react";
import QISchedulerContext from "../QISchedulerContext";
import QISchedulerDateHeader from "./QISchedulerDateHeader";

const QISchedulerDateHeaderRow = () => {
  const ctx = useContext(QISchedulerContext);

  if (!ctx) {
    return null;
  }

  return (
    <>
      {ctx.dates.map(date =>
        <QISchedulerDateHeader
          key={date.date.getTime()}
          date={date.date}
          isHighlighted={date.date.getTime() === ctx.today.getTime()}
        />
      )}
    </>
  );
};

export default QISchedulerDateHeaderRow;