import UrlRoutes from "components/routing/UrlRoutes";
import config from "config";
import React from "react";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Button from "shared/components/controls/buttons/button/Button";
import DataGrid from "shared/components/layout/grid/DataGrid";
import Page from "shared/components/layout/page/Page";
import { useAppSelector } from "store/store";
import AuditorManagerModal from "./modals/AuditorManagerModal";
import { setValue, IAuditorGridRowObject } from "store/auditors/AuditorSlice";
import formatDate from "shared/utilities/dateFormatters";
import { AuditorRole, IAuditorDto } from "api/auditor/auditorTypes";
import CircleImgButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import editIcon from "shared/media/dls/edit-1.svg";
import deleteIcon from "shared/media/dls/delete.svg";

const UM_GRID_COL_WIDTHS = {
  XSMALL: 75,
  SMALL: 175,
  MEDIUM: 200,
  LARGE: 250,
  XLARGE: 350,
};

const AuditorsAdminPage: React.FC = () => {
  const dispatch = useDispatch();
  const isManagerOpen = useAppSelector(store => store.auditors.isManagerOpen);
  const isDeleteConfirmationOpen = useAppSelector(store => store.auditors.isDeleteConfirmationOpen);
  const loadOp = useAppSelector(store => store.auditors.loadOp);
  const deleteOp = useAppSelector(store => store.auditors.deleteOp);
  const saveOp = useAppSelector(store => store.auditors.saveOp);

  const openManager = (auditor: Partial<IAuditorDto>) => {
    dispatch(setValue({
      isManagerOpen: true,
      managerValues: auditor,
      original: cloneDeep(auditor),
      isDirty: false,
    }));
  };

  const openAuditorEditModal = (rowData: IAuditorGridRowObject) => {
    if (rowData.createdBy === "SYSTEM")
      return;

    const auditor: IAuditorDto = {
      id: Number(rowData.id ?? 0),
      name: rowData.name,
      email: rowData.email,
      reason: rowData.reason,
      user: {
        name: rowData.name,
        email: rowData.email,
      },
      gin: Number(rowData.gin),
      type: rowData.type as AuditorRole,
      validFrom: new Date(rowData.validFrom),
      validTo: new Date(rowData.validTo),
    }
    openManager(auditor);
  };

  const openAuditorDeleteModal = (rowData: IAuditorGridRowObject) => {
    const auditor: IAuditorDto = {
      id: Number(rowData.id ?? 0),
      name: rowData.name,
      email: rowData.email,
      reason: rowData.reason,
      user: {
        name: rowData.name,
        email: rowData.email,
      },
      gin: Number(rowData.gin),
      type: rowData.type as AuditorRole,
      validFrom: new Date(rowData.validFrom),
      validTo: new Date(rowData.validTo),
    }

    dispatch(setValue({
      isDeleteConfirmationOpen: true,
      managerValues: auditor,
    }));
  };

  return (
    <Page
      title="Auditor / Lead Auditor"
      showBreadcrumbs={true}
      backButtonRoute={UrlRoutes.AuditAdmin.urlTemplate}
      headerControls={[
        {
          element: (
            <Button
              buttonType="primary"
              onClick={() => openManager({})}
            >
              ADD AUDITOR
            </Button>
          ),
          key: "ADD AUDITOR",
        },
      ]}
    >
      <DataGrid
        id="AuditorsGrid"
        dataSource="OData"
        dataUrl={`${config.endpoints.baseUrl}/odata/auditors`}
        className="with-button-content"
        showColumnChooser={true}
        allowExcelExport={true}
        sortSettings={{
          columns: [{
            field: "name",
            direction: "Ascending",
          }],
        }}
        gridColumns={[
          {
            uid: "editAuditor",
            type: "unbound",
            width: UM_GRID_COL_WIDTHS.XSMALL,
            template: (row: IAuditorGridRowObject) =>
              row.createdBy !== "SYSTEM" ?
                <CircleImgButton
                  icon={editIcon}
                  alt="Edit"
                  type="primary"
                  onClick={() => openAuditorEditModal(row)}
                />
                : "",
          },
          {
            uid: "name",
            field: "name",
            headerText: "Name",
            type: "string",
            width: UM_GRID_COL_WIDTHS.SMALL,
          },
          {
            uid: "gin",
            field: "gin",
            headerText: "GIN",
            type: "number",
            format: "f0",
            width: UM_GRID_COL_WIDTHS.SMALL,
          },
          {
            uid: "email",
            field: "email",
            headerText: "Email",
            type: "string",
            width: UM_GRID_COL_WIDTHS.XLARGE,
          },
          {
            uid: "type",
            field: "type",
            headerText: "Auditor Type",
            type: "string",
            width: UM_GRID_COL_WIDTHS.XLARGE,
            template: (row: IAuditorGridRowObject) => (row.type.match(/[A-Z][a-z]+|[0-9]+/g) ?? []).join(" ").toString(),
          },
          {
            uid: "validFrom",
            field: "validFrom",
            headerText: "Valid From",
            type: "date",
            width: UM_GRID_COL_WIDTHS.XLARGE,
            format: "MM/dd/yyyy",
            template: (row: IAuditorGridRowObject) => formatDate(row.validFrom),
          },
          {
            uid: "validTo",
            field: "validTo",
            headerText: "Valid To",
            type: "date",
            width: UM_GRID_COL_WIDTHS.MEDIUM,
            format: "MM/dd/yyyy",
            template: (row: IAuditorGridRowObject) => formatDate(row.validTo),
          },
          {
            uid: "createdBy",
            field: "createdBy",
            headerText: "Created By",
            type: "string",
            width: UM_GRID_COL_WIDTHS.LARGE,
          },
          {
            uid: "deleteAuditor",
            type: "unbound",
            width: UM_GRID_COL_WIDTHS.XSMALL,
            allowFiltering: false,
            allowSorting: false,
            showInColumnChooser: false,
            template: (row: IAuditorGridRowObject) =>
              row.createdBy !== "SYSTEM" ?
                <CircleImgButton
                  alt="Delete"
                  icon={deleteIcon}
                  type="secondary"
                  onClick={() => openAuditorDeleteModal(row)}
                  isDisabled={row.createdBy === "SYSTEM"}
                />
                : ""
          },
        ]}
        recordDoubleClick={openAuditorEditModal}
      />

      {(isManagerOpen || isDeleteConfirmationOpen) && <AuditorManagerModal />}

      {(loadOp?.isWorking
        || saveOp?.isWorking
        || deleteOp?.isWorking) && (
          <ModalSpinner />
        )}

    </Page>
  );
};

export default AuditorsAdminPage;
