import FilterTooltipSection from "components/search/filters-tooltip/FilterTooltipSection";
import "components/search/filters-tooltip/FiltersTooltip.scoped.scss";
import React from "react";
import { ICalendarFilterData } from "./useCalendarFilterInfo";

interface IFiltersTooltip {
  filterInfo: ICalendarFilterData,
}

const FiltersTooltip: React.FC<IFiltersTooltip> = ({
  filterInfo,
}) => {

  return (
    <div className="filters-tooltip">
      {Object.entries(filterInfo.filters).map(e =>
        <FilterTooltipSection
          label={e[0]}
          values={e[1]}
          key={e[0]}
        />
      )}
    </div>
  );
};

export default FiltersTooltip;
