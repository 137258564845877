import { authGetJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import ICLMDocRef from "types/clm/ICLMDocRef";
import { formatCLMDocRef } from "./formatters/clmFormatters";

class CLMApi {
  /**
   * Gets CLM Doc Refs from the API.
   * @param auditTopicIds Optional. Filters CLM Doc Refs by their associated AuditTopicId (non-hierarchical filter).
   * @param abortSignal Optional. An abort signal used to cancel the request.
   * @returns An array of ICLMDocRefs returned from the server.
   */
  public async getCLMDocRefs(auditTopicIds?: number[],
    abortSignal?: AbortSignal): Promise<ICLMDocRef[]> {
    let url = config.endpoints.baseUrl + "/clm";

    if (auditTopicIds?.length) {
      url += `?auditTopicIds=${auditTopicIds.join(',')}`;
    }

    const response = await authGetJson(url, abortSignal);

    await throwIfResponseError(response);

    let docRefs: ICLMDocRef[] = (await response.json())
      .map((obj: any) => formatCLMDocRef(obj));

    return docRefs
      .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new CLMApi();
