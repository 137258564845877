import PlanningAdministrationPeriod from "./planning-admin/PlanningAdministrationPeriod";

export enum PlanningPeriodsType {
  facilityAttribute = "FacilityAttribute",
  planApproval = "PlanApproval",
}

export interface IAdminPlanningAdministrationPeriodUpsertReturn {
  newId: number,
  businessTeamId: number
}

export type PeriodMode = "Global" | "PerGeoUnit";

export interface IAdminDefaultPlanningPeriod {
  defaultStartDate: Date,
  defaultEndDate: Date,
};

export interface IAdminPlanningAdministrationPeriod {
  id: number,
  businessTeamId: number,
  basin: string | undefined,
  geoUnit: string | undefined,
  planningAdministrationPeriodDates: PlanningAdministrationPeriod,
  manualOverride: boolean,
  overrideExpiryDate: number,
  modifiedBy: string,
  modifiedOn: number
}

export interface IUpsertAdminPlanningAdministrationPeriodRequest {
  id: number,
  businessTeamId: number,
  startDateDay: number,
  startDateMonth: number,
  endDateDay: number,
  endDateMonth: number,
  manualOverride: boolean,
  overrideExpiryDate: Date,
  modifiedBy: string,
  modifiedOn: Date,
  type: PlanningPeriodsType
}
