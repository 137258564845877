import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UrlRoutes from "components/routing/UrlRoutes";
import { IOperation } from "shared/types/operationTypes";
import { IActiveUserProfile, IAzureADUser, IProfile, IUserProfile, ModuleTypes } from "shared/types/userProfileTypes";
import { IRouteRoleMap, Roles } from "types/authTypes";

export interface ICurrentAuthState {
  currentUser: IAzureADUser,
  userProfile: IUserProfile,
  activeUserProfile: IActiveUserProfile,
  loadOperation?: IOperation<IUserProfile>,
  loadSwitchOperation?: IOperation<IActiveUserProfile>,
  routeMatrix: IRouteRoleMap[],
}

const initialState: ICurrentAuthState = {
  currentUser: {
    name: "",
    email: "",
    jobTitle: "",
  },
  userProfile: {
    id: 0,
    user: {
      name: "",
      email: "",
      jobTitle: "",
      photo: "",
    },
    activeProfile: {
      profileId: 0,
      moduleName: ""
    },
    profiles: [],
    derivedMetaRestrictions: [],
  },
  activeUserProfile: {
    profileId: 0,
    moduleName: "",
    roleName: "",
    profileMetaData: [],
    derivedMetaRestrictions: [],
  },
  loadOperation: undefined,
  loadSwitchOperation: undefined,
  routeMatrix: [
    {
      route: UrlRoutes.FacilityAttributes.urlTemplate,
      requiredRoles: [Roles.AuditPlanner, Roles.Admin, Roles.DefaultUser, Roles.SystemAdmin],
    },
    {
      route: UrlRoutes.PlanApprovals.urlTemplate,
      requiredRoles: [Roles.AuditPlanner, Roles.Admin, Roles.SystemAdmin],
    },
    {
      route: UrlRoutes.PlanVsResults.urlTemplate,
      requiredRoles: [Roles.AuditPlanner, Roles.Admin, Roles.DefaultUser, Roles.SystemAdmin],
    },
  ]
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IAzureADUser>) => {
      state.currentUser = action.payload;
    },
    loadUserProfile: (state, _: PayloadAction<{}>) => {
      state.loadOperation = {
        isWorking: true,
      };
    },
    finishLoadUserProfile: (state, action: PayloadAction<IOperation<IUserProfile>>) => {
      if (action.payload.errorMessage
        || action.payload.data === undefined) {
        state.loadOperation = action.payload;
        return;
      }

      if (!action.payload.data) {
        state.loadOperation = undefined;
        return;
      }

      state.userProfile = action.payload.data;
      state.activeUserProfile = formatActiveUserProfile(state.userProfile, state.userProfile.activeProfile.profileId);
      state.loadOperation = undefined;

    },
    switchProfile: (state, _: PayloadAction<{
      profileId: number,
      moduleName: string,
    }>) => {
      state.loadSwitchOperation = {
        isWorking: true,
      };
    },
    finishSwitchProfile: (state, action: PayloadAction<IOperation<IProfile>>) => {
      window.location.reload();
      if (!action.payload.data) {
        state.loadSwitchOperation = undefined;
        return;
      }
      state.activeUserProfile = formatActiveUserProfile(state.userProfile, action.payload.data?.id);
      state.loadSwitchOperation = undefined;
    },
  },
});

function formatActiveUserProfile(profile: IUserProfile, profileId: number): IActiveUserProfile {

  let activeProfileSelected = profile.profiles?.find(profile => profile.id === profileId);
  if (activeProfileSelected === undefined) {
    activeProfileSelected = profile.profiles?.find(p => p.module.name === ModuleTypes.All);
  }

  return {
    profileId: activeProfileSelected?.id || 0,
    moduleName: activeProfileSelected?.module?.name || "",
    roleName: activeProfileSelected?.role?.name || "",
    profileMetaData: activeProfileSelected?.profileMetaData || [],
    derivedMetaRestrictions: profile.derivedMetaRestrictions,
  };
}

export const {
  setCurrentUser,
  loadUserProfile,
  finishLoadUserProfile,
  switchProfile,
  finishSwitchProfile,
} = authSlice.actions;
