export interface IErrorWithRetryProps {
  /**
   * The message to show. Default: 'Load error.'
   */
  message?: string,
  /**
   * The error message to display on mouse over.
   */
  tooltip?: string,
  /**
   * If specified, a "Retry" link will be shown that will call this method when clicked.
   */
  onRetryClicked?: () => void,
}

const ErrorWithRetry: React.FC<IErrorWithRetryProps> = ({
  message,
  tooltip,
  onRetryClicked,
}) =>
  <span
    className="error"
    title={tooltip}
  >
    {message || "Load error."}
    {onRetryClicked && (
      <button
        className="link"
        onClick={onRetryClicked}
      >
        Retry
      </button>
    )}
  </span>;

export default ErrorWithRetry;