import React from "react";

interface IAuditorSearchMessageProps {
  isFirstPartySearch?: boolean,
}

const AuditorSearchMessage: React.FC<IAuditorSearchMessageProps> = ({
  isFirstPartySearch,
}) =>
  <span>
    {isFirstPartySearch &&
      <>
        *Only Qualified persons can be a Lead Auditor for 1st Party Audits.<br />
      </>
    }
    *A limited number of search results is returned. Please be more specific if you do not see the desired result.
  </span>;

export default AuditorSearchMessage;