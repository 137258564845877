import { PayloadAction } from "@reduxjs/toolkit";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { AuditPlanApprovalStatuses, ClmSelfAssessmentTypes, IApprovePlanRequest, IAuditPlanScore, IAuditPlanView, IRemovePlanResponse } from "types/auditPlanningTypes";
import { AuditSources, AuditStatuses } from "types/auditingTypes";
import { ICalendarWeek } from "types/masterDataTypes";

export interface IPlanningPlansState {
  planApprovalsPlans: IAuditPlanView[],
  planResultsPlans: IAuditPlanView[],
  planScores: IAuditPlanScore[],
}

export const initialPlanningPlansState: IPlanningPlansState = {
  planApprovalsPlans: [],
  planResultsPlans: [],
  planScores: [],
};

export const planningPlansReducers = {
  /** Finds the specified plans in all plan lists and marks them as approved. */
  setPlanToFinalApproval: (state: IPlanningPlansState, action: PayloadAction<IApprovePlanRequest[]>) => {
    action.payload.forEach(approvedItem => {
      // In both of the plan lists, find the plan and mark it approved.
      doToAllPlanLists(state, planList => {
        const plan = planList.find(x => x.id === approvedItem.auditPlanId);

        if (!plan) {
          return;
        }

        plan.leadAuditorEmail = approvedItem.leadAuditorEmail;
        plan.leadAuditorName = approvedItem.leadAuditorName;
        plan.weekOfYear = approvedItem.weekNumber;
        plan.approvalStatus = AuditPlanApprovalStatuses.FinalApproval;
      });
    });
  },

  updatePlansToLinkToAudit: (state: IPlanningPlansState, action: PayloadAction<{
    planIds: number[],
    auditId: number,
    leader: IAzureADUser | undefined,
    week: ICalendarWeek | undefined,
  }>) => {
    // In both of the plan lists, find the plans and link them to the audit.
    doToAllPlanLists(state, planList => {
      planList
        .filter(x => action.payload.planIds.indexOf(x.id) > -1)
        .forEach(plan => {
          // Update each plan now that the audit has been created.
          plan.auditSource = AuditSources.QoF;
          plan.auditId = action.payload.auditId;
          plan.auditLeadAuditorEmail = action.payload.leader?.email;
          plan.auditLeadAuditorName = action.payload.leader?.name;
          plan.auditStartWeekNumber = action.payload.week?.week;
          plan.auditStartDate = action.payload.week?.startDateTimestamp;
          plan.auditStatus = AuditStatuses.Planned;
        });
    });
  },

  /** Adds the spcified plans to all plan lists. */
  addPlan: (state: IPlanningPlansState, action: PayloadAction<IAuditPlanView>) => {
    doToAllPlanLists(state, planList => {
      const existingPlanIx = planList.findIndex(x =>
        x.parentDimensionType === action.payload.parentDimensionType
        && x.parentDimensionId === action.payload.parentDimensionId
        && x.childDimensionType === action.payload.childDimensionType
        && x.childDimensionId === action.payload.childDimensionId
        && x.planYear === action.payload.planYear
        && x.requirementDimensionType === action.payload.requirementDimensionType
        && x.requirementDimensionId === action.payload.requirementDimensionId
        && x.auditGroupId === action.payload.auditGroupId
        && x.auditTypeId === action.payload.auditTypeId);

      if (existingPlanIx > -1) {
        // If the plan already exists, replace it.
        planList.splice(existingPlanIx, 1, action.payload);
      } else {
        // Otherwise, add it to the list.
        planList.push(action.payload);
      }
    });
  },

  /** Marks the specified plan as deleted from all plan lists. */
  removePlan: (state: IPlanningPlansState, action: PayloadAction<IRemovePlanResponse>) => {
    doToAllPlanLists(state, planList => {
      const plan = planList.find(x => x.id === action.payload.id);

      if (plan) {
        // Copy all the properties from the removal data into the plan.
        Object.assign(plan, action.payload);
      }
    });
  },

  /** Updates a specific plan-approval plan's self assessment type. */
  updatePlanUserSelfAssessmentType: (state: IPlanningPlansState, action: PayloadAction<{
    planId: number,
    userSelectedCLMSelfAssessmentType: ClmSelfAssessmentTypes,
  }>) => {
    doToAllPlanLists(state, planList => {
      const plan = planList.find(x => x.id === action.payload.planId);

      if (plan) {
        plan.userSelectedCLMSelfAssessmentType = action.payload.userSelectedCLMSelfAssessmentType;
      }
    });
  },
};

function doToAllPlanLists(state: IPlanningPlansState, callback: (plans: IAuditPlanView[]) => void) {
  [
    state.planApprovalsPlans,
    state.planResultsPlans
  ].forEach(callback);
}