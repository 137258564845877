import QITag from "shared/components/common/tag/QITag";
import { IPickerItem } from "shared/types/pickerTypes";
import "./TagsRecap.scoped.scss";

interface ITagsRecapProps<T> {
  items: IPickerItem<T>[],
  nameMapper: (item: IPickerItem<T>) => React.ReactNode,
  onTagClick: (pickerItem: IPickerItem<T>) => void,
  itemSorter?: (item1: IPickerItem<T>, item2: IPickerItem<T>) => number,
  isDisabled?: boolean,
  noItemsMessage?: string,
  /** Optional. If specified, each item will call this function to determine if it should be enabled or not. */
  isDisabledMapper?: (item: IPickerItem<T>) => boolean,
}

const TagsRecap = <T,>({
  items,
  nameMapper,
  onTagClick,
  itemSorter,
  isDisabled,
  noItemsMessage,
  isDisabledMapper,
}: ITagsRecapProps<T>) =>
  <div
    className="tags"
  >
    {items.length === 0 && <>{noItemsMessage}</>}
    {items
      .slice()
      .sort(itemSorter
        ? (item1, item2) => itemSorter(item1, item2)
        : undefined
      )
      .map(item => (
        <QITag
          key={item.key}
          isDisabled={isDisabled || isDisabledMapper?.(item)}
          display={nameMapper(item)}
          onRemoveClick={() => onTagClick(item)}
          removeButtonTooltip="Delete"
          showRemoveButton={!isDisabled && !isDisabledMapper?.(item)}
        />
      ))}
  </div>;

export default TagsRecap;