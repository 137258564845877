import { formatActionItemStatus } from "api/actionItems/formatters/actionItemFormatters";
import { formatActionItemPriority, formatActionItemType, formatBusinessView, formatCausalFactor, formatCountry, formatFacility, formatBusinessFunction, formatMobileSite, formatBusinessTeam, formatAuditType } from "api/masterdata/formatters/masterDataFormatters";
import { formatAzureADUser } from "api/users/formatters/userFormatter";
import { formatItemsArr, formatToString } from "shared/utilities/formatterUtilities";
import { getDateTime } from "shared/utilities/dateFormatters";
import { mapMetaDataItemsByType } from "shared/utilities/metaDataUtilities";
import { MetaDataTypes } from "types/masterDataTypes";
import { ICustomActionSearch } from "types/searchActionTypes";

export function formatActionFilter(obj: any): ICustomActionSearch {
  return {
    id: Number(obj.id),
    dueDateStart: getDateTime(obj.dueDateStart),
    dueDateEnd: getDateTime(obj.dueDateEnd),
    actionItemTypes: formatItemsArr(obj.actionItemTypes, formatActionItemType),
    auditTypes: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.AuditType, formatAuditType),
    actionRequired: formatToString(obj.actionRequired),
    assignedTo: formatItemsArr(obj.assignedTo, formatAzureADUser),
    createdBy: formatItemsArr(obj.createdBy, formatAzureADUser),
    statuses: formatItemsArr(obj.statuses, formatActionItemStatus),
    facilities: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.Facility, formatFacility),
    countries: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.Country, formatCountry),
    businessViews: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.BusinessView, formatBusinessView),
    businessTeams: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.BusinessTeam, formatBusinessTeam),
    businessFunctions: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.BusinessFunction, formatBusinessFunction),
    mobileSites: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.MobileSite, formatMobileSite),
    parentTypes: formatItemsArr(obj.parentTypes, formatToString),
    parentId: formatToString(obj.parentId),
    parentName: formatToString(obj.parentName),
    leadAuditors: formatItemsArr(obj.leadAuditors, formatAzureADUser),
    priorities: formatItemsArr(obj.priorities, formatActionItemPriority),
    isValidated: obj.isValidated !== null
      ? Boolean(obj.isValidated)
      : undefined,
    creationDateStart: getDateTime(obj.creationDateStart),
    creationDateEnd: getDateTime(obj.creationDateEnd),
    closureDateStart: getDateTime(obj.closureDateStart),
    closureDateEnd: getDateTime(obj.closureDateEnd),
    causalFactors: formatItemsArr(obj.causalFactors, formatCausalFactor),
  };
}
