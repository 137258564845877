import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback, useMemo } from "react";
import { IPickerItem } from "shared/types/pickerTypes";
import { BusinessFunctionType, IBusinessFunction, MetaDataTypes } from "types/masterDataTypes";
import NonReduxPickerList from "./non-redux-list-picker/NonReduxPickerList";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IBusinessFunctionPickerProps {
  renderMode?: PickerMode,
  /** Optional. If specified, businessFunctions of this type will not be selectable by the user. (They will be disabled.) */
  disallowedTypes?: BusinessFunctionType[],
  codesToFilter?: string[] | undefined,
}

const BusinessFunctionPicker: React.FC<IBusinessFunctionPickerProps & ICommonPickerProps<IBusinessFunction>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  showSuggestions = false,
  onRenderPicker,
  disallowedTypes,
  isDisabled = false,
  userProfileMetaRestrictions,
  isItemDisabledMapper,
  codesToFilter,
  renderMode = "picker",
  showSelectedItems = true,
  hideUnselectableBranches = false,
}) => {
  const renderItem = useCallback((item: IBusinessFunction) => `${item.code} - ${item.name}`, []);

  const restrictedToItems = useMemo(() => userProfileMetaRestrictions
    ?.filter(x => x.type === MetaDataTypes.BusinessFunction) || [],
    [userProfileMetaRestrictions]);

  const loadItems = useCallback(async (_: unknown, abortSignal: AbortSignal) => {
    let businessFunctions = await MasterDataApi.getBusinessFunctions(abortSignal);

    if (codesToFilter?.length) {
      businessFunctions.forEach(element => {
        if (!codesToFilter.includes(element.code)) {
          let businessFunctionsToKeep: string[] = filterByCodes(codesToFilter, [], element.children);

          if (businessFunctionsToKeep.length === 0) {
            businessFunctions = businessFunctions.filter(x => x.id !== element.id);
          }

          element.children = element.children
            .filter(x => businessFunctionsToKeep.includes(x.code))
            .map(el => filterChildren(el, businessFunctionsToKeep));
        }
      });
    }

    return businessFunctions;
  }, [codesToFilter]);

  const isDisabledMapper = useCallback((item: IBusinessFunction, ancestorPath: IBusinessFunction[]) => {
    if (disallowedTypes
      && disallowedTypes.some(x => x === item.type)) {
      // This item is of a disallowed type. Disable it.
      return true;
    }

    if (restrictedToItems.length && !restrictedToItems.some(x => x.id === item.id)) {
      return true;
    }

    return isItemDisabledMapper?.(item, ancestorPath) || false;
  }, [disallowedTypes, restrictedToItems, isItemDisabledMapper]);

  const pickerFilterItems = useCallback(<T,>(item: IPickerItem<T>, search: string) => {
    if (item?.item) {
      return renderItem(((item.item as unknown) as IBusinessFunction))
        .toLowerCase()
        .includes(search.toLowerCase());
    }
    return false;
  }, [renderItem]);

  if (renderMode === "picker") {
    return (
      <NonReduxPicker<IBusinessFunction>
        itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title="Business Function (Function)"
        allowMultiSelect={allowMultiselect}
        displayMode="tree"
        renderListItem={renderItem}
        childrenMapper={item => item.children || []}
        onLoadSuggestedItems={async (abortSignal) => await MasterDataApi.getSuggestedBusinessFunctions(abortSignal)}
        showSuggestedItems={showSuggestions}
        searchOptions={{
          filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
        }}
        onRenderPicker={onRenderPicker}
        isDisabled={isDisabled}
        isDisabledMapper={isDisabledMapper}
      />
    );
  } else if (renderMode === 'list') {
    return (
      <NonReduxPickerList
        selectedItems={selectedItems}
        renderListItem={renderItem}
        onSelectionChanged={onApply}
        keyMapper={(item) => item.id}
        title="Business Functions"
        onLoadItems={loadItems}
        allowMultiSelect={allowMultiselect}
        tagsProps={[{
          label: "Selected Business Functions",
          renderItem: (item) => item.item ? renderItem(item.item) : item.text,
        }]}
        childMapper={(item) => item.children || []}
        isDisabledMapper={isDisabledMapper}
        isDisabled={isDisabled}
        showSuggestedItems={showSuggestions}
        showSelectedItems={showSelectedItems}
        hideUnselectableBranches={hideUnselectableBranches}
        filterItems={pickerFilterItems}
      />
    );
  } else {
    return <>Render mode "{renderMode}" is not supported.</>;
  }
};

function filterChildren(element: IBusinessFunction, businessViewsToKeep: string[] | undefined): any {
  if (businessViewsToKeep?.includes(element.code)) {
    if (element.children.length !== 0) {
      const filteredChildren: IBusinessFunction[] = element.children.map(child => filterChildren(child, businessViewsToKeep)).filter(x => x);
      return { ...element, children: filteredChildren };
    } else {
      return element;
    }
  }
}

function filterByCodes(codes: string[] | undefined, parentsCode: string[], childrenBusinessFunctions: IBusinessFunction[]): string[] {
  let businessFunctionsToKeep: string[] = [];

  childrenBusinessFunctions.forEach(element => {
    if (element.children.length !== 0) {
      if (codes?.includes(element.code)) parentsCode.push(element.code);
      const businessFunctionsFiltered = filterByCodes(codes, parentsCode, element.children);
      businessFunctionsFiltered.forEach(e => {
        businessFunctionsToKeep.push(e);
      });

      if (codes?.includes(element.code) || businessFunctionsFiltered.length !== 0) {
        businessFunctionsToKeep.push(element.code);
      }
    } else {
      if (codes?.includes(element.code) || codes?.some((item) => parentsCode.includes(item))) {
        businessFunctionsToKeep.push(element.code);
      }
    }

    const index = parentsCode.indexOf(element.code);
    parentsCode = parentsCode.splice(index, 1);
  });

  return businessFunctionsToKeep;
}

export default BusinessFunctionPicker;