import { IAzureADUser } from "shared/types/userProfileTypes"

export enum AuditorRole {
  Auditor = "Auditor",
  LeadAuditor = "LeadAuditor",
}

export interface ISaveAuditorRequest {
  id?: number,
  email: string,
  name: string,
  type: AuditorRole,
  gin?: number,
  validFrom: Date,
  validTo: Date,
  reason?: string
}

export interface IAuditorDto {
  id?: number,
  user?: IAzureADUser,
  email: string,
  name: string,
  type: AuditorRole,
  gin?: number,
  validFrom: Date,
  validTo: Date
  reason?: string
}

