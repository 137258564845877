import { PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IAuditInfo } from "types/auditingTypes";
import { IAuditState } from "../AuditSlice";
import { getAuditSummaryChanges } from "../sagas/saveChangesSagas";

export interface IAuditInfoState {
  loadAuditOp?: IOperation<IAuditInfo>,
  audit?: IAuditInfo,
  originalAudit?: IAuditInfo,
  isAuditDirty: boolean,
}

export const initialAuditInfoState: IAuditInfoState = {
  loadAuditOp: undefined,
  audit: undefined,
  originalAudit: undefined,
  isAuditDirty: false,
};

const auditInfoReducers = {
  /** Loads the main info for the specified audit id. */
  loadAudit: (state: IAuditState, _: PayloadAction<number>) => {
    state.loadAuditOp = {
      isWorking: true,
    };
  },

  /** If the operation has an error message, it is updated in the loadAuditOp.
   * Otherwise, the loadAuditOp is cleared and the payload audit info is put
   * into audit and a copy is put into originalAudit.
   */
  finishLoadAudit: (state: IAuditState, action: PayloadAction<IOperation<IAuditInfo>>) => {
    if (action.payload.errorMessage) {
      state.loadAuditOp = action.payload;
      return;
    }

    state.loadAuditOp = undefined;

    state.audit = action.payload.data;
    state.originalAudit = cloneDeep(action.payload.data);
    state.isAuditDirty = false;
  },

  /** Sets the specified properties of the audit. If a child array or object is updated, send in a clone with updated data. 
   * For example, if removing an auditor from the auditors array, send an object with an auditors list that contains
   * EVERYONE except the removed auditor.
  */
  setAudit: (state: IAuditState, action: PayloadAction<{
    audit: Partial<IAuditInfo>,
    alsoSetOriginal?: boolean,
  }>) => {
    if (state.audit) {
      Object.assign(state.audit, action.payload.audit);
    }

    if (action.payload.alsoSetOriginal
      && state.originalAudit) {
      Object.assign(state.originalAudit, action.payload.audit);
    }

    computeAuditIsDirty(state);

    const changeSet = getAuditSummaryChanges(state);

    if (!changeSet) {
      state.isAuditDirty = false;
    }
  },
};

export default auditInfoReducers;

function computeAuditIsDirty(state: IAuditState) {
  state.isAuditDirty = !isEqual(state.audit, state.originalAudit);
  return state.isAuditDirty;
}

export function getAuditScoringSystem(audit: IAuditInfo): string | undefined {
  return audit.auditTopics?.[0]?.scoringSystem;
}