import ActionItemsApi from "api/actionItems/ActionItemsApi";
import { all, call, put, race, select, take, TakeEffect, takeLatest } from "redux-saga/effects";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IActionItem, IMyAction } from "types/actionItemTypes";
import { IPagedResult } from "types/pageResultsTypes";
import { clearMyActions, finishLoadingActionItem, finishLoadingMyActions, IMyActionsState, loadAndInspectActionItem, loadMyActions, setRelationFilter, setStatusFilter } from "./MyActionsSlice";

export default function* myActionsSagas() {
  yield all([
    loadMyActionsAsync(),
    onFiltersChangedAsync(),
    loadAndInspectActionItemAsync(),
  ]);
}

function* loadMyActionsAsync() {
  yield takeLatest(loadMyActions, function* (action) {
    if (!loadMyActions.match(action)) {
      return;
    }

    // Grab the other filter information.
    const {
      relationFilter,
      statusFilter,
    }: IMyActionsState = yield select((store: RootState) => store.myActions);

    try {
      const result: {
        pagedAudits: IPagedResult<IMyAction>,
        cancelled: TakeEffect,
      } = yield race({
        pagedAudits: call(ActionItemsApi.getMyActions, action.payload.pageNumber, 20, statusFilter, relationFilter),
        cancelled: take(clearMyActions),
      });

      if (result.cancelled) {
        return;
      }

      yield put(finishLoadingMyActions({
        isWorking: false,
        data: result.pagedAudits,
      }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishLoadingMyActions({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* onFiltersChangedAsync() {
  yield takeLatest([
    setStatusFilter,
    setRelationFilter,
  ], function* () {
    // After the status and/or relation filter is changed,
    // need to restart the loading process.

    yield put(loadMyActions({
      pageNumber: 1,
    }));
  });
}

function* loadAndInspectActionItemAsync() {
  yield takeLatest(loadAndInspectActionItem, function* (action) {
    if (!loadAndInspectActionItem.match(action)) {
      return;
    }

    try {
      // Query the server to get the action item.
      const actionItem: IActionItem = yield call(ActionItemsApi.getActionItem, action.payload);

      yield put(finishLoadingActionItem({
        isWorking: false,
        data: actionItem,
      }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      
      yield put(finishLoadingActionItem({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}