import React, { useEffect } from "react";

interface ITextInputProps {
  /** Optional. If true, prevents the user from entering `<` and `>`. Default = true. */
  preventHTML?: boolean,
  /** An event to handle when the user changes the input value. */
  onChange: (value: string) => void,
  value?: string,
}

/** Renders an `<input type="text" .../>` element with some basic properties defined by default. See {@link ITextInputProps} for props.  */
const TextInput: React.FC<ITextInputProps & Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'type' | 'value'>> = (props) => {
  const preventHTML = props.preventHTML;
  const value = props.value;
  const onChange = props.onChange;

  // Automatically strip out any < or > from a value passed in by default.
  useEffect(() => {
    if (preventHTML === false
      || value === undefined) {
      return;
    }

    if (value.match(/(<|>)/)) {
      onChange(value.replaceAll(/(<|>)/g, ""))
    }
  }, [preventHTML, value, onChange]);

  return (
    <input
      {...props}
      type="text"
      onChange={e => {
        let value = e.currentTarget.value;

        if (props.preventHTML !== false) {
          // If HTML tags should be prevented, strip out all '<' and '>' characters.
          value = value.replaceAll(/(<|>)/g, "");
        }

        props.onChange(value);
      }}
    />
  );
};

export default TextInput;