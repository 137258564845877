import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import userIcon from "shared/media/dls/user.svg";
import formatDate from "shared/utilities/dateFormatters";
import { userToString } from "shared/utilities/userUtilities";
import { IPlanProfileAttribute } from "../../../facilityAttributeTypes";
import "./AttributeTooltipContents.scoped.scss";
import TemplateTooltipContents from "./TemplateTooltipContents";

interface IAttributeTooltipContentsProps {
  attribute?: IPlanProfileAttribute,
  canRemoveAttribute: boolean,
  canAddAttribute: boolean,
}

const AttributeTooltipContents: React.FC<IAttributeTooltipContentsProps> = ({
  attribute,
  canRemoveAttribute,
  canAddAttribute,
}) => {
  if (!attribute) {
    return null;
  }

  let reviewer: string = "";

  if (attribute.reviewedByName
    || attribute.reviewedByEmail) {
    reviewer = userToString({
      email: attribute.reviewedByEmail || "",
      name: attribute.reviewedByName || "",
    });
  }

  let remover: string = "";

  if (attribute.deletedByName
    || attribute.deletedByEmail) {
    remover = userToString({
      email: attribute.deletedByEmail || "",
      name: attribute.deletedByName || "",
    });
  }

  return (
    <div
      className="attribute-tooltip"
    >
      <FlexRow>
        <div>
          <img
            src={userIcon}
            alt=""
            className="icon-small dim-icon"
          />
        </div>

        <FlexCol>
          <span
            className="dim"
          >
            Reviewer's Name: {reviewer}
          </span>

          <span
            className="bright"
          >
            Reviewed on: {formatDate(attribute.reviewedOn
              ? new Date(attribute.reviewedOn)
              : undefined,
              false,
              true)}
          </span>

          {attribute.masterDataType === 'AuditTemplate' &&
            <div className="template-picker">
              <TemplateTooltipContents
                masterDataId={attribute.masterDataId}
                isRequired={false}
                allowMultiselect={true}
                selectedItems={[]}
                onApply={() => { }}
                isItemDisabledMapper={() => true}
              />
            </div>}

          {!attribute.deleted
            && canRemoveAttribute && (
              <span
                className="bright"
              >
                Click to edit the requirement.
              </span>
            )}

          {attribute.deleted && (
            <>
              <span
                className="bright"
              >
                This requirement has been removed.
              </span>

              <span
                className="dim"
              >
                Remover's Name: {remover}
              </span>

              <span
                className="bright"
              >
                Removed on: {formatDate(attribute.deletedOn
                  ? new Date(attribute.deletedOn)
                  : undefined,
                  false,
                  true)}
              </span>

              <span
                className="dim"
              >
                Removal Reason: {attribute.comment}
              </span>
            </>
          )}

          {canAddAttribute && (
            <span
              className="bright"
            >
              Click to re-add this requirement.
            </span>
          )}
        </FlexCol>
      </FlexRow>
    </div>
  );
};

export default AttributeTooltipContents;