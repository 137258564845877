import { authDelete, authFetch, authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import {
  ActionItemRelations,
  ActionItemStatusFilters,
  IActionItem,
  IActionItemClosure,
  IActionItemEvidence,
  IActionItemHistoryItem,
  IActionItemLink,
  IMyAction,
  IActionItemAccess
} from "types/actionItemTypes";
import { IPagedResult } from "types/pageResultsTypes";
import {
  formatActionItem,
  formatActionItemEvidenceItem,
  formatActionItemEvidenceLink,
  formatActionItemEvidences,
  formatActionItemHistoryItem,
  formatCreateUpdateActionItemRequest,
  formatGetMyActionsResponse,
  formatUnlinkActionItemResponse,
  formatGetActionsAccessResponse
} from "./formatters/actionItemFormatters";

class AuditsApi {
  public async createActionItem(request: IActionItem, sendNotification: boolean): Promise<IActionItem> {
    const response = await authPostJson(config.endpoints.actionItems.actionItems,
      formatCreateUpdateActionItemRequest(request, sendNotification));
    await throwIfResponseError(response);
    return formatActionItem(await response.json());
  }

  public async getActionItem(id: number): Promise<IActionItem> {
    const response = await authGetJson(`${config.endpoints.actionItems.actionItems}/${id}`);
    await throwIfResponseError(response);
    return formatActionItem(await response.json());
  }

  public async updateActionItem(request: IActionItem, sendNotification: boolean): Promise<void> {
    const response = await authPutJson(`${config.endpoints.actionItems.actionItems}/${request.id}`,
      formatCreateUpdateActionItemRequest(request, sendNotification));
    await throwIfResponseError(response);
  }

  public async closeActionItem(actionItemId: number,
    closure: IActionItemClosure,
    sendNotification: boolean): Promise<IActionItemHistoryItem> {
    const response = await authPutJson(`${config.endpoints.actionItems.actionItems}/${actionItemId}/close`,
      {
        actionItemId,
        closureDate: new Date(closure.closureTimestamp),
        actionFollowup: closure.actionFollowUp,
        sendNotification: sendNotification
      });

    await throwIfResponseError(response);

    return formatActionItemHistoryItem(await response.json());
  }

  public async reopenActionItem(actionItemId: number,
    comment: string,
    sendNotification: boolean): Promise<IActionItemHistoryItem> {
    const response = await authPutJson(`${config.endpoints.actionItems.actionItems}/${actionItemId}/reopen`, {
      actionItemId,
      comment,
      sendNotification
    });

    await throwIfResponseError(response);

    return formatActionItemHistoryItem(await response.json());
  }

  public async unlinkActionItem(actionItemId: number,
    links: IActionItemLink[]): Promise<IUnlinkActionItemResult> {
    const response = await authPutJson(`${config.endpoints.actionItems.actionItems}/${actionItemId}/unlink`, {
      actionItemId,
      linksToRemove: links,
    });

    await throwIfResponseError(response);

    return formatUnlinkActionItemResponse(await response.json());
  }

  public async deleteActionItem(actionItemId: number): Promise<void> {
    const response = await authDelete(`${config.endpoints.actionItems.actionItems}/${actionItemId}`);
    await throwIfResponseError(response);
  }

  public async getEvidenceItems(actionItemId: number, abortSignal: AbortSignal): Promise<IActionItemEvidence[]> {
    const response = await authGetJson(config
      .endpoints
      .actionItems
      .actionItemEvidenceCollection
      .replace(":actionItemId",
        actionItemId.toString()),
      abortSignal);
    await throwIfResponseError(response);
    return formatActionItemEvidences(await response.json());
  }

  public async deleteActionItemEvidenceFile(actionItemId: number, id: number): Promise<void> {
    const response = await authDelete(`${config
      .endpoints
      .actionItems
      .actionItemEvidenceFile
      .replace(":actionItemId", actionItemId.toString())}/${id}`);
    await throwIfResponseError(response);
  }

  public async getActionItemEvidenceFileBlobUrl(actionItemId: number, filename: string): Promise<string> {
    const response = await authFetch("GET",
      config
        .endpoints
        .actionItems
        .getActionItemEvidenceFileBlobUrl
        .replace(":actionItemId", actionItemId.toString())
        .replace(":filename", filename.toString()));
    await throwIfResponseError(response);
    return await response.text();
  }

  public async updateEvidenceNotes(actionItemId: number, id: number, notes: string): Promise<void> {
    const response = await authPutJson(`${config
      .endpoints
      .actionItems
      .actionItemEvidenceFile
      .replace(":actionItemId", actionItemId.toString())}/${id}`, {
      notes,
    });
    await throwIfResponseError(response);
  }

  public async uploadEvidenceItem(actionItemId: number, file: File, notes: string): Promise<IActionItemEvidence> {
    let formData = new FormData();

    formData.append("Filestream", file);
    formData.append("Notes", notes);
    formData.append("Filename", file.name);

    const response = await authFetch("POST",
      config
        .endpoints
        .actionItems
        .actionItemEvidenceFile
        .replace(":actionItemId", actionItemId.toString()),
      formData);
    await throwIfResponseError(response);

    const resultData = await response.json();

    return formatActionItemEvidenceItem(resultData);
  }

  public async searchActionItems(searchRequest: ISearchActionItemsRequest, abortSignal?: AbortSignal): Promise<IActionItem[]> {
    let url = config.endpoints.actionItems.search;

    let linkQSVars: string[] = [];

    for (let i = 0; i < searchRequest.linksSearch.length; i++) {
      linkQSVars.push(`linksSearch[${i}].linkId=${searchRequest.linksSearch[i].linkId}`);
      linkQSVars.push(`linksSearch[${i}].type=${searchRequest.linksSearch[i].type}`);
    }

    if (linkQSVars.length) {
      url += '?' + linkQSVars.join("&");
    }

    const response = await authGetJson(url, abortSignal);
    await throwIfResponseError(response);
    return (await response.json()).map(formatActionItem);
  }

  public async addActionItemComment(actionItemId: number,
    comment: string,
    sendNotification: boolean): Promise<IActionItemHistoryItem> {
    const response = await authPostJson(config.endpoints.actionItems.comments.replace(":actionItemId", actionItemId.toString()), {
      text: comment,
      sendNotification: sendNotification
    });

    await throwIfResponseError(response);

    return formatActionItemHistoryItem(await response.json());
  }

  public async validateActionItem(actionItemId: number,
    comment: string,
    sendNotification: boolean): Promise<IActionItemHistoryItem> {
    const response = await authPostJson(config.endpoints.actionItems.validate.replace(":actionItemId", actionItemId.toString()), {
      text: comment,
      sendNotification: sendNotification
    });

    await throwIfResponseError(response);

    return formatActionItemHistoryItem(await response.json());
  }

  public async getMyActions(pageNumber: number,
    pageSize: number,
    status: ActionItemStatusFilters,
    relation: ActionItemRelations): Promise<IPagedResult<IMyAction>> {
    let url = config.endpoints.actionItems.myActions;
    url += `?page=${pageNumber}`;
    url += `&pageSize=${pageSize}`;
    url += `&status=${status}`;
    url += `&relation=${relation}`;

    const response = await authGetJson(url);

    await throwIfResponseError(response);

    return formatGetMyActionsResponse(await response.json());
  }

  public async deleteActionItemEvidenceLink(actionItemId: number, id: number): Promise<void> {
    const response = await authDelete(`${config
      .endpoints
      .actionItems
      .actionItemEvidenceLink
      .replace(":actionItemId", actionItemId.toString())}/${id}`,);
    await throwIfResponseError(response);
  }

  public async saveActionItemEvidenceLink(actionItemId: number, id: number, link: string, notes: string): Promise<IActionItemEvidence> {
    const response = await authPostJson(`${config
      .endpoints
      .actionItems
      .actionItemEvidenceLink
      .replace(":actionItemId", actionItemId.toString())}`,
      {
        Id: id ?? null,
        Link: link,
        Notes: notes,
        ActionItemId: actionItemId,
      });

    await throwIfResponseError(response);

    return formatActionItemEvidenceLink(await response.json());
  }

  public async getActionItemAccess(id: number,
    abortSignal?: AbortSignal): Promise<IActionItemAccess> {
    const response = await authGetJson(config
      .endpoints
      .actionItems
      .actionItemAccess
      .replace(":actionItemId",
        id.toString()),
      abortSignal);

    await throwIfResponseError(response);

    return formatGetActionsAccessResponse(await response.json());
  }
}

export interface IUnlinkActionItemResult {
  historyItem: IActionItemHistoryItem,
  remainingLinkCount: number,
}

export interface ISearchActionItemsRequest {
  linksSearch: ISearchActionItemLink[],
}

export interface ISearchActionItemLink {
  linkId: string,
  type: string,
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditsApi();
