import { AuditScoringSystems, ComplianceResults } from "types/auditPlanningTypes";
import CLMScoreCalculator from "./score-calculators/CLMScoreCalculator";
import HSEScoreCalculator from "./score-calculators/HSEScoreCalculator";
import QMSScoreCalculator from "./score-calculators/QMSScoreCalculator";

export interface IComplianceQuestionProps {
  category: string,
  answer: string | undefined,
  isLto: boolean,
}

export interface IComplianceScore {
  overallPercentage: number | undefined,
  overallCompliance: ComplianceResults | undefined,
  cat1Percentage: number | undefined,
  cat2Percentage: number | undefined,
  numLTOQuestions: number | undefined,
  numLTONonCompliantQuestions: number | undefined,
  ltoCompliance: number | undefined,
}

export const calcComplianceScore = (questions: IComplianceQuestionProps[],
  scoringSystem: AuditScoringSystems): IComplianceScore | undefined => {
  if (scoringSystem === AuditScoringSystems.HSE) {
    return new HSEScoreCalculator().calculateComplianceScore(questions);
  } else if (scoringSystem === AuditScoringSystems.CLM) {
    return new CLMScoreCalculator().calculateComplianceScore(questions);
  } else if (scoringSystem === AuditScoringSystems.QMS) {
    return new QMSScoreCalculator().calculateComplianceScore(questions);
  }
};
