import React from "react";
import { useDispatch } from "react-redux";
import PlusButton from "shared/components/controls/buttons/circle-img-button/plus-btn/PlusButton";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import ApiPicker from "shared/components/controls/pickers/ApiPicker";
import BusinessFunctionPicker from "shared/components/controls/pickers/BusinessFunctionPicker";
import BusinessTeamPicker from "shared/components/controls/pickers/BusinessTeamPicker";
import BusinessViewPicker from "shared/components/controls/pickers/BusinessViewPicker";
import CountryPicker from "shared/components/controls/pickers/CountryPicker";
import IsoPicker from "shared/components/controls/pickers/IsoPicker";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import { IRestrictedMetaData } from "shared/types/userProfileTypes";
import { setQuestionProperties } from "store/question-details/QuestionDetailsSlice";
import { IApi, IIso } from "types/auditMasterDataTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICountry, MetaDataTypes, BusinessEpicType, IBusinessEpic } from "types/masterDataTypes";
import { IQuestion } from "types/questionTypes";
import QuestionIdDisplay from "../question-id-display/QuestionIdDisplay";
import { getQuestionMetaItems, itemToCodeName, onItemDeselected, onPickerApply } from "../sharedAssociationHelpers";
import SplitMetaPicker from "../split-meta-picker/SplitMetaPicker";
import BusinessEpicPicker from "shared/components/controls/pickers/BusinessEpicPicker";
import "./QuestionAssociationsCard.scoped.scss";

interface IQuestionAssociationsCardProps {
  isDisabled: boolean,
  question: Partial<IQuestion>,
  /** If provided, pickers will be restricted to these items. */
  derivedMetaRestrictions: IRestrictedMetaData[] | undefined,
}

/** The card named "Question Associations". */
const QuestionAssociationsCard: React.FC<IQuestionAssociationsCardProps> = ({
  isDisabled,
  question,
  derivedMetaRestrictions,
}) => {
  const dispatch = useDispatch();

  const createPlusButton = (openPicker: () => void) => {
    return (<PlusButton onClick={openPicker}></PlusButton>);
  };

  return (
    <Card
      title="Question Associations"
      cardStateId="manage-question-question-associations"
      headerElement={
        <QuestionIdDisplay
          question={question}
          invisible={true}
        />
      }
    >
      <div className="associations-grid">
        <div className="basin">
          <SplitMetaPicker<IBusinessTeam>
            isDisabled={isDisabled}
            columns={[{
              key: "Basin",
              label: "Basins",
            }, {
              key: "GeoUnit",
              label: "Geo Units",
            }, {
              key: "SubGeoUnit",
              label: "Sub-Geo Units",
            }]}
            selectedItems={getQuestionMetaItems<IBusinessTeam>(question.metaData,
              MetaDataTypes.BusinessTeam,
              false)}
            mapItemToColumnKey={item => item.type}
            itemSorter={(item1, item2) => itemToCodeName(item1) < itemToCodeName(item2) ? -1 : 1}
            onItemDeselected={item => onItemDeselected(item, item.id, MetaDataTypes.BusinessTeam, false, dispatch)}
            renderListItem={item => itemToCodeName(item)}
            keyMapper={item => item.id}
            metaTypeMapper={_ => MetaDataTypes.BusinessTeam}
            userProfileMetaRestrictions={
              derivedMetaRestrictions?.filter(x => x.type === MetaDataTypes.BusinessTeam) || []
            }
            picker={
              <BusinessTeamPicker
                onApply={items => onPickerApply(items,
                  question.metaData,
                  MetaDataTypes.BusinessTeam,
                  false,
                  dispatch)}
                selectedItems={getQuestionMetaItems<IBusinessTeam>(question.metaData,
                  MetaDataTypes.BusinessTeam,
                  false)}
                allowMultiselect={true}
                onRenderPicker={openPicker => createPlusButton(openPicker)}
                showSuggestions={true}
                disallowedTypes={["GeoLevel1"]}
                isDisabled={isDisabled}
                userProfileMetaRestrictions={derivedMetaRestrictions}
              />
            }
          />
        </div>

        <div className="division">
          <SplitMetaPicker<IBusinessView>
            isDisabled={isDisabled}
            columns={[{
              key: "Division",
              label: "Divisions",
            }, {
              key: "BusinessLine",
              label: "Business Lines",
            }, {
              key: "SubBusinessLine",
              label: "Sub-Business Lines",
            }, {
              key: "Support",
              label: "Business Supports",
            },]}
            selectedItems={getQuestionMetaItems<IBusinessView>(question.metaData,
              MetaDataTypes.BusinessView,
              false)}
            mapItemToColumnKey={item => item.type}
            itemSorter={(item1, item2) => itemToCodeName(item1) < itemToCodeName(item2) ? -1 : 1}
            onItemDeselected={item => onItemDeselected(item, item.id, MetaDataTypes.BusinessView, false, dispatch)}
            renderListItem={item => itemToCodeName(item)}
            keyMapper={item => item.id}
            metaTypeMapper={_ => MetaDataTypes.BusinessView}
            userProfileMetaRestrictions={
              derivedMetaRestrictions?.filter(x => x.type === MetaDataTypes.BusinessView) || []
            }
            picker={
              <BusinessViewPicker
                onApply={items => onPickerApply(items,
                  question.metaData,
                  MetaDataTypes.BusinessView,
                  false,
                  dispatch)
                }
                selectedItems={getQuestionMetaItems<IBusinessView>(question.metaData,
                  MetaDataTypes.BusinessView,
                  false)}
                allowMultiselect={true}
                onRenderPicker={openPicker => createPlusButton(openPicker)}
                showSuggestions={true}
                isDisabled={isDisabled}
                userProfileMetaRestrictions={derivedMetaRestrictions}
              />
            }
          />
        </div>
        <div className="epic">
          <LabeledControl
            label="EPIC - Brand"
          >
            <BusinessEpicPicker
              title="EPIC - Brand"
              onApply={items => onPickerApply(items,
                question.metaData,
                MetaDataTypes.BusinessEpic,
                false,
                dispatch,
                item => isBusinessEpicType(item, BusinessEpicType.Brand),
              )}
              selectedItems={getQuestionMetaItems<IBusinessEpic>(question.metaData, MetaDataTypes.BusinessEpic, false)}
              businessEpicType={BusinessEpicType.Brand}
              allowMultiselect={true}
              isDisabled={isDisabled}
              userProfileMetaRestrictions={derivedMetaRestrictions}
            />
          </LabeledControl>
          <LabeledControl
            label="EPIC - Product Family"
          >
            <BusinessEpicPicker
              title="EPIC - Product Family"
              onApply={items => onPickerApply(items,
                question.metaData,
                MetaDataTypes.BusinessEpic,
                false,
                dispatch,
                item => isBusinessEpicType(item, BusinessEpicType.ProductFamily),
              )}
              selectedItems={getQuestionMetaItems<IBusinessEpic>(question.metaData, MetaDataTypes.BusinessEpic, false)
              }
              businessEpicType={BusinessEpicType.ProductFamily}
              allowMultiselect={true}
              isDisabled={isDisabled}
              userProfileMetaRestrictions={derivedMetaRestrictions}
            />
          </LabeledControl>
          <LabeledControl
            label="EPIC - P/L Technology"
          >
            <BusinessEpicPicker
              title="EPIC - P/L Technology"
              onApply={items => onPickerApply(items,
                question.metaData,
                MetaDataTypes.BusinessEpic,
                false,
                dispatch,
                item => isBusinessEpicType(item, BusinessEpicType.PLTechnology),
              )}
              selectedItems={getQuestionMetaItems<IBusinessEpic>(question.metaData, MetaDataTypes.BusinessEpic, false)}
              businessEpicType={BusinessEpicType.PLTechnology}
              allowMultiselect={true}
              isDisabled={isDisabled}
              userProfileMetaRestrictions={derivedMetaRestrictions}
            />
          </LabeledControl>
          <LabeledControl
            label="EPIC - Equipment Code"
          >
            <BusinessEpicPicker
              title="EPIC - Equipment Code"
              onApply={items => onPickerApply(items,
                question.metaData,
                MetaDataTypes.BusinessEpic,
                false,
                dispatch,
                item => isBusinessEpicType(item, BusinessEpicType.EquipmentCode),
              )}
              selectedItems={getQuestionMetaItems<IBusinessEpic>(question.metaData, MetaDataTypes.BusinessEpic, false)}
              businessEpicType={BusinessEpicType.EquipmentCode}
              allowMultiselect={true}
              isDisabled={isDisabled}
              userProfileMetaRestrictions={derivedMetaRestrictions}
            />
          </LabeledControl>
        </div>
        <div className="function">
          <SplitMetaPicker<IBusinessFunction>
            isDisabled={isDisabled}
            columns={[{
              key: "Function",
              label: "Function",
            }, {
              key: "SubFunction",
              label: "SubFunction",
            },]}
            selectedItems={getQuestionMetaItems<IBusinessFunction>(question.metaData,
              MetaDataTypes.BusinessFunction,
              false)}
            mapItemToColumnKey={item => item.type}
            itemSorter={(item1, item2) => itemToCodeName(item1) < itemToCodeName(item2) ? -1 : 1}
            onItemDeselected={item => onItemDeselected(item, item.id, MetaDataTypes.BusinessFunction, false, dispatch)}
            renderListItem={item => itemToCodeName(item)}
            keyMapper={item => item.id}
            metaTypeMapper={_ => MetaDataTypes.BusinessFunction}
            userProfileMetaRestrictions={
              derivedMetaRestrictions?.filter(x => x.type === MetaDataTypes.BusinessFunction) || []
            }
            picker={
              <BusinessFunctionPicker
                onApply={items => onPickerApply(items,
                  question.metaData,
                  MetaDataTypes.BusinessFunction,
                  false,
                  dispatch)}
                selectedItems={getQuestionMetaItems<IBusinessFunction>(question.metaData,
                  MetaDataTypes.BusinessFunction,
                  false)}
                allowMultiselect={true}
                onRenderPicker={openPicker => createPlusButton(openPicker)}
                showSuggestions={true}
                disallowedTypes={["FunctionCategory"]}
                isDisabled={isDisabled}
                userProfileMetaRestrictions={derivedMetaRestrictions}
              />
            }
          />
        </div>

        <div className="country">
          <LabeledControl
            label="Country"
          >
            <CountryPicker
              onApply={items => onPickerApply(items,
                question.metaData,
                MetaDataTypes.Country,
                false,
                dispatch)}
              selectedItems={getQuestionMetaItems<ICountry>(question.metaData,
                MetaDataTypes.Country,
                false)}
              allowMultiselect={true}
              showSuggestions={true}
              isDisabled={isDisabled}
              userProfileMetaRestrictions={derivedMetaRestrictions}
            />
          </LabeledControl>
        </div>

        <div className="api">
          <LabeledControl
            label="API"
          >
            <ApiPicker
              onApply={items => onPickerApply(items,
                question.metaData,
                MetaDataTypes.API,
                false,
                dispatch)}
              selectedItems={getQuestionMetaItems<IApi>(question.metaData,
                MetaDataTypes.API,
                false)}
              allowMultiselect={true}
              isDisabled={isDisabled}
              renderMode="dropdown"
              userProfileMetaRestrictions={derivedMetaRestrictions}
            />
          </LabeledControl>
        </div>

        <div className="iso">
          <LabeledControl
            label="ISO"
          >
            <FlexCol className="iso-col">
              <IsoPicker
                onApply={items => onPickerApply(items,
                  question.metaData,
                  MetaDataTypes.ISO,
                  false,
                  dispatch)
                }
                selectedItems={getQuestionMetaItems<IIso>(question.metaData,
                  MetaDataTypes.ISO,
                  false)}
                allowMultiselect={true}
                isDisabled={isDisabled}
                renderMode="dropdown"
                userProfileMetaRestrictions={derivedMetaRestrictions}
              />

              <label>
                <input
                  type="checkbox"
                  checked={question.licenseToOperate}
                  onChange={e => dispatch(setQuestionProperties({
                    licenseToOperate: e.currentTarget.checked,
                  }))}
                  disabled={isDisabled}
                />
                Yes, I am a License to Operate Question.
              </label>
            </FlexCol>
          </LabeledControl>
        </div>
      </div>
    </Card>
  );
};

export default QuestionAssociationsCard;

function isBusinessEpicType(metaDataItem: any, businessEpicType: BusinessEpicType) {
  return (metaDataItem.masterDataType === MetaDataTypes.BusinessEpic
    && (metaDataItem.masterDataItem as IBusinessEpic).type === businessEpicType);
}