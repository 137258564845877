import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpdateNonComplianceResponsesRequest } from "api/auditing/types/auditApiTypes";
import pickerHandlers from "shared/store/picker/pickerReducerHandlers";
import { IOperation } from "shared/types/operationTypes";
import { IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { IApi, IQMSElement } from "types/auditMasterDataTypes";

export interface ISelectedAuditQuestion {
  auditQuestionId: number,
  questionId: number,
  questionNum: number,
}

export enum AuditSummaryPickerKeys {
  auditors = "auditors",
}

export enum NonConformanceTabs {
  current = "Current",
  complete = "Completed",
  closed = "Closed",
}

export interface IUpdateQuestionResponseModal {
  isOpen: boolean,
  auditQuestionIds: number[],
  saveOp?: IOperation<void>,
}

export interface IAuditResultNonConformanceState {
  pickerData: {
    auditors: IPickerState<IAzureADUser>,
  },
  selectedAuditQuestions: ISelectedAuditQuestion[],
  selectedComplianceTab: NonConformanceTabs,
  expandedRowKeys: string[],
  updateQuestionResponseModal: IUpdateQuestionResponseModal,
  loadQmsResultsItemsOperation?: IOperation<void> | undefined,
  qmsElements: IQMSElement[],
  apis: IApi[],
}

const initialState: IAuditResultNonConformanceState = {
  pickerData: {
    auditors: {
      key: AuditSummaryPickerKeys.auditors,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
  },
  selectedAuditQuestions: [],
  selectedComplianceTab: NonConformanceTabs.current,
  expandedRowKeys: [],
  updateQuestionResponseModal: {
    isOpen: false,
    auditQuestionIds: [],
    saveOp: undefined,
  },
  loadQmsResultsItemsOperation: undefined,
  qmsElements: [],
  apis: [],
};

export const auditResultNonConformanceSlice = createSlice({
  name: "Audit-Result-Non-Conformance",
  initialState,
  reducers: {
    ...pickerHandlers,

    toggleQuestionSelections: (state, action: PayloadAction<{
      auditQuestionId: number,
      questionId: number,
      questionNum: number,
      isSelected: boolean,
    }[]>) => {
      for (let i = 0; i < action.payload.length; i++) {
        const {
          auditQuestionId,
          questionId,
          questionNum,
          isSelected,
        } = action.payload[i];

        if (isSelected) {
          if (!state.selectedAuditQuestions.some(m => m.auditQuestionId === auditQuestionId)) {
            state.selectedAuditQuestions.push({
              auditQuestionId,
              questionId,
              questionNum,
            });
          }
        } else if (!isSelected) {
          state.selectedAuditQuestions = state
            .selectedAuditQuestions
            .filter(x => x.auditQuestionId !== auditQuestionId);
        }
      }
    },
    clearSelectedQuestions: (state) => {
      state.selectedAuditQuestions = [];
    },

    clearSpecificSelectedQuestions: (state, action: PayloadAction<number[]>) => {
      state.selectedAuditQuestions = state.selectedAuditQuestions.filter(x => !action.payload.some(z => z === x.auditQuestionId));
    },

    selectComplianceScore: (state, action: PayloadAction<NonConformanceTabs>) => {
      state.selectedComplianceTab = action.payload;
    },

    setUpdateQuestionResponseModal: (state, action: PayloadAction<Partial<IUpdateQuestionResponseModal>>) => {
      Object.assign(state.updateQuestionResponseModal, action.payload);
    },

    submitUpdatedResponses: (state, _: PayloadAction<IUpdateNonComplianceResponsesRequest>) => {
      state.updateQuestionResponseModal.saveOp = {
        isWorking: true,
      };
    },

    finishSubmitUpdatedResponses: state => {
      // Clear all the values that were set in the modal (which includes closing the modal).
      Object.assign(state.updateQuestionResponseModal, initialState.updateQuestionResponseModal);
    },

    setExpandedRowKeys: (state, action: PayloadAction<string[]>) => {
      state.expandedRowKeys = action.payload;
    },

    loadQmsResultsItems: (state, action: PayloadAction<number>) => {
      state.loadQmsResultsItemsOperation = {
        isWorking: true,
      };
    },

    finishLoadQmsResultsItems: (state, action: PayloadAction<IOperation<{
      qmsElements: IQMSElement[],
      apis: IApi[],
    }>>) => {
      if (action.payload.errorMessage
        || !action.payload.data) {
        state.loadQmsResultsItemsOperation = {
          isWorking: false,
          errorMessage: action.payload.errorMessage
        };
        return;
      }

      state.qmsElements = action.payload.data.qmsElements;
      state.apis = action.payload.data.apis;

      state.loadQmsResultsItemsOperation = undefined;
    },
  },
});

export const {
  openPicker,
  closePicker,
  loadPickerItems,
  setPickerError,
  setPickerItems,
  setSelectedPickerItems,
  toggleQuestionSelections,
  clearSelectedQuestions,
  clearSpecificSelectedQuestions,

  selectComplianceScore,
  setUpdateQuestionResponseModal,
  submitUpdatedResponses,
  finishSubmitUpdatedResponses,
  setExpandedRowKeys,

  loadQmsResultsItems,
  finishLoadQmsResultsItems,
} = auditResultNonConformanceSlice.actions;