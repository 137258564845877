import { AuditorRole, IAuditorDto, ISaveAuditorRequest } from "../auditorTypes";

export function formatSaveAuditorRequest(item: IAuditorDto): ISaveAuditorRequest {
  return {
    id: item.id ?? 0,
    email: item.user?.email ?? "",
    name: item.user?.name ?? "",
    gin: item.gin,
    type: item.type ?? AuditorRole.Auditor,
    validFrom: item.validFrom,
    validTo: item.validTo,
    reason: item.reason,
  };
}

export function formatAuditorDto(item: any): IAuditorDto {
  return {
    id: item.id ?? 0,
    email: item.email,
    name: item.name,
    gin: item.gin,
    type: item.role ?? AuditorRole.Auditor,
    validFrom: item.validFrom,
    validTo: item.validTo,
    reason: item.reason,
  };
}
