import React from "react";
import DetailText from "shared/components/common/detail-text/DetailText";
import CollapseSwitch from "shared/components/controls/collapse-switch/CollapseSwitch";
import { setCardState } from "store/card-states/CardStatesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "./Card.scoped.scss";

interface ICardProps {
  title?: string,
  titleClass?: string,
  titleStyle?: React.CSSProperties,
  headerElement?: React.ReactNode,
  className?: string,
  footer?: React.ReactNode,
  /**
   * If a title is provided, this will default to true.
   */
  showHeader?: boolean,
  /** If provided, the card will automatically look up its own state in redux and also show the expand/collapse button. */
  cardStateId?: string,
  /** If provided, the card will use this collapsed state if no card state exists in redux. */
  defaultCollapsedState?: boolean,
  /** An optional props to pass children */
  children?: React.ReactNode,
  /** If provided, will be used to render the detail text header. */
  headerDetailText?: React.ReactNode,
}

const Card: React.FC<ICardProps> = ({
  title,
  titleClass,
  titleStyle,
  headerElement,
  children,
  className,
  footer,
  showHeader,
  cardStateId,
  defaultCollapsedState,
  headerDetailText,
}) => {
  const cardState = useAppSelector(cardStateId
    ? store => store.cardStates[cardStateId]
    : () => undefined);
  const dispatch = useAppDispatch();

  const isCollapsed = cardState === undefined
    ? defaultCollapsedState
    : cardState.isCollapsed;

  // The header must render if cardStateId is provided so the
  // collapse switch can be shown.
  const shouldHeaderRender = (title && showHeader !== false)
    || showHeader === true
    || cardStateId;

  const shouldFooterRender = !isCollapsed;

  const onToggleCollapsedState = () => {
    if (!cardStateId) {
      return;
    }

    dispatch(setCardState({
      key: cardStateId,
      state: cardState === undefined
        ? {
          isCollapsed: !defaultCollapsedState,
        } : {
          ...cardState,
          isCollapsed: !cardState.isCollapsed,
        },
    }));
  };

  return (
    <div
      className={`card ${className || ""} ${isCollapsed
        || children === undefined
        ? "collapsed"
        : ""}`
      }
    >
      {shouldHeaderRender && (
        <div
          className="header"
        >
          <div
            className="header-left"
          >
            {!!title && (
              <h4
                className="title"
                style={titleStyle}
              >
                <DetailText
                  className={`${titleClass ?? "audit-card-title"}`}
                  maxTextLength={70}
                  showModalOnClick={true}
                  text={title}
                  modalData={{
                    header: headerDetailText ?? "Audit Name",
                    content:
                      <div>
                        <span>Name: {title}</span>
                      </div>
                  }}
                />
              </h4>
            )}
            {headerElement}
          </div>

          {!!cardStateId && (
            <CollapseSwitch
              className="header-collapse-switch"
              isOpen={!isCollapsed}
              onClick={onToggleCollapsedState}
            />
          )}
        </div>
      )}
      {children !== undefined
        && !isCollapsed && (
          <div
            className="body"
          >
            {children}
          </div>
        )}
      {footer
        && shouldFooterRender && (
          <div
            className="footer"
          >
            {footer}
          </div>
        )}
    </div>
  );
}

export default Card;