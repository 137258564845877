import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { AuditNotificationRecipients, IAuditComments, IAuditReport, IAuditReportGeneration } from "types/auditingTypes";
import { CommentType, ITopicComment } from "types/auditReportTypes";

export interface IAuditReportState {
  report?: IAuditReport,
  originalReport?: IAuditReport,
  loadReportDataOp?: IOperation<IAuditReport>,
  saveOp?: IOperation<IAuditComments>,
  saveTopicComentsOp?: IOperation<IAuditComments>,
  isCommentsModalOpen: boolean,
  isReportModalOpen: boolean,
  isNotifyTeamModalOpen: boolean,
  isSaveAuditCommentButtonEnabled: boolean,
  generateReportOp?: IOperation<void>,
  sendNotificationsOp?: IOperation<void>,
  topicCommentModal: ITopicCommentModalData,
  originalTopicComments: ITopicCommentModalData,
}
export interface ITopicCommentModalData {
  isOpen: boolean,
  isDirty: boolean,
  topicId: number,
  topicComments: ITopicComment[],
}

const initialState: IAuditReportState = {
  report: undefined,
  originalReport: undefined,
  loadReportDataOp: undefined,
  saveOp: undefined,
  saveTopicComentsOp: undefined,
  isCommentsModalOpen: false,
  isReportModalOpen: false,
  isNotifyTeamModalOpen: false,
  isSaveAuditCommentButtonEnabled: false,
  generateReportOp: undefined,
  sendNotificationsOp: undefined,
  topicCommentModal: { isOpen: false, isDirty: false, topicId: 0, topicComments: [] },
  originalTopicComments: { isOpen: false, isDirty: false, topicId: 0, topicComments: [] },
}

export const auditReportSlice = createSlice({
  name: "audit-report",
  initialState,
  reducers: {
    loadReportData: (state, _: PayloadAction<number>) => {
      state.loadReportDataOp = {
        isWorking: true,
      };
    },
    finishLoadingReportData: (state, action: PayloadAction<IOperation<IAuditReport>>) => {
      if (action.payload.errorMessage
        || !action.payload.data) {
        state.loadReportDataOp = action.payload;
        return;
      }

      state.report = action.payload.data;
      state.originalReport = cloneDeep(action.payload.data);
      state.loadReportDataOp = undefined;
    },
    resetState: state => {
      Object.assign(state, initialState);
    },
    setAuditComment: (state, action: PayloadAction<Partial<IAuditComments>>) => {
      if (!state.report) {
        return;
      }

      Object.assign(state.report.auditComment, action.payload);

      // Check report is dirty
      computeAuditReportIsSaveAuditCommentButtonEnabled(state);
    },
    toggleAuditCommentSaveButton: (state, action: PayloadAction<boolean>) => {
      state.isSaveAuditCommentButtonEnabled = action.payload;
    },
    saveAuditComments: state => {
      state.saveOp = {
        isWorking: true,
      };
    },
    finishSaveAuditComments: state => {
      state.saveOp = undefined;
      state.originalReport = cloneDeep(state.report)
    },
    saveTopicComments: (state,) => {
      state.saveTopicComentsOp = {
        isWorking: true,
      };
    },
    finishSaveTopicComments: (state, action: PayloadAction<IOperation<Array<ITopicComment>>>) => {
      state.saveTopicComentsOp = undefined;

      if (action.payload.errorMessage
        || !state.report) {
        return;
      }

      state.report.auditTopicComment = state
        .report
        .auditTopicComment
        .filter(x => x.auditTopicId !== state.topicCommentModal.topicId);

      state.report.auditTopicComment.push(...state.topicCommentModal.topicComments);

      state.topicCommentModal = {
        isOpen: false,
        isDirty: false,
        topicComments: [],
        topicId: 0,
      };

      state.originalTopicComments = {
        isOpen: false,
        isDirty: false,
        topicComments: [],
        topicId: 0,
      };
    },
    setTopicComment: (state, action: PayloadAction<{
      htmlText: string, type: CommentType, plainText: string
    }>) => {
      const topicCommenttype = state.topicCommentModal.topicComments.find(x => x.commentType === action.payload.type);

      if (topicCommenttype) {
        topicCommenttype.commentText = action.payload.plainText
        topicCommenttype.commentHtmlText = action.payload.htmlText;
        computeTopicCommentsIsDirty(state);
      }
    },
    openCommentsModal: (state, action: PayloadAction<{
      topicId: number, Email: string
    }>) => {

      const topicsComments = state.report?.auditTopicComment.filter(item => item.auditTopicId === action.payload.topicId);

      state.isCommentsModalOpen = true;
      state.topicCommentModal.isOpen = false;
      state.topicCommentModal.topicComments = cloneDeep(topicsComments) || [];

      [CommentType.HighLights, CommentType.LowLights, CommentType.GeneralComments]
        .forEach(type => {
          if (!state.topicCommentModal.topicComments.find(x => x.commentType === type)) {
            state.topicCommentModal.topicComments.push({
              id: 0,
              auditId: state.report?.id ?? 0,
              auditTopicId: action.payload.topicId ?? 0,
              associationId: undefined,
              commentHtmlText: "",
              commentType: type,
              commentText: "",
              createdBy: action.payload.Email,
            })
          }
        });

      state.originalTopicComments = cloneDeep(state.topicCommentModal);
      state.topicCommentModal.topicId = action.payload.topicId;
    },
    closedCommentsModal: (state, action: PayloadAction<boolean>) => {
      state.isCommentsModalOpen = false;
      state.topicCommentModal.isDirty = false;
      state.topicCommentModal.isOpen = action.payload;
    },
    toggleReportModal: (state, action: PayloadAction<boolean>) => {
      state.isReportModalOpen = action.payload;
    },
    generateAuditReport: (state, _: PayloadAction<{
      auditId: number,
      reportData: IAuditReportGeneration,
    }>) => {
      state.generateReportOp = {
        isWorking: true,
      };
    },
    finishGenerateAuditReport: state => {
      state.generateReportOp = undefined;
      state.isReportModalOpen = false;
    },
    toggleNotifyTeamModal: (state, action: PayloadAction<boolean>) => {
      state.isNotifyTeamModalOpen = action.payload;
    },
    sendAuditNotifications: (state, _: PayloadAction<{
      auditId: number,
      recipients: AuditNotificationRecipients[],
    }>) => {
      state.sendNotificationsOp = {
        isWorking: true,
      };
    },
    finishSendAuditNotifications: state => {
      state.sendNotificationsOp = undefined;
    },
  },
});

export const {
  loadReportData,
  finishLoadingReportData,
  resetState,
  openCommentsModal,
  toggleAuditCommentSaveButton,
  setAuditComment,
  saveAuditComments,
  finishSaveAuditComments,
  saveTopicComments,
  finishSaveTopicComments,
  toggleReportModal,
  generateAuditReport,
  finishGenerateAuditReport,
  setTopicComment,
  closedCommentsModal,
  toggleNotifyTeamModal,
  sendAuditNotifications,
  finishSendAuditNotifications,
} = auditReportSlice.actions

export interface ISaveAuditCommentPayload {
  auditId?: string,
  closingStatement: string,
  lowlights: string,
  highLights: string,
  wayforward: string,
  notes: string
}

function computeAuditReportIsSaveAuditCommentButtonEnabled(state: IAuditReportState) {
  state.isSaveAuditCommentButtonEnabled = false;
  state.isSaveAuditCommentButtonEnabled = state.isSaveAuditCommentButtonEnabled || !isEqual(state.report?.auditComment.closingStatementHTML, state.originalReport?.auditComment.closingStatementHTML);
  state.isSaveAuditCommentButtonEnabled = state.isSaveAuditCommentButtonEnabled || !isEqual(state.report?.auditComment.lowlightsHTML, state.originalReport?.auditComment.lowlightsHTML);
  state.isSaveAuditCommentButtonEnabled = state.isSaveAuditCommentButtonEnabled || !isEqual(state.report?.auditComment.highLightsHTML, state.originalReport?.auditComment.highLightsHTML);
  state.isSaveAuditCommentButtonEnabled = state.isSaveAuditCommentButtonEnabled || !isEqual(state.report?.auditComment.wayforwardHTML, state.originalReport?.auditComment.wayforwardHTML);
  state.isSaveAuditCommentButtonEnabled = state.isSaveAuditCommentButtonEnabled || !isEqual(state.report?.auditComment.notes, state.originalReport?.auditComment.notes);

  return state.isSaveAuditCommentButtonEnabled;
}

function computeTopicCommentsIsDirty(state: IAuditReportState) {
  state.topicCommentModal.isDirty = false;

  [CommentType.HighLights, CommentType.LowLights, CommentType.GeneralComments]
    .forEach(type => {
      const originalComment = state.originalTopicComments.topicComments.find(x => x.commentType === type) ?? { commentHtmlText: '', commentText: '' };
      const comment = state.topicCommentModal.topicComments.find(x => x.commentType === type);
      if (comment /*&& !isEqual(originalComment?.commentText.trim(), comment.commentText.trim())*/) {
        state.topicCommentModal.isDirty = state.topicCommentModal.isDirty
          || !isEqual(originalComment?.commentHtmlText, comment.commentHtmlText);
      }
    });
  return state.topicCommentModal.isDirty;
}

export interface ISaveTopicCommentPayload {
  id: number,
  auditId: number,
  auditTopicId: number,
  associationId: number | undefined,
  CommentType: CommentType,
  CommentText: string,
  CommentHtmlText: string,
  createdBy: IAzureADUser,
}
