import UrlRoutes from "components/routing/UrlRoutes";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import Button from "shared/components/controls/buttons/button/Button";
import Card from "shared/components/layout/card/Card";
import Page from "shared/components/layout/page/Page";
import { IAuditTemplatesMasterData, loadPageData, toggleMgmtModal } from "store/audit-templates/AuditTemplatesSlice";
import { useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IOwnerGroup } from "types/auditingTypes";
import { IDetailedTemplate } from "types/templateApiTypes";
import "./AuditTemplatesPage.scoped.scss";
import AuditTemplateFilters from "./template-filters/AuditTemplateFilters";
import AuditTemplateItem from "./template-item/AuditTemplateItem";
import AuditTemplateMgmtModal from "./template-management-modal/AuditTemplateMgmtModal";

const AuditTemplatesPage: React.FC = () => {
  const loadOp = useAppSelector(store => store.auditTemplates.loadOp);
  const templates: IDetailedTemplate[] = useAppSelector(store => store.auditTemplates.templates);
  const masterData: IAuditTemplatesMasterData = useAppSelector(store => store.auditTemplates.masterData);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const isMgmtModalOpen = useAppSelector(store => store.auditTemplates.isMgmtModalOpen);
  const isSaveOp = useAppSelector(store => store.auditTemplates.saveOp);
  const isDeleteOp = useAppSelector(store => store.auditTemplates.deleteOp);
  const filters = useAppSelector(store => store.auditTemplates.filters);
  const dispatch = useDispatch();

  const isDisabled = editRestriction !== EditRestriction.EditAll;

  useEffect(() => {
    dispatch(loadPageData(filters));
  }, [dispatch, filters]);

  const templatesGroups = filterTemplatesOwnerGroups(templates, masterData)
    .map(group =>
      <Card
        className="audit-detail-title"
        title={group.name}
        titleClass="audit-detail-title"
        key={group.id + '-' + group.name}>
        <div className="template-group">
          {templates
            .filter(x => x.ownerGroupId === group.id)
            .map(x =>
              <AuditTemplateItem
                key={group.id + '-' + x.key}
                template={x}
                isDisabled={isDisabled} />)}
        </div>
      </Card>)

  return (
    <Page
      title="Audit Templates"
      showBreadcrumbs={true}
      backButtonRoute={UrlRoutes.AuditAdmin.urlTemplate}
      headerControls={[{
        element: (
          <Button
            buttonType="primary"
            onClick={() => dispatch(toggleMgmtModal({ toogleOp: true }))}
            isDisabled={isDisabled}
            key="NEW-TEMPLATE"
          >
            New Audit Template
          </Button>
        ),
        key: "NEW AUDIT TEMPLATE",
      }]
      }
    >
      <Card>
        <AuditTemplateFilters
          filters={filters} />
      </Card>

      {templates.length === 0 &&
        <Banner
          type={BannerType.info}
        >
          No Records to Display.
        </Banner>}


      {loadOp?.isWorking
        ? <Spinner />
        : <>
          {isMgmtModalOpen && (
            <AuditTemplateMgmtModal />
          )}

          {templatesGroups}

          {(isSaveOp?.isWorking || isDeleteOp?.isWorking) && (
            <ModalSpinner />
          )}
        </>
      }
    </Page>
  );
};

const filterTemplatesOwnerGroups = (templates: IDetailedTemplate[],
  masterData: IAuditTemplatesMasterData): IOwnerGroup[] => {
  const templatesOwnerGroups: number[] = templates.map(x => x.ownerGroupId);

  return masterData.ownerGroups
    .filter(x => templatesOwnerGroups.includes(x.id));
}

export default AuditTemplatesPage;
