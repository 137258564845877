import { IColumnItem } from "shared/types/columnTypes";



interface ITableHeaderProps<T, K extends keyof T, BeforeRenderOutput = void> {
  /** Optional. Determines if the headers stay in view when the parent is scrolled. Default = true. */
  areHeadersSticky: boolean,
  /** The column information for the table. */
  columns: Array<IColumnItem<T, K, BeforeRenderOutput>>,
}

const TableHeader = <T, K extends keyof T, BeforeRenderOutput>({
  areHeadersSticky = true,
  columns,
}: ITableHeaderProps<T, K, BeforeRenderOutput>): JSX.Element =>
  <thead>
    <tr>
      {columns.map(column => (
        <th
          className={`table-header ${areHeadersSticky ? "sticky" : ""}`}
          key={`headCell-${column.key}`}
          style={column.headerTextAlign
            ? {
              textAlign: column.headerTextAlign,
            } : undefined
          }
        >
          <div
            style={column.width
              ? {
                width: column.width
              } : undefined}
          >
            {column.header}
          </div>
        </th>
      ))}
    </tr>
  </thead>;

export default TableHeader;
