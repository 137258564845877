import OwnerGroupsApi from "api/auditing/OwnerGroupsApi";
import React from "react";
import { IOwnerGroup } from "types/auditingTypes";
import NonReduxPickerDropdown from "./non-redux-dropdown-picker/NonReduxDropdownPicker";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IOwnerGroupPickerProps {
  /** Optional. If true, the user cannot change selections. */
  isDisabled?: boolean,
  /** Optional. Determines the picker's mode. Default = Picker. */
  renderMode?: PickerMode,
}

const OwnerGroupPicker: React.FC<IOwnerGroupPickerProps & ICommonPickerProps<IOwnerGroup>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  onRenderPicker,
  isDisabled = false,
  renderMode = 'picker',
}) => {
  const renderItem = (item: IOwnerGroup) => item.name;

  const loadItems = async (searchTerm: string | undefined, abortSignal: AbortSignal) => 
    await OwnerGroupsApi.getOwnerGroups(searchTerm, undefined, abortSignal);

  if(renderMode === 'picker') {
    return (
      <NonReduxPicker<IOwnerGroup>
        itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title="Owner Groups"
        allowMultiSelect={allowMultiselect}
        displayMode="list"
        renderListItem={renderItem}
        searchOptions={{
          filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
        }}
        onRenderPicker={onRenderPicker}
        isDisabled={isDisabled}
      />
    );
  } else if (renderMode === 'dropdown') {
    return (
      <NonReduxPickerDropdown<IOwnerGroup>
        itemFormatter={renderItem}
        keyMapper={item => item.id}
        onLoadItems={loadItems}
        onSelectionChanged={onApply}
        selectedItems={selectedItems}
        placeholder="Select"
        selectMode={allowMultiselect ? "multiple" : "single"}
        isDisabled={isDisabled}
      />
    );
  } else {
    return <>Render mode "{renderMode}" is not supported.</>
  }
};

export default OwnerGroupPicker;