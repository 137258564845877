import React from "react";
import { Link } from "react-router-dom";
import "./TabSelection.scoped.scss";

export interface ITabItem {
  /** The unique key to identify this tab. */
  key: string | number,
  /** An optional className to give to the tab element. */
  className?: string,
  /** Determines if this is the currently selected tab or not. */
  isSelected?: boolean,
  /** Determines if this tab should be a hyperlink. */
  isLink?: boolean,
  /** If isLink=true, this is the URL of the link for the tab. */
  linkUrl?: string,
  /** The text or element to render that represents this tab. */
  node: React.ReactNode,
}

interface ITabSelectionProps {
  tabs: ITabItem[],
  onTabClicked?: (key: string | number) => void,
  className?: string,
  /** Determines whether pipes are shown between the tabs. Default = true. */
  showDividers?: boolean,
  /** Optional. If true, user cannot interact with the tabs. Default = false. */
  isDisabled?: boolean,
}

const TabSelection: React.FC<ITabSelectionProps> = ({
  tabs,
  onTabClicked,
  className,
  showDividers = true,
  isDisabled = false,
}) =>
  <ul
    className={`tabs ${className} ${showDividers ? "with-dividers" : ""}`}
  >
    {tabs.map(tab => (
      <li
        key={tab.key}
        className={`${tab.isSelected ? "selected" : ""} ${tab.className || ""}`}
      >
        {tab.isLink
          ? (
            <Link
              to={tab.linkUrl || ""}
              onClick={onTabClicked
                ? () => onTabClicked(tab.key)
                : undefined
              }
              style={isDisabled
                ? { pointerEvents: "none" }
                : undefined
              }
            >
              {tab.node}
            </Link>
          ) : (
            <span
              onClick={onTabClicked
                ? () => onTabClicked(tab.key)
                : undefined
              }
              style={isDisabled
                ? { pointerEvents: "none" }
                : undefined
              }
            >
              {tab.node}
            </span>
          )}
      </li>
    ))}
  </ul>;

export default TabSelection;