import ActionItemsApi from "api/actionItems/ActionItemsApi";
import ActionItemManager from "components/actions/manage/ActionItemManager";
import { cloneDeep, isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import Divider from "shared/components/common/divider/Divider";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal, { ModalButton } from "shared/components/layout/modal/Modal";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { canAddComment, canCloseAndHandleEvidenceActionItem, canEditActionItem } from "shared/utilities/actionItemUtilities";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { tooltipMessage } from 'shared/utilities/stringUtilities';
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemChangeEvent, ActionItemLinkTypes, ActionItemStatuses, IActionItem, IActionItemEditor, IActionItemLink } from "types/actionItemTypes";
import { AuditStatuses } from "types/auditingTypes";
import ClosureModal from "../closure-modal/ClosureModal";
import CommentModal from "../comment-modal/CommentModal";
import ActionItemHistoryLog from "../history-log/ActionItemHistoryLog";
import ReopenModal from "../reopen-modal/ReopenModal";
import SendNotificationCheckbox from "../send-notification-checkbox/SendNotificationCheckbox";
import UnlinkModal from "../unlink-modal/UnlinkModal";
import ValidateModal from "../validate-modal/ValidateModal";
import { EditRestriction } from "types/auditPageAuthTypes";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";

interface IManageActionItemModalProps {
  /** The user the action item will be assigned to. This is only used when creating a NEW action item using this modal. Default = current user. */
  assignedTo?: IAzureADUser,
  /** The action item to be loaded into the editor. If not provided, the modal will be used to create a NEW action item instead. */
  actionItem: IActionItem | undefined,
  /** The list of people allowed to edit this action item. This list is only used when creating a NEW action item using this modal. */
  actionItemEditors: IActionItemEditor[],
  /** The list of links for this action item (use the exported createActionItemLinks functions to create them). This list is only used when creating a NEW action item using this modal. */
  actionItemLinks: IActionItemLink[],
  /** If specified, the manage action item modal will automatically perform this action when the modal finishes loading. */
  actionToPerformOnLoad?: IPerformOnLoadOptions,
  /** The callback function to call after the action item changed successfully on the API. */
  onActionItemChanged(event: ActionItemChangeEvent): void,
  /** The callback function to call to close the modal. */
  onCancel(): void,
  /** The audit status, so we can apply the logic for the defaultSendNotification */
  auditStatus?: AuditStatuses;
}

export enum ManageActionItemModalActions {
  Close = "Close",
  Reopen = "Reopen",
  Validate = "Validate",
  Comment = "Comment",
  Unlink = "Unlink",
}

const ManageActionItemModal: React.FC<IManageActionItemModalProps> = ({
  assignedTo,
  actionItem: initialActionItem,
  actionItemEditors,
  actionItemLinks,
  actionToPerformOnLoad,
  onActionItemChanged,
  onCancel,
  auditStatus
}) => {
  const currentUser = useAppSelector(store => store.auth.currentUser);
  const currentUserProfile = useAppSelector(store => store.auth.activeUserProfile);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);

  const dispatch = useAppDispatch();

  const [isClosureOpen, setIsClosureOpen] = useState(false);
  const [isReopenOpen, setIsReopenOpen] = useState(false);
  const [unlinkModalData, setUnlinkModalData] = useState<{
    isOpen: boolean,
    args?: IUnlinkActionItemArgs,
  }>({
    isOpen: false,
    args: undefined,
  });
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [isValidateOpen, setIsValidateOpen] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  // Record who this was assigned to when the component first rendered.
  // This will be updated when the user saves the action item to whoever is assigned at that point.
  const [originalAssignedTo, setOriginalAssignedTo] = useState<IAzureADUser | undefined>(
    initialActionItem?.assignedTo
    || assignedTo
    || {
      email: currentUser.email,
      name: currentUser.name,
    });

  const [actionItem, setActionItem] = useState<IActionItem>(initialActionItem
    ? cloneDeep(initialActionItem)
    : {
      id: 0,
      status: ActionItemStatuses.Open,
      dueByTimestamp: getNowPlus10Days(),
      actionRequired: "",
      description: "",
      assignedTo: assignedTo || {
        email: currentUser.email,
        name: currentUser.name,
      },
      links: actionItemLinks,
      editors: actionItemEditors,
      createdBy: {
        email: currentUser.email,
        name: currentUser.name,
      },
      createdOnTimestamp: new Date().getTime(),
      isValidated: false,
      isLocked: false,
      history: [],
      evidences: [],
      modifiedBy: {
        email: currentUser.email,
        name: currentUser.name,
      },
      modifiedOnTimestamp: new Date().getTime()
    });

  const defaultSendNotification =
    auditStatus
      ? (auditStatus === AuditStatuses.Planned || auditStatus === AuditStatuses.InProgress)
        ? false : true
      :
      (actionItem.defaultSendNotification ?? false);
  const [isSendNotificationSelected, setSendEmailNotification] = useState(defaultSendNotification);

  const [hasAccessOverride, setHasAccessOverride] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const modifiedAuditPageAuth = { ...auditPageAuth };
  const actionItemId = actionItem.id;

  useEffect(() => {
    if (actionToPerformOnLoad) {
      switch (actionToPerformOnLoad.action) {
        case ManageActionItemModalActions.Unlink: {
          setUnlinkModalData({
            isOpen: true,
            args: actionToPerformOnLoad.args?.type === "UNLINK_ARGS"
              ? actionToPerformOnLoad.args
              : undefined,
          });
          break;
        }
        case ManageActionItemModalActions.Close: {
          setIsClosureOpen(true);
          break;
        }
        case ManageActionItemModalActions.Comment: {
          setIsCommentOpen(true);
          break;
        }
        case ManageActionItemModalActions.Reopen: {
          setIsReopenOpen(true);
          break;
        }
        case ManageActionItemModalActions.Validate: {
          setIsValidateOpen(true);
          break;
        }
      }
    }
  }, [actionToPerformOnLoad, setUnlinkModalData, setIsClosureOpen, setIsCommentOpen, setIsReopenOpen, setIsValidateOpen]);

  useAsyncEffect(async (aborted, abortSignal) => {
    if (auditPageAuth.auditId === 0) {
      try {
        if (actionItemId) {
          setIsLoading(true);
          const hasAccess = await ActionItemsApi.getActionItemAccess(actionItemId, abortSignal);
          if (aborted) {
            return;
          }
          setIsLoading(false);
          setHasAccessOverride(hasAccess.hasAccessOverride);
        }
      } catch (error) {
        if (!aborted) {
          console.error("Error checking access for Action Item:", error);
          setIsLoading(false);
        }
      }
    }
  }, [auditPageAuth, actionItemId, setIsLoading]);

  const isDirty = !isEqual({ ...initialActionItem, history: [] }, { ...actionItem, history: [] });

  const onSave = async () => {
    // Ensure work item is filled in.
    if (!actionItem.actionRequired?.trim()
      || !actionItem.assignedTo
      || !actionItem.dueByTimestamp
      || !actionItem.priority
      || !actionItem.type) {
      dispatch(showErrorToast("Please complete all required fields."));
      return;
    }

    if (actionItem.type.allowCausalFactor
      && !actionItem.causalFactor) {
      dispatch(showErrorToast("Please complete all required fields."));
      return;
    }

    // Call server to save.
    try {
      setIsWorking(true);

      let actionItemToEmit: IActionItem;
      let eventType: "Updated" | "Created";

      if (!actionItem.id) {
        actionItemToEmit = await ActionItemsApi.createActionItem(actionItem, isSendNotificationSelected);

        eventType = "Created";
        setActionItem(cloneDeep(actionItemToEmit));
      } else {
        await ActionItemsApi.updateActionItem(actionItem, isSendNotificationSelected);
        eventType = "Updated";
        actionItemToEmit = cloneDeep(actionItem);
      }

      let successMessage = eventType === "Created"
        ? "Action item created successfully."
        : "Action item updated successfully.";

      dispatch(showSuccessToast(successMessage));

      setIsWorking(false);

      onActionItemChanged({
        actionItem: actionItemToEmit,
        type: eventType,
      });

      setOriginalAssignedTo(actionItem.assignedTo);

      // Close the modal after successfully saving an action item.
      onCancel();
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
      setIsWorking(false);
    }
  };

  if (hasAccessOverride) {
    modifiedAuditPageAuth.editRestriction = EditRestriction.EditAll;
  }

  const canEdit = actionItem
    ? canEditActionItem(actionItem, currentUser, currentUserProfile, modifiedAuditPageAuth)
    : true;


  const canReassignOnly = !canEdit
    && !actionItem.isLocked
    && (originalAssignedTo?.email || "").toLowerCase() === currentUser.email.toLowerCase();

  let modalButtons: ModalButton[] = [];

  const showCloseButton = canCloseAndHandleEvidenceActionItem(actionItem, currentUser, currentUserProfile, auditPageAuth)
    && actionItem.id
    && actionItem.status === ActionItemStatuses.Open;

  const showReopenButton = canCloseAndHandleEvidenceActionItem(actionItem, currentUser, currentUserProfile, auditPageAuth)
    && actionItem.status === ActionItemStatuses.Closed;

  const showCommentButton = actionItem.id
    && canAddComment(actionItem, currentUser, currentUserProfile, auditPageAuth);

  const showSaveButton = (canEdit || canReassignOnly)
    && actionItem.status !== ActionItemStatuses.Closed;

  const showValidateButton = canEdit
    && actionItem.status === ActionItemStatuses.Closed
    && actionItem.type?.name !== "Administrative";

  if (showCommentButton) {
    modalButtons.push({
      className: "tertiary",
      text: "Add Comment",
      key: "COMMENT",
      onClick: () => setIsCommentOpen(true),
    });
  }

  if (showCloseButton) {
    modalButtons.push({
      className: "secondary",
      text: "Close Action Item",
      key: "CLOSE_ACTION_ITEM",
      disabled: isDirty,
      title: isDirty
        ? "Please save any changes before attempting to close the action item."
        : "",
      onClick: () => setIsClosureOpen(true),
    });
  }

  if (showReopenButton) {
    modalButtons.push({
      className: "secondary",
      text: "Reopen Action Item",
      key: "REOPEN_ACTION_ITEM",
      disabled: isDirty,
      title: isDirty
        ? "Please save any changes before attempting to reopen the action item."
        : "",
      onClick: () => setIsReopenOpen(true),
    });
  }

  modalButtons.push({
    className: actionItem.status === ActionItemStatuses.Closed
      && actionItem.type?.name === "Administrative"
      ? "primary"
      : "secondary",
    text: showCloseButton
      || showSaveButton
      ? "Cancel"
      : "Close",
    key: "CLOSE",
    onClick: onCancel,
  });

  if (showValidateButton) {
    modalButtons.push({
      className: "primary",
      text: "Validate",
      key: "VALIDATE_ACTION_ITEM",
      disabled: isDirty,
      title: isDirty
        ? "Please save any changes before attempting to validate the action item."
        : "",
      onClick: () => setIsValidateOpen(true),
    });
  }

  if (showSaveButton) {
    modalButtons.push({
      className: "primary",
      text: "Save",
      key: "SAVE",
      disabled: !isDirty,
      title: tooltipMessage(!isDirty),
      onClick: onSave,
    });
  }

  if (modalButtons.length === 1
    && modalButtons[0].text === "Close") {
    modalButtons[0].className = "primary";
  }

  if (isLoading) {
    return (<ModalSpinner />);
  }
  return (

    <Modal
      isOpen={true}
      header={actionItem?.id
        ? `Action Item #${actionItem.id}`
        : "New Action Item"
      }
      buttons={modalButtons}
      showCloseButton={true}
      onCloseButtonClicked={onCancel}
      bottomLeftNode={
        <SendNotificationCheckbox
          isSendNotificationSelected={isSendNotificationSelected}
          setSendEmailNotification={setSendEmailNotification}
        />
      }
    >
      <FlexCol>
        <ActionItemManager
          actionItem={actionItem}
          onActionItemChanged={(value) => setActionItem(value)}
          onEvidenceChanged={(event, evidence) => {
            let newActionItem: IActionItem;

            if (event === "EvidenceAdded") {
              newActionItem = {
                ...actionItem,
                evidences: actionItem.evidences.concat([evidence]),
              };
            } else if (event === "EvidenceDeleted") {
              newActionItem = {
                ...actionItem,
                evidences: actionItem.evidences.filter(x => x.id !== evidence.id),
              };
            } else if (event === "EvidenceUpdated") {
              const existingIx = actionItem.evidences.findIndex(x => x.id === evidence.id);

              if (existingIx === -1) {
                return;
              }

              let newEvidence = actionItem.evidences.slice();
              newEvidence.splice(existingIx, 1, evidence);

              newActionItem = {
                ...actionItem,
                evidences: newEvidence,
              };
            } else {
              dispatch(showErrorToast(`Unknown EvidenceChangeEvent encountered in ManageActionItemModal: ${event}.`));
              return;
            }

            setActionItem(newActionItem);
            onActionItemChanged({
              type: event,
              evidence,
              actionItem: newActionItem,
            });
          }}
          isCausalFactorRequired={false}
          allowHandleEvidence={!!showCloseButton}
          isDisabled={!canEdit}
          allowReassign={canReassignOnly}
        />

        {!!actionItem.history?.length && (
          <>
            <Divider />
            <ActionItemHistoryLog
              history={actionItem.history}
            />
          </>
        )}

        {isClosureOpen
          && !!actionItem.id && (
            <ClosureModal
              actionItem={actionItem}
              onActionItemClosed={event => {
                const newActionItem = {
                  ...cloneDeep(actionItem),
                  status: ActionItemStatuses.Closed,
                  closureTimestamp: event.closureTimestamp,
                };
                newActionItem.history = [
                  event.newHistoryItem,
                  ...(newActionItem.history || []),
                ];
                setActionItem(newActionItem);
                onActionItemChanged({
                  type: "Closed",
                  actionItem: newActionItem,
                  newHistoryItem: event.newHistoryItem,
                });
              }}
              onEvidenceChanged={(actionItem, eventType, evidence) => {
                setActionItem(actionItem);

                onActionItemChanged({
                  type: eventType,
                  actionItem: cloneDeep(actionItem),
                  evidence,
                });
              }}
              onClose={() => setIsClosureOpen(false)}
              defaultSendNotification={defaultSendNotification}
            />
          )}

        {isReopenOpen
          && !!actionItem.id && (
            <ReopenModal
              actionItemId={actionItem.id}
              onActionItemReopened={event => {
                const newActionItem = {
                  ...cloneDeep(actionItem),
                  status: ActionItemStatuses.Open,
                  closureDate: undefined,
                };
                newActionItem.history = [
                  event.newHistoryItem,
                  ...(newActionItem.history || []),
                ];
                setActionItem(newActionItem);
                onActionItemChanged({
                  type: "Reopened",
                  actionItem: newActionItem,
                  newHistoryItem: event.newHistoryItem,
                });
              }}
              onClose={() => setIsReopenOpen(false)}
              defaultSendNotification={defaultSendNotification}
            />
          )}

        {isCommentOpen
          && !!actionItem.id && (
            <CommentModal
              actionItemId={actionItem.id}
              onActionItemCommentAdded={event => {
                const newActionItem = cloneDeep(actionItem);
                newActionItem.history = (actionItem.history || []).concat(event.newHistoryItem);
                setActionItem(newActionItem);
                onActionItemChanged({
                  type: "CommentAdded",
                  actionItem: newActionItem,
                  newHistoryItem: event.newHistoryItem,
                });
              }}
              onClose={() => setIsCommentOpen(false)}
              defaultSendNotification={defaultSendNotification}
            />
          )}

        {isValidateOpen
          && !!actionItem.id && (
            <ValidateModal
              actionItemId={actionItem.id}
              onActionItemValidated={event => {
                const newActionItem: IActionItem = {
                  ...actionItem,
                  isValidated: true,
                  history: (actionItem.history || []).concat(event.newHistoryItem),
                };
                setActionItem(newActionItem);
                onActionItemChanged({
                  type: "Validated",
                  actionItem: newActionItem,
                  newHistoryItem: event.newHistoryItem,
                });
              }}
              onClose={() => setIsValidateOpen(false)}
              defaultSendNotification={defaultSendNotification}
            />
          )}

        {unlinkModalData.isOpen
          && !!actionItem.id && (
            <UnlinkModal
              actionItem={actionItem}
              defaultSelectedLinks={unlinkModalData.args?.linksToUnlink.length
                ? actionItem
                  .links
                  .filter(l => unlinkModalData.args?.linksToUnlink.some(x => x.linkId === l.linkId.toString()
                    && x.type === l.type))
                : []
              }
              onClose={closeManageModal => {
                setUnlinkModalData({
                  isOpen: false,
                  args: undefined,
                });

                if (closeManageModal) {
                  onCancel();
                }
              }}
              onActionItemUnlinked={event => {
                const newActionItem: IActionItem = {
                  ...cloneDeep(actionItem),
                };

                event.unlinkedFrom.forEach(x => {
                  const ix = newActionItem
                    .links
                    .findIndex(z => z.linkId === x.linkId
                      && z.type === x.type);

                  if (ix > -1) {
                    newActionItem.links.splice(ix, 1);
                  }
                });

                newActionItem.history?.push(event.newHistoryItem);

                setActionItem(newActionItem);
                onActionItemChanged({
                  type: "Unlinked",
                  actionItem: newActionItem,
                  newHistoryItem: event.newHistoryItem,
                  unlinkedFrom: event.unlinkedFrom,
                });
              }}
              onActionItemDeleted={_ => {
                onActionItemChanged({
                  type: "Deleted",
                  actionItem: actionItem,
                });
                onCancel();
              }}
            />
          )}
      </FlexCol>

      {isWorking && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default ManageActionItemModal;

function getNowPlus10Days() {
  let now = new Date();
  now.setDate(now.getDate() + 10);
  return now.getTime();
}

export function createActionItemLinks(
  /** The full name of the audit (recap-name) to include in the display text of the links. */
  fullAuditName: string,
  /** The list of linked audit questions. If no questions should be linked, send empty array. */
  links: {
    /** The linked item Id. */
    linkId: number,
    /** The linked item type. */
    linkType: ActionItemLinkTypes,
    /** The linked item's display number for use in the display text of the link. (e.g. "Question #123" or "Finding #123") */
    linkDisplay: string,
    /** The Audit's id for use in the Audit Execute Question url. */
    auditId?: number;
  }[],
  linkedAuditId?: number) {
  let aiLinks: IActionItemLink[] = [];

  if (links.length) {
    // Create and append all the audit question links.
    aiLinks.push(...links.map(x => ({
      display: `${x.linkDisplay} from Audit: ${fullAuditName}`,
      linkId: x.linkId,
      type: x.linkType,
      url: x.auditId
        ? (
          x.linkType === ActionItemLinkTypes.AuditQuestion
            ? `https://${window.location.host}/audits/${x.auditId}/execute/${x.linkId}`
            : `https://${window.location.host}/audits/${x.auditId}/findings/${x.linkId}`
        ) : undefined,
    })));
  }

  if (linkedAuditId) {
    aiLinks.push({
      display: `Audit: ${fullAuditName}`,
      linkId: linkedAuditId,
      type: ActionItemLinkTypes.Audit,
      url: `https://${window.location.host}/audits/${linkedAuditId}/summary`
    });
  }

  return aiLinks;
}

export interface IPerformOnLoadOptions {
  /** The type of action to perform when the modal first opens. */
  action: ManageActionItemModalActions,
  /** The arguments to use associated with this action. */
  args?: OnloadActionArgs,
}

export interface IUnlinkActionItemArgs {
  type: "UNLINK_ARGS",
  /** The list of action item links to select for removal by default. */
  linksToUnlink: {
    linkId: string,
    type: string,
  }[],
}

export type OnloadActionArgs = IUnlinkActionItemArgs;
