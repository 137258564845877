import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback } from "react";
import { ICLMDocument, MetaDataTypes } from "types/masterDataTypes";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps } from "./pickerTypes";

interface ICLMDocumentPickerProps {
  /** Optional. If true, the picker will show deleted items as well. */
  includeDeleted?: boolean,
}

const ClmDocumentPicker: React.FC<ICLMDocumentPickerProps & ICommonPickerProps<ICLMDocument>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  showSuggestions = false,
  onRenderPicker,
  isDisabled = false,
  includeDeleted = false,
  userProfileMetaRestrictions,
  isItemDisabledMapper,
}) => {
  const renderItem = (item: ICLMDocument) => item.name;

  const restrictedToItems = userProfileMetaRestrictions
    ?.filter(x => x.type === MetaDataTypes.ClmDocument) ?? [];

  const loadItems = useCallback(async (_: string | undefined, abortSignal: AbortSignal) =>
    await MasterDataApi.getClmFunctions(includeDeleted, abortSignal),
    [includeDeleted]);


  return (
    <NonReduxPicker<ICLMDocument>
      itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
      keyMapper={x => x.id}
      onApply={onApply}
      onLoadItems={loadItems}
      renderSelectedItem={renderItem}
      selectedItems={selectedItems}
      title="CLM Function"
      allowMultiSelect={allowMultiselect}
      displayMode="list"
      renderListItem={renderItem}
      showSuggestedItems={showSuggestions}
      searchOptions={{
        filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
      }}
      onRenderPicker={onRenderPicker}
      isDisabled={isDisabled}
      isDisabledMapper={
        !isItemDisabledMapper
          && !restrictedToItems.length
          ? undefined
          : (item, ancestorPath) => {

            if (isItemDisabledMapper
              ? isItemDisabledMapper(item, ancestorPath)
              : false) {
              return true;
            } else if (!restrictedToItems?.some(x => x.id === item.id)) {
              return true;
            }

            return false;
          }
      }
    />
  );
};

export default ClmDocumentPicker;
