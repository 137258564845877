import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IMainMenuState {
  isOpen: boolean,
};

const initialState: IMainMenuState = {
  isOpen: true,
};

const mainMenuSlice = createSlice({
  name: "main-menu",
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export default mainMenuSlice;

export const {
  setIsOpen,
} = mainMenuSlice.actions;