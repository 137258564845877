import React from "react";
import { useDispatch } from "react-redux";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FacilityPicker from "shared/components/controls/pickers/FacilityPicker";
import { setSearchPropValue } from "store/search/actions/SearchActionsSlice";
import { useAppSelector } from "store/store";

const Facilities: React.FC = () => {
  const dispatch = useDispatch();
  const facilities = useAppSelector(store => store.searchActions.facilities);

  return (
    <LabeledControl
      label="Facility"
    >
      <FacilityPicker
        onSelectionChange={items =>
          dispatch(setSearchPropValue({
            facilities: items,
          }))}
        selectedItems={facilities}
        includeDeleted={true}
      />
    </LabeledControl>
  );
};

export default Facilities;