import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import CircleImgButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import editIcon from "shared/media/dls/edit-1.svg";
import { formatTimestamp } from "shared/utilities/dateFormatters";
import { toggleMgmtModal } from "store/audit-templates/AuditTemplatesSlice";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";
import "./AuditTemplateItem.scoped.scss";

export interface ITemplateItemProps {
  template: IDetailedTemplate;
  isDisabled?: boolean;
}

const AuditTemplateItem: React.FC<ITemplateItemProps> = ({
  template,
  isDisabled = false,
}) => {
  const dispatch = useDispatch();

  const headerElement = isDisabled
    ? <></>
    : (
      <div className="header-right">
        <CircleImgButton
          icon={editIcon}
          alt="Edit"
          type="primary"
          onClick={() => dispatch(toggleMgmtModal(
            {
              toogleOp: true,
              template,
            }))}
        />
      </div>
    );

  const footerElement = (
    <FlexRow className="footer">
      <LabeledControl label="Created on" className="label-sub-content-hint">
        <span>{formatTimestamp(template.createdOn)}</span>
      </LabeledControl>
      <LabeledControl label="Created by" className="label-sub-content-hint">
        <span>{template.createdBy}</span>
      </LabeledControl>
      <LabeledControl label="Modified on" className="label-sub-content-hint">
        <span>{formatTimestamp(template.modifiedOn)}</span>
      </LabeledControl>
      <LabeledControl label="Modified by" className="label-sub-content-hint">
        <span>{template.modifiedBy}</span>
      </LabeledControl>
    </FlexRow>
  );

  return (
    <Card
      className="template-item"
      titleClass="audit-card-title-16"
      title={template.name}
      headerElement={headerElement}
      footer={footerElement}
      headerDetailText="Audit Template Name"
    >
      <FlexCol className="item-content half-gap">
        {showChildren(template.children)}
      </FlexCol>
    </Card>
  );
};

export default AuditTemplateItem;

function showChildren(children: IDetailedTemplateChildren[]): ReactNode {
  return (
    <>
      {children.map((x) => ((
        <FlexCol
          className="child-list half-gap"
          key={`${x.masterDataType}_${x.masterDataId}`}
        >
          <label className="child-name">{x.name}</label>

          {x.children.length > 0
            ? showChildren(x.children)
            : null
          }
        </FlexCol>
      )))}
    </>
  )
}