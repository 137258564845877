import { PayloadAction } from "@reduxjs/toolkit";
import { IPlanProfile } from "components/audits/planning/facility-attributes/facilityAttributeTypes";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { MetaDataTypes } from "types/masterDataTypes";
import { IAuditPlanningState } from "../AuditPlanningSlice";
import { findYAxisGridItem } from "./planningGridReducers";

export interface IPlanningProfilesState {
  profiles: IPlanProfile[],
}

export const initialPlanningProfilesState: IPlanningProfilesState = {
  profiles: [],
};

export const planningProfilesReducers = {
  /** Removes the provided list of profileIds from the existing list in the store. */
  removeChildProfiles: (state: IAuditPlanningState, action: PayloadAction<number[]>) => {
    let profilesToRemove = state.profiles.filter(x => action.payload.includes(x.id))
      .map(x => ({
        profile: x,
        parentProfile: state.profiles.find(z => z.id === x.parentId),
      }));

    // Remove these profiles from the state.
    state.profiles = state.profiles.filter(x => !action.payload.includes(x.id));

    // Remove the associated yAxis items.
    profilesToRemove.forEach(childProfile => {
      if (childProfile.parentProfile) {
        // Find the parent of this item in all Y Axis lists.
        const parentYAxisItems = [
          findYAxisGridItem(state.facAttrYAxisData,
            childProfile.parentProfile.masterDataId,
            childProfile.parentProfile.masterDataType as MetaDataTypes),
          findYAxisGridItem(state.planApprovalsResultsYAxisData,
            childProfile.parentProfile.masterDataId,
            childProfile.parentProfile.masterDataType as MetaDataTypes),
        ];

        // From each of the found parents, remove these this child profile from it.
        parentYAxisItems
          .filter(isNotUndefined)
          .forEach(parentYAxisItem =>
            // Remove this profile's yAxis item from the parent.
            parentYAxisItem.children = parentYAxisItem
              .children
              .filter(x => !(x.id === childProfile.profile.masterDataId
                && x.type === childProfile.profile.masterDataType)));
      }
    });
  },

  /** Adds the specified profiles to the list of profiles already in the store. */
  addProfiles: (state: IPlanningProfilesState, action: PayloadAction<IPlanProfile[]>) => {
    state.profiles = state.profiles.concat(
      action.payload.filter(
        newProf => !state.profiles.some(x => x.id === newProf.id)));
  },
};