import { isArray } from "lodash";
import React, { useEffect, useState } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { loadCalendarYear } from "shared/store/calendar-dates/CalendarDatesSlice";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import formatDate from "shared/utilities/dateFormatters";
import { useAppDispatch, useAppSelector } from "store/store";
import { ICalendarWeek } from "types/masterDataTypes";
import DatePicker from "../date-picker/DatePicker";
import "./WeekPickerModal.scoped.scss";

interface IWeekPickerModalProps {
  year: number,
  selectedWeek: number | undefined,
  onCancel(): void,
  onApply(week: ICalendarWeek | undefined): void;
}

const WeekPickerModal: React.FC<IWeekPickerModalProps> = ({
  year,
  selectedWeek,
  onCancel,
  onApply,
}) => {
  const {
    calendars,
    loadOp,
  } = useAppSelector(store => store.calendarDates);
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<Date | undefined | null>(undefined);
  const [week, setWeek] = useState<ICalendarWeek | undefined>(undefined);

  const calendar = Object.keys(calendars).indexOf(year.toString()) > -1
    ? calendars[year]
    : undefined;
  const isLoading = loadOp?.isWorking === true;

  useEffect(() => {
    if (!calendar
      && year
      && !isLoading) {
      // No calendar loaded for the specified year.
      // Go load one.
      dispatch(loadCalendarYear(year));
    } else if (calendar
      && selectedWeek) {
      // There is a loaded calendar already.
      // Find the specified week and put it into the state as the selected one.
      const selWeek = calendar.weeks.find(x => x.week === selectedWeek);

      if (selWeek) {
        setWeek(selWeek);

        const parsedDate = new Date(selWeek.startDateTimestamp);

        setDate(new Date(parsedDate.getUTCFullYear(), parsedDate.getUTCMonth(), parsedDate.getUTCDate()));
      }
    }
  }, [calendar, year, selectedWeek, isLoading, dispatch, setWeek, setDate]);

  const onDateChange = (date: Date | [Date, Date] | null) => {
    let selDate = isArray(date) ? date[0] : date;

    setDate(selDate);

    if (selDate
      && calendar) {
      let selDateTimestamp: number;
      if (selDate.getTimezoneOffset() > 0) {
        selDateTimestamp = selDate.getTime() + (selDate.getTimezoneOffset() * 60000);
      } else {
        selDateTimestamp = selDate.getTime() - (selDate.getTimezoneOffset() * 60000);
      }
      let selectedWeek = calendar.weeks.find(x => x.startDateTimestamp <= selDateTimestamp
        && x.endDateTimestamp >= selDateTimestamp);

      setWeek(selectedWeek);
    }
  };

  return (
    <Modal
      isOpen={true}
      header="Choose Week"
      buttons={[
        {
          key: "clear",
          text: "Clear",
          className: "tertiary",
          onClick: () => onApply(undefined),
        },
        {
          key: "cancel",
          text: "Cancel",
          className: "secondary",
          onClick: onCancel,
        },
        {
          key: "apply",
          text: "Apply",
          className: "primary",
          disabled: week === undefined,
          onClick: isLoading
            ? undefined
            : () =>
              !week
                ? dispatch(showErrorToast("A week number must be selected."))
                : onApply(week),
        },
      ]}
    >
      {isLoading
        ? (
          <Spinner />
        ) : (
          <FlexCol>
            <div className="calendar-container">
              <DatePicker
                value={date}
                onChange={onDateChange}
                displayMode="inline"
                minDate={new Date(year, 0, 1)}
                maxDate={new Date(year, 11, 31)}
              />
            </div>
            <span>
              Selected Week: {week && (
                <b>
                  {week.week}
                  <br />
                  {formatDate(new Date(week.startDateTimestamp), undefined, undefined, undefined, true)} to {formatDate(new Date(week.endDateTimestamp), undefined, undefined, undefined, true)}
                </b>
              )}
            </span>
          </FlexCol >
        )}
    </Modal >
  );
};

export default WeekPickerModal;