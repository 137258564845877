import React, { useContext } from "react";
import QISchedulerContext from "../QISchedulerContext";
import { IQISchedulerRow } from "../qiSchedulerTypes";
import QISchedulerCalendarDateCell from "./QISchedulerCalendarDateCell";

interface IQISchedulerCalendarRowProps {
  /** The row bound to the scheduler. */
  row: IQISchedulerRow,
}

const QISchedulerCalendarRow: React.FC<IQISchedulerCalendarRowProps> = ({
  row,
}: IQISchedulerCalendarRowProps) => {
  const ctx = useContext(QISchedulerContext);

  if (!ctx) {
    return null;
  }

  const rowHeight = ctx.rowItemDictionary[row.id]?.rowHeight;

  return (
    <React.Fragment
      key={row.id}
    >
      <tr
        className="calendar-row"
        style={rowHeight
          ? { height: rowHeight }
          : undefined
        }
      >
        {ctx.dates.map(date =>
          <QISchedulerCalendarDateCell
            key={`${row.id}_${date.date.getTime()}`}
            isHighlighted={date.date.getTime() === ctx.today.getTime()}
          />
        )}
      </tr>

      {row.isExpanded !== false
        && row.children?.map(child =>
          <QISchedulerCalendarRow
            key={child.id}
            row={child}
          />
        )}
    </React.Fragment>
  );
};

export default QISchedulerCalendarRow;