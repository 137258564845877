import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import Hint from "shared/components/common/hint/Hint";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { useAppDispatch } from "store/store";
import { IPlanChildDimension, IPlanParentDimension, IPlanRequirementDimension } from "types/auditPlanningTypes";
import "./CreatePlanModal.scoped.scss";

const maxCommentLength = 500;

interface ICreatePlanProps {
  /**
   * Specifies if the modal is open or not. If not open, it does not render.
   */
  isOpen: boolean,
  /**
   * The action to perform when the user clicks the cancel button in the modal.
   */
  onCancel(): void,
  /**
   * The action to perform when the user clicks the save button in the modal.
   */
  onSave(comment: string): void,
  /**
   * The details of the parent dimension for the plan.
   */
  parentDimension?: IPlanParentDimension,
  /**
   * The details of the parent dimension for the plan.
   */
  childDimension?: IPlanChildDimension,
  /**
   * The details of the child dimension for the plan.
   */
  requirementDimension?: IPlanRequirementDimension,
  /**
   * The details of the requirement dimension for the plan.
   */
}

const CreatePlanModal: React.FC<ICreatePlanProps> = ({
  isOpen,
  onCancel,
  onSave,
  parentDimension,
  childDimension,
  requirementDimension,
}) => {
  const [comment, setComment] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    setComment("");
  }, [isOpen]);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (isOpen) {
      appInsights.trackPageView({
        name: `Plan Approvals - Recommend Plan`,
      });
    }
  }, [isOpen, appInsights]);

  const onSaveClicked = () => {
    if (!comment.trim()) {
      dispatch(showErrorToast("Comment is required."));
      return;
    }

    onSave(comment);
  };

  let planDisplayText = [
    parentDimension?.text,
    childDimension?.text,
  ].filter(x => !!x)
    .join(" + ");

  return (
    <Modal
      isOpen={isOpen}
      header={"Recommend Plan"}
      buttons={[{
        key: "cancel",
        text: "Cancel",
        className: "secondary",
        onClick: onCancel,
      }, {
        key: "addPlan",
        text: "Submit Recommendation",
        className: "primary",
        onClick: onSaveClicked,
      }]}
    >
      <FlexCol>
        <span>
          Would you like to recommend the addition of
          the <b>{requirementDimension?.text}</b> Audit Plan to <b>{planDisplayText}</b>?
        </span>

        <LabeledControl
          isRequired={true}
          label={`Why is ${requirementDimension?.text} being added?`}
          flexChildrenAs="column"
          hint={getTextboxMaxLengthHint(maxCommentLength)}
        >
          <textarea
            value={comment}
            onChange={e => setComment(e.currentTarget.value)}
            maxLength={maxCommentLength}
          />
          <Hint>
            {maxCommentLength - comment.length}/{maxCommentLength} characters remaining
          </Hint>
        </LabeledControl>
      </FlexCol>
    </Modal>
  );
};

export default CreatePlanModal;