import React from "react";
import { setConfirmAuditStatusChangeModal } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses } from "types/auditingTypes";

const StartAuditButton: React.FC = () => {
  const audit = useAppSelector(store => store.audit.audit);
  const originalAudit = useAppSelector(store => store.audit.originalAudit);
  const isAuditDirty = useAppSelector(store => store.audit.isAuditDirty);
  const areQuestionsDirty = useAppSelector(store => store.audit.areQuestionsDirty);
  const originalQuestions = useAppSelector(store => store.audit.originalQuestions);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const isEditable = auditPageAuth.editRestriction === EditRestriction.EditAll;
  const dispatch = useAppDispatch();

  const isDirty = isAuditDirty || areQuestionsDirty;

  if (!audit
    || !isEditable
    || audit.status !== AuditStatuses.Planned) {
    return null;
  }

  return (
    <button
      className="primary"
      onClick={() => dispatch(setConfirmAuditStatusChangeModal({
        isOpen: true,
        auditId: audit.id,
        message: "Are you sure you want to start the audit?",
        nextStatus: AuditStatuses.InProgress,
      }))}
      disabled={originalAudit === undefined
        || isDirty
        || originalQuestions.some(x => !x.auditorEmail)
      }
      title={isDirty
        || originalQuestions.some(x => !x.auditorEmail)
        ? "Questions are missing Auditor Assignments."
        : undefined
      }
    >
      Start Audit
    </button>
  );
};

export default StartAuditButton;