import { authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import { SearchType } from "components/search/SearchPage";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { ICustomActionSearch, ICustomActionSearchCreateRequest } from "types/searchActionTypes";
import { formatActionFilter } from "../formatters/customSearchActionFormatter";

class SearchActionApi {
  public async getActionFilters(id: number): Promise<ICustomActionSearch> {
    const response = await authGetJson(config.endpoints.customSearch.getActionFilters + `/${id}`);

    await throwIfResponseError(response);

    return formatActionFilter(await response.json());
  }

  public async saveActionFilters(filtersName: string, searchType: SearchType, filters: ICustomActionSearchCreateRequest): Promise<number> {
    const response = await authPostJson(config.endpoints.customSearch.saveActionFilters,
      {
        filtersName: filtersName,
        searchType: searchType,
        filtersData: filters
      });
    await throwIfResponseError(response);
    return Number(await response.json());
  }

  public async updateActionFilters(id: number, filters: ICustomActionSearchCreateRequest, abortSignal?: AbortSignal): Promise<void> {
    const response = await authPutJson(`${config.endpoints.customSearch.updateActionFilters}/${id}`,
      filters,
      abortSignal);

    await throwIfResponseError(response);
  }

  public async deleteActionFilters(id: number, abortSignal?: AbortSignal): Promise<void> {
    const response = await authPutJson(`${config.endpoints.customSearch.deleteFilters}/${id}`,
      abortSignal);

    await throwIfResponseError(response);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new SearchActionApi();
