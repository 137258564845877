import { IFinding, IFindingLink, IFindingType, IFindingTypeAnswerAssociation } from "store/audit/reducers/findingReducers";


export function formatFindingType(data: any): IFindingType {
  return {
    id: Number(data.id),
    name: data.name?.toString() || "",
    sortOrder: Number(data.sortOrder),
    answerAssociations: Array.isArray(data.answerAssociations)
      ? data.answerAssociations.map(formatFindingTypeAnswerAssociation)
      : [],
  };
}

export function formatFindingTypeAnswerAssociation(obj: any): IFindingTypeAnswerAssociation {
  return {
    answerCode: obj.answerCode?.toString() || "",
    scoringSystem: obj.scoringSystem?.toString() || "",
  };
}

export function formatSaveFindingRequest(finding: IFinding): any {
  return {
    id: finding.id,
    findingTypeId: finding.findingTypeId,
    justification: finding.justification,
    links: finding.links,
  };
}

export function formatFinding(obj: any): IFinding {
  return {
    id: Number(obj.id),
    findingTypeId: Number(obj.findingTypeId),
    justification: obj.justification?.toString() || "",
    deleted: Boolean(obj.deleted),
    links: Array.isArray(obj.links)
      ? obj.links.map(formatFindingLink)
      : [],
  };
}

export function formatFindingLink(obj: any): IFindingLink {
  return {
    findingId: Number(obj.findingId),
    id: Number(obj.id),
    linkId: Number(obj.linkId),
    linkType: obj.linkType?.toString() || "",
    deleted: Boolean(obj.deleted),
  };
}