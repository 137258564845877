import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const CARDSTATE_STORAGE_PREFIX = `cardState_`;
const CARDSTATE_PERSIST_TO: undefined | "localStorage" = undefined;

export interface ICardStatesState {
  [key: string]: ICardState | undefined,
}

export interface ICardState {
  isCollapsed: boolean,
}

const initialState: ICardStatesState = {
};

export const cardStatesSlice = createSlice({
  name: "cardStates",
  initialState,
  reducers: {
    restoreCardStatesFromCache: state => {
      if (CARDSTATE_PERSIST_TO === "localStorage") {
        for (let i = 0; i < localStorage.length; i++) {
          let key = localStorage.key(i);
          if (key?.indexOf(CARDSTATE_STORAGE_PREFIX) === 0) {
            let storageValue = localStorage.getItem(key);
            if (storageValue) {
              try {
                state[key.replace(CARDSTATE_STORAGE_PREFIX, '')] = formatCardState(storageValue, key);
              } catch { }
            }
          }
        }
      }
    },
    setCardState: (state, action: PayloadAction<ISetCardStatePayload>) => {
      state[action.payload.key] = action.payload.state;

      if (CARDSTATE_PERSIST_TO === "localStorage") {
        localStorage.setItem(`cardState_${action.payload.key}`, JSON.stringify(state[action.payload.key]));
      }
    },
  },
});

export const {
  restoreCardStatesFromCache,
  setCardState,
} = cardStatesSlice.actions;

export interface ISetCardStatePayload {
  key: string,
  state: ICardState,
}

function formatCardState(jsonString: string, key: string): ICardState {
  const obj = JSON.parse(jsonString);
  const objKeys = Object.keys(obj);

  if (obj
    && objKeys.indexOf("isCollapsed") > -1) {
    return {
      isCollapsed: Boolean(obj.isCollapsed),
    };
  } else {
    throw new Error(`Failed to parse localStorage card state for key '${key}'.`);
  }
}