import OwnerGroupsApi from "api/auditing/OwnerGroupsApi";
import MasterDataApi from "api/masterdata/MasterDataApi";
import QuestionApi from "api/question/QuestionApi";
import { history } from "App";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import { all, call, CallEffect, delay, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IOwnerGroup } from "types/auditingTypes";
import { IVerificationMethod } from "types/auditMasterDataTypes";
import { IQuestion, IQuestionMetaDataItem } from "types/questionTypes";
import { finishLoadPageData, finishSaveQuestion, finishToggleQuestionActive, IQuestionDetailsState, loadPageData, saveQuestion, saveQuestionAdditionalAssociations, toggleQuestionActive } from "./QuestionDetailsSlice";

export function* questionDetailsSagas() {
  yield all([
    loadPageDataAsync(),
    saveQuestionAsync(),
    toggleQuestionActiveAsync(),
    saveQuestionAdditionalAssociationsAsync(),
  ]);
}

function* loadPageDataAsync() {
  yield takeLatest(loadPageData, function* (action) {
    try {
      const calls: CallEffect<any>[] = [
        call(OwnerGroupsApi.getOwnerGroups),
        call(MasterDataApi.getVerificationMethods),
      ];

      if (action.payload) {
        calls.push(call(QuestionApi.getQuestionDetails, action.payload));
      }

      const callResultsArr: any[] = yield all(calls);

      yield put(finishLoadPageData({
        isWorking: false,
        data: {
          masterData: {
            ownerGroups: callResultsArr[0] as IOwnerGroup[],
            verificationMethods: callResultsArr[1] as IVerificationMethod[],
          },
          question: action.payload
            ? callResultsArr[callResultsArr.length - 1] as IQuestion
            : undefined,
        },
      }));
    } catch (err) {
      yield put(finishLoadPageData({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* saveQuestionAsync() {
  yield takeLatest(saveQuestion, function* (action) {
    try {
      // Load the partial question from the state.
      let question: Partial<IQuestion> = yield select((store: RootState) => store.questionDetails.question);

      // If the user hits the button Save as new copy, we need do clean the ID to send the backend
      // API will create a new question based on the other question that user chose
      if (action.payload.saveAsNewCopy) {
        if (question.id) {
          Object.assign(question.id, undefined)
        }
      }

      // Cast the object into a full IQuestion and send it to the server.
      // If any required properties are missing, the server will return an error.
      const newId: number = yield call(QuestionApi.saveQuestion,
        question as IQuestion,
        action.payload);

      // Finish the save operation.
      yield put(finishSaveQuestion());

      // Defer to redux to allow it time to handle the previous action.
      yield delay(1);

      // Forward the user to the url of the new question.
      history.push(formatRoute(UrlRoutes.EditQuestion, { id: newId.toString() }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishSaveQuestion());
    }
  });
}

function* toggleQuestionActiveAsync() {
  yield takeLatest(toggleQuestionActive, function* (action) {
    try {
      // Load the question id from the state.
      const questionId: number | undefined = yield select((store: RootState) => store.questionDetails.question?.id);

      if (!questionId) {
        // There is no question or no question id currently loaded!
        throw new Error("The current question (if any) cannot have its active flag changed.");
      }

      if (action.payload.isActive) {
        // Reactivate the question.
        yield call(QuestionApi.reactivateQuestion,
          questionId,
          action.payload.comment,
          action.payload.revertOnTimestamp);
      } else {
        // Deactivate the question.
        yield call(QuestionApi.deactivateQuestion,
          questionId,
          action.payload.comment,
          action.payload.revertOnTimestamp);
      }

      // Stop the operation.
      yield put(finishToggleQuestionActive());

      // Reload the question data from the server in case anything else has changed.
      yield put(loadPageData(questionId));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishToggleQuestionActive());
    }
  });
}

function* saveQuestionAdditionalAssociationsAsync() {
  yield takeLatest(saveQuestionAdditionalAssociations, function* () {
    try {
      // Load the partial question from the state.
      let questionState: IQuestionDetailsState = yield select((store: RootState) => store.questionDetails);

      if (!questionState.question
        || !questionState.originalQuestion) {
        throw new Error("Failed to save: No question data is loaded.");
      }

      const originalAddtlMeta = questionState.originalQuestion?.metaData?.filter(x => x.isAdditionalAssociation) || [];
      const addtlMeta = questionState.question?.metaData?.filter(x => x.isAdditionalAssociation) || [];

      // Find all new additional meta data.
      const newMeta = filterMetaNotInOtherList(addtlMeta, originalAddtlMeta);
      const removedMeta = filterMetaNotInOtherList(originalAddtlMeta, addtlMeta);

      if (!newMeta.length
        && !removedMeta.length) {
        throw new Error("Failed to save: No Additional Meta Data values have changed.");
      }

      // Call the backend service to save the additional meta data for this question.
      const newId: number = yield call(QuestionApi.saveQuestionAdditionalAssociations,
        questionState.question as IQuestion);

      // Finish the save operation.
      yield put(finishSaveQuestion());

      // Defer to redux to allow it time to handle the previous action.
      yield delay(1);

      // Forward the user to the url of the new question.
      history.push(formatRoute(UrlRoutes.EditQuestion, { id: newId.toString() }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishSaveQuestion());
    }
  });
}

function filterMetaNotInOtherList(metaData: IQuestionMetaDataItem[], otherList: IQuestionMetaDataItem[]): IQuestionMetaDataItem[] {
  return metaData
    .filter(item => !otherList.some(other => other.masterDataId === item.masterDataId
      && other.masterDataType === item.masterDataType
      && other.isAdditionalAssociation === item.isAdditionalAssociation));
}