import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import { groupBy } from "lodash";
import React, { useEffect } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import Prompt from "shared/components/common/prompt/Prompt";
import VideoLinkButton from "shared/components/common/video-link-button/VideoLinkButton";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IPageHeaderControl } from "shared/components/layout/page/PageBar";
import { tooltipMessage } from 'shared/utilities/stringUtilities';
import { clearSelectedQuestions } from "store/audit-summary/AuditSummarySlice";
import { saveChanges } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses, IAuditInfo, IAuditQuestion } from "types/auditingTypes";
import AuditPage from "../common/audit-page/AuditPage";
import AuditDetailsCard from "../common/details-card/AuditDetailsCard";
import ExportAuditButton from "../common/export-audit-button/ExportAuditButton";
import AuditorAssociationModal from "./auditor-assoc-modal/AuditorAssociationModal";
import AuditeesCard from "./auditors-list/AuditeesCard";
import AuditorsCard from "./auditors-list/AuditorsCard";
import SelectedQuestionsFooter from "./selected-questions-footer/SelectedQuestionsFooter";
import NextAuditStatusButton from "./status-change-controls/next-status-button/NextAuditStatusButton";
import TopicCard from "./topic-card/TopicCard";
import "./AuditSummaryPage.scoped.scss";

interface ITopicGroup {
  topicId: number,
  name: string,
  questions: IAuditQuestion[],
}

const AuditSummaryPage: React.FC = () => {
  const audit = useAppSelector(store => store.audit.audit);
  const questions = useAppSelector(store => store.audit.questions);
  const isAuditDirty = useAppSelector(store => store.audit.isAuditDirty);
  const areQuestionsDirty = useAppSelector(store => store.audit.areQuestionsDirty);
  const selectedAuditQuestionIds = useAppSelector(store => store.auditSummary.selectedAuditQuestionIds);
  const selectedAuditorTab = useAppSelector(store => store.auditSummary.selectedAuditorTab);
  const userEmail = useAppSelector(store => store.auth.currentUser.email);
  const isAssignAuditorByAssociationsModalOpen = useAppSelector(store => store.auditSummary.assignAuditorByAssociationsModal.isOpen);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearSelectedQuestions());
    };
  }, [dispatch]);

  const isDirty = isAuditDirty || areQuestionsDirty;

  // Restriction applied
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const isEditable = auditPageAuth.editRestriction === EditRestriction.EditAll;

  const {
    auditId,
  } = useParams();
  const navigate = useNavigate();

  let pageControls = audit
    && questions
    ? getPageControls(audit,
      isEditable,
      isDirty,
      dispatch,
      questions,
      userEmail,
      navigate)
    : [];

  return (
    <AuditPage
      title="Audit Summary"
      pageControls={pageControls}
      backButtonRoute={UrlRoutes.MyAudits.urlTemplate}
      footer={selectedAuditQuestionIds.length
        ? <SelectedQuestionsFooter />
        : undefined
      }
      auditId={auditId || 0}
      overviewSettings={{
        showComplianceScore: true,
        showQuestionCounts: true,
      }}
    >
      <FlexRow
        className="summary-row"
      >
        <AuditDetailsCard
          cardStateId="summaryAuditDetails"
        />

        {selectedAuditorTab === 'Auditors'
          ? <AuditorsCard />
          : <AuditeesCard />
        }
      </FlexRow>

      {audit
        && Object.entries(getTopicGroups(questions))
          .sort((a, b) => (a[1].name).localeCompare(b[1].name))
          .map(grp => (
            <TopicCard
              key={grp[0]}
              topicId={grp[1].topicId}
              title={grp[1].name}
              editable={auditPageAuth.editRestriction}
              questions={grp[1].questions}
            />
          ))}

      {isAssignAuditorByAssociationsModalOpen &&
        <AuditorAssociationModal />
      }

      <Prompt
        isDirty={isDirty}
        message="Any unsaved changes will be lost. Are you sure you want to leave?"
      />
    </AuditPage>
  );
};

export default AuditSummaryPage;


function getPageControls(audit: IAuditInfo,
  isEditable: boolean,
  isDirty: boolean,
  dispatch: Dispatch<AnyAction>,
  questions: IAuditQuestion[],
  userEmail: string,
  navigate: NavigateFunction,
) {
  let pageControls: IPageHeaderControl[] = [];
  pageControls.push({
    key: "EXPORT",
    element: (
      <ExportAuditButton
        auditId={audit.id}
      />
    ),
  });

  if (isEditable) {
    pageControls.push({
      key: "SAVE",
      element: (
        <button
          className="primary"
          disabled={!isDirty}
          onClick={() => dispatch(saveChanges())}
          title={tooltipMessage(!isDirty)}
        >
          Save
        </button>
      ),
    });
  }

  const nextStatusButton = <NextAuditStatusButton />;

  if (nextStatusButton) {
    pageControls.push({
      key: "NEXTSTATUS",
      element: nextStatusButton,
    });
  }

  if (audit.status === AuditStatuses.InProgress
    && questions.filter(x => x.auditorEmail?.toLowerCase() === userEmail?.toLowerCase()).length) {
    pageControls.push({
      key: "STARTQUESTIONS",
      element: (
        <button
          className="primary"
          onClick={() => navigate(formatRoute(UrlRoutes.AuditExecuteAll, { auditId: audit.id.toString() }))}
        >
          Start My Questions
        </button>
      ),
    });
  }

  pageControls.push(...[{
    key: "VIDEO",
    element: (
      <VideoLinkButton
        links={[{
          title: "To prepare an audit",
          url: "https://slb001.sharepoint.com/sites/QoF-ScrumTeams/_layouts/15/stream.aspx?uniqueId=9d626215-ac13-5336-e49a-dbe491344b79&portal=%7b%22ha%22:%22classicstream%22%2c%22hm%22:%22view%22%7d&referrer=StreamWebApp.Web&referrerScenario=AddressBarCopied.view&scenario=2",
          description: "This video provides information on how a Lead Auditor can prepare for an audit. It includes details like scheduling an audit, making adjustments & assigning auditors to audit",
        }, {
          title: "To execute an audit (until Status: Completed)",
          url: "https://slb001.sharepoint.com/sites/QoF-ScrumTeams/_layouts/15/stream.aspx?uniqueId=87f83a48-2298-53d1-c52d-6d3ad94f8cfd&portal=%7b%22ha%22:%22classicstream%22%2c%22hm%22:%22view%22%7d&referrer=StreamWebApp.Web&referrerScenario=AddressBarCopied.view&scenario=2&sw=auth",
          description: "This video provides step by step information on how user can execute an audit",
        }]}
      />
    ),
  }]);

  return pageControls;
}

function getTopicGroups(questions: IAuditQuestion[]) {
  return Object.entries(groupBy(questions,
    x => x.topicId))
    .map(([topicId, questions]): ITopicGroup => ({
      topicId: Number(topicId),
      name: questions[0].topicName,
      questions,
    }));
}