import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { AuditStatuses, IAuditEvidenceItem, IAuditEvidenceResponse, IAuditQuestion, } from "types/auditingTypes";

export interface IAuditEvidencesState {
  links: IAuditEvidenceItem[],
  attachments: IAuditEvidenceItem[],
  auditId: number,
  auditStatus: AuditStatuses,
  loadOperation?: IOperation<IAuditEvidenceResponse>,
  uploadOp?: IOperation<IAuditEvidenceItem>,
  deleteOp?: IOperation<IAuditEvidenceItem>,
  isAttachmentsModalVisible: boolean,
  isLinkModalVisible: boolean,
  isDeleteLinkModalVisible: boolean,
  isDeleteAttachmentModalVisible: boolean,
  itemToUpdate?: IAuditEvidenceItem,
  itemToDelete?: IAuditEvidenceItem,
  viewAuditEvidenceOp?: IOperation<IAuditEvidenceItem>,
  questionModal: {
    isOpen: boolean,
    questions: IAuditQuestion[],
  },

  loadFileData?: ILoadFileData,
}

export interface ILoadFileData {
  auditId: number,
  auditQuestionId?: number,
  evidenceId: number,
  loadOp?: IOperation<string>,
}

export interface IViewAuditEvidencePayload {
  auditId: number,
  evidenceId: number,
}

const initialState: IAuditEvidencesState = {
  links: [],
  attachments: [],
  auditId: 0,
  auditStatus: AuditStatuses.Planned,
  loadOperation: undefined,
  uploadOp: undefined,
  deleteOp: undefined,
  isAttachmentsModalVisible: false,
  isLinkModalVisible: false,
  isDeleteLinkModalVisible: false,
  isDeleteAttachmentModalVisible: false,
  itemToUpdate: undefined,
  itemToDelete: undefined,
  loadFileData: undefined,
  viewAuditEvidenceOp: undefined,
  questionModal: {
    isOpen: false,
    questions: [],
  }
};

export const auditEvidencesSlice = createSlice({
  name: "audit-evidences",
  initialState,
  reducers: {
    loadAuditEvidences: (state, _: PayloadAction<{
      id: number,
    }>) => {
      state.loadOperation = {
        isWorking: true,
      };
    },
    openAttachmentModal: (state, _: PayloadAction<{
      attachment?: IAuditEvidenceItem,
    }>) => {
      state.isAttachmentsModalVisible = true;
      state.itemToUpdate = _.payload.attachment;
    },
    closeAttachmentModal: (state, _: Action) => {
      state.isAttachmentsModalVisible = false;
      state.itemToUpdate = undefined;
    },
    closeDeleteLinkModal: (state, _: Action) => {
      state.isDeleteLinkModalVisible = false;
      state.itemToDelete = undefined;
    },
    openDeleteLinkModal: (state, _: PayloadAction<{
      item: IAuditEvidenceItem,
    }>) => {
      state.isDeleteLinkModalVisible = true;
      state.itemToDelete = _.payload.item;
    },
    closeDeleteAttachmentModal: (state, _: Action) => {
      state.isDeleteAttachmentModalVisible = false;
      state.itemToDelete = undefined;
    },
    openDeleteAttachmentModal: (state, _: PayloadAction<{
      item: IAuditEvidenceItem,
    }>) => {
      state.isDeleteAttachmentModalVisible = true;
      state.itemToDelete = _.payload.item;
    },
    openLinkModal: (state, _: PayloadAction<{
      link?: IAuditEvidenceItem,
    }>) => {
      state.isLinkModalVisible = true;
      state.itemToUpdate = _.payload.link;
    },
    closeLinkModal: (state, _: Action) => {
      state.isLinkModalVisible = false;
      state.itemToUpdate = undefined;
    },

    uploadAttachment: (state, _: PayloadAction<IUploadEvidencePayload>) => {
      state.uploadOp = {
        isWorking: true,
      };
    },

    appendUploadedAttachment: (state, action: PayloadAction<IAuditEvidenceItem>) => {
      // Add it to the modal list.
      state.attachments.unshift(action.payload);
    },

    finishUploadingAttachments: (state, action: PayloadAction<{ closeModal: boolean }>) => {
      state.uploadOp = undefined;

      if (action.payload.closeModal) {
        state.isAttachmentsModalVisible = false;
      }
    },

    uploadLink: (state, _: PayloadAction<IUploadLinkPayload>) => {
      state.uploadOp = {
        isWorking: true,
      };
    },
    finishLoadingAuditEvidences: (state, action: PayloadAction<IOperation<IAuditEvidenceResponse>>) => {
      if (action.payload.errorMessage
        || action.payload.data === undefined) {
        state.loadOperation = action.payload;
        return;
      }

      state.auditId = action.payload.data.auditId;
      state.auditStatus = action.payload.data.auditStatus;
      state.links = action.payload.data.links;
      state.attachments = action.payload.data.attachments;
      state.loadOperation = undefined;
    },

    finishUploadingLink: (state, action: PayloadAction<IOperation<IAuditEvidenceItem>>) => {
      state.uploadOp = undefined;

      if (action.payload.errorMessage
        || !action.payload.data) {
        state.uploadOp = {
          isWorking: false,
        };
        return;
      }

      // Add it to the modal list.
      state.links.unshift(action.payload.data);

      state.isLinkModalVisible = false;
      state.uploadOp = {
        isWorking: false,
      };
    },
    deleteAttachment: (state, _: PayloadAction<IDeleteAttachmentPayload>) => {
      state.deleteOp = {
        isWorking: true,
      };
    },
    deleteLink: (state, _: PayloadAction<IDeleteLinkPayload>) => {
      state.deleteOp = {
        isWorking: true,
      };
    },
    finishDeletingAttachment: (state, action: PayloadAction<IOperation<IDeleteAttachmentPayload>>) => {
      state.deleteOp = undefined;

      state.attachments =
        state.attachments.filter(item => item.id !== action.payload.data?.id);

      state.isDeleteAttachmentModalVisible = false;
      state.uploadOp = {
        isWorking: false,
      };
    },
    finishDeletingLink: (state, action: PayloadAction<IOperation<IDeleteLinkPayload>>) => {
      state.deleteOp = undefined;

      state.links =
        state.links.filter(item => item.id !== action.payload.data?.itemId);

      state.isDeleteLinkModalVisible = false;
      state.uploadOp = {
        isWorking: false,
      };
    },
    updateLink: (state, _: PayloadAction<IUpdateLinkPayload>) => {
      state.uploadOp = {
        isWorking: true,
      };
    },
    updateAttachment: (state, _: PayloadAction<IUpdateAttachmentPayload>) => {
      state.uploadOp = {
        isWorking: true,
      };
    },
    finishUpdatingLink: (state, action: PayloadAction<IOperation<IUpdateLinkPayload>>) => {
      state.uploadOp = undefined;

      if (action.payload.errorMessage
        || !action.payload.data) {
        state.uploadOp = {
          isWorking: false,
        };
        return;
      }

      // Update the item on the list.
      const linkIndex = state.links.findIndex(item => item.id === action.payload.data?.linkId);
      if (linkIndex > -1) {
        state.links[linkIndex].description = action.payload.data.description;
        state.links[linkIndex].url = action.payload.data.link;
      }

      state.isLinkModalVisible = false;
      state.uploadOp = {
        isWorking: false,
      };
    },
    finishUpdatingAttachment: (state, action: PayloadAction<IOperation<IAuditEvidenceItem>>) => {
      state.uploadOp = undefined;

      if (action.payload.errorMessage
        || !action.payload.data) {
        state.uploadOp = {
          isWorking: false,
        };
        return;
      }

      // Add it to the modal list.
      const attachmentIndex = state.attachments.findIndex(item => item.filename === action.payload.data?.filename);
      if (attachmentIndex > -1) {
        state.attachments[attachmentIndex].description = action.payload.data.description;
      }

      state.isAttachmentsModalVisible = false;
      state.uploadOp = {
        isWorking: false,
      };
    },
    loadAttachment: (state, action: PayloadAction<ILoadFileData>) => {
      state.loadFileData = {
        ...action.payload,
        loadOp: {
          isWorking: true,
        },
      };
    },
    setLoadFileData: (state, action: PayloadAction<ILoadFileData | undefined>) => {
      state.loadFileData = action.payload;
    },
    viewAuditEvidence: (state, _: PayloadAction<IViewAuditEvidencePayload>) => {
      state.viewAuditEvidenceOp = {
        isWorking: true,
      };
    },
    finishViewingAuditEvidence: (state) => {
      state.viewAuditEvidenceOp = undefined;
    },
    openQuestionLinkModal: (state, action: PayloadAction<IAuditQuestion[]>) => {
      state.questionModal = {
        isOpen: true,
        questions: action.payload,
      }
    },
    closeQuestionLinkModal: (state, _: Action) => {
      state.questionModal = {
        isOpen: false,
        questions: [],
      }
    },
  },
});

export const {
  openAttachmentModal,
  openLinkModal,
  openDeleteLinkModal,
  openDeleteAttachmentModal,
  closeAttachmentModal,
  closeLinkModal,
  closeDeleteLinkModal,
  closeDeleteAttachmentModal,
  loadAuditEvidences,
  uploadAttachment,
  updateAttachment,
  finishUpdatingAttachment,
  uploadLink,
  updateLink,
  finishUpdatingLink,
  deleteAttachment,
  deleteLink,
  appendUploadedAttachment,
  finishUploadingAttachments,
  finishLoadingAuditEvidences,
  finishUploadingLink,
  finishDeletingLink,
  finishDeletingAttachment,
  viewAuditEvidence,
  finishViewingAuditEvidence,

  loadAttachment,
  setLoadFileData,
  openQuestionLinkModal,
  closeQuestionLinkModal,
} = auditEvidencesSlice.actions;

export interface IFinishUploadingAttachmentPayload {
  evidenceStatus: string
  filename: string
  id: number
  notes: string,
  url: string,
  parent: string,
  size: number,
  createdBy: string,
  parentid: number
}
export interface IUploadEvidencePayload {
  auditId: number,
  files: FileList,
  description: string,
}

export interface IDeleteAttachmentPayload {
  auditId: number,
  id: number,
  parentId: number,
}

export interface IDeleteLinkPayload {
  auditId: number,
  itemId: number,
  type: "Audit" | "Question",
}

export interface IFinishLoadingLinkPayload {
  createdBy: string,
  createdOn: Date
  id: number
  link: string
  modifiedOn: Date
  notes: string,
  auditName: string
}

export interface IUploadLinkPayload {
  auditId: string,
  link: string,
  description: string,
}

export interface IUpdateLinkPayload {
  id: number,
  auditId: number,
  linkId: number,
  description: string,
  link: string,
  type: "Audit" | "Question",
  auditQuestionId?: number,
}

export interface IUpdateAttachmentPayload {
  auditId: number,
  id: number,
  parentId: number,
  filename: string,
  description: string,
}

