import React, { useState } from "react";
import Button from "shared/components/controls/buttons/button/Button";
import filterIcon from "shared/media/dls/filter.svg";
import { IAuditPlanningFilters } from "store/audit-planning-shared/reducers/planningFiltersReducers";
import { useAppSelector } from "store/store";
import { IAuditPlanningFiltersProps } from "./AuditPlanningFilters";
import MoreFiltersModal from "./more-filters/MoreFiltersModal";

const MoreFiltersButton: React.FC<IAuditPlanningFiltersProps> = (props) => {
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false);
  const currentFilters = useAppSelector(store => store.auditPlanning.currentFilters);
  const hasMoreFiltersSelected = checkIfMoreFiltersAreSelected(props, currentFilters);

  return (
    <>
      <Button
        buttonType="secondary"
        img={filterIcon}
        imgPlacement="right"
        onClick={() => setIsMoreFiltersOpen(true)}
        isDisabled={!currentFilters.perspectives.length}
        tooltip={!currentFilters.perspectives.length
          ? "Perspective is required first."
          : undefined
        }
      >
        More Filters{hasMoreFiltersSelected && "*"}
      </Button>

      <MoreFiltersModal
        options={props}
        isOpen={isMoreFiltersOpen}
        onClose={() => setIsMoreFiltersOpen(false)}
      />
    </>
  );
};

export default MoreFiltersButton;

function defaultTrue(opt?: boolean) {
  return opt || opt === undefined;
}

function checkIfMoreFiltersAreSelected(props: IAuditPlanningFiltersProps, filters: IAuditPlanningFilters) {
  return (defaultTrue(props.showPlanStatus) && filters.planStatus)
    || (defaultTrue(props.showFacilities) && filters.facilities.length)
    || (defaultTrue(props.showBusinessViews) && filters.businessViews.length)
    || (defaultTrue(props.showLeadAuditors) && filters.leadAuditors.length)
    || (defaultTrue(props.showReviewStatus) && filters.reviewStatus)
    || (defaultTrue(props.showReviewedBy) && filters.reviewedBy.length)
    || (defaultTrue(props.showIncludeDeletedAttributes) && filters.includeDeletedAttributes);
}