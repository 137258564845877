import AuditTopicApi from "api/masterdata/AuditTopicApi";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { IAuditTopicRearrangeState, finishInitialPageLoad, finishSaveChanges, getDirtyAuditTopics, runInitialPageLoad, saveChanges } from "./AuditTopicRearrangeSlice";

export function* auditTopicRearrangeSagas() {
  yield all([
    runInitialPageLoadAsync(),
    saveChangesAsync(),
  ]);
}

function* runInitialPageLoadAsync() {
  yield takeLatest(runInitialPageLoad, function* () {
    try {
      const auditTopics: IAuditTopic[] = yield call(AuditTopicApi.searchAuditTopics);

      auditTopics.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);

      yield put(finishInitialPageLoad({
        isWorking: false,
        data: {
          auditTopics,
        },
      }));
    } catch (err) {
      yield put(finishInitialPageLoad({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }))
    }
  });
}

function* saveChangesAsync() {
  yield takeLatest(saveChanges, function* () {
    try {
      // Get any changed audit topics.
      const state: IAuditTopicRearrangeState = yield select((store: RootState) => store.auditTopicRearrange);
      const dirtyAuditTopics = getDirtyAuditTopics(state);

      if (!dirtyAuditTopics) {
        throw new Error("No changes to save.");
      }

      // Call the api to save the updated sorts.
      const reorders = dirtyAuditTopics.map(x => ({ auditTopicId: x.id, sortOrder: x.sortOrder }));
      yield call(AuditTopicApi.reorderAuditTopics, reorders);

      // Update the redux state.
      yield put(finishSaveChanges({
        isWorking: false,
      }));

      yield put(showSuccessToast("Changes saved successfully."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishSaveChanges({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}
