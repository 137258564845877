import UrlRoutes from "components/routing/UrlRoutes";
import React from "react";
import { Link } from "react-router-dom";
import HelpButton from "shared/components/common/help-button/HelpButton";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import { IPickerItem } from "shared/types/pickerTypes";
import { ManageAuditPickerKeys, removeAuditTopic, removeTemplate, setFacilities, setSelectedIPMProjects, setSelectedMobileSites, setSelectedPickerItems, setSelectedSuppliers } from "store/manage-audit/ManageAuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import TagsRecap from "../tags-recap/TagsRecap";
import "./YourSelectionsCard.scoped.scss";

interface IYourSelectionsCardProps {
  allowEdit: boolean,
}

const YourSelectionsCard: React.FC<IYourSelectionsCardProps> = ({
  allowEdit,
}) => {
  const audit = useAppSelector(store => store.manageAudit.audit);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const hasEditPermission = editRestriction === EditRestriction.EditAll ||
    editRestriction === EditRestriction.EditOwn ?
    true :
    false;
  const dispatch = useAppDispatch();

  const getSection = (label: string,
    selectedItems: IPickerItem<any>[],
    nameMapper: (item: IPickerItem<any>) => string,
    pickerKey: ManageAuditPickerKeys,
    isDisabled?: boolean,
    behaviorOverride?: "auditTopics") => {
    if (!selectedItems.length) {
      return;
    }

    return (
      <LabeledControl
        label={label}
        className="tag-section label-sub-content-descrition"
      >
        <TagsRecap
          items={selectedItems}
          nameMapper={nameMapper}
          onTagClick={(item) => {
            if (behaviorOverride === "auditTopics") {
              dispatch(removeAuditTopic(item.item as IAuditTopic));
            } else {
              dispatch(setSelectedPickerItems({
                pickerKey: pickerKey,
                selectedItems: selectedItems.filter(x => x.key !== item.key),
              }));
            }
          }}
          isDisabled={(isDisabled || !hasEditPermission || !allowEdit)}
        />
      </LabeledControl>
    );
  };

  const getNonPickerSection = <T,>(label: string,
    selectedItems: T[],
    keyMapper: (item: T) => string | number,
    nameMapper: (item: T) => string,
    onTagClick: (item: T) => void,
    isDisabled?: boolean) => {
    if (!selectedItems.length) {
      return;
    }

    return (
      <LabeledControl
        label={label}
        className="tag-section label-sub-content-descrition"
      >
        <TagsRecap
          items={selectedItems.map(x => ({
            item: x,
            key: keyMapper(x),
          }))}
          nameMapper={x => nameMapper(x.item as T)}
          onTagClick={item => onTagClick(item.item as T)}
          isDisabled={isDisabled || !hasEditPermission || !allowEdit}
        />
      </LabeledControl>
    );
  };

  return (
    <Card
      title="Your Selections"
      className="selections-card"
      headerElement={
        <div
          className="help-header"
        >
          <Link
            to={UrlRoutes.LegacyChecklistMappings.urlTemplate}
            target="_blank"
            className="link"
          >
            Can't find your Checklist?
          </Link>

          <HelpButton
            helpSlug="auditMetadata"
          />
        </div>
      }
    >
      {getNonPickerSection("Templates",
        audit.templates.slice().sort((a, b) => a.name < b.name ? -1 : 1),
        x => x.id,
        x => x.name,
        template => dispatch(removeTemplate(template)))}

      {getSection("Topics / Sub-Topics",
        audit.auditTopics
          .slice()
          .sort((a, b) => a.name < b.name ? -1 : 1)
          .map(x => ({
            key: x.id,
            disabled: false,
            item: x,
          })),
        (item) => item.item?.name ?? "",
        ManageAuditPickerKeys.auditTopics,
        undefined,
        "auditTopics")}

      {getSection("Business View (Divisions)",
        audit.businessViews
          .slice()
          .sort((a, b) => a.name < b.name ? -1 : 1)
          .map(x => ({
            key: x.id,
            disabled: false,
            item: x,
          })),
        (item) => item.item?.name ?? "",
        ManageAuditPickerKeys.businessViews)}

      {getSection("Business Function (Functions)",
        audit.businessFunctions
          .slice()
          .sort((a, b) => a.name < b.name ? -1 : 1)
          .map(x => ({
            key: x.id,
            disabled: false,
            item: x,
          })),
        (item) => item.item?.name ?? "",
        ManageAuditPickerKeys.businessFunctions)}

      {getSection("Business Team (Basin Hierarchy)",
        audit.businessTeams
          .slice()
          .sort((a, b) => a.name < b.name ? -1 : 1)
          .map(x => ({
            key: x.id,
            disabled: false,
            item: x,
          })),
        (item) => item.item?.name ?? "",
        ManageAuditPickerKeys.businessTeams)}

      {getSection("Countries",
        audit.countries
          .slice()
          .sort((a, b) => a.name < b.name ? -1 : 1)
          .map(x => ({
            key: x.id,
            disabled: false,
            text: x.name,
          })),
        (item) => item.text ?? "",
        ManageAuditPickerKeys.countries)}

      {getNonPickerSection("Facilities",
        audit.facilities.slice().sort((a, b) => a.name < b.name ? -1 : 1),
        x => x.id,
        x => x.name,
        item => dispatch(setFacilities(audit.facilities.filter(x => x.id !== item.id))))}

      {getNonPickerSection("Mobile Sites",
        audit.mobileSites.slice().sort((a, b) => `${a.rigKey} - ${a.rigName}` < `${b.rigKey} - ${b.rigName}` ? -1 : 1),
        x => x.id,
        x => `${x.rigKey} - ${x.rigName}`,
        item => dispatch(setSelectedMobileSites(audit.mobileSites.filter(x => x.id !== item.id))))}

      {getNonPickerSection("IPM Assets",
        audit.ipmProjects.filter(x => x.type === "IPMAsset").sort((a, b) => `${a.code} - ${a.name}` < `${b.code} - ${b.name}` ? -1 : 1),
        x => x.id,
        x => `${x.code} - ${x.name}`,
        item => dispatch(setSelectedIPMProjects(audit.ipmProjects.filter(x => x.id !== item.id))))}

      {getNonPickerSection("IPM Projects",
        audit.ipmProjects.filter(x => x.type === "IPMProject").sort((a, b) => `${a.code} - ${a.name}` < `${b.code} - ${b.name}` ? -1 : 1),
        x => x.id,
        x => `${x.code} - ${x.name}`,
        item => dispatch(setSelectedIPMProjects(audit.ipmProjects.filter(x => x.id !== item.id))))}

      {getNonPickerSection("Suppliers",
        audit.suppliers.slice().sort((a, b) => `${a.aslNumber} - ${a.name}` < `${b.aslNumber} - ${b.name}` ? -1 : 1),
        x => x.id,
        x => `${x.aslNumber} - ${x.name}`,
        item => dispatch(setSelectedSuppliers(audit.suppliers.filter(x => x.id !== item.id))))}
    </Card>
  );
};

export default YourSelectionsCard;
