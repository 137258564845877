import React from "react";
import Portal from "shared/components/layout/portal/Portal";
import spinnerIcon from "shared/media/Pulse-1s-200px.svg";
import "./ModalSpinner.scoped.scss";

interface IModalSpinnerProps {
  /** Alt text for the spinner icon. */
  altText?: string,
  /** Optional: Default = true. Determines locality. If true, it will block out only its parent component. If true, blocks entire screen. */
  isLocal?: boolean,
}

const ModalSpinner: React.FC<IModalSpinnerProps> = ({
  altText,
  isLocal,
}) => {
  const spinner = (
    <div
      className={`modal-spinner ${isLocal ? "local" : ""}`}
    >
      <div
        className="fade"
      >
      </div>
      <div
        className="spinner"
        style={{
          display: "block",
          textAlign: "center",
        }}
      >
        <img
          src={spinnerIcon}
          alt={altText || ""}
        />
      </div>
    </div>
  );

  if (!isLocal) {
    return (
      <Portal>
        {spinner}
      </Portal>
    );
  } else {
    return spinner;
  }
};

export default ModalSpinner;