import FlexRow from "shared/components/layout/flex/FlexRow";
import OwnerGroupFilter from "./OwnerGroupFilter";
import AuditTopicsFilter from "./AuditTopicsFilter";
import Button from "shared/components/controls/buttons/button/Button";
import "./AuditTemplateFilters.scoped.scss";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { IAuditTemplatesFilters } from "types/templateApiTypes";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { IOwnerGroup } from "types/auditingTypes";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { setTemplateFilterProperties } from "store/audit-templates/AuditTemplatesSlice";
import { MetaDataTypes } from "types/masterDataTypes";

interface AuditTemplateFiltersProps {
  filters: IAuditTemplatesFilters;
}

const AuditTemplateFilters: React.FC<AuditTemplateFiltersProps> = ({
  filters,
}) => {
  const [ownerGroups, setOwnerGroups] = useState<IOwnerGroup[]>(
    filters.ownerGroups || []
  );
  const [auditTopics, setAuditTopics] = useState<IAuditTopic[]>(
    filters.auditTopics || []
  );
  const dispatch = useDispatch();

  const clearFilters = () => {
    dispatch(
      setTemplateFilterProperties({
        ownerGroups: undefined,
        childrenFilter: undefined,
      })
    );
    setOwnerGroups([]);
    setAuditTopics([]);
  }

  return (
    <FlexRow className="filters">
      <OwnerGroupFilter
        selected={ownerGroups}
        onApply={(items) => setOwnerGroups(items)}
      />
      <AuditTopicsFilter
        selected={auditTopics}
        filterToOwnerGroupIds={ownerGroups.map(og => og.id)}
        onApply={(items) => setAuditTopics(items)}
      />
      <LabeledControl label="&nbsp;" className="buttons">
        <Button
          buttonType="primary"
          onClick={() => dispatch(
            setTemplateFilterProperties({
              ownerGroups: ownerGroups,
              childrenFilter: auditTopics.map(auditTopic => ({id : auditTopic.id, type: MetaDataTypes.AuditTopic })),
            })
          )}
        >
          APPLY FILTERS
        </Button>
        <Button
          buttonType="secondary"
          onClick={clearFilters}
        >
          CLEAR FILTERS
        </Button>
      </LabeledControl>
    </FlexRow>
  );
};

export default AuditTemplateFilters;
