import { forwardRef, useContext } from "react";
import arrowLeft3Icon from "shared/media/dls/arrow-left-3.svg";
import arrowRight3Icon from "shared/media/dls/arrow-right-3.svg";
import formatDate from "shared/utilities/dateFormatters";
import Button from "../../buttons/button/Button";
import DatePicker, { DatePickerSelectionMode } from "../../date-picker/DatePicker";
import QISchedulerContext from "../QISchedulerContext";
import { getSchedulerMonthDateRangeFromDate } from "../qiSchedulerHelpers";
import { IQISchedulerDateRange } from "../qiSchedulerTypes";

interface IQISchedulerHeaderControlsProps {
  /** Determines the format of the displayed date range for the range picker.
   * If "range": "startDate to endDate".
   * If "monthYear": "JANUARY 2024". */
  dateRangeDisplayMode: "range" | "monthYear",
}

const QISchedulerHeaderControls = ({
  dateRangeDisplayMode,
}: IQISchedulerHeaderControlsProps) => {
  const ctx = useContext(QISchedulerContext);

  if (!ctx) {
    return null;
  }

  return (
    <>
      <Button
        img={arrowLeft3Icon}
        imgAlt="<"
        onClick={() => ctx.onDateRangeChange(getAdjacentMonthDateRange(ctx.dateRange, -1))}
        buttonType="clear"
      />

      <Button
        img={arrowRight3Icon}
        imgAlt=">"
        onClick={() => ctx.onDateRangeChange(getAdjacentMonthDateRange(ctx.dateRange, 1))}
        buttonType="clear"
      />

      <div>
        <DatePicker
          value={ctx.dateRange.startTimestamp}
          onChange={val => {
            if (val
              && 'getTime' in val) {
              ctx.onDateRangeChange(getSchedulerMonthDateRangeFromDate(val));
            }
          }}
          selectionMode={DatePickerSelectionMode.month}
          customInput={
            <CustomDateButton
              displayMode={dateRangeDisplayMode}
              currDateRange={ctx.dateRange}
            />
          }
        />
      </div>

      <span className="spacer"></span>

      <Button
        buttonType="clear"
        onClick={() => ctx.onDateRangeChange(getSchedulerMonthDateRangeFromDate(new Date()))}
      >
        Today
      </Button>
    </>
  );
};

export default QISchedulerHeaderControls;

function getAdjacentMonthDateRange(dateRange: IQISchedulerDateRange, monthDelta: -1 | 1): IQISchedulerDateRange {
  const startDate = new Date(dateRange.startTimestamp);
  const endDate = new Date(dateRange.endTimestamp);

  // Check if the date range spans the entirety of a single month
  if (startDate.getDate() === 1 && endDate.getDate() === new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate()) {
    // Calculate the new start and end dates based on the monthDelta
    const newStartDate = new Date(startDate.getFullYear(), startDate.getMonth() + monthDelta, 1);
    const newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0);

    // Convert the new dates back to timestamps
    const newStartTimestamp = newStartDate.getTime();
    const newEndTimestamp = newEndDate.getTime();

    return {
      startTimestamp: newStartTimestamp,
      endTimestamp: newEndTimestamp
    };
  }

  // If the date range doesn't span the entirety of a single month, return the original date range
  return dateRange;
}

type CustomInputProps = {
  value?: string,
  onClick?: () => void,
  displayMode: "range" | "monthYear",
  currDateRange: IQISchedulerDateRange,
};

const CustomDateButton = forwardRef<HTMLButtonElement, CustomInputProps>(
  (props, ref) => {
    const { currDateRange, displayMode, value, onClick } = props;

    return (
      <Button
        buttonType="clear"
        className="calendar-date-button"
        onClick={() => onClick?.()}
        ref={ref}
      >
        {displayMode === "range"
          ? (
            <>
              {formatTS(currDateRange.startTimestamp)} to {formatTS(currDateRange.endTimestamp)}
            </>
          ) : (
            <>
              {value}
            </>
          )}
      </Button>
    );
  }
);

function formatTS(timestamp: number): string {
  return formatDate(new Date(timestamp), false, true, "short");
}