import React from "react";
import { IAppInfo } from "shared/types/appInfoTypes";
import "../GlobalStyles.scss";
import FlowLayout from "./FlowLayout";
import "./MainLayout.scoped.scss";
import MainMenu from "./menu/MainMenu";
import { IMenuItemProps } from "./menu/MenuItem";
import TitleBar from "./titlebar/TitleBar";
import ConfirmCreateManualAuditModal from "components/audits/planning/create-audit-modal/create-audit-confirm-modal/ConfirmCreateManualAuditModal";

export interface IMainLayoutProps {
  menuItems: IMenuItemProps[],
  siteTitle: string,
  siteTitleUrl: string,
  appInfo?: IAppInfo,
  prodUrl?: string,
  /** An optional props to pass children */
  children?: React.ReactNode,
  onMainMenuIsOpenChanged?: (isOpen: boolean) => void,
}

const MainLayout: React.FC<IMainLayoutProps> = ({
  menuItems,
  siteTitle,
  siteTitleUrl,
  appInfo,
  prodUrl,
  children,
  onMainMenuIsOpenChanged,
}) =>
  <div
    className="site"
  >
    <TitleBar
      title={siteTitle}
      titleUrl={siteTitleUrl}
      appInfo={appInfo}
      prodUrl={prodUrl}
    />
    <div
      className="lower-section"
    >
      <MainMenu
        menuItems={menuItems}
        appInfo={appInfo}
        onIsOpenChanged={onMainMenuIsOpenChanged}
      />
      <FlowLayout>
        {children}
        <ConfirmCreateManualAuditModal />
      </FlowLayout>
    </div>
  </div>;

export default MainLayout;