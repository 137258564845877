import { IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import { IActiveUserProfile } from "shared/types/userProfileTypes";
import { IAuditTopic, IPerspectiveXAxes } from "types/auditMasterDataTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IEditableGridRow, IPlanRequirementDimension } from "types/auditPlanningTypes";
import { MetaDataTypes } from "types/masterDataTypes";

export function checkAttributeByTopicOrTemplatePermission(requirementId: number,
  requirementType: string,
  activeUserProfile: IActiveUserProfile,
  editRestriction: EditRestriction,
  xAxisData: IPlanRequirementDimension[]): boolean {
  if (editRestriction === EditRestriction.EditAll) {
    return true;
  } else if (editRestriction === EditRestriction.EditNone
    || editRestriction === EditRestriction.EditOwn) {
    return false;
  }

  const requirementItem = xAxisData
    .find(x => x.type === requirementType
      && x.id === requirementId)
    ?.masterDataItem;

  const ownerGroupIdOfItem = requirementItem
    ?.ownerGroupId;

  const ownerGroupRestrictions = activeUserProfile
    .profileMetaData
    .filter(x => x.type === MetaDataTypes.OwnerGroup);

  if (requirementType === MetaDataTypes.AuditTopic) {
    const auditTopicRestrictions = activeUserProfile
      .derivedMetaRestrictions
      .filter(x => x.type === MetaDataTypes.AuditTopic);

    if (!auditTopicRestrictions.length
      && !ownerGroupRestrictions.length) {
      // User is unrestricted by both types.
      return true;
    }

    if (ownerGroupRestrictions.some(x => x.masterDataId === ownerGroupIdOfItem)
      || auditTopicRestrictions.some(x => x.id === requirementId)
      || auditTopicRestrictions.some(x => x.id === (requirementItem as IAuditTopic)?.parentId)) {
      // User has allowance for either:
      //  * The owner group of the requirement item
      //  * The specific requirement item
      //  * The parent of the requirement item
      return true;
    }
  } else if (requirementType === MetaDataTypes.AuditTemplate) {
    if (!ownerGroupRestrictions.length) {
      // User is unrestricted by any owner group.
      return true;
    }

    if (ownerGroupRestrictions.some(x => x.masterDataId === ownerGroupIdOfItem)) {
      // User has allowance for the Owner Group of the template.
      return true;
    }
  }

  return false;
}

export function getEditablePlanningRows(yAxisData: IPlanningItem[],
  selectedPerspectiveXAxis: IPerspectiveXAxes | undefined): IEditableGridRow[] {
  const editableRows: IEditableGridRow[] = [];

  // If the currently-selected perspective x axis allows parent planning by non-facility items,
  // count any editable sub geo units as editableRows so that the Edit Attributes button will appear.
  const countEditableSubGeoUnits = selectedPerspectiveXAxis
    ?.plannableMasterDataTypes
    .some(x => x.masterDataType !== "Facility");

  const getEditableRows = (items: IPlanningItem[], parentRow: IPlanningItem | undefined, depth: number) => {
    items
      .forEach(x => {
        if (x.doesUserHavePermission 
          && x.isWithinPlanningRange
          && ((countEditableSubGeoUnits
            && depth === 3)
            || depth >= 4)) {
          editableRows.push({
            row: x,
            parentRow,
            rowDepth: depth,
          });
        }

        getEditableRows(x.children, x, depth + 1);
      });
  }

  getEditableRows(yAxisData, undefined, 1);

  return editableRows;
}
