import React from "react";
import { IAppInfo } from "shared/types/appInfoTypes";
import LogoTitle from "./LogoTitle";
import "./TitleBar.scoped.scss";
import UserBadge from "./UserBadge";
import UserHelp from './UserHelp';
import PrivacyPolicyLink from "./PrivacyPolicyLink";

interface ITitleBarProps {
  /** The name of the site to show. */
  title: string,
  /** The url to visit when the user clicks the title. */
  titleUrl: string,
  /** Info about the current app version. Specifically uses the env property and compares
   * against (tolowercase) "prod". If not "prod", the environment will be shown between
   * the logo and the site name and a message will be shown with a link to the prod instance
   * between the logo area and the user area.
   */
  appInfo?: IAppInfo,
  /** The url of the prod instance for the "non-prod" message link. */
  prodUrl?: string,
}

const TitleBar: React.FC<ITitleBarProps> = ({
  title,
  titleUrl,
  appInfo,
  prodUrl,
}) => {
  const showNonProd = appInfo?.env
    && appInfo.env.toLowerCase() !== "prod";

  return (
    <div
      className="title-bar no-text-select"
    >
      <LogoTitle
        title={title}
        titleUrl={titleUrl}
        appInfo={appInfo}
      />

      <span
        className="space"
      >
      </span>

      {showNonProd
        && !!prodUrl
        && (
          <>
            <div
              className="non-prod-warning"
            >
              This is the QI {appInfo.env} site. For production click <a href={prodUrl}>here</a>.
            </div>

            <span
              className="space"
            >
            </span>
          </>
        )}

      <UserBadge />
      <UserHelp />
      <PrivacyPolicyLink />
    </div>
  );
}

export default TitleBar;
