import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import { closeDeleteLinkModal, deleteLink } from "store/audit-evidences/AuditEvidencesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAuditEvidenceItem } from "types/auditingTypes";

const ConfirmDeleteLinkModal: React.FC = () => {
  const itemToDelete = useAppSelector(store => store.auditEvidences.itemToDelete);
  const deleteOp = useAppSelector(store => store.auditEvidences.deleteOp);

  const dispatch = useAppDispatch();

  return (
    <>
      <ConfirmModal
        header="Delete link"
        message="Are you sure you want to delete this link?"
        onYesClicked={() => dispatch(
          deleteLink({
            auditId: Number(itemToDelete?.auditId),
            itemId: Number(itemToDelete?.id),
            type: ((itemToDelete as IAuditEvidenceItem).type as "Audit" | "Question"),
          }))}
        onNoClicked={() => dispatch(closeDeleteLinkModal())}
      />
      
      {deleteOp?.isWorking && (
        <ModalSpinner />
      )}
    </>
  );
};

export default ConfirmDeleteLinkModal;