import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView } from "types/masterDataTypes";

export interface IQuestionLibraryFiltersState {
  /** Determines if the More Filters modal is open. */
  isMoreFiltersOpen: boolean,
  /** Determines if any filters have been applied. */
  hasFiltersApplied: boolean,
  /** The filter data currently in the modal. */
  modalFilters: IQuestionLibraryFilterData,
  /** The filter data currently applied to the page. */
  appliedFilters: IQuestionLibraryFilterData,
}

export interface IQuestionLibraryFilterData {
  businessTeams: IBusinessTeam[],
  businessViews: IBusinessView[],
  businessFunctions: IBusinessFunction[],
  auditTopics: IAuditTopic[],
}

const initialState: IQuestionLibraryFiltersState = {
  isMoreFiltersOpen: false,
  hasFiltersApplied: false,
  modalFilters: getEmptyFilterData(),
  appliedFilters: getEmptyFilterData(),
};

/** A slice representing the More Filters modal on the Question Library page. */
export const questionLibraryFiltersSlice = createSlice({
  name: "questionLibraryFilters",
  initialState,
  reducers: {
    /** Toggles visibility of the More Filters modal on the Question Library page. */
    toggleModal: (state, action: PayloadAction<boolean>) => {
      state.isMoreFiltersOpen = action.payload;

      if (state.isMoreFiltersOpen) {
        // Filter modal was just opened.
        // Copy the currently applied filters into the modal filters.
        state.modalFilters = cloneDeep(state.appliedFilters);
      }
    },

    /** Sets properties of the modalFilters value in the Question Library Page's More Filters modal. */
    setModalFilterValues: (state, action: PayloadAction<Partial<IQuestionLibraryFilterData>>) => {
      Object.assign(state.modalFilters, action.payload);
    },

    /** Clears the currently selected modal filters. */
    clearModalFilterValues: state => {
      state.modalFilters = getEmptyFilterData();
    },

    /** Clears the currently applied filters. */
    clearAppliedFilterValues: state => {
      state.appliedFilters = getEmptyFilterData();
      state.hasFiltersApplied = false;
    },

    /** Applies the currently selected modal filters, closes the modal, refreshes the question library grid. */
    applyModalFilterValues: state => {
      state.appliedFilters = cloneDeep(state.modalFilters);
      state.isMoreFiltersOpen = false;
      state.hasFiltersApplied = areAnyFiltersApplied(state.appliedFilters);
    },
  },
});

export const {
  toggleModal,
  setModalFilterValues,
  clearModalFilterValues,
  clearAppliedFilterValues,
  applyModalFilterValues,
} = questionLibraryFiltersSlice.actions;

/** Gets a new object holding all empty filters. */
function getEmptyFilterData(): IQuestionLibraryFilterData {
  return {
    businessFunctions: [],
    businessTeams: [],
    businessViews: [],
    auditTopics: [],
  };
}

/** Checks to see if any of the properties of the specified filterData are set. Arrays will check length > 0. */
function areAnyFiltersApplied(filterData: IQuestionLibraryFilterData) {
  return Object.entries(filterData).some(x => Array.isArray(x[1]) ? x[1].length > 0 : !!x[1]);
}