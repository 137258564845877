import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback } from "react";
import { IQuestionType } from "types/auditMasterDataTypes";
import NonReduxDropdownPicker from "./non-redux-dropdown-picker/NonReduxDropdownPicker";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IQuestionTypePickerProps {
  /** Optional. If true, the picker will show deleted items as well. */
  includeDeleted?: boolean,
  /** Optional. Determines the picker's mode. Default = Picker. */
  renderMode?: PickerMode,
}

const QuestionTypePicker: React.FC<IQuestionTypePickerProps & ICommonPickerProps<IQuestionType>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  onRenderPicker,
  isDisabled = false,
  includeDeleted = false,
  renderMode = "picker",
}) => {
  const renderItem = (item: IQuestionType) => item.name;

  const loadItems = useCallback(async (_: string | undefined, abortSignal: AbortSignal) =>
    await MasterDataApi.getQuestionTypes(includeDeleted, abortSignal),
    [includeDeleted]);

  if (renderMode === "picker") {
    return (
      <NonReduxPicker<IQuestionType>
        itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title="Question Types"
        allowMultiSelect={allowMultiselect}
        displayMode="list"
        renderListItem={renderItem}
        searchOptions={{
          filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
        }}
        onRenderPicker={onRenderPicker}
        isDisabled={isDisabled}
      />
    );
  } else if (renderMode === "dropdown") {
    return (
      <NonReduxDropdownPicker<IQuestionType>
        itemFormatter={renderItem}
        keyMapper={item => item.id}
        onLoadItems={loadItems}
        onSelectionChanged={onApply}
        selectedItems={selectedItems}
        isDisabled={isDisabled}
        placeholder="Select"
        selectMode={allowMultiselect ? "multiple" : "single"}
      />
    );
  } else {
    return <>Render mode "{renderMode}" is not supported.</>
  }
};

export default QuestionTypePicker;