import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { setLicenseKey } from '@mescius/wijmo';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import "@mescius/wijmo.styles/wijmo.css";
import config from 'config';
import "./Wijmo.scss";
import 'draft-js/dist/Draft.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-popper-tooltip/dist/styles.css';
import "shared/components/GlobalStyles.scss";
import "shared/components/ReactDatePicker.scss";
import Login from 'shared/components/auth/Login';
import PortalContext from 'shared/components/layout/portal/PortalContext';
import { store } from 'store/store';
import App from './App';
import { authRequest, msalPublicClient } from "./auth/auth-config";
import reportWebVitals from './reportWebVitals';


setLicenseKey(config.licenseKeys.wijmo);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <MsalProvider
    instance={msalPublicClient}
  >
    <PortalContext.Provider
      value={document.getElementById('portal')}
    >
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={(err) => (
          <p>Failed to authenticate: {JSON.stringify(err)}</p>
        )}
        loadingComponent={() => (
          <Login />
        )}
      >
        <Provider
          store={store}
        >
          <App />
        </Provider>
      </MsalAuthenticationTemplate>
    </PortalContext.Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
