import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import QIScheduler from "shared/components/controls/scheduler/QIScheduler";
import { IAuditSchedulerItemData, IQISchedulerItem, IQISchedulerRow } from "shared/components/controls/scheduler/qiSchedulerTypes";
import SchedulerItemTooltip from "shared/components/controls/scheduler/scheduler-item/tooltip/SchedulerItemTooltip";
import Card from "shared/components/layout/card/Card";
import FittedPage from "shared/components/layout/page/fitted-page/FittedPage";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { SchedulerVisibility, refreshAuditPlanCalendar, saveCalendarItem, setCalendarDateRange, setQuickInfoItem, setSelectedPlanIds } from "store/audit-plan-calendar/AuditPlanCalendarSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditSources } from "types/auditingTypes";
import AuditCalendarItem from "./audit-calendar-item/AuditCalendarItem";
import { checkShouldCancelAction, convertAuditPlansToSchedulerItems, convertBasinYAxisToSchedulerRows, convertLeadAuditorYAxisToSchedulerRows } from "./auditCalendarHelpers";
import "./AuditCalendarPage.scoped.scss";
import SchedulerItemEditorModal from "./editor-modal/SchedulerItemEditorModal";
import AuditPlanCalendarFooter from "./footer/AuditPlanCalendarFooter";
import QuickInfoModal from "./quick-info-modal/QuickInfoModal";
import SchedulerCreateAuditModal from "./scheduler-create-audit-modal/SchedulerCreateAuditModal";
import SchedulerHeader from "./scheduler-header/SchedulerHeader";

const AuditCalendarPage: React.FC = () => {
  const basinYAxisData = useAppSelector(store => store.auditPlanCalendar.basinYAxisData);
  const leadAuditorYAxisData = useAppSelector(store => store.auditPlanCalendar.leadAuditorYAxisData);
  const calendarDateRange = useAppSelector(store => store.auditPlanCalendar.calendarDateRange);
  const isLoading = useAppSelector(store => store.auditPlanCalendar.loadDataOp?.isWorking);
  const isPlanningLoading = useAppSelector(store => store.auditPlanning.isPlanningLoading);
  const appliedFilters = useAppSelector(store => store.auditPlanning.appliedFilters);
  const calendarMode = useAppSelector(store => store.auditPlanCalendar.calendarMode);
  const items = useAppSelector(store => store.auditPlanCalendar.schedulerItems);
  const selectedPlanIds = useAppSelector(store => store.auditPlanCalendar.selectedPlanIds);
  const quickInfoItem = useAppSelector(store => store.auditPlanCalendar.quickInfoItem);
  const schedulerVisibility = useAppSelector(store => store.auditPlanCalendar.schedulerVisibility);
  const editItemModal = useAppSelector(store => store.auditPlanCalendar.editItemModal);
  const saveEventsOp = useAppSelector(store => store.auditPlanCalendar.saveEventsOp);
  const currentUser = useAppSelector(store => store.auth.currentUser);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const clickTimerRef = useRef<number | null>(null);

  const [collapsedRowIds, setCollapsedRowIds] = useState<(string | number)[]>([]);

  const dispatch = useAppDispatch();

  const schedulerRows = useMemo(() => {
    if (calendarMode === "basin") {
      return convertBasinYAxisToSchedulerRows(basinYAxisData, collapsedRowIds);
    } else {
      return convertLeadAuditorYAxisToSchedulerRows(leadAuditorYAxisData, collapsedRowIds);
    }
  }, [basinYAxisData, leadAuditorYAxisData, calendarMode, collapsedRowIds]);

  const onRowToggle = useCallback((toggledRow: IQISchedulerRow, isExpanded: boolean) => {
    const isInCollapsedList = collapsedRowIds.includes(toggledRow.id);

    if (isExpanded
      && isInCollapsedList) {
      // Remove this item from the collapsed list.
      setCollapsedRowIds(collapsedRowIds.filter(x => x !== toggledRow.id));
    } else if (!isExpanded
      && !isInCollapsedList) {
      // Add this item to the collapsed list.
      setCollapsedRowIds(collapsedRowIds.concat(toggledRow.id));
    }
  }, [collapsedRowIds, setCollapsedRowIds]);

  const schedulerItems = useMemo(() => {
    return convertAuditPlansToSchedulerItems(items, selectedPlanIds, calendarMode, editRestriction, currentUser.email);
  }, [items, selectedPlanIds, calendarMode, currentUser, editRestriction]);

  const onItemClick = useCallback((extItem: IQISchedulerItem<any>, mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (clickTimerRef.current) {
      window.clearTimeout(clickTimerRef.current);
      clickTimerRef.current = null;
    }

    clickTimerRef.current = window.setTimeout(() => {
      clickTimerRef.current = null;

      const item = (extItem as IQISchedulerItem<IAuditSchedulerItemData>);

      if (!item.itemData) {
        return;
      }

      if (mouseEvent.ctrlKey) {
        if (!item.isSelectable) {
          return;
        }

        if (selectedPlanIds.includes(item.itemData.planId)) {
          // Deselect the plan.
          dispatch(setSelectedPlanIds(selectedPlanIds.filter(x => x !== item.itemData!.planId)));
        } else {
          dispatch(setSelectedPlanIds(selectedPlanIds.concat(item.itemData!.planId)));
        }
      } else {
        dispatch(setQuickInfoItem(item.itemData));
      }
    }, 500);
  }, [dispatch, selectedPlanIds]);

  const onItemDoubleClick = (extItem: IQISchedulerItem<any>) => {
    if (clickTimerRef.current) {
      window.clearTimeout(clickTimerRef.current);
      clickTimerRef.current = null;
    }

    const item = (extItem as IQISchedulerItem<IAuditSchedulerItemData>);

    if (item.itemData?.auditId
      && item.itemData.auditSource === AuditSources.QoF) {
      // This is a QI audit. Open it in a new tab.
      window.open(formatRoute(UrlRoutes.AuditSummary, {
        auditId: item.itemData.auditId.toString(),
      }));
    }
  };

  useEffect(() => {
    // Whenever the items list changes, clear any selections.
    dispatch(setSelectedPlanIds([]));
  }, [dispatch, items]);

  let mainSection: React.ReactNode;

  if (isPlanningLoading) {
    mainSection = <Spinner />;
  } else if (!appliedFilters?.perspectives.length
    || !appliedFilters?.perspectiveXAxes.length
    || !appliedFilters?.businessTeams?.length) {
    mainSection = (
      <Banner
        type={BannerType.info}
      >
        Please make the required selections above to view the relevant results.
      </Banner>
    );
  } else {
    mainSection = (
      <>
        <QIScheduler
          items={schedulerItems}
          isWorking={!!isLoading
            || isPlanningLoading
            || !!saveEventsOp?.isWorking
          }
          dateRange={calendarDateRange}
          onDateRangeChange={dateRange => {
            dispatch(setCalendarDateRange(dateRange));
          }}
          onItemClick={onItemClick}
          onItemDoubleClick={onItemDoubleClick}
          onRowToggle={onRowToggle}
          onUpdateItem={args => {
            if (checkShouldCancelAction(args,
              selectedPlanIds.length > 0,
              errMessage => dispatch(showErrorToast(errMessage)),
              editRestriction,
              currentUser.email)) {
              // Do not perform the update.
              return;
            }

            const item = args.item.itemData as IAuditSchedulerItemData;

            dispatch(saveCalendarItem({
              item,
              startDate: args.startDate.getTime(),
              endDate: args.endDate.getTime(),
              leadAuditor: item.leadAuditorEmail
                && item.leadAuditorName
                ? {
                  name: item.leadAuditorName,
                  email: item.leadAuditorEmail,
                } : undefined,
            }));
          }}
          renderSchedulerItemContent={item =>
            <AuditCalendarItem
              item={item}
            />
          }
          renderSchedulerItemTooltip={item =>
            <SchedulerItemTooltip
              isUnselectable={!item.isSelectable}
              hasSelections={selectedPlanIds.length > 0}
              item={item}
            />
          }
          rows={schedulerRows}
          highlightToday
          itemClassNameMapper={item => item.itemData?.auditStatus ?? item.itemData?.planStatus}
        />
        <AuditPlanCalendarFooter
          ownerGroup={appliedFilters?.perspectiveXAxes[0].ownerGroup}
        />
      </>

    );
  }

  return (
    <FittedPage
      title="Audit Calendar"
    >
      <div
        className="grid-card-wrapper"
      >
        <Card
          showHeader
          headerElement={
            <SchedulerHeader />
          }
          className={`calendar-card ${schedulerVisibility === SchedulerVisibility.normal
            ? ""
            : "maximized"}`
          }
        >
          {mainSection}

        </Card>
      </div>

      <SchedulerCreateAuditModal
        afterAuditCreation={() => {
          // Deselect all plans.
          dispatch(setSelectedPlanIds([]));
          dispatch(refreshAuditPlanCalendar());
        }}
      />

      {quickInfoItem &&
        <QuickInfoModal
          item={quickInfoItem}
        />
      }

      {editItemModal?.isOpen &&
        <SchedulerItemEditorModal />
      }

    </FittedPage>
  );
};

export default AuditCalendarPage;
