import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getResponseFromQuestion } from "components/audits/common/auditUtilities";
import AnswerInput from "components/audits/execution/question/answer-card/answer-input/AnswerInput";
import React, { useEffect, useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { setUpdateQuestionResponseModal, submitUpdatedResponses } from "store/audit-non-conformances/AuditNonConformancesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "./UpdateResponseModal.scoped.scss";

const UpdateResponseModal: React.FC = () => {
  const allQuestions = useAppSelector(store => store.audit.questions);
  const allAnswers = useAppSelector(store => store.audit.answers);
  const auditStatus = useAppSelector(store => store.audit.audit?.status);
  const modalData = useAppSelector(store => store.auditNonConformance.updateQuestionResponseModal);
  const auditId = useAppSelector(store => store.audit.audit?.id);
  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>();
  const [notes, setNotes] = useState<string>("");

  const dispatch = useAppDispatch();

  const selectedQuestions = allQuestions
    ?.filter(x => modalData
      .auditQuestionIds
      .indexOf(x.auditQuestionId) > -1)
    || [];

  const numSelectedQuestions = selectedQuestions.length;
  const singleQuestionOldNotes = numSelectedQuestions === 1
    && auditStatus
    ? getResponseFromQuestion(selectedQuestions[0], auditStatus)?.notes || ""
    : "";
  let allowNA = !selectedQuestions.some(x => !x.allowNA);

  useEffect(() => {
    if (singleQuestionOldNotes !== "") {
      setNotes(singleQuestionOldNotes);
    }
  }, [singleQuestionOldNotes, setNotes]);

  const onSaveClicked = () => {
    if (auditId
      && selectedAnswer) {
      dispatch(submitUpdatedResponses({
        auditId,
        auditQuestionIds: selectedQuestions.map(x => x.auditQuestionId),
        newAnswer: selectedAnswer,
        newNotes: notes,
      }));
    }
  };

  const onCancelModal = () => dispatch(setUpdateQuestionResponseModal({ isOpen: false }));

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: "ARNC - Update Question Response",
    });
  }, [appInsights]);

  return (
    <Modal
      header="Update Question Response"
      showCloseButton
      onCloseButtonClicked={onCancelModal}
      isOpen={true}
      buttons={[{
        key: "cancel",
        className: "secondary",
        text: "Cancel",
        onClick: onCancelModal,
      }, {
        key: "save",
        className: "primary",
        text: "Save",
        disabled: !numSelectedQuestions
          || notes === ""
          || !auditId,
        title: notes === ""
          ? "Notes are required."
          : undefined,
        onClick: onSaveClicked,
      }]}
    >
      {modalData.saveOp?.isWorking && (
        <ModalSpinner />
      )}

      {numSelectedQuestions === 0
        ? (
          <Banner
            type={BannerType.error}
          >
            You have no questions selected.
          </Banner>
        ) : (
          <FlexCol>
            <LabeledControl
              label={numSelectedQuestions === 1
                ? `Question #${selectedQuestions[0].questionNumber}`
                : `${numSelectedQuestions} questions selected`
              }
            >
              {numSelectedQuestions === 1
                ? (
                  <span
                    className="question-text"
                  >
                    {selectedQuestions[0].question}
                  </span>
                )
                : ""
              }
            </LabeledControl>

            <div
              className="answer-wrapper"
            >
              <AnswerInput
                allowNA={allowNA}
                isResponseUpdate={true}
                uniqueId={0}
                value={selectedAnswer}
                onChange={setSelectedAnswer}
                isDisabled={false}
                availableAnswers={allAnswers
                  .filter(x => x.scoringSystem === selectedQuestions[0].scoringSystem)
                }
              />
            </div>

            <LabeledControl
              label={"Auditor Notes"}
              isRequired
              hint={getTextboxMaxLengthHint(4000)}
            >
              <textarea
                value={notes}
                onChange={e => setNotes(e.currentTarget.value)}
                maxLength={4000}
              />
            </LabeledControl>
          </FlexCol>
        )}
    </Modal>
  );
};

export default UpdateResponseModal;