import { groupBy } from "lodash";
import { BusinessTeamType, IBusinessTeam } from "types/masterDataTypes";

/**
 * Groups and sorts BusinessTeams for display.
 * @param items BTs to group and sort.
 * @returns An array of groupings, each with a title, items, and pre-mapped labels.
 */
export function getGroupedBusinessTeams(items?: IBusinessTeam[]): IGroupedBusinessTeams[] {
  if (!items) {
    return [];
  }

  return Object.entries(groupBy(items, x => x.type))
    .map((group): IGroupedBusinessTeams => ({
      type: group[1][0].type,
      title: bvTypeLabelMappings[group[1][0].type].singular,
      pluralTitle: bvTypeLabelMappings[group[1][0].type].plural,
      items: group[1],
      labels: group[1].map(x => `${x.code} - ${x.name}`).sort((a, b) => a < b ? -1 : 1),
    })).sort((a, b) => bvSortOrder[a.type] < bvSortOrder[b.type] ? -1 : 1);
}

const bvSortOrder: Record<BusinessTeamType, number> = {
  "GeoLevel1": 1,
  "Basin": 2,
  "GeoUnit": 3,
  "SubGeoUnit": 4,
};

const bvTypeLabelMappings: Record<BusinessTeamType, { singular: string, plural: string; }> = {
  "GeoLevel1": { singular: "GeoLevel1", plural: "GeoLevel1s" },
  "Basin": { singular: "Basin", plural: "Basins" },
  "GeoUnit": { singular: "GeoUnit", plural: "GeoUnits" },
  "SubGeoUnit": { singular: "SubGeoUnit", plural: "SubGeoUnits" },
};

export interface IGroupedBusinessTeams {
  /** The BusinessTeam.type property that was used to group by. */
  type: BusinessTeamType,
  /** The title of this group. Corresponds to "Function Category", "Function" or "Sub-Function". */
  title: string,
  /** The title of this group. Corresponds to "Function Categories", "Functions" or "Sub-Functions". */
  pluralTitle: string,
  /** The items constituting this group. */
  items: IBusinessTeam[],
  /** Each item, pre-sorted, and mapped into the format `CODE - NAME`. */
  labels: string[],
}