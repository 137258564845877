import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IAuditReportSectionAttachment } from "types/auditingTypes";

export interface IAttachmentModalState {
  isOpen: boolean,
  attachmentList: IAuditReportSectionAttachment[],
  selectedItems: IAuditReportSectionAttachment[],
  loadListOp?: IOperation<IAuditReportSectionAttachment[]>,
  hasListBeenQueried: boolean,
}

const initialState: IAttachmentModalState = {
  isOpen: false,
  attachmentList: [],
  selectedItems: [],
  loadListOp: undefined,
  hasListBeenQueried: false,
};

export const attachmentModalSlice = createSlice({
  name: "audit-report-attachment-modal",
  initialState,
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    loadAuditAttachments: (state, _: PayloadAction<number>) => {
      state.loadListOp = {
        isWorking: true,
      };
    },
    finishLoadingAuditAttachments: (state,
      action: PayloadAction<IOperation<IAuditReportSectionAttachment[]>>) => {
      if (action.payload.errorMessage) {
        state.loadListOp = action.payload;
        return;
      }
      state.loadListOp = undefined;
      state.attachmentList = action.payload.data || [];
      state.hasListBeenQueried = true;
    },
    setSelectedItems: (state,
      action: PayloadAction<IAuditReportSectionAttachment[]>) => {
      state.selectedItems = cloneDeep(action.payload);
    },
    selectItem: (state,
      action: PayloadAction<IAuditReportSectionAttachment>) => {
      state.selectedItems = state.selectedItems.concat(action.payload);
    },
    deselectItem: (state,
      action: PayloadAction<IAuditReportSectionAttachment>) => {
      state.selectedItems = state.selectedItems.filter(x => !(x.id === action.payload.id
        && x.type === action.payload.type
        && x.filename === action.payload.filename));
    },
    resetState: (state) => {
      Object.assign(state, cloneDeep(initialState));
    },
  },
});

export const {
  openModal,
  closeModal,
  loadAuditAttachments,
  finishLoadingAuditAttachments,
  setSelectedItems,
  selectItem,
  deselectItem,
  resetState,
} = attachmentModalSlice.actions;
