import "components/search/filters-tooltip/FiltersTooltip.scoped.scss";
import React, { ReactNode } from "react";
import "./AppliedFiltersTooltip.scoped.scss";

interface IAppliedFiltersTooltipProps {
  /** The content of the tooltip. */
  children: ReactNode,
  /** Optional. The max height of the tooltip.
   * After this height, the tooltip scrolls its content.
   * If not specified, tooltip can be any size (even bigger than window). */
  maxHeight?: number,
}

const AppliedFiltersTooltip: React.FC<IAppliedFiltersTooltipProps> = ({ children, maxHeight }) => {
  return (
    <div className="filters-tooltip" style={maxHeight !== undefined
      ? { maxHeight }
      : undefined}
    >
      {children}
    </div>
  );
};

export default AppliedFiltersTooltip;