import React from "react";
import { IPerspective, IPerspectiveXAxes } from "types/auditMasterDataTypes";
import NonReduxPickerDropdown from "./non-redux-dropdown-picker/NonReduxDropdownPicker";

interface IPerspectiveXAxesPickerProps {
  onApply: (selectedItems: IPerspectiveXAxes[]) => void,
  selectedItems: IPerspectiveXAxes[],
  /** Optional. If true, the user cannot change selections. */
  isDisabled?: boolean,
  /** Optional. If true, the picker will show deleted items as well. */
  includeDeleted?: boolean,
  /** Optional. The XAxes from this perspective will be selectable. If not provided, control is disabled. */
  selectedPerspective?: IPerspective,
  /** Optional. If true, component is red if no value selected. */
  enableRequiredErrorDisplay?: boolean,
}

const PerspectiveXAxesPicker: React.FC<IPerspectiveXAxesPickerProps> = ({
  onApply,
  selectedItems,
  isDisabled = false,
  includeDeleted = false,
  selectedPerspective,
  enableRequiredErrorDisplay = false,
}) => {
  const renderItem = (item: IPerspectiveXAxes) => `${item.auditGroup}-${item.ownerGroup}`;

  // This loadItems is not in a callback. This forces it to re-run every re-render.
  // This is necessary because if selectedPerspective changes, this picker needs
  // to reload its items.
  const loadItems = async (_: string | undefined, __: AbortSignal) =>
    selectedPerspective
      ?.xAxes
      ?.filter(x => !x.deleted || includeDeleted) || [];

  return (
    <NonReduxPickerDropdown<IPerspectiveXAxes>
      // Specify a key value here equal to the selected perspective.
      // This will force the picker to re-render each time the selectedPerspective changes.
      key={selectedPerspective?.id || -1}
      itemFormatter={renderItem}
      keyMapper={item => item.id}
      onLoadItems={loadItems}
      onSelectionChanged={onApply}
      selectedItems={selectedItems}
      isDisabled={isDisabled || !selectedPerspective?.xAxes.length}
      placeholder="Select"
      selectMode={"single"}
      enableRequiredErrorDisplay={enableRequiredErrorDisplay}
    />
  );
};

export default PerspectiveXAxesPicker;