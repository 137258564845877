import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { ManageActionItemModalActions } from "components/actions/manage/ManageActionItemModal";
import ActionItemStatus from "components/actions/status/ActionItemStatus";
import React from "react";
import CircleImgButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import deleteIcon from "shared/media/dls/delete.svg";
import { IColumnItem } from "shared/types/columnTypes";
import { IActiveUserProfile, IAzureADUser } from "shared/types/userProfileTypes";
import { canUnlinkDeleteActionItem } from "shared/utilities/actionItemUtilities";
import formatDate from "shared/utilities/dateFormatters";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { userToString } from "shared/utilities/userUtilities";
import { viewFindings, viewQuestions } from "store/audit-rwp/AuditRWPSlice";
import { setManageActionItemModal } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes } from "types/actionItemTypes";
import { ITopicActionItemData } from "../AuditRWPPage";
import { IAuditPageAuth } from "types/auditPageAuthTypes";

interface IItemTableProps {
  actionItems: ITopicActionItemData[],
  linkTypeToRemove: "Audit" | "AuditQuestion",
}

const ItemTable: React.FC<IItemTableProps> = ({
  actionItems,
}) => {
  const currentUser = useAppSelector(store => store.auth.currentUser);
  const currentUserProfile = useAppSelector(store => store.auth.activeUserProfile);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const dispatch = useAppDispatch();

  return (
    <Table
      columns={getRWPTableColumns(currentUser, dispatch, currentUserProfile, auditPageAuth)}
      data={actionItems}
      keyProp="id"
      theme={TableThemes.compact}
    />
  );
};

export default ItemTable;

export function getRWPTableColumns(currentUser: IAzureADUser,
  dispatch: Dispatch<AnyAction>,
  currentUserProfile: IActiveUserProfile,
  auditPageAuth: IAuditPageAuth): IColumnItem<ITopicActionItemData, keyof ITopicActionItemData>[] {
  return [
    {
      key: "subTopic",
      header: "SubTopic",
      width: 130,
      maxHeight: "4em",
      getTooltip: item => item.subTopics.length > 0
        ? item.subTopics.join(', ') ?? "-"
        : "-",
      customRender: item => item.subTopics.length > 0
        ? item.subTopics.join(', ') ?? "-"
        : "-",
    },
    {
      key: "parent",
      header: "Parent",
      width: 75,
      customRender: item => {
        let parentValue: React.ReactNode = "Audit";

        if (item.parents.length === 1) {
          parentValue = (
            <button
              className="link"
              onClick={() => {
                if (item.parents[0].question) {
                  dispatch(viewQuestions([item.parents[0].question]));
                } else if (item.parents[0].finding) {
                  dispatch(viewFindings([item.parents[0].finding]));
                }
              }}
            >
              {item.parents[0].displayText}
            </button>
          );
        } else if (item.parents.length > 1) {
          parentValue = (
            <button
              className="link"
              onClick={() => {
                if (item.parents[0].question) {
                  dispatch(viewQuestions(item.parents
                    .filter(x => x.question)
                    .map(x => x.question)
                    .filter(isNotUndefined)));
                } else if (item.parents[0].finding) {
                  dispatch(viewFindings(item.parents
                    .filter(x => x.finding)
                    .map(x => x.finding)
                    .filter(isNotUndefined)));
                }
              }}
            >
              {item.parents.length} {item.parents[0].linkType === ActionItemLinkTypes.AuditQuestion
                ? "questions"
                : "findings"
              }
            </button>
          );
        }

        return parentValue;
      }
    },
    {
      key: "id",
      header: "Action Item #",
      width: 75,
      customRender: item => (
        <button
          className="link"
          onClick={() => dispatch(setManageActionItemModal({
            isOpen: true,
            actionItemId: item.id,
            auditId: undefined,
            linkOptions: undefined,
            actionToPerformOnLoad: undefined,
          }))}
        >
          {item.id}
        </button>
      ),
    },
    {
      key: "actionRequired",
      header: "Action Req.",
      width: 200,
      maxHeight: "4em",
      customRender: item => item.actionItem.actionRequired,
    },
    {
      key: "type",
      header: "Type",
      width: 125,
      maxHeight: "4em",
      customRender: item => item.actionItem.type?.name || "--",
    },
    {
      key: "causalFactor",
      header: "Causal Factor",
      width: 125,
      maxHeight: "4em",
      customRender: item => item.actionItem.causalFactor?.name || "--",
    },
    {
      key: "assignedBy",
      header: "Assigned By",
      width: 125,
      maxHeight: "4em",
      customRender: item => userToString(item.actionItem.createdBy) || "--",
    },
    {
      key: "assignedTo",
      header: "Assigned To",
      width: 125,
      maxHeight: "4em",
      customRender: item => userToString(item.actionItem.assignedTo) || "--",
    },
    {
      key: "dueDate",
      header: "Due Date",
      width: 100,
      customRender: item => formatDate(new Date(item.actionItem.dueByTimestamp)),
    },
    {
      key: "status",
      header: "Status",
      width: 150,
      customRender: item => (
        <ActionItemStatus
          statusData={item.actionItem}
        />
      ),
    },
    {
      key: "validated",
      header: "Validated?",
      width: 60,
      customRender: item => item.actionItem.type?.name === "Administrative"
        ? "N/A"
        : (item.actionItem.isValidated
          ? "Yes"
          : "No"
        ),
    },
    {
      key: "remove",
      header: "",
      width: 50,
      customRender: item => {
        if (!canUnlinkDeleteActionItem(item.actionItem, currentUser.email, currentUserProfile, auditPageAuth)) {
          return undefined;
        }

        let linksToUnlink: { type: string; linkId: string; }[];

        if (item.parents.length === 0) {
          linksToUnlink = item.actionItem.links.map(x => ({
            type: "Audit",
            linkId: x.linkId.toString(),
          }));
        } else {
          linksToUnlink = item.parents.map(x => ({
            type: x.linkType,
            linkId: x.linkId.toString(),
          }));
        }

        return (
          <CircleImgButton
            alt="Unlink"
            icon={deleteIcon}
            type="secondary"
            iconSize="small"
            onClick={() => dispatch(setManageActionItemModal({
              isOpen: true,
              actionToPerformOnLoad: {
                action: ManageActionItemModalActions.Unlink,
                args: {
                  type: "UNLINK_ARGS",
                  linksToUnlink: linksToUnlink,
                },
              },
              actionItemId: item.id,
              auditId: undefined,
              linkOptions: undefined,
            }))}
          />
        );
      },
    },
  ];
}