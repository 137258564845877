import React from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";

const HasNoMetaMessage: React.FC = () => {
  return (
    <Banner
      type={BannerType.info}
    >
      There are no questions matching any of the meta data items of this type in your audit or no meta data items of this type are in your audit.
    </Banner>
  );
};

export default HasNoMetaMessage;