import UrlRoutes, { IRoute, formatRoute } from "components/routing/UrlRoutes";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Divider from "shared/components/common/divider/Divider";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { IColumnItem } from "shared/types/columnTypes";
import { IUser } from "shared/types/userProfileTypes";
import { setOpenAssignFindingToQuestions } from "store/audit-finding/AuditFindingSlice";
import { openFindingModal } from "store/audit/AuditSlice";
import { FindingLinkType, FindingModalModes } from "store/audit/reducers/findingReducers";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAnswer, IAuditTopic } from "types/auditMasterDataTypes";
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";
import "./AssignFindingToQuestions.scoped.scss";
import { FilterRow } from "./filter-row/FilterRow";

interface IAssignFindingToQuestionsModalProps {
  questions: IAuditQuestion[],
}

export interface IAssignFindingToQuestionsModalState {
  questionFilter: string,
  topicFilter: IAuditTopic[],
  subTopicFilter: IAuditTopic[],
  auditorFilter: IUser[],
  auditeeFilter: IUser[],
  answerFilter: IAnswer[],
}

const AssignFindingToQuestionsModal: React.FC<IAssignFindingToQuestionsModalProps> = ({
  questions
}) => {
  const audit = useAppSelector(store => store.audit.audit);
  const [filterValues, setFilterValues] = useState<IAssignFindingToQuestionsModalState>({
    questionFilter: "",
    topicFilter: [],
    subTopicFilter: [],
    auditorFilter: [],
    auditeeFilter: [],
    answerFilter: [],
  });

  const updateFilterValues = (newValues: Partial<IAssignFindingToQuestionsModalState>) =>
    setFilterValues(Object.assign(cloneDeep(filterValues), newValues));

  const findingTypes = useAppSelector(store => store.audit.findingTypes);
  const [selectedAuditQuestionIds, setSelectedAuditQuestionIds] = useState<number[]>([]);
  const auditStatus = audit?.status;
  const [oldQuestionLoaded, setOldQuestionLoaded] = useState<IAuditQuestion[]>(questions);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Added this because when we click proceed this was call every time and the check boxes where cleared
    if(oldQuestionLoaded.length !== questions.length) {
      setSelectedAuditQuestionIds([]);
      setOldQuestionLoaded(questions);
    }
  }, [questions, oldQuestionLoaded]);

  const onCancel = () => {
    dispatch(setOpenAssignFindingToQuestions(false));
  };

  const onProceed = () => {
    const answerCode = questions
      .find(x => x.auditQuestionId === selectedAuditQuestionIds[0])
      ?.responses
      .find(x => x.auditStatus === auditStatus)
      ?.answer;

    if (!answerCode) {
      return;
    }

    dispatch(openFindingModal({
      finding: undefined,
      answerCode,
      desiredLinks: selectedAuditQuestionIds.map(x => ({
        linkId: x,
        linkType: FindingLinkType.AuditQuestion,
      })),
      autoSelectedLinksForRemoval: [],
      mode: FindingModalModes.EditCreate,
    }));
  };

  const changeQuestionSelection = (auditQuestionId: number) => {
    if (selectedAuditQuestionIds.includes(auditQuestionId)) {
      setSelectedAuditQuestionIds(selectedAuditQuestionIds.filter(x => x !== auditQuestionId));
    } else {
      setSelectedAuditQuestionIds(selectedAuditQuestionIds.concat(auditQuestionId));
    }
  };

  const filteredQuestions = useMemo(() =>
    filterQuestions(questions, filterValues, auditStatus)
      .map((x): IQuestionRow => ({
        auditQuestionId: x.auditQuestionId,
        questionNumber: x.questionNumber,
        topicName: x.topicName,
        answerCode: x.responses.find(x => x.auditStatus === auditStatus)?.answer ?? "",
        question: x.question,
        availableFindingTypeIds: findingTypes
          .filter(ft => ft.answerAssociations
            .some(a => a.answerCode === x.responses
              .find(x => x.auditStatus === auditStatus)
              ?.answer))
          .map(x => x.id)
          .sort((a, b) => a < b ? -1 : 1)
          .join(","),
      })),
    [filterValues, questions, auditStatus, findingTypes]);

  const firstSelectedAvailableFindingTypes = filteredQuestions
    .find(q => selectedAuditQuestionIds.includes(q.auditQuestionId))
    ?.availableFindingTypeIds;

  const changeAllQuestionSelection = (isChecked: boolean) => {
    if (!isChecked) {
      setSelectedAuditQuestionIds([]);
      return;
    }

    let matcher = firstSelectedAvailableFindingTypes;

    let selectedAQIds = selectedAuditQuestionIds.slice();

    filteredQuestions.forEach(x => {
      if (matcher === undefined) {
        if (!selectedAQIds.includes(x.auditQuestionId)) {
          selectedAQIds.push(x.auditQuestionId);
        }
        matcher = x.availableFindingTypeIds;
      } else if (matcher === x.availableFindingTypeIds) {
        if (!selectedAQIds.includes(x.auditQuestionId)) {
          selectedAQIds.push(x.auditQuestionId);
        }
      }
    });

    setSelectedAuditQuestionIds(selectedAQIds);
  };

  return (
    <Modal
      header={
        <FlexCol>
          <FlexRow>
            <span
              className="header"
            >
              Select Questions
            </span>
          </FlexRow>

          <Divider />

          <FilterRow
            filterValues={filterValues}
            onUpdateFilterValues={updateFilterValues}
            questions={questions}
          />
        </FlexCol>
      }
      isOpen={true}
      buttons={[{
        key: "CANCEL",
        text: "Cancel",
        className: "secondary",
        onClick: onCancel,
      }, {
        key: "PROCEED",
        text: "Proceed",
        className: "primary",
        disabled: selectedAuditQuestionIds.length === 0,
        onClick: onProceed,
      }]}
      showCloseButton={true}
      onCloseButtonClicked={onCancel}
    >
      <Table
        className="filter-table"
        data={filteredQuestions}
        columns={getAssignFindingToQuestionsTableColumns(
          selectedAuditQuestionIds,
          firstSelectedAvailableFindingTypes,
          filteredQuestions,
          changeQuestionSelection,
          changeAllQuestionSelection,
          navigate,
          UrlRoutes.AuditExecuteQuestion,
          audit?.id)}
        keyProp="auditQuestionId"
        theme={TableThemes.compact}
        areHeadersSticky = {false}
      />
    </Modal>
  );
};

export default AssignFindingToQuestionsModal;

function getAssignFindingToQuestionsTableColumns(
  selectedAuditQuestionIds: number[],
  availableFindingTypesMatcher: string | undefined,
  allQuestionRows: IQuestionRow[],
  changeQuestionSelection: (auditQuestionId: number) => void,
  changeAllQuestionSelection: (isChecked: boolean) => void,
  navigate: NavigateFunction,
  questionIdRoute: IRoute,
  auditId?: number): IColumnItem<IQuestionRow, keyof IQuestionRow>[] {
  return [{
    key: "checkBox",
    header:
      <input
        type="checkbox"
        checked={
          selectedAuditQuestionIds.length > 0
          && selectedAuditQuestionIds.length === allQuestionRows
            .filter(x => x.availableFindingTypeIds === availableFindingTypesMatcher)
            .length
        }
        onChange={e => changeAllQuestionSelection(e.currentTarget.checked)}
      />,
    width: 75,
    customRender: (item) => (
      <input
        type="checkbox"
        checked={selectedAuditQuestionIds.includes(item.auditQuestionId)}
        disabled={availableFindingTypesMatcher !== undefined
          && availableFindingTypesMatcher !== item.availableFindingTypeIds
        }
        onChange={_ => changeQuestionSelection(item.auditQuestionId)}
      />
    ),
  }, {
    key: "id",
    header: "ID",
    width: 75,
    customRender: (item) => (
      <button
        className="link"
        onClick={() => navigate(formatRoute(questionIdRoute, {
          auditId: auditId?.toString() || "0",
          auditQuestionId: item.auditQuestionId.toString(),
        })) }
      >
        {item.questionNumber}
      </button>
    ),
  }, {
    key: "topicName",
    property: "topicName",
    header: "Topic",
  }, {
    key: "question",
    property: "question",
    header: "Question",
  }, {
    key: "answer",
    property: "answerCode",
    header: "Answer",
  }];
}

function filterQuestions(questions: IAuditQuestion[],
  filterValues: IAssignFindingToQuestionsModalState,
  auditStatus: AuditStatuses | undefined,
): IAuditQuestion[] {
  return questions
    .filter(q =>
      (!filterValues.questionFilter
        || q.question.toLowerCase().includes(filterValues.questionFilter))
      && (!filterValues.topicFilter.length
        || filterValues.topicFilter.some(f => f.id === q.topicId))
      && (!filterValues.subTopicFilter.length
        || filterValues.subTopicFilter.some(f => q.subTopics.some(st => st.id === f.id)))
      && (!filterValues.auditorFilter.length
        || filterValues.auditorFilter.some(f => f.email === q.auditorEmail))
      && (!filterValues.auditeeFilter.length
        || filterValues.auditeeFilter.some(f => q.interviewees.some(i => i.email === f.email)))
      && (!filterValues.answerFilter.length
        || filterValues.answerFilter.some(f => q.responses.find(r => r.auditStatus === auditStatus)?.answer === f.code))
      // Is not one of the out-of-scope questions.
      && (!q.responses.some(x => x.isOutOfScope))
    );
}

interface IQuestionRow {
  auditQuestionId: number,
  questionNumber: number,
  topicName: string,
  question: string,
  answerCode: string,
  availableFindingTypeIds: string,
}