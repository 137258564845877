import { useEffect } from "react";
import Spinner from "shared/components/common/spinner/Spinner";

export interface IRadioSetProps<TItem> {
  isLoading?: boolean,
  loadError?: string,
  items: TItem[],
  selectedItem?: TItem,
  onChange: (item: TItem | undefined) => void,
  keyMapper: (item: TItem) => string,
  textMapper: (item: TItem) => string,
  loadItems?: () => void,
  autoLoadItems?: boolean,
  isDisabled?: boolean,
}

const RadioSet = <TItem,>({
  isLoading,
  loadError,
  items,
  selectedItem,
  onChange,
  keyMapper,
  textMapper,
  loadItems,
  autoLoadItems,
  isDisabled,
}: IRadioSetProps<TItem>) => {
  useEffect(() => {
    if (autoLoadItems
      && loadItems) {
      loadItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Spinner
        className="small-spinner"
        hAlign="left"
      />
    );
  } else if (loadError) {
    return (
      <span
        className="error"
        title={loadError}
      >
        Load error.
        {loadItems !== undefined && (
          <button
            className="link"
            onClick={loadItems}
          >
            Retry
          </button>
        )}
      </span>
    );
  }

  return (
    <div
      className="radio-set"
    >
      {items.map(item => (
        <label
          key={keyMapper(item)}
        >
          <input
            type="radio"
            onChange={() => onChange(item)}
            checked={selectedItem !== undefined
              && keyMapper(item) === keyMapper(selectedItem)
            }
            disabled={isDisabled}
          />
          {textMapper(item)}
        </label>
      ))
      }
      {!items.length
        && selectedItem && (
          <label
            key={keyMapper(selectedItem)}
          >
            <input
              type="radio"
              onChange={() => onChange(selectedItem)}
              checked={true}
              disabled={isDisabled}
            />
            {textMapper(selectedItem)}
          </label>
        )}
    </div>
  );
};

export default RadioSet;