import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { IAuditorDto } from "api/auditor/auditorTypes";
import { isEqual } from "lodash";
import { IOperation } from "shared/types/operationTypes";

export interface IAuditorGridRowObject {
  id: string,
  name: string,
  email: string,
  reason: string,
  gin: string,
  createdBy: string,
  type: string,
  validTo: Date,
  validFrom: Date,
}

export interface IAuditorSliceState {
  /** Determines the visibility of the auditor manager modal. */
  isManagerOpen: boolean,

  /** The currently held values of the auditor manager. */
  managerValues: Partial<IAuditorDto>,

  /** The currently held values of the audit topic manager. */
  original: Partial<IAuditorDto>,

  /** If true, the user has made some change to the loaded object. */
  isDirty: boolean,

  /** Determines the visibility of the delete/deactivate auditor confirmation modal. */
  isDeleteConfirmationOpen: boolean,

  /** Represents the save auditor operation. */
  saveOp?: IOperation<void>,
  /** Represents the load auditor operation. */
  loadOp?: IOperation<void>,
  /** Represents the delete auditor operation. */
  deleteOp?: IOperation<void>,
}

const initialState: IAuditorSliceState = {
  managerValues: {},
  original: {},
  isDirty: false,
  isManagerOpen: false,
  isDeleteConfirmationOpen: false,
  saveOp: undefined,
  loadOp: undefined,
  deleteOp: undefined,
}

export const auditorSlice = createSlice({
  name: "auditors",
  initialState,
  reducers: {
    /** Sets a value in the redux state. */
    setValue: (state, action: PayloadAction<Partial<IAuditorSliceState>>) => {
      Object.assign(state, action.payload);

      // Check if managedValues is dirty
      updateIsDirty(state);
    },


    /** Starts reverting a auditor status to the api via a Saga. */
    deleteAuditor: (state, action: PayloadAction<number>) => {
      state.saveOp = {
        isWorking: true,
      };
    },

    /** Finishes reverting a auditor. If the deletion was successful, send `true` in the payload. */
    finishDeleteAuditor: (state, action: PayloadAction<boolean>) => {
      state.saveOp = undefined;
      state.managerValues = {};
    },


    /** Starts saving a auditor to the api via a Saga. */
    stopSaveAuditor: state => {
      state.saveOp = {
        isWorking: false,
      };
    },

    /** Starts saving a auditor to the api via a Saga. */
    startSaveAuditor: state => {
      state.saveOp = {
        isWorking: true,
      };
    },

    /** Clears the save auditor operation. */
    finishSaveAuditor: (state, action: PayloadAction<boolean>) => {
      state.saveOp = undefined;

      if (action.payload) {
        state.isManagerOpen = false;
      }
    },

    /** Finishes loading a auditor and puts the auditor values into the state. */
    finishLoadAuditor: (state, action: PayloadAction<IAuditorDto | undefined>) => {
      state.loadOp = undefined;

      if (!action.payload) {
        return;
      }

      state.managerValues = action.payload;
      state.isManagerOpen = true;
    },

    /** Determines the visibility of the delete confirmation window. */
    toggleDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.isDeleteConfirmationOpen = action.payload;
    },
  },
});

function updateIsDirty(state: WritableDraft<IAuditorSliceState>) {
  if (!state.managerValues) {
    return;
  }

  const newComparer = {
    name: state.managerValues.name,
    email: state.managerValues.email,
    type: state.managerValues.type,
    validFrom: state.managerValues.validFrom,
    validTo: state.managerValues.validTo,
    reason: state.managerValues.reason,
  };

  const originalComparer = {
    name: state.original.name,
    email: state.original.email,
    type: state.original.type,
    validFrom: state.original.validFrom,
    validTo: state.original.validTo,
    reason: state.original.reason,
  };

  // check if original was modified on managerValues
  if (isEqual(newComparer, originalComparer)) {
    state.isDirty = false;
    return;
  }

  // Check required values
  if (!state.managerValues.email
    || !state.managerValues.type
    || !state.managerValues.validFrom
    || !state.managerValues.validTo
    || !state.managerValues.reason
  ) {
    state.isDirty = false;
    return;
  }

  state.isDirty = true;
}

export const {
  setValue,
  startSaveAuditor,
  stopSaveAuditor,
  finishSaveAuditor,
  finishLoadAuditor,
  toggleDeleteConfirmation,
  deleteAuditor,
  finishDeleteAuditor,
} = auditorSlice.actions;