import { cloneDeep } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import "./QIScheduler.scoped.scss";
import QISchedulerContext from "./QISchedulerContext";
import QISchedulerCalendarArea from "./calendar-area/QISchedulerCalendarArea";
import QISchedulerDateHeaderRow from "./date-header-row/QISchedulerDateHeaderRow";
import QISchedulerHeaderControls from "./header-controls/QISchedulerHeaderControls";
import { computeRowItemDictionary, getDates, getMidnightDate } from "./qiSchedulerHelpers";
import { IQISchedulerDateRange, IQISchedulerItem, IQISchedulerRow, IQISchedulerRowItemDictionary, IQISchedulerUpdateItemArgs } from "./qiSchedulerTypes";
import QISchedulerYAxisItem from "./yaxis-item/QISchedulerYAxisItem";

export interface IQISchedulerProps {
  /** The list of items to be rendered inside the scheduler. */
  items: IQISchedulerItem<any>[],
  /** The list of rows to display in the scheduler. */
  rows: IQISchedulerRow[],
  /** The date range displayed by the scheduler. */
  dateRange: IQISchedulerDateRange,
  /** Optional. If true, the current day's column will be highlighted. Default = false. */
  highlightToday?: boolean,
  /** Determines if an operation is in progress. If true, shows a spinner and disables user interactivity. */
  isWorking: boolean,
  /** Called when the user navigates the calendar, changing the start and/or end dates. */
  onDateRangeChange: (newDateRange: IQISchedulerDateRange) => void;
  /** Called when the user clicks an item in the scheduler. */
  onItemClick: (item: IQISchedulerItem<any>, mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  /** Called when the user clicks an item in the scheduler. */
  onItemDoubleClick?: (item: IQISchedulerItem<any>, mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  /** Called when the user expands or collapses a row. */
  onRowToggle: (row: IQISchedulerRow, isExpanded: boolean) => void,
  /** Called when the user attempts to update an item's row or start/end date. */
  onUpdateItem: (args: IQISchedulerUpdateItemArgs) => void,
  /** Optional. Given an item, this function returns the className to apply to the scheduler item ui element which should color the border and grips. */
  itemClassNameMapper?: (item: IQISchedulerItem<any>) => string | undefined,
  /** This function will be given a scheduler item and must return the content to be displayed inside the ui element in the scheduler. The returned ReactNode will be rendered inside a flex area that stretches the content. If multiple elements are returned, it is recommended to return a Fragment instead of a parent div to contain them. */
  renderSchedulerItemContent: (item: IQISchedulerItem<any>) => React.ReactNode,
  /** Optional. This function will be given a scheduler item and must return the content to be displayed inside the tooltip. If not provided, no tooltip will be rendered. */
  renderSchedulerItemTooltip?: (item: IQISchedulerItem<any>) => React.ReactNode,
}

const QIScheduler: React.FC<IQISchedulerProps> = (props) => {
  const {
    items: itemsExternal,
    rows,
    dateRange,
    isWorking,
    onRowToggle,
    onDateRangeChange,
  } = props;
  const calendarAreaRef = useRef<HTMLDivElement | null>(null);
  const calendarYAxisRef = useRef<HTMLDivElement | null>(null);
  const calendarXAxisRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState<{ top: number, left: number; }>({ top: 0, left: 0 });

  useEffect(() => {
    if (!calendarAreaRef.current
      || !calendarYAxisRef.current
      || !calendarXAxisRef.current) {
      return;
    }

    const calendarArea = calendarAreaRef.current;

    // Synchronize scrollbars.
    const scrollHandler = () => {
      if (!calendarArea
        || !calendarYAxisRef.current
        || !calendarXAxisRef.current) {
        return;
      }

      setScrollPosition({
        top: calendarArea.scrollTop,
        left: calendarArea.scrollLeft,
      });

      calendarYAxisRef.current.scrollTop = calendarArea.scrollTop;
      calendarXAxisRef.current.scrollLeft = calendarArea.scrollLeft;
    };

    calendarArea.addEventListener("scroll", scrollHandler);

    return () => {
      calendarArea.removeEventListener("scroll", scrollHandler);
    };
  }, [calendarAreaRef, calendarYAxisRef, calendarXAxisRef, setScrollPosition]);

  const dates = useMemo(() => {
    return getDates(new Date(dateRange.startTimestamp), new Date(dateRange.endTimestamp));
  }, [dateRange]);

  const {
    items,
    rowItemDictionary,
  } = useMemo(() => {
    const rowItemDictionary: IQISchedulerRowItemDictionary = {};

    const items = cloneDeep(itemsExternal);

    computeRowItemDictionary(rows, items, rowItemDictionary, -1);

    return {
      items,
      rowItemDictionary,
    };

  }, [rows, itemsExternal]);

  useEffect(() => {
    // When rows or dates change, scroll back to the top just in case.
    calendarAreaRef.current?.scrollTo({
      top: 0,
      left: 0,
    });
  }, [rows, dates]);

  // On every rerender, scroll back to the user's scroll position.
  calendarAreaRef.current?.scrollTo(scrollPosition);

  return (
    <div
      className="qi-scheduler"
    >
      <QISchedulerContext.Provider
        value={{
          ...props,
          items: items,
          dateRange,
          dates,
          rowItemDictionary,
          today: getMidnightDate(new Date()),
          onDateRangeChange,
          onRowToggle,
        }}
      >
        <div
          className="header-controls"
        >
          <QISchedulerHeaderControls
            dateRangeDisplayMode="monthYear"
          />
        </div>

        <div
          className="topleft"
        >

        </div>

        <div
          className="date-header-row"
          ref={calendarXAxisRef}
        >
          <QISchedulerDateHeaderRow />
        </div>

        <div
          className="yaxis"
          ref={calendarYAxisRef}
        >
          {rows.map(row =>
            <QISchedulerYAxisItem
              depth={1}
              row={row}
              key={row.id}
            />
          )}
        </div>

        <div
          className="calendar-area"
          ref={calendarAreaRef}
        >
          <QISchedulerCalendarArea
            areaDiv={calendarAreaRef.current}
          />
        </div>

        {isWorking &&
          <>
            <div
              className="qi-scheduler-fade"
            ></div>
            <Spinner
              className="qi-scheduler-spinner"
            />
          </>
        }
      </QISchedulerContext.Provider>
    </div>
  );
};

export default QIScheduler;
