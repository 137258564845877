import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import ActionItemsApi from "api/actionItems/ActionItemsApi";
import ActionItemsList from "components/actions/list/ActionItemsList";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemChangeEvent, ActionItemLinkTypes, IActionItem, IActionItemEditor, IActionItemLink } from "types/actionItemTypes";
import { AuditStatuses } from "types/auditingTypes";
import ManageActionItemModal, { IPerformOnLoadOptions, ManageActionItemModalActions } from "../manage/ManageActionItemModal";
import "./ActionItemsListModal.scoped.scss";

interface IActionItemsListModalProps {
  /** If this is true, the modal will use the `actionItemLink` prop to query the server for matching action items when the modal is loaded. */
  getActionItemsOnLoad?: boolean,

  /** If getActionItemsOnLoad is false, this is the list of action items to show in the modal. */
  actionItems?: IActionItem[],

  /** The links to add to any newly created action items from within this modal.
   * Also used if getActionItemsOnLoad is true as the search parameter to send to the server when the modal is loaded. */
  actionItemLinks?: IActionItemLink[],

  /** The list of users that should be included as editors in any newly created action items. */
  actionItemEditors?: IActionItemEditor[],

  /** If true, the current user can see and use the Create New Action Item button. */
  allowAdd: boolean,

  /** The header to show at the top of the modal. Default: Action Items */
  header?: string,

  /** When creating a new action item, it will automatically be assigned to the specified user. Default = current user. */
  assignedTo?: IAzureADUser,

  /** If provided, this node will be rendered after the list of action items. */
  bottomNode?: React.ReactNode,

  /** The callback to use when the user clicks the close button. */
  onClose(): void,

  /** The callback function to call after the action item is changed successfully on the API. */
  onActionItemChanged?(event: ActionItemChangeEvent): void,

  /** The audit status to apply the send notification rule */
  auditStatus?: AuditStatuses,
}

const ActionItemsListModal: React.FC<IActionItemsListModalProps> = ({
  getActionItemsOnLoad,
  actionItemLinks,
  actionItems: initialActionItems,
  actionItemEditors,
  allowAdd,
  header = "Action Items",
  assignedTo,
  bottomNode,
  onClose,
  onActionItemChanged: onActionItemSavedFwd,
  auditStatus,
}) => {
  const dispatch = useAppDispatch();

  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const [actionItems, setActionItems] = useState<IActionItem[]>(initialActionItems
    ? cloneDeep(initialActionItems)
    : []);
  const [isLoading, setIsLoading] = useState(false);
  const [manageActionItemModal, setManageActionItemModal] = useState<IManageActionItemModalOptions>({
    isOpen: false,
    actionItemId: undefined,
    actionItemLink: undefined,
    actionToPerformOnLoad: undefined,
  });
  const allowEdit = auditStatus !== AuditStatuses.Closed;

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: "New Action Items",
    });
  }, [appInsights]);

  useEffect(() => {
    let aborted = false;
    const controller = new AbortController();

    if (!getActionItemsOnLoad) {
      return;
    }

    const getActionItems = async () => {
      if (actionItemLinks === undefined
        || actionItemLinks.length === 0) {
        dispatch(showErrorToast("No Action Item Links specified. Unable to search for action items."));
      }

      if (aborted) {
        return;
      }

      setIsLoading(true);

      try {
        const responseItems = await ActionItemsApi.searchActionItems({
          linksSearch: actionItemLinks?.map(x => ({
            linkId: x.linkId.toString(),
            type: x.type,
          })) || [],
        }, controller.signal);

        if (aborted) {
          return;
        }

        setActionItems(responseItems);
      } catch (err) {
        if (aborted) {
          return;
        }

        dispatch(showErrorToast(getResponseErrorMessage(err)));
      } finally {
        if (!aborted) {
          setIsLoading(false);
        }
      }
    };

    getActionItems();

    return () => {
      aborted = true;
      controller.abort();
    };
  }, [getActionItemsOnLoad, actionItemLinks, setIsLoading, setActionItems, dispatch]);

  let buttons = [{
    key: "CLOSE",
    text: "Close",
    className: "secondary",
    onClick: onClose,
  }];

  if (allowAdd) {
    buttons.push({
      key: "ADD",
      text: "Create New Action Item",
      className: "primary",
      onClick: () => setManageActionItemModal({
        isOpen: true,
        actionItemId: undefined,
        actionToPerformOnLoad: undefined,
      }),
    });
  }

  const replaceLocalActionItem = (actionItem: IActionItem) => {
    const existingIx = actionItems.findIndex(x => x.id === actionItem.id);

    if (existingIx > -1) {
      const newList = actionItems.slice();
      newList.splice(existingIx, 1, actionItem);
      setActionItems(newList);
    } else {
      setActionItems(actionItems.concat(actionItem));
    }
  };

  return (
    <Modal
      isOpen={true}
      header={header}
      buttons={buttons}
      showCloseButton={true}
      onCloseButtonClicked={onClose}
    >
      <FlexCol>
        {!!actionItems.length
          ? (
            <ActionItemsList
              isDisabled={!allowAdd}
              onEditClick={item => setManageActionItemModal({
                isOpen: true,
                actionItemId: item.id,
                actionToPerformOnLoad: undefined,
              })}
              allowEdit={allowEdit}
              auditPageAuth = {auditPageAuth}
              onDeleteClick={item => setManageActionItemModal({
                isOpen: true,
                actionItemId: item.id,
                actionToPerformOnLoad: {
                  action: ManageActionItemModalActions.Unlink,
                  args: actionItemLinks
                    ? {
                      type: "UNLINK_ARGS",
                      linksToUnlink: actionItemLinks.map(x => ({
                        linkId: x.linkId.toString(),
                        type: x.type,
                      })),
                    } : undefined,
                },
              })}
              actionItems={actionItems}
            />
          ) : (
            <Banner
              type={BannerType.info}
            >
              {getNoItemsMessage(actionItemLinks)}
            </Banner>
          )
        }

        {bottomNode}
      </FlexCol>

      {manageActionItemModal.isOpen && (
        <ManageActionItemModal
          actionItem={manageActionItemModal.actionItemId
            ? actionItems.find(x => x.id === manageActionItemModal.actionItemId)
            : undefined
          }
          actionItemEditors={actionItemEditors || []}
          actionItemLinks={actionItemLinks || []}
          assignedTo={assignedTo}
          actionToPerformOnLoad={manageActionItemModal.actionToPerformOnLoad}
          onCancel={() => setManageActionItemModal({
            isOpen: false,
            actionToPerformOnLoad: undefined,
          })}
          onActionItemChanged={event => {
            // This action item should be removed from the list if it was deleted.
            let shouldRemoveFromList = event.type === "Deleted";

            // Also remove it if it was unlinked and the action item is no longer
            // linked to anything passed in via the actionItemLinks prop.
            if (!shouldRemoveFromList
              && event.type === "Unlinked"
              && !event.actionItem.links.some(x =>
                (actionItemLinks || [])
                  .some(z => z.linkId === x.linkId
                    && z.type === x.type))) {
              shouldRemoveFromList = true;
            }

            if (shouldRemoveFromList) {
              setActionItems(actionItems.filter(x => x.id !== event.actionItem.id));
            } else {
              replaceLocalActionItem(event.actionItem);
            }

            onActionItemSavedFwd?.(event);

            if (event.type === "Created") {
              // Since a new item was just created, update the modal to pass in that id
              // so the manager can correctly compare differences.
              setManageActionItemModal({
                ...manageActionItemModal,
                actionItemId: event.actionItem.id,
                actionToPerformOnLoad: undefined,
              });
            }
          }}
          auditStatus={auditStatus}
        />
      )}

      {isLoading && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default ActionItemsListModal;

interface IManageActionItemModalOptions {
  /** Whether or not the modal is open. */
  isOpen: boolean,
  /** The ActionItem id if this is open for edit. If not provided, the modal will be used to create a new action item instead. */
  actionItemId?: number,
  /** The link to apply to a newly created action item. */
  actionItemLink?: IActionItemLink,
  /** If specified, the manage action item modal will immediately perform this action upon load. */
  actionToPerformOnLoad?: IPerformOnLoadOptions,
}

function getNoItemsMessage(actionItemLinks: IActionItemLink[] | undefined) {
  let firstLinkTypeDisplay = actionItemLinks?.[0]?.type;

  if (firstLinkTypeDisplay === ActionItemLinkTypes.AuditQuestion) {
    firstLinkTypeDisplay = "question";
  } else if (firstLinkTypeDisplay === ActionItemLinkTypes.Audit) {
    firstLinkTypeDisplay = "audit";
  } else if (firstLinkTypeDisplay === ActionItemLinkTypes.AuditFinding) {
    firstLinkTypeDisplay = "finding";
  }

  return `There are no action items associated with this ${firstLinkTypeDisplay}.`;
}