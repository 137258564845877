import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { ISearchActionsState, setSearchPropValue } from "store/search/actions/SearchActionsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

interface IActionFreeTextEntryProps {
  propName: keyof ISearchActionsState,
  label: string,
  placeholder?: string,
}

const ActionFreeTextEntry: React.FC<IActionFreeTextEntryProps> = ({
  propName,
  label,
  placeholder,
}) => {
  const value = useAppSelector(store => store.searchActions[propName] as string);
  const dispatch = useAppDispatch();

  return (
    <LabeledControl
      label={label}
      hint={getTextboxMaxLengthHint(200)}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={e => dispatch(setSearchPropValue({
          [propName]: e.currentTarget.value,
        }))}
        maxLength={200}
      />
    </LabeledControl>
  );
};

export default ActionFreeTextEntry;