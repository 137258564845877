import MasterDataApi from "api/masterdata/MasterDataApi";
import AuditPlanningApi from "api/planning/AuditPlanningApi";
import { Action } from "redux";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast, showWarningToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import formatDate from "shared/utilities/dateFormatters";
import { addPlan, removePlan as removePlanFromState, setPlanToFinalApproval } from "store/audit-planning-shared/AuditPlanningSlice";
import { IAuditPlanningFilters } from "store/audit-planning-shared/reducers/planningFiltersReducers";
import { RootState } from "store/store";
import {
  IApprovePlanRequest,
  IAuditPlanView,
  ICreatePlanRequest,
  ICreatePlanResponse,
  IRemovePlanResponse
} from "types/auditPlanningTypes";
import { ICalendarWeek } from "types/masterDataTypes";
import {
  IApprovalModalItem,
  IAuditPlanApprovalsState,
  createPlan,
  editPlan,
  finishApprovePlans,
  finishCreatingPlan,
  finishEditingPlan,
  finishRemovingPlan,
  removePlan,
  setCurrentEditPlanApprovalData,
  setEditPlanModal,
  setFinishLoadEditPlanModal,
  startApprovePlans
} from "./AuditPlanApprovalsSlice";

export default function* auditPlanApprovalsSagas() {
  yield all([
    startApprovePlansAsync(),
    createPlanAsync(),
    removePlanAsync(),
    setEditPlanModalAsync(),
    editPlanAsync(),
  ]);
}

function* startApprovePlansAsync() {
  yield takeLatest(startApprovePlans, function* () {
    try {

      const modalItems: IApprovalModalItem[] =
        yield select((store: RootState) => store.auditPlanApprovals.approvalModal.items);

      const approvals: IApprovePlanRequest[] = modalItems.map(x => ({
        auditPlanId: x.plan.id,
        leadAuditorEmail: x.leadAuditor?.email,
        leadAuditorName: x.leadAuditor?.name,
        weekNumber: x.weekNumber,
      }));

      yield call(AuditPlanningApi.approvePlans, approvals);
      yield put(setPlanToFinalApproval(approvals));
      yield put(finishApprovePlans({
        isWorking: false,
      }));
      yield put(showSuccessToast("Audit Plans approved successfully."));
    } catch (err) {
      yield put(finishApprovePlans({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));

      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

function* createPlanAsync() {
  yield takeLatest(createPlan, function* (action: Action) {
    if (!createPlan.match(action)) {
      return;
    }

    try {
      const appliedFilters: IAuditPlanningFilters | undefined = yield select((store: RootState) => store.auditPlanning.appliedFilters);
      const state: IAuditPlanApprovalsState = yield select((store: RootState) => store.auditPlanApprovals);

      if (!state.createPlanModal.parentDimension) {
        throw new Error("A parent dimension must be specified.");
      }
      if (!state.createPlanModal.requirementDimension) {
        throw new Error("A requirement dimension must be specified.");
      }
      if (!appliedFilters?.perspectives.length) {
        throw new Error("A perspective must be specified.");
      }
      if (!appliedFilters?.perspectiveXAxes.length) {
        throw new Error("An X-Axis must be specified!");
      }
      if (!appliedFilters?.auditType) {
        throw new Error("An auditType must be specified.");
      }

      const request: ICreatePlanRequest = {
        auditGroupId: appliedFilters.perspectiveXAxes[0].auditGroupId,
        auditTypeId: appliedFilters.auditType.id,
        planYear: appliedFilters.planYear,
        comment: action.payload.comment,
        parentDimensionId: state.createPlanModal.parentDimension.id,
        parentDimensionType: state.createPlanModal.parentDimension.type,
        childDimensionId: state.createPlanModal.childDimension?.id,
        childDimensionType: state.createPlanModal.childDimension?.type,
        requirementDimensionId: state.createPlanModal.requirementDimension?.id,
        requirementDimensionType: state.createPlanModal.requirementDimension?.type,
      };

      const createdPlan: ICreatePlanResponse = yield call(AuditPlanningApi.createPlan, request);
      const modalData = state.createPlanModal;

      if (modalData.parentDimension?.id
        && modalData.parentDimension?.type
        && modalData.requirementDimension?.id
        && modalData.requirementDimension?.type) {
        yield put(addPlan({
          id: createdPlan.id,
          approvalStatus: createdPlan.approvalStatus,
          auditGroupId: appliedFilters.perspectiveXAxes[0].auditGroupId,
          auditTypeId: appliedFilters.auditType.id,
          deleted: createdPlan.deleted,
          parentDimensionId: modalData.parentDimension?.id,
          parentDimensionType: modalData.parentDimension?.type,
          planYear: appliedFilters.planYear,
          requirementDimensionId: modalData.requirementDimension?.id,
          requirementDimensionType: modalData.requirementDimension?.type,
          childDimensionId: modalData.childDimension?.id,
          childDimensionType: modalData.childDimension?.type,
          comment: createdPlan.comment,
          modifiedBy: createdPlan.modifiedBy,
          modifiedByName: createdPlan.modifiedByName,
          modifiedOn: createdPlan.modifiedOn,
          source: createdPlan.source,
        }));
      } else {
        yield put(showWarningToast("Could not display new plan recommendation. Please re-apply filters or refresh the page."));
      }

      yield put(finishCreatingPlan());

      yield put(showSuccessToast("New plan recommendation created successfully."))
    } catch (err) {
      yield put(finishCreatingPlan());
      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

function* removePlanAsync() {
  yield takeLatest(removePlan, function* (action: Action) {
    if (!removePlan.match(action)) {
      return;
    }

    try {
      const state: IAuditPlanApprovalsState = yield select((store: RootState) => store.auditPlanApprovals);

      if (!state.removePlanModal.planId) {
        throw new Error("A planId must be specified.");
      }

      if (!action.payload.comment) {
        throw new Error("A comment must be specified.");
      }

      const removalData: IRemovePlanResponse = yield call(AuditPlanningApi.removePlan,
        state.removePlanModal.planId,
        action.payload.comment);

      yield put(removePlanFromState(removalData));

      yield put(finishRemovingPlan());

      yield put(showSuccessToast("Plan removed successfully."));
    } catch (err) {
      yield put(finishRemovingPlan());
      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

function* setEditPlanModalAsync() {
  yield takeLatest(setEditPlanModal, function* (action: Action) {
    if (!setEditPlanModal.match(action)) {
      return;
    }

    if (action.payload.isOpen === false) {
      return;
    }

    try {
      let selectedWeek: ICalendarWeek | undefined = undefined;

      const planApprovalsPlans: IAuditPlanView[] = yield select((store: RootState) => store.auditPlanning.planApprovalsPlans);
      const state: IAuditPlanApprovalsState = yield select((store: RootState) => store.auditPlanApprovals);
      const {
        calendars,
      } = yield select((store => store.calendarDates));

      const planId = state.planApprovalEditModal.planId;
      if (!planId) {
        throw new Error("A planId must be specified!");
      }

      const plans = planApprovalsPlans.filter(p => p.id === planId);
      if (plans.length > 0) {
        // Try to get latest calendar loaded
        let calendar: any = Object.keys(calendars).indexOf(plans[0].planYear.toString()) > -1
          ? calendars[plans[0].planYear]
          : undefined;

        if (!calendar
          && plans[0].weekOfYear
          && plans[0].planYear) {
          // No calendar loaded for the specified year.
          // Go load one.
          const weeks: ICalendarWeek[] = yield call(MasterDataApi.getWeeksByYear, plans[0].planYear);
          calendar = { weeks };
        }

        if (calendar) {
          selectedWeek = calendar.weeks.find((x: { week: number | undefined; }) => x.week === plans[0].weekOfYear);
        }

        yield put(setCurrentEditPlanApprovalData({
          items: [{
            plan: plans[0],
            weekNumber: plans[0].weekOfYear,
            weekLabel: selectedWeek
              ? `${selectedWeek.week} (${formatDate(new Date(selectedWeek.startDateTimestamp), undefined, undefined, undefined, true)} to ${formatDate(new Date(selectedWeek.endDateTimestamp), undefined, undefined, undefined, true)})`
              : "---",
            requirementDimension: state.confirmEditOrRemovePlanModal.requirementDimension,
            leadAuditor: (plans[0].leadAuditorEmail && plans[0].leadAuditorName)
              ? {
                name: plans[0].leadAuditorName,
                email: plans[0].leadAuditorEmail,
              }
              : undefined,
          }],
        }));
      }

      yield put(setFinishLoadEditPlanModal());
    } catch (err) {
      yield put(finishEditingPlan({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));

      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

function* editPlanAsync() {
  yield takeLatest(editPlan, function* (action: Action) {
    if (!editPlan.match(action)) {
      return;
    }

    try {
      const state: IAuditPlanApprovalsState = yield select((store: RootState) => store.auditPlanApprovals);

      if (!state.planApprovalEditModal.planId) {
        throw new Error("A planId must be specified!");
      }

      const modalItems: IApprovalModalItem[] =
        yield select((store: RootState) => store.auditPlanApprovals.planApprovalEditModal.items);

      const approvals: IApprovePlanRequest[] = modalItems.map(x => ({
        auditPlanId: x.plan.id,
        leadAuditorEmail: x.leadAuditor?.email,
        leadAuditorName: x.leadAuditor?.name,
        weekNumber: x.weekNumber,
      }));

      yield call(AuditPlanningApi.approvePlans, approvals);
      yield put(setPlanToFinalApproval(approvals));
      yield put(finishEditingPlan({
        isWorking: false,
      }));

      yield put(showSuccessToast("Plan changed successfully!"));
    } catch (err) {
      yield put(finishEditingPlan({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));

      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}