import { ComplianceResults } from "types/auditPlanningTypes";
import { IComplianceQuestionProps, IComplianceScore } from "../auditScoreCalculator";

class QMSScoreCalculator {
  calculateComplianceScore(questions: IComplianceQuestionProps[]): IComplianceScore {
    const ltoQuestions = questions
      .filter(x => x.isLto
        && (x.category === "Category 1"
          || x.category === "Category 2"));

    const complianceData = this.getCompliance(questions);
    const ltoComplianceData = this.getCompliance(ltoQuestions.filter(x => x.answer !== undefined));

    const result: IComplianceScore = {
      overallCompliance: complianceData?.compliance,
      overallPercentage: complianceData?.percentage,
      cat1Percentage: undefined,
      cat2Percentage: undefined,
      ltoCompliance: ltoComplianceData?.percentage,
      numLTONonCompliantQuestions: ltoQuestions.filter(x => x.answer === "N").length,
      numLTOQuestions: ltoQuestions.length,
    };

    return result;
  };

  private getCompliance(questions: IComplianceQuestionProps[]) {
    const answeredQuestions = questions
      .filter(x => x.answer !== undefined
        && x.answer !== "NA");

    const percentage = answeredQuestions.length !== 0
      ? answeredQuestions
        .filter(x => x.answer === "Y")
        .length / answeredQuestions.length
      : undefined;
    const compliance = percentage !== undefined
      ? (percentage >= .5
        ? ComplianceResults.FullCompliance
        : ComplianceResults.NonCompliance
      )
      : undefined;

    return {
      percentage,
      compliance,
    };
  }
}

export default QMSScoreCalculator;