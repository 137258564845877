import "components/search/filters-tooltip/FiltersTooltip.scoped.scss";
import FilterTooltipDateRangeSection from "components/search/filters-tooltip/FilterTooltipDateRangeSection";
import FilterTooltipSection from "components/search/filters-tooltip/FilterTooltipSection";
import React from "react";
import { userToString } from "shared/utilities/userUtilities";
import { useAppSelector } from "store/store";

const FiltersTooltip: React.FC = () => {
  const state = useAppSelector(store => store.searchAudits);

  return (
    <div className="filters-tooltip">
      {/* Primary Filters */}

      <FilterTooltipSection
        label="Audit Id"
        values={[state.auditId || ""]}
      />

      <FilterTooltipDateRangeSection
        label="Completion Date"
        values={[state.completionDateStart, state.completionDateEnd]}
      />

      <FilterTooltipSection
        label="Audit Type"
        values={state.pickerData.auditTypes.selectedItems.map(x => x.text || x.key.toString())}
      />

      <FilterTooltipSection
        label="Audit Topic / Sub-Topic"
        values={state.pickerData.auditTopics.selectedItems.map(x => x.item?.name || x.text || x.key.toString())}
      />

      <FilterTooltipSection
        label="Status"
        values={state.status.map(x => x.toString())}
      />

      <FilterTooltipSection
        label="Facility"
        values={state.facilities.map(x => x.name)}
      />

      <FilterTooltipSection
        label="Country"
        values={state.pickerData.countries.selectedItems.map(x => x.item?.name || x.text || x.key.toString())}
      />

      <FilterTooltipSection
        label="Business View (Divisions)"
        values={state.pickerData.businessViews.selectedItems.map(x => `${x.item?.code} - ${x.item?.name}` || "")}
      />

      <FilterTooltipSection
        label="Business Team (Basin Hierarchy)"
        values={state.pickerData.businessTeams.selectedItems.map(x => `${x.item?.code} - ${x.item?.name}` || "")}
      />

      <FilterTooltipSection
        label="Business Function (Functions)"
        values={state.pickerData.businessFunctions.selectedItems.map(x => `${x.item?.code} - ${x.item?.name}` || "")}
      />

      <FilterTooltipSection
        label="Mobile Site"
        values={state.mobileSites.map(x => x.rigName)}
      />

      {/* Secondary Filters */}

      <FilterTooltipSection
        label="Audit Group"
        values={state.pickerData.auditGroups.selectedItems.map(x => x.item?.name || x.text || x.key.toString())}
      />

      <FilterTooltipSection
        label="Owner Group"
        values={state.pickerData.ownerGroups.selectedItems.map(x => x.item?.name || x.text || x.key.toString())}
      />

      <FilterTooltipSection
        label="Lead Auditor"
        values={state.pickerData.leadAuditors.selectedItems.map(x => userToString(x.item))}
      />

      <FilterTooltipSection
        label="Auditor"
        values={state.pickerData.auditors.selectedItems.map(x => userToString(x.item))}
      />

      <FilterTooltipSection
        label="Audit Name"
        values={[state.auditName]}
      />

      <FilterTooltipSection
        label="Audit Identifier"
        values={[state.auditIdentifier]}
      />

      <FilterTooltipSection
        label="Audit Plan Year"
        values={state.auditPlanYear.map(x => x.toString())}
      />

      <FilterTooltipDateRangeSection
        label="Start Date"
        values={[state.startDateStart, state.startDateEnd]}
      />

      <FilterTooltipDateRangeSection
        label="End Date"
        values={[state.endDateStart, state.endDateEnd]}
      />

      <FilterTooltipDateRangeSection
        label="Closure Date"
        values={[state.closureDateStart, state.closureDateEnd]}
      />

      <FilterTooltipSection
        label="Compliance Result @ Completion"
        values={state.complianceAtCompletion.map(x => x.toString())}
      />

      <FilterTooltipSection
        label="Compliance Result @ Closure"
        values={state.complianceAtClosure.map(x => x.toString())}
      />

      <FilterTooltipSection
        label="Action Item Status (All closed or not)"
        values={[state.allActionsClosed?.toString() || ""]}
      />
    </div>
  );
};

export default FiltersTooltip;