import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Hint from "shared/components/common/hint/Hint";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import IPMProjectPicker from "shared/components/controls/pickers/IPMProjectPicker";
import MobileSitePicker from "shared/components/controls/pickers/MobileSitePicker";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { IAddParentItem, addParentDimensions, setAddParentDimensionModal } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";
import { IPerspectiveXAxes } from "types/auditMasterDataTypes";
import { IIPMProject, IMobileSite, MetaDataTypes } from "types/masterDataTypes";

const AddParentDimensionModal: React.FC = () => {
  const [selectedProjects, setSelectedProjects] = useState<IIPMProject[]>([]);
  const [selectedMobileSites, setSelectedMobileSites] = useState<IMobileSite[]>([]);
  const addParentDimensionModal = useAppSelector(store => store.facilityAttributes.addParentDimensionModal);
  const isOpen = addParentDimensionModal.isOpen;

  const selectedPerspectiveXAxes = useAppSelector(store => store.auditPlanning.appliedFilters?.perspectiveXAxes);
  const selectedPerspectiveXAxis = selectedPerspectiveXAxes?.[0];

  const dispatch = useDispatch();

  // Used to determine if the submit button is active.
  const isAnythingSelected = selectedProjects.length > 0
    || selectedMobileSites.length > 0;

  useEffect(() => {
    setSelectedProjects([]);
    setSelectedMobileSites([]);
  }, [isOpen]);

  const closeModal = () => dispatch(setAddParentDimensionModal({ isOpen: false, }));

  const onSubmit = () => {
    const parentItems = selectedProjects
      .map((x): IAddParentItem => ({
        id: x.id,
        type: MetaDataTypes.IPMProject,
        subType: x.type,
        text: `${x.code} - ${x.name}`,
      })).concat(
        selectedMobileSites
          .map((x): IAddParentItem => ({
            id: x.id,
            type: MetaDataTypes.MobileSite,
            subType: undefined,
            text: `${x.rigKey} - ${x.rigName}`,
          }))
      );

    dispatch(addParentDimensions({
      parentItems,
      parentRowMasterDataId: addParentDimensionModal.locationId,
      parentRowMasterDataType: addParentDimensionModal.locationType,
      subGeoUnitId: addParentDimensionModal.subGeoUnitId,
    }));
  };

  return (
    <Modal
      isOpen={addParentDimensionModal.isOpen}
      showCloseButton={true}
      onCloseButtonClicked={closeModal}
      header="Add Parent Dimension"
      buttons={[{
        key: "Cancel",
        text: "Cancel",
        className: "secondary",
        onClick: closeModal,
      }, {
        key: "Submit",
        text: "Submit",
        className: "primary",
        disabled: !isAnythingSelected,
        onClick: onSubmit,
      },]}
    >
      <FlexCol>
        <span className="modal-message">
          Select items to be added under <b>{addParentDimensionModal.locationName}</b>:
        </span>

        {isTypePlannable(selectedPerspectiveXAxis, MetaDataTypes.IPMProject, "IPMAsset") &&
          <LabeledControl
            label="IPM Assets"
          >
            <IPMProjectPicker
              onSelectionChange={assets => setSelectedProjects(selectedProjects.filter(x => x.type !== "IPMAsset").concat(assets))}
              selectedItems={selectedProjects.filter(x => x.type === "IPMAsset")}
              subGeoUnitIds={[addParentDimensionModal.locationId]}
              ipmProjectType="IPMAsset"
              selectMode="Multiple"
            />
          </LabeledControl>
        }

        {isTypePlannable(selectedPerspectiveXAxis, MetaDataTypes.IPMProject, "IPMProject") &&
          <LabeledControl
            label="IPM Projects"
          >
            <IPMProjectPicker
              onSelectionChange={projects => setSelectedProjects(selectedProjects.filter(x => x.type !== "IPMProject").concat(projects))}
              selectedItems={selectedProjects.filter(x => x.type === "IPMProject")}
              subGeoUnitIds={[addParentDimensionModal.locationId]}
              ipmProjectType="IPMProject"
              selectMode="Multiple"
            />
          </LabeledControl>
        }

        {isTypePlannable(selectedPerspectiveXAxis, MetaDataTypes.MobileSite) &&
          <LabeledControl
            label="Mobile Sites"
          >
            <MobileSitePicker
              onSelectionChange={setSelectedMobileSites}
              selectedItems={selectedMobileSites}
              selectionMode="Multiple"
              subGeoUnitIds={[addParentDimensionModal.locationId]}
              includeUnassignedItems
            />
          </LabeledControl>
        }

        <Hint>
          Once you click the SUBMIT button, the newly added parent dimensions will be sorted under {addParentDimensionModal.locationName} as per its alphabetical order.
        </Hint>
      </FlexCol>
    </Modal>
  );
};

export default AddParentDimensionModal;

function isTypePlannable(selectedXAxis: IPerspectiveXAxes | undefined,
  type: string,
  subType?: string) {
  return selectedXAxis?.plannableMasterDataTypes
    .some(x => x.masterDataType === type
      && (x.masterDataSubType === subType
        || (!subType && !x.masterDataSubType)));
}