import { IPlanningGridCellClickArgs, IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import EditPlanIcon from "shared/media/dls/edit-1.svg";
import FinalApprovedPlanIcon from "shared/media/icons/audit-planning/final-approved-plan.svg";
import FinalApprovedPlanBasicComplianceIcon from "shared/media/icons/audit-planning/final-plan-approved-plan-basic-compliance.svg";
import FinalApprovedPlanFullComplianceIcon from "shared/media/icons/audit-planning/final-plan-approved-plan-full-compliance.svg";
import FinalApprovedPlanHighNonComplianceIcon from "shared/media/icons/audit-planning/final-plan-approved-plan-high-non-compliance.svg";
import FinalApprovedPlanMissingIcon from "shared/media/icons/audit-planning/final-plan-approved-plan-missing.svg";
import FinalApprovedPlanNonComplianceIcon from "shared/media/icons/audit-planning/final-plan-approved-plan-non-compliance.svg";
import RecManualPlanIcon from "shared/media/icons/audit-planning/recommended-manual-plan.svg";
import RecTimeTriggerIcon from "shared/media/icons/audit-planning/recommended-time-trigger.svg";
import RemovedPlanIcon from "shared/media/icons/audit-planning/removed-plan.svg";
import { AuditPlanApprovalStatuses, ClmSelfAssessmentTypes, ComplianceResults, IAuditPlanScore, IAuditPlanView, IPlanRequirementDimension } from "types/auditPlanningTypes";
import "./ApprovalGridCell.scoped.scss";
import GridCellTooltip from "./tooltip/GridCellTooltip";

const showPlanStatusInCorner = false;

interface IApprovalGridCellProps {
  score?: IAuditPlanScore,
  plan?: IAuditPlanView,
  planningItem: IPlanningItem,
  parentPlanningItem?: IPlanningItem,
  requirement: IPlanRequirementDimension,
  isPlannable: boolean,
  hideScoreWithoutPlan: boolean,
  onCellClick(eventArgs: IPlanningGridCellClickArgs): void,
  isEnabled: boolean;
}

const ApprovalGridCell: React.FC<IApprovalGridCellProps> = ({
  score,
  plan,
  planningItem,
  parentPlanningItem,
  requirement,
  isPlannable,
  hideScoreWithoutPlan,
  onCellClick,
  isEnabled,
}) => {
  let planTooltip: string = "";
  let planIcon: string = EditPlanIcon;
  let planAlt: string = "";
  let isPlanIconFiltered = true;
  let isNA = (!plan && !score && isPlannable) || (hideScoreWithoutPlan && score && !plan);
  let divClassName: string[] = ["cell-div "];
  let clmSelfText = getCLMSelfAssessmentAcronym(plan?.userSelectedCLMSelfAssessmentType
    ?? plan?.requiredCLMSelfAssessmentType);

  let editOrRemoveModalButton = undefined;

  if (!!plan?.deleted) {
    planTooltip = "Removed";
    planIcon = RemovedPlanIcon;
    planAlt = "X";
  } else if (plan?.approvalStatus === AuditPlanApprovalStatuses.FinalApproval) {
    planTooltip = "Final Approval";
    planAlt = "F";
    isPlanIconFiltered = false;

    if (score === undefined) {
      planIcon = FinalApprovedPlanIcon;
    } else if (score.complianceScore === undefined) {
      planIcon = FinalApprovedPlanMissingIcon;
    } else if (score.complianceScore === ComplianceResults.NonCompliance) {
      planIcon = FinalApprovedPlanNonComplianceIcon;
    } else if (score.complianceScore === ComplianceResults.HighNonCompliance) {
      planIcon = FinalApprovedPlanHighNonComplianceIcon;
    } else if (score.complianceScore === ComplianceResults.BasicCompliance) {
      planIcon = FinalApprovedPlanBasicComplianceIcon;
    } else if (score.complianceScore === ComplianceResults.FullCompliance
      || score.complianceScore === ComplianceResults.HighCompliance) {
      planIcon = FinalApprovedPlanFullComplianceIcon;
    }

  } else if (plan?.source === "Manual") {
    planTooltip = "Recommended - Manually Added";
    planIcon = RecManualPlanIcon;
    planAlt = "M";
  } else if (plan?.source === "Time Trigger") {
    planTooltip = "Recommended - Time Trigger";
    planIcon = RecTimeTriggerIcon;
    planAlt = "T";
  }

  if (!isEnabled) {
    divClassName.push("not-allowed");
  } else {
    divClassName.push("editable");
    editOrRemoveModalButton = <img
      src={EditPlanIcon}
      alt="Edit"
      className="icon-medium edit-icon plan-icon-filtered"
    />;
  }

  if (!isNA) {
    if (score) {
      divClassName.push(score.complianceScore ?? "Missing");
      divClassName.push("required");
    }
    else {
      divClassName.push(plan ? "no-score-with-plan" : "");
    }
  }
  else {
    divClassName.push("not-required");
  }

  if (isPlannable) {
    divClassName.push("plannable");
  }

  if (plan) {
    divClassName.push("has-plan");
  }

  const shouldShowTooltip = plan || score;

  let statusCode: string = "";
  if (plan?.approvalStatus === AuditPlanApprovalStatuses.FinalApproval) {
    statusCode = "A";
  } else if (plan?.approvalStatus === AuditPlanApprovalStatuses.Recommended) {
    statusCode = "R";
  }

  return (
    <>
      {isPlannable && (
        <TooltipElement
          isDisabled={!shouldShowTooltip}
          tooltipContent={
            <GridCellTooltip
              score={score}
              plan={plan}
              statusText={planTooltip}
            />
          }
        >
          <div
            className={divClassName.join(" ")}
            onClick={isEnabled
              ? () => onCellClick({
                parentRow: parentPlanningItem,
                row: planningItem,
                requirement,
                score,
                plan,
              })
              : undefined
            }
          >
            {editOrRemoveModalButton}

            {!!plan && (
              <img
                src={planIcon}
                alt={planAlt}
                className={`icon-medium plan plan-icon${isPlanIconFiltered ? "-filtered" : ""}`}
              />
            )}

            {!!plan?.weekOfYear && (
              <label
                className="week-number"
              >
                {plan.weekOfYear}
              </label>
            )}

            {!!plan?.leadAuditorName && (
              <label
                className="lead-auditor"
              >
                {getInitials(plan.leadAuditorName)}
              </label>
            )}

            {!!clmSelfText && (
              <label
                className="clm-self-type"
              >
                {clmSelfText}
              </label>
            )}

            {showPlanStatusInCorner
              && !!statusCode && (
                <label
                  className="status"
                >
                  {statusCode}
                </label>
              )}

            {isNA && (
              "N/A"
            )}
          </div>
        </TooltipElement>
      )}
    </>
  );
};

export default ApprovalGridCell;

function getInitials(name: string): string {
  const nameChunks = name.trim().split(' ');
  const firstInitial = nameChunks[0][0];
  const lastInitial = nameChunks.length > 1
    ? nameChunks[nameChunks.length - 1][0]
    : undefined;

  return firstInitial + (lastInitial ?? "");
}

function getCLMSelfAssessmentAcronym(selfAssessmentType: string | undefined) {
  return selfAssessmentType === ClmSelfAssessmentTypes.Partial
    ? "PA"
    : "";
}

