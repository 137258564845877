import React from "react";
import { PlanningPeriodsType } from "types/adminPlanningAdministration";
import AdminPlanPeriodPage from "../AdminPlanPeriodPage";
import PlanAutoDateCard from "./PlanAutoDateCard";

const PlanApprovalAdminPage: React.FC = () => {
  return (
    <AdminPlanPeriodPage
      pageTitle="Audit Planning Administration"
      cardTitle="Plan Approval Update Period"
      periodType={PlanningPeriodsType.planApproval}
      extraCards={[<PlanAutoDateCard hasEditPermission={false} key={'planApproval'} />]}
      showBreadcrumbs={true}
    />
  )
};

export default PlanApprovalAdminPage;