import React from 'react';
import PropTypes from 'prop-types';
import "./ActionItemCount.scoped.scss";


const ActionItemCount = ({ complianceScore = 0 , numActionItems = 0 }) => {
  const isFullyCompliant = complianceScore === 1;
  const hasActionItems = numActionItems > 0;

  return (
    <span
      className={
        hasActionItems || isFullyCompliant
          ? "greater-than-zero-count-circle"
          : "zero-count-circle"
      }
    >
      {numActionItems}
    </span>
  );
};

ActionItemCount.propTypes = {
  complianceScore: PropTypes.number,
  numActionItems: PropTypes.number,
};

export default ActionItemCount;