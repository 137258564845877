import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IAuditFindingState {
  isAssignFindingToQuestionsOpen: boolean,
}

const initialState: IAuditFindingState = {
  isAssignFindingToQuestionsOpen: false,
};

export const auditFindingSlice = createSlice({
  name: "audit-finding",
  initialState,
  reducers: {
    setOpenAssignFindingToQuestions: (state, action: PayloadAction<boolean>) => {
      state.isAssignFindingToQuestionsOpen = action.payload;
    },
  }
});

export const {
  setOpenAssignFindingToQuestions,
} = auditFindingSlice.actions;