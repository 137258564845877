import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PerspectiveXAxesPicker from "shared/components/controls/pickers/PerspectiveXAxesPicker";
import { setCurrentFilters } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IDisableComponent } from "types/auditPlanningTypes";

const XAxisFilter: React.FC<IDisableComponent> = ({ disabled, }) => {
  const perspectives = useAppSelector(store => store.auditPlanning.currentFilters.perspectives);
  const perspectiveXAxes = useAppSelector(store => store.auditPlanning.currentFilters.perspectiveXAxes);
  const dispatch = useAppDispatch();

  return (
    <LabeledControl
      label="X-Axis: Topics by Audit Group x Owner Group"
      isRequired
      className="x-axis-filter"
    >
      <PerspectiveXAxesPicker
        enableRequiredErrorDisplay
        onApply={items =>
          dispatch(setCurrentFilters({
            perspectiveXAxes: items,
          }))
        }
        selectedItems={perspectiveXAxes}
        isDisabled={disabled}
        selectedPerspective={perspectives?.[0]}
      />
    </LabeledControl>
  );
}

export default XAxisFilter;