import { ActionItemStatuses, IActionItem } from "types/actionItemTypes";
import "./ActionItemButton.scoped.scss";

import React from "react";

interface IActionItemButtonProps {
  allActionItems: IActionItem[],
  auditQuestionId: number,
  questionNumber: number,
  type: "closed" | "validated",
  onClick(): void,
}

const ActionItemButton: React.FC<IActionItemButtonProps> = ({
  allActionItems,
  auditQuestionId,
  questionNumber,
  type,
  onClick,
}: IActionItemButtonProps) => {
  const thisQuestionsActionItems = allActionItems
    .filter(
      x => (x.type?.name === "Corrective"
        || x.type?.name === "Preventative")
        && x.links.some(y => y.linkId === auditQuestionId));

  const closedOrValidatedCount = thisQuestionsActionItems.filter(x =>
    (type === "closed"
      && x.status === ActionItemStatuses.Closed)
    || (
      type === "validated"
      && x.isValidated
    )
  ).length;

  let className = "no-action-items";

  if (type === "closed") {
    className = thisQuestionsActionItems.length === 0
      ? "no-action-items"
      : closedOrValidatedCount === thisQuestionsActionItems.length
        ? "all-closed"
        : "has-open";
  } else {
    className = thisQuestionsActionItems.length === 0
      ? "no-action-items"
      : closedOrValidatedCount === thisQuestionsActionItems.length
        ? "all-validated"
        : "has-unvalidated";
  }

  return (
    <button
      className={`action-item-link ${className}`}
      onClick={onClick}
    >
      {closedOrValidatedCount}/{thisQuestionsActionItems.length}
    </button>
  );
};

export default ActionItemButton;