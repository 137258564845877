import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFinding } from "store/audit/reducers/findingReducers";
import { IAuditQuestion } from "types/auditingTypes";

export interface IAuditRWPState {
  questionModal: {
    isOpen: boolean,
    questions: IAuditQuestion[],
  },
  viewFindingsModal: {
    isOpen: boolean,
    findings: IFinding[],
  },
}

const initialState: IAuditRWPState = {
  questionModal: {
    isOpen: false,
    questions: [],
  },
  viewFindingsModal: {
    isOpen: false,
    findings: [],
  },
};

export const auditRWPSlice = createSlice({
  name: "audit-rwp",
  initialState,
  reducers: {
    viewQuestions: (state, action: PayloadAction<IAuditQuestion[]>) => {
      state.questionModal.isOpen = true;
      state.questionModal.questions = action.payload;
    },
    hideQuestions: (state) => {
      state.questionModal.isOpen = false;
      state.questionModal.questions = [];
    },
    viewFindings: (state, action: PayloadAction<IFinding[]>) => {
      state.viewFindingsModal.isOpen = true;
      state.viewFindingsModal.findings = action.payload;
    },
    hideFindings: (state) => {
      state.viewFindingsModal.isOpen = false;
      state.viewFindingsModal.findings = [];
    },
  },
});

export const {
  viewQuestions,
  hideQuestions,
  viewFindings,
  hideFindings,
} = auditRWPSlice.actions;