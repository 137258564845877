import TemplatesApi from "api/auditing/templates/TemplatesApi";
import React, { useCallback } from "react";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { ITemplate } from "types/auditingTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";
import NonReduxPickerDropdown from "./non-redux-dropdown-picker/NonReduxDropdownPicker";
import NonReduxPickerList from "./non-redux-list-picker/NonReduxPickerList";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";
import { IPickerItem } from "shared/types/pickerTypes";


interface ITemplatePickerProps {
  /** Optional. Determines the picker's mode. Default = Picker. */
  renderMode?: PickerMode,
  /** Flag to determine if the selection is required or not. */
  isRequired?: boolean,
  /** Optional. Determines if only the top level items are selectable. Default = true. */
  isOnlyTopLevelSelectable?: boolean,
  /** Optional. When items are selected, the trees will be traversed and all items in the tree will be split into a list of templates
   * and a list of auditTopics and sent to this callback. */
  onApplySplit?: (appliedData: ITemplatePickerAppliedItemsSplit) => void,
  /** Optional Filters the list of available Templates by the provided AuditGroupId. */
  auditGroupIdFilter?: number,
}

interface ITemplatePickerAppliedItemsSplit {
  templates: ITemplate[],
  auditTopics: IAuditTopic[],
}

const TemplatePicker: React.FC<ITemplatePickerProps & ICommonPickerProps<ITemplate | IDetailedTemplate | IDetailedTemplateChildren>> = ({
  onApply,
  onApplySplit,
  selectedItems,
  allowMultiselect = false,
  onRenderPicker,
  isDisabled = false,
  renderMode = 'picker',
  isRequired,
  isItemDisabledMapper,
  isOnlyTopLevelSelectable = true,
  auditGroupIdFilter,
}) => {

  const renderItem = (item: ITemplate | IDetailedTemplate | IDetailedTemplateChildren) => item.name;

  const pickerFilterItems = <T,>(item: IPickerItem<T>, search: string) => {
    if (item?.item) {
      return ((item.item as unknown) as (ITemplate | IDetailedTemplate | IDetailedTemplateChildren)).name
        .toLowerCase()
        .includes(search.toLowerCase());
    }
    return false;
  }

  const searchFilterItems = (item: ITemplate | IDetailedTemplate | IDetailedTemplateChildren, search: string) => {
    let test = item.name
      .toLowerCase()
      .includes(search.toLowerCase());
    return test;
  }

  const loadItems = useCallback(async (searchTerm: string | undefined, abortSignal: AbortSignal) =>
    await TemplatesApi.getDetailedTemplates({
      auditTopics: undefined,
      ownerGroups: undefined,
      auditGroupIds: auditGroupIdFilter
        ? [auditGroupIdFilter]
        : undefined,
      templateIdFilter: undefined,
      includeDeleted: false,
      nameFilter: searchTerm,
    }, abortSignal),
    [auditGroupIdFilter]);

  const internalIsDisabledMapper = (item: ITemplate | IDetailedTemplate | IDetailedTemplateChildren,
    ancestorPath: (ITemplate | IDetailedTemplate | IDetailedTemplateChildren)[],
    isSelectedItem?: boolean) => {
    if (isItemDisabledMapper && isItemDisabledMapper(item, ancestorPath)) {
      return true;
    }

    if (isSelectedItem) {
      return false;
    }

    return isOnlyTopLevelSelectable
      ? ancestorPath.length > 0
      : false;
  };

  const onApplyInternal = (selectedItems: (ITemplate | IDetailedTemplate | IDetailedTemplateChildren)[]) => {
    // Send out the standard OnApply event.
    onApply(selectedItems);

    // If the developer specified an onApplySplit callback, parse through the stree to get topics and
    // templates from the trees of the selected items and return them as separate lists.
    if (onApplySplit) {
      onApplySplit(splitSelectedItems(selectedItems));
    }
  };

  if (renderMode === 'picker') {
    return (
      <NonReduxPicker<ITemplate | IDetailedTemplate | IDetailedTemplateChildren>
        itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApplyInternal}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title="Templates"
        allowMultiSelect={allowMultiselect}
        displayMode="tree"
        childrenMapper={item => 'children' in item ? item.children : []}
        renderListItem={renderItem}
        searchOptions={{
          filterItem: searchFilterItems,
        }}
        onRenderPicker={onRenderPicker}
        isDisabled={isDisabled}
        isDisabledMapper={internalIsDisabledMapper}
      />
    );
  } else if (renderMode === 'dropdown') {
    return (
      <NonReduxPickerDropdown<ITemplate | IDetailedTemplate | IDetailedTemplateChildren>
        itemFormatter={renderItem}
        keyMapper={item => item.id}
        onLoadItems={loadItems}
        onSelectionChanged={onApplyInternal}
        selectedItems={selectedItems}
        placeholder="Select"
        selectMode={allowMultiselect ? "multiple" : "single"}
        isDisabled={isDisabled}
      />
    );
  } else if (renderMode === 'list') {
    return (
      <NonReduxPickerList
        selectedItems={selectedItems}
        renderListItem={item => item.name}
        onSelectionChanged={onApplyInternal}
        keyMapper={(item) => item.id}
        childMapper={(item) => (item as IDetailedTemplate).children || []}
        title="Templates"
        isRequired={isRequired}
        onLoadItems={loadItems}
        allowMultiSelect={allowMultiselect}
        filterItems={pickerFilterItems}
        tagsProps={[{
          label: "Selected Templates",
          renderItem: (item) => item?.item?.name,
          filterList: (item) => item.id !== 0,
        }]}
        isDisabledMapper={internalIsDisabledMapper}
      />
    );
  } else {
    return <>Render mode "{renderMode}" is not supported.</>;
  }
};

export default TemplatePicker;

function splitSelectedItems(items: (ITemplate | IDetailedTemplate | IDetailedTemplateChildren)[]): ITemplatePickerAppliedItemsSplit {
  // Pull all the templates and topics out of the template's tree.
  let templates: ITemplate[] = [];
  let topics: IAuditTopic[] = [];

  // Define recursive func that parses through children and adds them
  // to either list.
  const childParser = (children: IDetailedTemplateChildren[]) => {
    children.forEach(child => {
      if ('level' in child.masterDataObject) {
        topics.push(child.masterDataObject);
      } else {
        templates.push(child.masterDataObject);
      }

      childParser(child.children);
    });
  };

  // Parse each of the selected templates, adding them to the list
  // then parsing their child trees.
  items.forEach(template => {
    templates.push(template as ITemplate);

    if ('children' in template) {
      childParser(template.children);
    }
  });

  return {
    templates,
    auditTopics: topics,
  };
}	