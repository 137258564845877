import React from "react";
import { IPlanRequirementDimension } from "types/auditPlanningTypes";
import { IPlanningGridOptions, IPlanningItem } from "../PlanningGrid";
import "../PlanningGrid.scoped.scss";
import "./GridRow.scoped.scss";

interface IGridRowProps {
  row: IPlanningItem,
  requirements: IPlanRequirementDimension[],
  parentRow?: IPlanningItem,
  depth: number,
  options: IPlanningGridOptions,
}

const GridRow: React.FC<IGridRowProps> = ({
  row,
  parentRow,
  depth,
  requirements,
  options,
}) => {
  let computedRowData: any = undefined;

  if (options.computeRowData) {
    computedRowData = options.computeRowData(row, parentRow);
  }

  const leftCommandCellRenderData = options.leftCommandCellOptions?.isVisible
    ? options.leftCommandCellOptions.renderCell({
      row,
      parentRow,
      depth,
    }, computedRowData) : undefined;

  const rightCommandCellRenderData = options.rightCommandCellOptions?.isVisible
    ? options.rightCommandCellOptions.renderCell({
      row,
      parentRow,
      depth,
    }, computedRowData) : undefined;

  return (
    <>
      <tr className={row.id.toString()}>
        {leftCommandCellRenderData && (
          <td
            {...leftCommandCellRenderData.cellProps}
            className={`command-cell left-command-cell ${options.leftCommandCellOptions?.isSticky
              ? "left-sticky-cell"
              : ""}`
            }
          >
            {leftCommandCellRenderData.node}
          </td>
        )}

        {requirements.map(req => {
          const renderData = options.renderDataCell({
            row,
            parentRow,
            requirement: req,
            depth,
          }, computedRowData);

          return (
            <td
              {...renderData.cellProps}
              key={`${row.id}-${req.type}-${req.id}`}
              className="data-cell"
            >
              {renderData.node}
            </td>
          )
        })}

        {rightCommandCellRenderData && (
          <td
            {...rightCommandCellRenderData.cellProps}
            className={`command-cell right-command-cell ${options.rightCommandCellOptions?.isSticky
              ? "right-sticky-cell"
              : ""}`}
          >
            {rightCommandCellRenderData.node}
          </td>
        )}
      </tr>

      {row.isExpanded
        && row.children.map(child => (
          <GridRow
            options={options}
            requirements={requirements}
            row={child}
            parentRow={row}
            depth={depth + 1}
            key={`${depth}-${child.type}-${child.id}`}
          />
        ))}
    </>
  );
}

export default GridRow;