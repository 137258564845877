import { authGetJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { ClmSelfAssessmentTypes, ICLMPartialRequirementItemDto } from "types/auditPlanningTypes";
import { formatCLMPartialRequirementItemDto } from "./clmPartialPlanFormatters";

class ClmPartialPlanApi {
  /** Gets the CLM Partial plan's self-assessment requirement items (the opt-in / user-opt-in data). */
  public async getPartialPlanRequirementItems(planId: number,
    abortSignal?: AbortSignal): Promise<ICLMPartialRequirementItemDto[]> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/clmPartialPlans/${planId}/requirements`, abortSignal);
    await throwIfResponseError(response);

    const json = await response.json();

    return Array.isArray(json)
      ? json.map(formatCLMPartialRequirementItemDto)
        .sort((a, b) => {
          if (a.topicName < b.topicName) {
            return -1;
          } else if (a.topicName > b.topicName) {
            return 1;
          }

          return a.subTopicName < b.subTopicName ? -1 : 1;
        })
      : [];
  }

  /** Saves the CLM Partial plan's self-assessment requirement items (the opt-in / user-opt-in data). */
  public async savePartialPlanRequirementItems(planId: number,
    partialRequirements: ICLMPartialRequirementItemDto[],
    abortSignal?: AbortSignal) {
    const response = await authPutJson(`${config.endpoints.baseUrl}/clmPartialPlans/${planId}/requirements`,
      partialRequirements,
      abortSignal);
    await throwIfResponseError(response);
  }

  /** Updates the CLM Partial plan's self-assessment type. */
  public async setPartialPlanSelfAssessmentType(planId: number,
    selfAssessmentType: ClmSelfAssessmentTypes,
    abortSignal?: AbortSignal) {
    const response = await authPutJson(`${config.endpoints.baseUrl}/clmPartialPlans/${planId}/selfAssessmentType`,
      selfAssessmentType.toString(),
      abortSignal);
    await throwIfResponseError(response);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new ClmPartialPlanApi();
