import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IToastState {
  toasts: IToast[],
}

export interface IToast {
  id: string,
  message: string,
  style: ToastStyle,
}

export enum ToastStyle {
  Info = "info",
  Success = "success",
  Error = "error",
  Warning = "warning",
}

const initialState: IToastState = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    // These three actions must be declared so that they can be intercepted by the
    // saga. The saga will call addToastToState.
    showErrorToast: (_, __: PayloadAction<string>) => { },
    showInfoToast: (_, __: PayloadAction<string>) => { },
    showSuccessToast: (_, __: PayloadAction<string>) => { },
    showWarningToast: (_, __: PayloadAction<string>) => { },
    addToastToState: (state, action: PayloadAction<IToast>) => {
      state.toasts.push(action.payload);
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts
        .filter(x => x.id !== action.payload);
    },
  }
});

export const {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
  showWarningToast,
  addToastToState,
  removeToast,
} = toastSlice.actions;