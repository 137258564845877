import React from "react";
import PickerList from "shared/components/controls/picker/list/PickerList";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IPickerItem } from "shared/types/pickerTypes";
import { addRecursively, toPickerItem } from "shared/utilities/hierarchyUtilities";
import { ICreateAuditPlanContextInfo } from "store/audit-plan-results/AuditPlanResultsSlice";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";

interface IPlanTemplateHierarchyInfoDisplayProps {
  isFromAuditTemplate: boolean,
  planContextInfo: ICreateAuditPlanContextInfo
}

const PlanAttributeInfoDisplay: React.FC<IPlanTemplateHierarchyInfoDisplayProps> = ({
  isFromAuditTemplate,
  planContextInfo
}: IPlanTemplateHierarchyInfoDisplayProps) => {

  const availableTemplates = planContextInfo.availableTemplates;
  const selectedAuditTemplates = planContextInfo.selectedDetailedTemplateItems;

  // Related to extra metadatas
  const extraBusinessViews = planContextInfo.planTemplateHierarchyInfo?.extraBusinessViews || [];
  const extraBusinessFunctions = planContextInfo.planTemplateHierarchyInfo?.extraBusinessFunctions || [];
  const extraSubTopics = planContextInfo.planTemplateHierarchyInfo?.extraSubTopics || [];

  let allTemplates: (string | number)[] = [];

  availableTemplates.forEach(x => {
    addRecursively(x, allTemplates);
  });

  return (
    <>
      {isFromAuditTemplate && <>
        <hr className="divider" />
        <FlexCol className="template-picker">
          <span>
            <b>Included Templates and Topics/Subtopics</b>
          </span>
          <PickerList<IDetailedTemplate | IDetailedTemplateChildren>
            items={availableTemplates.map(x => toPickerItem(allTemplates, x, true, availableTemplates))}
            renderItem={(item) => item.name}
            renderTotal={(item) => item.total}
            showOnlySelectedItems={true}
            hiddenSelectOption={true}
            noItemsMessage={"No suggestions available."}
            selectedItems={selectedAuditTemplates.map(x => toPickerItem(allTemplates, x))}
            displayMode={"tree"}
            allowMultiSelect={true}
            onItemSelected={() => { return; }}
            notShowPlusMinusSign={true}
            onItemDeselected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => { return; }}
            isDisabledMapper={(item: (IDetailedTemplate | IDetailedTemplateChildren), ancestorPath: (IDetailedTemplate | IDetailedTemplateChildren)[]) => {
              return true;
            }}
          />
        </FlexCol>
      </>}
      <hr className="divider" />
      <div className="extra-metadata-title">
        Extra Metadata
      </div>
      <FlexRow >
        <FlexCol className="no-gap">
          <b>Business View</b>
          {
            extraBusinessViews.length > 0
              ? <>
                {extraBusinessViews.map(i =>
                  <span key={i.name}>{i.name}</span>
                )}
              </>
              : <span>-</span>
          }

        </FlexCol>

        <FlexCol className="no-gap">
          <b>Business Function</b>
          {
            extraBusinessFunctions.length > 0
              ? <>
                {extraBusinessFunctions.map(i =>
                  <span key={i.name}>{i.name}</span>
                )}
              </>
              : <span>-</span>

          }
        </FlexCol>

        {!isFromAuditTemplate &&
          <FlexCol className="no-gap">
            <b>Topics/Subtopics</b>
            {
              extraSubTopics.length > 0
                ? <>
                  {extraSubTopics.map(i =>
                    <span key={i.name}>{i.name}</span>
                  )}
                </>
                : <span>-</span>
            }
          </FlexCol>}
      </FlexRow>
    </>
  );
};

export default PlanAttributeInfoDisplay;
