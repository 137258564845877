import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import pickerHandlers from "shared/store/picker/pickerReducerHandlers";
import { IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { AuditStatuses, IAuditee, IAuditor } from "types/auditingTypes";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView } from "types/masterDataTypes";

export enum AuditSummaryPickerKeys {
  auditors = "auditors",
  auditees = "auditees",
}

export interface IAuditSummaryState {
  pickerData: {
    auditors: IPickerState<IAzureADUser>,
    auditees: IPickerState<IAuditee>,
  },
  selectedAuditQuestionIds: number[],
  focusedGuidance?: {
    questionNumber: number,
    guidance: string,
  },
  selectedAuditorTab: 'Auditors' | 'Auditees',
  assignAuditorByAssociationsModal: IAssignAuditorByAssociationsModal,
}

export interface IAssignAuditorByAssociationsModal {
  isOpen: boolean,
  auditor?: IAuditor,
  selectedAuditTopics: IAuditTopic[],
  selectedBusinessTeams: IBusinessTeam[],
  selectedBusinessFunctions: IBusinessFunction[],
  selectedBusinessViews: IBusinessView[],
  selectedOtherAssociations: IOtherAssocItem[],
}

export interface IOtherAssocItem {
  type: "Country" | "Global",
  text: string,
  id: number,
}

export interface IStatusConfirmModalData {
  /** Whether the confirm modal is open or not. */
  isOpen: boolean,
  /** The message to prompt the user with. */
  message: string,
  /** The status to update the audit to if the user confirms. */
  nextStatus: AuditStatuses,
}

const initialState: IAuditSummaryState = {
  pickerData: {
    auditors: {
      key: AuditSummaryPickerKeys.auditors,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    auditees: {
      key: AuditSummaryPickerKeys.auditees,
      isOpen: false,
      items: [],
      selectedItems: [],
    }
  },
  selectedAuditQuestionIds: [],
  focusedGuidance: undefined,
  selectedAuditorTab: 'Auditors',
  assignAuditorByAssociationsModal: {
    isOpen: false,
    auditor: undefined,
    selectedAuditTopics: [],
    selectedBusinessTeams: [],
    selectedBusinessFunctions: [],
    selectedBusinessViews: [],
    selectedOtherAssociations: [],
  },
};

export const auditSummarySlice = createSlice({
  name: "audit-summary",
  initialState,
  reducers: {
    ...pickerHandlers,

    toggleQuestionSelection: (state, action: PayloadAction<{
      auditQuestionId: number,
      isSelected: boolean,
    } | {
      auditQuestionId: number,
      isSelected: boolean,
    }[]>) => {
      let items: {
        auditQuestionId: number,
        isSelected: boolean,
      }[] = Array.isArray(action.payload)
          ? action.payload
          : [action.payload];

      items.forEach(item => {
        const {
          auditQuestionId,
          isSelected,
        } = item;

        const ix = state.selectedAuditQuestionIds.indexOf(auditQuestionId);

        if (isSelected
          && ix === -1) {
          state.selectedAuditQuestionIds.push(auditQuestionId);
        } else if (!isSelected
          && ix > -1) {
          state.selectedAuditQuestionIds = state
            .selectedAuditQuestionIds
            .filter(x => x !== auditQuestionId);
        }
      });
    },

    clearSelectedQuestions: (state) => {
      state.selectedAuditQuestionIds = [];
    },

    setSelectedAuditorTab: (state, action: PayloadAction<"Auditors" | "Auditees">) => {
      state.selectedAuditorTab = action.payload;
    },

    /** Sets properties of the Assign Auditor by Associations modal. */
    setAssignAuditorByAssociationsModal: (state, action: PayloadAction<Partial<IAssignAuditorByAssociationsModal>>) => {
      if (!state.assignAuditorByAssociationsModal.isOpen
        && action.payload.isOpen) {
        // The modal was opened. Clear the old data.
        Object.assign(state.assignAuditorByAssociationsModal, initialState.assignAuditorByAssociationsModal);
      } else if (state.assignAuditorByAssociationsModal.isOpen
        && action.payload.isOpen === false) {
        // Use canceled/closed the modal.
        Object.assign(state.assignAuditorByAssociationsModal, initialState.assignAuditorByAssociationsModal);
        return;
      }

      // Copy in whatever data the dev wants to set.
      Object.assign(state.assignAuditorByAssociationsModal, action.payload);
    },

    /** Assigns questions to the auditor opened in the Assign Auditor by Associations modal based on the selections therein. */
    assignAuditorByAssociations: state => {
      // Handled by assignAuditorByAssociationsAsync saga.
    },
  },
});

export const {
  toggleQuestionSelection,
  clearSelectedQuestions,
  setSelectedAuditorTab,
  setAssignAuditorByAssociationsModal,
  assignAuditorByAssociations,

  // Picker handlers
  openPicker,
  closePicker,
  loadPickerItems,
  setPickerError,
  setPickerItems,
  setSelectedPickerItems,
  loadSuggestedPickerItems,
  setSuggestedPickerItems,
} = auditSummarySlice.actions;