import React from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import Button from "shared/components/controls/buttons/button/Button";
import exportIcon from "shared/media/dls/export.svg";
import { generateGridExportFile } from "store/grid/GridSlice";
import { useAppDispatch, useAppSelector } from "store/store";

interface IDataGridExportButtonProps {
  /** The id of the grid to export when clicked. */
  gridId: string,
}

/** Integrates and handles all export to xlsx functions for a data grid.
 * Simply import this and put it on the page.
 */
const DataGridExportButton: React.FC<IDataGridExportButtonProps> = ({
  gridId,
}: IDataGridExportButtonProps) => {
  const generateExportFileOp = useAppSelector(store => store.grid.generateExportFileOp);
  const dispatch = useAppDispatch();

  return (
    <Button
      buttonType="secondary"
      img={exportIcon}
      imgPlacement="right"
      onClick={() => dispatch(generateGridExportFile({
        gridId,
      }))}
      isDisabled={!!generateExportFileOp}
      tooltip={!!generateExportFileOp
        ? "Please wait. An export is in progress..."
        : undefined
      }
    >
      {generateExportFileOp
        ? (
          <Spinner
            className="small-spinner"
          />
        ) : "Export"
      }
    </Button>
  );
};

export default DataGridExportButton;