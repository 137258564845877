import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { userToString } from "shared/utilities/userUtilities";
import { closePicker, loadPickerItems, openPicker, setSelectedPickerItems } from "store/search/actions/SearchActionsSlice";
import { useAppSelector } from "store/store";

const AssignedTo: React.FC = () => {
  const pickerState = useAppSelector(store => store.searchActions.pickerData.assignedTo);
  
  return (
    <LabeledControl
      label="Assigned To"
    >
      <Picker<IAzureADUser>
        title="Assigned To"
        renderListItem={(item) => userToString(item)}
        renderSelectedItem={(item) => userToString(item)}
        pickerState={pickerState}
        preserveItems={true}
        searchOptions={{
          asyncMinChars: 1,
          behavior: "async",
        }}
        openAction={openPicker}
        closeAction={closePicker}
        loadAction={loadPickerItems}
        setSelectedItemsAction={setSelectedPickerItems}
        preserveSuggestedItems={true}
        allowMultiSelect={true}
        noItemsMessage={"Please begin typing a search term to execute a search."}
        itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
      />
    </LabeledControl>
  );
};

export default AssignedTo;