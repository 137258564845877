import { Action } from "@reduxjs/toolkit";
import { history } from "App";
import AuditsApi from "api/auditing/AuditsApi";
import FindingApi from "api/findings/FindingApi";
import MasterDataApi from "api/masterdata/MasterDataApi";
import { isResponseMissingRequiredFields } from "components/audits/execution/question/answer-card/AnswerCard";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import { cloneDeep, isEqual } from "lodash";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IPickerItem } from "shared/types/pickerTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { onSetFinding, setQuestion, unlinkFindingInState } from "store/audit/AuditSlice";
import { FindingLinkType, IFinding } from "store/audit/reducers/findingReducers";
import { RootState } from "store/store";
import { IAnswer, ICausalFactor } from "types/auditMasterDataTypes";
import { IAuditQuestion } from "types/auditingTypes";
import { IIdNameObject } from "types/commonTypes";
import { AfterSaveActions, AuditExecutionPickerKeys, deleteWorkspaceResponse, finishSaveWorkspace, loadPickerItems, openFilters, saveWorkspace, setCurrentWorkspace, setPickerError, setPickerItems, setSelectedPickerItems, traverseQueue } from "./AuditExecutionSlice";

export default function* auditExecutionSagas() {
  yield all([
    loadPickerItemsAsync(),
    setSelectedPickerItemsAsync(),
    saveWorkspaceAsync(),
    deleteWorkspaceResponseAsync(),
  ]);
}

function* loadPickerItemsAsync() {
  yield takeLatest(loadPickerItems, function* (action: Action) {
    if (!loadPickerItems.match(action)) {
      return;
    }

    const pickerKey = action.payload.pickerKey;

    try {
      if (pickerKey === AuditExecutionPickerKeys.causalFactors) {
        const causalFactors: ICausalFactor[] = yield call(MasterDataApi.getCausalFactors);

        yield put(setPickerItems({
          pickerKey: AuditExecutionPickerKeys.causalFactors,
          items: causalFactors.map((x): IPickerItem<IIdNameObject> => ({
            key: x.id,
            disabled: false,
            item: {
              id: x.id,
              name: x.name,
            },
          })),
        }));

        yield put(setPickerError({
          pickerKey,
          errorMessage: "",
          stopLoading: true,
        }));
      } else {
        throw new Error(`Picker '${pickerKey}' has no associated saga for loading items!`);
      }
    } catch (err) {
      yield put(setPickerError({
        pickerKey: pickerKey,
        errorMessage: getResponseErrorMessage(err),
        stopLoading: true,
      }));
    }
  });
}

function* deleteWorkspaceResponseAsync() {
  yield takeEvery(deleteWorkspaceResponse, function* (action: Action) {
    if (!deleteWorkspaceResponse.match(action)) {
      return;
    }

    const workspace = action.payload.workspace;
    const findingToDelete: IFinding | undefined = action.payload.findingToDelete;

    if (!action.payload.auditId) {
      yield showErrorToast("Unable to save changes. No AuditId provided!");
      return;
    }

    if (!action.payload.auditQuestionId) {
      yield showErrorToast("Unable to save changes. No AuditQuestionId provided!");
      return;
    }

    if (!action.payload.workspace) {
      yield showErrorToast("Unable to save changes. Missing required properties.");
      return;
    }

    try {
      const updatedQuestion: IAuditQuestion = yield call(AuditsApi.deleteQuestionResponse,
        action.payload.auditId,
        action.payload.auditQuestionId,
      );

      if (findingToDelete?.id !== undefined) {
        const link = findingToDelete
          .links
          .find(x => x.linkId === action.payload.auditQuestionId
            && x.linkType === FindingLinkType.AuditQuestion);

        if (link) {
          yield call(FindingApi.unlinkFinding,
            findingToDelete.id,
            [link.id]);

          yield put(unlinkFindingInState({
            findingId: findingToDelete.id,
            linkId: action.payload.auditQuestionId,
            linkType: FindingLinkType.AuditQuestion,
          }));
        }
      }

      yield put(finishSaveWorkspace({
        isWorking: false,
        data: {
          auditQuestionId: action.payload.auditQuestionId,
          current: {
            ...workspace.current,
            notes: undefined,
            answer: undefined,
            interviewees: [],
            selectedVerificationMethods: [],
            finding: undefined,
          },
          original: {
            ...workspace.original,
            notes: undefined,
            answer: undefined,
            interviewees: [],
            selectedVerificationMethods: [],
            finding: undefined,
          },
          isDirty: false,
        },
      }));

      yield put(showSuccessToast("Response cleared successfully."));

      // Update the main list of questions.
      yield put(setQuestion({
        question: updatedQuestion,
        alsoUpdateOriginal: true,
      }));
    } catch (err) {
      yield put(finishSaveWorkspace({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

function* saveWorkspaceAsync() {
  yield takeEvery(saveWorkspace, function* (action: Action) {
    if (!saveWorkspace.match(action)) {
      return;
    }

    const nextAction = action.payload.afterSaveAction;

    const workspace = action.payload.workspace;
    const original = workspace.original;
    const current = workspace.current;

    const auditId: number | undefined = yield select((store: RootState) => store.audit.audit?.id);
    const allAnswers: IAnswer[] = yield select((store: RootState) => store.audit.answers);

    if (isEqual(current, original)) {
      // Not dirty. Just do whatever comes next.
      yield doNextAction(nextAction, auditId || 0);
      return;
    }

    if (!auditId) {
      yield showErrorToast("Unable to save changes. No AuditId found!");
      return;
    }

    const answer = current?.answer;
    const notes = current?.notes;


    if (!workspace
      || !answer
      || !notes
      || isResponseMissingRequiredFields(current, allAnswers)) {
      yield showErrorToast("Unable to save changes. One or more required properties are missing!");
      return;
    }

    try {
      const updatedQuestion: IAuditQuestion = yield call(AuditsApi.submitQuestionResponse,
        auditId,
        workspace.auditQuestionId,
        {
          answer: answer,
          notes: notes,
          causalFactor: workspace.current?.causalFactor,
          interviewees: workspace.current?.interviewees || [],
          selectedVerificationMethods: workspace.current?.selectedVerificationMethods || [],
        });

      // Check to see if the original finding needs to be removed first.
      let wasFindingDeleted = false;

      if (original?.finding?.id
        && original.finding.id !== current.finding?.id) {
        const linkToRemove = original.finding.links.find(x => x.linkId === workspace.auditQuestionId
          && x.linkType === "AuditQuestion");

        if (linkToRemove) {
          wasFindingDeleted = yield call(FindingApi.unlinkFinding,
            original.finding.id,
            [linkToRemove.id]);

          let deletedFinding = cloneDeep(original.finding);

          if (wasFindingDeleted) {
            deletedFinding.deleted = true;
          }

          const deletedFindingLink = deletedFinding.links.find(x => x.linkId === workspace.auditQuestionId
            && x.linkType === "AuditQuestion");

          if (deletedFindingLink) {
            deletedFindingLink.deleted = true;
          }

          yield put(onSetFinding(deletedFinding));
        }
      }

      if (current?.finding !== undefined) {
        if (original?.finding === undefined) {
          // A new finding was added to the workspace or an existing one was updated. Create it on the server.
          const savedFinding: IFinding = yield call(FindingApi.createFinding, current.finding);
          yield put(onSetFinding(savedFinding));
        } else if (original?.finding?.justification !== current.finding.justification
          || original?.finding?.findingTypeId !== current.finding.findingTypeId) {
          // The finding's justification or finding type changed. Update it on the server.
          const savedFinding: IFinding = yield call(FindingApi.updateFinding, current.finding);
          yield put(onSetFinding(savedFinding));
        }
      }

      // Finish saving and update the workspace to show that it is now aligned.
      yield put(finishSaveWorkspace({
        isWorking: false,
        data: {
          auditQuestionId: workspace.auditQuestionId,
          current: workspace.current,
          original: cloneDeep(workspace.current),
          isDirty: false,
        },
      }));

      yield put(showSuccessToast("Answer saved successfully."));

      // Update the main list of questions.
      yield put(setQuestion({
        question: updatedQuestion,
        alsoUpdateOriginal: true,
      }));

      yield doNextAction(nextAction, auditId);
    } catch (err) {
      yield put(finishSaveWorkspace({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

function* doNextAction(nextAction: AfterSaveActions, auditId: number) {
  if (nextAction === AfterSaveActions.next) {
    yield put(traverseQueue(1));
  } else if (nextAction === AfterSaveActions.prev) {
    yield put(traverseQueue(-1));
  } else if (nextAction === AfterSaveActions.filters) {
    yield put(openFilters());
  } else if (nextAction === AfterSaveActions.exit) {
    if (auditId) {
      history.push(formatRoute(UrlRoutes.AuditSummary, { auditId: auditId.toString() }));
    } else {
      history.push(UrlRoutes.MyAudits.urlTemplate);
    }
  }
}

function* setSelectedPickerItemsAsync() {
  yield takeEvery(setSelectedPickerItems, function* (action) {
    if (!setSelectedPickerItems.match(action)) {
      return;
    }

    if (action.payload.pickerKey === AuditExecutionPickerKeys.auditees) {
      // User changed one or more auditees in the current question.
      yield put(setCurrentWorkspace({
        interviewees: action.payload.selectedItems.map(x => x.item),
      }));
    }
  });
}