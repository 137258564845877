import { CollectionView, Control, DataType, SortDescription, Tooltip, hidePopup, showPopup } from '@mescius/wijmo';
import { DataMap, FormatItemEventArgs, FlexGrid as wjFlexGrid } from '@mescius/wijmo.grid';
import { FilterType, Operator, FlexGridFilter as wjFlexGridFilter } from '@mescius/wijmo.grid.filter';
import { CollectionViewNavigator as wjCollectionViewNavigator } from "@mescius/wijmo.input";
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from "@mescius/wijmo.react.grid";
import { FlexGridFilter } from '@mescius/wijmo.react.grid.filter';
import { CollectionViewNavigator, ListBox } from "@mescius/wijmo.react.input";
import { acquireAccessToken } from "auth/authFetches";
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import Button from 'shared/components/controls/buttons/button/Button';
import hamburgerIcon from "shared/media/dls/hamburger.svg";
import { isNotUndefined } from 'shared/utilities/typeCheck';
import { clearGridFilters, refreshGrid } from 'store/grid/GridSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { IDataGridProps, IDataGridSortSettings, IQueryStringValues } from "../types/dataGridTypes";
import { RestCollectionViewOData } from "./RestCollectionViewOData";
import WijmoGridExporter from './wijmo-grid-exporter/WijmoGridExporter';
import "./WijmoGrid.scoped.scss";

/********************************************************
 *        ABANDON ALL HOPE, YE WHO ENTER HERE
 *
 * The despicable acts that take place in this file are
 * all in effort to make the garbage Wijmo grid work in
 * a way compatible with react. Judge not the hands that
 * made these works, for they are battle-scarred and weary.
 *******************************************************/

/** Do not render this component directly in a page! Instead, render a `DataGrid`! This component should only be rendered from within `DataGrid`! */
const WijmoGrid = React.forwardRef<wjFlexGrid, IDataGridProps>((props, forwardedRef) => {
  const {
    dataSource,
    dataUrl,
    dataArray,
    allowColumnReordering = false,
    allowPaging = true,
    pageSize,
    allowExcelExport = false,
    id,
    recordClick,
    recordDoubleClick,
    showColumnChooser = false,
    allowResizing = false,
    extraQueryString,
    height,
    onLoadedRows,
    enablePersistence,
    className,
  } = props;

  const [isNoDataVisible, setIsNoDataVisible] = useState<boolean>(false);
  const [filterObj, setFilterObj] = useState<wjFlexGridFilter | undefined>();
  const [persistedGridColumns, setPersistedGridColumns] = useState<{
    align: string,
    binding: string,
    dataType?: number,
    header: string,
    width?: number,
    visible?: boolean,
  }[] | undefined>(undefined);

  const gridIdToClearFilters = useAppSelector(store => store.grid.gridIdToClearFilters);
  const gridIdToRefresh = useAppSelector(store => store.grid.gridIdToRefresh);
  const dispatch = useAppDispatch();

  const currentClickEventRef = useRef<any>(null);
  const currentDoubleClickEventRef = useRef<any>(null);
  /** Determines if the grid has already run through its layout restoration procedure. */
  const isGridStateRestored = useRef<boolean>(false);
  /** A copy of the previous extra query string sent to the grid. Used to try to restore filter values when it changes. */
  const prevQueryString = useRef<IQueryStringValues[] | undefined>(undefined);

  // This block takes the sortSettings & filterSettings from props and memoizes them, using the
  // json representation of the sort settings to determine if the memo needs
  // to be updated. This is to prevent a rerender every time the parent
  // rerenders and to prevent needing to memoize the settings in every single
  // place that we render a grid.
  const rawSortSettings = props.sortSettings;
  const sortSettings = useMemo(() => rawSortSettings,
    // eslint-disable-next-line
    [JSON.stringify(rawSortSettings)]);

  const rawFilterSettings = props.filterSettings;
  const filterSettings = useMemo(() => rawFilterSettings,
    // eslint-disable-next-line
    [JSON.stringify(rawFilterSettings)]);

  const rawGridColumns = props.gridColumns;
  const gridColumns = useMemo(() => rawGridColumns,
    // eslint-disable-next-line
    [JSON.stringify(rawGridColumns)]);

  const filterableColumnFields = useMemo(() => gridColumns
    .filter(x => defaultBool(x.allowFiltering, true)
      && x.field)
    .map(x => x.field),
    [gridColumns]);

  const tooltipRef = useRef<Tooltip>(new Tooltip());
  const gridRef = useRef<wjFlexGrid | null>(null);
  const columnChooser = useRef<any>(null);
  const columnChooserButtonRef = useRef<HTMLButtonElement>(null);
  const pagerRef = useRef<wjCollectionViewNavigator | null>(null);

  useImperativeHandle(forwardedRef, () => gridRef.current as any);

  const [token, setToken] = useState<string | undefined>(undefined);
  const [isGridInitialized, setIsGridInitialized] = useState(false);
  const [odataSource, setOdataSource] = useState<RestCollectionViewOData | null>(null);
  const [arraySource, setArraySource] = useState<CollectionView | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // An effect that gets an access token necessary for the grid to get data from the API (if applicable).
  useEffect(() => {
    if (dataSource === "array") {
      setToken(undefined);
      return;
    }

    const getToken = async () => {
      setToken(await acquireAccessToken());
    };

    getToken();
  }, [setToken, dataSource]);

  // An effect that sets the grid's data source and initial sorting.
  useEffect(() => {
    if (!isGridInitialized) {
      return;
    }

    if (dataSource === "OData"
      && dataUrl
      && token) {
      const sourceUrl = combineDataUrlAndExtraQS(dataUrl, extraQueryString);

      setOdataSource(new RestCollectionViewOData(
        sourceUrl,
        setIsLoading,
        gridColumns,
        {
          requestHeaders: {
            Authorization: `Bearer ${token}`,
          },
          pageSize,
          pageOnServer: allowPaging
            ? true
            : undefined,
          sortOnServer: true,
          filterOnServer: true,
          sortDescriptions: mapSortSettings(sortSettings),
        }));
    } else if (dataSource === "array"
      && dataArray) {
      setArraySource(new CollectionView(dataArray, {
        pageSize,
        sortDescriptions: mapSortSettings(sortSettings),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridColumns, dataSource, dataUrl, dataArray, allowPaging, pageSize, token, isGridInitialized, sortSettings, setIsLoading, setOdataSource, setArraySource]);

  const applyBooleanFilterLogic = useCallback((filter: wjFlexGridFilter | undefined) => {
    if (!filter) {
      return;
    }

    // Create the "Yes" and "No" filter options for any boolean columns.
    // This will change the filter popup for those columns to have these values instead
    // of the default values.
    const map = new DataMap([
      { value: true, caption: "Yes", },
      { value: false, caption: "No", },
    ], "value",
      "caption");

    // Now, for each of the boolean columns, apply the filter options to them.
    for (let c = 0; c < filter.grid.columns.length; c++) {
      if (filter.grid.columns[c].dataType === DataType.Boolean) {
        filter.getColumnFilter(c).dataMap = map;
      }
    }
  }, []);


  // An effect that listens to the redux action for refreshing grids.
  useEffect(() => {
    if (id === gridIdToRefresh) {
      odataSource?.refreshOnServer();

      // Tell redux that there is no grid needing refreshing anymore.
      dispatch(refreshGrid({ gridId: "" }));
    }
  }, [gridIdToRefresh, id, dispatch, odataSource]);

  // A handler called when the grid first initializes.
  const initializeGrid = useCallback((flex: wjFlexGrid) => {
    gridRef.current = flex;

    configureRowClick(flex, "click", recordClick, currentClickEventRef);
    configureRowClick(flex, "dblclick", recordDoubleClick, currentDoubleClickEventRef);
    configureTooltip(flex, tooltipRef.current);
  }, [recordDoubleClick, recordClick]);

  // A method that persists the grid's current columns, filters, and sorting.
  const persistGridState = useCallback(() => {
    if (!gridRef.current
      || !gridRef.current.collectionView
      || !enablePersistence) {
      return;
    }

    // Now that the data has finished loading, that must mean it was either the original
    // load or because the user filtered/sorted.
    // Persist the grid to local storage.
    const gridJson = JSON.stringify({
      columns: gridRef.current.columnLayout,
      filterDefinition: filterObj?.filterDefinition,
      sortDescriptions: gridRef.current.collectionView.sortDescriptions.map(function (sortDesc) {
        return { property: sortDesc.property, ascending: sortDesc.ascending };
      }),
    });

    localStorage[`wijmoGridState_${id}`] = gridJson;
  }, [id, filterObj, enablePersistence]);

  // An effect that listens to the redux action for clearing grid filters.
  useEffect(() => {
    if (id === gridIdToClearFilters) {
      if (filterObj) {
        // If this grid is told to clear its filter, do so.
        filterObj?.clear();

        // Re-apply the boolean filter data map because clearing the filter also apparently clears out configuration.
        applyBooleanFilterLogic(filterObj);

        // Also re-persist the grid state since the filters have changed.
        persistGridState();
      }

      // Tell redux that there is no grid needing its filters cleared anymore.
      dispatch(clearGridFilters({ gridId: "" }));
    }
  }, [gridIdToClearFilters, id, dispatch, filterObj, persistGridState, applyBooleanFilterLogic]);

  // A function that is used to setup the column chooser data source and click events.
  const setupColumnChooser = useCallback(() => {
    if (!gridRef.current
      || !columnChooser.current) {
      return;
    }

    const choosableColumns = gridColumns
      .filter(x => x.showInColumnChooser !== false)
      .map(x => gridRef.current?.columns.getColumn(x.uid))
      .filter(isNotUndefined);

    columnChooser.current.itemsSource = choosableColumns;
    columnChooser.current.checkedMemberPath = 'visible';
    columnChooser.current.displayMemberPath = 'header';

    columnChooser.current.lostFocus.addHandler(() => {
      hidePopup(columnChooser.current.hostElement);
      persistGridState();
      columnChooser.current.lostFocus.removeAllHandlers();
    });
  }, [gridColumns, persistGridState]);

  // An effect that updates the grid filter's FILTER APPLIED event to persist the grid state.
  useEffect(() => {
    if (!filterObj) {
      return;
    }

    if (filterObj?.filterChanged) {
      filterObj.filterChanged.removeAllHandlers();
    }

    filterObj.filterChanged.addHandler(() => {
      persistGridState();
    });
  }, [persistGridState, filterObj]);

  // An effect that updates the grid's SORTED COLUMN event to persist the grid state.
  useEffect(() => {
    if (!gridRef.current) {
      return;
    }

    if (gridRef.current.sortedColumn) {
      gridRef.current.sortedColumn.removeAllHandlers();
    }

    gridRef.current.sortedColumn.addHandler(() => {
      persistGridState();
    });
  }, [persistGridState]);

  // A handler called when the grid's filter initializes. Used to set the initial default filters.
  const initializedFilter = useCallback((filter: wjFlexGridFilter) => {
    setFilterObj(filter);

    filter.defaultFilterType = FilterType.Condition;

    if (filterSettings) {
      filter.filterDefinition = JSON.stringify({
        defaultFilterType: FilterType.Condition,
        filters: filterSettings.columns.map(c => ({
          binding: c.field,
          type: "condition",
          condition1: {
            operator: c.operator,
            value: c.value,
          },
          and: true,
          condition2: {
            operator: null,
            value: "",
          },
        })),
      });
    }

    applyBooleanFilterLogic(filter);
  }, [filterSettings, applyBooleanFilterLogic]);

  // A handler for after the data has been loaded.
  const loadedRows = useCallback((sender: wjFlexGrid, args: any) => {
    onLoadedRows?.(sender, args);

    setIsNoDataVisible(!sender.rows.length);
  }, [onLoadedRows, setIsNoDataVisible]);

  // An effect that updates the "row click" event of the grid when the recordClick handler changes.
  useEffect(() => {
    if (!gridRef.current) {
      return;
    }

    // Rebind a new click event.
    configureRowClick(gridRef.current, "click", recordClick, currentClickEventRef);
  }, [recordClick]);

  // An effect that updates the "row double click" event of the grid when the recordDoubleClick handler changes.
  useEffect(() => {
    if (!gridRef.current) {
      return;
    }

    // Rebind a new click event.
    configureRowClick(gridRef.current, "dblclick", recordDoubleClick, currentClickEventRef);
  }, [recordDoubleClick]);

  // An effect that listens for various things in an attempt to restore the grid's layout (if one was previously saved).
  useEffect(() => {
    if (isExtraQueryStringDifferent(extraQueryString, prevQueryString.current)) {
      // The extra query string has changed. Need to reload the filters.
      isGridStateRestored.current = false;
    }

    if (!gridRef.current
      || (!arraySource
        && !odataSource)
      || !gridRef.current.collectionView
      || !enablePersistence
      || isGridStateRestored.current) {
      // Grid either does not exist or has no source collection applied yet.
      return;
    }

    var json = localStorage[`wijmoGridState_${id}`];

    if (json) {
      var persistedState = JSON.parse(json);

      const columnState = JSON.parse(persistedState.columns).columns;

      setPersistedGridColumns(columnState);

      columnState.forEach((col: any, index: any) => {
        if (gridRef.current?.columns[index].binding !== col.binding) {
          let srcColIndex = gridRef.current?.columns.find(
            (c) => c.binding === col.binding
          )?.index;

          if (srcColIndex) {
            // move column on the required index
            gridRef.current?.columns.moveElement(srcColIndex, index);
          }
        }
      });

      if (filterObj) {
        const filtDef = JSON.parse(persistedState.filterDefinition);
        const persistedCols = JSON.parse(persistedState.columns).columns;

        filtDef.filters = filtDef.filters.filter((filter: any) => {
          const newColBinding = mapToWijmoDataType(gridColumns.find(x => x.field === filter.binding)?.type)?.toString();
          const oldColBinding = persistedCols.find((x: any) => x.binding === filter.binding)?.dataType?.toString();

          if (!newColBinding
            || !oldColBinding
            || newColBinding !== oldColBinding) {
            // Since the persisted grid column has a different type than the now current code,
            // this filter should not be applied and instead ignored.
            return false;
          }

          return true;
        });

        filtDef.filters.forEach((filter: any) => {
          const newColBinding = mapToWijmoDataType(gridColumns.find(x => x.field === filter.binding)?.type);

          if (newColBinding === DataType.Boolean) {
            // This is the filter for a boolean column.
            if (filter.condition1.value === true) {
              filter.condition1.value = "Yes";
            } else if (filter.condition1.value === false) {
              filter.condition1.value = "No";
            } else if (filter.condition2.value === true) {
              filter.condition2.value = "Yes";
            } else if (filter.condition2.value === false) {
              filter.condition2.value = "No";
            }
          }
        });

        filterObj.filterDefinition = JSON.stringify(filtDef);

        // Immediately set the flag that says the grid state was loaded (even if it doesn't do anything).
        // This ensures the procedure only runs through a single time once everything has been loaded.
        isGridStateRestored.current = true;
      }

      // Get the grid's current collectionView and restore the sort order info.
      var view = gridRef.current.collectionView;

      view.deferUpdate(function () {
        view.sortDescriptions.clear();

        for (var i = 0; i < persistedState.sortDescriptions.length; i++) {
          var sortDesc = persistedState.sortDescriptions[i];
          view.sortDescriptions.push(new SortDescription(sortDesc.property, sortDesc.ascending));
        }
      });

      // Re-configure the Yes/No options for boolean columns.
      if (filterObj) {
        // Create the "Yes" and "No" filter options for any boolean columns.
        // This will change the filter popup for those columns to have these values instead
        // of the default values.
        const map = new DataMap([
          { value: true, caption: "Yes", },
          { value: false, caption: "No", },
        ], "value",
          "caption");

        // Now, for each of the boolean columns, apply the filter options to them.
        for (let c = 0; c < filterObj.grid.columns.length; c++) {
          if (filterObj.grid.columns[c].dataType === DataType.Boolean) {
            filterObj.getColumnFilter(c).dataMap = map;
          }
        }
      }
    }
  }, [filterObj, id, arraySource, odataSource, enablePersistence, extraQueryString, gridColumns, setPersistedGridColumns]);

  // An effect that listens to the filterObj and adds a filterApplied event handler to move to the first page after a filter is applied.
  useEffect(() => {
    if (!filterObj
      || !pagerRef.current) {
      return;
    }

    if (filterObj.filterApplied) {
      filterObj.filterApplied.removeAllHandlers();
    }

    filterObj.filterApplied.addHandler(() => {
      pagerRef.current?.cv.moveToFirstPage();
    });
  }, [filterObj]);

  useEffect(() => {
    if (dataUrl
      && odataSource) {
      const sourceUrl = combineDataUrlAndExtraQS(dataUrl, extraQueryString);

      if (odataSource._url !== sourceUrl) {
        // Update the odataSource's url.
        odataSource._url = sourceUrl;
        // Force the grid to refresh with its new url.
        odataSource.refreshOnServer();
      }
    }
  }, [odataSource, dataUrl, extraQueryString]);

  return (
    <div
      className={`wijmo-grid ${className ? className : ""}`}
      style={height
        ? { height }
        : undefined
      }
    >
      <FlexGrid
        itemsSource={dataSource === "OData"
          ? odataSource
          : arraySource
        }
        isReadOnly={true}
        initialized={(flex: wjFlexGrid) => {
          setIsGridInitialized(true);
          initializeGrid(flex);
        }}
        selectionMode="None"
        allowResizing={allowResizing}
        loadedRows={loadedRows}
        allowDragging={allowColumnReordering}
        resizedColumn={persistGridState}
        draggedColumn={persistGridState}
        virtualizationThreshold={[0, 50]}
        style={{ minHeight: props.minHeight ?? "200px" }}
      >
        <FlexGridFilter
          initialized={initializedFilter}
          filterColumns={filterableColumnFields}
          showSortButtons={false}
          filterChanging={(filter: wjFlexGridFilter, e: any) => {
            const col = gridRef.current?.columns[e.col];
            const colBinding = col?.binding;

            if (!colBinding) {
              return;
            }

            const cnt = filter.getColumnFilter(colBinding);

            if (!cnt.conditionFilter.isActive) {
              if (col.dataType === DataType.String) {
                cnt.conditionFilter.condition1.operator = Operator.CT;
              } else if (col.dataType === DataType.Number
                || col.dataType === DataType.Date
                || col.dataType === DataType.Boolean) {
                cnt.conditionFilter.condition1.operator = Operator.EQ;
              }

              // Remove filter if no data is entered in first condition value
              // when the modal is closed.
              filter.activeEditor.lostFocus.addHandler(() => {
                let val = cnt.conditionFilter.condition1.value;
                if (val === undefined || val === null || val === '') {
                  cnt.clear();
                }
              });

              filter.activeEditor.updateEditor();
            }

            // Get a reference to both combo boxes and input values.
            const cb1 = Control.getControl(filter.activeEditor.hostElement.querySelector(`[wj-part="div-cmb1"]`));
            const cb2 = Control.getControl(filter.activeEditor.hostElement.querySelector(`[wj-part="div-cmb2"]`));

            if (col.dataType === DataType.Boolean) {
              let filterConditionItemsSource = [
                { name: '(not set)', op: null },
                { name: 'Equals', op: 0 },
                { name: 'Does not equal', op: 1 },
              ];

              if (cb1) {
                (cb1 as any).itemsSource = filterConditionItemsSource;
              }

              if (cb2) {
                (cb2 as any).itemsSource = filterConditionItemsSource;
              }
            }

            const val1 = Control.getControl(filter.activeEditor.hostElement.querySelector(`[wj-part="div-val1"]`));
            const val2 = Control.getControl(filter.activeEditor.hostElement.querySelector(`[wj-part="div-val2"]`));

            // Add handler to clear the filter values when the user chooses "(not set)".
            if (cb1
              && val1) {
              (cb1 as any).selectedIndexChanged.addHandler((s: any) => {
                if (s.selectedIndex <= 0) {
                  (val1 as any).text = "";
                }
              });
            }

            if (cb2
              && val2) {
              (cb2 as any).selectedIndexChanged.addHandler((s: any) => {
                if (s.selectedIndex <= 0) {
                  (val2 as any).text = "";
                }
              });
            }

            if (val1) {
              // If it was found, schedule a 1ms delay and then focus on it.
              // The 1 ms delay is necessary because Wijmo.
              window.setTimeout(() => val1?.focus(), 1);
            }
          }}
        />

        {showColumnChooser && (
          <FlexGridCellTemplate
            cellType="TopLeft"
            template={() =>
              <Button
                ref={columnChooserButtonRef}
                onClick={e => {
                  if (!columnChooserButtonRef.current
                    || !columnChooser.current) {
                    return;
                  }

                  let host = columnChooser.current.hostElement;

                  if (!host.offsetHeight) {
                    // Configure the column chooser.
                    setupColumnChooser();

                    // Show the column chooser.
                    showPopup(host, columnChooserButtonRef.current, true, true, false);
                    columnChooser.current.focus();
                  } else {
                    hidePopup(host, true, true);
                    columnChooserButtonRef.current.focus();
                  }

                  e.preventDefault();
                }}
                buttonType="clear"
                img={hamburgerIcon}
                imgAlt="C"
                imgPlacement="right"
                className="col-chooser-button"
              />
            }
          />
        )}

        {gridColumns.map(col => {
          const persistedCol = persistedGridColumns
            ?.find(x => x.header === col.headerText
              && x.binding === col.field);

          return (
            <FlexGridColumn
              key={col.uid}
              uid={col.uid}
              header={col.headerText}
              binding={col.field}
              width={persistedCol?.width ?? col.width}
              format={col.format}
              align={col.textAlign ?? "Left"}
              visible={defaultBool(persistedCol?.visible, defaultBool(col.visible, true))}
              minWidth={col.minWidth}
              allowSorting={defaultBool(col.allowSorting, true)}
              dataType={mapToWijmoDataType(col.type)}
            >
              {col.template &&
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context: any) => col.template?.(context.item)}
                />
              }

              {!col.template
                && col.field
                && col.type === "boolean" &&
                <FlexGridCellTemplate
                  cellType="Cell"
                  template={(context: any) =>
                    Boolean(context.item[col.field!])
                      ? "Yes"
                      : "No"
                  }
                />
              }
            </FlexGridColumn>
          );
        })}
      </FlexGrid>

      {showColumnChooser &&
        <ListBox
          className="column-chooser"
          initialized={(chooser: any) => columnChooser.current = chooser}
        />
      }

      {allowPaging &&
        <CollectionViewNavigator
          byPage={true}
          initialized={(pager: wjCollectionViewNavigator) => pagerRef.current = pager}
          headerFormat="{currentPage:n0} of {pageCount:n0} pages ({itemCount:n0} items)"
          cv={dataSource === "OData"
            ? odataSource
            : arraySource
          }
        />
      }

      {isLoading &&
        <div className="wijmo-grid-spinner-holder">
          <Spinner
            className="wijmo-grid-spinner"
          />
        </div>
      }

      {allowExcelExport &&
        <WijmoGridExporter
          gridId={id}
          ref={gridRef}
        />
      }

      {isNoDataVisible &&
        <div className="wijmo-grid-no-data-message">
          No records to display
        </div>
      }
    </div>
  );
});

export default WijmoGrid;

/** Converts the data grid sort settings into a Wijmogrid sort description array. */
function mapSortSettings(sortSettings: IDataGridSortSettings | undefined): SortDescription[] | undefined {
  if (!sortSettings) {
    return [];
  }

  return sortSettings
    .columns
    .map(setting => new SortDescription(
      setting.field,
      setting.direction === "Ascending"
    ));
}

/** Configures a tooltip for each cell. */
function configureTooltip(flex: any, tooltip: Tooltip) {
  flex.formatItem.addHandler((s: wjFlexGrid, e: FormatItemEventArgs) => {
    if ((e.panel === s.cells
      || e.panel === s.columnHeaders)
      && e.cell.innerText?.trim()?.length) {
      tooltip.setTooltip(e.cell, e.cell.innerText);
    }
  });
}

/** Sets up the grid to handle double clicks on data rows. */
function configureRowClick(flex: any,
  clickType: "click" | "dblclick",
  handler: ((rowData: any) => void) | undefined,
  currentClickEventRef: any) {
  if (!handler) {
    return;
  }

  const newClickHandler = (e: any) => {
    let ht = flex.hitTest(e.pageX, e.pageY);

    if (ht.cellType !== 1) {
      return;
    }

    // Call the dev's click method.
    handler(ht.getRow().dataItem);
  };

  if (currentClickEventRef.current) {
    // Remove the old event from the element then clear the ref.
    flex.hostElement.removeEventListener(clickType, currentClickEventRef.current);
  }

  currentClickEventRef.current = newClickHandler;

  flex.hostElement.addEventListener(clickType, newClickHandler);
}

/** Combines the url with the extra query string values provided by the dev into one full http. */
function combineDataUrlAndExtraQS(dataUrl: string, extraQueryString: IQueryStringValues[] | undefined) {
  if (!extraQueryString?.length) {
    return dataUrl;
  }

  const qs = extraQueryString
    .map(x => `${x.key}=${x.value}`)
    .join("&");

  let sourceUrl = dataUrl;

  if (qs) {
    if (sourceUrl.includes("?")) {
      // Already has a query string. Append.
      sourceUrl += `&${qs}`;
    } else {
      // No existing query string. Add.
      sourceUrl += `?${qs}`;
    }
  }

  return sourceUrl;
}

/** If the provided bool is not undefined, it is returned. Otherwise, defaultValue is returned. */
function defaultBool(bool: boolean | undefined, defaultValue: boolean) {
  return bool === undefined
    ? defaultValue
    : bool;
}

/** Maps a type to a Wijmo DataType enum value. */
function mapToWijmoDataType(type: "string" | "number" | "boolean" | "date" | "unbound" | undefined): DataType | undefined {
  switch (type) {
    case "string": return DataType.String;
    case "number": return DataType.Number;
    case "boolean": return DataType.Boolean;
    case "date": return DataType.Date;
    default: return undefined;
  }
}

function isExtraQueryStringDifferent(extraQueryString: IQueryStringValues[] | undefined, prevQueryString: IQueryStringValues[] | undefined) {
  if ((extraQueryString
    && !prevQueryString)
    || (!extraQueryString
      && prevQueryString)) {
    // Changed!
    return true;
  }

  if (extraQueryString === undefined
    && prevQueryString === undefined) {
    // No change.
    return false;
  }

  const valueChecker: any = {};

  extraQueryString!.forEach(extraItem => valueChecker[extraItem.key + "_" + extraItem.value] = 1);
  prevQueryString!.forEach(extraItem => {
    if (valueChecker[extraItem.key + "_" + extraItem.value]) {
      valueChecker[extraItem.key + "_" + extraItem.value]++;
    } else {
      valueChecker[extraItem.key + "_" + extraItem.value] = 1;
    }
  });

  // If there are any key/value pairs that only appear in one of the objects, that means it's changed.
  return Object.entries(valueChecker).some(x => x[1] === 1);
}

