import React from "react";
import { useAppSelector } from "store/store";
import { AuditStatuses } from "types/auditingTypes";
import CloseAuditButton from "../close-button/CloseAuditButton";
import CompleteAuditButton from "../complete-button/CompleteAuditButton";
import StartAuditButton from "../start-button/StartAuditButton";

const NextAuditStatusButton: React.FC = () => {
  const audit = useAppSelector(store => store.audit.audit);

  if (!audit) {
    return null;
  }

  const status = audit?.status;

  if (status === AuditStatuses.Planned) {
    return <StartAuditButton />;
  } else if (status === AuditStatuses.InProgress) {
    return <CompleteAuditButton />;
  } else if (status === AuditStatuses.Completed) {
    return <CloseAuditButton />;
  } else {
    return null;
  }
};

export default NextAuditStatusButton;