import AuditorSearchMessage from "components/audits/common/auditor-search-message/AuditorSearchMessage";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import DatePicker, { DatePickerSelectionMode } from "shared/components/controls/date-picker/DatePicker";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { userToString } from "shared/utilities/userUtilities";
import { closeEditItemModal, closePicker, loadPickerItems, loadSuggestedPickerItems, openPicker, saveCalendarItem, setSelectedPickerItems } from "store/audit-plan-calendar/AuditPlanCalendarSlice";
import { useAppSelector } from "store/store";
import "./SchedulerItemEditorModal.scoped.scss";

interface ISelDateRange {
  start?: Date,
  end?: Date,
}

interface ISchedulerItemEditorModalProps {
}

const SchedulerItemEditorModal: React.FC<ISchedulerItemEditorModalProps> = () => {
  const editItemModal = useAppSelector(store => store.auditPlanCalendar.editItemModal);
  const leadAuditors = useAppSelector(store => store.auditPlanCalendar.pickerData.editorLeadAuditors);
  const basinYAxisData = useAppSelector(store => store.auditPlanCalendar.basinYAxisData);
  const saveEventsOp = useAppSelector(store => store.auditPlanCalendar.saveEventsOp);

  const [selectedDateRange, setSelectedDateRange] = useState<ISelDateRange>({});

  const dispatch = useDispatch();

  let itemStartTime = editItemModal?.item?.startTime;
  let itemEndTime = editItemModal?.item?.endTime;

  useEffect(() => {
    setSelectedDateRange({
      start: itemStartTime ? new Date(itemStartTime) : undefined,
      end: itemEndTime ? new Date(itemEndTime) : undefined,
    });
  }, [itemStartTime, itemEndTime]);

  if (!editItemModal?.isOpen) {
    return null;
  }

  let item = editItemModal.item;

  const validateAndSave = () => {
    if (!selectedDateRange.start
      || !selectedDateRange.end) {
      dispatch(showErrorToast("Both Start and End dates must be specified."));
      return;
    }

    dispatch(saveCalendarItem({
      item,
      startDate: selectedDateRange.start.getTime(),
      endDate: selectedDateRange.end.getTime(),
      leadAuditor: leadAuditors.selectedItems.length > 0
        ? leadAuditors.selectedItems[0].item
        : undefined,
    }));
  };

  const onClose = () => {
    dispatch(closeEditItemModal());
  };

  return (
    <Modal
      header="Edit Item"
      isOpen={editItemModal.isOpen}
      buttons={[{
        className: "secondary",
        text: "Cancel",
        key: "Cancel",
        onClick: onClose,
      }, {
        className: "primary",
        text: "Save",
        key: "Save",
        onClick: () => validateAndSave(),
      }]}
      showCloseButton={true}
      onCloseButtonClicked={onClose}
    >
      <FlexCol>
        <FlexRow>
          <LabeledControl
            label="Start/End Dates"
          >
            <DatePicker
              selectionMode={DatePickerSelectionMode.range}
              value={selectedDateRange.start}
              startDate={selectedDateRange.start}
              endDate={selectedDateRange.end}
              onChange={dateRange => {
                if (Array.isArray(dateRange)) {
                  setSelectedDateRange({
                    start: dateRange[0] === null
                      ? undefined
                      : dateRange[0],
                    end: dateRange[1] === null
                      ? undefined
                      : dateRange[1],
                  });
                }
              }}
              isClearable={false}
              placeholderText="Select"
            />
          </LabeledControl>

          <LabeledControl
            label="Lead Auditor"
          >
            <Picker<IAzureADUser>
              title="Lead Auditor"
              subTitle={
                <AuditorSearchMessage
                  isFirstPartySearch={item.auditTypeId === 2}
                />
              }
              renderListItem={(item) => userToString(item)}
              renderSelectedItem={(item) => userToString(item, "")}
              pickerState={leadAuditors}
              preserveItems={true}
              searchOptions={{
                asyncMinChars: 1,
                behavior: "async",
              }}
              loadSuggestionsAction={loadSuggestedPickerItems}
              preserveSuggestedItems={false}
              openAction={openPicker}
              closeAction={closePicker}
              loadAction={loadPickerItems}
              setSelectedItemsAction={setSelectedPickerItems}
              noItemsMessage={"Please begin typing a search term to execute a search."}
              showSuggestedItems={true}
              itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
            />
          </LabeledControl>
        </FlexRow>

        <table>
          <thead>
            <tr>
              <th>
                Requirement
              </th>
              <th>
                Sub Geo Unit
              </th>
              <th>
                Parent Dimension
              </th>
              <th>
                Child Dimension
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {item.requirementDimensionTexts.slice().sort((a, b) => a < b ? -1 : 1).join(", ")}
              </td>
              <td>
                {basinYAxisData.subGeoUnits.find(x => x.id === item.subGeoUnitId)?.name || "--"}
              </td>
              <td>
                {item.parentDimensionText} ({item.parentDimensionSubType || item.parentDimensionType})
              </td>
              <td>
                {item.childDimensionId === undefined
                  ? "--"
                  : `${item.childDimensionText} (${item.childDimensionSubType || item.childDimensionType})`
                }
              </td>
            </tr>
          </tbody>
        </table>
      </FlexCol>

      {saveEventsOp?.isWorking && <ModalSpinner />}
    </Modal>
  );
};

export default SchedulerItemEditorModal;