import UrlRoutes from 'components/routing/UrlRoutes';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import VideoLinkButton from 'shared/components/common/video-link-button/VideoLinkButton';
import { clearSelectedQuestions, toggleQuestionSelections } from 'store/audit-non-conformances/AuditNonConformancesSlice';
import { removeAllActionsByLink } from 'store/audit/AuditSlice';
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes } from 'types/actionItemTypes';
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";
import AuditPage from "../common/audit-page/AuditPage";
import ManageFindingModal from '../findings/manage-finding-modal/ManageFindingModal';
import NonCompliantQuestionsCard from "./non-compliant-questions/NonCompliantQuestionsCard";
import TopicCard from "./non-compliant-questions/TopicCard";
import UpdateResponseModal from './update-response-modal/UpdateResponseModal';

const AuditNonConformancesPage: React.FC = () => {
  const auditStatus = useAppSelector(store => store.audit.audit?.status);
  const allAnswers = useAppSelector(store => store.audit.answers);
  const allQuestions = useAppSelector(store => store.audit.questions);
  const updateQuestionResponseModal = useAppSelector(store => store.auditNonConformance.updateQuestionResponseModal);
  const findings = useAppSelector(store => store.audit.findings);
  const findingModal = useAppSelector(store => store.audit.findingModal);
  const dispatch = useAppDispatch();

  const nonConformantAnswers = allAnswers
    .filter(x => x.canBeUpdated);

  const nonConformantAnswerCodes = nonConformantAnswers
    .map(x => x.code);

  // Filter out all questions that have OutOfScope responses.
  // They do not appear on this page ever.
  const questions = allQuestions
    .filter(x => !x.responses.some(r => r.isOutOfScope
      && nonConformantAnswerCodes.includes(r.answer)));

  useEffect(() => {
    return () => {
      dispatch(clearSelectedQuestions());
    };
  }, [dispatch]);

  const {
    auditId: routeId,
  } = useParams();

  if (!routeId) {
    return null;
  }

  return (
    <AuditPage
      title={"Audit Non-Conformances"}
      pageControls={[{
        key: "VIDEO",
        element: (
          <VideoLinkButton
            links={[{
              title: "To manage and close your RWP as a responsible party",
              url: "https://slb001.sharepoint.com/sites/QoF-ScrumTeams/_layouts/15/stream.aspx?uniqueId=e5ba912a-47c7-5562-5cc8-d68ef852e849&portal=%7b%22ha%22:%22classicstream%22%2c%22hm%22:%22view%22%7d&referrer=StreamWebApp.Web&referrerScenario=AddressBarCopied.view&scenario=2",
              description: "This video provides information on how a responsible party can manage & close the assigned action items",
            }, {
              title: "To validate actions and close an audit",
              url: "https://slb001.sharepoint.com/sites/QoF-ScrumTeams/_layouts/15/stream.aspx?uniqueId=0896a3e3-cf96-586e-019e-13e22798b7e9&portal=%7b%22ha%22:%22classicstream%22%2c%22hm%22:%22view%22%7d&referrer=StreamWebApp.Web&referrerScenario=AddressBarCopied.view&scenario=2",
              description: "This video provides information on how a Lead Auditor can validate actions & close an audit",
            }]}
          />
        ),
      }]}
      backButtonRoute={UrlRoutes.MyAudits.urlTemplate}
      auditId={routeId}
      overviewSettings={{
        showQuestionCounts: true,
        showComplianceScore: true,
      }}
      loadAuditScores
      loadFindingTypes
    >
      {!!questions.length && (
        <>
          
          <NonCompliantQuestionsCard />

          {Object.entries(groupNonCompliantTopics(questions,
            auditStatus,
            nonConformantAnswers
              .map(x => x.code)))
            .sort((a, b) => (a[1].name).localeCompare(b[1].name))
            .map(grp => (
              <TopicCard
                key={grp[0]}
                topicId={grp[1].topicId}
                title={grp[1].name}
                questions={grp[1].questions}
              />
            ))}
        </>
      )}

      {updateQuestionResponseModal.isOpen && (
        <UpdateResponseModal />
      )}

      {findingModal.isOpen && (
        <ManageFindingModal
          onSave={() => {
            dispatch(toggleQuestionSelections(questions.map(x => ({
              auditQuestionId: x.auditQuestionId,
              isSelected: false,
              questionId: x.questionId,
              questionNum: x.questionNumber,
            }))));

            return true;
          }}
          saveToServerOnSave={true}
          onLinksRemoved={removedLinks => {
            if (!removedLinks.length) {
              return;
            }

            const finding = findings.find(x => x.id === removedLinks[0].findingId);

            if (!finding?.id) {
              return;
            }

            if (!finding
              ?.links
              .filter(l => !removedLinks.some(r => r.id === l.id))
              .length) {
              // All links were removed.
              dispatch(removeAllActionsByLink({
                linkId: finding.id.toString(),
                linkType: ActionItemLinkTypes.AuditFinding,
              }));
            }
          }}
        />
      )}
    </AuditPage>
  );
};

export default AuditNonConformancesPage;

type TopicQuestionGroups = Record<number, ITopicGroup>;

interface ITopicGroup {
  topicId: number,
  name: string,
  questions: IAuditQuestion[],
}

export function groupNonCompliantTopics(questions: IAuditQuestion[],
  auditStatus: AuditStatuses | undefined,
  nonConformantAnswerCodes: string[]): { [s: string]: ITopicGroup; } | ArrayLike<ITopicGroup> {
  return questions
    .filter(q => (q.responses.find(x => isNonConformantAnswer(x.answer, nonConformantAnswerCodes)
      && x.auditStatus === auditStatus)
      || q.responses.some(x => isNonConformantAnswer(x.answer, nonConformantAnswerCodes)
        && x.auditStatus === AuditStatuses.InProgress)))
    .reduce((acc: TopicQuestionGroups, curr) => {
      if (!acc[curr.topicId]) {
        acc[curr.topicId] = {
          topicId: curr.topicId,
          name: curr.topicName,
          questions: [curr],
        };
      } else {
        acc[curr.topicId].questions.push(curr);
      }
      return acc;
    }, {});
}

export function isNonConformantAnswer(answer: string, nonConformantAnswerCodes: string[]) {
  return nonConformantAnswerCodes.some(x => x === answer);
}