import UrlRoutes from "components/routing/UrlRoutes";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Page from "shared/components/layout/page/Page";
import { clearFiltersNames, setSearchPropValue as setCustomSearchSearchPropValue } from "store/search/CustomSearchSlice";
import { clearFilters as clearActionFilters, clearFilters as clearFiltersAction, deleteFilters as deleteActionFilters, saveFilters as saveActionFilters, setSearchPropValue as setActionSearchPropValue, setSaveFiltersModal as setSaveActionFiltersModal, updateFilters as updateActionFilters } from "store/search/actions/SearchActionsSlice";
import {
  clearFilters as clearAuditFilters,
  clearFilters as clearFiltersAudit,
  deleteFilters as deleteAuditFilters,
  saveFilters as saveAuditFilters,
  setSearchPropValue as setAuditSearchPropValue,
  setSaveFiltersModal as setSaveAuditFiltersModal,
  updateFilters as updateAuditFilters
} from "store/search/audits/SearchAuditsSlice";
import { useAppSelector } from "store/store";
import { ICustomSearchListItem } from "types/customSearchTypes";
import { showErrorToast } from "../../shared/store/toast/ToastSlice";
import SaveCustomSearchModal from "./SearchAuditsSaveFiltersModal";
import SearchPageFooter from "./SearchPageFooter";
import SearchActionsFilters from "./actions/search-actions-filters/SearchActionsFilters";
import SearchAuditsFilters from "./audits/search-audits-filters/SearchAuditsFilters";
import SearchPageElements from "./search-elements/SearchPageElements";

export enum SearchType {
  Audits = "Audits",
  Actions = "Actions",
}

const SearchPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let searchType: SearchType | undefined = undefined;

  if (useMatch(UrlRoutes.SearchAudits.urlTemplate)) {
    searchType = SearchType.Audits;
  }
  if (useMatch(UrlRoutes.SearchActions.urlTemplate)) {
    searchType = SearchType.Actions;
  }

  const auditId = useAppSelector(store => store.searchAudits.id);
  const actionId = useAppSelector(store => store.searchActions.id);
  const isSaveAuditFiltersModalOpen = useAppSelector(store => store.searchAudits.isSaveFiltersModalOpen);
  const isSaveActionFiltersModalOpen = useAppSelector(store => store.searchActions.isSaveFiltersModalOpen);
  const loadAuditFiltersOperation = useAppSelector(store => store.searchAudits.loadAuditFiltersOperation);
  const updateAuditFiltersOperation = useAppSelector(store => store.searchAudits.updateFiltersOperation);
  const deleteAuditFiltersOperation = useAppSelector(store => store.searchAudits.deleteFiltersOperation);
  const updateActionFiltersOperation = useAppSelector(store => store.searchActions.updateFiltersOperation);
  const deleteActionFiltersOperation = useAppSelector(store => store.searchActions.deleteFiltersOperation);
  const targetPropCofirmationDeleteModalOpen = "cofirmationDeleteModalOpen";
  const cofirmationDeleteModalOpen = useAppSelector(store => store.customSearch[targetPropCofirmationDeleteModalOpen]);
  const filtersNameAudit = useAppSelector(store => store.customSearch.filtersNameAudit);
  const filtersNameAction = useAppSelector(store => store.customSearch.filtersNameAction);
  const customSearchAuditList = useAppSelector(store => store.customSearch.customSearchAuditList);
  const customSearchActionList = useAppSelector(store => store.customSearch.customSearchActionList);
  const targetPropcofirmationSameNameModalOpen = "cofirmationSameNameModalOpen";
  const cofirmationSameNameModalOpen = useAppSelector(store => store.customSearch[targetPropcofirmationSameNameModalOpen]);
  const [filtersIdCheck, setFiltersIdCheck] = useState<number>(0);

  return (
    <Page
      title="Search"
      footer={searchType !== undefined && (
        <SearchPageFooter
          id={searchType === SearchType.Audits
            ? auditId
            : actionId}
          onApplyFilters={() => {
            if (searchType === undefined) {
              return;
            }

            navigate(searchType === SearchType.Audits
              ? UrlRoutes.AuditSearchResults.urlTemplate
              : UrlRoutes.ActionSearchResults.urlTemplate);
          }}
          onClearFilters={() => {
            if (searchType === undefined) {
              return;
            }
            dispatch(clearFiltersNames());
            dispatch(searchType === SearchType.Audits
              ? clearAuditFilters()
              : clearActionFilters());
          }}
          onSaveFilters={() => {
            searchType === SearchType.Audits
              ? dispatch(setSaveAuditFiltersModal(true))
              : dispatch(setSaveActionFiltersModal(true));
          }}
          onUpdateFilters={() => {
            searchType === SearchType.Audits
              ? dispatch(updateAuditFilters())
              : dispatch(updateActionFilters());
          }}
          onDeleteFilters={() => {
            if (auditId !== undefined || actionId !== undefined) {
              dispatch(setCustomSearchSearchPropValue({ [targetPropCofirmationDeleteModalOpen]: true }))
            } else {
              dispatch(showErrorToast("No Search Filter was loaded."));
            }
          }}
          isDeleteViewDisabled={!(auditId !== undefined || actionId !== undefined)}
        />
      )}
    >
      <SearchPageElements />

      {searchType === SearchType.Audits &&
        <SearchAuditsFilters />
      }

      {searchType === SearchType.Actions &&
        <SearchActionsFilters />
      }

      <SaveCustomSearchModal
        isOpen={
          searchType === SearchType.Audits
            ? isSaveAuditFiltersModalOpen
            : isSaveActionFiltersModalOpen
        }
        onCancel={() => searchType === SearchType.Audits
          ? dispatch(setSaveAuditFiltersModal(false))
          : dispatch(setSaveActionFiltersModal(false))}
        onSave={name => {
          let foundSameName: ICustomSearchListItem | undefined = undefined;

          if (searchType === SearchType.Audits) {
            foundSameName = customSearchAuditList?.find(x => x.name === name);

            if (foundSameName === undefined) {
              dispatch(saveAuditFilters({ filtersName: name, searchType: searchType }));
            } else {
              setFiltersIdCheck(foundSameName.id);
              dispatch(setCustomSearchSearchPropValue({ [targetPropcofirmationSameNameModalOpen]: true }))
            }
          } else if (searchType === SearchType.Actions) {
            foundSameName = customSearchActionList?.find(x => x.name === name);

            if (foundSameName === undefined) {
              dispatch(saveActionFilters({ filtersName: name, searchType: searchType }));
            } else {
              setFiltersIdCheck(foundSameName.id);
              dispatch(setCustomSearchSearchPropValue({ [targetPropcofirmationSameNameModalOpen]: true }))
            }
          } else {
            dispatch(showErrorToast("Unknown search type."));
          }
        }}
        searchType={searchType}
      />

      {(updateAuditFiltersOperation?.isWorking || deleteAuditFiltersOperation?.isWorking || loadAuditFiltersOperation?.isWorking ||
        updateActionFiltersOperation?.isWorking || deleteActionFiltersOperation?.isWorking)
        && (
          <ModalSpinner />
        )}

      {cofirmationDeleteModalOpen && (
        <ConfirmModal
          message={<span> Are you sure you want to delete this filter {searchType === SearchType.Audits ? <b>{filtersNameAudit}</b> : <b>{filtersNameAction}</b>} ?</span>}
          onNoClicked={() => dispatch(setCustomSearchSearchPropValue({ [targetPropCofirmationDeleteModalOpen]: false }))}
          onYesClicked={() => {
            if (searchType === SearchType.Audits) {
              dispatch(deleteAuditFilters());
              dispatch(clearFiltersAudit());
            } else {
              dispatch(deleteActionFilters());
              dispatch(clearFiltersAction());
            }

            dispatch(clearFiltersNames());
            dispatch(setCustomSearchSearchPropValue({ [targetPropCofirmationDeleteModalOpen]: false }))
          }}
          header="Confirm Delete ?"
        />
      )}

      {cofirmationSameNameModalOpen && (
        <ConfirmModal
          message={<span>There is a filter with the same name do you want to replace it ?</span>}
          onNoClicked={() => dispatch(setCustomSearchSearchPropValue({ [targetPropcofirmationSameNameModalOpen]: false }))}
          onYesClicked={() => {
            if (searchType === SearchType.Audits) {
              dispatch(setAuditSearchPropValue({ id: filtersIdCheck }));
              dispatch(updateAuditFilters())
            } else {
              dispatch(setActionSearchPropValue({ id: filtersIdCheck }));
              dispatch(updateActionFilters())
            }
            dispatch(setSaveActionFiltersModal(false));
            dispatch(setCustomSearchSearchPropValue({ [targetPropcofirmationSameNameModalOpen]: false }));

          }}
          header="Confirm Replace ?"
        />
      )}
    </Page>
  );
}

export default SearchPage;