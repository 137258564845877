
export interface IAzureADUser {
  name: string,
  email: string,
  jobTitle?: string
};

export interface IUserProfile {
  id: number,
  user: IUser,
  activeProfile: IActiveProfile,
  profiles: IProfile[]
  derivedMetaRestrictions: IRestrictedMetaData[],
};

export interface IUser {
  name: string,
  email: string,
  jobTitle: string,
  photo?: string,
};

export interface IActiveProfile {
  profileId: number,
  moduleName: string,
};

export interface IProfile {
  id: number,
  userId: number,
  module: {
    name: string,
    description: string,
  },
  role: {
    name: string,
    description: string,
  },
  profileMetaData: IProfileMetaData[]
};

export interface IProfileMetaData {
  type: string,
  description: string,
  masterDataId: number,
  masterDataCode: string,
  masterDataName: string,
};

export interface IRestrictedMetaData {
  type: string,
  id: number,
}

export interface ISwitchUserProfile {
  activeProfile: IActiveProfile,
}

export interface IActiveUserProfile {
  profileId: number,
  moduleName: string,
  roleName: string,
  profileMetaData: IProfileMetaData[],
  /** The list of all profile meta data and all their child meta data from
   * the respective hierarchies. If this is empty, the user has no
   * profile restrictions whatsoever. */
  derivedMetaRestrictions: IRestrictedMetaData[],
}

export enum ModuleTypes {
  All = "All",
  Auditing = "Auditing",
  RiskRegistry = "RiskRegistry",
}

export enum RoleTypes {
  DefaultUser = "DefaultUser",
  Admin = "Admin",
  AuditPlanner = "AuditPlanner",
  SystemAdmin = "SystemAdmin",
}

export interface ISaveProfileRequest {
  module: {
    name: string,
    description: string,
  },
  role: {
    name: string,
    description: string,
  },
  profileMetaData: ISaveProfileMetaDataRequest[]
};

export interface ISaveProfileMetaDataRequest {
  type: string,
  masterDataId: number,
};