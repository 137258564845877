import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PickerList from "shared/components/controls/picker/list/PickerList";
import AuditTopicPicker from "shared/components/controls/pickers/AuditTopicPicker";
import BusinessFunctionPicker from "shared/components/controls/pickers/BusinessFunctionPicker";
import BusinessViewPicker from "shared/components/controls/pickers/BusinessViewPicker";
import FlexCol from "shared/components/layout/flex/FlexCol";
import { IPickerItem } from "shared/types/pickerTypes";
import { addRecursively, getChildrenRecursively, getTopicChildrenRecursively, toPickerItem } from "shared/utilities/hierarchyUtilities";
import { editAttribute, setEditAttributeModal } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";
import { AuditTopicSearchTypes, IAuditTopic } from "types/auditMasterDataTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IBusinessFunction, IBusinessView, MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";
import "./EditAttributeModal.scoped.scss";

const CreateAttributeModal: React.FC = () => {
  const dispatch = useDispatch();

  const editAttributeModal = useAppSelector(store => store.facilityAttributes.editAttributeModal);
  const {
    availableTemplates,
    mandatoryItems,
    savedTemplateSelection,
    planProfileAttributeInfo,
    availableTopics,
  } = useAppSelector(store => store.facilityAttributes.contextPlanProfileAttributeModal);
  const derivedMetaRestrictions = useAppSelector(store => store.auth.activeUserProfile.derivedMetaRestrictions);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);

  const [selectedExtraBusinessViews, setSelectedExtraBusinessViews] = useState<IBusinessView[]>(planProfileAttributeInfo?.extraBusinessViews ?? []);
  const [selectedExtraBusinessFunctions, setSelectedExtraBusinessFunctions] = useState<IBusinessFunction[]>(planProfileAttributeInfo?.extraBusinessFunctions ?? []);
  const [selectedExtraSubTopics, setSelectedExtraSubTopics] = useState<IAuditTopic[]>(planProfileAttributeInfo?.extraSubTopics ?? []);
  const [selectedAuditTemplates, setSelectedAuditTemplates] = useState<(IDetailedTemplate | IDetailedTemplateChildren)[]>(savedTemplateSelection);

  const isEditAuditTemplate = editAttributeModal.requirement.type === MetaDataTypes.AuditTemplate;

  const allTemplates: (string | number)[] = [];
  const allTopics: (string | number)[] = [];

  // Prepare available templates
  availableTemplates.forEach(x => {
    addRecursively(x, allTemplates);
  });

  // Prepare available audit topics
  availableTopics.forEach(x => {
    addRecursively(x, allTopics);
  });

  useEffect(() => {
    if (!isEditAuditTemplate) {
      setSelectedExtraSubTopics(selectedExtraSubTopics);
    }
    setSelectedAuditTemplates(savedTemplateSelection);
  }, [isEditAuditTemplate, savedTemplateSelection, selectedExtraSubTopics]);

  const isRowTypeBusinessView = editAttributeModal.row.type === "BusinessView";
  const businessViewFilterByCode = isRowTypeBusinessView
    ? [editAttributeModal.row.text, "MSP"]
    : ["MSP"];

  const isRowTypeBusinessFunction = editAttributeModal.row.type === "BusinessFunction";
  const businessFunctionFilterByCode = isRowTypeBusinessFunction
    ? [editAttributeModal.row.text]
    : [];

  return (
    editAttributeModal.isOpen
      ? (
        <ConfirmModal
          header="Edit Requirement"
          yesButtonOptions={{
            text: "Apply"
          }}

          noButtonOptions={{
            text: "Cancel"
          }}
          message={
            <div>
              <FlexCol>
                <span>
                  Edit the <b>{editAttributeModal.requirement.text}</b> Audit Requirement to <b>{editAttributeModal.row.text}</b>
                </span>
              </FlexCol>

              {isEditAuditTemplate &&
                <>
                  <hr className="divider" />

                  <FlexCol className="template-picker">
                    <PickerList<IDetailedTemplate | IDetailedTemplateChildren>
                      items={availableTemplates.map(x => toPickerItem(allTemplates, x, true, availableTemplates))}
                      renderItem={(item) => item.name}
                      allowMultiSelect={true}
                      noItemsMessage={"No suggestions available."}
                      selectedItems={selectedAuditTemplates.map(x => toPickerItem(allTemplates, x))}
                      displayMode={"tree"}
                      notShowPlusMinusSign={true}
                      onItemSelected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
                        if (item.item) {
                          const items = getChildrenRecursively(item.item, [], false, 'Both');
                          setSelectedAuditTemplates(selectedAuditTemplates.concat(items.filter(topic => topic.isSelectable)));
                        }
                      }}
                      onItemDeselected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
                        if (item.item) {
                          const items = getChildrenRecursively(item.item, [], false, 'Both');
                          const idsToRemove = items.map(i => i.key);
                          setSelectedAuditTemplates(selectedAuditTemplates.filter(({ key }) => !idsToRemove.includes(key)));
                        }
                      }}
                      isDisabledMapper={(item: (IDetailedTemplate | IDetailedTemplateChildren), ancestorPath: (IDetailedTemplate | IDetailedTemplateChildren)[]) => {
                        const child = mandatoryItems.filter(x => x.key === item.key);
                        if (child.length > 0) {
                          return true;
                        }
                        return false;
                      }}
                    />
                  </FlexCol>

                  <hr className="divider" />

                  <FlexCol className="template-picker">
                    <span>
                      <b>Topics/SubTopics</b>
                    </span>

                    <PickerList<IDetailedTemplate | IDetailedTemplateChildren>
                      items={availableTopics.map(x => toPickerItem(allTopics, x, true, availableTopics))}
                      renderItem={(item) => item.name}
                      allowMultiSelect={true}
                      noItemsMessage={"No suggestions available."}
                      selectedItems={selectedAuditTemplates.map(x => toPickerItem(allTopics, x))}
                      displayMode={"tree"}
                      notShowPlusMinusSign={true}
                      onItemSelected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
                        if (item.item) {
                          const items = getTopicChildrenRecursively(item.item, []);
                          setSelectedAuditTemplates(selectedAuditTemplates.concat(items));
                        }
                      }}
                      onItemDeselected={(item: IPickerItem<(IDetailedTemplate | IDetailedTemplateChildren)>) => {
                        if (item.item) {
                          const items = getTopicChildrenRecursively(item.item, []);
                          const idsToRemove = items.map(i => i.key);
                          setSelectedAuditTemplates(selectedAuditTemplates.filter(({ key }) => !idsToRemove.includes(key)));
                        }
                      }}
                      onItemExpanded={() => { }}
                      onItemCollapsed={() => { }}
                      isDisabledMapper={(item: (IDetailedTemplate | IDetailedTemplateChildren), ancestorPath: (IDetailedTemplate | IDetailedTemplateChildren)[]) => {
                        const child = mandatoryItems.filter(x => x.key === item.key);
                        if (child.length > 0) {
                          return true;
                        }
                        return false;
                      }}
                    />
                  </FlexCol>
                </>
              }

              <hr className="divider" />

              <div className="extra-metadata-title">
                Extra Metadata
              </div>

              <FlexCol className="template-picker">
                {
                  !isEditAuditTemplate && <LabeledControl
                    label="Sub Topics"
                  >
                    <AuditTopicPicker
                      selectedItems={selectedExtraSubTopics}
                      isRequired
                      onApply={(items) => {
                        setSelectedExtraSubTopics(items);
                      }}
                      renderMode="picker"
                      allowMultiselect
                      auditTopicSearch={{
                        auditTopicSearchIds: [editAttributeModal.requirement.id],
                        auditTopicSearchType: AuditTopicSearchTypes.OnlySubTopicsFromIds
                      }}
                    />
                  </LabeledControl>
                }
                <LabeledControl label="Business View (Divisions)">
                  <BusinessViewPicker
                    selectedItems={selectedExtraBusinessViews}
                    allowMultiselect={true}
                    showSuggestions={true}
                    userProfileMetaRestrictions={editRestriction === EditRestriction.EditAllRestrict
                      ? derivedMetaRestrictions
                      : undefined
                    }
                    onApply={setSelectedExtraBusinessViews}
                    isItemDisabledMapper={(item, ancestorPath) => {
                      // If the ancestor of this item is MSP, then disable it.
                      return isRowTypeBusinessView
                        && (!ancestorPath.some(x => businessViewFilterByCode.includes(x.code))
                          && item.code !== "MSP");
                    }}
                    codesToFilter={isRowTypeBusinessView
                      ? businessViewFilterByCode
                      : []
                    }
                  />
                </LabeledControl>
                <LabeledControl
                  label="Business Function (Functions)"
                >
                  <BusinessFunctionPicker
                    selectedItems={selectedExtraBusinessFunctions}
                    allowMultiselect={true}
                    showSuggestions={true}
                    userProfileMetaRestrictions={editRestriction === EditRestriction.EditAllRestrict
                      ? derivedMetaRestrictions
                      : undefined
                    }
                    onApply={setSelectedExtraBusinessFunctions}
                    isItemDisabledMapper={(item, ancestorPath) => {
                      return isRowTypeBusinessFunction && (!ancestorPath.some(x => businessFunctionFilterByCode.includes(x.code)) && item.code !== "MSP");
                    }}
                    codesToFilter={businessFunctionFilterByCode}
                  />
                </LabeledControl>
              </FlexCol>
            </div>
          }
          onNoClicked={() => dispatch(setEditAttributeModal({
            isOpen: false,
          }))}
          onYesClicked={() => dispatch(editAttribute({
            row: editAttributeModal.row,
            requirement: editAttributeModal.requirement,
            attributeId: editAttributeModal.attributeId,
            profileStandardTemplateSelectedItems: cloneDeep(selectedAuditTemplates), // TODO: FIX THIS.
            selectedExtraBusinessViews,
            selectedExtraBusinessFunctions,
            selectedExtraSubTopics
          }))}
        />
      ) : null
  );
};

export default CreateAttributeModal;
