import React from "react";
import FlexRow from "shared/components/layout/flex/FlexRow";
import attachIcon from "shared/media/dls/attachment.svg";
import { IAuditReportSectionAttachment } from "types/auditingTypes";
import "./SectionItem.scoped.scss";

interface ISectionItemProps {
  isChecked: boolean,
  isDisabled: boolean,
  isIndented: boolean,
  label: string,
  attachments: IAuditReportSectionAttachment[],
  onSectionClicked(): void,
  onAttachmentClicked(): void,
}
const SectionItem: React.FC<ISectionItemProps> = ({
  isChecked,
  isDisabled,
  isIndented,
  label,
  attachments,
  onSectionClicked,
  onAttachmentClicked,
}) => {
  let tooltipMessage = "";

  if (isDisabled
    && !isChecked) {
    tooltipMessage = "Not included in the report";
  } else if (!isDisabled
    && !isChecked) {
    tooltipMessage = "Mark the checkbox to enabled attach evidence";
  } else {
    tooltipMessage = "Click to attach evidence image";
  }

  return (
    <FlexRow
      className={isIndented
        ? "indented"
        : ""}
    >
      <label
        className={isDisabled ? "disabled" : ""}
      >
        <input
          type="checkbox"
          checked={isChecked}
          disabled={isDisabled}
          onChange={onSectionClicked}
        />
        {label}
      </label>
      <img
        alt="Att"
        title={tooltipMessage}
        className={`add-attachment icon-small ${attachments.length > 0
          ? 'has-attachment'
          : ""
          } ${!isChecked
            ? 'na'
            : ""
          }`}
        src={attachIcon}
        onClick={!isChecked
          ? undefined
          : onAttachmentClicked
        }
      />
    </FlexRow>
  );
}
export default SectionItem;
