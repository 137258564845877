import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import { closePicker, collapsePickerItem, expandPickerItem, loadPickerItems, openPicker, setSelectedPickerItems } from "store/search/audits/SearchAuditsSlice";
import { useAppSelector } from "store/store";
import { IAuditTopic } from "types/auditMasterDataTypes";

const Topics: React.FC = () => {
  const auditTopics = useAppSelector(store => store.searchAudits.pickerData.auditTopics);

  return (
    <LabeledControl
      label="Topics / Sub-Topics"
      className="type"
    >
      <Picker<IAuditTopic>
        maxSelectedItemsVisible={0}
        pickerState={auditTopics}
        title="Audit Topics / Sub-Topics"
        renderListItem={(item) => `${item.name}`}
        openAction={openPicker}
        loadAction={loadPickerItems}
        closeAction={closePicker}
        setSelectedItemsAction={setSelectedPickerItems}
        searchOptions={{
          filterItem: (item, searchTerm) => {
            const ix = item.item?.name?.toLowerCase().indexOf(searchTerm);
            if (ix !== undefined
              && ix > -1) {
              return true;
            }

            return false;
          },
        }}
        preserveItems={false}
        displayMode="tree"
        allowMultiSelect={true}
        expandItemsAction={expandPickerItem}
        collapseItemsAction={collapsePickerItem}
        itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
      />
    </LabeledControl>
  );
};

export default Topics;