import ManageActionItemModal from "components/actions/manage/ManageActionItemModal";
import { useDispatch } from "react-redux";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import { closeActionItem, finishLoadingActionItem } from "store/search/actions/SearchActionsSlice";
import { useAppSelector } from "store/store";

const ActionItemInspector: React.FC = () => {
  const dispatch = useDispatch();
  const inspectedActionItem = useAppSelector(store => store.searchActions.inspectedActionItem);
  const loadActionItemOp = useAppSelector(store => store.searchActions.loadActionItemOp);
  
  return (
    <>
      {loadActionItemOp?.isWorking && (
        <ModalSpinner />
      )}

      {inspectedActionItem && (
        <ManageActionItemModal
          actionItem={inspectedActionItem}
          actionItemEditors={[]}
          actionItemLinks={[]}
          onCancel={() => dispatch(closeActionItem())}
          onActionItemChanged={actionEvent => {
            dispatch(finishLoadingActionItem({
              isWorking: false,
              data: actionEvent.actionItem,
            }));
          }}
        />
      )}
    </>
  );
};

export default ActionItemInspector;