import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlanProfileAttribute } from "components/audits/planning/facility-attributes/facilityAttributeTypes";
import { IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import { cloneDeep, isEqual } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IOwnerGroup, ITemplate } from "types/auditingTypes";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { ICenterAuditAttribute, IPlanProfileAttributeInfo, IPlanRequirementDimension, IProfileParentChildMatrixItem } from "types/auditPlanningTypes";
import { IBusinessFunction, IBusinessView, MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";

export interface IFacilityAttributesState {
  mode: "view" | "edit",

  loadOp: IOperation<void> | undefined,

  reviewAttributesOp: IOperation<void> | undefined,

  profileParentChildMatrix?: IProfileParentChildMatrixItem[],
  profileParentChildMatrixOp?: IOperation<void>,

  addChildDimensionModal: IAddChildDimensionModal,
  addChildDimensionOp?: IOperation<void>,

  addParentDimensionModal: IAddParentDimensionModal,
  addParentDimensionOp?: IOperation<void>,

  createAttributeModal: ICreateAttributeModal,
  createAttributeOp?: IOperation<void>,

  editAttributeModal: IEditAttributeModal,
  editAttributeOp?: IOperation<void>,

  removeAttributeModal: IRemoveAttributeModal,
  removeAttributeOp?: IOperation<void>,

  removeChildDimensionModal: IRemoveChildDimensionModal,
  removeChildDimensionOp?: IOperation<void>,

  confirmRemoveOrEditAttributeModal: IConfirmRemoveOrEditAttributeModal,

  defaultMetaData: IDefaultMetaData,
  contextPlanProfileAttributeModal: IContextPlanProfileAttributeModal,

  ownerGroup: IOwnerGroup,
  centerAuditAttributes: ICenterAuditAttribute[],
  centerAuditAttributeModal: ICenterAuditAttributeModal,
  centerAuditAttributeOp?: IOperation<void>,
  centerAuditAttributesOriginals: ICenterAuditAttribute[],
  centerAuditAttributesToSave: ICenterAuditAttribute[],
}

export interface IAddParentDimensionModal {
  isOpen: boolean,
  /** The Id of the SubGeoUnit this parent profile should be added to. */
  locationId: number,
  /** The MetaDataType of the SubGeoUnit this parent profile should be added to. */
  locationType: MetaDataTypes,
  /** The Name of the SubGeoUnit this parent profile should be added to. */
  locationName: string,
  /** The SubGeoUnit this parent is being added to. */
  subGeoUnitId: number,
}

export interface IAddChildDimensionModal {
  isOpen: boolean,
  parentProfileId: number | undefined,
  parentMasterDataId: number,
  parentMasterDataType: MetaDataTypes,
  parentMasterDataSubType: string | undefined,
  parentText: string,
  subGeoUnitId: number,
}

export interface IRemoveChildDimensionModal {
  isOpen: boolean,
  parentText: string,
  profileId: number,
  rowText: string,
}

export type IRemoveAttributeModal = {
  isOpen: boolean,
  attribute: IPlanProfileAttribute,
  row: IPlanningItem,
  requirement: IPlanRequirementDimension,
};

export interface ICreateAttributeModal {
  isOpen: boolean,
  parentRow?: IPlanningItem,
  row: IPlanningItem,
  profileId: number,
  requirement: IPlanRequirementDimension,
  defaultSelectedDetailedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  subGeoUnitId: number,
}

export interface IEditAttributeModal {
  isOpen: boolean,
  attributeId: number,
  row: IPlanningItem,
  requirement: IPlanRequirementDimension,
}

export interface IContextPlanProfileAttributeModal {
  savedTemplateSelection: (IDetailedTemplate | IDetailedTemplateChildren)[],
  availableTemplates: (IDetailedTemplate | IDetailedTemplateChildren)[],
  availableTopics: (IDetailedTemplate | IDetailedTemplateChildren)[],
  mandatoryItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  planProfileAttributeInfo?: IPlanProfileAttributeInfo,
}

export type IConfirmRemoveOrEditAttributeModal = {
  isOpen: boolean,
  attribute: IPlanProfileAttribute,
  row: IPlanningItem,
  requirement: IPlanRequirementDimension,
  total?: string,
  isWorking: boolean,
  onEditCLMQMSClick: Partial<ICenterAuditAttributeModal>,
  scoringSystem: string,
};

export interface IDefaultMetaData {
  savedExtraBusinessViewItems: (IBusinessView)[],
  savedExtraBusinessFunctionItems: (IBusinessFunction)[],
  savedExtraSubTopicsItems: (IAuditTopic)[],
}

export interface ICenterAuditAttributeModal {
  isOpen: boolean,
  showModal: boolean,
  profileId: number,
  profileStandardId: number,
  requirement: IPlanRequirementDimension,
  parentRow?: IPlanningItem,
  row: IPlanningItem,
  defaultSelectedDetailedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  subGeoUnitId: number,
}

const initialState: IFacilityAttributesState = {
  mode: "view",

  loadOp: undefined,
  reviewAttributesOp: undefined,

  profileParentChildMatrix: undefined,
  profileParentChildMatrixOp: undefined,

  addParentDimensionModal: {
    isOpen: false,
    locationId: 0,
    locationType: MetaDataTypes.BusinessTeam,
    locationName: "",
    subGeoUnitId: 0,
  },
  addParentDimensionOp: undefined,

  addChildDimensionModal: {
    isOpen: false,
    parentMasterDataId: 0,
    parentMasterDataType: MetaDataTypes.Facility,
    parentMasterDataSubType: undefined,
    parentProfileId: undefined,
    parentText: "",
    subGeoUnitId: 0,
  },
  addChildDimensionOp: undefined,

  createAttributeModal: {
    isOpen: false,
    requirement: { id: 0, text: "", type: MetaDataTypes.AuditTopic, masterDataItem: undefined },
    row: {
      id: 0,
      children: [],
      isExpanded: true,
      isPlannable: true,
      text: "",
      type: MetaDataTypes.Facility,
      subType: undefined,
      doesUserHavePermission: false,
      isWithinPlanningRange: false,
      subGeoUnitId: 0,
    },
    profileId: 0,
    defaultSelectedDetailedTemplateItems: [],
    subGeoUnitId: 0,
  },
  createAttributeOp: undefined,

  editAttributeModal: {
    isOpen: false,
    requirement: { id: 0, text: "", type: MetaDataTypes.AuditTopic, masterDataItem: undefined },
    row: {
      id: 0,
      children: [],
      isExpanded: true,
      isPlannable: true,
      text: "",
      type: MetaDataTypes.Facility,
      subType: undefined,
      doesUserHavePermission: false,
      isWithinPlanningRange: false,
      subGeoUnitId: 0,
    },
    attributeId: 0,
  },

  contextPlanProfileAttributeModal: {
    savedTemplateSelection: [],
    availableTemplates: [],
    availableTopics: [],
    mandatoryItems: [],
    planProfileAttributeInfo: undefined,
  },

  editAttributeOp: undefined,

  removeAttributeModal: {
    isOpen: false,
    attribute: {
      id: 0,
      profileId: 0,
      masterDataId: 0,
      masterDataType: MetaDataTypes.Facility,
      deleted: false,
      comment: undefined,
      reviewedByEmail: undefined,
      reviewedByName: undefined,
      reviewedOn: undefined,
      deletedByEmail: undefined,
      deletedByName: undefined,
      deletedOn: undefined,
    },
    requirement: { id: 0, text: "", type: MetaDataTypes.AuditTopic, masterDataItem: undefined },
    row: {
      id: 0,
      children: [],
      isExpanded: true,
      isPlannable: true,
      text: "",
      type: MetaDataTypes.Facility,
      subType: undefined,
      doesUserHavePermission: false,
      isWithinPlanningRange: false,
      subGeoUnitId: 0,
    },
  },
  removeAttributeOp: undefined,

  removeChildDimensionModal: {
    isOpen: false,
    parentText: "",
    profileId: 0,
    rowText: "",
  },
  removeChildDimensionOp: undefined,

  confirmRemoveOrEditAttributeModal: {
    isOpen: false,
    isWorking: false,
    attribute: {
      id: 0,
      profileId: 0,
      masterDataId: 0,
      masterDataType: MetaDataTypes.Facility,
      deleted: false,
      comment: undefined,
      reviewedByEmail: undefined,
      reviewedByName: undefined,
      reviewedOn: undefined,
      deletedByEmail: undefined,
      deletedByName: undefined,
      deletedOn: undefined,
    },
    requirement: { id: 0, text: "", type: MetaDataTypes.AuditTopic, masterDataItem: undefined },
    row: {
      id: 0,
      children: [],
      isExpanded: true,
      isPlannable: true,
      text: "",
      type: MetaDataTypes.Facility,
      subType: undefined,
      doesUserHavePermission: false,
      isWithinPlanningRange: false,
      subGeoUnitId: 0,
    },
    total: undefined,
    onEditCLMQMSClick: {},
    scoringSystem: "",
  },

  defaultMetaData: {
    savedExtraBusinessViewItems: [],
    savedExtraBusinessFunctionItems: [],
    savedExtraSubTopicsItems: [],
  },

  ownerGroup: {
    id: 0,
    name: "",
    isDeleted: false,
    scoringSystem: "",
  },
  centerAuditAttributes: [],
  centerAuditAttributeModal: {
    isOpen: false,
    showModal: false,
    profileId: 0,
    profileStandardId: 0,
    requirement: {
      id: 0,
      text: "",
      type: MetaDataTypes.AuditTopic,
      masterDataItem: undefined
    },
    row: {
      id: 0,
      children: [],
      isExpanded: true,
      isPlannable: true,
      text: "",
      type: MetaDataTypes.Facility,
      subType: undefined,
      doesUserHavePermission: false,
      isWithinPlanningRange: false,
      subGeoUnitId: 0,
    },
    defaultSelectedDetailedTemplateItems: [],
    subGeoUnitId: 0,
  },
  centerAuditAttributeOp: undefined,
  centerAuditAttributesOriginals: [],
  centerAuditAttributesToSave: [],
};

export const facilityAttributesSlice = createSlice({
  name: "planning-facility-attributes",
  initialState,
  reducers: {
    /** Toggles the page mode as specified. */
    setMode: (state, action: PayloadAction<"view" | "edit">) => {
      state.mode = action.payload;
    },

    /** Opens the properties of the "Add Parent Dimension" modal. */
    setAddParentDimensionModal: (state, action: PayloadAction<Partial<IAddParentDimensionModal>>) => {
      Object.assign(state.addParentDimensionModal, {}, action.payload);
    },

    /** Starts an operation to add a new child profile to a profile. */
    addParentDimensions: (state, _: PayloadAction<{
      parentItems: IAddParentItem[],
      parentRowMasterDataId: number,
      parentRowMasterDataType: MetaDataTypes,
      subGeoUnitId: number,
    }>) => {
      state.addParentDimensionOp = {
        isWorking: true,
      };
    },

    /** Clears the operation. */
    clearAddParentDimensionOp: (state) => {
      state.addParentDimensionOp = undefined;
    },

    /** Opens the properties of the "Add Child Dimension" modal. */
    setAddChildDimensionModal: (state, action: PayloadAction<Partial<IAddChildDimensionModal>>) => {
      Object.assign(state.addChildDimensionModal, {}, action.payload);
    },

    /** Starts an operation to add a new child profile to a profile. */
    addChildDimensions: (state, _: PayloadAction<{
      childItems: { id: number, type: string, code: string }[],
      parentProfileId: number | undefined,
      parentMasterDataId: number,
      parentMasterDataType: MetaDataTypes,
      parentMasterDataSubType: string | undefined,
      parentText: string,
      subGeoUnitId: number,
    }>) => {
      state.addChildDimensionOp = {
        isWorking: true,
      };
    },

    /** Clears the operation. */
    clearAddChildDimensionOp: (state) => {
      state.addChildDimensionOp = undefined;
    },

    /** Sets the properties of the "Add Attribute" modal. */
    setCreateAttributeModal: (state, action: PayloadAction<Partial<ICreateAttributeModal>>) => {
      Object.assign(state.createAttributeModal, {}, action.payload);

      if (action.payload.isOpen) {
        // Modal was just opened.
        state.createAttributeOp = {
          isWorking: true,
        };
      } else if (action.payload.isOpen === false) {
        // Modal was just closed.
        state.defaultMetaData = {
          savedExtraBusinessFunctionItems: [],
          savedExtraBusinessViewItems: [],
          savedExtraSubTopicsItems: [],
        };
      }
    },

    /** Starts an operation to add a new attribute to a particular row. If no profile exists for that row, one will be created first. */
    createAttribute: (state, _: PayloadAction<{
      row: IPlanningItem,
      requirement: IPlanRequirementDimension,
      profileId: number,
      profileStandardTemplateSelectedItems: (ITemplate | IDetailedTemplate | IDetailedTemplateChildren)[],
      selectedExtraBusinessViews: IBusinessView[],
      selectedExtraBusinessFunctions: IBusinessFunction[],
      selectedExtraSubTopics: IAuditTopic[],
      subGeoUnitId: number,
    }>) => {
      state.createAttributeOp = {
        isWorking: true,
      };
    },

    /** Clears the operation. */
    clearCreateAttributeOp: (state) => {
      state.createAttributeOp = undefined;
    },

    /** Sets the properties of the "Edit Attribute" modal. */
    setEditAttributeModal: (state, action: PayloadAction<Partial<IEditAttributeModal>>) => {
      Object.assign(state.editAttributeModal, {}, action.payload);
      state.editAttributeOp = {
        isWorking: true,
      };
    },

    setLoadedSavedItems: (state, action: PayloadAction<Partial<IEditAttributeModal>>) => {
      Object.assign(state.editAttributeModal, {}, action.payload);
      state.editAttributeOp = {
        isWorking: false,
      };
    },

    setDefaultCreateTemplateSelection: (state, action: PayloadAction<Partial<ICreateAttributeModal>>) => {
      Object.assign(state.createAttributeModal, {}, action.payload);
    },

    /** Starts an operation to edit a particular attribute.  */
    editAttribute: (state, _: PayloadAction<{
      row: IPlanningItem,
      requirement: IPlanRequirementDimension,
      attributeId: number,
      profileStandardTemplateSelectedItems: (ITemplate | IDetailedTemplate | IDetailedTemplateChildren)[],
      selectedExtraBusinessViews: IBusinessView[],
      selectedExtraBusinessFunctions: IBusinessFunction[],
      selectedExtraSubTopics: IAuditTopic[],
    }>) => {
      state.editAttributeOp = {
        isWorking: true,
      };
    },

    /** Clears the operation. */
    clearEditAttributeOp: (state) => {
      state.editAttributeOp = undefined;
    },

    /** Sets the properties of the "Remove Attribute" modal. */
    setRemoveAttributeModal: (state, action: PayloadAction<Partial<IRemoveAttributeModal>>) => {
      Object.assign(state.removeAttributeModal, action.payload);
    },

    /** Starts an operation to remove an attribute from a particular row. */
    removeAttribute: (state, _: PayloadAction<{
      attribute: IPlanProfileAttribute,
      comment: string,
    }>) => {
      state.removeAttributeOp = {
        isWorking: true,
      };
    },

    /** Clears the operation. */
    clearRemoveAttributeOp: (state) => {
      state.removeAttributeOp = undefined;
    },

    /** Sets the properties of the "Remove Child Dimension" modal. */
    setRemoveChildDimensionModal: (state, action: PayloadAction<Partial<IRemoveChildDimensionModal>>) => {
      Object.assign(state.removeChildDimensionModal, action.payload);
    },

    /** Starts an operation to remove a child dimension profile. */
    removeChildDimension: (state, _: PayloadAction<{
      profileId: number,
      comment: string,
    }>) => {
      state.removeChildDimensionOp = {
        isWorking: true,
      };
    },

    /** Clears the operation. */
    clearRemoveChildDimensionOp: (state) => {
      state.removeChildDimensionOp = undefined;
    },

    /** Sets the properties of the "Confirmation of Remove or Edit Attribute" modal. */
    setConfirmRemoveOrEditAttributeModal: (state, action: PayloadAction<Partial<IConfirmRemoveOrEditAttributeModal>>) => {
      Object.assign(state.confirmRemoveOrEditAttributeModal, action.payload);

      if (action.payload.isOpen) {
        state.confirmRemoveOrEditAttributeModal.isWorking = true;
      }
    },

    /** Sets the properties of the "Confirmation of Remove or Edit Attribute" modal. */
    setTotalSelectedInformationForAttributeModal: (state, action: PayloadAction<string>) => {
      state.confirmRemoveOrEditAttributeModal.total = action.payload;
    },

    setDefaultMetaData: (state, action: PayloadAction<IDefaultMetaData>) => {
      state.defaultMetaData = action.payload;
    },

    /** Set context to modal add, edit and modal to confirmation action. */
    setContextPlanProfileAttributeModal: (state, action: PayloadAction<Partial<IContextPlanProfileAttributeModal>>) => {
      Object.assign(state.contextPlanProfileAttributeModal, action.payload);
    },

    loadProfileParentChildMatrix: (state) => {
      state.profileParentChildMatrixOp = {
        isWorking: true,
      };
    },

    finishLoadProfileParentChildMatrix: (state, action: PayloadAction<IOperation<IProfileParentChildMatrixItem[]>>) => {
      state.profileParentChildMatrixOp = undefined;

      if (action.payload.data) {
        state.profileParentChildMatrix = action.payload.data;
      }
    },

    /** Begins an operation to mark all specified attributes "Reviewed". A saga handles the request. */
    markAttributesReviewed: (state, _: PayloadAction<IPlanProfileAttribute[]>) => {
      state.reviewAttributesOp = {
        isWorking: true,
      };
    },

    /** Updates the review info for the specified attributes then clears the reviewOp. */
    clearMarkAttributesReviewedOp: state => {
      state.reviewAttributesOp = undefined;
    },

    //CLM

    /** Get the scoring type. Needs to send the owner gorup id */
    getScoringSystem: (state, _: PayloadAction<number>) => {

    },

    /** Save the scoring type */
    finishGetScoringSystem: (state, action: PayloadAction<IOwnerGroup>) => {
      state.ownerGroup = action.payload;
    },

    /** Sets the properties of the center audit attributes. */
    setCenterAuditAttributes: (state, action: PayloadAction<ICenterAuditAttribute[]>) => {
      state.centerAuditAttributes = action.payload;
      state.centerAuditAttributesOriginals = action.payload;
      state.centerAuditAttributeModal.showModal = true;
    },

    /** Sets the properties of the center audit attributes modal. */
    setCenterAuditAttributeModal: (state, action: PayloadAction<Partial<ICenterAuditAttributeModal>>) => {
      Object.assign(state.centerAuditAttributeModal, {}, action.payload);
      //Wait to open the modal when all te attributes are loaded
      
      if (action.payload.isOpen) {
        // Modal was just opened.
        state.centerAuditAttributeOp = {
          isWorking: true,
        };
      } else if (action.payload.isOpen === false) {
        // Modal was just closed.
        state.centerAuditAttributes = [];
        state.centerAuditAttributesOriginals = [];
        state.centerAuditAttributesToSave = [];
      }
    },

    /** Clears the operation for setCenterAuditAttributeModal. */
    clearCenterAuditAttributeOp: (state) => {
      state.centerAuditAttributeOp = undefined;
    },

    /** Call when changing the attributes */
    onSetAttributeCheckBox: (state, action: PayloadAction<ICenterAuditAttribute>) => {
      const attribute = state.centerAuditAttributes.find(x => x.topicId === action.payload.topicId);
      Object.assign(attribute!, action.payload);

      const attributeOriginal = state.centerAuditAttributesOriginals.find(x => x.topicId === action.payload.topicId);

      if (isEqual(attribute, attributeOriginal)) {
        state.centerAuditAttributesToSave = state.centerAuditAttributesToSave
          .filter(x => x.topicId !== action.payload.topicId);
      } else {
        const attributeToSave = state.centerAuditAttributesToSave.find(x => x.topicId === action.payload.topicId);

        if (attributeToSave === undefined) {
          state.centerAuditAttributesToSave.push(action.payload);
        } else {
          Object.assign(attributeToSave!, action.payload);
        }
      }
    },

    /** Start the saving process */
    saveCenterAuditAttributes: (state, action: PayloadAction<{
      row: IPlanningItem,
      requirement: IPlanRequirementDimension,
      profileId: number,
      subGeoUnitId: number,
      attributesToSave: ICenterAuditAttribute[],
    }>) => {
      state.centerAuditAttributeOp = {
        isWorking: true,
      };
      state.centerAuditAttributesToSave = cloneDeep(action.payload.attributesToSave);

      state.centerAuditAttributesToSave.forEach(e => {
        if (!e.applicable) {
          // Quick fix: If any of the items are not applicable and, for some reason they are
          // marked implemented, set implemented to false. This shouldn't happen because the
          // logic in the modal should handle this already. But just in case there are any
          // invalid records in the db, this will fix their state.
          e.implemented = false;
        }
        e.profileStandardId = state.centerAuditAttributeModal.profileStandardId;
        e.masterDataId = e.topicId;
        e.masterDataType = MetaDataTypes.AuditTopic;
      });
    },

    /** Finish the saving process */
    finishSaveCenterAuditAttributes: (state) => {
      state.centerAuditAttributesOriginals = state.centerAuditAttributes;
      state.centerAuditAttributesToSave = [];
      state.centerAuditAttributeOp = undefined;
      state.centerAuditAttributeModal.showModal = false;
    },

    /** Set the profile standar id in the CenterAuditAttributes */
    setAttributeIdForCenterCenterAuditAttributes: (state, action: PayloadAction<number>) => {
      state.centerAuditAttributesToSave.forEach(element => {
        element.profileStandardId = action.payload;
      });
    },
  }
});

export const {
  setMode,

  // Add parent dimension actions.
  setAddParentDimensionModal,
  addParentDimensions,
  clearAddParentDimensionOp,

  // Add child dimension actions.
  setAddChildDimensionModal,
  addChildDimensions,
  clearAddChildDimensionOp,

  // Profile Parent/Child Matrix actions.
  loadProfileParentChildMatrix,
  finishLoadProfileParentChildMatrix,

  // Create attribute actions.
  setCreateAttributeModal,
  setDefaultCreateTemplateSelection,
  createAttribute,
  clearCreateAttributeOp,
  setContextPlanProfileAttributeModal,

  // Edit attribute actions.
  setEditAttributeModal,
  setLoadedSavedItems,
  editAttribute,
  clearEditAttributeOp,

  // Review attributes actions.
  markAttributesReviewed,
  clearMarkAttributesReviewedOp,

  // Remove attribute actions.
  setRemoveAttributeModal,
  removeAttribute,
  clearRemoveAttributeOp,

  // Remove child dimension actions.
  setRemoveChildDimensionModal,
  removeChildDimension,
  clearRemoveChildDimensionOp,

  // Confirm Edit or Remove Modal
  setConfirmRemoveOrEditAttributeModal,
  setTotalSelectedInformationForAttributeModal,

  setDefaultMetaData,

  //CLM
  getScoringSystem,
  finishGetScoringSystem,
  setCenterAuditAttributes,
  setCenterAuditAttributeModal,
  clearCenterAuditAttributeOp,
  onSetAttributeCheckBox,
  saveCenterAuditAttributes,
  finishSaveCenterAuditAttributes,
  setAttributeIdForCenterCenterAuditAttributes,
} = facilityAttributesSlice.actions;

export interface IAddParentItem {
  id: number,
  type: string,
  subType?: string,
  text: string,
}