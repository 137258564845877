import { authGetJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IPerspective } from "types/auditMasterDataTypes";
import { formatGetPerspectivesResponse } from "./formatters/perspectiveFormatters";

class PerspectiveApi {
  public async getPerspectives(includeDeleted?: boolean,
    abortSignal?: AbortSignal): Promise<IPerspective[]> {
    let url = `${config.endpoints.baseUrl}/perspectives`;

    if (includeDeleted) {
      url += `?includeDeleted=true`;
    }

    const response = await authGetJson(url, abortSignal);

    await throwIfResponseError(response);

    return formatGetPerspectivesResponse(await response.json());
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new PerspectiveApi();
