import { authGetJson } from "auth/authFetches";
import config from "config";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { formatAzureADUser } from "./formatters/userFormatter";

class UsersApi {
  public async searchAzureADUsers(searchValue?: string, abortSignal?: AbortSignal): Promise<IAzureADUser[]> {
    const response = await authGetJson(
      `${config.endpoints.users.searchAzureAD}?searchTerm=${searchValue || ""}`, abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatAzureADUser(obj));
  }

  public async getIntervieweeSuggestions(): Promise<IAzureADUser[]> {
    const response = await authGetJson(config.endpoints.auditing.getSuggestedInterviewees);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatAzureADUser(obj));
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new UsersApi();
