import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import { closeDeleteAttachmentModal, deleteAttachment } from "store/audit-evidences/AuditEvidencesSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const ConfirmDeleteAttachmentModal: React.FC = () => {
  const itemToDelete = useAppSelector(store => store.auditEvidences.itemToDelete);
  const deleteOp = useAppSelector(store => store.auditEvidences.deleteOp);

  const dispatch = useAppDispatch();
  return (
    <>
      <ConfirmModal
        header="Delete file"
        message="Are you sure you want to delete this file?"
        onYesClicked={() => dispatch(
          deleteAttachment({
            auditId: Number(itemToDelete?.auditId),
            id: Number(itemToDelete?.id),
            parentId: Number(itemToDelete?.parentId)
          }))}
        onNoClicked={() => dispatch(closeDeleteAttachmentModal())}
        isWorking={deleteOp?.isWorking}
      />

      {deleteOp?.isWorking && (
        <ModalSpinner />
      )}
    </>
  );
};

export default ConfirmDeleteAttachmentModal;