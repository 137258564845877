import { IRenderCommandCellArgs } from "components/audits/planning/planning-grid/PlanningGrid";
import React from "react";
import { useDispatch } from "react-redux";
import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import removeIcon from "shared/media/dls/remove.svg";
import { setRemoveChildDimensionModal } from "store/facility-attributes/FacilityAttributesSlice";
import { IPlanProfile } from "../../facilityAttributeTypes";
import "./RemoveChildButton.scoped.scss";

interface IRemoveChildButtonProps {
  isDisabled?: boolean,
  cellArgs: IRenderCommandCellArgs,
  profile: IPlanProfile,
}

const RemoveChildButton: React.FC<IRemoveChildButtonProps> = ({
  isDisabled,
  cellArgs,
  profile,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <TooltipElement
        tooltipString="Delete all the requirements for this profile<br />to enable the delete button."
        isDisabled={isDisabled}
      >
        <img
          src={removeIcon}
          className={`icon-medium remove-child-button ${isDisabled ? "not-available" : ""}`}
          alt="Remove"
          onClick={isDisabled
            ? undefined
            : () => dispatch(setRemoveChildDimensionModal({
              isOpen: true,
              parentText: cellArgs.parentRow?.text || "unknown",
              profileId: profile.id,
              rowText: cellArgs.row.text,
            }))
          }
        />
      </TooltipElement>
    </>
  );
};

export default RemoveChildButton;