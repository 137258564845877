import { authGetJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IAuditPageAuth, IAuditPageAuthRequest, INonAuditPageAuthRequest } from "../../types/auditPageAuthTypes";
import { formatAuditPageAuthInfo } from "./formatters/auditPageAuthFormatters";

class AuditPageAuthApi {
  public async getAuditPageAuthInfo(auditPageAuthRequest: IAuditPageAuthRequest,
    abortSignal?: AbortSignal): Promise<IAuditPageAuth> {
    const response = await authGetJson(
      `${config.endpoints.auditing.audits}/${auditPageAuthRequest.auditId}/restriction/${auditPageAuthRequest.pageName}`,
      abortSignal);
    await throwIfResponseError(response);
    const result = await response.json();
    return formatAuditPageAuthInfo(result);
  }

  public async getNonAuditPageAuthInfo(auditPageAuthRequest: INonAuditPageAuthRequest,
    abortSignal?: AbortSignal): Promise<IAuditPageAuth> {
    let url = `${config.endpoints.auditing.audits}/restriction/admin/${auditPageAuthRequest.pageName}`;
    if (auditPageAuthRequest.objectType && auditPageAuthRequest.objectId) {
      url += `?objectId=${auditPageAuthRequest.objectId}&type=${auditPageAuthRequest.objectType}`;
    }
    const response = await authGetJson(url,
      abortSignal);
    await throwIfResponseError(response);
    const result = await response.json();
    return formatAuditPageAuthInfo(result);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditPageAuthApi();
