import { authGetJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { PlanningPeriodsType } from "types/adminPlanningAdministration";
import { IAuditPlanningConfig } from "types/auditPlanningTypes";
import { formatAuditPlanningConfig } from "./formatters/auditPlanningConfigFormatters";

class AuditPlanningConfigApi {
  public async getConfigs(searchValues: {
    type: PlanningPeriodsType,
    name: string,
  }[]): Promise<IAuditPlanningConfig[]> {
    let queryString = "?" + searchValues.map((item, ix) =>
      `items[${ix}].Type=${item.type}&items[${ix}].Name=${item.name}`)
      .join("&");

    const response = await authGetJson(config
      .endpoints
      .admin
      .planningAdministration
      .planningConfig
      + queryString);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatAuditPlanningConfig(obj));
  }

  public async updateConfig(item: IAuditPlanningConfig,
    abortSignal?: AbortSignal): Promise<IAuditPlanningConfig> {
    const response = await authPutJson(`${config.endpoints.admin.planningAdministration.planningConfig}/${item.type}/${item.name}`,
      item,
      abortSignal);

    await throwIfResponseError(response);

    return formatAuditPlanningConfig(await response.json());
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditPlanningConfigApi();
