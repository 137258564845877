import { all, call, put, TakeEffect, takeLatest } from "@redux-saga/core/effects";
import AuditsApi from "api/auditing/AuditsApi";
import { Action } from "redux";
import { race, take } from "redux-saga/effects";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { IMyAudit } from "types/auditingTypes";
import { IPagedResult } from "types/pageResultsTypes";
import { clearMyAudits, finishLoadingMyAudits, loadMyAudits } from "./MyAuditsSlice";

export function* myAuditsSagas() {
  yield all([
    loadMyAuditsAsync(),
  ]);
}

function* loadMyAuditsAsync() {
  yield takeLatest(loadMyAudits, function* (action: Action) {
    if (!loadMyAudits.match(action)) {
      return;
    }

    try {
      const result: {
        pagedAudits: IPagedResult<IMyAudit>,
        cancelled: TakeEffect,
      } = yield race({
        pagedAudits: call(AuditsApi.getMyAudits, action.payload.pageNumber),
        cancelled: take(clearMyAudits),
      });

      if (result.cancelled) {
        return;
      }

      yield put(finishLoadingMyAudits({
        isWorking: false,
        data: result.pagedAudits,
      }));
    } catch (err) {
      // Get error message.
      yield put(finishLoadingMyAudits({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  })
}