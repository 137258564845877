import React, { useCallback, useMemo } from "react";
import NonReduxPickerList from "shared/components/controls/pickers/non-redux-list-picker/NonReduxPickerList";
import { IPickerItem } from "shared/types/pickerTypes";
import { IOtherAssocItem, setAssignAuditorByAssociationsModal } from "store/audit-summary/AuditSummarySlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAuditInfo, IAuditQuestion } from "types/auditingTypes";
import { findGlobalAssocQuestionsInAudit } from "../AuditorAssociationModal";
import HasNoMetaMessage from "./HasNoMetaMessage";

const OtherAssociationsTabContent: React.FC = () => {
  const auditInfo = useAppSelector(store => store.audit.audit);
  const questionsInAudit = useAppSelector(store => store.audit.questions);
  const selectedOtherAssociations = useAppSelector(store => store.auditSummary.assignAuditorByAssociationsModal.selectedOtherAssociations);
  const dispatch = useAppDispatch();

  const items = useMemo(() => {
    if (!auditInfo) {
      return [];
    } else {
      return getOtherItemsList(auditInfo, questionsInAudit);
    }
  }, [auditInfo, questionsInAudit]);

  const selectedItems = useMemo(() => {
    return items
      .filter(x => selectedOtherAssociations
        .some(other => other.id === x.id
          && other.type === x.type));
  }, [items, selectedOtherAssociations]);

  const loadItems = useCallback(async () => items, [items]);

  const onApply = useCallback((items: IOtherAssocItem[]) => dispatch(setAssignAuditorByAssociationsModal({
    selectedOtherAssociations: items,
  })), [dispatch]);

  const pickerFilterItems = useCallback(<T,>(item: IPickerItem<T>, search: string) => {
    if (item?.item) {
      return ((item.item as unknown) as IOtherAssocItem)
        .text
        .toLowerCase()
        .includes(search.toLowerCase());
    }
    return false;
  }, []);

  if (!items.length) {
    return <HasNoMetaMessage />;
  }

  return (
    <NonReduxPickerList<IOtherAssocItem>
      selectedItems={selectedItems}
      renderListItem={item => item.text}
      onSelectionChanged={onApply}
      keyMapper={(item) => item.id}
      title="Other Associations"
      onLoadItems={loadItems}
      allowMultiSelect={true}
      tagsProps={[{
        label: "Selected Items",
        renderItem: (item) => item.item ? item.item.text : item.text,
      }]}
      showSuggestedItems={false}
      showSelectedItems={false}
      filterItems={pickerFilterItems}
      hideLabelAboveSearch={true}
    />
  );
};

export default OtherAssociationsTabContent;

function getOtherItemsList(audit: IAuditInfo,
  questionsInAudit: IAuditQuestion[]) {
  let otherItems: IOtherAssocItem[] = [];

  if (findGlobalAssocQuestionsInAudit(questionsInAudit, audit).length) {
    otherItems.push({
      id: 0,
      text: "Global",
      type: "Global",
    });
  }

  const availableCountries = audit.countries ?? [];

  // Add all the countries that have questions linked.
  otherItems.push(...availableCountries
    .filter(ac => questionsInAudit
      .some(q => q.countries
        .some(c => c.id === ac.id)))
    .map((country): IOtherAssocItem => ({
      id: country.id,
      text: country.name,
      type: "Country",
    })).sort((a, b) => a.text < b.text ? -1 : 1));

  return otherItems;
}