import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import UrlRoutes from "./UrlRoutes";

const NotFound: React.FC = () => {
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    const decrementSeconds = () => setSeconds(seconds - 1);
    if (seconds > 0) {
      window.setTimeout(decrementSeconds, 1000);
    }
  }, [seconds, setSeconds]);

  return (
    <div>
      <h2>
        Page Not Found
      </h2>
      <p>
        The page you requested was not found.
      </p>
      <p>
        You will be redirected to the main page in {seconds} seconds.
      </p>
      {seconds === 0 &&
        <Navigate
          to={UrlRoutes.MyAudits.urlTemplate}
          replace
        />
      }
    </div>
  );
}

export default NotFound;