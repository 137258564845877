import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { IHelpItem } from "types/auditMasterDataTypes";

export interface IHelpState {
  [key: string]: IOperation<IHelpItem> | undefined,
}

const initialState: IHelpState = {
};

export const helpSlice = createSlice({
  name: "help",
  initialState,
  reducers: {
    /** Starts loading a help item from the API. The payload is the SLUG of the help item. */
    loadHelpData: (_, __: PayloadAction<string>) => {},
    /** Sets a specific slug's help data in the state. */
    setHelpData: (state, action: PayloadAction<ISetHelpDataPayload>) => {
      state[action.payload.slug] = action.payload.data;
    },
  },
});

export const {
  loadHelpData,
  setHelpData,
} = helpSlice.actions;

export interface ISetHelpDataPayload {
  data?: IOperation<IHelpItem>,
  slug: string,
}