import React, { useEffect, useRef, useState } from "react";
import Animate, { Animations } from "shared/components/animations/Animate";
import useStateWithDelayedCallback from "shared/utilities/hooks/useStateWithDelayedCallback";
import { AuditStatuses, IAuditStep } from "types/auditingTypes";
import AuditStatusChip from "../../audit-status-chip/AuditStatusChip";
import AuditStepList from "../step-list/AuditStepList";
import "./AuditStepsPanel.scoped.scss";

interface IAuditStepsPanelProps {
  steps: IAuditStep[],
  auditStatus: AuditStatuses,
  onClose(): void,
}

const AuditStepsPanel: React.FC<IAuditStepsPanelProps> = ({
  steps,
  auditStatus,
  onClose,
}) => {
  const [titleBarHeight, setTitleBarHeight] = useState(-1);
  const panelRef = useRef<HTMLDivElement>(null);

  const [shouldBeVisible, setShouldBeVisible] = useStateWithDelayedCallback(true, false, 300, onClose);

  useEffect(() => {
    setTitleBarHeight(document.getElementsByClassName("title-bar")?.[0].clientHeight);
  }, [setTitleBarHeight]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (panelRef.current
        && !panelRef.current.contains(event.target)) {
        setShouldBeVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [panelRef, setShouldBeVisible]);

  if (titleBarHeight === -1) {
    return null;
  }

  const style = {
    top: titleBarHeight,
    height: `calc(100% - ${titleBarHeight}px)`,
  };

  return (
    <>
      <Animate
        animation={Animations.fade}
        shouldBeVisible={shouldBeVisible}
      >
        <div
          className="fade"
          style={style}
        >
        </div>
      </Animate>

      <Animate
        animation={Animations.slideRight}
        shouldBeVisible={shouldBeVisible}
      >
        <div
          className="audit-steps-panel"
          style={style}
          ref={panelRef}
        >
          <h3
            className="audit-steps-panel-title"
          >
            <span>
              Audit Work Progress
            </span>

            <AuditStatusChip
              status={auditStatus}
            />
          </h3>
          <AuditStepList
            steps={steps}
            auditStatus={auditStatus}
          />
        </div>
      </Animate>
    </>
  );
}

export default AuditStepsPanel;