import React from "react";
import CircleImageButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import deleteIcon from "shared/media/dls/delete.svg";
import { IColumnItem } from "shared/types/columnTypes";
import { IBulkFile } from "shared/types/bulkFileTypes";

export interface IFileManagerGridProps {
  /** The list of files to show up */
  files: IBulkFile[],
  /** If true, the DELETE FILE button is available, else disabled. */
  allowDelete: boolean,
  /** The function to call after the user successfully deletes an Element item. */
  onElementDeleted(key: string): void,
}

const FileManagerGrid: React.FC<IFileManagerGridProps> = ({
  files,
  allowDelete,
  onElementDeleted,
}) => {

  let displayComponent: React.ReactNode | undefined;

  const deleteElementItem = (key: string) => {
    onElementDeleted(key);
  };

  displayComponent = (
    <Table
      className="Element-table"
      columns={getElementTableColumn(
        deleteElementItem,
        allowDelete,
      )}
      data={files}
      keyProp="key"
      theme={TableThemes.compact}
      showHeader={false}
    />
  );
  return (
    displayComponent
  );
};

function getElementTableColumn(
  onDeleteElementItem: (key: string) => void,
  allowDelete: boolean,): IColumnItem<IBulkFile, keyof IBulkFile>[] {
  return [
    {
      key: "leftCommandCell",
      header: "",
      width: 50,
    },
    {
      key: "text",
      header: "",
      width: 300,
      customRender: item => (
        <div style={{
          display: "flex",
          flexDirection: "column",
        }}>
          <span title={item.name} className="filename">{item.name}</span>
        </div>
      ),
    },
    {
      key: "otherButtons",
      header: "",
      customRender: item => (
        <div style={{
          display: "flex",
          gap: "1em",
        }}>
          <CircleImageButton
            alt="Delete"
            icon={deleteIcon}
            type="secondary"
            iconSize="small"
            onClick={() => onDeleteElementItem(item.key)}
            tooltip={"Delete"}
          />
        </div>
      ),
    },
  ];
}


export default FileManagerGrid;