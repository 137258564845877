import React, { useRef } from "react";
import gripIcon from "shared/media/icons/vertical-grip.svg";
import { rearrangeItem } from "store/audit-topic-rearrange/AuditTopicRearrangeSlice";
import { useAppDispatch } from "store/store";
import { IAuditTopic } from "types/auditMasterDataTypes";
import "./AuditTopicItem.scoped.scss";

interface IAuditTopicItemProps {
  item: IAuditTopic,
  index: number,
}

const AuditTopicItem: React.FC<IAuditTopicItemProps> = ({
  item,
  index,
}: IAuditTopicItemProps) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const removeDragHoverStyle = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.classList.remove("drop-target-below", "drop-target-above");
  };

  return (
    <div
      ref={divRef}
      draggable
      className="audit-topic-item draggable"
      onDragStart={e => {
        e.dataTransfer.setData("index", index.toString());
      }}
      onDragOver={e => {
        e.dataTransfer.dropEffect = "move";
        e.preventDefault();

        const otherIndex = Number(e.dataTransfer.getData("index"));

        if (otherIndex > index) {
          e.currentTarget.classList.add("drop-target-above");
        } else if (otherIndex < index) {
          e.currentTarget.classList.add("drop-target-below");
        }
      }}
      onDragEnd={removeDragHoverStyle}
      onDragExit={removeDragHoverStyle}
      onDrop={e => {
        let newIndex = Number(e.dataTransfer.getData("index"));

        if (e.currentTarget.classList.contains("drop-target-above")) {
          newIndex = index;
        } else if (e.currentTarget.classList.contains("drop-target-below")) {
          newIndex = index + 1;
        }

        removeDragHoverStyle(e);
        const oldIndex = Number(e.dataTransfer.getData("index"));

        dispatch(rearrangeItem({
          oldIndex,
          newIndex,
        }));
      }}
      id={index.toString()}
    >
      <img src={gripIcon} alt="" />
      <span>
        {item.name}
      </span>
    </div>
  );
};

export default AuditTopicItem;