import { IPlanProfileAttribute } from "./facilityAttributeTypes";

export function isAttributeReviewed(attribute: IPlanProfileAttribute): boolean {
  const reviewedOn = attribute?.reviewedOn
    ? new Date(attribute.reviewedOn)
    : undefined;

  if (!reviewedOn) {
    return false;
  }

  // Review expires in 180 days.
  reviewedOn.setDate(reviewedOn.getDate() + 180);

  return new Date() <= reviewedOn;
}