import AuditTopicsRearrangePage from "components/admin/audit-topics/rearrange/AuditTopicsRearrangePage";
import AuditAdminPage from "components/admin/AuditAdminPage";
import FacilityAttributesAdminPage from "components/admin/planning/facility-attribute/FacilityAttributesAdminPage";
import PlanApprovalAdmin from "components/admin/planning/plan-approval/PlanApprovalAdminPage";
import ManageQuestionPage from "components/admin/questions/manage-question/ManageQuestionPage";
import QuestionLibraryPage from "components/admin/questions/QuestionLibraryPage";
import AuditTemplatesPage from "components/admin/templates/AuditTemplatesPage";
import UserManagementPage from "components/admin/users/management/UserManagementPage";
import React from "react";
import { Route } from "react-router-dom";
import { AuditPages, PageAuthRequestType } from "types/auditPageAuthTypes";
import AuditPageAuth from "../AuditPageAuth";
import NotImplemented from "../NotImplemented";
import UrlRoutes from "../UrlRoutes";
import AuditTopicsPage from "components/admin/audit-topics/manager/AuditTopicAdminPage";


import AuditorsAdminPage from "components/admin/auditors/manager/AuditorsAdminPage";

interface IAdminRoutesProps {
}

const AdminRoutes: React.FC<IAdminRoutesProps> = () =>
  <>
    <Route
      path={UrlRoutes.AuditAdmin.urlTemplate}
      element={<AuditAdminPage />}
    />

    <Route
      path={UrlRoutes.AuditAdmin.urlTemplate + "/*"}
      element={<NotImplemented />}
    />

    <Route
      path={UrlRoutes.AdminFacilityAttribute.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.AdminFacilityAttribute}
        >
          <FacilityAttributesAdminPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.AdminPlanApproval.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.AdminPlanApproval}
        >
          <PlanApprovalAdmin />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.QuestionLibrary.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.QuestionLibrary}
        >
          <QuestionLibraryPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.EditQuestion.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.EditQuestion}
          objectType={"Question"}
        >
          <ManageQuestionPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.NewQuestion.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.NewQuestion}
        >
          <ManageQuestionPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.AdminUserManagement.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.UserManagement}
        >
          <UserManagementPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.AdminAuditors.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.UserManagement}
        >
          <AuditorsAdminPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.AdminTopics.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.AuditTopicCreation}
        >
          <AuditTopicsPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.AdminTemplates.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.AuditTemplates}
        >
          <AuditTemplatesPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.AdminTopicsRearrange.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.AuditTopicRearrange}
        >
          <AuditTopicsRearrangePage />
        </AuditPageAuth>
      }
    />
  </>;

export default AdminRoutes;
