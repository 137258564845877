import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { IQuestionMetaDataAction, setQuestionMetaData } from "store/question-details/QuestionDetailsSlice";
import { MetaDataTypes } from "types/masterDataTypes";
import { IQuestionMetaDataItem } from "types/questionTypes";

export const itemToCodeName = (item: { code: string, name: string }) => `${item.code} - ${item.name}`;

export function onPickerApply(items: { id: number }[],
  questionMetaData: IQuestionMetaDataItem[] | undefined,
  metaType: MetaDataTypes,
  isAdditionalAssociation: boolean,
  dispatch: Dispatch<AnyAction>,
  extraMetaFilter?: (metaItem: IQuestionMetaDataItem) => boolean) {

  // Find the items already in the question that are not selected anymore.
  const removeMetaItemActions = (questionMetaData
    ?.filter(x => matchMetaItem(x, metaType, isAdditionalAssociation)
      && (!extraMetaFilter || extraMetaFilter(x))
      && !items.some(sel => sel.id === (x.masterDataItem as any).id)) || [])
    .map((item): IQuestionMetaDataAction => ({
      action: "remove",
      metaData: item,
    }));

  // Remove any items that are no longer selected.
  if (removeMetaItemActions.length) {
    dispatch(setQuestionMetaData(removeMetaItemActions));
  }

  const addMetaItemActions: IQuestionMetaDataAction[] = items.map(x => ({
    action: "add",
    metaData: {
      masterDataId: x.id,
      masterDataType: metaType,
      masterDataItem: x,
      isAdditionalAssociation,
    },
  }));

  // Add the selected items.
  if (addMetaItemActions.length) {
    dispatch(setQuestionMetaData(addMetaItemActions));
  }
};

export const onItemDeselected = (item: Object,
  id: number,
  type: MetaDataTypes,
  isAdditionalAssociation: boolean,
  dispatch: Dispatch<AnyAction>) => {
  dispatch(setQuestionMetaData([{
    action: "remove",
    metaData: {
      masterDataId: id,
      masterDataType: type,
      masterDataItem: item,
      isAdditionalAssociation,
    },
  }]));
};

export function getQuestionMetaItems<T>(questionMetaData: IQuestionMetaDataItem[] | undefined,
  metaType: MetaDataTypes,
  isAdditionalAssociation: boolean): T[] {
  return (questionMetaData
    ?.filter(x => matchMetaItem(x, metaType, isAdditionalAssociation)) || [])
    .map(x => x.masterDataItem as T);
}

export function matchMetaItem(item: IQuestionMetaDataItem,
  metaType: MetaDataTypes,
  isAdditionalAssociation: boolean) {
  return item.masterDataType === metaType
    && item.isAdditionalAssociation === isAdditionalAssociation;
}