import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "../../shared/types/operationTypes";
import { AuditPages, EditRestriction, IAuditPageAuth, IAuditPageAuthRequest, IAuditPageAuthState, ViewRestriction } from "../../types/auditPageAuthTypes";

const initialState: IAuditPageAuthState = {
  auditPageAuth: {
    auditId: 0,
    pageName: AuditPages.None,
    editRestriction: EditRestriction.EditNone,
    viewRestriction: ViewRestriction.ViewOwn
  },
  loadOperation: undefined,
  pageVisibleWhileLoading: false,
};

export const auditPageRestrictionSlice = createSlice({
  name: "auditPageRestriction",
  initialState,
  reducers: {
    loadPageAuth: (state, action: PayloadAction<IAuditPageAuthRequest>) => {
      state.pageVisibleWhileLoading = action.payload.pageVisibleWhileLoading;
      state.loadOperation = {
        isWorking: true,
      };
    },
    finishLoadPageAuth: (state, action: PayloadAction<IOperation<IAuditPageAuth>>) => {
      if (action.payload.errorMessage) {
        state.loadOperation = action.payload;
        return;
      }

      if (!action.payload.data) {
        state.loadOperation = undefined;
        return;
      }

      state.auditPageAuth = action.payload.data;
      state.loadOperation = undefined;
      state.pageVisibleWhileLoading = false;
    },
  },
});

export const {
  loadPageAuth,
  finishLoadPageAuth,
} = auditPageRestrictionSlice.actions;
