import { Action } from "@reduxjs/toolkit";
import UserViewApi from "api/auditPlanning/UserViewApi";
import { IUserPlanningViewUpsertRequestDto, IUserPlanningViewUpsertResponseDto } from "api/auditPlanning/types/UserPlanningViewDtos";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IUserPlanningView, IUserPlanningViewValue } from "types/audit-planning-shared/UserPlanningViews";
import { applyFilters, deletePlanningView, finishDeletePlanningView, finishLoadPlanningViewValues, finishLoadPlanningViews, finishSavePlanningView, loadPlanningViews, savePlanningView, setCurrentPlanningView } from "../AuditPlanningSlice";
import { setCurrentPlanningFiltersFromPlanningViewValues } from "../helpers/AuditPlanningLoadFiltersHelpers";
import { convertAppliedPlanningFiltersToSaveDtos } from "../helpers/AuditPlanningSaveFiltersHelper";
import { IAuditPlanningFilters } from "../reducers/planningFiltersReducers";

export default function* userPlanningViewsSagas() {
  yield all([
    savePlanningViewAsync(),
    setCurrentPlanningViewAsync(),
    deletePlanningViewAsync(),
    loadPlanningViewsAsync(),
  ]);
}

function* savePlanningViewAsync() {
  yield takeLatest(savePlanningView, function* (action: Action) {
    if (!savePlanningView.match(action)) {
      return;
    }

    try {
      const currentPlanningView: IUserPlanningView = yield select((store: RootState) => store.auditPlanning.currentPlanningView);

      if (!action.payload.viewName) {
        throw new Error("The name of planning view needs to be filled.");
      }

      const isUpdate = action.payload.saveType === "save"
        && currentPlanningView;

      const appliedFilters: IAuditPlanningFilters | undefined =
        yield select((store: RootState) => store.auditPlanning.appliedFilters);

      if (!appliedFilters) {
        throw new Error("Filters must be applied before they can be saved.");
      }

      let filtersToSave = convertAppliedPlanningFiltersToSaveDtos(appliedFilters);

      const request: IUserPlanningViewUpsertRequestDto = {
        id: isUpdate
          ? currentPlanningView.id
          : undefined,
        name: action.payload.viewName,
        deleted: false,
        values: filtersToSave,
      };

      const upsertPlanningViewResponseDto: IUserPlanningViewUpsertResponseDto = yield call(isUpdate
        ? UserViewApi.updatePlanningView
        : UserViewApi.savePlanningView,
        request);

      yield put(finishSavePlanningView({
        data: {
          id: upsertPlanningViewResponseDto.id,
          name: upsertPlanningViewResponseDto.name,
          deleted: upsertPlanningViewResponseDto.deleted,
        },
        isWorking: false,
      }));

      yield put(showSuccessToast("Planning view saved successfully."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishSavePlanningView({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  })
}

function* setCurrentPlanningViewAsync() {
  yield takeLatest(setCurrentPlanningView, function* (action: Action) {
    if (!setCurrentPlanningView.match(action)) {
      return;
    }

    try {
      if (!action.payload.planningView) {
        return;
      }

      const planningViewValues: IUserPlanningViewValue[] = yield call(UserViewApi.getPlanningViewValues,
        action.payload.planningView.id);

      // Set the planning values from the loaded view values.
      yield call(setCurrentPlanningFiltersFromPlanningViewValues, planningViewValues);

      // Dispatch an action to load all planning data.
      yield put(applyFilters());
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      yield put(finishLoadPlanningViewValues());
    }
  })
}

function* deletePlanningViewAsync() {
  yield takeLatest([deletePlanningView], function* (action) {
    if (!deletePlanningView.match(action)) {
      return;
    }

    try {
      yield call(UserViewApi.deletePlanningView,
        action.payload.userPlanningViewId);

      yield put(finishDeletePlanningView({
        data: action.payload.userPlanningViewId,
        isWorking: false,
      }));

      yield put(showSuccessToast("Planning view deleted successfully."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishDeletePlanningView({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* loadPlanningViewsAsync() {
  yield takeLatest(loadPlanningViews, function* (action: Action) {
    if (!loadPlanningViews.match(action)) {
      return;
    }

    try {
      const plannigViews: IUserPlanningView[] = yield call(UserViewApi.loadPlanningViews);

      yield put(finishLoadPlanningViews({
        data: plannigViews,
        isWorking: false,
      }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishLoadPlanningViews({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  })
}