import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Banner, { BannerType } from "../../shared/components/common/banner/Banner";
import Spinner from "../../shared/components/common/spinner/Spinner";
import Page from "../../shared/components/layout/page/Page";
import { finishLoadPageAuth, loadPageAuth } from "../../store/audit-page-auth/AuditPageAuthSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { AuditPages, EditRestriction, IAuditPageAuthRequest, PageAuthRequestType, ViewRestriction } from "../../types/auditPageAuthTypes";

interface IAuditPageAuthProps {
  /** Select with pages the component should navigate. */
  pageName: AuditPages,
  /** Which type of auth request will be sent. */
  type: PageAuthRequestType,
  /** AN optional props to send which Route should navigate to. */
  navigateTo?: string,
  /** An optional props to pass children. */
  children?: React.ReactNode,
  /** Optional. A custom node to show the user if they have view none permission. */
  customNoPermissionDisplay?: React.ReactNode,
  /** Optional. A type of the object that must be validated on the server. */
  objectType?: 'Question',
}

const AuditPageAuth: React.FC<IAuditPageAuthProps> = ({
  pageName,
  type,
  navigateTo,
  children,
  customNoPermissionDisplay,
  objectType,
}) => {
  const viewRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.viewRestriction);
  const loadOperation = useAppSelector(store => store.auditPageRestriction.loadOperation);
  const pageVisibleWhileLoading = useAppSelector(store => store.auditPageRestriction.pageVisibleWhileLoading);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);

  const dispatch = useAppDispatch();

  const { auditId, id } = useParams();

  useEffect(() => {
    const auditPageAuthRequest: IAuditPageAuthRequest = {
      auditId: auditId ? Number(auditId) : 0,
      pageName: pageName,
      pageVisibleWhileLoading: false,
      type,
      objectType,
      objectId: id ? Number(id) : undefined,
    };

    dispatch(loadPageAuth(auditPageAuthRequest));

    return () => {
      dispatch(finishLoadPageAuth({
        isWorking: false,
        data: {
          auditId: 0,
          pageName: AuditPages.None,
          editRestriction: EditRestriction.EditNone,
          viewRestriction: ViewRestriction.ViewOwn
        },
      }))
    };
  }, [dispatch, auditId, pageName, type, objectType, id]);

  const showSpinner = (!pageVisibleWhileLoading
    && loadOperation?.isWorking)
    || (
      !loadOperation?.errorMessage
      && auditPageAuth.pageName !== pageName);

  if (showSpinner) {
    return <Spinner />;
  } else if (loadOperation?.errorMessage) {
    return (
      <Page>
        <Banner type={BannerType.error}>
          Failed to load your permissions. {loadOperation?.errorMessage}
        </Banner>
      </Page>
    );
  } else if (viewRestriction === ViewRestriction.ViewNone) {
    return (
      <Page>
        {!!navigateTo
          ? <Navigate to={navigateTo?.replace(":auditId", auditId || "")} />
          : (
            <Banner type={BannerType.error}>
              You do not have permission to view this page.
              {
                !!customNoPermissionDisplay && (
                    <>
                      <br />
                      {customNoPermissionDisplay}
                    </>
                )
              }
            </Banner>
          )}
      </Page>
    );
  } else {
    return (
      <>
        {children}
        {pageVisibleWhileLoading
          && loadOperation?.isWorking
          && (
            <ModalSpinner />
          )}
      </>
    );
  }
};

export default AuditPageAuth;

