import { PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { DeepPartial, partialMerge } from "shared/utilities/partialUtilities";
import { ActionItemChangeEvent, ActionItemLinkTypes, IActionItem } from "types/actionItemTypes";
import { IActionItemListModal, IManageActionItemModal } from "../types/actionItemModalTypes";

export interface IAuditActionItemState {
  actionItems: IActionItem[],
  loadActionItemsOp?: IOperation<IActionItem[]>,
  actionItemListModal: IActionItemListModal,
  manageActionItemModal: IManageActionItemModal,
}

export const initialAuditActionItemsState: IAuditActionItemState = {
  actionItems: [],
  loadActionItemsOp: undefined,
  actionItemListModal: {
    isOpen: false,
    linkOptions: {
      linkId: 0,
      linkType: ActionItemLinkTypes.AuditQuestion,
      parentDisplay: "",
    },
  },
  manageActionItemModal: {
    isOpen: false,
  },
};

const actionItemReducers = {
  /** Loads all the action items related to the specified audit id and its questions. */
  loadActionItems: (state: IAuditActionItemState, _: PayloadAction<number>) => {
    state.loadActionItemsOp = {
      isWorking: true,
    };
  },

  /** If the operation has an error message, it is updated in the loadAuditOp.
   * Otherwise, the loadAuditOp is cleared and the payload audit info is put
   * into audit and a copy is put into originalAudit.
   */
  finishLoadActionItems: (state: IAuditActionItemState, action: PayloadAction<IOperation<IActionItem[]>>) => {
    if (action.payload.errorMessage
      || !action.payload.data) {
      state.loadActionItemsOp = action.payload;
      return;
    }

    state.loadActionItemsOp = undefined;
    state.actionItems = action.payload.data;
  },

  /** Sets the specified properties of an action item. */
  setActionItem: (state: IAuditActionItemState, action: PayloadAction<{
    actionItemId: number,
    actionItemData: DeepPartial<IActionItem>,
  }>) => {
    let actionItem = state.actionItems.find(x => x.id === action.payload.actionItemId);
    if (!actionItem) {
      return;
    }
    partialMerge(actionItem, action.payload.actionItemData);
  },

  /** Removes the list of action items from the state. */
  removeAllActionsByLink: (state: IAuditActionItemState, action: PayloadAction<{
    linkId: string,
    linkType: ActionItemLinkTypes,
  }>) => {
    state.actionItems = state
      .actionItems
      .filter(x => !x.links.some(l => l.linkId === action.payload.linkId
        && l.type === action.payload.linkType));
  },

  /** Sets the action item list modal. */
  setActionItemListModal: (state: IAuditActionItemState, action: PayloadAction<Partial<IActionItemListModal>>) => {
    Object.assign(state.actionItemListModal, action.payload);
  },

  /** Sets the manage action item modal. */
  setManageActionItemModal: (state: IAuditActionItemState, action: PayloadAction<Partial<IManageActionItemModal>>) => {
    state.manageActionItemModal = cloneDeep(initialAuditActionItemsState.manageActionItemModal);
    Object.assign(state.manageActionItemModal, action.payload);
  },

  /** Handles the action item changed event. */
  handleActionItemChanged: (state: IAuditActionItemState, action: PayloadAction<ActionItemChangeEvent>) => {
    const event = action.payload;
    if (event.type === "Created") {
      state.actionItems.push(cloneDeep(event.actionItem));
      if (state.manageActionItemModal) {
        state.manageActionItemModal.actionItemId = event.actionItem.id;
      }
    } else if (event.type === "Deleted") {
      state.actionItems = state.actionItems.filter(x => x.id !== event.actionItem.id);
    } else {
      const existingActionItemIndex = state.actionItems.findIndex(x => x.id === event.actionItem.id);

      if (existingActionItemIndex > -1) {
        // Remove the existing action item from the list so it can be re-added in the new form.
        state.actionItems.splice(existingActionItemIndex, 1, cloneDeep(event.actionItem));
      } else {
        state.actionItems.push(cloneDeep(event.actionItem));
      }
    }
  },
};

export default actionItemReducers;