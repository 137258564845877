import ActionItemsListModal from "components/actions/list/ActionItemsListModal";
import ManageActionItemModal, { createActionItemLinks } from "components/actions/manage/ManageActionItemModal";
import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { findActionItemsByParentItems } from "shared/utilities/actionItemUtilities";
import { findQuestionsAssignedToFinding } from "shared/utilities/findingUtilities";
import { handleActionItemChanged, setActionItemListModal, setManageActionItemModal } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes } from "types/actionItemTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IAuditQuestion } from "types/auditingTypes";

const ActionItemListModalHelper: React.FC = () => {
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const actionItemListModal = useAppSelector(store => store.audit.actionItemListModal);
  const auditors = useAppSelector(store => store.audit.audit?.auditors);
  const questions = useAppSelector(store => store.audit.questions);
  const findings = useAppSelector(store => store.audit.findings);
  const manageActionItemModal = useAppSelector(store => store.audit.manageActionItemModal);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const userEmail = useAppSelector(store => store.auth.currentUser.email);
  const audit = useAppSelector(store => store.audit.audit);

  const dispatch = useAppDispatch();

  const leadAuditor = auditors?.find(x => x.isLeader);
  const isEditable = auditPageAuth.editRestriction === EditRestriction.EditAll;

  let actionItemModalQuestions: IAuditQuestion[] = [];

  if (actionItemListModal.isOpen) {
    // Try to find the question this action belongs to.
    if (actionItemListModal.linkOptions.linkType === ActionItemLinkTypes.AuditQuestion) {
      actionItemModalQuestions = questions
        .filter(x => x.auditQuestionId === actionItemListModal.linkOptions.linkId);
    } else if (actionItemListModal.linkOptions.linkType === ActionItemLinkTypes.AuditFinding) {
      const finding = findings.find(x => x.id === actionItemListModal.linkOptions.linkId);

      if (finding) {
        actionItemModalQuestions = findQuestionsAssignedToFinding(finding, questions);
      }
    }
  }

  return (
    <>
      {
        actionItemListModal?.isOpen
        && actionItemModalQuestions.length > 0
        && (
          <ActionItemsListModal
            allowAdd={isEditable
              || (auditPageAuth.editRestriction === EditRestriction.EditOwn
                && actionItemModalQuestions[0]?.auditorEmail !== undefined
                && actionItemModalQuestions[0]?.auditorEmail.toLowerCase() === userEmail.toLowerCase())}
            onClose={() => dispatch(setActionItemListModal({
              isOpen: false,
              linkOptions: undefined,
            }))}
            actionItems={findActionItemsByParentItems(actionItems,
              [actionItemListModal.linkOptions])
            }
            assignedTo={actionItemModalQuestions[0]?.interviewees?.[0]}
            actionItemEditors={leadAuditor
              ? [leadAuditor]
              : []
            }
            actionItemLinks={
              createActionItemLinks(audit
                ? `${audit.recap}-${audit.name}`
                : "",
                [{
                  linkId: actionItemListModal.linkOptions.linkId,
                  linkType: actionItemListModal.linkOptions.linkType,
                  linkDisplay: actionItemListModal.linkOptions.parentDisplay,
                  auditId: audit && audit.id,
                }])
            }
            header={`${actionItemListModal.linkOptions.parentDisplay} Action Items`}
            onActionItemChanged={event => dispatch(handleActionItemChanged(event))}
            bottomNode={actionItemListModal.linkOptions.linkType === ActionItemLinkTypes.AuditQuestion
              && actionItemModalQuestions[0]?.recommendedRWP
              ? (
                <LabeledControl
                  label="Recommended RWP"
                  isLight={true}
                >
                  {actionItemModalQuestions[0].recommendedRWP}
                </LabeledControl>
              ) : undefined
            }
            auditStatus={audit?.status}
          />
        )
      }

      {
        manageActionItemModal.isOpen
        && (
          <ManageActionItemModal
            onCancel={() => dispatch(setManageActionItemModal({
              isOpen: false,
              actionItemId: undefined,
              auditId: undefined,
              linkOptions: undefined,
              actionToPerformOnLoad: undefined,
            }))}
            actionItemEditors={leadAuditor
              ? [leadAuditor]
              : []
            }
            actionItemLinks={
              actionItems.find(x => x.id === manageActionItemModal.actionItemId)?.links ||
              (createActionItemLinks((audit?.recap + "-" + audit?.name) || "",
                manageActionItemModal.linkOptions?.map(l => ({
                  linkId: l.linkId,
                  linkType: l.linkType,
                  linkDisplay: l.parentDisplay,
                  auditId: audit && audit.id
                })) || [],
                manageActionItemModal.auditId))
            }
            actionItem={manageActionItemModal.actionItemId
              ? actionItems.find(x => x.id === manageActionItemModal.actionItemId)
              : undefined
            }
            assignedTo={leadAuditor}
            onActionItemChanged={event => dispatch(handleActionItemChanged(event))}
            actionToPerformOnLoad={manageActionItemModal.actionToPerformOnLoad}
            auditStatus={audit?.status}
          />
        )
      }
    </>
  );
};

export default ActionItemListModalHelper;