import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { authSlice } from "shared/store/auth/AuthSlice";
import { calendarDatesSlice } from "shared/store/calendar-dates/CalendarDatesSlice";
import { toastSlice } from "shared/store/toast/ToastSlice";
import rootSaga from "store/RootSaga";
import { adminFacilityAttributesSlice, finishLoadPeriods } from "./admin/planning-administration/facility-attributes/AdminFacilityAttributesSlice";
import { bulkUploadQuestionsSlice } from "./admin/questions/bulk-upload-questions/BulkUploadQuestionsSlice";
import { auditEvidencesSlice } from "./audit-evidences/AuditEvidencesSlice";
import { auditExecutionSlice } from "./audit-execution/AuditExecutionSlice";
import { auditFindingSlice } from "./audit-finding/AuditFindingSlice";
import { auditResultNonConformanceSlice } from "./audit-non-conformances/AuditNonConformancesSlice";
import { auditPageRestrictionSlice } from "./audit-page-auth/AuditPageAuthSlice";
import { auditPlanApprovalsSlice } from "./audit-plan-approvals/AuditPlanApprovalsSlice";
import { auditPlanCalendarSlice, openEditItemModal } from "./audit-plan-calendar/AuditPlanCalendarSlice";
import { auditPlanResultsSlice, startCreateAudit } from "./audit-plan-results/AuditPlanResultsSlice";
import { auditPlanningSlice } from "./audit-planning-shared/AuditPlanningSlice";
import { auditReportSlice } from "./audit-report/AuditReportSlice";
import { attachmentModalSlice } from "./audit-report/attachment-modal/AttachmentModalSlice";
import { auditRWPSlice } from "./audit-rwp/AuditRWPSlice";
import { auditSummarySlice } from "./audit-summary/AuditSummarySlice";
import { auditTemplatesSlice } from "./audit-templates/AuditTemplatesSlice";
import { AuditTopicRearrangeSlice } from "./audit-topic-rearrange/AuditTopicRearrangeSlice";
import { auditSlice, finishLoadAllData } from "./audit/AuditSlice";
import { cardStatesSlice } from "./card-states/CardStatesSlice";
import { facilityAttributesSlice } from "./facility-attributes/FacilityAttributesSlice";
import { gridSlice } from "./grid/GridSlice";
import { helpSlice } from "./help/HelpSlice";
import mainMenuSlice from "./main-menu/MainMenuSlice";
import { manageActionItemSlice } from "./manage-action-item/ManageActionItemSlice";
import { manageAuditSlice } from "./manage-audit/ManageAuditSlice";
import { myActionsSlice } from "./my-actions/MyActionsSlice";
import { myAuditsSlice } from "./my-audits/MyAuditsSlice";
import { questionDetailsSlice } from "./question-details/QuestionDetailsSlice";
import { questionEvidenceSlice } from "./question-evidence/QuestionEvidenceSlice";
import { questionLibraryFiltersSlice } from "./question-library-filters/QuestionLibraryFiltersSlice";
import { customSearchSlice } from "./search/CustomSearchSlice";
import { searchActionsSlice } from "./search/actions/SearchActionsSlice";
import { searchAuditsSlice } from "./search/audits/SearchAuditsSlice";
import { userManagementSlice } from "./user-management/UserManagementSlice";
import { auditorSlice } from "./auditors/AuditorSlice";
import { auditTopicSlice } from "./audit-topics/AuditTopicSlice";

let sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    mainMenu: mainMenuSlice.reducer,
    audit: auditSlice.reducer,
    manageAudit: manageAuditSlice.reducer,
    auditSummary: auditSummarySlice.reducer,
    auditExecution: auditExecutionSlice.reducer,
    auditReportAttachmentModal: attachmentModalSlice.reducer,
    questionEvidence: questionEvidenceSlice.reducer,
    myAudits: myAuditsSlice.reducer,
    auditEvidences: auditEvidencesSlice.reducer,
    manageActionItem: manageActionItemSlice.reducer,
    auditRWP: auditRWPSlice.reducer,
    auditNonConformance: auditResultNonConformanceSlice.reducer,
    auth: authSlice.reducer,
    auditReport: auditReportSlice.reducer,
    toast: toastSlice.reducer,
    auditPlanApprovals: auditPlanApprovalsSlice.reducer,
    auditPlanResults: auditPlanResultsSlice.reducer,
    calendarDates: calendarDatesSlice.reducer,
    auditPageRestriction: auditPageRestrictionSlice.reducer,
    cardStates: cardStatesSlice.reducer,
    myActions: myActionsSlice.reducer,
    help: helpSlice.reducer,
    searchAudits: searchAuditsSlice.reducer,
    searchActions: searchActionsSlice.reducer,
    facilityAttributes: facilityAttributesSlice.reducer,
    auditPlanCalendar: auditPlanCalendarSlice.reducer,
    customSearch: customSearchSlice.reducer,
    adminFacilityAttributes: adminFacilityAttributesSlice.reducer,
    questionDetails: questionDetailsSlice.reducer,
    userManagement: userManagementSlice.reducer,
    auditTemplates: auditTemplatesSlice.reducer,
    bulkUploadQuestions: bulkUploadQuestionsSlice.reducer,
    auditTopicRearrange: AuditTopicRearrangeSlice.reducer,
    auditTopics: auditTopicSlice.reducer,
    questionLibraryFilters: questionLibraryFiltersSlice.reducer,
    auditPlanning: auditPlanningSlice.reducer,
    grid: gridSlice.reducer,
    auditFinding: auditFindingSlice.reducer,
    auditTopicManagement: auditTopicSlice.reducer,
    auditors: auditorSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          openEditItemModal.type,
          finishLoadPeriods.type,
          startCreateAudit.type,
          finishLoadAllData.type,
        ],
        ignoredPaths: [
          "auditPlanCalendar.editItemModal.item.startTime",
          "auditPlanCalendar.editItemModal.item.endTime",
          "auditPlanCalendar.selectedPlans",
          "adminFacilityAttributes.periods",
          "adminFacilityAttributes.originalPeriods",
          "audit.audit",
          "audit.originalAudit",
        ],
      },
      thunk: false,
    }),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
