import React, { useRef, useState } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { validateFileSize } from "shared/utilities/fileUtilities";
import { getTextboxMaxLengthHint, tooltipMessage } from 'shared/utilities/stringUtilities';
import { useAppDispatch } from "store/store";
import IEvidenceItem from "../IEvidenceItem";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { linkFormatter } from "shared/utilities/urlUtilities";
import HelpButton from "shared/components/common/help-button/HelpButton";
import "./ManageEvidenceItemModal.scoped.scss";

const DEFAULT_ALLOWED_FILE_TYPES = [
  ".pdf",
  ".doc",
  ".docx",
  ".docm",
  ".odt",
  ".rtf",
  ".csv",
  ".ods",
  ".xls",
  ".xlsx",
  ".xlsb",
  ".xlsm",
  ".odp",
  ".ppt",
  ".pptx",
  ".pptm",
  ".potm",
  ".ppsx",
  ".jpeg",
  ".jpg",
  ".jfif",
  ".html",
  ".htm",
  ".png",
  ".bmp",
  ".tiff",
  ".mpeg",
  ".mpf",
  ".heic",
  ".mov",
  ".mpeg4",
  ".mp4",
  ".avi",
  ".wmv",
  ".heif",
  ".zip",
  ".oft",
  ".msg",
  ".eml",
  ".txt",
];

export interface IManageEvidenceModalProps {
  /** The list of filesnames that already exist. Logic will prevent selecting another file with an existing name. */
  existingEvidenceFilenames: string[],
  /** The item being managed. If undefined, a new item will be created instead of updating an existing one. */
  managedEvidence: IEvidenceItem | undefined,
  /** Function to call when the user exits without saving. */
  onClose(): void,
  /** Function to call after the user successfully saves an existing or new evidence item. */
  onItemSaved(item: IEvidenceItem): void,
  /** The function to call when the user attempts to save an existing (id != 0) or new (id == 0) evidence item. Returns the id of the saved evidence item. */
  saveItem(item: IEvidenceItem, content?: File | string): Promise<number>,
  /** The max size in MB the file can be. Defaults to 50. */
  maxFileSizeMB?: number,
  /** The file extensions the user is allowed to select. Defaults to the standard file type list. */
  allowedFileTypes?: string[],
}

const ManageEvidenceItemModal: React.FC<IManageEvidenceModalProps> = ({
  existingEvidenceFilenames,
  managedEvidence,
  saveItem,
  onItemSaved,
  onClose,
  maxFileSizeMB = 50,
  allowedFileTypes = DEFAULT_ALLOWED_FILE_TYPES,
}) => {
  const dispatch = useAppDispatch();
  const [desc, setDesc] = useState(managedEvidence?.notes ?? "");
  const [fileError, setFileError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const fileRef = useRef<HTMLInputElement>(null);
  const [link, setLink] = useState(managedEvidence?.link ?? "");
  const [radioType, setRadioType] = useState<"Evidence" | "Link">(managedEvidence?.type ?? "Evidence");
  const isCreate: boolean = managedEvidence === undefined;

  const isSaveDisabled = !!fileError
    || isSaving
    || (radioType === "Link"
      && desc === managedEvidence?.notes
      && link === managedEvidence?.link)
    || (radioType === "Evidence"
      && desc === managedEvidence?.notes)
    || !desc.trim();

  const onSaveClicked = async () => {
    let errorMsgs: string[] = [];

    if (!managedEvidence
      && (!fileRef.current?.files?.length && radioType === "Evidence")) {
      errorMsgs.push("File is required.");
    } else if (!desc.trim().length) {
      errorMsgs.push("Description is required.");
    } else if (!link.length && radioType === "Link") {
      errorMsgs.push("Link is required.");
    }

    if (errorMsgs.length) {
      dispatch(showErrorToast(errorMsgs.join(" ")));
      return;
    }

    setIsSaving(true);

    if (radioType === "Evidence") {
      const selFiles = fileRef.current
        && fileRef.current.files
        ? fileRef.current.files
        : undefined;

      let currFilename = "";

      // Try to save this evidence item using the provided save function.
      try {
        // If there are multiple files, save each independently.
        // Otherwise, if there are NO files, that means the user is only updating the existing
        // item's description. In that case, run the loop only once.
        for (let i = 0; i < (selFiles?.length ?? 1); i++) {
          // If there are selected files, get this one. Otherwise, undefined.
          let selFile = selFiles
            ? selFiles[i]
            : undefined;

          // Save the item to the server. If this is an existing item, use the existing filename.
          currFilename = managedEvidence?.filename ?? selFile?.name ?? "";
          const itemToSave: IEvidenceItem = {
            id: managedEvidence?.id ?? 0,
            filename: currFilename,
            notes: desc,
            type: "Evidence"
          };

          const newId = await saveItem(itemToSave, selFile);

          dispatch(showSuccessToast(`Evidence '${currFilename}' ${managedEvidence?.id ? "updated" : "created"} successfully.`));

          onItemSaved({
            ...itemToSave,
            id: newId,
          });
        }

        onClose();
      } catch (err) {
        dispatch(showErrorToast(`Evidence '${currFilename}' failed to save: ${getResponseErrorMessage(err)}`));
        setIsSaving(false);
      }
    } else {
      const itemToSave: IEvidenceItem = {
        id: managedEvidence?.id ?? 0,
        link: link,
        notes: desc,
        type: "Link",
      };

      try {
        const newId = await saveItem(itemToSave);

        dispatch(showSuccessToast(`Link ${managedEvidence?.id ? "updated" : "created"} successfully.`));

        onItemSaved({
          ...itemToSave,
          id: newId,
        });

        onClose();
      } catch (err: any) {
        dispatch(showErrorToast(err.message.toString()));
      }
    }

    setIsSaving(false);
  };

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files?.length) {
      return;
    }

    let filenames = existingEvidenceFilenames.map(x => x.toLowerCase().trim());

    const fileErrors: string[] = validateSelectedEvidenceFiles(e.currentTarget.files,
      filenames,
      allowedFileTypes,
      maxFileSizeMB);

    setFileError(fileErrors.length
      ? fileErrors.join(" ")
      : "");
  }

  return (
    <Modal
      header={isCreate
        ? "Upload New Evidence"
        : "Update Evidence"
      }
      isOpen={true}
      showCloseButton={true}
      onCloseButtonClicked={onClose}
      buttons={[{
        className: "secondary",
        text: "Cancel",
        key: "CANCEL",
        onClick: onClose,
        disabled: isSaving,
      }, {
        className: "primary",
        text: managedEvidence
          ? "Save"
          : "Upload",
        key: "SAVE",
        title: tooltipMessage(isSaveDisabled, "Not all necessary fields have been completed."),
        onClick: onSaveClicked,
        disabled: isSaveDisabled,
      }]}
    >
      <FlexRow>
        <LabeledControl
          label={`Evidence Type`}
          isRequired={true}
        >
          <div className='evidence-input-types'>
            <div className="label-file-evidence">
              <input
                type="radio"
                checked={radioType === "Evidence"}
                onChange={() => setRadioType("Evidence")}
                disabled={!isCreate}
              />
              <label>File (Max File Size: {maxFileSizeMB} MB)</label>
              <div className="hint">
                <HelpButton
                  helpSlug="fileUploadTypes"
                />
              </div>
            </div>
            <div className="label-link-evidence">
              <input
                type="radio"
                checked={radioType === "Link"}
                onChange={() => setRadioType("Link")}
                disabled={!isCreate}
              />
              <label>Link</label>
            </div>
          </div>
        </LabeledControl>
      </FlexRow>
      {radioType === "Evidence"
        && <FlexRow className="evidence-upload">
          {!managedEvidence && (
            <label>
              <input
                type="file"
                accept={allowedFileTypes.join(",")}
                onChange={onFileInputChange}
                ref={fileRef}
                className={`evidence-upload ${fileError ? "error" : ""}`}
                multiple
                height={2}
              />
              {!!fileError &&
                <span
                  className="too-large"
                >
                  {fileError}
                </span>
              }
            </label>
          )}

          {managedEvidence && (
            <span className="evidence-file-or-link">
              {managedEvidence?.filename}
            </span>
          )}
        </FlexRow>}
      {radioType === "Link"
        && <FlexRow className="textarea-row description">
          <textarea
            value={link}
            onChange={e => setLink(e.currentTarget.value)}
            onBlur={() => setLink(linkFormatter(link))}
            placeholder="Type the link..."
            maxLength={1000}
            className="evidence-input"
          />
        </FlexRow>
      }

      <FlexRow className="description-row description">
        <LabeledControl
          label="Description"
          isRequired={true}
          hint={getTextboxMaxLengthHint(1000)}
        >
          <div className="description">
            <textarea
              value={desc}
              onChange={e => setDesc(e.currentTarget.value)}
              placeholder="Type a title or description..."
              maxLength={1000}
            />
          </div>
        </LabeledControl>
      </FlexRow>
      {isSaving && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default ManageEvidenceItemModal;

export function validateSelectedEvidenceFiles(files: FileList, filenames: string[], allowedFileTypes: string[], maxFileSizeMB: number): string[] {
  let fileErrors: string[] = [];

  for (let i = 0; i < files.length; i++) {
    const file = files.item(i);

    if (!file) {
      continue;
    }

    if (!validateFileSize(file, maxFileSizeMB)) {
      fileErrors.push(`The selected file '${file.name}' is too large. Please select a file that is <= ${maxFileSizeMB} MB.`);
    } else if (filenames.some(oldFn => oldFn === file.name.toLowerCase().trim())) {
      fileErrors.push(`The selected file '${file.name}' has a filename that is already attached to this question. Please choose a different file.`);
    } else if (!allowedFileTypes.some(allowedFT => file.name.toLowerCase().trim().endsWith(allowedFT))) {
      fileErrors.push(`The selected file '${file.name}' has a type that is not allowed. Please choose a different file.`);
    }

    // Save this filename into the array to check the other selected files against it.
    filenames.push(file.name.toLowerCase().trim());
  }

  return fileErrors;
}
