import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import QISelect from "shared/components/controls/select/QISelect";
import { setSearchPropValue } from "store/search/audits/SearchAuditsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ComplianceResults as ComplianceResultTypes } from "types/auditPlanningTypes";

interface IComplianceResultsProps {
  filterTarget: "Completion" | "Closure",
}

const ComplianceResults: React.FC<IComplianceResultsProps> = ({ filterTarget }) => {
  const complianceResults = useAppSelector(store => store.searchAudits[`complianceAt${filterTarget}`]);
  const dispatch = useAppDispatch();

  return (
    <LabeledControl
      label={`Compliance Result @ ${filterTarget}`}
      className="compliance-filter"
    >
      <QISelect
        items={Object.keys(ComplianceResultTypes).map(x => ({
          id: x,
          text: x,
        }))}
        selectedItemIds={complianceResults}
        onChange={items =>
          dispatch(setSearchPropValue({
            [`complianceAt${filterTarget}`]: items.map(x => ComplianceResultTypes[x.id as keyof typeof ComplianceResultTypes]),
          }))
        }
        selectMode="multi"
      />
    </LabeledControl>
  );
};

export default ComplianceResults;