import React, { useState } from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditorPicker from "shared/components/controls/pickers/AuditorPicker";
import WeekPickerModal from "shared/components/controls/week-picker/WeekPickerModal";
import FlexRow from "shared/components/layout/flex/FlexRow";
import formatDate from "shared/utilities/dateFormatters";
import { setEditPlanApprovalItemLeadAuditor, setEditPlanApprovalItemWeekNumber, } from "store/audit-plan-approvals/AuditPlanApprovalsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditorSearchTypes } from "types/auditingTypes";
import "./AuditPlansEditTable.scoped.scss";

const AuditPlansEditTable: React.FC = () => {
  const items = useAppSelector(store => store.auditPlanApprovals.planApprovalEditModal.items);
  const auditType = useAppSelector(store => store.auditPlanning.appliedFilters?.auditType);
  const [currWeekPlanId, setCurrWeekPlanId] = useState<number | undefined>(undefined);
  const [isWeekPickerVisible, setIsWeekPickerVisible] = useState(false);
  const dispatch = useAppDispatch();

  const currWeekPlan = items.find(x => x.plan.id === currWeekPlanId);

  return (
    <>
      {items.map(item => (
        <FlexRow
          key={item.plan.id}
        >
          <LabeledControl
            label="Audit Week"
          >
            <button
              className="secondary"
              onClick={() => {
                setCurrWeekPlanId(item.plan.id);
                setIsWeekPickerVisible(true);
              }}
            >
              {item.weekNumber
                ? item.weekLabel
                : "--"
              }
            </button>
          </LabeledControl>

          <LabeledControl
            label="Lead Auditor"
            className="lead-auditor"
          >
            <AuditorPicker
              auditType={auditType}
              auditorType={AuditorSearchTypes.LeadAuditor}
              onApply={values => dispatch(setEditPlanApprovalItemLeadAuditor({
                leader: values.length
                  ? values[0]
                  : undefined,
                planId: item.plan.id,
              }))}
              selectedItems={item.leadAuditor
                ? [item.leadAuditor]
                : []
              }
              allowMultiselect={false}
              showSuggestions
            />
          </LabeledControl>
        </FlexRow>
      ))}

      {
        isWeekPickerVisible
        && currWeekPlan && (
          <WeekPickerModal
            onCancel={() => setIsWeekPickerVisible(false)}
            selectedWeek={currWeekPlan.weekNumber}
            year={currWeekPlan.plan.planYear}
            onApply={week => {
              dispatch(setEditPlanApprovalItemWeekNumber({
                planId: currWeekPlan.plan.id,
                weekNumber: week?.week,
                weekLabel: week?.week ? `${week?.week} (${formatDate(new Date(week?.startDateTimestamp), undefined, undefined, undefined, true)} to ${formatDate(new Date(week.endDateTimestamp), undefined, undefined, undefined, true)})` : "---",
              }));
              setIsWeekPickerVisible(false);
            }}
          />
        )
      }
    </>
  );
};

export default AuditPlansEditTable;
