import React from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import { useAppSelector } from "store/store";

const PlanningSpinner: React.FC = () => {
  const isPlanningLoading = useAppSelector(store => store.auditPlanning.isPlanningLoading);

  if (!isPlanningLoading) {
    return null;
  }

  return (
    <ModalSpinner />
  );
};

export default PlanningSpinner;