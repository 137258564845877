import { formatGetPerspectivesResponse } from "api/masterdata/formatters/perspectiveFormatters";
import { formatAzureADUser } from "api/users/formatters/userFormatter";
import { put } from "redux-saga/effects";
import { isNotUndefined } from "shared/utilities/typeCheck";
import AuditPlanCalendarStatusFilterOptions from "types/audit-plan-calendar/AuditPlanCalendarStatusFilterOptions";
import { IUserPlanningViewValue } from "types/audit-planning-shared/UserPlanningViews";
import { IAuditType, IPerspective, IPerspectiveXAxes } from "types/auditMasterDataTypes";
import { ComplianceResults } from "types/auditPlanningTypes";
import { IBusinessTeam, IBusinessView, IFacility, MetaDataTypes } from "types/masterDataTypes";
import { setCurrentFilters } from "../AuditPlanningSlice";
import { IAuditPlanningFilters, PlanningPlanStatusFilterValues, PlanningReviewStatusFilterValues } from "../reducers/planningFiltersReducers";

export function* setCurrentPlanningFiltersFromPlanningViewValues(viewValues: IUserPlanningViewValue[]): any {
  let newFilters: IAuditPlanningFilters = {
    businessTeams: refObjToMasterData<IBusinessTeam>(viewValues, MetaDataTypes.BusinessTeam),
    businessViews: refObjToMasterData<IBusinessView>(viewValues, MetaDataTypes.BusinessView),
    facilities: refObjToMasterData<IFacility>(viewValues, MetaDataTypes.Facility),
    reviewStatus: undefined,
    reviewedBy: [],
    perspectives: [],
    perspectiveXAxes: [],
    includeDeletedAttributes: false,
    planStatus: itemValueToMasterData<PlanningPlanStatusFilterValues>(viewValues,
      "planStatuses")?.[0],
    planYear: viewValues
      .filter(x => !x.isMasterDataType
        && x.valueType === "planYear")
      .map(x => Number(x.value))
      ?.[0],
    auditType: refObjToMasterData<IAuditType>(viewValues, MetaDataTypes.AuditType)?.[0],
    leadAuditors: [],
    auditCalendarStatuses: itemValueToMasterData<AuditPlanCalendarStatusFilterOptions>(viewValues,
      "auditCalendarStatuses"),
    calendarAuditTypes: refObjToMasterData<IAuditType>(viewValues, MetaDataTypes.AuditType),
    complianceResults: itemValueToMasterData<ComplianceResults>(viewValues,
      "complianceResults"),
    unassigned: false,
  };

  newFilters.reviewStatus = viewValues.filter(x => !x.isMasterDataType
    && x.valueType === "reviewStatus")
    .map(x => x.value as PlanningReviewStatusFilterValues)?.[0];

  newFilters.leadAuditors = viewValues.filter(x => !x.isMasterDataType
    && x.valueType === "leadAuditors")
    .map(x => formatAzureADUser(x.value));

  newFilters.reviewedBy = viewValues.filter(x => !x.isMasterDataType
    && x.valueType === "reviewedBy")
    .map(x => formatAzureADUser(x.value));

  newFilters.perspectives = refObjToPerspectives(viewValues);
  newFilters.perspectiveXAxes = extractPerspectiveXAxesFromPerspectives(viewValues,
    newFilters.perspectives);

  newFilters.includeDeletedAttributes = viewValues
    .some(x => x.valueType === "includeDeletedAttributes"
      && Boolean(x.value));

  newFilters.unassigned = viewValues
    .some(x => x.valueType === "unassigned"
      && Boolean(x.value));

  yield put(setCurrentFilters(newFilters));
}

function refObjToMasterData<T>(viewValues: IUserPlanningViewValue[],
  metaType: string) {
  return viewValues.filter(x => x.isMasterDataType
    && x.valueType === metaType)
    .map(x => x.referencedObject as T);
}

function itemValueToMasterData<T>(viewValues: IUserPlanningViewValue[],
  valueType: string): T[] {
  return viewValues
    .filter(x => x.valueType === valueType
      && !x.isMasterDataType)
    .map(item => item.value as unknown as T);
}

function refObjToPerspectives(viewValues: IUserPlanningViewValue[]): IPerspective[] {
  return viewValues
    .filter(x => x.valueType === MetaDataTypes.Perspective)
    .map(x => formatGetPerspectivesResponse([x.referencedObject])[0] as IPerspective);
}

function extractPerspectiveXAxesFromPerspectives(viewValues: IUserPlanningViewValue[],
  perspectives: IPerspective[]): IPerspectiveXAxes[] {
  return viewValues
    .filter(x => x.valueType === MetaDataTypes.PerspectiveXAxes)
    .map(xAxesValue => perspectives
      .map(p => p.xAxes.find(x => x.id === Number(xAxesValue.value)))
      .find(isNotUndefined))
    .filter(isNotUndefined);
}