import AuditsApi from "api/auditing/AuditsApi";
import IModalEvidenceItem from "components/evidence/IEvidenceItem";
import EvidenceListModal from "components/evidence/list/EvidenceListModal";
import React, { useCallback } from "react";
import { AuditStatuses, IEvidenceLinkItem } from "types/auditingTypes";

interface IQuestionEvidenceModalProps {
  /** The text to show in the header of the modal. Defaults to "Evidence List". */
  header?: string,
  /** The Id of the parent audit. */
  auditId: number,
  /** The AuditQuestionId of the question inside the audit. */
  auditQuestionId: number,
  /** The current status of the audit. */
  auditStatus: AuditStatuses,
  /** The list of evidence to show in the modal. */
  evidenceItems: IEvidenceLinkItem[],
  /** If true, the ADD EVIDENCE button is available, else disabled. */
  allowAdd: boolean,
  /** The function to call to determine if a user should be able to edit or delete an evidence item. */
  isItemEditable(item: IEvidenceLinkItem): boolean,
  /** The function to call when the user closes the modal. */
  onClose(): void,
  /** The function to call after the user has deleted an item on the server. */
  onItemDeleted(item: IEvidenceLinkItem): void,
  /** The function to call after the user has updated an item on the server. */
  onItemUpdated(item: IEvidenceLinkItem): void,
  /** The function to call after the user has created an item on the server. */
  onItemCreated(item: IEvidenceLinkItem): void,
}

const QuestionEvidenceModal: React.FC<IQuestionEvidenceModalProps> = ({
  header,
  auditId,
  auditQuestionId,
  auditStatus,
  evidenceItems,
  allowAdd,
  isItemEditable,
  onClose,
  onItemDeleted,
  onItemUpdated,
  onItemCreated,
}) => {
  const modalItemToEvidenceItem = (item: IModalEvidenceItem): IEvidenceLinkItem => {
    return {
      auditStatus,
      description: item.notes,
      info: item.filename !== undefined ? item.filename : item.link!,
      id: item.id,
      type: item.type
    } as IEvidenceLinkItem;
  }

  const deleteItem = async (item: IModalEvidenceItem): Promise<void> => {
    const controller = new AbortController();

    if (item.type === "Evidence" && item.filename !== undefined) {
      await AuditsApi.deleteQuestionEvidence(auditId, auditQuestionId, item.id, controller.signal);
    } else {
      await AuditsApi.deleteQuestionLink(item.id, controller.signal);
    }

    onItemDeleted(modalItemToEvidenceItem(item));
  };

  const getActionItemEvidenceFileBlobUrl = async (item: IModalEvidenceItem): Promise<string> => {
    if (item.filename !== undefined) {
      return await AuditsApi.getQuestionEvidenceUrl(auditId, auditQuestionId, item.id);
    }

    throw new Error("Can't find the url.");
  };

  const saveEvidenceItem = async (item: IModalEvidenceItem, file?: File): Promise<number> => {
    if (item.id) {
      if (item.type === "Evidence") {
        await AuditsApi.updateQuestionEvidence(auditId, auditQuestionId, item.id, item.notes);

        onItemUpdated(modalItemToEvidenceItem(item));

        return item.id;
      } else {
        await AuditsApi.saveQuestionLink(item.id, auditId, auditQuestionId, item.notes, item.link!);

        onItemUpdated(modalItemToEvidenceItem(item));

        return item.id;
      }
    } else {
      if (item.type === "Evidence") {
        if (!file) {
          throw new Error("A file is required to upload evidence.");
        }

        const newEvidenceItem = await AuditsApi.uploadQuestionEvidence(auditId, auditQuestionId, item.notes, file);

        onItemCreated(newEvidenceItem);

        return newEvidenceItem.id;
      } else {
        const newEvidenceItem = await AuditsApi.saveQuestionLink(undefined, auditId, auditQuestionId, item.notes, item.link!);

        onItemCreated(newEvidenceItem);

        return newEvidenceItem.id;
      }
    }
  };

  const getEvidenceItems = useCallback(async () => {
    return evidenceItems.map((item): IModalEvidenceItem => ({
      filename: item.type === "Evidence" ? item.info : undefined,
      link: item.type === "Link" ? item.info : undefined,
      id: item.id,
      notes: item.description,
      type: item.type,
    }));
  }, [evidenceItems]);

  return (
    <EvidenceListModal
      header={header}
      allowAdd={allowAdd}
      allowDelete={allowAdd}
      allowEdit={allowAdd}
      onClose={onClose}
      deleteEvidenceItem={deleteItem}
      getActionItemEvidenceFileBlobUrl={getActionItemEvidenceFileBlobUrl}
      saveEvidenceItem={saveEvidenceItem}
      getEvidenceItems={getEvidenceItems}
      isItemEditable={modalItem => {
        const item = evidenceItems.find(x => x.id === modalItem.id);

        if (item) {
          return isItemEditable(item);
        } else {
          return false;
        }
      }}
    />
  );
};

export default QuestionEvidenceModal;