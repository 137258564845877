/**
 * If the valueToCheck is defined and not null, the funcToRun will accept it and return its result.
 * @param valueToCheck The value to check if it is null or undefined or not.
 * @param funcToRun This function will be called and the valueToCheck will be passed to it. The result of this function will be returned.
 * @returns If valueToCheck is undefined or null, undefined, otherwise, the funcToRun(valueToCheck) result will be returned.
 */
export function runWithParamIfDefined<T>(valueToCheck: any, funcToRun: (input: any) => T): T | undefined {
  if (valueToCheck !== undefined
    && valueToCheck !== null) {
    return funcToRun(valueToCheck);
  } else {
    return undefined;
  }
}