import { IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import { PlanningPeriodsType } from "./adminPlanningAdministration";
import { IAuditTopic } from "./auditMasterDataTypes";
import { AuditSources, AuditStatuses, ITemplate } from "./auditingTypes";
import { BusinessTeamType, IBusinessFunction, IBusinessView, MetaDataTypes } from "./masterDataTypes";

export interface IPlanHierarchyItem {
  id: number,
  type: BusinessTeamType,
  text: string,
  children: IPlanHierarchyItem[],
}

export interface IPlanParentDimension {
  /**
   * The Id number of the dimension.
   */
  id: number,
  /**
   * The type of the dimension.
   */
  type: MetaDataTypes,
  /**
   * The subtype of the dimension (if applicable).
   */
  subType?: string,
  /**
   * The text to display in the popup, i.e. the name of the item. (e.g. OFS Comodoro, RPE, SLB-QHSE-S010, etc.)
   */
  text: string,
  /**
   * If this parent plan dimension belongs inside a sub geo unit, this is its id.
   */
  subGeoUnitId?: number,
  /**
   * Plannable child-level dimensions of this parent.
   */
  children?: IPlanChildDimension[],
  /** A uniquely identifiable string. */
  uid?: string,
}

export interface IPlanChildDimension {
  /**
   * The Id number of the dimension.
   */
  id: number,
  /**
   * The type of the dimension.
   */
  type: MetaDataTypes,
  /**
   * The subtype of the dimension (if applicable).
   */
  subType?: string,
  /**
   * The text to display in the popup, i.e. the name of the item. (e.g. OFS Comodoro, RPE, SLB-QHSE-S010, etc.)
   */
  text: string,
  /** A uniquely identifiable string. */
  uid?: string,
}

export interface IPlanRequirementDimension {
  /**
   * The Id number of the dimension.
   */
  id: number,
  /**
   * The type of the dimension.
   */
  type: MetaDataTypes,
  /**
   * The text to display in the popup, i.e. the name of the item. (e.g. OFS Comodoro, RPE, SLB-QHSE-S010, etc.)
   */
  text: string,
  /**
   * If this requirement is an AuditTopic, this describes the topic (1) or subtopic (2) level.
   */
  auditTopicLevel?: number,
  /**
 * The master data item, Audit Topic or Audit Template
 */
  masterDataItem: IAuditTopic | ITemplate | undefined,
}

export interface ICreatePlanRequest {
  comment: string,
  planYear: number,
  auditTypeId: number,
  auditGroupId: number,
  parentDimensionType: MetaDataTypes,
  parentDimensionId: number,
  childDimensionType?: MetaDataTypes,
  childDimensionId?: number,
  requirementDimensionType: MetaDataTypes,
  requirementDimensionId: number,
}

export interface IAuditPlanView {
  id: number,
  parentDimensionType: MetaDataTypes,
  parentDimensionId: number,
  childDimensionType?: MetaDataTypes,
  childDimensionId?: number,
  planYear: number,
  requirementDimensionType: MetaDataTypes,
  requirementDimensionId: number,
  auditGroupId: number,
  auditTypeId: number,
  approvalStatus: AuditPlanApprovalStatuses,
  deleted: boolean,
  source?: string,
  comment?: string,
  weekOfYear?: number,
  leadAuditorName?: string,
  leadAuditorEmail?: string,
  modifiedOn: number,
  modifiedBy: string,
  modifiedByName?: string,

  auditSource?: AuditSources,
  legacyAuditNumber?: string,
  auditId?: number,
  auditName?: string,
  auditRecap?: string,
  auditStatus?: AuditStatuses,
  auditStartDate?: number,
  auditStartWeekNumber?: number,
  auditLeadAuditorName?: string,
  auditLeadAuditorEmail?: string,
  auditComplianceResult?: ComplianceResults,
  auditComplianceScore?: number,
  auditCompletionWeek?: number,
  auditCompletionDate?: number,
  auditClosureWeek?: number,
  auditClosureDate?: number,

  requiredCLMSelfAssessmentType?: ClmSelfAssessmentTypes,
  userSelectedCLMSelfAssessmentType?: ClmSelfAssessmentTypes,
}

export enum ClmSelfAssessmentTypes {
  Partial = "Partial",
  Full = "Full",
}

export interface ICLMPartialRequirementItemDto {
  topicId: number,
  topicName: string,
  subTopicId: number,
  subTopicName: string,
  isInScope: boolean,
  isOptedIn: boolean,
  clmDocRefDms?: string,
}

export enum AuditPlanApprovalStatuses {
  Recommended = "Recommended",
  FinalApproval = "FinalApproval",
}

export const AuditPlanResultsStatusFilters = { ...AuditPlanApprovalStatuses, ...AuditStatuses };
export type AuditPlanResultsStatusFiltersType = typeof AuditPlanResultsStatusFilters;

export interface IApprovePlanRequest {
  auditPlanId: number,
  leadAuditorEmail?: string,
  leadAuditorName?: string,
  weekNumber?: number,
}

export interface IAuditPlanScore {
  parentDimensionType: MetaDataTypes,
  parentDimensionId: number,
  childDimensionType?: MetaDataTypes,
  childDimensionId?: number,
  planYear: number,
  requirementDimensionType: MetaDataTypes,
  requirementDimensionId: number,
  auditGroupId: number,
  auditTypeId: number,
  complianceScore?: ComplianceResults,
  auditId?: number,
  auditDate?: number,
  isLegacyAudit?: boolean,
  legacyAuditReportNbr?: string,
}

export enum ComplianceResults {
  HighCompliance = "HighCompliance",
  FullCompliance = "FullCompliance",
  BasicCompliance = "BasicCompliance",
  NonCompliance = "NonCompliance",
  HighNonCompliance = "HighNonCompliance",
}

export interface IPlanXFiltersRequest {
  auditGroupId: number,
  ownerGroupId?: number,
}

export interface IPlanYFiltersRequest {
  perspectiveXAxisId: number,
  divisionIds?: number[],
  basinIds?: number[],
  facilityIds?: number[],
  includeEmptyBusinessTeams: boolean,
}

export interface IPlanFiltersRequest {
  planYear: number,
  auditGroupId: number,
  auditTypeId: number,
  ownerGroupId?: number,
  divisionIds?: number[],
  basinIds?: number[],
  facilityIds?: number[],
  leadAuditorEmails?: string[],
  approvalStatus?: AuditPlanApprovalStatuses[],
  auditStatuses?: AuditStatuses[],
  deletedPlans?: boolean,
}

export interface IPlanGridXAxesData {
  requirementItems: IPlanRequirementDimension[],
}

export interface IPlanGridYAxesData {
  hierarchyItems: IPlanHierarchyItem[],
  planItemHierarchy: IPlanParentDimension[],
}

export interface IDisableComponent {
  disabled: boolean,
}

export interface IAuditPlanningConfig {
  type: PlanningPeriodsType,
  name: string,
  value: string,
}

export interface IEditableGridRow {
  row: IPlanningItem,
  parentRow?: IPlanningItem,
  rowDepth: number,
}

export interface IPlanProfileAttribute {
  id: number,
  profileId: number,
  masterDataId: number,
  masterDataType: string,
  deleted: boolean,
  comment: string | undefined,
  reviewedByEmail: string | undefined,
  reviewedByName: string | undefined,
  reviewedOn: number | undefined,
  deletedByEmail: string | undefined,
  deletedByName: string | undefined,
  deletedOn: number | undefined,
}

export interface IRemovePlanResponse {
  id: number,
  approvalStatus: AuditPlanApprovalStatuses,
  deleted: boolean,
  comment: string,
  modifiedOn: number,
  modifiedBy: string,
  modifiedByName: string,
}

export interface ICreatePlanResponse {
  id: number,
  approvalStatus: AuditPlanApprovalStatuses,
  source: string,
  deleted: boolean,
  comment: string,
  modifiedOn: number,
  modifiedBy: string,
  modifiedByName: string,
}

export type IPlanProfileAttributeInfo = {
  extraBusinessViews: IBusinessView[],
  extraBusinessFunctions: IBusinessFunction[],
  extraSubTopics: IAuditTopic[],
  subTopics: IAuditTopic[],
  templates: ITemplate[],
} & IPlanProfileAttribute

export interface IPlanTemplateHierarchyInfo {
  planId: number,
  selectedProfileStandardIds: number[],
  extraBusinessViews: IBusinessView[],
  extraBusinessFunctions: IBusinessFunction[],
  extraSubTopics: IAuditTopic[],
}

export interface IPlanProfileAttributeInfoFilterRequest {
  includeBusinessViews: boolean,
  includeBusinessFunctions: boolean,
  includeSubTopics: boolean,
}

export interface IProfileParentChildMatrixItem {
  id: number,
  parentType: string,
  parentSubType?: string,
  childType: string,
  childSubType?: string,
}

export enum AuditScoringSystems {
  QMS = "QMS",
  HSE = "HSE",
  CLM = "CLM",
}

export interface ICenterAuditAttribute {
  profileId: number,
  profileStandardId: number,
  masterDataId: number,
  masterDataType: string,
  topicId: number,
  clmDocRefName: string,
  clmDocRefDms?: string,
  auditPackage: string,
  applicable: boolean,
  implemented: boolean,
  exempted: boolean,
  questExemption: string,
  comments: string,
}