import AuditCalendarPage from "components/audits/planning/audit-calendar/AuditCalendarPage";
import FacilityAttributesPage from "components/audits/planning/facility-attributes/FacilityAttributesPage";
import PlanApprovalsPage from "components/audits/planning/plan-approvals/PlanApprovalsPage";
import PlanResultsPage from "components/audits/planning/plan-results/PlanResultsPage";
import React from "react";
import { Route } from "react-router-dom";
import { AuditPages, PageAuthRequestType } from "types/auditPageAuthTypes";
import { IRouteRoleMap } from "types/authTypes";
import AuditPageAuth from "../AuditPageAuth";
import RequireAuth from "../RequireAuth";
import UrlRoutes from "../UrlRoutes";

interface IAuditPlanningProps {
  routeMatrix: IRouteRoleMap[],
}

const AuditPlanning: React.FC<IAuditPlanningProps> = ({
  routeMatrix,
}) =>
  <>
    <Route
      path={UrlRoutes.FacilityAttributes.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.FacilityAttributes}
        >
          <FacilityAttributesPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.PlanApprovals.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.nonAudit}
          pageName={AuditPages.PlanApprovalPage}
        >
          <PlanApprovalsPage />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.PlanVsResults.urlTemplate}
      element={
        <RequireAuth
          roles={routeMatrix.find(x => x.route === UrlRoutes.PlanVsResults.urlTemplate)?.requiredRoles}
        >
          <PlanResultsPage />
        </RequireAuth>
      }
    />

    <Route
      path={UrlRoutes.PlanCalendar.urlTemplate}
      element={
        <AuditPageAuth
          pageName={AuditPages.AuditCalendarPage}
          type={PageAuthRequestType.nonAudit}
        >
          <AuditCalendarPage />
        </AuditPageAuth>
      }
    />
  </>;

export default AuditPlanning;
