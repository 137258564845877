import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexRow from "shared/components/layout/flex/FlexRow";
import ApplyFiltersButton from "./ApplyFiltersButton";
import "./AuditPlanningFilters.scoped.scss";
import ClearFiltersButton from "./ClearFiltersButton";
import MoreFiltersButton from "./MoreFiltersButton";
import UserAccessFilter from "./UserAccessFilter";
import XAxisFilter from "./XAxisFilter";
import YAxisFilters from "./YAxisFilter";

export interface IAuditPlanningFiltersProps {
  showMoreFilters: boolean,
  showFacilities: boolean,
  showPlanStatus: boolean,
  showBusinessViews: boolean,
  showLeadAuditors: boolean,
  showReviewStatus: boolean,
  showReviewedBy: boolean,
  showIncludeDeletedAttributes: boolean,

  showCalendarAuditTypes: boolean,
  showAuditCalendarStatuses: boolean,
  showComplianceResults: boolean,
  showUnassigned: boolean,
}

const AuditPlanningFilters: React.FC<IAuditPlanningFiltersProps> = (props) => {
  return (
    <FlexRow
      className="filters"
    >
      <UserAccessFilter
        disabled={false}
      />

      <YAxisFilters
        disabled={false}
      />

      <XAxisFilter
        disabled={false}
      />

      <LabeledControl
        label="&nbsp;"
        className="buttons"
      >
        <ApplyFiltersButton />

        <ClearFiltersButton />

        <MoreFiltersButton
          {...props}
        />
      </LabeledControl>
    </FlexRow>
  );
};

export default AuditPlanningFilters;