import config from "config";
import { runWithParamIfDefined } from "shared/utilities/functionUtilities";
import { IAllAuditScoreResults, IAuditComplianceExtraData, IAuditScoreResult, IAuditScoreResults, ICLMAnswerPercentage } from "types/audit-scores/auditScoreTypes";
import { IGetAuditScoresOptions } from "../types/auditScoresApiTypes";
import { formatAuditStatus, formatComplianceResult } from "./auditFormatters";

export function formatGetAuditScoresResponse(response: any): IAllAuditScoreResults {
  if (!response) {
    return {};
  }

  return {
    currentScores: response.currentScores
      ? formatAuditScoreResults(response.currentScores)
      : undefined,
    completedScores: response.completedScores
      ? formatAuditScoreResults(response.completedScores)
      : undefined,
    closedScores: response.closedScores
      ? formatAuditScoreResults(response.closedScores)
      : undefined,
  };
}

export function formatGetAuditScoresOptionsToUrl(options: IGetAuditScoresOptions): string {
  let queryString = [
    `includeCurrentScores=${options.includeCurrentScore ?? false}`,
    `includeCompletedScore=${options.includeCompletedScore ?? false}`,
    `includeClosedScore=${options.includeClosedScore ?? false}`,
    `includeByAuditStatus=${options.includeByAuditStatus ?? false}`,
  ].join("&");

  return `${config.endpoints.baseUrl}/audits/${options.auditId}/scores?${queryString}`;
}

function formatAuditScoreResults(obj: any): IAuditScoreResults {
  return {
    auditStatus: formatAuditStatus(obj.auditStatus),
    overallCompliance: runWithParamIfDefined(obj.overallCompliance, formatComplianceResult),
    overallComplianceScore: runWithParamIfDefined(obj.overallComplianceScore, Number),
    overallNumQuestionsTotal: runWithParamIfDefined(obj.overallNumQuestionsTotal, Number),
    overallNumNonComplianceQuestions: runWithParamIfDefined(obj.overallNumNonComplianceQuestions, Number),
    overallCat1Score: runWithParamIfDefined(obj.overallCat1Score, Number),
    overallCat2Score: runWithParamIfDefined(obj.overallCat2Score, Number),
    overallIncludedQuestions: obj.overallIncludedQuestions?.toString(),
    overallExtraData: obj.overallExtraData,
    metaScores: Array.isArray(obj.metaScores)
      ? obj.metaScores.map(formatAuditScoreResult)
      : [],
  };
}

function formatAuditScoreResult(obj: any): IAuditScoreResult {
  return {
    parentDimensionType: obj.parentDimensionType?.toString(),
    parentDimensionId: Number(obj.parentDimensionId),
    childDimensionType: obj.childDimensionType?.toString(),
    childDimensionId: runWithParamIfDefined(obj.childDimensionId, Number),
    scoreByType: obj.scoreByType?.toString(),
    scoreById: Number(obj.scoreById),
    complianceResult: formatComplianceResult(obj.complianceResult),
    complianceScore: Number(obj.complianceScore),
    numQuestionsTotal: Number(obj.numQuestionsTotal),
    numNonComplianceQuestions: Number(obj.numNonComplianceQuestions),
    cat1Score: runWithParamIfDefined(obj.cat1Score, Number),
    cat2Score: runWithParamIfDefined(obj.cat2Score, Number),
    includedQuestions: obj.includedQuestions?.toString(),
    extraData: runWithParamIfDefined(obj.extraData, formatAuditComplianceExtraData),
    numLTOQuestions: obj.numLTOQuestions !== undefined
      && obj.numLTOQuestions !== null
      ? Number(obj.numLTOQuestions)
      : undefined,
    numLTONonCompliantQuestions: obj.numLTONonCompliantQuestions !== undefined
      && obj.numLTONonCompliantQuestions !== null
      ? Number(obj.numLTONonCompliantQuestions)
      : undefined,
    ltoComplianceScore: obj.ltoComplianceScore !== undefined
      && obj.ltoComplianceScore !== null
      ? Number(obj.ltoComplianceScore)
      : undefined,
  };
}

function formatAuditComplianceExtraData(obj: any): IAuditComplianceExtraData {
  return {
    clmAnswerPercentages: Array.isArray(obj.clmAnswerPercentages)
      ? obj.clmAnswerPercentages.map(formatCLMAnswerPercentage)
      : undefined,
  };
}

function formatCLMAnswerPercentage(obj: any): ICLMAnswerPercentage {
  return {
    answer: obj.answer.toString(),
    percentage: Number(obj.percentage),
  };
}