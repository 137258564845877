import ActionItemsApi from "api/actionItems/ActionItemsApi";
import React, { useState } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { useAppDispatch } from "store/store";
import { IActionItem, IActionItemLink, IActionItemUnlinkedEvent } from "types/actionItemTypes";
import "./UnlinkModal.scoped.scss";

interface IUnlinkModalProps {
  /** The ActionItem to unlink items from. */
  actionItem: IActionItem,
  /** If specified, these items will be auto-selected by default when the modal loads. */
  defaultSelectedLinks?: IActionItemLink[],
  /** The function to call when the user clicks the close button. */
  onClose(closeManagementModal: boolean): void,
  /** The function to call when the action item is successfully unlinked from one or more items. */
  onActionItemUnlinked(event: IActionItemUnlinkedEvent): void,
  /** The function to call when the action is deleted as a result of unlinking from all items. */
  onActionItemDeleted(actionItem: IActionItem): void,
}

const UnlinkModal: React.FC<IUnlinkModalProps> = ({
  actionItem,
  defaultSelectedLinks,
  onClose,
  onActionItemUnlinked,
  onActionItemDeleted,
}) => {
  const [isWorking, setIsWorking] = useState(false);
  const [selectedLinks, setSelectedLinks] = useState<IActionItemLink[]>(defaultSelectedLinks || []);

  const dispatch = useAppDispatch();

  const actionItemId = actionItem.id || 0;
  const actionItemLinks = actionItem.links;

  /** The modal is in "delete mode" when there are <= 1 links. */
  const isDeleteMode = actionItem.links.length <= 1;

  const header = isDeleteMode
    ? "Delete Action Item"
    : "Remove Action Item Links";

  const message = isDeleteMode
    ? "The action item is linked to the following items. Are you sure you want to delete this action item?"
    : "Select one or more links to remove from this action item. If all links are removed, the action item will be deleted.";


  const changeLinkSelection = (link: IActionItemLink) => {
    if (selectedLinks
      .find(x => x.linkId === link.linkId
        && x.type === link.type)) {
      setSelectedLinks(selectedLinks
        .filter(x => !(x.linkId === link.linkId
          && x.type === link.type)));
    } else {
      setSelectedLinks(selectedLinks.concat(link));
    }
  };

  const unlinkSelectedLinks = async () => {
    let successfullyUnlinked = true;
    let shouldDelete = false;
    let shouldClose = false;

    try {
      setIsWorking(true);

      const {
        historyItem,
        remainingLinkCount,
      } = await ActionItemsApi.unlinkActionItem(actionItemId, selectedLinks);

      onActionItemUnlinked({
        type: "Unlinked",
        newHistoryItem: historyItem,
        unlinkedFrom: selectedLinks,
      });

      dispatch(showSuccessToast("Action item unlinked successfully."));

      shouldDelete = remainingLinkCount === 0;

      if (!shouldDelete) {
        setIsWorking(false);
      }

      shouldClose = true;
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
      successfullyUnlinked = false;
      setIsWorking(false);
    }

    // If all links were selected, also delete.
    if (successfullyUnlinked
      && shouldDelete) {
      try {
        // Delete the action item in addition.
        await ActionItemsApi.deleteActionItem(actionItemId);

        setIsWorking(false);

        dispatch(showSuccessToast("Action item deleted successfully."));

        // Calling onActionItemDeleted will automatically close the parent modal
        // so no need to close the modal manually.
        onActionItemDeleted(actionItem);

        shouldClose = true;
      } catch (err) {
        dispatch(showErrorToast(getResponseErrorMessage(err)));
        setIsWorking(false);
      }
    }

    if (shouldClose) {
      onClose(true);
    }
  };

  return (
    <Modal
      header={header}
      isOpen={true}
      buttons={[{
        key: "CANCEL",
        text: "Cancel",
        className: "secondary",
        onClick: () => onClose(false),
      }, {
        key: "Submit",
        text: isDeleteMode
          ? "Delete"
          : "Remove Link(s)",
        className: "primary",
        disabled: !selectedLinks.length,
        onClick: unlinkSelectedLinks,
      }]}
      showCloseButton={true}
      onCloseButtonClicked={() => onClose(false)}
    >
      <FlexCol>
        <span>{message}</span>

        {actionItemLinks.map(link => (
          <label
            key={`${link.linkId}_${link.type}`}
            className="link"
          >
            {!isDeleteMode && (
              <input
                type="checkbox"
                checked={!!selectedLinks
                  .find(x => x.linkId === link.linkId
                    && x.type === link.type)
                }
                onChange={_ => changeLinkSelection(link)}
              />
            )}
            {link.display}
          </label>
        ))}
      </FlexCol>

      {isWorking && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default UnlinkModal;