import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, uniqBy } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { ActionItemChangeEvent, ActionItemRelations, ActionItemStatusFilters, IActionItem, IMyAction } from "types/actionItemTypes";
import { IPagedResult } from "types/pageResultsTypes";

export interface IMyActionsState {
  relationFilter: ActionItemRelations,
  statusFilter: ActionItemStatusFilters,
  loadOperation?: IOperation<IPagedResult<IMyAction>>,
  myActions: IMyAction[],
  nextPage: number,
  hasMore: boolean,

  loadActionItemOp?: IOperation<IActionItem>,
  inspectedActionItem?: IActionItem,
}

const initialState: IMyActionsState = {
  relationFilter: ActionItemRelations.All,
  statusFilter: ActionItemStatusFilters.Open,
  loadOperation: undefined,
  myActions: [],
  nextPage: 1,
  hasMore: false,

  loadActionItemOp: undefined,
  inspectedActionItem: undefined,
};

export const myActionsSlice = createSlice({
  name: "my-actions",
  initialState,
  reducers: {
    loadMyActions: (state, _: PayloadAction<{
      pageNumber: number,
    }>) => {
      state.loadOperation = {
        isWorking: true,
      };
    },
    finishLoadingMyActions: (state, action: PayloadAction<IOperation<IPagedResult<IMyAction>>>) => {
      if (action.payload.errorMessage
        || action.payload.data === undefined) {
        state.loadOperation = action.payload;
        return;
      }

      if (action.payload.data.currentPage === 1) {
        state.myActions = [];
        state.nextPage = 1;
      }

      const newActionItems = action.payload.data.results;
      state.myActions.push(...newActionItems);
      state.hasMore = newActionItems.length === action.payload.data.pageSize;
      state.nextPage = action.payload.data.currentPage + 1;
      state.myActions = uniqBy(state.myActions, x => x.id);

      state.loadOperation = undefined;
    },
    clearMyActions: (state) => {
      Object.assign(state, initialState);
    },
    setRelationFilter: (state, action: PayloadAction<ActionItemRelations>) => {
      state.relationFilter = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<ActionItemStatusFilters>) => {
      state.statusFilter = action.payload;
    },
    loadAndInspectActionItem: (state, _: PayloadAction<number>) => {
      state.loadActionItemOp = {
        isWorking: true,
      };
    },
    finishLoadingActionItem: (state, action: PayloadAction<IOperation<IActionItem>>) => {
      if (action.payload.errorMessage) {
        state.loadActionItemOp = undefined;
        state.inspectedActionItem = undefined;
        return;
      }

      state.loadActionItemOp = undefined;
      state.inspectedActionItem = action.payload.data;
    },
    closeActionItem: state => {
      state.inspectedActionItem = undefined;
    },
    handleActionItemChanged: (state, action: PayloadAction<ActionItemChangeEvent>) => {
      const {
        type,
        actionItem,
      } = action.payload;

      if (type === "Deleted") {
        state.myActions = state.myActions.filter(x => x.id !== actionItem.id);
        state.inspectedActionItem = undefined;
      } else {
        const ix = state.myActions.findIndex(x => x.id === actionItem.id);

        if (ix > -1) {
          state.myActions[ix].actionRequired = actionItem.actionRequired;
          state.myActions[ix].assignedToName = actionItem.assignedTo?.name || "--";
          state.myActions[ix].type = actionItem.type?.name || "--";
          state.myActions[ix].priority = actionItem.priority?.name || "--";
          state.myActions[ix].dueByTimestamp = actionItem.dueByTimestamp;
          state.myActions[ix].status = actionItem.status;
          state.myActions[ix].closureTimestamp = actionItem.closureTimestamp;
          state.myActions[ix].isValidated = actionItem.isValidated;
        }

        // Also copy the newly updated action item back into the inspected
        // action item so that the Action Item Modal functions properly.
        state.inspectedActionItem = cloneDeep(action.payload.actionItem);
      }
    },
  },
});

export const {
  loadMyActions,
  finishLoadingMyActions,
  clearMyActions,
  setRelationFilter,
  setStatusFilter,

  loadAndInspectActionItem,
  finishLoadingActionItem,
  closeActionItem,
  handleActionItemChanged,
} = myActionsSlice.actions;