import AdminPlanningPeriodsApi from "api/admin/planning-administration/AdminPlanningPeriodsApi";
import AuditPlanningConfigApi from "api/admin/planning-administration/AuditPlanningConfigApi";
import { formatDateFromAuditPlanningConfigItem } from "api/admin/planning-administration/formatters/auditPlanningConfigFormatters";
import AuditPageAuthApi from "api/auditPageAuth/AuditPageAuthApi";
import MasterDataApi from "api/masterdata/MasterDataApi";
import AuditPlanningApi from "api/planning/AuditPlanningApi";
import AuditPlanCalendarApi from "api/planning/calendar/AuditPlanCalendarApi";
import { IGetAuditPlanCalendarItemRequest } from "api/planning/calendar/auditPlanCalendarApiTypes";
import { IPlanProfile } from "components/audits/planning/facility-attributes/facilityAttributeTypes";
import { IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import { cloneDeep } from "lodash";
import { all, call, put, select } from "redux-saga/effects";
import { IAuditSchedulerItemData } from "shared/components/controls/scheduler/qiSchedulerTypes";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IActiveUserProfile, IAzureADUser } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { IAuditPlanCalendarState, finishLoadCalendarResources, setLoadDataOp } from "store/audit-plan-calendar/AuditPlanCalendarSlice";
import { RootState } from "store/store";
import { IAdminPlanningAdministrationPeriod, PlanningPeriodsType } from "types/adminPlanningAdministration";
import IAuditPlanCalendarBasinYAxisData from "types/audit-plan-calendar/IAuditPlanCalendarBasinYAxisData";
import IAuditPlanCalendarLeadAuditor from "types/audit-plan-calendar/IAuditPlanCalendarLeadAuditor";
import { IAuditType } from "types/auditMasterDataTypes";
import { AuditPages, EditRestriction, IAuditPageAuth, IAuditPageAuthRequest, PageAuthRequestType } from "types/auditPageAuthTypes";
import { AuditPlanApprovalStatuses, IAuditPlanScore, IAuditPlanView, IAuditPlanningConfig, IPlanFiltersRequest, IPlanGridXAxesData, IPlanGridYAxesData, IPlanProfileAttribute, IPlanXFiltersRequest, IPlanYFiltersRequest } from "types/auditPlanningTypes";
import { IFacility, MetaDataTypes } from "types/masterDataTypes";
import { finishLoadingAllPlanningData, setCurrentFilters, setIsPlanningLoading } from "../AuditPlanningSlice";
import { IAuditPlanningAppliedFilters, IAuditPlanningFilters, PlanningPlanStatusFilterValues } from "../reducers/planningFiltersReducers";
import { applyFilterToAttributes, buildYAxisData } from "./planningLoadHelpers";

/**
  * Loads all the planning data into Redux. Returns true if successful, else false.
  * @param loadMode If "fromCurrentFilters", it will use the currentFilters from the state to load the data
  * then copy the current filters into the applied filters. If "fromAppliedFilters", it will simply reload the data
  * by sending the applied filters to the server and not copying current filters into applied filters.
  * @returns The Id of the newly created question version.
  */
export function* loadAllPlanningData(loadMode: "fromCurrentFilters" | "fromAppliedFilters") {
  let hadError = false;
  try {
    // Get the current user and their profile.
    const currUser: IAzureADUser = yield select((store: RootState) => store.auth.currentUser);
    const activeUserProfile: IActiveUserProfile = yield select((store: RootState) => store.auth.activeUserProfile);

    // Show the loading spinner.
    yield put(setIsPlanningLoading(true));

    // Get the filters from the planning state.
    let filters: IAuditPlanningFilters
      | IAuditPlanningAppliedFilters = loadMode === "fromCurrentFilters"
        ? yield select((store: RootState) => store.auditPlanning.currentFilters)
        : yield select((store: RootState) => store.auditPlanning.appliedFilters);

    if (loadMode === "fromCurrentFilters") {
      // Ensure that there is an Audit Type selected -- if not, query and select one.
      const ensureAuditTypeResponse: {
        wasAlreadySet: boolean,
        auditType: IAuditType,
      } = yield call(ensureSelectedAuditType, filters);

      if (!ensureAuditTypeResponse.wasAlreadySet) {
        // Get the filters from the planning state again because ensureSelectedAuditType updated the filters.
        filters = yield select((store: RootState) =>
          store.auditPlanning.currentFilters);
      }
    }

    // At this point, it is assured that the filters contain an audit type due to the ensureSelectedAuditType.
    // It is now safe to cast the planningFilters into the appliedFilters.

    const {
      planningConfig,
      gridXAxes,
      gridYAxes,
      facilities,
      facAttrPageAuth,
      planApprovalsPageAuth,
    }: IPlanningDataBatch1 = yield call(getPlanningDataBatch1, filters);

    const {
      profiles,
      profileAttributes,
      planApprovalsPlans,
      planResultsPlans,
      planScores,
      facAttrPlanPeriods,
      planApprovalPlanPeriods,
    }: IPlanningDataBatch2 = yield call(getPlanningDataBatch2,
      filters,
      facAttrPageAuth,
      planApprovalsPageAuth,
      planningConfig);

    // Apply the filters to the loaded Audit Topics to figure out which attributes are
    // visible and which are not. The nonVisibleAttributes are used later to decide if
    // the RemoveChild button is enabled or not on the grid's row header.
    const {
      visibleAttributes,
      nonVisibleAttributes,
    } = applyFilterToAttributes(profileAttributes,
      filters as IAuditPlanningAppliedFilters,
      currUser.email);

    // Build the separate Y-Axis data for the Facility Attributes and PlanApprovals/Results pages:
    const facAttrYAxisData = buildYAxisData({
      yAxisData: gridYAxes,
      editRestriction: facAttrPageAuth.editRestriction,
      derivedMetaRestrictions: activeUserProfile.derivedMetaRestrictions,
      planConfigs: planningConfig.filter(x => x.type === PlanningPeriodsType.facilityAttribute),
      planPeriods: facAttrPlanPeriods,
      profilelessFacilities: facilities || [],
    });

    const planApprovalsResultsYAxisData: IPlanningItem[] = buildYAxisData({
      yAxisData: gridYAxes,
      editRestriction: facAttrPageAuth.editRestriction,
      derivedMetaRestrictions: activeUserProfile.derivedMetaRestrictions,
      planConfigs: planningConfig.filter(x => x.type === PlanningPeriodsType.planApproval),
      planPeriods: planApprovalPlanPeriods,
      profilelessFacilities: facilities || [],
    });

    // Put the loaded data into the state.
    yield put(finishLoadingAllPlanningData({
      profiles,
      profileAttributes: visibleAttributes,
      nonVisibleProfileAttributes: nonVisibleAttributes,
      facAttrYAxisData,
      planApprovalsResultsYAxisData,
      xAxisData: gridXAxes.requirementItems,
      facilities: facilities ?? [],
      planApprovalsPlans,
      planResultsPlans,
      planScores,
      appliedFilters: cloneDeep(filters) as IAuditPlanningAppliedFilters,
    }));

    // Now load the calendar's resources.
    yield call(loadAuditPlanCalendarData);
  } catch (err) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    hadError = true;
  } finally {
    yield put(setIsPlanningLoading(false));
  }

  // Return true if there was no error.
  return !hadError;
}

/** If no Audit Type is selected in the current filters, query the API for audit types and auto-select the first one. */
function* ensureSelectedAuditType(currentFilters: IAuditPlanningFilters) {
  const currentAuditType = currentFilters.auditType;

  if (currentAuditType) {
    // No problem. There's already an Audit Type selected.
    return {
      wasAlreadySet: true,
      auditType: currentAuditType,
    };
  } else if (!currentAuditType) {
    // No Audit Type is selected. Look them up and select the first before continuing.
    const auditTypes: IAuditType[] = yield call(MasterDataApi.getAuditTypes);

    if (auditTypes.length) {
      yield put(setCurrentFilters({
        auditType: auditTypes[0],
      }));

      return {
        wasAlreadySet: false,
        auditType: currentAuditType,
      };
    }
  }

  throw new Error("No Audit Type is selected and Audit Types could not be loaded from the server.");
}

function* getPlanningConfigData() {
  const auditPlanningConfigs: IAuditPlanningConfig[] = yield call(AuditPlanningConfigApi.getConfigs, [{
    type: PlanningPeriodsType.facilityAttribute,
    name: "Mode",
  }, {
    type: PlanningPeriodsType.facilityAttribute,
    name: "DefaultStart",
  }, {
    type: PlanningPeriodsType.facilityAttribute,
    name: "DefaultEnd",
  }, {
    type: PlanningPeriodsType.planApproval,
    name: "Mode",
  }, {
    type: PlanningPeriodsType.planApproval,
    name: "DefaultStart",
  }, {
    type: PlanningPeriodsType.planApproval,
    name: "DefaultEnd",
  }, {
    type: PlanningPeriodsType.planApproval,
    name: "AutoDate",
  }]);
  return auditPlanningConfigs;
}

function getPlanAndScoreRequests(filters: IAuditPlanningFilters,
  planningConfigs: IAuditPlanningConfig[]): {
    planApprovalsPlanQuery: IPlanFiltersRequest,
    planResultsPlanQuery: IPlanFiltersRequest,
  } {
  const showRemovedOnly = filters.planStatus === PlanningPlanStatusFilterValues.Removed;
  const statusFilters = filters.planStatus !== PlanningPlanStatusFilterValues.Removed
    && filters.planStatus !== undefined
    ? [filters.planStatus.toString() as AuditPlanApprovalStatuses]
    : [];

  // Determine whether to include Recommended plans automatically or not based on the config.
  let currentDate = new Date();
  let autoInclusionDate = formatDateFromAuditPlanningConfigItem(planningConfigs
    .find(x => x.name === "AutoDate")
    ?.value,
    currentDate.getFullYear());
  const includeRecommendedPlans = autoInclusionDate
    && currentDate >= autoInclusionDate;

  let approvalStatusFilter: AuditPlanApprovalStatuses[] = [AuditPlanApprovalStatuses.FinalApproval];

  if (includeRecommendedPlans) {
    approvalStatusFilter.push(AuditPlanApprovalStatuses.Recommended);
  }

  // If the user has selected any plan statuses themselves, overwrite the default filter from above with
  // their selected value.
  if (filters.planStatus?.length
    && filters.planStatus !== PlanningPlanStatusFilterValues.Removed) {
    approvalStatusFilter = [filters.planStatus.toString() as AuditPlanApprovalStatuses];
  }

  return {
    planApprovalsPlanQuery: {
      auditGroupId: filters.perspectiveXAxes[0].auditGroupId,
      auditTypeId: filters.auditType?.id || 0,
      planYear: filters.planYear,
      approvalStatus: statusFilters,
      basinIds: filters.businessTeams.map(x => x.id),
      deletedPlans: showRemovedOnly
        ? true
        : undefined,
      divisionIds: filters.businessViews.map(x => x.id),
      facilityIds: filters.facilities.map(x => x.id),
      ownerGroupId: filters.perspectiveXAxes[0].ownerGroupId,
    },
    planResultsPlanQuery: {
      auditGroupId: filters.perspectiveXAxes[0].auditGroupId,
      auditTypeId: filters.auditType?.id || 0,
      planYear: filters.planYear,
      approvalStatus: approvalStatusFilter,
      basinIds: filters.businessTeams.map(x => x.id),
      deletedPlans: showRemovedOnly
        ? true
        : undefined,
      divisionIds: filters.businessViews.map(x => x.id),
      facilityIds: filters.facilities.map(x => x.id),
      leadAuditorEmails: filters.leadAuditors.map(x => x.email),
      ownerGroupId: filters.perspectiveXAxes[0].ownerGroupId,
      auditStatuses: undefined,
    },
  };
}

interface IPlanningDataBatch1 {
  planningConfig: IAuditPlanningConfig[],
  gridXAxes: IPlanGridXAxesData,
  gridYAxes: IPlanGridYAxesData,
  facilities: IFacility[],
  facAttrPageAuth: IAuditPageAuth,
  planApprovalsPageAuth: IAuditPageAuth,
}

function* getPlanningDataBatch1(filters: IAuditPlanningFilters) {
  // Build the request object for grid x axes.
  const xAxisQuery: IPlanXFiltersRequest = {
    auditGroupId: filters.perspectiveXAxes[0].auditGroupId,
    ownerGroupId: filters.perspectiveXAxes[0].ownerGroupId,
  };

  // Build the request object for grid y axes.
  const yAxisQuery: IPlanYFiltersRequest = {
    perspectiveXAxisId: filters.perspectiveXAxes[0].id,
    basinIds: filters.businessTeams.map(x => x.id)
      // Add in any Perspective BusinessTeam metadata.
      .concat(filters.perspectives[0].metaData
        .filter(x => x.type === MetaDataTypes.BusinessTeam)
        .map(x => x.masterDataId)),
    divisionIds: filters.businessViews.map(x => x.id)
      // Add in any Perspective BusinessView metadata.
      .concat(filters.perspectives[0].metaData
        .filter(x => x.type === MetaDataTypes.BusinessView)
        .map(x => x.masterDataId)),
    facilityIds: filters.facilities.map(x => x.id),
    includeEmptyBusinessTeams: true,
  };

  // Build the request objects for the page permissions.
  const facAttrRequest: IAuditPageAuthRequest = {
    auditId: 0,
    pageName: AuditPages.FacilityAttributes,
    pageVisibleWhileLoading: true,
    type: PageAuthRequestType.nonAudit,
  };
  const planApprovalsRequest: IAuditPageAuthRequest = {
    auditId: 0,
    pageName: AuditPages.PlanApprovalPage,
    pageVisibleWhileLoading: true,
    type: PageAuthRequestType.nonAudit,
  };

  // Load this set of data which requires no per-page inputs:
  // * Audit Planning Config - PlanApprovals: Auto Date, Mode, Default Start, Default End
  // * Audit Planning Config - FacilityAttributes: Mode, Default Start, Default End
  // * Grid X Axes
  // * Grid Y Axes
  // * Audit Topics
  // * Facilities (only if the selected XAxes can plan by Facility, else null)
  // * Facility Attribute Page Permission
  // * Plan Approvals Page Permission
  const [
    planningConfig,
    gridXAxes,
    gridYAxes,
    facilities,
    facAttrPageAuth,
    planApprovalsPageAuth,
  ]: [
      IAuditPlanningConfig[],
      IPlanGridXAxesData,
      IPlanGridYAxesData,
      IFacility[],
      IAuditPageAuth,
      IAuditPageAuth,
    ] = yield all([
      call(getPlanningConfigData),
      call(AuditPlanningApi.getPlanGridXAxis, xAxisQuery),
      call(AuditPlanningApi.getPlanGridYAxis, yAxisQuery),
      filters.perspectiveXAxes[0]
        .plannableMasterDataTypes
        .some(x => x.masterDataType === MetaDataTypes.Facility)
        ? call(MasterDataApi.getFacilities,
          undefined,
          undefined,
          filters.businessTeams.map(x => x.id),
          filters.facilities.map(x => x.id))
        : [],
      call(AuditPageAuthApi.getNonAuditPageAuthInfo, facAttrRequest),
      call(AuditPageAuthApi.getNonAuditPageAuthInfo, planApprovalsRequest),
    ]);

  const universalData: IPlanningDataBatch1 = {
    planningConfig,
    gridXAxes,
    gridYAxes,
    facilities,
    facAttrPageAuth,
    planApprovalsPageAuth,
  };

  return universalData;
}

interface IPlanningDataBatch2 {
  profiles: IPlanProfile[],
  profileAttributes: IPlanProfileAttribute[],
  planApprovalsPlans: IAuditPlanView[],
  planResultsPlans: IAuditPlanView[],
  planScores: IAuditPlanScore[],
  facAttrPlanPeriods: IAdminPlanningAdministrationPeriod[],
  planApprovalPlanPeriods: IAdminPlanningAdministrationPeriod[],
}

function* getPlanningDataBatch2(filters: IAuditPlanningFilters,
  facAttrPageAuth: IAuditPageAuth,
  planApprovalsPageAuth: IAuditPageAuth,
  planConfigs: IAuditPlanningConfig[]) {
  // Load the following data once for each page:
  // * Plan Profiles (Facility Attributes)
  // * Plan Profile Attributes (Facility Attributes)
  // * Plans (Approvals and Results)
  // * Plan Scores (Approvals)
  // * Facility Attribute Planning Periods
  // * Plan Approvals Planning Periods
  const planAndScoreRequests = getPlanAndScoreRequests(filters, planConfigs);

  const [
    profiles,
    profileAttributes,
    planApprovalsPlans,
    planResultsPlans,
    planScores,
    facAttrPlanPeriods,
    planApprovalPlanPeriods,
  ]: [
      IPlanProfile[],
      IPlanProfileAttribute[],
      IAuditPlanView[],
      IAuditPlanView[],
      IAuditPlanScore[],
      IAdminPlanningAdministrationPeriod[],
      IAdminPlanningAdministrationPeriod[],
    ] = yield all([
      call(AuditPlanningApi.getPlanProfiles, filters),
      call(AuditPlanningApi.getPlanAttributes, filters),
      call(AuditPlanningApi.getPlans, planAndScoreRequests.planApprovalsPlanQuery),
      call(AuditPlanningApi.getPlans, planAndScoreRequests.planResultsPlanQuery),
      call(AuditPlanningApi.getPlanScores, planAndScoreRequests.planApprovalsPlanQuery),
      facAttrPageAuth.editRestriction === EditRestriction.EditNone
        ? []
        : call(AdminPlanningPeriodsApi.getPeriods, PlanningPeriodsType.facilityAttribute),
      planApprovalsPageAuth.editRestriction === EditRestriction.EditNone
        ? []
        : call(AdminPlanningPeriodsApi.getPeriods, PlanningPeriodsType.planApproval),
    ]);

  const pageSpecificData: IPlanningDataBatch2 = {
    profiles,
    profileAttributes,
    planApprovalsPlans,
    planResultsPlans,
    planScores,
    facAttrPlanPeriods,
    planApprovalPlanPeriods,
  };

  return pageSpecificData;
}

export function* loadAuditPlanCalendarData() {
  // Since the filters were just applied and the calendar is going to load its data,
  // the calendar resources need to be loaded as well.
  try {
    const state: IAuditPlanCalendarState = yield select((store: RootState) => store.auditPlanCalendar);
    const calendarMode = state.calendarMode;
    const appliedFilters: IAuditPlanningFilters = yield select((store: RootState) => store.auditPlanning.appliedFilters);

    if (!appliedFilters) {
      throw new Error("Cannot load calendar resources because there are no applied filters.");
    }

    yield put(setLoadDataOp({
      isWorking: true,
    }));

    const [
      basinYAxisData,
      leaderYAxisData,
      schedulerItemsData,
    ]: [
        IAuditPlanCalendarBasinYAxisData | undefined,
        IAuditPlanCalendarLeadAuditor[] | undefined,
        IAuditSchedulerItemData[],
      ] = yield all([
        calendarMode === "basin"
          ? call(AuditPlanCalendarApi.getPlanCalendarBasinYAxisData, appliedFilters)
          : undefined,
        calendarMode === "leader"
          ? call(getLeaderYAxisData, state, appliedFilters)
          : undefined,
        call(getCalendarItems)
      ]);

    yield put(finishLoadCalendarResources({
      isWorking: false,
      data: {
        basinYAxis: basinYAxisData,
        leaderYAxis: leaderYAxisData,
        items: schedulerItemsData,
      },
    }));
  } catch (err: any) {
    yield put(finishLoadCalendarResources({
      isWorking: false,
      errorMessage: getResponseErrorMessage(err),
    }));

    yield put(showErrorToast(getResponseErrorMessage(err)));
  }
}

export function* getCalendarItems() {
  const state: IAuditPlanCalendarState = yield select((store: RootState) => store.auditPlanCalendar);
  const appliedFilters: IAuditPlanningFilters = yield select((store: RootState) => store.auditPlanning.appliedFilters);

  const calendarItemsRequest: IGetAuditPlanCalendarItemRequest = {
    auditCalendarStatuses: appliedFilters.auditCalendarStatuses,
    auditTypeIds: appliedFilters.calendarAuditTypes.map(x => x.id),
    businessTeamIds: appliedFilters.businessTeams.map(x => x.id),
    businessViewIds: appliedFilters.businessViews.map(x => x.id),
    complianceResults: appliedFilters.complianceResults,
    EndDate: new Date(state.calendarDateRange.endTimestamp),
    facilityIds: appliedFilters.facilities.map(x => x.id),
    leadAuditorEmails: appliedFilters.leadAuditors.map(x => x.email),
    onlyUnassignedLeaders: appliedFilters.unassigned,
    perspectiveXAxisId: Number(appliedFilters.perspectiveXAxes?.[0]?.id),
    StartDate: new Date(state.calendarDateRange.startTimestamp),
  };

  const items: IAuditSchedulerItemData[] = yield call(AuditPlanCalendarApi.getCalendarItems, calendarItemsRequest);
  return items;
}

function* getLeaderYAxisData(calendarState: IAuditPlanCalendarState, appliedFilters: IAuditPlanningFilters) {
  const leadAuditorYAxisData: IAuditPlanCalendarLeadAuditor[] = (!calendarState.calendarDateRange.startTimestamp
    || !calendarState.calendarDateRange.endTimestamp)
    ? []
    : yield call(AuditPlanCalendarApi.getPlanCalendarLeadAuditorYAxisData,
      appliedFilters,
      calendarState.calendarDateRange.startTimestamp,
      calendarState.calendarDateRange.endTimestamp);

  leadAuditorYAxisData.sort(
    function (a, b) {
      if (a.leadAuditorName === undefined) return -1;
      if (b.leadAuditorName === undefined) return -1;

      if (a.leadAuditorName > b.leadAuditorName) return 1;
      if (a.leadAuditorName < b.leadAuditorName) return -1;

      return 0;
    }
  );

  return leadAuditorYAxisData;
}

export function* loadPlanCalendarLeadAuditorsAsync() {
  const state: IAuditPlanCalendarState = yield select((store: RootState) => store.auditPlanCalendar);
  const appliedFilters: IAuditPlanningFilters = yield select((store: RootState) => store.auditPlanning.appliedFilters);

  try {
    if (!appliedFilters) {
      throw new Error("Cannot load calendar resources because there are no applied filters.");
    }

    const leadAuditorYAxisData: IAuditPlanCalendarLeadAuditor[] = yield call(getLeaderYAxisData, state, appliedFilters);

    yield put(finishLoadCalendarResources({
      isWorking: false,
      data: {
        leaderYAxis: leadAuditorYAxisData,
      },
    }));
  } catch (err) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(setLoadDataOp({
      isWorking: false,
    }));
  }
}
