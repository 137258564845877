import { PayloadAction } from "@reduxjs/toolkit";
import { IGetAuditScoresOptions } from "api/auditing/types/auditScoresApiTypes";
import { IOperation } from "shared/types/operationTypes";
import { IAllAuditScoreResults } from "types/audit-scores/auditScoreTypes";

export interface IAuditScoresState {
  loadScoresOp?: IOperation<IAllAuditScoreResults>,
  scores?: IAllAuditScoreResults,
}

export const initialAuditScoresState: IAuditScoresState = {
  loadScoresOp: undefined,
  scores: undefined,
};

const scoresReducers = {
  /** Loads the scores for the specified audit id. */
  loadScores: (state: IAuditScoresState, _: PayloadAction<IGetAuditScoresOptions>) => {
    state.loadScoresOp = {
      isWorking: true,
    };
  },

  /** Sets the specified question operation info into the state. */
  finishLoadScores: (state: IAuditScoresState, action: PayloadAction<IOperation<IAllAuditScoreResults>>) => {
    const {
      errorMessage,
      data,
    } = action.payload;

    if (errorMessage
      || !data) {
      state.loadScoresOp = action.payload;
      return;
    }

    state.loadScoresOp = undefined;
    state.scores = action.payload.data;
  },
};

export default scoresReducers;