import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PickerDropdown from "shared/components/controls/picker-dropdown/PickerDropdown";
import { loadPickerItems, setSelectedPickerItems } from "store/search/actions/SearchActionsSlice";
import { useAppSelector } from "store/store";

const AuditType: React.FC = () => {
  const auditTypes = useAppSelector(store => store.searchActions.pickerData.auditTypes);

  return (
    <LabeledControl
      label="Audit Type"
      className="auditor-type"
    >
      <PickerDropdown
        pickerState={auditTypes}
        setSelectedItemsAction={setSelectedPickerItems}
        loadAction={loadPickerItems}
      />
    </LabeledControl>
  );
};

export default AuditType;