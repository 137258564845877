
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import AppStartup from 'components/app-startup/AppStartup';
import AuditLayout from 'components/audits/common/layout/AuditLayout';
import NewProdUrlMessageBar from 'components/other/NewProdUrlMessageBar';
import { createBrowserHistory } from "history";
import React, { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import QoFErrorBoundary from 'shared/components/common/qof-error-boundary/QoFErrorBoundary';
import { reactPlugin } from "./AppInsights";

export const history = createBrowserHistory();

const App: React.FC = () =>
  <QoFErrorBoundary isFullPage>
    <AppInsightsContext.Provider value={reactPlugin}>
      <QoFErrorBoundary isFullPage>
        <AppStartup>
          <CustomRouter
            history={history}
          >
            <NewProdUrlMessageBar />
            <AuditLayout />
          </CustomRouter>
        </AppStartup>
      </QoFErrorBoundary>
    </AppInsightsContext.Provider>
  </QoFErrorBoundary>;

export default App;

interface ICustomRouterProps {
  history: any,
  children?: React.ReactNode,
}

const CustomRouter: React.FC<ICustomRouterProps> = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};