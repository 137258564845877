import React, { ReactElement } from "react";

interface IAnimateProps {
  children: React.ReactHTMLElement<any> | ReactElement<any, any>,
  shouldBeVisible: boolean,
  animation: Animations,
}

export enum Animations {
  fade = "fade",
  slideRight = "slide-right",
  slideLeft = "slide-left",
}

const Animate: React.FC<IAnimateProps> = ({
  children,
  shouldBeVisible,
  animation,
}) =>
  <>
    {React.cloneElement(children, {
      className: `${children.props.className} ${animation}-${shouldBeVisible ? "in" : "out"}`
    })}
  </>;

export default Animate;