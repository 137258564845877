import { uniqBy } from "lodash";
import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import NonReduxPicker from "shared/components/controls/pickers/non-redux-picker/NonReduxPicker";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IUser } from "shared/types/userProfileTypes";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { userToString } from "shared/utilities/userUtilities";
import { useAppSelector } from "store/store";
import { IAnswer, IAuditTopic } from "types/auditMasterDataTypes";
import { IAuditQuestion } from "types/auditingTypes";
import { IAssignFindingToQuestionsModalState } from "../AssignFindingToQuestions";
import "./FilterRow.scoped.scss";

export interface IFilterRowProps {
  filterValues: IAssignFindingToQuestionsModalState,
  onUpdateFilterValues: (newValues: Partial<IAssignFindingToQuestionsModalState>) => void,
  questions: IAuditQuestion[],
}

export const FilterRow: React.FC<IFilterRowProps> = ({
  filterValues,
  onUpdateFilterValues,
  questions,
}: IFilterRowProps) => {
  const audit = useAppSelector(store => store.audit.audit);
  const allAnswers = useAppSelector(store => store.audit.answers);

  if (!audit) {
    return null;
  }

  const availableAuditors = uniqBy(questions
    .filter(x => x.auditorEmail
      && x.auditorName)
    .map((x): IUser => ({
      email: x.auditorEmail ?? "",
      name: x.auditorName ?? "",
      jobTitle: "",
      photo: undefined,
    })), x => x.email);

  const peopleInterviewed = uniqBy(questions
    .filter(x => x.interviewees.length)
    .flatMap(x => x.interviewees)
    .map((x): IUser => ({
      email: x.email ?? "",
      name: x.name ?? "",
      jobTitle: "",
      photo: undefined,
    })), x => x.email);

  const availableAnswers = uniqBy(questions
    .map(x => allAnswers.find(a =>
      a.code === x.responses
        .find(z => z.auditStatus === audit.status)
        ?.answer))
    .filter(isNotUndefined),
    x => x.key);

  return (
    <>
      <FlexRow
        className="control-row"
      >
        <LabeledControl
          label="Search questions"
        >
          <input
            type="text"
            onChange={e => onUpdateFilterValues({
              questionFilter: e.currentTarget.value,
            })}
            value={filterValues.questionFilter ?? ""}
          />
        </LabeledControl>

        <LabeledControl
          label="Topic"
        >
          <NonReduxPicker<IAuditTopic>
            keyMapper={x => x.id}
            onApply={items => onUpdateFilterValues({
              topicFilter: items,
            })}
            onLoadItems={async (searchTerm) => audit
              .auditTopics
              .filter(x => x.level === 1
                && (!searchTerm?.trim()
                  || x.name.toLowerCase().includes(searchTerm.toLowerCase()))
              )
            }
            searchOptions={{
              filterItem: (item, search) => item.name
                .toLowerCase()
                .includes(search.toLowerCase()) === true,
            }}
            renderSelectedItem={x => x.name}
            selectedItems={filterValues.topicFilter}
            title="Topic"
            allowMultiSelect
            renderListItem={x => x.name}
            maxSelectedItemsVisible={0}
          />
        </LabeledControl>

        <LabeledControl
          label="Sub-topic"
        >
          <NonReduxPicker<IAuditTopic>
            keyMapper={x => x.id}
            onApply={items => onUpdateFilterValues({
              subTopicFilter: items,
            })}
            onLoadItems={async (searchTerm) => audit
              .auditTopics
              .filter(x => !!x.parentId
                && (!searchTerm?.trim()
                  || x.name.toLowerCase().includes(searchTerm.toLowerCase()))
              )
            }
            searchOptions={{
              filterItem: (item, search) => item.name
                .toLowerCase()
                .includes(search.toLowerCase()) === true,
            }}
            renderSelectedItem={x => x.name}
            selectedItems={filterValues.subTopicFilter}
            title="Sub-topic"
            allowMultiSelect
            renderListItem={x => x.name}
            maxSelectedItemsVisible={0}
          />
        </LabeledControl>

        <LabeledControl
          label="Auditor"
        >
          <NonReduxPicker<IUser>
            keyMapper={x => x.email}
            onApply={items => onUpdateFilterValues({
              auditorFilter: items,
            })}
            onLoadItems={async (searchTerm) => availableAuditors
              .filter(x => !searchTerm
                || userToString(x).toLowerCase().includes(searchTerm))
            }
            searchOptions={{
              filterItem: (item, search) => userToString(item)
                .toLowerCase()
                .includes(search.toLowerCase()),
            }}
            renderSelectedItem={userToString}
            selectedItems={filterValues.auditorFilter}
            title="Auditor"
            allowMultiSelect
            renderListItem={userToString}
            maxSelectedItemsVisible={0}
          />
        </LabeledControl>

        <LabeledControl
          label="People Interviewed"
        >
          <NonReduxPicker<IUser>
            keyMapper={x => x.email}
            onApply={items => onUpdateFilterValues({
              auditeeFilter: items,
            })}
            onLoadItems={async (searchTerm) => peopleInterviewed
              .filter(x => !searchTerm
                || userToString(x).toLowerCase().includes(searchTerm))
            }
            searchOptions={{
              filterItem: (item, search) => userToString(item)
                .toLowerCase()
                .includes(search.toLowerCase()),
            }}
            renderSelectedItem={userToString}
            selectedItems={filterValues.auditeeFilter}
            title="People Interviewed"
            allowMultiSelect
            renderListItem={userToString}
            maxSelectedItemsVisible={0}
          />
        </LabeledControl>

        <LabeledControl
          label="Answer"
        >
          <NonReduxPicker<IAnswer>
            keyMapper={x => x.key}
            onApply={items => onUpdateFilterValues({
              answerFilter: items,
            })}
            onLoadItems={async () => availableAnswers}
            searchOptions={{
              filterItem: (item, search) => item.name.toLowerCase().includes(search.toLowerCase()),
            }}
            renderSelectedItem={x => x.name}
            selectedItems={filterValues.answerFilter}
            title="Answer"
            allowMultiSelect
            renderListItem={x => x.name}
            maxSelectedItemsVisible={1}
          />
        </LabeledControl>
      </FlexRow>
    </>
  );
};
