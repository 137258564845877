import AuditPlanCalendarApi from "api/planning/calendar/AuditPlanCalendarApi";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import config from "config";
import React, { useState } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import QITag from "shared/components/common/tag/QITag";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { IAuditSchedulerItemData } from "shared/components/controls/scheduler/qiSchedulerTypes";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IOperation } from "shared/types/operationTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import formatDate from "shared/utilities/dateFormatters";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";
import { userToString } from "shared/utilities/userUtilities";
import { openEditItemModal, setQuickInfoItem } from "store/audit-plan-calendar/AuditPlanCalendarSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import AuditPlanCalendarItemTypes from "types/audit-plan-calendar/IAuditPlanCalendarItemTypes";
import IExtraAuditPlanCalendarItemInfo from "types/audit-plan-calendar/IExtraAuditPlanCalendarItemInfo";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditSources, AuditStatuses } from "types/auditingTypes";
import "./QuickInfoModal.scoped.scss";

interface IQuickInfoModalProps {
  item: IAuditSchedulerItemData,
}

const QuickInfoModal: React.FC<IQuickInfoModalProps> = ({
  item,
}) => {
  const {
    planId,
    auditId,
    type: itemType,
    auditSource: itemAuditSource,
  } = item;

  const selectedPlanIds = useAppSelector(store => store.auditPlanCalendar.selectedPlanIds);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const userEmail = useAppSelector(store => store.auth.currentUser.email);

  const [extraInfo, setExtraInfo] = useState<IOperation<IExtraAuditPlanCalendarItemInfo> | undefined>({ isWorking: true, });
  const dispatch = useAppDispatch();

  useAsyncEffect(async (aborted, abortSignal) => {
    try {
      const data = await AuditPlanCalendarApi.getExtraCalendarItemInfo(itemAuditSource === AuditSources.QoF
        && auditId
        ? auditId
        : planId,
        itemType,
        itemAuditSource,
        abortSignal);

      if (aborted) {
        return;
      }

      setExtraInfo({
        isWorking: false,
        data,
      });
    } catch (err) {
      if (!aborted) {
        setExtraInfo({
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        });
      }
    }
  }, [planId, itemType, auditId, itemAuditSource, setExtraInfo]);

  let mdSections: JSX.Element[] = [];

  if (extraInfo?.isWorking) {
    mdSections = [
      renderLabel("", <Spinner className="icon-small" key={'loading'} />),
    ];
  } else if (extraInfo?.data?.extraInfoItems) {
    Array.from(new Set(extraInfo.data.extraInfoItems.map(x => x.type)))
      .forEach(type => {
        const items = extraInfo.data?.extraInfoItems.filter(x => x.type === type);

        if (!items) {
          return;
        }

        if (type === "IPMProject") {
          // Need to split by subtype as well for IPM Projects.
          Array.from(new Set(items.map(x => x.subType))).forEach(subtype => {
            let subItems = items?.filter(x => x.subType === subtype);

            if (!subItems) {
              return;
            }

            mdSections.push(
              renderLabel(subtype.replace(/^IPM/, "IPM "),
                subItems
                  .slice()
                  .sort((a, b) => a.text < b.text ? -1 : 1)
                  .map(x => x.text)
                  .join(", "))
            );
          });
        } else {
          // Other types simply use the type.
          mdSections.push(
            renderLabel(type.replace(/([a-z])([A-Z])/, "$1 $2"),
              items
                .slice()
                .sort((a, b) => a.text < b.text ? -1 : 1)
                .map(x => x.text)
                .join(", "))
          );
        }
      });
  }

  const headerText = item.type === AuditPlanCalendarItemTypes.Plan
    ? "Audit Plan"
    : (item.auditSource === AuditSources.LegacyQuest
      ? "Legacy Quest Audit"
      : "QI Audit");

  const onCloseClicked = () => {
    dispatch(setQuickInfoItem());
  };

  const onEditClicked = () => {
    if (item.type === AuditPlanCalendarItemTypes.Plan) {
      if (checkShouldCancelAction(item,
        item,
        selectedPlanIds.length > 0,
        (err) => dispatch(showErrorToast(err)),
        editRestriction,
        userEmail)) {
        // User is not allowed to edit this plan.
        return;
      }

      // Hide the quick info modal.
      dispatch(setQuickInfoItem());

      // Plans can be edited in the modal.
      dispatch(openEditItemModal(item));
    } else {
      // Audits redirect to the audit page.
      if (item.legacyAuditNumber) {
        window.open(config.endpoints.legacyQuest.audit.replace(":id", item.legacyAuditNumber));
      } else if (item.auditId) {
        window.open(formatRoute(UrlRoutes.EditAudit, { auditId: item.auditId.toString() }));
      }
    }
  };

  return (
    <Modal
      header={headerText}
      showCloseButton
      onCloseButtonClicked={onCloseClicked}
      isOpen
      buttons={[{
        key: "CLOSE",
        text: "Close",
        className: "secondary",
        onClick: onCloseClicked,
      }, {
        key: "EDIT",
        text: "Edit",
        className: "primary",
        onClick: onEditClicked,
      }]}
    >
      <div className="contents">
        {item.type === AuditPlanCalendarItemTypes.Plan
          ? renderLabel("Audit Plan", item.planId.toString())
          : renderLabel(item.auditSource === AuditSources.LegacyQuest
            ? "Legacy Quest Audit"
            : "QI Audit",
            item.auditId?.toString() || item.legacyAuditNumber || "--")}

        {renderLabel("Audit Type", item.auditTypeName || "--")}

        {renderLabel("Lead Auditor",
          item.leadAuditorEmail
            && item.leadAuditorName
            ? userToString({
              name: item.leadAuditorName,
              email: item.leadAuditorEmail,
            }) : "--")
        }

        {mdSections}

        {renderLabel("Requirements",
          <FlexRow>
            {item.requirementDimensionTexts.map(name => (
              <QITag
                key={name}
                display={name}
              />
            ))}
          </FlexRow>, true)}

        {renderLabel("Status", item.auditStatus || item.planStatus)}

        {renderLabel("Start & End Dates",
          `${formatDate(new Date(item.startTime))} - ${formatDate(new Date(item.endTime))}`
        )}

        {!!item.auditCompliance && (
          renderLabel("Compliance", item.auditCompliance || "--")
        )}
      </div>
    </Modal>
  );
};

export default QuickInfoModal;

function renderLabel(label: string, node: React.ReactNode, isDoubleWide?: boolean) {
  return (
    <LabeledControl
      label={label}
      className={`item ${isDoubleWide ? "wide" : ""}`}
      key={label ?? "undefined"}
    >
      {node}
    </LabeledControl>
  );
}

/**
 * Checks a performed action to decide if it should be canceled.
 * @param updatedItem The item that is to be updated.
 * @param originalItem The original item yet to be updated.
 * @param hasSelection Whether the scheduler has any selected items or not.
 * @param handleErrorMessage A callback for handling error messages.
 * @returns True if the action should be canceled, else false.
 */
function checkShouldCancelAction(updatedItem: IAuditSchedulerItemData | undefined,
  originalItem: IAuditSchedulerItemData | undefined,
  hasSelection: boolean,
  handleErrorMessage: (err: string) => void,
  editRestriction: EditRestriction,
  currentUserEmail: string): boolean {
  if (!originalItem
    || !updatedItem
    || hasSelection) {
    return false;
  }

  let error = "";

  if (originalItem.auditStatus === AuditStatuses.Completed
    || originalItem.auditStatus === AuditStatuses.Closed) {
    error = `This audit cannot be changed because it is ${originalItem.auditStatus}.`;
  } else if (originalItem.auditSource === AuditSources.LegacyQuest) {
    error = "Legacy QUEST audits cannot be modified.";
  } else if (originalItem.parentDimensionId !== updatedItem.parentDimensionId
    || originalItem.parentDimensionType !== updatedItem.parentDimensionType
    || originalItem.subGeoUnitId !== updatedItem.subGeoUnitId
    || originalItem.geoUnitId !== updatedItem.geoUnitId
    || originalItem.basinId !== updatedItem.basinId) {
    error = "The location assignment for this item cannot be modified as it will delink the audit from the plan they were created for.";
  } else if (originalItem.childDimensionId !== updatedItem.childDimensionId
    || originalItem.childDimensionType !== updatedItem.childDimensionType) {
    error = "The child dimension assignment for this item cannot be modified as it will delink the audit from the plan they were created for.";
  } else if ((editRestriction === EditRestriction.EditOwn
    && originalItem.leadAuditorEmail?.toLowerCase() !== currentUserEmail.toLowerCase())
    || editRestriction === EditRestriction.EditNone) {
    error = "You do not have permission to edit this item.";
  }

  if (error) {
    handleErrorMessage(error);
  }

  // Return false if there is any error message to prevent the action from occuring.
  return !!error;
}
