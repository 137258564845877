import UrlRoutes, { formatRoute } from 'components/routing/UrlRoutes';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FlexCol from 'shared/components/layout/flex/FlexCol';
import Modal from 'shared/components/layout/modal/Modal';
import { closeCreateManualAuditModal } from 'store/audit/AuditSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import './ConfirmCreateManualAuditModal.scoped.scss';

const ConfirmCreateManualAuditModal: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isConfirmCreateManualAuditModalOpen = useAppSelector(
    (store) => store.audit.isConfirmCreateManualAuditModalOpen
  );

  return (
    <Modal
      isOpen={isConfirmCreateManualAuditModalOpen}
      header="Create Audit"
      onCloseButtonClicked={() => dispatch(closeCreateManualAuditModal())}
      showCloseButton={true}
      buttons={[
        {
          key: 'no',
          className: 'secondary',
          text: 'No',
          onClick: () => {
            dispatch(closeCreateManualAuditModal());
            navigate(formatRoute(UrlRoutes.NewAudit, {}));
          }
        },
        {
          key: 'yes',
          className: 'primary',
          text: 'Yes',
          onClick: () => {
            dispatch(closeCreateManualAuditModal());
            navigate(formatRoute(UrlRoutes.PlanVsResults, {}));
          }
        }
      ]}
    >
      <FlexCol>
        <p>Do you intend to create an audit from a plan?</p>
      </FlexCol>
    </Modal>
  );
};

export default ConfirmCreateManualAuditModal;
