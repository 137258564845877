import MasterDataApi from "api/masterdata/MasterDataApi";
import React from "react";
import { ICountry, MetaDataTypes } from "types/masterDataTypes";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps } from "./pickerTypes";

interface ICountryPickerProps {
  /** Optional. If specified, the country query will be filtered by these facility Ids. */
  facilityIdFilter?: number[],
}

const CountryPicker: React.FC<ICountryPickerProps & ICommonPickerProps<ICountry>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  showSuggestions = false,
  onRenderPicker,
  facilityIdFilter,
  isDisabled = false,
  userProfileMetaRestrictions,
  isItemDisabledMapper,
}) => {
  const renderItem = (item: ICountry) => item.name;

  const restrictedToItems = userProfileMetaRestrictions
    ?.filter(x => x.type === MetaDataTypes.Country) || [];

  return (
    <NonReduxPicker<ICountry>
      itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
      keyMapper={x => x.id}
      onApply={onApply}
      onLoadItems={async (searchValue, abortSignal) => await MasterDataApi.getCountries(searchValue, facilityIdFilter, undefined, abortSignal)}
      renderSelectedItem={renderItem}
      selectedItems={selectedItems}
      title="Countries"
      allowMultiSelect={allowMultiselect}
      displayMode="list"
      renderListItem={renderItem}
      onLoadSuggestedItems={async (abortSignal) => await MasterDataApi.getSuggestedCountries(facilityIdFilter, abortSignal)}
      showSuggestedItems={showSuggestions}
      searchOptions={{
        filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
      }}
      onRenderPicker={onRenderPicker}
      isDisabled={isDisabled}
      isDisabledMapper={
        !isItemDisabledMapper
          && !restrictedToItems.length
          ? undefined
          : (item, ancestorPath) => {

            if (isItemDisabledMapper
              && isItemDisabledMapper(item, ancestorPath)) {
              return true;
            } else if (restrictedToItems
              && restrictedToItems.length
              && !restrictedToItems.some(x => x.id === item.id)) {
              return true;
            }

            return false;
          }
      }
    />
  );
};

export default CountryPicker;