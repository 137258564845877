import { getResponseFromQuestion } from "components/audits/common/auditUtilities";
import ManageFindingModal from "components/audits/findings/manage-finding-modal/ManageFindingModal";
import config from "config";
import { cloneDeep, isEqual } from "lodash";
import { useEffect, useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import Button from "shared/components/controls/buttons/button/Button";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AzureUserPicker, { AzureUserPickerSuggestionMode } from "shared/components/controls/pickers/AzureUserPicker";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import arrowIcon from "shared/media/dls/arrow-down-2.svg";
import arrowLeftIcon from "shared/media/dls/arrow-left-3.svg";
import arrowRightIcon from "shared/media/dls/arrow-right-3.svg";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getTextboxMaxLengthHint, tooltipMessage } from "shared/utilities/stringUtilities";
import {
  AfterSaveActions,
  IExecutionWorkspaceData,
  clearWorkspace,
  deleteWorkspaceResponse,
  saveWorkspace,
  setCurrentWorkspace,
  setFindingToDelete,
  setSelectedPickerItems,
  setWorkspace,
  toggleClearingConfirmation,
  traverseQueue
} from "store/audit-execution/AuditExecutionSlice";
import { onRemoveFinding, openFindingModal, removeAllActionsByLink } from "store/audit/AuditSlice";
import { FindingLinkType, FindingModalModes } from "store/audit/reducers/findingReducers";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes } from "types/actionItemTypes";
import { IAnswer, IVerificationMethod } from "types/auditMasterDataTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";
import MissingFieldsModal from "../missing-fields/MissingFieldsModal";
import AnswerInput from "./answer-input/AnswerInput";
import VerMethodInput from "./vermethod-input/VerMethodInput";
import "./AnswerCard.scoped.scss";

const REQUIRE_CAUSAL_FACTOR_WITH_NO_ANSWERS = false;

interface IAnswerCardProps {
  question: IAuditQuestion,
  auditStatus: AuditStatuses,
}

interface IWarningData {
  isOpen: boolean,
  action: AfterSaveActions,
}

const AnswerCard: React.FC<IAnswerCardProps> = ({
  question,
  auditStatus,
}) => {
  const dispatch = useAppDispatch();

  const peopleInterviewed = useAppSelector(store => store.auditExecution.pickerData.auditees);
  const currQueueIndex = useAppSelector(store => store.auditExecution.currQueueIndex);
  const queueAQIds = useAppSelector(store => store.auditExecution.queueAQIds);
  const workspace = useAppSelector(store => store.auditExecution.workspace);
  const isClearingConfirmationOpen = useAppSelector(store => store.auditExecution.isClearingConfirmationOpen);
  const audit = useAppSelector(store => store.audit.audit);
  const allAnswers = useAppSelector(store => store.audit.answers);
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const isDirty = workspace && !isEqual(workspace.current, workspace.original);
  const isResponseDeletable = !!workspace?.original?.answer;

  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const currentUserEmail = useAppSelector(store => store.auth.currentUser.email);

  const leadAuditor = audit?.auditors.find(x => x.isLeader);

  const userOwnsQuestion = question.auditorEmail?.toLowerCase() === currentUserEmail.toLowerCase();

  // Finding
  const findingModal = useAppSelector(store => store.audit.findingModal);
  const findings = useAppSelector(store => store.audit.findings);
  const finding = workspace?.current?.finding;
  const findingTypes = useAppSelector(store => store.audit.findingTypes);
  const findingTypeSelected = findingTypes.find(x => x.id === finding?.findingTypeId);

  useEffect(() => {
    return () => {
      dispatch(clearWorkspace());
    }
  }, [dispatch]);

  useEffect(() => {
    // Whenever the question changes, need to reload the workspace.
    if (workspace
      && workspace.auditQuestionId === question.auditQuestionId) {
      // Workspace is already loaded for this question.
      return;
    }

    if (!question) {
      dispatch(setWorkspace(undefined));
      return;
    }

    const response = getResponseFromQuestion(question, auditStatus);

    const workspaceData: IExecutionWorkspaceData | undefined = {
      notes: response?.notes,
      selectedVerificationMethods: question.selectedVerificationMethods.slice(),
      answer: response?.answer,
      causalFactor: response?.causalFactor,
      interviewees: question.interviewees.slice(),
      finding: findings
        .find(f => f.links.some(x => x.linkId === question.auditQuestionId
          && x.linkType === FindingLinkType.AuditQuestion
          && !x.deleted)),
    };

    dispatch(setWorkspace(workspaceData
      && question
      ? {
        auditQuestionId: question.auditQuestionId,
        current: workspaceData,
        original: cloneDeep(workspaceData),
        isDirty: false,
      } : undefined));
  }, [question, workspace, auditStatus, findings, dispatch]);

  const priorResponse = auditStatus === AuditStatuses.Completed
    || auditStatus === AuditStatuses.Closed
    ? getResponseFromQuestion(question, AuditStatuses.InProgress)
    : undefined;

  const [warningData, setWarningData] = useState<IWarningData>({
    isOpen: false,
    action: AfterSaveActions.next,
  });

  // Check Restriction & ensure question is not OUT OF SCOPE.
  const isEditable = !question.responses.some(x => x.isOutOfScope === true)
    && (auditPageAuth.editRestriction === EditRestriction.EditAll
      || (auditPageAuth.editRestriction === EditRestriction.EditOwn
        && userOwnsQuestion));
  // if the component is editable then isDisabled invert the logic
  let isDisabled = !isEditable;

  const onAnswerChange = (newAnswer: string) => {
    if (finding !== undefined
      && question.scoringSystem === AuditScoringSystems.QMS) {
      dispatch(setFindingToDelete(finding));
      if (finding.id) {
        dispatch(onRemoveFinding({
          findingId: finding.id,
        }));
      }
    }

    dispatch(setCurrentWorkspace({
      answer: newAnswer,
    }));
  };

  if (!audit) {
    return (
      <Banner
        type={BannerType.error}
      >
        There is no audit loaded.
      </Banner>
    );
  }

  if (!leadAuditor) {
    return (
      <Banner
        type={BannerType.error}
      >
        There is no lead auditor assigned to this audit.
      </Banner>
    );
  }

  const trySaveThenAct = (actionName: AfterSaveActions) => {
    if (!question) {
      callAction(actionName);
      return;
    }

    const showDirtyWarning = isDirty
      && isResponseMissingRequiredFields(workspace?.current, allAnswers);

    const isAnswerNonCompliant = (answerCode: string | undefined) => allAnswers
      .some(ans => ans.canBeUpdated
        && answerCode === ans.code);

    if (isDirty
      && config.executionConfig.noAnswerRequiresActionItem
      && isAnswerNonCompliant(workspace?.current?.answer)
      && !actionItems.some(x => x.links.some(z => z.type === "AuditQuestion" && z.linkId === question.auditQuestionId))) {
      // This NO response is missing an Action Item.
      if (!isAnswerNonCompliant(workspace?.original?.answer)) {
        // Answer didn't used to be no.
        setWarningData({
          action: actionName,
          isOpen: true,
        });
      } else {
        dispatch(showErrorToast("This question cannot be saved because it is missing a Preventative or Corrective action item."));
        return;
      }
    } else if (showDirtyWarning) {
      setWarningData({
        action: actionName,
        isOpen: true,
      });
    } else if (isDirty
      && workspace) {
      dispatch(saveWorkspace({
        workspace: workspace,
        afterSaveAction: actionName,
      }));
    } else {
      callAction(actionName);
    }
  };

  const callAction = (actionName: AfterSaveActions) => {
    switch (actionName) {
      case AfterSaveActions.next: {
        dispatch(traverseQueue(1));
        break;
      }
      case AfterSaveActions.prev: {
        dispatch(traverseQueue(-1));
        break;
      }
    }
  };

  const showBothResponses = (audit.status === AuditStatuses.Closed
    || audit.status === AuditStatuses.Completed)
    && workspace?.current?.notes !== priorResponse?.notes;

  const onVerMethodChanged = (verMethod: IVerificationMethod,
    isSelected: boolean) => {
    if (isSelected
      && workspace?.current?.selectedVerificationMethods?.some(x => x.id === verMethod.id)) {
      // Already in list.
      return;
    }

    if (!workspace) {
      return;
    }

    const currList = workspace.current?.selectedVerificationMethods || [];

    dispatch(setCurrentWorkspace({
      selectedVerificationMethods: isSelected
        ? currList.concat(verMethod)
        : currList.filter(x => x.id !== verMethod.id),
    }));
  };

  const questionTopicOrSubtopic = question
    .subTopics
    .find(q => audit
      .auditTopics
      .some(a => a.id === q.id))?.name
    || question.topicName;

  // Gather the available answers for this specific question's scoring system.
  const availableAnswers = allAnswers
    .filter(x => x.scoringSystem === question.scoringSystem);

  return (
    <Card
      showHeader={true}
      className="answer-card"
      headerElement={(
        <FlexRow
          className="header-row"
        >
          <LabeledControl
            label={`${questionTopicOrSubtopic} | Question #${question.questionNumber}`}
            isLight={true}
          >
            <span className="answer-card-question-title">{question.question}</span>
          </LabeledControl>

          <LabeledControl
            isLight={true}
            label={`${currQueueIndex + 1}/${queueAQIds.length}`}
          />
        </FlexRow>
      )}
    >
      <FlexCol
        className="col"
      >
        <FlexRow
          className="answer-row"
        >
          <LabeledControl
            label="Answers"
            isRequired={true}
            className="answers"
          >
            <AnswerInput
              uniqueId={question.auditQuestionId}
              value={workspace?.current?.answer}
              allowNA={question.allowNA}
              availableAnswers={availableAnswers}
              onChange={onAnswerChange}
              causalFactor={workspace?.current?.causalFactor}
              isDisabled={isDisabled}
              tooltip={tooltipMessage(audit.status === AuditStatuses.Planned
                && isDisabled,
                "Questions can only be answered when the audit's status is In Progress.")}
            />
          </LabeledControl>
        </FlexRow>
        <FlexRow>
          <LabeledControl
            label="Verification Methods"
            className="verMethods"
          >
            <VerMethodInput
              uniqueId={question.auditQuestionId}
              verMethods={question.verificationMethods}
              selectedVerMethodIds={workspace?.current?.selectedVerificationMethods?.map(x => x.id) || []}
              onVerMethodChanged={onVerMethodChanged}
              isDisabled={isDisabled}
              tooltip={tooltipMessage(audit.status === AuditStatuses.Planned && isDisabled, "Questions can only be answered when the audit's status is In Progress.")}
            />
          </LabeledControl>
        </FlexRow>
        {showBothResponses
          && priorResponse && (
            <LabeledControl
              label="Auditor Notes - Original"
              isRequired={true}
              className="labeled-notes"
            >
              <textarea
                value={priorResponse.notes || ""}
                disabled={true}
                readOnly={true}
              />
            </LabeledControl>
          )}

        <LabeledControl
          label={showBothResponses
            ? "Auditor Notes - Updated"
            : "Auditor Notes"
          }
          isRequired={true}
          className="labeled-notes"
          hint={getTextboxMaxLengthHint(4000)}
          tooltip={tooltipMessage(audit.status === AuditStatuses.Planned && isDisabled, "Questions can only be answered when the audit's status is In Progress.")}
        >
          <textarea
            value={workspace?.current?.notes || ""}
            onChange={e => dispatch(setCurrentWorkspace({
              notes: e.currentTarget.value,
            }))}
            maxLength={4000}
            disabled={showBothResponses || isDisabled}
            readOnly={showBothResponses || isDisabled}
          />
        </LabeledControl>

        <FlexRow>
          <FlexRow
            className="people-interviewed">

            <LabeledControl
              label="People Interviewed"
            >
              <AzureUserPicker
                onApply={items => dispatch(setSelectedPickerItems({
                  pickerKey: peopleInterviewed.key,
                  selectedItems: items.map(x => ({
                    key: x.email,
                    item: x,
                  }))
                }))}
                selectedItems={workspace?.current?.interviewees ?? []}
                allowMultiselect
                isDisabled={isDisabled}
                tooltip={tooltipMessage(audit.status === AuditStatuses.Planned && isDisabled,
                  "Questions can only be answered when the audit's status is In Progress.")}
                title="People Interviewed"
                className="auditee-picker"
                suggestionMode={AzureUserPickerSuggestionMode.Interviewees}
              />
            </LabeledControl>
          </FlexRow>

          <FlexRow>
            {(question.scoringSystem === AuditScoringSystems.CLM || question.scoringSystem === AuditScoringSystems.QMS)
              && <LabeledControl
                label="Findings"
                isRequired={workspace?.current?.answer === 'N'}
              >
                <Button
                  className="text-finding-button"
                  buttonType="secondary"
                  imgPlacement="left"
                  img={arrowIcon}
                  allCaps={false}
                  isDisabled={isDisabled
                    || audit.status !== AuditStatuses.InProgress
                    || workspace?.current?.answer === undefined}
                  onClick={() =>
                    workspace?.current?.answer
                      ? dispatch(openFindingModal({
                        finding: finding,
                        answerCode: workspace.current.answer,
                        mode: finding
                          ? FindingModalModes.Prompt
                          : FindingModalModes.EditCreate,
                        desiredLinks: [{
                          linkId: question.auditQuestionId,
                          linkType: FindingLinkType.AuditQuestion,
                        }],
                        autoSelectedLinksForRemoval: [{
                          linkId: question.auditQuestionId,
                          linkType: FindingLinkType.AuditQuestion,
                        }],
                      }))
                      : undefined
                  }
                >
                  <span
                    className="text-finding-button"
                  >
                    {findingTypeSelected
                      ? findingTypeSelected.name
                      : 'Select'
                    }
                  </span>

                </Button>
              </LabeledControl>
            }
          </FlexRow>
        </FlexRow>

        <FlexRow
          className="space-buttons"
        >
          <button
            className="secondary prev-button default-buttons"
            onClick={() => trySaveThenAct(AfterSaveActions.prev)}
          >
            <img
              alt="Previous Question"
              src={arrowLeftIcon}
              className="icon-small"
            />
            Prev. Question
          </button>
          {isEditable && isResponseDeletable &&
            <button
              className="red default-buttons"
              onClick={() => dispatch(toggleClearingConfirmation(true))}
            >
              Clear Question Response
            </button>
          }
          <button
            className="primary next-button default-buttons"
            onClick={() => trySaveThenAct(AfterSaveActions.next)}
          >
            Next Question
            <img
              alt="Next Questions"
              src={arrowRightIcon}
              className="icon-small"
            />
          </button>
        </FlexRow>
      </FlexCol>

      {
        warningData.isOpen &&
        <MissingFieldsModal
          onNo={() => setWarningData({
            isOpen: false,
            action: AfterSaveActions.next,
          })}
          onYes={() => {
            callAction(warningData.action);

            setWarningData({
              isOpen: false,
              action: AfterSaveActions.next,
            });
          }}
        />
      }

      {
        isClearingConfirmationOpen && (
          <ConfirmModal
            header="Confirm Clearing"
            message={(question.scoringSystem === AuditScoringSystems.CLM || question.scoringSystem === AuditScoringSystems.QMS)
              ? "Would you like to clear the question response, auditor notes, verification methods, people interviewed, and findings?"
              : "Would you like to clear the question response, auditor notes, verification methods and people interviewed?"}
            onNoClicked={() => dispatch(toggleClearingConfirmation(false))}
            onYesClicked={() => {
              dispatch(toggleClearingConfirmation(false));

              if (workspace) {
                if (finding !== undefined) {
                  dispatch(setFindingToDelete(finding));
                }

                dispatch(deleteWorkspaceResponse({
                  auditId: audit.id,
                  auditQuestionId: question.auditQuestionId,
                  workspace: workspace,
                  findingToDelete: finding,
                }));
              }
            }}
          />
        )
      }

      {findingModal.isOpen
        && workspace?.current?.answer &&
        <ManageFindingModal
          isEditable={isEditable}
          onSave={findingToSave => {
            dispatch(setCurrentWorkspace({
              finding: cloneDeep(findingToSave),
            }));
            return true;
          }}
          saveToServerOnSave={false}
          onLinksRemoved={removedLinks => {
            if (finding?.id
              && !finding
                ?.links
                .filter(l => !removedLinks.some(r => r.id === l.id))
                .length) {
              // All links were removed.
              dispatch(removeAllActionsByLink({
                linkId: finding.id.toString(),
                linkType: ActionItemLinkTypes.AuditFinding
              }));
            }

            if (removedLinks
              .some(x => x.linkId === question.auditQuestionId
                && x.linkType === FindingLinkType.AuditQuestion)) {
              dispatch(setWorkspace({
                ...workspace,
                current: {
                  ...workspace.current,
                  finding: undefined,
                },
                original: {
                  ...workspace.original,
                  finding: undefined,
                },
              }));
            }
          }}
        />
      }
    </Card>
  );
};

export default AnswerCard;

export function isResponseMissingRequiredFields(response: IExecutionWorkspaceData | undefined,
  availableAnswers: IAnswer[]) {
  return response
    && (!response.notes
      || !response.answer
      || (availableAnswers.some(x => x.canBeUpdated
        && x.code === response.answer)
        && !response.causalFactor
        && REQUIRE_CAUSAL_FACTOR_WITH_NO_ANSWERS));
}
