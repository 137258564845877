import React from "react";
import { userToString } from "shared/utilities/userUtilities";
import AuditPlanCalendarItemTypes from "types/audit-plan-calendar/IAuditPlanCalendarItemTypes";
import { AuditSources } from "types/auditingTypes";
import { IAuditSchedulerItemData, IQISchedulerItem } from "../../qiSchedulerTypes";
import "./SchedulerItemTooltip.scoped.scss";

interface ISchdulerItemTooltipProps {
  /** The item whose date to show in the tooltip. */
  item: IQISchedulerItem<IAuditSchedulerItemData>,
  /** If true, the tooltip will show a message at the top indicating this item is unselectable. */
  isUnselectable: boolean,
  /** Determines if any items in the scheduler are already selected or not. */
  hasSelections: boolean,
}

const SchedulerItemTooltip: React.FC<ISchdulerItemTooltipProps> = ({
  item,
  isUnselectable,
  hasSelections,
}) => {
  const itemData = item.itemData;

  if (!itemData) {
    return null;
  }

  let unselectableMessage = "";

  if (hasSelections
    && isUnselectable) {
    unselectableMessage = `This item cannot be combined with your current selections. ${itemData.type === AuditPlanCalendarItemTypes.Audit
      ? "Audits cannot be selected."
      : "Only plans of the same type (SA/FP) and plan year may be combined."
      }`;
  }

  return (
    <div className="qi-scheduler-item-tooltip">
      {isUnselectable && (
        <div className="unselectable-message">
          {unselectableMessage}
        </div>
      )}

      {itemData.type === AuditPlanCalendarItemTypes.Plan
        ? renderLabel("Audit Plan", item.id.toString())
        : renderLabel(itemData.auditSource === AuditSources.LegacyQuest
          ? "QUEST Audit"
          : "QI Audit",
          (itemData.auditSource === AuditSources.LegacyQuest
            ? itemData.legacyAuditNumber
            : itemData.auditId?.toString()) || "--")
      }

      {itemData.parentDimensions?.length
        ? Array.from(new Set(itemData.parentDimensions.map(x => x.type)))
          .sort((a, b) => a < b ? -1 : 1)
          .map(type => renderLabel(addSpaceInType(type),
            <>
              {itemData
                .parentDimensions
                .filter(x => x.type === type)
                .map(x => x.text)
                .sort((a, b) => a < b ? -1 : 1)
                .join(', ')}
            </>,
            type
          ))
        : renderLabel(addSpaceInType(itemData.parentDimensionSubType ?? itemData.parentDimensionType), itemData.parentDimensionText)
      }

      {itemData.leadAuditorEmail
        && itemData.leadAuditorEmail !== "unassigned@slb.com"
        && itemData.leadAuditorName
        && renderLabel("Lead Auditor", userToString({ name: itemData.leadAuditorName, email: itemData.leadAuditorEmail }))
      }

      {renderLabel("Status", itemData.auditStatus || itemData.planStatus || "--")}

      {itemData.type === AuditPlanCalendarItemTypes.Audit
        && renderLabel("Compliance", itemData.auditCompliance || "--")}

      {renderLabel("Requirement(s)", itemData.requirementDimensionTexts.join(", "))}
    </div>
  );
};

export default SchedulerItemTooltip;

function renderLabel(label: string, value: React.ReactNode | string, key?: string) {
  return (
    <div
      className="section"
      key={key}
    >
      <b>{label}:</b>

      {typeof value === "string"
        ? <span>{value}</span>
        : value
      }
    </div>
  );
}

function addSpaceInType(type: string): string {
  return type.replace(/^IPM/, "IPM ").replace(/([a-z])([A-Z])/, "$1 $2");
}