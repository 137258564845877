import { Action } from "@reduxjs/toolkit";
import AuditsApi from "api/auditing/AuditsApi";
import { call, put, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { downloadBlob } from "shared/utilities/fileUtilities";
import { exportAudit, finishExportAudit } from "../AuditSlice";

export function* exportAuditAsync() {
  yield takeLatest(exportAudit, function* (action: Action) {
    if (!exportAudit.match(action)) {
      return;
    }

    try {
      const blob: Blob = yield call(AuditsApi.getAuditExcelReport, action.payload);

      downloadBlob(blob, `AuditData-${action.payload}.xlsx`);

      yield put(finishExportAudit());

      yield put(showSuccessToast("Audit data exported successfully. Please check the downloaded Excel file."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));

      yield put(finishExportAudit());
    }
  });
}