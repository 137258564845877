import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getTextboxMaxLengthHint, tooltipMessage } from "shared/utilities/stringUtilities";
import { updateLink, uploadLink } from "store/audit-evidences/AuditEvidencesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import './LinkModal.scoped.scss';
import { isValidUrl, linkFormatter } from "shared/utilities/urlUtilities";

interface ILinkModalProps {
  auditId: string,
  isUploading: boolean,
  onClose(): void,
}

const LinkModal: React.FC<ILinkModalProps> = ({
  auditId,
  isUploading,
  onClose,
}) => {
  const {
    itemToUpdate
  } = useAppSelector(store => store.auditEvidences);
  const dispatch = useAppDispatch();
  const [desc, setDesc] = useState(itemToUpdate?.description || "");
  const [initialDesc] = useState(itemToUpdate?.description || "")
  const[initialLink] = useState(itemToUpdate?.url || "")
  const [link, setLink] = useState(itemToUpdate?.url || "");

  const isSaveDisabled = !desc.trim() || !link.trim();
  const isDirty = initialDesc !== desc || initialLink !== link


  const onSaveClicked = () => {
    // Check to see if the url is valid
    // Need to have http or https or www.
    // Then something.(dot) 
    if (!isValidUrl(link)) {
      dispatch(showErrorToast("Fully qualified link format is required (e.g. https://google.com)."));
      return;
    }

    if (!desc.trim().length
      || !link.trim().length) {
      dispatch(showErrorToast("Both description and a link are required."));
      return;
    }

    if (!!itemToUpdate) {
      dispatch(updateLink({
        id: itemToUpdate.id,
        auditId: Number(auditId),
        description: desc,
        linkId: itemToUpdate.id,
        link: link,
        type: itemToUpdate.type as "Audit" | "Question",
        auditQuestionId: itemToUpdate.auditQuestionId,
      }));
    } else {
      dispatch(uploadLink({
        auditId,
        description: desc,
        link: link,
      }));
    }
  };

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: "AE - Link",
    });
  }, [appInsights]);

  return (
    <Modal
      header={itemToUpdate?.id
        ? "Update Link"
        : "Create Link"
      }
      isOpen={true}
      buttons={[{
        className: "secondary",
        text: "Cancel",
        key: "CANCEL",
        onClick: onClose,
      }, {
        className: "primary",
        text: "Save",
        key: "SAVE",
        title: tooltipMessage(!!!link || isUploading),
        disabled: isSaveDisabled || isUploading || !isDirty,
        onClick: onSaveClicked,
      }]}
    >
      <FlexCol>
        <LabeledControl
          label="Link"
          isRequired={true}
          hint={getTextboxMaxLengthHint(1000)}
        >
          <input
            type="text"
            value={link}
            onChange={e => setLink(e.currentTarget.value)}
            placeholder="Type a link..."
            maxLength={1000}
            onBlur={() => setLink(linkFormatter(link))}
          />
        </LabeledControl>

        <LabeledControl
          label="Description"
          isRequired={true}
          hint={getTextboxMaxLengthHint(1000)}
        >
          <textarea
            value={desc}
            onChange={e => setDesc(e.currentTarget.value)}
            placeholder="Type a description..."
            maxLength={1000}
          />
        </LabeledControl>
      </FlexCol>

      {
        isUploading && (
          <ModalSpinner />
        )
      }
    </Modal >
  );
};

export default LinkModal;

