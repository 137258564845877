import { calcComplianceScore } from "components/audits/common/auditScoreCalculator";
import { countAuditors, getResponseFromQuestion, isQuestionMissingAnswer } from "components/audits/common/auditUtilities";
import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import { userToString } from "shared/utilities/userUtilities";
import { assignAuditorToQuestions, setAudit } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { IAuditQuestion } from "types/auditingTypes";
import AuditeeDropdown from "../auditee-dropdown/AuditeeDropdown";
import AuditorDropdown from "../auditor-dropdown/AuditorDropdown";
import TopicQuestionTable from "../topic-question-table/TopicQuestionTable";
import "./TopicCard.scoped.scss";

interface ITopicCardProps {
  topicId: number,
  title: string,
  editable: EditRestriction,
  questions: IAuditQuestion[],
}

const TopicCard: React.FC<ITopicCardProps> = ({
  topicId,
  title,
  editable,
  questions,
}) => {
  const auditInfo = useAppSelector(store => store.audit.audit);
  const auditScoringSystem = questions?.[0]?.scoringSystem;
  const dispatch = useAppDispatch();

  if (!auditInfo) {
    return null;
  }

  const {
    auditors,
    auditees,
    auditTopicAuditees,
    status: auditStatus,
  } = auditInfo;

  // Restriction applied
  const isDisabled = editable !== EditRestriction.EditAll;

  const answeredCount = auditStatus !== undefined
    && questions
      .filter(x => !isQuestionMissingAnswer(x, auditStatus))
      .length;

  const isTopicAssignmentAvailable = answeredCount !== questions.length;

  const compliance = auditStatus !== undefined
    ? calcComplianceScore(questions
      .map(x => ({
        category: x.category,
        answer: getResponseFromQuestion(x, auditStatus)?.answer,
        isLto: x.licenseToOperate,
      })), auditScoringSystem as AuditScoringSystems)
    : undefined;

  // Build an array of the unique auditor names
  // plus all the undefined ones.
  const topicAuditors = questions
    .map(x => x.auditorEmail === undefined
      ? undefined
      : auditors.find(a => a.email.toLowerCase() === x.auditorEmail?.toLowerCase()));

  let {
    comboText,
    hasUnassigned,
  } = countAuditors(topicAuditors);

  // Find the auditor assigned to this topic (if any).
  const assignedAuditee = auditees
    .find(a => a.email.toLowerCase() === auditTopicAuditees
      ?.find(x => x.auditTopicId === topicId)
      ?.auditeeEmail
      ?.toLowerCase());

  let comboTextAuditee = assignedAuditee !== undefined
    ? userToString({ name: assignedAuditee.name, email: assignedAuditee.email })
    : "Unassigned";

  return (
    <Card
      cardStateId={`summaryTopicCard_${topicId}`}
      defaultCollapsedState={true}
      title={title}
      titleStyle={{
        flex: "none",
        flexBasis: "40%",
      }}
      className="topic-card"
      headerElement={(
        <div
          className="topic-summary"
        >
          <LabeledControl
            label="# of Questions"
            isLight={true}
          >
            <span className="label-content-non-hint">{questions.length} questions</span>
          </LabeledControl>

          <LabeledControl
            label="Completed Questions"
            isLight={true}
          >
            <span className="label-content-non-hint">{answeredCount} questions</span>
          </LabeledControl>

          <LabeledControl
            label="Compliance Score"
            isLight={true}
          >
            <span className="label-content-non-hint">
              {compliance?.overallPercentage === undefined
                ? "-"
                : `${Math.floor(compliance.overallPercentage * 100)}%`
              }
            </span>

          </LabeledControl>

          <LabeledControl

            label="Auditee"
            className="auditee"
          >
            {!isDisabled
              && isTopicAssignmentAvailable
              ? (
                <AuditeeDropdown
                  auditees={auditees}
                  selectedAuditee={assignedAuditee}
                  disabled={isDisabled}
                  onChange={auditee => {
                    let newList = auditInfo.auditTopicAuditees.slice();

                    if (auditee === undefined) {
                      // Unassigned. Remove this audit topic auditee from the audit.
                      newList = newList
                        .filter(x => x.auditTopicId !== topicId);
                    } else {
                      // Assigned to someone else.
                      // Remove this one from the list and add one for the new auditee instead.
                      newList = newList
                        .filter(x => x.auditTopicId !== topicId)
                        .concat({
                          auditTopicId: topicId,
                          auditeeEmail: auditee?.email,
                        });
                    }

                    dispatch(setAudit({ audit: { auditTopicAuditees: newList, }, }));
                  }}
                />
              )
              : (
                <span>
                  {comboTextAuditee}
                </span>
              )}
          </LabeledControl>

          <LabeledControl
            isRequired={true}
            label="Auditor"
            className="auditor"
          >
            {!isDisabled
              && isTopicAssignmentAvailable
              ? (
                <AuditorDropdown
                  auditors={auditors}
                  selectedAuditors={topicAuditors}
                  disabled={isDisabled}
                  onChange={(auditor) => dispatch(assignAuditorToQuestions({
                    auditQuestionIds: questions.map(x => x.auditQuestionId),
                    auditorEmail: auditor?.email,
                  }))}
                  className={hasUnassigned ? "required-error" : undefined}
                  allowClearButton
                />
              )
              : (
                <span>
                  {comboText}
                </span>
              )}
          </LabeledControl>
        </div>
      )}
    >
      <TopicQuestionTable
        questions={questions}
        isDisabled={isDisabled}
        auditors={auditors}
        auditStatus={auditStatus}
      />
    </Card>
  );
};

export default TopicCard;