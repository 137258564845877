import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { call, put } from "redux-saga/effects";
import { ISetPickerErrorAction, ISetPickerItemsAction, ISetPickerSelectedItemsAction, ISetSuggestedPickerItemsAction } from "shared/store/picker/pickerReducerHandlers";
import { IPickerItem } from "shared/types/pickerTypes";
import { showErrorToast } from "shared/store/toast/ToastSlice";

export function* retrieveAndPutPickerData<TItem, TPickerItemType>(apiMethod: (searchValue?: string | undefined) => Promise<TItem[]>,
  itemMapper: (item: TItem) => IPickerItem<TPickerItemType>,
  setPickerItems: ActionCreatorWithPayload<ISetPickerItemsAction<any>, string>,
  setSelectedPickerItems: ActionCreatorWithPayload<ISetPickerSelectedItemsAction<any>, string>,
  setPickerError: ActionCreatorWithPayload<ISetPickerErrorAction, string>,
  pickerKey: string,
  selectFirstIfOnlyOne: boolean,
  searchValue?: string) {
  try {
    const rawItems: TItem[] = yield call(apiMethod, searchValue);
    const items = rawItems.map(itemMapper);
    yield put(setPickerItems({
      pickerKey,
      items,
    }));

    if (items.length === 1
      && selectFirstIfOnlyOne) {
      yield put(setSelectedPickerItems({
        pickerKey,
        selectedItems: items,
      }));
    }

    yield put(setPickerError({
      pickerKey,
      errorMessage: "",
      stopLoading: true,
    }));
  } catch (err) {
    setPickerError({
      pickerKey,
      errorMessage: getResponseErrorMessage(err),
      stopLoading: true,
    });
  }
}

export function* loadAndSetSuggestedPickerItems<ItemType>(apiMethod: () => Promise<ItemType[]>,
  itemMapper: (item: ItemType) => IPickerItem<ItemType>,
  pickerKey: string,
  setSuggestionsAction: ActionCreatorWithPayload<ISetSuggestedPickerItemsAction<ItemType>>) {
  let data: ItemType[] = [];

  try {
    data = yield call(apiMethod);
  } catch (err) {
    yield put(showErrorToast(`Failed to load picker suggestions for ${pickerKey}. ${getResponseErrorMessage(err)}`));
    return;
  }

  const pickerItems = data.map(itemMapper);

  yield put(setSuggestionsAction({
    pickerKey,
    suggestedItems: pickerItems,
  }));
}