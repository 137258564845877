import { authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import { SearchType } from "components/search/SearchPage";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { ICustomAuditSearch, ISaveCustomSearchCreateRequest } from "types/searchAuditTypes";
import { formatAuditFilter } from "../formatters/customSearchAuditFormatter";

class SearchAuditApi {
  public async getAuditFilters(id: number): Promise<ICustomAuditSearch> {
    const response = await authGetJson(config.endpoints.customSearch.getAuditFilters + `/${id}`);

    await throwIfResponseError(response);

    return formatAuditFilter(await response.json());
  }

  public async saveAuditFilters(name: string, searchType: SearchType, filters: ISaveCustomSearchCreateRequest): Promise<number> {
    const response = await authPostJson(config.endpoints.customSearch.saveAuditFilters,
      {
        name,
        searchType: searchType,
        filtersData: filters
      });
    await throwIfResponseError(response);
    return Number(await response.json());
  }

  public async updateAuditFilters(id: number, filters: ISaveCustomSearchCreateRequest, abortSignal?: AbortSignal): Promise<void> {
    const response = await authPutJson(`${config.endpoints.customSearch.updateAuditFilters}/${id}`,
      filters,
      abortSignal);

    await throwIfResponseError(response);
  }

  public async deleteAuditFilters(id: number, abortSignal?: AbortSignal): Promise<void> {
    const response = await authPutJson(`${config.endpoints.customSearch.deleteFilters}/${id}`,
      abortSignal);

    await throwIfResponseError(response);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new SearchAuditApi();
