import { authGetJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IHelpItem } from "types/auditMasterDataTypes";
import { formatHelpItem } from "./helpFormatters";

class HelpApi {
  public async getHelpItem(slug: string): Promise<IHelpItem> {
    const response = await authGetJson(`${config.endpoints.auditMasterData.help}/${slug}`);
    await throwIfResponseError(response);
    const result = await response.json();
    return formatHelpItem(result);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new HelpApi();
