import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { validateSelectedEvidenceFiles } from "components/evidence/manage/ManageEvidenceItemModal";
import React, { useEffect, useRef, useState } from "react";
import HelpButton from "shared/components/common/help-button/HelpButton";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { updateAttachment, uploadAttachment } from "store/audit-evidences/AuditEvidencesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import './AttachmentModal.scoped.scss';

const allowedFileTypes = [
  ".pdf",
  ".doc",
  ".docx",
  ".docm",
  ".odt",
  ".rtf",
  ".csv",
  ".ods",
  ".xls",
  ".xlsx",
  ".xlsb",
  ".xlsm",
  ".odp",
  ".ppt",
  ".pptx",
  ".pptm",
  ".potm",
  ".ppsx",
  ".jpeg",
  ".jpg",
  ".jfif",
  ".html",
  ".htm",
  ".png",
  ".bmp",
  ".tiff",
  ".mpeg",
  ".mpf",
  ".heic",
  ".mov",
  ".mpeg4",
  ".mp4",
  ".avi",
  ".wmv",
  ".heif",
  ".zip",
  ".oft",
  ".msg",
  ".eml",
  ".txt",
];

const maxFileSizeMB = 50;

interface IAttachmentModalProps {
  existingEvidenceFilenames: string[],
  auditId: number,
  isUploading: boolean,
  onClose(): void,
}

const AttachmentModal: React.FC<IAttachmentModalProps> = ({
  existingEvidenceFilenames,
  auditId,
  isUploading,
  onClose,
}) => {
  const itemToUpdate = useAppSelector(store => store.auditEvidences.itemToUpdate);
  const uploadOp = useAppSelector(store => store.auditEvidences.uploadOp);
  const dispatch = useAppDispatch();
  const [desc, setDesc] = useState(itemToUpdate?.description || "");
  const [initialDesc] = useState(itemToUpdate?.description || "");
  const [hasUploadedFile] = useState(itemToUpdate?.filename || false);
  const [fileError, setFileError] = useState("");
  const [hasFile, setHasFile] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);

  const onSaveClicked = () => {
    if (!!itemToUpdate && desc.trim().length) {
      dispatch(updateAttachment({
        auditId,
        id: Number(itemToUpdate?.id),
        parentId: Number(itemToUpdate?.parentId),
        description: desc,
        filename: itemToUpdate.filename || '',
      }));
      return;
    }

    if (!desc.trim().length
      || !fileRef.current?.files?.length) {
      dispatch(showErrorToast("Both description and a file are required."));
      return;
    }

    dispatch(uploadAttachment({
      auditId,
      description: desc,
      files: fileRef.current.files,
    }));
  };

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files?.length) {
      return;
    }

    let filenames = existingEvidenceFilenames.map(x => x.toLowerCase().trim());

    setHasFile(true);

    const fileErrors: string[] = validateSelectedEvidenceFiles(e.currentTarget.files,
      filenames,
      allowedFileTypes,
      maxFileSizeMB);

    setFileError(fileErrors.length
      ? fileErrors.join(" ")
      : "");
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDesc(e.currentTarget.value);
  };

  const isDescUpdated = desc !== initialDesc;
  const hasFileAttached = fileRef.current?.files?.length || hasUploadedFile || hasFile;

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: "AE - New Attachment",
    });
  }, [appInsights]);

  return (
    <Modal
      header="New Attachment"
      isOpen={true}
      buttons={[{
        className: "secondary",
        text: "Cancel",
        key: "CANCEL",
        onClick: onClose,
      }, {
        className: "primary",
        text: "Upload",
        key: "UPLOAD",
        disabled: !!fileError || isUploading || !isDescUpdated || !hasFileAttached,
        onClick: onSaveClicked,
      }]}
    >
      <LabeledControl
        label="Description"
        isRequired={true}
        hint={getTextboxMaxLengthHint(100)}
      >
        <div className="save-attachment-modal-width">
          <textarea
            value={desc}
            onChange={e => onDescriptionChange(e)}
            placeholder="Type a title or description..."
            maxLength={100}
          />
        </div>
      </LabeledControl>


      <LabeledControl
        label={`File `}
        isRequired={true}
        hint={
          <>
            <span>(max file size: {maxFileSizeMB} MB)</span>
            <HelpButton
              helpSlug="fileUploadTypes"
            />
          </>

        }
      >
        <div>
          {!itemToUpdate && (
            <>
              <input
                type="file"
                accept={allowedFileTypes.join(",")}
                onChange={onFileInputChange}
                ref={fileRef}
                className={fileError ? "error" : ""}
                disabled={!!itemToUpdate}
                multiple
              />

              {!!fileError &&
                <span
                  className="too-large"
                >
                  {fileError}
                </span>
              }
            </>
          )}

          {itemToUpdate && (
            <span>
              {itemToUpdate?.filename}
            </span>
          )}
        </div>
      </LabeledControl>

      {uploadOp?.isWorking && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default AttachmentModal;
