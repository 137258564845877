import React, { useCallback, useEffect, useState } from "react";
import { usePopper } from 'react-popper';
import Portal from "shared/components/layout/portal/Portal";
import arrowIcon from "shared/media/dls/arrow-down-2.svg";
import Button, { ButtonImgPlacement, ButtonType } from "../buttons/button/Button";
import "./SplitButton.scoped.scss";

interface ISplitButtonProps {
  /** Determines mode. "button" = fires click event when clicked. "dropdown" = opens dropdown when clicked. */
  mode: "button" | "dropdown",
  /** The display to show in the dropdown. */
  display: React.ReactNode,
  /** Optional. The menu items to display in the dropdown. */
  items?: (ISplitButtonItem | ISplitButtonSeparator)[],
  /** Optional. Called when the user clicks a menu item. Returns the menu item id. */
  onItemClick?(id: string): void,
  /** Optional. If mode = "button", this will be called when the user clicks on the left side of the button. */
  onClick?: () => void,
  /** Optional. If true, the button cannot be clicked. Default = false. */
  isButtonDisabled?: boolean,
  /** Optional. If true, the dropdown cannot be opened and items cannot be selected. Default = false. */
  isDropdownDisabled?: boolean,
  /** Determines button style. */
  buttonType: ButtonType,
  /** Optional. Custom handler to override when user clicks down arrow. If specified, `items` will be ignored and the dev will
   * need to handle opening their own custom dropdown.
  */
  onDropdownOpen?: () => void,
  /** Optional. Allows overriding the rendering of the items in the dropdown popup. The second argument is the function that should be called when the user chooses that item. */
  renderItem?: (item: ISplitButtonItem, onClick: () => void) => React.ReactNode,
}

export interface ISplitButtonItem {
  /** Unique identifier for button item. */
  id: string,
  /** Text to show in the button item. */
  text: string,
  /** Optional. Url of the icon to show in the button. */
  imgUrl?: string,
  /** Optional. If imgUrl is provided, this determines the alignment of the img. Default = "right". */
  imgPlacement?: ButtonImgPlacement,
  /** Optional. If true, item can't be selected. Default = false. */
  isDisabled?: boolean,
  /** Optional. Determines button style. Default = "secondary". */
  buttonType?: ButtonType,
}

export interface ISplitButtonSeparator {
  /** The unique id for this separator. Must be distinct from all other separators and menu items. */
  id: string,
  isSeparator: true,
}

const SplitButton: React.FC<ISplitButtonProps> = ({
  display,
  items,
  onItemClick: onItemClicked,
  onClick,
  isButtonDisabled = false,
  isDropdownDisabled = false,
  mode,
  buttonType,
  onDropdownOpen,
  renderItem,
}: ISplitButtonProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOutside = useCallback((e: any) => {
    if (!popperElement?.contains(e.target)
      && !referenceElement?.contains(e.target)) {
      setIsOpen(false);
    }
  }, [popperElement, referenceElement, setIsOpen]);

  const handleResizeEvent = useCallback(() => {
    update?.();
  }, [update]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [handleResizeEvent]);

  return (
    <>
      <span
        className="split-button"
        ref={setReferenceElement}
      >
        <Button
          onClick={() => {
            if (mode === "button") {
              onClick?.();
            } else if (mode === "dropdown") {
              setIsOpen(!isOpen);
            }
          }}
          buttonType={buttonType}
          isDisabled={isButtonDisabled}
        >
          {display}
        </Button>

        <Button
          onClick={onDropdownOpen
            ? onDropdownOpen
            : () => setIsOpen(!isOpen)}
          buttonType={buttonType}
          img={arrowIcon}
          isDisabled={isDropdownDisabled}
        />
      </span>

      {isOpen && (
        <Portal>
          <div
            ref={setPopperElement}
            {...attributes.popper}
            className="qi-split-button-popup"
            style={{
              ...styles.popper,
              backgroundColor: "white",
            }}
          >
            {items?.map(i => 'isSeparator' in i
              ? <hr key={i.id} />
              : (
                renderItem
                  ? renderItem(i, () => {
                    setIsOpen(false);
                    onItemClicked?.(i.id);
                  }) : (
                    <Button
                      buttonType={i.buttonType ?? "secondary"}
                      onClick={() => {
                        setIsOpen(false);
                        onItemClicked?.(i.id);
                      }}
                      key={i.id}
                      img={i.imgUrl}
                      imgPlacement={i.imgPlacement}
                      isDisabled={i.isDisabled}
                    >
                      {i.text}
                    </Button>
                  )))}
          </div>
        </Portal>
      )}
    </>
  );
};

export default SplitButton;