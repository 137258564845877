import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback, useMemo } from "react";
import { IPickerItem } from "shared/types/pickerTypes";
import { BusinessTeamType, IBusinessTeam, MetaDataTypes } from "types/masterDataTypes";
import NonReduxPickerList from "./non-redux-list-picker/NonReduxPickerList";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IBusinessTeamPickerProps {
  renderMode?: PickerMode,
  onApply: (selectedItems: IBusinessTeam[]) => void,
  selectedItems: IBusinessTeam[],
  allowMultiselect?: boolean,
  showSuggestions?: boolean,
  showTechCenterOnly?: boolean;
  /**
   * Optional. If specified, the picker will render itself using this function. The returned React.ReactNode should
   * call the provided `openPicker` function when clicked to open the picker.
   */
  onRenderPicker?: (openPicker: () => void) => React.ReactNode,
  /** Optional. If specified, businessTeams of this type will not be selectable by the user. (They will be disabled.) */
  disallowedTypes?: BusinessTeamType[],
  /** Optional. If true, the user cannot change selections. */
  isDisabled?: boolean,
  /** Optional. If true, deleted business teams will appear in the picker. Default = false.*/
  includeDeleted?: boolean,
  /** Optional. If true, hidden business teams will appear in the picker. Default = false.*/
  includeHidden?: boolean,
  /** Optional. If provided, each item will call this function to determine if it should be disabled or not. */
  isItemDisabledMapper?: (item: IBusinessTeam) => boolean,
}

const BusinessTeamPicker: React.FC<IBusinessTeamPickerProps & ICommonPickerProps<IBusinessTeam>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  showSuggestions = false,
  showTechCenterOnly = false,
  onRenderPicker,
  disallowedTypes,
  isDisabled = false,
  userProfileMetaRestrictions,
  includeDeleted = false,
  includeHidden = false,
  isItemDisabledMapper,
  showSelectedItems = true,
  hideUnselectableBranches = false,
  enableRequiredErrorDisplay,
  maxSelectedItemsVisible,
  renderSelectedItem,
  renderMode = 'picker',
}) => {
  const renderItem = useCallback((item: IBusinessTeam) => `${item.code} - ${item.name}`, []);

  const restrictedToItems = useMemo(() => userProfileMetaRestrictions
    ?.filter(x => x.type === MetaDataTypes.BusinessTeam) || [],
    [userProfileMetaRestrictions]);

  const loadItems = useCallback(async (_: unknown, abortSignal: AbortSignal) => {
    const result = await MasterDataApi.getBusinessTeams({ includeDeleted, includeHidden, }, abortSignal);

    if (showTechCenterOnly) {
      return result.filter(item => item.code.includes("TECH"));
    }
    return result;
  }, [includeDeleted, includeHidden, showTechCenterOnly]);

  const isDisabledMapper = useCallback((item: IBusinessTeam, ancestorPath: IBusinessTeam[]) => {
    if (disallowedTypes === undefined
      && !restrictedToItems.length
      && !isItemDisabledMapper) {
      return false;
    }

    if (isItemDisabledMapper?.(item, ancestorPath)) {
      return true;
    }

    if (disallowedTypes
      && disallowedTypes.some(x => x === item.type)) {
      // This item is of a disallowed type. Disable it.
      return true;
    }

    if (!restrictedToItems.length) {
      // The user has no businessTeam restriction. This item is not disabled.
      return false;
    }

    return !restrictedToItems.some(x => x.id === item.id);
  }, [disallowedTypes, restrictedToItems, isItemDisabledMapper]);

  const pickerFilterItems = useCallback(<T,>(item: IPickerItem<T>, search: string) => {
    if (item?.item) {
      return renderItem(((item.item as unknown) as IBusinessTeam))
        .toLowerCase()
        .includes(search.toLowerCase());
    }
    return false;
  }, [renderItem]);

  if (renderMode === "picker") {
    return (
      <NonReduxPicker<IBusinessTeam>
        itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderSelectedItem ?? renderItem}
        selectedItems={selectedItems}
        title="Business Team (Basin Hierarchy)"
        allowMultiSelect={allowMultiselect}
        displayMode="tree"
        renderListItem={renderItem}
        childrenMapper={item => item.children || []}
        onLoadSuggestedItems={async (abortSignal) => await MasterDataApi.getSuggestedBusinessTeams(abortSignal)}
        showSuggestedItems={showSuggestions}
        searchOptions={{
          filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
        }}
        onRenderPicker={onRenderPicker}
        isDisabledMapper={isDisabledMapper}
        isDisabled={isDisabled}
        enableRequiredErrorDisplay={enableRequiredErrorDisplay}
        maxSelectedItemsVisible={maxSelectedItemsVisible}
      />
    );
  } else if (renderMode === 'list') {
    return (
      <NonReduxPickerList
        selectedItems={selectedItems}
        renderListItem={renderItem}
        onSelectionChanged={onApply}
        keyMapper={(item) => item.id}
        title="Business Teams"
        onLoadItems={loadItems}
        allowMultiSelect={allowMultiselect}
        tagsProps={[{
          label: "Selected Business Teams",
          renderItem: (item) => item.item ? renderItem(item?.item) : item.text,
        }]}
        childMapper={(item) => item.children || []}
        isDisabledMapper={isDisabledMapper}
        isDisabled={isDisabled}
        showSuggestedItems={showSuggestions}
        showSelectedItems={showSelectedItems}
        hideUnselectableBranches={hideUnselectableBranches}
        filterItems={pickerFilterItems}
      />
    );
  } else {
    return <>Render mode "{renderMode}" is not supported.</>;
  }
};

export default BusinessTeamPicker;
