import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchType } from "components/search/SearchPage";
import pickerHandlers from "shared/store/picker/pickerReducerHandlers";
import { IOperation } from "shared/types/operationTypes";
import { IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { AuditStatuses, IOwnerGroup } from "types/auditingTypes";
import { IAuditGroup, IAuditTopic, IAuditType } from "types/auditMasterDataTypes";
import { ComplianceResults } from "types/auditPlanningTypes";
import { IBusinessView, ICountry, IFacility, IBusinessFunction, IMobileSite, IBusinessTeam } from "types/masterDataTypes";
import { ICustomAuditSearch } from "types/searchAuditTypes";

export interface ISearchAuditsState {
  pickerData: {
    auditTypes: IPickerState<IAuditType>
    auditTopics: IPickerState<IAuditTopic>,
    ownerGroups: IPickerState<IOwnerGroup>,
    auditGroups: IPickerState<IAuditGroup>,
    countries: IPickerState<ICountry>,
    businessViews: IPickerState<IBusinessView>,
    businessTeams: IPickerState<IBusinessTeam>,
    businessFunctions: IPickerState<IBusinessFunction>,
    leadAuditors: IPickerState<IAzureADUser>,
    auditors: IPickerState<IAzureADUser>,
  },
  facilities: IFacility[],
  mobileSites: IMobileSite[],
  auditName: string,
  auditIdentifier: string,
  auditPlanYear: number[],
  startDateStart: number | undefined,
  startDateEnd: number | undefined,
  endDateStart: number | undefined,
  endDateEnd: number | undefined,
  completionDateStart: number | undefined,
  completionDateEnd: number | undefined,
  closureDateStart: number | undefined,
  closureDateEnd: number | undefined,
  allActionsClosed: boolean | undefined,
  complianceAtCompletion: ComplianceResults[],
  complianceAtClosure: ComplianceResults[],
  status: AuditStatuses[],
  id: number | undefined,
  auditId: string | undefined,
  isSaveFiltersModalOpen: boolean,
  loadAuditOperation?: IOperation<void>,
  loadAuditFiltersOperation?: IOperation<ICustomAuditSearch>,
  saveFiltersOperation?: IOperation<number>,
  saveAuditFiltersModal: ISaveAuditFiltersModalData,
  updateFiltersOperation?: IOperation<void>,
  deleteFiltersOperation?: IOperation<void>,
}

export enum SearchAuditsPickerKeys {
  auditTypes = "auditTypes",
  auditTopics = "auditTopics",
  auditGroups = "auditGroups",
  ownerGroups = "ownerGroups",
  status = "status",
  countries = "countries",
  businessViews = "businessViews",
  businessTeams = "businessTeams",
  businessFunctions = "businessFunctions",
  leadAuditors = "leadAuditors",
  auditors = "auditors",
}

export interface ISaveAuditFiltersModalData {
  isOpen: boolean,
}

const initialState: ISearchAuditsState = {
  pickerData: {
    auditTypes: {
      key: SearchAuditsPickerKeys.auditTypes,
      isOpen: false,
      items: [],
      selectedItems: [],
      loadOperation: undefined,
    },
    auditTopics: {
      key: SearchAuditsPickerKeys.auditTopics,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    auditGroups: {
      key: SearchAuditsPickerKeys.auditGroups,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    ownerGroups: {
      key: SearchAuditsPickerKeys.ownerGroups,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    countries: {
      key: SearchAuditsPickerKeys.countries,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessViews: {
      key: SearchAuditsPickerKeys.businessViews,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessTeams: {
      key: SearchAuditsPickerKeys.businessTeams,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessFunctions: {
      key: SearchAuditsPickerKeys.businessFunctions,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    leadAuditors: {
      key: SearchAuditsPickerKeys.leadAuditors,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    auditors: {
      key: SearchAuditsPickerKeys.auditors,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
  },
  mobileSites: [],
  facilities: [],
  auditName: "",
  auditIdentifier: "",
  auditPlanYear: [],
  startDateStart: undefined,
  startDateEnd: undefined,
  endDateStart: undefined,
  endDateEnd: undefined,
  completionDateStart: undefined,
  completionDateEnd: undefined,
  closureDateStart: undefined,
  closureDateEnd: undefined,
  allActionsClosed: undefined,
  complianceAtCompletion: [],
  complianceAtClosure: [],
  status: [],
  id: undefined,
  auditId: undefined,
  isSaveFiltersModalOpen: false,
  loadAuditOperation: undefined,
  loadAuditFiltersOperation: undefined,
  saveFiltersOperation: undefined,
  saveAuditFiltersModal: {
    isOpen: false,
  },
  updateFiltersOperation: undefined,
  deleteFiltersOperation: undefined,
};

export const searchAuditsSlice = createSlice({
  name: "search-audits",
  initialState,
  reducers: {
    ...pickerHandlers,
    /** Sets any provided state property. */
    setSearchPropValue: (state, action: PayloadAction<Partial<ISearchAuditsState>>) => {
      Object.assign(state, action.payload);
    },
    clearFilters: (state) => {
      Object.assign(state, initialState);
    },
    loadAudit: (state, _: PayloadAction<number>) => {
      state.loadAuditOperation = {
        isWorking: true,
      };
    },
    finishLoadingAudit: (state, action: PayloadAction<IOperation<void>>) => {
      if (action.payload.errorMessage) {
        state.loadAuditOperation = action.payload;
        return;
      }

      state.loadAuditOperation = undefined;
    },
    saveFilters: (state, _: PayloadAction<{ filtersName: string, searchType: SearchType }>) => {
      state.saveFiltersOperation = {
        isWorking: true,
      };
    },
    finishSavingFilters: (state, action: PayloadAction<IOperation<number>>) => {
      if (action.payload.errorMessage) {
        state.saveFiltersOperation = action.payload;
        return;
      }

      state.id = action.payload.data;

      state.saveFiltersOperation = undefined;
      state.isSaveFiltersModalOpen = false;
    },
    setSaveFiltersModal: (state, action: PayloadAction<boolean>) => {
      state.saveFiltersOperation = undefined;
      state.isSaveFiltersModalOpen = action.payload;
    },
    loadAuditFilters: (state, action: PayloadAction<number>) => {
      state.loadAuditFiltersOperation = {
        isWorking: true,
      };
    },
    finishLoadAuditFilters: (state, action: PayloadAction<IOperation<ICustomAuditSearch>>) => {
      if (action.payload.errorMessage
        || !action.payload.data) {
        state.loadAuditFiltersOperation = action.payload;
        return;
      }

      const filters = action.payload.data;

      state.id = filters.id;
      state.completionDateStart = filters.completionDateStart;
      state.completionDateEnd = filters.completionDateEnd;
      state.status = filters.status;
      state.facilities = filters.facilities;
      state.mobileSites = filters.mobileSites;
      state.auditName = filters.auditName;
      state.auditIdentifier = filters.auditIdentifier;
      state.auditPlanYear = filters.auditPlanYear;
      state.startDateStart = filters.startDateStart;
      state.startDateEnd = filters.startDateEnd;
      state.endDateStart = filters.endDateStart;
      state.endDateEnd = filters.endDateEnd;
      state.closureDateStart = filters.closureDateStart;
      state.closureDateEnd = filters.closureDateEnd;
      state.complianceAtCompletion = filters.complianceAtCompletion;
      state.complianceAtClosure = filters.complianceAtClosure;
      state.allActionsClosed = filters.allActionsClosed;

      state.loadAuditOperation = undefined;
    },
    updateFilters: (state) => {
      state.updateFiltersOperation = {
        isWorking: true,
      };
    },
    finishUpdateFilters: (state, action: PayloadAction<IOperation<void>>) => {
      if (action.payload.errorMessage) {
        state.updateFiltersOperation = action.payload;
        return;
      }

      state.updateFiltersOperation = undefined;
    },
    deleteFilters: (state) => {
      state.deleteFiltersOperation = {
        isWorking: true,
      };
    },
    finishDeleteFilters: (state, action: PayloadAction<IOperation<void>>) => {
      if (action.payload.errorMessage) {
        state.deleteFiltersOperation = action.payload;
        return;
      }
      
      state.deleteFiltersOperation = undefined;
    },
  }
});

export const {
  openPicker,
  closePicker,
  expandPickerItem,
  collapsePickerItem,
  loadPickerItems,
  loadSuggestedPickerItems,
  setPickerItems,
  setSelectedPickerItems,
  setPickerError,
  setSearchPropValue,
  clearFilters,
  loadAudit,
  finishLoadingAudit,
  saveFilters,
  finishSavingFilters,
  setSaveFiltersModal,
  loadAuditFilters,
  finishLoadAuditFilters,
  updateFilters,
  finishUpdateFilters,
  deleteFilters,
  finishDeleteFilters,
} = searchAuditsSlice.actions;