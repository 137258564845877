interface IQISchedulerCalendarDateCellProps {
  /** If true, the cell will be highlighted as "today". */
  isHighlighted: boolean,
}

const QISchedulerCalendarDateCell = (props: IQISchedulerCalendarDateCellProps) => {
  return (
    <td
      className={`calendar-cell ${props.isHighlighted ? "today" : ""}`}
    ></td>
  );
};

export default QISchedulerCalendarDateCell;