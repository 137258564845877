import UrlRoutes from "components/routing/UrlRoutes";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import HelpButton from "shared/components/common/help-button/HelpButton";
import VideoLinkButton from "shared/components/common/video-link-button/VideoLinkButton";
import Card from "shared/components/layout/card/Card";
import Page from "shared/components/layout/page/Page";
import { tooltipMessage } from 'shared/utilities/stringUtilities';
import { clearSelectedPlans, setCreateAuditModal } from "store/audit-plan-results/AuditPlanResultsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import PlanningSpinner from "../PlanningSpinner";
import AuditPlanningFilters from "../planning-filters/AuditPlanningFilters";
import GridCard from "../planning-grid/grid-card/GridCard";
import GridLegend, { GridLegendMode } from "../planning-grid/grid-legend/GridLegend";
import "./PlanResultsPage.scoped.scss";
import PlanResultsCreateAuditModal from "./create-audit-modal/PlanResultsCreateAuditModal";
import PlanResultsGrid from "./grid-components/PlanResultsGrid";

const PlanResultsPage: React.FC = () => {
  const {
    selectedPlanIds,
  } = useAppSelector(store => store.auditPlanResults);

  const {
    appliedFilters,
    hasDataEverLoaded,
    planResultsPlans,
  } = useAppSelector(store => store.auditPlanning);
  const planYear = appliedFilters?.planYear;
  const auditType = appliedFilters?.auditType;

  const dispatch = useAppDispatch();

  const createAudit = useCallback((planIds: number[]) => {
    const firstPlanWithLeader = planResultsPlans.find(p => planIds.some(x => x === p.id)
      && p.leadAuditorEmail
      && p.leadAuditorName);

    dispatch(setCreateAuditModal({
      isOpen: true,
      planIds: planIds,
      planYear,
      auditType: auditType,
      selectedAuditor: firstPlanWithLeader?.leadAuditorEmail
        && firstPlanWithLeader.leadAuditorName
        ? { email: firstPlanWithLeader.leadAuditorEmail, name: firstPlanWithLeader.leadAuditorName }
        : undefined,
    }));
  }, [dispatch, planResultsPlans, planYear, auditType]);

  let gridComponent: React.ReactNode | undefined = undefined;

  if (!appliedFilters
    || !hasDataEverLoaded) {
    gridComponent = (
      <Banner
        type={BannerType.info}
      >
        Please choose at least one of each required filter above and then click Apply Filters.
      </Banner>
    );
  } else {
    gridComponent = (
      <>
        <PlanResultsGrid
          createAudit={createAudit}
        />
        <GridLegend
          displayMode={GridLegendMode.PlanResults}
          isCLMMode={appliedFilters.perspectiveXAxes[0]?.ownerGroup === "CLM"}
        />
      </>
    );
  }

  return (
    <Page
      title="Audit Plan &amp; Results"
      headerControls={[{
        key: "VIDEO",
        element: (
          <VideoLinkButton
            links={[{
              title: "To create audit from plans and view its result",
              url: "https://slb001.sharepoint.com/sites/QoF-ScrumTeams/_layouts/15/stream.aspx?uniqueId=24869494-eb80-565f-bf97-289b4342827b&portal=%7b%22ha%22:%22classicstream%22%2c%22hm%22:%22view%22%7d&referrer=ClassicStream&referrerScenario=ClassicPortalMigration.View&scenario=2",
              description: "This video provides information on how a Lead Auditor can create an audit from a plan and view its results",
            }]}
          />
        )
      }]}
    >
      <Card>
        <AuditPlanningFilters
          showMoreFilters
          showFacilities
          showPlanStatus
          showBusinessViews
          showLeadAuditors
          showIncludeDeletedAttributes={false}
          showReviewStatus={false}
          showReviewedBy={false}
          showAuditCalendarStatuses={false}
          showCalendarAuditTypes={false}
          showComplianceResults={false}
          showUnassigned={false}
        />
      </Card>

      <GridCard
        centerNode={
          <>
            <Link
              to={UrlRoutes.LegacyNodeMapping.urlTemplate}
              target="_blank"
            >
              Legacy QUEST Node mapping
            </Link>

            <HelpButton
              helpSlug="auditPlanResults"
            />
            <div
              className="audit-buttons"
            >
              <button
                className="secondary"
                disabled={selectedPlanIds.length === 0}
                onClick={() => dispatch(clearSelectedPlans())}
                title={tooltipMessage(selectedPlanIds.length === 0, "Please fill in all fields first.")}
              >
                Clear Selection
              </button>

              <button
                className="primary"
                disabled={selectedPlanIds.length === 0}
                onClick={() => createAudit(selectedPlanIds)}
              >
                Create Audit
              </button>
            </div>
          </>
        }
        page="PlanResults"
      >
        {gridComponent}
      </GridCard >

      <PlanningSpinner />
      <PlanResultsCreateAuditModal />
    </Page >
  );
}

export default PlanResultsPage;
