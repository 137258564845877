import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import arrowIcon from "shared/media/dls/arrow-down-2.svg";
import { IClosePickerAction, ICollapsePickerItemAction, IExpandPickerItemAction, ILoadPickerItemsAction, ILoadSuggestedPickerItemsAction, IOpenPickerAction, ISetPickerItemsAction, ISetPickerSelectedItemsAction } from "shared/store/picker/pickerReducerHandlers";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";
import "./Picker.scoped.scss";
import PickerModal from "./modal/PickerModal";

export interface INonReduxPickerProps<T> {
  /**
   * Placeholder text to show when no items are selected. Defaults to "Select".
   */
  placeholder?: string,
  /**
   * The title text to show in the modal.
   */
  title: string,
  /**
   * The subTitle text to show above the search box.
   *
  */
  subTitle?: React.ReactNode,
  /**
   * The tooltip shown when you hover the picker.
   */
  tooltip?: string,
  /**
   * Disables the picker so that the user cannot interact with it.
   */
  isDisabled?: boolean,
  /**
   * If specified, the picker will render itself as this node rather than the default display.
   */
  pickerNode?: React.ReactNode,
  /**
  * Determines how many selected items can be shown in the picker control. If the number of selected items
  * exceeds this limit, "x selected items" will appear instead. If not set, there is no limit.
  */
  maxSelectedItemsVisible?: number,
  /**
   * If set to true, allows multiple picker items to be selected. Defaults to false.
   */
  allowMultiSelect?: boolean,
  /**
   * Determines display type for the items in the list.
   * Defaults to list.
   */
  displayMode?: "list" | "tree",
  /**
   * When the modal opens, if this is set to true and there are any existing items in redux for this picker,
   * those items will be used instead of dispatching the loadAction.
   */
  preserveItems?: boolean,
  /**
   * When the modal opens, if this is set to true and there are any existing suggested items in redux for this picker,
   * those items will be used instead of dispatching the loadSuggestedItemsAction.
   */
  preserveSuggestedItems?: boolean,
  /**
   * Options for the search bar in the header. If no options are specified, the search bar will appear and filter the local list.
   */
  searchOptions?: {
    /**
     * Determines visibility of the search bar in the modal header.
     * Defaults to true.
     */
    show?: boolean,
    /**
     * Determines whether the search box will call the load action (async) or simply filter the local list (sync).
     * Defaults to "sync".
     */
    behavior?: "async" | "sync",
    /**
     * If specified and > 0, the loadAction will not be dispatched until the user types this many characters into the search box.
     * The search value will then be included in the load action. This option has no effect when loadAction is undefined.
     */
    asyncMinChars?: number,
    /**
     * The number of milliseconds after the search value changes to wait before dispatching the loadAction.
     * This option has no effect unless behavior is "async", "asyncMinChars" is > 0, and loadAction is specified.
     * Defaults to 1000.
     */
    asyncSearchDelay?: number,
  },
  /**
   * The message to show if there are no items available in the picker popup.
   * To show no message at all, pass an empty string.
   * Defaults to "No items were found."
   */
  noItemsMessage?: string,
  /**
   * Determines whether the items in redux should be cleared when the modal closes.
   * Defaults to true.
   */
  clearItemsOnClose?: boolean,
  /**
   * Receives one of the picker items and must return its renderable node for display in the list.
   * If this is not provided, the "text" property of the IPickerItem will be rendered instead.
  */
  renderListItem?: (item: T) => string | React.ReactNode,
  /**
   * Receives one of the picker items and must return its renderable node for display in the selected list when the modal is not open.
   * If this is not provided, the "text" property of the IPickerItem will be rendered instead.
   */
  renderSelectedItem?: (item: T) => string,
  /**
   * The classname to apply to the picker element.
   */
  className?: string,
  /**
   * If true, the picker will render red with red text if it has no selected items.
   */
  enableRequiredErrorDisplay?: boolean,
  /**
   * If true, the picker will show a section for the suggested items.
   */
  showSuggestedItems?: boolean,
  /**
   * Optional. If true, the picker will show the selected items section. Default = true.
   */
  showSelectedItems?: boolean,
  /** Optional. If true and the display mode is 'list', branches of the hierarchy that have no selectable items will be pruned. Default = false. */
  hideUnselectableBranches?: boolean,
  /** Optional. If true, the label above the search box will not be shown. Default = false. */
  hideLabelAboveSearch?: boolean,
}

export type IPickerProps<T> = INonReduxPickerProps<T> & {
  /**
   * The picker state from redux.
   */
  pickerState: IPickerState<T>,
  searchOptions?: {
    /**
      * Receives one of the picker items and a search string and must return whether or not it should be visible.
      * If this is not specified, the picker will attempt to filter by the item's text property.
      */
    filterItem?: (a: IPickerItem<T>, filterValue: string) => boolean,
  },
  /** If specified, this function will be called to sort the items shown in the Selected Items list. */
  itemSorter: (item1: IPickerItem<T>, item2: IPickerItem<T>) => number,
  /**
   * The redux action to dispatch when the user clicks to open the picker modal.
   */
  openAction: ActionCreatorWithPayload<IOpenPickerAction, string>,
  /**
   * The redux action to dispatch when the the user clicks the close button in the modal.
   */
  closeAction: ActionCreatorWithPayload<IClosePickerAction, string>,
  /**
   * The redux action to dispatch to load the picker items.
   * If this is not specified, a load action will never be dispatched.
   * In that case, items from the picker state will always be used instead.
   */
  loadAction?: ActionCreatorWithPayload<ILoadPickerItemsAction, string>,
  /**
   * The redux action the dispatch to set the picker items in redux.
   * This will only be called when the user closes the modal and only if clearItemsOnClose is true.
   */
  setItemsAction?: ActionCreatorWithPayload<ISetPickerItemsAction<T>, string>,
  /**
   * The redux action to dispatch when the user clicks the apply button.
   */
  setSelectedItemsAction?: ActionCreatorWithPayload<ISetPickerSelectedItemsAction<T>, string>,
  /**
   * If the redux action setSelectedItemsAction is not specified, it will use this callback instead
   * when items are selected.
   */
  setSelectedItems?: (items: IPickerItem<T>[]) => void,
  /**
   * The redux action to dispatch when the user expands an item.
   * This should only be specified when displayMode is "tree".
   */
  expandItemsAction?: ActionCreatorWithPayload<IExpandPickerItemAction, string>,
  /**
   * The redux action to dispatch when the user collapses an item.
   * This should only be specified when displayMode is "tree".
   */
  collapseItemsAction?: ActionCreatorWithPayload<ICollapsePickerItemAction, string>,
  /**
   * The redux action to dispatch to load the suggested picker items.
   * If this is not specified, a suggested load action will never be dispatched.
   * In that case, suggested items from the picker state will always be used instead.
  */
  loadSuggestionsAction?: ActionCreatorWithPayload<ILoadSuggestedPickerItemsAction, string>,
}

const Picker = <T,>(props: IPickerProps<T>) => {
  const {
    pickerState: {
      key,
      isOpen,
      items,
      selectedItems,
      suggestedItems,
    },
    placeholder,
    tooltip,
    pickerNode,
    renderSelectedItem,
    isDisabled,
    maxSelectedItemsVisible,
    preserveItems,
    preserveSuggestedItems,
    searchOptions,
    clearItemsOnClose,
    openAction,
    loadAction,
    setItemsAction,
    className,
    loadSuggestionsAction,
    enableRequiredErrorDisplay,
  } = props;

  const dispatch = useDispatch();
  const [localSelectedItems, setLocalSelectedItems] = useState<IPickerItem<T>[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const searchBehavior = searchOptions?.behavior;
  const asyncSearchMinChars = searchOptions?.asyncMinChars;
  const asyncSearchDelay = searchOptions?.asyncSearchDelay;

  useEffect(() => {
    setLocalSelectedItems([...selectedItems]);
  }, [selectedItems]);

  useEffect(() => {
    setSearchValue("");
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen
      && setItemsAction
      && !preserveItems
      && (clearItemsOnClose === undefined
        || clearItemsOnClose)) {
      dispatch(setItemsAction({
        pickerKey: key,
        items: [],
      }));
    }
  }, [isOpen, dispatch, setItemsAction, clearItemsOnClose, key, preserveItems]);

  useEffect(() => {
    let timeoutId: number | undefined = undefined;

    if (searchBehavior !== "async"
      || !asyncSearchMinChars
      || !loadAction
      || searchValue.trim().length < asyncSearchMinChars) {
      return;
    }

    timeoutId = window.setTimeout(() => {
      dispatch(loadAction({
        pickerKey: key,
        searchValue: searchValue,
      }));
    }, asyncSearchDelay ?? 1000);

    return () => {
      window.clearTimeout(timeoutId);
    }
  }, [searchValue, dispatch, loadAction, key, searchBehavior, asyncSearchMinChars, asyncSearchDelay]);

  const anySelected = selectedItems.length > 0;

  const onOpen = () => {
    dispatch(openAction({
      pickerKey: key,
    }));

    if (loadAction
      && (!preserveItems
        || !items.length)) {
      if (!(asyncSearchMinChars !== undefined
        && asyncSearchMinChars > 0)) {
        dispatch(loadAction({
          pickerKey: key,
        }));
      }
    }

    if (loadSuggestionsAction
      && (!suggestedItems?.length
        || !preserveSuggestedItems)) {
      dispatch(loadSuggestionsAction({
        pickerKey: key,
      }));
    }
  }

  const renderSelectedItems = () => {
    if (maxSelectedItemsVisible !== undefined
      && selectedItems.length > maxSelectedItemsVisible) {
      return `${selectedItems.length} selected item${selectedItems.length !== 1
        ? "s"
        : ""}`;
    }

    return selectedItems
      .map(x => (renderSelectedItem
        && x.item
        ? renderSelectedItem(x.item)
        : x.text)
        || "")
      .sort((a, b) => a < b
        ? -1
        : 1)
      .join('; ');
  }

  let hasRequiredError = !isDisabled
    && enableRequiredErrorDisplay
    && selectedItems.length === 0;

  const onClose = () => dispatch(props.closeAction({
    pickerKey: props.pickerState.key,
  }));

  return (
    <>
      {pickerNode !== undefined
        ? pickerNode
        : (
          <div
            className={`input picker ${isDisabled
              ? "disabled"
              : ""
              } ${className === undefined
                ? ""
                : className
              } ${hasRequiredError
                ? "required-error"
                : ""}`}
            title={tooltip}
            onClick={isDisabled
              ? undefined
              : onOpen
            }
          >
            <div
              className={`labels ${!anySelected
                && !hasRequiredError
                ? "placeholder"
                : ""}`
              }
            >
              {anySelected
                ? renderSelectedItems()
                : (placeholder || "Select")
              }
            </div>
            <img
              src={arrowIcon}
              alt=""
              className="icon-small"
            />
          </div>
        )}
      {isOpen &&
        <PickerModal
          localSelectedItems={localSelectedItems}
          setLocalSelectedItems={setLocalSelectedItems}
          pickerProps={props}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          availableItems={props.pickerState.items}
          itemSorter={props.itemSorter}
          suggestedItems={props.pickerState.suggestedItems}
          filterItem={props.searchOptions?.filterItem}
          isLoading={props.pickerState.loadOperation?.isWorking}
          loadError={props.pickerState.loadOperation?.errorMessage}
          isLoadingSuggestions={props.pickerState.loadSuggestionsOperation?.isWorking}
          loadSuggestionsError={props.pickerState.loadSuggestionsOperation?.errorMessage}
          onClose={onClose}
          onCancel={() => {
            setLocalSelectedItems([...props.pickerState.selectedItems]);
            onClose();
          }}
          onApply={(selItems: IPickerItem<T>[]) => {
            if (props.setSelectedItemsAction) {
              dispatch(props.setSelectedItemsAction({
                pickerKey: props.pickerState.key,
                selectedItems: selItems,
              }));
            } else if (props.setSelectedItems) {
              props.setSelectedItems(selItems);
            }
            onClose();
          }}
          onItemExpanded={(item: IPickerItem<T>, ancestryPath?: (string | number)[]) => {
            if (!props.expandItemsAction) {
              return;
            }

            dispatch(props.expandItemsAction({
              pickerKey: props.pickerState.key,
              itemKey: item.key,
              ancestryPath,
            }));
          }}
          onItemCollapsed={(item: IPickerItem<T>, ancestryPath?: (string | number)[]) => {
            if (!props.collapseItemsAction) {
              return;
            }

            dispatch(props.collapseItemsAction({
              pickerKey: props.pickerState.key,
              itemKey: item.key,
              ancestryPath,
            }));
          }}
        />
      }
    </>
  );
};

export default Picker;
