import { isQuestionMissingAnswer } from "components/audits/common/auditUtilities";
import React from "react";
import { questionHasRequiredActionItem } from "shared/utilities/actionItemUtilities";
import pluralize from "shared/utilities/pluralize";
import { tooltipMessage } from "shared/utilities/stringUtilities";
import { setConfirmAuditStatusChangeModal } from "store/audit/AuditSlice";
import { getAuditScoringSystem } from "store/audit/reducers/auditInfoReducers";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemStatuses, IActionItem } from "types/actionItemTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";

const CloseAuditButton: React.FC = () => {
  const audit = useAppSelector(store => store.audit.audit);
  const questions = useAppSelector(store => store.audit.questions);
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const answers = useAppSelector(store => store.audit.answers);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const dispatch = useAppDispatch();

  const isEditable = auditPageAuth.editRestriction === EditRestriction.EditAll;

  if (!audit
    || !isEditable) {
    return null;
  }

  const scoringSystem = getAuditScoringSystem(audit);

  const answerCodesThatCanBeUpdated = answers
    .filter(x => x.canBeUpdated
      && x.scoringSystem === scoringSystem)
    .map(x => x.code);

  const actionsToValidate = actionItems
    .filter(x => x.isValidated === false
      && x.type?.name !== "Administrative");

  const questionsToUpdate = questions
    .filter(q => q.responses
      .some(response => response.auditStatus === AuditStatuses.Completed
        && answerCodesThatCanBeUpdated.includes(response.answer)));

  // Unable to close if any questions are missing an answer.
  let hasUnansweredQuestionsOrMissingActions = questions
    .some(q => isQuestionMissingAnswer(q, audit.status)
      || questionHasRequiredActionItem(q,
        audit.status,
        actionItems,
        answers
          .filter(x => x.requiresRWP)
          .map(x => x.code)) === false);

  let hasUnclosedActionsThatMustBeClosed = actionItems.some(x => x.status === ActionItemStatuses.Open);

  return (
    <button
      className="primary"
      onClick={() => dispatch(setConfirmAuditStatusChangeModal({
        isOpen: true,
        auditId: audit.id,
        message: getConfirmationMessage(actionsToValidate, questionsToUpdate),
        nextStatus: AuditStatuses.Closed,
      }))}
      title={getButtonTooltip(hasUnansweredQuestionsOrMissingActions, hasUnclosedActionsThatMustBeClosed)}
      disabled={hasUnansweredQuestionsOrMissingActions
        || hasUnclosedActionsThatMustBeClosed}
    >
      Close Audit
    </button >
  );
};

export default CloseAuditButton;

function getButtonTooltip(hasUnansweredQuestionsOrMissingActions: boolean,
  hasUnclosedActionsThatMustBeClosed: boolean) {
  return hasUnansweredQuestionsOrMissingActions
    ? tooltipMessage(hasUnansweredQuestionsOrMissingActions,
      "All Questions must be answered and all Non-Compliant Questions must have a preventative or corrective action item assigned.")
    : tooltipMessage(hasUnclosedActionsThatMustBeClosed,
      "All Action Items must be closed.");
}

function getConfirmationMessage(actionsToValidate: IActionItem[],
  questionsToUpdate: IAuditQuestion[]) {
  let pendingActions: string[] = [];

  if (actionsToValidate.length) {
    pendingActions.push(`${actionsToValidate.length} action ${pluralize(actionsToValidate.length, "item", "items")} to be validated`);
  }

  if (questionsToUpdate.length) {
    pendingActions.push(`${questionsToUpdate.length} ${pluralize(questionsToUpdate.length, "question", "questions")} to be updated`);
  }

  if (!pendingActions.length) {
    return "Are you sure you want to close the audit?\n Audit cannot be edited once closed.";
  }

  return "You have the following pending actions:\n\n"
    + pendingActions.join("\n")
    + "\n\n Do you still want to close the audit?\n Audit cannot be edited once closed.";
}