import { getResponseFromQuestion } from "components/audits/common/auditUtilities";
import { FindingLinkType, IFinding, IFindingType } from "store/audit/reducers/findingReducers";
import { IAnswer } from "types/auditMasterDataTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";

/**
 * Checks if a question is assigned a required finding.
 * @param question The question to check.
 * @param findings The list of all findings in the audit.
 * @param auditStatus The current status of the audit.
 * @param allAnswers The list of all answer codes available to the audit.
 * @returns True if the question requires a finding and doesn't have one assigned. False otherwise.
 */
export function isMissingRequiredFinding(question: IAuditQuestion,
  findings: IFinding[],
  auditStatus: AuditStatuses,
  allAnswers: IAnswer[]) {
  if (question.scoringSystem !== AuditScoringSystems.QMS) {
    // Only QMS requires findings. So if it is any other scoring system,
    // return false -- the question is not missing a required finding.
    return false;
  }

  const currResponse = getResponseFromQuestion(question, auditStatus);
  const hasFinding = findings
    .some(f =>
      f.links.some(x =>
        x.linkId === question.auditQuestionId
        && x.linkType === FindingLinkType.AuditQuestion));

  if (hasFinding) {
    // This question is OK. It has a finding.
    return false;
  }

  const questionIsNonCompliant = allAnswers
    .some(x => x.canBeUpdated
      && x.scoringSystem === question.scoringSystem
      && x.code === currResponse?.answer);

  return questionIsNonCompliant
    && !hasFinding;
}

/**
 * Determines if the provided scoring system allows finding assignments.
 * @param scoringSystem The scoring system to check.
 * @returns True if findings are allowed. False otherwise.
 */
export function doesScoringSystemAllowFindings(scoringSystem: AuditScoringSystems | string) {
  return scoringSystem === AuditScoringSystems.CLM
    || scoringSystem === AuditScoringSystems.QMS;
}

/**
 * Checks if the provided answer codes can be combined into a single finding.
 * @param answerCodes The answer codes to check for combination.
 * @param allFindingTypes The list of all finding types available.
 * @returns True if all answers can be combined together. False otherwise.
 */
export function canAnswersBeCombinedForFindingAssignment(answerCodes: (string | undefined)[],
  allFindingTypes: IFindingType[]) {
  if (!answerCodes.length) {
    return true;
  }

  // Get the distinct list of answer codes.
  const distinctAnswers = Array.from(new Set(answerCodes));

  if (distinctAnswers.length === 1) {
    // They are all the same answer code. Of course they can be combined.
    return true;
  }

  // Get the finding types available to the first answer. Make it a string for easy comparison.
  let compareFindingTypes = getFindingTypesForAnswerCode(distinctAnswers[0],
    allFindingTypes)
    .toString();

  // Now check all OTHER answer code to see if they have the same list.
  for (let i = 1; i < distinctAnswers.length; i++) {
    if (getFindingTypesForAnswerCode(distinctAnswers[i],
      allFindingTypes)
      .toString() !== compareFindingTypes) {
      return false;
    }
  }

  return true;
}

/**
 * Finds the finding types allowed for the specified answer code.
 * @param answerCode Answer code to find finding types for.
 * @param allFindingTypes All available finding types.
 * @returns The array of finding types available to the answer code.
 */
export function getFindingTypesForAnswerCode(answerCode: string | undefined,
  allFindingTypes: IFindingType[]): IFindingType[] {
  if (!answerCode) {
    return [];
  }

  return allFindingTypes
    .filter(x => x.answerAssociations
      .some(a => a.answerCode === answerCode))
    .sort((a, b) => a.id < b.id ? -1 : 1);
}

/**
 * Finds the list of questions assigned to the specified finding.
 * @param finding The finding to search for question links.
 * @param allQuestions All questions to search against.
 * @returns The array of questions linked to this finding.
 */
export function findQuestionsAssignedToFinding(finding: IFinding,
  allQuestions: IAuditQuestion[]) {
  return allQuestions.filter(q =>
    finding.links.some(l => l.linkId === q.auditQuestionId
      && l.linkType === FindingLinkType.AuditQuestion));
}