import MyActionsPage from "components/actions/MyActionsPage";
import React from "react";
import { Route } from "react-router-dom";
import { IRouteRoleMap } from "types/authTypes";
import RequireAuth from "../RequireAuth";
import UrlRoutes from "../UrlRoutes";

interface IActionRoutesProps {
  routeMatrix: IRouteRoleMap[],
}

const ActionRoutes: React.FC<IActionRoutesProps> = ({
  routeMatrix,
}) =>
  <>
    <Route
      path={UrlRoutes.MyActions.urlTemplate}
      element={
        <RequireAuth
          roles={routeMatrix.find(x => x.route === UrlRoutes.MyActions.urlTemplate)?.requiredRoles}>
          <MyActionsPage />
        </RequireAuth>
      }
    />
  </>;

export default ActionRoutes;