import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import ErrorWithRetry from "shared/components/common/error-with-retry/ErrorWithRetry";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import SplitButton, { ISplitButtonItem, ISplitButtonSeparator } from "shared/components/controls/split-button/SplitButton";
import deleteIcon from "shared/media/dls/delete.svg";
import saveAsIcon from "shared/media/dls/save-as.svg";
import saveIcon from "shared/media/dls/save.svg";
import { deletePlanningView, loadPlanningViews, savePlanningView, setCurrentPlanningView, setIsDeleteModalOpen, setSaveViewModal } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IUserPlanningView } from "types/audit-planning-shared/UserPlanningViews";
import "./UserPlanningViewPicker.scoped.scss";
import SaveViewModal from "./save-view-modal/SaveViewModal";

interface IUserPlanningViewPickerProps {
  /** Sets which page this component is being rendered on. */
  page: "FacilityAttributes" | "PlanApprovals" | "PlanResults",
}

const UserViewPicker: React.FC<IUserPlanningViewPickerProps> = ({
  page,
}: IUserPlanningViewPickerProps) => {
  const appliedFilters = useAppSelector(store => store.auditPlanning.appliedFilters);
  const currentPlanningView = useAppSelector(store => store.auditPlanning.currentPlanningView);
  const userPlanningViews = useAppSelector(store => store.auditPlanning.userPlanningViews);
  const loadPlanningViewsOp = useAppSelector(store => store.auditPlanning.loadPlanningViewsOp);
  const isDeleteModalOpen = useAppSelector(store => store.auditPlanning.isDeleteModalOpen);
  const savePlanningViewsOp = useAppSelector(store => store.auditPlanning.savePlanningViewsOp);
  const deletePlanningViewsOp = useAppSelector(store => store.auditPlanning.deletePlanningViewsOp);
  const loadPlanningViewValuesOp = useAppSelector(store => store.auditPlanning.loadPlanningViewValuesOp);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadPlanningViews());
  }, [dispatch]);

  let mainElement: React.ReactNode;

  if (loadPlanningViewsOp?.isWorking) {
    mainElement = <Spinner />;
  } else if (loadPlanningViewsOp?.errorMessage) {
    mainElement = (
      <ErrorWithRetry
        tooltip={loadPlanningViewsOp?.errorMessage}
        onRetryClicked={() => {
          dispatch(loadPlanningViews());
        }}
      />
    );
  } else {
    let savedViewItems: (ISplitButtonItem | ISplitButtonSeparator)[] = [{
      id: "save",
      text: "Save View",
      imgUrl: saveIcon,
      imgPlacement: "right",
      isDisabled: !appliedFilters,
    }, {
      id: "saveAs",
      text: "Save View As",
      imgUrl: saveAsIcon,
      imgPlacement: "right",
      isDisabled: !appliedFilters,
    }, {
      id: "delete",
      text: "Delete View",
      buttonType: "red",
      imgUrl: deleteIcon,
      imgPlacement: "right",
      isDisabled: currentPlanningView === undefined,
    }];

    if (userPlanningViews.length) {
      savedViewItems = savedViewItems.concat([
        {
          id: "sep1",
          isSeparator: true,
        }, ...userPlanningViews.map(userPlanningView => ({
          id: `view_${userPlanningView.id}`,
          text: userPlanningView.name,
        }))]);
    }

    mainElement = (
      <SplitButton
        mode="dropdown"
        buttonType="secondary"
        display={currentPlanningView?.name ?? "(saved views)"}
        items={savedViewItems}
        onItemClick={id => handleMenuSelect(id, dispatch, currentPlanningView, userPlanningViews, page)}
      />
    );
  }

  return (
    <div
      className="user-planning-view-picker"
    >
      {mainElement}

      <SaveViewModal />

      {currentPlanningView
        && isDeleteModalOpen &&
        <ConfirmModal
          header="Confirm Delete"
          message={
            <>
              Are you sure you want to delete the saved view <b>{currentPlanningView?.name}</b>?
            </>
          }
          onYesClicked={() => dispatch(deletePlanningView({
            userPlanningViewId: currentPlanningView.id,
          }))}
          onNoClicked={() => dispatch(setIsDeleteModalOpen(false))}
          showCloseButton
          onCloseButtonClicked={() => dispatch(setIsDeleteModalOpen(false))}
        />
      }

      {(savePlanningViewsOp
        || deletePlanningViewsOp
        || loadPlanningViewValuesOp) &&
        <ModalSpinner />
      }
    </div>
  );
};

export default UserViewPicker;

function handleMenuSelect(itemId: string,
  dispatch: Dispatch<AnyAction>,
  currentPlanningView: IUserPlanningView | undefined,
  userPlanningViews: IUserPlanningView[],
  page: "FacilityAttributes" | "PlanApprovals" | "PlanResults") {
  switch (itemId) {
    case "save": {
      // If there is a currently-selected planning view, immediately save it.
      if (currentPlanningView) {
        dispatch(savePlanningView({
          viewName: currentPlanningView.name,
          saveType: "save",
        }));
        return;
      } else {
        // Otherwise, show the save modal.
        dispatch(setSaveViewModal({
          isOpen: true,
          viewName: "",
          saveType: "save",
        }));
      }
      break;
    }
    case "saveAs": {
      // Always show the "save as" modal.
      dispatch(setSaveViewModal({
        isOpen: true,
        viewName: "",
        saveType: "saveAs",
      }));
      break;
    }
    case "delete": {
      // Show the delete modal.
      dispatch(setIsDeleteModalOpen(true));
      break;
    }
    default: {
      // If the user selected a planning view, select it!
      const planningView = userPlanningViews
        .find(x => x.id === Number(itemId.split("_")?.[1]));

      if (planningView) {
        dispatch(setCurrentPlanningView({
          planningView,
          page,
        }));
      }
    }
  }

}