import React from "react";
import { AuditStatuses } from "types/auditingTypes";
import "./AuditStatusChip.scoped.scss";

interface IAuditStatusChipProps {
  /** The status to display. */
  status: AuditStatuses,
}

/** Shows the rounded bordered status chip for a specified audit status. */
const AuditStatusChip: React.FC<IAuditStatusChipProps> = ({ status }) =>
  <span
    className={`audit-status-chip ${status}`}
  >
    {status.toString().replace(/([A-Z])/g, " $1").trim()}
  </span>;

export default AuditStatusChip;