import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import config from "config";
import React, { useCallback } from "react";
import { showErrorToast, showWarningToast } from "shared/store/toast/ToastSlice";
import { deselectPlanId, selectPlanId } from "store/audit-plan-results/AuditPlanResultsSlice";
import { toggleYAxisItem } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAuditPlanView } from "types/auditPlanningTypes";
import { AuditSources } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import PlanningGrid, { IPlanningGridCellClickArgs, IPlanningItem, IRenderCommandCellArgs } from "../../planning-grid/PlanningGrid";
import PlanningLeftCell from "../../planning-grid/left-cell/PlanningLeftCell";
import PlanResultsGridCell from "./grid-cell/PlanResultsGridCell";

interface IPlanResultsGrid {
  createAudit(planIds: number[]): void
}

const PlanResultsGrid: React.FC<IPlanResultsGrid> = ({
  createAudit
}) => {
  const selectedPlanIds = useAppSelector(store => store.auditPlanResults.selectedPlanIds);
  const {
    xAxisData,
    planApprovalsResultsYAxisData: yAxisData,
    planResultsPlans: plans,
  } = useAppSelector(store => store.auditPlanning);

  const dispatch = useAppDispatch();

  const onToggleRow = useCallback((args: IRenderCommandCellArgs) => {
    dispatch(toggleYAxisItem({
      isExpanded: !args.row.isExpanded,
      item: args.row,
      shared: true,
    }))
  }, [dispatch]);

  const getParentChildDimensions = (row: IPlanningItem, parentRow: IPlanningItem | undefined) => {
    if (parentRow?.isPlannable) {
      return {
        childDimension: row,
        parentDimension: parentRow,
      };
    } else {
      return {
        childDimension: undefined,
        parentDimension: row,
      };
    }
  }

  const getPlansByDimensions = (parentDimension: IPlanningItem, childDimension?: IPlanningItem) => {
    return plans.filter(x => x.parentDimensionId === parentDimension.id
      && x.parentDimensionType === parentDimension.type
      && x.childDimensionId === childDimension?.id
      && x.childDimensionType === childDimension?.type);
  }

  const onClickPlannedCell = (args: IPlanningGridCellClickArgs) => {
    if (!args.plan) {
      return;
    }

    if (args.plan.auditId
      || args.plan.legacyAuditNumber) {
      if (selectedPlanIds.length > 0) {
        dispatch(showWarningToast("The clicked plan already has an audit."));
        return;
      }

      if (args.plan.auditId
        && args.plan.auditSource === AuditSources.QoF) {
        window.open(formatRoute(UrlRoutes.AuditSummary, { auditId: args.plan.auditId.toString() }));
      } else if (args.plan.legacyAuditNumber
        && args.plan.auditSource === AuditSources.LegacyQuest) {
        window.open(config.endpoints.legacyQuest.audit.replace(":id", args.plan.legacyAuditNumber));
      }
      return;
    }

    if (selectedPlanIds.indexOf(args.plan.id) > -1) {
      dispatch(deselectPlanId(args.plan.id));
    } 
    else {
      if (selectedPlanIds.length === 0 && args.requirement.type === MetaDataTypes.AuditTemplate) {
        dispatch(selectPlanId(args.plan.id));
        createAudit([args.plan.id]);
      } else if (selectedPlanIds.length !== 0 && plans.some(x => selectedPlanIds.some(y => y === x.id) && x.requirementDimensionType === MetaDataTypes.AuditTemplate)) {
        dispatch(showErrorToast("Template cannot be group with another Template or Topic"));
      } else if (selectedPlanIds.length !== 0 && args.requirement.type === MetaDataTypes.AuditTemplate) {
        dispatch(showErrorToast("Template cannot be group with another Template or Topic"));
      } else {
        dispatch(selectPlanId(args.plan.id));
      }
    }
  }

  return (
    <>
      <PlanningGrid
        requirements={xAxisData}
        rows={yAxisData}
        options={{
          leftCommandCellOptions: {
            isVisible: true,
            isSticky: true,
            renderCell: (args) => {
              return {
                node: (
                  <PlanningLeftCell
                    renderCommandCellArgs={args}
                    isCollapsible={true}
                    onToggleRow={onToggleRow}
                  />
                ),
              };
            },
          },
          renderDataCell: (args) => {
            let plan: IAuditPlanView | undefined = undefined;

            if (args.row.isPlannable) {
              const dimensions = getParentChildDimensions(args.row, args.parentRow);

              plan = getPlansByDimensions(dimensions.parentDimension, dimensions.childDimension)
                .find(x => x.requirementDimensionId === args.requirement.id
                  && x.requirementDimensionType === args.requirement.type);

              if (plan?.deleted
                && plan?.source === "Manual") {
                plan = undefined;
              }
            }

            return {
              node: (
                <PlanResultsGridCell
                  plan={plan}
                  planningItem={args.row}
                  parentPlanningItem={args.parentRow}
                  requirement={args.requirement}
                  isPlannable={args.row.isPlannable}
                  gridHasSelection={selectedPlanIds.length > 0}
                  isSelected={plan !== undefined
                    && selectedPlanIds.indexOf(plan.id) > -1
                  }
                  onCellClick={args => {
                    if (!args.row.isPlannable) {
                      return;
                    }

                    if (!args.plan) {
                      return;
                    } else {
                      onClickPlannedCell(args);
                    }
                  }}
                />
              )
            };
          },
        }}
      />
    </>
  );
}

export default PlanResultsGrid;
