export interface IPlanningAdministrationPeriodProps {
  startMonth: number,
  startDay: number,
  endMonth: number,
  endDay: number,
}

export default class PlanningAdministrationPeriod {
  public startMonth: number;
  public startDay: number;
  public endMonth: number;
  public endDay: number;

  constructor(props: IPlanningAdministrationPeriodProps) {
    this.startMonth = props.startMonth;
    this.startDay = props.startDay;
    this.endMonth = props.endMonth;
    this.endDay = props.endDay;
  }

  public checkDateInRange(date: Date) {
    let currentDate = new Date();

    let ranges = this.getDateRanges(date.getFullYear());
    return ranges.some(x => currentDate >= x.start && currentDate <= x.end);
  }

  public getStartDate(year: number) {
    return new Date(year, this.startMonth - 1, this.startDay);
  }

  public getEndDate(year: number) {
    return new Date(year, this.endMonth - 1, this.endDay);
  }

  public getDateRanges(year: number) {
    let ranges = [];

    let start = this.getStartDate(year);
    let end = this.getEndDate(year);

    if (start < end) {
      ranges.push({ start, end });
    } else if (end < start) {
      ranges.push({ start, end: new Date(year, 11, 31, 23, 59, 59) });
      ranges.push({ start: new Date(year, 0, 1), end });
    }

    return ranges;
  }
}


