import ExportAuditButton from "components/audits/common/export-audit-button/ExportAuditButton";
import React from "react";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import { toggleNotifyTeamModal, toggleReportModal } from "store/audit-report/AuditReportSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditStatuses, IAuditReportGeneration } from "types/auditingTypes";
import { IIdNameObject } from "types/commonTypes";
import NotifyTeamModal from "../notify-team-modal/NotifyTeamModal";
import ReportModal from "../report-modal/ReportModal";
import "./ActionsCard.scoped.scss";

interface IActionsCardProps {
  auditId: number,
  auditStatus: AuditStatuses,
  topics: IIdNameObject[],
  onCloseReportModal(): void,
  onGenerateReport(options: IAuditReportGeneration): void,
  showCreateReportButton?: boolean,
  showNotifyTeamButton?: boolean,
}

const ActionsCard: React.FC<IActionsCardProps> = ({
  auditId,
  auditStatus,
  topics,
  onCloseReportModal,
  onGenerateReport,
  showCreateReportButton,
  showNotifyTeamButton,
}) => {
  const isReportModalOpen = useAppSelector(store => store.auditReport.isReportModalOpen);
  const isNotifyTeamModalOpen = useAppSelector(store => store.auditReport.isNotifyTeamModalOpen);
  const dispatch = useAppDispatch();

  return (
    <Card
      className="actions-card"
    >
      <FlexCol>
        {showCreateReportButton &&
          <button
            onClick={() => dispatch(toggleReportModal(true))}
            className="primary centered"
          >
            Create Report
          </button>
        }

        <ExportAuditButton
          auditId={auditId}
        />

        {showNotifyTeamButton && (
          <button
            className="secondary centered"
            onClick={() => dispatch(toggleNotifyTeamModal(true))}
          >
            Notify Team
          </button>
        )}
      </FlexCol>

      {isReportModalOpen
        && (
          <ReportModal
            auditId={auditId}
            auditStatus={auditStatus}
            topics={topics}
            onClose={onCloseReportModal}
            onSubmit={onGenerateReport}
          />
        )}

      {isNotifyTeamModalOpen && (
        <NotifyTeamModal
          auditId={auditId}
        />
      )}
    </Card>
  );
};

export default ActionsCard;
