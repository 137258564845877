import { FlexGrid as wjFlexGrid } from '@mescius/wijmo.grid';

export interface IDataGridProps {
  /** The unique identifier for the data grid. */
  id: string,
  /** The data source type. */
  dataSource: "OData" | "array",
  /** Optional. The OData url (required if dataSource = 'OData'). */
  dataUrl?: string,
  /** Optional. The array of data (rqeuired if dataSource = 'array'). */
  dataArray?: Object[],
  /** Optional. If true, the user can export to Excel. Default = false. */
  allowExcelExport?: boolean,
  /** Optional. Settings for the filters. */
  filterSettings?: IDataGridFilterSettings,
  /** Optional. Settings for sorting. */
  sortSettings?: IDataGridSortSettings,
  /** The list of columns to render in the grid. */
  gridColumns: IDataGridColumn[],
  /** Optional. If true, the grid's layout and options are saved to the user's browser and later restored automatically. Default = false. */
  enablePersistence?: boolean,
  /** Optional. Determines if the user can drag and drop columns to re-order them. Default = false. */
  allowColumnReordering?: boolean,
  /** Optional. Determines if paging is enabled. Default = true. */
  allowPaging?: boolean,
  /** Optional. Determines if columns can be resized. Default = false. */
  allowResizing?: boolean,
  /** Optional. Determines the page size if allowPaging = true. Default = 30. */
  pageSize?: number,
  /** Optional. Function to call when a user clicks on a row. */
  recordClick?: (rowData: any) => void,
  /** Optional. Function to call when a user double clicks on a row. */
  recordDoubleClick?: (rowData: any) => void,
  /** Optional. Determines column chooser visibility. Default = false. */
  showColumnChooser?: boolean,
  /** Optional. Determines additional querystring variables for the odata endpoint. */
  extraQueryString?: IQueryStringValues[],
  /** Optional. Determines height of the grid (as per CSS). */
  height?: string | number,
  /** Optional. Called after rows have been bound to objects in the data source. */
  onLoadedRows?: (sender: wjFlexGrid, args: any) => void,
  /** Optional. Determines min height of grid. Default = "200px". */
  minHeight?: string,

  /** Optional. Aditional css class. */
  className?: string,
}

export interface IQueryStringValues {
  key: string,
  value: string,
}

export interface IDataGridFilterSettings {
  columns: IDataGridFilterColumnSetting[],
}

export interface IDataGridFilterColumnSetting {
  uid: string,
  field: string,
  value: string | boolean | number,
  operator: DataGridFilterOperator,
}

export enum DataGridFilterOperator {
  Equals = 0,
  NotEquals = 1,
  GreaterThan = 2,
  GreaterThanOrEqual = 3,
  LessThan = 4,
  LessThanOrEqual = 5,
  BeginsWith = 6,
  EndsWith = 7,
  Contains = 8,
  DoesNotContain = 9
}

export interface IDataGridSortSettings {
  columns: IDataGridSortColumnSetting[],
}

export interface IDataGridSortColumnSetting {
  field: string,
  direction: "Ascending" | "Descending",
}

export interface IDataGridColumn {
  /** The unique identifier for the column. */
  uid: string,
  /** Optional. The name of the property from the bound object to bind the column to. */
  field?: string,
  /** Optional. The column header text. */
  headerText?: string,
  /** Optional. Display width of the column. */
  width?: number | string,
  /** Optional. The data type of the bound property. If unspecified, the value will be toString'ed. If the type is "unbound", it will be ignored. */
  type: "number" | "string" | "date" | "boolean" | "unbound",
  /** Optional. Determines date format (e.g. "MM/dd/yyyy"). */
  format?: string,
  /** Optional. Cell text alignment. Default = Left. */
  textAlign?: "Left" | "Right" | "Center",
  /** Optional. Allows overriding cell rendering. The entire data object will be passed to the callback and a JSXElement must be returned. */
  template?: (row: any) => JSX.Element | string,
  /** Optional. Determines column visibility. Default = true. */
  visible?: boolean,
  /** Optional. Determines if column appears in column chooser. Default = true. */
  showInColumnChooser?: boolean,
  /** Optional. Determines minimum column display width. */
  minWidth?: number,
  /** Optional. Determines if this column is filterable. Default = true. */
  allowFiltering?: boolean,
  /** Optional. Determines if this column is sortable. Default = true. */
  allowSorting?: boolean,
}