import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSchedulerMonthDateRangeFromDate } from "shared/components/controls/scheduler/qiSchedulerHelpers";
import { IAuditSchedulerItemData, IQISchedulerDateRange } from "shared/components/controls/scheduler/qiSchedulerTypes";
import pickerHandlers, { IOpenPickerAction } from "shared/store/picker/pickerReducerHandlers";
import { IOperation } from "shared/types/operationTypes";
import { IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import AuditPlanCalendarStatusFilterOptions from "types/audit-plan-calendar/AuditPlanCalendarStatusFilterOptions";
import IAuditPlanCalendarBasinYAxisData from "types/audit-plan-calendar/IAuditPlanCalendarBasinYAxisData";
import IAuditPlanCalendarFinishLoadPayload from "types/audit-plan-calendar/IAuditPlanCalendarFinishLoadPayload";
import IAuditPlanCalendarLeadAuditor from "types/audit-plan-calendar/IAuditPlanCalendarLeadAuditor";
import { ComplianceResults } from "types/auditPlanningTypes";

export interface IAuditPlanCalendarPickerData {
  editorLeadAuditors: IPickerState<IAzureADUser>,

  [key: string]: IPickerState<any>,
}

export enum SchedulerVisibility {
  normal = "normal",
  maximized = "maximized",
}

export interface IAuditPlanCalendarState {
  basinYAxisData: IAuditPlanCalendarBasinYAxisData,
  loadDataOp?: IOperation<void>,
  saveEventsOp?: IOperation<void>,
  calendarMode: "basin" | "leader",
  calendarDateRange: IQISchedulerDateRange,

  pickerData: IAuditPlanCalendarPickerData,

  editItemModal: IEditItemModalProps | undefined,
  leadAuditorYAxisData: IAuditPlanCalendarLeadAuditor[],
  schedulerItems: IAuditSchedulerItemData[],
  selectedPlanIds: number[],
  quickInfoItem: IAuditSchedulerItemData | undefined,
  schedulerVisibility: SchedulerVisibility,
}

export interface IEditItemModalProps {
  isOpen: boolean,
  item: IAuditSchedulerItemData,
}

const initialState: IAuditPlanCalendarState = {
  basinYAxisData: {
    basins: [],
    geoUnits: [],
    subGeoUnits: [],
    parentDimensions: [],
    childDimensions: [],
  },
  loadDataOp: undefined,
  saveEventsOp: undefined,
  calendarMode: "basin",
  calendarDateRange: getSchedulerMonthDateRangeFromDate(new Date()),

  pickerData: {
    businessTeams: {
      key: "businessTeams",
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    businessViews: {
      key: "businessViews",
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    facilities: {
      key: "facilities",
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    calendarAuditTypes: {
      key: "calendarAuditTypes",
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    leadAuditors: {
      key: "leadAuditors",
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    editorLeadAuditors: {
      key: "editorLeadAuditors",
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    auditCalendarStatuses: {
      key: "auditCalendarStatuses",
      isOpen: false,
      items: [{
        key: AuditPlanCalendarStatusFilterOptions.RecommendedApproved,
        text: AuditPlanCalendarStatusFilterOptions.RecommendedApproved.replace("A", "/A"),
      }, {
        key: AuditPlanCalendarStatusFilterOptions.PlannedInProgress,
        text: AuditPlanCalendarStatusFilterOptions.PlannedInProgress.replace("I", "/I"),
      }, {
        key: AuditPlanCalendarStatusFilterOptions.Completed,
        text: AuditPlanCalendarStatusFilterOptions.Completed,
      }, {
        key: AuditPlanCalendarStatusFilterOptions.Closed,
        text: AuditPlanCalendarStatusFilterOptions.Closed,
      }],
      selectedItems: [],
    },
    complianceResults: {
      key: "complianceResults",
      isOpen: false,
      items: Object
        .keys(ComplianceResults)
        .map(x => ({
          key: x,
          text: x,
        })),
      selectedItems: [],
    },
    perspectives: {
      key: "perspectives",
      isOpen: false,
      items: [],
      selectedItems: [],
    },
    perspectiveXAxes: {
      key: "perspectiveXAxes",
      isOpen: false,
      items: [],
      selectedItems: [],
    },
  },

  editItemModal: undefined,
  leadAuditorYAxisData: [],
  schedulerItems: [],
  selectedPlanIds: [],
  quickInfoItem: undefined,
  schedulerVisibility: SchedulerVisibility.normal,
};

export const auditPlanCalendarSlice = createSlice({
  name: "audit-plan-calendar",
  initialState,
  reducers: {
    ...pickerHandlers,

    /** Fix for the openPicker not working for AuditTypes. I don't know why this is necessary as it is not necessary in any other slice... */
    openPicker: (state, action: PayloadAction<IOpenPickerAction>) => {
      pickerHandlers.openPicker(state, action);
    },

    /** Finishes the backend data load operation. */
    finishLoadCalendarResources: (state, action: PayloadAction<IOperation<IAuditPlanCalendarFinishLoadPayload>>) => {
      state.loadDataOp = undefined;

      if (!action.payload.data) {
        return;
      }

      if (action.payload.data.basinYAxis) {
        state.basinYAxisData = action.payload.data.basinYAxis;
      }

      if (action.payload.data.leaderYAxis) {
        state.leadAuditorYAxisData = action.payload.data.leaderYAxis;
      }

      if (action.payload.data.items) {
        state.schedulerItems = action.payload.data.items.slice().sort((a, b) => {
          if (a.startTime === b.startTime) {
            return a.planId - b.planId;
          } else {
            return a.startTime - b.startTime;
          }
        });
      }
    },

    /** Sets the load operation. */
    setLoadDataOp: (state, action: PayloadAction<IOperation<void> | undefined>) => {
      state.loadDataOp = action.payload;
    },

    /** Opens the edit item modal for the particular item. */
    openEditItemModal: (state, action: PayloadAction<IAuditSchedulerItemData>) => {
      state.editItemModal = {
        isOpen: true,
        item: action.payload,
      };

      state.pickerData.editorLeadAuditors.selectedItems = action.payload.leadAuditorEmail
        && action.payload.leadAuditorName
        ? [{
          key: "editorLeadAuditors",
          item: {
            name: action.payload.leadAuditorName,
            email: action.payload.leadAuditorEmail,
          },
        }] : [];
      state.pickerData.editorLeadAuditors.suggestedItems = [];
      state.pickerData.editorLeadAuditors.items = [];
    },

    /** Closes the edit item modal. */
    closeEditItemModal: state => {
      state.editItemModal = undefined;
    },

    /** Copies provided properties into the currently open edit item. */
    setEditItem: (state, action: PayloadAction<Partial<IAuditSchedulerItemData>>) => {
      if (!state.editItemModal?.item) {
        return;
      }

      Object.assign(state.editItemModal.item, action.payload);
    },

    /** Sends the currently edited item to the server for saving. */
    saveCalendarItem: (state, _: PayloadAction<{
      item: IAuditSchedulerItemData,
      startDate: number,
      endDate: number,
      leadAuditor?: IAzureADUser,
    }>) => {
      state.saveEventsOp = {
        isWorking: true,
      };
    },

    /** Clears the save spinner. */
    finishSaveCalendarItem: (state, action: PayloadAction<{ wasSuccessful: boolean; }>) => {
      state.saveEventsOp = undefined;

      if (action.payload.wasSuccessful) {
        state.editItemModal = undefined;
      }
    },

    /** Sets the calendar mode. */
    setCalendarMode: (state, action: PayloadAction<"basin" | "leader">) => {
      state.calendarMode = action.payload;
    },

    /** Sets the calendar's visible date range. */
    setCalendarDateRange: (state, action: PayloadAction<IQISchedulerDateRange>) => {
      state.calendarDateRange = action.payload;
    },

    /** Sets the selected plan ids within the scheduler. */
    setSelectedPlanIds: (state, action: PayloadAction<number[]>) => {
      state.selectedPlanIds = action.payload;
    },

    /** Sets the currently opened quick info item. If undefined, then the quick info will close. */
    setQuickInfoItem: (state, action: PayloadAction<IAuditSchedulerItemData | undefined>) => {
      state.quickInfoItem = action.payload;
    },

    /** Sets the visibility state of the scheduler. */
    setSchedulerVisibility: (state, action: PayloadAction<SchedulerVisibility>) => {
      state.schedulerVisibility = action.payload;
    },

    refreshAuditPlanCalendar: () => { },
  },
});

export const {
  // Data load actions.
  finishLoadCalendarResources,

  // Filter actions.
  setLoadDataOp,

  // Event alteration actions.
  openEditItemModal,
  closeEditItemModal,
  setEditItem,
  saveCalendarItem,
  finishSaveCalendarItem,

  setSelectedPlanIds,
  setQuickInfoItem,
  setSchedulerVisibility,
  refreshAuditPlanCalendar,

  // Set calendar mode action.
  setCalendarMode,

  // Set calendar selected date range.
  setCalendarDateRange,

  // Picker Actions.
  openPicker,
  closePicker,
  loadPickerItems,
  loadSuggestedPickerItems,
  setPickerError,
  setPickerItems,
  setSuggestedPickerItems,
  setSelectedPickerItems,
  expandPickerItem,
  collapsePickerItem,
} = auditPlanCalendarSlice.actions;