import { isQuestionMissingAnswer } from "components/audits/common/auditUtilities";
import React from "react";
import { questionHasRequiredActionItem } from "shared/utilities/actionItemUtilities";
import { isMissingRequiredFinding } from "shared/utilities/findingUtilities";
import { setConfirmAuditStatusChangeModal } from "store/audit/AuditSlice";
import { getAuditScoringSystem } from "store/audit/reducers/auditInfoReducers";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { AuditStatuses } from "types/auditingTypes";

const CompleteAuditButton: React.FC = () => {
  const audit = useAppSelector(store => store.audit.audit);
  const questions = useAppSelector(store => store.audit.questions);
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const answers = useAppSelector(store => store.audit.answers);
  const findings = useAppSelector(store => store.audit.findings);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const dispatch = useAppDispatch();

  const isEditable = auditPageAuth.editRestriction === EditRestriction.EditAll;

  if (!audit
    || !isEditable) {
    return null;
  }

  const scoringSystem = getAuditScoringSystem(audit);

  const answersRequiringRWP = answers
    .filter(x => x.requiresRWP
      && x.scoringSystem === scoringSystem)
    .map(x => x.code);

  // Unable to close if any questions are missing an answer.
  let isUnableToComplete = questions
    .some(q => isQuestionMissingAnswer(q, audit.status)
      || questionHasRequiredActionItem(q, audit.status,
        actionItems,
        answersRequiringRWP) === false
      || isMissingRequiredFinding(q, findings, audit.status, answers));

  return (
    <button
      className="primary"
      onClick={() => dispatch(setConfirmAuditStatusChangeModal({
        isOpen: true,
        auditId: audit.id,
        message: "Are you sure you want to complete the audit?",
        nextStatus: AuditStatuses.Completed,
      }))}
      disabled={isUnableToComplete}
      title={isUnableToComplete
        ? (
          scoringSystem === AuditScoringSystems.CLM
            ? "All Questions must be answered."
            : (
              scoringSystem === AuditScoringSystems.QMS
                ? "All Questions must be answered and all Non-Compliant Questions must have a Finding assigned."
                : "All Questions must be answered and all Non-Compliant Questions must have a preventative or corrective action item assigned."
            )
        ) : undefined
      }
    >
      Complete Audit
    </button>
  );
};

export default CompleteAuditButton;