import { useMsal } from "@azure/msal-react";
import React, { useEffect, useRef, useState } from "react";
import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import arrowDownIcon from "shared/media/dls/arrow-down-2.svg";
import profileIcon from "shared/media/dls/profile.svg";
import UserInfo from "../../auth/UserInfo";
import "./UserBadge.scoped.scss";

const UserBadge: React.FC = () => {
  const { accounts } = useMsal();
  const user = accounts[0];
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e: any) => {
    if (!refOne.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const initialRef: any = null;
  const refOne = useRef(initialRef);

  if (!user) {
    return null;
  }

  return (
    <>
      <span
        className="badge"
      >
        <TooltipElement
          tooltipString="View User Entitlements"
          position={"Below"}
        >
          <div
            onClick={() => setIsOpen(!isOpen)}
          >
            <label>
              {user.username}
            </label>

            <img
              className="down-arrow"
              src={arrowDownIcon}
              alt=""
            />

            <img
              className="profile-image"
              src={profileIcon}
              alt=""
            />
          </div>
        </TooltipElement>
      </span>

      {isOpen &&
        <div ref={refOne}>
          <UserInfo />
        </div>
      }
    </>
  );
};

export default UserBadge;
