import { formatMobileSite } from "api/masterdata/formatters/masterDataFormatters";
import config from "config";
import React from "react";
import { IDataGridColumn } from "shared/components/layout/grid/types/dataGridTypes";
import { IMobileSite } from "types/masterDataTypes";
import ODataPicker from "../odata-picker/ODataPicker";

interface IMobileSitePickerProps {
  /** The callback used when the user applies their selection in the picker. */
  onSelectionChange: (items: IMobileSite[]) => void,
  /** The list of already-selected facilities. */
  selectedItems: IMobileSite[],
  /** Optional. Determines if suggestions should be loaded and shown. Default = true.*/
  showSuggestions?: boolean,
  /** Optional. Determines how many can be selected. Default = Single. */
  selectionMode?: "Single" | "Multiple",
  /** Optional. Disables the component. Default = false. */
  isDisabled?: boolean,
  /** Optional. Used to filter projects by their subgeounit. */
  subGeoUnitIds?: number[],
  /** Optional. If a SubGeoUnit filter is applied, setting this to true will ALSO include mobile sites with SubGeoUnitId=null. */
  includeUnassignedItems?: boolean,
}

const gridColumns: IDataGridColumn[] = [{
  uid: "id",
  field: "id",
  headerText: "Id",
  visible: false,
  width: 75,
  type: "string",
}, {
  uid: "rigType",
  field: "rigType",
  headerText: "Type",
  width: 100,
  type: "string",
}, {
  uid: "owner",
  field: "owner",
  headerText: "Owner",
  width: 100,
  type: "string",
}, {
  uid: "rigName",
  field: "rigName",
  headerText: "Rig Name",
  width: 175,
  type: "string",
}, {
  uid: "drillingContractor",
  field: "drillingContractor",
  headerText: "Drilling Contractor",
  width: 175,
  type: "string",
}, {
  uid: "rigKey",
  field: "rigKey",
  headerText: "Key",
  width: 100,
  type: "number",
}, {
  uid: "country",
  field: "country",
  headerText: "Country",
  width: 100,
  type: "string",
}];

const MobileSitePicker: React.FC<IMobileSitePickerProps> = ({
  onSelectionChange,
  selectedItems,
  showSuggestions = true,
  selectionMode = "Single",
  isDisabled,
  subGeoUnitIds,
  includeUnassignedItems,
}: IMobileSitePickerProps) => {

  let queryString = '';
  let linkQSVars: string[] = [];

  if (subGeoUnitIds?.length) {
    for (let i = 0; i < subGeoUnitIds.length; i++) {
      linkQSVars.push(`subGeoUnitIds[${i}]=${subGeoUnitIds[i]}`);
    }

    queryString = `?${linkQSVars.join('&')}${includeUnassignedItems
      ? `&subGeoUnitIds[${subGeoUnitIds?.length}]=0`
      : ""
      }`;
  }

  return (
    <ODataPicker<IMobileSite>
      keyMapper={item => item.id}
      oDataUrl={`${config.endpoints.masterData.mobileSites}${queryString}`}
      onSelectionChange={items => onSelectionChange(items.map(x => formatMobileSite(x)))}
      selectedItems={selectedItems}
      renderSelectedItem={item => `${item.rigKey} - ${item.rigName}`}
      selectMode={selectionMode}
      title="Mobile Sites"
      isDisabled={isDisabled}
      sortSelectedItems={(a, b) => a.rigKey < b.rigKey ? -1 : 1}
      gridColumns={gridColumns}
      suggestedItemFormatter={showSuggestions
        ? obj => formatMobileSite(obj)
        : undefined
      }
      suggestedItemsUrl={showSuggestions
        ? `${config.endpoints.masterData.suggestedMobileSites}`
        : undefined
      }
      className="mobile-sites-grid-picker"
    />
  );

}

export default MobileSitePicker;