import { useMsal } from "@azure/msal-react";
import React from "react";
import profileIcon from "shared/media/dls/profile.svg";
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { switchProfile } from "../../store/auth/AuthSlice";
import { IProfileMetaData } from "../../types/userProfileTypes";
import Animate, { Animations } from "../animations/Animate";
import Spinner from "../common/spinner/Spinner";
import TooltipElement from "../common/tooltip/TooltipElement";
import FlexCol from "../layout/flex/FlexCol";
import FlexRow from "../layout/flex/FlexRow";
import "./UserInfo.scoped.scss";

const UserInfo: React.FC = () => {
  const {
    userProfile,
    activeUserProfile,
    loadSwitchOperation
  } = useAppSelector(store => store.auth);
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  let profiles = userProfile.profiles;

  function onRoleClickHandler(profileId: number, moduleName: string) {
    if (profileId !== activeUserProfile.profileId) {
      dispatch(switchProfile({
        profileId,
        moduleName,
      }));
    }
  }

  let userProfileList =
    profiles.map((profile) => {
      const metaDataText = getProfileMetaData(profile.profileMetaData);

      let row =
        <div
          key={profile.id}
          className={profile.id === activeUserProfile.profileId
            ? "entitlement selected"
            : "entitlement"
          }
          onClick={() => onRoleClickHandler(profile.id, profile.module.name)}
        >
          <input
            readOnly
            type="radio"
            checked={profile.id === activeUserProfile.profileId}
          />

          <div className="role-info">
            <span>
              {profile.role.description.replace(/([a-z])([A-Z])/g, "$1 $2")}
            </span>

            {metaDataText.length > 0 && (
              <TooltipElement
                tooltipString={metaDataText}
                position={"Below"}
              >
                <div
                  className="roles-description"
                >
                  {metaDataText}
                </div>
              </TooltipElement>
            )}
          </div>
        </div>;
      return row;
    });

  return (
    <Animate
      animation={Animations.fade}
      shouldBeVisible={true}
    >
      <div className="menu">
        <FlexRow className="user-info">
          <img
            className={
              userProfile.user.photo
                ? undefined
                : "noimage"
            }
            src={userProfile.user.photo || profileIcon}
            alt={userProfile.user.name}
          />

          <FlexCol className="user-detail">
            <div className="detail-item name">
              {userProfile.user.name}
            </div>

            <div className="detail-item">
              {userProfile.user.email}
            </div>

            <div className="detail-item">
              {userProfile.user.jobTitle}
            </div>
          </FlexCol>
        </FlexRow>

        <div className="entitlement-section">
          <div className="header">
            User Entitlements
          </div>

          {loadSwitchOperation?.isWorking
            ? <Spinner />
            : (
              <div className="entitlements-list">
                {userProfileList}
              </div>
            )}
        </div>

        <div
          className="logout"
          onClick={() => instance.logoutRedirect()}
        >
          Log Out!
        </div>
      </div>
    </Animate>
  );
};

function getProfileMetaData(profileMetaData: IProfileMetaData[]) {
  return !Array.isArray(profileMetaData) || profileMetaData.length === 0
    ? ""
    : Array.from(new Set(profileMetaData.map(x => x.type)))
      .sort((a, b) => a < b ? -1 : 1)
      .map(metaType => `${metaType}: `
        + profileMetaData
          .filter(x => x.type === metaType)
          .sort((a, b) => (a.masterDataCode ?? a.masterDataName ?? a.description) < (b.masterDataCode ?? b.masterDataName ?? b.description) ? -1 : 1)
          .map(a => [a.masterDataCode, a.masterDataName ?? a.description ?? ""]
            .filter(x => x)
            .join(" - "))
          .join(", ")
      ).join("; ");
}

export default UserInfo;
