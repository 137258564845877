import "components/search/filters-tooltip/FiltersTooltip.scoped.scss";
import FilterTooltipDateRangeSection from "components/search/filters-tooltip/FilterTooltipDateRangeSection";
import FilterTooltipSection from "components/search/filters-tooltip/FilterTooltipSection";
import React from "react";
import { userToString } from "shared/utilities/userUtilities";
import { useAppSelector } from "store/store";

const FiltersTooltip: React.FC = () => {
  const state = useAppSelector(store => store.searchActions);

  return (
    <div className="filters-tooltip">
      {/* Primary Filters */}

      <FilterTooltipSection
        label="Action Item Id"
        values={[state.actionId || ""]}
      />

      <FilterTooltipDateRangeSection
        label="Due Date"
        values={[state.dueDateStart, state.dueDateEnd]}
      />

      <FilterTooltipSection
        label="Action Item Type"
        values={state.pickerData.actionItemTypes.selectedItems.map(x => x.text || x.key.toString())}
      />

      <FilterTooltipSection
        label="Audit Type"
        values={state.pickerData.auditTypes.selectedItems.map(x => x.text || x.key.toString())}
      />

      <FilterTooltipSection
        label="Action Required"
        values={[state.actionRequired]}
      />

      <FilterTooltipSection
        label="Assigned To"
        values={state.pickerData.assignedTo.selectedItems.map(x => userToString(x.item) || userToString({ name: x.text!, email: x.key.toString() }) || "")}
      />

      <FilterTooltipSection
        label="Created By"
        values={state.pickerData.createdBy.selectedItems.map(x => userToString(x.item) || userToString({ name: x.text!, email: x.key.toString() }) || "")}
      />

      <FilterTooltipSection
        label="Status"
        values={state.statuses.map(x => x.toString())}
      />

      <FilterTooltipSection
        label="Facility"
        values={state.facilities.map(x => x.name)}
      />

      <FilterTooltipSection
        label="Country"
        values={state.pickerData.countries.selectedItems.map(x => x.item?.name || x.text || x.key.toString())}
      />

      <FilterTooltipSection
        label="Business View (Divisions)"
        values={state.pickerData.businessViews.selectedItems.map(x => `${x.item?.code} - ${x.item?.name}` || "")}
      />

      <FilterTooltipSection
        label="Business Team (Basin Hierarchy)"
        values={state.pickerData.businessTeams.selectedItems.map(x => `${x.item?.code} - ${x.item?.name}` || "")}
      />

      <FilterTooltipSection
        label="Business Function (Functions)"
        values={state.pickerData.businessFunctions.selectedItems.map(x => `${x.item?.code} - ${x.item?.name}` || "")}
      />

      <FilterTooltipSection
        label="Mobile Site"
        values={state.mobileSites.map(x => x.rigName)}
      />

      {/* Secondary Filters */}

      <FilterTooltipSection
        label="Parent Type"
        values={state.parentTypes}
      />

      <FilterTooltipSection
        label="Parent ID"
        values={[state.parentId]}
      />

      <FilterTooltipSection
        label="Parent Name"
        values={[state.parentName]}
      />

      <FilterTooltipSection
        label="Lead Auditor"
        values={state.pickerData.leadAuditors.selectedItems.map(x => userToString(x.item) || userToString({ name: x.text!, email: x.key.toString() }) || "")}
      />

      <FilterTooltipSection
        label="Priority"
        values={state.pickerData.priorities.selectedItems.map(x => x.item?.name || x.text || x.key.toString())}
      />

      <FilterTooltipSection
        label="Validated"
        values={state.isValidated !== undefined
          ? [state.isValidated ? "Yes" : "No"]
          : []
        }
      />

      <FilterTooltipDateRangeSection
        label="Creation Date"
        values={[state.creationDateStart, state.creationDateEnd]}
      />

      <FilterTooltipDateRangeSection
        label="Closed Date"
        values={[state.closureDateStart, state.closureDateEnd]}
      />

      <FilterTooltipSection
        label="Causal Factor"
        values={state.pickerData.causalFactors.selectedItems.map(x => x.item?.name || x.text || x.key.toString())}
      />
    </div>
  );
};

export default FiltersTooltip;