import { useCallback, useState } from "react";
import ErrorWithRetry from "shared/components/common/error-with-retry/ErrorWithRetry";
import Spinner from "shared/components/common/spinner/Spinner";
import { IOperation } from "shared/types/operationTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";
import QISelect from "../../select/QISelect";
import "./NonReduxDropdownPicker.scoped.scss";

interface INonReduxPickerDropdownProps<T> {
  /** The list of currently selected items. */
  selectedItems: T[],
  /** Placeholder text to show when no items are selected. Defaults to "Select". */
  placeholder?: string,
  /** Disables the picker so that the user cannot interact with it. */
  isDisabled?: boolean,
  /** If true, the picker will render red with red text if it has no selected items. */
  enableRequiredErrorDisplay?: boolean,
  /** Determines single/multiple selection mode. */
  selectMode?: "single" | "multiple",
  /** The callback to use to load the list of available items. */
  onLoadItems: (searchValue: string | undefined, abortSignal: AbortSignal) => Promise<T[]>,
  /** A callback action to call when the user selects an item. If this is set,
   * then setSelectedItemsAction will never be used. */
  onSelectionChanged(selectedItems: T[]): void,
  /** A function that will be called for each item to get the text to show in the dropdown. */
  itemFormatter(item: T): string,
  /** A callback to get the unique identifier for an item. */
  keyMapper(item: T): number | string,
  /** Optional. If specified, each item will call this function to determine if it should be enabled or not. Note: MultiSelect dropdowns do not support this and will instead filter those items out. */
  isDisabledMapper?: (item: T) => boolean,
}

const NonReduxPickerDropdown = <T,>({
  selectedItems,
  placeholder,
  isDisabled,
  enableRequiredErrorDisplay,
  selectMode = "single",
  onLoadItems,
  onSelectionChanged,
  itemFormatter,
  keyMapper,
  isDisabledMapper,
}: INonReduxPickerDropdownProps<T>) => {

  const [loadOperation, setLoadOperation] = useState<IOperation<T> | undefined>(undefined);
  const [availableItems, setAvailableItems] = useState<T[]>([]);

  const showLoading = !!loadOperation?.isWorking;
  const showError = !loadOperation?.isWorking
    && !!loadOperation?.errorMessage;

  const loadItems = useCallback(async (aborted: boolean, abortSignal: AbortSignal) => {
    try {
      setLoadOperation({
        isWorking: true,
      });
      const items = await onLoadItems(undefined, abortSignal);
      if (aborted) {
        return;
      }

      setAvailableItems(items);
      setLoadOperation(undefined);
    } catch (err) {
      if (aborted) {
        return;
      }

      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      });
    }
  }, [setLoadOperation, setAvailableItems, onLoadItems]);

  useAsyncEffect(loadItems, []);

  if (showError) {
    return (
      <ErrorWithRetry
        tooltip={loadOperation?.errorMessage}
        onRetryClicked={() => {
          const abortController = new AbortController();
          loadItems(false, abortController.signal);
        }}
      />
    );
  } else if (showLoading) {
    return (
      <Spinner
        className="small-spinner"
        hAlign="left"
      />
    );
  } else {
    if (selectMode === "multiple") {
      const multiAvailableItems = isDisabledMapper
        ? availableItems.filter(x => !isDisabledMapper(x))
        : availableItems;

      return (
        <QISelect
          items={multiAvailableItems.map(x => ({
            id: keyMapper(x).toString(),
            text: itemFormatter(x),
          }))}
          selectedItemIds={selectedItems.map(x => keyMapper(x).toString())}
          onChange={selectedItems => {
            onSelectionChanged(multiAvailableItems
              .filter(x => selectedItems
                .some(z => z.id.toString() === keyMapper(x).toString())));
          }
          }
          isDisabled={isDisabled}
          selectMode="multi"
          placeholder={placeholder}
        />
      );
    } else {
      return (
        <QISelect
          items={availableItems.map(x => ({
            id: keyMapper(x).toString(),
            text: itemFormatter(x),
            isDisabled: isDisabledMapper?.(x),
          }))}
          selectedItemIds={selectedItems.length
            ? [keyMapper(selectedItems[0]).toString()]
            : []
          }
          onChange={selectedItems => {
            if (!selectedItems.length) {
              onSelectionChanged([]);
              return;
            }

            const item = availableItems
              .find(x => keyMapper(x).toString() === selectedItems[0].id.toString());

            if (item) {
              onSelectionChanged([item]);
            }
          }}
          isDisabled={isDisabled}
          selectMode="single"
          placeholder={placeholder}
        />
      );
    }
  }
};

export default NonReduxPickerDropdown;
