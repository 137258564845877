import UrlRoutes from "components/routing/UrlRoutes";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Divider from "shared/components/common/divider/Divider";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Button from "shared/components/controls/buttons/button/Button";
import Card from "shared/components/layout/card/Card";
import Page from "shared/components/layout/page/Page";
import CloseIcon from "shared/media/dls/close.svg";
import editIcon from "shared/media/dls/edit-1.svg";
import FullscreenIcon from "shared/media/dls/expand-1.svg";
import infoIcon from "shared/media/dls/info.svg";
import CheckIcon from "shared/media/icons/audit-planning/final-plan-approved-plan-missing.svg";
import { checkAttributeByTopicOrTemplatePermission, getEditablePlanningRows } from "store/audit-planning-shared/PlanningShared";
import { markAttributesReviewed, setMode } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";
import { IEditableGridRow } from "types/auditPlanningTypes";
import PlanningSpinner from "../PlanningSpinner";
import AuditPlanningFilters from "../planning-filters/AuditPlanningFilters";
import UserPlanningViewPicker from "../user-planning-view-picker/UserPlanningViewPicker";
import AddChildDimensionModal from "./add-child-dimension-modal/AddChildDimensionModal";
import AddParentDimensionModal from "./add-parent-dimension-modal/AddParentDimensionModal";
import FacilityAttributesGrid from "./attributes-grid/FacilityAttributesGrid";
import { isAttributeReviewed } from "./attributesUtils";
import ConfirmRemoveOrEditAttributeModal from "./confirm-remove-or-edit-attr-modal/ConfirmRemoveOrEditAttributeModal";
import CreateAttributeModalCLM from "./create-attr-modal-clm/CreateAttributeModalCLM";
import CreateAttributeModal from "./create-attr-modal/CreateAttributeModal";
import EditAttributeModal from "./edit-attr-modal/EditAttributeModal";
import { IPlanProfileAttribute } from "./facilityAttributeTypes";
import RemoveAttributeModal from "./remove-attr-modal/RemoveAttributeModal";
import RemoveChildDimensionModal from "./remove-child-dimension-modal/RemoveChildDimensionModal";
import "./FacilityAttributesPage.scoped.scss";

const FacilityAttributes: React.FC = () => {
  // Facility Attribute specific data.
  const reviewAttributesOp = useAppSelector(store => store.facilityAttributes.reviewAttributesOp);
  const mode = useAppSelector(store => store.facilityAttributes.mode);
  const addChildDimensionOp = useAppSelector(store => store.facilityAttributes.addChildDimensionOp);
  const addParentDimensionOp = useAppSelector(store => store.facilityAttributes.addParentDimensionOp);
  const createAttributeOp = useAppSelector(store => store.facilityAttributes.createAttributeOp);
  const editAttributeOp = useAppSelector(store => store.facilityAttributes.editAttributeOp);
  const removeAttributeOp = useAppSelector(store => store.facilityAttributes.removeAttributeOp);
  const removeChildDimensionOp = useAppSelector(store => store.facilityAttributes.removeChildDimensionOp);
  const centerAuditAttributeOp = useAppSelector(store => store.facilityAttributes.centerAuditAttributeOp);
  const centerAuditAttributeModal = useAppSelector(store => store.facilityAttributes.centerAuditAttributeModal);

  // Universal planning data.
  const activeUserProfile = useAppSelector(store => store.auth.activeUserProfile);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const appliedPerspectiveXAxes = useAppSelector(store => store.auditPlanning.appliedFilters?.perspectiveXAxes);
  const yAxisData = useAppSelector(store => store.auditPlanning.facAttrYAxisData);
  const xAxisData = useAppSelector(store => store.auditPlanning.xAxisData);
  const attributes = useAppSelector(store => store.auditPlanning.profileAttributes);
  const profiles = useAppSelector(store => store.auditPlanning.profiles);
  const hasDataEverLoaded = useAppSelector(store => store.auditPlanning.hasDataEverLoaded);
  const appliedFilters = useAppSelector(store => store.auditPlanning.appliedFilters);

  // Get the extra, non-Facility plannable types to decide if ADD PARENT should be shown.
  const selectedPerspectiveXAxis = appliedPerspectiveXAxes?.[0];

  const [isMaximized, setIsMaximized] = useState<boolean>(false);

  const editableRows: IEditableGridRow[] = useMemo(() => getEditablePlanningRows(yAxisData, selectedPerspectiveXAxis), [
    yAxisData,
    selectedPerspectiveXAxis
  ]);

  const editableAttributes = useMemo(() => {
    // Find undeleted attributes that are not reviewed that
    // belong to an allowed audit topic.
    // That appear 
    let allowedAttrsByTopic = attributes
      .filter(attr => checkAttributeByTopicOrTemplatePermission(attr.masterDataId,
        attr.masterDataType,
        activeUserProfile,
        editRestriction,
        xAxisData));

    const editableAttrs: IPlanProfileAttribute[] = [];

    for (let i = 0; i < allowedAttrsByTopic.length; i++) {
      // Find the profile associated with this attribute.
      const profile = profiles.find(x => x.id === allowedAttrsByTopic[i].profileId);
      const parentProfile = profile?.parentId
        ? profiles.find(x => x.id === profile.parentId)
        : undefined;

      // Find the planning row that is editable that matches this profile.
      const editableRow = editableRows
        .find(x => x.row.id === profile?.masterDataId
          && x.row.type === profile?.masterDataType
          && (
            (parentProfile === undefined
              && x.parentRow === undefined)
            || (parentProfile?.masterDataId === x.parentRow?.id
              && parentProfile?.masterDataType === x.parentRow?.type)
            || (parentProfile === undefined
              && !x.parentRow?.isPlannable)
          ));

      if (editableRow) {
        editableAttrs.push(allowedAttrsByTopic[i]);
      }
    }

    return editableAttrs;
  }, [
    attributes,
    profiles,
    activeUserProfile,
    editableRows,
    xAxisData,
    editRestriction,
  ]);

  const reviewableAttributes = useMemo(() => {
    return editableAttributes
      .filter(x => !x.deleted
        && !isAttributeReviewed(x));
  }, [editableAttributes]);

  const dispatch = useDispatch();

  return (
    <Page
      title={UrlRoutes.FacilityAttributes.label}
    >
      <Card>
        <AuditPlanningFilters
          showMoreFilters
          showFacilities
          showPlanStatus={false}
          showBusinessViews
          showLeadAuditors={false}
          showReviewStatus
          showReviewedBy
          showIncludeDeletedAttributes
          showAuditCalendarStatuses={false}
          showCalendarAuditTypes={false}
          showComplianceResults={false}
          showUnassigned={false}
        />
      </Card>

      <div
        className="grid-card-wrapper"
      >
        <Card
          title="Facility Attributes List"
          className={`grid-card ${mode === "edit" ? "editable" : ""} ${isMaximized ? "maximized" : ""}`}
          headerElement={
            <div
              className="main-buttons"
            >
              {editableRows.length > 0
                && hasDataEverLoaded && (
                  <>
                    <Button
                      buttonType={mode === "view" ? "secondary" : "red"}
                      img={editIcon}
                      imgPlacement="right"
                      onClick={() => dispatch(setMode(mode === "view" ? "edit" : "view"))}
                    >
                      {mode === "view"
                        ? "Edit Attributes"
                        : "Exit Edit Mode"
                      }
                    </Button>

                    {reviewableAttributes.length > 0 && (
                      <Button
                        buttonType="secondary"
                        img={editIcon}
                        imgPlacement="right"
                        onClick={() => dispatch(markAttributesReviewed(reviewableAttributes))}
                      >
                        Mark All Reviewed
                      </Button>
                    )}
                  </>
                )
              }
              <UserPlanningViewPicker
                page="FacilityAttributes"
              />
              <Button
                buttonType="secondary"
                imgPlacement="right"
                img={isMaximized
                  ? CloseIcon
                  : FullscreenIcon}
                onClick={() => setIsMaximized(!isMaximized)}
              />
            </div>
          }
        >
          {(!appliedFilters
            || !hasDataEverLoaded)
            ? (
              <Banner
                type={BannerType.info}
              >
                Please choose at least one of each required filter above and then click Apply Filters.
              </Banner>
            ) : (
              <>
                <FacilityAttributesGrid
                  editableRows={editableRows}
                  editableAttributes={editableAttributes}
                />

                <div
                  className="grid-footer"
                >
                  <div
                    className="facility-info"
                  >
                    <img
                      className="icon-small-info"
                      alt=""
                      src={infoIcon}
                    />

                    <span>
                      For any changes to the Facilities, please contact your local<br />
                      Facilities Representative.
                    </span>
                  </div>

                  <div>
                    <div
                      className="cell-div"
                    >
                    </div>
                    <span className="hint">Audit Requirement</span>
                  </div>

                  <div
                    className="divider-line"
                  >
                    <Divider />
                  </div>

                  <div>
                    <div
                      className="cell-div"
                    >
                      <img
                        src={CheckIcon}
                        alt="Reviewed"
                        className="icon-medium"
                      />
                    </div>
                    <span className="hint">Reviewed Audit Requirement (within last 6 months)</span>
                  </div>

                </div>
              </>
            )
          }
        </Card>
      </div>

      <PlanningSpinner />

      {(reviewAttributesOp?.isWorking
        || addChildDimensionOp?.isWorking
        || addParentDimensionOp?.isWorking
        || createAttributeOp?.isWorking
        || editAttributeOp?.isWorking
        || removeAttributeOp?.isWorking
        || removeChildDimensionOp?.isWorking
        || centerAuditAttributeOp?.isWorking)
        && <ModalSpinner />
      }

      <AddParentDimensionModal />
      <AddChildDimensionModal />
      <RemoveAttributeModal />
      <RemoveChildDimensionModal />

      {!createAttributeOp?.isWorking &&
        <CreateAttributeModal />
      }

      {centerAuditAttributeModal.showModal &&
        <CreateAttributeModalCLM />
      }

      <ConfirmRemoveOrEditAttributeModal />

      {!editAttributeOp?.isWorking &&
        <EditAttributeModal />
      }
    </Page>
  );
};

export default FacilityAttributes;
