import config from "config";
import React from "react";
import { BannerType } from "shared/components/common/banner/Banner";
import GlobalMessageBar from "shared/components/layout/global-message-bar/GlobalMessageBar";

const NewProdUrlMessageBar: React.FC = () => {
  if (config.appInfo.env !== "PROD") {
    // This message should not appear if the current environment is not prod.
    return null;
  }

  return (
    <GlobalMessageBar
      messageUuid="auditMsg_586e4822-6540-413a-afa8-be1120a04a1a"
      type={BannerType.info}
    >
      We have updated our website URL to <a href={config.prodUrl}>{config.prodUrl}</a>. Please update your bookmarks to reflect this change.
    </GlobalMessageBar>
  );
};

export default NewProdUrlMessageBar;