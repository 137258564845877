import SecurityApi from "api/security/SecurityApi";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IProfile, IUserProfile } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { refreshGrid } from "store/grid/GridSlice";
import { RootState } from "store/store";
import { Roles } from "types/authTypes";
import { finishDeleteProfile, finishLoadProfile, finishSaveProfile, IProfileManagerValues, startDeleteProfile, startLoadProfile, startSaveProfile, stopSaveProfile } from "./UserManagementSlice";
export default function* userManagementSagas() {
  yield all([
    startSaveProfileAsync(),
    startLoadProfileAsync(),
    startDeleteProfileAsync(),
  ]);
}

function* startSaveProfileAsync() {
  yield takeLatest(startSaveProfile, function* () {
    const managerValues: Partial<IProfileManagerValues>
      = yield select((store: RootState) => store.userManagement.managerValues);

    if (!managerValues.user?.email) {
      yield put(showErrorToast("User email is required."));
      yield put(stopSaveProfile());
      return;
    }

    if (!managerValues.module) {
      yield put(showErrorToast("Module is required."));
      yield put(stopSaveProfile());
      return;
    }

    if (!managerValues.role) {
      yield put(showErrorToast("Role is required."));
      yield put(stopSaveProfile());
      return;
    }

    const profile: IProfile = {
      id: managerValues.profileId || 0,
      module: {
        description: managerValues.module,
        name: managerValues.module,
      },
      profileMetaData: managerValues.metaData || [],
      role: {
        description: managerValues.role,
        name: managerValues.role,
      },
      userId: 0,
    };

    try {
      const opType = profile.id
        ? "update"
        : "create";

      if (opType === "update") {
        // Update the profile on the api.
        yield call(SecurityApi.updateProfileForUser, profile, managerValues.user.email);
      } else {
        // Create the profile on the api.
        yield call(SecurityApi.createProfileForUser, profile, managerValues.user.email);
      }

      yield put(showSuccessToast(`Profile ${opType}d successfully.`));
      yield put(finishSaveProfile(true));
      yield put(refreshGrid({ gridId: "UserManagement" }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishSaveProfile(false));
    }
  });
}

function* startLoadProfileAsync() {
  yield takeLatest(startLoadProfile, function* (action) {
    try {
      // Load all profiles for this user.
      const userProfile: IUserProfile = yield call(SecurityApi.getUserProfileByUserAndModule,
        action.payload.module,
        action.payload.email);

      // Find the one profile that matches this profile Id.
      const profile = userProfile.profiles.find(x => x.id === action.payload.profileId);

      if (!profile) {
        throw Error("Profile not found.");
      }

      // Construct the data to be sent into the redux store.
      const managerData: IProfileManagerValues = {
        module: profile.module.name,
        profileId: profile.id,
        role: profile.role.name as Roles,
        user: userProfile.user,
        metaData: profile.profileMetaData,
      };

      // Finish loading the profile with this meta data.
      yield put(finishLoadProfile(managerData));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishLoadProfile(undefined));
    }
  });
}

function* startDeleteProfileAsync() {
  yield takeLatest(startDeleteProfile, function* (action) {
    try {
      yield call(SecurityApi.deleteProfileForUser, action.payload.profileId, action.payload.email);
      yield put(showSuccessToast("Profile deleted successfully."));
      yield put(finishDeleteProfile(true));
      yield put(refreshGrid({ gridId: "UserManagement" }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishDeleteProfile(false));
    }
  });
}