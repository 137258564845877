import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import config from "config";
import React, { useEffect, useState } from "react";
import ModalSpinner from 'shared/components/common/spinner/ModalSpinner';
import Card from "shared/components/layout/card/Card";
import FlexRow from "shared/components/layout/flex/FlexRow";
import DataGrid from "shared/components/layout/grid/DataGrid";
import DataGridExportButton from "shared/components/layout/grid/export-button/DataGridExportButton";
import { DataGridFilterOperator } from "shared/components/layout/grid/types/dataGridTypes";
import FittedPage from "shared/components/layout/page/fitted-page/FittedPage";
import TabSelection from "shared/components/layout/tab-selection/TabSelection";
import formatDate from 'shared/utilities/dateFormatters';
import { useQuery } from "shared/utilities/routingUtilities";
import { toTitleCase } from "shared/utilities/stringUtilities";
import { refreshGrid } from "store/grid/GridSlice";
import { closeActionItem, handleActionItemChanged, loadAndInspectActionItem } from "store/my-actions/MyActionsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemRelations } from "types/actionItemTypes";
import ManageActionItemModal from "./manage/ManageActionItemModal";
import "./MyActionsPage.scoped.scss";

const MyActionsPage: React.FC = () => {
  const [relation, setRelation] = useState<ActionItemRelations>(ActionItemRelations.All);
  const url = config.endpoints.actionItems.myActions + `?relationFilter=${relation}`;

  // Action item modal data.
  const loadActionItemOp = useAppSelector(store => store.myActions.loadActionItemOp);
  const inspectedActionItem = useAppSelector(store => store.myActions.inspectedActionItem);

  const dispatch = useAppDispatch();

  const queryParams = useQuery();
  const queryActionItemId = queryParams.get("actionItemId");

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: `My Actions - ${relation}`,
    });
  }, [relation, appInsights]);

  useEffect(() => {
    const num = Number(queryActionItemId);

    if (queryActionItemId
      && num
      && !isNaN(num)
      && isFinite(num)) {
      dispatch(loadAndInspectActionItem(num));
    }
  }, [queryActionItemId, dispatch]);

  const onActionItemClicked = (id: number) => {
    dispatch(loadAndInspectActionItem(id));
  };

  const actionTemplate = (row: IMyActionsGridRowObject) => {
    return (
      <button
        className="link"
        onClick={() => onActionItemClicked(row.id)}
      >
        {row.actionRequired}
      </button>
    );
  };

  return (
    <FittedPage>
      <Card
        title="Action Items"
        className="my-actions-card"
        headerElement={(
          <FlexRow className="align-items-center">
            <DataGridExportButton
              gridId="MyActions"
            />

            <TabSelection
              tabs={enumKeys(ActionItemRelations).map(k => ({
                key: ActionItemRelations[k],
                node: toTitleCase(ActionItemRelations[k].toString()),
                isSelected: relation === ActionItemRelations[k],
              }))}
              onTabClicked={key => {
                let keys = enumKeys(ActionItemRelations);
                for (let i = 0; i < keys.length; i++) {
                  if (keys[i] === key.toString()) {
                    setRelation(ActionItemRelations[keys[i]]);
                    return;
                  }
                }
              }}
            />
          </FlexRow>
        )}
      >

        <DataGrid
          id="MyActions"
          dataSource="OData"
          dataUrl={url}
          allowExcelExport={true}
          enablePersistence={true}
          filterSettings={{
            columns: [{
              uid: "status",
              field: "status",
              value: "Open",
              operator: DataGridFilterOperator.Equals,
            }]
          }}
          sortSettings={{
            columns: [{
              field: "dueDate",
              direction: "Ascending",
            }],
          }}
          gridColumns={[{
            uid: "parentId",
            field: "parentId",
            headerText: "Parent Id",
            width: 150,
            format: "f0",
            type: "number",
            textAlign: "Left"
          },
          {
            uid: "parentType",
            field: "parentType",
            headerText: "Parent Type",
            width: 150,
            type: "string",
          },
          {
            uid: "parentName",
            field: "parentName",
            headerText: "Parent Name",
            width: 250,
            type: "string",
          },
          {
            uid: "id",
            field: "id",
            headerText: "Action Item Id",
            width: 180,
            format: "f0",
            type: "number",
          },
          {
            uid: "type",
            field: "type",
            headerText: "Action Item Type",
            width: 250,
            type: "string",
          },
          {
            uid: "actionRequired",
            field: "actionRequired",
            headerText: "Action Required",
            width: 250,
            type: "string",
            template: actionTemplate
          },
          {
            uid: "assignedToName",
            field: "assignedToName",
            headerText: "Assigned To",
            width: 250,
            type: "string",
          },
          {
            uid: "createdByName",
            field: "createdByName",
            headerText: "Created By",
            width: 250,
            type: "string",
          },
          {
            uid: "priority",
            field: "priority",
            headerText: "Priority",
            width: 250,
            type: "string",
          },
          {
            uid: "dueDate",
            field: "dueDate",
            headerText: "Due Date",
            width: 200,
            type: "date",
            format: "MM/dd/yyyy",
            template: (row: IMyActionsGridRowObject) => formatDate(row.dueDate),
          },
          {
            uid: "status",
            field: "status",
            headerText: "Status",
            width: 200,
            type: "string",
          },
          {
            uid: "isValidated",
            field: "isValidated",
            headerText: "Validated?",
            width: 160,
            type: "boolean",
          },
          ]}
        />

      </Card>

      {loadActionItemOp?.isWorking && <ModalSpinner />}

      {inspectedActionItem && (
        <ManageActionItemModal
          actionItem={inspectedActionItem}
          actionItemEditors={[]}
          actionItemLinks={[]}
          onCancel={() => dispatch(closeActionItem())}
          onActionItemChanged={ev => {
            dispatch(handleActionItemChanged(ev));
            dispatch(refreshGrid({ gridId: "MyActions" }));
          }}
        />
      )}
    </FittedPage>
  );
};

export default MyActionsPage;

function enumKeys<E>(e: E): (keyof E)[] {
  return Object.keys(e as any) as (keyof E)[];
}

export interface IMyActionsGridRowObject {
  parentId?: number,
  parentType: string,
  parentName: string,
  id: number,
  type: string,
  actionRequired: string,
  assignedToName: string,
  assignedToEmail: string,
  createdByName: string,
  createdByEmail: string,
  priority: string,
  dueDate: Date,
  status: string,
  isValidated: boolean,
  closedOn?: Date,
  createdOn: Date,
  auditLeaderEmail: string,
  deleted: boolean,
}