import { PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { IAuditState } from "../AuditSlice";

export interface IAuditExportState {
  exportOp?: IOperation<void>,
}

export const initialAuditExportState: IAuditExportState = {
  exportOp: undefined,
};

const exportAuditReducers = {
  /** Begins the audit export procedure and sets exportOp to working. */
  exportAudit: (state: IAuditState, _: PayloadAction<number>) => {
    state.exportOp = {
      isWorking: true,
    };
  },

  /** 
   * Clears the exportOp.
   */
  finishExportAudit: (state: IAuditState) => {
    state.exportOp = undefined;
  },
};

export default exportAuditReducers;
