import { getMatchingRoute } from "components/routing/UrlRoutes";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Breadcrumbs.scoped.scss";

const Breadcrumbs: React.FC = () => {
  const location = useLocation();

  let locationChunks = location
    .pathname
    .split("/")
    .filter(x => x);

  let links: { to: string, label: string }[] = [];

  let overallUrl = "";
  locationChunks.forEach(locationChunk => {
    overallUrl += "/" + locationChunk;

    const matchedRoute = getMatchingRoute(overallUrl);

    if (matchedRoute?.label) {
      links.push({ to: overallUrl, label: matchedRoute.label });
    }
  });

  if (!links.length) {
    return null;
  }

  return (
    <div>
      {links.map(x =>
        <Link
          key={x.label}
          to={x.to}
        >
          {x.label}
        </Link>
      )}
    </div>
  );
};

export default Breadcrumbs;