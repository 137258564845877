import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { getResponseFromQuestion, sortQuestionsBySTThenNumber } from "components/audits/common/auditUtilities";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import { Link } from "react-router-dom";
import DetailText from "shared/components/common/detail-text/DetailText";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import infoIcon from "shared/media/dls/info.svg";
import { IColumnItem } from "shared/types/columnTypes";
import { findActionItemsByParentItems } from "shared/utilities/actionItemUtilities";
import { userToString } from "shared/utilities/userUtilities";
import { toggleQuestionSelection } from "store/audit-summary/AuditSummarySlice";
import { assignAuditorToQuestions, setActionItemListModal, setEvidenceModal, setFocusedGuidance } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes, IActionItem } from "types/actionItemTypes";
import { IAnswer, IAuditTopic } from "types/auditMasterDataTypes";
import { AuditStatuses, IAuditQuestion, IAuditor } from "types/auditingTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICountry } from "types/masterDataTypes";
import AuditorDropdown from "../auditor-dropdown/AuditorDropdown";
import QuestionAssociationsModal from "../question-associations-modal/QuestionAssociationsModal";
import "./TopicQuestionTable.scoped.scss";

interface IQuestionTableProps {
  questions: IAuditQuestion[],
  auditStatus: AuditStatuses,
  isDisabled: boolean,
  auditors: IAuditor[],
}

interface ITableRowProps {
  association?: string,
  countries?: ICountry[],
  businessFunctions?: IBusinessFunction[],
  businessTeams?: IBusinessTeam[],
  businessViews?: IBusinessView[],
  subTopics?: IAuditTopic[],
  answer?: IAnswer,
  notes?: string,
}

const TopicQuestionTable: React.FC<IQuestionTableProps> = ({
  questions,
  auditStatus,
  isDisabled,
  auditors,
}) => {
  const audit = useAppSelector(store => store.audit.audit);
  const allAnswers = useAppSelector(store => store.audit.answers);
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const selectedAuditQuestionIds = useAppSelector(store => store.auditSummary.selectedAuditQuestionIds);
  const dispatch = useAppDispatch();

  const sortedQuestions = questions
    .slice()
    .sort(sortQuestionsBySTThenNumber) || [];

  const checkableQuestions = questions.filter(question => {
    if (isDisabled) {
      return false;
    }

    const response = getResponseFromQuestion(question, auditStatus);
    return response === undefined;
  });

  let areAllCheckableQuestionsSelected = false;

  if (checkableQuestions.length) {
    areAllCheckableQuestionsSelected = !checkableQuestions
      .some(q => !selectedAuditQuestionIds.includes(q.auditQuestionId));
  }

  return (
    <div className="table-container">
      <Table<IAuditQuestion, keyof IAuditQuestion, ITableRowProps>
        columns={getTableColumns(selectedAuditQuestionIds,
          isDisabled,
          audit?.id || 0,
          auditors || [],
          actionItems,
          dispatch,
          areAllCheckableQuestionsSelected,
          checkableQuestions)
        }
        data={sortedQuestions}
        keyProp="auditQuestionId"
        theme={TableThemes.compact}
        getBeforeRenderRowData={question => {
          // Figure out the association for this question.
          // Also get the current response.
          const response = getResponseFromQuestion(question, auditStatus);

          const answer = allAnswers.find(x => x.code === response?.answer);

          const tableRowProps: ITableRowProps = {
            answer,
            notes: response?.notes,
          };

          if (!audit) {
            return tableRowProps;
          }

          tableRowProps.subTopics = question
            .subTopics
            .filter(x => audit.auditTopics.map(z => z.id).includes(x.id));

          tableRowProps.countries = question
            .countries
            .filter(x => audit.countries.map(z => z.id).includes(x.id));

          tableRowProps.businessViews = question
            .businessViews
            .filter(x => audit.businessViews.map(z => z.id).includes(x.id));

          tableRowProps.businessTeams = question
            .businessTeams
            .filter(x => audit.businessTeams.map(z => z.id).includes(x.id));

          tableRowProps.businessFunctions = question
            .businessFunctions
            .filter(x => audit.businessFunctions.map(z => z.id).includes(x.id));

          // See `isQuestionGlobalAssociation` in AuditorAssociationModal.tsx.
          tableRowProps.association = tableRowProps.subTopics?.[0]?.name
            ?? tableRowProps.countries?.[0]?.name
            ?? getCodePlusName(tableRowProps.businessViews?.[0])
            ?? getCodePlusName(tableRowProps.businessTeams?.[0])
            ?? getCodePlusName(tableRowProps.businessFunctions?.[0]);

          return tableRowProps;
        }}
      />
    </div>
  );
};

export default TopicQuestionTable;

function getTableColumns(selectedAuditQuestionIds: number[],
  isDisabled: boolean,
  auditId: number,
  auditors: IAuditor[],
  actionItems: IActionItem[],
  dispatch: Dispatch<AnyAction>,
  areAllQuestionsSelected: boolean,
  checkableQuestions: IAuditQuestion[]): IColumnItem<IAuditQuestion, keyof IAuditQuestion, ITableRowProps | undefined>[] {
  return [
    {
      key: "checkbox",
      header: !checkableQuestions.length || isDisabled
        ? ""
        : (
          <input
            type="checkbox"
            checked={areAllQuestionsSelected}
            disabled={!checkableQuestions.length || isDisabled}
            onChange={() => dispatch(toggleQuestionSelection(
              checkableQuestions.map(question => ({
                auditQuestionId: question.auditQuestionId,
                isSelected: !areAllQuestionsSelected,
              }))))
            }
          />
        ),
      width: 30,
      customRender: (item, rowProps) => {
        if (isDisabled
          || rowProps?.answer !== undefined) {
          return undefined;
        }

        const isSelected = selectedAuditQuestionIds.indexOf(item.auditQuestionId) > -1;

        return (
          <input
            type="checkbox"
            value={item.auditQuestionId}
            checked={isSelected}
            disabled={rowProps?.answer !== undefined || isDisabled}
            onChange={() => dispatch(toggleQuestionSelection({
              auditQuestionId: item.auditQuestionId,
              isSelected: !isSelected,
            }))}
          />
        );
      },
    },
    {
      key: "associations",
      header: "Associations",
      width: 100,
      maxHeight: "4em",
      getTooltip: (_, rowProps) => rowProps?.association ?? "Global",
      customRender: (_, rowProps) => rowProps?.association
        ? (
          <DetailText
            className="table-cell-content"
            text={rowProps.association}
            showModalOnClick
            isAlwaysClickable={rowProps.association !== undefined}
            modalData={{
              header: "Associations",
              content:
                <QuestionAssociationsModal
                  {...rowProps}
                />
            }}
          />
        )
        : "Global",
    },
    {
      key: "questionNumber",
      property: "questionNumber",
      header: "ID",
      customRender: item => (
        <Link
          to={formatRoute(UrlRoutes.AuditExecuteQuestion, {
            auditId: auditId.toString(),
            auditQuestionId: item.auditQuestionId.toString(),
          })}
        >
          {item.questionNumber}
        </Link>
      ),
    },
    {
      key: "question",
      property: "question",
      header: "Question",
      width: 250,
      maxHeight: "6em",
      getTooltip: item => item.question || "--",
      customRender: item => item.question || "--",
    },
    {
      key: "lto",
      property: "licenseToOperate",
      header: "LTO",
      width: 60,
      maxHeight: "4em",
      customRender: item => item.licenseToOperate ? "Y" : "--",
    },
    {
      key: "guidance",
      property: "guidance",
      header: "Guidance",
      width: 60,
      customRender: item => item.guidance
        ? (
          <img
            src={infoIcon}
            className="icon-small guidance-button"
            title={item.guidance}
            onClick={() => dispatch(setFocusedGuidance({
              guidance: item.guidance,
              questionNumber: item.questionNumber,
            }))}
            alt="View"
          />
        ) : "--",
    },
    {
      key: "answer",
      header: "Answer",
      width: 60,
      getTooltip: (_, rowProps) => rowProps?.answer?.name ?? rowProps?.answer?.code ?? "--",
      customRender: (_, rowProps) => rowProps?.answer?.code ?? "--",
    },
    {
      key: "auditorComments",
      header: "Auditor Comments",
      width: 250,
      maxHeight: "4em",
      getTooltip: (_, rowProps) => rowProps?.notes || "--",
      customRender: (_, rowProps) => rowProps?.notes || "--",
    },
    {
      key: "evidence",
      property: "evidence",
      header: "Evidence",
      width: 60,
      customRender: item => (
        <button
          className="link"
          onClick={() => dispatch(setEvidenceModal({
            auditQuestionId: item.auditQuestionId,
            isOpen: true,
          }))}
        >
          {item.evidence.length}
        </button>
      ),
    },
    {
      key: "numActionItems",
      header: "# Action Items",
      width: 80,
      customRender: item => (
        <button
          className="link"
          onClick={() => dispatch(setActionItemListModal({
            linkOptions: {
              linkId: item.auditQuestionId,
              linkType: ActionItemLinkTypes.AuditQuestion,
              parentDisplay: `Question #${item.questionNumber}`,
            },
            isOpen: true,
          }))}
        >
          {findActionItemsByParentItems(actionItems, [{
            linkType: ActionItemLinkTypes.AuditQuestion,
            linkId: item.auditQuestionId,
          }]).length.toString()}
        </button>
      ),
    },
    {
      key: "interviewees",
      property: "interviewees",
      header: "Interviewees",
      width: 100,
      maxHeight: "4em",
      customRender: item => item.interviewees.length === 0
        ? "--"
        : item.interviewees.map(x => userToString(x)).join(", "),
      getTooltip: item => item.interviewees.length === 0
        ? "--"
        : item.interviewees.map(x => userToString(x)).join(", "),
    },
    {
      key: "auditorEmail",
      property: "auditorEmail",
      header: "Auditor",
      width: 150,
      customRender: (item, rowProps) => {
        const assignedAuditor = auditors.find(x => x.email === item.auditorEmail);

        if (isDisabled
          || rowProps?.answer !== undefined) {
          return (
            <span>
              {assignedAuditor === undefined
                ? "Unassigned"
                : userToString(assignedAuditor)
              }
            </span>
          );
        }

        return (
          <AuditorDropdown
            auditors={auditors}
            maxWidth={"10em"}
            disabled={rowProps?.answer !== undefined || isDisabled}
            selectedAuditors={[assignedAuditor]}
            allowClearButton={true}
            onChange={(auditor) => dispatch(assignAuditorToQuestions({
              auditorEmail: auditor?.email,
              auditQuestionIds: [item.auditQuestionId],
            }))}
            className={assignedAuditor === undefined
              ? "required-error"
              : undefined
            }
          />
        );
      },
    },
  ];
}

function getCodePlusName(item?: { name: string, code: string; }): string | undefined {
  if (!item) {
    return undefined;
  }
  return `${item.code} - ${item.name}`;
}