import React from "react";
import { PlanningPeriodsType } from "types/adminPlanningAdministration";
import AdminPlanPeriodPage from "../AdminPlanPeriodPage";

const FacilityAttributesAdminPage: React.FC = () => {
  return (
    <AdminPlanPeriodPage
      pageTitle="Audit Planning Administration"
      cardTitle="Facilities Attributes Update Period"
      periodType={PlanningPeriodsType.facilityAttribute}
      showBreadcrumbs={true}
    />
  )
};

export default FacilityAttributesAdminPage;