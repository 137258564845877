import React from "react";
import "./SendNotificationCheckbox.scoped.css";

interface ISendNotificationCheckbox {
  isSendNotificationSelected: boolean,
  setSendEmailNotification(isSendNotificationSelected: boolean): void,
}

const SendNotificationCheckbox: React.FC<ISendNotificationCheckbox> = ({
  isSendNotificationSelected,
  setSendEmailNotification
}) => {
  return (
    <div className="div">
      <label className="label">
        Send e-mail notification
        <input
          type="checkbox"
          disabled={false}
          value="Send Email"
          checked={isSendNotificationSelected}
          onChange={() => setSendEmailNotification(!isSendNotificationSelected)}
        />
      </label>
    </div>
  );
};

export default SendNotificationCheckbox;