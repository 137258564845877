import React from "react";
import "./MultiButtons.scoped.scss";

export interface IMultiButton {
  /** The unique key to identify this tab. */
  key: string | number,
  /** The text to be displayed. */
  text: string,
  /** The function to call when the user clicks the button. */
  onClick(): void,
  /** If the button will be disabled or not */
  disabled: boolean,
}

interface IMultiButtonsProps {
  /** The buttons that will be displayed */
  buttons: IMultiButton[],
  /** The buttons that will be displayed */
  selectedKey: string,
}

const MultiButtons: React.FC<IMultiButtonsProps> = ({
  buttons,
  selectedKey
}) => {
  return (
    <div>
      {
        buttons.map(b => (
          <button
            disabled={b.disabled}
            key={b.key}
            className={b.key === selectedKey ? "primary" : "secondary"}
            onClick={() => b.onClick()}
          >
            {b.text}
          </button>
        ))
      }
    </div>
  );
};

export default MultiButtons;