
import AuditPageAuthApi from "api/auditPageAuth/AuditPageAuthApi";
import { Action } from "redux";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { showErrorToast } from "../../shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "../../shared/utilities/apiUtilities";
import { IAuditPageAuth, PageAuthRequestType } from "../../types/auditPageAuthTypes";
import { finishLoadPageAuth, loadPageAuth } from "./AuditPageAuthSlice";

export default function* auditPageAuthSagas() {
  yield all([
    loadAuditPageAuthAsync(),
  ]);
}

function* loadAuditPageAuthAsync() {
  yield takeEvery(loadPageAuth, function* (action: Action) {
    if (!loadPageAuth.match(action)) {
      return;
    }

    try {
      const restriction: IAuditPageAuth =
        action.payload.type === PageAuthRequestType.audit
          ? yield call(AuditPageAuthApi.getAuditPageAuthInfo, action.payload)
          : yield call(AuditPageAuthApi.getNonAuditPageAuthInfo, action.payload);

      yield put(finishLoadPageAuth({
        isWorking: false,
        data: restriction,
      }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishLoadPageAuth({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}