import React from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { useAppSelector } from "store/store";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { deleteAuditor, setValue, startSaveAuditor, toggleDeleteConfirmation } from "store/auditors/AuditorSlice";
import AzureUserPicker from "shared/components/controls/pickers/AzureUserPicker";
import { AuditorRole } from "api/auditor/auditorTypes";
import DatePicker from "shared/components/controls/date-picker/DatePicker";
import "./AuditorManagerModal.scoped.scss";

/** A modal that handles all user interaction for creating/editing/deleting a user profile. */
const AuditorManagerModal: React.FC = () => {
  const dispatch = useDispatch();
  const isDeleteConfirmationOpen = useAppSelector(store => store.auditors.isDeleteConfirmationOpen);
  const managerValues = useAppSelector(store => store.auditors.managerValues);
  const isDirty = useAppSelector(store => store.auditors.isDirty);
  const modalButtons = [
    {
      className: "secondary",
      key: "Cancel",
      text: "Cancel",
      onClick: () => dispatch(setValue({ isManagerOpen: false, })),
    }, {
      className: "primary",
      key: "Save",
      text: managerValues.id
        ? "Save"
        : "Add",
      disabled: !isDirty,
      onClick: () => dispatch(startSaveAuditor()),
    }].filter(isNotUndefined);

  return (
    <>
      {!isDeleteConfirmationOpen && <Modal
        showCloseButton={true}
        onCloseButtonClicked={() => dispatch(setValue({
          isManagerOpen: false,
        }))
        }
        isOpen={true}
        header={managerValues.id
          ? "Update Auditor"
          : "Add Auditor"
        }
        buttons={modalButtons}
      >
        <FlexCol>
          <LabeledControl label="User"
            isRequired={true}>
            <AzureUserPicker
              onApply={items => dispatch(setValue({
                managerValues: {
                  ...managerValues,
                  email: items[0]?.email,
                  user: items.length > 0
                    ? items[0]
                    : undefined
                }
              }))}
              selectedItems={managerValues?.user
                ? [managerValues?.user]
                : []}
              allowMultiselect={false}
              isDisabled={(managerValues?.id ?? 0) > 0}
            />
          </LabeledControl>
          <FlexRow>
            <LabeledControl
              label="Valid From"
              className="start-date"
              isRequired
            >
              <DatePicker
                value={managerValues.validFrom}
                onChange={(date) => {
                  const dateValue = Array.isArray(date)
                    && date[0]
                    ? date[0]
                    : (!Array.isArray(date)
                      && date
                      ? date
                      : null);

                  if (!dateValue) {
                    return;
                  }
                  dispatch(setValue({ managerValues: { ...managerValues, validFrom: dateValue } }))
                }}
              />
            </LabeledControl>

            <LabeledControl
              label="Valid To"
              className="end-date"
              isRequired
            >
              <DatePicker
                value={managerValues.validTo}
                minDate={managerValues.validFrom ?? undefined
                }
                onChange={(date) => {
                  const dateValue = Array.isArray(date)
                    && date[0]
                    ? date[0]
                    : (!Array.isArray(date)
                      && date
                      ? date
                      : null);

                  if (!dateValue) {
                    return;
                  }

                  dispatch(setValue({ managerValues: { ...managerValues, validTo: dateValue } }))
                }}
              />
            </LabeledControl>
          </FlexRow>
          <LabeledControl
            label={`Role`}
            isRequired
          >
            <div className='input-types'>
              <div className="label-options yes-option">
                <input
                  type="radio"
                  checked={managerValues.type === AuditorRole.Auditor}
                  onChange={() => dispatch(setValue({ managerValues: { ...managerValues, type: AuditorRole.Auditor } }))}
                />
                <label className="yes-no-option">Auditor</label>
              </div>
              <div className="label-options">
                <input
                  type="radio"
                  checked={managerValues.type === AuditorRole.LeadAuditor}
                  onChange={() => dispatch(setValue({ managerValues: { ...managerValues, type: AuditorRole.LeadAuditor } }))}
                />
                <label className="yes-no-option">Lead Auditor</label>
              </div>
            </div>
          </LabeledControl>
          <FlexRow>
            <LabeledControl
              label="Reason For Manual Add"
              className="reason-to-manual-add"
              isRequired
            >
              <input
                type="text"
                min={0}
                placeholder="Enter Reason"
                value={managerValues.reason ?? ""}
                onChange={(e) => dispatch(setValue({ managerValues: { ...managerValues, reason: e.target.value } }))}
                maxLength={200}
                width={400}
              />
            </LabeledControl>
          </FlexRow>
        </FlexCol>
      </Modal >
      }
      {
        isDeleteConfirmationOpen && (
          <ConfirmModal
            header="Confirm Delete"
            message={<span>
              Are you sure you want to delete this auditor? This operation cannot be undone.
              <p>
                {managerValues?.user?.name}({managerValues?.user?.email?.split("@")[0]})
              </p>
            </span>}

            onNoClicked={() => dispatch(toggleDeleteConfirmation(false))}
            onYesClicked={() => {
              dispatch(toggleDeleteConfirmation(false));
              dispatch(deleteAuditor(Number(managerValues?.id)));
            }}

            noButtonOptions={{ text: `No` }}
            yesButtonOptions={{ text: `Yes` }}
          />
        )
      }

    </>);
};

export default AuditorManagerModal;
