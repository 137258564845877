import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatDateFromAuditPlanningConfigItem, parsePlanningConfigDefaultDates, parsePlanningConfigMode } from "api/admin/planning-administration/formatters/auditPlanningConfigFormatters";
import { cloneDeep, isEqual } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IAdminPlanningAdministrationPeriod, PeriodMode, PlanningPeriodsType } from "types/adminPlanningAdministration";
import { IAuditPlanningConfig } from "types/auditPlanningTypes";
import { IBusinessTeam } from "types/masterDataTypes";
import PlanningAdministrationPeriod from "types/planning-admin/PlanningAdministrationPeriod";

export interface IAdminFacilityAttributesState {
  periods: IAdminPlanningAdministrationPeriod[],
  originalPeriods: IAdminPlanningAdministrationPeriod[],
  askEnforceGlobal: boolean,
  mode: PeriodMode,
  originalMode: PeriodMode,

  autoDate: number | undefined,
  originalAutoDate: number | undefined,

  isDirty: boolean,
  loadPeriodsOp?: IOperation<{
    planningPeriods: IAdminPlanningAdministrationPeriod[],
    businessTeams: IBusinessTeam[],
    configItems: IAuditPlanningConfig[],
  }>,
  upsertPeriodsOp?: IOperation<IAdminPlanningAdministrationPeriod[]>,
}

export interface IAdminPlanApprovalSetValue {
  businessTeamId: number,
  values: Partial<IAdminPlanningAdministrationPeriod>,
}

const initialState: IAdminFacilityAttributesState = {
  periods: [],
  originalPeriods: [],
  askEnforceGlobal: false,
  mode: "PerGeoUnit",
  originalMode: "PerGeoUnit",

  autoDate: undefined,
  originalAutoDate: undefined,

  isDirty: false,
  loadPeriodsOp: undefined,
  upsertPeriodsOp: undefined,
}

export const adminFacilityAttributesSlice = createSlice({
  name: "admin-facility-attribute",
  initialState,
  reducers: {
    loadPeriods: (state, _: PayloadAction<PlanningPeriodsType>) => {
      state.loadPeriodsOp = {
        isWorking: true,
      };
    },

    finishLoadPeriods: (state, action: PayloadAction<IOperation<{
      planningPeriods: IAdminPlanningAdministrationPeriod[],
      businessTeams: IBusinessTeam[],
      configItems: IAuditPlanningConfig[],
    }>>) => {
      if (action.payload.errorMessage
        || !action.payload.data) {
        state.loadPeriodsOp = action.payload;
        state.periods = [];
        return;
      }

      const {
        planningPeriods,
        businessTeams,
        configItems,
      } = action.payload.data;

      const currYear = new Date().getFullYear();
      state.originalAutoDate = formatDateFromAuditPlanningConfigItem(configItems.find(x => x.name === "AutoDate")?.value, currYear)?.getTime();
      state.autoDate = formatDateFromAuditPlanningConfigItem(configItems.find(x => x.name === "AutoDate")?.value, currYear)?.getTime();

      state.originalMode = state.mode = parsePlanningConfigMode(
        configItems.find(x => x.name === "Mode"));

      const {
        defaultStartDate,
        defaultEndDate,
      } = parsePlanningConfigDefaultDates(configItems);

      const dateGlobal = planningPeriods.find(x => x.businessTeamId === 0);
      const periodGlobal: IAdminPlanningAdministrationPeriod[] = [{
        id: dateGlobal?.id || 0,
        businessTeamId: dateGlobal?.businessTeamId || 0,
        basin: "",
        geoUnit: "Global",
        planningAdministrationPeriodDates: dateGlobal?.planningAdministrationPeriodDates
          || new PlanningAdministrationPeriod({
            endDay: defaultEndDate.getDate(),
            endMonth: defaultEndDate.getMonth() + 1,
            startDay: defaultStartDate.getDate(),
            startMonth: defaultStartDate.getMonth() + 1,
          }),
        manualOverride: dateGlobal?.manualOverride ?? false,
        overrideExpiryDate: dateGlobal?.overrideExpiryDate ?? defaultStartDate.getTime(),
        modifiedBy: dateGlobal?.modifiedBy ?? "System",
        modifiedOn: dateGlobal?.modifiedOn ?? new Date().getTime()
      }];

      let newBusinessTeamHierarchy:IBusinessTeam[] = [];
      for (let i = 0; i < businessTeams.length; i++) {
        getCorrectBusinessTeamHierarchy(businessTeams[i], newBusinessTeamHierarchy);
      }

      // For each of the geo units, find or create a planning period item.
      const periodsWithGeoUnitData: IAdminPlanningAdministrationPeriod[] =
        newBusinessTeamHierarchy.flatMap(x =>
          x.children.map(y => getPeriod(x, y, planningPeriods, defaultStartDate, defaultEndDate)));

      state.periods = periodsWithGeoUnitData.concat(periodGlobal);
      state.originalPeriods = cloneDeep(state.periods);
      state.isDirty = false;
      state.loadPeriodsOp = undefined;
    },

    upsertPeriods: (state, _: PayloadAction<PlanningPeriodsType>) => {
      state.upsertPeriodsOp = {
        isWorking: true,
      }
    },

    finishUpsertPeriods: (state,
      action: PayloadAction<IOperation<IAdminPlanningAdministrationPeriod[]>>) => {
      if (action.payload.errorMessage) {
        state.upsertPeriodsOp = action.payload;
        return;
      }

      state.originalMode = state.mode;
      state.originalAutoDate = state.autoDate;

      if (action.payload.data) {
        action.payload.data.forEach(savedItem => {
          // Find the saved item in both the periods list and the originalPeriods list.
          const periodIx = state.periods.findIndex(x => x.businessTeamId === savedItem.businessTeamId);
          const originalPeriodIx = state.originalPeriods.findIndex(x => x.businessTeamId === savedItem.businessTeamId);

          // Remove the saved item from the period list and add in the newly saved one.
          if (periodIx > -1) {
            savedItem.basin = state.periods[periodIx].basin;
            savedItem.geoUnit = state.periods[periodIx].geoUnit;
            state.periods.splice(periodIx, 1);
          }

          state.periods.push(savedItem);

          // Remove the saved item from the original period list and add in the newly saved one.
          if (originalPeriodIx > -1) {
            state.originalPeriods.splice(originalPeriodIx, 1);
          }

          state.originalPeriods.push(savedItem);
        });
      }

      state.isDirty = calculateIsDirty(state);
      state.upsertPeriodsOp = undefined;
    },

    setPeriodPropValue: (state, action: PayloadAction<IAdminPlanApprovalSetValue>) => {
      const period = state.periods.find(x => x.businessTeamId === action.payload.businessTeamId);

      if (period) {
        Object.assign(period, action.payload.values);
        state.isDirty = calculateIsDirty(state);
      }
    },

    setAskEnforceGlobal: (state, action: PayloadAction<boolean>) => {
      state.askEnforceGlobal = action.payload;
    },

    setMode: (state, action: PayloadAction<PeriodMode>) => {
      state.mode = action.payload;

      state.isDirty = calculateIsDirty(state);
    },

    setAutoDate: (state, action: PayloadAction<number>) => {
      state.autoDate = action.payload;
      state.isDirty = calculateIsDirty(state);
    },
  },
});

function calculateIsDirty(state: IAdminFacilityAttributesState): boolean {
  if (state.mode !== state.originalMode
    || state.autoDate !== state.originalAutoDate) {
    return true;
  }

  for (let i = 0; i < state.periods.length; i++) {
    const originalItem = state.originalPeriods.find(x => x.businessTeamId === state.periods[i].businessTeamId);

    const itemIsDirty = !isEqual(state.periods[i], originalItem);

    if (itemIsDirty) {
      return true;
    }
  }

  return false;
}

function getPeriod(basin: IBusinessTeam,
  geoUnit: IBusinessTeam,
  periodsDates: IAdminPlanningAdministrationPeriod[],
  defaultStartDate: Date,
  defaultEndDate: Date): IAdminPlanningAdministrationPeriod {
  let matchingPeriod = periodsDates.find(x => x.businessTeamId === geoUnit.id);

  let adminPeriodDate = new PlanningAdministrationPeriod({
    endDay: matchingPeriod?.planningAdministrationPeriodDates.endDay || defaultEndDate.getDate(),
    endMonth: matchingPeriod?.planningAdministrationPeriodDates.endMonth || (defaultEndDate.getMonth() + 1),
    startDay: matchingPeriod?.planningAdministrationPeriodDates.startDay || defaultStartDate.getDate(),
    startMonth: matchingPeriod?.planningAdministrationPeriodDates.startMonth || (defaultStartDate.getMonth() + 1),
  });

  return {
    id: matchingPeriod?.id || 0,
    manualOverride: matchingPeriod?.manualOverride || false,
    overrideExpiryDate: matchingPeriod?.overrideExpiryDate || defaultStartDate.getTime(),
    modifiedBy: matchingPeriod?.modifiedBy || "System",
    modifiedOn: matchingPeriod?.modifiedOn || new Date().getTime(),
    basin: basin.code,
    geoUnit: `${geoUnit.code} - ${geoUnit.name}`,
    planningAdministrationPeriodDates: adminPeriodDate,
    businessTeamId: geoUnit.id,
  };
}

function getCorrectBusinessTeamHierarchy(businessTeam: IBusinessTeam, newHierarchy: IBusinessTeam[]) {
  if(businessTeam.type !== "Basin") {
    for (let i = 0; i < businessTeam.children.length; i++) {
      getCorrectBusinessTeamHierarchy(businessTeam.children[i], newHierarchy);
    }
  } else {
    newHierarchy.push(businessTeam);
  }
}

export const {
  loadPeriods,
  finishLoadPeriods,
  upsertPeriods,
  finishUpsertPeriods,
  setPeriodPropValue,
  setAskEnforceGlobal,
  setMode,
  setAutoDate,
} = adminFacilityAttributesSlice.actions;
