import React from "react";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import Button from "shared/components/controls/buttons/button/Button";
import RevertIcon from "shared/media/dls/reset.svg";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import usePageRestriction from "shared/utilities/hooks/usePageRestriction";
import { confirmRevertAuditStatus, setConfirmAuditRevertStatusModal } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditPages, EditRestriction, PageAuthRequestType } from "types/auditPageAuthTypes";
import { AuditStatuses } from "types/auditingTypes";

interface IRevertStatusButtonProps {
  auditId: number,
  auditStatus: AuditStatuses,
}

const RevertStatusButton: React.FC<IRevertStatusButtonProps> = ({
  auditId,
  auditStatus,
}: IRevertStatusButtonProps) => {
  const dispatch = useAppDispatch();
  // Ask the server what the current user's RevertAuditStatus permissions are.
  const revertStatusRestriction = usePageRestriction({
    pageName: AuditPages.RevertAuditStatus,
    type: PageAuthRequestType.audit,
    auditId: auditId,
  });

  // Get the revert status modal info.
  const statusConfirmRevertModal = useAppSelector(store => store.audit.statusConfirmRevertModal);

  // Check to see if the server says the current user is allowed to revert the status.
  const hasPermission = revertStatusRestriction.data?.editRestriction !== EditRestriction.EditNone;
  // Get the previous status to which to revert the audit.
  const revertToStatus = getRevertToStatus(auditStatus);

  if (!hasPermission
    || !revertToStatus) {
    // User doesn't have permission or there is no previous status to revert to.
    return null;
  }

  return (
    <>
      <Button
        buttonType="red"
        img={RevertIcon}
        onClick={() => dispatch(setConfirmAuditRevertStatusModal({
          isOpen: true,
          auditId: auditId,
          revertFromStatus: auditStatus,
        }))}
      >
        {getButtonText(revertToStatus)}
      </Button>

      {statusConfirmRevertModal.isOpen &&
        <ConfirmModal
          message={getConfirmMessage(revertToStatus)}
          onNoClicked={() => dispatch(setConfirmAuditRevertStatusModal({ isOpen: false }))}
          onYesClicked={() => {
            const id = Number(auditId);

            if (!id) {
              dispatch(showErrorToast("No audit data is loaded."));
              return;
            }

            // Dispatch the event to start the status reversion.
            dispatch(confirmRevertAuditStatus({
              id,
              revertFromStatus: statusConfirmRevertModal.revertFromStatus,
              revertToStatus: revertToStatus,
            }));
          }}
          header={`Confirm ${getButtonText(revertToStatus)}`}
        />
      }
    </>
  );
};

export default RevertStatusButton;

function getRevertToStatus(auditStatus: AuditStatuses) {
  switch (auditStatus) {
    case AuditStatuses.Planned: return null;
    case AuditStatuses.InProgress: return AuditStatuses.Planned;
    case AuditStatuses.Completed: return AuditStatuses.InProgress;
    case AuditStatuses.Closed: return AuditStatuses.Completed;
    default: return null;
  }
}

function getButtonText(revertToStatus: AuditStatuses) {
  switch (revertToStatus) {
    case AuditStatuses.Planned: return "Change to Planned";
    case AuditStatuses.InProgress: return "Change to In Progress";
    case AuditStatuses.Completed: return "Reopen Audit";
    case AuditStatuses.Closed: return "";
    default: return "";
  }
}

function getConfirmMessage(revertToStatus: AuditStatuses) {
  switch (revertToStatus) {
    case AuditStatuses.Planned: return (
      <>
        Are you sure you want to change this audit to Planned?<br />
        <span style={{ color: "red", fontWeight: "bold" }}>All answers will be removed. This operation cannot be undone.</span>
      </>
    );
    case AuditStatuses.InProgress: return "Are you sure you want to change this audit to In Progress?";
    case AuditStatuses.Completed: return "Are you sure you want to reopen this audit?";
    case AuditStatuses.Closed: return "";
    default: return "";
  }
}