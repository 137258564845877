import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PickerDropdown from "shared/components/controls/picker-dropdown/PickerDropdown";
import { loadPickerItems, setSelectedPickerItems } from "store/search/audits/SearchAuditsSlice";
import { useAppSelector } from "store/store";

const OwnerGroups: React.FC = () => {
  const ownerGroups = useAppSelector(store => store.searchAudits.pickerData.ownerGroups);

  return (
    <LabeledControl
      label="Owner Group"
    >
      <PickerDropdown
        pickerState={ownerGroups}
        setSelectedItemsAction={setSelectedPickerItems}
        loadAction={loadPickerItems}
        placeholder="Select Owner Group"
      />
    </LabeledControl>
  );
};

export default OwnerGroups;