import { PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { AuditStatuses } from "types/auditingTypes";
import { IAuditState } from "../AuditSlice";
import { IRevertStatusConfirmModalData } from "../types/IRevertStatusConfirmModalData";
import { IStatusConfirmModalData } from "../types/IStatusConfirmModalData";

export interface IAuditStatusState {
  statusConfirmModal: IStatusConfirmModalData,
  statusConfirmRevertModal: IRevertStatusConfirmModalData,
  updateStatusOp?: IOperation<number>,
}

export const initialAuditStatusState: IAuditStatusState = {
  statusConfirmModal: {
    isOpen: false,
    auditId: 0,
    message: "",
    nextStatus: AuditStatuses.InProgress,
  },
  statusConfirmRevertModal: {
    isOpen: false,
    auditId: 0,
    revertFromStatus: AuditStatuses.Closed,
  },
  updateStatusOp: undefined,
};

const statusReducers = {
  /** Sets the "change status" confirmation modal data. */
  setConfirmAuditStatusChangeModal: (state: IAuditState, action: PayloadAction<Partial<IStatusConfirmModalData>>) => {
    Object.assign(state.statusConfirmModal, action.payload);
  },

  /** Sets the "revert status" confirmation modal data. */
  setConfirmAuditRevertStatusModal: (state: IAuditState, action: PayloadAction<Partial<IRevertStatusConfirmModalData>>) => {
    Object.assign(state.statusConfirmRevertModal, action.payload);
  },

  /**
   * The user has confirmed their intent to change the audit status. This will trigger a request to the backend
   * to update the audit status. This should only be dispatched by the Confirm Audit Status Change modal.
   */
  confirmAuditStatusChange: (state: IAuditState, _: PayloadAction<{ id: number, status: AuditStatuses }>) => {
    state.updateStatusOp = {
      isWorking: true,
    };
  },

  /**
   * The user has confirmed their intent to revert the audit status. This will trigger a request to the backend
   * to update the revert status. This should only be dispatched by the Confirm Audit Status Revert modal.
   */
  confirmRevertAuditStatus: (state: IAuditState, _: PayloadAction<{ id: number, revertFromStatus: AuditStatuses, revertToStatus: AuditStatuses }>) => {
    state.updateStatusOp = {
      isWorking: true,
    };
  },

  /** Stops the updateStatusOp and updates the audit info if the operation has no error. */
  finishUpdateAuditStatus: (state: IAuditState, action: PayloadAction<IOperation<{ statusChangeTimestamp: number, newStatus: AuditStatuses }>>) => {
    state.updateStatusOp = undefined;

    if (!action.payload.data
      || !state.audit
      || !state.originalAudit) {
      return;
    }

    const {
      newStatus,
      statusChangeTimestamp,
    } = action.payload.data;

    state.audit.status = newStatus;
    state.originalAudit.status = newStatus;

    if (newStatus === AuditStatuses.Completed) {
      state.originalAudit.completionDateTime =
        state.audit.completionDateTime = statusChangeTimestamp;
    } else if (newStatus === AuditStatuses.Closed) {
      state.originalAudit.completionDateTime =
        state.audit.closureDateTime = statusChangeTimestamp;
    }
  },
};

export default statusReducers;
