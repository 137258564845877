import { authDelete, authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IAuditorDto } from "./auditorTypes";
import { formatAuditorDto, formatSaveAuditorRequest } from "./formatters/auditorFormatters";

class AuditorApi {
  /** Gets a single auditor from the API by its Id. */
  public async getAuditorDtoDetails(id: number, abortSignal?: AbortSignal): Promise<IAuditorDto> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/auditors/${id}`,
      abortSignal);
    await throwIfResponseError(response);
    const respObj = await response.json();
    return formatAuditorDto(respObj);
  }

  /** Marks the specified auditor as inactive. */
  public async deleteAuditor(id: number,
    abortSignal?: AbortSignal): Promise<void> {
    const response = await authDelete(`${config.endpoints.baseUrl}/auditors/${id}`,
      abortSignal);

    await throwIfResponseError(response);
  }

  /**
   * Creates a new auditor (if `auditor.Id` is 0) or update an existing auditor.
   * @param auditor
   * @param abortSignal
   * @returns The Id of the newly created auditor or auditor version.
   */
  public async saveAuditor(auditor: IAuditorDto,
    abortSignal?: AbortSignal): Promise<number> {
    const requestObj = formatSaveAuditorRequest(auditor);

    // If the auditor has no id number, consider it a creation. Else, update.
    const response = !auditor.id
      ? await authPostJson(`${config.endpoints.baseUrl}/auditors`,
        requestObj,
        abortSignal)
      : await authPutJson(`${config.endpoints.baseUrl}/auditors/${auditor.id}`,
        requestObj,
        abortSignal);

    await throwIfResponseError(response);
    return await response.json();
  }

}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditorApi();
