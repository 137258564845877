import AuditsApi from "api/auditing/AuditsApi";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast, showInfoToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IChangedAuditeeAssignmentAction, IQuestionAssignmentAction, IUpdateSummaryRequest } from "types/auditingTypes";
import { IAuditState, finishSaveChanges, saveChanges } from "../AuditSlice";

export function* saveChangesAsync() {
  yield takeLatest(saveChanges, function* (action) {
    if (!saveChanges.match(action)) {
      return;
    }

    try {
      const state: IAuditState = yield select((state: RootState) => state.audit);

      const changeSet = getAuditSummaryChanges(state);

      if (!changeSet) {
        yield put(showInfoToast("There are no changes to save."));

        yield put(finishSaveChanges({
          isWorking: false,
          errorMessage: "There are no changes to save.",
        }));
        return;
      }

      // Send the updates to the server.
      yield call(AuditsApi.updateSummary, changeSet);

      // Finish saving.
      yield put(finishSaveChanges({
        isWorking: false,
        wasSuccessful: true,
      }));

      yield put(showSuccessToast("Changes saved successfully."));
    } catch (err) {
      yield put(finishSaveChanges({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
        wasSuccessful: false,
      }));

      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  });
}

export function getAuditSummaryChanges(state: IAuditState): IUpdateSummaryRequest | undefined {
  // If there are no changes to send, say so.
  if ((!state.isAuditDirty
    && !state.areQuestionsDirty)
    || !state.audit?.id) {
    return;
  }

  if (state.isAuditDirty
    && (!state.originalAudit
      || !state.audit)) {
    return;
  }

  if (state.areQuestionsDirty
    && (!state.originalQuestions
      || !state.questions)) {
    return;
  }

  const addedAuditors = state.audit?.auditors.filter(o => !state
    .originalAudit
    ?.auditors
    .find(na => na.email.toLowerCase() === o.email.toLowerCase())) || [];

  const removedAuditors = state.originalAudit?.auditors.filter(o => !state
    .audit
    ?.auditors
    .find(na => na.email.toLowerCase() === o.email.toLowerCase())) || [];

  const addedAuditees = state.audit?.auditees.filter(o => !state
    .originalAudit
    ?.auditees
    .find(na => na.email.toLowerCase() === o.email.toLowerCase())) || [];

  const removedAuditees = state.originalAudit?.auditees.filter(o => !state
    .audit
    ?.auditees
    .find(na => na.email.toLowerCase() === o.email.toLowerCase())) || [];

  const questionAssignments: IQuestionAssignmentAction[] = [];

  state.originalQuestions.forEach(oq => {
    const newQ = state
      .questions
      .find(x => x.auditQuestionId === oq.auditQuestionId);

    if (newQ?.auditorEmail?.toLowerCase() !== oq.auditorEmail?.toLowerCase()) {
      // This question's assignment was changed.
      questionAssignments.push({
        auditQuestionId: oq.auditQuestionId,
        auditorEmail: newQ?.auditorEmail,
      });
    }
  });

  const removedAuditeeAssignments: IChangedAuditeeAssignmentAction[] = state
    .originalAudit
    ?.auditTopicAuditees
    .filter(x => !state.audit?.auditTopicAuditees
      .some(z => z.auditTopicId === x.auditTopicId
        && z.auditeeEmail.toLowerCase() === x.auditeeEmail.toLowerCase()))
    .map(x => ({
      auditTopicId: x.auditTopicId,
      auditeeEmail: null,
    })) || [];

  const addedAuditeeAssignments: IChangedAuditeeAssignmentAction[] = state
    .audit
    ?.auditTopicAuditees
    .filter(x => !state.originalAudit?.auditTopicAuditees
      .some(z => z.auditTopicId === x.auditTopicId
        && z.auditeeEmail.toLowerCase() === x.auditeeEmail.toLowerCase()))
    .map(x => ({
      auditTopicId: x.auditTopicId,
      auditeeEmail: x.auditeeEmail,
    })) || [];

  const assignedAuditTopics: IChangedAuditeeAssignmentAction[] = [
    ...removedAuditeeAssignments,
    ...addedAuditeeAssignments,
  ];

  if (addedAuditors.length
    + removedAuditors.length
    + questionAssignments.length
    + removedAuditees.length
    + addedAuditees.length
    + assignedAuditTopics.length === 0) {
    return;
  }

  const oldLeadAuditee = state.originalAudit?.auditees.find(x => x.isLeader);
  const currentLeadAuditee = state.audit?.auditees.find(x => x.isLeader);

  return {
    id: state.audit.id,
    questionAssignments,
    addedAuditees,
    removedAuditees,
    addedAuditors,
    newLeadAuditeeEmail: oldLeadAuditee?.email?.toLowerCase() !== currentLeadAuditee?.email?.toLowerCase()
      ? currentLeadAuditee?.email
      : undefined,
    removedAuditors,
    assignedAuditTopics,
  };
}