import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import { Action } from "@reduxjs/toolkit";
import MasterDataApi from "api/masterdata/MasterDataApi";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { ICalendarWeek } from "types/masterDataTypes";
import { finishLoadCalendarYear, loadCalendarYear } from "./CalendarDatesSlice";

export default function* calendarDatesSagas() {
  yield all([
    loadCalendarYearAsync(),
  ]);
}

function* loadCalendarYearAsync() {
  yield takeEvery(loadCalendarYear, function* (action: Action) {
    if (!loadCalendarYear.match(action)) {
      return;
    }

    try {
      const weeks: ICalendarWeek[] = yield call(MasterDataApi.getWeeksByYear, action.payload);

      yield put(finishLoadCalendarYear({
        isWorking: false,
        data: {
          year: action.payload,
          weeks,
        },
      }));

    } catch (err) {
      yield put(finishLoadCalendarYear({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}