import { AuditPages, EditRestriction, IAuditPageAuth, ViewRestriction } from "../../../types/auditPageAuthTypes";

export function formatAuditPageAuthInfo(obj: any): IAuditPageAuth {
  let page = obj?.pageName || obj?.page;

  return page === undefined
    || page === AuditPages.None
    ? {
      auditId: obj?.auditId,
      pageName: AuditPages.None,
      editRestriction: EditRestriction.EditNone,
      viewRestriction: ViewRestriction.ViewNone,
    } : {
      auditId: obj?.auditId,
      pageName: page,
      editRestriction: obj?.editRestriction || EditRestriction.EditNone,
      viewRestriction: obj?.viewRestriction || ViewRestriction.ViewNone,
    };
};