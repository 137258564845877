import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import Modal from "shared/components/layout/modal/Modal";
import helpIcon from "shared/media/dls/help.svg";
import { loadHelpData } from "store/help/HelpSlice";
import { useAppDispatch, useAppSelector } from "store/store";

interface IHelpButtonProps {
  /** The slug of the associated help item in from the API. */
  helpSlug: string,
  /** An optional className to apply to the image. */
  iconClassName?: string,
  /** The size of the icon. Default="medium" */
  iconSize?: "small" | "medium" | "large" | "xlarge",
}

const HelpButton: React.FC<IHelpButtonProps> = ({
  helpSlug,
  iconClassName,
  iconSize = "medium",
}) => {
  const helpData = useAppSelector(store => store.help[helpSlug]);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const isWorking = helpData?.isWorking;
  const error = helpData?.errorMessage;
  const helpItem = helpData?.data;

  useEffect(() => {
    if (!helpSlug
      || isWorking
      || helpItem) {
      return;
    }

    dispatch(loadHelpData(helpSlug));
  }, [helpSlug, isWorking, error, helpItem, dispatch]);

  if (!helpItem) {
    return null;
  }

  return (
    <>
      <img
        src={helpIcon}
        alt={helpItem.title}
        title={helpItem.title}
        className={`icon-${iconSize} pointer black-to-primary ${iconClassName || ""}`}
        onClick={() => setIsOpen(true)}
      />

      {isOpen &&
        <Modal
          onCloseButtonClicked={() => setIsOpen(false)}
          showCloseButton={true}
          width={600}
          header={helpItem.title}
          isOpen={isOpen}
          buttons={[
            {
              className: "primary",
              key: "CLOSE",
              text: "Close",
              onClick: () => {
                setIsOpen(false);
              }
            },
          ]}
        >
          <ReactMarkdown className="reactMarkDown"
            components={{
              // Map `h1` (`# heading`) to use `h2`s.
              br: 'p',
              // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
              li(props) {
                const { node, ...rest } = props
                return <li style={{ marginTop: '15px' }} {...rest} />
              }
            }}
          >
            {helpItem.helpText}
          </ReactMarkdown>
        </Modal>}
    </>
  );
}

export default HelpButton;