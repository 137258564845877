import { PayloadAction } from "@reduxjs/toolkit";
import { IPlanProfileAttribute } from "types/auditPlanningTypes";
import { IAuditPlanningState } from "../AuditPlanningSlice";

export interface IPlanningAttributesState {
  profileAttributes: IPlanProfileAttribute[],
  nonVisibleProfileAttributes: IPlanProfileAttribute[],
}

export const initialPlanningAttributesState: IPlanningAttributesState = {
  profileAttributes: [],
  nonVisibleProfileAttributes: [],
};

export const planningAttributesReducers = {
  /** Adds the provided list of profile attributes to the existing list in the store. */
  addAttributes: (state: IPlanningAttributesState, action: PayloadAction<IPlanProfileAttribute[]>) => {
    const ids = action.payload.map(x => x.id);

    state.profileAttributes = state.profileAttributes
      .filter(x => !ids.includes(x.id))
      .concat(action.payload);
  },

  /** Removes (or marks as deleted) the provided list of attributes from the existing list in the store. */
  removeAttributes: (state: IAuditPlanningState, action: PayloadAction<{
    attributes: IPlanProfileAttribute[],
  }>) => {
    const ids = action.payload.attributes.map(x => x.id);

    if (!state.appliedFilters?.includeDeletedAttributes) {
      // If the user has opted not to see deleted attributes, remove them from the list.
      state.profileAttributes = state.profileAttributes.filter(x => !ids.includes(x.id));
    } else {
      // If the user has opted to see deleted attributes, replace them in the state's attribute list.
      action.payload.attributes.forEach(remAttr => {
        const ix = state.profileAttributes.findIndex(x => x.id === remAttr.id);

        if (ix === -1) {
          return;
        }

        state.profileAttributes.splice(ix, 1, remAttr);
      });
    }
  },

  /** Updates or inserts attributes in the state. */
  upsertAttributes: (state: IAuditPlanningState, action: PayloadAction<IPlanProfileAttribute[]>) => {
    action.payload.forEach(attr => {
      const stateAttr = state.profileAttributes.find(x => x.id === attr.id);

      if (stateAttr) {
        Object.assign(stateAttr, attr);
      } else {
        state.profileAttributes.push(attr);
      }
    });
  }
};