import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PickerDropdown from "shared/components/controls/picker-dropdown/PickerDropdown";
import { loadPickerItems, setSelectedPickerItems } from "store/search/actions/SearchActionsSlice";
import { useAppSelector } from "store/store";
import { ICausalFactor } from "types/auditMasterDataTypes";

const CausalFactor: React.FC = () => {
  const pickerState = useAppSelector(store => store.searchActions.pickerData.causalFactors);

  return (
    <LabeledControl
      label="Causal Factor"
    >
      <PickerDropdown<ICausalFactor>
        pickerState={pickerState}
        setSelectedItemsAction={setSelectedPickerItems}
        loadAction={loadPickerItems}
        selectMode="multiple"
        itemFormatter={item => item.item?.name || item.text || item.key.toString()}
      />
    </LabeledControl>
  );
};

export default CausalFactor;