import Banner, { BannerType } from "shared/components/common/banner/Banner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import SearchBox from "shared/components/controls/search-box/SearchBox";
import { clearLoadError, loadAndInspectActionItem, setSearchPropValue } from "store/search/actions/SearchActionsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import ActionItemInspector from "../../action-item-inspector/ActionItemInspector";
import "./ActionId.scoped.scss";

const ActionId: React.FC = () => {
  const id = useAppSelector(store => store.searchActions.actionId);
  const loadActionItemOp = useAppSelector(store => store.searchActions.loadActionItemOp);

  const dispatch = useAppDispatch();

  return (
    <div
      className="id-search"
    >
      <LabeledControl
        label="Action Item ID"
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            dispatch(loadAndInspectActionItem(Number(id)));
          }}
        >
          <SearchBox
            inputProps={{
              placeholder: "Search",
              onChange: e => {
                if (e.currentTarget.value.match(/^\d*$/)) {
                  dispatch(setSearchPropValue({ actionId: e.currentTarget.value }));
                }
                // Clear out the existing error message, if any.
                dispatch(clearLoadError());
              },
              autoFocus: true,
              value: id,
            }}
            showButton={true}
            onButtonClick={() => {
              dispatch(loadAndInspectActionItem(Number(id)));
            }}
          />
        </form>
      </LabeledControl>

      {!!loadActionItemOp?.errorMessage && (
        <Banner
          type={BannerType.error}
        >
          {loadActionItemOp.errorMessage}
        </Banner>
      )}

      <ActionItemInspector />
    </div>
  );
}

export default ActionId;