import React from "react";
import { useDispatch } from "react-redux";
import ToggleSwitch from "shared/components/controls/toggle-switch/ToggleSwitch";
import { setCalendarMode } from "store/audit-plan-calendar/AuditPlanCalendarSlice";
import { useAppSelector } from "store/store";

const SchedulerModeToggle: React.FC = () => {
  const dispatch = useDispatch();
  const calendarMode = useAppSelector(store => store.auditPlanCalendar.calendarMode);
  const appliedFilters = useAppSelector(store => store.auditPlanning.appliedFilters);

  if (!appliedFilters) {
    return null;
  }

  return (
    <ToggleSwitch
      isChecked={calendarMode === "leader"}
      onChange={isChecked => dispatch(setCalendarMode(isChecked
        ? "leader"
        : "basin"
      ))}
      label="View by Lead Auditor"
      isDisabled={!appliedFilters.businessTeams.length}
    />
  );
};

export default SchedulerModeToggle;
