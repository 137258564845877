import React from "react";
import { useDispatch } from "react-redux";
import Button from "shared/components/controls/buttons/button/Button";
import { setCreateAuditModal } from "store/audit-plan-results/AuditPlanResultsSlice";
import { useAppSelector } from "store/store";
import { IAuditType } from "types/auditMasterDataTypes";

const CreateAuditButton: React.FC = () => {
  const dispatch = useDispatch();
  const schedulerItems = useAppSelector(store => store.auditPlanCalendar.schedulerItems);
  const selectedPlanIds = useAppSelector(store => store.auditPlanCalendar.selectedPlanIds);

  const selectedPlans = schedulerItems
    .filter(x => selectedPlanIds.includes(x.planId))
    .map(x => x);

  const auditType: IAuditType | undefined = selectedPlans?.[0]
    ? {
      id: selectedPlans[0].auditTypeId,
      name: selectedPlans[0].auditTypeName,
      isDeleted: false,
    } : undefined;

  const firstPlanWithLeader = selectedPlans
    .find(p => p.leadAuditorEmail
      && p.leadAuditorName);

  return (
    <Button
      buttonType="primary"
      isDisabled={!selectedPlans.length}
      onClick={() => dispatch(setCreateAuditModal({
        isOpen: true,
        planIds: selectedPlans.map(x => x.planId),
        planYear: selectedPlans[0].planYear,
        auditType,
        selectedAuditor: firstPlanWithLeader?.leadAuditorEmail
          && firstPlanWithLeader.leadAuditorName
          ? { email: firstPlanWithLeader.leadAuditorEmail, name: firstPlanWithLeader.leadAuditorName }
          : undefined,
      }))}
    >
      Create Audit
    </Button>
  );
};

export default CreateAuditButton;