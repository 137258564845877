import { authGetJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IOwnerGroup } from "types/auditingTypes";

class OwnerGroupsApi {
  public async getOwnerGroups(searchValue?: string,
    ownerGroupIds?: number[],
    abortSignal?: AbortSignal): Promise<IOwnerGroup[]> {
    let queryString = '';

    if (ownerGroupIds?.length) {
      queryString = ownerGroupIds.map(x => `ownerGroupIds=${x}`).join('&');
    }

    if (searchValue) {
      queryString += `searchTerm=${searchValue.trim()}`;
    }

    if (queryString) {
      queryString = `?${queryString}`;
    }

    const response = await authGetJson(config.endpoints.auditing.ownerGroups + queryString, abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatOwnerGroup(obj));
  }
}

export function formatOwnerGroup(obj: any): IOwnerGroup {
  return {
    id: obj.id,
    name: obj.name,
    isDeleted: obj.deleted,
    scoringSystem: obj.scoringSystem,
  };
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new OwnerGroupsApi();
