import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import QISelect from "shared/components/controls/select/QISelect";
import { setSearchPropValue } from "store/search/audits/SearchAuditsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const YEARS_TO_THE_PAST = 5;
const YEARS_TO_THE_FUTURE = 2;
const NUM_OF_YEARS = YEARS_TO_THE_PAST + 1 + YEARS_TO_THE_FUTURE;
const targetProp = "auditPlanYear";

const AuditPlanYear: React.FC = () => {
  const dispatch = useAppDispatch();
  const auditPlanYears = useAppSelector(store => store.searchAudits[targetProp]);

  const years = new Array(NUM_OF_YEARS)
    .fill(new Date().getFullYear() - YEARS_TO_THE_PAST)
    .map((year, ix) => year + ix);

  return (
    <LabeledControl
      label="Audit Plan Year"
    >
      <QISelect
        items={years.map(x => ({
          id: x,
          text: x.toString(),
        }))}
        selectedItemIds={auditPlanYears}
        onChange={items =>
          dispatch(setSearchPropValue({
            [targetProp]: items.map(x => Number(x.id)),
          }))
        }
        selectMode="multi"
      />
    </LabeledControl>
  );
};

export default AuditPlanYear;