import { SearchType } from "components/search/SearchPage";
import React, { useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Hint from "shared/components/common/hint/Hint";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import TextInput from "shared/components/controls/text-input/TextInput";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { useAppDispatch, useAppSelector } from "store/store";

const maxCommentLength = 25;

interface ISaveCustomSearchModalProps {
  /**
   * Specifies if the modal is open or not. If not open, it does not render.
   */
  isOpen: boolean,
  /**
   * The action to perform when the user clicks the cancel button in the modal.
   */
  onCancel(): void,
  /**
   * The action to perform when the user clicks the save button in the modal.
   */
  onSave(name: string): void,
  /**
   * The Type of the search 
   */
  searchType: SearchType | undefined,
}

const SaveCustomSearchModal: React.FC<ISaveCustomSearchModalProps> = ({
  isOpen,
  onCancel,
  onSave,
  searchType,
}) => {
  const [name, setName] = useState("");
  const saveAuditFiltersOperation = useAppSelector(store => store.searchAudits.saveFiltersOperation);
  const saveActionFiltersOperation = useAppSelector(store => store.searchActions.saveFiltersOperation);
  const dispatch = useAppDispatch();

  return (
    <Modal
      isOpen={isOpen}
      header="Save Filter"
      showCloseButton={true}
      onCloseButtonClicked={() => {
        setName("");
        onCancel();
      }}
      buttons={[{
        key: "cancel",
        className: "secondary",
        text: "Cancel",
        onClick: () => {
          setName("");
          onCancel()
        }
      }, {
        className: "primary",
        text: "Save",
        key: "SAVE",
        onClick: () => {
          if (!name.trim().length) {
            dispatch(showErrorToast("File name is required."));
            return;
          }
          onSave(name);
        },
      }]}
    >
      <FlexCol>
        <span>
          Enter the name for the filter.
        </span>

        <LabeledControl
          label="Filter Name"
          isRequired={true}
          hint={getTextboxMaxLengthHint(maxCommentLength)}
        >
          <TextInput
            onChange={val => setName(val)}
            value={name}
            maxLength={maxCommentLength}
          />
        </LabeledControl>

        <Hint>
          {maxCommentLength - name.length}/{maxCommentLength} characters remaining
        </Hint>

        {(!!saveAuditFiltersOperation?.errorMessage || !!saveActionFiltersOperation?.errorMessage) && (
          <Banner
            type={BannerType.error}
          >
            {searchType === SearchType.Audits
              ? saveAuditFiltersOperation?.errorMessage
              : saveActionFiltersOperation?.errorMessage}
          </Banner>
        )}

        {(saveAuditFiltersOperation?.isWorking || saveActionFiltersOperation?.isWorking) && (
          <ModalSpinner />
        )}
      </FlexCol>
    </Modal>
  )
}

export default SaveCustomSearchModal;