import { IAuditTopic } from "types/auditMasterDataTypes";
import { ISaveAuditTopicRequest } from "../auditTopicTypes";

export function formatSaveAuditTopicRequest(item: Partial<IAuditTopic>): ISaveAuditTopicRequest {
  return {
    id: item.id ?? 0,
    name: item.name,
    shortName: item.shortName,
    auditGroupId: item.auditGroupId ?? 0,
    ownerGroupId: item.ownerGroupId ?? 0,
    parentId: item.parentId ? Number(item.parentId) : undefined,
    plannable: item.isPlannable ?? false,
    firstPartyFrequency: item.firstPartyFrequency ?? 0,
    selfAssessmentFrequency: item.selfAssessmentFrequency ?? 0,
    deleted: item.isDeleted ?? false,
  };
}
