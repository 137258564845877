import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import AuditorSearchMessage from "components/audits/common/auditor-search-message/AuditorSearchMessage";
import React from "react";
import DetailText from "shared/components/common/detail-text/DetailText";
import DatePicker from "shared/components/controls/date-picker/DatePicker";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PickerDropdown from "shared/components/controls/picker-dropdown/PickerDropdown";
import Picker from "shared/components/controls/picker/Picker";
import Card from "shared/components/layout/card/Card";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { userToString } from "shared/utilities/userUtilities";
import { closePicker, loadPickerItems, loadSuggestedPickerItems, openPicker, setEndDateTime, setPlanName, setSelectedPickerItems, setStartDateTime } from "store/manage-audit/ManageAuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses } from "types/auditingTypes";
import "../ManageAuditPage.scoped.scss";
import "./ManageAuditDetailsCard.scoped.scss";

interface IManageAuditDetailsCardProps {
  allowEdit: boolean,
  allowEditOnlyLeadAuditor: boolean,
  showStatus: boolean,
}

const ManageAuditDetailsCard: React.FC<IManageAuditDetailsCardProps> = ({
  showStatus,
  allowEdit,
  allowEditOnlyLeadAuditor,
}) => {
  const auditTypes = useAppSelector(store => store.manageAudit.pickerData.auditTypes);
  const leadAuditors = useAppSelector(store => store.manageAudit.pickerData.leadAuditors);
  const audit = useAppSelector(store => store.manageAudit.audit);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const hasEditPermission = audit.status !== AuditStatuses.Closed && (editRestriction === EditRestriction.EditAll ||
    editRestriction === EditRestriction.EditOwn ?
    true :
    false);

  const dispatch = useAppDispatch();
  let isLeadAuditorDDLDisabled = (auditTypes?.selectedItems?.length === 0);

  const updateReduxDate = (timestamp: number | undefined,
    reduxAction: ActionCreatorWithPayload<number, string>) => {
    if (!timestamp) {
      return;
    }

    dispatch(reduxAction(timestamp));
  };

  const statusValue: React.ReactNode = (
    <>
      {audit?.status === AuditStatuses.InProgress
        ? "In Progress"
        : audit?.status
      }
    </>
  );

  return (
    <Card
      title="Audit Details"
      headerElement={
        <>
          {showStatus &&
            <LabeledControl className="label-card-sub-title"
              label="Status"
              labelContentExtraClass="label-card-title"
            >
              {statusValue}
            </LabeledControl>
          }

        </>
      }
    >
      <div
        className="card-row"
      >
        <LabeledControl
          label="Audit Name"
          className="plan-name"
          isRequired
          hint={getTextboxMaxLengthHint(200)}
        >
          <input
            type="text"
            placeholder="Audit Name"
            value={audit.name}
            onChange={(e) => dispatch(setPlanName((e.target.value)))}
            disabled={!hasEditPermission || !allowEdit}
            maxLength={200}
          />
        </LabeledControl>
        <LabeledControl
          label="Audit Type"
          className="auditor-type"
          isRequired={true}
        >
          <PickerDropdown
            pickerState={{
              ...auditTypes,
              selectedItems: audit.auditType
                ? [{
                  item: audit.auditType,
                  disabled: false,
                  key: audit.auditType.id,
                }]
                : [],
            }}
            setSelectedItemsAction={setSelectedPickerItems}
            loadAction={loadPickerItems}
            isDisabled={!hasEditPermission || !allowEdit}
          />
        </LabeledControl>
      </div>
      <div
        className="card-row"
      >
        <LabeledControl
          label="Start Date"
          className="start-date"
          isRequired={true}
        >
          <DatePicker
            value={audit.startDateTime}
            onChange={(date) => {
              const dateValue = Array.isArray(date)
                && date[0]
                ? date[0]
                : (!Array.isArray(date)
                  && date
                  ? date
                  : null);

              if (!dateValue) {
                return;
              }

              updateReduxDate(dateValue.getTime(), setStartDateTime);
            }}
            isDisabled={!hasEditPermission || !allowEdit}
          />
        </LabeledControl>

        <LabeledControl
          label="End Date"
          className="end-date"
          isRequired={true}
        >
          <DatePicker
            value={audit.endDateTime}
            minDate={audit.startDateTime
              ? new Date(audit.startDateTime)
              : undefined
            }
            onChange={(date) => {
              const dateValue = Array.isArray(date)
                && date[0]
                ? date[0]
                : (!Array.isArray(date)
                  && date
                  ? date
                  : null);

              if (!dateValue) {
                return;
              }

              updateReduxDate(dateValue.getTime(), setEndDateTime);
            }}
            isDisabled={!hasEditPermission || !allowEdit}
          />
        </LabeledControl>
        <LabeledControl
          label="Lead Auditor"
          className="lead-auditor"
          isRequired={true}
        >
          <Picker<IAzureADUser>
            title="Lead Auditor"
            subTitle={
              <AuditorSearchMessage
                isFirstPartySearch={auditTypes?.selectedItems[0]?.text === '1st Party - Official'}
              />
            }
            renderListItem={(item) => userToString(item)}
            renderSelectedItem={(item) => userToString(item, "")}
            pickerState={{
              ...leadAuditors,
              selectedItems: audit.leadAuditorEmail
                ? [{
                  item: {
                    email: audit.leadAuditorEmail,
                    name: audit.leadAuditorName || audit.leadAuditorEmail,
                  },
                  disabled: false,
                  key: audit.leadAuditorEmail,
                }] : [],
            }}
            preserveItems={true}
            searchOptions={{
              asyncMinChars: 1,
              behavior: "async",
            }}
            loadSuggestionsAction={loadSuggestedPickerItems}
            preserveSuggestedItems={false}
            openAction={openPicker}
            closeAction={closePicker}
            loadAction={loadPickerItems}
            setSelectedItemsAction={setSelectedPickerItems}
            noItemsMessage={"Please begin typing a search term to execute a search."}
            isDisabled={(isLeadAuditorDDLDisabled
              || !hasEditPermission || !allowEdit) && !allowEditOnlyLeadAuditor
            }
            showSuggestedItems={true}
            itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
          />
        </LabeledControl>

      </div>
      <div
        className="card-row"
      >
        <LabeledControl
          label="Audit Identifier"
        >
          <DetailText
            className="audit-detail-placeholder"
            text={`${audit.recap}-${audit.name}`}
            showModalOnClick={true}
            modalData={{
              header: "Audit Name",
              content:
                <div>
                  <span>Recap: {audit.recap}</span>
                  <br></br>
                  <span>Name: {audit.name}</span>
                </div>
            }}
          />
        </LabeledControl>
      </div>
    </Card>
  );
};

export default ManageAuditDetailsCard;
