import React from "react";
import { IAnswer } from "types/auditMasterDataTypes";
import "./AnswerDisplay.scoped.scss";

interface IAnswerDisplayProps {
  answerCode?: string,
  answer?: IAnswer,
}

const AnswerDisplay: React.FC<IAnswerDisplayProps> = ({
  answerCode,
  answer,
}) =>
  <span
    className={answer?.displayColor}
  >
    {answerToText(answerCode, answer)}
  </span>;

export default AnswerDisplay;

export function answerToText(answerCode: string | undefined, answer: IAnswer | undefined) {
  return answer?.name || answerCode || "--";
}