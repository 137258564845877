import React, { useEffect, useMemo } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditorPicker from "shared/components/controls/pickers/AuditorPicker";
import WeekPickerModal from "shared/components/controls/week-picker/WeekPickerModal";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import { loadCalendarYear } from "shared/store/calendar-dates/CalendarDatesSlice";
import formatDate from "shared/utilities/dateFormatters";
import { checkSelfAuditor, loadPlanTemplateChildren, setCreateAuditModal, startCreateAudit } from "store/audit-plan-results/AuditPlanResultsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditorSearchTypes, IAuditCreationItem } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import PlanAttributeInfoDisplay from "./plan-template-children-display/PlanAttributeInfoDisplay";
import "./CreateAuditModal.scoped.scss";

interface ICreateAuditModalProps {
  plans: IAuditCreationItem[],
  afterAuditCreation?: (newAuditId: number) => void,
}

const CreateAuditModal: React.FC<ICreateAuditModalProps> = ({
  plans,
  afterAuditCreation,
}) => {
  const {
    isOpen,
    planIds,
    week,
    auditType,
    createOp,
    isWeekPickerOpen,
    loadTemplateChildrenOp,
    planContextInfo,
    selectedAuditor,
  } = useAppSelector(store => store.auditPlanResults.createAuditModal);
  const checkSelfAuditorOp = useAppSelector(store => store.auditPlanResults.checkSelfAuditorOp);
  const calendars = useAppSelector(store => store.calendarDates.calendars);
  const loadCalendarOp = useAppSelector(store => store.calendarDates.loadOp);

  const dispatch = useAppDispatch();

  const year = plans.length > 0
    ? plans[0].plan.planYear
    : undefined;

  const plansInModal = useMemo(() => plans.filter(x => planIds.indexOf(x.plan.id) > -1), [planIds, plans]);
  const firstPlanWithWeek = plansInModal.find(x => !!x.plan.weekOfYear);
  const firstPlanWeekNumber = firstPlanWithWeek?.plan.weekOfYear;
  const firstPlanYear = firstPlanWithWeek?.plan.planYear;
  const calendarYear = firstPlanYear
    ? calendars[firstPlanYear]
    : undefined;
  const firstPlanCalendarWeek = calendarYear
    && firstPlanWeekNumber
    ? calendarYear.weeks.find(x => x.week === firstPlanWeekNumber)
    : undefined;

  const isFromAuditTemplate = plansInModal
    .some(x => x.requirementType === MetaDataTypes.AuditTemplate);

  useEffect(() => {
    if (isOpen) {
      dispatch(checkSelfAuditor(plansInModal));

      const requirementIds = plansInModal
        .filter(x => x.requirementId !== undefined)
        .map(x => x.requirementId || 0);

      if (planIds.length) {
        dispatch(loadPlanTemplateChildren({
          planIds,
          isFromAuditTemplate,
          requirementIds
        }));
      }
    }
  }, [isOpen, dispatch, plansInModal, planIds, isFromAuditTemplate]);

  useEffect(() => {
    if (firstPlanYear
      && !calendarYear
      && !loadCalendarOp?.isWorking) {
      dispatch(loadCalendarYear(firstPlanYear));
    }
  }, [firstPlanYear, calendarYear, loadCalendarOp, dispatch]);

  useEffect(() => {
    if (isOpen) {
      dispatch(setCreateAuditModal({
        week: firstPlanCalendarWeek,
      }));
    }
  }, [firstPlanCalendarWeek, isOpen, dispatch]);

  let weekDisplay = "--";

  if (week?.week) {
    weekDisplay = `${week.week} (${formatDate(new Date(week.startDateTimestamp), undefined, undefined, undefined, true)} to ${formatDate(new Date(week.endDateTimestamp), undefined, undefined, undefined, true)})`;
  }

  return (
    <Modal
      isOpen={isOpen}
      header="Create Audit"
      onCloseButtonClicked={() => dispatch(setCreateAuditModal({
        isOpen: false,
      }))}
      showCloseButton={true}
      buttons={[{
        key: "cancel",
        className: "secondary",
        text: "Cancel",
        onClick: () => dispatch(setCreateAuditModal({
          isOpen: false,
        })),
      }, {
        key: "create",
        className: "primary",
        text: "Create Audit",
        disabled: !week
          || !selectedAuditor,
        onClick: !!week
          && selectedAuditor
          ? () => dispatch(startCreateAudit({
            planIds,
            startDate: week.startDateTimestamp,
            endDate: week.endDateTimestamp,
            leadAuditor: selectedAuditor,
            afterAuditCreation,
          })) : undefined,
      }]}
    >
      <FlexCol>
        <p className='confirm-message'>Create <span className='bolded'>{auditType?.name}</span> Audit from these Audit Plans?</p>

        <FlexRow>
          <LabeledControl
            label="Audit week"
            isRequired={true}
          >
            <button
              className="secondary audit-week-button"
              onClick={() =>
                dispatch(setCreateAuditModal({
                  isWeekPickerOpen: true,
                }))
              }
            >
              {weekDisplay}
            </button>
          </LabeledControl>

          <LabeledControl
            label="Lead Auditor"
            isRequired={true}
          >
            <AuditorPicker
              auditType={auditType}
              auditorType={AuditorSearchTypes.LeadAuditor}
              onApply={values => dispatch(setCreateAuditModal({
                selectedAuditor: values.length
                  ? values[0]
                  : undefined,
              }))}
              selectedItems={selectedAuditor
                ? [selectedAuditor]
                : []
              }
              allowMultiselect={false}
              showSuggestions
            />
          </LabeledControl>
        </FlexRow>

        <table>
          <thead>
            <tr>
              <th>
                Requirement
              </th>
              <th>
                Sub Geo Unit
              </th>
              <th>
                Parent Dimension (Type)
              </th>
              <th>
                Child Dimension (Type)
              </th>
            </tr>
          </thead>
          <tbody>
            {plans.map(item => (
              <tr
                key={item.plan.id}
              >
                <td>
                  {item.requirementName || "--"}
                </td>
                <td>
                  {item.geoName || "--"}
                </td>
                <td>
                  {getParentDimensionLabel(item)}
                </td>
                <td>
                  {getChildDimensionLabel(item)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {loadTemplateChildrenOp?.isWorking && (
          <Spinner />
        )}

        {planContextInfo?.planTemplateHierarchyInfo !== undefined && (
          <PlanAttributeInfoDisplay
            planContextInfo={planContextInfo}
            isFromAuditTemplate={isFromAuditTemplate}
          />
        )}
      </FlexCol>

      {(createOp?.isWorking
        || loadCalendarOp?.isWorking
        || checkSelfAuditorOp?.isWorking) && (
          <ModalSpinner />
        )}

      {isWeekPickerOpen
        && !!year && (
          <WeekPickerModal
            onCancel={() => dispatch(setCreateAuditModal({
              isWeekPickerOpen: false,
            }))}
            selectedWeek={week?.week || firstPlanWeekNumber}
            year={year}
            onApply={selWeek => dispatch(setCreateAuditModal({
              week: selWeek,
              isWeekPickerOpen: false,
            }))}
          />
        )}
    </Modal>
  );
};

export default CreateAuditModal;

function getChildDimensionLabel(item: IAuditCreationItem): React.ReactNode {
  return item.childName
    ? `${item.childName} (${(item.plan.childDimensionSubType || item.plan.childDimensionType)?.replace(/([a-z])([A-Z])/g, '$1 $2')})`
    : "--";
}

function getParentDimensionLabel(item: IAuditCreationItem): React.ReactNode {
  return `${item.parentName || "--"} (${item.plan.parentDimensionSubType || item.plan.parentDimensionType})`;
}
