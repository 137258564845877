import { authGetJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IActionItemPriority, IActionItemType } from "types/actionItemTypes";
import { IAnswer, IApi, IAuditGroup, IAuditType, ICausalFactor, IHSESubElement, IIso, IQMSElement, IQuestionCategory, IQuestionType, IVerificationMethod } from "types/auditMasterDataTypes";
import { BusinessEpicType, IBusinessEpic, IBusinessFunction, IBusinessTeam, IBusinessView, ICalendarWeek, ICLMDocument, ICountry, IFacility } from "types/masterDataTypes";
import { formatActionItemPriority, formatActionItemType, formatAnswer, formatApi, formatAuditGroup, formatAuditType, formatBusinessEpicType, formatBusinessFunction, formatBusinessTeam, formatBusinessView, formatCalendarWeeks, formatCausalFactor, formatClmDocRef, formatClmFunction, formatClmWhomToCheck, formatCountry, formatFacility, formatHSESubElement, formatIso, formatQMSElement, formatQuestionCategory, formatQuestionType, formatVerificationMethod } from "./formatters/masterDataFormatters";
import { IGetBusinessTeamsRequest } from "./types/masterDataApiRequests";

class MasterDataApi {
  public async getAuditTypes(): Promise<IAuditType[]> {
    const response = await authGetJson(config.endpoints.auditMasterData.auditTypes);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatAuditType(obj));
  }

  public async getAuditGroups(abortSignal?: AbortSignal): Promise<IAuditGroup[]> {
    const response = await authGetJson(config.endpoints.auditMasterData.auditGroups, abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatAuditGroup(obj));
  }

  public async getBusinessViews(_?: string,
    businessViewIds?: number[],
    abortSignal?: AbortSignal): Promise<IBusinessView[]> {
    let queryString = '';

    if (businessViewIds?.length) {
      queryString = businessViewIds.map(x => `countryIds=${x}`).join('&');
    }

    if (queryString) {
      queryString = `?${queryString}`;
    }

    const response = await authGetJson(`${config.endpoints.masterData.businessViews}${queryString}`,
      abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatBusinessView(obj));
  }

  public async getBusinessFunctions(abortSignal?: AbortSignal): Promise<IBusinessFunction[]> {
    const response = await authGetJson(config.endpoints.masterData.businessFunctions,
      abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatBusinessFunction(obj));
  }

  public async getBusinessTeams(request?: IGetBusinessTeamsRequest,
    abortSignal?: AbortSignal): Promise<IBusinessTeam[]> {
    let qsChunks: string[] = [];

    if (request?.businessTeamIds?.length) {
      request?.businessTeamIds.forEach(x => qsChunks.push(`businessTeamIds=${x}`));
    }

    if (request?.includeDeleted) {
      qsChunks.push("includeDeleted=true");
    }

    if (request?.includeHidden) {
      qsChunks.push("includeHidden=true");
    }

    let queryString = qsChunks.length
      ? `?${qsChunks.join("&")}`
      : "";

    const response = await authGetJson(`${config.endpoints.masterData.businessTeams}${queryString}`, abortSignal);
    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatBusinessTeam(obj));
  }

  public async getCountries(searchValue?: string,
    facilityIds?: number[],
    countryIds?: number[],
    abortSignal?: AbortSignal): Promise<ICountry[]> {
    let url = config.endpoints.masterData.countries;

    let kvPairs: string[] = [];

    if (searchValue?.trim()) {
      kvPairs.push(`searchTerm=${searchValue}`);
    }

    if (facilityIds?.length) {
      kvPairs.push(...facilityIds.map(x => `facilityIds=${x}`));
    }

    if (countryIds?.length) {
      kvPairs.push(...countryIds.map(x => `countryIds=${x}`));
    }

    if (kvPairs.length) {
      url += `?${kvPairs.join('&')}`;
    }

    const response = await authGetJson(url, abortSignal);

    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatCountry(obj));
  }

  public async getFacilities(searchValue?: string,
    countryIds?: number[],
    basinHierarchyIds?: number[],
    facilityIds?: number[]): Promise<IFacility[]> {
    let url = config.endpoints.masterData.facilities;

    let kvPairs: string[] = [];

    if (searchValue?.trim()) {
      kvPairs.push(`searchTerm=${searchValue}`);
    }

    if (countryIds?.length) {
      kvPairs.push(...countryIds.map(x => `countryIds=${x}`));
    }

    if (basinHierarchyIds?.length) {
      kvPairs.push(...basinHierarchyIds.map(x => `basinHierarchyIds=${x}`));
    }

    if (facilityIds?.length) {
      kvPairs.push(...facilityIds.map(x => `facilityIds=${x}`));
    }

    if (kvPairs.length) {
      url += `?${kvPairs.join('&')}`;
    }

    const response = await authGetJson(url);

    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatFacility(obj));
  }

  public async getSuggestedFacilities(countryIds: number[]): Promise<IFacility[]> {
    let url = config.endpoints.masterData.suggestedFacilities;

    if (countryIds.length) {
      url += "?" + countryIds.map(x => `countryIds=${x}`).join(`&`);
    }

    const response = await authGetJson(url);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatFacility(obj));
  }

  public async getCausalFactors(searchValue?: string): Promise<ICausalFactor[]> {
    const response = await authGetJson(config.endpoints.auditMasterData.causalFactors
      + (searchValue?.trim()
        ? `?searchTerm=${searchValue}`
        : ""
      ));
    await throwIfResponseError(response);
    const items = (await response.json())
      .map((obj: any) => formatCausalFactor(obj));
    return items;
  }

  public async getVerificationMethods(): Promise<IVerificationMethod[]> {
    const response = await authGetJson(config.endpoints.auditMasterData.verificationMethods);
    await throwIfResponseError(response);
    const items: IVerificationMethod[] = (await response.json())
      .map((obj: any) => formatVerificationMethod(obj));

    return items.sort((a, b) => a.name < b.name ? -1 : 1);
  }

  public async getHSESubElements(_?: string, abortSignal?: AbortSignal): Promise<IHSESubElement[]> {
    const response = await authGetJson(config.endpoints.auditMasterData.hseSubElements, abortSignal);
    await throwIfResponseError(response);
    const items: IHSESubElement[] = (await response.json())
      .map((obj: any) => formatHSESubElement(obj));

    return items.sort((a, b) => a.name < b.name ? -1 : 1);
  }

  public async getQMSElements(_?: string, abortSignal?: AbortSignal): Promise<IQMSElement[]> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/masterData/QMSElements`, abortSignal);
    await throwIfResponseError(response);
    const items: IQMSElement[] = (await response.json())
      .map((obj: any) => formatQMSElement(obj));

    return items.sort((a, b) => a.name < b.name ? -1 : 1);
  }

  public async getActionItemTypes(searchValue?: string): Promise<IActionItemType[]> {
    const response = await authGetJson(config.endpoints.masterData.actionItemTypes
      + (searchValue?.trim()
        ? `?searchTerm=${searchValue}`
        : ""
      ));

    await throwIfResponseError(response);
    const items = (await response.json())
      .map((obj: any) => formatActionItemType(obj));
    return items;
  }

  public async getActionItemPriorities(searchValue?: string): Promise<IActionItemPriority[]> {
    const response = await authGetJson(config.endpoints.masterData.actionItemPriorities
      + (searchValue?.trim()
        ? `?searchTerm=${searchValue}`
        : ""
      ));
    await throwIfResponseError(response);
    const items = (await response.json())
      .map((obj: any) => formatActionItemPriority(obj));
    return items;
  }

  public async getWeeksByYear(year: number): Promise<ICalendarWeek[]> {
    const response = await authGetJson(config.endpoints.masterData.calendarWeeks.replace(":year", year.toString()));
    await throwIfResponseError(response);
    const items = formatCalendarWeeks(await response.json());
    return items;
  }

  public async getSuggestedBusinessTeams(abortSignal?: AbortSignal): Promise<IBusinessTeam[]> {
    const response = await authGetJson(config.endpoints.masterData.suggestedBusinessTeams,
      abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatBusinessTeam(obj));
  }

  public async getSuggestedCountries(facilityIds?: number[], abortSignal?: AbortSignal): Promise<ICountry[]> {
    let url = config.endpoints.masterData.suggestedCountries;

    if (facilityIds?.length) {
      url += `?${facilityIds.map(x => `facilityIds=${x}`).join('&')}`;
    }

    const response = await authGetJson(url, abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatCountry(obj));
  }

  public async getSuggestedBusinessViews(_?: string, abortSignal?: AbortSignal): Promise<IBusinessView[]> {
    const response = await authGetJson(config.endpoints.masterData.suggestedBusinessViews,
      abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatBusinessView(obj));
  }

  public async getSuggestedBusinessFunctions(abortSignal?: AbortSignal): Promise<IBusinessFunction[]> {
    const response = await authGetJson(config.endpoints.masterData.suggestedBusinessFunctions,
      abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatBusinessFunction(obj));
  }

  public async getQuestionCategories(includeDeleted: boolean = false,
    abortSignal?: AbortSignal): Promise<IQuestionCategory[]> {
    const qs = !includeDeleted
      ? "?$filter=(deleted eq false)"
      : "";

    const response = await authGetJson(`${config.endpoints.baseUrl}/odata/questionCategories${qs}`,
      abortSignal);

    await throwIfResponseError(response);
    return (await response.json())
      .value
      .map((obj: any) => formatQuestionCategory(obj));
  }

  public async getQuestionTypes(includeDeleted: boolean = false,
    abortSignal?: AbortSignal): Promise<IQuestionType[]> {
    const qs = !includeDeleted
      ? "?$filter=(deleted eq false)"
      : "";

    const response = await authGetJson(`${config.endpoints.baseUrl}/odata/questionTypes${qs}`,
      abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .value
      .map((obj: any) => formatQuestionType(obj));
  }

  public async getAPIs(includeDeleted: boolean = false,
    abortSignal?: AbortSignal): Promise<IApi[]> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/masterData/apis?includeDeleted=${includeDeleted}`,
      abortSignal);
    await throwIfResponseError(response);
    return ((await response.json())
      .map((obj: any) => formatApi(obj)) as IApi[])
      .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
  }

  public async getIsos(includeDeleted: boolean = false,
    abortSignal?: AbortSignal): Promise<IIso[]> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/masterData/isos?includeDeleted=${includeDeleted}`,
      abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatIso(obj));
  }

  public async getBusinessEpics(includeDeleted: boolean, businessEpicType: BusinessEpicType,
    abortSignal?: AbortSignal): Promise<IBusinessEpic[]> {

    const filterDeleted = !includeDeleted
      ? "and deleted eq false"
      : "";
    const response = await authGetJson(`${config.endpoints.baseUrl}/odata/BusinessEpics?$filter=(type eq '${businessEpicType}' ${filterDeleted})`,
      abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .value
      .map((obj: any) => formatBusinessEpicType(obj));
  }

  public async getAnswers(abortSignal?: AbortSignal): Promise<IAnswer[]> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/odata/answers`,
      abortSignal);

    await throwIfResponseError(response);
    return (await response.json())
      .value
      .map((obj: any) => formatAnswer(obj))
      .sort((a: IAnswer, b: IAnswer) => a.sortOrder < b.sortOrder ? -1 : 1);
  }

  public async getClmDocRefs(includeDeleted: boolean, abortSignal?: AbortSignal): Promise<ICLMDocument[]> {

    const filterDeleted = !includeDeleted
      ? "deleted eq false"
      : "";

    const response = await authGetJson(`${config.endpoints.baseUrl}/odata/CLMDocRefs?$filter=(${filterDeleted})`,
      abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .value
      .map((obj: any) => formatClmDocRef(obj));
  }

  public async getClmFunctions(includeDeleted: boolean, abortSignal?: AbortSignal): Promise<IBusinessEpic[]> {

    const filterDeleted = !includeDeleted
      ? "deleted eq false"
      : "";

    const response = await authGetJson(`${config.endpoints.baseUrl}/odata/CLMFunctions?$filter=(${filterDeleted})`,
      abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .value
      .map((obj: any) => formatClmFunction(obj));
  }

  public async getClmWhomToCheck(includeDeleted: boolean, abortSignal?: AbortSignal): Promise<IBusinessEpic[]> {

    const filterDeleted = !includeDeleted
      ? "deleted eq false"
      : "";

    const response = await authGetJson(`${config.endpoints.baseUrl}/odata/ClmWhomToChecks?$filter=(${filterDeleted})`,
      abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .value
      .map((obj: any) => formatClmWhomToCheck(obj));
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new MasterDataApi();
