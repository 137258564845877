import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Button from "shared/components/controls/buttons/button/Button";
import plusIcon from "shared/media/dls/plus.svg";
import { toggleYAxisItem } from "store/audit-planning-shared/AuditPlanningSlice";
import { checkAttributeByTopicOrTemplatePermission } from "store/audit-planning-shared/PlanningShared";
import { getScoringSystem, markAttributesReviewed, setAddChildDimensionModal, setAddParentDimensionModal } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditScoringSystems, IEditableGridRow } from "types/auditPlanningTypes";
import PlanningGrid, { IPlanningGridOptions, IRenderCommandCellArgs } from "../../planning-grid/PlanningGrid";
import PlanningLeftCell from "../../planning-grid/left-cell/PlanningLeftCell";
import { isAttributeReviewed } from "../attributesUtils";
import { IPlanProfile, IPlanProfileAttribute } from "../facilityAttributeTypes";
import "./FacilityAttributesGrid.scoped.scss";
import FacilityAttributesGridCell from "./grid-cell/FacilityAttributesGridCell";
import RemoveChildButton from "./remove-child-button/RemoveChildButton";

interface IAttributeData {
  attribute: IPlanProfileAttribute,
  isEditable: boolean,
}

interface IComputedRowData {
  /** Determines if the user can edit the attributes on this row. */
  isRowEditable: boolean,
  /** The plan profile associated with this row (if any). */
  rowProfile: IPlanProfile | undefined,
  /** The array of all attributes on this row. */
  rowAttributes: IAttributeData[],
  /** A dictionary of the attributes on the row. The key is `type_id`. */
  rowAttributeMap: Map<string, IAttributeData>,
}

interface IFacilityAttributesGrid {
  /** The list of rows the current user is allowed to modify. */
  editableRows: IEditableGridRow[],
  /** The list of all attributes in the current view (even those that are deleted) that
   * the user is allowed to modify.
   */
  editableAttributes: IPlanProfileAttribute[],
}

const FacilityAttributesGrid: React.FC<IFacilityAttributesGrid> = ({
  editableRows,
  editableAttributes,
}) => {
  const xAxisData = useAppSelector(store => store.auditPlanning.xAxisData);
  const yAxisData = useAppSelector(store => store.auditPlanning.facAttrYAxisData);
  const profiles = useAppSelector(store => store.auditPlanning.profiles);
  const attributes = useAppSelector(store => store.auditPlanning.profileAttributes);
  const nonVisibleAttributes = useAppSelector(store => store.auditPlanning.nonVisibleProfileAttributes);
  const activeUserProfile = useAppSelector(store => store.auth.activeUserProfile);
  const mode = useAppSelector(store => store.facilityAttributes.mode);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const selectedPerspectiveXAxes = useAppSelector(store => store.auditPlanning.appliedFilters?.perspectiveXAxes);
  const currentFilters = useAppSelector(store => store.auditPlanning.currentFilters);

  const ownerGroup = useAppSelector(store => store.facilityAttributes.ownerGroup);
  // Check Restriction
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const isEditableForDefaultUser = auditPageAuth.editRestriction === EditRestriction.EditNone;

  // Get the extra, non-Facility plannable types to decide if ADD PARENT should be shown.
  const selectedPerspectiveXAxis = selectedPerspectiveXAxes?.[0];
  const plannableMasterDataTypes = selectedPerspectiveXAxis
    ?.plannableMasterDataTypes
    ?.filter(x => x.masterDataType !== "Facility");

  const dispatch = useDispatch();

  const onToggleRow = useCallback((args: IRenderCommandCellArgs) => {
    dispatch(toggleYAxisItem({
      isExpanded: !args.row.isExpanded,
      item: args.row,
      shared: true,
    }))
  }, [dispatch]);

  useEffect(() => {
    if (currentFilters.perspectiveXAxes.length > 0) {
      dispatch(getScoringSystem(currentFilters.perspectiveXAxes[0].ownerGroupId));
    }
  }, [dispatch, currentFilters.perspectiveXAxes]);


  const gridOptions = useMemo((): IPlanningGridOptions => ({
    computeRowData: (row, parentRow): IComputedRowData => {
      let isRowEditable = editableRows.some(x => x.row === row);

      // Need to know if there is a parent profile in order to find the profile for this row.
      // Only find the parent profile if the parent row is plannable.
      let parentProfile: IPlanProfile | undefined;

      if (parentRow?.isPlannable) {
        parentProfile = profiles
          .find(x => x.masterDataId === parentRow.id
            && x.masterDataType === parentRow.type
            && !x.parentId);
      }

      // Find the profile associated to this row. The profile will have a matching MasterDataId
      // and MasterDataType and its ParentId will match the found parentProfile above.
      let profile: IPlanProfile | undefined = profiles
        .find(x => x.masterDataType === row.type
          && x.masterDataId === row.id
          && x.parentId === parentProfile?.id);

      // Find the already-existing attributes associated with this profile.
      const rowAttributes: IAttributeData[] = [];
      const rowAttributeMap: Map<string, IAttributeData> = new Map<string, IAttributeData>();

      attributes
        .filter(x => x.profileId === profile?.id)
        .forEach(attr => {
          let isAttrEditable = editableAttributes.some(x => x.id === attr.id);

          const attrData = {
            attribute: attr,
            isEditable: isAttrEditable,
          };

          rowAttributes.push(attrData);

          rowAttributeMap.set(`${attr.masterDataType}_${attr.masterDataId}`, attrData);
        });

      // Return the computed data for this entire row to be used by the other cells later.
      return {
        isRowEditable,
        rowProfile: profile,
        rowAttributes,
        rowAttributeMap,
      };
    },

    renderDataCell: (args, computedRowData: IComputedRowData) => {
      const attrData = computedRowData
        .rowAttributeMap
        .get(`${args.requirement.type}_${args.requirement.id}`);

      return {
        node: (
          <FacilityAttributesGridCell
            cellArgs={args}
            isEnabled={
              (
                mode === "edit"
                && computedRowData.isRowEditable
                && (attrData?.isEditable
                  || checkAttributeByTopicOrTemplatePermission(args.requirement.id,
                    args.requirement.type,
                    activeUserProfile,
                    editRestriction,
                    xAxisData))
              ) ||
              (
                ownerGroup.scoringSystem === AuditScoringSystems.CLM
                && isEditableForDefaultUser
                && attrData !== undefined
              )
            }
            profile={computedRowData.rowProfile}
            attribute={attrData?.attribute}
            subGeoUnitId={args.row.subGeoUnitId}
          />
        ),
      };
    },

    leftCommandCellOptions: {
      isVisible: true,
      isSticky: true,
      renderCell: (args, computedRowData: IComputedRowData) => {
        let customNode: React.ReactNode | undefined = undefined;
        let customNodePosition: "before" | "after" = "before";

        if (computedRowData.isRowEditable
          && mode === "edit"
          && args.depth === 2
          && plannableMasterDataTypes?.length) {
          // This is a SubGeoUnit.
          customNodePosition = "after";
          customNode = (
            <Button
              buttonType="secondary"
              img={plusIcon}
              imgPlacement="right"
              onClick={() => dispatch(setAddParentDimensionModal({
                isOpen: true,
                locationId: args.row.id,
                locationType: args.row.type,
                locationName: args.row.text,
                subGeoUnitId: args.row.subGeoUnitId,
              }))}
              className="add-parent-btn"
            >
              Parent<br />Dimension
            </Button>
          );
        } else if (computedRowData.isRowEditable
          && mode === "edit"
          && args.row.isPlannable) {
          // Only add the extra button if the row is allowed to be edited,
          // and the user is in edit mode.
          if (args.depth === 3) {
            customNodePosition = "after";
            customNode = (
              <Button
                buttonType="secondary"
                img={plusIcon}
                imgPlacement="right"
                onClick={() => dispatch(setAddChildDimensionModal({
                  isOpen: true,
                  parentProfileId: computedRowData.rowProfile?.id,
                  parentMasterDataId: args.row.id,
                  parentMasterDataType: args.row.type,
                  parentMasterDataSubType: args.row.subType,
                  parentText: args.row.text,
                  subGeoUnitId: args.row.subGeoUnitId || 0,
                }))}
                className="add-child-btn"
              >
                Child<br />Dimension
              </Button>
            );
          } else if (args.depth === 4) {
            if (computedRowData.rowProfile) {
              // Check to see if there are ANY (visible and non-visible)
              // non-deleted attributes for this profile before allowing removal.
              const hasAttributes = computedRowData.rowAttributes
                .some(x => !x.attribute.deleted)
                || nonVisibleAttributes
                  .some(x => x.profileId === computedRowData.rowProfile?.id && !x.deleted);
              customNode = (
                <RemoveChildButton
                  cellArgs={args}
                  profile={computedRowData.rowProfile}
                  isDisabled={hasAttributes}
                />
              );
            }
          }
        }

        return {
          node:
            <PlanningLeftCell
              renderCommandCellArgs={args}
              isCollapsible={true}
              onToggleRow={onToggleRow}
              customNode={customNode}
              customNodePosition={customNodePosition}
            />
        };
      },
    },

    rightCommandCellOptions: {
      isVisible: true,
      isSticky: true,
      renderCell: (_, computedRowData: IComputedRowData) => {
        let node: React.ReactNode | undefined = undefined;

        if (computedRowData.isRowEditable) {
          const editableAttributeCount = computedRowData
            .rowAttributes
            .filter(x => x.isEditable)
            .length;

          if (!editableAttributeCount) {
            // User cannot edit any of the attributes on this row.
            // Return no button.
            return {};
          }

          const unreviewedAttributes = computedRowData
            .rowAttributes
            .filter(x =>
              x.isEditable
              && !x.attribute.deleted
              && !isAttributeReviewed(x.attribute))
            .map(x => x.attribute);

          const allReviewed = unreviewedAttributes.length === 0;

          node = (
            <div
              className="review-cell"
            >
              {allReviewed
                ? (
                  <span
                    className="all-reviewed"
                  >
                    🗸 Reviewed
                  </span>
                ) : (
                  <Button
                    buttonType={"secondary"}
                    onClick={() => dispatch(markAttributesReviewed(unreviewedAttributes))}
                  >
                    Mark Reviewed
                  </Button>
                )
              }
            </div>
          );
        }

        return {
          node,
        };
      },
    },
  }), [
    profiles,
    attributes,
    mode,
    dispatch,
    nonVisibleAttributes,
    onToggleRow,
    editableRows,
    editableAttributes,
    activeUserProfile,
    xAxisData,
    editRestriction,
    plannableMasterDataTypes,
    ownerGroup.scoringSystem,
    isEditableForDefaultUser,
  ]);

  return (
    <PlanningGrid
      requirements={xAxisData}
      rows={yAxisData}
      options={gridOptions}
    />
  );
};

export default FacilityAttributesGrid;
