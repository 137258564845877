import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PerspectivePicker from "shared/components/controls/pickers/PerspectivePicker";
import { setCurrentFilters } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IDisableComponent } from "types/auditPlanningTypes";

const UserAccessFilter: React.FC<IDisableComponent> = ({ disabled, }) => {
  const dispatch = useAppDispatch();
  const selectedPerspectives = useAppSelector(store => store.auditPlanning.currentFilters.perspectives);

  return (
    <LabeledControl
      label="Perspective"
      isRequired={true}
      className="perspective-filter"
    >
      <PerspectivePicker
        enableRequiredErrorDisplay={true}
        onApply={items => {
          // Set the selected Perspective.
          dispatch(setCurrentFilters({
            perspectives: items,
          }));

          // Clear the selected Perspective X Axes since the Perspective changed.
          dispatch(setCurrentFilters({
            perspectiveXAxes: [],
          }));
        }}
        selectedItems={selectedPerspectives}
        isDisabled={disabled}
      />
    </LabeledControl>
  );
}

export default UserAccessFilter;