import config from "config";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export const authRequest = {
  scopes: [
    config.authConfig.appScope,
  ],
};

export const msalConfig: Configuration = {
  auth: {
    clientId: config.authConfig.clientId,
    authority: `https://login.microsoftonline.com/${config.authConfig.tenantId}`,
    redirectUri: config.authConfig.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

export const msalPublicClient = new PublicClientApplication(msalConfig);
