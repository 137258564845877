import SearchAuditorsApi from "api/users/SearchAuditorsApi";
import AuditorSearchMessage from "components/audits/common/auditor-search-message/AuditorSearchMessage";
import React from "react";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { userToString } from "shared/utilities/userUtilities";
import { IAuditType } from "types/auditMasterDataTypes";
import { AuditorSearchTypes } from "types/auditingTypes";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps } from "./pickerTypes";

interface IAuditorPickerProps {
  /** The Id of the current Audit Type. This is used to decide whether to search Azure Active Directory (for Self Assessments) or the Lead Auditors database (First Party). */
  auditType: IAuditType | undefined,
  /** Determines which type of auditors to query. */
  auditorType: AuditorSearchTypes,
}

/** A picker for selecting a user from Azure AD. This is NOT the same as LeadAuditor. For LeadAuditors, use the
 * LeadAuditorPicker instead.
 */
const AuditorPicker: React.FC<IAuditorPickerProps & ICommonPickerProps<IAzureADUser>> = ({
  onApply,
  selectedItems,
  allowMultiselect,
  onRenderPicker,
  isDisabled = false,
  auditType,
  auditorType,
  showSuggestions,
}) => {
  return (
    <NonReduxPicker<IAzureADUser>
      itemSorter={(a, b) => userToString(a) < userToString(b) ? -1 : 1}
      keyMapper={x => x.email}
      onApply={onApply}
      onLoadItems={async (searchTerm, abortSignal) =>
        auditType
          ? await SearchAuditorsApi.searchAuditors(searchTerm || "",
            auditType.id,
            auditorType,
            abortSignal)
          : []
      }
      showSuggestedItems={showSuggestions}
      onLoadSuggestedItems={async () =>
        auditType
          ? await SearchAuditorsApi.getAuditorSuggestions(auditType.id, auditorType)
          : []
      }
      renderSelectedItem={userToString}
      selectedItems={selectedItems}
      title={auditorType === AuditorSearchTypes.LeadAuditor
        ? "Lead Auditor"
        : "Auditor"
      }
      subTitle={
        <AuditorSearchMessage
          isFirstPartySearch={auditType?.name === "1st Party - Official"}
        />
      }
      allowMultiSelect={allowMultiselect}
      renderListItem={userToString}
      searchOptions={{
        filterItem: (item, search) => userToString(item).toLowerCase().includes(search.toLowerCase()),
        behavior: "async",
        asyncMinChars: 2,
      }}
      onRenderPicker={onRenderPicker}
      isDisabled={isDisabled
        || !auditType
      }
      tooltip={!auditType
        ? "Audit Type must be selected first."
        : undefined
      }
    />
  );
};

export default AuditorPicker;