import React from "react";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";

interface IMissingFieldsModalProps {
  onYes(): void,
  onNo(): void,
}

const MissingFieldsModal: React.FC<IMissingFieldsModalProps> = ({
  onYes,
  onNo,
}) =>
  <ConfirmModal
    header="Missing Required Fields"
    message="Your response is missing one or more required fields. If you continue, your changes will be lost. Continue without saving?"
    onYesClicked={onYes}
    onNoClicked={onNo}
  />;

export default MissingFieldsModal;