import { PayloadAction } from "@reduxjs/toolkit";
import { IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import { IPlanRequirementDimension } from "types/auditPlanningTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import { IAuditPlanningState } from "../AuditPlanningSlice";

export interface IPlanningGridState {
  facAttrYAxisData: IPlanningItem[],
  planApprovalsResultsYAxisData: IPlanningItem[],
  xAxisData: IPlanRequirementDimension[],
  collapsedYAxisItems: ICollapsedYAxisItem[],
}

export interface ICollapsedYAxisItem {
  id: number,
  type: MetaDataTypes,
}

export const initialPlanningGridState: IPlanningGridState = {
  facAttrYAxisData: [],
  planApprovalsResultsYAxisData: [],
  xAxisData: [],
  collapsedYAxisItems: [],
};

export const planningGridReducers = {
  /** Recursively toggles the collapsed state of a Y-Axis item. */
  toggleYAxisItem: (state: IPlanningGridState, action: PayloadAction<{
    /** The item to expand/collapse. */
    item: IPlanningItem,
    /** Whether the item is expanded or collapsed. */
    isExpanded: boolean,
    /** Determines whether this action should be forwarded to the other planning page. */
    shared: boolean,
  }>) => {
    // Find the toggled item in all Y Axis lists and set its state accordingly.
    const facAttrItem = findYAxisGridItem(state.facAttrYAxisData, action.payload.item.id, action.payload.item.type);
    const planApprovalsResultsYAxisData = findYAxisGridItem(state.planApprovalsResultsYAxisData, action.payload.item.id, action.payload.item.type);

    if (facAttrItem) {
      facAttrItem.isExpanded = action.payload.isExpanded;
    }
    if (planApprovalsResultsYAxisData) {
      planApprovalsResultsYAxisData.isExpanded = action.payload.isExpanded;
    }

    // Also store this collapsed/expanded state in another place so it can be remembered when clearing/reloading data.
    if (action.payload.isExpanded) {
      // Remove the "collapsed" state for this item.
      state.collapsedYAxisItems = state
        .collapsedYAxisItems
        .filter(x => !(x.id === action.payload.item.id
          && x.type === action.payload.item.type));
    } else {
      // If this item's "collapsed" state isn't already in the list, add it.
      if (!state.collapsedYAxisItems
        .some(x => x.id === action.payload.item.id
          && x.type === action.payload.item.type)) {
        state.collapsedYAxisItems.push({
          id: action.payload.item.id,
          type: action.payload.item.type,
        });
      }
    }
  },

  /** Adds new YAxis items to the list already in the store. */
  addFacAttrYAxisItem: (state: IPlanningGridState, action: PayloadAction<{
    items: IPlanningItem[],
    parentItemId: number | undefined,
    parentItemType: MetaDataTypes | undefined,
  }>) => {
    appendYAxisItem(state.facAttrYAxisData, action.payload);
  },
};

/** Adds the provided new planning items to the specified parent anywhere in the provided `yAxisData`.
 * If no parent provided, the items are appended directly to the provided `yAxisData` instead. */
function appendYAxisItem(yAxisData: IPlanningItem[], newItemData: {
  items: IPlanningItem[];
  parentItemId: number | undefined;
  parentItemType: MetaDataTypes | undefined;
}) {
  if (!newItemData.parentItemType
    || !newItemData.parentItemId) {
    // This item doesn't have a parent, push it directly to the list.
    yAxisData.push(...newItemData
      .items
      .filter(newItem =>
        !yAxisData
          .some(x => x.id === newItem.id
            && x.type === newItem.type)));
  } else {
    const parentItem = findYAxisGridItem(yAxisData,
      newItemData.parentItemId,
      newItemData.parentItemType);

    if (parentItem) {
      const itemsToAppend = newItemData.items
        .filter(newItem =>
          !parentItem
            .children
            .some(x => x.id === newItem.id
              && x.type === newItem.type));

      if (itemsToAppend.length) {
        parentItem.children = parentItem
          .children
          .concat(itemsToAppend)
          .sort((a, b) => a.text < b.text ? -1 : 1);
      }
    }
  }
}

/** Recursively searches the provided Y Axis data and returns the specified item from the list or undefined if not found. */
export function findYAxisGridItem(items: IPlanningItem[], itemId: number, type: MetaDataTypes): IPlanningItem | undefined {
  for (const item of items) {

    if (item.id === itemId
      && item.type === type) {
      return item;
    } else if (item.children.length) {
      let result = findYAxisGridItem(item.children, itemId, type);
      if (result
        && result.id === itemId
        && result.type === type) {
        return result;
      }
    }
  }

  return undefined;
}

export function applyYAxisCollapsedStates(state: IAuditPlanningState) {
  if (state.collapsedYAxisItems.length) {
    const collapseRecursively = (item: IPlanningItem) => {
      if (state.collapsedYAxisItems.some(x => x.id === item.id
        && x.type === item.type)) {
        item.isExpanded = false;
      }

      item.children.forEach(child => collapseRecursively(child));
    }

    state.facAttrYAxisData.forEach(row => collapseRecursively(row));
    state.planApprovalsResultsYAxisData.forEach(row => collapseRecursively(row));
  }
}