import React from "react";
import playIcon from "shared/media/dls/play.svg";
import "./VideoIcon.scoped.scss";

interface IVideoIconProps {
  onClick?: () => void,
  tooltip?: string,
  /** Optional. CSS size (width and height). Default = "2em" */
  size?: string,
}

const VideoIcon: React.FC<IVideoIconProps> = ({
  onClick,
  tooltip,
  size = "2em",
}: IVideoIconProps) => {
  return (
    <img
      src={playIcon}
      alt="Video"
      className={"video-button " + (onClick ? "clickable" : "")}
      title={tooltip}
      onClick={onClick}
      style={size ? {
        width: size,
        height: size,
      } : undefined}
    />
  );
};

export default VideoIcon;