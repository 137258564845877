import { authDelete, authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IFinding, IFindingType } from "store/audit/reducers/findingReducers";
import { formatFinding, formatFindingType, formatSaveFindingRequest } from "./formatters/findingFormatters";

class FindingApi {
  /**
   * Gets all finding types.
   * @param scoringSystem Filters the finding types based on the provided scoring system.
   * @param abortSignal 
   * @returns 
   */
  public async getFindingTypes(scoringSystem: string,
    abortSignal?: AbortSignal): Promise<IFindingType[]> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/findingTypes?scoringSystem=${scoringSystem}`,
      abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatFindingType(obj));
  }

  /**
   * Creates a new finding on the server.
   * @param finding The finding to create.
   * @param abortSignal 
   * @returns 
   */
  public async createFinding(finding: IFinding,
    abortSignal?: AbortSignal): Promise<IFinding> {
    const response = await authPostJson(`${config.endpoints.baseUrl}/findings`,
      formatSaveFindingRequest(finding),
      abortSignal);

    await throwIfResponseError(response);

    return formatFinding(await response.json());
  }

  /**
   * Updates an existing finding on the server (and its links).
   * @param finding The finding to update.
   * @param abortSignal 
   * @returns 
   */
  public async updateFinding(finding: IFinding,
    abortSignal?: AbortSignal): Promise<IFinding> {
    const response = await authPutJson(`${config.endpoints.baseUrl}/findings/${finding.id}`,
      formatSaveFindingRequest(finding),
      abortSignal);

    await throwIfResponseError(response);

    return formatFinding(await response.json());
  }

  /**
   * Gets all findings links to the Audit, its Questions, its Action Items, or its Questions' Action Items.
   * @param auditId The Id of the audit to search in.
   * @param abortSignal 
   * @returns 
   */
  public async getFindingsInAudit(auditId: number,
    abortSignal?: AbortSignal): Promise<IFindingType[]> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/findings?auditId=${auditId}`,
      abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatFinding(obj));
  }

  /**
   * Marks a Finding deleted on the server.
   * @param findingId The Id of the finding.
   * @param abortSignal 
   */
  public async deleteFinding(findingId: number,
    abortSignal?: AbortSignal): Promise<void> {
    const response = await authDelete(`${config.endpoints.baseUrl}/findings/${findingId}`, abortSignal);

    await throwIfResponseError(response);
  }

  /**
   * Deletes a specific link of the finding.
   * @param findingId The Id of the finding.
   * @param findingLinkIds The Ids of the links to delete.
   * @param abortSignal 
   * @returns Returns an object with a property explaining if the Finding itself was also deleted or not.
   */
  public async unlinkFinding(findingId: number,
    findingLinkIds: number[],
    abortSignal?: AbortSignal): Promise<{ wasFindingDeleted: boolean, }> {
    const response = await authDelete(`${config.endpoints.baseUrl}/findings`, {
      findingId,
      findingLinkIds,
    }, abortSignal);

    await throwIfResponseError(response);

    return {
      wasFindingDeleted: Boolean(await response.json()),
    };
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new FindingApi();
