import React from "react";
import "./Hint.scoped.scss";

const Hint: React.FC<React.HTMLProps<HTMLDivElement>> = (props) =>
  <div
    {...props}
    className={`hint ${props.className || ""}`}
  >
    {props.children}
  </div>;

export default Hint;