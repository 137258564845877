import React from "react";
import MultilineText from "shared/components/common/multiline-text/MultilineText";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { useAppSelector } from "store/store";

interface IFindingDisplayProps {
  findingTypeId?: number,
  justification?: string,
}

interface IViewFindingBodyProps {
  finding: IFindingDisplayProps,
}

const ViewFindingBody: React.FC<IViewFindingBodyProps> = ({
  finding,
}: IViewFindingBodyProps) => {
  const findingTypes = useAppSelector(store => store.audit.findingTypes);

  return (
    <>
      <LabeledControl
        label="Finding"
      >
        {findingTypes.find(x => x.id === finding.findingTypeId)?.name}
      </LabeledControl>

      <LabeledControl
        label="Justification notes"
      >
        <div>
          <MultilineText
            text={finding.justification}
          />
        </div>
      </LabeledControl>
    </>
  );
};

export default ViewFindingBody;