import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import { closePicker, collapsePickerItem, expandPickerItem, loadPickerItems, openPicker, setPickerItems, setSelectedPickerItems } from "store/search/audits/SearchAuditsSlice";
import { useAppSelector } from "store/store";
import { IBusinessFunction } from "types/masterDataTypes";

const BusinessFunctions: React.FC = () => {
  const businessFunctions = useAppSelector(store => store.searchAudits.pickerData.businessFunctions);
  
  return (
    <LabeledControl
      label="Business Function (Functions)"
    >
      <Picker<IBusinessFunction>
        pickerState={businessFunctions}
        title="Business Function (Functions)"
        renderListItem={(item) => `${item.code} - ${item.name}`}
        renderSelectedItem={(item) => item.code}
        openAction={openPicker}
        loadAction={loadPickerItems}
        closeAction={closePicker}
        setItemsAction={setPickerItems}
        setSelectedItemsAction={setSelectedPickerItems}
        searchOptions={{
          filterItem: (item, searchTerm) => {
            if (`${item.item?.code} - ${item.item?.name}`.toLowerCase().indexOf(searchTerm) > -1) {
              return true;
            }

            return false;
          },
        }}
        preserveItems={true}
        displayMode="tree"
        allowMultiSelect={true}
        expandItemsAction={expandPickerItem}
        collapseItemsAction={collapsePickerItem}
        itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
      />
    </LabeledControl>
  );
};

export default BusinessFunctions;