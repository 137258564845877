import React from "react";
import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import { IPlanRequirementDimension } from "types/auditPlanningTypes";
import "../PlanningGrid.scoped.scss";

interface IHeaderCellProps {
  requirement: IPlanRequirementDimension,
}

const HeaderCell: React.FC<IHeaderCellProps> = ({
  requirement,
}) => {
  return (
    <th>
      <TooltipElement
        tooltipContent={
          <div
            className="header-tooltip"
            style={{ maxWidth: 350 }}
          >
            {requirement.text}
          </div>
        }
        position={"Above"}
      >
        <span
          className="requirement"
        >
          {requirement.text}
        </span>
      </TooltipElement>
    </th>
  );
};

export default HeaderCell;