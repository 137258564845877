import AuditorSearchMessage from "components/audits/common/auditor-search-message/AuditorSearchMessage";
import React, { useEffect, useState } from "react";
import Divider from "shared/components/common/divider/Divider";
import HelpButton from "shared/components/common/help-button/HelpButton";
import Button from "shared/components/controls/buttons/button/Button";
import DatePicker from "shared/components/controls/date-picker/DatePicker";
import Dropdown from "shared/components/controls/dropdown/Dropdown";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import RadioSet from "shared/components/controls/radio-set/RadioSet";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IAzureADUser } from "shared/types/userProfileTypes";
import pluralize from "shared/utilities/pluralize";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { userToString } from "shared/utilities/userUtilities";
import { closePicker, loadCausalFactors, loadPickerItems, loadPriorities, loadTypes, openPicker } from "store/manage-action-item/ManageActionItemSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemStatuses, IActionItem, IActionItemEvidence, IActionItemLink } from "types/actionItemTypes";
import { IAuditQuestion } from "types/auditingTypes";
import ActionItemStatus from "../status/ActionItemStatus";
import ActionItemValidationStatus from "../validation-status/ActionItemValidationStatus";
import ActionItemEvidenceModal from "./evidence/ActionItemEvidenceModal";
import ViewEvidenceButton from "./evidence/ViewEvidenceButton";
import "./ActionItemManager.scoped.scss";

interface IActionItemManagerProps {
  /** The action item to edit. */
  actionItem: IActionItem,
  /** Optional. If true, disables all entries. */
  isDisabled?: boolean,
  /** Optional. If true, allow the current user to reassign this action item even if they do not have permission to edit it. */
  allowReassign?: boolean,
  /** Enforces a required causal factor. */
  isCausalFactorRequired: boolean,
  /** If true, allow the current user to handle evidence even if they are an assignee. */
  allowHandleEvidence: boolean,
  /** Fired as the user makes changes to the action item. */
  onActionItemChanged: (value: IActionItem) => void,
  /** Fired after the user adds, updates, or deletes an evidence item. */
  onEvidenceChanged: (evidenceEvent: "EvidenceAdded" | "EvidenceUpdated" | "EvidenceDeleted",
    evidence: IActionItemEvidence) => void,
}

const ActionItemManager: React.FC<IActionItemManagerProps> = ({
  actionItem,
  isDisabled,
  allowReassign,
  allowHandleEvidence,
  isCausalFactorRequired,
  onActionItemChanged,
  onEvidenceChanged,
}) => {
  const {
    causalFactors,
    types,
    priorities,
    pickerData: {
      users,
    },
  } = useAppSelector(store => store.manageActionItem);
  const dispatch = useAppDispatch();

  const [isEvidenceOpen, setIsEvidenceOpen] = useState(false);
  const [areLinksExpanded, setAreLinksExpanded] = useState(false);

  const availableTypes = types.data;
  const availablePriorities = priorities.data;
  const canEdit = !isDisabled
    && actionItem.status === ActionItemStatuses.Open;

  const questions = useAppSelector(store => store.audit.questions);
  const isQuestionLinked: boolean = actionItem.links.some(x => x.type === "AuditQuestion");
  const recommendedRWP: string = isQuestionLinked ? getRecommendedRWP(actionItem.links, questions) : "";

  useEffect(() => {
    if (!actionItem.type
      && !isDisabled
      && availableTypes?.length) {
      const noTypes = availableTypes.filter(x => x.allowCausalFactor);
      const preventative = noTypes.find(x => x.name === "Corrective");

      if (noTypes.length) {
        onActionItemChanged({
          ...actionItem,
          type: preventative || noTypes[0],
        });
      }
    }
  }, [availableTypes, actionItem, isDisabled, onActionItemChanged]);

  useEffect(() => {
    if (!actionItem.priority
      && !isDisabled
      && availablePriorities?.length) {
      const medium = availablePriorities.find(x => x.name === "Medium");

      if (medium) {
        onActionItemChanged({
          ...actionItem,
          priority: medium,
        });
      }
    }
  }, [availablePriorities, actionItem, isDisabled, onActionItemChanged]);

  const onEvidenceAdded = (item: IActionItemEvidence) => {
    onEvidenceChanged("EvidenceAdded", item);
  };

  const onEvidenceDeleted = (item: IActionItemEvidence) => {
    onEvidenceChanged("EvidenceDeleted", item);
  };

  const onEvidenceUpdated = (item: IActionItemEvidence) => {
    onEvidenceChanged("EvidenceUpdated", item);
  };

  return (
    <FlexCol
      className="action-item-manager"
    >
      {!!actionItem.id && (
        <>
          <FlexRow>
            <LabeledControl
              label="Status"
              className="status"
            >
              <ActionItemStatus
                statusData={actionItem}
                showClosureDate={true}
              />
            </LabeledControl>

            <ActionItemValidationStatus
              validationData={actionItem}
            />
          </FlexRow>
        </>
      )}

      {!!actionItem.links.length && (
        <FlexRow>
          <LabeledControl
            label={pluralize(actionItem.links.length, "Parent", "Parents")}
          >
            <FlexCol
              className="link-list"
            >
              {actionItem
                .links
                .slice(0, areLinksExpanded ? undefined : 1)
                .map(link => (
                  <span
                    key={`${link.linkId}__${link.type}`}
                  >
                    {link.url ?
                      <a href={link.url} target="_blank" rel="noopener noreferrer">
                        {link.url}
                      </a>
                      :
                      link.display || `${link.type}: ${link.linkId}`
                    }
                  </span>
                ))}

              {actionItem.links.length > 1 && (
                <div
                  className="more-less"
                  onClick={() => setAreLinksExpanded(!areLinksExpanded)}
                >
                  <Divider />
                  <span
                    className={areLinksExpanded ? "expanded" : undefined}
                  >
                    {areLinksExpanded
                      ? "show less"
                      : "show more"
                    }
                  </span>
                </div>
              )}
            </FlexCol>
          </LabeledControl>
        </FlexRow>
      )}

      <FlexRow>
        <LabeledControl
          label="Type"
          isRequired={true}
          hint={
            <HelpButton
              helpSlug="actionItemTypes"
            />
          }
        >
          <RadioSet
            isLoading={types.isWorking}
            loadError={types.errorMessage}
            items={isCausalFactorRequired
              ? (types.data || []).filter(x => x.allowCausalFactor)
              : (types.data || [])
            }
            selectedItem={actionItem.type}
            onChange={value => onActionItemChanged({
              ...actionItem,
              type: value,
              causalFactor: value?.allowCausalFactor
                ? actionItem.causalFactor
                : undefined,
            })}
            keyMapper={item => item.id.toString()}
            textMapper={item => item.name}
            loadItems={() => !types.data?.length
              && !isDisabled
              ? dispatch(loadTypes())
              : []
            }
            autoLoadItems={true}
            isDisabled={!canEdit}
          />
        </LabeledControl>

        {actionItem.type?.allowCausalFactor && (
          <LabeledControl
            label="Causal Factor"
            hint={
              <HelpButton
                helpSlug="actionItemCausalFactor"
              />
            }
            isRequired={true}
          >
            <Dropdown
              isLoading={causalFactors.isWorking}
              loadError={causalFactors.errorMessage}
              items={causalFactors.data || []}
              selectedItem={actionItem.causalFactor}
              onChange={value => onActionItemChanged({
                ...actionItem,
                causalFactor: value,
              })}
              keyMapper={item => item.id.toString()}
              textMapper={item => item.name}
              loadItems={() => !causalFactors.data?.length
                && !isDisabled
                ? dispatch(loadCausalFactors())
                : []
              }
              autoLoadItems={true}
              isDisabled={!canEdit}
            />
          </LabeledControl>
        )}
      </FlexRow>

      <FlexRow>
        <LabeledControl
          label="Action Responsibility"
          isRequired={true}
        >
          <Picker<IAzureADUser>
            title="Action Responsibility"
            subTitle={<AuditorSearchMessage />}
            renderListItem={(item) => userToString(item)}
            renderSelectedItem={(item) => userToString(item, "")}
            pickerState={{
              ...users,
              selectedItems: actionItem.assignedTo
                ? [{
                  key: actionItem.assignedTo.email,
                  disabled: false,
                  item: actionItem.assignedTo,
                }] : [],
            }}
            preserveItems={true}
            searchOptions={{
              asyncMinChars: 1,
              behavior: "async",
            }}
            openAction={openPicker}
            closeAction={closePicker}
            loadAction={loadPickerItems}
            setSelectedItems={items => onActionItemChanged({
              ...actionItem,
              assignedTo: items.length
                ? items[0].item
                : undefined
            })}
            noItemsMessage={"Please begin typing a search term to execute a search."}
            isDisabled={!canEdit && !allowReassign}
            itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
          />
        </LabeledControl>

        <LabeledControl
          label="Priority"
          isRequired={true}
        >
          <Dropdown
            isLoading={priorities.isWorking}
            loadError={priorities.errorMessage}
            items={priorities.data || []}
            selectedItem={actionItem.priority}
            onChange={value => {
              if (value) {
                let dueDate = new Date();
                dueDate.setDate(dueDate.getDate() + value.addsDeadlineDays);

                onActionItemChanged({
                  ...actionItem,
                  priority: value,
                  dueByTimestamp: dueDate.getTime(),
                });
              } else {
                onActionItemChanged({
                  ...actionItem,
                  priority: value,
                });
              }
            }}
            keyMapper={item => item.id.toString()}
            textMapper={item => item.name}
            loadItems={() => !priorities.data?.length
              && !isDisabled
              ? dispatch(loadPriorities())
              : []
            }
            autoLoadItems={true}
            isDisabled={!canEdit}
          />
        </LabeledControl>

        <LabeledControl
          label="Required date"
          isRequired={true}
        >
          <DatePicker
            value={actionItem.dueByTimestamp}
            onChange={date => {
              if (!date) {
                return;
              }

              if (Array.isArray(date)
                && date[0] !== null) {
                onActionItemChanged({
                  ...actionItem,
                  dueByTimestamp: date[0].getTime(),
                });
              } else if (!Array.isArray(date)) {
                onActionItemChanged({
                  ...actionItem,
                  dueByTimestamp: date.getTime(),
                });
              }
            }}
            isDisabled={!canEdit}
          />
        </LabeledControl>
      </FlexRow>

      <FlexRow>
        <LabeledControl
          label="Action Required"
          isRequired={true}
          hint={getTextboxMaxLengthHint(4000)}
        >
          <textarea
            placeholder="Action required"
            className="action-required"
            value={actionItem.actionRequired}
            onChange={e => onActionItemChanged({
              ...actionItem,
              actionRequired: e.currentTarget.value,
            })}
            maxLength={4000}
            disabled={!canEdit}
          />
        </LabeledControl>
      </FlexRow>
      {
        isQuestionLinked &&
        <div
          className="recommended-RWP-button">
          <Button
            isDisabled={recommendedRWP === ""
              || !canEdit}
            tooltip={recommendedRWP === "" ? "No RWP information available" : undefined}
            buttonType="secondary"
            onClick={() => {
              onActionItemChanged({
                ...actionItem,
                actionRequired: recommendedRWP,
              });
            }}>
            Copy Recommended RWP
          </Button>
        </div>
      }
      <FlexRow>
        <LabeledControl
          label="Description"
          hint={getTextboxMaxLengthHint(4000)}
        >
          <textarea
            placeholder="Description"
            value={actionItem.description}
            onChange={e => onActionItemChanged({
              ...actionItem,
              description: e.currentTarget.value,
            })}
            maxLength={4000}
            disabled={!canEdit}
          />
        </LabeledControl>
      </FlexRow>

      {!!actionItem.id && (
        <FlexRow>
          <ViewEvidenceButton
            evidenceCount={actionItem.evidences.length}
            onClick={() => setIsEvidenceOpen(true)}
          />

          {isEvidenceOpen && (
            <ActionItemEvidenceModal
              actionItemId={actionItem.id}
              allowAdd={allowHandleEvidence}
              allowDelete={allowHandleEvidence}
              allowEdit={allowHandleEvidence}
              getEvidenceOnLoad={false}
              evidenceItems={[...actionItem.evidences]}
              onClose={() => setIsEvidenceOpen(false)}
              onEvidenceAdded={onEvidenceAdded}
              onEvidenceDeleted={onEvidenceDeleted}
              onEvidenceUpdated={onEvidenceUpdated}
            />
          )}
        </FlexRow>
      )}
    </FlexCol>
  );
};
function getRecommendedRWP(links: IActionItemLink[], questions: IAuditQuestion[]): string {
  const questionLinksIds = links
    .filter(link => link.type === "AuditQuestion")
    .map(link => link.linkId);

  const auditQuestions = questions
    .filter(question => question.recommendedRWP
      && questionLinksIds.includes(question.auditQuestionId));

  auditQuestions.sort(question => question.questionNumber);


  let recommendedRWP = "";
  for (let i = 0; i < auditQuestions.length; i++) {
    recommendedRWP += `Question Number: ${auditQuestions[i].questionNumber} RWP: ${auditQuestions[i].recommendedRWP}`;
    if (i !== auditQuestions.length - 1) {
      recommendedRWP += "\n";
    };
  }
  return recommendedRWP;

};
export default ActionItemManager;
