import React from "react";
import editIcon from "shared/media/dls/edit-1.svg";
import deleteIcon from "shared/media/dls/delete.svg";
import downloadIcon from "shared/media/dls/download.svg";
import IEvidenceItem from "../../IEvidenceItem";
import "./EvidenceListItem.scoped.scss";

export interface IEvidenceListItemProps {
  /** The evidence item. */
  item: IEvidenceItem,
  /** Determines if the evidence item is selected currently or not. */
  isSelected?: boolean,
  /** Function to call when the user toggles the selection box. If not specified, the checkbox is not shown. */
  onSelectionChanged?(evidenceId: number, isSelected: boolean): void,
  /** Function to call when the edit button is clicked for this item. If not specified, the button is not shown. */
  onEditClicked?(evidenceId: number): void,
  /** Function to call when the delete button is clicked for this item. If not specified, the button is not shown. */
  onDeleteClicked?(evidenceId: number): void,
  /** Function to call when the download button is clicked for this item. If not specified, the button is not shown. */
  onDownloadClicked?(evidenceId: number): void,
}

const EvidenceListItem: React.FC<IEvidenceListItemProps> = ({
  item,
  isSelected,
  onSelectionChanged,
  onEditClicked,
  onDeleteClicked,
  onDownloadClicked,
}) =>
  <div
    className="evidence-item"
    key={item.filename}
  >
    <span
      className="edit-button-col"
    >
      {onSelectionChanged && (
        <input
          type="checkbox"
          checked={isSelected}
          onChange={onSelectionChanged
            ? e => onSelectionChanged(item.id, e.currentTarget.checked)
            : undefined
          }
        />
      )}

      {onEditClicked
        && (
          <button
            className="circle-img-button"
            onClick={() => onEditClicked(item.id)}
          >
            <img
              src={editIcon}
              className="icon-small"
              alt="Edit"
            />
          </button>
        )}
    </span>
    <span
      className="text"
    >
      <strong>{item.notes}</strong>
      <span>{item.filename}</span>
    </span>
    <span
      className="other-buttons-col"
    >
      {onDeleteClicked
        && (
          <button
            className="circle-img-button"
            onClick={() => onDeleteClicked(item.id)}
          >
            <img
              src={deleteIcon}
              className="icon-small"
              alt="Delete"
            />
          </button>
        )}

      {onDownloadClicked && (
        <button
          className="circle-img-button"
          onClick={() => onDownloadClicked(item.id)}
        >
          <img
            src={downloadIcon}
            className="icon-small"
            alt="Download"
          />
        </button>
      )}
    </span>
  </div>;

export default EvidenceListItem;