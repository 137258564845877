import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { ICalendarWeek } from "types/masterDataTypes";

export interface IYear {
  year: number,
  weeks: ICalendarWeek[],
}

export interface ICalendarDatesState {
  calendars: {
    [year: number]: IYear,
  },
  loadOp?: IOperation<IYear>,
}

const initialState: ICalendarDatesState = {
  calendars: {},
};

export const calendarDatesSlice = createSlice({
  name: "calendar-dates",
  initialState,
  reducers: {
    loadCalendarYear: (state, _: PayloadAction<number>) => {
      state.loadOp = {
        isWorking: true,
      };
    },
    finishLoadCalendarYear: (state, action: PayloadAction<IOperation<IYear>>) => {
      if (!action.payload.data
        || action.payload.errorMessage) {
        state.loadOp = action.payload;
        return;
      }

      state.loadOp = undefined;
      state.calendars[action.payload.data.year] = action.payload.data;
    },
  }
});

export const {
  loadCalendarYear,
  finishLoadCalendarYear,
} = calendarDatesSlice.actions;