import React from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditTopicPicker from "shared/components/controls/pickers/AuditTopicPicker";
import OwnerGroupPicker from "shared/components/controls/pickers/OwnerGroupPicker";
import AuditGroupPicker from "shared/components/controls/pickers/AuditGroupPicker";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { useAppSelector } from "store/store";
import { setValue, startRevertAuditTopicStatus, startSaveAuditTopic, toggleDeleteConfirmation } from "store/audit-topics/AuditTopicSlice";
import { AuditTopicSearchTypes } from "types/auditMasterDataTypes";
import FlexRow from "shared/components/layout/flex/FlexRow";
import "./AuditTopicManagerModal.scoped.scss";

/** A modal that handles all user interaction for creating/editing/deleting a user profile. */
const AuditTopicManagerModal: React.FC = () => {
  const dispatch = useDispatch();
  const isRevertConfirmationOpen = useAppSelector(store => store.auditTopicManagement.isRevertConfirmationOpen);
  const isDirty = useAppSelector(store => store.auditTopicManagement.isDirty);
  const auditTopicRow = useAppSelector(store => store.auditTopicManagement.auditTopicRow);
  const managerValues = useAppSelector(store => store.auditTopicManagement.managerValues);

  const modalButtons = [
    managerValues.id
      ? {
        className: "red",
        key: "Delete",
        text: "Delete",
        onClick: () => dispatch(toggleDeleteConfirmation(true)),
      } : undefined,
    {
      className: "secondary",
      key: "Cancel",
      text: "Cancel",
      onClick: () => dispatch(setValue({ isManagerOpen: false, })),
    }, {
      className: "primary",
      key: "Save",
      text: managerValues.id
        ? "Update"
        : "Add",
      disabled: !isDirty,
      onClick: () => dispatch(startSaveAuditTopic()),
    }].filter(isNotUndefined);

  return (
    <>
      {!isRevertConfirmationOpen && <Modal
        showCloseButton={true}
        onCloseButtonClicked={() => dispatch(setValue({
          isManagerOpen: false,
        }))
        }
        isOpen={true}
        header={managerValues.id
          ? "Update Topic/Sub-Topic"
          : "Add Topic/Sub-Topic"
        }
        buttons={modalButtons}
      >
        <FlexCol>
          <LabeledControl label="Audit Group"
            isRequired={true}>
            <AuditGroupPicker
              onApply={items => dispatch(setValue({
                managerValues: {
                  ...managerValues,
                  auditGroupId: items[0]?.id,
                  auditGroup: items.length > 0
                    ? items[0]
                    : undefined
                }
              }))}
              selectedItems={managerValues?.auditGroup
                ? [managerValues?.auditGroup]
                : []}
              allowMultiselect={false}
            />
          </LabeledControl>
          <LabeledControl label="Owner Group"
            isRequired={true}
          >
            <OwnerGroupPicker

              onApply={items => dispatch(setValue({
                managerValues: {
                  ...managerValues,
                  ownerGroupId: items[0]?.id,
                  ownerGroup: items.length > 0
                    ? items[0]
                    : undefined
                }
              }))}
              selectedItems={managerValues?.ownerGroup
                ? [managerValues?.ownerGroup]
                : []}
              allowMultiselect={false}
            />
          </LabeledControl>
          <LabeledControl
            label="Topic/ Sub-Topic"
            className="audit-topic-name"
            isRequired
          >
            <input
              type="text"
              placeholder="Enter Topic/ Sub-Topic Name"
              value={managerValues.name ?? ""}
              onChange={(e) => dispatch(setValue({ managerValues: { ...managerValues, name: e.target.value } }))}
              maxLength={200}
            />
          </LabeledControl>

          <LabeledControl
            label="Topic/ Sub-Topic Short Name"
            className="audit-topic-name"
            isRequired
          >
            <input
              type="text"
              placeholder="Enter Topic/ Sub-Topic Short Name"
              value={managerValues.shortName ?? ""}
              onChange={(e) => dispatch(setValue({ managerValues: { ...managerValues, shortName: e.target.value } }))}
              maxLength={200}
            />
          </LabeledControl>

          <LabeledControl label="Parent">
            <AuditTopicPicker
              onApply={items => dispatch(setValue({
                managerValues: {
                  ...managerValues,
                  parentId: items[0]?.id,
                  parent: items.length > 0
                    ? items[0]
                    : undefined
                }
              }))}
              selectedItems={managerValues?.parent
                ? [managerValues?.parent]
                : []}
              allowMultiselect={false}

              auditTopicSearch={{
                auditTopicSearchIds: [managerValues.ownerGroupId ?? 0],
                auditTopicSearchType: AuditTopicSearchTypes.OwnerGroups
              }}
              auditTopicIdToDisable={managerValues.id}
            />
          </LabeledControl>
          <LabeledControl
            label={`Is it Plannable?`}
          >
            <div className='input-types'>
              <div className="label-plannable-options yes-option">
                <input
                  type="radio"
                  checked={managerValues.isPlannable}
                  onChange={() => dispatch(setValue({ managerValues: { ...managerValues, isPlannable: true } }))}
                />
                <label className="yes-no-option">YES</label>
              </div>
              <div className="label-plannable-options">
                <input
                  type="radio"
                  checked={!managerValues.isPlannable}
                  onChange={() => dispatch(setValue({ managerValues: { ...managerValues, isPlannable: false } }))}
                />
                <label className="yes-no-option">NO</label>
              </div>
            </div>
          </LabeledControl>
          <FlexRow>
            <FlexCol>
              <LabeledControl
                label="1st Party Frequency (Months)"
                className="audit-topic-frequency"
              >
                <input
                  type="number"
                  min={0}
                  placeholder="0"
                  value={managerValues.firstPartyFrequency ?? 0}
                  onChange={(e) => dispatch(setValue({ managerValues: { ...managerValues, firstPartyFrequency: Number(e.target.value) } }))}
                  maxLength={200}
                />
              </LabeledControl>
            </FlexCol>
            <FlexCol>
              <LabeledControl
                label="Self Assessment Frequency (Months)"
                className="audit-topic-frequency"
              >
                <input
                  type="number"
                  min={0}
                  placeholder="0"
                  value={managerValues.selfAssessmentFrequency ?? 0}
                  onChange={(e) => dispatch(setValue({ managerValues: { ...managerValues, selfAssessmentFrequency: Number(e.target.value) } }))}
                  maxLength={200}
                />
              </LabeledControl>
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </Modal >
      }
      {
        isRevertConfirmationOpen && (
          <ConfirmModal
            header={`${!auditTopicRow?.deleted ? "Disable" : "Enable"} Topic/Sub-Topic`}
            message={<span>
              {!auditTopicRow?.deleted
                ? "Following topic will be marked as Disabled. Do you want to proceed?"
                : "Following topic will be marked as Enabled. Do you want to proceed?"}
              <p>
                <b>
                  Topic {auditTopicRow?.name} from Audit Group: {auditTopicRow?.auditGroup}
                </b>
              </p>
            </span>}

            onNoClicked={() => dispatch(toggleDeleteConfirmation(false))}
            onYesClicked={() => {
              dispatch(toggleDeleteConfirmation(false));
              dispatch(startRevertAuditTopicStatus());
            }}
            noButtonOptions={{ text: "Cancel" }}
            yesButtonOptions={{ text: `${!auditTopicRow?.deleted ? "Disable" : "Enable"}` }}
          />
        )
      }

    </>);
};

export default AuditTopicManagerModal;
