import QuestionApi from "api/question/QuestionApi";
import DiffDisplay from "components/diff/DiffDisplay";
import React, { useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import Modal from "shared/components/layout/modal/Modal";
import { IOperation } from "shared/types/operationTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";
import { IQuestionVersionHistoryItem } from "types/questionTypes";

interface IQuestionDiffModalProps {
  historyLogId: number,
  onClose(): void,
}

const QuestionDiffModal: React.FC<IQuestionDiffModalProps> = ({
  historyLogId,
  onClose,
}) => {
  const [loadOp, setLoadOp] = useState<undefined | IOperation<IQuestionVersionHistoryItem>>({ isWorking: true });

  useAsyncEffect(async (aborted, abortSignal) => {
    setLoadOp({
      isWorking: true,
    });

    try {
      const logItem = await QuestionApi.getQuestionHistoryLogItem(historyLogId, abortSignal);

      if (!aborted) {
        setLoadOp({
          isWorking: false,
          wasSuccessful: true,
          data: logItem,
        });
      }
    } catch (err) {
      if (!aborted) {
        setLoadOp({
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        });
      }
    }
  }, [historyLogId]);

  let mainElement: React.ReactNode;
  let header: string = "Question Change Details";

  if (loadOp?.isWorking) {
    mainElement = <Spinner />;
  } else if (loadOp?.errorMessage
    || !loadOp?.data) {
    mainElement = (
      <Banner
        type={BannerType.error}
      >
        {loadOp?.errorMessage
          ? loadOp.errorMessage
          : "Data not found."
        }
      </Banner>
    );
  } else if (loadOp?.data) {
    header = `Question #${loadOp.data.number}, version ${loadOp.data.version} Changes`;

    if (loadOp.data.diffDetails) {
      mainElement = (
        <DiffDisplay
          diffDetails={loadOp.data.diffDetails}
        />
      );
    } else {
      mainElement = (
        <Banner
          type={BannerType.info}
        >
          No extra detail information available.
        </Banner>
      );
    }
  }

  return (
    <Modal
      isOpen={true}
      header={header}
      showCloseButton={true}
      onCloseButtonClicked={onClose}
      buttons={[{
        key: "Close",
        text: "Close",
        className: "primary",
        onClick: onClose,
      }]}
    >
      {mainElement}
    </Modal>
  );
};

export default QuestionDiffModal;