import { authGetJson } from "auth/authFetches";
import config from "config";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { AuditorSearchTypes } from "types/auditingTypes";
import { formatAzureADUser } from "./formatters/userFormatter";

class SearchAuditorsApi {
  public async searchAuditors(searchValue: string,
    auditTypeId: number,
    searchType: AuditorSearchTypes,
    abortSignal?: AbortSignal): Promise<IAzureADUser[]> {
    let url = config.endpoints.users.searchAuditors;
    url += `?searchTerm=${searchValue?.trim() || ""}`;
    url += `&auditTypeId=${auditTypeId.toString()}`;
    url += `&searchType=${searchType.toString()}`;

    const response = await authGetJson(url, abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatAzureADUser(obj));
  }

  public async getAuditorSuggestions(auditTypeId: number,
    searchType: AuditorSearchTypes,
    abortSignal?: AbortSignal): Promise<IAzureADUser[]> {
    let url = config.endpoints.users.suggestedAuditors;
    url += `?auditTypeId=${auditTypeId.toString()}`;
    url += `&searchType=${searchType.toString()}`;

    const response = await authGetJson(url, abortSignal);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatAzureADUser(obj));
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new SearchAuditorsApi();
