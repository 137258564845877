import React from "react";
import { exportAudit } from "store/audit/AuditSlice";
import { useAppDispatch } from "store/store";
import "./ExportAuditButton.scoped.scss";

interface IExportAuditButtonProps {
  auditId: number,
}

const ExportAuditButton: React.FC<IExportAuditButtonProps> = ({
  auditId,
}) => {
  const dispatch = useAppDispatch();

  return (
    <button
      className="secondary centered"
      onClick={() => dispatch(exportAudit(auditId))}
    >
      Export Questions
    </button>
  );
};

export default ExportAuditButton;