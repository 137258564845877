import { v4 as uuidv4 } from "uuid";
import "./FiltersTooltip.scoped.scss";

export default function FilterTooltipSection({ label, values }: { label: string, values: string[] }) {
  if (!values.length
    || !values.some(x => x)) {
    return null;
  }

  return (
    <div className="filter-section">
      <label>{label}</label>

      <span>
        {values.map((x) =>
          <span
            key={uuidv4()}
          >
            {x}
          </span>
        )}
      </span>
    </div>
  );
}
