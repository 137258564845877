import AuditPageAuthApi from "api/auditPageAuth/AuditPageAuthApi";
import { useState } from "react";
import { IOperation } from "shared/types/operationTypes";
import { AuditPages, IAuditPageAuth, PageAuthRequestType } from "types/auditPageAuthTypes";
import { getResponseErrorMessage } from "../apiUtilities";
import useAsyncEffect from "./useAsyncEffect";

export interface IPageRestrictionProps {
  /** Select with pages the component should navigate */
  pageName: AuditPages,
  /** Wich type of auth request will be sent */
  type: PageAuthRequestType,
  /** Optional. The Id number of the audit for this page. */
  auditId?: number,
}

export default function usePageRestriction({
  pageName,
  type,
  auditId,
}: IPageRestrictionProps): IOperation<IAuditPageAuth> {
  const [authOp, setAuthOp] = useState<IOperation<IAuditPageAuth>>({ isWorking: true, });

  useAsyncEffect(async (aborted, abortSignal) => {
    setAuthOp({ isWorking: true });

    try {
      const restriction: IAuditPageAuth =
        type === PageAuthRequestType.audit
          ? await AuditPageAuthApi.getAuditPageAuthInfo({
            auditId: auditId ?? 0,
            pageName,
            pageVisibleWhileLoading: true,
            type,
          }, abortSignal)
          : await AuditPageAuthApi.getNonAuditPageAuthInfo({
            pageName,
            pageVisibleWhileLoading: true,
          }, abortSignal);

      if (!aborted) {
        setAuthOp({
          isWorking: false,
          data: restriction,
        });
      }
    } catch (err) {
      if (aborted) {
        return;
      } else {
        setAuthOp({
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        });
      }
    }
  }, [pageName, type]);

  return authOp;
}