import { ReactPlugin, useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import AuditStatusChip from 'components/audits/common/audit-status-chip/AuditStatusChip';
import ComplianceResult from 'components/audits/common/compliance-result/ComplianceResult';
import { IMyAuditsGridRowObject } from 'components/audits/mine/MyAuditsPage';
import UrlRoutes, { formatRoute } from 'components/routing/UrlRoutes';
import AppliedFilters from "components/search/filters-tooltip/AppliedFilters";
import SearchTabs from "components/search/tabs/SearchTabs";
import config from "config";
import { uniq } from 'lodash';
import React, { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import CircleImgButton from 'shared/components/controls/buttons/circle-img-button/CircleImageButton';
import Card from "shared/components/layout/card/Card";
import DataGrid from 'shared/components/layout/grid/DataGrid';
import DataGridExportButton from 'shared/components/layout/grid/export-button/DataGridExportButton';
import WijmoGridLink from 'shared/components/layout/grid/grid-link/WijmoGridLink';
import FittedPage from "shared/components/layout/page/fitted-page/FittedPage";
import editIcon from "shared/media/dls/edit-1.svg";
import filterIcon from "shared/media/dls/filter.svg";
import formatDate from 'shared/utilities/dateFormatters';
import { isNotUndefined } from 'shared/utilities/typeCheck';
import { AuditStatuses } from 'types/auditingTypes';
import "./AuditSearchResultsPage.scoped.scss";
import FiltersTooltip from "./filters-tooltip/FiltersTooltip";
import useAuditSearchValues from './useAuditSearchValues';

const AuditSearchResultsPage: React.FC = () => {
  const navigate = useNavigate();
  const searchValues = useAuditSearchValues();

  const queryString = searchValues.queryString;

  const url = config.endpoints.auditing.searchAudits + queryString;
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    // The first time the user comes here, log the search params.
    logSearchParamsToAppInsights(queryString, appInsights);
  }, [queryString, appInsights]);

  return (
    <FittedPage
      title="Audit Search"
    >
      <div className="search-results-header-buttons">
        <SearchTabs />

        <div className="right-side">
          <DataGridExportButton
            gridId="AuditSearchResults"
          />

          <AppliedFilters
            numAppliedFilters={searchValues.filterCount}
            tooltipContent={<FiltersTooltip />}
            maxTooltipHeight={350}
          />

          <Link
            to={UrlRoutes.SearchAudits.urlTemplate}
          >
            <CircleImgButton
              alt="Filters"
              icon={filterIcon}
              type="primary"
            />
          </Link>
        </div>
      </div>

      <Card
        title="Audits"
        className="search-results-card"
      >
        <DataGrid
          id="AuditSearchResults"
          dataSource="OData"
          className="with-button-content"
          showColumnChooser={true}
          dataUrl={url}
          recordClick={row => navigate(formatRoute(UrlRoutes.AuditSummary, { auditId: row.id.toString() }))}
          allowExcelExport={true}
          gridColumns={[
            {
              uid: "editButton",
              type: "unbound",
              width: 50,
              showInColumnChooser: false,
              template: (row: IMyAuditsGridRowObject) =>
                <WijmoGridLink
                  href={formatRoute(UrlRoutes.EditAudit, { auditId: row.id.toString() })}
                  isCircleButton={true}
                >
                  <img src={editIcon} className="icon-small" alt="Edit" />
                </WijmoGridLink>,
            },
            {
              uid: "id",
              field: "id",
              headerText: "Audit ID",
              width: 150,
              template: (row: IMyAuditsGridRowObject) => row.id.toString(),
              type: "unbound",
            },
            {
              uid: "name",
              field: "name",
              headerText: "Audit Name",
              width: 250,
              showInColumnChooser: false,
              template: (row: IMyAuditsGridRowObject) =>
                <WijmoGridLink
                  href={formatRoute(UrlRoutes.AuditSummary, { auditId: row.id.toString() })}
                >
                  {row.name}
                </WijmoGridLink>,
              type: "string",
            },
            {
              uid: "leadAuditor",
              field: "leadAuditor",
              headerText: "Lead Auditor",
              width: 250,
              template: (row: IMyAuditsGridRowObject) => (row.leadAuditor || "").replace(/\((.+?)@.+?\)/, "($1)"),
              type: "string",
            },
            {
              uid: "facilityNames",
              field: "facilityNames",
              headerText: "Facility",
              width: 250,
              type: "string",
            },
            {
              uid: "businessViewNames",
              field: "businessViewNames",
              headerText: "Business View (Divisions)",
              width: 250,
              type: "string",
            },
            {
              uid: "auditTopicNames",
              field: "auditTopicNames",
              headerText: "Topic",
              width: 250,
              type: "string",
            },
            {
              uid: "auditType",
              field: "auditType",
              headerText: "Audit Type",
              width: 200,
              type: "string",
            },
            {
              uid: "startDate",
              field: "startDate",
              headerText: "Start Date",
              template: (row: IMyAuditsGridRowObject) => formatDate(row.startDate),
              width: 150,
              type: "date",
            },
            {
              uid: "status",
              field: "status",
              headerText: "Status",
              template: (row: IMyAuditsGridRowObject) =>
                <AuditStatusChip status={row.status} />,
              width: 150,
              type: "string",
              textAlign: "Left",
            },
            {
              uid: "completionPercent",
              field: "completionPercent",
              headerText: "% Completed",
              template: (row: IMyAuditsGridRowObject) =>
                row.completionPercent === undefined
                  || row.completionPercent === null
                  ? "0%"
                  : `${row.completionPercent}%`,
              width: 150,
              textAlign: "Left",
              type: "number",
            },
            {
              uid: "completedCompliance",
              field: "completedCompliance",
              headerText: "Completed Compliance",
              width: 200,
              type: "string",
              template: (audit: IMyAuditsGridRowObject) =>
                audit.status === AuditStatuses.Closed
                  || audit.status === AuditStatuses.Completed
                  ? (
                    <ComplianceResult
                      complianceResult={audit.completedCompliance}
                    />
                  ) : '-',
            },
            {
              uid: "closedCompliance",
              field: "closedCompliance",
              headerText: "Closed Compliance",
              width: 200,
              type: "string",
              template: (audit: IMyAuditsGridRowObject) =>
                audit.status === AuditStatuses.Closed
                  || audit.status === AuditStatuses.Completed
                  ? (
                    <ComplianceResult
                      complianceResult={audit.closedCompliance}
                    />
                  ) : '-',
            },
            {
              uid: "allActionsClosed",
              field: "allActionsClosed",
              headerText: "All Actions Closed",
              width: 200,
              textAlign: "Left",
              type: "boolean",
            },
            {
              uid: "closedActionsOverTotalActions",
              headerText: "# Closed Actions / Total # Actions",
              template: (row: IMyAuditsGridRowObject) =>
                <span>{row.closedActionItemCount} / {row.closedActionItemCount + row.openActionItemCount}</span>,
              width: 250,
              textAlign: "Left",
              type: "unbound",
            },
          ]}
        />

      </Card>
    </FittedPage>
  );
};

export default AuditSearchResultsPage;

function logSearchParamsToAppInsights(queryString: string,
  appInsights: ReactPlugin) {
  let metaItems: { ix: number, name: string, value: string; }[] = [];
  // Split the query string into all the key=value pairs.
  const qsKeyValues = queryString
    .replace("?", "")
    .split("&")
    .filter(x => !!x)
    .map(x => {
      const [name, value] = x.split("=");

      let metaMatch = name.match(/MetaData\[(\d+)\].(Type|Id)/);

      if (metaMatch) {
        // This is a metadata item.
        let metaItem = metaItems
          .find(x => metaMatch
            && x.ix === Number(metaMatch[1]));

        if (metaItem) {
          if (metaMatch[2] === "Type") {
            metaItem.name = value;
          } else if (metaMatch[2] === "Id") {
            metaItem.value = value;
          }
        } else {
          metaItems.push({
            ix: Number(metaMatch[1]),
            name: metaMatch[2] === "Type" ? value : "",
            value: metaMatch[2] === "Id" ? value : "",
          });
        }
        return undefined;
      } else {
        return {
          key: name,
          value: value,
        };
      }
    }).filter(isNotUndefined);

  // Combine the "special" meta data query string items to the list.
  qsKeyValues.push(...metaItems.map(x => ({ key: x.name, value: x.value })));

  // Get the unique list of keys.
  uniq(qsKeyValues.map(x => x.key))
    // Foreach of the keys, log it as a custom event.
    .forEach(queryKey => {
      appInsights.trackEvent({
        name: "Audit Search | Audit Search Parameter",
        properties: {
          "searchParam": queryKey,
          "values": qsKeyValues
            .filter(x => x.key === queryKey)
            .map(x => x.value),
        },
      });
    });
}