import React from "react";
import { useSelector } from "react-redux";
import Portal from "shared/components/layout/portal/Portal";
import { IToast } from "shared/store/toast/ToastSlice";
import "./ToastContainer.scoped.scss";
import ToastMessage from "./ToastMessage";

interface IToastContainerProps {
  reduxReducerName: string,
}

const ToastContainer: React.FC<IToastContainerProps> = ({
  reduxReducerName,
}) => {
  const toasts: IToast[] | undefined = useSelector(store => (store as any)[reduxReducerName]?.toasts);

  if (!toasts?.length) {
    return null;
  }

  return (
    <Portal>
      <div className="toast-container">
        {toasts.map(t =>
          <ToastMessage
            {...t}
            key={t.id}
          />
        )}
      </div>
    </Portal>
  );
};

export default ToastContainer;