import React from "react";
import "./FindingsButton.scoped.scss";

interface IFindingsButtonProps {
  hasFinding: boolean,
  buttonText: string | undefined,
  onClick(): void,
}

const FindingsButton: React.FC<IFindingsButtonProps> = ({
  hasFinding,
  buttonText,
  onClick,
}: IFindingsButtonProps) => {
  return (
    <button
      className={`findings-button ${!hasFinding ? "missing" : ""}`}
      onClick={onClick}
    >
      {buttonText ?? "-"}
    </button>
  );
};

export default FindingsButton;