import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IPickerItem } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { IAuditCreationItem } from "types/auditingTypes";
import { IAuditType, IPerspective, IPerspectiveXAxes } from "types/auditMasterDataTypes";
import { AuditPlanApprovalStatuses, IPlanTemplateHierarchyInfo } from "types/auditPlanningTypes";
import { IBusinessTeam, IBusinessView, ICalendarWeek, IFacility } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";

export interface IAuditPlanResultsState {
  selectedPlanIds: number[],
  checkSelfAuditorOp?: IOperation<IAzureADUser>,
  createAuditModal: ICreateAuditModalData,
}

export interface ISetAuditPlanResultsFiltersArg {
  auditTypes: IPickerItem<IAuditType>[],
  businessTeams: IPickerItem<IBusinessTeam>[],
  facilities: IPickerItem<IFacility>[],
  businessViews: IPickerItem<IBusinessView>[],
  planYear: number,
  planStatuses: IPickerItem<AuditPlanApprovalStatuses>[],
  perspectives: IPickerItem<IPerspective>[],
  perspectiveXAxes: IPickerItem<IPerspectiveXAxes>[],
  leadAuditors: IPickerItem<IAzureADUser>[],
}

export interface ICreateAuditPlanContextInfo {
  planTemplateHierarchyInfo: IPlanTemplateHierarchyInfo | undefined,
  selectedDetailedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  availableTemplates: (IDetailedTemplate | IDetailedTemplateChildren)[],
}

export interface ICreateAuditModalData {
  isOpen: boolean,
  planIds: number[],
  planYear: number,
  week?: ICalendarWeek,
  auditType?: IAuditType,
  createOp?: IOperation<number>,
  isWeekPickerOpen: boolean,
  selectedAuditor?: IAzureADUser,
  planContextInfo?: ICreateAuditPlanContextInfo,
  loadTemplateChildrenOp?: IOperation<ICreateAuditPlanContextInfo>,
}

const initialState: IAuditPlanResultsState = {
  selectedPlanIds: [],
  checkSelfAuditorOp: undefined,
  createAuditModal: {
    isOpen: false,
    planYear: 0,
    planIds: [],
    week: undefined,
    auditType: undefined,
    createOp: undefined,
    isWeekPickerOpen: false,
    planContextInfo: undefined,
    loadTemplateChildrenOp: undefined,
  },
};

export const auditPlanResultsSlice = createSlice({
  name: "audit-plan-results",
  initialState,
  reducers: {
    selectPlanId: (state, action: PayloadAction<number>) => {
      if (state.selectedPlanIds.indexOf(action.payload) === -1) {
        state.selectedPlanIds.push(action.payload);
      }
    },
    deselectPlanId: (state, action: PayloadAction<number>) => {
      state.selectedPlanIds = state.selectedPlanIds.filter(x => x !== action.payload);
    },
    clearSelectedPlans: state => {
      state.selectedPlanIds = [];
    },
    setCreateAuditModal: (state, action: PayloadAction<Partial<ICreateAuditModalData>>) => {
      let wasOpened = !state.createAuditModal.isOpen
        && action.payload.isOpen;

      Object.assign(state.createAuditModal, action.payload);

      if (wasOpened) {
        state.createAuditModal.planContextInfo = undefined;

        if (!action.payload.week) {
          state.createAuditModal.week = undefined;
        }

        if (action.payload.selectedAuditor) {
          state.createAuditModal.selectedAuditor = cloneDeep(action.payload.selectedAuditor);
        } else {
          state.createAuditModal.selectedAuditor = undefined;
        }
      }
    },
    setCreateAuditModalWeekNumber: (state, action: PayloadAction<ICalendarWeek | undefined>) => {
      state.createAuditModal.week = action.payload;
    },
    startCreateAudit: (state, _: PayloadAction<{
      planIds: number[],
      startDate: number,
      endDate: number,
      leadAuditor: IAzureADUser,
      afterAuditCreation?: (newAuditId: number) => void,
    }>) => {
      state.createAuditModal.createOp = {
        isWorking: true,
      };
    },
    finishCreateAudit: (state, action: PayloadAction<IOperation<void>>) => {
      state.createAuditModal.createOp = undefined;

      if (action.payload.errorMessage) {
        return;
      }

      // Close and reset the modal.
      state.createAuditModal = cloneDeep(initialState.createAuditModal);

      // Deselect all selected plans.
      state.selectedPlanIds = [];
    },
    checkSelfAuditor: (state, _: PayloadAction<IAuditCreationItem[]>) => {
      state.checkSelfAuditorOp = {
        isWorking: true,
      };
    },
    setCheckSelfAuditorOp: (state, action: PayloadAction<IOperation<IAzureADUser> | undefined>) => {
      state.checkSelfAuditorOp = action.payload;
    },

    /** Loads all the children inside of the specified plans' templates and puts the into the redux state. */
    loadPlanTemplateChildren: (state, _: PayloadAction<{ planIds: number[], isFromAuditTemplate: boolean, requirementIds: number[] }>) => {
      state.createAuditModal.loadTemplateChildrenOp = {
        isWorking: true,
      };
    },

    /** Finishes the load of plan template children. */
    finishloadPlanTemplateChildren: (state, action: PayloadAction<IOperation<ICreateAuditPlanContextInfo>>) => {
      if (action.payload.errorMessage) {
        state.createAuditModal.loadTemplateChildrenOp = action.payload;
      } else {
        state.createAuditModal.planContextInfo = action.payload.data;
        state.createAuditModal.loadTemplateChildrenOp = undefined;
      }
    },
  },
});

export const {
  selectPlanId,
  deselectPlanId,
  clearSelectedPlans,
  setCreateAuditModal,
  setCreateAuditModalWeekNumber,
  startCreateAudit,
  finishCreateAudit,
  checkSelfAuditor,
  setCheckSelfAuditorOp,
  loadPlanTemplateChildren,
  finishloadPlanTemplateChildren,
} = auditPlanResultsSlice.actions;
