import React from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";

const AuditNotStartedBanner: React.FC = () =>
  <Banner
    type={BannerType.info}
  >
    Audit not yet Started. The Lead Auditor can start it from the Audit Summary page when they are ready to assign auditors or execute the audit.
  </Banner>;

export default AuditNotStartedBanner;