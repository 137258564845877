export function formatItemsArr<T>(arr: any, formatter: (obj: any) => T): T[] {
  if (!Array.isArray(arr)) {
    return [];
  }

  return arr
    .filter((item: any) => !!item)
    .map((item: any) => formatter(item));
}

export function formatToString(obj: any) : string {
  return obj?.toString() || "";
}