import { IAuditSchedulerItemData } from "shared/components/controls/scheduler/qiSchedulerTypes";
import { IAuditPlanningFilters } from "store/audit-planning-shared/reducers/planningFiltersReducers";
import IAuditPlanCalendarBasinYAxisData from "types/audit-plan-calendar/IAuditPlanCalendarBasinYAxisData";
import IAuditPlanCalendarChildDimension from "types/audit-plan-calendar/IAuditPlanCalendarChildDimension";
import IAuditPlanCalendarGeoUnit from "types/audit-plan-calendar/IAuditPlanCalendarGeoUnit";
import AuditPlanCalendarItemTypes from "types/audit-plan-calendar/IAuditPlanCalendarItemTypes";
import IAuditPlanCalendarLeadAuditor from "types/audit-plan-calendar/IAuditPlanCalendarLeadAuditor";
import IAuditPlanCalendarParentDimension from "types/audit-plan-calendar/IAuditPlanCalendarParentDimension";
import IAuditPlanCalendarSubGeoUnit from "types/audit-plan-calendar/IAuditPlanCalendarSubGeoUnit";
import IAuditPlanCalendarYAxisItem from "types/audit-plan-calendar/IAuditPlanCalendarYAxisItem";
import IExtraAuditPlanCalendarItemInfo from "types/audit-plan-calendar/IExtraAuditPlanCalendarItemInfo";
import IExtraInfoItem from "types/audit-plan-calendar/IExtraInfoItem";

export function formatCalendarResourcesResponse(obj: any): IAuditPlanCalendarBasinYAxisData {
  const resources: IAuditPlanCalendarBasinYAxisData = {
    basins: [],
    geoUnits: [],
    subGeoUnits: [],
    parentDimensions: [],
    childDimensions: [],
  };

  obj.forEach((basin: any) => {
    resources.basins.push(formatIAuditPlanCalendarYAxisItem(basin));

    (basin.children || []).forEach((geoUnit: any) => {
      resources.geoUnits.push(formatIAuditPlanCalendarGeoUnit(geoUnit, Number(basin.id)));

      (geoUnit.children || []).forEach((subGeoUnit: any) => {
        resources.subGeoUnits.push(formatIAuditPlanCalendarSubGeoUnit(subGeoUnit, Number(geoUnit.id)));

        (subGeoUnit.children || []).forEach((parentDimension: any) => {
          const parentItem = formatIAuditPlanCalendarParentDimension(parentDimension, Number(subGeoUnit.id));
          resources.parentDimensions.push(parentItem);

          (parentDimension.children || []).forEach((childDimension: any) => {
            resources.childDimensions.push(formatIAuditPlanCalendarChildDimension(childDimension, parentItem));
          });
        });
      });
    });
  });

  resources.basins.sort((a, b) => a.name < b.name ? -1 : 1);
  resources.geoUnits.sort((a, b) => a.name < b.name ? -1 : 1);
  resources.subGeoUnits.sort((a, b) => a.name < b.name ? -1 : 1);
  resources.parentDimensions.sort((a, b) => a.name < b.name ? -1 : 1);
  resources.childDimensions.sort((a, b) => a.name < b.name ? -1 : 1);

  return resources;
}

function formatIAuditPlanCalendarYAxisItem(obj: any): IAuditPlanCalendarYAxisItem {
  return {
    id: Number(obj.id),
    name: obj.text?.toString() || "",
  };
}

function formatIAuditPlanCalendarGeoUnit(obj: any, basinId: number): IAuditPlanCalendarGeoUnit {
  return {
    id: Number(obj.id),
    name: obj.text?.toString() || "",
    basinId,
  };
}

function formatIAuditPlanCalendarSubGeoUnit(obj: any, geoUnitId: number): IAuditPlanCalendarSubGeoUnit {
  return {
    id: Number(obj.id),
    name: obj.text?.toString() || "",
    geoUnitId,
  };
}

function formatIAuditPlanCalendarParentDimension(obj: any, subGeoUnitId: number): IAuditPlanCalendarParentDimension {
  return {
    id: Number(obj.id),
    key: (obj.type?.toString() || "") + "_" + Number(obj.id),
    name: obj.text?.toString() || "",
    type: obj.type?.toString() || "",
    subGeoUnitId,
  };
}

function formatIAuditPlanCalendarChildDimension(obj: any, parentDimension: IAuditPlanCalendarParentDimension): IAuditPlanCalendarChildDimension {
  return {
    id: Number(obj.id),
    key: (obj.type?.toString() || "") + "_" + Number(obj.id),
    name: obj.text?.toString() || "",
    type: obj.type?.toString() || "",
    parentDimensionId: parentDimension.id,
    parentDimensionType: parentDimension.type,
    parentDimensionKey: parentDimension.key,
  };
}

export function formatExtraAuditPlanCalendarItemInfo(obj: any): IExtraAuditPlanCalendarItemInfo {
  return {
    id: Number(obj.id),
    lookupType: obj.Type as AuditPlanCalendarItemTypes,
    extraInfoItems: Array.isArray(obj.extraInfoItems)
      ? obj.extraInfoItems.map(formatExtraInfoItem)
      : [],
  };
}

export function formatExtraInfoItem(obj: any): IExtraInfoItem {
  return {
    id: Number(obj.id),
    type: obj.type?.toString() || "",
    subType: obj.subType?.toString() || "",
    text: obj.text?.toString() || "",
  };
}

export function formatIAuditPlanCalendarLeadAuditorResponse(obj: any): IAuditPlanCalendarLeadAuditor[] {
  return obj.map(formatIAuditPlanCalendarLeadAuditorYAxisItem);
}

function formatIAuditPlanCalendarLeadAuditorYAxisItem(obj: any): IAuditPlanCalendarLeadAuditor {
  return {
    leadAuditorEmail: obj.leadAuditorEmail?.toString() || "",
    leadAuditorName: obj.leadAuditorName?.toString() || "Unassigned",
  };
}

export function convertAppliedAuditPlanCalendarFiltersToRequestObj(appliedFilters: IAuditPlanningFilters): Object {
  return {
    businessTeamIds: appliedFilters.businessTeams.map(x => x.id),
    businessViewIds: appliedFilters.businessViews.map(x => x.id),
    facilityIds: appliedFilters.facilities.map(x => x.id),
    auditTypeIds: appliedFilters.calendarAuditTypes.map(x => x.id),
    leadAuditorEmails: appliedFilters.leadAuditors.map(x => x.email),
    auditCalendarStatuses: appliedFilters.auditCalendarStatuses,
    complianceResults: appliedFilters.complianceResults,
    onlyUnassignedLeaders: appliedFilters.unassigned,
    perspectiveXAxisId: appliedFilters.perspectiveXAxes?.[0].id,
  };
}

export function convertAppliedAuditPlanCalendarLeadAuditorFiltersToRequestObj(appliedFilters: IAuditPlanningFilters,
  calendarStartDate: number,
  calendarEndDate: number): Object {
  return {
    businessTeamIds: appliedFilters.businessTeams.map(x => x.id),
    businessViewIds: appliedFilters.businessViews.map(x => x.id),
    facilityIds: appliedFilters.facilities.map(x => x.id),
    auditTypeIds: appliedFilters.calendarAuditTypes.map(x => x.id),
    leadAuditorEmails: appliedFilters.leadAuditors.map(x => x.email),
    auditCalendarStatuses: appliedFilters.auditCalendarStatuses,
    complianceResults: appliedFilters.complianceResults,
    onlyUnassignedLeaders: appliedFilters.unassigned,
    startDate: new Date(calendarStartDate),
    endDate: new Date(calendarEndDate),
    perspectiveXAxisId: appliedFilters.perspectiveXAxes?.[0].id,
  };
}

export function formatGetCalendarItemsResponse(obj: any): IAuditSchedulerItemData[] {
  if (!Array.isArray(obj)) {
    return [];
  }

  return obj.map((x: any): IAuditSchedulerItemData => ({
    auditTypeId: Number(x.auditTypeId),
    auditTypeName: x.auditType?.toString() ?? "",
    parentDimensions: Array.isArray(x.parentDimensions)
      ? x.parentDimensions?.map((z: any) => ({
        type: z?.type?.toString() ?? "",
        text: z?.text?.toString() ?? "",
      })) : [],
    parentDimensionId: Number(x.parentDimensionId),
    parentDimensionText: x.parentDimensionText?.toString() ?? "",
    parentDimensionType: x.parentDimensionType?.toString() ?? "",
    parentDimensionSubType: x.parentDimensionSubType?.toString(),
    requirementDimensionTexts: Array.isArray(x.requirementDimensionTexts)
      ? x.requirementDimensionTexts?.map((z: any) => z?.toString() ?? "")
      : [],
    requirementDimensionText: x.requirementDimensionText?.toString() ?? "",
    requirementDimensionType: x.requirementDimensionType?.toString() ?? "",
    requirementDimensionId: x.requirementDimensionId
      ? Number(x.requirementDimensionId)
      : undefined,
    subGeoUnitId: Number(x.subGeoUnitId),
    geoUnitId: Number(x.geoUnitId),
    basinId: Number(x.basinId),
    type: x.type?.toString() ?? "",
    auditCompliance: x.auditCompliance?.toString() ?? "",
    auditId: x.auditId
      ? Number(x.auditId)
      : undefined,
    auditSource: x.auditSource?.toString(),
    auditStatus: x.auditStatus?.toString(),
    leadAuditorEmail: x.leadAuditorEmail?.toString(),
    leadAuditorName: x.leadAuditorName?.toString(),
    legacyAuditNumber: x.legacyAuditNumber?.toString(),
    planId: Number(x.planId),
    planStatus: x.planStatus?.toString(),
    childDimensionId: x.childDimensionId
      ? Number(x.childDimensionId)
      : undefined,
    childDimensionType: x.childDimensionType?.toString(),
    childDimensionSubType: x.childDimensionSubType?.toString(),
    childDimensionText: x.childDimensionText?.toString(),
    planYear: Number(x.planYear),
    planWeekOfYear: x.planWeekOfYear
      ? Number(x.planWeekOfYear)
      : undefined,
    // The Start and End date might either come from the Audit, in which case the real values must be used and converted
    // to local time, or from the Plan. Since the plan only specifies WeekNumber, the start/end dates are as yet unspecified
    // so strip the "Z" off the end and pretend they're local times.
    endTime: x.auditId
      ? new Date(x.endTime).getTime()
      : new Date(x.endTime.replace("Z", "")).getTime(),
    startTime: x.auditId
      ? new Date(x.startTime).getTime()
      : new Date(x.startTime.replace("Z", "")).getTime(),
  }));
}