import { getResponseFromQuestion } from "components/audits/common/auditUtilities";
import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { canAnswersBeCombinedForFindingAssignment, doesScoringSystemAllowFindings } from "shared/utilities/findingUtilities";
import usePageRestriction from "shared/utilities/hooks/usePageRestriction";
import { tooltipMessage } from 'shared/utilities/stringUtilities';
import { ISelectedAuditQuestion, setUpdateQuestionResponseModal } from "store/audit-non-conformances/AuditNonConformancesSlice";
import { openFindingModal, setManageActionItemModal } from "store/audit/AuditSlice";
import { FindingLinkType, FindingModalModes, IFinding, IFindingType } from "store/audit/reducers/findingReducers";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes } from "types/actionItemTypes";
import { AuditPages, EditRestriction, PageAuthRequestType } from "types/auditPageAuthTypes";
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";
import { findActionItemsByParentItems } from "../../../../shared/utilities/actionItemUtilities";
import { AuditScoringSystems } from "../../../../types/auditPlanningTypes";
import "./TopicCard.scoped.scss";

const NonCompliantQuestionsCard: React.FC = () => {
  const selectedAuditQuestions = useAppSelector(store => store.auditNonConformance.selectedAuditQuestions);
  const auditId = useAppSelector(store => store.audit.audit?.id);
  const status = useAppSelector(store => store.audit.audit?.status);
  const allAnswers = useAppSelector(store => store.audit.answers);
  const questions = useAppSelector(store => store.audit.questions);
  const findings = useAppSelector(store => store.audit.findings);
  const findingTypes = useAppSelector(store => store.audit.findingTypes);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const hasEditPermission = (editRestriction === EditRestriction.EditAll
    || editRestriction === EditRestriction.EditOwn);

  const scoringSystem = questions[0]?.scoringSystem;

  const dispatch = useAppDispatch();

  const nonConformantAnswerCodes = allAnswers.filter(x => x.canBeUpdated).map(x => x.code);

  const checkedQuestions = questions
    .filter(x => selectedAuditQuestions
      .some(y => y.questionId === x.questionId));

  // If the scoring system is QMS / HSE, then each selected question
  // must have a Validated Corrective/Preventative action item assigned to it.
  // If not, then the response cannot be updated.
  const isAnySelectedQuestionMissingValidatedRWP =
    (scoringSystem === AuditScoringSystems.QMS
      || scoringSystem === AuditScoringSystems.HSE)
    && checkedQuestions
      .some(q => {
        // Find the actions linked to this question
        // and check to ensure that there is at least 1 Corrective/Preventative
        // RWP that is validated.
        return !findActionItemsByParentItems(actionItems,
          [{
            linkType: ActionItemLinkTypes.AuditQuestion,
            linkId: q.auditQuestionId,
          }]).some(ai => (ai.type?.name === "Corrective"
            || ai.type?.name === "Preventative")
            && ai.isValidated);
      });

  // Buttons are disabled if the status is not loaded or is closed
  // or if there are no questions with current NO answers
  // or if the audit is HSE/QMS and any of the selected questions are missing
  // a validated Preventative/Corrective action item.
  const isUpdateQuestionButtonDisabled = !status
    || status === AuditStatuses.Closed
    || !questions.some(x => nonConformantAnswerCodes.some(ans => ans === getResponseFromQuestion(x, status)?.answer))
    || isAnySelectedQuestionMissingValidatedRWP;

  const areCreateActionItemButtonDisabled = !status
    || status === AuditStatuses.Closed
    || !questions.some(x => nonConformantAnswerCodes.some(ans => ans === getResponseFromQuestion(x, status)?.answer));

  // Get the user's restriction for the "Findings" page to find if the button should appear or not.
  const findingsPagePermission = usePageRestriction({
    pageName: AuditPages.AuditFindingsPage,
    type: PageAuthRequestType.audit,
    auditId,
  });

  const isFindingsEditable = findingsPagePermission.data?.editRestriction === EditRestriction.EditAll;

  let isFindingsButtonDisabled = true;
  let findingsDisabledReason = "";

  if (isFindingsEditable
    && doesScoringSystemAllowFindings(scoringSystem)) {
    let findingsBtnAvailability = getFindingsButtonAvailability(status,
      questions,
      findings,
      findingTypes,
      selectedAuditQuestions);

    isFindingsButtonDisabled = findingsBtnAvailability.isFindingsButtonDisabled;
    findingsDisabledReason = findingsBtnAvailability.findingsDisabledReason;
  }

  const onCreateActionItemClicked = () => {
    if (selectedAuditQuestions.length > 0) {
      // Open the modal and give it the selected audit question data.
      dispatch(setManageActionItemModal({
        actionItemId: undefined,
        auditId: undefined,
        linkOptions: selectedAuditQuestions.map(x => ({
          linkId: x.auditQuestionId,
          linkType: ActionItemLinkTypes.AuditQuestion,
          parentDisplay: `Question #${x.questionNum}`,
        })),
        isOpen: true,
        actionToPerformOnLoad: undefined,
      }));
    } else if (selectedAuditQuestions.length === 0
      && auditId) {
      // Open the modal and give it just the auditId.
      dispatch(setManageActionItemModal({
        actionItemId: undefined,
        auditId: auditId,
        linkOptions: [],
        isOpen: true,
        actionToPerformOnLoad: undefined,
      }));
    }
  };

  const onUpdateQuestionResponseClicked = () => {
    dispatch(setUpdateQuestionResponseModal({
      isOpen: true,
      auditQuestionIds: selectedAuditQuestions.map(x => x.auditQuestionId),
    }));
  };

  return (
    <Card
      title=""
      showHeader={true}
      headerElement={(
        <div
          className="question-topic-labels"
        >
          <div className="question-Topic-display">

            <LabeledControl
              label=""

            >
              <span className="question-topic-title">Non-Compliant Questions</span>
            </LabeledControl>

          </div>
          <div className="question-Topic-display">
            <LabeledControl
              label=""
              className="nc-question-topic-lbl"
            >
              {selectedAuditQuestions.length} Selected Questions
            </LabeledControl>

            {hasEditPermission
              && (
                <>
                  <button
                    disabled={isUpdateQuestionButtonDisabled
                      || selectedAuditQuestions.length === 0
                      || status !== AuditStatuses.Completed
                      || status === undefined
                    }
                    title={tooltipMessage(status !== AuditStatuses.Completed,
                      "The Audit's status must be Completed to update responses here.")
                    }
                    className="secondary"
                    onClick={onUpdateQuestionResponseClicked}
                  >
                    Update Question Response
                  </button>

                  {doesScoringSystemAllowFindings(scoringSystem)
                    && isFindingsEditable
                    && status === AuditStatuses.InProgress && (
                      <button
                        disabled={isFindingsButtonDisabled}
                        title={tooltipMessage(isFindingsButtonDisabled, findingsDisabledReason)}
                        className="primary"
                        onClick={() => {
                          // The button is disabled if the user selects questions with different answer
                          // codes. So it should be find to just grab the very first question's answer
                          // since they'll all be the same.
                          const answerCode = questions
                            .find(x => x.auditQuestionId === selectedAuditQuestions[0].auditQuestionId)
                            ?.responses.find(x => x.auditStatus === status)
                            ?.answer;

                          if (answerCode) {
                            dispatch(openFindingModal({
                              finding: undefined,
                              answerCode,
                              desiredLinks: selectedAuditQuestions.map(x => ({
                                linkId: x.auditQuestionId,
                                linkType: FindingLinkType.AuditQuestion,
                              })),
                              autoSelectedLinksForRemoval: [],
                              mode: FindingModalModes.EditCreate,
                            }));
                          } else {
                            // This shouldn't be possible because the button is disabled if the user has
                            // selected questions without answers.
                            dispatch(showErrorToast("Cannot create findings for questions unless those questions are answered and share the same answer."));
                          }
                        }}
                      >
                        Assign Findings
                      </button>
                    )}

                  <button
                    disabled={areCreateActionItemButtonDisabled}
                    title={tooltipMessage(areCreateActionItemButtonDisabled, "No Non-Compliant Questions.")}
                    className="primary"
                    onClick={onCreateActionItemClicked}
                  >
                    Create New Action Item
                  </button>
                </>
              )}
          </div>
        </div>
      )}
    />
  );
};

export default NonCompliantQuestionsCard;

function getFindingsButtonAvailability(status: AuditStatuses | undefined,
  questions: IAuditQuestion[],
  findings: IFinding[],
  findingTypes: IFindingType[],
  selectedAuditQuestions: ISelectedAuditQuestion[]): {
    isFindingsButtonDisabled: boolean,
    findingsDisabledReason: string,
  } {
  let isDisabled = false;
  let disabledReason = "";

  if (!status) {
    isDisabled = true;
    disabledReason = "No Audit Status found.";
  } else if (status !== AuditStatuses.InProgress) {
    isDisabled = true;
    disabledReason = "Findings can only be created during In Progress status.";
  } else if (!selectedAuditQuestions.length) {
    isDisabled = true;
    disabledReason = "Questions must first be selected to assign findings.";
  } else if (selectedAuditQuestions
    .some(x => doesQuestionHaveFinding(x.auditQuestionId, findings))) {
    isDisabled = true;
    disabledReason = "One or more of the selected questions is already assigned a finding.";
  } else if (
    !canAnswersBeCombinedForFindingAssignment(selectedAuditQuestions
      .map(x => questions.find(q => q.auditQuestionId === x.auditQuestionId)
        ?.responses
        .find(r => r.auditStatus === status)
        ?.answer),
      findingTypes)) {
    isDisabled = true;
    disabledReason = "All selected questions must be assignable to the same finding type.";
  }

  return {
    isFindingsButtonDisabled: isDisabled,
    findingsDisabledReason: disabledReason,
  };
}

function doesQuestionHaveFinding(auditQuestionId: number,
  allFindings: IFinding[]) {
  return allFindings
    .some(f => f.links
      .some(l => l.linkId === auditQuestionId
        && l.linkType === FindingLinkType.AuditQuestion));
}
