import { userToString } from 'shared/utilities/userUtilities';
import { IAuditPlanningFilters } from 'store/audit-planning-shared/reducers/planningFiltersReducers';
import { useAppSelector } from 'store/store';
import { auditPlanCalendarStatusFilterToString } from 'types/audit-plan-calendar/auditPlanCalendarStatusFilterUtils';

const useCalendarFilterCount = (): ICalendarFilterData => {
  const appliedFilters = useAppSelector(store => store.auditPlanning.appliedFilters);

  return getFilterData(appliedFilters);
};

export default useCalendarFilterCount;

function getFilterData(appliedFilters: IAuditPlanningFilters | undefined): ICalendarFilterData {
  let filterData: ICalendarFilterData = {
    count: 0,
    filters: {},
  };

  if (!appliedFilters) {
    return filterData;
  }

  addItemsToFilterData(filterData,
    "Audit Types",
    appliedFilters.calendarAuditTypes.map(x => x.name));

  addItemsToFilterData(filterData,
    "Business Teams",
    appliedFilters.businessTeams.map(x => `${x.code} - ${x.name}`));

  addItemsToFilterData(filterData,
    "Business Views",
    appliedFilters.businessViews.map(x => `${x.code} - ${x.name}`));

  addItemsToFilterData(filterData,
    "Compliance Results",
    appliedFilters.complianceResults.map(x => x.toString()));

  addItemsToFilterData(filterData,
    "Facilities",
    appliedFilters.facilities.map(x => `${x.commonId} - ${x.name}`));

  addItemsToFilterData(filterData,
    "Lead Auditors",
    appliedFilters.leadAuditors.map(x => userToString(x)));

  if (appliedFilters.perspectives.length) {
    addItemToFilterData(filterData,
      "Perspective",
      appliedFilters.perspectives[0].name);
  }

  if (appliedFilters.perspectiveXAxes.length) {
    addItemToFilterData(filterData,
      "Perspective X-Axis",
      appliedFilters.perspectiveXAxes[0].auditGroup + "-" + appliedFilters.perspectiveXAxes[0].ownerGroup);
  }

  // Only include the "Show Unassigned Only" filter if it is true.
  if (appliedFilters.unassigned) {
    addItemToFilterData(filterData,
      "Show Unassigned Only",
      "Yes");
  }

  if (appliedFilters.auditCalendarStatuses.length) {
    addItemsToFilterData(filterData,
      "Status",
      appliedFilters.auditCalendarStatuses.map(auditPlanCalendarStatusFilterToString));
  }

  return filterData;
}

function addItemsToFilterData(filterData: ICalendarFilterData, label: string, items: string[]) {
  filterData.count += items.length;
  items.sort(alphaSort);
  filterData.filters[label] = items;
}

function addItemToFilterData(filterData: ICalendarFilterData, label: string, itemText: string) {
  filterData.count += 1;
  filterData.filters[label] = [itemText];
}

export interface ICalendarFilterData {
  count: number,
  filters: {
    [key: string]: string[],
  },
}

function alphaSort(a: string, b: string) {
  return a < b ? -1 : 1;
}