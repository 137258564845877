import { ReactNode } from "react";
import { IColumnItem } from "shared/types/columnTypes";

interface ITableRowsProps<T, K extends keyof T, BeforeRenderOutput = void> {
  /** The data to show in the table body. */
  data: Array<T>,
  /** The name of the property that can uniquely identify an object that will be rendered. */
  keyProp: keyof T,
  /** The column information for the table. */
  columns: Array<IColumnItem<T, K, BeforeRenderOutput>>,
  /** If provided, this function will be called for each table cell. The function must return a <td> element. */
  columnRender?: (columnKey: string, boundObj: any, objProperty: any, beforeRenderData?: BeforeRenderOutput) => JSX.Element,
  /** Specifies a function to call before a row is rendered. Whatever this returns is then passed to all customRenders for the columns of that row. */
  getBeforeRenderRowData?: (item: T) => BeforeRenderOutput,
  /** A custom props for <tr/> element. */
  rowProps?: ITableRowProps[],
  /** Optional. A function to render a table row in a completely custom way.
 * If this function returns `undefined`, the default row rendering will be used instead.
 * If it returns `null` then no row will be rendered.
 * Otherwise, it must return a <tr key="xxx"></tr> with a unique key. */
  customRowRender?: (item: T) => ReactNode,
}

export interface ITableRowProps {
  dataId: number,
  className?: string,
}

const TableRows = <T, K extends keyof T, BeforeRenderOutput>({
  data,
  keyProp,
  columns,
  columnRender,
  getBeforeRenderRowData,
  rowProps,
  customRowRender,
}: ITableRowsProps<T, K, BeforeRenderOutput>) =>
  <tbody>
    {data.map(row => {
      const beforeRenderData = getBeforeRenderRowData?.(row);

      const customRowDisplay = customRowRender?.(row);

      if (customRowDisplay === null) {
        return null;
      } else if (customRowDisplay !== undefined) {
        return customRowDisplay;
      } else {
        return (
          <tr
            key={`row-${row[keyProp]}`}
            className={rowProps?.find(x => x.dataId === row[keyProp] as any)?.className}
          >
            {columns.map(column => {
              if (column.customRender) {
                return (
                  <td
                    key={`cell-${column.key}`}
                    title={column.getTooltip?.(row, beforeRenderData)}
                    style={column.textAlign
                      ? {
                        textAlign: column.textAlign,
                      } : undefined
                    }
                  >
                    <div
                      style={column.width || column.maxHeight
                        ? {
                          width: column.width,
                          maxHeight: column.maxHeight,
                          overflowY: column.maxHeight ? "hidden" : undefined,
                        } : undefined}
                    >
                      {column.customRender
                        ? column.customRender(row, beforeRenderData)
                        : (column.property
                          ? (row[column.property] as any)?.toString()
                          : undefined)
                      }
                    </div>
                  </td>
                );
              } else if (columnRender) {
                return columnRender(column.key, row, column.property, beforeRenderData);
              } else {
                return (
                  <td
                    key={`cell-${column.key}`}
                    title={column.getTooltip?.(row, beforeRenderData)}
                    style={column.textAlign
                      ? {
                        textAlign: column.textAlign,
                      } : undefined
                    }
                  >
                    <div
                      style={column.width || column.maxHeight
                        ? {
                          width: column.width,
                          maxHeight: column.maxHeight,
                          overflowY: column.maxHeight ? "hidden" : undefined,
                        } : undefined
                      }
                    >
                      {column.property
                        ? (row[column.property] as any)?.toString()
                        : undefined
                      }
                    </div>
                  </td>
                );
              }
            })}
          </tr>
        );
      }
    })}
  </tbody>;

export default TableRows;