import React from "react";
import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import CollapseSwitch from "shared/components/controls/collapse-switch/CollapseSwitch";
import { IRenderCommandCellArgs } from "../PlanningGrid";
import "./PlanningLeftCell.scoped.scss";

interface IPlanningLeftCellProps {
  renderCommandCellArgs: IRenderCommandCellArgs,
  isCollapsible?: boolean,
  onToggleRow?: (args: IRenderCommandCellArgs) => void,
  /** Optional. An extra node that will be rendered if the customNodePosition is provided. */
  customNode?: React.ReactNode,
  /** Optional. Where to render the custom node. Before = before the cell text. After = after the cell text, before the collapse/expand button. */
  customNodePosition?: "before" | "after",
}

const PlanningLeftCell: React.FC<IPlanningLeftCellProps> = ({
  renderCommandCellArgs: args,
  isCollapsible,
  onToggleRow,
  customNode,
  customNodePosition,
}: IPlanningLeftCellProps) => {
  return (
    <div
      className={`left-cell ${args.parentRow?.isPlannable
        ? "right"
        : ""} ${args.row.isPlannable
          ? "plannable"
          : undefined}`
      }
      style={{
        paddingLeft: `${args.depth * 1}em`,
      }}
    >
      <span
        className={`${args.row.type}-name cell-text`}
      >
        {customNodePosition === "before" && customNode}

        <TooltipElement
          tooltipContent={
            <div
              className="header-tooltip"
              style={{ maxWidth: 350 }}
            >
              {args.row.text}
            </div>
          }
          position={"Above"}
        >
          <label>
            {args.row.text}
          </label>
        </TooltipElement>

        {customNodePosition === "after" && customNode}
      </span>

      <div
        className="toggle-holder"
      >
        {!!args.row.children.length
          && isCollapsible
          && onToggleRow && (
            <CollapseSwitch
              isOpen={args.row.isExpanded}
              onClick={() => onToggleRow(args)}
            />
          )}
      </div>
    </div>
  );
};

export default PlanningLeftCell;