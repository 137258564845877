import { authRequest, msalPublicClient } from "./auth-config";

export const acquireAccessToken = async () => {
  // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const activeAccount = msalPublicClient.getActiveAccount();
  const accounts = msalPublicClient.getAllAccounts();

  if (!activeAccount
    && accounts.length === 0) {
    throw new Error("Network request aborted. User is not authenticated.");
  }

  const request = {
    scopes: authRequest.scopes,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalPublicClient.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (err: any) {
    console.log(err);
    msalPublicClient.loginRedirect();
  }
};

const authFetch = async (method: "GET" | "POST" | "PUT" | "DELETE",
  url: string,
  body?: any,
  headers?: any,
  abortSignal?: AbortSignal) => {
  const token = await acquireAccessToken();

  return fetch(url, {
    signal: abortSignal,
    method,
    headers: Object.assign({},
      headers || {},
      {
        "Authorization": `Bearer ${token}`,
      }),
    body,
  });
}
const authGetJson = (url: string, abortSignal?: AbortSignal) =>
  authFetch("GET", url, undefined, undefined, abortSignal);

const authPostJson = (url: string, data: any, abortSignal?: AbortSignal) =>
  authFetch("POST", url, JSON.stringify(data), {
    "Content-Type": "application/json",
  }, abortSignal);

const authPutJson = (url: string, data: any, abortSignal?: AbortSignal) =>
  authFetch("PUT", url, JSON.stringify(data), {
    "Content-Type": "application/json",
  }, abortSignal);

const authDelete = (url: string, data?: any, abortSignal?: AbortSignal) =>
  authFetch("DELETE", url, data
    ? JSON.stringify(data)
    : undefined,
    data ? {
      "Content-Type": "application/json",
    } : undefined,
    abortSignal);

export {
  authFetch,
  authGetJson,
  authPostJson,
  authPutJson,
  authDelete,
};

