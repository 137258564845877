import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import UrlRoutes from "components/routing/UrlRoutes";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import HelpButton from "shared/components/common/help-button/HelpButton";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Card from "shared/components/layout/card/Card";
import Page from "shared/components/layout/page/Page";
import { createPlan, removePlan, setConfirmEditOrRemovePlanModal, setCreatePlanModal, setEditPlanModal, setRemovePlanModal } from "store/audit-plan-approvals/AuditPlanApprovalsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import PlanningSpinner from "../PlanningSpinner";
import AuditPlanningFilters from "../planning-filters/AuditPlanningFilters";
import GridCard from "../planning-grid/grid-card/GridCard";
import GridLegend, { GridLegendMode } from "../planning-grid/grid-legend/GridLegend";
import ApprovalModal from "./approval-modal/ApprovalModal";
import ConfirmEditOrRemovePlanModal from "./confirm-remove-or-edit-modal/ConfirmEditOrRemovePlanModal";
import CreatePlanModal from "./create-plan/CreatePlanModal";
import EditPlanModal from "./edit-plan-modal/EditPlanModal";
import PlanApprovalsGrid from "./grid-components/PlanApprovalsGrid";
import RemovePlanModal from "./remove-modal/RemovePlanModal";

const PlanApprovalsPage: React.FC = () => {
  const {
    createPlanModal,
    removePlanModal,
    planApprovalEditModal,
    confirmEditOrRemovePlanModal,
    approvalModal,
  } = useAppSelector(store => store.auditPlanApprovals);

  const {
    appliedFilters,
    hasDataEverLoaded,
  } = useAppSelector(store => store.auditPlanning);
  const auditTypeName = appliedFilters?.auditType?.name;

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: `Plan Approvals - ${auditTypeName}`,
    });
  }, [auditTypeName, appInsights]);

  const dispatch = useAppDispatch();

  let gridComponent: React.ReactNode | undefined = undefined;

  if (!appliedFilters
    || !hasDataEverLoaded) {
    gridComponent = (
      <Banner
        type={BannerType.info}
      >
        Please choose at least one of each required filter above and then click Apply Filters.
      </Banner>
    );
  } else {
    gridComponent = (
      <>
        <PlanApprovalsGrid />
        <GridLegend
          displayMode={GridLegendMode.PlanApprovals}
          isCLMMode={appliedFilters.perspectiveXAxes[0]?.ownerGroup === "CLM"}
        />
      </>
    );
  }

  return (
    <Page
      title="Audit Plan Approval"
    >
      <Card>
        <AuditPlanningFilters
          showMoreFilters
          showFacilities
          showPlanStatus
          showBusinessViews
          showLeadAuditors={false}
          showIncludeDeletedAttributes={false}
          showReviewStatus={false}
          showReviewedBy={false}
          showAuditCalendarStatuses={false}
          showCalendarAuditTypes={false}
          showComplianceResults={false}
          showUnassigned={false}
        />
      </Card>

      <GridCard
        centerNode={
          <>
            <Link
              to={UrlRoutes.LegacyNodeMapping.urlTemplate}
              target="_blank"
            >
              Legacy QUEST Node mapping
            </Link>
            <HelpButton
              helpSlug="planApprovals"
            />
          </>
        }
        page="PlanApprovals"
      >
        {gridComponent}
      </GridCard>

      <PlanningSpinner />

      <ConfirmEditOrRemovePlanModal
        isOpen={confirmEditOrRemovePlanModal.isOpen}
        onCancel={() => dispatch(setConfirmEditOrRemovePlanModal({ isOpen: false, }))}
        onEdit={() => {
          dispatch(setConfirmEditOrRemovePlanModal({ isOpen: false, }));
          dispatch(setEditPlanModal({ ...confirmEditOrRemovePlanModal, isOpen: true, }));
        }}
        onRemove={() => {
          dispatch(setConfirmEditOrRemovePlanModal({ isOpen: false, }));
          dispatch(setRemovePlanModal({ ...confirmEditOrRemovePlanModal, isOpen: true, }));
        }}
        childDimension={confirmEditOrRemovePlanModal.childDimension}
        parentDimension={confirmEditOrRemovePlanModal.parentDimension}
        requirementDimension={confirmEditOrRemovePlanModal.requirementDimension}
      />

      <ApprovalModal
        isOpen={approvalModal.isOpen}
        childDimension={approvalModal.childDimension}
        parentDimension={approvalModal.parentDimension}
        auditTypeName={appliedFilters?.auditType?.name || ""}
      />

      <CreatePlanModal
        isOpen={createPlanModal.isOpen}
        onCancel={() => dispatch(setCreatePlanModal({ isOpen: false, }))}
        onSave={comment => dispatch(createPlan({ comment }))}
        childDimension={createPlanModal.childDimension}
        parentDimension={createPlanModal.parentDimension}
        requirementDimension={createPlanModal.requirementDimension}
      />

      <RemovePlanModal
        isOpen={removePlanModal.isOpen}
        onCancel={() => dispatch(setRemovePlanModal({ isOpen: false, }))}
        onRemove={comment => dispatch(removePlan({ comment }))}
        childDimension={removePlanModal.childDimension}
        parentDimension={removePlanModal.parentDimension}
        requirementDimension={removePlanModal.requirementDimension}
      />

      <EditPlanModal
        isOpen={planApprovalEditModal.isOpen}
        childDimension={planApprovalEditModal.childDimension}
        parentDimension={planApprovalEditModal.parentDimension}
        requirementDimension={planApprovalEditModal.requirementDimension}
      />

      {(createPlanModal.operation?.isWorking
        || confirmEditOrRemovePlanModal.operation?.isWorking
        || removePlanModal.operation?.isWorking
        || planApprovalEditModal.operation?.isWorking
      ) && (
          <ModalSpinner />
        )}
    </Page>
  );
};

export default PlanApprovalsPage;
