
interface IStorageObject {
  value: boolean;
  expirationDate: Date;
}

class StorageApi {
  public setItem(key: string, value: any, expirationDays: number) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    localStorage.setItem(key, JSON.stringify({
      value, expirationDate
    }));
  }

  public getItem(key: string) {
    const item = localStorage.getItem(key);
    if (item) {
      const retVal: IStorageObject = JSON.parse(item);
      return retVal.value;
    }
    return false;
  }

  public clearExpiredItems() {
    Object.keys(localStorage).forEach(key => {
      const item = localStorage.getItem(key);

      if (item) {
        try {
          const data: IStorageObject = JSON.parse(item);
          const expirationDate = new Date(data.expirationDate);
          const currentDate = new Date(Date.now());
          if (expirationDate < currentDate) {
            localStorage.removeItem(key);
          }
        } catch { }
      }
    });
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new StorageApi();
