import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Prompt from "shared/components/common/prompt/Prompt";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import VideoLinkButton from "shared/components/common/video-link-button/VideoLinkButton";
import filterIcon from "shared/media/dls/filter.svg";
import { openFilters, setQueueAndIndex } from "store/audit-execution/AuditExecutionSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import AuditPage from "../common/audit-page/AuditPage";
import ExportAuditButton from "../common/export-audit-button/ExportAuditButton";
import "./AuditExecutionPage.scoped.scss";
import FilterModal from "./filters/FilterModal";
import QuestionExecution from "./question/QuestionExecution";

const AuditExecutionPage: React.FC = () => {
  const {
    auditId,
    auditQuestionId,
  } = useParams();

  const questions = useAppSelector(store => store.audit.questions);
  const auditStatus = useAppSelector(store => store.audit.audit?.status);
  const isFilterModalOpen = useAppSelector(store => store.auditExecution.filterModal.isOpen);
  const saveWorkspaceOp = useAppSelector(store => store.auditExecution.saveWorkspaceOp);
  const isCurrQuestionDirty = useAppSelector(store => store.auditExecution.workspace?.isDirty);
  const userEmail = useAppSelector(store => store.auth.currentUser.email);

  const dispatch = useAppDispatch();

  const [hasLoadedQueue, setHasLoadedQueue] = useState(false);

  useEffect(() => {
    if (hasLoadedQueue
      || !questions.length) {
      return;
    }

    let queueAQIds: number[] = [];
    let currQueueIndex = -1;

    if (!isNaN(Number(auditQuestionId))) {
      const aqId = Number(auditQuestionId);

      let qMatch = questions.find(x => x.auditQuestionId === aqId);

      if (qMatch !== undefined) {
        let topicId = qMatch.topicId;

        // Find all questions with the same topic.
        queueAQIds = questions
          .filter(x => x.topicId === topicId)
          .map(x => x.auditQuestionId);

        currQueueIndex = queueAQIds.indexOf(aqId);
      } else {
        queueAQIds = [];
      }
    } else {
      queueAQIds = questions
        .filter(x => x.auditorEmail?.toLowerCase() === userEmail.toLowerCase())
        .map(x => x.auditQuestionId);
      currQueueIndex = 0;
    }

    dispatch(setQueueAndIndex({
      queueAQIds,
      currQueueIndex,
    }));

    setHasLoadedQueue(true);
  }, [questions, auditStatus, userEmail, auditQuestionId, hasLoadedQueue, dispatch]);

  if (!auditId
    || isNaN(Number(auditId))) {
    return null;
  }

  return (
    <AuditPage
      title={"Audit Questions"}
      loadFindingTypes={true}
      pageControls={[{
        key: "EXPORT QUESTIONS",
        element: (
          <ExportAuditButton
            auditId={Number(auditId)}
          />
        ),
      }, {
        key: "FILTER",
        element: (
          <button
            className="secondary filter-questions"
            onClick={() => dispatch(openFilters())}
          >
            <img
              alt=""
              src={filterIcon}
              className="icon-small"
            />
            Filter Questions
          </button>
        ),
      }, {
        key: "VIDEO",
        element: (
          <VideoLinkButton
            links={[{
              title: "To execute an audit (until Status: Completed)",
              url: "https://slb001.sharepoint.com/sites/QoF-ScrumTeams/_layouts/15/stream.aspx?uniqueId=87f83a48-2298-53d1-c52d-6d3ad94f8cfd&portal=%7b%22ha%22:%22classicstream%22%2c%22hm%22:%22view%22%7d&referrer=StreamWebApp.Web&referrerScenario=AddressBarCopied.view&scenario=2&sw=auth",
              description: "This video provides step by step information on how a user can execute an audit",
            }]}
          />
        ),
      }]}
      auditId={auditId}
      overviewSettings={{
        showComplianceScore: true,
        showQuestionCounts: true,
      }}
    >
      {!questions.length
        ? (
          <Banner
            type={BannerType.error}
          >
            No questions are loaded.
          </Banner>
        ) : (
          <>
            <QuestionExecution />

            {isFilterModalOpen && <FilterModal />}

            <Prompt
              message="Any unsaved changes will be lost. You can save your changes by clicking Next or Previous Question. Are you sure you want to abandon your changes and leave?"
              isDirty={isCurrQuestionDirty === undefined
                ? false
                : isCurrQuestionDirty
              }
            />
          </>
        )}

      {saveWorkspaceOp?.isWorking && <ModalSpinner />}
    </AuditPage>
  );
};

export default AuditExecutionPage;
