import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback, useMemo } from "react";
import { IPickerItem } from "shared/types/pickerTypes";
import { IBusinessView, MetaDataTypes } from "types/masterDataTypes";
import NonReduxPickerList from "./non-redux-list-picker/NonReduxPickerList";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IBusinessViewPickerProps {
  renderMode?: PickerMode,
  codesToFilter?: string[] | undefined,
}

const BusinessViewPicker: React.FC<IBusinessViewPickerProps & ICommonPickerProps<IBusinessView>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  showSuggestions = false,
  onRenderPicker,
  isDisabled = false,
  userProfileMetaRestrictions,
  isItemDisabledMapper,
  codesToFilter,
  renderMode = "picker",
  showSelectedItems = true,
  hideUnselectableBranches = false,
}) => {
  const renderItem = useCallback((item: IBusinessView) => `${item.code} - ${item.name}`, []);

  const restrictedToItems = useMemo(() => userProfileMetaRestrictions
    ?.filter(x => x.type === MetaDataTypes.BusinessView) || [],
    [userProfileMetaRestrictions]);

  const loadItems = useCallback(async (searchTerm: string | undefined, abortSignal: AbortSignal) => {
    let businessViews = await MasterDataApi.getBusinessViews(searchTerm, undefined, abortSignal);

    if (codesToFilter?.length) {
      businessViews.forEach(element => {
        if (!codesToFilter.includes(element.code)) {
          let businessViewsToKeep: string[] = filterByCodes(codesToFilter, [], element.children);
          if (businessViewsToKeep.length === 0) {
            businessViews = businessViews.filter(x => x.id !== element.id);
          }

          element.children = element.children
            .filter(x => businessViewsToKeep.includes(x.code))
            .map(el => filterChildren(el, businessViewsToKeep));
        }
      });
    }
    return businessViews;
  }, [codesToFilter]);

  const isDisabledMapper = useCallback((item: IBusinessView, ancestorPath: IBusinessView[]) => {
    if (restrictedToItems.length
      && !restrictedToItems.some(x => x.id === item.id)) {
      return true;
    }

    return isItemDisabledMapper?.(item, ancestorPath) ?? false;
  }, [restrictedToItems, isItemDisabledMapper]);

  const pickerFilterItems = useCallback(<T,>(item: IPickerItem<T>, search: string) => {
    if (item?.item) {
      return renderItem(((item.item as unknown) as IBusinessView))
        .toLowerCase()
        .includes(search.toLowerCase());
    }
    return false;
  }, [renderItem]);

  if (renderMode === "picker") {
    return (
      <NonReduxPicker<IBusinessView>
        itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title="Business View (Divisions)"
        allowMultiSelect={allowMultiselect}
        displayMode="tree"
        renderListItem={renderItem}
        childrenMapper={item => item.children || []}
        onLoadSuggestedItems={async (abortSignal) => await MasterDataApi.getSuggestedBusinessViews(undefined, abortSignal)}
        showSuggestedItems={showSuggestions}
        searchOptions={{
          filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
        }}
        onRenderPicker={onRenderPicker}
        isDisabled={isDisabled}
        isDisabledMapper={isDisabledMapper}
      />
    );
  } else if (renderMode === 'list') {
    return (
      <NonReduxPickerList
        selectedItems={selectedItems}
        renderListItem={renderItem}
        onSelectionChanged={onApply}
        keyMapper={(item) => item.id}
        title="Business Views"
        onLoadItems={loadItems}
        allowMultiSelect={allowMultiselect}
        tagsProps={[{
          label: "Selected Business Views",
          renderItem: (item) => item.item ? renderItem(item.item) : item.text,
        }]}
        childMapper={(item) => item.children || []}
        isDisabledMapper={isDisabledMapper}
        isDisabled={isDisabled}
        showSuggestedItems={showSuggestions}
        showSelectedItems={showSelectedItems}
        hideUnselectableBranches={hideUnselectableBranches}
        filterItems={pickerFilterItems}
      />
    );
  } else {
    return <>Render mode "{renderMode}" is not supported.</>;
  }
};

function filterChildren(element: IBusinessView, businessViewsToKeep: string[] | undefined): any {
  if (businessViewsToKeep?.includes(element.code)) {
    if (element.children.length !== 0) {
      const filteredChildren: IBusinessView[] = element.children.map(child => filterChildren(child, businessViewsToKeep)).filter(x => x);
      return { ...element, children: filteredChildren };
    } else {
      return element;
    }
  }
}

function filterByCodes(codes: string[] | undefined, parentsCode: string[], childrenBusinessViews: IBusinessView[]): string[] {
  let businessViewsToKeep: string[] = [];

  childrenBusinessViews.forEach(element => {
    if (element.children.length !== 0) {
      parentsCode.push(element.code);
      const businessViewsFiltered = filterByCodes(codes, parentsCode, element.children);
      businessViewsFiltered.forEach(e => {
        businessViewsToKeep.push(e);
      });

      if (codes?.includes(element.code) || businessViewsFiltered.length !== 0) {
        businessViewsToKeep.push(element.code);
      }

      parentsCode = [];
    } else {
      if (codes?.includes(element.code) || codes?.some((item) => parentsCode.includes(item))) {
        businessViewsToKeep.push(element.code);
      }
    }
  });

  return businessViewsToKeep;
}

export default BusinessViewPicker;