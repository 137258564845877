import { useEffect } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import "./Dropdown.scoped.scss";

export interface IDropdownProps<TItem> {
  isLoading?: boolean,
  loadError?: string,
  items: TItem[],
  selectedItem?: TItem,
  onChange: (item: TItem | undefined) => void,
  keyMapper: (item: TItem) => string,
  textMapper: (item: TItem) => string,
  loadItems?: () => void,
  autoLoadItems?: boolean,
  isDisabled?: boolean,
  placeholder?: string,
}

const Dropdown = <TItem,>({
  isLoading,
  loadError,
  items,
  selectedItem,
  onChange,
  keyMapper,
  textMapper,
  loadItems,
  autoLoadItems,
  isDisabled,
  placeholder,
}: IDropdownProps<TItem>) => {
  useEffect(() => {
    if (autoLoadItems
      && loadItems) {
      loadItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Spinner
        className="small-spinner"
        hAlign="left"
      />
    );
  } else if (loadError) {
    return (
      <span
        className="error"
        title={loadError}
      >
        Load error.
        {loadItems !== undefined && (
          <button
            className="link"
            onClick={loadItems}
          >
            Retry
          </button>
        )}
      </span>
    );
  }

  return (
    <select
      onChange={(e) => onChange(!e.currentTarget.value
        ? undefined
        : items.find(x => keyMapper(x) === e.currentTarget.value))}
      value={selectedItem
        ? keyMapper(selectedItem)
        : undefined
      }
      disabled={isDisabled}
      className={!selectedItem
        ? "placeholder"
        : undefined
      }
    >
      <option
        value={undefined}
        className="placeholder"
      >
        {placeholder || "Select"}
      </option >
      {items.map(item => (
        <option
          key={keyMapper(item)}
          value={keyMapper(item)}
        >
          {textMapper(item)}
        </option>
      ))
      }
      {!items.length
        && selectedItem && (
          <option
            value={keyMapper(selectedItem)}
          >
            {textMapper(selectedItem)}
          </option>
        )}
    </select>
  );
};

export default Dropdown;