import OwnerGroupsApi from "api/auditing/OwnerGroupsApi";
import TemplatesApi from "api/auditing/templates/TemplatesApi";
import AuditTopicApi from "api/masterdata/AuditTopicApi";
import MasterDataApi from "api/masterdata/MasterDataApi";
import { all, call, CallEffect, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IOwnerGroup, ITemplate } from "types/auditingTypes";
import { IAuditGroup, IAuditTopic } from "types/auditMasterDataTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import { IAuditTemplatesFilters, IDetailedTemplate, IDetailedTemplateChildren, ITemplateChildrenRequest, ITemplateRequest } from "types/templateApiTypes";
import { deleteTemplate, finishDeleteTemplate, finishLoadPageData, finishSaveTemplate, loadPageData, saveTemplate } from "./AuditTemplatesSlice";

export function* auditTemplatesSagas() {
	yield all([
		loadPageDataAsync(),
		saveTemplateAsync(),
		deleteTemplateAsync(),
	]);
}

/** Loads all the templates and all the master data used in the page. */
function* loadPageDataAsync() {
	yield takeLatest(loadPageData, function* (action) {
		try {
			const calls: CallEffect<any>[] = [
				call(OwnerGroupsApi.getOwnerGroups),
				call(TemplatesApi.getDetailedTemplates, action.payload),
				call(AuditTopicApi.searchAuditTopics),
				call(TemplatesApi.getTemplates),
				call(MasterDataApi.getAuditGroups),
			];

			const callResultsArr: any[] = yield all(calls);

			yield put(finishLoadPageData({
				isWorking: false,
				data: {
					masterData: {
						auditGroups: callResultsArr[4] as IAuditGroup[],
						ownerGroups: callResultsArr[0] as IOwnerGroup[],
					},
					templates: callResultsArr[1] as IDetailedTemplate[],
					auditTopics: callResultsArr[2] as IAuditTopic[],
					auditTemplate: callResultsArr[3] as ITemplate[]
				},
			}));
		} catch (err) {
			yield put(showErrorToast(getResponseErrorMessage(err)));
			yield put(finishLoadPageData({
				isWorking: false,
				errorMessage: getResponseErrorMessage(err),
			}));
		}
	});
}

/** Saves the template being created/edited. */
function* saveTemplateAsync() {
	yield takeEvery(saveTemplate, function* (action) {
		try {
			const {
				template,
				selectedTemplates,
				selectedTopics,
			} = action.payload;

			let request: ITemplateRequest = {
				id: template?.id,
				name: template?.name as string,
				auditGroupId: template?.auditGroupId as number,
				ownerGroupId: template?.ownerGroupId as number,
				plannable: template?.isPlannable as boolean,
				children: addChildren(selectedTemplates, selectedTopics),
			};

			if (template?.id) {
				yield call(TemplatesApi.updateTemplate, request)
			} else {
				yield call(TemplatesApi.createTemplate, request)
			}

			// Finish the save operation.
			yield put(finishSaveTemplate(true));

			const filters: IAuditTemplatesFilters = yield select((store: RootState) => store.auditTemplates.filters);
			yield put(loadPageData(filters));

		} catch (err) {
			yield put(showErrorToast(getResponseErrorMessage(err)));
			yield put(finishSaveTemplate(false));
		}
	});
}

/** Delete the template. */
function* deleteTemplateAsync() {
	yield takeLatest(deleteTemplate, function* () {
		try {
			const templateId: number | undefined = yield select((store: RootState) => store.auditTemplates.template?.id);
			if (!templateId) {
				// There is no template or no template id currently loaded!
				throw new Error("The current template (if any) cannot be deleted.");
			}

			yield call(TemplatesApi.deleteTemplate,
				templateId)

			// Finish the delete operation.
			yield put(finishDeleteTemplate());

			const filters: IAuditTemplatesFilters = yield select((store: RootState) => store.auditTemplates.filters);
			yield put(loadPageData(filters));
		} catch (err) {
			yield put(showErrorToast(getResponseErrorMessage(err)));
			yield put(finishDeleteTemplate());
		}
	});
}

function addChildren(templates: (IDetailedTemplate | IDetailedTemplateChildren)[], topics: IAuditTopic[]): ITemplateChildrenRequest[] {
	let children: ITemplateChildrenRequest[] = [];

	templates.forEach(x => {
		children.push({
			id: x.id,
			type: MetaDataTypes.AuditTemplate,
			isRequired: x.isRequired as boolean,
		})
	});

	topics.forEach(x => {
		children.push({
			id: x.id,
			type: MetaDataTypes.AuditTopic,
			isRequired: true,
		})
	});

	return children;
}