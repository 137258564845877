const month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default function formatDate(date: Date | undefined,
  showTime: boolean = false,
  showDate: boolean = true,
  monthFormat: 'long' | 'short' = 'short',
  formatUTC: boolean = false): string {

  if (!date) {
    return "";
  }

  let day = formatUTC ? date.getUTCDate() : date.getDate();
  let month = formatUTC ? date.getUTCMonth() : date.getMonth();
  let year = formatUTC ? date.getUTCFullYear() : date.getFullYear();
  let hour = formatUTC ? date.getUTCHours() : date.getHours();
  let minute = formatUTC ? date.getUTCMinutes() : date.getMinutes();

  let monthString = monthFormat === "long"
    ? month_names[month]
    : month_names_short[month];

  let tt = hour >= 12
    ? "PM"
    : "AM";

  if (hour > 12) {
    hour -= 12;
  }

  let dateStr = '';
  if (showDate) {
    dateStr += date ? `${day.toString().padStart(2, '0')}-${monthString}-${year}` : '';
  }
  if (showTime) {
    dateStr += date ? ` ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${tt}` : '';
  }

  return dateStr;
}

export function formatTimestamp(timestamp: number | undefined,
  showTime: boolean = false,
  showDate: boolean = true,
  monthFormat: 'long' | 'short' = 'short'): string {
  return formatDate(timestamp === undefined
    ? undefined
    : new Date(timestamp),
    showTime,
    showDate,
    monthFormat);
}

export function getDateDiff(endDate: Date | undefined,
  startDate: Date | undefined): string {
  if (endDate && startDate) {
    let msec = endDate.getTime() - startDate.getTime();
    let mins = Math.floor(msec / 60000);
    let hrs = Math.floor(mins / 60);
    mins = mins % 60;

    if (hrs > 0) {
      return `${hrs} hour${hrs === 1 ? "" : "s"} ${mins} minute${mins === 1 ? "" : "s"}`;
    } else {
      return `${mins} minute${mins === 1 ? "" : "s"}`;
    }
  } else if (endDate) {
    return "0 minutes";
  }

  return "";
}

/**
 * Parses a date string from an api into a Date object.
 * @param dateString The string to parse.
 * @param assumeUTCIfUnknown If no timezone information is included, should the date be assumed to be UTC? Default = true.
 */
export function parseStringToDate(dateString: string, assumeUTCIfUnknown: boolean = true) {
  if (dateString.indexOf("T")
    && dateString.split("T")[1].search(/[a-z]/i) === -1
    && assumeUTCIfUnknown) {
    return new Date(dateString + "Z");
  } else if (dateString.search(/[a-z]/i) === -1
    && assumeUTCIfUnknown) {
    return new Date(dateString + "Z");
  } else {
    return new Date(dateString);
  }
}

export function getTodayMidnight() {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export function getDateTime(date: any): number | undefined {
  return date
    ? new Date(date).getTime()
    : undefined;
}