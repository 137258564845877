import AuditEvidencePage from "components/audits/evidences/AuditEvidencePage";
import AuditExecutionPage from "components/audits/execution/AuditExecutionPage";
import AuditFindingsPage from "components/audits/findings/AuditFindingsPage";
import ManageAuditPage from "components/audits/manage/ManageAuditPage";
import MyAudits from "components/audits/mine/MyAuditsPage";
import AuditResultsNonConformancesPage from "components/audits/nonconformances/AuditResultsNonConformancesPage";
import AuditNonConformancesPage from "components/audits/nonconformances/AuditNonConformancesPage";
import AuditReportPage from "components/audits/report/AuditReportPage";
import AuditRWPPage from "components/audits/rwp/AuditRWPPage";
import React from "react";
import { Link, Route, useParams } from "react-router-dom";
import { AuditPages, PageAuthRequestType } from "types/auditPageAuthTypes";
import { IRouteRoleMap } from "types/authTypes";
import { useQuery } from "../../../shared/utilities/routingUtilities";
import AuditSummaryPage from "../../audits/summary/AuditSummaryPage";
import AuditPageAuth from "../AuditPageAuth";
import RequireAuth from "../RequireAuth";
import UrlRoutes, { formatRoute } from "../UrlRoutes";

interface IAuditRoutesProps {
  routeMatrix: IRouteRoleMap[],
}
const AuditRoutes: React.FC<IAuditRoutesProps> = ({ routeMatrix }) =>
  <>
    <Route
      path={UrlRoutes.MyAudits.urlTemplate}
      element={
        <RequireAuth
          roles={routeMatrix.find(x => x.route === UrlRoutes.MyAudits.urlTemplate)?.requiredRoles}
        >
          <MyAudits />
        </RequireAuth>
      }
    />

    <Route
      path={UrlRoutes.NewAudit.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.audit}
          pageName={AuditPages.CreateAuditPage}
        >
          <ManageAuditPage
            mode="new"
          />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.EditAudit.urlTemplate}
      element={
        <AuditPageAuth
          type={PageAuthRequestType.audit}
          pageName={AuditPages.EditAuditPage}
        >
          <ManageAuditPage
            mode="edit"
          />
        </AuditPageAuth>
      }
    />

    <Route
      path={UrlRoutes.AuditSummary.urlTemplate}
      element={
        <AuditRoute
          page={AuditPages.AuditDetailsPage}
          allowAutoRedirect={true}
        >
          <AuditSummaryPage />
        </AuditRoute>
      }
    />

    {[UrlRoutes.AuditExecuteAll,
    UrlRoutes.AuditExecuteQuestion]
      .map((route) => {
        return (
          <Route path={route.urlTemplate}
            element={
              <AuditRoute
                page={AuditPages.AuditQuestionsPage}
              >
                <AuditExecutionPage />
              </AuditRoute>
            }
            key={route.label}
          />
        );
      })}

    <Route
      path={UrlRoutes.AuditEvidence.urlTemplate}
      element={
        <AuditRoute
          page={AuditPages.AuditEvidencesPage}
        >
          <AuditEvidencePage />
        </AuditRoute>
      }
    />

    {[UrlRoutes.AuditFindings,
    UrlRoutes.AuditFindingsShowFinding]
      .map((route) => {
        return (
          <Route
            path={route.urlTemplate}
            element={
              <AuditRoute
                page={AuditPages.AuditFindingsPage}
              >
                <AuditFindingsPage />
              </AuditRoute>
            }
            key={route.label}
          />
        );
      })}

    <Route
      path={UrlRoutes.AuditRWP.urlTemplate}
      element={
        <AuditRoute
          page={AuditPages.AuditRWPPage}
        >
          <AuditRWPPage />
        </AuditRoute>
      }
    />

    <Route
      path={UrlRoutes.AuditReport.urlTemplate}
      element={
        <AuditRoute
          page={AuditPages.AuditReportsPage}
        >
          <AuditReportPage />
        </AuditRoute>
      }
    />

    <Route
      path={UrlRoutes.AuditResult.urlTemplate}
      element={
        <AuditRoute
          page={AuditPages.AuditResultPage}
        >
          <AuditResultsNonConformancesPage />
        </AuditRoute>
      } />

<Route
      path={UrlRoutes.AuditNonConformance.urlTemplate}
      element={
        <AuditRoute
          page={AuditPages.NonConformancesPage}
        >
          <AuditNonConformancesPage />
        </AuditRoute>
      } />
  </>;

export default AuditRoutes;

const AuditRoute: React.FC<{
  page: AuditPages,
  allowAutoRedirect?: boolean,
  children: React.ReactNode,
}> = ({
  page,
  allowAutoRedirect,
  children,
}) => {
    const auditId = useParams()?.auditId;
    const autoRedirect = useQuery()?.get("noredirect") !== "true"
      && allowAutoRedirect;

    return (
      <AuditPageAuth
        type={PageAuthRequestType.audit}
        pageName={page}
        navigateTo={autoRedirect
          ? UrlRoutes.EditAudit.urlTemplate
          : undefined
        }
        customNoPermissionDisplay={
          <Link
            to={formatRoute(UrlRoutes.EditAudit, { "auditId": auditId || "" })}
          >
            Click here to view the Audit details.
          </Link>
        }
      >
        {children}
      </AuditPageAuth>
    );
  };
