import React, { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import Page from "shared/components/layout/page/Page";
import { loadPageData } from "store/question-details/QuestionDetailsSlice";
import { useAppSelector } from "store/store";

interface IQuestionLoaderProps {
  children: ReactNode,
}

/** Checks the URL parameters for "id" and then loads the question details or clears the redux state. */
const QuestionLoader: React.FC<IQuestionLoaderProps> = ({ children }) => {
  const loadOp = useAppSelector(store => store.questionDetails.loadOp);
  const dispatch = useDispatch();

  const {
    id: urlId,
  } = useParams();

  useEffect(() => {
    const numId = Number(urlId);

    dispatch(loadPageData(!isNaN(numId)
      && numId
      ? numId
      : undefined));
  }, [urlId, dispatch]);

  if (loadOp?.isWorking) {
    return (
      <Page>
        <Spinner />
      </Page>
    )
  } else if (loadOp?.errorMessage) {
    return (
      <Page>
        <Banner
          type={BannerType.error}
        >
          {loadOp.errorMessage}
        </Banner>
      </Page>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default QuestionLoader;