import Banner, { BannerType } from "shared/components/common/banner/Banner";
import { useAppSelector } from "store/store";
import "./QuestionExecution.scoped.scss";
import AnswerCard from "./answer-card/AnswerCard";
import EvidenceCard from "./evidence-card/EvidenceCard";
import GuidanceCard from "./guidance-card/GuidanceCard";
import RwpCard from "./rwp-card/RwpCard";

const QuestionExecution: React.FC = () => {
  const queueAQIds = useAppSelector(store => store.auditExecution.queueAQIds);
  const currQueueIndex = useAppSelector(store => store.auditExecution.currQueueIndex);
  const questions = useAppSelector(store => store.audit.questions);
  const auditStatus = useAppSelector(store => store.audit.audit?.status);
  const auditTemplate = useAppSelector(store => store.audit.audit?.templates);
  const audit = useAppSelector(store => store.audit.audit);

  let question = currQueueIndex > -1
    && currQueueIndex < queueAQIds.length
    && queueAQIds.length
    ? questions
      .find(q => q.auditQuestionId === queueAQIds[currQueueIndex])
    : undefined;

  if (!question
    || currQueueIndex === -1
    || !queueAQIds.length) {
    return (
      <Banner
        type={BannerType.info}
      >
        There are no questions found with the current filters. Use the Filter Questions button to find some.
      </Banner>
    );
  }

  return (
    <div className="container question-execution">
      {!auditStatus
        ? (
          <Banner
            type={BannerType.error}
          >
            There is no audit data loaded.
          </Banner>
        ) : audit && (
          <>

            <div className="answer">
              <AnswerCard
                question={question}
                auditStatus={auditStatus}
              />
            </div>

            <div className="evidence">
              <EvidenceCard
                question={question}
                auditId={audit.id}
                auditStatus={auditStatus}
              />
            </div>

            <div className="rwp">
              <RwpCard
                question={question}
              />
            </div>

            <div className="guidance">
              <GuidanceCard
                question={question}
                auditStatus={auditStatus}
                auditTemplate={auditTemplate}
              />
            </div>
          </>
        )}
    </div>
  );
};

export default QuestionExecution;