export function linkFormatter(link: string): string {
  if (!link.includes("://") && isValidUrl(`https://${link}`)) {
    return `https://${link}`;
  }
  return link;
}

export function isValidUrl(urlString: string) {
  try {
    new URL(urlString); // Try to create a URL object
    return true; // If no error occurs, the URL is valid
  } catch (e) {
    return false; // If an error occurs, the URL is invalid
  }
}