import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import { IAzureADUser } from "shared/types/userProfileTypes";
import AuditPlanCalendarStatusFilterOptions from "types/audit-plan-calendar/AuditPlanCalendarStatusFilterOptions";
import { IAuditType, IPerspective, IPerspectiveXAxes } from "types/auditMasterDataTypes";
import { ComplianceResults } from "types/auditPlanningTypes";
import { IBusinessTeam, IBusinessView, IFacility } from "types/masterDataTypes";
import { IAuditPlanningState } from "../AuditPlanningSlice";

export interface IPlanningFiltersState {
  currentFilters: IAuditPlanningFilters,
  appliedFilters?: IAuditPlanningAppliedFilters,
  areFiltersDirty: boolean,
}

export interface IAuditPlanningFilters {
  perspectives: IPerspective[],
  perspectiveXAxes: IPerspectiveXAxes[],
  businessTeams: IBusinessTeam[],
  facilities: IFacility[],
  businessViews: IBusinessView[],
  reviewStatus: PlanningReviewStatusFilterValues | undefined,
  planStatus: PlanningPlanStatusFilterValues | undefined,
  reviewedBy: IAzureADUser[],
  includeDeletedAttributes: boolean,
  planYear: number,
  auditType: IAuditType | undefined,
  leadAuditors: IAzureADUser[],
  calendarAuditTypes: IAuditType[],
  auditCalendarStatuses: AuditPlanCalendarStatusFilterOptions[],
  complianceResults: ComplianceResults[],
  unassigned: boolean,
}

export type IAuditPlanningAppliedFilters = IAuditPlanningFilters & {
  auditType: IAuditType,
};

export enum PlanningReviewStatusFilterValues {
  Reviewed = "Reviewed",
  NotReviewed = "Not Reviewed",
  ReviewedByMe = "Reviewed (By Me)",
}

export enum PlanningPlanStatusFilterValues {
  Recommended = "Recommended",
  FinalApproval = "FinalApproval",
  Removed = "Removed",
}

export const initialPlanningFiltersState: IPlanningFiltersState = {
  currentFilters: {
    perspectives: [],
    perspectiveXAxes: [],
    businessTeams: [],
    facilities: [],
    businessViews: [],
    reviewStatus: undefined,
    planStatus: undefined,
    reviewedBy: [],
    includeDeletedAttributes: false,
    leadAuditors: [],
    planYear: getDefaultPlanningYear(),
    auditType: undefined,
    calendarAuditTypes: [],
    auditCalendarStatuses: [],
    complianceResults: [],
    unassigned: false,
  },
  appliedFilters: undefined,
  areFiltersDirty: false,
};

export const planningFiltersReducers = {
  /** Override the setSelectedPickerItems to handle clearing review status. */
  setCurrentFilters: (state: IPlanningFiltersState, action: PayloadAction<Partial<IAuditPlanningFilters>>) => {
    Object.assign(state.currentFilters, action.payload);

    if ('reviewStatus' in action.payload
      && action.payload.reviewStatus !== PlanningReviewStatusFilterValues.Reviewed) {
      // If the user sets the Review Status to anything other than "Reviewed",
      // clear the Reviewed By filter selection.
      state.currentFilters.reviewedBy = [];
    }

    computeAreFiltersDirty(state);
  },

  /** Overwrites the `appliedFilters` in the state and triggers no sagas. */
  overrideAppliedFilters: (state: IPlanningFiltersState, action: PayloadAction<IAuditPlanningAppliedFilters>) => {
    state.appliedFilters = cloneDeep(action.payload);
  },

  /** Applies the currently selected filters. A saga handles all operations. */
  applyFilters: () => { },

  /** Clears the current and applied filters. */
  clearFilters: (state: IAuditPlanningState) => {
    state.currentFilters = cloneDeep(initialPlanningFiltersState.currentFilters);
    state.appliedFilters = undefined;
    state.hasDataEverLoaded = false;

    computeAreFiltersDirty(state);
  },
};

export function computeAreFiltersDirty(state: Draft<IPlanningFiltersState>) {
  state.areFiltersDirty = !isEqual(state.currentFilters, state.appliedFilters);
}

/** Gets the currently selected planning year. */
export function getDefaultPlanningYear() {
  let year = (new Date()).getFullYear();
  return year;
}