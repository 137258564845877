import SecurityApi from "api/security/SecurityApi";
import { Action } from "redux";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { IProfile, IUserProfile } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { showErrorToast, showSuccessToast } from "../toast/ToastSlice";
import { finishLoadUserProfile, finishSwitchProfile, loadUserProfile, switchProfile } from "./AuthSlice";


export default function* authSagas() {
  yield all([
    loadUserProfileAsync(),
    switchProfileAsync(),
  ]);
}

function* loadUserProfileAsync() {
  yield takeLatest(loadUserProfile, function* (action: Action) {
    if (!loadUserProfile.match(action)) {
      return;
    }

    try {
      const userProfile: IUserProfile = yield call(SecurityApi.getUserProfile);
      yield put(finishLoadUserProfile({
        isWorking: false,
        data: userProfile,
      }));
    } catch (err) {
      // Get error message.
      yield put(finishLoadUserProfile({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* switchProfileAsync() {
  yield takeLatest(switchProfile, function* (action: Action) {
    if (!switchProfile.match(action)) {
      return;
    }

    try {
      const authState: IUserProfile | undefined = yield select((store: RootState) => store.auth.userProfile);

      if (!authState) {
        throw new Error("No activeUserProfile is loaded.");
      }

      const activatedUserProfile: IProfile = yield call(SecurityApi.setActiveUserProfile,
        {
          activeProfile: {
            profileId: action.payload.profileId,
            moduleName: action.payload.moduleName
          }
        });
      yield put(finishSwitchProfile({
        isWorking: false,
        data: activatedUserProfile,
      }))
      yield put(showSuccessToast("Profile saved successfully."));
    } catch (err) {
      let errorMessage = `Failed to change user Profile: ${getResponseErrorMessage(err)}`;
      yield put(showErrorToast(errorMessage));
      // Get error message.
      yield put(finishSwitchProfile({
        isWorking: false,
      }));
    }
  });
}

