import React from "react";
import Button from "shared/components/controls/buttons/button/Button";
import { applyFilters } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const ApplyFiltersButton: React.FC = () => {
  const areFiltersDirty = useAppSelector(store => store.auditPlanning.areFiltersDirty);
  const currentFilters = useAppSelector(store => store.auditPlanning.currentFilters);
  const dispatch = useAppDispatch();
  
  return (
    <Button
      buttonType={areFiltersDirty ? "primary" : "secondary"}
      onClick={() => dispatch(applyFilters())}
      isDisabled={!currentFilters.perspectives.length
        || !currentFilters.perspectiveXAxes.length
        || !currentFilters.businessTeams.length
      }
    >
      Apply Filters
    </Button>
  );
};

export default ApplyFiltersButton;