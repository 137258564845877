import Routing from 'components/routing/Routing';
import UrlRoutes from 'components/routing/UrlRoutes';
import config from "config";
import React from 'react';
import ToastContainer from 'shared/components/common/toasts/ToastContainer';
import MainLayout from 'shared/components/layout/MainLayout';
import addIcon from "shared/media/dls/add.svg";
import calendarIcon from "shared/media/dls/calendar.svg";
import checklistIcon from "shared/media/dls/checklist.svg";
import dotIcon from "shared/media/dls/oil-bitumen.svg";
import search from "shared/media/dls/search.svg";
import adminIcon from "shared/media/icons/admin.svg";
import graph from "shared/media/icons/graph.svg";
import { IActiveUserProfile, RoleTypes } from 'shared/types/userProfileTypes';
import { setIsOpen } from 'store/main-menu/MainMenuSlice';
import {
  openConfirmCreateManualAuditModal
} from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from 'store/store';

const AuditLayout: React.FC = () => {
  const { activeUserProfile, } = useAppSelector(store => store.auth);
  const dispatch = useAppDispatch();
  const menuItems = getMainMenuItems(activeUserProfile);

  const createAuditMenuItem = menuItems.find(a => a.subItems?.some(z => z.key === "Create Audit"))
    ?.subItems
    .find(x => x.key === "Create Audit")

  if (createAuditMenuItem) {
    createAuditMenuItem.onClick = () => {
      dispatch(openConfirmCreateManualAuditModal());
      return null;
    };
  }

  return (
    <MainLayout
      siteTitle="Audit"
      siteTitleUrl={UrlRoutes.MyAudits.urlTemplate}
      menuItems={menuItems}
      appInfo={{
        buildNumber: config?.appInfo?.buildNumber,
        env: config?.appInfo?.env,
      }}
      prodUrl={config.prodUrl}
      onMainMenuIsOpenChanged={isOpen => dispatch(setIsOpen(isOpen))}
    >
      <ToastContainer
        reduxReducerName="toast"
      />
      <Routing />
    </MainLayout>
  );
};

export default AuditLayout;

function getMainMenuItems(activeUserProfile: IActiveUserProfile) {
  let auditPlanningItems = [{
    key: UrlRoutes.FacilityAttributes.urlTemplate,
    label: UrlRoutes.FacilityAttributes.label,
    route: UrlRoutes.FacilityAttributes.urlTemplate,
    icon: dotIcon,
  }, {
    key: "Plan Approvals",
    label: "Plan Approvals",
    route: UrlRoutes.PlanApprovals.urlTemplate,
    icon: dotIcon,
  }, {
    key: "Plan vs Results",
    label: "Plan vs Results",
    route: UrlRoutes.PlanVsResults.urlTemplate,
    icon: dotIcon,
  }, {
    key: "Calendar",
    label: "Calendar",
    route: UrlRoutes.PlanCalendar.urlTemplate,
    icon: dotIcon,
  }];

  // removed links if the user is Default User
  if (activeUserProfile.roleName === RoleTypes.DefaultUser) {
    const removedKeys = ["Plan Approvals"];
    auditPlanningItems = auditPlanningItems.filter(item => !removedKeys.includes(item.key));
  }

  let auditMenuItems = [{
    key: "Audit",
    label: "Audit",
    route: UrlRoutes.Root.urlTemplate,
    icon: checklistIcon,
    toggleOnClick: true,
    subItems: [{
      key: "Create Audit",
      label: "Create Audit",
      route: "#",
      icon: addIcon,
      toggleOnClick: true,
    }, {
      key: "My Audits",
      label: "My Audits",
      route: UrlRoutes.MyAudits.urlTemplate,
      icon: calendarIcon,
    }, {
      key: "My Actions",
      label: "My Actions",
      route: UrlRoutes.MyActions.urlTemplate,
      icon: checklistIcon,
    }, {
      key: "Search",
      label: "Search",
      route: UrlRoutes.Search.urlTemplate,
      icon: search,
    }, {
      key: "Planning",
      label: "Planning",
      route: UrlRoutes.PlanApprovals.urlTemplate,
      icon: checklistIcon,
      toggleOnClick: true,
      subItems: auditPlanningItems,
    }, {
      key: UrlRoutes.AuditAdmin.label,
      label: UrlRoutes.AuditAdmin.label,
      route: UrlRoutes.AuditAdmin.urlTemplate,
      icon: adminIcon,
    }, {
      key: "PowerBi Report",
      label: "PowerBi Report",
      route: "",
      icon: graph,
      onClick: () => window.open(config.powerBiUrl),
    },],
  }];

  return auditMenuItems;
}
