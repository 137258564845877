import { FlexGrid as wjFlexGrid } from "@mescius/wijmo.grid";
import { authGetJson } from "auth/authFetches";
import TagsRecap from "components/audits/manage/tags-recap/TagsRecap";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import DataGrid from 'shared/components/layout/grid/DataGrid';
import { IDataGridColumn, IDataGridFilterSettings, IDataGridSortSettings } from 'shared/components/layout/grid/types/dataGridTypes';
import Modal from "shared/components/layout/modal/Modal";
import { IOperation } from "shared/types/operationTypes";
import { IPickerItem } from "shared/types/pickerTypes";
import { getResponseErrorMessage, throwIfResponseError } from "shared/utilities/apiUtilities";
import { mediumScreenMaxWidth, useWindowSize } from "shared/utilities/windowUtilities";
import LabeledControl from "../../labeled-control/LabeledControl";
import PickerList from "../../picker/list/PickerList";
import "../../picker/modal/PickerModal.scoped.scss";
import "./GridPickerModal.scoped.scss";

interface IGridPickerModalProps<ModelType> {
  /** The function to call when the user closes, clears, or applies. */
  onClose(): void,
  /** The function to call when the user has finished choosing items. */
  onApply(selectedItems: ModelType[]): void,
  /**
   * Receives one of the picker items and must return its renderable node for display in the selected list when the modal is not open.
   * If this is not provided, the "text" property of the IPickerItem will be rendered instead.
   */
  renderSelectedItem: (item: ModelType) => string,
  /** A function that takes an item and returns its unique key. */
  keyMapper: (item: ModelType) => number | string,
  /**
   * If provided, this sorting function will be used to sort items when they appear in a list. (e.g. the selected items)
   */
  sortItems?: (item1: ModelType, item2: ModelType) => number,
  /** The title text to show in the modal. */
  title: string,
  /** The subTitle text to show above the search box. */
  subTitle?: React.ReactNode,
  /** The list of already-selected items. */
  selectedItems: ModelType[],
  /**
   * Determines the source of data. If "OData", "dataUrl" is required.
   * If "array", "dataArray" is required.
   **/
  dataSource: "OData" | "array",
  /** The url of an oData endpoint. */
  dataUrl?: string,
  /** The local data source for the grid. */
  dataArray?: ModelType[],
  /** Specifies the grid column properties. */
  gridColumns?: IDataGridColumn[],
  /** The selection mode (single or multiple). */
  selectMode: "Single" | "Multiple",
  /** Optional. Specifies the grid column properties. If not provided, all columns will be shown with raw data and default widths. */
  suggestedItemsUrl?: string,
  /** Optional. Called for each item returned from the suggestedItemsUrl. If not provided, the suggested items will be directly cast into the ModelType. */
  suggestedItemFormatter?(obj: any): ModelType,
  /**
   * Optional. Filter settings for the sync fusion grid. By default, the following settings are applied. Any settings provided
   * via this property will be merged into the below default filter settings.
   * ```
    {
      mode: "Immediate",
      immediateModeDelay: 1000,
      type: 'Menu',
    }
    ```
  */
  filterSettings?: IDataGridFilterSettings,
  /** Optional. Sort settings for the sync fusion grid. */
  sortSettings?: IDataGridSortSettings,
}

const GridPickerModal = <ModelType,>(props: IGridPickerModalProps<ModelType>) => {
  const {
    onClose,
    onApply,
    renderSelectedItem,
    keyMapper,
    sortItems,
    selectedItems,
    dataSource,
    dataUrl,
    dataArray,
    selectMode,
    title,
    subTitle,
    gridColumns,
    suggestedItemsUrl,
    suggestedItemFormatter,
    filterSettings,
    sortSettings,
  } = props;
  const [localSelectedItems, setLocalSelectedItems] = useState(selectedItems.slice());
  const [suggestions, setSuggestions] = useState<IOperation<ModelType[]> | undefined>();
  const windowSize = useWindowSize();
  const gridRef = useRef<wjFlexGrid>(null);

  const localSelectedKeys = localSelectedItems.map(x => keyMapper(x));

  /** Ref holding the same data as localSelectedKeys in the state. Used for the Wijmo grid event handlers. */
  const localSelectedKeysRef = useRef<(string | number)[]>(localSelectedKeys);

  useEffect(() => {
    // Update the ref whenever the state changes. Used for the Wijmo grid event handlers.
    localSelectedKeysRef.current = localSelectedKeys;
  }, [localSelectedKeys]);

  useEffect(() => {
    if (!suggestedItemsUrl) {
      setSuggestions(undefined);
      return;
    }

    let aborted = false;
    let abortController = new AbortController();

    const getSuggestions = async () => {
      if (aborted) { return; }

      setSuggestions({
        isWorking: true,
      });

      try {
        if (aborted) { return; }

        const response = await authGetJson(suggestedItemsUrl, abortController.signal);

        if (aborted) { return; }

        await throwIfResponseError(response);

        if (aborted) { return; }

        const json = await response.json();

        if (aborted) { return; }

        if (suggestedItemFormatter) {
          setSuggestions({
            isWorking: false,
            data: json.map((x: any) => suggestedItemFormatter(x)),
          });
        } else {
          setSuggestions({
            isWorking: false,
            data: json as ModelType[],
          });
        }
      } catch (err) {
        if (aborted) { return; }

        setSuggestions({
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        });
      }
    };

    getSuggestions();

    return () => {
      aborted = true;
      abortController.abort();
    };
  }, [setSuggestions, suggestedItemsUrl, suggestedItemFormatter]);

  const refreshGridSelections = useCallback((selectedItems: ModelType[]) => {
    if (!gridRef?.current?.rows) {
      return;
    }

    const selectedKeys = selectedItems.map(x => keyMapper(x).toString());

    gridRef.current.rows.forEach(row => {
      if (selectedKeys.includes(keyMapper(row.dataItem).toString())) {
        // It is selected. Ensure it has the classname.
        if (!row.cssClass?.includes("picker_grid_row_selected")) {
          row.cssClass = (row.cssClass ?? "") + " picker_grid_row_selected";
        }
      } else {
        // It is not selected. Remove the classname.
        row.cssClass = (row.cssClass ?? "").replace(" picker_grid_row_selected", "");
      }
    });
  }, [keyMapper]);

  const selectItem = useCallback((item: ModelType) => {
    let newSelections =
      selectMode === "Single"
        ? [item]
        : [...localSelectedItems, item];

    setLocalSelectedItems(newSelections);
    refreshGridSelections(newSelections);
  }, [setLocalSelectedItems, localSelectedItems, refreshGridSelections, selectMode]);

  const deSelectItem = useCallback((item: ModelType) => {
    const newSelections = localSelectedItems
      .filter(x => keyMapper(x) !== keyMapper(item));

    setLocalSelectedItems(newSelections);
    refreshGridSelections(newSelections);
  }, [keyMapper, localSelectedItems, setLocalSelectedItems, refreshGridSelections]);

  let suggestionsComponent: React.ReactNode = null;

  if (suggestions?.isWorking) {
    suggestionsComponent = <Spinner />;
  } else if (suggestions?.errorMessage) {
    suggestionsComponent = (
      <Banner
        type={BannerType.error}
      >
        {suggestions.errorMessage}
      </Banner>
    );
  } else if (suggestions?.data) {
    const suggestionPickerItems = (suggestions.data || []).map(x => modelToPickerItem(x, keyMapper));
    suggestionsComponent = (
      <PickerList
        items={suggestionPickerItems}
        renderItem={item => renderSelectedItem(item)}
        allowMultiSelect={selectMode === "Multiple"}
        noItemsMessage={"No suggestions available."}
        selectedItems={localSelectedItems.map(x => modelToPickerItem(x, keyMapper))}
        displayMode={"list"}
        onItemSelected={item => selectItem(item.item as ModelType)}
        onItemDeselected={item => deSelectItem(item.item as ModelType)}
      />
    );
  }

  const onLoadedRows = useCallback(() => {
    if (!gridRef.current) {
      return;
    }

    gridRef.current.rows.forEach(row => {
      const rowKey = keyMapper(row.dataItem);
      const isSelected = localSelectedKeysRef.current.includes(rowKey);

      if (isSelected) {
        // It is selected. Ensure it has the classname.
        if (!row.cssClass?.includes("picker_grid_row_selected")) {
          row.cssClass = (row.cssClass ?? "") + " picker_grid_row_selected";
        }
      } else {
        // It is not selected. Remove the classname.
        row.cssClass = (row.cssClass ?? "").replace(" picker_grid_row_selected", "");
      }
    });
  }, [keyMapper]);

  const onRowClick = useCallback((rowData: any) => {
    if (rowData
      && !Array.isArray(rowData)) {

      if (localSelectedKeys.includes(keyMapper(rowData as ModelType))) {
        deSelectItem(rowData as ModelType);
      } else {
        selectItem(rowData as ModelType);
      }
    }
  }, [localSelectedKeys, deSelectItem, selectItem, keyMapper]);

  const gridDataArray = useMemo(() => (dataArray ?? []) as Object[], [dataArray]);

  return (
    <Modal
      isOpen={true}
      className="__picker-modal"
      showCloseButton={true}
      onCloseButtonClicked={onClose}
      header={
        <>
          <div
            className="picker-modal-title"
          >
            {title}
          </div>

          {!!subTitle && (
            <div
              className="picker-modal-subtitle"
            >
              {subTitle}
            </div>
          )}
        </>
      }
      buttons={[
        {
          className: "secondary",
          key: "CLEAR",
          text: "Clear",
          onClick: () => onApply([]),
        },
        {
          className: "secondary",
          key: "CANCEL",
          text: "Cancel",
          onClick: onClose,
        },
        {
          className: "primary",
          key: "APPLY",
          text: "Apply",
          onClick: () => onApply(localSelectedItems),
        },
      ]}
    >
      <FlexRow
        className="lists-row"
      >
        <FlexCol
          className="available-items-col"
        >
          <DataGrid
            id={"GridPicker"}
            ref={gridRef}
            height={(windowSize.width || 0) > mediumScreenMaxWidth
              ? "100%"
              : 450}
            filterSettings={filterSettings}
            sortSettings={sortSettings}
            dataSource={dataSource}
            dataArray={gridDataArray}
            dataUrl={dataUrl}
            gridColumns={gridColumns ?? []}
            recordClick={onRowClick}
            onLoadedRows={onLoadedRows}
          />
        </FlexCol>

        <div className="divider"></div>

        <FlexCol
          className="selected-suggested-col"
        >
          <LabeledControl
            label="Selected Items"
            className="color-black"
          >
            {localSelectedItems.length > 0
              ? (
                <TagsRecap
                  items={localSelectedItems.map(x => modelToPickerItem(x, keyMapper))}
                  nameMapper={item => renderSelectedItem(item.item as ModelType)}
                  onTagClick={removedItem => {
                    deSelectItem(removedItem.item as ModelType);
                  }}
                  itemSorter={sortItems
                    ? (a, b) => sortItems(a.item as ModelType, b.item as ModelType)
                    : undefined
                  }
                />
              ) : "none"
            }
          </LabeledControl>

          {!!suggestionsComponent && (
            <LabeledControl
              label="Suggested Items"
            >
              {suggestionsComponent}
            </LabeledControl>
          )}
        </FlexCol>
      </FlexRow>
    </Modal>
  );
};

export default GridPickerModal;

function modelToPickerItem<T>(model: T, keyMapper: (item: T) => number | string): IPickerItem<T> {
  return {
    item: model,
    key: keyMapper(model),
  };
}