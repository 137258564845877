import { useState } from "react";
import { IDataGridColumn, IDataGridFilterSettings, IDataGridSortSettings } from "shared/components/layout/grid/types/dataGridTypes";
import arrowIcon from "shared/media/dls/arrow-down-2.svg";
import pluralize from "shared/utilities/pluralize";
import GridPickerModal from "../grid-picker/grid-picker-modal/GridPickerModal";
import "../picker/Picker.scoped.scss";

export interface IODataPickerProps<TModel> {
  /** The title text to show in the modal. */
  title?: string,
  /** The subTitle text to show above the search box. */
  subTitle?: React.ReactNode,
  /** Determines if the picker is disabled. Default = false. */
  isDisabled?: boolean,
  /** A custom className to apply to the picker input. */
  className?: string,
  /** Determines if this picker should display with a required error. Default = false. */
  hasRequiredError?: boolean,
  /** Mouse hover tooltip text for the picker. */
  tooltip?: string,
  /** The odata endpoint url. */
  oDataUrl: string,
  /** The selection mode (single or multiple). */
  selectMode: "Single" | "Multiple",
  /** The callback for when the user applies their changes. */
  onSelectionChange: (selectedItems: TModel[]) => void,
  /** If specified and the pickerType is not Dropdown, this node will be shown instead of the default. */
  customRenderNode?: React.ReactNode,
  /** The currently selected items. */
  selectedItems: TModel[],
  /** The max number of selected items to show in the selection bar. Default is 5.*/
  maxSelectedItemsVisible?: number,
  /** If provided, this sorting function will be used to sort items when they appear in the selection bar. Only used for GridModal type picker. */
  sortSelectedItems?: (item1: TModel, item2: TModel) => number,
  /** Maps a selected item to text for display in the selection bar or dropdown. */
  renderSelectedItem: (item: TModel) => string,
  /** Optional. Specifies the grid column properties. If not provided, all columns will be shown with raw data and default widths. */
  gridColumns?: IDataGridColumn[],
  
  /** Optional. Specifies the grid column properties. If not provided, all columns will be shown with raw data and default widths. */
  suggestedItemsUrl?: string,
  /** Optional. Called for each item returned from the suggestedItemsUrl. If not provided, the suggested items will be directly cast into the ModelType. */
  suggestedItemFormatter?(obj: any): TModel,
  /** A function that takes an item and returns its unique key. */
  keyMapper: (item: TModel) => number | string,
  /**
   * Optional. Filter settings for the sync fusion grid. By default, the following settings are applied. Any settings provided
   * via this property will be merged into the below default filter settings.
   * ```
    {
      mode: "Immediate",
      immediateModeDelay: 1000,
      type: 'Menu',
    }
    ```
  */
  filterSettings?: IDataGridFilterSettings,
  /** Optional. Sort settings for the sync fusion grid. */
  sortSettings?: IDataGridSortSettings,
}

const ODataPicker = <TModel,>({
  title,
  subTitle,
  tooltip,
  isDisabled,
  className,
  hasRequiredError,
  oDataUrl,
  selectMode,
  onSelectionChange,
  customRenderNode,
  selectedItems,
  maxSelectedItemsVisible,
  sortSelectedItems: sortItems,
  renderSelectedItem,
  gridColumns,
  suggestedItemsUrl,
  suggestedItemFormatter,
  keyMapper,
  filterSettings,
  sortSettings,
}: IODataPickerProps<TModel>) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const anySelected = selectedItems.length > 0;

  const renderSelectedItems = () => {
    if (maxSelectedItemsVisible !== undefined
      && selectedItems.length > maxSelectedItemsVisible) {
      return `${selectedItems.length} selected ${pluralize(selectedItems.length, "item", "items")}`;
    }

    return selectedItems
      .slice()
      .sort(sortItems)
      .map(x => renderSelectedItem(x))
      .join('; ');
  }

  return (
    <>
      {customRenderNode === undefined
        ? (
          <div
            className={`input picker ${isDisabled
              ? "disabled"
              : ""
              } ${className === undefined
                ? ""
                : className
              } ${hasRequiredError
                ? "required-error"
                : ""}`}
            title={tooltip}
            onClick={isDisabled
              ? undefined
              : () => setIsModalOpen(true)
            }
          >
            <div
              className={`labels ${!anySelected
                && !hasRequiredError
                ? "placeholder"
                : ""}`
              }
            >
              {anySelected
                ? renderSelectedItems()
                : "Select"
              }
            </div>
            <img
              src={arrowIcon}
              alt=""
              className="icon-small"
            />
          </div>
        ) : customRenderNode
      }

      {isModalOpen && (
        <GridPickerModal
          dataSource={"OData"}
          dataUrl={oDataUrl}
          onApply={(items) => {
            onSelectionChange(items);
            setIsModalOpen(false);
          }}
          onClose={() => setIsModalOpen(false)}
          sortItems={sortItems}
          keyMapper={keyMapper}
          renderSelectedItem={renderSelectedItem}
          selectMode={selectMode}
          selectedItems={selectedItems}
          title={title || "Items"}
          subTitle={subTitle}
          gridColumns={gridColumns}
          suggestedItemsUrl={suggestedItemsUrl}
          suggestedItemFormatter={suggestedItemFormatter}
          filterSettings={filterSettings}
          sortSettings={sortSettings}
        />
      )}
    </>
  );
};

export default ODataPicker;