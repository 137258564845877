import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PickerDropdown from "shared/components/controls/picker-dropdown/PickerDropdown";
import { loadPickerItems, setSelectedPickerItems } from "store/search/actions/SearchActionsSlice";
import { useAppSelector } from "store/store";

const ActionItemType: React.FC = () => {
  const pickerState = useAppSelector(store => store.searchActions.pickerData.actionItemTypes);

  return (
    <LabeledControl
      label="Action Item Type"
    >
      <PickerDropdown
        pickerState={pickerState}
        setSelectedItemsAction={setSelectedPickerItems}
        loadAction={loadPickerItems}
        selectMode="multiple"
      />
    </LabeledControl>
  );
};

export default ActionItemType;