import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import React, { ReactElement, useEffect } from "react";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import Prompt from "shared/components/common/prompt/Prompt";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import MultiButtons from "shared/components/controls/buttons/multi-buttons/MultiButtons";
import DatePicker from "shared/components/controls/date-picker/DatePicker";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import ToggleSwitch from "shared/components/controls/toggle-switch/ToggleSwitch";
import Card from "shared/components/layout/card/Card";
import Page, { IPageProps } from "shared/components/layout/page/Page";
import FittedPage from "shared/components/layout/page/fitted-page/FittedPage";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { ITableRowProps } from "shared/components/layout/table/TableRows";
import { IColumnItem } from "shared/types/columnTypes";
import formatDate from "shared/utilities/dateFormatters";
import { loadPeriods, setAskEnforceGlobal, setMode, setPeriodPropValue, upsertPeriods } from "store/admin/planning-administration/facility-attributes/AdminFacilityAttributesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAdminPlanningAdministrationPeriod, PeriodMode, PlanningPeriodsType } from "types/adminPlanningAdministration";
import { EditRestriction } from "types/auditPageAuthTypes";
import PlanningAdministrationPeriod from "types/planning-admin/PlanningAdministrationPeriod";
import IAdminPlanPeriodExtraCard from "./IAdminPlanPeriodExtraCard";
import AuditPlanningAdministrationTabs from "./tabs/AuditPlanningAdministrationTabs";
import "./AdminPlanPeriodPage.scoped.scss";

interface IAdminPlanPeriodPageProps {
  pageTitle: string,
  periodType: PlanningPeriodsType,
  cardTitle: string,
  extraCards?: ReactElement<IAdminPlanPeriodExtraCard>[],
  showBreadcrumbs?: boolean;
}

const AdminPlanPeriodPage: React.FC<IAdminPlanPeriodPageProps> = ({
  pageTitle,
  periodType,
  cardTitle,
  extraCards,
  showBreadcrumbs
}) => {
  const dispatch = useAppDispatch();
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const hasEditPermission = editRestriction === EditRestriction.EditAll ||
    editRestriction === EditRestriction.EditOwn ?
    true :
    false;

  const periods = useAppSelector(store => store.adminFacilityAttributes.periods);
  const askEnforceGlobal = useAppSelector(store => store.adminFacilityAttributes.askEnforceGlobal);
  const mode = useAppSelector(store => store.adminFacilityAttributes.mode);
  const isDirty = useAppSelector(store => store.adminFacilityAttributes.isDirty);
  const loadPeriodsOp = useAppSelector(store => store.adminFacilityAttributes.loadPeriodsOp);
  const upsertPeriodsOp = useAppSelector(store => store.adminFacilityAttributes.upsertPeriodsOp);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    dispatch(loadPeriods(periodType));
  }, [dispatch, periodType]);

  const pageContent = (
    <div className="admin-page">
      <div className="facility-header-buttons">
        <AuditPlanningAdministrationTabs />
      </div>

      <Card
        showHeader={true}
        className={extraCards?.length ? "main-card" : ""}
        headerElement={(
          <div className="facility-result-labels">
            <div className="facility-result-display">
              <LabeledControl
                label=""
                isLight={true}
              >
                <span className='facility-result-content'>
                  {cardTitle}
                </span>
              </LabeledControl>
            </div>
            <div className="facility-result-display">
              <div
                className="label-text"
              >
                <LabeledControl
                  label="Enforce:"
                  className="enforce-label"
                >
                </LabeledControl>
              </div>

              <MultiButtons
                buttons={[{
                  key: "Global",
                  text: "Global",
                  onClick: () => dispatch(setAskEnforceGlobal(true)),
                  disabled: !hasEditPermission
                },
                {
                  key: "PerGeoUnit",
                  text: "Geo Units",
                  onClick: () => dispatch(setMode("PerGeoUnit")),
                  disabled: !hasEditPermission
                },]}
                selectedKey={mode}
              />
            </div>
          </div>
        )}
      >
        <div
          className="truncate"
        >
          <Table
            columns={getTableColumns(currentYear,
              mode,
              hasEditPermission,
              dispatch)}
            data={periods
              .slice()
              .sort(sortPlanningPeriods)
            }
            keyProp="businessTeamId"
            rowProps={periods.map(x => ({
              dataId: x.businessTeamId,
              className: setRowStyle(x, mode, currentDate)
            } as ITableRowProps))
            }
            theme={TableThemes.spacious}
          />
        </div>
      </Card>

      {extraCards !== undefined
        && extraCards.length > 0
        && extraCards.map((card, ix) => (
          <>
            {React.cloneElement(card, {
              hasEditPermission,
            })}
          </>
        ))}

      {askEnforceGlobal &&
        <ConfirmModal
          header="Enforce Global Values"
          message=
          {
            <div>
              <b>Are you sure that you want to enforce the Global values?</b>
              <br></br>
              <br></br>
              All GeoUnits will follow the global dates.
            </div>
          }
          onYesClicked={() => {
            dispatch(setMode("Global"));
            dispatch(setAskEnforceGlobal(false));
          }}
          onNoClicked={() => {
            dispatch(setMode("PerGeoUnit"));
            dispatch(setAskEnforceGlobal(false));
          }}
        />}

      {(loadPeriodsOp?.isWorking || upsertPeriodsOp?.isWorking) &&
        <ModalSpinner />
      }

      <Prompt
        isDirty={isDirty}
        message="Any unsaved changes will be lost. Are you sure you want to leave?"
      />
    </div>
  );

  const pageProps: IPageProps = {
    title: pageTitle,
    headerControls: [{
      key: "SAVE",
      element: (
        <button
          className="primary"
          disabled={!hasEditPermission || !isDirty}
          onClick={() => dispatch(upsertPeriods(periodType))}
        >
          Save
        </button>
      )
    }],
    showBreadcrumbs: showBreadcrumbs
  };

  if (!extraCards?.length) {
    // There are no extra cards. Render the 1 card as full screen.
    return (
      <FittedPage
        {...pageProps}
      >
        {pageContent}
      </FittedPage>
    );
  } else {
    // There are extra cards. Render a regular page with the non-fullscreen display.
    return (
      <Page
        {...pageProps}
      >
        {pageContent}
      </Page>
    );
  }
};

export default AdminPlanPeriodPage;

function getTableColumns(currentYear: number,
  mode: PeriodMode,
  hasEditPermission: boolean,
  dispatch: Dispatch<AnyAction>
): IColumnItem<IAdminPlanningAdministrationPeriod, keyof IAdminPlanningAdministrationPeriod>[] {
  return [
    {
      key: "basin",
      header: "Basin",
      customRender: item => item.basin
    },
    {
      key: "geoUnit",
      header: "GeoUnit",
      customRender: item => item.geoUnit
    },
    {
      key: "startDate",
      header:
        <div>
          Start Date
          <span style={{ color: "red" }}>*</span>
        </div>,
      customRender: item => (
        <DatePicker
          customCssClass="calendar"
          value={item.planningAdministrationPeriodDates.getStartDate(currentYear)}
          minDate={new Date(currentYear, 0, 1)}
          maxDate={new Date(currentYear, 11, 31)}
          isDisabled={
            (item.businessTeamId === 0 && mode !== "Global")
            || (item.businessTeamId !== 0 && mode === "Global")
            || item.manualOverride === true
            || !hasEditPermission
          }
          dateFormat="MM/dd"
          fixedHeight
          onChange={date => {
            if (!date) {
              return;
            }

            const dateValue = Array.isArray(date)
              && date[0]
              ? date[0]
              : (!Array.isArray(date)
                && date
                ? date
                : null);

            if (!dateValue) {
              return;
            }

            dispatch(setPeriodPropValue({
              businessTeamId: item.businessTeamId,
              values: {
                planningAdministrationPeriodDates: new PlanningAdministrationPeriod({
                  startDay: dateValue.getDate(),
                  startMonth: dateValue.getMonth() + 1,
                  endDay: item.planningAdministrationPeriodDates.endDay,
                  endMonth: item.planningAdministrationPeriodDates.endMonth,
                }),
              },
            }));
          }}
        />
      )
    },
    {
      key: "endDate",
      header:
        <div>
          End Date
          <span style={{ color: "red" }}>*</span>
        </div>,
      customRender: item => (
        <DatePicker
          customCssClass="calendar"
          value={item.planningAdministrationPeriodDates.getEndDate(currentYear)}
          minDate={new Date(currentYear, 0, 1)}
          maxDate={new Date(currentYear, 11, 31)}
          isDisabled={
            (item.businessTeamId === 0 && mode !== "Global")
            || (item.businessTeamId !== 0 && mode === "Global")
            || item.manualOverride === true
            || !hasEditPermission
          }
          dateFormat="MM/dd"
          fixedHeight
          onChange={(date) => {
            if (!date) {
              return;
            }

            const dateValue = Array.isArray(date)
              && date[0]
              ? date[0]
              : (!Array.isArray(date)
                && date
                ? date
                : null);

            if (!dateValue) {
              return;
            }

            dispatch(setPeriodPropValue({
              businessTeamId: item.businessTeamId,
              values: {
                planningAdministrationPeriodDates: new PlanningAdministrationPeriod({
                  startDay: item.planningAdministrationPeriodDates.startDay,
                  startMonth: item.planningAdministrationPeriodDates.startMonth,
                  endDay: dateValue.getDate(),
                  endMonth: dateValue.getMonth() + 1,
                }),
              },
            }));
          }}
        />
      )
    },
    {
      key: "manualOverride",
      header: "Manual Override",
      customRender: item => (
        <ToggleSwitch
          isChecked={item.manualOverride}
          onChange={isChecked => dispatch(setPeriodPropValue({
            businessTeamId: item.businessTeamId,
            values: {
              manualOverride: isChecked,
            },
          }))}
        />
      )
    },
    {
      key: "expiryDate",
      header: "Override Expiry Date",
      customRender: item => (
        <DatePicker
          customCssClass="calendar-expiry"
          value={item.overrideExpiryDate}
          isDisabled={
            (item.businessTeamId === 0 && mode !== "Global")
            || (item.businessTeamId !== 0 && mode === "Global")
            || item.manualOverride === false
            || !hasEditPermission
          }
          fixedHeight
          onChange={(date) => {
            if (!date) {
              return;
            }

            const dateValue = Array.isArray(date)
              && date[0]
              ? date[0]
              : (!Array.isArray(date)
                && date
                ? date
                : null);

            if (!dateValue) {
              return;
            }

            dispatch(setPeriodPropValue({
              businessTeamId: item.businessTeamId,
              values: {
                overrideExpiryDate: dateValue.getTime(),
              },
            }));
          }}
        />
      )
    },
    {
      key: "modifiedBy",
      header: "Modified By",
      customRender: item => item.modifiedBy
    },
    {
      key: "modifiedOn",
      header: "Modified On",
      customRender: item => formatDate(new Date(item.modifiedOn)),
    },
  ];
}

function setRowStyle(item: IAdminPlanningAdministrationPeriod, mode: PeriodMode, currentDate: Date) {
  let style = "";
  let disabled = "disabled-plan-period";
  let editableFacilityRow = "editable-facility-row";

  if ((item.businessTeamId === 0
    && mode !== "Global")
    || (item.businessTeamId !== 0
      && mode === "Global")) {
    return disabled;
  }

  if (item.manualOverride
    && new Date(item.overrideExpiryDate) > new Date()) {
    return editableFacilityRow;
  }

  const thisYear = new Date().getFullYear();

  if (item.planningAdministrationPeriodDates.getStartDate(thisYear) > item.planningAdministrationPeriodDates.getEndDate(thisYear)) {
    if (currentDate >= item.planningAdministrationPeriodDates.getStartDate(thisYear)
      || currentDate <= item.planningAdministrationPeriodDates.getEndDate(thisYear)) {
      return editableFacilityRow;
    }
  } else {
    if (currentDate >= item.planningAdministrationPeriodDates.getStartDate(thisYear)
      && currentDate <= item.planningAdministrationPeriodDates.getEndDate(thisYear)) {
      return editableFacilityRow;
    }
  }

  return style;
}

function sortPlanningPeriods(item1: IAdminPlanningAdministrationPeriod, item2: IAdminPlanningAdministrationPeriod) {
  if (item1.businessTeamId === 0 && item2.businessTeamId !== 0) {
    return -1;
  } else if (item1.businessTeamId !== 0 && item2.businessTeamId === 0) {
    return 1;
  }

  if ((item1.basin || "") < (item2.basin || "")) {
    return -1;
  } else if ((item1.basin || "") > (item2.basin || "")) {
    return 1;
  }

  return (item1.geoUnit || "") < (item2.geoUnit || "")
    ? -1
    : 1;
}
