import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { TRANSFORMERS } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { useEffect, useRef, useState } from "react";
import "./RichTextEditor.scss";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import OnChangePlugin from "./plugins/OnChangePlugin";
import RestoreHTMLPlugin from "./plugins/RestoreHTMLPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import QITheme from "./themes/QITheme";

function Placeholder() {
  return <div className="editor-placeholder"></div>;
}

const editorConfig = {
  namespace: "qi",
  // The editor theme
  theme: QITheme,
  // Handling of errors during update
  onError(error: any) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};

interface IRichTextEditorProps {
  isDisabled?: boolean,
  htmlValue: string,
  onChange(plainText: string, htmlValue: string): void,
}

export default function RichTextEditor({
  isDisabled,
  htmlValue,
  onChange,
}: IRichTextEditorProps) {
  const [internalHtmlValue, setInternalHtmlValue] = useState<string>(htmlValue);
  const editorDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Whenever the provided htmlValue prop changes, update the state so that it
    // refreshes the editor.
    setInternalHtmlValue(htmlValue);
  }, [htmlValue, setInternalHtmlValue]);

  useEffect(() => {
    const divRef = editorDivRef.current;

    divRef?.addEventListener("click", editorDivClickHandler);

    return () => {
      divRef?.removeEventListener("click", editorDivClickHandler);
    };
  }, []);

  return (
    <LexicalComposer initialConfig={{
      ...editorConfig,
      editable: !isDisabled,
    }}>
      <div className="editor-container">
        <ToolbarPlugin
          isDisabled={isDisabled}
        />

        <div
          className="editor-inner"
          ref={editorDivRef}
        >
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />

          <HistoryPlugin />
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <OnChangePlugin onChange={onChange} />
          <RestoreHTMLPlugin html={internalHtmlValue} />
        </div>
      </div>
    </LexicalComposer>
  );
}

function editorDivClickHandler(this: HTMLDivElement, ev: MouseEvent) {
  if (!ev.ctrlKey) {
    return;
  }

  const target = ev.target as HTMLElement;

  if (target.parentElement?.tagName === "A") {
    const attr = target.parentElement.attributes.getNamedItem("href");
    if (attr?.value) {
      window.open(attr.value, "_blank", "noreferrer noopener");
    }
  }
}