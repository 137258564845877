import React from "react";
import basicCompliance from "shared/media/icons/audit-compliance/Basic Compliance.svg";
import fullCompliance from "shared/media/icons/audit-compliance/Full Compliance.svg";
import highCompliance from "shared/media/icons/audit-compliance/High Compliance.svg";
import highNonCompliance from "shared/media/icons/audit-compliance/High Non-Compliance.svg";
import nonCompliance from "shared/media/icons/audit-compliance/Non-Compliance.svg";
import { ComplianceResults } from "types/auditPlanningTypes";
import "./ComplianceResult.scoped.scss";

export interface IComplianceResultProps {
  /** The compliance result. Will also be used as the label unless `labelOverride` is specified. */
  complianceResult: "Full Compliance" | "Basic Compliance" | "Non Compliance" | ComplianceResults | string | undefined,
  /** Optional. Replaces the default text shown with this. */
  labelOverride?: React.ReactNode,
}

const ComplianceResult: React.FC<IComplianceResultProps> = ({
  complianceResult,
  labelOverride,
}) =>
  <span
    className="compliance-result"
  >
    {!complianceResult
      ? "-"
      : (
        <>
          {getComplianceImage(complianceResult)}

          <span>
            {!!labelOverride
              ? labelOverride
              : complianceResult?.replace(/([a-z])([A-Z])/g, "$1 $2")
            }
          </span>
        </>
      )}
  </span>;

export default ComplianceResult;

const getComplianceImage = (complianceName?: string): React.ReactNode | null => {
  let imgUrl = undefined;

  switch (complianceName) {
    case "Non Compliance":
    case ComplianceResults.NonCompliance:
      imgUrl = nonCompliance;
      break;
    case "High Non Compliance":
    case ComplianceResults.HighNonCompliance:
      imgUrl = highNonCompliance;
      break;
    case "Basic Compliance":
    case ComplianceResults.BasicCompliance:
      imgUrl = basicCompliance;
      break;
    case "Full Compliance":
    case ComplianceResults.FullCompliance:
      imgUrl = fullCompliance;
      break;
    case "High Compliance":
    case ComplianceResults.HighCompliance:
      imgUrl = highCompliance;
      break;
    default:
      return null;
  }

  return (
    <img
      alt={complianceName}
      src={imgUrl}
    />
  );
};