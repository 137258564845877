import React from "react";
import Divider from "shared/components/common/divider/Divider";
import MultilineText from "shared/components/common/multiline-text/MultilineText";
import FlexCol from "shared/components/layout/flex/FlexCol";
import formatDate from "shared/utilities/dateFormatters";
import { userToString } from "shared/utilities/userUtilities";
import { IActionItemHistoryItem } from "types/actionItemTypes";
import "./ActionItemHistoryLog.scoped.scss";

interface IActionItemHistoryLogProps {
  history: IActionItemHistoryItem[],
}

const ActionItemHistoryLog: React.FC<IActionItemHistoryLogProps> = ({
  history,
}) =>
  <FlexCol
    className="history-log"
  >
    <h5
      className="log-title"
    >
      History
    </h5>
    <FlexCol
      className="log-list"
    >
      {history.slice().sort(sortByTimestamp).map((log, ix) => (
        <React.Fragment
          key={log.timestamp}
        >
          {ix > 0 && (
            <Divider />
          )}
          <FlexCol
            className="log-item"
          >
            <span
              className="header"
            >
              <span
                className="event-user"
              >
                {!!log.event && (
                  <>
                    <span
                      className="event"
                    >
                      {log.event}
                    </span>
                    <span
                      className="spacer"
                    >
                      -
                    </span>
                  </>
                )}

                <span
                  className="user"
                >
                  {userToString(log.userInfo)}
                </span>
              </span>

              <span
                className="timestamp"
              >
                {formatDate(new Date(log.timestamp), true, true, "short")}
              </span>
            </span>
            {!!log.text && (
              <div
                className="text"
              >
                <MultilineText
                  text={log.text}
                />
              </div>
            )}
          </FlexCol>
        </React.Fragment>
      ))}
    </FlexCol>
  </FlexCol>;

export default ActionItemHistoryLog;

function sortByTimestamp(a: IActionItemHistoryItem, b: IActionItemHistoryItem) {
  return a.timestamp < b.timestamp
    ? 1
    : -1;
}