import { MetaDataTypes } from "types/masterDataTypes";

export function mapMetaDataItemsByType<T>(metaDataList: any,
  metaDataType: MetaDataTypes,
  formatter: (item: any) => T) {
  if (!Array.isArray(metaDataList)) {
    return [];
  }

  return metaDataList
    .filter(x => x.type === metaDataType)
    .map(x => formatter(x.masterDataItem));
}