import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { isEqual } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IAuditTopic } from "types/auditMasterDataTypes";

export interface IAuditTopicGridRowObject {
  id: string,
  name: string,
  shortName: string,
  parentId: number,
  auditGroupId: number,
  auditGroup: string,
  ownerGroupId: number,
  ownerGroup: string,
  isPlannable: string,
  isDeleted: string,
  firstPartyFrequency: string,
  selfAssessmentFrequency: string,
  deleted: string,
}

export interface IAuditTopicSliceState {
  /** Determines the visibility of the audit topic manager modal. */
  isManagerOpen: boolean,

  /** The currently held values of the audit topic manager. */
  managerValues: Partial<IAuditTopic>,

  /** The currently held values of the audit topic manager. */
  original: Partial<IAuditTopic>,

  /** If true, the user has made some change to the loaded object. */
  isDirty: boolean,

  /** Determines the visibility of the delete/deactivate audit topic confirmation modal. */
  isRevertConfirmationOpen: boolean,

  /** Determines audit topic item to revert status on confirmation modal. */
  auditTopicRow?: IAuditTopicGridRowObject,

  /** Represents the save audit topic operation. */
  saveOp?: IOperation<void>,
  /** Represents the load audit topic operation. */
  loadOp?: IOperation<void>,
  /** Represents the delete audit topic operation. */
  deleteOp?: IOperation<void>,
}

const initialState: IAuditTopicSliceState = {
  managerValues: {},
  original: {},
  isManagerOpen: false,
  isRevertConfirmationOpen: false,
  auditTopicRow: undefined,
  saveOp: undefined,
  loadOp: undefined,
  deleteOp: undefined,
  isDirty: false,
}

export const auditTopicSlice = createSlice({
  name: "auditTopics",
  initialState,
  reducers: {
    /** Sets a value in the redux state. */
    setValue: (state, action: PayloadAction<Partial<IAuditTopicSliceState>>) => {
      Object.assign(state, action.payload);

      // Check if managedValues is dirty
      updateIsDirty(state);
    },


    /** Starts reverting a audit topic status to the api via a Saga. */
    startRevertAuditTopicStatus: (state, _: PayloadAction) => {
      state.saveOp = {
        isWorking: true,
      };
    },

    /** Starts reverting a audit topic status to the api via a Saga. */
    confirmRevertAuditTopicStatus: (state, action: PayloadAction<IAuditTopicGridRowObject>) => {
      state.auditTopicRow = action.payload;
      state.isRevertConfirmationOpen = true;
      state.isManagerOpen = false;
    },

    /** Finishes reverting a audit topic. If the deletion was successful, send `true` in the payload. */
    finishRevertAuditTopicStatus: (state, action: PayloadAction<boolean>) => {
      state.saveOp = undefined;
      state.auditTopicRow = undefined;
    },

    /** Starts saving a audit topic to the api via a Saga. */
    stopSaveAuditTopic: state => {
      state.saveOp = {
        isWorking: false,
      };
    },

    /** Starts saving a audit topic to the api via a Saga. */
    startSaveAuditTopic: state => {
      state.saveOp = {
        isWorking: true,
      };
    },

    /** Clears the save audit topic operation. */
    finishSaveAuditTopic: (state, action: PayloadAction<boolean>) => {
      state.saveOp = undefined;

      if (action.payload) {
        state.isManagerOpen = false;
      }
    },

    /** Finishes loading a audit topic and puts the audit topic values into the state. */
    finishLoadAuditTopic: (state, action: PayloadAction<IAuditTopic | undefined>) => {
      state.loadOp = undefined;

      if (!action.payload) {
        return;
      }

      state.managerValues = action.payload;
      state.isManagerOpen = true;
    },

    /** Determines the visibility of the delete confirmation window. */
    toggleDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.isRevertConfirmationOpen = action.payload;
      state.isManagerOpen = false;
    },
  },
});

function updateIsDirty(state: WritableDraft<IAuditTopicSliceState>) {
  if (!state.managerValues) {
    return;
  }

  const newComparer = {
    name: state.managerValues.name,
    shortName: state.managerValues.shortName,
    parentId: state.managerValues.parentId,
    auditGroupId: state.managerValues.auditGroupId,
    ownerGroupId: state.managerValues.ownerGroupId,
    isPlannable: state.managerValues.isPlannable,
    firstPartyFrequency: state.managerValues.firstPartyFrequency,
    selfAssessmentFrequency: state.managerValues.selfAssessmentFrequency,
  };

  const originalComparer = {
    name: state.original.name,
    shortName: state.original.shortName,
    parentId: state.original.parentId,
    auditGroupId: state.original.auditGroupId,
    ownerGroupId: state.original.ownerGroupId,
    isPlannable: state.original.isPlannable,
    firstPartyFrequency: state.original.firstPartyFrequency,
    selfAssessmentFrequency: state.original.selfAssessmentFrequency,
  };

  // check if original was modified on managerValues
  if (isEqual(newComparer, originalComparer)) {
    state.isDirty = false;
    return;
  }

  // Check required values
  if (!state.managerValues.name
    || !state.managerValues.shortName
    || !state.managerValues.auditGroupId
    || !state.managerValues.ownerGroupId
  ) {
    state.isDirty = false;
    return;
  }

  state.isDirty = true;
}

export const {
  setValue,
  startSaveAuditTopic,
  stopSaveAuditTopic,
  finishSaveAuditTopic,
  finishLoadAuditTopic,
  confirmRevertAuditTopicStatus,
  toggleDeleteConfirmation,
  startRevertAuditTopicStatus,
  finishRevertAuditTopicStatus,
} = auditTopicSlice.actions;