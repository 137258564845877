import React from "react";
import downArrow from "shared/media/dls/arrow-down-3.svg";
import upArrow from "shared/media/dls/arrow-up-3.svg";
import "./CollapseSwitch.scoped.scss";

export interface ICollapseSwitchProps {
  isOpen: boolean,
  onClick(): void,
  className?: string,
}

const CollapseSwitch: React.FC<ICollapseSwitchProps> = ({
  isOpen,
  onClick,
  className,
}) =>
  <img
    src={isOpen ? upArrow : downArrow}
    alt={isOpen ? "▲" : "▼"}
    className={className}
    onClick={onClick}
  />;

export default CollapseSwitch;