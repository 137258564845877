import AuditorSearchMessage from "components/audits/common/auditor-search-message/AuditorSearchMessage";
import { isQuestionMissingAnswer } from "components/audits/common/auditUtilities";
import React, { useMemo, useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import Button from "shared/components/controls/buttons/button/Button";
import Picker from "shared/components/controls/picker/Picker";
import Card from "shared/components/layout/card/Card";
import TabSelection from "shared/components/layout/tab-selection/TabSelection";
import addIcon from "shared/media/dls/add.svg";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IPickerItem } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { userToString } from "shared/utilities/userUtilities";
import { closePicker, loadPickerItems, loadSuggestedPickerItems, openPicker, setSelectedAuditorTab, setSelectedPickerItems } from "store/audit-summary/AuditSummarySlice";
import { setAudit } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditStatuses } from "types/auditingTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import AuditorRow from "./AuditorRow";

const AuditorsCard: React.FC = () => {
  const audit = useAppSelector(store => store.audit.audit);
  const questions = useAppSelector(store => store.audit.questions);
  const pickerData = useAppSelector(store => store.auditSummary.pickerData);

  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const selectedAuditorTab = useAppSelector(store => store.auditSummary.selectedAuditorTab);

  const [confirmRemoveEmail, setConfirmRemoveEmail] = useState("");

  const dispatch = useAppDispatch();
  const isDisabled = auditPageAuth.editRestriction !== EditRestriction.EditAll;
  const answeredCount = audit?.status !== undefined
    && questions
      .filter(x => !isQuestionMissingAnswer(x, audit.status))
      .length;
  const isTopicAssignmentAvailable = answeredCount !== questions.length;
  const canAssignAuditors = !isDisabled && isTopicAssignmentAvailable;

  const auditors = audit?.auditors;

  const selectedAuditors = useMemo(() => {
    return (auditors || [])
      .filter(x => x.isLeader === false)
      .map((item): IPickerItem<IAzureADUser> => ({
        key: item.email,
        disabled: false,
        item: item,
      }));
  }, [auditors]);

  if (!audit) {
    // No audit is loaded. Don't show the card.
    return null;
  }

  //Restriction applied
  const isEnableToEdit = auditPageAuth.editRestriction !== EditRestriction.EditNone;


  const unassignedCount = questions
    .filter(x => !x.auditorEmail)
    .length;

  const assignedCount = questions.length - unassignedCount;
  const assignedPercent = questions.length === 0
    ? "-"
    : `${Math.floor((questions.length - unassignedCount) / (questions.length) * 100)}%`;

  const totalQuestions = questions.length;
  const completedCount = questions
    .filter(x => !isQuestionMissingAnswer(x, audit.status))
    .length;
  const completedPercent = totalQuestions > 0
    ? Math.floor((completedCount / totalQuestions) * 100)
    : 0;

  const removeAuditorClicked = (email: string) => {
    if (questions
      .some(x => x.auditorEmail === email
        && !isQuestionMissingAnswer(x, audit.status))) {
      dispatch(showErrorToast(`${email} has answered some questions in this audit and cannot be removed.`));
      return;
    }

    setConfirmRemoveEmail(email);
  };

  if (!audit) {
    return (
      <Card
        cardStateId={`auditSummaryAuditorsAuditeesCard`}
        defaultCollapsedState={false}
        className={"auditors-card"}
      >
        <Banner
          type={BannerType.error}
        >
          Audit information not found.
        </Banner>
      </Card>
    );
  }

  return (
    <Card
      cardStateId={`auditSummaryAuditorsAuditeesCard`}
      defaultCollapsedState={false}
      className={"auditors-card"}
      headerElement={(
        <>
          <div
            className="tab-holder"
          >
            <TabSelection
              onTabClicked={(key: string | number) => {
                dispatch(setSelectedAuditorTab(key as "Auditors" | "Auditees"));
              }}
              tabs={[{
                key: "Auditors",
                node: "Auditors",
                isLink: false,
                isSelected: selectedAuditorTab === 'Auditors',
                linkUrl: undefined,
              }, {
                key: "Auditees",
                node: "Auditees",
                isLink: false,
                isSelected: selectedAuditorTab === 'Auditees',
                linkUrl: undefined,
              }]}
            />
          </div>
          {isEnableToEdit
            && audit.status !== AuditStatuses.Completed
            && audit.status !== AuditStatuses.Closed
            ? (
              <Picker<IAzureADUser>
                title="Auditors"
                subTitle={<AuditorSearchMessage />}
                renderListItem={(item) => userToString(item)}
                renderSelectedItem={(item) => userToString(item)}
                pickerState={{
                  ...pickerData.auditors,
                  selectedItems: selectedAuditors,
                }}
                preserveItems={true}
                searchOptions={{
                  asyncMinChars: 1,
                  behavior: "async",
                }}
                openAction={openPicker}
                closeAction={closePicker}
                loadAction={loadPickerItems}
                setSelectedItemsAction={setSelectedPickerItems}
                preserveSuggestedItems={true}
                allowMultiSelect={true}
                noItemsMessage={"Please begin typing a search term to execute a search."}
                pickerNode={(
                  <Button
                    buttonType="secondary"
                    onClick={() => {
                      dispatch(openPicker({
                        pickerKey: pickerData.auditors.key,
                      }));

                      dispatch(loadSuggestedPickerItems({
                        pickerKey: pickerData.auditors.key,
                      }));
                    }}
                    img={addIcon}
                  >
                    Add Auditor
                  </Button>
                )}
                itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
                showSuggestedItems
                loadSuggestionsAction={loadSuggestedPickerItems}
              />
            ) : undefined
          }
        </>
      )}
      footer={(
        <table>
          <thead>
            <tr>
              <th
                className="name"
              >
                {(auditors || []).length} Auditor(s)
              </th>
              <th
                className="centered questions"
              >
                <span className="centered-label light"># Assigned</span><br />
                <span className="centered-content">{assignedCount} / {questions.length} ({assignedPercent})</span>

              </th>
              <th
                className="centered completed"
              >
                <span className="centered-label light"> Completed</span><br />
                <span className="centered-content">{completedPercent}%</span>
              </th>
            </tr>
          </thead>
        </table>
      )}
    >
      <div
        className="card-body"
      >
        <table>
          <thead>
            <tr>
              <th
                className="name"
              >
                Auditors
              </th>
              {canAssignAuditors && (
                <th
                  className="centered"
                >
                  Associations
                </th>
              )}
              <th
                className="centered"
              >
                Questions
              </th>
              <th
                className="centered"
              >
                Completed
              </th>
            </tr>
          </thead>
          <tbody>
            {(auditors || [])
              .slice()
              .sort((a, b) => {
                if (a.isLeader) {
                  return -1;
                } else if (b.isLeader) {
                  return 1;
                }

                return a.name < b.name ? -1 : 1;
              })
              .map(auditor => (
                <AuditorRow
                  key={auditor.email}
                  auditor={auditor}
                  isEnabledToEdit={audit.status !== AuditStatuses.Completed
                    && isEnableToEdit
                    && !auditor.isLeader}
                  assignedQuestions={questions.filter(x => x.auditorEmail === auditor.email)}
                  onRemoveClick={audit.status !== AuditStatuses.Completed
                    && audit.status !== AuditStatuses.Closed && isEnableToEdit
                    ? removeAuditorClicked
                    : undefined
                  }
                  auditStatus={audit.status}
                  canAssignAuditors={canAssignAuditors}
                />
              ))}
          </tbody>
        </table>
      </div>

      {!!confirmRemoveEmail && (
        <ConfirmModal
          header="Remove Auditor?"
          message={`Are you sure you want to remove ${confirmRemoveEmail}?`}
          onYesClicked={() => {
            dispatch(setAudit({
              audit: {
                auditors: audit.auditors.filter(x => x.email !== confirmRemoveEmail),
              },
            }));

            setConfirmRemoveEmail("");
          }}
          onNoClicked={() => setConfirmRemoveEmail("")}
        />
      )}
    </Card>
  );
};

export default AuditorsCard;
