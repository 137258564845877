import React, { useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import "./GlobalMessageBar.scoped.scss";

interface IGlobalMessageBarProps {
  messageUuid: string,
  type: BannerType,
  children: React.ReactNode,
}

const GlobalMessageBar: React.FC<IGlobalMessageBarProps> = ({
  messageUuid,
  type,
  children,
}) => {
  const [isMsgVisible, setIsMsgVisible] = useState<boolean>(localStorage.getItem(messageUuid) !== "true");

  if (!isMsgVisible) {
    return null;
  }

  return (
    <div
      className="message-bar"
    >
      <Banner
        type={type}
      >
        <div
          className="message"
        >
          {children}
        </div>

        <div
          className="close-button"
          onClick={() => {
            localStorage.setItem(messageUuid, "true");
            setIsMsgVisible(false);
          }}
        >
          X
        </div>
      </Banner>
    </div>
  );
};

export default GlobalMessageBar;