import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useEffect, } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import RichTextEditor from "shared/components/controls/rich-text-editor/RichTextEditor";
import Modal, { ModalButton } from "shared/components/layout/modal/Modal";
import { tooltipMessage } from 'shared/utilities/stringUtilities';
import { closedCommentsModal, saveTopicComments, setTopicComment } from 'store/audit-report/AuditReportSlice';
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { CommentType } from "types/auditReportTypes";
import "./CommentsModal.scoped.scss";

interface ICommentsModalProps {
  isEditable: boolean,
}

const CommentsModal: React.FC<ICommentsModalProps> = ({
  isEditable,
}) => {

  const topicComments = useAppSelector(store => store.auditReport.topicCommentModal.topicComments);
  const isDirty = useAppSelector(store => store.auditReport.topicCommentModal.isDirty);
  const saveTopicComentsOp = useAppSelector(store => store.auditReport.saveTopicComentsOp);
  const reportSummary = useAppSelector(store => store.auditReport.report?.reportSummaryDtos);
  const currentTopicId = topicComments[0]?.auditTopicId ?? 0;
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const currentUserEmail = useAppSelector(store => store.auth.currentUser.email);
  const auditorsForTheTopic = reportSummary?.find(r => r.topicId === currentTopicId)?.auditor ?? [];

  const isOwnEditable = (auditPageAuth.editRestriction === EditRestriction.EditOwn
    && auditorsForTheTopic.some(a => a.email.toLowerCase() === currentUserEmail.toLowerCase()));

  const enableComments = (isEditable || isOwnEditable);

  const dispatch = useAppDispatch();

  const topicModalButtons: ModalButton[] = [
    {
      key: "Cancel",
      text: "Cancel",
      disabled: false,
      className: "secondary",
      onClick: () => {
        dispatch(closedCommentsModal(false));
      },
    },
  ];

  if (enableComments) {
    topicModalButtons.push({
      key: "Save",
      text: "Save",
      disabled: !isDirty,
      title: tooltipMessage(!isDirty),
      className: "primary",
      onClick: () => {
        dispatch(saveTopicComments());
      }
    });
  }

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView({
      name: "AR - Topic Comments",
    });
  }, [appInsights]);

  return (<div>
    <Modal
      isOpen={true}
      header="Topic Comment"

      buttons={topicModalButtons}
    >
      <div className="comments_business_team">
        <LabeledControl
          label="Highlights"
          labelExtraClass='label-content'
        >
          <RichTextEditor
            htmlValue={topicComments.find(x => x.commentType === CommentType.HighLights)?.commentHtmlText || ''}
            isDisabled={!enableComments}
            onChange={(text: string, html: string) => {
              dispatch(setTopicComment({
                htmlText: text.trim() === ''
                  ? ''
                  : html,
                type: CommentType.HighLights,
                plainText: text.trim() === ''
                  ? ''
                  : text,
              }));
            }
            }
          />
        </LabeledControl>

        <br></br>

        <LabeledControl
          label="Opportunities for Improvement"
          labelExtraClass='label-content'
        >
          <RichTextEditor
            htmlValue={topicComments.find(x => x.commentType === CommentType.LowLights)?.commentHtmlText || ''}
            isDisabled={!enableComments}
            onChange={(text: string, html: string) => {
              dispatch(setTopicComment({
                htmlText: text.trim() === ''
                  ? ''
                  : html,
                type: CommentType.LowLights,
                plainText: text.trim() === ''
                  ? ''
                  : text,
              }));
            }
            }
          />
        </LabeledControl>

        <br></br>

        <LabeledControl
          label="General Comments"
          labelExtraClass='label-content'
        >
          <RichTextEditor
            htmlValue={topicComments.find(x => x.commentType === CommentType.GeneralComments)?.commentHtmlText || ''}
            isDisabled={!enableComments}
            onChange={(text: string, html: string) => {
              dispatch(setTopicComment({
                htmlText: text.trim() === ''
                  ? ''
                  : html,
                type: CommentType.GeneralComments,
                plainText: text.trim() === ''
                  ? ''
                  : text,
              }));
            }
            }
          />
        </LabeledControl>
      </div>
      {(saveTopicComentsOp?.isWorking
      ) && (
          <ModalSpinner />
        )}
    </Modal>

  </div>)
}

export default CommentsModal
