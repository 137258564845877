import React from "react";
import { IPickerItem } from "shared/types/pickerTypes";
import PickerItemRow from "./PickerItemRow";
import "./PickerList.scoped.scss";

interface IPickerListProps<T> {
  items: IPickerItem<T>[],
  allowMultiSelect?: boolean,
  noItemsMessage: string,
  displayMode: "list" | "tree";
  ancestryPath?: IPickerItem<T>[],
  selectedItems: IPickerItem<T>[],
  renderItem?: (item: T) => string | React.ReactNode,
  onItemSelected(item: IPickerItem<T>): void,
  onItemDeselected(item: IPickerItem<T>): void,
  onItemExpanded?(item: IPickerItem<T>, ancestryPath?: (string | number)[]): void,
  onItemCollapsed?(item: IPickerItem<T>, ancestryPath?: (string | number)[]): void,
  className?: string,
  /** Optional. If specified, each item will call this function to determine if it should be enabled or not. The ancestor path is the list of all parent items above the item. */
  isDisabledMapper?: (item: T, ancestorPath: T[]) => boolean,
  isDisabled?: boolean,
  notShowPlusMinusSign?: boolean,
  renderTotal?: (item: T) => string | React.ReactNode,
  showOnlySelectedItems?: boolean,
  hiddenSelectOption?: boolean,
  itemSorter?: (item1: IPickerItem<T>, item2: IPickerItem<T>) => number,
  /** Optional. If true and the display mode is 'list', branches of the hierarchy that have no selectable items will be pruned. Default = false. */
  hideUnselectableBranches?: boolean,
}

const PickerList = <T,>({
  items,
  allowMultiSelect,
  noItemsMessage,
  displayMode,
  ancestryPath,
  selectedItems,
  renderItem,
  onItemSelected,
  onItemDeselected,
  onItemExpanded,
  onItemCollapsed,
  className,
  isDisabledMapper,
  isDisabled,
  notShowPlusMinusSign,
  renderTotal,
  showOnlySelectedItems,
  hiddenSelectOption,
  itemSorter,
  hideUnselectableBranches = false,
}: IPickerListProps<T>) =>
  <div
    className={`picker-list ${className || ""}`}
  >
    {items
      .slice()
      .sort(itemSorter
        ? (item1, item2) => itemSorter(item1, item2)
        : undefined
      )
      .map(item => {
        const showChildren = displayMode === "tree"
          && item.children !== undefined
          && item.children.length > 0;

        const childItems = item.children !== undefined
          ? item.children
          : [];

        const selectabilityData = isItemSelectableOrHasSelectableChildrenRecursive(item, ancestryPath, isDisabledMapper);

        return (
          <React.Fragment
            key={item.key}
          >
            {(!showOnlySelectedItems
              || (showOnlySelectedItems && selectedItems.some(x => x.key === item.key)))
              && (
                !hideUnselectableBranches
                  || selectabilityData.isSelectable
                  || selectabilityData.hasSelectableChild
                  ? (
                    <PickerItemRow<T>
                      item={item}
                      allowMultiSelect={allowMultiSelect}
                      displayMode={displayMode}
                      ancestryPath={ancestryPath}
                      renderItem={renderItem}
                      onItemSelected={onItemSelected}
                      onItemDeselected={onItemDeselected}
                      onItemExpanded={onItemExpanded}
                      onItemCollapsed={onItemCollapsed}
                      showChildren={showChildren}
                      isSelected={selectedItems.some(x => x.key === item.key)}
                      isDisabledMapper={isDisabled ? () => true : isDisabledMapper}
                      notShowPlusMinusSign={notShowPlusMinusSign}
                      renderTotal={renderTotal}
                      showOnlySelectedItems={showOnlySelectedItems}
                      hiddenSelectOption={hiddenSelectOption}
                      hideUnselectableBranches={hideUnselectableBranches}
                      hasSelectableChild={selectabilityData.hasSelectableChild}
                    />
                  ) : null
              )}

            {showChildren
              && item.isExpanded
              && (!hideUnselectableBranches || selectabilityData.hasSelectableChild)
              && (
                <div className="child-nodes">
                  <PickerList
                    items={childItems}
                    selectedItems={selectedItems}
                    renderItem={renderItem}
                    allowMultiSelect={allowMultiSelect}
                    displayMode={displayMode || "list"}
                    ancestryPath={ancestryPath === undefined
                      ? [item]
                      : [
                        ...ancestryPath,
                        item,
                      ]}
                    noItemsMessage={noItemsMessage}
                    onItemSelected={onItemSelected}
                    onItemDeselected={onItemDeselected}
                    onItemExpanded={onItemExpanded}
                    onItemCollapsed={onItemCollapsed}
                    isDisabledMapper={isDisabledMapper}
                    isDisabled={isDisabled}
                    notShowPlusMinusSign={notShowPlusMinusSign}
                    renderTotal={renderTotal}
                    showOnlySelectedItems={showOnlySelectedItems}
                    hiddenSelectOption={hiddenSelectOption}
                    itemSorter={itemSorter}
                    hideUnselectableBranches={hideUnselectableBranches}
                  />
                </div>
              )
            }
          </React.Fragment>
        );
      })
    }
    {!items.length &&
      <span
        className="no-items"
      >
        {noItemsMessage}
      </span>
    }
  </div>;

export default PickerList;

function isItemSelectableOrHasSelectableChildrenRecursive<T>(item: IPickerItem<T>,
  ancestorPath: IPickerItem<T>[] | undefined,
  isDisabledMapper: ((item: T, ancestorPath: T[]) => boolean) | undefined): { isSelectable: boolean, hasSelectableChild?: boolean, } {

  if (!item.item) {
    return { isSelectable: false, hasSelectableChild: false, };
  }

  let hasSelectableChild = false;

  if (item.children) {
    for (let i = 0; i < item.children.length; i++) {
      const child = item.children[i];

      const childSelectabilityData = isItemSelectableOrHasSelectableChildrenRecursive(child,
        (ancestorPath ?? []).concat(item),
        isDisabledMapper);

      if (childSelectabilityData.isSelectable
        || childSelectabilityData.hasSelectableChild) {
        hasSelectableChild = true;
      }
    }
  }

  if (isDisabledMapper) {
    const isDisabled = isDisabledMapper(item.item, ancestorPath ?? [] as any);

    if (!isDisabled
      || item.isSelectable) {
      return { isSelectable: true, hasSelectableChild };
    }
  } else if (item.isSelectable) {
    return { isSelectable: true, hasSelectableChild };
  }

  if (!item.children) {
    return { isSelectable: false, hasSelectableChild };
  }

  return { isSelectable: false, hasSelectableChild };
}