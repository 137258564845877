import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Hint from "shared/components/common/hint/Hint";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import BusinessFunctionPicker from "shared/components/controls/pickers/BusinessFunctionPicker";
import BusinessTeamPicker from "shared/components/controls/pickers/BusinessTeamPicker";
import BusinessViewPicker from "shared/components/controls/pickers/BusinessViewPicker";
import IPMProjectPicker from "shared/components/controls/pickers/IPMProjectPicker";
import MobileSitePicker from "shared/components/controls/pickers/MobileSitePicker";
import SupplierPicker from "shared/components/controls/pickers/SupplierPicker";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { addChildDimensions, loadProfileParentChildMatrix, setAddChildDimensionModal } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, IIPMProject, IMobileSite, ISupplier, MetaDataTypes } from "types/masterDataTypes";

const AddChildDimensionModal: React.FC = () => {
  const [selectedBusinessViews, setSelectedBusinessViews] = useState<IBusinessView[]>([]);
  const [selectedBusinessFunctions, setSelectedBusinessFunctions] = useState<IBusinessFunction[]>([]);
  const [selectedBusinessTeams, setSelectedBusinessTeams] = useState<IBusinessTeam[]>([]);
  const [selectedMobileSites, setSelectedMobileSites] = useState<IMobileSite[]>([]);
  const [selectedIPMProjects, setSelectedIPMProjects] = useState<IIPMProject[]>([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState<ISupplier[]>([]);

  const addChildDimensionModal = useAppSelector(store => store.facilityAttributes.addChildDimensionModal);
  const profileParentChildMatrix = useAppSelector(store => store.facilityAttributes.profileParentChildMatrix);
  const profileParentChildMatrixOp = useAppSelector(store => store.facilityAttributes.profileParentChildMatrixOp);
  const isOpen = addChildDimensionModal.isOpen;

  const derivedMetaRestrictions = useAppSelector(store => store.auth.activeUserProfile.derivedMetaRestrictions);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);

  const dispatch = useDispatch();

  const isAnythingSelected = selectedBusinessViews.length
    + selectedBusinessFunctions.length
    + selectedBusinessTeams.length
    + selectedMobileSites.length
    + selectedIPMProjects.length
    + selectedSuppliers.length > 0;

  useEffect(() => {
    setSelectedBusinessFunctions([]);
    setSelectedBusinessViews([]);
    setSelectedBusinessTeams([]);
    setSelectedMobileSites([]);
    setSelectedIPMProjects([]);
    setSelectedSuppliers([]);

    if (profileParentChildMatrix === undefined) {
      dispatch(loadProfileParentChildMatrix());
    }
  }, [isOpen, profileParentChildMatrix, dispatch]);

  const allowedChildTypes = (profileParentChildMatrix || [])
    .filter(x => x.parentType === addChildDimensionModal.parentMasterDataType
      && x.parentSubType === addChildDimensionModal.parentMasterDataSubType);

  return (
    <Modal
      isOpen={addChildDimensionModal.isOpen}
      showCloseButton={true}
      onCloseButtonClicked={() => dispatch(setAddChildDimensionModal({ isOpen: false, }))}
      header="Add Child Dimension"
      buttons={[{
        key: "Cancel",
        text: "Cancel",
        className: "secondary",
        onClick: () => dispatch(setAddChildDimensionModal({ isOpen: false, })),
      }, {
        key: "Submit",
        text: "Submit",
        className: "primary",
        disabled: !isAnythingSelected,
        onClick: () => dispatch(addChildDimensions({
          childItems: selectedBusinessViews
            .map(x => ({ id: x.id, type: MetaDataTypes.BusinessView, code: x.code }))
            .concat(selectedBusinessFunctions
              .map(x => ({ id: x.id, type: MetaDataTypes.BusinessFunction, code: x.code })))
            .concat(selectedBusinessTeams
              .map(x => ({ id: x.id, type: MetaDataTypes.BusinessTeam, code: x.code })))
            .concat(selectedMobileSites
              .map(x => ({ id: x.id, type: MetaDataTypes.MobileSite, code: `${x.rigKey} - ${x.rigName}` })))
            .concat(selectedIPMProjects
              .map(x => ({ id: x.id, type: MetaDataTypes.IPMProject, code: `${x.code} - ${x.name}` })))
            .concat(selectedSuppliers
              .map(x => ({ id: x.id, type: MetaDataTypes.Supplier, code: `${x.aslNumber} - ${x.name}` }))),
          parentProfileId: addChildDimensionModal.parentProfileId,
          parentMasterDataId: addChildDimensionModal.parentMasterDataId,
          parentMasterDataType: addChildDimensionModal.parentMasterDataType,
          parentMasterDataSubType: addChildDimensionModal.parentMasterDataSubType,
          parentText: addChildDimensionModal.parentText,
          subGeoUnitId: addChildDimensionModal.subGeoUnitId,
        })),
      },]}
    >
      {profileParentChildMatrixOp?.isWorking && <ModalSpinner />}

      {allowedChildTypes.length === 0
        ? (
          <Banner
            type={BannerType.info}
          >
            No items can be added under <b>{addChildDimensionModal.parentText}</b>.
          </Banner>
        )
        : (

          <FlexCol>
            <span className="modal-message">
              Select items to be added under <b>{addChildDimensionModal.parentText}</b>:
            </span>

            {allowedChildTypes.some(x => x.childType === MetaDataTypes.BusinessView) &&
              <LabeledControl
                label="Business View (Divisions)"
              >
                <BusinessViewPicker
                  selectedItems={selectedBusinessViews}
                  allowMultiselect={true}
                  showSuggestions={true}
                  userProfileMetaRestrictions={editRestriction === EditRestriction.EditAllRestrict
                    ? derivedMetaRestrictions
                    : undefined
                  }
                  onApply={setSelectedBusinessViews}
                  isItemDisabledMapper={(item, ancestorPath) => {
                    // If the ancestor of this item is MSP, then disable it.
                    return ancestorPath.some(x => x.code === "MSP")
                      || item.code === "MSP";
                  }}
                  codesToFilter={[]}
                />
              </LabeledControl>
            }

            {allowedChildTypes.some(x => x.childType === MetaDataTypes.BusinessFunction) &&
              <LabeledControl
                label="Business Function (Functions)"
              >
                <BusinessFunctionPicker
                  selectedItems={selectedBusinessFunctions}
                  allowMultiselect={true}
                  showSuggestions={true}
                  userProfileMetaRestrictions={editRestriction === EditRestriction.EditAllRestrict
                    ? derivedMetaRestrictions
                    : undefined
                  }
                  onApply={setSelectedBusinessFunctions}
                />
              </LabeledControl>
            }

            {allowedChildTypes.some(x => x.childType === MetaDataTypes.BusinessTeam) &&
              <LabeledControl
                label="Technology Center (Teams)"
              >
                <BusinessTeamPicker
                  selectedItems={selectedBusinessTeams}
                  allowMultiselect={true}
                  showSuggestions={false}
                  showTechCenterOnly={true}
                  userProfileMetaRestrictions={editRestriction === EditRestriction.EditAllRestrict
                    ? derivedMetaRestrictions
                    : undefined
                  }
                  onApply={setSelectedBusinessTeams}
                />
              </LabeledControl>
            }

            {allowedChildTypes.some(x => x.childType === MetaDataTypes.MobileSite) &&
              <LabeledControl
                label="Mobile Sites"
              >
                <MobileSitePicker
                  selectedItems={selectedMobileSites}
                  selectionMode="Multiple"
                  showSuggestions={false}
                  onSelectionChange={setSelectedMobileSites}
                />
              </LabeledControl>
            }

            {allowedChildTypes
              .filter(x => x.childType === MetaDataTypes.IPMProject
                && x.childSubType !== undefined)
              .map(type => (
                <LabeledControl
                  label={type.childSubType
                    ? type.childSubType.replace("IPM", "IPM ") + "s"
                    : "IPM Projects"
                  }
                  key={type.id}
                >
                  <IPMProjectPicker
                    selectMode="Multiple"
                    ipmProjectType="IPMProject"
                    selectedItems={selectedIPMProjects.filter(x => x.type === type.childSubType)}
                    onSelectionChange={items => setSelectedIPMProjects(selectedIPMProjects
                      .filter(x => x.type !== type.childSubType)
                      .concat(items))
                    }
                  />
                </LabeledControl>
              ))}

            {allowedChildTypes.some(x => x.childType === MetaDataTypes.Supplier) &&
              <LabeledControl
                label="Suppliers"
              >
                <SupplierPicker
                  onSelectionChange={setSelectedSuppliers}
                  selectedItems={selectedSuppliers}
                  selectMode="Multiple"
                />
              </LabeledControl>
            }

            <Hint className="info">
              Once you click the SUBMIT button, the newly added child dimensions will be sorted under the facility as per its alphabetical order.
            </Hint>
          </FlexCol>
        )}
    </Modal>
  );
};

export default AddChildDimensionModal;