import React from "react";
import CircleImgButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import FlexRow from "shared/components/layout/flex/FlexRow";
import deleteIcon from "shared/media/dls/delete.svg";
import { IProfileMetaData } from "shared/types/userProfileTypes";

interface IProfileMetaRowProps {
  metaItem: IProfileMetaData,
  onRemove(metaItem: IProfileMetaData): void,
}

const ProfileMetaRow: React.FC<IProfileMetaRowProps> = ({
  metaItem,
  onRemove,
}) => (
  <FlexRow>
    <CircleImgButton
      alt="Remove"
      icon={deleteIcon}
      type="secondary"
      iconSize="small"
      onClick={() => onRemove(metaItem)}
    />

    <FlexRow>
      <label>
        {metaItem.type}:
      </label>

      {getMetaText(metaItem)}
    </FlexRow>

  </FlexRow>
);

export default ProfileMetaRow;

function getMetaText(metaItem: IProfileMetaData): string {
  if (metaItem.masterDataCode
    && metaItem.masterDataCode !== metaItem.masterDataName) {
    return `${metaItem.masterDataCode} - ${metaItem.masterDataName}`;
  }

  return `${metaItem.masterDataName}`;
}