import { uniqBy } from "lodash";
import React from "react";
import TabSelection, { ITabItem } from "shared/components/layout/tab-selection/TabSelection";
import { ICLMPartialRequirementItemDto } from "types/auditPlanningTypes";
import "./CLMTopicTabs.scoped.scss";

interface ICLMTopicTabsProps {
  requirements: ICLMPartialRequirementItemDto[],
  selectedTopicId: number,
  onTopicChange(topicId: number): void,
}

const CLMTopicTabs: React.FC<ICLMTopicTabsProps> = ({
  requirements,
  selectedTopicId,
  onTopicChange,
}: ICLMTopicTabsProps) => {
  const topicTabs = Object.entries(
    uniqBy(requirements.map(x => ({
      topicId: x.topicId,
      topicName: x.topicName,
    })), x => x.topicId)
  ).map((entry): ITabItem => ({
    key: entry[1].topicId,
    node: entry[1].topicName,
    isSelected: entry[1].topicId === selectedTopicId,
  }));

  return (
    <TabSelection
      tabs={topicTabs}
      onTabClicked={key => onTopicChange(Number(key))}
      showDividers={false}
      className="topic-tabs"
    />
  );
};

export default CLMTopicTabs;