import React, { useEffect, useState } from "react";
import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import pluralize from "shared/utilities/pluralize";
import AppliedFiltersTooltip from "./AppliedFiltersTooltip";
import "./AppliedFilters.scoped.scss";

interface IAppliedFiltersProps {
  /** The content to show in the popup. If numAppliedFilters=0, it will show "0 applied filters" instead. */
  tooltipContent: React.ReactNode,
  /** The number of applied filters to show on the page. If 0, "0 applied filters" will be show in the tooltip. */
  numAppliedFilters: number,
  /** Optional. The max height of the tooltip.
   * After this height, the tooltip scrolls its content.
   * If not specified, tooltip can be any size (even bigger than window). */
  maxTooltipHeight?: number,
  /** Optional. Determines if the tooltip remains after un-hovering. Default = true. */
  isTooltipSticky?: boolean,
}

/**
 * Displays the filter count as a span and adds a hover tooltip that shows extra info.
 */
const AppliedFilters: React.FC<IAppliedFiltersProps> = ({
  tooltipContent,
  numAppliedFilters,
  maxTooltipHeight,
  isTooltipSticky = true,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    // This is a hack to force the tooltip to unrender and then re-render
    // whenever the props change. Otherwise, the tooltip renders on initial
    // load and then never again--even if the props change.
    setIsVisible(false);
    window.setTimeout(() => setIsVisible(true), 1);
  }, [tooltipContent, numAppliedFilters, setIsVisible]);

  const textContent = (
    <span>
      {numAppliedFilters} applied {pluralize(numAppliedFilters, "filter", "filters")}
    </span>
  );

  return (
    <span
      className="applied-filters"
    >
      {isVisible
        ? (
          <TooltipElement
            tooltipContent={numAppliedFilters === 0
              ? <>0 applied filters</>
              : (
                <AppliedFiltersTooltip
                  maxHeight={maxTooltipHeight}
                >
                  {tooltipContent}
                </AppliedFiltersTooltip>
              )
            }
            position={"Below"}
            isSticky={isTooltipSticky}
          >
            {textContent}
          </TooltipElement>
        ) : textContent
      }
    </span >
  );
};

export default AppliedFilters;