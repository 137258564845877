import TagsRecap from "components/audits/manage/tags-recap/TagsRecap";
import { cloneDeep } from "lodash";
import React, { useEffect } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import { IPickerItem } from "shared/types/pickerTypes";
import { mediumScreenMaxWidth, useWindowSize } from "shared/utilities/windowUtilities";
import LabeledControl from "../../labeled-control/LabeledControl";
import SearchBox from "../../search-box/SearchBox";
import { INonReduxPickerProps } from "../Picker";
import PickerList from "../list/PickerList";
import "./PickerModal.scoped.scss";
import "./PickerModal.scss";

interface IPickerModalProps<T> {
  pickerProps: INonReduxPickerProps<T>,
  itemSorter?: (item1: IPickerItem<T>, item2: IPickerItem<T>) => number,

  onClose: () => void,
  onApply: (selItems: IPickerItem<T>[]) => void,
  onCancel: () => void,
  onItemExpanded: (item: IPickerItem<T>, ancestryPath?: (string | number)[]) => void;
  onItemCollapsed: (item: IPickerItem<T>, ancestryPath?: (string | number)[]) => void;

  searchValue: string,
  setSearchValue(newValue: string): void,

  availableItems: IPickerItem<T>[],
  suggestedItems?: IPickerItem<T>[],

  localSelectedItems: IPickerItem<T>[],
  setLocalSelectedItems(newList: IPickerItem<T>[]): void,

  filterItem?: (a: IPickerItem<T>, filterValue: string) => boolean,

  isLoading?: boolean,
  loadError?: string,

  isLoadingSuggestions?: boolean,
  loadSuggestionsError?: string,

  /** Optional. If specified, each item will call this function to determine if it should be enabled or not. */
  isDisabledMapper?: (item: T, ancestorPath: T[], isSelectedItem: boolean) => boolean,

  /** Optional. If specified, this callback will be called whenever the modal opens. */
  onOpen?: () => void,
}

const PickerModal = <T,>(props: IPickerModalProps<T>) => {
  const windowSize = useWindowSize();
  const pickerProps = props.pickerProps;
  const onOpen = props.onOpen;

  useEffect(() => {
    onOpen?.();
  }, [onOpen]);

  const showSearch = pickerProps.searchOptions?.show !== false;
  const itemListDisplayState = {
    showLoading: props.isLoading,
    showError: !props.isLoading && !!props.loadError,
    showItems: !props.isLoading && !props.loadError,
  };

  const suggestionsDisplayState = {
    showLoading: props.isLoadingSuggestions,
    showError: !props.isLoadingSuggestions && !!props.loadSuggestionsError,
    showItems: !props.isLoadingSuggestions && !props.loadSuggestionsError,
  };

  const showSearchBoxInHeader = windowSize.width !== undefined
    && windowSize.width <= mediumScreenMaxWidth;

  const defaultNoItemsMessage = pickerProps.searchOptions?.asyncMinChars
    ? (!props.searchValue.trim()
      ? "Please use the search box to find items."
      : "No items were found. Please use the search box to find items."
    )
    : "No items were found.";

  const searchBox = (
    <SearchBox
      inputProps={{
        placeholder: `Search ${pickerProps.title}`,
        value: props.searchValue,
        onChange: (e) => props.setSearchValue(e.currentTarget.value),
        autoFocus: true
      }}
    />
  );

  const modalHeader = showSearch
    ? (
      <>
        <div
          className="picker-modal-title"
        >
          {pickerProps.title}
        </div>

        {!!pickerProps.subTitle && (
          <div
            className="picker-modal-subtitle"
          >
            {pickerProps.subTitle}
          </div>
        )}

        {showSearchBoxInHeader && (
          <div
            className="header-search-box"
          >
            {searchBox}
          </div>
        )}
      </>
    ) : pickerProps.title;

  let searchLowered = "";
  let visibleItems = props.availableItems;

  if (pickerProps.searchOptions?.behavior !== "async") {
    searchLowered = props.searchValue.trim().toLowerCase();

    visibleItems = filterItems(props.availableItems,
      searchLowered,
      props.filterItem
      ?? ((item, searchTerm) => {
        const ix = item.text?.toLowerCase()?.indexOf(searchTerm);
        return ix !== undefined
          && ix > -1;
      }),
      pickerProps.displayMode === "tree");
  }

  const onItemSelected = (item: IPickerItem<T>) => {
    if (pickerProps.allowMultiSelect) {
      if (!props.localSelectedItems.some(k => k.key === item.key)) {
        props.setLocalSelectedItems([
          ...props.localSelectedItems,
          item,
        ]);
      }
    } else {
      props.setLocalSelectedItems([item]);
    }
  };

  const onItemDeselected = (item: IPickerItem<T>) => {
    props.setLocalSelectedItems([
      ...props.localSelectedItems.filter(k => k.key !== item.key),
    ]);
  };

  const getAvailableSection = () => {
    if (itemListDisplayState.showItems) {
      return (
        <PickerList
          items={visibleItems}
          renderItem={pickerProps.renderListItem}
          allowMultiSelect={pickerProps.allowMultiSelect}
          noItemsMessage={pickerProps.noItemsMessage === undefined
            ? defaultNoItemsMessage
            : pickerProps.noItemsMessage
          }
          selectedItems={props.localSelectedItems}
          displayMode={pickerProps.displayMode || "list"}
          onItemSelected={onItemSelected}
          onItemDeselected={onItemDeselected}
          onItemExpanded={props.onItemExpanded}
          onItemCollapsed={props.onItemCollapsed}
          className="available-item-list"
          isDisabledMapper={props.isDisabledMapper
            ? (item, ancestorPath) => props?.isDisabledMapper?.(item, ancestorPath, false) ?? false
            : undefined
          }
          itemSorter={props.itemSorter}
        />
      );
    } else if (itemListDisplayState.showLoading) {
      return (
        <Spinner />
      );
    } else if (itemListDisplayState.showError) {
      return (
        <Banner
          type={BannerType.error}
        >
          {props.loadError}
        </Banner>
      );
    }
  };


  const getSuggestedSection = () => {
    if (!pickerProps.showSuggestedItems) {
      return undefined;
    }

    let component: React.ReactNode;

    if (suggestionsDisplayState.showItems) {
      component = (
        <PickerList
          items={props.suggestedItems || []}
          renderItem={pickerProps.renderListItem}
          allowMultiSelect={pickerProps.allowMultiSelect}
          noItemsMessage={"No suggestions available."}
          selectedItems={props.localSelectedItems}
          displayMode={"list"}
          onItemSelected={onItemSelected}
          onItemDeselected={onItemDeselected}
          onItemExpanded={props.onItemExpanded}
          onItemCollapsed={props.onItemCollapsed}
          isDisabledMapper={props.isDisabledMapper
            ? (item, ancestorPath) => props?.isDisabledMapper?.(item, ancestorPath, false) ?? false
            : undefined
          }
          itemSorter={props.itemSorter}
        />
      );
    } else if (suggestionsDisplayState.showLoading) {
      component = (
        <Spinner />
      );
    } else if (suggestionsDisplayState.showError) {
      component = (
        <Banner
          type={BannerType.error}
        >
          {props.loadSuggestionsError}
        </Banner>
      );
    }

    return (
      <LabeledControl
        label="Suggested Items"
        className="color-grey1"
      >
        {component}
      </LabeledControl>
    );
  };

  return (
    <Modal
      onCloseButtonClicked={props.onClose}
      showCloseButton={true}
      header={modalHeader}
      isOpen={true}
      className="__picker-modal"
      buttons={[
        {
          className: "secondary",
          key: "CLEAR",
          text: "Clear",
          onClick: () => props.onApply([]),
        },
        {
          className: "secondary",
          key: "CANCEL",
          text: "Cancel",
          onClick: props.onCancel,
        },
        {
          className: "primary",
          key: "APPLY",
          text: "Apply",
          onClick: () => props.onApply(props.localSelectedItems),
        },
      ]}
    >
      <FlexRow
        className="lists-row"
      >
        <FlexCol
          className="available-items-col"
        >
          {!showSearchBoxInHeader &&
            searchBox
          }

          {getAvailableSection()}
        </FlexCol>

        {(pickerProps.showSelectedItems !== false
          || pickerProps.showSuggestedItems) && (
            <>
              <div className="divider"></div>

              <FlexCol
                className="selected-suggested-col"
              >
                {pickerProps.showSelectedItems !== false && (
                  <LabeledControl
                    label="Selected Items"
                    className="color-grey1"
                  >
                    {props.localSelectedItems.length > 0
                      ? (
                        <TagsRecap
                          items={props.localSelectedItems}
                          nameMapper={item => (pickerProps.renderListItem
                            && item.item)
                            ? pickerProps.renderListItem(item.item)
                            : (item.text || "")
                          }
                          onTagClick={onItemDeselected}
                          itemSorter={props.itemSorter}
                          isDisabledMapper={props.isDisabledMapper
                            ? pickerItem => !!props.isDisabledMapper?.(pickerItem.item!, pickerItem.ancestorPath ?? [], true)
                            : undefined
                          }
                        />
                      ) : "none"
                    }
                  </LabeledControl>
                )}

                {getSuggestedSection()}
              </FlexCol>
            </>
          )}
      </FlexRow>
    </Modal>
  );
};

export default PickerModal;

function filterItems<T>(allItems: IPickerItem<T>[],
  searchTerm: string,
  filterItem: (item: IPickerItem<T>, searchTerm: string) => boolean,
  isTree: boolean): IPickerItem<T>[] {
  if (!searchTerm) {
    return allItems;
  }

  if (isTree) {
    // Tree mode filtering.
    let allTreeItems: IPickerItem<T>[] = cloneDeep(allItems);

    for (let i = allTreeItems.length - 1; i >= 0; i--) {
      let treeItem = allTreeItems[i];

      filterChildItems(treeItem,
        searchTerm,
        filterItem);

      if (!(treeItem.children?.length
        || filterItem(treeItem, searchTerm))) {
        // This item has no children that match the filter
        // and it doesn't match the filter.
        // Remove it from the list.
        allTreeItems.splice(i, 1);
      }
    }

    return allTreeItems;
  } else {
    // List mode filtering.
    return allItems.filter(x => filterItem(x, searchTerm));
  }
}

function filterChildItems<T>(item: IPickerItem<T>,
  searchTerm: string,
  filterItem: (item: IPickerItem<T>, searchTerm: string) => boolean) {
  if (!item.children?.length) {
    return;
  }

  let newChildren: IPickerItem<T>[] = [];

  for (let i = 0; i < item.children.length; i++) {
    const childItem = item.children[i];

    // If this item has any children, call this again.
    if (childItem.children?.length) {
      filterChildItems(childItem,
        searchTerm,
        filterItem);

      if (childItem.children.length > 0
        || filterItem(childItem, searchTerm)) {
        // This child item matches the filter or has a child that does.
        newChildren.push(childItem);
      }
    } else {
      if (filterItem(childItem, searchTerm)) {
        // This item matches the filter. Add it to the list for the parent item.
        newChildren.push(childItem);
      }
    }
  }

  item.children = newChildren;
}