import { useState } from "react";
import pluralize from "shared/utilities/pluralize";
import { clearSelectedQuestions } from "store/audit-summary/AuditSummarySlice";
import { assignAuditorToQuestions } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAuditor } from "types/auditingTypes";
import AuditorDropdown from "../auditor-dropdown/AuditorDropdown";
import "./SelectedQuestionsFooter.scoped.scss";

const SelectedQuestionsFooter = () => {
  const auditors = useAppSelector(store => store.audit.audit?.auditors || []);
  const selectedQuestionNumbers = useAppSelector(store => store.auditSummary.selectedAuditQuestionIds);
  const dispatch = useAppDispatch();
  const [selAuditor, setSelAuditor] = useState<IAuditor | undefined>(undefined);

  if (!selectedQuestionNumbers.length) {
    return null;
  }

  return (
    <div>
      <span
        className="count"
      >
        {selectedQuestionNumbers.length} {pluralize(selectedQuestionNumbers.length, "question", "questions")} selected
      </span>

      <AuditorDropdown
        auditors={auditors}
        disabled={false}
        selectedAuditors={[selAuditor]}
        onChange={(auditor) => setSelAuditor(auditor)}
        allowClearButton={true}
      />

      <button
        className="primary"
        onClick={() => {
          dispatch(assignAuditorToQuestions({
            auditorEmail: selAuditor?.email,
            auditQuestionIds: selectedQuestionNumbers,
          }));

          dispatch(clearSelectedQuestions());
        }}
      >
        Assign
      </button>

      <button
        className="secondary"
        onClick={() => dispatch(clearSelectedQuestions())}
      >
        Cancel
      </button>
    </div>
  );
};

export default SelectedQuestionsFooter;