import { Action } from "@reduxjs/toolkit";
import QuestionApi from "api/question/QuestionApi";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { downloadBlob } from "shared/utilities/fileUtilities";
import {
  finishBulkUploadQuestions,
  finishgBulkQuestionsTemplateDownload,
  startBulkQuestionsTemplateDownload,
  startBulkUploadQuestions
} from "./BulkUploadQuestionsSlice";


export function* bulkUploadQuestionsSaga() {
  yield all([
    startBulkUploadQuestionsAsync(),
    startBulkQuestionsTemplateDownloadAsync(),
  ]);
}

function* startBulkUploadQuestionsAsync() {
  yield takeLatest(startBulkUploadQuestions, function* (action: Action) {
    if (!startBulkUploadQuestions.match(action)) {
      return;
    }

    try {

      yield call(QuestionApi.bulkUploadQuestions, action.payload);
      yield put(showSuccessToast("Bulk upload processed."));
      yield put(finishBulkUploadQuestions({
        isWorking: false,
        data: {
          attachments: [],
          isBulkUploadQuestionsModalVisible: false,
        },
      }));

    } catch (err) {
      yield put(showErrorToast(`Failed to process bulk import questions: ${getResponseErrorMessage(err)}`));

      yield put(finishBulkUploadQuestions({
        isWorking: false,
        errorMessage: `Failed to process bulk import questions: ${getResponseErrorMessage(err)}`,
      }));
    }
  });
}

function* startBulkQuestionsTemplateDownloadAsync() {
  yield takeLatest(startBulkQuestionsTemplateDownload, function* (action: Action) {
    try {
      if (!startBulkQuestionsTemplateDownload.match(action)) {
        return;
      }

      const blob: Blob = yield call(QuestionApi.bulkQuestionsTemplateDownload);
      downloadBlob(blob, "Template.xlsm");
      yield put(showSuccessToast("Template downloaded successfully. Please check the Excel file."));
      yield put(finishgBulkQuestionsTemplateDownload());

    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishgBulkQuestionsTemplateDownload());
    }
  });
}
