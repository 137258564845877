import ComplianceResult from "components/audits/common/compliance-result/ComplianceResult";
import { useState } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import CollapseSwitch from "shared/components/controls/collapse-switch/CollapseSwitch";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { IColumnItem } from "shared/types/columnTypes";
import { findActionItemsByParentItems } from "shared/utilities/actionItemUtilities";
import { NonConformanceTabs } from "store/audit-non-conformances/AuditNonConformancesSlice";
import { useAppSelector } from "store/store";
import { ActionItemLinkTypes } from "types/actionItemTypes";
import { IAuditScoreResult } from "types/audit-scores/auditScoreTypes";
import { ComplianceResults } from "types/auditPlanningTypes";
import { IAuditQuestion } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import "./QmsResultCard.scoped.scss";
import ActionItemCount from "./ActionItemCount";

interface IQmsCardItem {
  id: number,
  name: string,
  mainItem: string,
  subItem: string,
}

export interface IQmsTableRow {
  requirementId: number,
  requirementType: string,
  requirementKey: string,
  requirementName: string,
  totalQuestions: number,
  nonCompliantQuestions: number,
  complianceScore?: number,
  complianceResult?: ComplianceResults,
  cat1Score?: number,
  cat2Score?: number,
  numActionItems: number,
  hasChildItems: boolean,
  isChildRow: boolean,
  mainItem: string,
  subItem: string,
}

interface IQmsResultCardProps {
  items: IQmsCardItem[],
  metaDataType: string,
  header: string,
}

const QmsResultCard: React.FC<IQmsResultCardProps> = ({
  items,
  metaDataType,
  header
}) => {
  const selectedComplianceScore = useAppSelector(store => store.auditNonConformance.selectedComplianceTab);
  const auditComplianceScores = useAppSelector(store => store.audit.scores);
  const [expandedItemIds, setExpandedItemIds] = useState<string[]>([]);
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const questions = useAppSelector(store => store.audit.questions);
  const isLoadingScores = useAppSelector(store => store.audit.loadScoresOp?.isWorking);

  let visibleComplianceScores: IAuditScoreResult[] | undefined = undefined;

  if (selectedComplianceScore === NonConformanceTabs.current) {
    visibleComplianceScores = auditComplianceScores?.currentScores?.metaScores;
  } else if (selectedComplianceScore === NonConformanceTabs.complete) {
    visibleComplianceScores = auditComplianceScores?.completedScores?.metaScores;
  } else if (selectedComplianceScore === NonConformanceTabs.closed) {
    visibleComplianceScores = auditComplianceScores?.closedScores?.metaScores;
  }

  const tableRows: IQmsTableRow[] = [];

  const doesQuestionHaveMetaItem = (question: IAuditQuestion, itemId: number): boolean => {
    return metaDataType === MetaDataTypes.QMSElement
      ? question.qmsElements.some(q => q.id === itemId)
      : question.apis.some(q => q.id === itemId);
  };

  for (const item of items) {
    let score = visibleComplianceScores
      ?.filter(x => x.scoreByType === metaDataType
        && x.scoreById === item.id)[0];

    const actionItemsInQMSItem = findActionItemsByParentItems(actionItems,
      questions
        .filter(x => doesQuestionHaveMetaItem(x, item.id))
        .map(x => ({
          linkId: x.auditQuestionId,
          linkType: ActionItemLinkTypes.AuditQuestion,
        })));

    const hasChild = items.some(x => x.mainItem === item.mainItem
      && x.id !== item.id);

    let totalQuestions;

    if (metaDataType === MetaDataTypes.QMSElement) {
      totalQuestions = questions
        .flatMap(x => x.qmsElements)
        .reduce((acc, curr) => curr.id === item.id ? acc + 1 : acc, 0);
    }

    if (metaDataType === MetaDataTypes.API) {
      totalQuestions = questions
        .flatMap(x => x.apis)
        .reduce((acc, curr) => curr.id === item.id ? acc + 1 : acc, 0);
    }

    tableRows.push({
      requirementId: item.id,
      requirementType: metaDataType,
      requirementKey: `${metaDataType}_${item.id}`,
      requirementName: item.name,
      totalQuestions: totalQuestions || 0,
      nonCompliantQuestions: score?.numNonComplianceQuestions || 0,
      complianceScore: score?.complianceScore,
      complianceResult: score?.complianceResult,
      numActionItems: actionItemsInQMSItem.length,
      hasChildItems: hasChild,
      isChildRow: item.mainItem !== item.subItem,
      mainItem: item.mainItem,
      subItem: item.subItem
    });
  }

  const onRowToggle = (item: IQmsTableRow) => setExpandedItemIds(
    expandedItemIds.some(x => x === item.mainItem)
      ? [...expandedItemIds.filter(x => x !== item.mainItem)]
      : [...expandedItemIds, item.mainItem]);

  let requirement = metaDataType === MetaDataTypes.QMSElement
    ? "QMS Elements & Subelements"
    : "API Q2 Elements & Subelements";

  const tableColumns = getQmsResultsTableColumns(expandedItemIds, onRowToggle, requirement);

  const tableRowsData = tableRows
    .filter(x => x.mainItem === x.subItem
      || expandedItemIds.some(p => p === x.mainItem))
    .sort((a, b) => a.requirementName < b.requirementName ? -1 : 1);

  return (
    <Card
      showHeader={true}
      cardStateId={`auditQmsResults_${items[0]?.id}`}
      defaultCollapsedState={true}
      headerElement={(
        <div className="audit-result-labels">
          <div className="audit-result-display">
            <LabeledControl
              label=""
              isLight={true}
              labelContentExtraClass="label-heading5"
            >
              {header}
            </LabeledControl>
          </div>
        </div>
      )}
    >
      {isLoadingScores
        ? <Spinner />
        : (
          <Table<(IQmsTableRow), keyof (IQmsTableRow), void>
            data={tableRowsData}
            columns={tableColumns}
            keyProp="requirementKey"
            theme={TableThemes.compact}
          />
        )}
    </Card>
  );
};

export default QmsResultCard;

function getQmsResultsTableColumns(expandedRowKeys: string[],
  onRowToggle?: (item: (IQmsTableRow)) => void,
  requirement?: string): IColumnItem<(IQmsTableRow),
    keyof (IQmsTableRow),
    void>[] {

  return [
    {
      key: "toggle",
      header: "",
      width: 0,
      customRender: onRowToggle
        ? ((item) =>
          !item.isChildRow
            && item.hasChildItems
            ? (
              <CollapseSwitch
                className="header-collapse-switch"
                isOpen={expandedRowKeys.some(x => x === item.mainItem)}
                onClick={() => onRowToggle(item)}
              />
            ) : null
        ) : undefined,
    },
    {
      key: "requirementName",
      property: "requirementName",
      header: requirement || "Requirement",
      width: 350,
      maxHeight: "4em",
      customRender: item => item.isChildRow ? item.requirementName : <b>{item.requirementName}</b>
    },
    {
      key: "totalQuestions",
      property: "totalQuestions",
      header: "Total Questions",
      width: 100,
    },
    {
      key: "nonCompliantQuestions",
      property: "nonCompliantQuestions",
      header: "Non-Compliance Questions",
      width: 150,
    },
    {
      key: "complianceScore",
      header: "Compliance Score",
      width: 100,
      customRender: item =>
        item.complianceScore === undefined
          ? "-"
          : Math.floor(item.complianceScore * 100) + "%"
    },
    {
      key: "complianceResult",
      header: "Compliance",
      width: 150,
      customRender: item =>
        <ComplianceResult
          complianceResult={item.complianceResult}
        />
    },
    {
      key: "numActionItems",
      header: "# Action Items",
      width: 100,
      customRender: item => (
        <ActionItemCount
      complianceScore={item.complianceScore}
      numActionItems={item.numActionItems}
    />
        ),
    },
  ];
};