import { toTitleCase } from "shared/utilities/stringUtilities";
import AuditPlanCalendarStatusFilterOptions from "./AuditPlanCalendarStatusFilterOptions";

export function auditPlanCalendarStatusFilterToString(status: AuditPlanCalendarStatusFilterOptions) {
  switch (status) {
    case AuditPlanCalendarStatusFilterOptions.RecommendedApproved:
      return "Recommended/Approved";
    case AuditPlanCalendarStatusFilterOptions.PlannedInProgress:
      return "Planned/InProgress";
    default:
      return toTitleCase(status.toString()) || status.toString();
  }
}