import { addIfAny, addIfAnyDefined, addIfNotEmpty, dateToString, metaDataToQueryParam, simpleListToMetaDataQueryParam, simpleListToQueryParam, simplePickerToQueryParam, stringToQueryParam } from 'components/search/helpers/searchQueryHelpers';
import { ISearchAuditsState } from 'store/search/audits/SearchAuditsSlice';
import { useAppSelector } from 'store/store';
import { MetaDataTypes } from 'types/masterDataTypes';

const useAuditSearchValues = () => {
  const state = useAppSelector(store => store.searchAudits);

  return {
    queryString: getAuditSearchQueryString(state),
    filterCount: getFilterCount(state),
  };
};

export default useAuditSearchValues;

function getAuditSearchQueryString(state: ISearchAuditsState) {
  let queryStringParameters: string[] = [];
  let metaDataCount = -1;

  stringToQueryParam(state.auditId, "AuditId", queryStringParameters);
  stringToQueryParam(state.auditName, "AuditName", queryStringParameters);
  stringToQueryParam(state.allActionsClosed?.toString(), "AllActionsClosed", queryStringParameters);
  stringToQueryParam(state.auditIdentifier, "AuditIdentifier", queryStringParameters);
  stringToQueryParam(dateToString(state.completionDateStart, true), "CompletionDateStart", queryStringParameters);
  stringToQueryParam(dateToString(state.completionDateEnd, false), "CompletionDateEnd", queryStringParameters);
  stringToQueryParam(dateToString(state.startDateStart, true), "StartDateStart", queryStringParameters);
  stringToQueryParam(dateToString(state.startDateEnd, false), "StartDateEnd", queryStringParameters);
  stringToQueryParam(dateToString(state.endDateStart, true), "EndDateStart", queryStringParameters);
  stringToQueryParam(dateToString(state.endDateEnd, false), "EndDateEnd", queryStringParameters);
  stringToQueryParam(dateToString(state.closureDateStart, true), "ClosureDateStart", queryStringParameters);
  stringToQueryParam(dateToString(state.closureDateEnd, false), "ClosureDateEnd", queryStringParameters);
  stringToQueryParam(state.pickerData.auditTypes.selectedItems?.[0]?.key.toString(), "AuditTypeId", queryStringParameters);
  stringToQueryParam(state.pickerData.auditGroups.selectedItems?.[0]?.key.toString(), "AuditGroupId", queryStringParameters);
  stringToQueryParam(state.pickerData.ownerGroups.selectedItems?.[0]?.key.toString(), "OwnerGroupId", queryStringParameters);

  simplePickerToQueryParam(state.pickerData.leadAuditors, "LeadAuditorEmails", queryStringParameters);
  simplePickerToQueryParam(state.pickerData.auditors, "AuditorEmails", queryStringParameters);

  simpleListToQueryParam(state.auditPlanYear, "AuditPlanYears", queryStringParameters);
  simpleListToQueryParam(state.status.map(x => x.toString()) || [], "Statuses", queryStringParameters);
  simpleListToQueryParam(state.complianceAtCompletion.map(x => x.toString()) || [], "CompletedComplianceResults", queryStringParameters);
  simpleListToQueryParam(state.complianceAtClosure.map(x => x.toString()) || [], "ClosedComplianceResults", queryStringParameters);

  metaDataCount = metaDataToQueryParam(state.pickerData.businessViews,
    _ => MetaDataTypes.BusinessView,
    metaDataCount,
    queryStringParameters,
    "MetaData");
  metaDataCount = metaDataToQueryParam(state.pickerData.countries,
    _ => MetaDataTypes.Country,
    metaDataCount,
    queryStringParameters,
    "MetaData");
  metaDataCount = metaDataToQueryParam(state.pickerData.businessTeams,
    _ => MetaDataTypes.BusinessTeam,
    metaDataCount,
    queryStringParameters,
    "MetaData");
  metaDataCount = metaDataToQueryParam(state.pickerData.businessFunctions,
    _ => MetaDataTypes.BusinessFunction,
    metaDataCount,
    queryStringParameters,
    "MetaData");
  metaDataCount = metaDataToQueryParam(state.pickerData.auditTopics,
    _ => MetaDataTypes.AuditTopic,
    metaDataCount,
    queryStringParameters,
    "MetaData");
  metaDataCount = simpleListToMetaDataQueryParam(state.mobileSites.map(x => x.id.toString()),
    MetaDataTypes.MobileSite,
    metaDataCount,
    queryStringParameters,
    "MetaData");
  simpleListToMetaDataQueryParam(state.facilities.map(x => x.id.toString()),
    MetaDataTypes.Facility,
    metaDataCount,
    queryStringParameters,
    "MetaData");

  return queryStringParameters.length === 0
    ? ""
    : `?${queryStringParameters.join('&')}`;
}

function getFilterCount(state: ISearchAuditsState) {
  let count = 0;

  Object.entries(state.pickerData)
    .forEach(entry => {
      if (entry[1].selectedItems.length > 0) {
        count++;
      }
    });

  count = addIfNotEmpty(count, state.auditId);
  count = addIfAnyDefined(count, state.startDateStart, state.startDateEnd);
  count = addIfAnyDefined(count, state.endDateStart, state.endDateEnd);
  count = addIfAnyDefined(count, state.completionDateStart, state.completionDateEnd);
  count = addIfAnyDefined(count, state.closureDateStart, state.closureDateEnd);
  count = addIfNotEmpty(count, state.auditName);
  count = addIfNotEmpty(count, state.auditIdentifier);
  count = addIfAny(count, state.mobileSites);
  count = addIfAny(count, state.facilities);
  count = addIfAny(count, state.auditPlanYear);
  count = addIfAny(count, state.complianceAtCompletion);
  count = addIfAny(count, state.complianceAtClosure);
  count = addIfAny(count, state.status);
  count = addIfAnyDefined(count, state.allActionsClosed);

  return count;
}