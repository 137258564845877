import React from "react";
import Modal, { IModalButton } from "shared/components/layout/modal/Modal";

interface IConfirmModalProps {
  /**
   * Header to show. If not specified, no header will be shown.
   */
  header?: React.ReactNode,
  /**
   * Message to show.
   */
  message: React.ReactNode,
  yesButtonOptions?: {
    /**
     * Whether to show the yes button.
     * Defaults to true.
     */
    show?: boolean,
    /**
     * Custom text to show in the button.
     * Defaults to 'Yes'.
     */
    text?: string,
  },
  noButtonOptions?: {
    /**
     * Whether to show the no button.
     * Defaults to true.
     */
    show?: boolean,
    /**
     * Custom text to show in the button.
     * Defaults to 'No'.
     */
    text?: string,
  },
  cancelButtonOptions?: {
    /**
     * Whether to show the cancel button.
     * Defaults to false.
     */
    show?: boolean,
    /**
     * Custom text to show in the button.
     * Defaults to 'Cancel'.
     */
    text?: string,
  },
  onYesClicked?: () => void,
  onNoClicked?: () => void,
  onCancelClicked?: () => void,
  isWorking?: boolean,
  /** An optional props to pass children */
  children?: React.ReactNode,
  showCloseButton?: boolean,
  onCloseButtonClicked?(): void,
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  header,
  message,
  yesButtonOptions,
  noButtonOptions,
  cancelButtonOptions,
  onYesClicked,
  onNoClicked,
  onCancelClicked,
  isWorking,
  showCloseButton,
  onCloseButtonClicked,
}) => {
  let buttons: (IModalButton & React.ButtonHTMLAttributes<HTMLButtonElement>)[] = [];

  if (cancelButtonOptions?.show) {
    buttons.push({
      key: "CANCEL",
      text: cancelButtonOptions?.text || "Cancel",
      onClick: onCancelClicked,
      className: "tertiary"
    });
  }

  if (noButtonOptions?.show !== false) {
    buttons.push({
      key: "NO",
      text: noButtonOptions?.text || "No",
      onClick: onNoClicked,
      className: "secondary"
    });
  }

  if (yesButtonOptions?.show !== false) {
    buttons.push({
      key: "YES",
      text: yesButtonOptions?.text || "Yes",
      disabled: isWorking,
      onClick: onYesClicked,
      className: "primary"
    });
  }

  return (
    <Modal
      header={header}
      isOpen={true}
      buttons={buttons}
      showCloseButton={showCloseButton}
      onCloseButtonClicked={onCloseButtonClicked}
    >
      {message}
    </Modal>
  );
};

export default ConfirmModal;