import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";

export interface IGridState {
  generateExportFileOp?: IOperation<string>,
  gridIdToClearFilters: string,
  gridIdToRefresh: string,
}

const initialState: IGridState = {
  generateExportFileOp: undefined,
  gridIdToClearFilters: "",
  gridIdToRefresh: "",
};

export const gridSlice = createSlice({
  name: "grid",
  initialState,
  reducers: {
    resetState: state => {
      Object.assign(state, initialState);
    },
    generateGridExportFile: (state, action: PayloadAction<{ gridId: string; }>) => {
      state.generateExportFileOp = {
        isWorking: true,
        data: action.payload.gridId,
      };
    },
    finishGenerateGridExportFile: state => {
      state.generateExportFileOp = undefined;
    },
    clearGridFilters: (state, action: PayloadAction<{ gridId: string, }>) => {
      state.gridIdToClearFilters = action.payload.gridId;
    },
    refreshGrid: (state, action: PayloadAction<{ gridId: string; }>) => {
      state.gridIdToRefresh = action.payload.gridId;
    },
  },
});

export const {
  resetState,
  generateGridExportFile,
  finishGenerateGridExportFile,
  clearGridFilters,
  refreshGrid,
} = gridSlice.actions;
