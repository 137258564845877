import React from "react";
import { ActionItemStatuses, IActionItemType } from "types/actionItemTypes";
import validated from "shared/media/icons/action-items/validation/Validated.svg";
import pending from "shared/media/icons/action-items/validation/ValidationPending.svg";

import "./ActionItemValidationStatus.scoped.scss";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";

interface IActionItemValidationStatusProps {
  validationData: IActionItemData,
}

export interface IActionItemData {
  status: ActionItemStatuses,
  type?: IActionItemType,
  isValidated: boolean,
}

const ActionItemValidationStatus: React.FC<IActionItemValidationStatusProps> = ({
  validationData: {
    status,
    type,
    isValidated,
  },
}) => {
  // Show nothing at all if the action item is still open
  // or if the action item is Administrative.
  if (status !== ActionItemStatuses.Closed
    || type?.name === "Administrative") {
    return null;
  }

  const text = isValidated ? "Validated" : "Pending";

  return (
    <LabeledControl
      label={text}
      className="label"
    >
      <img
        src={isValidated ? validated : pending}
        alt={text}
      />
    </LabeledControl>
  );
};

export default ActionItemValidationStatus;
