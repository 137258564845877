import { cloneDeep } from "lodash";
import React, { ButtonHTMLAttributes, useEffect, useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import CircleImageButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal, { IModalButton } from "shared/components/layout/modal/Modal";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import attIcon from "shared/media/dls/attachment.svg";
import deleteIcon from "shared/media/dls/delete.svg";
import downloadIcon from "shared/media/dls/download.svg";
import editIcon from "shared/media/dls/edit-1.svg";
import linkIcon from "shared/media/dls/link.svg";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IColumnItem } from "shared/types/columnTypes";
import { IOperation } from "shared/types/operationTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import IEvidenceItem from "../IEvidenceItem";
import ManageEvidenceItemModal from "../manage/ManageEvidenceItemModal";
import { useDispatch } from "react-redux";
import "./EvidenceListModal.scoped.scss";

export interface IEvidenceListModalProps {
  /** The text to show in the header of the modal. Defaults to \"Evidence List". */
  header?: string,
  /** If true, the ADD EVIDENCE button is available, else disabled. */
  allowAdd: boolean,
  /** If true, the EDIT EVIDENCE button is available, else disabled. */
  allowEdit: boolean,
  /** If true, the DELETE EVIDENCE button is available, else disabled. */
  allowDelete: boolean,
  /** If true, show header, USED IN EVIDENCE CARD */
  isEvidenceCard?: boolean,
  /** Sets the mode of the modal. Default = Management. */
  mode?: EvidenceListModalModes,
  /** The function to call when the user closes the modal. */
  onClose(): void,
  /** The async function to call that will return the list of evidence items. */
  getEvidenceItems(abortSignal: AbortSignal): Promise<IEvidenceItem[]>,
  /** The function to call to retrieve the blob url for an evidence item. */
  getActionItemEvidenceFileBlobUrl?(item: IEvidenceItem): Promise<string>,
  /** The function to call to delete an evidence item. */
  deleteEvidenceItem?(item: IEvidenceItem): Promise<void>,
  /** The function to call when the user attempts to save an existing (id != 0)
   * or new (id == 0) evidence item. A new item will also supply the File parameter
   * that needs to be uploaded to the server. Returns the id of the saved evidence item. */
  saveEvidenceItem?(item: IEvidenceItem, content?: File | string): Promise<number>,
  /** The function to call to determine if a user should be able to edit or delete an evidence item. This overrides the allowEdit and allowDelete for individual items. */
  isItemEditable?(item: IEvidenceItem): boolean,
}

export enum EvidenceListModalModes {
  /** Management mode. Allows editing & deleting items in the list. */
  Management = "Management",
  /** Selection mode. Allows selecting one or more items and returns the selected list. */
  Selection = "Selection",
}

const EvidenceListModal: React.FC<IEvidenceListModalProps> = ({
  header = "Evidence List",
  getEvidenceItems,
  allowAdd,
  allowEdit,
  allowDelete,
  isEvidenceCard = false,
  mode = EvidenceListModalModes.Management,
  onClose,
  deleteEvidenceItem,
  getActionItemEvidenceFileBlobUrl,
  saveEvidenceItem,
  isItemEditable,
}) => {
  const dispatch = useDispatch();
  const [loadListOp, setLoadListOp] = useState<IOperation<IEvidenceItem[]>>({
    isWorking: false,
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | undefined>();
  const [manageModalData, setManageModalData] = useState<{
    editingId: number | undefined,
    isOpen: boolean,
  }>({
    editingId: undefined,
    isOpen: false,
  });

  useEffect(() => {
    let aborted = false;
    let abortController = new AbortController();

    const getItems = async () => {
      if (aborted) {
        return;
      }

      setLoadListOp({
        isWorking: true,
      });

      try {
        const items = await getEvidenceItems(abortController.signal);

        if (aborted) {
          return;
        }

        setLoadListOp({
          isWorking: false,
          data: items,
        });
      } catch (err) {
        if (aborted) {
          return;
        }

        setLoadListOp({
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        });
      }
    };

    getItems();

    return () => {
      // Tell the async request to abort.
      aborted = true;
      abortController.abort();
    };
  }, [getEvidenceItems, setLoadListOp]);

  const modalButtons: (IModalButton & ButtonHTMLAttributes<HTMLButtonElement>)[] = [{
    text: "Close",
    className: "secondary",
    key: "CLOSE",
    onClick: onClose,
  }];

  if (allowAdd) {
    modalButtons.push({
      text: (
        <>
          +
          <img
            alt=""
            src={attIcon}
            className="icon-small"
          />
          Add Evidence
        </>
      ),
      className: "primary add-evidence",
      key: "ADDEVIDENCE",
      disabled: loadListOp.isWorking
        || !!loadListOp.errorMessage,
      onClick: () => setManageModalData({
        editingId: undefined,
        isOpen: true,
      }),
    });
  }

  const deleteItem = async (deleteId: number) => {
    try {
      const item = loadListOp.data?.find(x => x.id === deleteId);

      if (!item) {
        throw new Error(`Unknown id: ${deleteId}.`);
      }

      setIsDeleting(true);

      await deleteEvidenceItem?.(item);

      dispatch(showSuccessToast("Evidence deleted successfully."));

      setLoadListOp({
        ...loadListOp,
        data: loadListOp.data?.filter(x => x.id !== deleteId),
      });
      setConfirmDeleteId(undefined);
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      setIsDeleting(false);
    }
  };

  const onDownloadClicked = async (id: number) => {
    try {
      const item = loadListOp.data?.find(x => x.id === id);

      if (!item) {
        throw new Error(`Unknown id: ${id}.`);
      }

      setIsDownloading(true);
      const url = await getActionItemEvidenceFileBlobUrl?.(item);
      window.open(url);
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      setIsDownloading(false);
    }
  };

  const onItemSaved = (item: IEvidenceItem) => {
    const list = cloneDeep(loadListOp.data || []);
    const existingItem = list.find(x => x.id === item.id);

    if (existingItem) {
      // Update the existing item if it's in the list.
      Object.assign(existingItem, item);
    } else {
      // Add the new item since it's not in the list.
      list.push(item);
    }

    // Update the list in the state.
    setLoadListOp({
      ...loadListOp,
      data: list,
    });
  };

  const onSelectionChanged = (evidenceId: number, isSelected: boolean) => {
    if (isSelected && selectedIds.indexOf(evidenceId) === -1) {
      setSelectedIds(selectedIds.concat(evidenceId));
    } else if (!isSelected && selectedIds.indexOf(evidenceId) > -1) {
      setSelectedIds(selectedIds.filter(x => x !== evidenceId));
    }
  };

  const onEditClicked = (id: number) => {
    setManageModalData({
      editingId: id,
      isOpen: true,
    });
  };

  const onLinkClicked = async (link: string) => {
    if (!link.match(/^https?:\/\//i)) {
      link = 'https://' + link;
    }

    window.open(link, "_blank");
  };

  const displayConfirmDeleteEvidenceMessage = (): React.ReactNode =>
    <div>
      Are you sure you want to delete this evidence item?
      {(() => {
        const item = loadListOp
          .data
          ?.find(x => x.id === confirmDeleteId);

        if (item) {
          return (
            <div>
              <br />
              <strong>{item.notes}</strong>
              <div>{item.filename ?? item.link}</div>
            </div>
          );
        }
      })()}
    </div>;

  let displayComponent: React.ReactNode | undefined;

  if (loadListOp.isWorking) {
    // The request to the server for evidence is still active.
    displayComponent = <Spinner />;
  } else if (loadListOp.errorMessage) {
    // The request to the server for evidence has failed.
    displayComponent = (
      <Banner
        type={BannerType.error}
      >
        {loadListOp.errorMessage}
      </Banner>
    );
  } else if (!loadListOp.data
    || !loadListOp.data.length) {
    // The request to the server for evidence completed but there are no
    // evidence items available to show.
    displayComponent = (
      <Banner
        type={BannerType.info}
      >
        There are no existing evidence items.
      </Banner>
    );
  } else {
    // The request to the server for evidence completed and there are
    // evidence items available to show.
    displayComponent = (
      <Table
        className="evidence-table"
        columns={getEvidenceTableColumn(
          selectedIds,
          mode,
          allowDelete,
          allowEdit,
          isEvidenceCard,
          onSelectionChanged,
          isItemEditable,
          setConfirmDeleteId,
          onDownloadClicked,
          onEditClicked,
          onLinkClicked,
        )}
        data={loadListOp.data}
        keyProp="id"
        theme={TableThemes.compact}
        showHeader={isEvidenceCard}
      />
    );
  }

  return (
    <Modal
      isOpen={true}
      header={header}
      buttons={modalButtons}
      showCloseButton
      onCloseButtonClicked={onClose}
    >
      <FlexCol
        className="evidence-list-col"
      >
        {displayComponent}
      </FlexCol>

      {confirmDeleteId !== undefined && (
        <ConfirmModal
          header="Delete Evidence"
          message={displayConfirmDeleteEvidenceMessage()}
          isWorking={isDeleting}
          onNoClicked={() => setConfirmDeleteId(undefined)}
          onYesClicked={() => deleteItem(confirmDeleteId)}
        />
      )}

      {manageModalData.isOpen && (
        <ManageEvidenceItemModal
          existingEvidenceFilenames={loadListOp.data?.map(x => x.filename!) || []}
          managedEvidence={loadListOp.data?.find(x => x.id === manageModalData.editingId)}
          onClose={() => setManageModalData({ editingId: undefined, isOpen: false })}
          saveItem={saveEvidenceItem
            ? saveEvidenceItem
            : async () => 0
          }
          onItemSaved={onItemSaved}
        />
      )}

      {(isDeleting
        || isDownloading) && (
          <ModalSpinner />
        )}
    </Modal>
  )
};

export default EvidenceListModal;

export function getEvidenceTableColumn(
  selectedIds: number[],
  mode: EvidenceListModalModes,
  allowDelete: boolean,
  allowEdit: boolean,
  isEvidenceCard: boolean,
  onSelectionChanged: (evidenceId: number, isSelected: boolean) => void,
  isItemEditable: ((item: IEvidenceItem) => boolean) | undefined,
  onDeleteClicked: React.Dispatch<React.SetStateAction<number | undefined>>,
  onDownloadClicked: (id: number) => Promise<void>,
  onEditClicked: (id: number) => void,
  onLinkClicked: (link: string) => Promise<void>,)
  : IColumnItem<IEvidenceItem, keyof IEvidenceItem>[] {
  if (isEvidenceCard) {
    const validateURL = (link: string) => {
      if (link.indexOf("http://") === 0 || link.indexOf("https://") === 0) {
        return link;
      } else {
        return "//" + link;
      }
    }

    return [
      {
        key: "leftCommandCell",
        header: "",
        width: 50,
        customRender: item => {
          if (mode === EvidenceListModalModes.Selection
            && onSelectionChanged) {
            return (
              <input
                type="checkbox"
                checked={selectedIds.some(x => x === item.id)}
                onChange={onSelectionChanged
                  ? e => onSelectionChanged(item.id, e.currentTarget.checked)
                  : undefined
                }
              />
            );
          }

          if (mode === EvidenceListModalModes.Management
            && onEditClicked) {
            return (
              <CircleImageButton
                alt="Edit"
                icon={editIcon}
                type="primary"
                iconSize="small"
                onClick={() => onEditClicked(item.id)}
                tooltip={"Edit"}
                isDisabled={!isItemEditable?.(item)}

              />
            );
          }
        }
      },
      {
        key: "Id",
        header: "ID",
        width: 50,
        customRender: item =>
          item.id
        ,
      },
      {
        key: "notes",
        header: "Description",
        width: 300,
        customRender: item => (
          <span title={item.notes} className="notes">{item.notes}</span>
        ),
      },
      {
        key: "evidence-file-or-link-column",
        header: "File / Link",
        width: 300,
        customRender: item => (
          item.type === 'Link' && item.link ?
            <span
              className="evidence-file-or-link-column like-as-link">
              <a
                rel="noreferrer"
                target="_blank"
                href={validateURL(item.link)}
                title={item.link}>
                {item.link}
              </a>
            </span>
            :
            <span
              className="evidence-file-or-link-column like-as-link"
              title={item.filename ? item.filename : item.link}
              onClick={() => onDownloadClicked(item.id)}
            >
              {item.filename ? item.filename : item.link}
            </span>
        ),
      },
      {
        key: "otherButtons",
        header: "",
        customRender: item => (
          <div style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1em",
          }}>
            {onDeleteClicked
              && mode === EvidenceListModalModes.Management
              && (
                <CircleImageButton
                  alt="Delete"
                  icon={deleteIcon}
                  type="secondary"
                  iconSize="small"
                  onClick={() => onDeleteClicked(item.id)}
                  tooltip={"Delete"}
                  isDisabled={!isItemEditable?.(item)}
                />
              )}
          </div>
        ),
      },
    ]
  } else {
    return [
      {
        key: "leftCommandCell",
        header: "",
        width: 50,
        customRender: item => {
          if (mode === EvidenceListModalModes.Selection
            && onSelectionChanged) {
            return (
              <input
                type="checkbox"
                checked={selectedIds.some(x => x === item.id)}
                onChange={onSelectionChanged
                  ? e => onSelectionChanged(item.id, e.currentTarget.checked)
                  : undefined
                }
              />
            );
          }

          if (((isItemEditable === undefined && allowEdit) || isItemEditable?.(item))
            && mode === EvidenceListModalModes.Management
            && onEditClicked) {
            return (
              <CircleImageButton
                alt="Edit"
                icon={editIcon}
                type="secondary"
                iconSize="small"
                onClick={() => onEditClicked(item.id)}
                tooltip={"Edit"}
              />
            );
          }
        }
      },
      {
        key: "text",
        header: "",
        width: 300,
        customRender: item => (
          <div style={{
            display: "flex",
            flexDirection: "column",
          }}>
            <strong title={item.notes} className="notes">{item.notes}</strong>

            {item.type === "Evidence"
              && <span title={item.filename} className="evidence-file-or-link-column">{item.filename}</span>}

            {item.type === "Link"
              && <span title={item.link} className="evidence-file-or-link-column">{item.link}</span>}
          </div>
        ),
      },
      {
        key: "otherButtons",
        header: "",
        customRender: item => (
          <div style={{
            display: "flex",
            gap: "1em",
          }}>
            {onDeleteClicked
              && ((isItemEditable === undefined && allowDelete)
                || isItemEditable?.(item))
              && mode === EvidenceListModalModes.Management
              && (
                <CircleImageButton
                  alt="Delete"
                  icon={deleteIcon}
                  type="secondary"
                  iconSize="small"
                  onClick={() => onDeleteClicked(item.id)}
                  tooltip={"Delete"}
                  isDisabled={!allowDelete}
                />
              )}
            {onDownloadClicked
              && item.type === "Evidence"
              && (<CircleImageButton
                alt="Download"
                icon={downloadIcon}
                type="secondary"
                iconSize="small"
                onClick={() => onDownloadClicked(item.id)}
                tooltip={"Download"}
              />
              )}
            {onLinkClicked
              && item.type === "Link"
              && (<CircleImageButton
                alt="Link"
                icon={linkIcon}
                type="secondary"
                iconSize="small"
                onClick={() => onLinkClicked(item.link!)}
                tooltip={"Link"}
              />
              )}
          </div>
        ),
      },
    ];
  }
}
