import { IAdminDefaultPlanningPeriod, PeriodMode } from "types/adminPlanningAdministration";
import { IAuditPlanningConfig } from "types/auditPlanningTypes";

export function formatAuditPlanningConfig(obj: any): IAuditPlanningConfig {
  return {
    type: obj.type?.toString() || "",
    name: obj.name?.toString() || "",
    value: obj.value?.toString(),
  };
}

export function formatDateFromAuditPlanningConfigItem(defaultDayMonth: string | undefined, year: number): Date | undefined {
  if (!defaultDayMonth) {
    return undefined;
  }

  const dayMonth = defaultDayMonth.split("/");
  const day = Number(dayMonth[0]);
  const month = Number(dayMonth[1]);

  // Since javascript dates use a 0-based month, need to subtract 1 from it here.
  return new Date(year, month - 1, day);
}

export function parsePlanningConfigMode(configItem?: IAuditPlanningConfig): PeriodMode {
  const serverMode = configItem?.value;

  if (serverMode === "PerGeoUnit"
    || serverMode === "Global") {
    return serverMode;
  } else {
    // The server has an unknown config value for the mode.
    return "PerGeoUnit";
  }
}

export function parsePlanningConfigDefaultDates(configItems: IAuditPlanningConfig[]): IAdminDefaultPlanningPeriod {
  const currentYear = new Date().getFullYear();

  const startConfig = configItems.find(x => x.name === "DefaultStart");
  const endConfig = configItems.find(x => x.name === "DefaultEnd");

  // Get the default start date (which should be June 1st).
  const defaultStartDate = formatDateFromAuditPlanningConfigItem(startConfig?.value, currentYear)
    || new Date(currentYear, 5, 1);

  // Get the default end date (which should be September 30th).
  const defaultEndDate = formatDateFromAuditPlanningConfigItem(endConfig?.value, currentYear)
    || new Date(currentYear, 8, 30);

  return {
    defaultStartDate,
    defaultEndDate,
  };
}