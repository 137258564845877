import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import help from 'shared/media/dls/help.svg';
import "./UserHelp.scoped.scss";

const UserHelp: React.FC = () => {
  return (
    <TooltipElement
      tooltipString="View Help"
    >
      <a
        className="user-help-icon-link"
        href="https://slb001.sharepoint.com/sites/QUESTIntelligence/SitePages/QI-Audit-Help.aspx"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="View Help"
          src={help}
          className="user-help-icon icon-medium"
        />
      </a>
    </TooltipElement>
  );
};

export default UserHelp;