import { IPickerItem, IPickerState } from "shared/types/pickerTypes";

export function sanitizeQSParameter(value: string | number) {
  return encodeURIComponent(value.toString());
}

export function stringToQueryParam(value: string | undefined,
  paramName: string,
  listToAppend: string[]) {
  if (value?.trim().length) {
    listToAppend.push(`${paramName}=${sanitizeQSParameter(value.trim())}`);
  }
}

export function simplePickerToQueryParam(pickerState: IPickerState<any>,
  paramName: string,
  listToAppend: string[]) {
  listToAppend.push(
    ...pickerState.selectedItems.map(x => `${paramName}=${sanitizeQSParameter(x.key)}`),
  );
}

export function simpleListToQueryParam(selectedItems: string[] | number[],
  paramName: string,
  listToAppend: string[]) {
  listToAppend.push(
    ...selectedItems.map(x => `${paramName}=${sanitizeQSParameter(x)}`),
  );
}

export function metaDataToQueryParam<T>(pickerState: IPickerState<T>,
  getItemType: (item: IPickerItem<T>) => string,
  metaDataCount: number,
  listToAppend: string[],
  paramName: string) {
  pickerState.selectedItems.forEach(item => {
    const ix = ++metaDataCount;

    listToAppend.push(`${paramName}[${ix}].Type=${sanitizeQSParameter(getItemType(item))}`);
    listToAppend.push(`${paramName}[${ix}].Id=${sanitizeQSParameter(item.key)}`);
  });
  return metaDataCount;
}

export function simpleListToMetaDataQueryParam(values: string[],
  type: string,
  metaDataCount: number,
  listToAppend: string[],
  paramName: string) {
  values.forEach(value => {
    const ix = ++metaDataCount;

    listToAppend.push(`${paramName}[${ix}].Type=${sanitizeQSParameter(type)}`);
    listToAppend.push(`${paramName}[${ix}].Id=${sanitizeQSParameter(value)}`);
  });
  return metaDataCount;
}

export function dateToString(timestamp: number | undefined,
  setToMidnight: boolean): string | undefined {
  if (timestamp === undefined) {
    return undefined;
  }

  let date: Date = new Date(timestamp);

  if (setToMidnight) {
    date.setHours(0, 0, 0, 0);
  } else {
    date.setHours(23, 59, 59, 0);
  }

  return JSON.stringify(date).replace(/"/g, "");
}

export function addIfAnyDefined(count: number, ...args: any[]) {
  if (args?.some(x => x !== undefined)) {
    return count + 1;
  }
  return count;
}

export function addIfNotEmpty(count: number, val: string | undefined) {
  if (val?.trim()?.length) {
    return count + 1;
  }
  return count;
}

export function addIfAny(count: number, arr: any[]) {
  if (arr.length) {
    return count + 1;
  }
  return count;
}