/**
 * 
 * @param file The file whose size should be validated.
 * @param maxMBLimit The max size in MB the file should be.
 * @returns a boolean indicating if the file is <= the limit.
 */
export function validateFileSize(file: File, maxMBLimit: number) {
  return file.size <= maxMBLimit * 1024 * 1024;
}

export function downloadBlob(blob: Blob, downloadFileName: string) {
  let fileDownloadurl = URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.href = fileDownloadurl;
  link.download = downloadFileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}