import React from "react";
import {
  Navigate,
  Route,
  Routes as Switch
} from "react-router-dom";
import QoFErrorBoundary from "shared/components/common/qof-error-boundary/QoFErrorBoundary";
import { useAppSelector } from "store/store";
import NotFound from "./NotFound";
import NotImplemented from "./NotImplemented";
import RouteTracker from "./RouteTracker";
import UrlRoutes from "./UrlRoutes";
import ActionRoutes from "./routes/ActionRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import AuditPlanningRoutes from "./routes/AuditPlanningRoutes";
import AuditRoutes from "./routes/AuditRoutes";
import LegacyRoutes from "./routes/LegacyRoutes";
import SearchRoutes from "./routes/SearchRoutes";

const Routing: React.FC = () => {
  const routeMatrix = useAppSelector(store => store.auth.routeMatrix);

  return (
    <QoFErrorBoundary
      isFullPage={false}
    >
      <Switch>
        {AuditRoutes({ routeMatrix })}
        {ActionRoutes({ routeMatrix })}
        {AdminRoutes({})}
        {AuditPlanningRoutes({ routeMatrix })}
        {SearchRoutes({})}
        {LegacyRoutes({})}

        <Route path={UrlRoutes.Root.urlTemplate} element={<Navigate to={UrlRoutes.MyAudits.urlTemplate} />} />
        <Route path={UrlRoutes.NotImplemented.urlTemplate} element={<NotImplemented />} />
        <Route path="*" element={<NotFound />} />
      </Switch >

      <RouteTracker />
    </QoFErrorBoundary>
  );
};

export default Routing;