import { PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IAuditState } from "../AuditSlice";

export interface IAuditSaveChangesState {
  saveChangesOp?: IOperation<void>,
}

export const initialAuditSaveChangesState: IAuditSaveChangesState = {
  saveChangesOp: undefined,
};

const saveChangesReducer = {
  /** Loads the questions for the specified audit id. */
  saveChanges: (state: IAuditState) => {
    state.saveChangesOp = {
      isWorking: true,
    };
  },

  /** Sets the specified save changes operation info into the state. */
  finishSaveChanges: (state: IAuditState, action: PayloadAction<IOperation<void>>) => {
    if (action.payload.errorMessage
      || action.payload.wasSuccessful === false) {
      state.saveChangesOp = action.payload;
      return;
    }

    state.saveChangesOp = undefined;

    state.originalAudit = cloneDeep(state.audit);
    state.originalQuestions = cloneDeep(state.questions);
    
    state.isAuditDirty = false;
    state.areQuestionsDirty = false;
  },
};

export default saveChangesReducer;
