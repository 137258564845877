import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPlanProfile } from "components/audits/planning/facility-attributes/facilityAttributeTypes";
import { IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import { IAuditPlanScore, IAuditPlanView, IPlanProfileAttribute, IPlanRequirementDimension } from "types/auditPlanningTypes";
import { IFacility } from "types/masterDataTypes";
import { IPlanningAttributesState, initialPlanningAttributesState, planningAttributesReducers } from "./reducers/planningAttributesReducers";
import { IAuditPlanningAppliedFilters, IPlanningFiltersState, computeAreFiltersDirty, initialPlanningFiltersState, planningFiltersReducers } from "./reducers/planningFiltersReducers";
import { IPlanningGridState, applyYAxisCollapsedStates, initialPlanningGridState, planningGridReducers } from "./reducers/planningGridReducers";
import { IPlanningPlansState, initialPlanningPlansState, planningPlansReducers } from "./reducers/planningPlansReducers";
import { IPlanningProfilesState, initialPlanningProfilesState, planningProfilesReducers } from "./reducers/planningProfilesReducers";
import { IUserPlanningViewsState, initialUserPlanningViewsState, userPlanningViewsReducers } from "./reducers/userPlanningViewsReducers";

export type IAuditPlanningState =
  IUserPlanningViewsState
  & IPlanningFiltersState
  & IPlanningProfilesState
  & IPlanningAttributesState
  & IPlanningPlansState
  & IPlanningGridState
  & {
    /** Indicates if data has ever been successfully loaded. */
    hasDataEverLoaded: boolean,
    /** Indicates if plan data is currently loading. */
    isPlanningLoading: boolean,
  };

const initialState: IAuditPlanningState = {
  ...initialUserPlanningViewsState,
  ...initialPlanningFiltersState,
  ...initialPlanningProfilesState,
  ...initialPlanningPlansState,
  ...initialPlanningAttributesState,
  ...initialPlanningGridState,

  hasDataEverLoaded: false,
  isPlanningLoading: false,
};

export const auditPlanningSlice = createSlice({
  name: "auditPlanning",
  initialState,
  reducers: {
    ...userPlanningViewsReducers,
    ...planningFiltersReducers,
    ...planningProfilesReducers,
    ...planningPlansReducers,
    ...planningAttributesReducers,
    ...planningGridReducers,

    /** Sets the main spinner for loading planning data. */
    setIsPlanningLoading: (state: IAuditPlanningState, action: PayloadAction<boolean>) => {
      state.isPlanningLoading = action.payload;
    },

    /** Sets all of the main planning data. */
    finishLoadingAllPlanningData: (state: IAuditPlanningState, action: PayloadAction<{
      profiles: IPlanProfile[],
      profileAttributes: IPlanProfileAttribute[],
      facAttrYAxisData: IPlanningItem[],
      planApprovalsResultsYAxisData: IPlanningItem[],
      xAxisData: IPlanRequirementDimension[],
      nonVisibleProfileAttributes: IPlanProfileAttribute[],
      facilities: IFacility[],
      planApprovalsPlans: IAuditPlanView[],
      planResultsPlans: IAuditPlanView[],
      planScores: IAuditPlanScore[],
      appliedFilters: IAuditPlanningAppliedFilters,
    }>) => {
      state.hasDataEverLoaded = true;
      state.profiles = action.payload.profiles;
      state.profileAttributes = action.payload.profileAttributes;
      state.nonVisibleProfileAttributes = action.payload.nonVisibleProfileAttributes;
      state.facAttrYAxisData = action.payload.facAttrYAxisData;
      state.planApprovalsResultsYAxisData = action.payload.planApprovalsResultsYAxisData;
      state.xAxisData = action.payload.xAxisData;
      state.planApprovalsPlans = action.payload.planApprovalsPlans;
      state.planResultsPlans = action.payload.planResultsPlans;
      state.planScores = action.payload.planScores;

      // Now that all the data has been loaded, apply the remembered Y-Axis collapsed states to the YAxis items.
      applyYAxisCollapsedStates(state);

      // Also copy the current filters into the applied filters.
      state.appliedFilters = action.payload.appliedFilters;

      computeAreFiltersDirty(state);
    },
  },
});

export const {
  // Overall Actions.
  setIsPlanningLoading,
  finishLoadingAllPlanningData,

  // User Planning View Actions.
  savePlanningView,
  finishSavePlanningView,
  finishLoadPlanningViewValues,
  setIsDeleteModalOpen,
  deletePlanningView,
  finishDeletePlanningView,
  loadPlanningViews,
  finishLoadPlanningViews,
  setCurrentPlanningView,
  setSaveViewModal,

  // Planning Filters Actions.
  setCurrentFilters,
  clearFilters,
  applyFilters,
  overrideAppliedFilters,

  // Planning Grid Actions.
  addFacAttrYAxisItem,
  toggleYAxisItem,

  // Planning Profiles Actions.
  removeChildProfiles,
  addProfiles,

  // Planning Attributes Actions.
  addAttributes,
  removeAttributes,
  upsertAttributes,

  // Planning Plans Actions.
  setPlanToFinalApproval,
  updatePlansToLinkToAudit,
  addPlan,
  removePlan,
  updatePlanUserSelfAssessmentType,
} = auditPlanningSlice.actions;