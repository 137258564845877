import { IAdminPlanningAdministrationPeriod, IUpsertAdminPlanningAdministrationPeriodRequest, PlanningPeriodsType } from "types/adminPlanningAdministration";
import PlanningAdministrationPeriod from "types/planning-admin/PlanningAdministrationPeriod";

export function formatIAdminPlanningAdministrationPeriod(obj: any): IAdminPlanningAdministrationPeriod {
  return {
    id: obj.id,
    businessTeamId: obj.businessTeamId,
    basin: "",
    geoUnit: "",
    planningAdministrationPeriodDates: new PlanningAdministrationPeriod({
      endDay: obj.endDateDay,
      endMonth: obj.endDateMonth,
      startDay: obj.startDateDay,
      startMonth: obj.startDateMonth
    }),
    manualOverride: obj.manualOverride,
    overrideExpiryDate: new Date(obj.overrideExpiryDate).getTime(),
    modifiedBy: obj.modifiedBy,
    modifiedOn: new Date(obj.modifiedOn).getTime(),
  }
}

export function formatUpsertPeriodsRequest(obj: IAdminPlanningAdministrationPeriod, type:PlanningPeriodsType): IUpsertAdminPlanningAdministrationPeriodRequest {
  return {
    id: obj.id,
    businessTeamId: obj.businessTeamId,
    startDateDay: obj.planningAdministrationPeriodDates.startDay,
    startDateMonth: obj.planningAdministrationPeriodDates.startMonth,
    endDateDay: obj.planningAdministrationPeriodDates.endDay,
    endDateMonth: obj.planningAdministrationPeriodDates.endMonth,
    manualOverride: obj.manualOverride,
    overrideExpiryDate: new Date(obj.overrideExpiryDate),
    modifiedBy: obj.modifiedBy,
    modifiedOn: new Date(obj.modifiedOn),
    type: type,
  }
}
