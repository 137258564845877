import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import { closePicker, collapsePickerItem, expandPickerItem, loadPickerItems, openPicker, setPickerItems, setSelectedPickerItems } from "store/search/audits/SearchAuditsSlice";
import { useAppSelector } from "store/store";
import { IBusinessTeam } from "types/masterDataTypes";

const GLOBAL_OPS_BUSINESSTEAM_ID = 84;

const BusinessTeams: React.FC = () => {
  const businessTeams = useAppSelector(store => store.searchAudits.pickerData.businessTeams);

  let boldedBusinessTeamIds: number[] = [];

  let globalOps = businessTeams.items.map(x => x.item).find(x => x?.id === GLOBAL_OPS_BUSINESSTEAM_ID);
  if (globalOps) {
    boldedBusinessTeamIds.push(globalOps.id);
    boldedBusinessTeamIds.push(...globalOps.children.flatMap(child => [
      child.id,
      ...child.children.flatMap(gc => [gc.id, ...gc.children.flatMap(ggc => ggc.id)])
    ]));
  }

  return (
    <LabeledControl
      label="Business Team (Basin Hierarchy)"
    >
      <Picker<IBusinessTeam>
        pickerState={businessTeams}
        title="Business Team (Basin Hierarchy)"
        renderListItem={(item) => (
          <span className={boldedBusinessTeamIds.indexOf(item.id) > -1 ? "highlighted-business-team" : ""}>
            {item.code} - {item.name}
          </span>
        )}
        renderSelectedItem={(item) => item.code}
        openAction={openPicker}
        loadAction={loadPickerItems}
        closeAction={closePicker}
        setItemsAction={setPickerItems}
        setSelectedItemsAction={setSelectedPickerItems}
        searchOptions={{
          filterItem: (item, searchTerm) => {
            if (`${item.item?.code} - ${item.item?.name}`.toLowerCase().indexOf(searchTerm) > -1) {
              return true;
            }

            return false;
          },
        }}
        preserveItems={true}
        displayMode="tree"
        allowMultiSelect={true}
        expandItemsAction={expandPickerItem}
        collapseItemsAction={collapsePickerItem}
        itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
      />
    </LabeledControl>
  );
};

export default BusinessTeams;