import React from "react";
import QISelect from "shared/components/controls/select/QISelect";
import { userToString } from "shared/utilities/userUtilities";
import { IAuditee } from "types/auditingTypes";
import "./AuditeeDropdown.scoped.scss";

interface IAuditeeDropdownProps {
  auditees: IAuditee[],
  selectedAuditee: IAuditee | undefined,
  onChange: (auditee?: IAuditee) => void,
  maxWidth?: string,
  disabled: boolean,
  className?: string,
}

const AuditeeDropdown: React.FC<IAuditeeDropdownProps> = ({
  auditees,
  selectedAuditee,
  onChange,
  maxWidth,
  disabled,
  className,
}) => {
  const onSelectValueChanged = (selection: string) => {
    if (selection === "Unassigned") {
      onChange(undefined);
    } else {
      const auditee = auditees.find(x => x.email === selection);

      if (!auditee) {
        return;
      }

      onChange(auditee);
    }
  };

  return (
    <QISelect
      items={auditees.map(x => ({
        id: x.email,
        text: userToString(x),
      }))}
      selectedItemIds={selectedAuditee?.email
        ? [selectedAuditee.email]
        : []
      }
      selectMode="single"
      placeholder="Unassigned"
      onChange={selectedItems => onSelectValueChanged(selectedItems[0]?.id?.toString() ?? "Unassigned")}
      className={`auditee-dropdown ${className || ""}`}
      isDisabled={disabled}
      style={maxWidth !== undefined
        ? {
          maxWidth: maxWidth,
        } : undefined
      }
    />
  );
};

export default AuditeeDropdown;