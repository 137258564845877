import React from "react";
import ReactDatePicker from "react-datepicker";
import Portal from "shared/components/layout/portal/Portal";

export enum DatePickerSelectionMode {
  single = "single",
  range = "range",
  month = "month",
  quarter = "quarter",
};

interface IDatePickerProps {
  /** Optional. The currently selected value. Date or timestamp. */
  value?: Date | number | null,
  /** Optional. Called when the user changes the selected value. */
  onChange?(value?: Date | [Date | null, Date | null] | null): void,
  /** Optional. If true, the control is disabled. Default = false. */
  isDisabled?: boolean,
  /** Optional. Extra css class name(s). */
  customCssClass?: string,
  /** Optional. Specifies minimum selectable date. */
  minDate?: Date,
  /** Optional. Specifies maximum selectable date. */
  maxDate?: Date,
  /** Optional. Specifies date display format. */
  dateFormat?: string,
  /** Optional. Forces fixed height to input. */
  fixedHeight?: boolean,
  /** Optional. Displayed text if the picker is empty. */
  placeholderText?: string,
  /** Optional. Determines whether the user selects a `single` date or a `range` of two dates. Default = `single`. */
  selectionMode?: DatePickerSelectionMode,
  /** Optional. If selectionMode is `range`, this will be the start date for the currently selected range. */
  startDate?: Date | null | undefined,
  /** Optional. If selectionMode is `range`, this will be the end date for the currently selected range. */
  endDate?: Date | null | undefined,
  /** Optional. Determines if the value can be cleared. Default = false. */
  isClearable?: boolean,
  /** Optional. Determines display mode. Default = `popup`. */
  displayMode?: "inline" | "popup",
  /** Optional. Determines a different, custom input display which will show the calendar when clicked. */
  customInput?: React.ReactNode,
}

const DatePicker: React.FC<IDatePickerProps> = ({
  value,
  onChange,
  isDisabled,
  customCssClass,
  minDate,
  maxDate,
  dateFormat,
  fixedHeight,
  placeholderText,
  selectionMode,
  startDate,
  endDate,
  isClearable = false,
  displayMode,
  customInput,
}: IDatePickerProps) => {
  const onChangeHandler = (date: Date | [Date | null, Date | null] | null) => {
    if (!onChange) {
      return;
    }

    onChange(date);
  };

  return (
    <ReactDatePicker
      selected={typeof (value) === "number"
        ? new Date(value)
        : value}
      className={`date-picker ${customCssClass ?? ""}`}
      onChange={onChangeHandler}
      popperContainer={Portal}
      disabled={isDisabled}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={getDateFormat(selectionMode, dateFormat)}
      fixedHeight={fixedHeight}
      placeholderText={placeholderText}
      selectsRange={selectionMode === DatePickerSelectionMode.range}
      startDate={startDate}
      endDate={endDate}
      isClearable={isClearable}
      inline={displayMode === "inline"}
      showMonthYearPicker={selectionMode === DatePickerSelectionMode.month}
      customInput={customInput}
    />
  );
};

export default DatePicker;

function getDateFormat(selectionMode?: DatePickerSelectionMode, requestedDateFormat?: string) {
  switch (selectionMode) {
    case DatePickerSelectionMode.month:
      return "MMMM yyyy";
    case DatePickerSelectionMode.quarter:
      return "yyyy, QQQ";
    default:
      return requestedDateFormat;
  }
}