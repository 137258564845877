import UrlRoutes from "components/routing/UrlRoutes";
import React from "react";
import { useMatch } from "react-router-dom";
import TabSelection from "shared/components/layout/tab-selection/TabSelection";

const SearchTabs: React.FC = () =>
  <TabSelection
    className="search-tabs"
    tabs={[{
      key: "Audit",
      node: "Audit",
      isLink: true,
      isSelected: !!useMatch(UrlRoutes.AuditSearchResults.urlTemplate),
      linkUrl: UrlRoutes.AuditSearchResults.urlTemplate,
    }, {
      key: "Action",
      node: "Action",
      isLink: true,
      isSelected: !!useMatch(UrlRoutes.ActionSearchResults.urlTemplate),
      linkUrl: UrlRoutes.ActionSearchResults.urlTemplate,
    }]}
  />;

export default SearchTabs;