import { PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { IOperation } from "shared/types/operationTypes";

export interface IFinding {
  id?: number,
  findingTypeId?: number;
  justification?: string,
  deleted: boolean,
  links: IFindingLink[],
}

export interface IFindingType {
  id: number,
  name: string,
  sortOrder: number,
  answerAssociations: IFindingTypeAnswerAssociation[],
}

export interface IFindingTypeAnswerAssociation {
  scoringSystem: string,
  answerCode: string,
}

export interface IFindingLink {
  id: number,
  findingId: number,
  linkId: number,
  linkType: string,
  deleted: boolean,
}

export enum FindingLinkType {
  AuditQuestion = "AuditQuestion",
}

export interface IFindingState {
  findingModal: IFindingModal,
  findings: IFinding[],
  findingTypes: IFindingType[],
}

export interface IFindingModal {
  isOpen: boolean,
  operation?: IOperation<void>,
  originalFinding?: Partial<IFinding> | undefined,
  currentFinding: Partial<IFinding> | undefined,
  answerCode: string,
  mode: FindingModalModes,
  autoSelectedLinksForRemoval: ISelectedFindingLink[],
}

export interface ISelectedFindingLink {
  linkId: number,
  linkType: string,
}

export enum FindingModalModes {
  Prompt,
  EditCreate,
  Unlink,
}

export const initialFindingState: IFindingState = {
  findingModal: {
    isOpen: false,
    operation: undefined,
    currentFinding: undefined,
    answerCode: "",
    mode: FindingModalModes.Prompt,
    autoSelectedLinksForRemoval: [],
  },
  findings: [],
  findingTypes: [],
};

const findingReducers = {
  /** Opens the finding modal, ready for create/edit. */
  openFindingModal: (state: IFindingState, action: PayloadAction<{
    /** If opening the modal for an edit operation, pass in the existing finding here. */
    finding?: IFinding,
    /** The answerCode of the linked question must be provided to determine which findingTypes are available. */
    answerCode: string,
    /** If specified, these links will automatically be added to the new finding. If a finding is provided in the action, the desiredLinks are ignored. Optional. */
    desiredLinks?: { linkId: number, linkType: string; }[],
    /** The initial mode of the modal. */
    mode: FindingModalModes,
    /** The list of finding links that should be automatically checked if the user opens the UNLINK modal. */
    autoSelectedLinksForRemoval: ISelectedFindingLink[],
  }>) => {
    let currentFinding: Partial<IFinding> = cloneDeep(action.payload.finding) ?? {};
    let originalFinding: Partial<IFinding> = cloneDeep(action.payload.finding) ?? {};

    if (!action.payload.finding
      && action.payload.desiredLinks) {
      currentFinding.links = action.payload.desiredLinks.map(x => ({
        id: 0,
        deleted: false,
        findingId: 0,
        linkId: x.linkId,
        linkType: x.linkType,
      }));
    }

    state.findingModal = {
      isOpen: true,
      currentFinding,
      originalFinding,
      answerCode: action.payload.answerCode,
      mode: action.payload.mode,
      autoSelectedLinksForRemoval: action.payload.autoSelectedLinksForRemoval,
    };
  },

  /** Closes the finding modal. */
  closeFindingModal: (state: IFindingState) => {
    state.findingModal = {
      isOpen: false,
      operation: undefined,
      currentFinding: undefined,
      answerCode: "",
      mode: FindingModalModes.Prompt,
      autoSelectedLinksForRemoval: [],
    };
  },

  /** Sets the properties of the findingModal's current finding. */
  setCurrentFindingProperties: (state: IFindingState, action: PayloadAction<Partial<IFinding>>) => {
    if (!state.findingModal.currentFinding) {
      state.findingModal.currentFinding = {};
    }

    Object.assign(state.findingModal.currentFinding, action.payload);
  },

  /** Adds or replaces the payload finding in the findings state. */
  onSetFinding: (state: IFindingState, action: PayloadAction<IFinding>) => {
    var index = state.findings.findIndex(x => x.id === action.payload.id);
    if (index !== -1) {
      state.findings[index] = action.payload;
    } else {
      state.findings.push(action.payload);
    }
  },

  /** Removes the finding from the findings state. */
  onRemoveFinding: (state: IFindingState, action: PayloadAction<{
    findingId: number,
  }>) => {
    state.findings = state.findings.filter(x => x.id !== action.payload.findingId);
  },

  /** Starts the "save finding" operation & triggers a saga to save the payload to the server. */
  startFindingOp: (state: IFindingState) => {
    state.findingModal.operation = {
      isWorking: true,
    };
  },

  /** Clears the "save finding" operation. */
  finishFindingOp: (state: IFindingState) => {
    state.findingModal.operation = undefined;
  },

  unlinkFindingInState: (state: IFindingState, action: PayloadAction<{
    findingId: number,
    linkId: number,
    linkType: FindingLinkType,
  }>) => {
    const finding = state.findings.find(x => x.id === action.payload.findingId);

    if (!finding) {
      return;
    }

    const findingLink = finding.links.find(x => !x.deleted
      && x.linkId === action.payload.linkId
      && x.linkType === action.payload.linkType);

    if (findingLink) {
      findingLink.deleted = true;
    }
  },

  /** Sets the mode of the finding modal. */
  setFindingModalMode: (state: IFindingState, action: PayloadAction<FindingModalModes>) => {
    state.findingModal.mode = action.payload;
  },
};

export default findingReducers;