import React from "react";
import Hint from "shared/components/common/hint/Hint";
import Button from "shared/components/controls/buttons/button/Button";
import DatePicker from "shared/components/controls/date-picker/DatePicker";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { setQuestionActiveModal, toggleQuestionActive } from "store/question-details/QuestionDetailsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "./QuestionActiveModal.scoped.scss";

const QuestionActiveModal: React.FC = () => {
  const question = useAppSelector(store => store.questionDetails.question);
  const modalState = useAppSelector(store => store.questionDetails.questionActiveModal);
  const dispatch = useAppDispatch();

  if (!modalState.isOpen) {
    return null;
  }

  const modalTitle = question?.isDeleted
    ? "Activate Question"
    : "Deactivate Question";

  const onOkClicked = () => {
    dispatch(toggleQuestionActive({
      isActive: question?.isDeleted || false,
      comment: modalState.reason,
      revertOnTimestamp: modalState.revertOnTimestamp,
    }));
  };

  const closeModal = () => {
    dispatch(setQuestionActiveModal({
      isOpen: false,
    }));
  };

  return (
    <Modal
      isOpen={modalState.isOpen}
      header={modalTitle}
      showCloseButton
      onCloseButtonClicked={closeModal}
      buttons={[{
        key: "CANCEL",
        text: "Cancel",
        className: "secondary",
        onClick: closeModal,
      },
      {
        key: "OK",
        text: question?.isDeleted ? "Activate" : "Deactivate",
        className: "primary",
        disabled: !modalState.reason.trim(),
        onClick: onOkClicked,
      }]}
    >
      <FlexCol>
        <p>
          Are you sure you want to {question?.isDeleted ? "activate" : "deactivate"} this question?
          (The question can be {question?.isDeleted ? "re-activated" : "deactivated"} later.)
          <br />
          Please enter a reason for this {question?.isDeleted ? "activation" : "deactivation"}.
        </p>

        <LabeledControl
          label={`Reason for ${question?.isDeleted ? "Activation" : "Deactivation"}`}
          isRequired
          hint={getTextboxMaxLengthHint(500)}
        >
          <div className="reason-holder">
            <textarea
              value={modalState.reason}
              maxLength={500}
              onChange={e => dispatch(setQuestionActiveModal({
                reason: e.currentTarget.value,
              }))}
            />
            <br />
            <Hint>
              {500 - modalState.reason.length}/500 characters remaining
            </Hint>
          </div>
        </LabeledControl>

        <LabeledControl
          label={`Auto-${question?.isDeleted ? "Deactivate" : "Reactivate"} On`}
        >
          <div className="date-holder">
            <DatePicker
              value={modalState.revertOnTimestamp}
              minDate={new Date()}
              onChange={date => {
                if (!date) {
                  return;
                }

                const dateValue = Array.isArray(date)
                  && date[0]
                  ? date[0]
                  : (!Array.isArray(date)
                    && date
                    ? date
                    : null);

                if (!dateValue) {
                  return;
                }

                dispatch(setQuestionActiveModal({
                  revertOnTimestamp: dateValue.setHours(0, 0, 0, 0),
                }));
              }}
              placeholderText="Select date..."
            />

            {!!modalState.revertOnTimestamp &&
              <Button
                buttonType="secondary"
                onClick={() => dispatch(setQuestionActiveModal({
                  revertOnTimestamp: undefined,
                }))}
              >
                Clear
              </Button>
            }
          </div>
        </LabeledControl>
      </FlexCol>
    </Modal>
  );
};

export default QuestionActiveModal;