import { PublicClientApplication } from "@azure/msal-browser";
import config from "config";
import React, { useEffect } from "react";
import { throwIfResponseError } from "shared/utilities/apiUtilities";

interface IUserSentimentSurveyProps {
  userAlias: string,
  msalPublicClient: PublicClientApplication,
  slbTenantId: string,
  surveyScope: string,
  surveySecretKey: string,
  checkForFeedbackUrl: string,
}

interface ISurveyEligibilityResponse {
  isEligible: boolean,
  urlToRedirect: string,
}

const UserSentimentSurvey: React.FC<IUserSentimentSurveyProps> = ({
  userAlias,
  msalPublicClient,
  slbTenantId,
  surveyScope,
  surveySecretKey,
  checkForFeedbackUrl,
}) => {
  useEffect(() => {
    let aborted = false;
    let controller = new AbortController();

    const runSurveyLogic = async () => {
      try {
        const eligibilityData = await isUserEligibleForFeedback(
          controller.signal,
          userAlias,
          msalPublicClient,
          slbTenantId,
          surveyScope,
          surveySecretKey,
          checkForFeedbackUrl);

        if (aborted) {
          return;
        }

        if (eligibilityData.isEligible) {
          openSurveyPopup(eligibilityData.urlToRedirect);
        }
      } catch (err) {
        console.log("Failed to handle user sentiment survey.", err);
      }
    };

    runSurveyLogic();

    return () => {
      aborted = true;
      controller.abort();
    };
  }, [
    userAlias,
    msalPublicClient,
    slbTenantId,
    surveyScope,
    surveySecretKey,
    checkForFeedbackUrl,
  ]);

  return null;
};

export default UserSentimentSurvey;

async function isUserEligibleForFeedback(abortSignal: AbortSignal,
  userAlias: string,
  msalPublicClient: PublicClientApplication,
  slbTenantId: string,
  surveyScope: string,
  surveySecretKey: string,
  checkForFeedbackUrl: string): Promise<ISurveyEligibilityResponse> {
  const token = await getAccessToken(msalPublicClient,
    slbTenantId,
    surveyScope);

  const result = await fetch(checkForFeedbackUrl.replace(":userId", userAlias), {
    headers: {
      "x-apikey": surveySecretKey,
      "authorization": `Bearer ${token}`,
    },
    signal: abortSignal,
  });

  await throwIfResponseError(result);

  const json = await result.json();

  return {
    isEligible: Boolean(json.isEligible),
    urlToRedirect: json.urlToRedirect?.toString() ?? "",
  };
}

async function getAccessToken(msalPublicClient: PublicClientApplication,
  slbTenantId: string,
  surveyScope: string): Promise<string | undefined> {
  const activeAccount = msalPublicClient.getActiveAccount();
  const accounts = msalPublicClient.getAllAccounts();

  const tokenReqData = {
    appId: config.authConfig.clientId,
    redirectUri: config.authConfig.redirectUri,
    authority: `https://login.microsoftonline.com/${slbTenantId}`,
    scopes: [
      surveyScope,
    ],
    account: activeAccount || accounts[0],
  } as any;

  try {
    let result = await msalPublicClient.acquireTokenSilent(tokenReqData);

    if (result) {
      return result.accessToken;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
}

function openSurveyPopup(surveyUrl: string) {
  // Open the survey popup.
  let surveyWindow = window.open(surveyUrl, "_blank");

  if (surveyWindow?.focus) {
    surveyWindow.focus();
  }
}