import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React from "react";
import {
  Route,
  Routes as Switch
} from "react-router-dom";
import UrlRoutes, { IRoute } from "./UrlRoutes";

const RouteTracker: React.FC = () => {
  return (
    <Switch>
      {Object.keys(UrlRoutes).map(key => {
        const urlRoute: IRoute = (UrlRoutes as any)[key] as IRoute;

        return <Route key={urlRoute.label} path={urlRoute.urlTemplate} element={
          <PageViewTracker pageName={urlRoute.label} />
        } />
      })}
    </Switch>
  );
};

export default RouteTracker;

interface IPageViewTrackerProps {
  pageName: string,
}

/**
 * This is a component. Do not call it from a function body or it will render a page view every re-render.
 * It should only EVER be used from the RouteTracker component.
*/
const PageViewTracker: React.FC<IPageViewTrackerProps> = ({
  pageName
}) => {
  const appInsights = useAppInsightsContext();

  appInsights.trackPageView({
    name: pageName,
  });

  return null;
}