import { getResponseFromQuestion, sortQuestionsBySTThenNumber } from "components/audits/common/auditUtilities";
import React, { useMemo } from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { doesScoringSystemAllowFindings } from "shared/utilities/findingUtilities";
import { toggleQuestionSelections } from "store/audit-non-conformances/AuditNonConformancesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditPages, EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";
import { getNonCompTopicTableColumns } from "./topicCardUtils";
import "./TopicCard.scoped.scss";

interface INonCompiantProps {
  topicId: number,
  title: string,
  questions: IAuditQuestion[],
}

const TopicCard: React.FC<INonCompiantProps> = ({
  topicId,
  title,
  questions,
}) => {
  const findings = useAppSelector(store => store.audit.findings);
  const findingTypes = useAppSelector(store => store.audit.findingTypes);

  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const userEmail = useAppSelector(store => store.auth.currentUser.email);

  const auditId = useAppSelector(store => store.audit.audit?.id);
  const auditStatus = useAppSelector(store => store.audit.audit?.status);
  const auditors = useAppSelector(store => store.audit.audit?.auditors);
  const leadAuditor = auditors?.find(x => x.isLeader);
  const currUserIsLeader = userEmail.toLowerCase() === leadAuditor?.email.toLowerCase();
  const allAnswers = useAppSelector(store => store.audit.answers);

  const allSelectedAuditQuestions = useAppSelector(store => store.auditNonConformance.selectedAuditQuestions);
  const actionItems = useAppSelector(store => store.audit.actionItems);

  const parentPage = useAppSelector(store => store.auditPageRestriction.auditPageAuth.pageName);
  const disableSelectAllChkBox = !(parentPage === AuditPages.NonConformancesPage
    && (editRestriction === EditRestriction.EditAll
      || editRestriction === EditRestriction.EditOwn));

  const dispatch = useAppDispatch();

  const sortedQuestions = useMemo(() => questions
    .slice()
    .sort(sortQuestionsBySTThenNumber) || [],
    [questions]);

  const thisTopicSelectedQuestions = allSelectedAuditQuestions
    .filter(s => questions.some(q => s.auditQuestionId === q.auditQuestionId));

  const areAllSelected = thisTopicSelectedQuestions.length === questions.length;

  const scoringSystem = questions[0]?.scoringSystem;

  let tableColumns = getNonCompTopicTableColumns(disableSelectAllChkBox,
    thisTopicSelectedQuestions,
    auditId,
    auditStatus,
    auditors,
    currUserIsLeader,
    actionItems,
    findings,
    findingTypes,
    dispatch);

  if (!doesScoringSystemAllowFindings(scoringSystem)) {
    // If the scoring system doesn't allow findings, don't show that column.
    tableColumns = tableColumns.filter(x => x.key !== "findings");
  }

  return (
    <Card
      showHeader={true}
      cardStateId={`nonConformancesTopicCard_${topicId}`}
      defaultCollapsedState={false}
      className="topic-card"
      headerElement={(
        <div
          className="topic-nonconpliant"
        >
          <div className="question-Topic-display">
            <input
              type="checkbox"
              disabled={disableSelectAllChkBox}
              value="all"
              checked={areAllSelected}
              onChange={() => dispatch(toggleQuestionSelections(questions.map(x => ({
                auditQuestionId: x.auditQuestionId,
                isSelected: !areAllSelected,
                questionId: x.questionId,
                questionNum: x.questionNumber,
              }))))}
            />
            {title}
          </div>

          <LabeledControl
            label="Non-Compliant"
            className="singleItem"
            isLight={true}
          >
            <span className="single-item-content">{questions.length} questions</span>
          </LabeledControl>
        </div>
      )}
    >
      <Table
        columns={tableColumns}
        keyProp="auditQuestionId"
        data={sortedQuestions}
        theme={TableThemes.compact}
        getBeforeRenderRowData={question => {
          const currResponse = auditStatus && getResponseFromQuestion(question, auditStatus);
          const inProgResponse = getResponseFromQuestion(question, AuditStatuses.InProgress);

          if (!currResponse
            || !inProgResponse
            || currResponse.notes === inProgResponse.notes) {
            return {
              inProgressNotes: undefined,
              currentAnswer: allAnswers.find(x => x.code === currResponse?.answer),
              currentNotes: currResponse?.notes,
            };
          }

          return {
            inProgressNotes: inProgResponse.notes,
            currentAnswer: allAnswers.find(x => x.code === currResponse?.answer),
            currentNotes: currResponse?.notes,
          };
        }}
      />
    </Card>
  );
};

export default TopicCard;
