import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import { closePicker, loadPickerItems, openPicker, setPickerItems, setSelectedPickerItems } from "store/search/actions/SearchActionsSlice";
import { useAppSelector } from "store/store";
import { ICountry } from "types/masterDataTypes";

const Countries: React.FC = () => {
  const countries = useAppSelector(store => store.searchActions.pickerData.countries);
  
  return (
    <LabeledControl
      label="Country"
    >
      <Picker<ICountry>
        pickerState={countries}
        title="Country"
        openAction={openPicker}
        loadAction={loadPickerItems}
        closeAction={closePicker}
        setItemsAction={setPickerItems}
        setSelectedItemsAction={setSelectedPickerItems}
        preserveItems={true}
        allowMultiSelect={true}
        itemSorter={(a, b) => (a.item?.name || a.text || "").localeCompare(b.item?.name || b.text || "")}
      />
    </LabeledControl>
  );
};

export default Countries;