import { Action } from "@reduxjs/toolkit";
import CustomSearchApi from "api/search/CustomSearchApi";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { ICustomSearchListItem } from "types/customSearchTypes";
import { finishLoadCustomSearchList, loadCustomSearchList } from "./CustomSearchSlice";

export default function* customSearchSagas() {
  yield all([
    loadCustomSearchListAsync(),
  ]);
}

function* loadCustomSearchListAsync() {
  yield takeLatest(loadCustomSearchList, function* (action: Action) {
    if (!loadCustomSearchList.match(action)) {
      return;
    }

    try {
      const filters: {
        auditList: ICustomSearchListItem[];
        actionList: ICustomSearchListItem[];
      } = yield call(CustomSearchApi.getCustomSearchList);

      yield put(finishLoadCustomSearchList({
        isWorking: false,
        data: filters
      }));
    } catch (err) {
      yield put(finishLoadCustomSearchList({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}