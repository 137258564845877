import formatDate from "shared/utilities/dateFormatters";
import "./FiltersTooltip.scoped.scss";

export default function FilterTooltipDateRangeSection({ label, values }: { label: string, values: (number | undefined)[] }) {
  if (!values.length
    || !values.some(x => x)) {
    return null;
  }

  return (
    <div className="filter-section">
      <label>{label}</label>

      <span>
        <span>
          {values?.[0] ? formatDate(new Date(values[0])) : "?"} to {values?.[1] ? formatDate(new Date(values[1])) : "?"}
        </span>
      </span>
    </div>
  );
}