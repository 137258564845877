import { IPerspective, IPerspectiveMetaData, IPerspectiveXAxes, IPerspectiveXAxisPlannableType } from "types/auditMasterDataTypes";

export function formatGetPerspectivesResponse(obj: any): IPerspective[] {
  let perspectives = (Array.isArray(obj)
    ? obj
    : []).map(per => ({
      id: Number(per.id),
      name: per.name?.toString(),
      deleted: Boolean(per.deleted),
      metaData: (Array.isArray(per.metaData)
        ? per.metaData
        : []).map(formatPerspectiveMetaData),
      xAxes: (Array.isArray(per.xAxes)
        ? per.xAxes
        : []).map(formatPerspectiveXAxes),
    }));

  perspectives = perspectives.sort((a, b) => a.name < b.name ? -1 : 1);
  return perspectives;
}

export function formatPerspectiveMetaData(obj: any): IPerspectiveMetaData {
  return {
    id: Number(obj.id),
    perspectiveId: Number(obj.perspectiveId),
    deleted: Boolean(obj.deleted),
    masterDataId: Number(obj.masterDataId),
    type: obj.type?.toString(),
  };
}

export function formatPerspectiveXAxes(obj: any): IPerspectiveXAxes {
  return {
    id: Number(obj.id),
    perspectiveId: Number(obj.perspectiveId),
    deleted: Boolean(obj.deleted),
    auditGroupId: Number(obj.auditGroupId),
    ownerGroupId: Number(obj.ownerGroupId),
    auditGroup: obj.auditGroup?.toString() || "",
    ownerGroup: obj.ownerGroup?.toString() || "",
    plannableMasterDataTypes: Array.isArray(obj.plannableMasterDataTypes)
      ? obj.plannableMasterDataTypes.map(formatPerspectiveXAxisPlannableType)
      : [],
  };
}

export function formatPerspectiveXAxisPlannableType(obj: any): IPerspectiveXAxisPlannableType {
  return {
    id: Number(obj.id),
    masterDataType: obj.masterDataType || "",
    masterDataSubType: obj.masterDataSubType,
  };
}