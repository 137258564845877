import React from "react";
import "./ToastMessage.scoped.scss";
import { useDispatch } from "react-redux";
import { IToast, removeToast, ToastStyle } from "shared/store/toast/ToastSlice";
import Banner, { BannerType } from "../banner/Banner";

const ToastMessage: React.FC<IToast> = ({ id, message, style }) => {
  const dispatch = useDispatch();

  let bannerType: BannerType = BannerType.info;

  switch (style) {
    case ToastStyle.Info: {
      bannerType = BannerType.info;
      break;
    }
    case ToastStyle.Error: {
      bannerType = BannerType.error;
      break;
    }
    case ToastStyle.Success: {
      bannerType = BannerType.success;
      break;
    }
    case ToastStyle.Warning: {
      bannerType = BannerType.warn;
      break;
    }
  }

  return (
    <div
      className="toast"
      onClick={style !== ToastStyle.Error
        ? () => dispatch(removeToast(id))
        : undefined
      }
    >
      <Banner
        type={bannerType}
      >
        {message}
      </Banner>
    </div>
  );
};

export default ToastMessage;