
import { authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IAdminPlanningAdministrationPeriod, IUpsertAdminPlanningAdministrationPeriodRequest, PlanningPeriodsType } from "types/adminPlanningAdministration";
import { formatIAdminPlanningAdministrationPeriod } from "./formatters/adminPlanningAdministrationFormatters";

class AdminPlanningPeriodsApi {
  public async getPeriods(type: PlanningPeriodsType): Promise<IAdminPlanningAdministrationPeriod[]> {
    const response = await authGetJson(config
      .endpoints
      .admin
      .planningAdministration
      .planningPeriods
      + `?type=${type}`);

    await throwIfResponseError(response);

    return (await response.json())
      .map((obj: any) => formatIAdminPlanningAdministrationPeriod(obj));
  }

  public async createPeriod(period: IUpsertAdminPlanningAdministrationPeriodRequest,
    abortSignal?: AbortSignal): Promise<IAdminPlanningAdministrationPeriod> {
    const response = await authPostJson(config.endpoints.admin.planningAdministration.planningPeriods,
      period,
      abortSignal);

    await throwIfResponseError(response);

    return formatIAdminPlanningAdministrationPeriod(await response.json());
  }

  public async updatePeriod(period: IUpsertAdminPlanningAdministrationPeriodRequest,
    abortSignal?: AbortSignal): Promise<IAdminPlanningAdministrationPeriod> {
    const response = await authPutJson(`${config.endpoints.admin.planningAdministration.planningPeriods}/${period.id}`,
      period,
      abortSignal);

    await throwIfResponseError(response);

    return formatIAdminPlanningAdministrationPeriod(await response.json());
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AdminPlanningPeriodsApi();
