import React from "react";
import FlexRow from "shared/components/layout/flex/FlexRow";
import "./LegendIcon.scoped.scss";
import "components/audits/planning/planning-grid/PlanningColors.scoped.scss";

interface ILegendIconProps {
  bgColor?: string,
  borderColor?: string,
  className?: string,
  icon?: string,
  type: "color" | "icon",
  /**
   * The text to show inside the colored square if type=color.
   */
  textInColor?: string,
  label: string,
}

const LegendIcon: React.FC<ILegendIconProps> = ({
  bgColor,
  borderColor,
  className,
  label,
  type,
  textInColor,
  icon,
}) =>
  <FlexRow>
    {type === "color" && (
      <div
        className={`${className || ""} shape`}
        style={{
          backgroundColor: bgColor,
          borderColor: borderColor,
        }}
      >
        {textInColor !== undefined
          && textInColor !== "" && (
            <span
              className="text"
            >
              {textInColor}
            </span>
          )}
      </div>
    )}
    {type === "icon" && (
      <img
        src={icon}
        className={`icon-small ${className || ""}`}
        alt={""}
        title={label}
      />
    )}
    <span
      className="label"
    >
      {label}
    </span>
  </FlexRow>;

export default LegendIcon;