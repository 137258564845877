import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import Plus from "shared/media/dls/plus.svg";
import pluralize from "shared/utilities/pluralize";
import { setManageActionItemModal } from "store/audit/AuditSlice";
import { useAppDispatch } from "store/store";
import { IActionItem } from "types/actionItemTypes";
import { ITopicActionItemData } from "../AuditRWPPage";
import ItemTable from "../item-table/ItemTable";
import "./TopicCard.scoped.scss";

interface IAuditCardProps {
  actionItems: IActionItem[],
  auditId: number,
  isEditable: boolean,
}

const AuditCard: React.FC<IAuditCardProps> = ({
  actionItems,
  auditId,
  isEditable,
}) => {
  const dispatch = useAppDispatch();

  const dataItems = actionItems.map((item): ITopicActionItemData => ({
    actionItem: item,
    id: item.id,
    parents: [],
    subTopics: [],
  }));

  return (
    <Card
      title={"Action Items"}
      cardStateId={`auditRWPAuditCard`}
      defaultCollapsedState={false}
      headerElement={(
        <div
          className="labels"
        >
          {actionItems.length > 0 && (
            <LabeledControl
              label="# of Action Items"
              isLight={true}
            >
              {actionItems.length} {pluralize(actionItems.length, "item", "items")}
            </LabeledControl>
          )}

          {isEditable && (
            <button
              className="primary"
              onClick={() => dispatch(setManageActionItemModal({
                isOpen: true,
                actionItemId: undefined,
                auditId: auditId,
                linkOptions: undefined,
                actionToPerformOnLoad: undefined,
              }))}
            >
              <img
                alt=""
                src={Plus}
                className="icon-extra-small"
              />
              Action Item
            </button>
          )}
        </div>
      )}
    >
      <ItemTable
        actionItems={dataItems}
        linkTypeToRemove="Audit"
      />
    </Card>
  );
};

export default AuditCard;
