import { history } from "App";
import React from "react";

interface IWijmoGridLinkProps {
  href: string,
  style?: React.CSSProperties,
  isCircleButton?: boolean,
  circleButtonType?: "secondary" | "primary",
  className?: string,
  children: React.ReactNode,
}

const WijmoGridLink: React.FC<IWijmoGridLinkProps> = ({
  href,
  style,
  isCircleButton,
  circleButtonType = "primary",
  className,
  children,
}: IWijmoGridLinkProps) => {
  return (
    <a
      href={href}
      style={style}
      className={`${className ?? ""} ${isCircleButton ? "circle" : ""} ${circleButtonType}`}
      onClick={e => {
        e.preventDefault();
        if (e.ctrlKey) {
          window.open(href);
        } else {
          history.push(href);
        }
      }}
    >
      {children}
    </a>
  );
};

export default WijmoGridLink;