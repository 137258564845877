import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Picker from "shared/components/controls/picker/Picker";
import { closePicker, collapsePickerItem, expandPickerItem, loadPickerItems, openPicker, setPickerItems, setSelectedPickerItems } from "store/search/audits/SearchAuditsSlice";
import { useAppSelector } from "store/store";
import { IBusinessView } from "types/masterDataTypes";

const BusinessViewItems: React.FC = () => {
  const businessViews = useAppSelector(store => store.searchAudits.pickerData.businessViews);
  
  return (
    <LabeledControl
      label="Business View (Divisions)"
    >
      <Picker<IBusinessView>
        pickerState={businessViews}
        title="Business View (Divisions)"
        renderListItem={(item) => `${item.code} - ${item.name}`}
        renderSelectedItem={(item) => item.code}
        openAction={openPicker}
        loadAction={loadPickerItems}
        closeAction={closePicker}
        setItemsAction={setPickerItems}
        setSelectedItemsAction={setSelectedPickerItems}
        searchOptions={{
          filterItem: (item, searchTerm) => {
            if (`${item.item?.code} - ${item.item?.name}`.toLowerCase().indexOf(searchTerm) > -1) {
              return true;
            }

            return false;
          },
        }}
        preserveItems={true}
        displayMode="tree"
        allowMultiSelect={true}
        expandItemsAction={expandPickerItem}
        collapseItemsAction={collapsePickerItem}
        itemSorter={(a, b) => (a.item?.code || a.text || "").localeCompare(b.item?.code || b.text || "")}
      />
    </LabeledControl>
  );
};

export default BusinessViewItems;