import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { IAuditPlanView, IPlanChildDimension, IPlanParentDimension, IPlanRequirementDimension, IRemovePlanResponse } from "types/auditPlanningTypes";

export interface IApprovalModalItem {
  plan: IAuditPlanView,
  weekNumber?: number,
  weekLabel?: string,
  leadAuditor?: IAzureADUser,
  requirementDimension?: IPlanRequirementDimension,
}

export interface IApprovalModalState {
  isOpen: boolean,
  items: IApprovalModalItem[],
  parentDimension?: IPlanParentDimension,
  childDimension?: IPlanChildDimension,
  approveOperation?: IOperation<void>,
}

export interface ICreateRemovePlanApprovalModalState {
  isOpen: boolean,
  planId?: number,
  parentDimension?: IPlanParentDimension,
  childDimension?: IPlanChildDimension,
  requirementDimension?: IPlanRequirementDimension,
  operation?: IOperation<number | void>,
}

export interface IEditPlanApprovalModalState {
  isOpen: boolean,
  planId?: number,
  items: IApprovalModalItem[],
  parentDimension?: IPlanParentDimension,
  childDimension?: IPlanChildDimension,
  requirementDimension?: IPlanRequirementDimension,
  operation?: IOperation<number | void>,
  isWithinPlanningRange: boolean,
  isEditingCLMPartial: boolean,
}

export interface IAuditPlanApprovalsState {
  approvalModal: IApprovalModalState,
  confirmEditOrRemovePlanModal: ICreateRemovePlanApprovalModalState,
  createPlanModal: ICreateRemovePlanApprovalModalState,
  removePlanModal: ICreateRemovePlanApprovalModalState,
  planApprovalEditModal: IEditPlanApprovalModalState,
}

const initialState: IAuditPlanApprovalsState = {
  approvalModal: {
    isOpen: false,
    items: [],
    parentDimension: undefined,
    childDimension: undefined,
    approveOperation: undefined,
  },
  createPlanModal: {
    isOpen: false,
    parentDimension: undefined,
    childDimension: undefined,
    requirementDimension: undefined,
  },
  removePlanModal: {
    isOpen: false,
    parentDimension: undefined,
    childDimension: undefined,
    requirementDimension: undefined,
  },
  confirmEditOrRemovePlanModal: {
    isOpen: false,
    parentDimension: undefined,
    childDimension: undefined,
    requirementDimension: undefined,
  },
  planApprovalEditModal: {
    isOpen: false,
    items: [],
    parentDimension: undefined,
    childDimension: undefined,
    requirementDimension: undefined,
    isWithinPlanningRange: false,
    isEditingCLMPartial: false,
  },
};

export const auditPlanApprovalsSlice = createSlice({
  name: "audit-plan-approvals",
  initialState,
  reducers: {
    setApprovalModal: (state, action: PayloadAction<Partial<IApprovalModalState>>) => {
      state.approvalModal = Object.assign({},
        state.approvalModal,
        action.payload);
    },
    setApprovalItemWeekNumber: (state, action: PayloadAction<{
      planId: number,
      weekNumber?: number,
    }>) => {
      const ix = state.approvalModal.items.findIndex(x => x.plan.id === action.payload.planId);

      if (ix === -1) {
        return;
      }

      if (ix === 0) {
        for (let i = 0; i < state.approvalModal.items.length; i++) {
          state.approvalModal.items[i].weekNumber = action.payload.weekNumber;
        }
      } else {
        state.approvalModal.items[ix].weekNumber = action.payload.weekNumber;
      }
    },
    setApprovalItemLeadAuditor: (state, action: PayloadAction<{
      planId: number,
      leader: IAzureADUser | undefined,
    }>) => {
      const itemIx = state.approvalModal.items.findIndex(x => x.plan.id === action.payload.planId);

      if (itemIx === -1) {
        // Bad plan id was supplied in the action.
        return;
      }

      if (itemIx === 0) {
        // If the user set the leader for the first item, copy that user into all items.
        for (let i = 0; i < state.approvalModal.items.length; i++) {
          state.approvalModal.items[i].leadAuditor = cloneDeep(action.payload.leader);
        }
      } else {
        // Otherwise, just copy the leader into this one item.
        state.approvalModal.items[itemIx].leadAuditor = cloneDeep(action.payload.leader);
      }
    },
    startApprovePlans: state => {
      state.approvalModal.approveOperation = {
        isWorking: true,
      };
    },
    finishApprovePlans: (state, action: PayloadAction<IOperation<void>>) => {
      state.approvalModal.approveOperation = undefined;

      if (!action.payload.errorMessage) {
        state.approvalModal.isOpen = false;
      }
    },
    setCreatePlanModal: (state, action: PayloadAction<Partial<ICreateRemovePlanApprovalModalState>>) => {
      state.createPlanModal = Object.assign({},
        state.createPlanModal,
        action.payload);
    },
    createPlan: (state, _: PayloadAction<{ comment: string; }>) => {
      state.createPlanModal.operation = {
        isWorking: true,
      };
    },
    finishCreatingPlan: state => {
      state.createPlanModal.operation = undefined;

      state.createPlanModal = {
        isOpen: false,
      };
    },
    setRemovePlanModal: (state, action: PayloadAction<Partial<ICreateRemovePlanApprovalModalState>>) => {
      state.removePlanModal = Object.assign({},
        state.removePlanModal,
        action.payload);
    },
    removePlan: (state, _: PayloadAction<{ comment: string; }>) => {
      state.removePlanModal.operation = {
        isWorking: true,
      };
    },
    finishRemovingPlan: (state) => {
      state.removePlanModal.operation = undefined;

      state.removePlanModal = {
        isOpen: false,
      };
    },
    setCurrentEditPlanApprovalData: (state, action: PayloadAction<Partial<IEditPlanApprovalModalState>>) => {
      state.planApprovalEditModal = Object.assign({},
        state.planApprovalEditModal,
        action.payload);
    },
    setEditPlanApprovalItemLeadAuditor: (state, action: PayloadAction<{
      planId: number,
      leader: IAzureADUser | undefined,
    }>) => {
      const itemIx = state.planApprovalEditModal.items.findIndex(x => x.plan.id === action.payload.planId);

      if (itemIx === -1) {
        // Bad plan id was supplied in the action.
        return;
      }

      state.planApprovalEditModal.items[itemIx].leadAuditor = cloneDeep(action.payload.leader);
    },
    setEditPlanApprovalItemWeekNumber: (state, action: PayloadAction<{
      planId: number,
      weekNumber?: number,
      weekLabel?: string,
    }>) => {
      const ix = state.planApprovalEditModal.items.findIndex(x => x.plan.id === action.payload.planId);

      if (ix === -1) {
        return;
      }

      if (ix === 0) {
        for (let i = 0; i < state.planApprovalEditModal.items.length; i++) {
          state.planApprovalEditModal.items[i].weekNumber = action.payload.weekNumber;
          state.planApprovalEditModal.items[i].weekLabel = action.payload.weekLabel;
        }
      } else {
        state.planApprovalEditModal.items[ix].weekNumber = action.payload.weekNumber;
        state.planApprovalEditModal.items[ix].weekLabel = action.payload.weekLabel;
      }
    },
    setEditPlanModal: (state, action: PayloadAction<Partial<IEditPlanApprovalModalState>>) => {
      Object.assign(state.planApprovalEditModal, action.payload);

      if (action.payload.isOpen === true) {
        state.planApprovalEditModal.operation = {
          isWorking: true,
        };
      }
    },
    editPlan: (state, _: Action) => {
      state.planApprovalEditModal.operation = {
        isWorking: true,
      };
    },
    setFinishLoadEditPlanModal: (state, _: Action) => {
      state.planApprovalEditModal.operation = {
        isWorking: false,
      };
    },
    finishEditingPlan: (state, action: PayloadAction<IOperation<IRemovePlanResponse>>) => {
      if (action.payload.errorMessage) {
        state.planApprovalEditModal.operation = undefined;
        return;
      }

      state.planApprovalEditModal = {
        isOpen: false,
        items: [],
        isWithinPlanningRange: false,
        isEditingCLMPartial: false,
      };
    },
    setConfirmEditOrRemovePlanModal: (state, action: PayloadAction<Partial<ICreateRemovePlanApprovalModalState>>) => {
      state.confirmEditOrRemovePlanModal = Object.assign({},
        state.confirmEditOrRemovePlanModal,
        action.payload);
    },
  },
});

export const {
  setApprovalModal,
  setApprovalItemWeekNumber,
  setApprovalItemLeadAuditor,
  startApprovePlans,
  finishApprovePlans,
  setCreatePlanModal,
  createPlan,
  finishCreatingPlan,
  setConfirmEditOrRemovePlanModal,
  setRemovePlanModal,
  removePlan,
  finishRemovingPlan,
  setCurrentEditPlanApprovalData,
  setEditPlanApprovalItemWeekNumber,
  setEditPlanApprovalItemLeadAuditor,
  setEditPlanModal,
  editPlan,
  setFinishLoadEditPlanModal,
  finishEditingPlan,
} = auditPlanApprovalsSlice.actions;
