import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import BusinessTeamPicker from "shared/components/controls/pickers/BusinessTeamPicker";
import { setCurrentFilters } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch, useAppSelector } from "store/store";

interface IYAxisFiltersProps {
  disabled: boolean,
}

const YAxisFilters: React.FC<IYAxisFiltersProps> = ({ disabled, }) => {
  const businessTeams = useAppSelector(store => store.auditPlanning.currentFilters.businessTeams);
  const dispatch = useAppDispatch();

  return (
    <LabeledControl
      label="Y-Axis: Business Team (Basin)"
      isRequired={true}
      className="y-axis-filter"
    >
      <BusinessTeamPicker
        onApply={businessTeams => dispatch(setCurrentFilters({
          businessTeams,
        }))}
        selectedItems={businessTeams}
        showSuggestions
        allowMultiselect
        isDisabled={disabled}
        enableRequiredErrorDisplay={true}
        renderSelectedItem={x => x.code}
        maxSelectedItemsVisible={3}
      />
    </LabeledControl>
  );
}

export default YAxisFilters;