import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { ComplianceResults } from "types/auditPlanningTypes";
// import SchedulerAuditCompliance from "../scheduler-compliance/SchedulerAuditCompliance";
import SchedulerAuditCompliance from "../scheduler-audit-compliance/SchedulerAuditCompliance";
import "./AuditPlanCalendarFooter.scoped.scss";

interface IAuditPlanCalendarFooterProps {
  ownerGroup?: string,
}

const AuditPlanCalendarFooter: React.FC<IAuditPlanCalendarFooterProps> = ({
  ownerGroup
}) => {
  return (
    <div
      className="audit-plan-calendar-footer"
    >
      <LabeledControl
        label="AUDIT TYPE:"
      >
        {renderAuditType("First Party-Official", "FP")}
        {renderAuditType("Self-Assessment", "SA")}
      </LabeledControl>

      <span className="pipe">|</span>

      <LabeledControl
        label="STATUS:"
      >
        {renderColorLegendItem("Recommended/Approved", "recommended")}
        {renderColorLegendItem("Planned/In Progress", "planned")}
        {renderColorLegendItem("Completed", "completed")}
        {renderColorLegendItem("Closed", "closed")}
      </LabeledControl>

      <span className="pipe">|</span>

      <LabeledControl
        label="COMPLIANCE RESULT:"
      >
        {ownerGroup === "CLM" && renderComplianceItem(ComplianceResults.HighNonCompliance)}
        {renderComplianceItem(ComplianceResults.NonCompliance)}
        {renderComplianceItem(ComplianceResults.BasicCompliance)}
        {ownerGroup === "CLM"
          ? renderComplianceItem(ComplianceResults.HighCompliance)
          : renderComplianceItem(ComplianceResults.FullCompliance)}
      </LabeledControl>
    </div>
  );
};

export default AuditPlanCalendarFooter;

function renderAuditType(label: string, shorthand: string) {
  return (
    <span
      className="block"
    >
      <span
        className="rounded"
      >
        {shorthand}
      </span>

      <span>
        {label}
      </span>
    </span>
  );
}

function renderColorLegendItem(label: string, color: string) {
  return (
    <span
      className="block"
    >
      <span
        className={`color ${color}`}
      ></span>

      <span>
        {label}
      </span>
    </span>
  );
}

function renderComplianceItem(compliance: ComplianceResults) {
  return (
    <div
      className="block"
    >
      <SchedulerAuditCompliance
        compliance={compliance}
      />

      <span>
        {compliance.replace(/([a-z])([A-Z])/g, '$1 $2')}
      </span>
    </div>
  );
}