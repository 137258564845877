import React from "react";
import Page from "shared/components/layout/page/Page";
import useCurrentRoute from "./useCurrentRoute";

const NotImplemented: React.FC = () => {
  const route = useCurrentRoute();

  return (
    <Page
      title={route?.label || "Not Implemented"}
      showBreadcrumbs={true}
    >
      <h2>This area is not yet implemented</h2>
    </Page>
  );
}

export default NotImplemented;