import { groupBy } from "lodash";
import React from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IDiffDetailItem } from "types/questionTypes";
import "./DiffDisplay.scoped.scss";

interface IDiffDisplayProps {
  diffDetails: IDiffDetailItem[],
}

const DiffDisplay: React.FC<IDiffDisplayProps> = ({
  diffDetails,
}) => {
  const entries = Object.entries(groupBy(diffDetails, x => x.prop));

  return (
    <FlexCol>
      {entries.map(entry => {
        return (
          <DiffGroup
            details={entry[1]}
            key={entry[0]}
          />
        );
      })}
    </FlexCol>
  );
};

export default DiffDisplay;

const DiffGroup = ({
  details,
}: {
  details: IDiffDetailItem[]
}) => {
  const sortedDetails = details
    .slice()
    .sort((a, b) => {
      if (a.action < b.action) {
        return -1;
      } else if (a.action > b.action) {
        return 1;
      }

      return (a.label ?? a.value) < (b.label ?? b.value)
        ? -1
        : 1;
    });

  return (
    <FlexCol
      className="diff-group"
    >
      <label>
        {sortedDetails[0].prop}
      </label>
      <FlexRow
        className="diffs"
      >
        {sortedDetails.map(x => (
          <span
            className={`diff ${x.action}`}
            key={x.value}
            title={x.action}
          >
            {x.label ?? x.value?.toString()}
          </span>
        ))}
      </FlexRow>
    </FlexCol>
  );
}