import { IAnswer } from "types/auditMasterDataTypes";
import { IIdNameObject } from "types/commonTypes";
import "./AnswerInput.scoped.scss";

interface IAnswerInputProps {
  uniqueId: number,
  /** Determines whether the "N/A" is available. */
  allowNA: boolean,
  /** Determines if this is an "update response" input. If true, only the answers that can be updated to will be available. */
  isResponseUpdate?: boolean,
  value: string | undefined,
  availableAnswers: IAnswer[],
  onChange(value: string): void,
  divProps?: React.HTMLProps<HTMLDivElement>,
  causalFactor?: IIdNameObject,
  tooltip?: string,
  isDisabled: boolean,
}

const AnswerInput: React.FC<IAnswerInputProps> = ({
  uniqueId,
  allowNA,
  isResponseUpdate = false,
  value,
  availableAnswers,
  onChange,
  divProps,
  causalFactor,
  tooltip,
  isDisabled,
}) =>
  <div
    className="answer-input"
    {...divProps}
  >
    <div className="method-list">
    {availableAnswers
      // If this is a Response Update input, only the answers that canBeUpdatedTo can be chosen. Otherwise, any answer is OK.
      .filter(answer => !isResponseUpdate
        || answer.canBeUpdatedTo)
      // If the N/A answer cannot be chosen, filter out any answers that are flagged as such.
      .filter(answer => allowNA
        || !answer.isNA)
      .map(answer =>
        <span key={answer.code}>
          <label  className="method-item">
            <input
              type="radio"
              name={`question_answer_${uniqueId}`}
              value={answer.code}
              onChange={() => onChange(answer.code)}
              checked={value === answer.code}
              disabled={isDisabled}
              title={tooltip}
            />
            {answer.name}
          </label>
          {value === answer.code
            && answer.canBeUpdated
            && causalFactor
            && (
              <span className="method-item"> ({causalFactor.name})</span>
            )
          }
        </span>
      )}
      </div>
  </div>;
export default AnswerInput;
