import SearchAuditorsApi from "api/users/SearchAuditorsApi";
import UsersApi from "api/users/UsersApi";
import { isQuestionMissingAnswer } from "components/audits/common/auditUtilities";
import { isQuestionGlobalAssociation } from "components/audits/summary/auditor-assoc-modal/AuditorAssociationModal";
import { Action } from "redux";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showInfoToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IPickerItem } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { userToString } from "shared/utilities/userUtilities";
import { assignAuditorToQuestions, IAuditState, setAudit } from "store/audit/AuditSlice";
import { loadAndSetSuggestedPickerItems } from "store/common/sagas/commonSagas";
import { RootState } from "store/store";
import { IAuditType } from "types/auditMasterDataTypes";
import { AuditorSearchTypes, IAuditee, IAuditInfo, IAuditor, IAuditQuestion } from "types/auditingTypes";
import { assignAuditorByAssociations, AuditSummaryPickerKeys, IAssignAuditorByAssociationsModal, loadPickerItems, loadSuggestedPickerItems, setAssignAuditorByAssociationsModal, setPickerError, setPickerItems, setSelectedPickerItems, setSuggestedPickerItems } from "./AuditSummarySlice";

export default function* auditSummarySagas() {
  yield all([
    loadPickerItemsAsync(),
    setSelectedPickerItemsAsync(),
    loadSuggestedPickerItemsAsync(),
    assignAuditorByAssociationsAsync(),
  ]);
}

function* loadPickerItemsAsync() {
  yield takeLatest(loadPickerItems, function* (action: Action) {
    if (!loadPickerItems.match(action)) {
      return;
    }

    const pickerKey = action.payload.pickerKey;

    try {
      if (pickerKey === AuditSummaryPickerKeys.auditors) {
        let auditType: IAuditType | undefined = yield select((store: RootState) => store.audit.originalAudit?.auditType);

        if (!auditType) {
          throw new Error("Audit data must be loaded before searching for auditors. If an audit has been loaded, its Audit Type is not defined properly.");
        }

        if (!action.payload.searchValue) {
          throw new Error("A search value is required.");
        }

        const items: IAzureADUser[] = yield call(SearchAuditorsApi.searchAuditors,
          action.payload.searchValue,
          auditType.id,
          AuditorSearchTypes.All);

        yield put(setPickerItems({
          pickerKey,
          items: items.map((item): IPickerItem<IAzureADUser> => ({
            key: item.email,
            disabled: false,
            item: item,
          })),
        }));

        yield put(setPickerError({
          pickerKey,
          errorMessage: "",
          stopLoading: true,
        }));

        return;
      }
      if (pickerKey === AuditSummaryPickerKeys.auditees) {
        const items: IAuditee[] = yield call(UsersApi.searchAzureADUsers,
          action.payload.searchValue);

        yield put(setPickerItems({
          pickerKey,
          items: items.map((item): IPickerItem<IAuditee> => ({
            key: item.email,
            item: item,
          })),
        }));

        yield put(setPickerError({
          pickerKey,
          errorMessage: "",
          stopLoading: true,
        }));

        return;
      }

      throw new Error(`Picker '${pickerKey}' has no associated saga for loading items!`);
    } catch (err) {
      yield put(setPickerError({
        pickerKey: pickerKey,
        errorMessage: getResponseErrorMessage(err),
        stopLoading: true,
      }));
    }
  });
}

function* setSelectedPickerItemsAsync() {
  yield takeLatest(setSelectedPickerItems, function* (action: Action) {
    if (!setSelectedPickerItems.match(action)) {
      return;
    }

    if (action.payload.pickerKey !== AuditSummaryPickerKeys.auditors
      && action.payload.pickerKey !== AuditSummaryPickerKeys.auditees) {
      return;
    }

    const audit: IAuditInfo | undefined = yield select((store: RootState) => store.audit.audit);
    const questions: IAuditQuestion[] = yield select((store: RootState) => store.audit.questions);

    if (!audit) {
      yield put(showErrorToast("No audit data is loaded."));
      return;
    }

    const {
      selectedItems,
      pickerKey,
    } = action.payload;

    if (pickerKey === AuditSummaryPickerKeys.auditors) {
      const existingAuditors = audit.auditors.filter(x => !x.isLeader);
      const existingLeader = audit.auditors.find(x => x.isLeader);
      const leader = audit.auditors.find(x => x.isLeader);
      const selectedAuditors = (selectedItems as IPickerItem<IAzureADUser>[])
        .filter(x => x.item?.email.toLowerCase() !== existingLeader?.email.toLowerCase());

      // Find auditors that have been removed and added.
      let removedAuditors = existingAuditors.filter(e => !selectedAuditors.some(s => s.item?.email.toLowerCase() === e.email.toLowerCase()));
      let addedAuditors = selectedAuditors.filter(s => !existingAuditors.some(e => s.item?.email.toLowerCase() === e.email.toLowerCase()));

      if (!removedAuditors.length
        && !addedAuditors.length) {
        return;
      }

      // Find the auditors that would be removed but cannot be because they've answered a question.
      let unremovableAuditors = removedAuditors
        .filter(a => questions
          .some(q => q.responses
            .some(r => r.createdBy.toLowerCase() === a.email.toLowerCase()
              || r.modifiedBy.toLowerCase() === a.email.toLowerCase())));

      // Filter the removal list down to those who CAN be removed.
      removedAuditors = removedAuditors
        .filter(r => !unremovableAuditors
          .some(u => u.email.toLowerCase() === r.email.toLowerCase()));

      // Create a new auditor list by keeping all the existing auditors
      // and removing the ones that should be removed, then adding in
      // the ones that have been added.
      let newAuditorList = existingAuditors
        .filter(e => !removedAuditors
          .some(r => r.email.toLowerCase() === e.email.toLowerCase()))
        .concat(addedAuditors
          .map(a => a.item as IAzureADUser)
          .map((i): IAuditor => ({
            email: i.email,
            name: i.name,
            isLeader: false,
          })));

      // Ensure the leader is always in the list.
      if (leader
        && !newAuditorList
          .some(x => x.email.toLowerCase() === leader?.email.toLowerCase())) {
        newAuditorList.unshift(leader);
      }

      if (unremovableAuditors.length) {
        yield put(showInfoToast(`The following auditor(s) cannot be removed because they have answered questions: ${unremovableAuditors
          .map(x => userToString(x)).join(', ')}`));
      }

      yield put(setAudit({
        audit: {
          auditors: newAuditorList,
        },
      }));
    } else {
      const existingAuditees = audit.auditees;
      const selectedAuditees = selectedItems as IPickerItem<IAzureADUser>[];

      let removedAuditees = existingAuditees.filter(e => !selectedAuditees.some(s => s.item?.email.toLowerCase() === e.email.toLowerCase()));
      let addedAuditees = selectedAuditees.filter(s => !existingAuditees.some(e => s.item?.email.toLowerCase() === e.email.toLowerCase()));


      let filteredAuditeeList = existingAuditees
        .filter(e => !removedAuditees
          .some(r => r.email.toLowerCase() === e.email.toLowerCase()))
        .concat(addedAuditees
          .map(a => a.item as IAzureADUser)
          .map((i): IAuditee => ({
            email: i.email,
            name: i.name,
            isLeader: false,
          })));

      let newAuditeeList = filteredAuditeeList.map((i): IAuditee => ({
        email: i.email,
        name: i.name,
        isLeader: i.isLeader
      }));

      if (!newAuditeeList.find(x => x.isLeader) && newAuditeeList.length) {
        newAuditeeList[0].isLeader = true;
      };

      if (newAuditeeList.length === 1) {
        newAuditeeList[0].isLeader = true;
      }

      yield put(setAudit({
        audit: {
          auditees: newAuditeeList,
        },
      }));
    }
  });
}

function* loadSuggestedPickerItemsAsync() {
  yield takeEvery(loadSuggestedPickerItems, function* (action: Action) {
    if (!loadSuggestedPickerItems.match(action)) {
      return;
    }

    const {
      pickerKey,
    } = action.payload;

    switch (pickerKey) {
      case AuditSummaryPickerKeys.auditors:
        // Get the selected audit type first.
        const auditType: IAuditType | undefined = yield select((store: RootState) =>
          store
            .audit
            .originalAudit
            ?.auditType);

        if (!auditType) {
          setSuggestedPickerItems({
            pickerKey,
            suggestedItems: [],
          });
          return;
        }

        // Call the helper function to load the suggestions and put them into redux.
        yield call<any>(loadAndSetSuggestedPickerItems,
          () => SearchAuditorsApi.getAuditorSuggestions(auditType.id, AuditorSearchTypes.Auditor),
          (user: IAzureADUser): IPickerItem<IAzureADUser> => ({
            key: user.email,
            item: user,
          }),
          pickerKey,
          setSuggestedPickerItems);
        break;
      default:
        yield put(showErrorToast(`No handler defined for loadSuggestedItems with key ${action.payload.pickerKey}.`));
    }
  });
}

function* assignAuditorByAssociationsAsync() {
  yield takeLatest(assignAuditorByAssociations, function* () {
    let modalData: IAssignAuditorByAssociationsModal = yield select((store: RootState) => store.auditSummary.assignAuditorByAssociationsModal);

    const {
      selectedAuditTopics,
      selectedBusinessFunctions,
      selectedBusinessTeams,
      selectedBusinessViews,
      selectedOtherAssociations,
    } = modalData;

    if (!modalData.auditor) {
      yield put(showErrorToast("No auditor is currently selected."));
      return;
    } else if (!modalData.isOpen) {
      yield put(showErrorToast("The assign auditor by associations modal is not open."));
      return;
    } else if (
      !selectedAuditTopics.length
      && !selectedBusinessFunctions.length
      && !selectedBusinessTeams.length
      && !selectedBusinessViews.length
      && !selectedOtherAssociations.length) {
      yield put(showErrorToast("No associations are selected."));
      return;
    }

    let auditData: IAuditState = yield select((store: RootState) => store.audit);
    const auditQuestions = auditData.questions;
    const auditStatus = auditData.audit?.status;
    const audit = auditData.audit;

    if (!auditStatus
      || !audit) {
      // Audit data is not loaded.
      yield put(showErrorToast("The audit is in an unknown status."));
      return;
    }

    // Find the questions to assign to the auditor.
    const questionsToAssign = auditQuestions
      .filter(x => isQuestionMissingAnswer(x, auditStatus)
        && doesQuestionMatchMeta(x, modalData, audit));

    if (questionsToAssign.length) {
      yield put(assignAuditorToQuestions({
        auditorEmail: modalData.auditor.email,
        auditQuestionIds: questionsToAssign.map(x => x.auditQuestionId),
      }));

      // Show a success message.
      yield put(showSuccessToast(`Assigned ${questionsToAssign.length} questions to ${userToString(modalData.auditor)}.`));

      // Close the modal.
      yield put(setAssignAuditorByAssociationsModal({
        isOpen: false,
      }));
    } else {
      // No questions were found that could be reassigned. Show a message but don't close the modal.
      yield put(showInfoToast(`No unanswered questions found that match your selections.`));
    }
  });
}

function doesQuestionMatchMeta(question: IAuditQuestion,
  assgnAuditorModalData: IAssignAuditorByAssociationsModal,
  audit: IAuditInfo) {
  // Check the Audit Topic filter.
  return assgnAuditorModalData
    .selectedAuditTopics
    .some(sel => sel.id === question.topicId
      || question.subTopics.some(sub => sub.id === sel.id))
    // Check the Business Function filter.
    || assgnAuditorModalData
      .selectedBusinessFunctions
      .some(sel => question.businessFunctions.some(meta => meta.id === sel.id))
    // Check the Business Team filter.
    || assgnAuditorModalData
      .selectedBusinessTeams
      .some(sel => question.businessTeams.some(meta => meta.id === sel.id))
    // Check the Business View filter.
    || assgnAuditorModalData
      .selectedBusinessViews
      .some(sel => question.businessViews.some(meta => meta.id === sel.id))
    // Check other associations.
    || assgnAuditorModalData
      .selectedOtherAssociations
      .some(sel =>
        // Check the Country filter.
        (sel.type === "Country"
          && question.countries.some(meta => meta.id === sel.id))
        // Check the Global filter.
        || (sel.type === "Global"
          && isQuestionGlobalAssociation(question, audit)));
}