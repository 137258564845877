import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "store/store";
import { applyFilters, overrideAppliedFilters, setCurrentFilters } from "../AuditPlanningSlice";
import { loadAllPlanningData } from "../helpers/loadPlanningDataGenerators";
import { IAuditPlanningAppliedFilters } from "../reducers/planningFiltersReducers";

export function* planningFiltersSagas() {
  yield all([
    applyFiltersAsync(),
    auditTypeOrPlanYearChangedAsync(),
  ]);
}

function* applyFiltersAsync() {
  yield takeLatest(applyFilters, function* () {
    // Load all the planning data into the state.
    yield call(loadAllPlanningData, "fromCurrentFilters");
  });
}

function* auditTypeOrPlanYearChangedAsync() {
  yield takeLatest(setCurrentFilters, function* (action) {
    // If the user has changed AuditType or PlanYear and there are already filters applied,
    // the AuditType and PlanYear must be immediately applied (but not the rest of the
    // current filters).
    if (!action.payload.auditType
      && !action.payload.planYear) {
      // This action is not setting AuditType or PlanYear. No need to do anything.
      return;
    }

    const appliedFilters: IAuditPlanningAppliedFilters = yield select((store: RootState) => store.auditPlanning.appliedFilters);

    if (!appliedFilters) {
      // There are no applied filters so there is no need to auto-load the data.
      return;
    }

    // Copy the applied filters, overwriting auditType and planYear (if they are set in the action).
    // Then save these new applied filters into the state.
    yield put(overrideAppliedFilters({
      ...appliedFilters,
      auditType: action.payload.auditType ?? appliedFilters.auditType,
      planYear: action.payload.planYear ?? appliedFilters.planYear,
    }));

    // Now that the `appliedFilters` are set with the new values in the state,
    // the api data can all be reloaded. Call `loadAllPlanningData` but this time send in
    // "fromAppliedFilters" so that it knows not to use the currentFilter data.
    yield call(loadAllPlanningData, "fromAppliedFilters");
  });
}