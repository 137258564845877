export function isType<T>(object: any, checkForMembers: string[]): object is T {
  for (let i = 0; i < checkForMembers.length; i++) {
    if (!(checkForMembers[i] in object)) {
      return false;
    }
  }

  return true;
}

export function isNotUndefined<T>(item: T | undefined): item is T {
  return !!item
}

export function isNumber(value: any): value is number {
  return !isNaN(Number(value));
}