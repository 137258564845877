import ICLMDocRef from "types/clm/ICLMDocRef";

export function formatCLMDocRef(obj: any): ICLMDocRef {
  return {
    id: Number(obj.id),
    name: obj.name?.toString() ?? "",
    dms: obj.dms?.toString() ?? "",
    revisionUsed: obj.revisionUsed?.toString() ?? "",
    currentRevision: obj.currentRevision?.toString() ?? "",
    emsixPackage: obj.emsixPackage?.toString() ?? "",
    emsixSubPackage: obj.emsixSubPackage?.toString() ?? "",
    auditPackage: obj.auditPackage?.toString() ?? "",
    packageAcronym: obj.packageAcronym?.toString() ?? "",
    documentUid: obj.documentUid?.toString() ?? "",
    sortOrder: Number(obj.sortOrder),
    deleted: Boolean(obj.deleted),
    auditTopicId: obj.auditTopicId !== undefined
      ? Number(obj.auditTopicId)
      : undefined,
  };
}
