import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import MasterDataApi from "api/masterdata/MasterDataApi";
import UsersApi from "api/users/UsersApi";
import { Action } from "redux";
import { put, all, takeEvery, takeLatest, call } from "redux-saga/effects";
import { IPickerItem } from "shared/types/pickerTypes";
import { retrieveAndPutPickerData } from "store/common/sagas/commonSagas";
import { IActionItemPriority, IActionItemType } from "types/actionItemTypes";
import { ICausalFactor } from "types/auditMasterDataTypes";
import { IIdNameObject } from "types/commonTypes";
import { ManageActionItemPickerKeys, loadPickerItems, setPickerError, setPickerItems, setSelectedPickerItems, loadPriorities, finishLoadingPriorities, loadTypes, finishLoadingTypes, loadCausalFactors, finishLoadingCausalFactors } from "./ManageActionItemSlice";
import { IAzureADUser } from "shared/types/userProfileTypes";

export default function* manageActionItemSagas() {
  yield all([
    loadPickerItemsAsync(),
    loadCausalFactorsAsync(),
    loadPrioritiesAsync(),
    loadTypesAsync(),
  ]);
}

function* loadPickerItemsAsync() {
  yield takeEvery(loadPickerItems, function* (action: Action) {
    if (!loadPickerItems.match(action)) {
      return;
    }

    const pickerKey = action.payload.pickerKey;

    try {
      switch (pickerKey) {
        case ManageActionItemPickerKeys.users: {
          yield retrieveAndPutPickerData(UsersApi.searchAzureADUsers,
            (item: IAzureADUser): IPickerItem<IAzureADUser> => ({
              key: item.email,
              disabled: false,
              item: item,
            }),
            setPickerItems,
            setSelectedPickerItems,
            setPickerError,
            pickerKey,
            false,
            action.payload.searchValue);
          return;
        }
      }

      throw new Error(`Picker '${pickerKey}' has no associated saga for loading items!`);
    } catch (err) {
      yield put(setPickerError({
        pickerKey: pickerKey,
        errorMessage: getResponseErrorMessage(err),
        stopLoading: true,
      }));
    }
  });
}

function* loadCausalFactorsAsync() {
  yield takeLatest(loadCausalFactors, function* (action: Action) {
    if (!loadCausalFactors.match(action)) {
      return;
    }

    try {
      const items: ICausalFactor[] = yield call(MasterDataApi.getCausalFactors);

      yield put(finishLoadingCausalFactors({
        isWorking: false,
        data: items.map((item): IIdNameObject => ({
          id: item.id,
          name: item.name,
        })),
      }));

    } catch (err) {
      yield put(finishLoadingCausalFactors({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* loadPrioritiesAsync() {
  yield takeLatest(loadPriorities, function* (action: Action) {
    if (!loadPriorities.match(action)) {
      return;
    }

    try {
      const items: IActionItemPriority[] = yield call(MasterDataApi.getActionItemPriorities);

      yield put(finishLoadingPriorities({
        isWorking: false,
        data: items,
      }));

    } catch (err) {
      yield put(finishLoadingPriorities({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* loadTypesAsync() {
  yield takeLatest(loadTypes, function* (action: Action) {
    if (!loadTypes.match(action)) {
      return;
    }

    try {
      const items: IActionItemType[] = yield call(MasterDataApi.getActionItemTypes);

      yield put(finishLoadingTypes({
        isWorking: false,
        data: items,
      }));

    } catch (err) {
      yield put(finishLoadingTypes({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}


