import React, { useCallback } from "react";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IApprovalModalItem, IApprovalModalState, ICreateRemovePlanApprovalModalState, IEditPlanApprovalModalState, setApprovalModal, setConfirmEditOrRemovePlanModal, setCreatePlanModal, setEditPlanModal, setRemovePlanModal } from "store/audit-plan-approvals/AuditPlanApprovalsSlice";
import { toggleYAxisItem } from "store/audit-planning-shared/AuditPlanningSlice";
import { checkAttributeByTopicOrTemplatePermission } from "store/audit-planning-shared/PlanningShared";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditPlanApprovalStatuses, ClmSelfAssessmentTypes, IAuditPlanScore, IAuditPlanView } from "types/auditPlanningTypes";
import PlanningGrid, { IPlanningGridApproveClickArgs, IPlanningGridCellClickArgs, IPlanningItem, IRenderCommandCellArgs } from "../../planning-grid/PlanningGrid";
import PlanningLeftCell from "../../planning-grid/left-cell/PlanningLeftCell";
import "./PlanApprovalsGrid.scoped.scss";
import ApprovalGridCell from "./grid-cell/ApprovalGridCell";


const PlanApprovalsGrid: React.FC = () => {
  const xAxisData = useAppSelector(store => store.auditPlanning.xAxisData);
  const yAxisData = useAppSelector(store => store.auditPlanning.planApprovalsResultsYAxisData);
  const plans = useAppSelector(store => store.auditPlanning.planApprovalsPlans);
  const planScores = useAppSelector(store => store.auditPlanning.planScores);
  const appliedFilters = useAppSelector(store => store.auditPlanning.appliedFilters);
  const activeUserProfile = useAppSelector(store => store.auth.activeUserProfile);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);

  const dispatch = useAppDispatch();

  const onToggleRow = useCallback((args: IRenderCommandCellArgs) => {
    dispatch(toggleYAxisItem({
      isExpanded: !args.row.isExpanded,
      item: args.row,
      shared: true,
    }));
  }, [dispatch]);

  const getParentChildDimensions = (row: IPlanningItem, parentRow: IPlanningItem | undefined) => {
    if (parentRow?.isPlannable) {
      return {
        childDimension: row,
        parentDimension: parentRow,
      };
    } else {
      return {
        childDimension: undefined,
        parentDimension: row,
      };
    }
  };

  const getPlansByDimensions = (parentDimension: IPlanningItem, childDimension?: IPlanningItem) => {
    return plans.filter(x => x.parentDimensionId === parentDimension.id
      && x.parentDimensionType === parentDimension.type
      && x.childDimensionId === childDimension?.id
      && x.childDimensionType === childDimension?.type);
  };

  const getPlanScoresByDimensions = (parentDimension: IPlanningItem, childDimension?: IPlanningItem) => {
    return planScores.filter(x => x.parentDimensionId === parentDimension.id
      && x.parentDimensionType === parentDimension.type
      && x.childDimensionId === childDimension?.id
      && x.childDimensionType === childDimension?.type);
  };

  const getCreatePlanData = (args: IPlanningGridCellClickArgs): ICreateRemovePlanApprovalModalState => {
    const dimensions = getParentChildDimensions(args.row, args.parentRow);

    return {
      parentDimension: {
        id: dimensions.parentDimension.id,
        text: dimensions.parentDimension.text,
        type: dimensions.parentDimension.type,
      },
      childDimension: !dimensions.childDimension
        ? undefined
        : {
          id: dimensions.childDimension.id,
          text: dimensions.childDimension.text,
          type: dimensions.childDimension.type,
        },
      isOpen: true,
      planId: args.plan?.id,
      requirementDimension: {
        id: args.requirement.id,
        text: args.requirement.text,
        type: args.requirement.type,
        masterDataItem: args.requirement.masterDataItem,
      },
    };
  };
  const getPlanDataForEditDetails = (args: IPlanningGridCellClickArgs): IEditPlanApprovalModalState => {
    return {
      parentDimension: args.row,
      childDimension: args.parentRow,
      isOpen: true,
      planId: args.plan?.id,
      items: [],
      requirementDimension: {
        id: args.requirement.id,
        text: args.requirement.text,
        type: args.requirement.type,
        masterDataItem: args.requirement.masterDataItem,
      },
      isWithinPlanningRange: args.row.isWithinPlanningRange,
      isEditingCLMPartial: args.plan?.requiredCLMSelfAssessmentType === ClmSelfAssessmentTypes.Partial
        && args.row.isWithinPlanningRange,
    };
  };
  const onClickUnplannedCell = (args: IPlanningGridCellClickArgs) => {
    dispatch(setCreatePlanModal(getCreatePlanData(args)));
  };

  const onClickPlannedCell = (args: IPlanningGridCellClickArgs) => {
    if (args.plan?.deleted) {
      dispatch(setCreatePlanModal(getCreatePlanData(args)));
    } else if (args.plan?.auditId) {
      dispatch(showErrorToast("This audit plan cannot be edited or removed because an audit has been created for it."));
    } else if (!args.row.isPlannable) {
      dispatch(showErrorToast("Plan not planned."));
    } else if (args.plan?.approvalStatus === AuditPlanApprovalStatuses.FinalApproval
      || args.plan?.requiredCLMSelfAssessmentType === ClmSelfAssessmentTypes.Partial) {
      if (args.row.isWithinPlanningRange) {
        dispatch(setConfirmEditOrRemovePlanModal(getPlanDataForEditDetails(args)));
      } else {
        dispatch(setEditPlanModal(getPlanDataForEditDetails(args)));
      }
    } else {
      dispatch(setRemovePlanModal(getPlanDataForEditDetails(args)));
    }
  };

  const onClickApprovedButton = (args: IPlanningGridApproveClickArgs) => {
    const dimensions = getParentChildDimensions(args.row, args.parentRow);
    const requirementsItems = args.requirements;

    // Get the plans to be approved.
    const plansNeedingApproval = getPlansByDimensions(dimensions.parentDimension,
      dimensions.childDimension)
      .filter(x => x.approvalStatus !== AuditPlanApprovalStatuses.FinalApproval);

    if (!plansNeedingApproval.length) {
      return;
    }

    const approvalItems: IApprovalModalItem[] = plansNeedingApproval
      .map((x): IApprovalModalItem => ({
        plan: x,
        weekNumber: undefined,
        requirementDimension: requirementsItems.find(r => r.id === x.requirementDimensionId
          && r.type === x.requirementDimensionType),
        leadAuditor: undefined,
      }));

    const approvalDimensions: Partial<IApprovalModalState> = {
      parentDimension: {
        id: dimensions.parentDimension.id,
        text: dimensions.parentDimension.text,
        type: dimensions.parentDimension.type,
      },
      childDimension: !dimensions.childDimension
        ? undefined
        : {
          id: dimensions.childDimension.id,
          text: dimensions.childDimension.text,
          type: dimensions.childDimension.type,
        },
      isOpen: true,
      items: approvalItems,
    };
    dispatch(setApprovalModal(approvalDimensions));
  };

  return (
    <PlanningGrid
      requirements={xAxisData}
      rows={yAxisData}
      options={{
        leftCommandCellOptions: {
          isVisible: true,
          isSticky: true,
          renderCell: (args) => {
            return {
              node: (
                <PlanningLeftCell
                  renderCommandCellArgs={args}
                  isCollapsible={true}
                  onToggleRow={onToggleRow}
                />
              ),
            };
          },
        },
        rightCommandCellOptions: {
          isVisible: true,
          isSticky: true,
          renderCell: (args) => {
            if (!args.row.isPlannable
              || !(args.row.doesUserHavePermission && args.row.isWithinPlanningRange)) {
              // Row is not plannable and not editable. Do not show approve.
              return { node: undefined };
            }

            const dimensions = getParentChildDimensions(args.row, args.parentRow);
            const plans = getPlansByDimensions(dimensions.parentDimension,
              dimensions.childDimension);

            const hasAnyUnapprovedPlans = plans
              .some(x => x.approvalStatus !== AuditPlanApprovalStatuses.FinalApproval);

            return {
              node: hasAnyUnapprovedPlans
                ? (
                  <button
                    className="primary"
                    onClick={() => onClickApprovedButton({
                      row: args.row,
                      parentRow: args.parentRow,
                      plans: plans
                        .filter(x => x.parentDimensionId === dimensions.parentDimension.id
                          && x.parentDimensionType === dimensions.parentDimension.type
                          && x.childDimensionId === dimensions.childDimension?.id
                          && x.childDimensionType === dimensions.childDimension?.type
                          && x.approvalStatus !== AuditPlanApprovalStatuses.FinalApproval),
                      requirements: xAxisData,
                    })
                    }
                  >
                    Approve
                  </button>
                ) : undefined,
            };
          },
        },
        renderDataCell: (args) => {
          let score: IAuditPlanScore | undefined = undefined;
          let plan: IAuditPlanView | undefined = undefined;

          if (args.row.isPlannable) {
            const dimensions = getParentChildDimensions(args.row, args.parentRow);

            score = getPlanScoresByDimensions(dimensions.parentDimension, dimensions.childDimension)
              .find(x => x.requirementDimensionId === args.requirement.id
                && x.requirementDimensionType === args.requirement.type);

            plan = getPlansByDimensions(dimensions.parentDimension, dimensions.childDimension)
              .find(x => x.requirementDimensionId === args.requirement.id
                && x.requirementDimensionType === args.requirement.type);

            if (plan?.deleted
              && plan?.source === "Manual") {
              plan = undefined;
            }
          }

          const isRequirementEnabled =
            checkAttributeByTopicOrTemplatePermission(args.requirement.id,
              args.requirement.type,
              activeUserProfile,
              editRestriction,
              xAxisData);

          const isRowEnabled = plan
            ? args.row.doesUserHavePermission
            && (plan.approvalStatus === AuditPlanApprovalStatuses.FinalApproval
              || args.row.isWithinPlanningRange)
            : (args.row.doesUserHavePermission && args.row.isWithinPlanningRange);

          return {
            node: (
              <ApprovalGridCell
                score={score}
                plan={plan}
                planningItem={args.row}
                parentPlanningItem={args.parentRow}
                requirement={args.requirement}
                isPlannable={args.row.isPlannable}
                hideScoreWithoutPlan={!!appliedFilters?.planStatus}
                isEnabled={isRequirementEnabled && isRowEnabled}
                onCellClick={args => {
                  if (!args.row.isPlannable) {
                    return;
                  }

                  if (!args.plan) {
                    onClickUnplannedCell(args);
                  } else {
                    onClickPlannedCell(args);
                  }
                }}
              />
            )
          };
        },
      }}
    />
  );
};

export default PlanApprovalsGrid;
