import HelpApi from "api/help/HelpApi";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { IOperation } from "shared/types/operationTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IHelpItem } from "types/auditMasterDataTypes";
import { loadHelpData, setHelpData } from "./HelpSlice";

export default function* helpSagas() {
  yield all([
    loadHelpDataAsync(),
  ]);
}

function* loadHelpDataAsync() {
  yield takeEvery(loadHelpData, function* (action) {
    if (!loadHelpData.match(action)) {
      return;
    }

    // Pull out the slug of the help item from the action payload.
    const slug = action.payload;

    // Get the current value for this slug from the store.
    const existingHelpData: IOperation<IHelpItem> | undefined = yield select((store: RootState) => store.help[slug]);

    if (existingHelpData?.data
      || existingHelpData?.isWorking) {
      // Do not attempt to load this help item again if it's currently
      // trying to load or if its data is already loaded.
      return;
    }

    // Use the help api to load the data.
    try {
      const helpItem: IHelpItem = yield call(HelpApi.getHelpItem, slug);

      yield put(setHelpData({
        slug,
        data: {
          isWorking: false,
          data: helpItem,
        },
      }))
    } catch (err) {
      yield put(setHelpData({
        slug,
        data: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      }))
    }
  });
}