import { Action } from "@reduxjs/toolkit";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import AuditsApi from "api/auditing/AuditsApi";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IAuditReportSectionAttachment } from "types/auditingTypes";
import { finishLoadingAuditAttachments, loadAuditAttachments } from "./AttachmentModalSlice";

export default function* attachmentModalSagas() {
  yield all([
    loadAuditAttachmentsAsync(),
  ]);
}

function* loadAuditAttachmentsAsync() {
  yield takeLatest(loadAuditAttachments, function* (action: Action) {
    if (!loadAuditAttachments.match(action)) {
      return;
    }

    try {
      const data: IAuditReportSectionAttachment[] = yield call(AuditsApi.getImageAttachments, action.payload);

      yield put(finishLoadingAuditAttachments({
        isWorking: false,
        data,
      }))
    } catch (err) {
      yield put(finishLoadingAuditAttachments({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}
