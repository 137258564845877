import DatePicker, { DatePickerSelectionMode } from "shared/components/controls/date-picker/DatePicker";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { ISearchActionsState, setSearchPropValue } from "store/search/actions/SearchActionsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

interface IActionDateRangeEntryProps {
  startPropName: keyof ISearchActionsState,
  endPropName: keyof ISearchActionsState,
  label: string,
}

const ActionDateRangeEntry: React.FC<IActionDateRangeEntryProps> = ({ startPropName, endPropName, label }) => {
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(store => store.searchActions[startPropName] as (number | undefined));
  const endDate = useAppSelector(store => store.searchActions[endPropName] as (number | undefined));

  return (
    <LabeledControl
      label={label}
    >
      <DatePicker
        selectionMode={DatePickerSelectionMode.range}
        value={startDate}
        startDate={startDate ? new Date(startDate) : undefined}
        endDate={endDate ? new Date(endDate) : undefined}
        onChange={dateRange => {
          if (Array.isArray(dateRange)) {
            dispatch(setSearchPropValue({
              [startPropName]: dateRange[0] === null
                ? undefined
                : dateRange[0].getTime(),
              [endPropName]: dateRange[1] === null
                ? undefined
                : dateRange[1].getTime(),
            }));
          }
        }}
        isClearable
        placeholderText="Select"
      />
    </LabeledControl>
  );
};

export default ActionDateRangeEntry;