import TagsRecap from "components/audits/manage/tags-recap/TagsRecap";
import { cloneDeep } from "lodash";
import { useState } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { INonReduxPickerProps } from "shared/components/controls/picker/Picker";
import PickerList from "shared/components/controls/picker/list/PickerList";
import SearchBox from "shared/components/controls/search-box/SearchBox";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IOperation } from "shared/types/operationTypes";
import { IPickerItem } from "shared/types/pickerTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";
import { convertToPickerItem } from "shared/utilities/pickerUtiilities";
import "./NonReduxPickerList.scoped.scss";

/** Tags properties to be rendered on the right side of the list. */
interface ITagRecapProps<T> {
  /** The tags group's label that will be rendered */
  label: string,
  /** How the tag item will be rendered */
  renderItem: (item: IPickerItem<T>) => React.ReactNode,
  /** How to filter the items (from the selected ones) to be shown on 
   * the current group */
  filterList?: (item: T) => boolean,
}

type INonReduxPickerListProps<T> = INonReduxPickerProps<T> & {
  /** The selected items to be rendered and selected on the list. */
  selectedItems: T[],
  /** The event to call whenever a select or deselect action is performed. */
  onSelectionChanged: (items: T[]) => void,
  /** How the list item will be rendered on the list. */
  renderListItem: (item: T) => string | React.ReactNode,
  /** A mapper to get the key for each one of the items on the list. */
  keyMapper: (item: T) => string | number,
  /** A function to get the items of the list. */
  onLoadItems: (searchValue: string | undefined, abortSignal: AbortSignal) => Promise<T[]>,
  /** How to get a parent item's childs to be rendered hierarchically. */
  childMapper?: (item: T, allItems: T[]) => T[],
  /** How to filter the items by the searchValue (This is a syncronous call) */
  filterItems?: (item: IPickerItem<T>, valueSearched: string) => boolean,
  /** An array containing the properties of the tags that are going to be rendered on right side. 
   * If none was give, the tags are not rendered.
  */
  tagsProps?: ITagRecapProps<T>[],
  /** Flag to determine if the selection is required or not. */
  isRequired?: boolean,
  /** Optional. If specified, each item will call this function to determine if it should be disabled or not. */
  isDisabledMapper?: (item: T, ancestorPath: T[]) => boolean,
};

const filterChildItems = <T,>(item: IPickerItem<T>,
  searchTerm: string,
  filterItem: (item: IPickerItem<T>, searchTerm: string) => boolean) => {
  if (!item.children?.length) {
    return;
  }

  let newChildren: IPickerItem<T>[] = [];

  for (let i = 0; i < item.children.length; i++) {
    const childItem = item.children[i];

    // If this item has any children, call this again.
    if (childItem.children?.length) {
      filterChildItems(childItem,
        searchTerm,
        filterItem);

      if (childItem.children.length > 0
        || filterItem(childItem, searchTerm)) {
        // This child item matches the filter or has a child that does.
        newChildren.push(childItem);
      }
    } else {
      if (filterItem(childItem, searchTerm)) {
        // This item matches the filter. Add it to the list for the parent item.
        newChildren.push(childItem);
      }
    }
  }

  item.children = newChildren;
};

const filterItemsByTerm = <T,>(allItems: IPickerItem<T>[],
  searchTerm: string,
  filterItem: (item: IPickerItem<T>, searchTerm: string) => boolean,
  isTree: boolean): IPickerItem<T>[] => {
  if (!searchTerm) {
    return allItems;
  }

  if (isTree) {
    // Tree mode filtering.
    let allTreeItems: IPickerItem<T>[] = cloneDeep(allItems);

    for (let i = allTreeItems.length - 1; i >= 0; i--) {
      let treeItem = allTreeItems[i];

      filterChildItems(treeItem,
        searchTerm,
        filterItem);

      if (!(treeItem.children?.length
        || filterItem(treeItem, searchTerm))) {
        // This item has no children that match the filter
        // and it doesn't match the filter.
        // Remove it from the list.
        allTreeItems.splice(i, 1);
      }
    }

    return allTreeItems;
  } else {
    // List mode filtering.
    return allItems.filter(x => filterItem(x, searchTerm));
  }
};

const NonReduxPickerList = <T,>(props: INonReduxPickerListProps<T>) => {
  const {
    selectedItems,
    renderListItem,
    onSelectionChanged,
    keyMapper,
    onLoadItems,
    childMapper,
    tagsProps,
    filterItems,
    displayMode = 'tree',
    isRequired = false,
    isDisabled,
    isDisabledMapper,
  } = props;

  const [availableItems, setAvailableItems] = useState<T[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [expandedKeys, setExpandedKeys] = useState<(string | number)[]>([]);
  const [loadOp, setLoadOperation] = useState<IOperation<T> | undefined>();

  const searchBehavior = props.searchOptions?.behavior;
  const asyncSearchMinChars = props.searchOptions?.asyncMinChars;
  const asyncSearchDelay = props.searchOptions?.asyncSearchDelay;

  useAsyncEffect(async (aborted, abortSignal) => {
    try {
      setLoadOperation({
        isWorking: true,
      });

      const allItems = await onLoadItems(undefined, abortSignal);
      if (aborted) {
        return;
      }

      setAvailableItems(allItems);
      setLoadOperation(undefined);
    } catch (err) {
      if (aborted) {
        return;
      }

      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      });
    }
  }, [setLoadOperation, setAvailableItems, onLoadItems]);

  useAsyncEffect(async (_, abortSignal) => {
    if (searchBehavior !== "async"
      || !asyncSearchMinChars
      || searchValue.trim().length < asyncSearchMinChars) {
      return;
    }

    // Wait for a small delay.
    await new Promise(resolve => setTimeout(resolve, asyncSearchDelay ?? 1000));

    if (abortSignal.aborted) {
      return;
    }

    try {
      setLoadOperation({
        isWorking: true,
      });

      const items = await onLoadItems(searchValue, abortSignal);
      if (abortSignal.aborted) {
        return;
      }

      setAvailableItems(items);
      setLoadOperation(undefined);
    } catch (err) {
      if (abortSignal.aborted) {
        return;
      }

      const errMsg = getResponseErrorMessage(err);

      if (errMsg.trim() === "The operation was aborted.") {
        return;
      }

      setLoadOperation({
        isWorking: false,
        errorMessage: errMsg,
      });
    }
  }, [searchValue, searchBehavior, asyncSearchMinChars, setLoadOperation, setAvailableItems]);

  const toPickerItem = (item: T, includeChildren?: boolean, allItems?: T[]): IPickerItem<T> => {
    return convertToPickerItem(item, expandedKeys, keyMapper, childMapper, includeChildren, allItems);
  };

  const onSelected = (item: IPickerItem<T>) => {
    if (item?.item) {
      if (props.allowMultiSelect
        && !selectedItems.some(k => keyMapper(k) === item.key)) {
        onSelectionChanged([...selectedItems, item?.item]);
      } else {
        onSelectionChanged([item?.item]);
      }
    }
  };

  const onDeselected = (item: IPickerItem<T>) => {
    onSelectionChanged(selectedItems.filter(x => keyMapper(x) !== item.key));
  };

  /** Filters the items synchronously. */
  let visibleItems: IPickerItem<T>[] = [...availableItems.map(x => toPickerItem(x, true, availableItems))];

  if (props.searchOptions?.behavior !== "async"
    && filterItems) {
    const searchLowered = searchValue.toLowerCase();

    visibleItems = filterItemsByTerm(visibleItems,
      searchLowered,
      filterItems
      ?? ((item: IPickerItem<T>, searchLowered: string) => {
        const ix = item.text?.toLowerCase()?.indexOf(searchLowered);
        return ix !== undefined
          && ix > -1;
      }),
      true);
  }

  const defaultNoItemsMessage = asyncSearchMinChars
    ? (!searchValue.trim()
      ? "Please use the search box to find items."
      : "No items were found. Please use the search box to find items."
    )
    : "No items were found.";

  let searchBox = <></>;
  if (filterItems || searchBehavior === "async") {
    const box = (
      <SearchBox
        inputProps={{
          placeholder: `Search ${props.title}`,
          value: searchValue,
          onChange: (e) => setSearchValue(e.currentTarget.value),
        }}
      />
    );

    if (!props.hideLabelAboveSearch) {
      searchBox = (
        <LabeledControl
          label={props.title}
          isRequired={isRequired}>
          {box}
        </LabeledControl>
      );
    } else {
      searchBox = box;
    }
  }

  let tagsRecap = <></>;

  if (tagsProps
    && (props.showSelectedItems !== false
      || props.showSuggestedItems)) {
    tagsRecap = (
      <>
        <hr className="divider" />
        <FlexCol className="selected-items">
          {tagsProps.map(prop =>
            <LabeledControl
              label={prop.label}
              key={prop.label}
            >
              <TagsRecap
                items={selectedItems.filter(x => !prop.filterList || prop.filterList(x)).map(x => ({
                  key: keyMapper(x),
                  item: x,
                })) || []}
                nameMapper={prop.renderItem}
                onTagClick={x => x.item && onDeselected(x)}
                noItemsMessage="--"
              />
            </LabeledControl>
          )}
        </FlexCol>
      </>
    );
  }

  return (
    <FlexCol>
      {searchBox}
      <FlexCol className="list-box">
        {loadOp?.isWorking &&
          <Spinner />
        }
        {!loadOp?.isWorking &&
          <FlexRow>
            <PickerList
              items={visibleItems}
              renderItem={renderListItem}
              allowMultiSelect={props.allowMultiSelect}
              noItemsMessage={props.noItemsMessage ?? defaultNoItemsMessage}
              selectedItems={selectedItems.map(x => toPickerItem(x))}
              displayMode={displayMode}
              onItemSelected={onSelected}
              onItemDeselected={onDeselected}
              onItemExpanded={(item: IPickerItem<T>, _?: (string | number)[]) => {
                setExpandedKeys([...expandedKeys, item.key]);
              }}
              onItemCollapsed={(item: IPickerItem<T>, _?: (string | number)[]) => {
                setExpandedKeys(expandedKeys.filter(x => x !== item.key));
              }}
              isDisabledMapper={isDisabledMapper}
              isDisabled={isDisabled}
              hideUnselectableBranches={props.hideUnselectableBranches}
            />
            {tagsRecap}
          </FlexRow>
        }
      </FlexCol>
    </FlexCol>
  );
};

export default NonReduxPickerList;