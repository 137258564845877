import PerspectiveApi from "api/masterdata/PerspectiveApi";
import React, { useCallback } from "react";
import { IPerspective } from "types/auditMasterDataTypes";
import NonReduxPickerDropdown from "./non-redux-dropdown-picker/NonReduxDropdownPicker";

interface IPerspectivePickerProps {
  onApply: (selectedItems: IPerspective[]) => void,
  selectedItems: IPerspective[],
  /** Optional. If true, the user cannot change selections. */
  isDisabled?: boolean,
  /** Optional. If true, the picker will show deleted items as well. */
  includeDeleted?: boolean,
  /** Optional. If true, component is red if no value selected. */
  enableRequiredErrorDisplay?: boolean,
}

const PerspectivePicker: React.FC<IPerspectivePickerProps> = ({
  onApply,
  selectedItems,
  isDisabled = false,
  includeDeleted = false,
  enableRequiredErrorDisplay = false,
}) => {
  const renderItem = (item: IPerspective) => item.name;

  const loadItems = useCallback(async (_: string | undefined, abortSignal: AbortSignal) =>
    await PerspectiveApi.getPerspectives(includeDeleted, abortSignal),
    [includeDeleted]);

  return (
    <NonReduxPickerDropdown<IPerspective>
      itemFormatter={renderItem}
      keyMapper={item => item.id}
      onLoadItems={loadItems}
      onSelectionChanged={onApply}
      selectedItems={selectedItems}
      isDisabled={isDisabled}
      placeholder="Select"
      selectMode={"single"}
      enableRequiredErrorDisplay={enableRequiredErrorDisplay}
    />
  );
};

export default PerspectivePicker;