import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback } from "react";
import { IApi } from "types/auditMasterDataTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import NonReduxPickerDropdown from "./non-redux-dropdown-picker/NonReduxDropdownPicker";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IApiPickerProps {
  /** Optional. If true, the picker will show deleted items as well. */
  includeDeleted?: boolean,
  /** Optional. Determines the picker's mode. Default = Picker. */
  renderMode?: PickerMode,
}

const ApiPicker: React.FC<IApiPickerProps & ICommonPickerProps<IApi>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  onRenderPicker,
  isDisabled = false,
  includeDeleted = false,
  renderMode = "picker",
  userProfileMetaRestrictions,
}) => {
  const renderItem = (item: IApi) => item.name;

  const loadItems = useCallback(async (_: string | undefined, abortSignal: AbortSignal) =>
    await MasterDataApi.getAPIs(includeDeleted, abortSignal),
    [includeDeleted]);

  const restrictedToItems = userProfileMetaRestrictions
    ?.filter(x => x.type === MetaDataTypes.API) || [];

  if (renderMode === "picker") {
    return (
      <NonReduxPicker<IApi>
        itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title="APIs"
        allowMultiSelect={allowMultiselect}
        displayMode="list"
        renderListItem={renderItem}
        searchOptions={{
          filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
        }}
        onRenderPicker={onRenderPicker}
        isDisabled={isDisabled}
        isDisabledMapper={!restrictedToItems.length
          ? undefined
          : item => !restrictedToItems.some(x => x.id === item.id)
        }
      />
    );
  } else if (renderMode === "dropdown") {
    return (
      <NonReduxPickerDropdown<IApi>
        itemFormatter={renderItem}
        keyMapper={item => item.id}
        onLoadItems={loadItems}
        onSelectionChanged={onApply}
        selectedItems={selectedItems}
        placeholder="Select"
        selectMode={allowMultiselect ? "multiple" : "single"}
        isDisabled={isDisabled}
        isDisabledMapper={!restrictedToItems.length
          ? undefined
          : item => !restrictedToItems.some(x => x.id === item.id)
        }
      />
    )
  } else {
    return <>Render mode "{renderMode}" is not supported.</>
  }
};

export default ApiPicker;