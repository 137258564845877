import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import { closeModal, openModal } from "store/audit-report/attachment-modal/AttachmentModalSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { AuditReportOutputTypes, AuditReportSections, AuditReportTypes, AuditStatuses, IAuditReportGeneration, IAuditReportSection, IAuditReportSectionAttachment } from "types/auditingTypes";
import { IIdNameObject } from "types/commonTypes";
import AttachmentModal from "../attachment-modal/AttachmentModal";
import "./ReportModal.scoped.scss";
import SectionItem from "./section-item/SectionItem";
import Hint from "shared/components/common/hint/Hint";

interface IReportModalProps {
  auditId: number,
  auditStatus: AuditStatuses,
  topics: IIdNameObject[],
  onClose(): void,
  onSubmit(options: IAuditReportGeneration): void,
}

interface ISection {
  isChecked: boolean,
  customLabel?: string,
  availability: ISectionAvailability[],
  section: AuditReportSections,
  topicId?: number,
  attachments: IAuditReportSectionAttachment[],
}

type AvailabilityTypes = "default" | "included" | "optional" | "n/a";

interface ISectionAvailability {
  reportType: AuditReportTypes,
  availability: AvailabilityTypes,
}

const ReportModal: React.FC<IReportModalProps> = ({
  auditId,
  auditStatus,
  topics,
  onClose,
  onSubmit,
}) => {
  const auditScoringSystem = useAppSelector(store => store.audit.questions?.[0]?.scoringSystem);
  const [reportType, internalSetReportType] = useState<AuditReportTypes | undefined>();
  const [outputType, setOutputType] = useState<AuditReportOutputTypes>(AuditReportOutputTypes.PowerPoint);
  const [sections, setSections] = useState<ISection[]>(getInitialSections(topics, auditScoringSystem));
  const [currentSection, setCurrentSection] = useState<ISection | undefined>();
  const isEvidenceModalOpen = useAppSelector(store => store.auditReportAttachmentModal.isOpen);
  
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const reportTypes = [
    AuditReportTypes.OpeningMeeting,
    AuditReportTypes.ClosingMeeting,
  ];

  const setReportType = useCallback((reportType: AuditReportTypes) => {
    internalSetReportType(reportType);

    // Update the checked status of the sections.
    let newSections = sections.slice();

    let anyChange = false;

    newSections.forEach(sect => {
      const avail = sect.availability.find(x => x.reportType === reportType);

      if (!sect.isChecked
        && (avail?.availability === "included"
          || avail?.availability === "default"
        )) {
        sect.isChecked = true;
        anyChange = true;
      } else if (sect.isChecked
        && (avail?.availability === "n/a"
          || avail?.availability === "optional")) {
        sect.isChecked = false;
        anyChange = true;
      }
    });

    if (anyChange) {
      setSections(newSections);
    }
  }, [sections, internalSetReportType, setSections]);

  const onSectionClicked = (sect: ISection) => {
    if (!reportType) {
      return;
    }

    const newSections = cloneDeep(sections);
    const exSect = newSections.find(x => x.section === sect.section
      && x.topicId === sect.topicId);

    if (!exSect) {
      return;
    }

    const avail = exSect.availability.find(x => x.reportType === reportType);

    if (exSect.isChecked
      && (avail?.availability === "optional"
        || avail?.availability === "included")) {
      if (exSect.section === AuditReportSections.TopicSummary) {
        newSections.filter((x) => x.section === AuditReportSections.SpecificTopicSummary)
          .forEach((x) => x.isChecked = false);
      }
      exSect.isChecked = false;
      if (exSect.section === AuditReportSections.SpecificTopicSummary) {
        let hasTopics = newSections.some((x) => x.section === AuditReportSections.SpecificTopicSummary && x.isChecked === true);
        if (!hasTopics) {
          newSections.filter((x) => x.section === AuditReportSections.TopicSummary)
            .forEach((x) => x.isChecked = false);
        }
      }
      setSections(newSections);
    } else if (!exSect.isChecked
      && (avail?.availability === "optional"
        || avail?.availability === "included")) {
      if (exSect.section === AuditReportSections.TopicSummary) {
        newSections.filter((x) => x.section === AuditReportSections.SpecificTopicSummary)
          .forEach((x) => x.isChecked = true);
      }
      exSect.isChecked = true;
      setSections(newSections);
    }
  }

  const onGenerateReport = () => {
    if (!reportType
      || !outputType
      || !sections.some(x => x.isChecked)) {
      return;
    }

    onSubmit({
      reportType,
      outputType,
      includedSections: sections
        .filter(x => x.isChecked)
        .map((sect): IAuditReportSection => ({
          section: sect.section,
          topicId: sect.topicId,
          attachments: sect.attachments,
        })),
    });

    appInsights.trackPageView({
      name: `Generate Report - ${outputType}`,
    });
  }

  const onAttachmentButtonClicked = (section: ISection) => {
    setCurrentSection(section);
    dispatch(openModal());
  }

  const onSetSectionAttachments = (attachments: IAuditReportSectionAttachment[]) => {
    if (!currentSection) {
      return;
    }

    let newSections = cloneDeep(sections);
    let match = newSections.find(x => x.section === currentSection.section
      && x.topicId === currentSection.topicId);

    if (!match) {
      return;
    }

    match.attachments = attachments;

    setSections(newSections);
    setCurrentSection(undefined);
    dispatch(closeModal());
  }

  useEffect(() => {
    if (reportType === undefined) {
      setReportType(
        auditStatus === AuditStatuses.Planned
          || auditStatus === AuditStatuses.InProgress
          ? AuditReportTypes.OpeningMeeting
          : AuditReportTypes.ClosingMeeting
      );
    }
  }, [auditStatus, reportType, setReportType]);


  return (
    <Modal
      isOpen={true}
      header="Create a Report"
      minWidth="400px"
      buttons={[{
        key: "CANCEL",
        text: "Cancel",
        className: "secondary",
        onClick: onClose,
      }, {
        key: "OK",
        text: "Generate Report",
        className: "primary",
        onClick: onGenerateReport,
        disabled: !outputType
          || !reportType
          || !sections.some(x => x.isChecked)
      }]}
    >
      <FlexCol
        className="modal-col"
      >
        <LabeledControl
          label="Type"
          isRequired
          labelExtraClass="create-report-labels"
        >
          <FlexRow
            className="report-types"
          >
            {reportTypes.map(type => (
              <label
                key={type}
              >
                <input
                  type="radio"
                  name="type"
                  value={type}
                  checked={reportType === type}
                  onChange={() => setReportType(type)}
                />
                {type}
              </label>
            ))}
          </FlexRow>
        </LabeledControl>

        <LabeledControl
          label="Sections to include"
          isRequired
        >
          <FlexCol
            className="sections-col"
          >
            {sections.map(sect => (
              <SectionItem
                key={sect.section + '_' + (sect.topicId || '0')}
                isChecked={sect.isChecked}
                isDisabled={reportType === undefined
                  || isSectionDisabled(reportType, sect.availability)}
                isIndented={sect.section === AuditReportSections.SpecificTopicSummary}
                label={sect.customLabel || sect.section}
                attachments={sect.attachments}
                onSectionClicked={() => onSectionClicked(sect)}
                onAttachmentClicked={() => onAttachmentButtonClicked(sect)}
              />
            ))}
          </FlexCol>
        </LabeledControl>

        <LabeledControl
          label="Output type"
          isRequired
        >
          <FlexRow
            className="output-types"
          >
            <label className="create-report-labels">
              <input
                type="radio"
                name="output"
                value={AuditReportOutputTypes.Word.toString()}
                checked={outputType === AuditReportOutputTypes.Word}
                onChange={() => setOutputType(AuditReportOutputTypes.Word)}
              />
              {AuditReportOutputTypes.Word.toString()}
            </label>
            <label>
              <input
                type="radio"
                name="output"
                value={AuditReportOutputTypes.PowerPoint}
                checked={outputType === AuditReportOutputTypes.PowerPoint}
                onChange={() => setOutputType(AuditReportOutputTypes.PowerPoint)}
              />
              {AuditReportOutputTypes.PowerPoint}
            </label>
          </FlexRow>
        </LabeledControl>
        <Hint className="span-3 info">
          Please ensure your pop-up blocker is turned off.
        </Hint>
      </FlexCol>

      {isEvidenceModalOpen && (
        <AttachmentModal
          header={`${currentSection?.customLabel ?? currentSection?.section} Attachments`}
          auditId={auditId}
          selectedAttachments={currentSection?.attachments || []}
          onOk={onSetSectionAttachments}
        />
      )}
    </Modal>
  );
};

export default ReportModal;

function isSectionDisabled(reportType: AuditReportTypes, availabilities: ISectionAvailability[]) {
  let avails = availabilities.find(x => x.reportType === reportType);

  return avails?.availability === "n/a"
    || avails?.availability === "default";
}

function getInitialSections(topics: IIdNameObject[], auditScoringSystem: string): ISection[] {
  var isQMSorCLM = auditScoringSystem === AuditScoringSystems.QMS || auditScoringSystem === AuditScoringSystems.CLM;

  let items: ISection[] = [
    getInitialSection(AuditReportSections.AuditTeams, getAvailabilities("included", "optional")),
    getInitialSection(AuditReportSections.AuditScope, getAvailabilities("default", "default")),
    getInitialSection(AuditReportSections.AuditSchedule, getAvailabilities("default", "optional")),
    getInitialSection(AuditReportSections.LocalKnowledge, getAvailabilities("included", "n/a")),
    getInitialSection(AuditReportSections.Findings, getAvailabilities("n/a", isQMSorCLM ? "included" : "n/a")),
    getInitialSection(AuditReportSections.FindingsDetails, getAvailabilities("n/a", isQMSorCLM ? "included" : "n/a")),
    getInitialSection(AuditReportSections.ResultsSummary, getAvailabilities("n/a", "default")),
    getInitialSection(AuditReportSections.TopicSummary, getAvailabilities("n/a", "optional")),
  ];

  topics.forEach(t => {
    items.push({
      section: AuditReportSections.SpecificTopicSummary,
      availability: getAvailabilities("n/a", "optional"),
      attachments: [],
      isChecked: false,
      topicId: t.id,
      customLabel: t.name,
    });
  });

  items = items.concat([
    getInitialSection(AuditReportSections.Highlights, getAvailabilities("n/a", "included")),
    getInitialSection(AuditReportSections.OpportunitiesForImprovement, getAvailabilities("n/a", "included")),
    getInitialSection(AuditReportSections.RWPSummary, getAvailabilities("n/a", "default")),
    getInitialSection(AuditReportSections.ClosingStatements, getAvailabilities("n/a", "included")),
    getInitialSection(AuditReportSections.WayForward, getAvailabilities("n/a", "included")),
  ]);

  return items;
}

function getInitialSection(section: AuditReportSections, availabilities: ISectionAvailability[]) {
  return {
    section,
    availability: availabilities,
    attachments: [],
    isChecked: false,
  };
}

function getAvailabilities(openingMeeting: AvailabilityTypes, closingMeeting: AvailabilityTypes): ISectionAvailability[] {
  return [{
    reportType: AuditReportTypes.OpeningMeeting,
    availability: openingMeeting,
  }, {
    reportType: AuditReportTypes.ClosingMeeting,
    availability: closingMeeting,
  },
  ];
}
