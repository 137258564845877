import { useMsal } from "@azure/msal-react";
import React from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import CircleImgButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import deleteIcon from "shared/media/dls/delete.svg";
import editIcon from "shared/media/dls/edit-1.svg";
import { IColumnItem } from "shared/types/columnTypes";
import { canEditActionItem } from "shared/utilities/actionItemUtilities";
import formatDate from "shared/utilities/dateFormatters";
import { userToString } from "shared/utilities/userUtilities";
import { IActionItem } from "types/actionItemTypes";
import ActionItemStatus from "../status/ActionItemStatus";
import { useAppSelector } from "store/store";
import { IActiveUserProfile, IAzureADUser } from "shared/types/userProfileTypes";
import { IAuditPageAuth } from "types/auditPageAuthTypes";

interface IActionItemsListProps {
  actionItems: IActionItem[],
  isDisabled?: boolean,
  onEditClick(actionItem: IActionItem): void,
  allowEdit: boolean,
  onDeleteClick?(actionItem: IActionItem): void,
  auditPageAuth: IAuditPageAuth
}

const ActionItemsList: React.FC<IActionItemsListProps> = ({
  actionItems,
  isDisabled,
  onEditClick,
  allowEdit,
  auditPageAuth,
  onDeleteClick,
}) => {
  const msal = useMsal();
  const user = msal.accounts[0];
  const userEmailLowered = user.username?.toLowerCase() || "";
  const currentUserProfile = useAppSelector(store => store.auth.activeUserProfile);
  const currentUser = useAppSelector(store => store.auth.currentUser);

  if (!actionItems.length) {
    return (
      <Banner
        type={BannerType.info}
      >
        There are no action items.
      </Banner>
    );
  }

  return (
    <Table
      keyProp="id"
      className="action-items-table"
      columns={getActionItemTableColumns(isDisabled === true,
        onEditClick,
        userEmailLowered,
        currentUser,
        currentUserProfile,
        allowEdit,
        auditPageAuth,
        onDeleteClick)
      }
      data={actionItems}
      theme={TableThemes.compact}
    />
  );
}

export default ActionItemsList;

export function getActionItemTableColumns(isDisabled: boolean,
  onEditClick: (item: IActionItem) => void,
  userEmailLowered: string,
  currentUser: IAzureADUser,
  currentUserProfile: IActiveUserProfile,
  allowEdit: boolean,
  auditPageAuth: IAuditPageAuth,
  onDeleteClick?: (item: IActionItem) => void,
): IColumnItem<IActionItem, keyof IActionItem>[] {
  return [
    {
      key: "edit",
      header: "",
      customRender: item => (

        <CircleImgButton
          alt="Edit"
          icon={editIcon}
          type="primary"
          onClick={() => onEditClick(item)}
          isDisabled={!canEditActionItem(item, currentUser, currentUserProfile, auditPageAuth)}
        />
      ),
    },
    {
      key: "ID",
      header: "ID",
      width: 50,
      customRender: item => item.id || "-",
    },
    {
      key: "assignedTo",
      header: "Responsible Party",
      width: 150,
      customRender: item => userToString(item.assignedTo) || "-",
    },
    {
      key: "actionReq",
      header: "Description",
      width: 225,
      maxHeight: "4em",
      customRender: item => item.actionRequired,
    },
    {
      key: "priority",
      header: "Priority",
      width: 80,
      customRender: item => item.priority?.name || "--",
    },
    {
      key: "dueDate",
      header: "Due Date",
      width: 100,
      customRender: item => formatDate(new Date(item.dueByTimestamp)),
    },
    {
      key: "status",
      header: "Status",
      width: 100,
      customRender: item => (
        <ActionItemStatus
          statusData={item}
        />
      ),
    },
    {
      key: "type",
      header: "Action Item Type",
      width: 100,
      customRender: item => item.type?.name || "--",
    },
    {
      key: "causalFactor",
      header: "Causal Factor",
      width: 130,
      customRender: item => item.causalFactor?.name || "--",
    },
    {
      key: "delete",
      header: "",
      customRender: item => onDeleteClick
        ? (
          <CircleImgButton
            alt="Delete"
            icon={deleteIcon}
            isDisabled={!canEditActionItem(item, currentUser, currentUserProfile, auditPageAuth)}
            tooltip={!canEditActionItem(item, currentUser, currentUserProfile, auditPageAuth)
              ? "You cannot remove this action item."
              : undefined
            }
            type="secondary"
            onClick={() => onDeleteClick(item)}
          />
        ) : undefined,
    }
  ];
}