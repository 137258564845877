import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { setSearchPropValue } from "store/search/audits/SearchAuditsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const AuditName: React.FC = () => {
  const auditName = useAppSelector(store => store.searchAudits.auditName);
  const dispatch = useAppDispatch();

  return (
    <LabeledControl
      label="Audit Name"
      hint={getTextboxMaxLengthHint(200)}
    >
      <input
        type="text"
        placeholder="Audit Name"
        value={auditName}
        onChange={e => dispatch(setSearchPropValue({
          auditName: e.currentTarget.value,
        }))}
        maxLength={200}
      />
    </LabeledControl>
  );
};

export default AuditName;