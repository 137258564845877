import FindingApi from "api/findings/FindingApi";
import { useState } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal, { ModalButton } from "shared/components/layout/modal/Modal";
import DeleteIcon from "shared/media/dls/delete.svg";
import EditIcon from "shared/media/dls/edit-2.svg";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { closeFindingModal, finishFindingOp, onRemoveFinding, onSetFinding, setFindingModalMode, startFindingOp } from "store/audit/AuditSlice";
import { FindingModalModes, IFinding, IFindingLink, IFindingModal } from "store/audit/reducers/findingReducers";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses } from "types/auditingTypes";
import EditCreateFindingBody from "./modal-bodies/EditCreateFindingBody";
import UnlinkModalBody from "./modal-bodies/UnlinkModalBody";
import ViewFindingBody from "./modal-bodies/ViewFindingBody";

interface IManageFindingModal {
  /** The function to call when the user clicks the save button. The new or edited finding will be passed to the function. If the function returns TRUE, the modal will also close. */
  onSave(finding: IFinding): boolean,
  /** If true, the modal will save the Finding to the server itself, update the redux state, and then call onSave with the now saved Finding. */
  saveToServerOnSave: boolean,
  /** Called after the user has removed links & the removal has been saved to the server. */
  onLinksRemoved?(removedLinks: IFindingLink[]): void,
  /** Optional. Determines if the modal is being rendered from the Findings page. Used to change the modal header text. */
  isFindingsPage?: boolean,
  /** Optional. Overrides the default permissions logic check of the finding. */
  isEditable?: boolean,
}

const ManageFindingModal: React.FC<IManageFindingModal> = ({
  onSave,
  saveToServerOnSave,
  onLinksRemoved,
  isFindingsPage,
  isEditable = false,
}) => {
  const modalState = useAppSelector(store => store.audit.findingModal);
  const workspace = useAppSelector(store => store.auditExecution.workspace);
  const status = useAppSelector(store => store.audit.audit?.status);
  const isFindingEditable = (useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction === EditRestriction.EditAll)
    && status === AuditStatuses.InProgress)
    || isEditable;
  const isSaveEnabled = modalState.currentFinding?.justification !== modalState.originalFinding?.justification
    || modalState.currentFinding?.findingTypeId !== modalState.originalFinding?.findingTypeId;
  const isWorkspaceFindingDirty = workspace?.current?.finding !== undefined
    && (
      workspace?.current?.finding?.justification !== workspace?.original?.finding?.justification
      || workspace?.current?.finding?.findingTypeId !== workspace?.original?.finding?.findingTypeId
    );
  const [selectedLinkIds, setSelectedLinkIds] = useState<number[]>([]);

  const dispatch = useAppDispatch();
  const onClose = () => dispatch(closeFindingModal());

  const onSaveClicked = async () => {
    if (!modalState.currentFinding?.findingTypeId
      || !modalState.currentFinding?.justification) {
      return;
    }

    let findingToSave: IFinding = {
      deleted: false,
      id: modalState.currentFinding?.id,
      justification: modalState.currentFinding.justification,
      findingTypeId: modalState.currentFinding.findingTypeId,
      links: modalState.currentFinding.links ?? [],
    };

    if (saveToServerOnSave) {
      try {
        // Show the spinner.
        dispatch(startFindingOp());

        // Create or update the finding.
        let savedFinding = await (
          findingToSave.id
            ? FindingApi.updateFinding(findingToSave)
            : FindingApi.createFinding(findingToSave)
        );

        // Put the new finding into the state.
        dispatch(onSetFinding(savedFinding));
      } catch (err) {
        // Toast about the error.
        dispatch(showErrorToast("Failed to load action items: " + getResponseErrorMessage(err)));
      } finally {
        // Stop the save finding op.
        dispatch(finishFindingOp());
      }
    }

    if (onSave(findingToSave)) {
      onClose();
    }
  };

  const onEditClicked = () => {
    dispatch(setFindingModalMode(FindingModalModes.EditCreate));
  };

  const onShowRemoveLinksClicked = () => {
    dispatch(setFindingModalMode(FindingModalModes.Unlink));
  };

  const onRemoveLinksClicked = async () => {
    if (!modalState.currentFinding) {
      dispatch(showErrorToast("There is no finding currently loaded."));
      return;
    }

    if (!modalState.currentFinding?.links) {
      dispatch(showErrorToast("The current finding has no links."));
      return;
    }

    if (!modalState.currentFinding.id) {
      // The finding was never saved to the server.
      onLinksRemoved?.(modalState.currentFinding.links);
      onClose();
      return;
    }

    // Send the link ids to remove to the server.
    const linkIdsToUnlink = modalState.currentFinding.links.length === 1
      ? [modalState.currentFinding.links[0].id]
      : selectedLinkIds;

    if (!linkIdsToUnlink.length) {
      dispatch(showErrorToast("There are no links selected for removal."));
      return;
    }

    try {
      dispatch(startFindingOp());

      const {
        wasFindingDeleted,
      } = await FindingApi.unlinkFinding(modalState.currentFinding.id,
        linkIdsToUnlink);

      onLinksRemoved?.(linkIdsToUnlink
        .map(x => modalState.currentFinding?.links?.find(l => l.id === x))
        .filter(isNotUndefined));

      if (wasFindingDeleted) {
        dispatch(onRemoveFinding({
          findingId: modalState.currentFinding.id,
        }));
      } else {
        dispatch(onSetFinding({
          deleted: modalState.currentFinding.deleted ?? false,
          links: (modalState.currentFinding.links ?? [])
            .filter(x => !linkIdsToUnlink.includes(x.id)),
          findingTypeId: modalState.currentFinding.findingTypeId,
          id: modalState.currentFinding.id ?? 0,
          justification: modalState.currentFinding.justification ?? "",
        }));
      }

      onClose();
    } catch (error) {
      dispatch(showErrorToast(getResponseErrorMessage(error)));
    } finally {
      dispatch(finishFindingOp());
    }
  };

  return (
    <Modal
      header={getModalHeader(modalState, isWorkspaceFindingDirty, isFindingsPage)}
      isOpen={true}
      showCloseButton
      onCloseButtonClicked={onClose}
      minWidth={800}
      buttons={getModalButtons(modalState,
        isFindingEditable,
        isSaveEnabled,
        isWorkspaceFindingDirty,
        selectedLinkIds,
        onClose,
        onSaveClicked,
        onEditClicked,
        onShowRemoveLinksClicked,
        onRemoveLinksClicked)}
    >
      <FlexCol>
        {modalState.mode === FindingModalModes.EditCreate &&
          <EditCreateFindingBody isEditable={isFindingEditable} />
        }

        {modalState.mode === FindingModalModes.Prompt &&
          <ViewFindingBody
            finding={{
              justification: modalState.currentFinding?.justification,
              findingTypeId: modalState.currentFinding?.findingTypeId,
            }}
          />
        }

        {modalState.mode === FindingModalModes.Unlink &&
          <UnlinkModalBody
            selectedLinkIds={selectedLinkIds}
            setSelectedLinkIds={setSelectedLinkIds}
          />
        }
      </FlexCol>

      {modalState.operation?.isWorking && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default ManageFindingModal;

function getModalHeader(modalState: IFindingModal, isWorkspaceFindingDirty: boolean, isFindingsPage?: boolean) {
  if (modalState.mode === FindingModalModes.Prompt) {
    return modalState.currentFinding?.id
      ? `Finding #${modalState.currentFinding.id}`
      : "Finding Details"
  } else if (modalState.mode === FindingModalModes.EditCreate && !isFindingsPage) {
    return (modalState.currentFinding?.id || isWorkspaceFindingDirty)
      ? "Edit Finding"
      : "Assign Finding";
  } else if (modalState.mode === FindingModalModes.EditCreate && isFindingsPage) {
    return (modalState.currentFinding?.id || isWorkspaceFindingDirty)
      ? `Finding #${modalState.currentFinding?.id}`
      : "Assign Finding";
  } else if (modalState.mode === FindingModalModes.Unlink) {
    return (modalState.currentFinding?.links?.length ?? 1) > 1
      ? "Remove Finding Links"
      : "Delete Finding";
  } else {
    return "Unknown Modal";
  }
}

function getModalButtons(modalState: IFindingModal,
  isEditable: boolean,
  isSaveEnabled: boolean,
  isWorkspaceFindingDirty: boolean,
  selectedLinkIds: number[],
  onClose: () => void,
  onSaveClicked: () => void,
  onEditClicked: () => void,
  onShowRemoveLinksClicked: () => void,
  onRemoveLinksClicked: () => void): ModalButton[] {
  const cancelButton: ModalButton = {
    className: "secondary",
    text: "Cancel",
    key: "CANCEL",
    onClick: onClose,
  };

  const modalButtons: ModalButton[] = [];

  if (modalState.mode === FindingModalModes.Prompt) {
    cancelButton.className = "primary";

    modalButtons.push({
      className: "secondary img-right",
      text: "Edit Finding",
      key: "EDITFINDING",
      onClick: onEditClicked,
      disabled: !isEditable,
      img: EditIcon,
    }, {
      className: "secondary img-right",
      text: "Remove Finding",
      key: "REMOVEFINDING",
      onClick: onShowRemoveLinksClicked,
      disabled: !isEditable,
      img: DeleteIcon,
    }, cancelButton);
  } else if (modalState.mode === FindingModalModes.EditCreate) {
    modalButtons.push(cancelButton, {
      className: "primary",
      text: (modalState.currentFinding?.id || isWorkspaceFindingDirty)
        ? "Save"
        : "Assign Finding",
      key: "ASSIGNFINDING",
      onClick: onSaveClicked,
      disabled: !isEditable
        || !modalState.currentFinding?.findingTypeId
        || !modalState.currentFinding?.justification?.trim()
        || !isSaveEnabled,
    });
  } else if (modalState.mode === FindingModalModes.Unlink) {
    modalButtons.push(cancelButton, {
      className: "primary img-right",
      text: (modalState.currentFinding?.links?.length ?? 1) > 1
        ? "Remove Links"
        : "Delete",
      key: "REMOVELINKS",
      onClick: onRemoveLinksClicked,
      img: DeleteIcon,
      imgclassname: "white-filter",
      disabled: !isEditable
        || ((modalState.currentFinding?.links?.length ?? 1) > 1
          && !selectedLinkIds.length),
    });
  }

  return modalButtons;
}