import { PayloadAction } from "@reduxjs/toolkit";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";

interface IReducerWithPickerData<T> {
  pickerData: {
    [pickerName: string]: IPickerState<T>,
  }
}

interface IPickerReducerHandlers<T> {
  openPicker: (state: IReducerWithPickerData<T>, action: PayloadAction<IOpenPickerAction>) => void,
  closePicker: (state: IReducerWithPickerData<T>, action: PayloadAction<IClosePickerAction>) => void,
  loadPickerItems: (state: IReducerWithPickerData<T>, action: PayloadAction<ILoadPickerItemsAction>) => void,
  loadSuggestedPickerItems: (state: IReducerWithPickerData<T>, action: PayloadAction<ILoadSuggestedPickerItemsAction>) => void,
  setPickerError: (state: IReducerWithPickerData<T>, action: PayloadAction<ISetPickerErrorAction>) => void,
  setPickerItems: (state: IReducerWithPickerData<T>, action: PayloadAction<ISetPickerItemsAction<T>>) => void,
  setSuggestedPickerItems: (state: IReducerWithPickerData<T>, action: PayloadAction<ISetSuggestedPickerItemsAction<T>>) => void,
  setSelectedPickerItems: (state: IReducerWithPickerData<T>, action: PayloadAction<ISetPickerSelectedItemsAction<any>>) => void,
  expandPickerItem: (state: IReducerWithPickerData<T>, action: PayloadAction<IExpandPickerItemAction>) => void,
  collapsePickerItem: (state: IReducerWithPickerData<T>, action: PayloadAction<ICollapsePickerItemAction>) => void,
  setPickerState: (state: IReducerWithPickerData<T>, action: PayloadAction<IPickerState<T>>) => void,
}

export interface IOpenPickerAction {
  pickerKey: string,
}

export interface IClosePickerAction {
  pickerKey: string,
}

export interface ILoadPickerItemsAction {
  pickerKey: string,
  searchValue?: string,
}

export interface ILoadSuggestedPickerItemsAction {
  pickerKey: string,
}

export interface ISetPickerErrorAction {
  pickerKey: string,
  errorMessage: string,
  stopLoading: boolean,
}

export interface ISetPickerItemsAction<T> {
  pickerKey: string,
  items: IPickerItem<T>[],
}

export interface ISetSuggestedPickerItemsAction<T> {
  pickerKey: string,
  suggestedItems: IPickerItem<T>[],
}

export interface ISetPickerSelectedItemsAction<T> {
  pickerKey: string,
  selectedItems: IPickerItem<T>[],
}

export interface IExpandPickerItemAction {
  pickerKey: string,
  itemKey: (string | number),
  ancestryPath?: (string | number)[],
}

export interface ICollapsePickerItemAction {
  pickerKey: string,
  itemKey: (string | number),
  ancestryPath?: (string | number)[],
}

const pickerHandlers: IPickerReducerHandlers<any> = {
  openPicker: (state, action: PayloadAction<IOpenPickerAction>) => {
    let pickerData = state.pickerData[action.payload.pickerKey];

    if (!pickerData) {
      return;
    }

    pickerData.isOpen = true;
  },
  closePicker: (state, action: PayloadAction<IClosePickerAction>) => {
    let pickerData = state.pickerData[action.payload.pickerKey];

    if (!pickerData) {
      return;
    }

    pickerData.isOpen = false;
  },
  loadPickerItems: (state, action: PayloadAction<ILoadPickerItemsAction>) => {
    let pickerData = state.pickerData[action.payload.pickerKey];

    if (!pickerData) {
      return;
    }

    pickerData.loadOperation = {
      isWorking: true,
    };
  },
  loadSuggestedPickerItems: (state, action: PayloadAction<ILoadPickerItemsAction>) => {
    let pickerData = state.pickerData[action.payload.pickerKey];

    if (!pickerData) {
      return;
    }

    pickerData.loadSuggestionsOperation = {
      isWorking: true,
    };
  },
  setPickerError: (state, action: PayloadAction<ISetPickerErrorAction>) => {
    const {
      pickerKey,
      errorMessage,
      stopLoading,
    } = action.payload;

    let pickerData = state.pickerData[pickerKey];

    if (!pickerData) {
      return;
    }

    if (!pickerData.loadOperation) {
      pickerData.loadOperation = {
        isWorking: false,
        errorMessage,
      };
    }

    if (stopLoading) {
      pickerData.loadOperation.isWorking = false;
    }

    pickerData.loadOperation.errorMessage = errorMessage;
  },
  setPickerItems: (state, action: PayloadAction<ISetPickerItemsAction<any>>) => {
    let pickerData = state.pickerData[action.payload.pickerKey];

    if (!pickerData) {
      return;
    }

    pickerData.items = action.payload.items;
    pickerData.loadOperation = undefined;
  },
  setSuggestedPickerItems: (state, action: PayloadAction<ISetSuggestedPickerItemsAction<any>>) => {
    let pickerData = state.pickerData[action.payload.pickerKey];

    if (!pickerData) {
      return;
    }

    pickerData.suggestedItems = action.payload.suggestedItems;
    pickerData.loadSuggestionsOperation = undefined;
  },
  setSelectedPickerItems: (state, action: PayloadAction<ISetPickerSelectedItemsAction<any>>) => {
    let pickerData = state.pickerData[action.payload.pickerKey];

    if (!pickerData) {
      return;
    }

    pickerData.selectedItems = action.payload.selectedItems;
  },
  expandPickerItem: (state, action: PayloadAction<IExpandPickerItemAction>) => {
    const {
      pickerKey,
      itemKey,
      ancestryPath,
    } = action.payload;

    let pickerData = state.pickerData[pickerKey];

    if (!pickerData) {
      return;
    }

    toggleItemExpansion(itemKey,
      pickerData.items,
      true,
      ancestryPath || []);
  },
  collapsePickerItem: (state, action: PayloadAction<IExpandPickerItemAction>) => {
    const {
      pickerKey,
      itemKey,
      ancestryPath,
    } = action.payload;

    let pickerData = state.pickerData[pickerKey];

    if (!pickerData) {
      return;
    }

    toggleItemExpansion(itemKey,
      pickerData.items,
      false,
      ancestryPath || []);
  },
  setPickerState: (state, action: PayloadAction<IPickerState<any>>) => {
    state.pickerData[action.payload.key] = action.payload;
  },
};

export default pickerHandlers;

function toggleItemExpansion<T>(key: string | number,
  items: IPickerItem<T>[],
  isExpanded: boolean,
  ancestryPath: (string | number)[]) {

  let parentKey = ancestryPath[0];

  if (parentKey === undefined) {
    items
      .filter(x => x.key === key)
      .forEach(x => x.isExpanded = isExpanded);

    return;
  } else {
    const parent = items.find(x => x.key === parentKey);
    if (!parent) {
      return;
    }

    toggleItemExpansion(key,
      parent.children || [],
      isExpanded,
      ancestryPath.slice(1));
  }
}
