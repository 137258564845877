import UrlRoutes from "components/routing/UrlRoutes";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import Hint from "shared/components/common/hint/Hint";
import Prompt from "shared/components/common/prompt/Prompt";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Button from "shared/components/controls/buttons/button/Button";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Page from "shared/components/layout/page/Page";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import usePageRestriction from "shared/utilities/hooks/usePageRestriction";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { isNotUndefined } from "shared/utilities/typeCheck";
import {
  getDisabledSaveReason,
  saveQuestion,
  saveQuestionAdditionalAssociations,
  setOpenedQuestionLogId,
  toggleSaveConfirmation
} from "store/question-details/QuestionDetailsSlice";
import { useAppSelector } from "store/store";
import { AuditPages, EditRestriction, PageAuthRequestType } from "types/auditPageAuthTypes";
import { IQuestion } from "types/questionTypes";
import QuestionActiveModal from "./question-active-modal/QuestionActiveModal";
import QuestionAdditionalAssociationsCard from "./question-additional-associations-card/QuestionAdditionalAssociationsCard";
import QuestionAssociationsCard from "./question-associations/QuestionAssociationsCard";
import QuestionDetailsCard from "./question-details/QuestionDetailsCard";
import QuestionDiffModal from "./question-diff-modal/QuestionDiffModal";
import QuestionHistoryCard from "./question-history/QuestionHistoryCard";
import QuestionLoader from "./question-loader/QuestionLoader";
import { AuditScoringSystems } from "types/auditPlanningTypes";

const ManageQuestionPage: React.FC = () => {
  const isDirty = useAppSelector(store => store.questionDetails.isDirty);
  const question = useAppSelector(store => store.questionDetails.question);
  const ownerGroups = useAppSelector(store => store.questionDetails.masterData.ownerGroups);
  const originalQuestion = useAppSelector(store => store.questionDetails.originalQuestion);
  const openedQuestionLogId = useAppSelector(store => store.questionDetails.openedQuestionLogId);
  const isSaveConfirmOpen = useAppSelector(store => store.questionDetails.isSaveConfirmOpen);
  const saveOp = useAppSelector(store => store.questionDetails.saveOp);
  const editRestriction = useAppSelector(store => store.auditPageRestriction.auditPageAuth.editRestriction);
  const [comment, setComment] = useState<string>("");
  const derivedMetaRestrictions = useAppSelector(store => store.auth.activeUserProfile.derivedMetaRestrictions);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const canEdit = editRestriction !== EditRestriction.EditNone
    && !question?.isDeleted
    && (!question?.id
      || question?.isLatestVersion);

  const addtlAssocPermissionOp = usePageRestriction({ pageName: AuditPages.EditQuestionAdditionalAssociation, type: PageAuthRequestType.nonAudit });
  const editAttlAssocEditPermission = addtlAssocPermissionOp.data?.editRestriction;

  const canEditAddtlAssoc = (editAttlAssocEditPermission === EditRestriction.EditAll
    || editAttlAssocEditPermission === EditRestriction.EditAllRestrict)
    && !canEdit;

  const ownerGroup = ownerGroups.find(x => x.id === question?.auditTopic?.ownerGroupId);
  const scoringSystem = ownerGroup?.scoringSystem;

  const disabledSaveReason = getDisabledSaveReason(question || {},
    scoringSystem);

  const isCLM = scoringSystem === AuditScoringSystems.CLM;

  const headerButtons = [{
    element: (
      <Button
        buttonType="secondary"
        onClick={() => navigate(UrlRoutes.QuestionLibrary.urlTemplate)}
      >
        {canEdit ? "Cancel" : "Close"}
      </Button>
    ),
    key: "CANCEL",
  }, {
    element: (
      <Button
        buttonType="primary"
        isDisabled={
          !getIsSaveAsCopyDisabled(question, originalQuestion)
          || !question?.id
        }
        onClick={() => dispatch(saveQuestion({
          comment: "",
          isCLM,
          saveAsNewCopy: true,
        }))}
        tooltip={getCantSaveAsNewToolTip(question, originalQuestion)}
      >
        SAVE AS NEW COPY
      </Button>
    ),
    key: "SAVE NEW",
  }, canEdit
    ? {
      element: (
        <Button
          buttonType="primary"
          isDisabled={!isDirty
            || !canEdit
            || !!disabledSaveReason
          }
          tooltip={getCantSaveTooltip(disabledSaveReason,
            question,
            isDirty)
          }
          onClick={() => !question?.id
            ? dispatch(saveQuestion({
              comment: "",
              isCLM,
              saveAsNewCopy: false,
            }))
            : dispatch(toggleSaveConfirmation(true))
          }
        >
          Save
        </Button>
      ),
      key: "SAVE",
    } : undefined,
  !canEdit
    && canEditAddtlAssoc
    ? {
      element: (
        <Button
          buttonType="primary"
          isDisabled={!isDirty
            || canEdit
            || !canEditAddtlAssoc
            || !!disabledSaveReason
          }
          tooltip={getCantSaveTooltip(disabledSaveReason,
            question,
            isDirty)
          }
          onClick={() => dispatch(saveQuestionAdditionalAssociations())}
        >
          Save
        </Button>
      ),
      key: "SAVE ADDTL ASSOC",
    } : undefined,
  ].filter(isNotUndefined);

  return (
    <QuestionLoader>
      <Page
        title={getQuestionTitle(question)}
        showBreadcrumbs={true}
        headerControls={headerButtons}
        maxTitleLength={250}
        backButtonRoute={UrlRoutes.QuestionLibrary.urlTemplate}
      >

        {question && (
          <>
            <QuestionDetailsCard
              isDisabled={!canEdit}
              question={question}
              derivedMetaRestrictions={editRestriction === EditRestriction.EditAllRestrict
                ? derivedMetaRestrictions
                : undefined
              }
              editRestriction={editRestriction}
            />

            <QuestionAssociationsCard
              isDisabled={!canEdit}
              question={question}
              derivedMetaRestrictions={editRestriction === EditRestriction.EditAllRestrict
                ? derivedMetaRestrictions
                : undefined
              }
            />

            <QuestionAdditionalAssociationsCard
              isDisabled={!canEditAddtlAssoc}
              question={question}
              derivedMetaRestrictions={canEditAddtlAssoc
                && editAttlAssocEditPermission === EditRestriction.EditAllRestrict
                ? derivedMetaRestrictions
                : undefined
              }
            />

            {!!question?.id && (
              <QuestionHistoryCard
                questionNumber={question?.number}
              />
            )}
          </>
        )}

        {openedQuestionLogId && (
          <QuestionDiffModal
            historyLogId={openedQuestionLogId}
            onClose={() => dispatch(setOpenedQuestionLogId(undefined))}
          />
        )}

        {isSaveConfirmOpen && (
          <ConfirmModal
            header="Save Question"
            message={
              <ModificationCommentEntry
                message="Please enter a reason for this modification."
                label="Reason for Modification"
                comment={comment}
                setComment={setComment}
              />
            }
            onNoClicked={() => dispatch(toggleSaveConfirmation(false))}
            onYesClicked={() => {
              if (!comment.trim()) {
                dispatch(showErrorToast("A Reason for Modification is required."));
                return;
              }

              dispatch(toggleSaveConfirmation(false));
              dispatch(saveQuestion({
                comment: comment,
                isCLM,
                saveAsNewCopy: false
              }));
            }}
          />
        )}

        {saveOp?.isWorking && (
          <ModalSpinner />
        )}

        <QuestionActiveModal />

        <Prompt
          isDirty={isDirty}
          message="Any unsaved changes will be lost. Are you sure you want to leave?"
        />

      </Page>
    </QuestionLoader>
  );
};

export default ManageQuestionPage;

function getQuestionTitle(question: Partial<IQuestion> | undefined): string {
  if (!question?.id
    || !question.number) {
    return "New Question";
  }

  if (!question.auditTopic?.name) {
    return "<Topic Required>";
  }

  return `${question.auditTopic.name} - #${question.number}`;
}

function getCantSaveTooltip(disabledSaveReason: string | undefined,
  question: Partial<IQuestion> | undefined,
  isDirty: boolean): string | undefined {
  if (!!disabledSaveReason) {
    return disabledSaveReason;
  } else if (question?.id
    && !question?.isLatestVersion) {
    return "Only the latest version of a question may be edited.";
  } else if (question?.isDeleted) {
    return "Inactive questions may not be edited.";
  } else if (!isDirty) {
    return "There are no changes to save.";
  }
}

function ModificationCommentEntry({
  comment,
  setComment,
  message,
  label,
}: {
  comment: string,
  setComment(value: string): void,
  message: React.ReactNode,
  label: string,
}) {
  return (
    <>
      <p>
        {message}
      </p>

      <LabeledControl
        label={label}
        isRequired={true}
        hint={getTextboxMaxLengthHint(500)}
      >
        <textarea
          onChange={e => setComment(e.currentTarget.value)}
          value={comment}
          maxLength={500}
        />
      </LabeledControl>
      <Hint>
        {500 - comment.length}/500 characters remaining
      </Hint>
    </>
  );
}

function getIsSaveAsCopyDisabled(question: Partial<IQuestion> | undefined, originalQuestion: Partial<IQuestion> | undefined): boolean {
  return question?.questionText?.trim() !== originalQuestion?.questionText?.trim() &&
    question?.guidance?.trim() !== originalQuestion?.guidance?.trim();
}

function getCantSaveAsNewToolTip(question: Partial<IQuestion> | undefined, originalQuestion: Partial<IQuestion> | undefined): string | undefined {
  if (question?.questionText?.trim() === originalQuestion?.questionText?.trim() &&
    question?.guidance?.trim() === originalQuestion?.guidance?.trim()) {
    return "A question can only be copied if the Question Text and Guidance are changed";
  }
}
