import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { cloneDeep, isEqual } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IAuditGroup, IAuditTopic } from "types/auditMasterDataTypes";
import { IOwnerGroup, ITemplate } from "types/auditingTypes";
import { IAuditTemplatesFilters, IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";

export interface IAuditTemplatesMasterData {
	auditGroups: IAuditGroup[],
	ownerGroups: IOwnerGroup[],
}

export interface IAuditTemplatesState {
	/** An array containing the all templates loaded. */
	templates: IDetailedTemplate[],
	/** The master data used in the controls. */
	masterData: IAuditTemplatesMasterData,
	/** An operation tracking the loading of the template data. */
	loadOp: IOperation<void> | undefined,
	/** An operation tracking the saving of the template data. */
	saveOp: IOperation<void> | undefined,
	/** An operation tracking the deleting of the template data. */
	deleteOp: IOperation<void> | undefined,
	/** A flag to determine if the management template modal is opened to delete, save 
	 * or update a question. */
	isMgmtModalOpen: boolean,
	/** A template object to contain the data of the template being edited or created. */
	template: Partial<IDetailedTemplate> | undefined,
	/** A copy of the original template so we can discard the changed and 
	 * check if any changes was made. */
	originalTemplate: IDetailedTemplate | undefined,
	/** Flag that indicates if the currently template modal is being create or edited. */
	isNew: boolean,
	/** A flag to determine if the template being create or edited was changed. */
	isDirty: boolean,
	/** A filter object to contain the filter's data. */
	filters: IAuditTemplatesFilters,
	/** The audit topics in our system */
	auditTopics: IAuditTopic[],
	/** The templates in our system */
	auditTemplates: ITemplate[],
	selectedTopics: IAuditTopic[],
	originalSelectedTopics: IAuditTopic[],
	selectedTemplates: (IDetailedTemplate | IDetailedTemplateChildren)[],
	originalSelectedTemplates: (IDetailedTemplate | IDetailedTemplateChildren)[],
	/** The templates the user saved */
	savedTemplatesChildren: Partial<IDetailedTemplateChildren>[],
	/** The Topics the user saved */
	savedTopicsChildren: Partial<IDetailedTemplateChildren>[],
}

const initialState: IAuditTemplatesState = {
	templates: [],
	masterData: {
		auditGroups: [],
		ownerGroups: [],
	},
	loadOp: undefined,
	saveOp: undefined,
	deleteOp: undefined,
	isMgmtModalOpen: false,
	template: undefined,
	originalTemplate: undefined,
	isNew: false,
	isDirty: false,
	filters: {
		ownerGroups: undefined,
		auditTopics: undefined,
		includeDeleted: undefined,
		childrenFilter: undefined,
	},
	auditTopics: [],
	auditTemplates: [],
	selectedTopics: [],
	selectedTemplates: [],
	savedTemplatesChildren: [],
	savedTopicsChildren: [],
	originalSelectedTopics: [],
	originalSelectedTemplates: [],
}

export const auditTemplatesSlice = createSlice({
	name: 'auditTemplates',
	initialState,
	reducers: {
		/** Begins the procedure to load all the template data. */
		loadPageData: (state: IAuditTemplatesState, action: PayloadAction<IAuditTemplatesFilters>) => {
			state.loadOp = {
				isWorking: true,
			};
			state.isMgmtModalOpen = false;
			state.filters = action.payload;
		},

		/** Finishes the procedure of loading the templates. */
		finishLoadPageData: (state: IAuditTemplatesState, action: PayloadAction<IOperation<{
			templates: IDetailedTemplate[],
			masterData: IAuditTemplatesMasterData,
			auditTopics: IAuditTopic[],
			auditTemplate: ITemplate[]
		}>>) => {
			if (action.payload.errorMessage
				|| !action.payload.data) {
				state.loadOp = {
					isWorking: false,
					errorMessage: action.payload.errorMessage,
				};
				return;
			}
			state.templates = action.payload.data.templates;
			state.masterData = action.payload.data.masterData;
			state.auditTopics = action.payload.data.auditTopics;
			state.auditTemplates = action.payload.data.auditTemplate;

			state.loadOp = undefined;
		},

		/** Toggles the management template modal to open the modal. It also may contain
		 * the tempalte object that is going to be edited. */
		toggleMgmtModal: (state: IAuditTemplatesState, action: PayloadAction<{
			toogleOp: boolean,
			template?: IDetailedTemplate,
		}>) => {
			state.isMgmtModalOpen = action.payload.toogleOp;
			if (action.payload.template) {
				state.template = action.payload.template;
				state.originalTemplate = cloneDeep(action.payload.template);
				state.isNew = false;
			} else {
				state.template = { name: '', isPlannable: false };
				state.originalTemplate = undefined;
				state.originalSelectedTopics = [];
				state.originalSelectedTemplates = [];
				state.isNew = true;
			}
			state.isDirty = false;
			state.selectedTemplates = [];
			state.selectedTopics = [];
		},

		/** Sets partially the template being created/edited properties. */
		setTemplateProperties: (state: IAuditTemplatesState, action: PayloadAction<Partial<IDetailedTemplate>>) => {
			if (state.template) {
				Object.assign(state.template, action.payload);
				updateIsDirty(state);
			}
		},

		setDetailedTemplatesChildren: (state: IAuditTemplatesState, action: PayloadAction<(IDetailedTemplate | IDetailedTemplateChildren)[]>) => {
			if (state.template) {
				state.selectedTemplates = [];
				Object.assign(state.selectedTemplates, action.payload);

				updateIsDirty(state);
			}
		},

		setDetailedTopicsChildren: (state: IAuditTemplatesState, action: PayloadAction<IAuditTopic[]>) => {
			if (state.template) {
				state.selectedTopics = [];
				Object.assign(state.selectedTopics, action.payload);

				updateIsDirty(state);
			}
		},

		/** Begins the procedure to save the template being created/edited. */
		saveTemplate: (state: IAuditTemplatesState, action: PayloadAction<{
			template: Partial<IDetailedTemplate> | undefined,
			selectedTemplates: (IDetailedTemplate | IDetailedTemplateChildren)[],
			selectedTopics: IAuditTopic[],
		}>) => {
			state.saveOp = {
				isWorking: true,
			}
		},

		/** Begins the procedure to delete the template. */
		deleteTemplate: (state: IAuditTemplatesState) => {
			state.deleteOp = {
				isWorking: true,
			}
		},

		/** Finishes the procedure of saving the templates. */
		finishSaveTemplate: (state: IAuditTemplatesState, action: PayloadAction<boolean>) => {
			state.saveOp = undefined;
			if (action.payload) {
				state.isDirty = false;

				state.selectedTemplates = [];
				state.selectedTopics = [];
			}
		},

		/** Finishes the procedure of deleting the templates. */
		finishDeleteTemplate: (state: IAuditTemplatesState) => {
			state.deleteOp = undefined;
			state.isDirty = false;
		},

		/** Sets partially the filters properties. */
		setTemplateFilterProperties: (state: IAuditTemplatesState, action: PayloadAction<Partial<IAuditTemplatesFilters>>) => {
			if (state.filters) {
				Object.assign(state.filters, action.payload);
			}
		},

		setOriginalTemplatesTopics: (state: IAuditTemplatesState, action: PayloadAction<{
			templates: (IDetailedTemplate | IDetailedTemplateChildren)[],
			topics: IAuditTopic[]
		}>) => {
			state.originalSelectedTemplates = cloneDeep(action.payload.templates);
			state.originalSelectedTopics = cloneDeep(action.payload.topics);
			state.selectedTemplates = action.payload.templates;
			state.selectedTopics = action.payload.topics;
		},
	}
})

export const {
	loadPageData,
	finishLoadPageData,
	toggleMgmtModal,
	setTemplateProperties,
	saveTemplate,
	deleteTemplate,
	finishSaveTemplate,
	finishDeleteTemplate,
	setTemplateFilterProperties,
	setDetailedTemplatesChildren,
	setDetailedTopicsChildren,
	setOriginalTemplatesTopics,
} = auditTemplatesSlice.actions;

function updateIsDirty(state: WritableDraft<IAuditTemplatesState>) {
	if (!state.template) {
		return;
	}

	const {
		template,
		selectedTemplates,
		selectedTopics,
		originalTemplate: original = {} as Partial<IDetailedTemplate>,
		originalSelectedTopics,
		originalSelectedTemplates,
		isNew,
	} = state;

	if (isNew) {
		state.isDirty = !!(template.name
			|| template.ownerGroupId
			|| template.isPlannable !== undefined
			|| selectedTemplates.length
			|| selectedTopics.length);
	} else {
		const topicIds = selectedTopics.map(x => x.id).slice().sort((a, b) => a < b ? -1 : 1) || [];
		const originalTopicIds = originalSelectedTopics.map(x => x.id).slice().sort((a, b) => a < b ? -1 : 1) || [];

		const templateIds = selectedTemplates.map(x => x.id).slice().sort((a, b) => a < b ? -1 : 1) || [];
		const originalTemplates = originalSelectedTemplates.map(x => x.id).slice().sort((a, b) => a < b ? -1 : 1) || [];

		const newComparer = { ...template, topicIds: topicIds, templateIds: templateIds };
		const originalComparer = { ...original, topicIds: originalTopicIds, templateIds: originalTemplates };

		state.isDirty = !isEqual(newComparer, originalComparer);
	}
}
