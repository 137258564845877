import ActionItemsApi from "api/actionItems/ActionItemsApi";
import ActionItemEvidenceModal from "components/actions/manage/evidence/ActionItemEvidenceModal";
import React, { useState } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import DatePicker from "shared/components/controls/date-picker/DatePicker";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { getTextboxMaxLengthHint, tooltipMessage } from 'shared/utilities/stringUtilities';
import { useAppDispatch } from "store/store";
import { IActionItem, IActionItemEvidence, IActionItemHistoryItem } from "types/actionItemTypes";
import ViewEvidenceButton from "../manage/evidence/ViewEvidenceButton";
import SendNotificationCheckbox from "../send-notification-checkbox/SendNotificationCheckbox";

interface IClosureModalProps {
  /** The ActionItem to close. */
  actionItem: IActionItem,
  /** The function to call when the user clicks the close button. */
  onClose(): void,
  /** The function to call when the action item is successfully closed. */
  onActionItemClosed(event: {
    actionItemId: number,
    closureTimestamp: number,
    newHistoryItem: IActionItemHistoryItem,
  }): void,
  /**
   * The function to call after the user successfully adds, removed, or updates evidence for this action item.
   * @param actionItem The updated version of the action item.
   */
  onEvidenceChanged(actionItem: IActionItem,
    evidenceEvent: "EvidenceAdded" | "EvidenceUpdated" | "EvidenceDeleted",
    evidence: IActionItemEvidence): void,
  /** The default state of the check box for send notification */
  defaultSendNotification: boolean,
}

const ClosureModal: React.FC<IClosureModalProps> = ({
  actionItem,
  onActionItemClosed,
  onClose,
  onEvidenceChanged,
  defaultSendNotification
}) => {
  const [followUp, setFollowUp] = useState("");
  const [closureTimestamp, setClosureTimestamp] = useState(new Date().getTime());
  const [isClosing, setIsClosing] = useState(false);
  const [isSendNotificationSelected, setSendEmailNotification] = useState(defaultSendNotification);

  const dispatch = useAppDispatch();

  const [isEvidenceOpen, setIsEvidenceOpen] = useState(false);

  const closeActionItem = async () => {
    try {
      setIsClosing(true);

      const newHistoryItem = await ActionItemsApi.closeActionItem(actionItem.id, {
        actionFollowUp: followUp,
        closureTimestamp
      }, isSendNotificationSelected);

      setIsClosing(false);

      dispatch(showSuccessToast("Action item closed successfully."));

      onActionItemClosed({
        actionItemId: actionItem.id,
        closureTimestamp,
        newHistoryItem,
      });

      onClose();
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
      setIsClosing(false);
    }
  };

  const onEvidenceAdded = (item: IActionItemEvidence) => {
    onEvidenceChanged({
      ...actionItem,
      evidences: actionItem.evidences.concat(item),
    },
      "EvidenceAdded",
      item);
  };

  const onEvidenceDeleted = (item: IActionItemEvidence) => {
    onEvidenceChanged({
      ...actionItem,
      evidences: actionItem.evidences.filter(x => x.id !== item.id),
    }, "EvidenceDeleted",
      item);
  };

  const onEvidenceUpdated = (item: IActionItemEvidence) => {
    const existingIx = actionItem.evidences.findIndex(x => x.id === item.id);

    if (existingIx === -1) {
      return;
    }

    let newEvidence = actionItem.evidences.slice();
    newEvidence.splice(existingIx, 1, item);

    onEvidenceChanged({
      ...actionItem,
      evidences: newEvidence,
    }, "EvidenceUpdated",
      item);
  };

  return (
    <Modal
      header="Close Action Item"
      isOpen={true}
      buttons={[{
        key: "CANCEL",
        text: "Cancel",
        className: "secondary",
        onClick: onClose,
      }, {
        key: "Submit",
        text: "Submit",
        title: tooltipMessage(!followUp.trim(), "Not all necessary fields have been completed"),
        className: "primary",
        disabled: !followUp.trim(),
        onClick: closeActionItem,
      }]}
      showCloseButton={true}
      onCloseButtonClicked={onClose}
      bottomLeftNode={
        <SendNotificationCheckbox
          isSendNotificationSelected={isSendNotificationSelected}
          setSendEmailNotification={setSendEmailNotification}
        />
      }
    >
      <FlexCol>

        <LabeledControl
          isRequired={true}
          label="Action Follow Up"
          hint={getTextboxMaxLengthHint(1000)}
        >
          <textarea
            value={followUp}
            onChange={e => setFollowUp(e.currentTarget.value)}
            maxLength={1000}
          />
        </LabeledControl>

        <LabeledControl
          isRequired={true}
          label="Closure date"
        >
          <DatePicker
            value={closureTimestamp}
            onChange={date => {
              if (!date) {
                return;
              }

              if (Array.isArray(date)
                && date[0] !== null) {
                setClosureTimestamp(date[0].getTime());
              } else if (!Array.isArray(date)) {
                setClosureTimestamp(date.getTime());
              }
            }}
          />
        </LabeledControl>

        <ViewEvidenceButton
          evidenceCount={actionItem.evidences.length}
          onClick={() => setIsEvidenceOpen(true)}
          isPrimary={!!followUp}
        />
      </FlexCol>

      {
        isClosing && (
          <ModalSpinner />
        )
      }

      {
        isEvidenceOpen && (
          <ActionItemEvidenceModal
            actionItemId={actionItem.id}
            allowAdd={true}
            allowDelete={true}
            allowEdit={true}
            onClose={() => setIsEvidenceOpen(false)}
            getEvidenceOnLoad={false}
            evidenceItems={actionItem.evidences}
            onEvidenceAdded={onEvidenceAdded}
            onEvidenceDeleted={onEvidenceDeleted}
            onEvidenceUpdated={onEvidenceUpdated}
          />
        )
      }
    </Modal >
  );
};
export default ClosureModal;
