import React from "react";
import { useDispatch } from "react-redux";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import PickerList from "shared/components/controls/picker/list/PickerList";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import deleteIcon from "shared/media/dls/delete.svg";
import editIcon from "shared/media/dls/edit-1.svg";
import { IPickerItem } from "shared/types/pickerTypes";
import { convertToPickerItem } from "shared/utilities/pickerUtiilities";
import { setCenterAuditAttributeModal, setConfirmRemoveOrEditAttributeModal, setEditAttributeModal, setRemoveAttributeModal } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";
import AttributeInfoDetailsDisplay from "./attr-info-display/AttributeInfoDetailsDisplay";
import "./ConfirmRemoveOrEditAttributeModal.scoped.scss";

const ConfirmRemoveOrEditAttributeModal: React.FC = () => {
  const dispatch = useDispatch();
  const modalState = useAppSelector(store => store.facilityAttributes.confirmRemoveOrEditAttributeModal);
  const contextPlanProfileAttributeModal = useAppSelector(store => store.facilityAttributes.contextPlanProfileAttributeModal);

  const attributeInfo = contextPlanProfileAttributeModal.planProfileAttributeInfo;
  const selectedAuditTemplates = contextPlanProfileAttributeModal.savedTemplateSelection;
  const availableTemplates = contextPlanProfileAttributeModal.availableTemplates;
  const isOpen = modalState?.isOpen;
  const isModalFromAuditTemplate = modalState.requirement.type === MetaDataTypes.AuditTemplate;

  const allTemplates: (string | number)[] = [];

  const addRecursively = (item: IDetailedTemplate | IDetailedTemplateChildren, allItems: (string | number)[]) => {
    allItems.push(item.key);

    item.children.forEach(c => {
      addRecursively(c, allItems);
    });
  };

  availableTemplates.forEach(x => {
    addRecursively(x, allTemplates);
  });

  const toPickerItem = (item: IDetailedTemplate | IDetailedTemplateChildren, includeChildren?: boolean, allItems?: (IDetailedTemplate | IDetailedTemplateChildren)[])
    : IPickerItem<IDetailedTemplate | IDetailedTemplateChildren> => {
    return convertToPickerItem(item, allTemplates, item => item.key, (item) => item.children || [], includeChildren, allItems);
  };

  if (modalState.isWorking) {
    return <ModalSpinner />;
  }

  return (
    <Modal
      isOpen={isOpen}
      header="Edit or Remove Requirement"
      showCloseButton={true}
      onCloseButtonClicked={() => dispatch(setConfirmRemoveOrEditAttributeModal({ isOpen: false }))}
      buttons={[{
        key: "Edit",
        text: "Edit Requirement",
        className: "secondary",
        img: editIcon,
        onClick: () => {
          if (modalState.onEditCLMQMSClick) {
            // If this confirmation modal is open for a CLM or QMS template, dispatch this event
            // rather than the normal one for other scoring systems.
            dispatch(setCenterAuditAttributeModal(modalState.onEditCLMQMSClick));
          } else {
            dispatch(setEditAttributeModal({
              isOpen: true,
              requirement: modalState.requirement,
              row: modalState.row,
              attributeId: modalState.attribute.id
            }));
          }

          dispatch(setConfirmRemoveOrEditAttributeModal({ isOpen: false }));
        }
      }, {
        key: "Remove",
        text: "Remove Requirement",
        className: "red",
        img: deleteIcon,
        onClick: () => {
          dispatch(setConfirmRemoveOrEditAttributeModal({ isOpen: false }));
          dispatch(setRemoveAttributeModal({
            isOpen: true,
            requirement: modalState.requirement,
            row: modalState.row,
            attribute: modalState.attribute,
          }));
        }
      }, {
        key: "Cancel",
        text: "Cancel",
        className: "secondary",
        onClick: () => dispatch(setConfirmRemoveOrEditAttributeModal({ isOpen: false })),
      }]}
    >
      <FlexCol>
        <span>Would you like to EDIT or REMOVE {
          isModalFromAuditTemplate
            ? "template"
            : "topic/subtopic"
        } <b>{modalState.requirement.text}</b> Audit Requirement?</span>

        {isModalFromAuditTemplate && <>
          <FlexCol className="template-picker">
            <span>
              <b>{`${modalState.requirement.text} ${modalState.total}`}</b>
            </span>
            <PickerList<IDetailedTemplate | IDetailedTemplateChildren>
              items={availableTemplates.map(x => toPickerItem(x, true, availableTemplates))}
              renderItem={(item) => item.name}
              renderTotal={(item) => item.total}
              noItemsMessage={"No suggestions available."}
              selectedItems={selectedAuditTemplates.map(x => toPickerItem(x))}
              displayMode={"tree"}
              allowMultiSelect={true}
              onItemSelected={() => { }}
              notShowPlusMinusSign={true}
              onItemDeselected={() => { }}
              isDisabledMapper={() => true}
              itemSorter={(a, b) => (a.item?.name ?? a.text ?? "") < (b.item?.name ?? b.text ?? "") ? -1 : 1}
            />
          </FlexCol>
        </>}

        {modalState.scoringSystem !== AuditScoringSystems.CLM
          && modalState.scoringSystem !== AuditScoringSystems.QMS && (
            <>
              <span className="extra-metadata-title">
                Extra Metadata
              </span>

              {attributeInfo &&
                <AttributeInfoDetailsDisplay
                  attributeInfo={attributeInfo}
                  type={modalState.requirement.type}
                />
              }
            </>
          )}
      </FlexCol>
    </Modal>
  );
};

export default ConfirmRemoveOrEditAttributeModal;
