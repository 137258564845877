/**
 * Converts a user object into a string.
 * @param user The user to convert to a string.
 * @param defaultString The default string to return if the user object is undefined.
 * @returns User info in the format `Name (Email)`
 */
export function userToString(user: { name: string | undefined, email: string | undefined } | undefined, defaultString?: string) {
  if (!user) {
    return defaultString || "";
  }

  let name = user.name;
  let alias = user.email;

  if (user.email) {
    // Extract only the alias part of the email (if present).
    const atIx = user.email?.indexOf("@");
    if (atIx > -1) {
      alias = user.email.substring(0, atIx);
    }
  }

  if (name && alias) {
    return `${name} (${alias})`;
  } else if (name) {
    return name;
  } else if (alias) {
    return alias;
  } else {
    return defaultString || "";
  }
}