import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { AuditRelations, IMyAudit } from "types/auditingTypes";
import { IPagedResult } from "types/pageResultsTypes";

export interface IMyAuditsState {
  audits: IMyAudit[],
  loadOperation?: IOperation<IPagedResult<IMyAudit>>,
  nextPage: number,
  hasMore: boolean,
  currentAuditRelation: AuditRelations,
}

const initialState: IMyAuditsState = {
  audits: [],
  loadOperation: undefined,
  nextPage: 1,
  hasMore: false,
  currentAuditRelation: AuditRelations.All,
};

export const myAuditsSlice = createSlice({
  name: "my-audits",
  initialState,
  reducers: {
    setMyAuditPropValue: (state, action: PayloadAction<Partial<IMyAuditsState>>) => {
      Object.assign(state, action.payload);
    },
    loadMyAudits: (state, _: PayloadAction<{
      pageNumber: number,
    }>) => {
      state.loadOperation = {
        isWorking: true,
      };
    },
    finishLoadingMyAudits: (state, action: PayloadAction<IOperation<IPagedResult<IMyAudit>>>) => {
      if (action.payload.errorMessage
        || action.payload.data === undefined) {
        state.loadOperation = action.payload;
        return;
      }

      if (action.payload.data.currentPage === 1) {
        state.audits = [];
        state.nextPage = 1;
      }

      const newAudits = action.payload.data.results;
      state.audits.push(...newAudits);
      state.hasMore = newAudits.length === action.payload.data.pageSize;
      state.nextPage = action.payload.data.currentPage + 1;
      state.audits = uniqBy(state.audits, x => x.id);

      state.loadOperation = undefined;
    },
    clearMyAudits: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setMyAuditPropValue,
  loadMyAudits,
  finishLoadingMyAudits,
  clearMyAudits,
} = myAuditsSlice.actions;