import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditTopicPicker from "shared/components/controls/pickers/AuditTopicPicker";
import "./AuditTemplateFilters.scoped.scss";
import { AuditTopicSearchTypes, IAuditTopic } from "types/auditMasterDataTypes";

interface IAuditTopicFilterProps {
  selected?: IAuditTopic[],
  filterToOwnerGroupIds?: number[],
  onApply: (items: IAuditTopic[]) => void,
}

const AuditTopicsFilter: React.FC<IAuditTopicFilterProps> = (
  {
    selected = [],
    filterToOwnerGroupIds = [],
    onApply,
  }
) => {
  return (
    <div className="audit-topics">
      <LabeledControl label="Topics/Sub-Topics">
        <AuditTopicPicker
          onApply={onApply}
          selectedItems={selected}
          auditTopicSearch={filterToOwnerGroupIds.length > 0 ?
            { auditTopicSearchType: AuditTopicSearchTypes.OwnerGroups, auditTopicSearchIds: filterToOwnerGroupIds }
            : undefined}
          allowMultiselect
        />
      </LabeledControl>
    </div>
  );
};

export default AuditTopicsFilter;
