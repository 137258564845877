import AuditsApi from "api/auditing/AuditsApi";
import { IGetAuditComplianceScoresFilter } from "api/auditing/types/auditApiTypes";
import { IPlanningGridCellClickArgs, IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import { useState } from "react";
import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import BasicCompliancePlanIcon from "shared/media/icons/audit-planning/BasicCompliance-check.svg";
import FullCompliancePlanIcon from "shared/media/icons/audit-planning/FullCompliance-check.svg";
import HighNonCompliancePlanIcon from "shared/media/icons/audit-planning/HighNonCompliance-check.svg";
import NonCompliancePlanIcon from "shared/media/icons/audit-planning/NonCompliance-check.svg";
import FinalApprovedPlanIcon from "shared/media/icons/audit-planning/final-approved-plan.svg";
import RemovedPlanIcon from "shared/media/icons/audit-planning/removed-plan.svg";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { useAppDispatch } from "store/store";
import { AuditPlanApprovalStatuses, ComplianceResults, IAuditPlanView, IPlanRequirementDimension } from "types/auditPlanningTypes";
import { AuditComplianceScoreIncludedQuestions, AuditSources, AuditStatuses, IAuditComplianceScore } from "types/auditingTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import "./PlanResultsGridCell.scoped.scss";
import GridCellTooltip from "./tooltip/GridCellTooltip";

interface IPlanResultsGridCellProps {
  plan?: IAuditPlanView,
  planningItem: IPlanningItem,
  parentPlanningItem?: IPlanningItem,
  requirement: IPlanRequirementDimension,
  isPlannable: boolean,
  gridHasSelection: boolean,
  isSelected: boolean,
  onCellClick(eventArgs: IPlanningGridCellClickArgs): void,
}

const PlanResultsGridCell: React.FC<IPlanResultsGridCellProps> = ({
  plan,
  planningItem,
  parentPlanningItem,
  requirement,
  isPlannable,
  gridHasSelection,
  isSelected,
  onCellClick,
}) => {
  const dispatch = useAppDispatch();
  let planIcon: string = "";
  let planAlt: string = "";
  let isPlanIconFiltered = true;
  let leadAuditorName = plan?.auditLeadAuditorName
    || plan?.leadAuditorName;
  let classNames = ["cell-div"];
  let weekNumber = plan?.auditCompletionWeek
    || plan?.auditStartWeekNumber
    || plan?.weekOfYear;

  if (plan?.deleted) {
    planIcon = RemovedPlanIcon;
    planAlt = "X";
  } else {
    if (plan?.approvalStatus === AuditPlanApprovalStatuses.FinalApproval) {
      planAlt = "F";
      isPlanIconFiltered = false;
    }

    if (!!plan?.auditId) {
      isPlanIconFiltered = false;
      planIcon = FinalApprovedPlanIcon;
      planAlt = "P";

      if (plan.auditComplianceResult === ComplianceResults.FullCompliance
        || plan.auditComplianceResult === ComplianceResults.HighCompliance) {
        planIcon = FullCompliancePlanIcon;
      } else if (plan.auditComplianceResult === ComplianceResults.BasicCompliance) {
        planIcon = BasicCompliancePlanIcon;
      } else if (plan.auditComplianceResult === ComplianceResults.NonCompliance) {
        planIcon = NonCompliancePlanIcon;
      } else if (plan.auditComplianceResult === ComplianceResults.HighNonCompliance) {
        planIcon = HighNonCompliancePlanIcon;
      }
    }
  }

  if (plan) {
    classNames.push("has-plan");

    if (!plan.auditComplianceResult) {
      classNames.push("Missing");
    } else {
      classNames.push(plan.auditComplianceResult.toString());
    }
  } else {
    classNames.push("na");
  }

  if (gridHasSelection) {
    if (isSelected) {
      classNames.push("selected");
    } else {
      classNames.push("not-selected");
    }
  }

  let statusCode = "";

  if (plan?.auditStatus) {
    switch (plan.auditStatus) {
      case AuditStatuses.Planned:
        statusCode = "P";
        break;
      case AuditStatuses.InProgress:
        statusCode = "IP";
        break;
      case AuditStatuses.Completed:
        statusCode = "C";
        break;
      case AuditStatuses.Closed:
        statusCode = "CL";
        break;
    }
  } else if (plan?.approvalStatus === AuditPlanApprovalStatuses.FinalApproval) {
    statusCode = "A";
  } else if (plan?.approvalStatus === AuditPlanApprovalStatuses.Recommended) {
    statusCode = "R";
  }

  const [data, setData] = useState<IAuditComplianceScore[] | null>(null);
  const [fetchController, setFetchController] = useState<AbortController>();
  const [preloadTimeout, setPreloadTimeout] = useState<number | undefined>();
  const [isLoadingTT, setIsLoadingTT] = useState(false);

  const shouldShowTooltip = !!plan;
  const extraLookupType: "GlobalAndCountry" | "GlobalAndChild" | null = plan
    && plan.childDimensionId
    ? "GlobalAndChild"
    : "GlobalAndCountry";
  const shouldLookupExtraTooltipData = plan
    && plan.auditSource === AuditSources.QoF
    && (plan.auditStatus === AuditStatuses.Completed
      || plan.auditStatus === AuditStatuses.Closed)
    && (!!plan.auditCompletionWeek
      || !!plan.auditClosureWeek)
    && plan.auditId
    && (
      (requirement.type === MetaDataTypes.AuditTopic
        && requirement.auditTopicLevel === 1)
      || requirement.type === MetaDataTypes.AuditTemplate
    );

  const getExtraTooltipData = (id: number) => {
    setPreloadTimeout(undefined);

    const queryApi = async () => {
      const controller = new AbortController();

      setFetchController(controller);

      if (!plan) {
        return;
      }

      try {
        const filters: IGetAuditComplianceScoresFilter[] = [];
        let includedPlanId: number | undefined = undefined;

        if (plan.requirementDimensionType === MetaDataTypes.AuditTemplate) {
          filters.push({
            parentDimensionId: plan.parentDimensionId,
            parentDimensionType: plan.parentDimensionType,
            scoreByType: plan.requirementDimensionType,
            scoreById: plan.requirementDimensionId,
            childDimensionId: plan.childDimensionId,
            childDimensionType: plan.childDimensionType,
            includedQuestions: plan.childDimensionId
              ? AuditComplianceScoreIncludedQuestions.GlobalPlusChildDimension
              : AuditComplianceScoreIncludedQuestions.GlobalOnly,
          });
        } else if (plan.requirementDimensionType === MetaDataTypes.AuditTopic) {
          filters.push({
            parentDimensionId: plan.parentDimensionId,
            parentDimensionType: plan.parentDimensionType,
            scoreByType: plan.requirementDimensionType,
            scoreById: plan.requirementDimensionId,
            includedQuestions: AuditComplianceScoreIncludedQuestions.GlobalOnly,
          });

          if (extraLookupType === "GlobalAndChild") {
            filters.push({
              parentDimensionId: plan.parentDimensionId,
              parentDimensionType: plan.parentDimensionType,
              childDimensionId: plan.childDimensionId,
              childDimensionType: plan.childDimensionType,
              scoreByType: plan.requirementDimensionType,
              scoreById: plan.requirementDimensionId,
              includedQuestions: AuditComplianceScoreIncludedQuestions.ChildDimensionOnly,
            });
          } else if (extraLookupType === "GlobalAndCountry") {
            includedPlanId = plan.id;
          }
        }

        const scores = await AuditsApi.getAuditComplianceScores({
          auditId: id,
          planIdToIncludeCountryOfParentScore: includedPlanId,
          filters,
        }, controller.signal);

        setData(scores);
      } catch (err: any) {
        setData(null);

        if (!('name' in err)
          || err.name !== "AbortError") {
          dispatch(showErrorToast(err.toString()));
        }
      } finally {
        setIsLoadingTT(false);
      }
    };

    queryApi();
  };

  const onMouseEnter = () => {
    if (plan?.auditId) {
      if (data
        || !plan?.auditId) {
        setIsLoadingTT(false);
        return;
      }

      const auditId = plan.auditId;

      if (!data
        && shouldLookupExtraTooltipData) {
        setIsLoadingTT(true);
        setPreloadTimeout(window.setTimeout(() => getExtraTooltipData(auditId), 500));
      }

      let elements = document.getElementsByClassName("cell-div");
      for (let i = 0; i < elements.length; i++) {
        let auditId = elements[i].getAttribute("data-auditid");
        if (auditId === undefined) {
          continue;
        } else if (Number(auditId) === plan?.auditId) {
          if (elements[i].className.indexOf('hovered') === -1) {
            elements[i].className += ' hovered';
          }
        }
      }
    }
  };

  const onMouseLeave = () => {
    if (preloadTimeout) {
      window.clearTimeout(preloadTimeout);
      setPreloadTimeout(undefined);
      setIsLoadingTT(false);
    }

    if (fetchController) {
      fetchController.abort();
    }

    let elements = document.getElementsByClassName("cell-div");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].className.indexOf('hovered') > -1) {
        elements[i].className = elements[i].className.replace(" hovered", "");
      }
    }
  };

  return (
    <>
      {isPlannable && (
        <TooltipElement
          isDisabled={!shouldShowTooltip}
          tooltipContent={
            plan
              ? (
                <GridCellTooltip
                  plan={plan}
                  isLoadingScores={isLoadingTT}
                  extraScores={data}
                />
              ) : undefined
          }
        >
          <div
            className={classNames.join(' ')}
            onClick={plan
              ? () => onCellClick({
                parentRow: parentPlanningItem,
                row: planningItem,
                requirement,
                plan,
              }) : undefined
            }
            data-auditid={plan?.auditId}
            onMouseEnter={plan?.auditId
              ? onMouseEnter
              : undefined
            }
            onMouseLeave={plan?.auditId
              ? onMouseLeave
              : undefined
            }
          >
            {!!planIcon && (
              <img
                src={planIcon}
                alt={planAlt}
                className={`icon-medium plan-icon${isPlanIconFiltered ? "-filtered" : ""}`}
              />
            )}

            {!!weekNumber && (
              <label
                className="week-number"
              >
                {weekNumber}
              </label>
            )}

            {!!leadAuditorName && (
              <label
                className="lead-auditor"
              >
                {getInitials(leadAuditorName)}
              </label>
            )}

            {!!statusCode && (
              <label
                className="status"
              >
                {statusCode}
              </label>
            )}

            {!plan && isPlannable && (
              "N/A"
            )}
          </div>
        </TooltipElement>
      )}
    </>
  );
};

export default PlanResultsGridCell;

function getInitials(name: string): string {
  const nameChunks = name.trim().split(' ');
  const firstInitial = nameChunks[0][0];
  const lastInitial = nameChunks.length > 1
    ? nameChunks[nameChunks.length - 1][0]
    : undefined;

  return firstInitial + (lastInitial || "");
}