import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexRow from "shared/components/layout/flex/FlexRow";
import BottomLeftIcon from "shared/media/icons/audit-planning/bottom-left-highlight.svg";
import PlannedAuditIcon from "shared/media/icons/audit-planning/planned-audit.svg";
import FinalApprovedPlanIcon from "shared/media/icons/audit-planning/final-approved-plan.svg";
import RecManualPlanIcon from "shared/media/icons/audit-planning/recommended-manual-plan.svg";
import RecTimeTriggerIcon from "shared/media/icons/audit-planning/recommended-time-trigger.svg";
import RemovedPlanIcon from "shared/media/icons/audit-planning/removed-plan.svg";
import LegendIcon from "../../planning-grid/legend-icon/LegendIcon";
import "./GridLegend.scoped.scss";

export enum GridLegendMode {
  PlanApprovals,
  PlanResults,
}

interface IGridLegendProps {
  displayMode: GridLegendMode,
  isCLMMode?: boolean,
}

const GridLegend: React.FC<IGridLegendProps> = ({
  displayMode,
  isCLMMode = false,
}) => {
  return (
    <FlexRow
      className="legend first-legend"
    >
      <LabeledControl
        label={displayMode === GridLegendMode.PlanApprovals
          ? "Status of the Required Audits"
          : "Audit Status"
        }
        isLight={true}
        flexChildrenAs="row"
        className="grid-legend-label"
      >
        <LegendIcon
          className="Required"
          label={displayMode === GridLegendMode.PlanApprovals
            ? "Missing"
            : "Required"
          }
          type="color"
        />

        {isCLMMode && (
          <LegendIcon
            className="HighNonCompliance"
            label="High Non-Compliance"
            type="color"
          />
        )}

        <LegendIcon
          className="NonCompliance"
          label="Non-Compliance"
          type="color"
        />

        <LegendIcon
          className="BasicCompliance"
          label="Basic Compliance"
          type="color"
        />

        <LegendIcon
          className={isCLMMode
            ? "HighCompliance"
            : "FullCompliance"
          }
          label={isCLMMode
            ? "High Compliance"
            : "Full Compliance"
          }
          type="color"
        />

        {displayMode === GridLegendMode.PlanResults && (
          <LegendIcon
            label="Planned Audit"
            type="icon"
            className="planned-icon"
            icon={PlannedAuditIcon}
          />
        )}
      </LabeledControl>

      {displayMode === GridLegendMode.PlanResults && (
        <LabeledControl
          label="Plan/Audit Details"
          isLight={true}
          flexChildrenAs="row"
        >
          <LegendIcon
            label="Lead Auditor"
            type="icon"
            icon={BottomLeftIcon}
          />

          <LegendIcon
            label="Week"
            type="icon"
            icon={BottomLeftIcon}
            className="bottom-right-highlight"
          />

          <LegendIcon
            label="Status"
            type="icon"
            icon={BottomLeftIcon}
            className="top-right-highlight"
          />

          <LegendIcon
            label="Recommended"
            type="color"
            bgColor="#8B90AD"
            textInColor="R"
            className="status-code"
          />

          <LegendIcon
            label="Approved"
            type="color"
            bgColor="#8B90AD"
            textInColor="A"
            className="status-code"
          />

          <LegendIcon
            label="Planned by Lead Auditor"
            type="color"
            bgColor="#8B90AD"
            textInColor="P"
            className="status-code"
          />

          <LegendIcon
            label="In Progress"
            type="color"
            bgColor="#8B90AD"
            textInColor="IP"
            className="status-code"
          />

          <LegendIcon
            label="Completed"
            type="color"
            bgColor="#8B90AD"
            textInColor="C"
            className="status-code"
          />

          <LegendIcon
            label="Closed"
            type="color"
            bgColor="#8B90AD"
            textInColor="CL"
            className="status-code"
          />
        </LabeledControl>
      )}

      {displayMode === GridLegendMode.PlanApprovals && (
        <LabeledControl
          label="Status of the Audit Plan"
          isLight={true}
          flexChildrenAs="row"
          className="grid-legend-label"
        >
          <LegendIcon
            label="Recommended - Time Trigger"
            type="icon"
            icon={RecTimeTriggerIcon}
          />
          <LegendIcon
            label="Recommended - Manually Added"
            type="icon"
            icon={RecManualPlanIcon}
          />
          <LegendIcon
            label="Removed System Recommendation"
            type="icon"
            icon={RemovedPlanIcon}
          />
          <LegendIcon
            label="Final Approval"
            type="icon"
            icon={FinalApprovedPlanIcon}
          />
        </LabeledControl>
      )}
    </FlexRow>
  );
};

export default GridLegend;