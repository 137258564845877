import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback } from "react";
import { IHSESubElement } from "types/auditMasterDataTypes";
import NonReduxPickerDropdown from "./non-redux-dropdown-picker/NonReduxDropdownPicker";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IHSESubElementPickerProps {
  /** Optional. Determines the picker's mode. Default = Picker. */
  renderMode?: PickerMode,
}

const HSESubElementPicker: React.FC<IHSESubElementPickerProps & ICommonPickerProps<IHSESubElement>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  isDisabled = false,
  renderMode = "picker",
}) => {
  const renderItem = (item: IHSESubElement) => item.name;

  const loadItems = useCallback(async (_: string | undefined, abortSignal: AbortSignal) =>
    await MasterDataApi.getHSESubElements(undefined, abortSignal),
    []);

  if (renderMode === "picker") {
    return (
      <NonReduxPicker<IHSESubElement>
        itemSorter={(a, b) => a.name < b.name ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title="HSE MS Sub-Elements"
        allowMultiSelect={allowMultiselect}
        displayMode="list"
        renderListItem={item => item.name}
        searchOptions={{
          filterItem: (item, search) => item.name.toLowerCase().includes(search.toLowerCase()),
        }}
        isDisabled={isDisabled}
      />
    );
  } else if (renderMode === "dropdown") {
    return (
      <NonReduxPickerDropdown<IHSESubElement>
        itemFormatter={renderItem}
        keyMapper={item => item.id}
        onLoadItems={loadItems}
        onSelectionChanged={onApply}
        selectedItems={selectedItems}
        isDisabled={isDisabled}
        placeholder="Select"
        selectMode={allowMultiselect ? "multiple" : "single"}
      />
    )
  } else {
    return <>Render mode "{renderMode}" is not supported.</>
  }
}

export default HSESubElementPicker;