import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import QISelect from "shared/components/controls/select/QISelect";
import { setSearchPropValue } from "store/search/actions/SearchActionsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const ParentType: React.FC = () => {
  const values = useAppSelector(store => store.searchActions.parentTypes);
  const dispatch = useAppDispatch();

  return (
    <LabeledControl
      label="Parent Type"
    >
      <QISelect
        items={[{
          id: "Audit",
          text: "Audit",
        }]}
        selectedItemIds={values}
        placeholder="Select"
        allowFilter={false}
        allowSelectAll={false}
        onChange={items =>
          dispatch(setSearchPropValue({
            parentTypes: items.map(x => x.id.toString()),
          }))
        }
        selectMode="multi"
      />
    </LabeledControl>
  );
};

export default ParentType;