import { authDelete, authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { IProfile, ISwitchUserProfile, IUserProfile } from "shared/types/userProfileTypes";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { formatProfile, formatSaveProfileRequest, formatUserProfiles, formatUserProfileWithoutDerivedMetaData } from "./formatters/securityFormatters";

class SecurityApi {
  public async getUserProfile(): Promise<IUserProfile> {
    const moduleName = config.moduleName;

    const response = await authGetJson(`${config.endpoints.security.userProfile}?name=${moduleName}`);
    await throwIfResponseError(response);
    const result = await response.json();
    return formatUserProfiles(result);
  }

  public async getUserProfileByUserAndModule(module: string, email: string): Promise<IUserProfile> {
    const response = await authGetJson(`${config.endpoints.security.userProfile}/${email}?moduleName=${module}`);
    await throwIfResponseError(response);
    const result = await response.json();
    return formatUserProfileWithoutDerivedMetaData(result);
  }

  public async setActiveUserProfile(userProfile: ISwitchUserProfile): Promise<IProfile> {
    const response = await authPutJson(config.endpoints.security.userProfile, { userProfile: userProfile });

    await throwIfResponseError(response);
    const result = await response.json();
    return formatProfile(result.activeProfile);
  }

  public async createProfileForUser(profile: IProfile, userEmail: string): Promise<void> {
    const response = await authPostJson(
      `${config.endpoints.security.userProfile}/${userEmail}/profiles`,
      [formatSaveProfileRequest(profile)]);

    await throwIfResponseError(response);
  }

  public async updateProfileForUser(profile: IProfile, userEmail: string): Promise<void> {
    const response = await authPutJson(
      `${config.endpoints.security.userProfile}/${userEmail}/profiles/${profile.id}`,
      formatSaveProfileRequest(profile));

    await throwIfResponseError(response);
  }

  public async deleteProfileForUser(profileId: number, userEmail: string): Promise<void> {
    const response = await authDelete(`${config.endpoints.security.userProfile}/${userEmail}/profiles/${profileId}`);
    await throwIfResponseError(response);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new SecurityApi();
