import QuestionEvidenceModal from "components/audits/question-evidence/QuestionEvidenceModal";
import React, { useEffect, useState } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import Page from "shared/components/layout/page/Page";
import { IPageHeaderControl } from "shared/components/layout/page/PageBar";
import infoIcon from "shared/media/dls/info.svg";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { isWorking } from "shared/utilities/operationUtilities";
import {
  confirmAuditStatusChange,
  loadAllData, removeQuestionEvidence,
  setConfirmAuditStatusChangeModal,
  setEvidenceModal,
  setFocusedGuidance,
  setQuestionEvidenceItem
} from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses } from "types/auditingTypes";
import { v4 as uuidv4 } from "uuid";
import AuditNotStartedBanner from "../AuditNotStartedBanner";
import ActionItemListModalHelper from "../action-item-list-modal-helper/ActionItemListModalHelper";
import AuditOverview from "../audit-overview/AuditOverview";
import AuditStatusChip from "../audit-status-chip/AuditStatusChip";
import AuditStepsPanel from "../audit-steps/panel/AuditStepsPanel";
import { getAuditSteps } from "../auditUtilities";
import "./AuditPage.scoped.scss";
import AuditNav from "./audit-nav/AuditNav";
import RevertStatusButton from "./revert-status-button/RevertStatusButton";

export interface IOverviewSettings {
  /** Determines if the overview card should be completely hidden. Default = false. */
  hideCard?: boolean,
  /** Determines if the compliance score info should be shown. Default = false. */
  showComplianceScore?: boolean,
  /** Determines if the question completion info should be shown. Default = false. */
  showQuestionCompletionPercent?: boolean,
  /** Determines if the question counts should be shown. Default = false. */
  showQuestionCounts?: boolean,
  /** Overrides the audit status for use in the overview card. Used when the audit is loaded from elsewhere. */
  auditStatusOverride?: AuditStatuses,
}

export interface IAuditPageProps {
  /** The id of the audit whose information should be loaded. */
  auditId: number | string,
  /** The title to show on the page header bar while the audit is loading. Optional. */
  title?: string,
  /** The route to visit when the user clicks the back button. If not specified, the back button does not appear. Optional. */
  backButtonRoute?: string,
  /** The elements to show to the right of the navigation tabs. Optional. */
  pageControls?: IPageHeaderControl[],
  /** The content to show in the footer bar to show at the bottom of the screen. If not specified, no footer appears. Optional. */
  footer?: React.ReactNode,
  /** The settings for displaying the Audit Overview card. */
  overviewSettings?: IOverviewSettings,
  /** A node to display above the audit navigation bar. Optional. */
  aboveNavNode?: React.ReactNode,
  /** If true, when loading audit data, scores will be calculated (if applicable) and returned along with the other data. Optional. Default = false. */
  loadAuditScores?: boolean,
  /** If true, when loading audit data, the available finding types for this audit will be returned along with the other data. Optional. Default = false. */
  loadFindingTypes?: boolean,
  children?: React.ReactNode,
}

const AuditPage: React.FC<IAuditPageProps> = ({
  auditId,
  title,
  backButtonRoute,
  pageControls,
  footer,
  overviewSettings,
  aboveNavNode,
  loadAuditScores,
  loadFindingTypes,
  children,
}) => {
  // Common info
  const saveChangesOp = useAppSelector(store => store.audit.saveChangesOp);
  const exportOp = useAppSelector(store => store.audit.exportOp);
  const loadAllDataOp = useAppSelector(store => store.audit.loadAllDataOp);
  const allAnswers = useAppSelector(store => store.audit.answers);

  // Audit info
  const loadAuditOp = useAppSelector(store => store.audit.loadAuditOp);
  const audit = useAppSelector(store => store.audit.audit);
  const originalAudit = useAppSelector(store => store.audit.originalAudit);

  // Questions info
  const loadQuestionsOp = useAppSelector(store => store.audit.loadQuestionsOp);
  const questions = useAppSelector(store => store.audit.questions);
  const focusedGuidance = useAppSelector(store => store.audit.focusedGuidance);
  const evidenceModalData = useAppSelector(store => store.audit.evidenceModal);

  // Status changes.
  const updateStatusOp = useAppSelector(store => store.audit.updateStatusOp);
  const statusConfirmModal = useAppSelector(store => store.audit.statusConfirmModal);

  // Action Items
  const loadActionItemsOp = useAppSelector(store => store.audit.loadActionItemsOp);
  const actionItems = useAppSelector(store => store.audit.actionItems);

  // Auth
  const userEmail = useAppSelector(store => store.auth.currentUser.email);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const isEditable = auditPageAuth.editRestriction === EditRestriction.EditAll;

  const [isStepsPanelOpen, setIsStepsPanelOpen] = useState(false);

  const dispatch = useAppDispatch();

  const anyAreLoading = loadAuditOp?.isWorking
    || loadQuestionsOp?.isWorking
    || loadActionItemsOp?.isWorking
    || loadAllDataOp?.isWorking;

  // Set bold to the line content when its contain pending actions.
  const formatLineContent = (line: string) => {
    let content = !!line.trim() ? line : <>&nbsp;</>;

    if (line.includes("You have the following pending actions:")) {
      content = <b>{line}</b>;
    }
    return content;
  };

  // Effect used to load the audit info.
  useEffect(() => {
    dispatch(loadAllData({
      auditId: Number(auditId),
      includeAuditScores: loadAuditScores ?? false,
      loadFindingTypes: loadFindingTypes ?? false,
    }));
  }, [auditId, loadAuditScores, loadFindingTypes, dispatch]);

  const scoringSystem = questions[0]?.scoringSystem;

  let node: React.ReactNode;

  if (anyAreLoading) {
    node = <Spinner />;
  } else if (!!loadAuditOp?.errorMessage
    || !!loadAllDataOp?.errorMessage) {
    node = (
      <Banner
        type={BannerType.error}
      >
        {loadAllDataOp?.errorMessage || loadAuditOp?.errorMessage || "An error occurred while loading the audit data."}
      </Banner>
    );
  } else {
    let auditStatus = audit?.status || overviewSettings?.auditStatusOverride;

    node = (
      <>
        {audit
          && audit.status === AuditStatuses.Planned && (
            <AuditNotStartedBanner />
          )}

        {aboveNavNode}

        <AuditNav
          auditId={auditId}
          pageControls={pageControls}
          scoringSystem={scoringSystem}
        />

        {auditStatus
          && !overviewSettings?.hideCard && (
            <AuditOverview
              showComplianceScore={overviewSettings?.showComplianceScore}
              showQuestionCompletionPercent={overviewSettings?.showQuestionCompletionPercent}
              showQuestionCounts={overviewSettings?.showQuestionCounts}
              questions={questions}
              auditStatus={auditStatus}
            />
          )}

        {children}
      </>
    );
  }

  const onConfirmStatusChange = () => {
    const id = Number(auditId);

    if (!id) {
      dispatch(showErrorToast("No audit data is loaded."));
      return;
    }

    dispatch(setConfirmAuditStatusChangeModal({ isOpen: false }));
    dispatch(confirmAuditStatusChange({
      id,
      status: statusConfirmModal.nextStatus,
    }));
  };


  const auditSteps = originalAudit
    ? getAuditSteps(originalAudit, questions, actionItems, allAnswers)
    : undefined;

  return (
    <Page
      maxTitleLength={70}
      title={audit && !anyAreLoading
        ? `${audit.recap}-${audit?.name}`
        : title
      }
      backButtonRoute={backButtonRoute}
      footer={footer}
      headerControls={audit && !anyAreLoading
        ? [{
          key: "auditId",
          element: (
            <div className="header-info-section">
              <span className="audit-id">#{audit.id}</span>

              <AuditStatusChip status={audit.status} />

              <RevertStatusButton
                auditId={audit.id}
                auditStatus={audit.status}
              />

              {auditSteps && (
                <button
                  className="link"
                  onClick={() => setIsStepsPanelOpen(true)}
                >
                  {auditSteps.currentStep === "DONE"
                    ? auditSteps.currentStep
                    : `${auditSteps.currentStep.number} - ${auditSteps.currentStep.text}`
                  }
                </button>
              )}
            </div >
          )
        },] : undefined
      }
    >
      {node}

      {
        isWorking(updateStatusOp,
          saveChangesOp,
          exportOp) &&
        <ModalSpinner />
      }

      <ActionItemListModalHelper />

      {
        statusConfirmModal.isOpen && (
          <ConfirmModal
            message={(
              <>
                {statusConfirmModal
                  .message
                  .split("\n")
                  .map((line) => (
                    <div
                      key={uuidv4()}
                      className='confirm-modal-div'
                    >
                      {line.includes("once closed")
                        ? (<img
                          className="icon-small"
                          alt=""
                          src={infoIcon}
                        />)
                        : null
                      }

                      {formatLineContent(line)}
                    </div>
                  ))}
              </>)}
            onNoClicked={() => dispatch(setConfirmAuditStatusChangeModal({ isOpen: false }))}
            onYesClicked={onConfirmStatusChange}
            header={audit?.status === AuditStatuses.Completed ? "Close Audit" : "Confirm Status Change"}
          />
        )
      }

      {
        focusedGuidance && (
          <ConfirmModal
            header={`Question #${focusedGuidance.questionNumber} Guidance`}
            noButtonOptions={{
              show: false,
            }}
            yesButtonOptions={{
              text: "OK",
            }}
            onYesClicked={() => dispatch(setFocusedGuidance(undefined))}
            message={(
              <>
                {focusedGuidance
                  .guidance
                  .split("\n")
                  .map((line, ix) => (
                    <div
                      key={uuidv4()}
                    >
                      {!!line.trim() ? line : <>&nbsp;</>}
                    </div>
                  ))
                }
              </>
            )}
          />
        )
      }

      {
        evidenceModalData.isOpen
        && audit
        && (
          <>
            {(() => {
              const question = questions
                .find(x => x.auditQuestionId === evidenceModalData.auditQuestionId);

              const auditData = audit;

              if (!question
                || !auditData) {
                return null;
              }

              return (
                <QuestionEvidenceModal
                  allowAdd={isEditable
                    || (auditPageAuth.editRestriction === EditRestriction.EditOwn
                      && question.auditorEmail !== undefined
                      && question.auditorEmail.toLowerCase() === userEmail.toLowerCase())
                  }
                  header={`Question #${question.questionNumber} Evidence`}
                  onClose={() => dispatch(setEvidenceModal({
                    isOpen: false,
                  }))}
                  auditId={audit.id}
                  auditQuestionId={question.auditQuestionId}
                  auditStatus={audit.status}
                  evidenceItems={question.evidence}
                  isItemEditable={item => item.auditStatus === auditData.status
                    && (
                      isEditable
                      || (auditPageAuth.editRestriction === EditRestriction.EditOwn
                        && question.auditorEmail !== undefined
                        && question.auditorEmail.toLowerCase() === userEmail.toLowerCase())
                    )
                  }
                  onItemCreated={item => {
                    dispatch(setQuestionEvidenceItem({ item, auditQuestionId: question.auditQuestionId }));
                  }}
                  onItemDeleted={item => dispatch(removeQuestionEvidence({
                    auditQuestionId: question.auditQuestionId,
                    filename: item.info,
                  }))}
                  onItemUpdated={item => dispatch(setQuestionEvidenceItem({ item, auditQuestionId: question.auditQuestionId }))}
                />
              );
            })()}
          </>
        )
      }

      {
        isStepsPanelOpen
        && auditSteps
        && audit && (
          <AuditStepsPanel
            steps={auditSteps.steps}
            auditStatus={audit.status}
            onClose={() => setIsStepsPanelOpen(false)}
          />
        )
      }
    </Page >
  );
};

export default AuditPage;