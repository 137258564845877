import React from "react";
import Card from "shared/components/layout/card/Card";
import FlexRow from "shared/components/layout/flex/FlexRow";
import ActionItemStatuses from "./entries/ActionItemStatuses";
import AuditGroup from "./entries/AuditGroup";
import AuditId from "./entries/AuditId";
import AuditIdentifier from "./entries/AuditIdentifier";
import AuditName from "./entries/AuditName";
import Auditors from "./entries/Auditors";
import AuditPlanYear from "./entries/AuditPlanYear";
import AuditStatus from "./entries/AuditStatus";
import AuditType from "./entries/AuditType";
import BusinessViewItems from "./entries/BusinessViewItems";
import ClosureDate from "./entries/ClosureDate";
import CompletionDate from "./entries/CompletionDate";
import ComplianceResults from "./entries/ComplianceResults";
import Countries from "./entries/Countries";
import EndDate from "./entries/EndDate";
import Facilities from "./entries/Facilities";
import BusinessFunctions from "./entries/BusinessFunctions";
import LeadAuditors from "./entries/LeadAuditors";
import MobileSites from "./entries/MobileSites";
import OwnerGroups from "./entries/OwnerGroups";
import BusinessTeam from "./entries/BusinessTeam";
import StartDate from "./entries/StartDate";
import Topics from "./entries/Topics";
import "./SearchAuditsFilters.scoped.scss";


const SearchAuditsFilters: React.FC = () =>
  <>
    <AuditId />

    <Card>
      <FlexRow
        className="filters"
      >
        <CompletionDate />
        <AuditType />
        <Topics />

        <AuditStatus />
        <Facilities />
        <Countries />

        <BusinessViewItems />
        <BusinessTeam />
        <BusinessFunctions />

        <MobileSites />
      </FlexRow>
    </Card>

    <Card>
      <FlexRow
        className="filters"
      >
        <AuditGroup />
        <OwnerGroups />
        <LeadAuditors />

        <Auditors />
        <AuditName />
        <AuditIdentifier />

        <AuditPlanYear />
        <StartDate />
        <EndDate />
        <ClosureDate />

        <ComplianceResults
          filterTarget="Completion"
        />
        <ComplianceResults
          filterTarget="Closure"
        />

        <ActionItemStatuses />
      </FlexRow>
    </Card>
  </>;


export default SearchAuditsFilters;