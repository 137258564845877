import { addIfAny, addIfAnyDefined, addIfNotEmpty, dateToString, metaDataToQueryParam, simpleListToMetaDataQueryParam, simpleListToQueryParam, simplePickerToQueryParam, stringToQueryParam } from 'components/search/helpers/searchQueryHelpers';
import { ISearchActionsState } from 'store/search/actions/SearchActionsSlice';
import { useAppSelector } from 'store/store';
import { MetaDataTypes } from 'types/masterDataTypes';

const useActionSearchValues = () => {
  const state = useAppSelector(store => store.searchActions);

  return {
    queryString: getActionSearchQueryString(state),
    filterCount: getFilterCount(state),
  };
};

export default useActionSearchValues;

function getActionSearchQueryString(state: ISearchActionsState) {
  let queryStringParameters: string[] = [];
  let metaDataCount = -1;

  stringToQueryParam(state.actionId, "ActionId", queryStringParameters);

  stringToQueryParam(dateToString(state.dueDateStart, true), "DueDateStart", queryStringParameters);
  stringToQueryParam(dateToString(state.dueDateEnd, false), "DueDateEnd", queryStringParameters);
  simplePickerToQueryParam(state.pickerData.actionItemTypes, "TypeIds", queryStringParameters);
  stringToQueryParam(state.pickerData.auditTypes.selectedItems?.[0]?.key.toString(), "AuditTypeId", queryStringParameters);
  stringToQueryParam(state.actionRequired, "ActionRequired", queryStringParameters);

  simplePickerToQueryParam(state.pickerData.assignedTo, "AssignedToEmails", queryStringParameters);
  simplePickerToQueryParam(state.pickerData.createdBy, "CreatedByEmails", queryStringParameters);
  simpleListToQueryParam(state.statuses.map(x => x.toString()), "Statuses", queryStringParameters);

  stringToQueryParam(dateToString(state.creationDateStart, true), "CreationDateStart", queryStringParameters);
  stringToQueryParam(dateToString(state.creationDateEnd, false), "CreationDateEnd", queryStringParameters);
  stringToQueryParam(dateToString(state.closureDateStart, true), "ClosureDateStart", queryStringParameters);
  stringToQueryParam(dateToString(state.closureDateEnd, false), "ClosureDateEnd", queryStringParameters);

  simplePickerToQueryParam(state.pickerData.leadAuditors, "LeadAuditorEmails", queryStringParameters);
  simplePickerToQueryParam(state.pickerData.causalFactors, "CausalFactorIds", queryStringParameters);
  simplePickerToQueryParam(state.pickerData.priorities, "PriorityIds", queryStringParameters);
  stringToQueryParam(state.isValidated?.toString(), "IsValidated", queryStringParameters);
  stringToQueryParam(state.parentName, "ParentName", queryStringParameters);
  stringToQueryParam(state.parentId, "ParentId", queryStringParameters);
  simpleListToQueryParam(state.parentTypes, "ParentTypes", queryStringParameters);

  metaDataCount = metaDataToQueryParam(state.pickerData.businessViews,
    _ => MetaDataTypes.BusinessView,
    metaDataCount,
    queryStringParameters,
    "AuditMetaData");
  metaDataCount = metaDataToQueryParam(state.pickerData.countries,
    _ => MetaDataTypes.Country,
    metaDataCount,
    queryStringParameters,
    "AuditMetaData");
  metaDataCount = metaDataToQueryParam(state.pickerData.businessTeams,
    _ => MetaDataTypes.BusinessTeam,
    metaDataCount,
    queryStringParameters,
    "AuditMetaData");
  metaDataCount = metaDataToQueryParam(state.pickerData.businessFunctions,
    _ => MetaDataTypes.BusinessFunction,
    metaDataCount,
    queryStringParameters,
    "AuditMetaData");
  metaDataCount = simpleListToMetaDataQueryParam(state.mobileSites.map(x => x.id.toString()),
    MetaDataTypes.MobileSite,
    metaDataCount,
    queryStringParameters,
    "AuditMetaData");
  simpleListToMetaDataQueryParam(state.facilities.map(x => x.id.toString()),
    MetaDataTypes.Facility,
    metaDataCount,
    queryStringParameters,
    "AuditMetaData");

  return queryStringParameters.length === 0
    ? ""
    : `?${queryStringParameters.join('&')}`;
}

function getFilterCount(state: ISearchActionsState) {
  let count = 0;

  Object.entries(state.pickerData)
    .forEach(entry => {
      if (entry[1].selectedItems.length > 0) {
        count++;
      }
    });

  count = addIfNotEmpty(count, state.actionId);
  count = addIfAnyDefined(count, state.isValidated);
  count = addIfAnyDefined(count, state.dueDateStart, state.dueDateEnd);
  count = addIfAnyDefined(count, state.creationDateStart, state.creationDateEnd);
  count = addIfAnyDefined(count, state.closureDateStart, state.closureDateEnd);
  count = addIfAny(count, state.mobileSites);
  count = addIfAny(count, state.facilities);
  count = addIfNotEmpty(count, state.actionRequired);
  count = addIfNotEmpty(count, state.parentName);
  count = addIfNotEmpty(count, state.parentId);
  count = addIfAny(count, state.parentTypes);
  count = addIfAny(count, state.statuses);

  return count;
}