import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { IBulkFile } from "shared/types/bulkFileTypes";

export interface IBulkUploadQuestionsState {
  attachments: IBulkFile[],
  uploadOp?: IOperation<IBulkFile>,
  downloadOp?: IOperation<IBulkFile>,
  isBulkUploadQuestionsModalVisible: boolean,
}

const initialState: IBulkUploadQuestionsState = {
  attachments: [],
  isBulkUploadQuestionsModalVisible: false,
  uploadOp: undefined,
};

export const bulkUploadQuestionsSlice = createSlice({
  name: "bulk-upload-questions",
  initialState,
  reducers: {
    openBulkUploadQuestionsModal: (state, _: Action) => {
      state.isBulkUploadQuestionsModalVisible = true;
    },

    closeBulkUploadQuestionsModal: (state, _: Action) => {
      state.isBulkUploadQuestionsModalVisible = false;
      state.uploadOp = {
        isWorking: false,
      };
    },

    startBulkUploadQuestions: (state, _: PayloadAction<File[]>) => {
      state.uploadOp = {
        isWorking: true,
      };
    },

    startBulkUploadQuestionsByApi: (state, _: Action) => {
      state.uploadOp = {
        isWorking: true,
      };
    },

    finishBulkUploadQuestions: (state, action: PayloadAction<IOperation<IBulkUploadQuestionsState>>) => {
      state.uploadOp = undefined;

      if (action.payload.errorMessage
        || !action.payload.data) {
        state.uploadOp = {
          isWorking: false,
        };
        return;
      }

      state.isBulkUploadQuestionsModalVisible = false;
      state.uploadOp = {
        isWorking: false,
      };
    },

    startBulkQuestionsTemplateDownload: (state, _: Action) => {
      state.downloadOp = {
        isWorking: true,
      };
    },

    finishgBulkQuestionsTemplateDownload: (state, _: Action) => {
      state.downloadOp = {
        isWorking: false,
      };
    },
  },
});

export const {
  openBulkUploadQuestionsModal,
  closeBulkUploadQuestionsModal,
  startBulkUploadQuestions,
  startBulkUploadQuestionsByApi,
  finishBulkUploadQuestions,
  startBulkQuestionsTemplateDownload,
  finishgBulkQuestionsTemplateDownload,
} = bulkUploadQuestionsSlice.actions;
