import React from "react";

interface IQISchedulerDateHeaderProps {
  /** The date bound to this column. */
  date: Date,
  /** If true, this cell will be highlighted. */
  isHighlighted: boolean,
}

const QISchedulerDateHeader: React.FC<IQISchedulerDateHeaderProps> = ({
  date,
  isHighlighted,
}: IQISchedulerDateHeaderProps) => {

  return (
    <div
      className={`date-header ${isHighlighted ? "today" : ""}`}
    >
      {date.getDate() === 1 && month_names_short[date.getMonth()]} {date.getDate()}
    </div>
  );
};

export default QISchedulerDateHeader;

const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];