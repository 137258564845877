import React from "react";
import HelpButton from "shared/components/common/help-button/HelpButton";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { getTextboxMaxLengthHint } from "shared/utilities/stringUtilities";
import { setCurrentFindingProperties } from "store/audit/AuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "./EditCreateFindingBody.scoped.scss";

interface IEditCreateFindingBodyProps {
  isEditable: boolean
}

const EditCreateFindingBody: React.FC<IEditCreateFindingBodyProps> = ({ isEditable }) => {
  const modalState = useAppSelector(store => store.audit.findingModal);
  const findingTypes = useAppSelector(store => store.audit.findingTypes);
  const dispatch = useAppDispatch();

  const findingTypesToDisplay = findingTypes
    .filter(x => x.answerAssociations
      .some(z => z.answerCode === modalState.answerCode));

  return (
    <>
      <LabeledControl
        label="Select Finding"
        isRequired={true}
        hint={
          <HelpButton
            helpSlug="findingTypes"
          />
        }
      >
        <FlexRow className="finding-type-response">
          {findingTypesToDisplay
            .slice()
            .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1)
            .map(type =>
              <label key={type.id}>
                <input
                  type="radio"
                  name={type.name}
                  value={type.id}
                  disabled={!isEditable}
                  onChange={() => dispatch(setCurrentFindingProperties({
                    findingTypeId: type.id,
                  }))}
                  checked={modalState.currentFinding?.findingTypeId === type.id}
                />
                {type.name}
              </label>
            )}
        </FlexRow>
      </LabeledControl>

      <LabeledControl
        isRequired={true}
        label="Finding Justification"
        hint={getTextboxMaxLengthHint(4000)}
      >
        <textarea
          disabled={!isEditable}
          value={modalState.currentFinding?.justification ?? ""}
          maxLength={4000}
          onChange={e => dispatch(setCurrentFindingProperties({
            justification: e.currentTarget.value,
          }))}
        />
      </LabeledControl>
    </>
  );
};

export default EditCreateFindingBody;