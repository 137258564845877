import { authDelete, authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { ITemplate } from "types/auditingTypes";
import { IAuditTemplatesFilters, IDetailedTemplate, ITemplateRequest } from "types/templateApiTypes";
import { formatDetailedTemplate, formatSaveTemplateRequest, formatTemplate } from "./formatters/templateFormatters";

class TemplatesApi {
  public async getTemplates(searchValue?: string,
    abortSignal?: AbortSignal): Promise<ITemplate[]> {
    const response = await authGetJson(config.endpoints.auditing.templates
      + (searchValue?.trim()
        ? `?searchTerm=${searchValue.trim()}`
        : ""
      ), abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatTemplate(obj));
  }

  public async getDetailedTemplates(filter: IAuditTemplatesFilters | undefined,
    abortSignal?: AbortSignal): Promise<IDetailedTemplate[]> {
    const filterParams: string = getFilterParams(filter);
    const response = await authGetJson(`${config.endpoints.auditing.templates}/detailed`
      + filterParams, abortSignal);
    await throwIfResponseError(response);
    return (await response.json())
      .map((obj: any) => formatDetailedTemplate(obj));
  }

  public async createTemplate(template: ITemplateRequest, abortSignal?: AbortSignal): Promise<void> {
    const response = await authPostJson(`${config.endpoints.auditing.templates}`,
      formatSaveTemplateRequest(template), abortSignal);
    await throwIfResponseError(response);
  }

  public async updateTemplate(template: ITemplateRequest, abortSignal?: AbortSignal): Promise<void> {
    const response = await authPutJson(`${config.endpoints.auditing.templates}/${template.id}`,
      formatSaveTemplateRequest(template), abortSignal);
    await throwIfResponseError(response);
  }

  public async deleteTemplate(templateId: number, abortSignal?: AbortSignal): Promise<void> {
    const response = await authDelete(`${config.endpoints.auditing.templates}/${templateId}`, abortSignal);
    await throwIfResponseError(response);
  }
}

function getFilterParams(filter: IAuditTemplatesFilters | undefined): string {

  let linkQSVars: string[] = [];
  let filterParams: string = "";

  const ownerGroups = filter?.ownerGroups || [];
  const auditTopics = filter?.auditTopics || [];
  const templateIdFilter = filter?.templateIdFilter || [];
  const childrenFilter = filter?.childrenFilter || [];

  if (filter?.nameFilter) {
    linkQSVars.push(`searchTerm=${filter.nameFilter}`);
  }

  for (let i = 0; i < (ownerGroups.length ?? 0); i++) {
    linkQSVars.push(`ownerGroups[${i}]=${ownerGroups[i].id}`);
  }

  if (filter?.auditGroupIds?.length) {
    for (let i = 0; i < filter.auditGroupIds.length; i++) {
      linkQSVars.push(`auditGroups[${i}]=${filter.auditGroupIds[i]}`);
    }
  }

  for (let i = 0; i < (auditTopics.length ?? 0); i++) {
    linkQSVars.push(`auditTopics[${i}]=${auditTopics[i].id}`);
  }

  for (let i = 0; i < (templateIdFilter.length ?? 0); i++) {
    linkQSVars.push(`templateIdFilter[${i}]=${templateIdFilter[i].id}`);
  }

  for (let i = 0; i < (childrenFilter.length ?? 0); i++) {
    linkQSVars.push(`ChildrenFilter[${i}].id=${childrenFilter[i].id}`);
    linkQSVars.push(`ChildrenFilter[${i}].type=${childrenFilter[i].type}`);
  }

  if (linkQSVars.length) {
    filterParams += '?' + linkQSVars.join("&");
  }

  return filterParams;
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new TemplatesApi();

