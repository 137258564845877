import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Hint from "shared/components/common/hint/Hint";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { removeChildDimension, setRemoveChildDimensionModal } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";

const maxCommentLength = 500;

const RemoveChildDimensionModal: React.FC = () => {
  const dispatch = useDispatch();
  const modalState = useAppSelector(store => store.facilityAttributes.removeChildDimensionModal);

  const isOpen = modalState?.isOpen;

  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    setComment("");
  }, [isOpen, setComment]);

  return (
    <Modal
      isOpen={isOpen}
      header="Remove Child Dimension"
      showCloseButton={true}
      onCloseButtonClicked={() => dispatch(setRemoveChildDimensionModal({ isOpen: false }))}
      buttons={[{
        key: "Cancel",
        text: "Cancel",
        className: "secondary",
        onClick: () => dispatch(setRemoveChildDimensionModal({ isOpen: false })),
      }, {
        key: "Submit",
        text: "Submit",
        className: "primary",
        disabled: !comment.trim(),
        onClick: () => dispatch(removeChildDimension({
          profileId: modalState.profileId,
          comment,
        })),
      }]}
    >
      <FlexCol>
        <span>Would you like to REMOVE <b>{modalState.rowText}</b> from <b>{modalState.parentText}</b>?</span>

        <LabeledControl
          label={`Why is ${modalState.rowText} not required?`}
          isRequired
        >
          <textarea
            value={comment}
            placeholder="Enter Reason"
            maxLength={maxCommentLength}
            onChange={e => setComment(e.currentTarget.value)}
          />
        </LabeledControl>
        <Hint>
          {maxCommentLength - comment.length}/{maxCommentLength} characters remaining.
        </Hint>
      </FlexCol>
    </Modal>
  );
};

export default RemoveChildDimensionModal;