import { IQISchedulerDate, IQISchedulerDateRange, IQISchedulerItem, IQISchedulerRow, IQISchedulerRowItemDictionary, qiSchedulerBaseItemHeight, qiSchedulerBaseRowHeight } from "./qiSchedulerTypes";

export function addDays(days: number, toDate: Date) {
  var date = new Date(toDate.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export function getDates(startDate: Date, stopDate: Date): IQISchedulerDate[] {
  let dateArray: IQISchedulerDate[] = [];
  let currentDate = startDate;
  let dateIndex = -1;
  while (currentDate <= stopDate) {
    dateArray.push({
      date: new Date(currentDate.getTime()),
      index: ++dateIndex,
    });
    currentDate = addDays(1, currentDate);
  }
  return dateArray;
}

export function computeRowItemDictionary(rows: IQISchedulerRow[],
  items: IQISchedulerItem<any>[],
  dict: IQISchedulerRowItemDictionary,
  rowIndex: number): number {
  // For each row, figure out its index and the number of items in it.
  rows.forEach(row => {
    const rowItems = items
      .filter(x => x.rowId === row.id)
      .sort((a, b) => a.startDate.getTime() < b.startDate.getTime() ? -1 : 1);

    dict[row.id] = {
      items: rowItems,
      rowIndex: ++rowIndex,
      rowHeight: qiSchedulerBaseRowHeight,
    };

    if (row.children?.length) {
      rowIndex = computeRowItemDictionary(row.children,
        items,
        dict,
        rowIndex,
      );
    }
  });

  // Now compute the slot positions for all items.
  computeItemSlots(dict);

  // Now for each row, figure out the max overlaps to determine the row's height.
  rows.forEach(row => {
    if (!dict[row.id]?.items.length) {
      return;
    }

    const maxRowOverlaps = Math.max.apply(null, dict[row.id]!.items.map(x => x.rowSlot ?? 0) ?? [0]) + 1;

    const expectedRowHeight = maxRowOverlaps * (qiSchedulerBaseItemHeight + 2) + 10;

    dict[row.id]!.rowHeight = qiSchedulerBaseRowHeight > expectedRowHeight
      ? qiSchedulerBaseRowHeight
      : expectedRowHeight;
  });

  return rowIndex;
}

export function computeItemSlots(rowItemDictionary: IQISchedulerRowItemDictionary) {
  // For each row, iterate through its items and figure out which "slot" each one should occupy.
  Object.entries(rowItemDictionary)
    .forEach(([_, entry]) => {
      if (!entry) {
        return;
      }

      interface IDateEntryItem {
        itemId: string | number,
        slot: number,
      }

      interface IDateEntry {
        date: Date,
        items: IDateEntryItem[],
      }

      const rowDates: IDateEntry[] = [];

      const items = entry.items;

      items.forEach(item => {
        let allSlotsOccupiedByItem: IDateEntry[] = [];

        // For each of the items (pre-sorted by earlier startDate first), figure out which slot it
        // should occupy across all the days it occupies.
        const currDate = new Date(item.startDate);
        const endDate = new Date(item.endDate);
        currDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        while (currDate <= endDate) {
          // Find the date entry for this date.
          let dateEntry = rowDates.find(x => x.date.getTime() === currDate.getTime());

          if (!dateEntry) {
            // No items have occupied this date yet. Create a date entry.
            dateEntry = {
              date: new Date(currDate),
              items: [],
            };

            rowDates.push(dateEntry);
          }

          // Add this dateEntry to the list occupied by this item.
          allSlotsOccupiedByItem.push(dateEntry);

          currDate.setDate(currDate.getDate() + 1);
        }

        // Now that the list of dateEntries occupied by this item have been found,
        // figure out which slot this item should occupy.
        const occupiedSlots = allSlotsOccupiedByItem
          .flatMap(x => x.items.map(i => i.slot))
          .sort((a, b) => a - b);

        let slotNum = 0;

        if (occupiedSlots.length) {
          // One or more other items occupy these date(s). Find the highest slot
          // number and put this item into the next slot.
          slotNum = findLowestUnusedSlotNumber(occupiedSlots);
        }

        allSlotsOccupiedByItem.forEach(dateEntry => {
          dateEntry.items.push({
            itemId: item.id,
            slot: slotNum,
          });
        });

        // Now that the slot number has been figured out, copy it into the item itself.
        item.rowSlot = slotNum;
      });
    });
}

function findLowestUnusedSlotNumber(sortedOccupiedSlots: number[]) {
  let smallestUnused = 0;

  // Find the first gap in the sequence of non-negative integers.
  for (let i = 0; i < sortedOccupiedSlots.length; i++) {
    if (sortedOccupiedSlots[i] > smallestUnused) {
      return smallestUnused;
    }
    smallestUnused = sortedOccupiedSlots[i] + 1;
  }

  // If no unused number found, return the largest number plus one.
  return sortedOccupiedSlots[sortedOccupiedSlots.length - 1] + 1;
}

export function getMidnightDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getSchedulerMonthDateRangeFromDate(date: Date): IQISchedulerDateRange {
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return {
    startTimestamp: startOfMonth.getTime(),
    endTimestamp: endOfMonth.getTime()
  };
}