import { formatAuditStatus, formatComplianceResult } from 'api/auditing/formatters/auditFormatters';
import { formatOwnerGroup } from 'api/auditing/OwnerGroupsApi';
import { formatAuditTopic } from 'api/masterdata/AuditTopicApi';
import { formatAuditGroup, formatAuditType, formatBusinessView, formatCountry, formatFacility, formatBusinessFunction, formatMobileSite, formatBusinessTeam } from 'api/masterdata/formatters/masterDataFormatters';
import { formatAzureADUser } from 'api/users/formatters/userFormatter';
import { formatItemsArr, formatToString } from 'shared/utilities/formatterUtilities';
import { getDateTime } from 'shared/utilities/dateFormatters';
import { mapMetaDataItemsByType } from 'shared/utilities/metaDataUtilities';
import {  MetaDataTypes } from 'types/masterDataTypes';
import { ICustomAuditSearch } from 'types/searchAuditTypes';

export function formatAuditFilter(obj: any): ICustomAuditSearch {
  return {
    id: Number(obj.id),
    completionDateStart: getDateTime(obj.completionDateStart),
    completionDateEnd: getDateTime(obj.completionDateEnd),
    status: (obj.status || []).map(formatAuditStatus),
    auditTypes: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.AuditType, formatAuditType),
    auditTopics: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.AuditTopic, formatAuditTopic),
    facilities: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.Facility, formatFacility),
    countries: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.Country, formatCountry),
    businessViews: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.BusinessView, formatBusinessView),
    businessTeams: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.BusinessTeam, formatBusinessTeam),
    businessFunctions: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.BusinessFunction, formatBusinessFunction),
    mobileSites: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.MobileSite, formatMobileSite),
    auditGroups: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.AuditGroup, formatAuditGroup),
    ownerGroups: mapMetaDataItemsByType(obj.metaDataItems, MetaDataTypes.OwnerGroup, formatOwnerGroup),
    leadAuditors: formatItemsArr(obj.leadAuditors, formatAzureADUser),
    auditors: formatItemsArr(obj.auditors, formatAzureADUser),
    auditName: formatToString(obj.auditName),
    auditIdentifier: formatToString(obj.auditIdentifier),
    auditPlanYear: formatItemsArr(obj.auditPlanYear, Number),
    startDateStart: getDateTime(obj.startDateStart),
    startDateEnd: getDateTime(obj.startDateEnd),
    endDateStart: getDateTime(obj.endDateStart),
    endDateEnd: getDateTime(obj.endDateEnd),
    closureDateStart: getDateTime(obj.closureDateStart),
    closureDateEnd: getDateTime(obj.closureDateEnd),
    complianceAtCompletion: formatItemsArr(obj.complianceAtCompletion, formatComplianceResult),
    complianceAtClosure: formatItemsArr(obj.complianceAtClosure, formatComplianceResult),
    allActionsClosed: obj.allActionsClosed !== null
      ? Boolean(obj.allActionsClosed)
      : undefined,
  };
}
