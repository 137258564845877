import { formatFacility } from "api/masterdata/formatters/masterDataFormatters";
import UrlRoutes from "components/routing/UrlRoutes";
import config from "config";
import React from "react";
import { Link } from "react-router-dom";
import { DataGridFilterOperator, IDataGridColumn } from "shared/components/layout/grid/types/dataGridTypes";
import { IFacility } from "types/masterDataTypes";
import ODataPicker from "../odata-picker/ODataPicker";

const applyBusinessTeamFilter = false;

interface IFacilityPickerProps {
  /** Optional. Used to filter facilities by their country. */
  countryIdsFilter?: number[],
  /** Optional. Used to filter facilities by their businessTeam hierarchy. */
  businessTeamIdsFilter?: number[],
  /** The callback used when the user applies their selection in the picker. */
  onSelectionChange: (items: IFacility[]) => void,
  /** The list of already-selected facilities. */
  selectedItems: IFacility[],
  /** Optional. Determines if suggestions should be loaded and shown. Default = true.*/
  showSuggestions?: boolean,
  /** Optional.
   * If true, deleted/inactive facilities will be included in the search results.
   * Default = false. */
  includeDeleted?: boolean,
  /** Determines if the picker is disabled. Default = false. */
  isDisabled?: boolean,
}

const facilityGridColumns: IDataGridColumn[] = [
  { uid: "id", field: "id", headerText: "Id", type: "number", width: 130, visible: false, template: row => row.id.toString(), },
  { uid: "country", field: "country", headerText: "Country", type: "string", width: 150, },
  { uid: "state", field: "state", headerText: "State", type: "string", width: 225, },
  { uid: "city", field: "city", headerText: "City", type: "string", width: 225, },
  { uid: "name", field: "name", headerText: "Name", type: "string", width: 300, },
  { uid: "commonId", field: "commonId", headerText: "CommonId", type: "number", width: 150, template: row => row.commonId.toString(), },
  { uid: "facilityStatus", field: "facilityStatus", headerText: "Status", type: "string", width: 150, },
];

const FacilityPicker: React.FC<IFacilityPickerProps> = ({
  countryIdsFilter,
  businessTeamIdsFilter,
  onSelectionChange,
  selectedItems,
  showSuggestions = true,
  includeDeleted = false,
  isDisabled,
}: IFacilityPickerProps) => {
  let queryString = [
    (countryIdsFilter || []).map(x => `countryIds=${x}`).join('&'),
    ((applyBusinessTeamFilter ? businessTeamIdsFilter : []) || []).map(x => `businessTeamIds=${x}`).join('&'),
    `includeDeleted=${includeDeleted}`
  ].filter(x => x).join('&');
  queryString = queryString.length ? `?${queryString}` : "";

  return (
    <ODataPicker<IFacility>
      keyMapper={item => item.id}
      oDataUrl={`${config.endpoints.baseUrl}/odata/facilities${queryString}`}
      onSelectionChange={items => onSelectionChange(items.map(x => formatFacility(x)))}
      selectedItems={selectedItems}
      renderSelectedItem={item => item.name}
      selectMode="Multiple"
      title="Facilities"
      subTitle={
        <Link
          to={UrlRoutes.LegacyNodeMapping.urlTemplate}
          target="_blank"
        >
          Legacy QUEST Node mapping.
        </Link>
      }
      sortSelectedItems={(a, b) => a.name < b.name ? -1 : 1}
      gridColumns={facilityGridColumns}
      suggestedItemFormatter={showSuggestions
        ? obj => formatFacility(obj)
        : undefined
      }
      suggestedItemsUrl={showSuggestions
        ? `${config.endpoints.masterData.suggestedFacilities}${queryString}`
        : undefined
      }
      className="facility-grid-picker"
      filterSettings={{
        columns: [{
          uid: "facilityStatus",
          field: "facilityStatus",
          value: "ACTIVE",
          operator: DataGridFilterOperator.Equals,
        }],
      }}
      isDisabled={isDisabled}
    />
  );

};

export default FacilityPicker;