import { AnyAction } from "@reduxjs/toolkit";
import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import { Dispatch } from "react";
import { Link } from "react-router-dom";
import infoIcon from "shared/media/dls/info.svg";
import { IColumnItem } from "shared/types/columnTypes";
import { userToString } from "shared/utilities/userUtilities";
import { ISelectedAuditQuestion, toggleQuestionSelections } from "store/audit-non-conformances/AuditNonConformancesSlice";
import { openFindingModal, setActionItemListModal, setEvidenceModal, setFocusedGuidance } from "store/audit/AuditSlice";
import { FindingLinkType, FindingModalModes, IFinding, IFindingType } from "store/audit/reducers/findingReducers";
import { ActionItemLinkTypes, IActionItem } from "types/actionItemTypes";
import { IAnswer } from "types/auditMasterDataTypes";
import { AuditStatuses, IAuditQuestion, IAuditor } from "types/auditingTypes";
import ActionItemButton from "./action-item-button/ActionItemButton";
import FindingsButton from "./findings-button/FindingsButton";

interface IExtraRowData {
  currentAnswer?: IAnswer,
  currentNotes?: string,
  inProgressNotes?: string,
}

export function getNonCompTopicTableColumns(disableSelection: boolean,
  selectedAuditQuestions: ISelectedAuditQuestion[],
  auditId: number | undefined,
  auditStatus: AuditStatuses | undefined,
  auditors: IAuditor[] | undefined,
  currUserIsLeader: boolean,
  actionItems: IActionItem[],
  findings: IFinding[],
  findingTypes: IFindingType[],
  dispatch: Dispatch<AnyAction>): IColumnItem<IAuditQuestion, keyof IAuditQuestion, IExtraRowData | undefined>[] {
  return [
    {
      key: "check",
      header: "",
      customRender: item => (
        <input
          type="checkbox"
          disabled={disableSelection}
          value={item.auditQuestionId}
          checked={selectedAuditQuestions.some(s => s.auditQuestionId === item.auditQuestionId)}
          onChange={() => dispatch(toggleQuestionSelections([{
            auditQuestionId: item.auditQuestionId,
            questionId: item.questionId,
            questionNum: item.questionNumber,
            isSelected: !selectedAuditQuestions.some(s => s.auditQuestionId === item.auditQuestionId),
          }]))}
        />
      ),
    },
    {
      key: "subTopic",
      header: "Sub-Topic",
      width: 150,
      maxHeight: "4em",
      customRender: item => !item.subTopics.length
        ? "--"
        : item.subTopics[0].name,
    },
    {
      key: "questionNumber",
      header: "ID",
      width: 70,
      customRender: item => (
        <Link
          to={formatRoute(UrlRoutes.AuditExecuteQuestion, {
            auditId: auditId?.toString() || "0",
            auditQuestionId: item.auditQuestionId.toString(),
          })}
        >
          {item.questionNumber}
        </Link>
      ),
    },
    {
      key: "question",
      header: "Question",
      width: 250,
      maxHeight: "4em",
      customRender: item => item.question,
    },
    {
      key: "findings",
      header: "Findings",
      width: 125,
      textAlign: "center",
      headerTextAlign: "center",
      customRender: item => getFindingsButton(findings,
        item.auditQuestionId,
        item.responses.find(x => x.auditStatus === auditStatus)?.answer,
        findingTypes,
        dispatch),
    },
    {
      key: "guidance",
      header: "Guidance",
      width: 75,
      customRender: item => !!item.guidance
        ? (
          <img
            src={infoIcon}
            className="icon-small guidance-button"
            title={item.guidance}
            onClick={() => dispatch(setFocusedGuidance({
              guidance: item.guidance,
              questionNumber: item.questionNumber,
            }))}
            alt="View"
          />
        ) : "-",
    },
    {
      key: "answer",
      header: "Answer",
      width: 50,
      customRender: (_, rowData) => <span className="answer" title={rowData?.currentAnswer?.name}>{rowData?.currentAnswer?.code || "-"}</span>,
    },
    {
      key: "notes-original",
      header: "Auditor Notes - Original",
      width: 175,
      maxHeight: "4em",
      customRender: (_, rowData) => rowData?.inProgressNotes || rowData?.currentNotes || "-",
    },
    {
      key: "notes-updated",
      header: "Auditor Notes - Updated",
      width: 175,
      maxHeight: "4em",
      customRender: (_, rowData) => rowData?.inProgressNotes
        && rowData.currentNotes !== rowData?.inProgressNotes
        ? rowData.currentNotes
        : "-",
    },
    {
      key: "evidence",
      header: "Evidence",
      width: 75,
      customRender: item => item.evidence.length
        ? (
          <button
            className="link"
            onClick={() => dispatch(setEvidenceModal({
              auditQuestionId: item.auditQuestionId,
              isOpen: true,
            }))}
          >
            View
          </button>
        )
        : "-",
    },
    {
      key: "closedItems",
      header: "# Closed Corrective & Preventative Actions",
      width: 125,
      customRender: item => getActionItemButton(actionItems,
        item.auditQuestionId,
        item.questionNumber,
        "closed",
        dispatch),
    },
    {
      key: "validatedItems",
      header: "# Validated Corrective & Preventative Actions",
      width: 140,
      customRender: item => getActionItemButton(actionItems,
        item.auditQuestionId,
        item.questionNumber,
        "validated",
        dispatch),
    },
    {
      key: "category",
      header: "Category",
      width: 80,
      customRender: item => item.category,
    },
    {
      key: "auditor",
      header: "Auditor",
      width: 150,
      customRender: (item, rowData) => (item.auditorEmail !== undefined
        && rowData?.currentAnswer !== undefined)
        || !currUserIsLeader
        || auditStatus === AuditStatuses.Completed
        || auditStatus === AuditStatuses.Closed
        ? (
          userToString(auditors?.find(x => x.email === item.auditorEmail)) || "Unassigned"
        ) : "-"
    }
  ];
}

function getActionItemButton(allActionItems: IActionItem[],
  auditQuestionId: number,
  questionNumber: number,
  type: "closed" | "validated",
  dispatch: Dispatch<AnyAction>) {
  return (
    <ActionItemButton
      allActionItems={allActionItems}
      auditQuestionId={auditQuestionId}
      questionNumber={questionNumber}
      type={type}
      onClick={() => {
        dispatch(setActionItemListModal({
          linkOptions: {
            linkId: auditQuestionId,
            linkType: ActionItemLinkTypes.AuditQuestion,
            parentDisplay: `Question #${questionNumber}`,
          },
          isOpen: true,
        }));
      }}
    />
  );
}

function getFindingsButton(findings: IFinding[],
  auditQuestionId: number,
  answer: string | undefined,
  findingTypes: IFindingType[],
  dispatch: Dispatch<AnyAction>) {
  let questionFinding = findings
    .find(f => f.links
      .some(l => l.linkId === auditQuestionId
        && l.linkType === FindingLinkType.AuditQuestion));

  return (
    <FindingsButton
      hasFinding={!!questionFinding}
      buttonText={findingTypes
        .find(x => x.id === questionFinding?.findingTypeId)
        ?.name}
      onClick={() => {
        if (answer) {
          dispatch(openFindingModal({
            answerCode: answer,
            finding: questionFinding,
            mode: questionFinding
              ? FindingModalModes.Prompt
              : FindingModalModes.EditCreate,
            desiredLinks: [{
              linkId: auditQuestionId,
              linkType: FindingLinkType.AuditQuestion,
            }],
            autoSelectedLinksForRemoval: [{
              linkId: auditQuestionId,
              linkType: FindingLinkType.AuditQuestion,
            }],
          }));
        }
      }}
    />
  );
}
