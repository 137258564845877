import React from "react";
import "./Banner.scoped.scss";

interface IBannerProps {
  type: BannerType,
}

export enum BannerType {
  info = "info",
  warn = "warn",
  error = "error",
  success = "success"
}

const Banner: React.FC<IBannerProps & React.HTMLProps<HTMLDivElement>> = (props) =>
  <div
    {...props}
    className={`banner ${props.type} ${props.className}`}
  >
    {props.children}
  </div>;

export default Banner;