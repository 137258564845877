import { useMsal } from "@azure/msal-react";
import StorageApi from "api/storage/StorageApi";
import { msalPublicClient } from "auth/auth-config";
import config from "config";
import React, { useEffect, useRef, useState } from "react";
import Login from "shared/components/auth/Login";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import "shared/components/GlobalStyles.scss";
import UserSentimentSurvey from "shared/components/user-sentiment-survey/UserSentimentSurvey";
import { loadUserProfile, setCurrentUser } from "shared/store/auth/AuthSlice";
import { restoreCardStatesFromCache } from "store/card-states/CardStatesSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "shared/components/GlobalStyles.scss";

const AppStartup: React.FC<{ children: React.ReactNode; }> = ({ children }) => {
  const authLoadOperation = useAppSelector(store => store.auth.loadOperation);
  const userProfile = useAppSelector(store => store.auth.userProfile);
  const currentUser = useAppSelector(store => store.auth.currentUser);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const loadErrorTimeoutRef = useRef<number>(0);

  const dispatch = useAppDispatch();
  const { accounts } = useMsal();
  const user = accounts[0];
  const username = user.username || "";
  const name = (user as any).name || "";

  useEffect(() => {
    dispatch(setCurrentUser({
      email: username,
      name: name,
    }));
  }, [dispatch, username, name]);

  useEffect(() => {
    dispatch(loadUserProfile({}));
    dispatch(restoreCardStatesFromCache());
    StorageApi.clearExpiredItems();
  }, [dispatch]);

  var isOpWorking = authLoadOperation?.isWorking;
  var authError = authLoadOperation?.errorMessage;

  useEffect(() => {
    if (isOpWorking) {
      setShowLoadingScreen(true);
    } else if (authError) {
      // Start a timer for 10 seconds that will show the error message.
      loadErrorTimeoutRef.current = window.setTimeout(() => setShowLoadingScreen(false), 10000);
    } else if (!isOpWorking
      && !authError) {
      // Loading has finished and there is no auth error.
      // Clear the timer if one is running.
      window.clearTimeout(loadErrorTimeoutRef.current);
      // Stop showing the load screen.
      setShowLoadingScreen(false);
    }
  }, [setShowLoadingScreen, isOpWorking, authError]);

  if (showLoadingScreen) {
    return (
      <Login
        message={(
          <>
            Retrieving user profile information...
            <Spinner />
          </>
        )}
      />
    );
  } else if (authLoadOperation?.errorMessage) {
    return (
      <Login
        error
        message={(
          <Banner
            type={BannerType.error}
          >
            We failed to load your user profile.<br />
            <br />
            {authLoadOperation.errorMessage}
          </Banner>
        )}
      />
    );
  } else if (userProfile.profiles.length === 0) {
    return (
      <Login
        error
        message={(
          <Banner
            type={BannerType.error}
          >
            You have no user profiles assigned to you. Please contact an administrator if you believe this to be in error.
          </Banner>
        )}
      />
    );
  }

  return (
    <>
      {children}
      <UserSentimentSurvey
        userAlias={currentUser.email.substring(0, currentUser.email.indexOf("@"))}
        slbTenantId={config.authConfig.tenantId}
        msalPublicClient={msalPublicClient}
        checkForFeedbackUrl={config.userSentimentSurvey.checkFeedbackUrl}
        surveyScope={config.userSentimentSurvey.scope}
        surveySecretKey={config.userSentimentSurvey.secretKey}
      />
    </>
  );
};

export default AppStartup;