import TagsRecap from "components/audits/manage/tags-recap/TagsRecap";
import { groupBy } from "lodash";
import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IRestrictedMetaData } from "shared/types/userProfileTypes";
import "./SplitMetaPicker.scoped.scss";

interface ISplitMetaPickerProps<T> {
  className?: string,
  customLeftElement?: React.ReactNode,
  columns: ISplitMetaColumn[],
  selectedItems: T[],
  isDisabled: boolean,
  /** Maps individual items to columnKeys defined in the `columns` property. */
  mapItemToColumnKey: (item: T) => string,
  /**
  * Receives one of the picker items and must return its renderable node for display in the list.
  * If this is not provided, the "text" property of the IPickerItem will be rendered instead.
  */
  renderListItem?: (item: T) => string | React.ReactNode,
  /** When an item is deselected, this is called. */
  onItemDeselected: (item: T) => void,
  /**
   * This function will be called to sort the items shown in the Selected Items list.
   */
  itemSorter: (item1: T, item2: T) => number,
  /** Given an item, this should return the unique identifier of that item. */
  keyMapper: (item: T) => number | string,
  /** Optional. Given an item, this should return its MetaDataType for
   * comparison against the userProfileMetaRestriction. If no `userProfileMetaRestrictions`
   * are provided, this function will not be used. */
  metaTypeMapper?: (item: T) => string,
  /**
   * The picker to render on the far right of the control. The `pickerNode` should be set to: `<PlusButton />`
   */
  picker: React.ReactNode,
  /** Optional. Disables any picker item that doesn't appear in this list. Only provide this list if the the
   * list of available items should be filtered. If there are no items in the list that match the type of the
   * picker, all items will be enabled.
   * 
   * For the SplitMetaPicker, it is important that this list be pre-filtered to the correct types. For example
   * if this SplitMetaPicker renders IBusinessView items, this list should be filtered to only meta of those types!
   * */
  userProfileMetaRestrictions?: IRestrictedMetaData[],
  /** Optional. If provided, this method will be used to determine if an item exists in the user's profile
   * metadata restriction list. If true, the user will be able to remove the item.
   */
  customMetaRestrictionChecker?: (item: T, meta: IRestrictedMetaData[]) => boolean,
}

interface ISplitMetaColumn {
  /** A uniquely-identifiable string. */
  key: string,
  /** The text shown on the top of the column. */
  label: string,
  /** Optional. If true, the column will show a red asterisk on the label. */
  isRequired?: boolean,
}

const SplitMetaPicker = <T,>({
  className,
  customLeftElement,
  columns,
  selectedItems,
  isDisabled,
  mapItemToColumnKey,
  renderListItem,
  onItemDeselected,
  itemSorter,
  keyMapper,
  metaTypeMapper,
  picker,
  userProfileMetaRestrictions,
  customMetaRestrictionChecker,
}: ISplitMetaPickerProps<T>) => {

  const itemsByColumn = groupBy(selectedItems
    .map(item => ({
      item,
      columnKey: mapItemToColumnKey(item),
    })), x => x.columnKey);

  return (
    <FlexRow
      className={`split-meta-picker ${className || ""}`}
    >
      {!!customLeftElement && (
        <div className="split-col custom-element">
          {customLeftElement}
        </div>
      )}

      {columns.map(col => (
        <div
          className="split-col"
          key={col.key}
        >
          <LabeledControl
            label={col.label}
            isRequired={col.isRequired}
          >
            <TagsRecap
              items={itemsByColumn[col.key]?.map(x => ({
                key: keyMapper(x.item),
                item: x.item,
              })) || []}
              nameMapper={item => (renderListItem
                && item.item)
                ? renderListItem(item.item)
                : (item.text || "")
              }
              onTagClick={x => x.item && onItemDeselected(x.item)}
              itemSorter={(a, b) => a.item === undefined || b.item === undefined ? 0 : itemSorter(a.item, b.item)}
              isDisabled={isDisabled}
              noItemsMessage="--"
              isDisabledMapper={
                !userProfileMetaRestrictions?.length
                  ? undefined
                  : item => {
                    if (!item.item) {
                      return false;
                    }

                    if (customMetaRestrictionChecker
                      && item.item) {
                      return !customMetaRestrictionChecker(item.item, userProfileMetaRestrictions);
                    }

                    if (item.item
                      && metaTypeMapper) {
                      return !userProfileMetaRestrictions
                        .some(x => x.id === keyMapper(item.item!)
                          && x.type === metaTypeMapper(item.item!));
                    }

                    return false;
                  }
              }
            />
          </LabeledControl>
        </div>
      ))}

      {!isDisabled && (
        <LabeledControl
          className="add-btn"
          label=""
        >
          {picker}
        </LabeledControl>
      )}
    </FlexRow>
  );
};

export default SplitMetaPicker;