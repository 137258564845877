import { useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import completeMajorIcon from "shared/media/icons/audit-steps/complete-major.svg";
import completeMinorIcon from "shared/media/icons/audit-steps/complete-minor.svg";
import nextUpMajorIcon from "shared/media/icons/audit-steps/next-up-major.svg";
import nextUpMinorIcon from "shared/media/icons/audit-steps/next-up-minor.svg";
import optionalIcon from "shared/media/icons/audit-steps/optional.svg";
import { showInfoToast } from "shared/store/toast/ToastSlice";
import { useAppDispatch } from "store/store";
import { AuditStepBuckets, AuditSteps, AuditStepStage } from "types/auditingTypes";
import "./AuditStep.scoped.scss";

interface IAuditStepProps {
  level: number,
  number: number | undefined,
  text: AuditStepBuckets | AuditSteps,
  stage: AuditStepStage,
  isOptional?: boolean,
  url?: string,
  onLayoutChange(): void,
  children?: React.ReactNode,
}

const majorStepImages = {
  [AuditStepStage.done]: completeMajorIcon,
  [AuditStepStage.nextUp]: nextUpMajorIcon,
  [AuditStepStage.toDo]: optionalIcon,
};

const minorStepImages = {
  [AuditStepStage.done]: completeMinorIcon,
  [AuditStepStage.nextUp]: nextUpMinorIcon,
  [AuditStepStage.toDo]: optionalIcon,
};

const AuditStep: React.FC<IAuditStepProps> = ({
  level,
  number,
  text,
  stage,
  isOptional,
  url,
  onLayoutChange,
  children,
}) => {
  const hasChildren = !!children;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    onLayoutChange();
  }, [onLayoutChange]);

  const isOnThisPage = useMatch(url || "-----");

  return (
    <div
      className={`audit-step ${level === 1 ? "bucket" : "step"}`}
    >
      <div
        className={`audit-step-header`}
        onClick={!hasChildren && url
          ? () => isOnThisPage ? dispatch(showInfoToast("You are already on the selected page.")) : navigate(url)
          : undefined
        }
      >
        <div className="track">
          <img
            className={`audit-step-stage ${level !== 1 ? "minor-stage" : "major-stage"} ${stage}`}
            alt="Open/Go"
            src={level === 1
              ? majorStepImages[stage]
              : minorStepImages[stage]
            }
          />
        </div>

        <span
          className={`audit-step-title ${stage}`}
        >
          {text}

          {isOptional && (
            <span className="optional">(optional)</span>
          )}
        </span>
      </div>

      {hasChildren
        && (
          <div
            className="child-steps"
          >
            {children}
          </div>
        )}
    </div>
  );
};

export default AuditStep;
