import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import PickerDropdown from "shared/components/controls/picker-dropdown/PickerDropdown";
import { loadPickerItems, setSelectedPickerItems } from "store/search/audits/SearchAuditsSlice";
import { useAppSelector } from "store/store";

const AuditGroup: React.FC = () => {
  const auditGroups = useAppSelector(store => store.searchAudits.pickerData.auditGroups);
  
  return (
    <LabeledControl
      label="Audit Group"
      className="type"
    >
      <PickerDropdown
        pickerState={auditGroups}
        setSelectedItemsAction={setSelectedPickerItems}
        loadAction={loadPickerItems}
      />
    </LabeledControl>
  );
};

export default AuditGroup;