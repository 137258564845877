import React, { useState } from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import IVideoLinkItem from "./VideoLinkItem";
import VideoIcon from "./video-icon/VideoIcon";
import VideoLink from "./video-link/VideoLink";

const videoModalTitle = "QI - Application Knowledge Videos";

interface IVideoLinkButtonProps {
  links: IVideoLinkItem[],
}

const VideoLinkButton: React.FC<IVideoLinkButtonProps> = ({
  links,
}: IVideoLinkButtonProps) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const closeModal = () => setIsFilterModalOpen(false);

  return (
    <>
      <VideoIcon
        onClick={() => setIsFilterModalOpen(true)}
        tooltip={videoModalTitle}
      />

      {isFilterModalOpen &&
        <Modal
          isOpen={isFilterModalOpen}
          header={videoModalTitle}
          showCloseButton
          onCloseButtonClicked={closeModal}
          buttons={[{
            key: "close",
            text: "Close",
            onClick: closeModal,
            className: "primary",
          }]}
        >
          <FlexCol>
            {links.map(item =>
              <VideoLink
                item={item}
                key={item.url}
              />
            )}
          </FlexCol>
        </Modal>
      }
    </>
  );
};

export default VideoLinkButton;