import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot } from 'lexical';
import { useEffect } from "react";

export default function OnChangePlugin({ onChange }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      const {
        plainText,
        html,
      } = editorState
        .read(() => {
          const html = $generateHtmlFromNodes(editor, null);
          const plainText = $getRoot().getTextContent();

          return {
            plainText,
            html,
          };
        });
      onChange(plainText, html);
    });
  }, [editor, onChange]);
  return null;
}