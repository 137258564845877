import React from "react";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { ICLMPartialRequirementItemDto } from "types/auditPlanningTypes";

interface ICLMSubTopicRequirementsProps {
  requirements: ICLMPartialRequirementItemDto[],
  selectedTopicId: number,
  onSubTopicOptInChanged(subTopicId: number, isOptedIn: boolean): void,
  onTopicOptInChanged: (topicId: number, isOptedIn: boolean) => void,
}

const CLMSubTopicRequirements: React.FC<ICLMSubTopicRequirementsProps> = ({
  requirements,
  selectedTopicId,
  onSubTopicOptInChanged: onOptInChanged,
  onTopicOptInChanged,
}: ICLMSubTopicRequirementsProps) => {
  const items = requirements
    .filter(x => x.topicId === selectedTopicId);

  return (
    <Table
      data={items}
      className="clm-subtopics-table"
      keyProp={"subTopicId"}
      columns={[{
        key: "dmsNumber",
        property: "clmDocRefDms",
        header: "DMS Number",
      },
      {
        key: "name",
        property: "subTopicName",
        header: "Procedure",
      },
      {
        key: "isInScope",
        header: (
          <FlexRow className="header-flex-row">
            <input
              type="checkbox"
              checked={!items
                .some(x => !x.isInScope
                  && !x.isOptedIn)}
              disabled={!items
                .some(x => !x.isInScope)}
              id="edit_plan_clm_partial_checkall"
              onChange={e => onTopicOptInChanged(selectedTopicId, e.currentTarget.checked)}
            />

            <label
              htmlFor="edit_plan_clm_partial_checkall"
            >
              Include in Audit
            </label>
          </FlexRow>
        ),
        customRender: item => (
          <input
            type="checkbox"
            checked={item.isInScope
              || item.isOptedIn}
            disabled={item.isInScope}
            onChange={e => onOptInChanged(item.subTopicId, e.currentTarget.checked)}
          />
        ),
      }]}
      theme={TableThemes.compact}
      areHeadersSticky={false}
    />
  );
};

export default CLMSubTopicRequirements;