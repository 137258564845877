import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import { dismissQuestionRemovalOverride, saveAudit } from "store/manage-audit/ManageAuditSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const ConfirmDeleteQuestionsAuditModal = () => {
  const {
    showQuestionRemovalOverride,
  } = useAppSelector(store => store.manageAudit);
  const dispatch = useAppDispatch();

  if (!showQuestionRemovalOverride) {
    return null;
  }

  const confirmMessage = (
    <>
      <p>The changes you've made to the audit will cause one or more already-answered questions to be <u>removed</u> from the audit. </p>
      <p>If any of those questions have associated RWPs they will also be <u>removed</u>. <strong>This operation cannot be undone.</strong></p>
      <p>Do you want to proceed with the update?</p>
    </>
  );

  return (
    <ConfirmModal
      header="Confirm Changes"
      message={confirmMessage}
      onYesClicked={() => {
        dispatch(saveAudit({
          allowDeletingAnsweredQuestions: true,
        }));
        dispatch(dismissQuestionRemovalOverride());
      }}
      onNoClicked={() => dispatch(dismissQuestionRemovalOverride())}
    />
  );
};

export default ConfirmDeleteQuestionsAuditModal;