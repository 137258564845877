import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import React from "react";
import { Link } from "react-router-dom";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import DetailText from "shared/components/common/detail-text/DetailText";
import Spinner from "shared/components/common/spinner/Spinner";
import CircleImgButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import editIcon from "shared/media/dls/edit-1.svg";
import { formatTimestamp } from "shared/utilities/dateFormatters";
import { userToString } from "shared/utilities/userUtilities";
import { useAppSelector } from "store/store";
import AuditHierarchyDisplay from "./audit-hierarchy-display/AuditHierarchyDisplay";
import "./AuditDetailsCard.scoped.scss";

interface IAuditDetailsCardProps {
  cardStateId: string,
}

const AuditDetailsCard: React.FC<IAuditDetailsCardProps> = (props) => {
  const auditOp = useAppSelector(store => store.audit.loadAuditOp);
  const auditFromStore = useAppSelector(store => store.audit.audit);

  const isWorking = auditOp?.isWorking;
  const audit = auditOp?.isWorking
    ? undefined
    : auditFromStore;
  const errorMessage = auditOp?.isWorking
    ? undefined
    : auditOp?.errorMessage

  let cardBody: React.ReactNode;

  if (isWorking) {
    cardBody = (
      <Spinner />
    );
  } else if (errorMessage) {
    cardBody = (
      <Banner
        type={BannerType.error}
      >
        Failed to load audit details:<br />
        {errorMessage}
      </Banner>
    );
  } else {
    const topics = audit?.auditTopics.filter(x => x.level === 1);
    const subtopics = audit?.auditTopics.filter(x => x.level === 2);

    const ipmProjs = audit?.ipmProjects.filter(x => x.type === "IPMProject");
    const ipmAssets = audit?.ipmProjects.filter(x => x.type === "IPMAsset");

    cardBody = (
      <FlexRow
        className="content-row"
      >
        <FlexCol>
          {getLabel("Templates", joinNames(audit?.templates))}
          {getLabel("Sub Topic", joinNames(subtopics), namesToUL(subtopics))}
          {getLabel("Business Team (Basin Hierarchy)", sortAndFormatHierarchy(audit?.businessTeams), (
            <AuditHierarchyDisplay
              items={(audit?.businessTeams || []).map(x => ({ id: x.id, type: x.type, display: `${x.code} - ${x.name}` }))}
              typeSortOrder={{
                "GeoLevel1": 1,
                "Basin": 2,
                "GeoUnit": 3,
                "SubGeoUnit": 4,
              }}
            />
          ), true)}
          {getLabel("Lead Auditor", userToString(audit?.auditors.find(x => x.isLeader)))}
          {getLabel("IPM Asset", joinNames(ipmAssets), namesToUL(ipmAssets))}
        </FlexCol>
        <FlexCol>
          {getLabel("Location", joinNames(audit?.facilities), namesToUL(audit?.facilities))}
          {getLabel("Audit Group", audit?.auditGroup?.name)}
          {getLabel("Business Function (Functions)", sortAndFormatHierarchy(audit?.businessFunctions), (
            <AuditHierarchyDisplay
              items={(audit?.businessFunctions || []).map(x => ({ id: x.id, type: x.type, display: `${x.code} - ${x.name}` }))}
              typeSortOrder={{
                "FunctionCategory": 1,
                "Function": 2,
                "SubFunction": 3,
              }}
            />
          ), true)}
          {getLabel("Audit Type", audit?.auditType?.name)}
          {getLabel("IPM Project", joinNames(ipmProjs), namesToUL(ipmProjs))}
        </FlexCol>
        <FlexCol>
          {getLabel("Topic", joinNames(topics), namesToUL(topics))}
          {getLabel("Business View (Divisions)", sortAndFormatHierarchy(audit?.businessViews), (
            <AuditHierarchyDisplay
              items={(audit?.businessViews || []).map(x => ({ id: x.id, type: x.type, display: `${x.code} - ${x.name}` }))}
              typeSortOrder={{
                "Division": 1,
                "BusinessLine": 2,
                "SubBusinessLine": 3,
                "Support": 4,
              }}
            />), true)}
          {getLabel("Country", joinNames(audit?.countries), namesToUL(audit?.countries))}
          {getLabel("Mobile Site (Rig)", joinStrings(audit?.mobileSites.map(x => `${x.rigName} (${x.rigType})`)))}
          {getLabel("Supplier", joinNames(audit?.suppliers), namesToUL(audit?.suppliers))}
        </FlexCol>
        <FlexCol>
          {getLabel("Start Date", formatTimestamp(audit?.startDateTime))}
          {getLabel("End Date", formatTimestamp(audit?.endDateTime))}
          {getLabel("Completed Date", formatTimestamp(audit?.completionDateTime))}
          {getLabel("Closed Date", formatTimestamp(audit?.closureDateTime))}
        </FlexCol>
      </FlexRow>
    );
  }

  return (
    <Card
      showHeader={true}
      className="audit-details-card"
      cardStateId={props.cardStateId}
      defaultCollapsedState={false}
      headerElement={
        <FlexRow
          className="header-row"
        >
          <span className="title">
            Audit Details
          </span>

          {audit && (
            <Link
              to={formatRoute(UrlRoutes.EditAudit, { auditId: audit.id.toString() })}
              className="edit-button"
            >
              <CircleImgButton
                icon={editIcon}
                alt="Edit"
                type="secondary"
              />
            </Link>
          )}
        </FlexRow>
      }
    >
      {cardBody}
    </Card>
  );
};

export default AuditDetailsCard;

function getLabel(label: string, value: string | undefined, modalData?: React.ReactNode, isAlwaysClickable?: boolean) {
  return (
    <LabeledControl
      className="label-sub-content-hint"
      label={label}
    >
      <DetailText
        className="audit-detail-content"
        text={value || "-"}
        showModalOnClick
        isAlwaysClickable={isAlwaysClickable && !!value}
        modalData={{
          header: "label",
          content: modalData,
        }}
      />
    </LabeledControl>
  );
}

const sorter = (a: string, b: string) => a < b ? -1 : 1;

function joinStrings(items: string[] | undefined): string | undefined {
  return items
    ?.sort(sorter)
    .join(', ');
}

function joinNames(items: { name: string }[] | undefined): string | undefined {
  return joinStrings(items?.map(x => x.name));
}

function namesToUL(items: { name: string }[] | undefined): React.ReactNode {
  return items
    ? (
      <ul>
        {items
          .map(x => x.name)
          .sort(sorter)
          .map((x) => (
            <li key={x}>
              {x}
            </li>
          ))
        }
      </ul>
    ) : null;
}

function sortAndFormatHierarchy(items?: { code: string, name: string }[]): string {
  return items
    ?.map(x => `${x.code} - ${x.name}`)
    .sort((a, b) => a < b ? -1 : 1)
    .join("; ") || "";
}