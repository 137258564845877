/**
 * Given a number, will return either the singular or plural string provided.
 * 
 * @param count The number to check.
 * @param singular The singular version of the word.
 * @param plural The plural version of the word.
 */
export default function pluralize(count: number, singular: string, plural: string) {
  if (count === 1) {
    return singular;
  } else {
    return plural;
  }
}