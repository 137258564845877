import React from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import IVideoLinkItem from "../VideoLinkItem";
import VideoIcon from "../video-icon/VideoIcon";
import "./VideoLink.scoped.scss";

interface IVideoLinkProps {
  item: IVideoLinkItem,
}

const VideoLink: React.FC<IVideoLinkProps> = ({
  item,
}: IVideoLinkProps) => {
  return (
    <FlexRow
      className="video-link"
    >
      <VideoIcon
        size="2.5em"
        onClick={() => window.open(item.url, "_blank", "noopener noreferrer")}
      />

      <FlexCol
        className="video-details"
      >
        <a
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.title}
        </a>
        <span>{item.description}</span>
      </FlexCol>
    </FlexRow>
  );
};

export default VideoLink;