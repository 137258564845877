import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ErrorWithRetry from "shared/components/common/error-with-retry/ErrorWithRetry";
import Spinner from "shared/components/common/spinner/Spinner";
import { ILoadPickerItemsAction, ISetPickerSelectedItemsAction } from "shared/store/picker/pickerReducerHandlers";
import { IPickerItem, IPickerState } from "shared/types/pickerTypes";
import QISelect from "../select/QISelect";
import "./PickerDropdown.scoped.scss";

interface IPickerDropdownProps<T> {
  /**
   * The picker state from redux.
   */
  pickerState: IPickerState<T>,
  /**
   * Placeholder text to show when no items are selected. Defaults to "Select".
   */
  placeholder?: string,
  /**
   * Disables the picker so that the user cannot interact with it.
   */
  isDisabled?: boolean,
  /**
   * The tooltip shown when you hover the picker.
   */
  tooltip?: string,
  /**
   * The redux action to dispatch to load the picker items.
   * If this is not specified, a load action will never be dispatched.
   * In that case, items from the picker state will always be used instead.
   */
  loadAction?: ActionCreatorWithPayload<ILoadPickerItemsAction, string>,
  /**
   * The redux action to dispatch when the user selects an item.
   * If setSelectedItemsCallback is specified, that will be called instead.
   */
  setSelectedItemsAction?: ActionCreatorWithPayload<ISetPickerSelectedItemsAction<T>, string>,
  /**
   * A callback action to call when the user selects an item. If this is set,
   * then setSelectedItemsAction will never be used.
   */
  setSelectedItemsCallback?(selectedItems: IPickerItem<T>[]): void,
  /**
   * A function that will be called for each item to get the text to show in the dropdown.
   * If not specified, the item's text property will be used instead.
   */
  itemFormatter?(item: IPickerItem<T>): string,
  /**
   * If true, a load action will not be dispatched if there are any items already loaded. (Default: true)
   */
  preserveItems?: boolean,
  /**
   * If true, the picker will render red with red text if it has no selected items.
   */
  enableRequiredErrorDisplay?: boolean,
  /** Determines single/multiple selection mode. */
  selectMode?: "single" | "multiple",
}

const PickerDropdown = <T,>({
  pickerState: {
    key,
    items,
    selectedItems,
    loadOperation,
  },
  loadAction,
  setSelectedItemsAction,
  setSelectedItemsCallback,
  itemFormatter,
  isDisabled,
  tooltip,
  placeholder,
  preserveItems: preserveItemsProp,
  enableRequiredErrorDisplay,
  selectMode = "single",
}: IPickerDropdownProps<T>) => {
  const dispatch = useDispatch();

  const preserveItems = preserveItemsProp === true
    || preserveItemsProp === undefined;
  const itemsLength = items.length;
  const showLoading = !!loadOperation?.isWorking;
  const showError = !loadOperation?.isWorking
    && !!loadOperation?.errorMessage;

  useEffect(() => {
    if (loadAction
      && (!preserveItems
        || !itemsLength)
      && !showLoading
      && !showError) {
      dispatch(loadAction({
        pickerKey: key,
      }));
    }
  }, [loadAction, preserveItems, itemsLength, key, showLoading, showError, dispatch]);

  const onItemsSelected = (items?: IPickerItem<T>[]) => {
    let selItems = items?.slice() || [];

    if (selectMode === "single") {
      selItems = selItems.slice(0, 1);
    }

    if (setSelectedItemsCallback) {
      setSelectedItemsCallback(selItems);
    } else if (setSelectedItemsAction) {
      dispatch(setSelectedItemsAction({
        selectedItems: selItems,
        pickerKey: key,
      }));
    }
  };

  if (showError) {
    return (
      <ErrorWithRetry
        tooltip={loadOperation?.errorMessage}
        onRetryClicked={loadAction ?
          () => dispatch(loadAction({
            pickerKey: key,
          }))
          : undefined}
      />
    );
  } else if (showLoading) {
    return (
      <Spinner
        className="small-spinner"
        hAlign="left"
      />
    );
  } else {
    let hasRequiredError = false;

    if (enableRequiredErrorDisplay) {
      if (selectedItems.length === 0) {
        hasRequiredError = true;
      } else if (selectedItems.length === 1
        && selectedItems[0].key === "___picker_undefined__") {
        hasRequiredError = true;
      }
    }

    return (
      <QISelect
        items={items.map(x => ({
          id: x.key.toString(),
          text: (itemFormatter === undefined
            ? x.text
            : itemFormatter(x)) || "",
        }))}
        selectedItemIds={selectedItems.map(x => x.key.toString())}
        onChange={selectedItems =>
          onItemsSelected(items
            .filter(x => selectedItems
              .some(z => z.id.toString() === x.key.toString())))
        }
        selectMode={selectMode === "multiple"
          ? "multi"
          : "single"
        }
        allowClearButton
        className={hasRequiredError ? "required-error" : undefined}
        isDisabled={isDisabled}
      />
    );
  }
};

export default PickerDropdown;
