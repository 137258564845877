import AuditorApi from "api/auditor/AuditorApi";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { refreshGrid } from "store/grid/GridSlice";
import { RootState } from "store/store";
import { deleteAuditor, finishDeleteAuditor, finishSaveAuditor, startSaveAuditor, stopSaveAuditor } from "./AuditorSlice";
import { IAuditorDto } from "api/auditor/auditorTypes";

export default function* auditorSagas() {
  yield all([
    startSaveAuditorAsync(),
    deleteAuditorAsync(),
  ]);
}

function* startSaveAuditorAsync() {
  yield takeLatest(startSaveAuditor, function* () {
    const managerValues: IAuditorDto
      = yield select((store: RootState) => store.auditors.managerValues);


    if (!managerValues.user) {
      yield put(showErrorToast("Auditor is required."));
      yield put(stopSaveAuditor());
      return;
    }

    if (!managerValues.type) {
      yield put(showErrorToast("Auditor role is required."));
      yield put(stopSaveAuditor());
      return;
    }

    if (!managerValues.type) {
      yield put(showErrorToast("Reason For Manual Add is required."));
      yield put(stopSaveAuditor());
      return;
    }

    try {
      const opType = managerValues.id
        ? "update"
        : "create";

      yield call(AuditorApi.saveAuditor, managerValues);

      yield put(showSuccessToast(`Auditor ${opType}d successfully.`));
      yield put(finishSaveAuditor(true));
      yield put(refreshGrid({ gridId: "AuditorsGrid" }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishSaveAuditor(false));
    }
  });
}

function* deleteAuditorAsync() {
  yield takeLatest(deleteAuditor, function* (action) {
    try {
      yield call(AuditorApi.deleteAuditor, action.payload);
      yield put(showSuccessToast("Auditor deleted successfully."));
      yield put(finishDeleteAuditor(true));
      yield put(refreshGrid({ gridId: "AuditorsGrid" }));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishDeleteAuditor(false));
    }
  });
}