import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditTopicPicker from "shared/components/controls/pickers/AuditTopicPicker";
import BusinessFunctionPicker from "shared/components/controls/pickers/BusinessFunctionPicker";
import BusinessTeamPicker from "shared/components/controls/pickers/BusinessTeamPicker";
import BusinessViewPicker from "shared/components/controls/pickers/BusinessViewPicker";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { applyModalFilterValues, clearModalFilterValues, setModalFilterValues, toggleModal } from "store/question-library-filters/QuestionLibraryFiltersSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const MoreQuestionLibraryFiltersModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMoreFiltersOpen = useAppSelector(store => store.questionLibraryFilters.isMoreFiltersOpen);
  const modalFilters = useAppSelector(store => store.questionLibraryFilters.modalFilters);

  const closeModal = () => {
    dispatch(toggleModal(false));
  };

  return (
    <Modal
      header="More Filters"
      isOpen={isMoreFiltersOpen}
      showCloseButton
      onCloseButtonClicked={closeModal}
      bottomLeftNode={
        <button
          className="secondary"
          onClick={() => dispatch(clearModalFilterValues())}
        >
          Clear
        </button>
      }
      buttons={[
        {
          key: "cancel",
          text: "Cancel",
          className: "secondary",
          onClick: closeModal,
        },
        {
          key: "apply",
          text: "Apply",
          className: "primary",
          onClick: () => dispatch(applyModalFilterValues()),
        },
      ]}
    >
      <FlexCol>
        <LabeledControl label="Business Team (Basin Hierarchy)">
          <BusinessTeamPicker
            onApply={items => dispatch(setModalFilterValues({
              businessTeams: items,
            }))}
            selectedItems={modalFilters.businessTeams}
            allowMultiselect
          />
        </LabeledControl>

        <LabeledControl label="Business View (Division Hierarchy)">
          <BusinessViewPicker
            onApply={items => dispatch(setModalFilterValues({
              businessViews: items,
            }))}
            selectedItems={modalFilters.businessViews}
            allowMultiselect
          />
        </LabeledControl>

        <LabeledControl label="Business Function (Function Hierarchy)">
          <BusinessFunctionPicker
            onApply={items => dispatch(setModalFilterValues({
              businessFunctions: items,
            }))}
            selectedItems={modalFilters.businessFunctions}
            allowMultiselect
          />
        </LabeledControl>

        <LabeledControl label="Audit Topic">
          <AuditTopicPicker
            onApply={items => dispatch(setModalFilterValues({
              auditTopics: items,
            }))}
            selectedItems={modalFilters.auditTopics}
            allowMultiselect
          />
        </LabeledControl>
      </FlexCol>
    </Modal>
  )
};

export default MoreQuestionLibraryFiltersModal;