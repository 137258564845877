export enum CommentType {
    HighLights = "HighLights",
    LowLights = "LowLights",
    GeneralComments = "GeneralComments"
}

export interface ITopicComment {
    id: number,
    auditId: number,
    auditTopicId: number,
    associationId: number|undefined,
    commentType: CommentType,
    commentText: string,
    commentHtmlText: string,
    createdBy: string,
}
