import { createSlice } from "@reduxjs/toolkit";
import actionItemReducers, { IAuditActionItemState, initialAuditActionItemsState } from "./reducers/actionItemReducers";
import auditInfoReducers, { IAuditInfoState, initialAuditInfoState } from "./reducers/auditInfoReducers";
import confirmCreateManualAuditModalReducers, { IConfirmCreateManualAuditModalState, initialConfirmCreateManualAuditModalState } from "./reducers/confirmCreateManualAuditModalReducers";
import exportAuditReducers, { IAuditExportState, initialAuditExportState } from "./reducers/exportAuditReducers";
import findingReducers, { IFindingState, initialFindingState } from "./reducers/findingReducers";
import questionReducers, { IAuditQuestionState, initialAuditQuestionState } from "./reducers/questionReducers";
import saveChangesReducer, { IAuditSaveChangesState, initialAuditSaveChangesState } from "./reducers/saveChangesReducers";
import scoresReducers, { IAuditScoresState, initialAuditScoresState } from "./reducers/scoreReducers";
import auditSharedReducers, { IAuditSharedReducersState, initialAuditSharedReducers } from "./reducers/sharedReducers";
import statusReducers, { IAuditStatusState, initialAuditStatusState } from "./reducers/statusReducers";

export type IAuditState =
  IAuditSaveChangesState
  & IAuditActionItemState
  & IAuditInfoState
  & IAuditQuestionState
  & IAuditStatusState
  & IAuditExportState
  & IAuditSharedReducersState
  & IConfirmCreateManualAuditModalState
  & IFindingState
  & IAuditScoresState;


const initialState: IAuditState = {
  ...initialAuditInfoState,
  ...initialAuditQuestionState,
  ...initialAuditStatusState,
  ...initialAuditActionItemsState,
  ...initialAuditExportState,
  ...initialAuditSaveChangesState,
  ...initialAuditSharedReducers,
  ...initialConfirmCreateManualAuditModalState,
  ...initialFindingState,
  ...initialAuditScoresState,
};

export const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    ...auditInfoReducers,
    ...actionItemReducers,
    ...exportAuditReducers,
    ...questionReducers,
    ...saveChangesReducer,
    ...statusReducers,
    ...auditSharedReducers,
    ...confirmCreateManualAuditModalReducers,
    ...findingReducers,
    ...scoresReducers,
  },
});

export const {
  // Shared Actions.
  loadAllData,
  finishLoadAllData,

  // Status change Actions.
  saveChanges,
  finishSaveChanges,

  // Audit Info Actions.
  loadAudit,
  finishLoadAudit,
  setAudit,

  // Status Actions.
  setConfirmAuditStatusChangeModal,
  confirmAuditStatusChange,
  finishUpdateAuditStatus,

  // Revert Status
  setConfirmAuditRevertStatusModal,
  confirmRevertAuditStatus,

  // Question Actions.
  loadQuestions,
  finishLoadQuestions,
  assignAuditorToQuestions,
  setFocusedGuidance,
  setEvidenceModal,
  setQuestionEvidenceItem,
  removeQuestionEvidence,
  setQuestion,

  // Action Items.
  loadActionItems,
  finishLoadActionItems,
  setActionItem,
  handleActionItemChanged,
  setActionItemListModal,
  setManageActionItemModal,
  removeAllActionsByLink,

  // Export audit.
  exportAudit,
  finishExportAudit,

  // Manage Confirmation Create Audit Modal
  closeCreateManualAuditModal,
  openConfirmCreateManualAuditModal,

  // Findings
  openFindingModal,
  closeFindingModal,
  onSetFinding,
  onRemoveFinding,
  setCurrentFindingProperties,
  startFindingOp,
  finishFindingOp,
  unlinkFindingInState,
  setFindingModalMode,

  // Scores.
  loadScores,
  finishLoadScores,
} = auditSlice.actions;