import { ReactPlugin, useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Component, ErrorInfo, ReactNode } from "react";
import Login from "shared/components/auth/Login";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { v4 as uuidV4 } from "uuid";
import Banner, { BannerType } from "../banner/Banner";

interface IQoFErrorBoundaryProps {
  isFullPage: boolean,
  children: ReactNode;
}

const QoFErrorBoundary: React.FC<IQoFErrorBoundaryProps> = ({
  isFullPage,
  children,
}) => {
  const appInsights = useAppInsightsContext();

  return (
    <QoFErrorBoundaryInternal
      isFullPage={isFullPage}
      appInsightsContext={appInsights}
    >
      {children}
    </QoFErrorBoundaryInternal>
  );
};

export default QoFErrorBoundary;

interface IQoFErrorBoundaryInternalProps {
  isFullPage: boolean;
  appInsightsContext: ReactPlugin;
  children: ReactNode;
}

interface IQoFErrorBoundaryInternalState {
  error: Error | undefined;
  errorUUid: string,
}

class QoFErrorBoundaryInternal extends Component<IQoFErrorBoundaryInternalProps, IQoFErrorBoundaryInternalState> {
  public state: IQoFErrorBoundaryInternalState = {
    error: undefined,
    errorUUid: "",
  };

  public static getDerivedStateFromError(err: Error): IQoFErrorBoundaryInternalState {
    const errorUUid = uuidV4();
    (err as any).uuid = errorUUid;

    // Update state so the next render will show the fallback UI.
    return { error: err, errorUUid, };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);

    if (this.props.appInsightsContext) {
      const errorUUId = (error as any).uuid;

      this.props.appInsightsContext.trackException({
        error,
        severityLevel: SeverityLevel.Error,
      }, {
        uuid: errorUUId,
      });
    }
  }

  public render() {
    if (this.state.error) {
      const errString = [
        this.state.errorUUid,
        getResponseErrorMessage(this.state.error.message),
      ].join(": ");

      if (this.props.isFullPage) {
        return (
          <Login
            error
            message={
              <>
                <h1>QI has encountered a fatal error. Please refresh the page to continue.</h1>
                <h3>If this error persists, please contact an administrator with the following details:</h3>
                <h6>{errString}</h6>
              </>
            }
          />
        );
      } else {
        return (
          <Banner
            type={BannerType.error}
          >
            QI has encountered a fatal error. Please refresh the page to continue.<br />
            If this error persists, please contact an administrator with the following details:<br />
            {errString}
          </Banner>
        )
      }
    }

    return this.props.children;
  }
}