import React from "react";
import BCIcon from "shared/media/icons/audit-scheduler/audit-compliance/basic-compliance.svg";
import FCIcon from "shared/media/icons/audit-scheduler/audit-compliance/full-compliance.svg";
import NCIcon from "shared/media/icons/audit-scheduler/audit-compliance/non-compliance.svg";
import { ComplianceResults } from "types/auditPlanningTypes";
import "./SchedulerAuditCompliance.scoped.scss";

interface ISchedulerAuditComplianceProps {
  compliance: ComplianceResults,
}

const complianceShorthands = {
  [ComplianceResults.HighNonCompliance]: "HNC",
  [ComplianceResults.NonCompliance]: "NC",
  [ComplianceResults.BasicCompliance]: "BC",
  [ComplianceResults.FullCompliance]: "FC",
  [ComplianceResults.HighCompliance]: "HC",
};

const icons = {
  [ComplianceResults.HighNonCompliance]: NCIcon,
  [ComplianceResults.NonCompliance]: NCIcon,
  [ComplianceResults.BasicCompliance]: BCIcon,
  [ComplianceResults.FullCompliance]: FCIcon,
  [ComplianceResults.HighCompliance]: FCIcon,
};

const SchedulerAuditCompliance: React.FC<ISchedulerAuditComplianceProps> = ({
  compliance,
}) => (
  <div
    className={`compliance-tag ${compliance}`}
  >
    <img
      src={icons[compliance]}
      alt=""
      className="icon-small-compliance"
    />

    <span>
      {complianceShorthands[compliance]}
    </span>
  </div>
);

export default SchedulerAuditCompliance;