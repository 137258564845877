import config from "config";
import React from "react";
import Card from "shared/components/layout/card/Card";
import DataGrid from "shared/components/layout/grid/DataGrid";
import FittedPage from "shared/components/layout/page/fitted-page/FittedPage";

const LegacyNodeToQoFMapping: React.FC = () => {
  return (
    <FittedPage
      title="Legacy Node Mapping"
    >
      <Card>
        <DataGrid
          id="LegacyNodeQoFMapping"
          dataSource="OData"
          dataUrl={config.endpoints.legacyQuest.legacyNodeMappings}
          showColumnChooser={true}
          gridColumns={[{
            uid: "legacyNodeId",
            field: "legacyNodeId",
            headerText: "Node Id",
            width: 120,
            template: x => x.legacyNodeId.toString(),
            type: "number",
          },
          {
            uid: "legacyNodeName",
            field: "legacyNodeName",
            headerText: "Node Name",
            width: 200,
            type: "string",
          },
          {
            uid: "facilityCommonId",
            field: "facilityCommonId",
            headerText: "Common Id",
            width: 150,
            template: x => x.facilityCommonId.toString(),
            type: "number",
          },
          {
            uid: "qofFacilityId",
            field: "qofFacilityId",
            headerText: "QI Facility Id",
            width: 150,
            visible: false,
            template: x => x.qofFacilityId.toString(),
            type: "number",
          },
          {
            uid: "facilityName",
            field: "facilityName",
            headerText: "Facility",
            width: 200,
            type: "string",
          },
          {
            uid: "facilityCountry",
            field: "facilityCountry",
            headerText: "Country",
            width: 150,
            type: "string",
          },
          {
            uid: "businessViews",
            field: "businessViews",
            headerText: "Business Views",
            width: 400,
            type: "string",
          },
          ]}
        />
      </Card>
    </FittedPage>
  );
}

export default LegacyNodeToQoFMapping;