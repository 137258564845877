import { usePrompt } from "shared/utilities/hooks/usePrompt";

interface IPromptProps {
  isDirty: boolean,
  message?: string,
}

/**
 * Shows a prompt if the user attempts to navigate away from the current route if there are unsaved changes.
 */
const Prompt: React.FC<IPromptProps> = ({
  isDirty,
  message,
}) => {
  usePrompt(message || "Your unsaved changes will be lost if you continue.", isDirty);
  return null;
}

export default Prompt;