import { Action } from "redux";

export interface IConfirmCreateManualAuditModalState {
  isConfirmCreateManualAuditModalOpen: boolean,
}

export const initialConfirmCreateManualAuditModalState: IConfirmCreateManualAuditModalState = {
  isConfirmCreateManualAuditModalOpen: false,
};

const confirmCreateManualAuditModalReducers = {

  closeCreateManualAuditModal: (state: IConfirmCreateManualAuditModalState, action: Action) => {
    state.isConfirmCreateManualAuditModalOpen = false;
  },

  openConfirmCreateManualAuditModal: (state: IConfirmCreateManualAuditModalState, action: Action) => {
    state.isConfirmCreateManualAuditModalOpen = true;
  },
};

export default confirmCreateManualAuditModalReducers;
