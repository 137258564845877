import { formatAuditReportTopic, formatAuditTopic } from "api/masterdata/AuditTopicApi";
import { formatCLMDocRef } from "api/masterdata/formatters/clmFormatters";
import { formatApi, formatAuditGroup, formatAuditType, formatAuditorType, formatBusinessFunction, formatBusinessTeam, formatBusinessView, formatCausalFactor, formatClmWhomToCheck, formatCountry, formatFacility, formatHSESubElement, formatIPMProject, formatMobileSite, formatQMSElement, formatSupplier, formatVerificationMethod } from "api/masterdata/formatters/masterDataFormatters";
import { formatAuditComplianceScoreIncludedQuestions, formatAuditPlanDimensionType } from "api/planning/auditPlanningFormatters";
import { parseStringToDate } from "shared/utilities/dateFormatters";
import { formatItemsArr } from "shared/utilities/formatterUtilities";
import { ComplianceResults } from "types/auditPlanningTypes";
import { ITopicComment } from "types/auditReportTypes";
import { AuditReportOutputTypes, AuditReportSectionAttachmentTypes, AuditReportSections, AuditReportTypes, AuditStatuses, IAudit, IAuditComments, IAuditComplianceScore, IAuditEvidenceItem, IAuditEvidenceResponse, IAuditInfo, IAuditQuestion, IAuditQuestionResponse, IAuditReport, IAuditReportGeneration, IAuditReportSection, IAuditReportSectionAttachment, IAuditTopicAuditee, IAuditee, IAuditor, ICreateAuditResponse, IEvidenceLinkItem, ISelection, ITopicSummary, IUserSelection } from "types/auditingTypes";
import { IIdNameObject } from "types/commonTypes";
import { formatAzureADUser } from "../../users/formatters/userFormatter";
import { formatOwnerGroup } from "../OwnerGroupsApi";
import { formatTemplate } from "../templates/formatters/templateFormatters";
import { IGetAuditComplianceScoresRequest, IUpdateNonComplianceResponsesRequest } from "../types/auditApiTypes";

export function formatAudit(obj: any): IAudit {
  return {
    id: obj.id,
    name: obj.name,
    recap: obj.recap,
    startDateTime: new Date(obj.startDate).getTime(),
    endDateTime: new Date(obj.endDate).getTime(),
    auditGroup: obj.auditGroup
      ? formatAuditGroup(obj.auditGroup)
      : undefined,
    templates: Array.isArray(obj.templates)
      ? obj.templates.map((x: any) => formatTemplate(x))
      : [],
    auditType: obj.auditType
      ? formatAuditType(obj.auditType)
      : undefined,
    status: formatAuditStatus(obj.status),
    includeAllAPIQ2Questions: obj.includeAllAPIQ2Questions === true,
    leadAuditorEmail: obj.leadAuditorEmail,
    leadAuditorName: obj.leadAuditorName,
    businessViews: formatItemsArr(obj.businessViews, formatBusinessView),
    businessFunctions: formatItemsArr(obj.businessFunctions, formatBusinessFunction),
    businessTeams: formatItemsArr(obj.businessTeams, formatBusinessTeam),
    countries: formatItemsArr(obj.countries, formatCountry),
    facilities: formatItemsArr(obj.facilities, formatFacility),
    ownerGroups: formatItemsArr(obj.ownerGroups, formatOwnerGroup),
    auditTopics: formatItemsArr(obj.auditTopics, formatAuditTopic),
    mobileSites: formatItemsArr(obj.mobileSites, formatMobileSite),
    ipmProjects: formatItemsArr(obj.ipmProjects, formatIPMProject),
    suppliers: formatItemsArr(obj.suppliers, formatSupplier),
  };
}

export function formatAuditInfo(obj: any): IAuditInfo {
  return {
    id: obj.id,
    name: obj.name,
    recap: obj.recap,
    createdOnTime: new Date(obj.createdOn).getTime(),
    modifiedOnTime: new Date(obj.modifiedOn).getTime(),
    startDateTime: new Date(obj.startDate).getTime(),
    endDateTime: new Date(obj.endDate).getTime(),
    auditGroup: obj.auditGroup
      ? formatAuditGroup(obj.auditGroup)
      : undefined,
    templates: formatItemsArr(obj.templates, formatTemplate),
    auditType: obj.auditType
      ? formatAuditType(obj.auditType)
      : undefined,
    status: formatAuditStatus(obj.status),
    businessViews: formatItemsArr(obj.businessViews, formatBusinessView),
    businessFunctions: formatItemsArr(obj.businessFunctions, formatBusinessFunction),
    businessTeams: formatItemsArr(obj.businessTeams, formatBusinessTeam),
    countries: formatItemsArr(obj.countries, formatCountry),
    facilities: formatItemsArr(obj.facilities, formatFacility),
    ownerGroups: formatItemsArr(obj.ownerGroups, formatOwnerGroup),
    auditTopics: formatItemsArr(obj.topics, formatAuditTopic),
    mobileSites: formatItemsArr(obj.mobileSites, formatMobileSite),
    ipmProjects: formatItemsArr(obj.ipmProjects, formatIPMProject),
    suppliers: formatItemsArr(obj.suppliers, formatSupplier),
    auditees: formatItemsArr(obj.auditees, formatAuditAuditee),
    auditors: formatItemsArr(obj.auditors, formatAuditAuditor),
    closureDateTime: obj.closureDate
      ? new Date(obj.closureDate).getTime()
      : undefined,
    completionDateTime: obj.completionDate
      ? new Date(obj.completionDate).getTime()
      : undefined,
    evidence: Array.isArray(obj.evidence)
      ? obj.evidence.map(formatAuditEvidenceItem)
      : [],
    links: Array.isArray(obj.links)
      ? obj.links.map(formatAuditEvidenceItem)
      : [],
    auditTopicAuditees: Array.isArray(obj.auditTopicAuditees)
      ? obj.auditTopicAuditees.map(formatAuditTopicAuditee)
      : [],
  };
}

export function formatAuditStatus(status: any): AuditStatuses {
  if (!Object.values(AuditStatuses).includes(status)) {
    throw Error(`Unrecognized status (${status}) while formatting Audit.`);
  }

  return (AuditStatuses as any)[status];
}

export function formatComplianceResult(status: any): ComplianceResults {
  if (!Object.values(ComplianceResults).includes(status)) {
    throw Error(`Unrecognized status (${status}) while formatting Audit.`);
  }

  return (ComplianceResults as any)[status];
}

export function formatAuditAuditee(obj: any): IAuditee {
  const summary: IAuditee = {
    name: obj.name,
    email: obj.email,
    isLeader: obj.type === "LeadAuditee",
  };

  return summary;
}

export function formatAuditTopicAuditee(obj: any): IAuditTopicAuditee {
  return {
    auditTopicId: Number(obj.auditTopicId),
    auditeeEmail: obj.auditeeEmail,
  };
}

export function formatAuditReport(obj: any): IAuditReport {
  const report: IAuditReport = {
    id: obj.id,
    auditComment: !obj.auditComment
      ? {
        closingStatement: "",
        highLights: "",
        lowlights: "",
        notes: "",
        wayforward: "",
        closingStatementHTML: "",
        highLightsHTML: "",
        lowlightsHTML: "",
        wayforwardHTML: "",
      } : formatAuditComment(obj.auditComment),
    recap: obj.recap,
    name: obj.name,
    status: formatAuditStatus(obj.status),
    templates: formatItemsArr(obj.templates, formatTemplate),
    facilities: formatItemsArr(obj.facilities, formatFacility),
    topics: formatItemsArr(obj.topics, formatAuditTopic),
    countries: formatItemsArr(obj.countries, formatCountry),
    businessFunctions: formatItemsArr(obj.businessFunctions, formatBusinessFunction),
    basins: formatItemsArr(obj.basins, formatBusinessTeam),
    subTopics: formatItemsArr(obj.subTopics, formatAuditTopic),
    businessLines: formatItemsArr(obj.businessLines, formatBusinessView),
    auditType: obj.auditType === null
      ? undefined
      : formatAuditType(obj.auditType),
    auditorType: !obj.auditorType
      ? undefined
      : formatAuditorType(obj.auditorType),
    startDateTime: new Date(obj.startDate).getTime(),
    endDateTime: new Date(obj.endDate).getTime(),
    auditors: formatItemsArr(obj.auditors, formatAuditAuditor),
    hasOpenActionItems: obj.hasOpenActionItems === true,
    questions: formatItemsArr(obj.questions, formatAuditQuestion),
    reportSummaryDtos: formatItemsArr(obj.reportSummaryDtos, formatAuditReportTopic),
    auditTopicComment: formatItemsArr(obj.auditTopicComment, formatTopicComment),
  };

  return report;
}

export function formatAuditComment(obj: any): IAuditComments {
  return {
    closingStatement: obj.closingStatement,
    lowlights: obj.lowlights,
    highLights: obj.highLights,
    wayforward: obj.wayforward,
    closingStatementHTML: obj.closingStatementHTML,
    lowlightsHTML: obj.lowlightsHTML,
    highLightsHTML: obj.highLightsHTML,
    wayforwardHTML: obj.wayforwardHTML,
    notes: obj.notes
  }
}

export function formatTopicComment(obj: any): ITopicComment {
  return {
    id: obj.id,
    auditId: obj.auditId,
    auditTopicId: obj.auditTopicId,
    associationId: obj.associationId,
    commentType: obj.commentType,
    commentText: obj.commentText,
    commentHtmlText: obj.commentHtmlText,
    createdBy: obj.createdBy,
  }
}

export function formatAuditAuditor(obj: any): IAuditor {
  return {
    email: obj.email,
    name: obj.name,
    isLeader: obj.isLeader,
  };
}

export function formatAuditQuestion(obj: any): IAuditQuestion {
  return {
    auditQuestionId: Number(obj.auditQuestionId),
    questionId: Number(obj.questionId),
    questionNumber: Number(obj.questionNumber),
    question: obj.question?.toString(),
    guidance: obj.guidance?.toString(),
    guidanceHTML: obj.guidanceHTML?.toString(),
    isGlobal: Boolean(obj.isGlobal),
    recommendedRWP: obj.recommendedRWP?.toString(),
    allowNA: Boolean(obj.allowNA),
    interviewees: Array.isArray(obj.interviewees)
      ? formatItemsArr(obj.interviewees, formatAzureADUser)
      : [],
    auditorEmail: obj.auditorEmail?.toString(),
    auditorName: obj.auditorName?.toString(),
    topicId: Number(obj.topicId),
    topicName: obj.topicName?.toString(),
    scoringSystem: obj.scoringSystem?.toString(),
    category: obj.category?.toString(),
    licenseToOperate: Boolean(obj.licenseToOperate),
    evidence: formartEvidenceLinkResponse(
      Array.isArray(obj.evidence) ? obj.evidence : [],
      Array.isArray(obj.links) ? obj.links : []),
    responses: Array.isArray(obj.responses)
      ? obj.responses.map(formatAuditQuestionResponse)
      : [],
    selectedVerificationMethods: Array.isArray(obj.selectedVerificationMethods)
      ? obj.selectedVerificationMethods.map(formatVerificationMethod)
      : [],
    verificationMethods: Array.isArray(obj.verificationMethods)
      ? obj.verificationMethods.map(formatVerificationMethod)
      : [],
    subTopics: Array.isArray(obj.subTopics)
      ? formatItemsArr(obj.subTopics, formatAuditTopic)
        .sort((a, b) => a.name < b.name ? -1 : 1)
      : [],
    countries: Array.isArray(obj.countries)
      ? obj.countries.map(formatCountry)
      : [],
    businessViews: Array.isArray(obj.businessViews)
      ? obj.businessViews.map(formatBusinessView)
      : [],
    businessFunctions: Array.isArray(obj.businessFunctions)
      ? obj.businessFunctions.map(formatBusinessFunction)
      : [],
    businessTeams: Array.isArray(obj.businessTeams)
      ? obj.businessTeams.map(formatBusinessTeam)
      : [],
    apis: Array.isArray(obj.apis)
      ? obj.apis.map(formatApi)
      : [],
    templates: Array.isArray(obj.templates)
      ? obj.templates.map(formatTemplate)
      : [],
    hseSubElement: obj.hseSubElement
      ? formatHSESubElement(obj.hseSubElement)
      : undefined,
    clause: obj.clause?.toString(),
    qmsElements: Array.isArray(obj.qmsElements)
      ? obj.qmsElements.map(formatQMSElement)
      : [],
    clmDocRefs: Array.isArray(obj.clmDocRefs)
      ? obj.clmDocRefs.map(formatCLMDocRef)
      : [],
    clmWhomToCheck: Array.isArray(obj.clmWhomToCheck)
      ? obj.clmWhomToCheck.map(formatClmWhomToCheck)
      : [],
  };
}

export function formatAuditQuestionResponse(obj: any): IAuditQuestionResponse {
  return {
    id: Number(obj.id),
    auditQuestionId: Number(obj.auditQuestionId),
    auditStatus: formatAuditStatus(obj.auditStatus),
    answer: obj.answer?.toString()?.toUpperCase(),
    causalFactor: obj.causalFactor
      ? formatCausalFactor(obj.causalFactor)
      : undefined,
    notes: obj.notes?.toString() || "",
    createdOnTimestamp: parseStringToDate(obj.createdOn).getTime(),
    createdBy: obj.createdBy?.toString() || "",
    modifiedOnTimestamp: parseStringToDate(obj.modifiedOn).getTime(),
    modifiedBy: obj.modifiedBy?.toString() || "",
    isOutOfScope: Boolean(obj.isOutOfScope),
  };
}

export function formatAuditReportSectionAttachmentType(ansType: any): AuditReportSectionAttachmentTypes {
  let parsed = AuditReportSectionAttachmentTypes[ansType as keyof typeof AuditReportSectionAttachmentTypes];

  if (!parsed) {
    throw Error(`Unrecognized AuditReportSectionAttachmentType: ${ansType}.`);
  }

  return parsed;
}

export function formatEvidenceItem(obj: any): IEvidenceLinkItem {
  return {
    id: Number(obj.id),
    info: obj.filename,
    description: obj.notes,
    auditStatus: formatAuditStatus(obj.auditStatus),
    type: "Evidence"
  };
}

export function formatLinkItem(obj: any): IEvidenceLinkItem {
  return {
    id: Number(obj.id),
    info: obj.link,
    description: obj.notes,
    auditStatus: formatAuditStatus(obj.auditStatus),
    type: "Link"
  };
}

export function formartEvidenceLinkResponse(objEvidence: any[], objLink: any[]): IEvidenceLinkItem[] {
  let items: IEvidenceLinkItem[] = [];

  objEvidence.forEach(x => {
    items.push({
      id: x.id,
      info: x.filename,
      description: x.notes,
      auditStatus: x.auditStatus,
      type: "Evidence"
    });
  });

  objLink.forEach(x => {
    items.push({
      id: x.id,
      info: x.link,
      description: x.notes,
      auditStatus: x.auditStatus,
      type: "Link"
    });
  });

  return items;
}

export function formatAuditEvidenceResponse(obj: any): IAuditEvidenceResponse {
  return {
    auditId: Number(obj?.auditId),
    auditStatus: formatAuditStatus(obj?.auditStatus),
    attachments: Array.isArray(obj?.evidences)
      ? obj?.evidences.map((x: any) => formatAuditEvidenceItem(x))
      : [],
    links: Array.isArray(obj?.links)
      ? obj?.links.map((x: any) => formatAuditEvidenceItem(x))
      : [],
  }
}

export function formatAuditEvidenceItem(obj: any): IAuditEvidenceItem {
  return {
    auditId: Number(obj?.auditId),
    id: Number(obj?.id),
    parent: obj?.type === "Audit"
      ? "Audit"
      : obj?.parent?.toString() || "",
    description: obj?.description?.toString() || "",
    filename: obj?.filename?.toString(),
    size: obj?.size
      ? Number(obj?.size)
      : undefined,
    uploadedBy: obj?.uploadedBy?.toString(),
    uploadDate: obj?.date
      ? parseStringToDate(obj.date)
      : undefined,
    parentId: obj?.parentId
      ? Number(obj.parentId)
      : undefined,
    url: obj?.url?.toString(),
    auditStatus: formatAuditStatus(obj.linkStatus || obj.evidenceStatus),
    type: obj?.type,
    auditQuestionId: obj?.auditQuestionId,
  }
}

export function formatIdNameObject(obj: any): IIdNameObject {
  return {
    id: obj.id,
    name: obj.name,
  };
}

export function formatSaveAuditRequest(audit: IAudit, allowDeletingAnsweredQuestions: boolean): any {
  return {
    id: audit.id
      ? audit.id
      : undefined,
    businessViewIds: audit.businessViews.map(x => x.id),
    businessFunctionIds: audit.businessFunctions.map(x => x.id),
    businessTeamIds: audit.businessTeams.map(x => x.id),
    countryIds: audit.countries.map(x => x.id),
    facilityIds: audit.facilities.map(x => x.id),
    ownerGroupIds: audit.ownerGroups.map(x => x.id),
    auditTopicIds: audit.auditTopics.map(x => x.id),
    auditTemplateIds: audit.templates.map(x => x.id),
    auditGroupId: audit.auditGroup?.id,
    startDate: new Date(audit.startDateTime),
    endDate: new Date(audit.endDateTime),
    auditTypeId: audit.auditType?.id,
    name: audit.name,
    recap: audit.recap,
    includeAllAPIQ2Questions: audit.includeAllAPIQ2Questions,
    leadAuditorEmail: audit.leadAuditorEmail,
    leadAuditorName: audit.leadAuditorName,
    allowDeletingAnsweredQuestions,
    mobileSiteIds: audit.mobileSites.map(x => x.id),
    ipmProjectIds: audit.ipmProjects.map(x => x.id),
    supplierIds: audit.suppliers.map(x => x.id),
  };
}

export function formatUpdateNonConformancesRequest(obj: IUpdateNonComplianceResponsesRequest): any {
  return obj.auditQuestionIds.map(aqi => ({
    auditQuestionId: aqi,
    newAnswer: obj.newAnswer,
    newNotes: obj.newNotes,
  }));
}

export function formatAuditReportSectionAttachment(obj: any): IAuditReportSectionAttachment {
  return {
    id: Number(obj.id),
    auditId: Number(obj.auditId),
    auditQuestionId: Number(obj.auditQuestionId),
    type: formatAuditReportSectionAttachmentType(obj.type),
    filename: obj.filename,
    description: obj.description,
  }
}

export function formatGenerateAuditReportRequest(obj: IAuditReportGeneration): IUserSelection {

  let userSelection: IUserSelection = {
    auditReportType: getReportType(obj.reportType),
    auditReportExtensionType: getReportExtensionType(obj.outputType),
    selections: getSelections(obj.includedSections),
    topicSummary: getTopics(obj.includedSections)
  };

  return userSelection;
}

function getReportType(auditReportType: AuditReportTypes): number {
  switch (auditReportType) {
    case AuditReportTypes.OpeningMeeting: return 0;
    case AuditReportTypes.ClosingMeeting: return 1;
    default: return -1;
  }
}

function getReportExtensionType(auditReportOutputType: AuditReportOutputTypes): number {
  switch (auditReportOutputType) {
    case AuditReportOutputTypes.Word: return 0;
    case AuditReportOutputTypes.PowerPoint: return 1;
    default: return -1;
  }
}

function getPageType(auditReportSectionType: AuditReportSections): number {
  switch (auditReportSectionType) {
    case AuditReportSections.AuditTeams: return 2;
    case AuditReportSections.AuditScope: return 3;
    case AuditReportSections.AuditSchedule: return 4;
    case AuditReportSections.LocalKnowledge: return 5;
    case AuditReportSections.Findings: return 6;
    case AuditReportSections.FindingsDetails: return 7;
    case AuditReportSections.ResultsSummary: return 8;
    case AuditReportSections.TopicSummary: return 9;
    case AuditReportSections.Highlights: return 10;
    case AuditReportSections.OpportunitiesForImprovement: return 11;
    case AuditReportSections.RWPSummary: return 12;
    case AuditReportSections.ClosingStatements: return 13;
    case AuditReportSections.WayForward: return 14;
    default: return -1;
  }
}

function getSelections(sections: IAuditReportSection[]): ISelection[] {
  let data: ISelection[] = [];
  let sectionsSelected = sections.filter((x) => x.section !== AuditReportSections.SpecificTopicSummary);

  if (sectionsSelected.length > 0) {
    sectionsSelected.forEach((sel) => {
      data.push(
        {
          name: sel.section,
          selected: true,
          pageType: getPageType(sel.section),
          url: sel.attachments?.map((att) => getBlobUrl(att)) ?? []
        });
    });
  }
  let topicSelected = sections.filter((x) => x.section === AuditReportSections.SpecificTopicSummary);
  if (topicSelected.length > 0
    && !sectionsSelected.some((x) => x.section === AuditReportSections.TopicSummary)) {
    data.push(
      {
        name: AuditReportSections.TopicSummary,
        selected: true,
        pageType: getPageType(AuditReportSections.TopicSummary),
        url: []
      });
  }
  return data.sort((a, b) => a.pageType - b.pageType);
}

function getTopics(sections: IAuditReportSection[]): ITopicSummary[] {
  const topicsSelected = sections.filter((x) => x.section === AuditReportSections.SpecificTopicSummary);
  let data: ITopicSummary[] = [];
  if (topicsSelected.length > 0) {

    topicsSelected.forEach((topic) => {
      data.push({
        id: topic.topicId,
        selected: true,
        url: topic.attachments?.map((att) => getBlobUrl(att)) ?? [],
      });

    });
  }
  return data;
}

function getBlobUrl(attachment: IAuditReportSectionAttachment): string {
  const evidenceFolder = "/evidence";
  const fileName = `/${attachment.filename}`;
  let questionInfo = "";

  if (attachment.auditQuestionId
    && attachment.auditQuestionId > 0) {
    questionInfo = `/${attachment.auditQuestionId}`;
  }

  return `${attachment.auditId}${evidenceFolder}${questionInfo}${fileName}`;
}

export function formatSaveAuditCommentRequest(auditComment: IAuditComments) {
  return {
    closingStatement: auditComment.closingStatement,
    lowlights: auditComment.lowlights,
    highLights: auditComment.highLights,
    wayforward: auditComment.wayforward,
    closingStatementHTML: auditComment.closingStatementHTML,
    lowlightsHTML: auditComment.lowlightsHTML,
    highLightsHTML: auditComment.highLightsHTML,
    wayforwardHTML: auditComment.wayforwardHTML,
    notes: auditComment.notes,
  };
}

export function formatGetAuditComplianceScoresRequest(request: IGetAuditComplianceScoresRequest): string {
  let queryString = `auditId=${request.auditId}`;

  if (request.planIdToIncludeCountryOfParentScore !== undefined) {
    queryString += `&planIdToIncludeCountryOfParentScore=${request.planIdToIncludeCountryOfParentScore}`;
  }

  if (!request.filters) {
    return queryString;
  }

  for (let i = 0; i < request.filters.length; i++) {
    queryString += Object
      .entries(request.filters[i])
      .filter(entry => entry[1] !== undefined)
      .map(entry => `&filters[${i}].${entry[0]}=${entry[1].toString()}`)
      .join('');
  }

  return queryString;
}

export function formatGetAuditComplianceScoresResponse(response: any): IAuditComplianceScore[] {
  return Array.isArray(response)
    ? response.map(formatAuditComplianceScore)
    : [];
}

export function formatAuditComplianceScore(item: any): IAuditComplianceScore {
  return {
    id: Number(item.id),
    auditId: Number(item.auditId),
    auditStatus: formatAuditStatus(item.auditStatus),
    scoreTimestamp: new Date(item.scoreTimestamp).getTime(),
    complianceScore: Number(item.complianceScore),
    complianceResult: formatComplianceResult(item.complianceResult),
    parentDimensionType: formatAuditPlanDimensionType(item.parentDimensionType),
    parentDimensionId: Number(item.parentDimensionId),
    childDimensionType: item.childDimensionType
      ? formatAuditPlanDimensionType(item.childDimensionType)
      : undefined,
    childDimensionId: item.childDimensionId
      ? Number(item.childDimensionId)
      : undefined,
    scoreById: item.scoreById
      ? Number(item.scoreById)
      : undefined,
    scoreByType: formatAuditPlanDimensionType(item.scoreByType),
    numQuestionsTotal: Number(item.numQuestionsTotal),
    numNonComplianceQuestions: Number(item.numNonComplianceQuestions),
    cat1Score: item.cat1Score
      ? Number(item.cat1Score)
      : undefined,
    cat2Score: item.cat2Score
      ? Number(item.cat2Score)
      : undefined,
    includedQuestions: formatAuditComplianceScoreIncludedQuestions(item.includedQuestions),
    shortName: item.shortName?.toString() || "",
  };
}

export function formatCreateAuditResponse(obj: any): ICreateAuditResponse {
  return {
    auditId: Number(obj.auditId),
    numLinkedPlans: obj.numLinkedPlans === null
      || obj.numLinkedPlans === undefined
      ? undefined
      : Number(obj.numLinkedPlans),
  }
}
