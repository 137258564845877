import React from "react";
import TabSelection, { ITabItem } from "shared/components/layout/tab-selection/TabSelection";
import { setCurrentFilters } from "store/audit-planning-shared/AuditPlanningSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import "./PlanYearSelector.scoped.scss";

const PlanYearSelector: React.FC = () => {
  const selectedPlanYear = useAppSelector(store => store.auditPlanning.currentFilters.planYear);
  const dispatch = useAppDispatch();

  const tabItems = getAvailablePlanYears().map((x): ITabItem => ({
    key: x,
    node: x.toString(),
    isSelected: selectedPlanYear === x,
  })).sort((a, b) => a.key < b.key ? -1 : 1);

  return (
    <div>
      <TabSelection
        tabs={tabItems}
        onTabClicked={key => dispatch(setCurrentFilters({
          planYear: Number(key),
        }))}
        className="year-tabs"
      />
    </div>
  );
};

export default PlanYearSelector;

export function getAvailablePlanYears() {
  let year = (new Date()).getFullYear();
  return [year - 1, year, year + 1];
}