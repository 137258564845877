import React, { ReactNode } from "react";
import logoIcon from "../../media/icons/logo-icon.svg";
import logoDivider from "../../media/logo-divider.svg";
import Button from "shared/components/controls/buttons/button/Button";
import "./Login.scoped.scss";

interface ILoginProps {
  message?: ReactNode,
  error?: boolean
}

const Login: React.FC<ILoginProps> = ({
  message,
  error = false
}) =>
  <div
    className="login-page"
  >
    <div
      className="bg-bar"
    >
      <div
        className="qi-logo"
      >
        <a
          href="https://www.slb.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt=""
            src={logoIcon}
            className="logo-icon"
          />
        </a>
        <img
          alt="|"
          src={logoDivider}
          className="logo-divider"
        />
        <span className="slb-text">
          QI
        </span>
        {error &&
          <>
            <img
              alt="|"
              src={logoDivider}
              className="logo-divider"
            />
            <Button
              buttonType="secondary"
              onClick={() => window.location.reload()}
            >
              Login
            </Button>
          </>
        }
      </div>
    </div>

    <div
      className="prompt"
    >
      {message === undefined
        ? "Logging in..."
        : message
      }
    </div>

    <div
      className="bg-bar"
    ></div>
  </div>;

export default Login;