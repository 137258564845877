import { cloneDeep } from "lodash";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "shared/components/layout/modal/Modal";
import TabSelection, { ITabItem } from "shared/components/layout/tab-selection/TabSelection";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IColumnItem } from "shared/types/columnTypes";
import { saveCenterAuditAttributes, setCenterAuditAttributeModal } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { ICenterAuditAttribute } from "types/auditPlanningTypes";
import "./CreateAttributeModalCLM.scoped.scss";

const CreateAttributeModalCLM: React.FC = () => {
  const dispatch = useDispatch();

  const centerAuditAttributeModal = useAppSelector(store => store.facilityAttributes.centerAuditAttributeModal);
  const attributes = useAppSelector(store => store.facilityAttributes.centerAuditAttributes);
  const auditPackages = attributes.map(x => x.auditPackage).filter(onlyUnique);
  const [auditPackageSelected, setAuditPackage] = useState(auditPackages[0]);
  const [attributesToSave, setAttributesToSave] = useState(cloneDeep(attributes));

  // Check Restriction
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const isEditable = auditPageAuth.editRestriction === EditRestriction.EditAll
    || auditPageAuth.editRestriction === EditRestriction.EditOwn
    || auditPageAuth.editRestriction === EditRestriction.EditAllRestrict;

  const onSaveClicked = () => {
    const [isValid, errMessage] = validateAttributes(attributesToSave);

    if (!isValid) {
      dispatch(showErrorToast(`Please enter ${errMessage} in highlighted fields`));
      return;
    }

    dispatch(saveCenterAuditAttributes({
      row: centerAuditAttributeModal.row,
      requirement: centerAuditAttributeModal.requirement,
      profileId: centerAuditAttributeModal.profileId,
      subGeoUnitId: centerAuditAttributeModal.subGeoUnitId,
      attributesToSave: attributesToSave,
    }));
  };

  const onCloseClicked = () => {
    dispatch(setCenterAuditAttributeModal({
      isOpen: false,
      showModal: false,
    }));
  };

  const onAuditPackageClicked = (key: string) => {
    setAuditPackage(key);
  };

  const realignAttributeProps = (attrToReplaceWith: ICenterAuditAttribute) => {
    // If the attribute is not applicable, it cannot be implemented.
    if (!attrToReplaceWith.applicable) {
      attrToReplaceWith.implemented = false;
    }

    // If the attribute cannot be exempted, clear the quest exemption.
    if (isQuestExemptionDisabled(attrToReplaceWith)) {
      attrToReplaceWith.questExemption = "";
    }

    // If the attribute cannot be commented, clear the comment.
    if (isCommentDisabled(attrToReplaceWith)) {
      attrToReplaceWith.comments = "";
    }
  };

  const onAttributeChanged = (attrToReplaceWith: ICenterAuditAttribute) => {
    realignAttributeProps(attrToReplaceWith);

    setAttributesToSave((currentAttributes: ICenterAuditAttribute[]) =>
      currentAttributes
        .filter(x => x.topicId !== attrToReplaceWith.topicId)
        .concat(attrToReplaceWith)
    );
  };

  /** The items to render on the current tab. */
  const currentTabItems = attributesToSave
    .filter(x => x.auditPackage === auditPackageSelected)
    .sort((a, b) => a.clmDocRefName < b.clmDocRefName ? -1 : 1);

  const onCheckAllChanged = useCallback((isChecked: boolean,
    which: "applicable" | "implemented" | "exempted") => {
    const newAttrList = cloneDeep(attributesToSave);

    // Find only the current tab's items from this new list.
    const itemsToUpdate = newAttrList.filter(newItem =>
      currentTabItems.some(currItem => currItem.topicId === newItem.topicId)
    );

    // If possible, set the property appropriately for each item.
    itemsToUpdate.forEach(x => {
      if (which === "applicable") {
        x.applicable = isChecked;
      } else if (which === "implemented") {
        if (!isImplementedDisabled(x)) {
          x.implemented = isChecked;
        }
      } else if (which === "exempted") {
        if (!isExemptedDisabled(x)) {
          x.exempted = isChecked;
        }
      }

      // Update all dependent properties based on the changes.
      realignAttributeProps(x);
    });

    setAttributesToSave(newAttrList);
  }, [attributesToSave, currentTabItems]);

  /** Indicates if all items on the current tab are marked applicable. */
  const areAllApplicable = !currentTabItems.some(x => !x.applicable);
  /** Indicates if all items on the current tab that are able to be implemented are marked as such. */
  const areAllImplemented = !currentTabItems.filter(x => !isImplementedDisabled(x)).some(x => !x.implemented);
  /** Indicates if all items on the current tab that are able to be exempted are marked as such. */
  const areAllExempted = !currentTabItems.filter(x => !isExemptedDisabled(x)).some(x => !x.exempted);
  /** Indicates if all items are unable to be marked implemented. */
  const isAllImplementedDisabled = !currentTabItems.some(x => !isImplementedDisabled(x));
  /** Indicates if all items are unable to be marked exempted. */
  const isAllExemptedDisabled = !currentTabItems.some(x => !isExemptedDisabled(x));

  return (
    <Modal
      header="Center Audit Attributes"
      isOpen={true}
      showCloseButton={true}
      onCloseButtonClicked={onCloseClicked}
      buttons={isEditable
        ? [{
          className: "secondary",
          text: "Cancel",
          key: "CANCEL",
          onClick: onCloseClicked,
        }, {
          className: "primary",
          text: "Apply",
          key: "SAVE",
          onClick: onSaveClicked,
          disabled: !attributesToSave
            .some(x => x.implemented)
            || !isEditable,
        }]
        : [{
          className: "primary",
          text: "Close",
          key: "CLOSE",
          onClick: onCloseClicked,
        }]}
    >
      <div className="audit-packages-nav">
        <TabSelection
          showDividers={true}
          onTabClicked={(key) => {
            onAuditPackageClicked(key as string);
          }}
          tabs={
            auditPackages
              .map(e => {
                return (
                  {
                    key: e,
                    node: e,
                    isLink: true,
                    linkUrl: "",
                    isSelected: e === auditPackageSelected,
                  } as ITabItem
                );
              })
          }
        />
      </div>

      <div className="table-wrapper">
        <Table
          data={currentTabItems}
          keyProp={"topicId"}
          columns={getTableColumns(
            onAttributeChanged,
            onCheckAllChanged,
            { areAllApplicable, areAllImplemented, areAllExempted, isAllImplementedDisabled, isAllExemptedDisabled },
            isEditable
          )}
          theme={TableThemes.compact}
          className="clm-subtopics-table"
          areHeadersSticky={false}
        />
      </div>
    </Modal>
  );
};

export default CreateAttributeModalCLM;

function getTableColumns(
  onAttributeChanged: (attrToReplaceWith: ICenterAuditAttribute) => void,
  onCheckAllChanged: (isChecked: boolean, which: "applicable" | "implemented" | "exempted") => void,
  checkAllState: { areAllApplicable: boolean, areAllImplemented: boolean, areAllExempted: boolean, isAllImplementedDisabled: boolean, isAllExemptedDisabled: boolean, },
  isEditable: boolean,
): IColumnItem<ICenterAuditAttribute, keyof ICenterAuditAttribute>[] {
  return [
    {
      key: "dmsNumber",
      property: "clmDocRefDms",
      header: "DMS Number",
      width: 120,
    },
    {
      key: "clmDocRefName",
      property: "clmDocRefName",
      header: "Procedure",
      width: 250,
    },
    {
      key: "Applicable",
      property: "applicable",
      header: (
        <div className="checkbox-header">
          <input
            type="checkbox"
            onChange={e => onCheckAllChanged(e.currentTarget.checked, "applicable")}
            checked={checkAllState.areAllApplicable}
            disabled={!isEditable}
          /> Applicable
        </div>
      ),
      width: 120,
      customRender: item => {
        return (
          <div className="centered">
            <input
              type="checkbox"
              disabled={!isEditable}
              checked={item.applicable}
              onChange={e => {
                const attribute = { ...item };
                attribute.applicable = e.currentTarget.checked;
                onAttributeChanged(attribute);
              }}
            />
          </div>
        );
      }
    },
    {
      key: "Implemented",
      property: "implemented",
      header: (
        <div className="checkbox-header">
          <input
            type="checkbox"
            onChange={e => onCheckAllChanged(e.currentTarget.checked, "implemented")}
            checked={checkAllState.areAllImplemented && !checkAllState.isAllImplementedDisabled}
            disabled={checkAllState.isAllImplementedDisabled || !isEditable}
          /> Implemented
        </div>
      ),
      width: 120,
      customRender: item => {
        return (
          <div className="centered">
            <input
              type="checkbox"
              disabled={isImplementedDisabled(item) || !isEditable}
              checked={item.implemented}
              onChange={(e) => {
                let attribute: ICenterAuditAttribute = { ...item };
                attribute.implemented = e.target.checked;
                onAttributeChanged(attribute);
              }}
            />
          </div>
        );
      }
    },
    {
      key: "Exempted",
      property: "exempted",
      header: (
        <div className="checkbox-header">
          <input
            type="checkbox"
            onChange={e => onCheckAllChanged(e.currentTarget.checked, "exempted")}
            checked={checkAllState.areAllExempted && !checkAllState.isAllExemptedDisabled}
            disabled={checkAllState.isAllExemptedDisabled || !isEditable}
          /> Exempted
        </div>
      ),
      width: 120,
      customRender: item => {
        return (
          <div className="centered">
            <input
              type="checkbox"
              disabled={isExemptedDisabled(item) || !isEditable}
              checked={item.exempted}
              onChange={(e) => {
                let attribute: ICenterAuditAttribute = { ...item };
                attribute.exempted = e.target.checked;
                onAttributeChanged(attribute);
              }}
            />
          </div>
        );
      }
    },
    {
      key: "QUEST Exemption #",
      property: "questExemption",
      header: "QUEST Exemption #",
      width: 150,
      customRender: item => {
        return (
          <textarea
            disabled={isQuestExemptionDisabled(item) || !isEditable}
            className={`textarea ${isItemMissingRequiredQuestExemption(item) ? "required-error" : ""}`}
            value={item.questExemption}
            maxLength={500}
            onChange={(e) => {
              let attribute: ICenterAuditAttribute = { ...item };
              attribute.questExemption = e.target.value;
              onAttributeChanged(attribute);
            }}
          />
        );
      }
    },
    {
      key: "Comments",
      property: "comments",
      header: "Comments",
      width: 150,
      customRender: item => {
        return (
          <textarea
            disabled={isCommentDisabled(item) || !isEditable}
            className={`textarea ${isItemMissingRequiredComment(item) ? "required-error" : ""}`}
            value={item.comments}
            maxLength={500}
            onChange={(e) => {
              let attribute: ICenterAuditAttribute = { ...item };
              attribute.comments = e.target.value;
              onAttributeChanged(attribute);
            }}
          />
        );
      }
    },
  ];
};

function isImplementedDisabled(item: ICenterAuditAttribute) {
  return !item.applicable
    || item.exempted;
}

function isExemptedDisabled(item: ICenterAuditAttribute) {
  return item.implemented;
}

function isQuestExemptionDisabled(item: ICenterAuditAttribute) {
  return !item.exempted;
}

function isCommentDisabled(item: ICenterAuditAttribute) {
  return item.applicable
    && item.implemented;
}

function isItemMissingRequiredQuestExemption(item: ICenterAuditAttribute) {
  return !isQuestExemptionDisabled(item)
    && !item.questExemption?.trim();
}

function isItemMissingRequiredComment(item: ICenterAuditAttribute) {
  return !isCommentDisabled(item)
    && !item.comments?.trim()
    && (item.applicable
      || item.exempted);
}

function onlyUnique(value: any, index: any, array: any) {
  return array.indexOf(value) === index;
}

function isNullOrEmpty(str: string) {
  return !str || !str.trim().length;
}

function validateAttributes(attributesToSave: ICenterAuditAttribute[]): [boolean, string] {
  let questExemptionException = false;
  let commentsException = false;
  let textToFill = "";

  attributesToSave.forEach(attribute => {
    if (attribute.exempted) {
      if (isNullOrEmpty(attribute.questExemption)) {
        questExemptionException = true;
      }
      if (isNullOrEmpty(attribute.comments)) {
        commentsException = true;
      }
    }

    if ((attribute.applicable && !attribute.implemented)
      && (isNullOrEmpty(attribute.comments))) {
      commentsException = true;
    }

    if (questExemptionException && commentsException) {
      return;
    }
  });

  if (questExemptionException) {
    textToFill = "QUEST Exemption";
    if (commentsException) {
      textToFill += "# & Comments";
    }
  } else if (commentsException) {
    textToFill = "Comments";
  }

  return [!questExemptionException && !commentsException, textToFill];
}