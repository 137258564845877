import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import OwnerGroupPicker from "shared/components/controls/pickers/OwnerGroupPicker";
import "./AuditTemplateFilters.scoped.scss";
import { IOwnerGroup } from "types/auditingTypes";

interface OwnerGroupFilterProps {
  selected?: IOwnerGroup[],
  onApply: (items: IOwnerGroup[]) => void,
}

const OwnerGroupFilter: React.FC<OwnerGroupFilterProps> = (
  {
    selected = [],
    onApply
  }) => {
  return (
    <div className="owner-group">
      <LabeledControl label="Owner Group">
        <OwnerGroupPicker
          onApply={onApply}
          selectedItems={selected ?? []}
          allowMultiselect={false}
          renderMode="dropdown"
        />
      </LabeledControl>
    </div>
  );
};

export default OwnerGroupFilter;
