import { IPickerItem } from "shared/types/pickerTypes";
import { convertToPickerItem } from "shared/utilities/pickerUtiilities";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";

export function flattenHierarchy<Type>(hierarchy: Type[],
  childrenSelector: (item: Type) => Type[]) {
  const flatList: Type[] = [];

  const flatten = (item: Type) => {
    flatList.push(item);

    childrenSelector(item).forEach(flatten);
  };

  hierarchy.forEach(flatten);

  return flatList;
}

export function setToSelectedItemsForCreating(selectedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  children: IDetailedTemplateChildren[],
  selectedChildIds: number[],
  applySelectedIds: boolean,
  isFromMandatoyTemplate: boolean) {
  let detailedTemplate: IDetailedTemplate;

  children.forEach(childItem => {
    if (childItem.masterDataType === "AuditTopic") {

      if (applySelectedIds) {
        if (selectedChildIds
          .includes(childItem.id)) {
          selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
        }
      } else if (childItem.isSelectable
        && (detailedTemplate?.isRequired
          || childItem.isRequired
          || isFromMandatoyTemplate)) {
        selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
      }

      if (childItem.children.length > 0) {
        setToSelectedItemsForCreating(selectedTemplateItems,
          childItem.children,
          selectedChildIds,
          applySelectedIds,
          (childItem.isSelectable && (isFromMandatoyTemplate
            || childItem.isRequired
            || (detailedTemplate?.isRequired ?? false))));
      }
    } else if (childItem.masterDataType === MetaDataTypes.AuditTemplate) {

      detailedTemplate = childItem as unknown as IDetailedTemplate;

      if (applySelectedIds) {
        if (selectedChildIds
          .includes(childItem.id)) {
          selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
        }
      } else if (childItem.isSelectable
        && (childItem.isRequired
          || isFromMandatoyTemplate)) {
        selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
      }

      if (childItem.children.length > 0) {
        setToSelectedItemsForCreating(selectedTemplateItems,
          childItem.children,
          selectedChildIds,
          applySelectedIds,
          (childItem.isSelectable
            && (isFromMandatoyTemplate
              || childItem.isRequired)));
      }
    }
  });
}

export function setToSelectedItemsForEditing(
  selectedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  children: IDetailedTemplateChildren[],
  selectedChildIds: number[],
  applySelectedIds: boolean,
  isFromMandatoyTemplate: boolean,
  parentIsRequired: boolean = false,
  level: number = 0
) {

  children.forEach(childItem => {
    if (childItem.masterDataType === "AuditTopic") {

      /* Topics from root of tree will be selected if its exists on selectedChildIds */
      if (level === 0) {
        isFromMandatoyTemplate = true;
      }

      if (applySelectedIds && isFromMandatoyTemplate) {
        if (selectedChildIds
          .includes(childItem.id)) {
          selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
        }
      } else if (childItem.isSelectable
        && (parentIsRequired
          || isFromMandatoyTemplate)) {
        selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
      }

      if (childItem.children.length > 0) {
        setToSelectedItemsForEditing(selectedTemplateItems,
          childItem.children,
          selectedChildIds,
          applySelectedIds,
          (childItem.isSelectable && (isFromMandatoyTemplate
            || childItem.isRequired
            || parentIsRequired)),
          parentIsRequired,
          childItem.isSelectable ? level + 1 : level);
      }
    } else if (childItem.masterDataType === MetaDataTypes.AuditTemplate) {
      if (isFromMandatoyTemplate) {
        parentIsRequired = true;
      } else {
        parentIsRequired = childItem.isRequired;
        if (selectedChildIds
          .includes(childItem.id)) {
          parentIsRequired = true;
        }
      }

      if (applySelectedIds) {
        if (selectedChildIds
          .includes(childItem.id)) {
          selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
        }
      } else if (childItem.isSelectable
        && (childItem.isRequired
          || isFromMandatoyTemplate)) {
        selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
      }

      if (childItem.children.length > 0) {
        setToSelectedItemsForEditing(selectedTemplateItems,
          childItem.children,
          selectedChildIds,
          applySelectedIds,
          (childItem.isSelectable
            && childItem.isRequired),
          parentIsRequired,
          level + 1);
      }
    }
  });
}

export function setDeSelectedItems(selectedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  children: IDetailedTemplateChildren[],
  selectedChildIds: number[],
  applySelectedIds: boolean,
  isFromMandatoyTemplate: boolean) {
  let detailedTemplate: IDetailedTemplate;

  children.forEach(childItem => {
    if (childItem.masterDataType === "AuditTopic") {
      if (applySelectedIds && isFromMandatoyTemplate) {
        if (selectedChildIds
          .includes(childItem.id)) {
          selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
        }
      } else if (childItem.isSelectable
        && (detailedTemplate?.isRequired
          || isFromMandatoyTemplate)) {
        selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
      }

      if (childItem.children.length > 0) {
        setToSelectedItemsForCreating(selectedTemplateItems,
          childItem.children,
          selectedChildIds,
          applySelectedIds,
          (childItem.isSelectable && (isFromMandatoyTemplate
            || childItem.isRequired
            || (detailedTemplate?.isRequired ?? false))));
      }
    } else if (childItem.masterDataType === MetaDataTypes.AuditTemplate) {

      detailedTemplate = childItem as unknown as IDetailedTemplate;

      if (applySelectedIds) {
        if (selectedChildIds
          .includes(childItem.id)) {
          selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
        }
      } else if (childItem.isSelectable
        && (childItem.isRequired
          || isFromMandatoyTemplate)) {
        selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
      }

      if (childItem.children.length > 0) {
        setToSelectedItemsForCreating(selectedTemplateItems,
          childItem.children,
          selectedChildIds,
          applySelectedIds,
          (childItem.isSelectable
            && childItem.isRequired));
      }
    }
  });
}

export function addRecursively(item: IDetailedTemplate | IDetailedTemplateChildren, allItems: (string | number)[]) {
  allItems.push(item.key);
  item.children.forEach(c => {
    addRecursively(c, allItems);
  });
};

export function toPickerItem(allTemplates: (string | number)[],
  item: IDetailedTemplate | IDetailedTemplateChildren,
  includeChildren?: boolean,
  allItems?: (IDetailedTemplate | IDetailedTemplateChildren)[])
  : IPickerItem<IDetailedTemplate | IDetailedTemplateChildren> {
  return convertToPickerItem(item,
    allTemplates,
    item => item.key,
    (item) => item.children || [], includeChildren,
    allItems);
};

export function getChildrenRecursively(item: IDetailedTemplate | IDetailedTemplateChildren,
  selectedItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  useOnlyChildren: boolean = false,
  masterDataType: MetaDataTypes.AuditTemplate | MetaDataTypes.AuditTopic | 'Both' = 'Both'): (IDetailedTemplate | IDetailedTemplateChildren)[] {

  // **useOnlyChildren** is used to ignore item, usually it will be ignored if item is unselectable but its has children that should be considered
  if (!useOnlyChildren && !item.isRequired
    && (item as IDetailedTemplateChildren).masterDataType === MetaDataTypes.AuditTemplate) {
    selectedItems.push(item);
  }

  if (!item.isRequired && item.children.length > 0) {
    item.children.forEach(i => {
      const detailedTemplateChildrenItem = (i as unknown as IDetailedTemplateChildren);
      if ((detailedTemplateChildrenItem.masterDataType === masterDataType
        || masterDataType === 'Both')
        && detailedTemplateChildrenItem.isSelectable
        && !(detailedTemplateChildrenItem.masterDataType === MetaDataTypes.AuditTemplate
          && detailedTemplateChildrenItem.isRequired)) {
        selectedItems.push(i);
      }
      if (detailedTemplateChildrenItem.children.length > 0) {
        // call function recurseverly to analized each child from item
        getChildrenRecursively(detailedTemplateChildrenItem, selectedItems, false, masterDataType);
      }
    });
  }

  return selectedItems;
}

export function getTopicChildrenRecursively(item: IDetailedTemplate | IDetailedTemplateChildren,
  selectedItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  useOnlyChildren: boolean = false): (IDetailedTemplate | IDetailedTemplateChildren)[] {

  // **useOnlyChildren** is used to ignore item, usually it will be ignored if item is unselectable but its has children that should be considered
  if (!useOnlyChildren) {
    selectedItems.push(item);
  }

  if (!item.isRequired && item.children.length > 0) {
    item.children.forEach(i => {
      const detailedTemplateChildrenItem = (i as unknown as IDetailedTemplateChildren);
      if (detailedTemplateChildrenItem.masterDataType === MetaDataTypes.AuditTopic) {
        if (detailedTemplateChildrenItem.isSelectable) {
          selectedItems.push(i);
        }
        else if (detailedTemplateChildrenItem.children.length > 0) {
          // call function recurseverly to analized each child from item
          getChildrenRecursively(detailedTemplateChildrenItem, selectedItems, true);
        }
      }
    });
  }

  return selectedItems;
}

export function setToReadOnlyItems(selectedTemplateItems: IDetailedTemplate[],
  children: IDetailedTemplateChildren[],
  skipAllTopicsFromThisLevel: boolean,
  parentIsMandatory: boolean = false): void {
  children.forEach(childItem => {
    if ((childItem.masterDataType === MetaDataTypes.AuditTopic
      && (!skipAllTopicsFromThisLevel
        || !childItem.isSelectable))
      || (childItem.masterDataType === MetaDataTypes.AuditTemplate
        && (childItem.isRequired || parentIsMandatory))) {
      selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
    }
    if (childItem.children.length > 0) {
      setToReadOnlyItems(selectedTemplateItems, childItem.children, skipAllTopicsFromThisLevel && childItem.masterDataType === MetaDataTypes.AuditTopic && !childItem.isSelectable, true);
    }
  });
}

export function isDetailedTemplateChildren(object: any): object is IDetailedTemplateChildren {
  return object && 'masterDataObject' in object;
}

export function getAllVisibleAuditTopicIds(visibleAuditTopics: IAuditTopic[]) {
  let visibleIds: number[] = [];

  const gatherIds = (visibleAuditTopics: IAuditTopic[]) => {
    visibleAuditTopics.forEach(at1 => {
      if (!at1.isDeleted) {
        visibleIds.push(at1.id);
      }

      if (at1.children?.length) {
        gatherIds(at1.children);
      }
    });
  };

  gatherIds(visibleAuditTopics);
  return visibleIds;
}

export function flattenDetailedTemplateChildren(templateSelections: (IDetailedTemplate | IDetailedTemplateChildren)[], childrenOutput: IDetailedTemplateChildren[] = []): (IDetailedTemplate | IDetailedTemplateChildren)[] {
  let children: IDetailedTemplateChildren[] = [];
  return templateSelections.map(m => {
    if (m?.children && m.children.length) {
      children = [...children, ...m.children];
    }
    childrenOutput.push((m as unknown as IDetailedTemplateChildren));
    return m;
  }).concat(children.length ? flattenDetailedTemplateChildren(children, childrenOutput) : children);
}

export function setTotalSelectedItems(selectedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[],
  children: IDetailedTemplateChildren[],
  selectedChildIds: number[],
  applySelectedIds: boolean,
  isFromMandatoryTemplate: boolean,
  parentIsRequired: boolean = false,
  level: number = 0): number {
  let total = 0;
  children.forEach(childItem => {

    if (childItem.masterDataType === MetaDataTypes.AuditTopic) {
      const isChildSelected = selectedChildIds.includes(childItem.id);

      /* Topics from root of tree will be selected if its exists on selectedChildIds */
      if ((parentIsRequired || isChildSelected) && level === 0) {
        isFromMandatoryTemplate = true;
      }

      if (applySelectedIds && isFromMandatoryTemplate) {
        if (isChildSelected) {
          total++;
        }
      } else if (isChildSelected
        && (parentIsRequired
          || isFromMandatoryTemplate)) {
        total++;
      }

      if (childItem.children.length > 0) {
        total += setTotalSelectedItems(selectedTemplateItems,
          childItem.children,
          selectedChildIds,
          applySelectedIds,
          (childItem.children.every(x => x.isRequired)),
          parentIsRequired,
          level + 1);
      }

    } else if (childItem.masterDataType === MetaDataTypes.AuditTemplate) {
      let totalThisLevelAndBellow = 0;

      if (isFromMandatoryTemplate) {
        parentIsRequired = true;
      } else {
        parentIsRequired = childItem.isRequired;
        if (selectedChildIds
          .includes(childItem.id)) {
          parentIsRequired = true;
        }

      }

      if (childItem.children.length > 0) {
        totalThisLevelAndBellow += setTotalSelectedItems(selectedTemplateItems,
          childItem.children,
          selectedChildIds,
          applySelectedIds,
          (childItem.isSelectable
            && childItem.isRequired),
          parentIsRequired,
          level + 1);
      }

      const flattenItems: (IDetailedTemplate | IDetailedTemplateChildren)[] = flattenDetailedTemplateChildren(childItem.children, []);
      const totalFlattenItems = flattenItems.filter(i => {
        if ((i as IDetailedTemplateChildren).masterDataType) {
          return i.isSelectable && (i as IDetailedTemplateChildren).masterDataType !== MetaDataTypes.AuditTemplate;
        }

        return i.isSelectable;
      }).length;

      childItem.total = `(${totalThisLevelAndBellow}/${totalFlattenItems})`;

      total += totalThisLevelAndBellow;

      if (applySelectedIds) {
        if (selectedChildIds
          .includes(childItem.id)) {
          selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
        }
      } else if (childItem.isSelectable
        && (childItem.isRequired
          || isFromMandatoryTemplate)) {
        selectedTemplateItems.push(childItem as unknown as IDetailedTemplate);
      }
    }

  });
  return total;
}

export interface IHierarchyItem {
  id: string | number,
  children?: IHierarchyItem[],
}

/**
 * Recursively searches a hierarchy for a specified item.
 * @param itemId The item id to search for in the hierarchy.
 * @param entireHierarchy The hierarchy of all available items to search against.
 * @returns Returns an array (in order) defining the ancestor path from the top of the tree to the matching item. If the item is not found, the array is empty.
 */
export function getHierarchyItemAncestorPath<T extends IHierarchyItem>(itemId: string | number,
  entireHierarchy: T[]): T[] {
  return getHierarchyRecursively(itemId, entireHierarchy) ?? [];
}

function getHierarchyRecursively<T extends IHierarchyItem>(itemId: string | number,
  hierarchy: T[],
  ancestorPath?: T[]): T[] | undefined {
  if (!hierarchy.length) {
    return [];
  }

  for (const hierarchyItem of hierarchy) {
    if (hierarchyItem.id === itemId) {
      // This is the item we're looking for! Return the ancestor path to this item.
      return ancestorPath ?? [];
    } else if (hierarchyItem.children?.length) {
      // This isn't the item we're looking for but it has children.
      // Start searching through the children. Pass in the existing ancestory path to get to this position
      // in the hierarchy and then add in this item as well.
      const pathFromChildren = getHierarchyRecursively<T>(itemId,
        hierarchyItem.children as T[],
        [...(ancestorPath ?? []), hierarchyItem]);

      // If the item was found somewhere in the child list, return the path that was found.
      if (pathFromChildren !== undefined) {
        return pathFromChildren;
      }
    }
  }

  // The item was not found in this list of hierarchy items.
  return undefined;
}