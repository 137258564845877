import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AzureUserPicker from "shared/components/controls/pickers/AzureUserPicker";
import BusinessViewPicker from "shared/components/controls/pickers/BusinessViewPicker";
import FacilityPicker from "shared/components/controls/pickers/FacilityPicker";
import NonReduxPickerDropdown from "shared/components/controls/pickers/non-redux-dropdown-picker/NonReduxDropdownPicker";
import NonReduxPicker from "shared/components/controls/pickers/non-redux-picker/NonReduxPicker";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { toTitleCase } from "shared/utilities/stringUtilities";
import { setCurrentFilters } from "store/audit-planning-shared/AuditPlanningSlice";
import { PlanningPlanStatusFilterValues, PlanningReviewStatusFilterValues } from "store/audit-planning-shared/reducers/planningFiltersReducers";
import { useAppSelector } from "store/store";
import AuditPlanCalendarStatusFilterOptions from "types/audit-plan-calendar/AuditPlanCalendarStatusFilterOptions";
import { auditPlanCalendarStatusFilterToString } from "types/audit-plan-calendar/auditPlanCalendarStatusFilterUtils";
import { IAuditType } from "types/auditMasterDataTypes";
import { ComplianceResults } from "types/auditPlanningTypes";
import { IAuditPlanningFiltersProps } from "../AuditPlanningFilters";

interface IMoreFiltersModalProps {
  options: IAuditPlanningFiltersProps,
  isOpen: boolean,
  onClose(): void,
}

const MoreFiltersModal: React.FC<IMoreFiltersModalProps> = ({
  options,
  isOpen,
  onClose,
}) => {
  const currentFilters = useAppSelector(store => store.auditPlanning.currentFilters);
  const dispatch = useDispatch();

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (isOpen) {
      appInsights.trackPageView({
        name: "Audit Planning - More Filters",
      });
    }
  }, [isOpen, appInsights]);

  return (
    <Modal
      isOpen={isOpen}
      header="More Filters"
      buttons={[{
        key: "close",
        text: "Close",
        className: "primary",
        onClick: onClose,
      }]}
      showCloseButton
      onCloseButtonClicked={onClose}
    >
      <FlexCol>
        {options?.showFacilities && (
          <LabeledControl
            label="Facility"
          >
            <FacilityPicker
              selectedItems={currentFilters.facilities}
              onSelectionChange={items => dispatch(setCurrentFilters({
                facilities: items,
              }))}
            />
          </LabeledControl>
        )}

        {options.showPlanStatus && (
          <LabeledControl
            label="Audit Plan Status"
          >
            <NonReduxPickerDropdown<PlanningPlanStatusFilterValues>
              keyMapper={x => x}
              selectMode="single"
              selectedItems={currentFilters.planStatus
                ? [currentFilters.planStatus]
                : []
              }
              onLoadItems={async (_, __) => {
                return [
                  PlanningPlanStatusFilterValues.Recommended,
                  PlanningPlanStatusFilterValues.FinalApproval,
                  PlanningPlanStatusFilterValues.Removed,
                ];
              }}
              onSelectionChanged={items => dispatch(setCurrentFilters({
                planStatus: items.length ? items[0] : undefined,
              }))}
              itemFormatter={item => item}
            />
          </LabeledControl>
        )}

        {options.showBusinessViews && (
          <LabeledControl
            label="Business View (Divisions)"
          >
            <BusinessViewPicker
              onApply={items => dispatch(setCurrentFilters({
                businessViews: items,
              }))}
              selectedItems={currentFilters.businessViews}
              showSuggestions
              allowMultiselect
            />
          </LabeledControl>
        )}

        {options.showLeadAuditors && (
          <LabeledControl
            label="Lead Auditor"
          >
            <AzureUserPicker
              onApply={items => dispatch(setCurrentFilters({
                leadAuditors: items,
              }))}
              selectedItems={currentFilters.leadAuditors}
              allowMultiselect
            />
          </LabeledControl>
        )}

        {options.showReviewStatus && (
          <LabeledControl
            label="Review Status"
          >
            <NonReduxPickerDropdown<PlanningReviewStatusFilterValues>
              keyMapper={x => x}
              selectMode="single"
              selectedItems={currentFilters.reviewStatus
                ? [currentFilters.reviewStatus]
                : []
              }
              onLoadItems={async (_, __) => {
                return [
                  PlanningReviewStatusFilterValues.NotReviewed,
                  PlanningReviewStatusFilterValues.Reviewed,
                  PlanningReviewStatusFilterValues.ReviewedByMe,
                ];
              }}
              onSelectionChanged={items => dispatch(setCurrentFilters({
                reviewStatus: items.length ? items[0] : undefined,
              }))}
              itemFormatter={item => item}
            />
          </LabeledControl>
        )}

        {options.showReviewedBy && (
          <LabeledControl
            label="Reviewed By"
          >
            <AzureUserPicker
              onApply={items => dispatch(setCurrentFilters({
                reviewedBy: items,
              }))}
              selectedItems={currentFilters.reviewedBy}
              isDisabled={currentFilters.reviewStatus !== PlanningReviewStatusFilterValues.Reviewed}
            />
          </LabeledControl>
        )}

        {options.showIncludeDeletedAttributes && (
          <label>
            <input
              type="checkbox"
              checked={currentFilters.includeDeletedAttributes}
              onChange={e => dispatch(setCurrentFilters({
                includeDeletedAttributes: e.currentTarget.checked,
              }))}
            />
            Include deleted attributes
          </label>
        )}

        {options.showCalendarAuditTypes && (
          <LabeledControl
            label="Audit Type"
          >
            <NonReduxPicker<IAuditType>
              title="Audit Type"
              selectedItems={currentFilters.calendarAuditTypes}
              renderSelectedItem={x => x.name}
              renderListItem={x => x.name}
              itemSorter={(a, b) => a.name < b.name ? -1 : 1}
              onApply={values => dispatch(setCurrentFilters({
                calendarAuditTypes: values,
              }))}
              onLoadItems={MasterDataApi.getAuditTypes}
              keyMapper={x => x.id}
              searchOptions={{
                filterItem: (item, search) => item.name
                  .toLowerCase()
                  .includes(search.toLowerCase()) === true
              }}
            />
          </LabeledControl>
        )}

        {options.showAuditCalendarStatuses && (
          <LabeledControl
            label="Status"
          >
            <NonReduxPicker<AuditPlanCalendarStatusFilterOptions>
              title="Status"
              selectedItems={currentFilters.auditCalendarStatuses}
              renderListItem={auditPlanCalendarStatusFilterToString}
              renderSelectedItem={auditPlanCalendarStatusFilterToString}
              onApply={values => dispatch(setCurrentFilters({
                auditCalendarStatuses: values,
              }))}
              onLoadItems={async () => [
                AuditPlanCalendarStatusFilterOptions.RecommendedApproved,
                AuditPlanCalendarStatusFilterOptions.PlannedInProgress,
                AuditPlanCalendarStatusFilterOptions.Completed,
                AuditPlanCalendarStatusFilterOptions.Closed,
              ]}
              searchOptions={{
                filterItem: (item, search) => auditPlanCalendarStatusFilterToString(item)
                  .toLowerCase()
                  .includes(search.toLowerCase()) === true
              }}
              keyMapper={x => x}
            />
          </LabeledControl>
        )}

        {options.showComplianceResults && (
          <LabeledControl
            label="Compliance Result"
          >
            <NonReduxPicker<ComplianceResults>
              title="Compliance Result"
              selectedItems={currentFilters.complianceResults}
              renderSelectedItem={x => toTitleCase(x.toString()) || x.toString()}
              renderListItem={x => toTitleCase(x.toString()) || x.toString()}
              onApply={values => dispatch(setCurrentFilters({
                complianceResults: values,
              }))}
              onLoadItems={async () => [
                ComplianceResults.FullCompliance,
                ComplianceResults.HighNonCompliance,
                ComplianceResults.NonCompliance,
                ComplianceResults.BasicCompliance,
                ComplianceResults.HighCompliance,
              ]}
              keyMapper={x => x}
              searchOptions={{
                filterItem: (item, search) => {
                  return (toTitleCase(item) || "")
                    .toLowerCase()
                    .includes(search.toLowerCase()) === true
                }
              }}
            />
          </LabeledControl>
        )}

        {options.showUnassigned && (
          <LabeledControl
            label="No Lead Auditor"
          >
            <label>
              <input
                type="checkbox"
                checked={currentFilters.unassigned}
                onChange={e => dispatch(setCurrentFilters({
                  unassigned: e.currentTarget.checked,
                }))}
              />
              Show Unassigned Only
            </label>
          </LabeledControl>
        )}
      </FlexCol>
    </Modal>
  );
}

export default MoreFiltersModal;
