import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback } from "react";
import { BusinessEpicType, IBusinessEpic, MetaDataTypes } from "types/masterDataTypes";
import NonReduxPickerDropdown from "./non-redux-dropdown-picker/NonReduxDropdownPicker";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IBusinessEpicPickerProps {
  onApply: (selectedItems: IBusinessEpic[]) => void,
  selectedItems: IBusinessEpic[],
  allowMultiselect?: boolean,
  businessEpicType: BusinessEpicType,
  title: string,
  /**
   * Optional. If specified, the picker will render itself using this function. The returned React.ReactNode should
   * call the provided `openPicker` function when clicked to open the picker.
   */
  onRenderPicker?: (openPicker: () => void) => React.ReactNode,
  /** Optional. If true, the user cannot change selections. */
  isDisabled?: boolean,
  /** Optional. If true, the picker will show deleted items as well. */
  includeDeleted?: boolean,
  /** Optional. Determines the picker's mode. Default = Picker. */
  renderMode?: PickerMode,
}

const BusinessEpicPicker: React.FC<IBusinessEpicPickerProps & ICommonPickerProps<IBusinessEpic>> = ({
  onApply,
  selectedItems,
  businessEpicType,
  title,
  allowMultiselect = false,
  onRenderPicker,
  isDisabled = false,
  includeDeleted = false,
  renderMode = "picker",
  userProfileMetaRestrictions,
}) => {
  const renderItem = (item: IBusinessEpic) => item.name;
  const loadItems = useCallback(async (_: string | undefined, abortSignal: AbortSignal) =>
    await MasterDataApi.getBusinessEpics(includeDeleted, businessEpicType, abortSignal),
    [includeDeleted, businessEpicType]);

  const restrictedToItems = userProfileMetaRestrictions
    ?.filter(x => x.type === MetaDataTypes.BusinessEpic) || [];

  selectedItems = selectedItems.filter(item => item.type === businessEpicType)

  if (renderMode === "picker") {
    return (
      <NonReduxPicker<IBusinessEpic>
        itemSorter={(a, b) => renderItem(a) < renderItem(b) ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title={title}
        allowMultiSelect={allowMultiselect}
        displayMode="list"
        renderListItem={renderItem}
        searchOptions={{
          filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
          asyncMinChars: 2,
          behavior: "async",
        }}
        onRenderPicker={onRenderPicker}
        isDisabled={isDisabled}
        isDisabledMapper={!restrictedToItems.length
          ? undefined
          : item => !restrictedToItems.some(x => x.id === item.id)
        }
      />
    );
  } else if (renderMode === "dropdown") {
    return (
      <NonReduxPickerDropdown<IBusinessEpic>
        itemFormatter={renderItem}
        keyMapper={item => item.id}
        onLoadItems={loadItems}
        onSelectionChanged={onApply}
        selectedItems={selectedItems}
        isDisabled={isDisabled}
        placeholder="Select"
        selectMode={allowMultiselect ? "multiple" : "single"}
        isDisabledMapper={!restrictedToItems.length
          ? undefined
          : item => !restrictedToItems.some(x => x.id === item.id)
        }
      />
    )
  } else {
    return <>Render mode "{renderMode}" is not supported.</>
  }
};

export default BusinessEpicPicker;