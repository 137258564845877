import config from "config";
import React from "react";
import Card from "shared/components/layout/card/Card";
import DataGrid from "shared/components/layout/grid/DataGrid";
import FittedPage from "shared/components/layout/page/fitted-page/FittedPage";

const ChecklistMappingsPage: React.FC = () =>
  <FittedPage
    title="QUEST Checklist / QI Topic Mappings"
  >
    <Card>
      <DataGrid
        id="ChecklistMappings"
        dataSource="OData"
        dataUrl={config.endpoints.legacyQuest.checklistMappings}
        gridColumns={[
          {
            uid: "id",
            field: "id",
            headerText: "Id",
            width: 150,
            template: x => x.id.toString(),
            visible: false,
            type: "number",
          },
          {
            uid: "legacyChecklistId",
            field: "legacyChecklistId",
            headerText: "Checklist Id",
            width: 150,
            template: x => x.legacyChecklistId.toString(),
            type: "number",
          },
          {
            uid: "legacyChecklistName",
            field: "legacyChecklistName",
            headerText: "Checklist Name",
            width: 250,
            type: "string",
          },
          {
            uid: "qofTopicId",
            field: "qofTopicId",
            headerText: "QI Topic Id",
            width: 150,
            type: "number",
            visible: false,
            template: x => x.qofTopicId.toString(),
          },
          {
            uid: "qofTopic",
            field: "qofTopic",
            headerText: "QI Topic",
            width: 250,
            type: "string",
          },
          {
            uid: "qofSubTopics",
            field: "qofSubTopics",
            headerText: "QI Sub-Topic",
            width: 250,
            type: "string",
          },
          {
            uid: "isGlobal",
            field: "isGlobal",
            headerText: "Global",
            width: 120,
            type: "boolean",
          },
          {
            uid: "divisions",
            field: "divisions",
            headerText: "Division",
            width: 250,
            type: "string",
          },
          {
            uid: "businessLines",
            field: "businessLines",
            headerText: "Business Line",
            width: 250,
            type: "string",
          },
          {
            uid: "subBusinessLines",
            field: "subBusinessLines",
            headerText: "Sub Business Line",
            width: 250,
            type: "string",
          },
          {
            uid: "countries",
            field: "countries",
            headerText: "Country",
            width: 250,
            type: "string",
          },
        ]}
      />
    </Card>
  </FittedPage>;

export default ChecklistMappingsPage;