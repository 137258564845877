import { ReactNode, useCallback, useEffect, useRef } from "react";
import { AuditStatuses, AuditStepBuckets, AuditStepStage, IAuditStep } from "types/auditingTypes";
import AuditStep from "./step/AuditStep";
import "./AuditStepList.scoped.scss";
import "components/audits/common/audit-status-chip/AuditStatusChip.scoped.scss";

interface IAuditStepListProps {
  steps: IAuditStep[],
  auditStatus: AuditStatuses,
}

const AuditStepList: React.FC<IAuditStepListProps> = ({
  steps,
  auditStatus,
}) => {
  const lineRef = useRef<SVGLineElement>(null);
  const stepListRef = useRef<HTMLDivElement>(null);

  const updateLine = useCallback(() => {
    const stageElements = document.getElementsByClassName("major-stage");

    if (stageElements.length
      && lineRef.current
      && stepListRef.current) {
      const firstItem = stageElements.item(0);
      const lastItem = stageElements.item(stageElements.length - 1);

      let x = (firstItem as HTMLDivElement).offsetLeft
        + ((firstItem as HTMLDivElement).offsetWidth / 2);

      let halfHeight = (firstItem as HTMLDivElement).offsetHeight / 2;

      if (firstItem && lastItem) {
        lineRef.current?.setAttribute("display", "block");

        lineRef.current.setAttribute("x1", x.toString());
        lineRef.current.setAttribute("y1", ((firstItem as HTMLDivElement).offsetTop + halfHeight).toString());
        lineRef.current.setAttribute("x2", x.toString());
        lineRef.current.setAttribute("y2", ((lastItem as HTMLDivElement).offsetTop + halfHeight).toString());
        return;
      }
    }

    lineRef.current?.setAttribute("display", "none");
  }, [lineRef, stepListRef]);

  useEffect(() => {
    updateLine();
  }, [updateLine]);

  const currentBucket = steps.find(x => x.stage === AuditStepStage.nextUp)?.bucket;

  const getBucketAuditStep = (bucket: AuditStepBuckets): AuditStepStage => {
    if (currentBucket === bucket) {
      return AuditStepStage.nextUp;
    }

    return steps.some(x => x.bucket === bucket
      && !x.isOptional
      && x.stage !== AuditStepStage.done)
      ? AuditStepStage.toDo
      : AuditStepStage.done;
  };

  return (
    <div
      className="audit-step-list"
      ref={stepListRef}
    >
      <svg>
        <line id="audit-status-track-line" ref={lineRef} stroke="#CCCFDE" strokeWidth="1" />
      </svg>

      {Object.values(AuditStepBuckets).map(bucket => {
        return (
          <AuditStep
            level={1}
            number={100}
            stage={getBucketAuditStep(bucket)}
            text={bucket}
            key={bucket}
            onLayoutChange={updateLine}
          >
            {
              steps.filter(x => x.bucket === bucket).map(x => {
                let closeVerticalBar = <></>;
                const statusInfo: AuditStatuses = getStatusInfo(x);
                const style = `${statusInfo === auditStatus
                  ? `audit-status-vertical-bar-current`
                  : ""} ${statusInfo}`;

                if (x.number === 1 || x.number === 4 || x.number === 8 || x.number === 12) {
                  closeVerticalBar = (
                    <div className={`verticalProgressLine ${style}`}>
                      <span className="verticalProgressLabel">
                        {statusInfo.replace(/([a-z])([A-Z])/, "$1 $2")}
                      </span>
                    </div>
                  );
                }
                return renderStep(x, 2, closeVerticalBar, updateLine);
              })
            }
          </AuditStep>
        );
      })}
    </div >
  );
}

export default AuditStepList;

function renderStep(step: IAuditStep, level: number, extraNode: ReactNode, updateLine: () => void) {
  return (
    <>
      {extraNode}

      <AuditStep
        level={level}
        number={step.number}
        stage={step.stage}
        isOptional={step.isOptional}
        text={step.text}
        key={step.text}
        url={step.url}
        onLayoutChange={updateLine}
      />
    </>
  );
}

function getStatusInfo(auditStep: IAuditStep): AuditStatuses {
  let auditStatus: AuditStatuses = AuditStatuses.Planned;

  switch (auditStep.number) {
    case 4:
      auditStatus = AuditStatuses.InProgress;
      break;
    case 8:
      auditStatus = AuditStatuses.Completed;
      break;
    case 12:
      auditStatus = AuditStatuses.Closed;
      break;
  };

  return auditStatus;
}
