import React, { useContext } from "react";
import CollapseSwitch from "../../collapse-switch/CollapseSwitch";
import QISchedulerContext from "../QISchedulerContext";
import { IQISchedulerRow } from "../qiSchedulerTypes";

interface IQISchedulerYAxisItemProps {
  /** The relative depth of the row (starting at 1). */
  depth: number,
  /** The row to render. */
  row: IQISchedulerRow,
}

const QISchedulerYAxisItem: React.FC<IQISchedulerYAxisItemProps> = ({
  depth,
  row,
}: IQISchedulerYAxisItemProps) => {
  const ctx = useContext(QISchedulerContext);

  if (!ctx) {
    return null;
  }

  const rowHeight = ctx.rowItemDictionary[row.id]?.rowHeight;

  return (
    <React.Fragment
      key={row.id}
    >
      <div
        className={`yaxis-item row depth-${depth} ${row.textAlign ? `align-${row.textAlign}` : ""}`}
        style={rowHeight
          ? { height: rowHeight }
          : undefined
        }
      >
        <div
          className="collapse-switch-holder"
        >
          {row.children !== undefined
            && row.children.length > 0 &&
            <CollapseSwitch
              isOpen={row.isExpanded === undefined
                ? true
                : row.isExpanded
              }
              onClick={() => ctx.onRowToggle(row, row.isExpanded === undefined
                ? false
                : !row.isExpanded)
              }
            />
          }
        </div>

        <div
          className="row-label"
        >
          {row.text}
        </div>
      </div>

      {row.isExpanded !== false
        && row.children?.map(child =>
          <QISchedulerYAxisItem
            key={child.id}
            row={child}
            depth={depth + 1}
          />
        )}
    </React.Fragment>
  );
};

export default QISchedulerYAxisItem;