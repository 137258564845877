import { formatActionItem } from "api/actionItems/formatters/actionItemFormatters";
import { authDelete, authFetch, authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IExecutionWorkspaceData } from "store/audit-execution/AuditExecutionSlice";
import { ITopicCommentModalData } from "store/audit-report/AuditReportSlice";
import { IActionItem } from "types/actionItemTypes";
import { AuditNotificationRecipients, AuditStatuses, IAudit, IAuditComplianceScore, IAuditEvidenceItem, IAuditEvidenceResponse, IAuditInfo, IAuditQuestion, IAuditQuestionResponse, IAuditReport, IAuditReportGeneration, IAuditReportSectionAttachment, ICreateAuditResponse, IEvidenceLinkItem, IMyAudit, ISaveAuditCommentRequest, IUpdateSummaryRequest } from "types/auditingTypes";
import { IPagedResult } from "types/pageResultsTypes";
import { formatAudit, formatAuditEvidenceItem, formatAuditEvidenceResponse, formatAuditInfo, formatAuditQuestion, formatAuditQuestionResponse, formatAuditReport, formatAuditReportSectionAttachment, formatCreateAuditResponse, formatEvidenceItem, formatGenerateAuditReportRequest, formatGetAuditComplianceScoresRequest, formatGetAuditComplianceScoresResponse, formatLinkItem, formatSaveAuditCommentRequest, formatSaveAuditRequest, formatUpdateNonConformancesRequest } from "./formatters/auditFormatters";
import { IGetAuditComplianceScoresRequest, IUpdateNonComplianceResponsesRequest } from "./types/auditApiTypes";

class AuditsApi {
  public async getMyAudits(page?: number, abortSignal?: AbortSignal): Promise<IPagedResult<IMyAudit>> {
    const response = await authGetJson(config.endpoints.auditing.myAudits
      + `?page=${page}`, abortSignal);
    await throwIfResponseError(response);
    return (await response.json());
  }

  public async createAudit(audit: IAudit, abortSignal?: AbortSignal): Promise<ICreateAuditResponse> {
    const response = await authPostJson(config.endpoints.auditing.audits,
      formatSaveAuditRequest(audit, false),
      abortSignal);
    await throwIfResponseError(response);
    return formatCreateAuditResponse(await response.json());
  }

  public async updateAudit(audit: IAudit,
    allowDeletingAnsweredQuestions: boolean,
    abortSignal?: AbortSignal): Promise<void> {
    const url = `${config.endpoints.auditing.audits}/${audit.id}`;
    const response = await authPutJson(url,
      formatSaveAuditRequest(audit, allowDeletingAnsweredQuestions),
      abortSignal);
    await throwIfResponseError(response);
  }

  public async getAudit(auditId: number, abortSignal?: AbortSignal): Promise<IAudit> {
    const response = await authGetJson(`${config.endpoints.auditing.audits}/${auditId}`,
      abortSignal);

    await throwIfResponseError(response);

    const jsonObj = await response.json();

    return formatAudit(jsonObj);
  }

  public async getAuditInfo(auditId: number, abortSignal?: AbortSignal): Promise<IAuditInfo> {
    const response = await authGetJson(config.endpoints.auditing.auditInfo.replace(":id", auditId.toString()),
      abortSignal);

    await throwIfResponseError(response);

    const jsonObj = await response.json();

    return formatAuditInfo(jsonObj);
  }

  public async getAuditQuestions(auditId: number, abortSignal?: AbortSignal): Promise<IAuditQuestion[]> {
    const response = await authGetJson(`${config.endpoints.auditing.audits}/${auditId}/questions`,
      abortSignal);

    await throwIfResponseError(response);

    const jsonObj = await response.json();

    return Array.isArray(jsonObj)
      ? jsonObj.map((x: any) => formatAuditQuestion(x))
      : [];
  }

  public async getReport(auditId: number, abortSignal?: AbortSignal): Promise<IAuditReport> {
    const response = await authGetJson(`${config.endpoints.auditing.audits}/${auditId}/getReportSummary`,
      abortSignal);

    await throwIfResponseError(response);

    const jsonObj = await response.json();

    return formatAuditReport(jsonObj);
  }

  public async saveAuditComment(request: ISaveAuditCommentRequest, abortSignal?: AbortSignal): Promise<void> {

    const response = await authPostJson(`${config.endpoints.auditing.audits}/${request.auditId}/comments`,
      formatSaveAuditCommentRequest(request.comments),
      abortSignal);

    await throwIfResponseError(response);
  }

  public async saveTopicComment(auditId: number, comments: ITopicCommentModalData, abortSignal?: AbortSignal): Promise<void> {
    const response = await authPostJson(`${config.endpoints.auditing.audits}/${auditId}/comments/${comments.topicId}`,
      comments.topicComments,
      abortSignal);

    await throwIfResponseError(response);
  }

  public async updateSummary(request: IUpdateSummaryRequest, abortSignal?: AbortSignal): Promise<void> {
    const response = await authPostJson(`${config.endpoints.auditing.audits}/${request.id}/summary`,
      request,
      abortSignal);

    await throwIfResponseError(response);
  }

  public async deleteAudit(id: number, abortSignal?: AbortSignal): Promise<void> {
    const response = await authDelete(`${config.endpoints.auditing.audits}/${id}`,
      undefined,
      abortSignal);
    await throwIfResponseError(response);
  }

  public async startAudit(auditId: number, abortSignal?: AbortSignal): Promise<void> {
    const response = await authFetch("POST",
      config
        .endpoints
        .auditing
        .startAudit
        .replace(":id", auditId.toString()),
      abortSignal);
    await throwIfResponseError(response);
  }

  public async completeAudit(auditId: number, abortSignal?: AbortSignal): Promise<void> {
    const response = await authFetch("POST",
      config
        .endpoints
        .auditing
        .completeAudit
        .replace(":id", auditId.toString()),
      abortSignal);
    await throwIfResponseError(response);
  }

  public async closeAudit(auditId: number, abortSignal?: AbortSignal): Promise<void> {
    const response = await authFetch("POST",
      config
        .endpoints
        .auditing
        .closeAudit
        .replace(":id", auditId.toString()),
      abortSignal);
    await throwIfResponseError(response);
  }

  public async revertStatus(auditId: number, revertFromStatus: AuditStatuses, abortSignal?: AbortSignal): Promise<void> {
    const response = await authPostJson(`${config.endpoints.auditing.audits}/${auditId}/revertStatus`, revertFromStatus, abortSignal);

    await throwIfResponseError(response);
  }

  public async uploadQuestionEvidence(
    auditId: number,
    auditQuestionId: number,
    description: string,
    file: File,
    abortSignal?: AbortSignal,
  ): Promise<IEvidenceLinkItem> {
    let formData = new FormData();
    formData.append("File", file);
    formData.append("Description", description);
    formData.append("Filename", file.name);

    let response = await authFetch(
      "POST",
      config.endpoints.auditing.questionEvidence
        .replace(":auditId", auditId.toString())
        .replace(":auditQuestionId", auditQuestionId.toString()),
      formData,
      abortSignal,
    );
    await throwIfResponseError(response);
    return formatEvidenceItem(await response.json());
  }

  public async saveQuestionLink(
    id: number | undefined,
    auditId: number,
    auditQuestionId: number,
    description: string,
    link: string,
    abortSignal?: AbortSignal,
  ): Promise<IEvidenceLinkItem> {
    const response = await authPostJson(`${config.endpoints.baseUrl}/audits/:auditId/question/link`
      .replace(":auditId", auditId.toString()),
      {
        Id: id,
        AuditQuestionId: auditQuestionId,
        Link: link,
        Notes: description,
      },
      abortSignal);

    await throwIfResponseError(response);

    return formatLinkItem(await response.json());
  }

  public async getQuestionEvidenceUrl(
    auditId: number,
    auditQuestionId: number,
    evidenceId: number,
    abortSignal?: AbortSignal,
  ): Promise<string> {
    const response = await authGetJson(config.endpoints.auditing.questionEvidence
      .replace(":auditId", auditId.toString())
      .replace(":auditQuestionId", auditQuestionId.toString())
      + `/${evidenceId}`,
      abortSignal);

    await throwIfResponseError(response);

    const jsonObj = await response.json();

    return jsonObj.evidenceUrl;
  }

  public async getAuditEvidenceUrl(
    auditId: number,
    evidenceId: number,
    abortSignal?: AbortSignal,
  ): Promise<string> {
    const response = await authGetJson(config.endpoints.auditing.uploadAttachment
      .replace(":id", auditId.toString())
      + `/${evidenceId}`,
      abortSignal);

    await throwIfResponseError(response);

    const jsonObj = await response.json();

    return jsonObj.evidenceUrl;
  }

  public async deleteQuestionEvidence(
    auditId: number,
    auditQuestionId: number,
    evidenceId: number,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const response = await authDelete(config.endpoints.auditing.questionEvidence
      .replace(":auditId", auditId.toString())
      .replace(":auditQuestionId", auditQuestionId.toString())
      + `/${evidenceId}`,
      undefined,
      abortSignal);

    await throwIfResponseError(response);
  }

  public async deleteQuestionLink(
    id: number,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const response = await authDelete(`${config.endpoints.baseUrl}/audits/${id}/question/link/`,
      undefined,
      abortSignal);

    await throwIfResponseError(response);
  }

  public async updateQuestionEvidence(
    auditId: number,
    auditQuestionId: number,
    evidenceId: number,
    description: string,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const response = await authPutJson(config.endpoints.auditing.questionEvidence
      .replace(":auditId", auditId.toString())
      .replace(":auditQuestionId", auditQuestionId.toString())
      + `/${evidenceId}`, {
      description,
      abortSignal,
    });

    await throwIfResponseError(response);
  }

  public async submitQuestionResponse(
    auditId: number,
    auditQuestionId: number,
    questionResponse: IExecutionWorkspaceData,
    abortSignal?: AbortSignal,
  ): Promise<IAuditQuestion> {
    const response = await authPutJson(config.endpoints.auditing.questionResponse
      .replace(":auditId", auditId.toString())
      .replace(":auditQuestionId", auditQuestionId.toString()),
      {
        answer: questionResponse.answer,
        causalFactorId: questionResponse.causalFactor?.id,
        verificationMethodIds: questionResponse.selectedVerificationMethods?.map(x => x.id) || [],
        auditorNotes: questionResponse.notes,
        interviewees: questionResponse.interviewees?.map(x => ({
          name: x.name,
          email: x.email,
        })) || [],
      }, abortSignal);

    await throwIfResponseError(response);

    return formatAuditQuestion(await response.json());
  }

  public async deleteQuestionResponse(
    auditId: number,
    auditQuestionId: number,
    abortSignal?: AbortSignal,
  ): Promise<IAuditQuestion> {
    const response = await authDelete(config.endpoints.auditing.questionResponse
      .replace(":auditId", auditId.toString())
      .replace(":auditQuestionId", auditQuestionId.toString()),
      abortSignal);

    await throwIfResponseError(response);

    return formatAuditQuestion(await response.json());
  }

  public async getAuditExcelReport(id: number, abortSignal?: AbortSignal): Promise<Blob> {
    const exporturl = config
      .endpoints
      .auditing
      .exportAudit
      .replace(":id", id.toString());
    const response = await authGetJson(exporturl, abortSignal);
    await throwIfResponseError(response);
    return await response.blob();
  }

  public async getAuditEvidences(auditId: number, abortSignal?: AbortSignal): Promise<IAuditEvidenceResponse> {
    const response = await authGetJson(config.endpoints.auditing.auditEvidences
      .replace(":id", auditId.toString()), abortSignal);

    await throwIfResponseError(response);
    return formatAuditEvidenceResponse(await response.json());
  }

  public async uploadAuditAttachment(
    auditId: number,
    description: string,
    file: File,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    let formData = new FormData();
    formData.append("File", file);
    formData.append("Description", description);
    formData.append("Filename", file.name);

    let response = await authFetch(
      "POST",
      config.endpoints.auditing.uploadAttachment
        .replace(":id", auditId.toString()),
      formData,
      abortSignal,
    );
    await throwIfResponseError(response);
    return await response.json();
  }

  public async uploadAuditLink(
    auditId: string,
    description: string,
    link: string,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    let response = await authPostJson(`${config.endpoints.auditing.uploadLink}`,
      {
        AuditId: auditId,
        Notes: description,
        Link: link
      }, abortSignal);
    await throwIfResponseError(response);
    return await response.json();
  }

  public async updateAuditLink(
    auditId: number,
    description: string,
    linkId: number,
    url: string,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    let response = await authPutJson(config.endpoints.auditing.auditLink
      .replace(":auditId", auditId.toString())
      .replace(":linkId", linkId.toString()),
      {
        AuditId: auditId,
        Description: description,
        AuditLinkId: linkId,
        URL: url
      }, abortSignal);
    await throwIfResponseError(response);
    return {
      id: auditId,
      notes: description,
      url
    };
  }

  public async updateAuditAttachment(
    auditId: number,
    id: number,
    parentId: number,
    description: string,
    filename: string,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    let response = await authPutJson(config.endpoints.auditing.auditAttachment
      .replace(":auditId", auditId.toString())
      .replace(":id", id.toString() === 'NaN' ? "0" : id.toString())
      .replace(":parentId", parentId.toString() === 'NaN' ? "0" : parentId.toString()),
      {
        AuditId: auditId,
        Description: description,
        Filename: filename
      }, abortSignal);
    await throwIfResponseError(response);
    return {
      id: auditId,
      notes: description,
      filename
    };
  }

  public async deleteAuditAttachment(
    auditId: number,
    id: number,
    parentId: number,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const response = await authDelete(config.endpoints.auditing.auditAttachment
      .replace(":auditId", auditId.toString())
      .replace(":id", id.toString() === 'NaN' ? "0" : id.toString())
      .replace(":parentId", parentId.toString() === 'NaN' ? "0" : parentId.toString()),
      undefined,
      abortSignal);

    await throwIfResponseError(response);
  }

  public async deleteAuditLink(
    auditId: number,
    linkId: number,
    abortSignal?: AbortSignal,
  ): Promise<Blob> {
    const response = await authDelete(config.endpoints.auditing.auditLink
      .replace(":auditId", auditId.toString())
      .replace(":linkId", linkId.toString()),
      undefined,
      abortSignal);

    await throwIfResponseError(response);
    return await response.blob();
  }

  public async updateNonConformanceResponse(data: IUpdateNonComplianceResponsesRequest, abortSignal?: AbortSignal): Promise<IAuditQuestionResponse[]> {
    let url = config
      .endpoints
      .auditing
      .nonConformances
      .replace(":id", data.auditId.toString());

    const response = await authPutJson(url, formatUpdateNonConformancesRequest(data), abortSignal);
    await throwIfResponseError(response);

    const result = await response.json();
    return Array.isArray(result)
      ? result.map(formatAuditQuestionResponse)
      : [];
  }

  public async getImageAttachments(id: number, abortSignal?: AbortSignal): Promise<IAuditReportSectionAttachment[]> {
    const url = config
      .endpoints
      .auditing
      .auditAllImagesEvidences
      .replace(":id", id.toString());
    const response = await authGetJson(url, abortSignal);
    await throwIfResponseError(response);
    const jsonObj = await response.json();
    return Array.isArray(jsonObj)
      ? jsonObj.map((a: any) => formatAuditReportSectionAttachment(a))
      : [];
  }

  public async generateAuditReport(auditId: number,
    request: IAuditReportGeneration,
    abortSignal?: AbortSignal): Promise<{
      evidenceUrl: string,
      createdEvidence: IAuditEvidenceItem,
    }> {

    const url = `${config
      .endpoints
      .auditing
      .auditGenerateReport}`.replace(":id", auditId.toString());

    const formattedData = formatGenerateAuditReportRequest(request);
    let response = await authPostJson(url, formattedData, abortSignal);

    await throwIfResponseError(response);

    const json = await response.json();
    return {
      evidenceUrl: json.url?.toString() || "",
      createdEvidence: formatAuditEvidenceItem(json.createdEvidence),
    };
  }

  public async getAuditComplianceScores(request: IGetAuditComplianceScoresRequest,
    abortSignal?: AbortSignal): Promise<IAuditComplianceScore[]> {
    const queryString = formatGetAuditComplianceScoresRequest(request);

    const response = await authGetJson(`${config.endpoints.auditing.auditComplianceScores}?${queryString}`,
      abortSignal);

    await throwIfResponseError(response);

    return formatGetAuditComplianceScoresResponse(await response.json());
  }

  public async getAllActionItemsForAudit(auditId: number, abortSignal?: AbortSignal): Promise<IActionItem[]> {
    const response = await authGetJson(config
      .endpoints
      .auditing
      .getAllActionItemsInAudit
      .replace(":id", auditId.toString()),
      abortSignal);

    await throwIfResponseError(response);

    const jsonObj = await response.json();

    return Array.isArray(jsonObj)
      ? jsonObj.map((a: any) => formatActionItem(a))
      : [];
  }

  public async notifyTeam(auditId: number,
    recipients: AuditNotificationRecipients[],
    abortSignal?: AbortSignal): Promise<void> {
    const response = await authPostJson(config
      .endpoints
      .auditing
      .notifyTeam
      .replace(":id", auditId.toString()),
      {
        recipients,
      },
      abortSignal);

    await throwIfResponseError(response);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditsApi();
