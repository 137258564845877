import React, { useEffect } from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { FindingLinkType } from "store/audit/reducers/findingReducers";
import { useAppSelector } from "store/store";

interface IUnlinkModalBodyProps {
  selectedLinkIds: number[],
  setSelectedLinkIds(values: number[]): void,
}

const UnlinkModalBody: React.FC<IUnlinkModalBodyProps> = ({
  selectedLinkIds,
  setSelectedLinkIds,
}) => {
  const modalState = useAppSelector(store => store.audit.findingModal);
  const questions = useAppSelector(store => store.audit.questions);

  const {
    autoSelectedLinksForRemoval,
    currentFinding
  } = modalState;

  const availableLinks = modalState
    .currentFinding
    ?.links
    ?.filter(link => link.linkType === FindingLinkType.AuditQuestion)
    .map(x => ({
      questionNumber: questions
        .find(q => q.auditQuestionId === x.linkId)
        ?.questionNumber,
      link: x,
    })) || [];

  useEffect(() => {
    setSelectedLinkIds(
      autoSelectedLinksForRemoval
        .map(a => (currentFinding
          ?.links ?? [])
          .find(x => x.linkId === a.linkId
            && x.linkType === a.linkType))
        .map(x => x?.id)
        .filter(isNotUndefined)
    );
  }, [setSelectedLinkIds,
    autoSelectedLinksForRemoval,
    currentFinding]);


  function toggleSelectedItem(id: number, isSelected: boolean) {
    if (isSelected
      && !selectedLinkIds.includes(id)) {
      setSelectedLinkIds(selectedLinkIds.concat(id));
    } else if (!isSelected
      && selectedLinkIds.includes(id)) {
      setSelectedLinkIds(selectedLinkIds.filter(x => x !== id));
    }
  }

  return (
    <>
      <span>
        {availableLinks.length > 1
          ? "Select one or more links to remove from this finding. If all the links are removed, then the finding will be deleted & action items associated to the finding will also be removed."
          : "The Finding is linked to the following items. Are you sure you want to delete this finding and the associated action items?"
        }
      </span>

      {availableLinks.length > 1
        ? (
          <FlexCol>
            {availableLinks.map(linkInfo => (
              <label
                key={linkInfo.link.id}
              >
                <input
                  type="checkbox"
                  checked={selectedLinkIds.includes(linkInfo.link.id)}
                  onChange={e => toggleSelectedItem(linkInfo.link.id,
                    e.currentTarget.checked)
                  }
                />
                Question #{linkInfo.questionNumber ?? linkInfo.link.linkId}
              </label>
            ))}
          </FlexCol>
        ) : (
          <label>
            Question #{availableLinks[0]?.questionNumber
              ?? availableLinks[0]?.link.linkId}
          </label>
        )
      }
    </>
  );
};

export default UnlinkModalBody;