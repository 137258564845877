import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { ICustomSearchListItem } from "types/customSearchTypes";

export interface ICustomSearchActionState {
  customSearchAuditList: ICustomSearchListItem[] | undefined,
  customSearchActionList: ICustomSearchListItem[] | undefined,
  loadCustomSearchList?: IOperation<void>,  
  filtersNameAudit: string | undefined,
  filtersNameAction: string | undefined,
  cofirmationDeleteModalOpen: boolean,
  cofirmationSameNameModalOpen: boolean,
}

const initialState: ICustomSearchActionState = {
  customSearchAuditList: [],
  customSearchActionList: [],
  loadCustomSearchList: undefined,
  filtersNameAudit: undefined,
  filtersNameAction: undefined,
  cofirmationDeleteModalOpen: false,
  cofirmationSameNameModalOpen: false,
}

export const customSearchSlice = createSlice({
  name: "custom-search",
  initialState,
  reducers:{
    setSearchPropValue: (state, action: PayloadAction<Partial<ICustomSearchActionState>>) => {
      Object.assign(state, action.payload);
    },
    loadCustomSearchList: (state) => {
      state.loadCustomSearchList = {
        isWorking: true,
      };
    },
    finishLoadCustomSearchList: (state, action: PayloadAction<IOperation<{ auditList: ICustomSearchListItem[]; actionList: ICustomSearchListItem[]; }>>) => {
      if (action.payload.errorMessage
        || !action.payload.data) {

        return;
      }

      state.customSearchAuditList = action.payload.data.auditList;
      state.customSearchActionList = action.payload.data.actionList;
      state.loadCustomSearchList = undefined;
    },
    addCustomSearchItem: (state, action: PayloadAction<{
      savedFilters: ICustomSearchListItem,
      type: "audit" | "action",
    }> ) => {
      if (action.payload.type === "audit") {
        state.customSearchAuditList?.push(action.payload.savedFilters);
      } else if (action.payload.type === "action") {
        state.customSearchActionList?.push(action.payload.savedFilters);
      }
    },
    removeCustomSearchItem: (state, action: PayloadAction<{
      type: "audit" | "action",
      id: number
    }> ) => {
      if (action.payload.type === "audit") {
        const index = state.customSearchAuditList?.findIndex(x => x.id === action.payload.id);
        state.customSearchAuditList?.splice(index!, 1);
      } else if (action.payload.type === "action") {
        const index = state.customSearchActionList?.findIndex(x => x.id === action.payload.id);
        state.customSearchActionList?.splice(index!, 1);
      }
    },
    setFiltersName: (state, action: PayloadAction<{
      name: string,
      type: "audit" | "action",
    }>) => {
      if (action.payload.type === "audit") {
        state.filtersNameAudit = action.payload.name;
      } else if (action.payload.type === "action") {
        state.filtersNameAction = action.payload.name;
      }
    },
    clearFiltersNames: (state) => {
      state.filtersNameAudit = undefined;
      state.filtersNameAction = undefined;
    }
  }
})

export const {
  setSearchPropValue,
  loadCustomSearchList,
  finishLoadCustomSearchList,
  addCustomSearchItem,
  removeCustomSearchItem,
  setFiltersName,
  clearFiltersNames,
} = customSearchSlice.actions;