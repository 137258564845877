import React, { useCallback, useEffect, useRef, useState } from "react";
import Divider from "shared/components/common/divider/Divider";
import cornerGripIcon from "shared/media/dls/corner-grip.svg";
import { smallScreenMaxWidth, useWindowSize } from "shared/utilities/windowUtilities";
import Portal from "../portal/Portal";
import "./Modal.scoped.scss";

export interface IModalProps {
  /**
   * Specifies if the modal is open or not. If not open, it does not render.
   */
  isOpen: boolean,
  /**
   * Specifies if the close button (x) should be shown on the right side of the header.
   */
  showCloseButton?: boolean,
  /**
   * Specifies what should be shown at the top of the modal.
   * Default is false.
   */
  header?: string | React.ReactNode,
  /**
   * Specifies a hard-coded width. If not specified, it will conform to its content's width.
   */
  width?: number | string,
  /**
   * Specifies a minimum width.
   */
  minWidth?: number | string,
  /**
   * Specifies a hard-coded max-width.
   */
  maxWidth?: number | string,
  /**
   * Specifies the modal's theme for various coloring.
   */
  theme?: ModalTheme,
  /**
   * Specifies the callback for when the closeButton is clicked.
   */
  onCloseButtonClicked?(): void,
  /**
   * If bottomLeftNode are provided, they will be rendered at the bottom left of the modal.
   */
  bottomLeftNode?: React.ReactNode,
  /**
   * The array of buttons that should be rendered (in order) at the bottom of the modal.
   */
  buttons?: ModalButton[],
  /** An extra class name to apply to the modal itself. */
  className?: string,
  /**  */
  children?: React.ReactNode,
  /** Optional. Determines the display size. Default = minimum. */
  size?: ModalSizes,
}

export enum ModalSizes {
  minimum = "size-minimum",
  maximum = "size-maximum",
  maximumHeight = "size-maximumHeight",
}

export type ModalButton = IModalButton & React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface IModalButton {
  key: string,
  text: React.ReactNode,
  img?: string,
  imgclassname?: string,
  imgAlt?: string;
  disabled?: boolean;
}

export enum ModalTheme {
  Normal = "modal",
  Error = "modal error",
}

interface IChangeVector {
  x: number,
  y: number,
  isActive: boolean,
}

const Modal: React.FC<IModalProps> = (props) => {
  const winSize = useWindowSize();
  const winHeight = winSize.height || 0;
  const winWidth = winSize.width || 0;

  const modalWidth = props.width;
  const modalSize = props.size;
  const [dragVector, setDragVector] = useState<IChangeVector>({ x: 0, y: 0, isActive: false, });
  const [resizeVector, setResizeVector] = useState<IChangeVector>({ x: 0, y: 0, isActive: false, });
  const [initialModalSize, setInitialModalSize] = useState<IChangeVector>({ x: 0, y: 0, isActive: false });

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current
      && modalWidth === undefined
      && modalSize === undefined) {
      modalRef.current.style.position = "relative";
      modalRef.current.style.top = "initial";
      modalRef.current.style.left = "initial";
      modalRef.current.style.width = "initial";
      modalRef.current.style.height = "initial";
    }
  }, [winHeight, winWidth, modalRef, modalWidth, modalSize]);

  const onDragMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    // If modal is fullscreen, disallow.
    if (winWidth <= smallScreenMaxWidth
      || !modalRef.current) {
      return;
    }

    modalRef.current.className += " no-text-select";

    setDragVector({
      x: e.screenX - modalRef.current.getBoundingClientRect().left,
      y: e.screenY - modalRef.current.getBoundingClientRect().top,
      isActive: true,
    });
  }, [setDragVector, winWidth, modalRef]);

  const onMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!modalRef.current) {
      return;
    }

    if (dragVector.isActive) {
      modalRef.current.style.left = e.screenX - dragVector.x + "px";
      modalRef.current.style.top = e.screenY - dragVector.y + "px";
      modalRef.current.style.position = "fixed";
    } else if (resizeVector.isActive) {
      const rect = modalRef.current.getBoundingClientRect();

      modalRef.current.style.top = rect.top + "px";
      modalRef.current.style.left = rect.left + "px";
      modalRef.current.style.width = initialModalSize.x + (e.pageX - resizeVector.x) + "px";
      modalRef.current.style.height = initialModalSize.y + (e.pageY - resizeVector.y) + "px";
      modalRef.current.style.position = "fixed";
    }
  }, [dragVector, resizeVector, modalRef, initialModalSize]);

  const onMouseUp = useCallback(() => {
    if (modalRef.current) {
      modalRef.current.className = modalRef.current.className.replace(" no-text-select", "");
    }

    setDragVector({
      x: 0,
      y: 0,
      isActive: false,
    });
    setResizeVector({
      x: 0,
      y: 0,
      isActive: false,
    });
  }, [setDragVector, setResizeVector, modalRef]);

  const onResizeMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    // If modal is fullscreen, disallow.
    if (winWidth <= smallScreenMaxWidth
      || !modalRef.current) {
      return;
    }

    setResizeVector({
      x: e.pageX,
      y: e.pageY,
      isActive: true,
    });

    modalRef.current.className += " no-text-select";

    setInitialModalSize({
      x: modalRef.current.getBoundingClientRect().width,
      y: modalRef.current.getBoundingClientRect().height,
      isActive: false,
    });
  }, [setResizeVector, winWidth, modalRef, setInitialModalSize]);

  useEffect(() => {
    let pageOffset = window.pageYOffset;

    if (props.isOpen) {
      document.body.style.top = `-${pageOffset}px`;
      document.body.classList.add('has-open-modal');
    }

    return () => {
      document.body.classList.remove('has-open-modal');
      document.body.style.removeProperty('top');
      window.scrollTo(0, pageOffset || 0);
    };
  }, [props.isOpen]);

  if (!props.isOpen) {
    return null;
  }

  const canMoveAndResize = (props.size ?? ModalSizes.minimum) === ModalSizes.minimum;

  return (
    <Portal
      className="modal-portal"
    >
      <div
        className="fade"
        onClick={props.onCloseButtonClicked}
        onMouseMove={canMoveAndResize ? onMouseMove : undefined}
        onMouseUp={canMoveAndResize ? onMouseUp : undefined}
      >
      </div>

      <div
        className={`${props.theme || ModalTheme.Normal} ${props.className || ""} ${props.size ?? ""} ${canMoveAndResize ? "movable resizable" : ""}`}
        style={{
          width: props.width,
          minWidth: props.minWidth,
          maxWidth: props.maxWidth,
        }}
        ref={modalRef}
        onMouseMove={canMoveAndResize ? onMouseMove : undefined}
        onMouseUp={canMoveAndResize ? onMouseUp : undefined}
      >
        <div
          className="top header-bar no-text-select"
          onMouseDown={canMoveAndResize ? onDragMouseDown : undefined}
          onMouseUp={canMoveAndResize ? onMouseUp : undefined}
          onMouseMove={canMoveAndResize ? onMouseMove : undefined}
        >
          <span
            className="header header-bar"
          >
            <div
              className="header-bar"
            >
              {props.header}
            </div>
            <Divider />
          </span>
          {props.showCloseButton &&
            <span
              className="close"
              onClick={props.onCloseButtonClicked}
            >
              x
            </span>
          }
        </div>
        <div
          className="middle"
        >
          {props.children}
        </div>
        {(props.bottomLeftNode
          || props.buttons) &&
          <div
            className="bottom"
          >
            {props.bottomLeftNode &&
              <div
                className="bottomLeftNode"
              >
                {props.bottomLeftNode}
              </div>}

            {props.buttons &&
              <div
                className="buttons"
              >
                {props.buttons?.map(btnProps => {
                  let btnAttrs = { ...btnProps };
                  delete btnAttrs.text;
                  const key = btnProps.key;
                  delete (btnAttrs as any).key;

                  return (
                    <button
                      {...btnAttrs}
                      key={key}
                    >
                      {btnProps?.img && <img className={btnProps?.imgclassname ? btnProps?.imgclassname : ""} src={btnProps?.img} alt={btnProps?.imgAlt} />} {btnProps.text}
                    </button>
                  );
                })}
              </div>}
          </div>
        }

        {canMoveAndResize && (
          <div
            className="corner-grip"
            draggable={false}
            onMouseDown={onResizeMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
            style={{
              backgroundImage: `url(${cornerGripIcon})`,
            }}
          >
          </div>
        )}
      </div>
    </Portal>
  );
};

export default Modal;
