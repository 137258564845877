import AppliedFilters from "components/search/filters-tooltip/AppliedFilters";
import React from "react";
import FiltersTooltip from "./FiltersTooltip";
import useCalendarFilterInfo from "./useCalendarFilterInfo";

const AppliedFiltersLabel: React.FC = () => {
  const filterInfo = useCalendarFilterInfo();

  return (
    <AppliedFilters
      numAppliedFilters={filterInfo.count}
      tooltipContent={
        <FiltersTooltip
          filterInfo={filterInfo}
        />
      }
      maxTooltipHeight={350}
    />
  );
};

export default AppliedFiltersLabel;