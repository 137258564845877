import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import { groupBy } from "lodash";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import { doesScoringSystemAllowFindings } from "shared/utilities/findingUtilities";
import { setOpenAssignFindingToQuestions } from "store/audit-finding/AuditFindingSlice";
import { hideFindings, viewFindings } from "store/audit-rwp/AuditRWPSlice";
import { removeAllActionsByLink } from "store/audit/AuditSlice";
import { FindingLinkType } from "store/audit/reducers/findingReducers";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes } from "types/actionItemTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import AuditPage from "../common/audit-page/AuditPage";
import FindingsCard from "./FindingsCard";
import AssignFindingToQuestionsModal from "./assign-finding-to-questions/AssignFindingToQuestions";
import FindingsOverviewCard from "./findings-overview/FindingsOverviewCard";
import ManageFindingModal from "./manage-finding-modal/ManageFindingModal";
import ViewFindingsModal from "./view-findings-modal/ViewFindingsModal";
import { AuditStatuses } from "types/auditingTypes";

const AuditFindingsPage: React.FC = () => {
  const questions = useAppSelector(store => store.audit.questions);
  const audit = useAppSelector(store => store.audit.audit);
  const findings = useAppSelector(store => store.audit.findings);
  const findingLinks = findings.flatMap(x => x.links);
  const isFindingsModalOpen = useAppSelector(store => store.audit.findingModal.isOpen);
  const isAssignFindingToQuestionsOpen = useAppSelector(store => store.auditFinding.isAssignFindingToQuestionsOpen);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const {
    isOpen: isFindingModalOpen,
    findings: inspectedFindings,
  } = useAppSelector(store => store.auditRWP.viewFindingsModal);

  const {
    auditId,
    findingId,
  } = useParams();

  const scoringSystem = questions[0]?.scoringSystem;

  const isPageUnavailable = !doesScoringSystemAllowFindings(scoringSystem);

  const isEditable = auditPageAuth.editRestriction === EditRestriction.EditAll
    && audit?.status === AuditStatuses.InProgress;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const finding = findings.find(x => x.id === Number(findingId));
    if (finding !== undefined) {
      dispatch(viewFindings([finding]));
    }
  }, [findingId, findings, dispatch]);

  return (
    <AuditPage
      backButtonRoute={formatRoute(UrlRoutes.AuditSummary, { auditId: auditId || "0" })}
      title="Audit Findings"
      loadFindingTypes={true}
      auditId={Number(auditId)}
      overviewSettings={{
        hideCard: isPageUnavailable,
        showComplianceScore: true,
        showQuestionCompletionPercent: true,
        showQuestionCounts: true,
      }}
    >
      {!doesScoringSystemAllowFindings(scoringSystem)
        ? (
          <Banner
            type={BannerType.error}
          >
            Audits that use the {scoringSystem} scoring system do not support findings. Please use one of the tabs above to visit another page for this audit.
          </Banner>
        ) : (
          <>
            <FindingsOverviewCard
              isEditable={isEditable}
            />

            {Object
              .entries(groupBy(questions, x => x.topicId))
              .map(group => (
                <FindingsCard
                  key={group[0]}
                  topicId={Number(group[0])}
                  title={group[1][0].topicName}
                  questions={group[1]}
                />
              ))}

            {isAssignFindingToQuestionsOpen &&
              <AssignFindingToQuestionsModal
                questions={questions
                  .filter(x => x.responses
                    .some(y => y.auditQuestionId === x.auditQuestionId
                      && y.auditStatus === audit?.status
                      && y.answer !== undefined))
                  .filter(x => !findingLinks
                    .some(y => y.linkId === x.auditQuestionId
                      && y.linkType === FindingLinkType.AuditQuestion))}
              />
            }

            {isFindingsModalOpen &&
              <ManageFindingModal
                isFindingsPage={true}
                saveToServerOnSave
                onSave={() => {
                  dispatch(setOpenAssignFindingToQuestions(false));
                  return true;
                }}
                onLinksRemoved={removedLinks => {
                  if (!removedLinks.length) {
                    return;
                  }

                  const finding = findings.find(x => x.id === removedLinks[0].findingId);

                  if (!finding?.id) {
                    return;
                  }

                  if (!finding
                    ?.links
                    .filter(l => !removedLinks.some(r => r.id === l.id))
                    .length) {
                    // All links were removed.
                    dispatch(removeAllActionsByLink({
                      linkId: finding.id.toString(),
                      linkType: ActionItemLinkTypes.AuditFinding,
                    }));
                  }
                }}
              />
            }

            {isFindingModalOpen && (
              <ViewFindingsModal
                findings={inspectedFindings}
                onClose={() => dispatch(hideFindings())}
              />
            )}
          </>
        )}
    </AuditPage>
  );
};

export default AuditFindingsPage;