import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import QISelect from "shared/components/controls/select/QISelect";
import { setSearchPropValue } from "store/search/actions/SearchActionsSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const Validated: React.FC = () => {
  const value = useAppSelector(store => store.searchActions.isValidated);
  const dispatch = useAppDispatch();

  return (
    <LabeledControl
      label="Validated"
    >
      <QISelect
        items={[{
          id: "true",
          text: "Yes",
        }, {
          id: "false",
          text: "No",
        }]}
        selectedItemIds={value === undefined
          ? []
          : [value.toString()]
        }
        onChange={selectedItems => dispatch(setSearchPropValue({
          isValidated: !selectedItems.length
            ? undefined
            : selectedItems[0].id === "true",
        }))}
        allowClearButton={true}
        selectMode="single"
      />
    </LabeledControl>
  );
};

export default Validated;