import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $insertNodes } from 'lexical';
import { useEffect } from "react";

export default function RestoreHTMLPlugin({ html }: { html: string; }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      const pageCtrl = document.querySelector(".page-min-layout");
      const scrollTop = pageCtrl?.scrollTop ?? 0;
      const scrollLeft = pageCtrl?.scrollLeft ?? 0;

      // In the browser you can use the native DOMParser API to parse the HTML string.
      const parser = new DOMParser();
      const dom = parser.parseFromString(html, "text/html");

      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editor, dom);

      // Get the root node.
      const root = $getRoot();

      // Clear anything inside it.
      root.getChildren().forEach(x => x.remove());

      // Select the root
      root.select();

      // Insert the parsed html at a selection.
      $insertNodes(nodes);

      // Ugly hack. Sorry.
      // After setting the initial value, find the page body and scroll it back to the top.
      // This should hopefully scroll the page back to the top after we're done here.
      window.setTimeout(() => {
        document.querySelector(".page-min-layout")?.scrollTo(scrollLeft, scrollTop);
        // Unfocus the current active element. However, if user is typing into the box, this
        // blurs on every change...
        // if (document.activeElement
        //   && document.activeElement instanceof HTMLElement) {
        //   document.activeElement.blur();
        // }
      });
    });
  }, [editor, html]);
  return null;
}