import UrlRoutes from "components/routing/UrlRoutes";
import React from "react";
import { useMatch } from "react-router-dom";
import TabSelection from "shared/components/layout/tab-selection/TabSelection";

const AuditPlanningAdministrationTabs: React.FC = () =>
  <TabSelection
    className="search-tabs"
    tabs={[{
      key: "FacilityAttributesAdmin",
      node: "Facility Attributes Admin",
      isLink: true,
      isSelected: !!useMatch(UrlRoutes.AdminFacilityAttribute.urlTemplate),
      linkUrl: UrlRoutes.AdminFacilityAttribute.urlTemplate,
    }, {
      key: "PlanApprovalAdmin",
      node: "Plan Approval Admin",
      isLink: true,
      isSelected: !!useMatch(UrlRoutes.AdminPlanApproval.urlTemplate),
      linkUrl: UrlRoutes.AdminPlanApproval.urlTemplate,
    }]}
  />;

export default AuditPlanningAdministrationTabs;