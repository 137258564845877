import { useState } from "react";
import Spinner from "shared/components/common/spinner/Spinner";
import { INonReduxPickerProps } from "shared/components/controls/picker/Picker";
import PickerList from "shared/components/controls/picker/list/PickerList";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IOperation } from "shared/types/operationTypes";
import { IPickerItem } from "shared/types/pickerTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";
import { convertToPickerItem } from "shared/utilities/pickerUtiilities";
import "./NonReduxShowPicker.scoped.scss";

/** Tags properties to be rendered on the right side of the list. */
interface ITagRecapProps<T> {
  /** The tags group's label that will be rendered */
  label: string,
  /** How the tag item will be rendered */
  renderItem: (item: IPickerItem<T>) => React.ReactNode,
  /** How to filter the items (from the selected ones) to be shown on 
   * the current group */
  filterList?: (item: T) => boolean,
}

type INonReduxPickerListProps<T> = INonReduxPickerProps<T> & {
  /** The selected items to be rendered and selected on the list. */
  selectedItems: T[],
  /** The event to call whenever a select or deselect action is performed. */
  onSelectionChanged: (items: T[]) => void,
  /** How the list item will be rendered on the list. */
  renderListItem: (item: T) => string | React.ReactNode,
  /** A mapper to get the key for each one of the items on the list. */
  keyMapper: (item: T) => string | number,
  searchOptions?: {
    /**
      * Receives one of the picker items and a search string and must return whether or not it should be visible.
      * If this is not specified, the picker will attempt to filter by the item's text property.
      */
    filterItem?: (a: T, filterValue: string) => boolean,
  },
  /** A function to get the items of the list. */
  onLoadItems: (searchValue: string | undefined, abortSignal: AbortSignal) => Promise<T[]>,
  /** How to get a parent item's childs to be rendered hierarchically. */
  childMapper?: (item: T, allItems: T[]) => T[],
  /** How to filter the items by the searchValue (This is a syncronous call) */
  filterItems?: (item: T, valueSearched: string) => boolean,
  /** An array containing the properties of the tags that are going to be rendered on right side. 
   * If none was give, the tags are not rendered.
  */
  tagsProps?: ITagRecapProps<T>[],
  /** Flag to determine if the selection is required or not. */
  isRequired?: boolean,
  /** Optional. If specified, each item will call this function to determine if it should be disabled or not. */
  isDisabledMapper?: (item: T, ancestorPath: T[]) => boolean,

  expandedKeyIds?: (string | number)[],
}

const NonReduxShowPicker = <T,>(props: INonReduxPickerListProps<T>) => {
  const {
    selectedItems,
    renderListItem,
    keyMapper,
    onLoadItems,
    childMapper,
    filterItems,
    displayMode = 'tree',
    isDisabled,
    isDisabledMapper,
    expandedKeyIds,
  } = props;

  const [availableItems, setAvailableItems] = useState<T[]>([]);
  const [searchValue] = useState<string>("");
  const [expandedKeys, setExpandedKeys] = useState<(string | number)[]>([...(expandedKeyIds ?? [])]);
  const [loadOp, setLoadOperation] = useState<IOperation<T> | undefined>();

  useAsyncEffect(async (aborted, abortSignal) => {
    try {
      setLoadOperation({
        isWorking: true,
      });

      const allItems = await onLoadItems(undefined, abortSignal);
      if (aborted) {
        return;
      }

      setAvailableItems(allItems);
      setLoadOperation(undefined);
    } catch (err) {
      if (aborted) {
        return;
      }

      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      });
    }
  }, []);


  const toPickerItem = (item: T, includeChildren?: boolean, allItems?: T[]): IPickerItem<T> => {
    return convertToPickerItem(item, expandedKeys, keyMapper, childMapper, includeChildren, allItems);
  };


  /** Filters the items synchronously. */
  let visibleItems: IPickerItem<T>[] = [...availableItems.map(x => toPickerItem(x, true, availableItems))];
  if (props.searchOptions?.behavior !== "async" && filterItems) {
    const containsChildren = (item: IPickerItem<T>, search: string) => {
      if (item.children && item?.item && displayMode === "tree") {
        return item.children.some(x => filterItems(x.item as T, search));
      }
      return false;
    }

    const filterChildren = (item: IPickerItem<T>, search: string) => {
      if (item.children && item?.item) {
        item.children = item.children.filter(x => filterItems(x.item as T, search));
      }
    }

    const searchLowered = searchValue.toLowerCase();
    visibleItems = visibleItems.filter(item => {
      if (!item?.item) {
        return false;
      }

      filterChildren(item, searchLowered);

      return filterItems(item?.item as T, searchLowered)
        || containsChildren(item, searchLowered);
    });
  }

  const defaultNoItemsMessage = "No items were found.";

  return (
    <FlexCol>
      <FlexCol className="list-box">
        {loadOp?.isWorking &&
          <Spinner />
        }
        {!loadOp?.isWorking &&
          <FlexRow>
            <PickerList
              items={visibleItems}
              renderItem={renderListItem}
              allowMultiSelect={props.allowMultiSelect}
              noItemsMessage={props.noItemsMessage ?? defaultNoItemsMessage}
              selectedItems={selectedItems.map(x => toPickerItem(x))}
              displayMode={displayMode}
              onItemSelected={() => {}}
              onItemDeselected={() => {}}
              onItemExpanded={(item: IPickerItem<T>, _?: (string | number)[]) => {
                setExpandedKeys([...expandedKeys, item.key]);
              }}
              onItemCollapsed={(item: IPickerItem<T>, _?: (string | number)[]) => {
                setExpandedKeys(expandedKeys.filter(x => x !== item.key));
              }}
              isDisabledMapper={isDisabledMapper}
              isDisabled={isDisabled}
            />
          </FlexRow>
        }
      </FlexCol>
    </FlexCol>
  );
}

export default NonReduxShowPicker;