import AuditPlanningFilters from "components/audits/planning/planning-filters/AuditPlanningFilters";
import React from "react";
import Hint from "shared/components/common/hint/Hint";
import AppliedFiltersLabel from "../applied-filters-label/AppliedFiltersLabel";
import ClearSelectionButton from "../clear-selection-button/ClearSelectionButton";
import CreateAuditButton from "../create-audit-button/CreateAuditButton";
import MaxMinSchedulerButton from "../max-min-scheduler-button/MaxMinSchedulerButton";
import SchedulerModeToggle from "../scheduler-mode-toggle/SchedulerModeToggle";
import "./SchedulerHeader.scoped.scss";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";

const SchedulerHeader: React.FC = () => {
  return (
    <div className="scheduler-card-header">
      <div
        className="perspective-row"
      >
        <AuditPlanningFilters
          showMoreFilters
          showComplianceResults
          showBusinessViews
          showFacilities
          showAuditCalendarStatuses
          showCalendarAuditTypes
          showLeadAuditors
          showUnassigned
          showIncludeDeletedAttributes={false}
          showPlanStatus={false}
          showReviewStatus={false}
          showReviewedBy={false}
        />

        <div className="min-max-buttons">
          <LabeledControl label="&nbsp;">
            <MaxMinSchedulerButton />
          </LabeledControl>
        </div>
      </div>

      <div
        className="buttons-row"
      >
        <div>
          <SchedulerModeToggle />
        </div>

        <div>
          <Hint className="info">
            Use ctrl+click to select<br />
            plans for audit creation.
          </Hint>

          <ClearSelectionButton />
          <CreateAuditButton />
          <AppliedFiltersLabel />
        </div>
      </div>
    </div>
  );
};

export default SchedulerHeader;