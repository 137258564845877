import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import QISelect from "shared/components/controls/select/QISelect";
import { setSearchPropValue } from "store/search/audits/SearchAuditsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuditStatuses } from "types/auditingTypes";

const targetProp = "status";

const AuditStatus: React.FC = () => {
  const statuses = useAppSelector(store => store.searchAudits[targetProp]);
  const dispatch = useAppDispatch();

  return (
    <LabeledControl
      label="Status"
    >
      <QISelect
        items={Object.keys(AuditStatuses).map(x => ({
          id: x,
          text: x,
        }))}
        selectedItemIds={statuses}
        onChange={items =>
          dispatch(setSearchPropValue({
            [targetProp]: items.map(val => AuditStatuses[val.id as keyof typeof AuditStatuses]),
          }))
        }
        selectMode="multi"
      />
    </LabeledControl>
  );
};

export default AuditStatus;