import React from "react";
import plusIcon from "shared/media/dls/plus.svg";
import CircleImgButton from "../CircleImageButton";

interface IPlusButtonProps {
  onClick?(): void,
}

const PlusButton: React.FC<IPlusButtonProps> = ({
  onClick,
}) =>
  <CircleImgButton
    alt="+"
    icon={plusIcon}
    type="secondary"
    iconSize="small"
    onClick={onClick}
  />;

export default PlusButton;

