import { PopupPosition, Tooltip as wjTooltip } from "@mescius/wijmo";
import React, { useCallback, useRef } from "react";
import Portal from "shared/components/layout/portal/Portal";

export type TooltipPosition = "Above" | "Below";

interface ITooltipElementProps {
  /** Optional. The string to show in the tooltip. Either this or `tooltipContent` is required. */
  tooltipString?: string,
  /** Optional. The content to show in the tooltip. Either this or `tooltipString` is required. */
  tooltipContent?: React.ReactNode,
  /** Optional. Tooltip position. Default = "Above".*/
  position?: TooltipPosition,
  /** The child element to add the mouse-over tooltip to. */
  children: JSX.Element,
  /** Optional. If true, the tooltip won't disappear on mouse-out and the user must click a close button. Default = false. */
  isSticky?: boolean,
  /** Optional. If true, the tooltip will not be rendered. Default = false. */
  isDisabled?: boolean,
  /** Optional. If true, the tooltip will be centered at the mouse position. Default = true. */
  centerAtMouse?: boolean,
}

/** Displays a tooltip on mouseover of the child element. */
const TooltipElement: React.FC<ITooltipElementProps> = ({
  tooltipString,
  tooltipContent,
  position = "Above",
  children,
  isSticky,
  isDisabled = false,
  centerAtMouse = true,
}: ITooltipElementProps) => {
  if (isDisabled) {
    return children;
  }

  if (!isSticky
    && tooltipString) {
    return (
      <StringTooltip
        tooltip={tooltipString}
        position={position}
        children={children}
        centerAtMouse={centerAtMouse}
      />
    );
  } else if (tooltipContent
    || isSticky) {
    return (
      <ContentTooltip
        tooltipContent={tooltipContent ?? <span>{tooltipString}</span>}
        position={position}
        children={children}
        isSticky={isSticky}
        centerAtMouse={centerAtMouse}
      />
    );
  } else {
    return children;
  }
};

export default TooltipElement;

interface IStringTooltipProps {
  tooltip: string,
  position?: TooltipPosition,
  children: JSX.Element,
  centerAtMouse: boolean,
}

const StringTooltip: React.FC<IStringTooltipProps> = ({
  tooltip,
  position,
  children,
}: IStringTooltipProps) => {
  const tooltipRef = useRef<wjTooltip>(new wjTooltip());

  const onMouseOver = useCallback((e: React.MouseEvent<JSX.Element, MouseEvent>) => {
    tooltipRef.current.show(e.currentTarget, tooltip ?? "", undefined, position === "Above" ? PopupPosition.Above : PopupPosition.Below);
  }, [tooltip, position]);

  const onMouseOut = useCallback(() => tooltipRef.current.hide(), []);

  const hoverableChild = React.cloneElement(children, {
    onMouseOver: onMouseOver,
    onMouseOut: onMouseOut,
  });

  return hoverableChild;
};

interface IContentTooltipProps {
  tooltipContent: React.ReactNode,
  position: TooltipPosition,
  children: JSX.Element,
  isSticky?: boolean,
  centerAtMouse: boolean,
}

const ContentTooltip: React.FC<IContentTooltipProps> = ({
  tooltipContent,
  position,
  children,
  isSticky = false,
  centerAtMouse = false,
}: IContentTooltipProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  const hidePopupElement = useCallback(() => {
    if (!divRef.current) {
      return;
    }

    divRef.current.style.visibility = "hidden";
  }, []);

  const onMouseOver = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!divRef.current) {
      return;
    }

    moveToTargetPosition(divRef.current,
      e.currentTarget,
      e,
      position,
      centerAtMouse);

    Array.from(divRef
      .current
      .getElementsByClassName("wj-tooltip-close-button"))
      ?.[0]
      ?.addEventListener("click", () => {
        if (divRef.current) {
          divRef.current.style.visibility = "hidden";
        }
      });
  }, [position, centerAtMouse]);

  const hoverableChild = React.cloneElement(children, {
    onMouseOver: onMouseOver,
    onMouseOut: !isSticky
      ? hidePopupElement
      : undefined,
  });

  return (
    <>
      {hoverableChild}

      <Portal>
        <div
          style={{
            visibility: "hidden",
          }}
          ref={divRef}
          className="wj-tooltip"
        >
          {isSticky && (
            <span
              className="wj-tooltip-close-button no-text-select"
            >
              X
            </span>
          )}
          {tooltipContent}
        </div>
      </Portal>
    </>
  );
};

function moveToTargetPosition(item: HTMLDivElement,
  targetItem: EventTarget & HTMLElement,
  mouseEvent: React.MouseEvent<HTMLElement, MouseEvent>,
  popupPosition: "Above" | "Below",
  centerAtMouse: boolean) {
  item.style.visibility = "";
  item.style.position = "fixed";

  const targetRect = targetItem.getBoundingClientRect();

  item.style.left = "";
  item.style.top = "";
  item.style.right = "";
  item.style.bottom = "";

  let newTop = 0;
  let newLeft = 0;

  if (popupPosition === "Above") {
    newTop = targetRect.top - item.clientHeight - 20;
  } else if (popupPosition === "Below") {
    newTop = targetRect.top + targetRect.height + 20;
  }

  if (centerAtMouse) {
    newLeft = mouseEvent.pageX - item.clientWidth / 2;
  } else {
    newLeft = targetRect.left + targetRect.width / 2;
  }

  if (newLeft + item.clientWidth > window.innerWidth) {
    newLeft = window.innerWidth - item.clientWidth;
  }

  if (newTop < 0) {
    newTop = 0;
  } else if (newTop + item.clientHeight > window.innerHeight) {
    newTop = window.innerHeight - item.clientHeight;
  }

  item.style.left = (newLeft - 30) + "px";
  item.style.top = newTop + "px";
}