import UrlRoutes, { formatRoute } from "components/routing/UrlRoutes";
import { groupBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { findActionItemsByParentItems } from "shared/utilities/actionItemUtilities";
import { hideFindings, hideQuestions } from "store/audit-rwp/AuditRWPSlice";
import { FindingLinkType, IFinding } from "store/audit/reducers/findingReducers";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes, IActionItem } from "types/actionItemTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IAuditQuestion } from "types/auditingTypes";
import AuditPage from "../common/audit-page/AuditPage";
import ViewFindingsModal from "../findings/view-findings-modal/ViewFindingsModal";
import AuditCard from "./action-items-card/AuditCard";
import TopicCard from "./action-items-card/TopicCard";
import ViewQuestionModal from "../../../shared/components/modals/question-modal/ViewQuestionModal";

const AuditRWPPage: React.FC = () => {
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const questionData = useAppSelector(x => x.audit.questions);
  const findingData = useAppSelector(x => x.audit.findings);
  const actionItems = useAppSelector(x => x.audit.actionItems);
  const audit = useAppSelector(x => x.audit.audit);
  const {
    isOpen: isQuestionModalOpen,
    questions: inspectedQuestions,
  } = useAppSelector(store => store.auditRWP.questionModal);
  const {
    isOpen: isFindingModalOpen,
    findings: inspectedFindings,
  } = useAppSelector(store => store.auditRWP.viewFindingsModal);

  const dispatch = useAppDispatch();

  // Apply Restriction
  const isEditable = auditPageAuth.editRestriction === EditRestriction.EditAll;

  const {
    auditId,
  } = useParams();

  const mapActionToITopicActionItemData = useCallback((action: IActionItem): ITopicActionItemData => {
    // Find all questions and findings in this action.
    const questionsForThisAction = questionData
      .filter(q => action.links
        .some(l => l.type === ActionItemLinkTypes.AuditQuestion
          && l.linkId.toString() === q.auditQuestionId.toString()));
    const findingsForThisAction = findingData
      .filter(f => action.links
        .some(l => l.type === ActionItemLinkTypes.AuditFinding
          && l.linkId.toString() === f.id?.toString()));

    return {
      id: action.id,
      actionItem: action,
      parents: questionsForThisAction
        .map((x): ITopicActionItemDataParent => ({
          linkId: x.auditQuestionId,
          linkType: ActionItemLinkTypes.AuditQuestion,
          displayText: `#${x.questionNumber}`,
          question: x,
        })).concat(
          findingsForThisAction.map((x): ITopicActionItemDataParent => ({
            linkId: Number(x.id),
            linkType: ActionItemLinkTypes.AuditFinding,
            displayText: `F:${x.id}`,
            finding: x,
          }))
        ),
      subTopics: Array.from(
        new Set(
          questionsForThisAction
            ?.filter(x => x.subTopics.length)
            .flatMap(x => x.subTopics.map(z => z.name)) || [])
          .values()),
    };
  }, [questionData, findingData]);

  let topicActionItems: ITopicActionItems[] = useMemo(() =>
    Object.entries(
      groupBy(questionData, x => x.topicId))
      .map((group): ITopicActionItems => {
        const questionActions = findActionItemsByParentItems(actionItems,
          group[1].map(x => ({
            linkId: x.auditQuestionId,
            linkType: ActionItemLinkTypes.AuditQuestion,
          })));

        const aqIds = group[1].map(x => x.auditQuestionId);

        const findingsInThisTopic = findingData
          .filter(f => f.links
            .some(l => l.linkType === FindingLinkType.AuditQuestion
              && aqIds.some(aq => aq === l.linkId)));

        const findingActions = findActionItemsByParentItems(actionItems,
          findingsInThisTopic.map(x => ({
            linkId: Number(x.id),
            linkType: ActionItemLinkTypes.AuditFinding,
          })));

        return {
          topicId: Number(group[0]),
          topicName: group[1][0].topicName,
          actionItems: questionActions
            .concat(findingActions)
            .map(mapActionToITopicActionItemData),
          numQuestions: group[1].length,
        };
      }), [questionData, actionItems, findingData, mapActionToITopicActionItemData]);

  const auditActionItems = actionItems
    .filter(item => item.links.some(x => x.type === "Audit"));

  return (
    <AuditPage
      backButtonRoute={formatRoute(UrlRoutes.AuditSummary, { auditId: auditId || "0" })}
      title="Audit Remedial Work Plan"
      auditId={Number(auditId)}
      overviewSettings={{
        showComplianceScore: true,
        showQuestionCompletionPercent: true,
      }}
      loadFindingTypes
    >
      {audit && (
        <>
          <AuditCard
            auditId={audit.id}
            actionItems={auditActionItems}
            isEditable={isEditable}
          />

          {topicActionItems.map(topic => (
            <TopicCard
              key={topic.topicId}
              title={topic.topicName}
              topicId={topic.topicId}
              actionItems={topic.actionItems}
              numQuestions={topic.numQuestions}
            />
          ))}

          {isQuestionModalOpen && (
            <ViewQuestionModal
              questions={inspectedQuestions}
              onClose={() => dispatch(hideQuestions())}
            />
          )}

          {isFindingModalOpen && (
            <ViewFindingsModal
              findings={inspectedFindings}
              onClose={() => dispatch(hideFindings())}
            />
          )}
        </>
      )}
    </AuditPage>
  );
};

export default AuditRWPPage;

export interface ITopicActionItems {
  topicId: number,
  topicName: string,
  actionItems: ITopicActionItemData[],
  numQuestions: number,
}

export interface ITopicActionItemData {
  id: number,
  actionItem: IActionItem,
  subTopics: string[],
  parents: ITopicActionItemDataParent[],
}

export interface ITopicActionItemDataParent {
  linkId: number,
  linkType: ActionItemLinkTypes,
  displayText: string,
  question?: IAuditQuestion,
  finding?: IFinding,
}