import { useContext, useEffect } from "react";
import QISchedulerContext from "../QISchedulerContext";
import { IQISchedulerItemResizeData, qiSchedulerBaseColWidth } from "../qiSchedulerTypes";
import QISchedulerItem from "../scheduler-item/QISchedulerItem";
import QISchedulerCalendarRow from "./QISchedulerCalendarRow";

interface IQISchedulerCalendarAreaProps {
  areaDiv: HTMLDivElement | null,
}

const QISchedulerCalendarArea = ({
  areaDiv,
}: IQISchedulerCalendarAreaProps) => {
  const ctx = useContext(QISchedulerContext);
  const items = ctx?.items;
  const onUpdateItem = ctx?.onUpdateItem;
  const dates = ctx?.dates;

  useEffect(() => {
    if (!areaDiv) {
      return;
    }

    let resizeData: IQISchedulerItemResizeData | undefined;

    const onMouseMove = (e: any) => {
      if (!resizeData) {
        return;
      }

      const itemDiv = resizeData.itemDiv;
      const mouseDownPosition = resizeData.mouseDownPosition;
      const initialItemSize = resizeData.initialItemSize;
      const side = resizeData.side;

      var rect = areaDiv.getBoundingClientRect();
      var newSnappedMouseX = snapToNearestIncrement(e.clientX - rect.left, qiSchedulerBaseColWidth);

      if (side === "left") {
        // Calculate the new position.
        const newWidth = snapToNearestIncrement(initialItemSize.width + (mouseDownPosition.x - e.pageX), qiSchedulerBaseColWidth);
        const newLeft = initialItemSize.left - (newWidth - initialItemSize.width);

        // Update the width and left offset of the item.
        itemDiv.style.width = newWidth + "px";
        itemDiv.style.left = newLeft + "px";

        // Calculate the days offset from its starting position.
        resizeData.offsetDays = (itemDiv.offsetLeft - resizeData.initialItemSize.left) / qiSchedulerBaseColWidth;

        // Calculate the new start date and save it into the resize data.
        resizeData.startDate = new Date(resizeData.item.startDate);
        resizeData.startDate.setDate(resizeData.startDate.getDate() + resizeData.offsetDays);
      } else if (side === "right") {
        // Calculate the new position and update the width of the item.
        itemDiv.style.width = snapToNearestIncrement(initialItemSize.width + -(mouseDownPosition.x - e.pageX), qiSchedulerBaseColWidth) + "px";

        // Calculate the days offset from its starting position.
        resizeData.offsetDays = (itemDiv.clientWidth - resizeData.initialItemSize.width) / qiSchedulerBaseColWidth;

        // Calculate the new end date and save it into the resize data.
        resizeData.endDate = new Date(resizeData.item.endDate);
        resizeData.endDate.setDate(resizeData.endDate.getDate() + resizeData.offsetDays);
      } else if (side === "center") {
        const snappedOffset = newSnappedMouseX - resizeData.snappedMouseDownPosition.x;

        // Calculate the days offset from its starting position.
        resizeData.offsetDays = snappedOffset / qiSchedulerBaseColWidth;

        // Calculate the new start and end dates and save them into the resize data.
        resizeData.startDate = new Date(resizeData.item.startDate);
        resizeData.startDate.setDate(resizeData.startDate.getDate() + resizeData.offsetDays);

        resizeData.endDate = new Date(resizeData.item.endDate);
        resizeData.endDate.setDate(resizeData.endDate.getDate() + resizeData.offsetDays);

        if (dates) {
          if (resizeData.endDate < dates[1].date) {
            // Force the end date to be no sooner than the 2nd of the month (otherwise the item disappears from the calendar).
            resizeData.endDate = new Date(dates[1].date);
          }
          
          // Calculate new left/right/width.
          let leftEdge = (dates.find(x => x.date.getTime() === resizeData?.startDate.getTime())?.index ?? 0) * qiSchedulerBaseColWidth;
          let rightEdge = (dates.find(x => x.date.getTime() === resizeData?.endDate.getTime())?.index ?? dates[dates.length - 1].index) * qiSchedulerBaseColWidth;
          let width = rightEdge - leftEdge;

          if (width < qiSchedulerBaseColWidth) {
            width = qiSchedulerBaseColWidth;
          }

          itemDiv.style.width = width + "px";
          itemDiv.style.left = leftEdge + "px";

          if (resizeData.startDate >= dates[0].date) {
            resizeData.itemLeftGrip.style.display = "block";
          } else {
            resizeData.itemLeftGrip.style.display = "none";
          }

          var firstDayNextMonth = new Date(dates[dates.length - 1].date);
          firstDayNextMonth.setDate(firstDayNextMonth.getDate() + 1);

          if (resizeData.endDate <= firstDayNextMonth) {
            resizeData.itemRightGrip.style.display = "block";
          } else {
            resizeData.itemRightGrip.style.display = "none";
          }
        }
      }
    };

    const onMouseUp = () => {
      if (!resizeData) {
        return;
      }

      resizeData.itemDiv.style.left = resizeData.initialItemSize.left + "px";
      resizeData.itemDiv.style.width = resizeData.initialItemSize.width + "px";
      resizeData.itemDiv.style.pointerEvents = "";

      if (!(resizeData.item.startDate.getTime() === resizeData.startDate.getTime()
        && resizeData.item.endDate.getTime() === resizeData.endDate.getTime())) {
        // Fire the event to tell the outside world this item has been updated.
        onUpdateItem?.({
          item: resizeData.item,
          startDate: resizeData.startDate,
          endDate: resizeData.endDate,
        });
      }

      resizeData = undefined;
    };

    const onMouseDown = (e: any) => {
      if (e.buttons !== 1) {
        // Must be left mouse button only.
        return;
      }

      let isDraggable = e.target.classList.contains("draggable");
      let side: "left" | "right" | "center" | undefined;

      if (e.target.classList.contains("grip-left")) {
        side = "left";
      } else if (e.target.classList.contains("grip-right")) {
        side = "right";
      } else if (e.target.classList.contains("grip-top")) {
        side = "center";
      }

      if (!items
        || !side
        || !isDraggable) {
        return;
      }

      const dataItemId = e.target.getAttribute("data-scheduler-item-id");
      const item = items.find(x => x.id.toString() === dataItemId);
      const itemDiv = e.target.parentElement;

      if (!dataItemId
        || !item
        || !itemDiv) {
        return;
      }

      itemDiv.style.pointerEvents = "none";

      const itemStart = new Date(item.startDate);
      itemStart.setHours(0, 0, 0, 0);

      const itemEnd = new Date(item.endDate);
      itemEnd.setHours(0, 0, 0, 0);

      const rightGrip = itemDiv.querySelector(".grip-right");
      const leftGrip = itemDiv.querySelector(".grip-left");

      var rect = areaDiv.getBoundingClientRect();
      var snappedMouseX = snapToNearestIncrement(e.clientX - rect.left, qiSchedulerBaseColWidth);
      var mouseRelativeY = e.clientY - rect.top;

      resizeData = {
        mouseDownPosition: {
          x: e.pageX,
          y: e.pageY,
        },
        snappedMouseDownPosition: {
          x: snappedMouseX,
          y: mouseRelativeY,
        },
        initialItemSize: {
          width: itemDiv.clientWidth,
          left: itemDiv.offsetLeft,
        },
        itemDiv: itemDiv as HTMLDivElement,
        itemRightGrip: rightGrip as HTMLDivElement,
        itemLeftGrip: leftGrip as HTMLDivElement,
        side: side,
        startDate: itemStart,
        endDate: itemEnd,
        offsetDays: 0,
        item: {
          ...item,
          startDate: itemStart,
          endDate: itemEnd,
        },
      };
    };

    areaDiv.addEventListener("mousedown", onMouseDown);
    areaDiv.addEventListener("mouseup", onMouseUp);
    areaDiv.addEventListener("mousemove", onMouseMove);

    return () => {
      areaDiv.removeEventListener("mousedown", onMouseDown);
      areaDiv.removeEventListener("mouseup", onMouseUp);
      areaDiv.removeEventListener("mousemove", onMouseMove);
    };
  }, [areaDiv, items, onUpdateItem, dates]);

  if (!ctx) {
    return null;
  }

  const hasAnySelectedItems = ctx.items.some(x => x.isSelected);

  return (
    <>
      <table>
        <tbody>
          {ctx.rows.map(row =>
            <QISchedulerCalendarRow
              key={row.id}
              row={row}
            />
          )}
        </tbody>
      </table>

      {ctx.items.map(item =>
        <QISchedulerItem
          key={item.id}
          item={item}
          isTransparent={hasAnySelectedItems
            && !item.isSelected
          }
        />
      )}
    </>
  );
};

export default QISchedulerCalendarArea;

function snapToNearestIncrement(number: number, increment: number) {
  return Math.round(number / increment) * increment;
}