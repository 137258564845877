import React from "react";
import { ActionItemStatuses } from "types/actionItemTypes";
import atRisk from "shared/media/icons/action-items/status/AtRisk.svg";
import behind from "shared/media/icons/action-items/status/Behind.svg";
import closedOnTime from "shared/media/icons/action-items/status/ClosedOnTime.svg";
import closedPastDue from "shared/media/icons/action-items/status/ClosedPastDue.svg";
import onTrack from "shared/media/icons/action-items/status/OnTrack.svg";
import pastDue from "shared/media/icons/action-items/status/PastDue.svg";

import "./ActionItemStatus.scoped.scss";
import formatDate from "shared/utilities/dateFormatters";

interface IActionItemStatusProps {
  statusData: IActionItemStatusData,
  showClosureDate?: boolean,
  spanProps?: React.HTMLProps<HTMLSpanElement>,
}

export interface IActionItemStatusData {
  status: ActionItemStatuses,
  dueByTimestamp: number,
  createdOnTimestamp: number,
  closureTimestamp?: number,
}

const ActionItemStatus: React.FC<IActionItemStatusProps> = ({
  statusData,
  showClosureDate,
  spanProps,
}) => {
  const {
    icon,
    text,
    tooltip,
  } = getIconAndText(statusData)

  return (
    <span
      {...spanProps}
      title={tooltip}
      className="status-row"
    >
      <img
        src={icon}
        alt=""
      />

      <span
        className="text"
      >
        {text}
      </span>

      {showClosureDate
        && !!statusData.closureTimestamp && (
          <span
            className="closureDate"
          >
            ({formatDate(new Date(statusData.closureTimestamp))})
          </span>
        )}
    </span>
  );
};

export default ActionItemStatus;

interface IIconAndText {
  icon: string,
  text: string,
  tooltip: string,
}

function getIconAndText({
  createdOnTimestamp,
  dueByTimestamp,
  status,
  closureTimestamp,
}: IActionItemStatusData): IIconAndText {
  const now = new Date().getTime();
  let timespan = dueByTimestamp - createdOnTimestamp;
  let timeUsed = now - createdOnTimestamp;
  let percentUsed = 1 - timeUsed / timespan;

  const getReturnResult = (icon: string, text: string, tooltip: string) => ({
    icon,
    text,
    tooltip,
  });

  if (status === ActionItemStatuses.Open) {
    // The action item is still open.
    if (now > dueByTimestamp) {
      return getReturnResult(pastDue, "Past Due", "Past Due");
    } else if (percentUsed <= .3) {
      return getReturnResult(atRisk, "At Risk", "< 30% of the days are remaining between the creation and due dates.");
    } else if (percentUsed <= .5) {
      return getReturnResult(behind, "Behind", "50 - 30% of the days are remaining between the creation and due dates.");
    } else {
      return getReturnResult(onTrack, "On Track", ">50% of the days are remaining between the creation and due dates.");
    }
  } else {
    // The action item is closed.
    if (closureTimestamp
      && closureTimestamp < dueByTimestamp) {
      return getReturnResult(closedOnTime, "Closed on Time", "Closed on Time");
    } else {
      return getReturnResult(closedPastDue, "Closed Past Due", "Closed Past Due");
    }
  }
}