import { parsePlanningConfigDefaultDates, parsePlanningConfigMode } from "api/admin/planning-administration/formatters/auditPlanningConfigFormatters";
import { isAttributeReviewed } from "components/audits/planning/facility-attributes/attributesUtils";
import { IPlanningItem } from "components/audits/planning/planning-grid/PlanningGrid";
import { IRestrictedMetaData } from "shared/types/userProfileTypes";
import { canUserAccessItem } from "shared/utilities/permissionUtilities";
import { IAdminPlanningAdministrationPeriod } from "types/adminPlanningAdministration";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IAuditPlanningConfig, IPlanGridYAxesData, IPlanHierarchyItem, IPlanProfileAttribute } from "types/auditPlanningTypes";
import { isDefaultPlanningPeriodPermissible, isPlanningDateRangePermissible } from "types/authRestrictionUtilities";
import { IFacility, MetaDataTypes } from "types/masterDataTypes";
import { IAuditPlanningAppliedFilters, PlanningReviewStatusFilterValues } from "../reducers/planningFiltersReducers";

export function applyFilterToAttributes(allAttributes: IPlanProfileAttribute[],
  filters: IAuditPlanningAppliedFilters,
  currentUserEmail: string): {
    visibleAttributes: IPlanProfileAttribute[],
    nonVisibleAttributes: IPlanProfileAttribute[],
  } {
  const visible: IPlanProfileAttribute[] = [];
  const nonVisible: IPlanProfileAttribute[] = [];
  allAttributes.forEach(att => {
    const isReviewed = isAttributeReviewed(att);

    if (!filters.includeDeletedAttributes
      && att.deleted) {
      // Not supposed to show deleted attributes.
      nonVisible.push(att);
      return;
    } else if (filters.reviewStatus === PlanningReviewStatusFilterValues.NotReviewed) {
      if (isReviewed) {
        // Supposed to be not reviewed but it is.
        nonVisible.push(att);
        return;
      }
    } else if (filters.reviewStatus === PlanningReviewStatusFilterValues.ReviewedByMe
      && (!isReviewed
        || att.reviewedByEmail?.toLowerCase() !== currentUserEmail.toLowerCase())) {
      // It isn't reviewed by me.
      nonVisible.push(att);
      return;
    } else if (filters.reviewStatus === PlanningReviewStatusFilterValues.Reviewed) {
      if (!isReviewed) {
        // It isn't reviewed.
        nonVisible.push(att);
        return;
      }

      const reviewedByEmailFilter = filters.reviewedBy?.[0]?.email?.toLowerCase();

      if (reviewedByEmailFilter
        && reviewedByEmailFilter !== att.reviewedByEmail?.toLowerCase()) {
        // It isn't reviewed by the correct person.
        nonVisible.push(att);
        return;
      }
    }

    visible.push(att);
  });

  return {
    visibleAttributes: visible,
    nonVisibleAttributes: nonVisible,
  };
}

export function buildYAxisData(options: IBuildYAxisOptions) {
  const plannableDateRangeInfo = getPlannableGeoUnitInfoFromDateRanges(options);

  const planningItems: IPlanningItem[] = [];

  // Convert each hierarchy item into an IPlanningItem.
  options.yAxisData.hierarchyItems.forEach(x => addYAxisLevels({
    hierarchyItem: x,
    parentHierarchyItems: [],
    planningItems,
    plannableDateRangeInfo,
    buildOptions: options,
    depth: 1,
  }));

  if (options.profilelessFacilities) {
    addProfilelessFacilitiesToYAxis(planningItems,
      options,
      plannableDateRangeInfo);
  }

  return planningItems;
}

function addYAxisLevels(input: {
  hierarchyItem: IPlanHierarchyItem,
  parentHierarchyItems: IPlanHierarchyItem[],
  planningItems: IPlanningItem[],
  plannableDateRangeInfo: IGeoUnitDateRangeInfo,
  buildOptions: IBuildYAxisOptions,
  depth: number,
}) {
  const {
    hierarchyItem,
    parentHierarchyItems,
    planningItems,
    plannableDateRangeInfo,
    depth,
  } = input;
  const {
    editRestriction,
    yAxisData,
    derivedMetaRestrictions,
  } = input.buildOptions;

  const itemParent = parentHierarchyItems.find(x => x.children.find(x => x.id === hierarchyItem.id));
  const isWithinPlanningRange = plannableDateRangeInfo.geoUnitIdsWithValidDateRanges.some(x => x === itemParent?.id)
    || plannableDateRangeInfo.globalIsPlannable;

  let item: IPlanningItem = {
    children: [],
    id: hierarchyItem.id,
    isPlannable: false,
    text: hierarchyItem.text,
    type: MetaDataTypes.BusinessTeam,
    subType: undefined,
    isExpanded: true,
    doesUserHavePermission: editRestriction !== EditRestriction.EditNone
      && canUserAccessItem({ id: hierarchyItem.id, type: MetaDataTypes.BusinessTeam }, derivedMetaRestrictions),
    isWithinPlanningRange,
    subGeoUnitId: depth === 3
      ? hierarchyItem.id
      : 0,
  };

  planningItems.push(item);

  hierarchyItem.children.forEach(child => addYAxisLevels({
    ...input,
    hierarchyItem: child,
    planningItems: item.children,
    parentHierarchyItems: parentHierarchyItems.concat(hierarchyItem),
    depth: depth + 1,
  }));

  item.children.sort((a, b) => a.text < b.text ? -1 : 1);

  // Find the parent plan items that should be here.
  const parentPlanItems = yAxisData
    .planItemHierarchy
    .filter(z => z.subGeoUnitId === item.id);

  if (!parentPlanItems.length) {
    return;
  }

  let planItems: IPlanningItem[] = [];

  parentPlanItems.forEach(ppi => {
    // Create the parent item.
    const parentItem: IPlanningItem = {
      id: ppi.id,
      text: ppi.text,
      isPlannable: true,
      type: ppi.type,
      subType: ppi.subType,
      isExpanded: true,
      // Check if this parent item is accessible
      doesUserHavePermission: isMetaItemEditableByUser(editRestriction,
        ppi.id,
        ppi.type,
        hierarchyItem.id,
        derivedMetaRestrictions),
      isWithinPlanningRange,
      children: [],
      subGeoUnitId: ppi.subGeoUnitId || 0,
    };

    // Now parse all the children (if any).
    parentItem.children = (ppi.children?.map((c): IPlanningItem => ({
      id: c.id,
      text: c.text,
      isPlannable: true,
      type: c.type,
      subType: c.subType,
      isExpanded: true,
      children: [],
      doesUserHavePermission: parentItem.doesUserHavePermission
        && canUserAccessItem(c, derivedMetaRestrictions),
      isWithinPlanningRange,
      subGeoUnitId: ppi.subGeoUnitId || 0,
    })) || []).sort((a, b) => a.text < b.text ? -1 : 1);

    planItems.push(parentItem);
  });

  planItems.sort((a, b) => a.text < b.text ? -1 : 1);
  item.children.push(...planItems);
}

function addProfilelessFacilitiesToYAxis(yAxisData: IPlanningItem[],
  options: IBuildYAxisOptions,
  plannableDateRangeInfo: IGeoUnitDateRangeInfo) {
  // Recursively find all the SubGeoUnits.
  const recursiveScanner = (item: IPlanningItem, depth: number, ancestors: IPlanningItem[]) => {
    if (depth === 3) {
      const subGeoUnit = item;

      const itemParent = ancestors.find(x => x.children.find(x => x.id === subGeoUnit.id));
      const isWithinPlanningRange = plannableDateRangeInfo.geoUnitIdsWithValidDateRanges.some(x => x === itemParent?.id)
        || plannableDateRangeInfo.globalIsPlannable;

      const originalChildrenLength = subGeoUnit.children.length;

      (options.profilelessFacilities || [])
        .filter(x => x.subGeoUnitId === subGeoUnit.id)
        .forEach(facility => {
          if (!subGeoUnit
            .children
            .some(x => x.id === facility.id)) {
            subGeoUnit.children.push({
              children: [],
              id: facility.id,
              isExpanded: true,
              isPlannable: true,
              text: `${facility.commonId} - ${facility.name}`,
              type: MetaDataTypes.Facility,
              subType: undefined,
              doesUserHavePermission: isMetaItemEditableByUser(options.editRestriction,
                facility.id,
                MetaDataTypes.Facility,
                subGeoUnit.id,
                options.derivedMetaRestrictions),
              isWithinPlanningRange,
              subGeoUnitId: item.id,
            });
          }
        });

      if (subGeoUnit.children.length !== originalChildrenLength) {
        // One or more facilities was added. Sort the list.
        subGeoUnit.children.sort((a, b) => a.text < b.text ? -1 : 1);
      }
    } else {
      item.children.forEach(x => recursiveScanner(x, depth + 1, ancestors.concat([x])));
    }
  }

  yAxisData.forEach(x => recursiveScanner(x, 1, [x]));
}

function isMetaItemEditableByUser(editRestriction: EditRestriction,
  masterDataId: number,
  masterDataType: MetaDataTypes,
  subGeoUnitId: number,
  derivedMetaRestrictions: IRestrictedMetaData[]): boolean {
  return editRestriction !== EditRestriction.EditNone
    // The parent subgeounit must be accessible.
    && canUserAccessItem({
      id: subGeoUnitId,
      type: MetaDataTypes.BusinessTeam,
    }, derivedMetaRestrictions)
    // This item itself must be accessible.
    && canUserAccessItem({ id: masterDataId, type: masterDataType }, derivedMetaRestrictions);
}

export function getPlannableGeoUnitInfoFromDateRanges(options: IBuildYAxisOptions) {
  let plannableData: IGeoUnitDateRangeInfo = {
    globalIsPlannable: false,
    geoUnitIdsWithValidDateRanges: [],
  };

  if (options.editRestriction === EditRestriction.EditNone) {
    return plannableData;
  }

  const planMode = parsePlanningConfigMode(options.planConfigs
    .find(x => x.name === "Mode"));
  const defaultDateRange = parsePlanningConfigDefaultDates(options.planConfigs);

  const now = new Date();

  if (planMode === "Global") {
    const globalItem = options.planPeriods.find(x => x.businessTeamId === 0);

    plannableData.globalIsPlannable = globalItem
      ? isPlanningDateRangePermissible(globalItem, now)
      : isDefaultPlanningPeriodPermissible(defaultDateRange, now);
  } else {
    options.planPeriods
      .filter(period => period.businessTeamId !== 0)
      .filter(period => isPlanningDateRangePermissible(period, now))
      .forEach(x => plannableData.geoUnitIdsWithValidDateRanges.push(x.businessTeamId));
  }

  return plannableData;
}

interface IGeoUnitDateRangeInfo {
  globalIsPlannable: boolean,
  geoUnitIdsWithValidDateRanges: number[],
}

interface IBuildYAxisOptions {
  yAxisData: IPlanGridYAxesData,
  editRestriction: EditRestriction,
  derivedMetaRestrictions: IRestrictedMetaData[],
  planConfigs: IAuditPlanningConfig[],
  planPeriods: IAdminPlanningAdministrationPeriod[],
  profilelessFacilities?: IFacility[],
}