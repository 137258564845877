import EvidenceListItem from "components/evidence/list/item/EvidenceListItem";
import React, { useEffect } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Modal from "shared/components/layout/modal/Modal";
import { viewAuditEvidence } from "store/audit-evidences/AuditEvidencesSlice";
import { closeModal, deselectItem, loadAuditAttachments, selectItem, setSelectedItems } from "store/audit-report/attachment-modal/AttachmentModalSlice";
import { viewEvidence } from "store/question-evidence/QuestionEvidenceSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAuditReportSectionAttachment } from "types/auditingTypes";
import "./AttachmentModal.scoped.scss";

interface IAttachmentModalProps {
  header: string,
  selectedAttachments: IAuditReportSectionAttachment[],
  auditId: number,
  onOk(selectedAttachments: IAuditReportSectionAttachment[]): void,
}

const AttachmentModal: React.FC<IAttachmentModalProps> = ({
  header,
  selectedAttachments,
  auditId,
  onOk,
}) => {
  const {
    auditReportAttachmentModal: {
      attachmentList,
      selectedItems,
      loadListOp,
      hasListBeenQueried,
    },
    questionEvidence: {
      viewEvidenceOp,
    },
    auditEvidences: {
      viewAuditEvidenceOp,
    },
  } = useAppSelector(store => store);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedItems(selectedAttachments));
  }, [selectedAttachments, dispatch]);

  useEffect(() => {
    if (hasListBeenQueried) {
      return;
    }

    dispatch(loadAuditAttachments(auditId));
  }, [hasListBeenQueried, dispatch, auditId]);

  return (
    <Modal
      isOpen={true}
      header={header}
      buttons={[{
        key: "CANCEL",
        text: "Cancel",
        onClick: () => dispatch(closeModal()),
        className: "secondary",
      }, {
        key: "OK",
        text: "Ok",
        onClick: () => onOk(selectedItems),
        className: "primary",
      }]}
    >
      {!!loadListOp?.errorMessage
        && (
          <Banner
            type={BannerType.error}
          >
            Failed to load audit attachment list: {loadListOp.errorMessage || "Unknown Error"}<br />
            <br />
            <button
              className="link"
              onClick={() => dispatch(loadAuditAttachments(auditId))}
            >
              Retry
            </button>
          </Banner>
        )}

      {loadListOp?.isWorking && (
        <ModalSpinner />
      )}

      {hasListBeenQueried
        && attachmentList.length === 0 && (
          <Banner
            type={BannerType.info}
          >
            Your audit has no attached evidence images.
            To able to attach an image to your report,
            an image must be first submitted as evidence
            to the audit or one of the questions.
          </Banner>
        )}

      {hasListBeenQueried
        && attachmentList.length > 0 && (
          <>
            {attachmentList.map(item => (
              <EvidenceListItem
                key={item.id}
                item={{
                  filename: item.filename,
                  id: item.id,
                  notes: item.description,
                }}
                isSelected={selectedItems.some(x => x.id === item.id && x.type === item.type)}
                onSelectionChanged={(_, isSelected) => {
                  const isInList = selectedItems.some(x => x.id === item.id && x.type === item.type);

                  if (isSelected && !isInList) {
                    dispatch(selectItem(item))
                  } else if (!isSelected && isInList) {
                    dispatch(deselectItem(item))
                  }
                }}
                onDownloadClicked={() => {
                  if (item.type === "AuditQuestionEvidence"
                    && item.auditQuestionId) {
                    dispatch(viewEvidence({
                      auditId: auditId,
                      auditQuestionId: item.auditQuestionId,
                      evidenceId: item.id,
                    }));
                  } else if (item.type === "AuditEvidence"
                    && item.auditId) {
                    dispatch(viewAuditEvidence({
                      auditId: auditId,
                      evidenceId: item.id,
                    }));
                  }
                }}
              />
            ))}
          </>
        )}

      {(viewEvidenceOp?.isWorking || viewAuditEvidenceOp?.isWorking) && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default AttachmentModal;