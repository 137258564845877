import { isQuestionMissingAnswer } from "components/audits/common/auditUtilities";
import React from "react";
import Button from "shared/components/controls/buttons/button/Button";
import removeIcon from "shared/media/dls/remove.svg";
import { userToString } from "shared/utilities/userUtilities";
import { setAssignAuditorByAssociationsModal } from "store/audit-summary/AuditSummarySlice";
import { useAppDispatch } from "store/store";
import { AuditStatuses, IAuditor, IAuditQuestion } from "types/auditingTypes";

interface IAuditorRowProps {
  auditor: IAuditor,
  assignedQuestions: IAuditQuestion[],
  auditStatus: AuditStatuses,
  isEnabledToEdit: boolean,
  onRemoveClick?: (email: string) => void,
  canAssignAuditors: boolean,
}

const AuditorRow: React.FC<IAuditorRowProps> = ({
  auditor,
  assignedQuestions,
  auditStatus,
  isEnabledToEdit,
  onRemoveClick,
  canAssignAuditors,
}) => {
  const totalQuestions = assignedQuestions.length;
  const completedCount = assignedQuestions
    .filter(x => !isQuestionMissingAnswer(x, auditStatus))
    .length;
  const completedPercent = totalQuestions > 0
    ? Math.floor((completedCount / totalQuestions) * 100)
    : 0;
  const dispatch = useAppDispatch();

  return (
    <tr>
      <td
        className={`name ${auditor.isLeader ? "leader" : ""}`}
      >
        {isEnabledToEdit && (
          <img
            src={removeIcon}
            alt="Remove"
            className="remove-button icon-small"
            onClick={onRemoveClick
              ? () => onRemoveClick(auditor.email)
              : undefined
            }
          />
        )}
        {userToString(auditor)}
      </td>
      {canAssignAuditors && (
        <td
          className="centered"
        >
          <Button
            buttonType="link"
            onClick={() => dispatch(setAssignAuditorByAssociationsModal({
              isOpen: true,
              auditor,
            }))}
            allCaps={false}
          >
            +Add
          </Button>
        </td>
      )}
      <td
        className="centered questions"
      >
        {totalQuestions}
      </td>
      <td
        className="centered completed"
      >
        {completedPercent}%
      </td>
    </tr>
  );
};

export default AuditorRow;
