import { useCallback, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import Portal from 'shared/components/layout/portal/Portal';
import arrowIcon from "shared/media/dls/arrow-down-2.svg";
import closeIcon from "shared/media/dls/close.svg";
import "./QISelect.scoped.scss";
import "./QISelect.scss";

interface IQISelectProps {
  /** Optional. Additional class name to apply to the multi select control. */
  className?: string,
  /** Items available in dropdown. */
  items: IQISelectItem[],
  /** The list of currently-selected item ids. */
  selectedItemIds: (string | number)[],
  /** Optional. Text to show in dropdown when nothing is selected. Default = "Select". */
  placeholder?: string,
  /** Optional. If true and selectMode is "multi", a "Select All" checkbox will be available. Default = true. */
  allowSelectAll?: boolean,
  /** Optional. If true and selectMode is "multi", user can filter items by typing in search box. Default = true. */
  allowFilter?: boolean,
  /** Optional. If true, a small "X" appears near dropdown icon that, when clicked, clears the selection. Default = true. */
  allowClearButton?: boolean,
  /** Callback for when user changes any selected items. Happens immediately. */
  onChange(selectedItems: IQISelectItem[]): void,
  /** Optional. If true, control is non-interactive. Default = false. */
  isDisabled?: boolean,
  /** Determines selection mode. */
  selectMode: "multi" | "single",
  /** Optional. Extra styles to apply to dropdown component. */
  style?: React.CSSProperties,
  /** Optional. Overrides the normal display text of the select element. */
  displayOverride?: string,
}

export interface IQISelectItem {
  id: string | number,
  text: string,
}

const QISelect = ({
  className = "",
  items,
  selectedItemIds,
  placeholder = "Select",
  allowSelectAll = true,
  allowFilter = true,
  allowClearButton = true,
  isDisabled = false,
  onChange,
  selectMode,
  style,
  displayOverride,
}: IQISelectProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  /** A handler for when the user clicks any element. If the element is outside the select popup, it closes the popup. */
  const handleClickOutside = useCallback((e: any) => {
    if (!popperElement?.contains(e.target)
      && !e.target.closest(".qi-multiselect-clear-icon")) {
      setIsOpen(false);
    }
  }, [popperElement, setIsOpen]);

  /** A handler for when the user scrolls any element anywhere. It closes the popup. */
  const scrollHandler = useCallback((ev: Event) => {
    if (ev.target
      && (ev.target as HTMLElement)?.closest(".qi-multiselect-popup")) {
      // The user scrolled inside the popper element. Do not close it.
      return;
    }

    setIsOpen(false);
  }, [setIsOpen]);

  /** A handler for when the window is resized. It updates the position of the popup. */
  const handleResizeEvent = useCallback(() => {
    update?.();
  }, [update]);

  /** An effect that configures the click-outside event. */
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  /** An effect that configures the scroll event. */
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);

    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, [scrollHandler]);

  /** An effect that configures the window resize event. */
  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [handleResizeEvent]);

  // Figure out what the current display text should be.
  // Start it as the placeholder text.
  let displayText = placeholder;

  // Find all the selected items and extract their texts.
  const selectedItemTexts = items
    .filter(x => selectedItemIds.includes(x.id))
    .map(x => x.text);

  // If there is at least 1 selected item, join all the texts together. 
  if (selectedItemTexts.length) {
    displayText = selectedItemTexts.join(", ");
  }

  return (
    <>
      <div
        style={style}
        ref={setReferenceElement}
        className={`qi-multiselect input picker ${className} ${isDisabled ? "disabled" : ""}`}
        onClick={isDisabled
          ? undefined
          : e => {
            if (!(e.target as any)?.closest(".qi-multiselect-clear-icon")) {
              setIsOpen(!isOpen);
            }
          }}
      >
        {displayOverride !== undefined
          ? (
            <div
              className="labels"
            >
              {displayOverride}
            </div>
          ) : (
            <div
              className={`labels ${selectedItemIds.length === 0 ? "placeholder" : ""} ${className}`}
            >
              {displayText}
            </div>
          )}

        {selectedItemIds.length > 1
          && selectMode === "multi"
          && (
            <div className="selected-count">
              ({selectedItemIds.length} items)
            </div>
          )}

        {allowClearButton
          && selectedItemIds.length > 0 && (
            <img
              src={closeIcon}
              alt="X"
              className="icon-extra-small qi-multiselect-clear-icon"
              onClick={isDisabled
                ? undefined
                : () => onChange([])
              }
            />
          )}

        <img
          src={arrowIcon}
          alt=""
          className="icon-small"
        />
      </div>

      {isOpen && (
        <Portal>
          <div
            ref={setPopperElement}
            {...attributes.popper}
            className="qi-multiselect-popup"
            style={{
              ...styles.popper,
              minWidth: referenceElement?.clientWidth,
              backgroundColor: "white",
            }}
          >
            {allowFilter
              && selectMode === "multi" && (
                <input
                  type="text"
                  value={searchValue}
                  onChange={e => setSearchValue(e.currentTarget.value)}
                  placeholder="Search"
                  className="qi-multiselect-filter"
                />
              )}

            {allowSelectAll
              && selectMode === "multi" && (
                <div
                  className="qi-multiselect-items no-text-select"
                >
                  <label
                    className="qi-multiselect-item"
                  >
                    <input
                      type="checkbox"
                      checked={items.length === selectedItemIds.length}
                      onChange={e => onChange(e.currentTarget.checked
                        ? items
                        : [])
                      }
                    />

                    Select All
                  </label>
                </div>
              )}

            {(allowFilter
              || allowSelectAll)
              && selectMode === "multi" && (
                <hr />
              )}

            <div
              className="qi-multiselect-items no-text-select"
            >
              {items
                .filter(x => !searchValue.trim()
                  || x.text.toLowerCase().includes(searchValue.toLowerCase()))
                .map(item =>
                  <label
                    key={item.id}
                    className="qi-multiselect-item"
                    onClick={selectMode === "single"
                      ? () => {
                        onChange([item]);
                        setIsOpen(false);
                      } : undefined
                    }
                  >
                    {selectMode === "multi" && (
                      <input
                        type="checkbox"
                        checked={selectedItemIds.includes(item.id)}
                        onChange={e => {
                          const isChecked = e.currentTarget.checked;

                          let checkedItems = items.filter(x => selectedItemIds.includes(x.id));

                          if (isChecked) {
                            checkedItems.push(item);
                          } else {
                            checkedItems = checkedItems.filter(x => x.id !== item.id);
                          }

                          onChange(checkedItems);
                        }}
                      />
                    )}

                    {item.text}
                  </label>
                )}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default QISelect;