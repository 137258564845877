import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "shared/components/common/breadcrumbs/Breadcrumbs";
import leftArrowIcon from "shared/media/dls/arrow-left-1.svg";
import "./PageBar.scoped.scss";
import DetailText from "shared/components/common/detail-text/DetailText";

export interface IPageBarProps {
  /** The title to show on the page header bar. Optional. */
  title?: string,
  /** The route to visit when the user clicks the back button. If not specified, the back button does not appear. Optional. */
  backButtonRoute?: string,
  /** The elements to show on the right of the page header bar. Optional. */
  headerControls?: IPageHeaderControl[],
  /** The content to show between the title and header controls in the page header bar. Optional. */
  centerNode?: React.ReactNode,
  /** The max number of character of the title to show before it becomes truncated. Default = 40. */
  maxTitleLength: number,
  /** Optional. If true, breadcrumbs will be shown above the title in its own row. */
  showBreadcrumbs?: boolean,
}

export interface IPageHeaderControl {
  key: string,
  element: React.ReactNode,
}

const PageBar: React.FC<IPageBarProps> = ({
  title,
  backButtonRoute,
  headerControls,
  centerNode,
  maxTitleLength,
  showBreadcrumbs,
}) => {
  return (
    <React.Fragment>
      {showBreadcrumbs && <Breadcrumbs />}
      <div
        className="page-bar"
      >
        <div
          className="pageBarTitle"
        >
          {backButtonRoute &&
            <Link
              to={backButtonRoute}
            >
              <img
                src={leftArrowIcon}
                alt="< Back"
                className="icon-small"
              />
            </Link>
          }

          <DetailText
            maxTextLength={maxTitleLength}
            text={`${title}`}
            showModalOnClick={true}
            modalData={{
              header: "Audit Identifier",
              content:
                <div>
                  <span>Name: {title}</span>
                </div>,
            }}
          />
        </div>
        {centerNode}
        <div
          className="controls"
        >
          {!!headerControls?.length && (
            <React.Fragment>
              {headerControls.map(control =>
                <div
                  key={control.key}
                >
                  {control.element}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PageBar;
