import { IPickerItem } from "shared/types/pickerTypes";
import { IProfileMetaData } from "shared/types/userProfileTypes";
import { IAuditGroup } from "types/auditMasterDataTypes";
import { IOwnerGroup } from "types/auditingTypes";
import { IBusinessTeam, IBusinessView, MetaDataTypes } from "types/masterDataTypes";
import {
  IAdminDefaultPlanningPeriod,
  IAdminPlanningAdministrationPeriod
} from "./adminPlanningAdministration";

export function filterAuditGroupsByRestriction(items: IAuditGroup[], metaData: IProfileMetaData[]): IAuditGroup[] {
  const restricted = metaData.filter(x => x.type === MetaDataTypes.AuditGroup)
    .map(x => x.masterDataId);

  if (restricted.length > 0) {
    let itemsSelected = items.filter(x => restricted.includes(x.id));
    if (itemsSelected.length > 0) {
      return itemsSelected;
    }
    else {
      return [{
        id: 0,
        name: "",
        isDeleted: false
      }];
    }
  }
  return items;
}

export function filterOwnerGroupsByRestriction(items: IOwnerGroup[], metaData: IProfileMetaData[]): IOwnerGroup[] {
  const restricted = metaData.filter(x => x.type === MetaDataTypes.OwnerGroup)
    .map(x => x.masterDataId);

  if (restricted.length > 0) {
    let itemsSelected = items.filter(x => restricted.includes(x.id));
    if (itemsSelected.length > 0) {
      return itemsSelected;
    }
    else {
      return [{
        id: 0,
        name: "",
        isDeleted: false,
        scoringSystem: ","
      }];
    }
  }
  return items;
}

export function filterBusinessTeamsByRestriction(items: IPickerItem<IBusinessTeam>[], metaData: IProfileMetaData[]): IPickerItem<IBusinessTeam>[] {
  const restricted = metaData.filter(x => x.type === MetaDataTypes.BusinessTeam)
    .map(x => x.masterDataId);

  if (restricted.length > 0) {
    return parseNodesForBusinessTeams(items, restricted, []);
  }
  return items;
}

function parseNodesForBusinessTeams(nodeList: IPickerItem<IBusinessTeam>[],
  allowedNodes: number[],
  ancestorNodes: IPickerItem<IBusinessTeam>[]): IPickerItem<IBusinessTeam>[] {

  let myIndexInParent;

  for (let n = nodeList.length - 1; n >= 0; n--) {
    const node = nodeList[n];

    const defaultedAncestors = (ancestorNodes || []);

    node.disabled = allowedNodes.indexOf(node.key as number) === -1
      && !defaultedAncestors.some(x => allowedNodes.indexOf(x.key as number) > -1);

    if (node.children?.length) {
      parseNodesForBusinessTeams(node.children, allowedNodes, defaultedAncestors.concat(node));
    }

    if (node.disabled
      && !node.children?.length) {
      if (defaultedAncestors.length) {
        myIndexInParent = defaultedAncestors[defaultedAncestors.length - 1].children?.indexOf(node) || 0;

        if (myIndexInParent > -1) {
          defaultedAncestors[defaultedAncestors.length - 1].children?.splice(myIndexInParent, 1);
        }
      } else {
        myIndexInParent = nodeList.indexOf(node);
        nodeList.splice(myIndexInParent, 1);
      }
    }
  }
  return nodeList;
}

export function filterBusinessViewByRestriction(items: IPickerItem<IBusinessView>[],
  metaData: IProfileMetaData[]): IPickerItem<IBusinessView>[] {

  const restricted = metaData.filter(x => x.type === MetaDataTypes.BusinessView)
    .map(x => x.masterDataId);

  if (restricted.length > 0) {
    return parseNodesForDivisions(items, restricted, []);
  }
  return items;
}

function parseNodesForDivisions(nodeList: IPickerItem<IBusinessView>[],
  allowedNodes: number[],
  ancestorNodes: IPickerItem<IBusinessView>[]): IPickerItem<IBusinessView>[] {

  let myIndexInParent;

  for (let n = nodeList.length - 1; n >= 0; n--) {
    const node = nodeList[n];

    const defaultedAncestors = (ancestorNodes || []);

    node.disabled = allowedNodes.indexOf(node.key as number) === -1
      && !defaultedAncestors.some(x => allowedNodes.indexOf(x.key as number) > -1);

    if (node.children?.length) {
      parseNodesForDivisions(node.children, allowedNodes, defaultedAncestors.concat(node));
    }

    if (node.disabled
      && !node.children?.length) {
      if (defaultedAncestors.length) {
        myIndexInParent = defaultedAncestors[defaultedAncestors.length - 1].children?.indexOf(node) || 0;

        if (myIndexInParent > -1) {
          defaultedAncestors[defaultedAncestors.length - 1].children?.splice(myIndexInParent, 1);
        }
      } else {
        myIndexInParent = nodeList.indexOf(node);
        nodeList.splice(myIndexInParent, 1);
      }
    }
  }
  return nodeList;
}

export function isPlanningDateRangePermissible(planningPeriod: IAdminPlanningAdministrationPeriod, currentDate: Date) {
  return (planningPeriod.manualOverride
    && currentDate < new Date(planningPeriod.overrideExpiryDate))
    || planningPeriod.planningAdministrationPeriodDates.checkDateInRange(currentDate);
}

export function isDefaultPlanningPeriodPermissible(defaultPeriod: IAdminDefaultPlanningPeriod, currentDate: Date) {
  return defaultPeriod.defaultStartDate <= currentDate
    && currentDate <= defaultPeriod.defaultEndDate;
}
