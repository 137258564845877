import { useCallback, useEffect } from "react";

/**
 * An async version of useEffect that will send an abort signal to the provided async function when dependencies change.
 * @param asyncFunc The async function to run. It will receive a flag determining if it should be aborted as well as an AbortSignal for http requests.
 * @param dependencies The dependency array passed to useEffect.
 */
export default function useAsyncEffect(asyncFunc: (aborted: boolean, abortSignal: AbortSignal) => Promise<void>, dependencies: any[]) {
  // eslint-disable-next-line
  const funcMemo = useCallback(asyncFunc, dependencies);

  useEffect(() => {
    let aborted = false;
    let controller = new AbortController();

    funcMemo(aborted, controller.signal);

    return () => {
      aborted = true;
      controller.abort();
    };
    // eslint-disable-next-line
  }, dependencies.concat(funcMemo));
}