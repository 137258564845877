export interface IRouteRoleMap {
  route: string,
  requiredRoles: string[],
};

export enum Roles {
  AuditPlanner = "AuditPlanner",
  Admin = "Admin",
  DefaultUser = "DefaultUser",
  SystemAdmin = "SystemAdmin",
  QuestionEditor = "QuestionEditor",
};