import { isEqual } from "lodash";
import { useEffect, useState } from "react";

/**
 * Initializes a state variable and a trigger to call a callback when the state variable equals a certain value.
 * @param value The initial value for the state variable.
 * @param callWhen The value that the state variable should equal in order to run the callback.
 * @param delayTimeMs The time in milliseconds to wait before running the callback.
 * @param callback The callback to run.
 * @returns An array containing the stateValue and a function to update it. (The same as useState hook).
 */
export default function useStateWithDelayedCallback<T>(value: T, callWhen: T, delayTimeMs: number, callback?: (value: T) => void):
  [T, React.Dispatch<React.SetStateAction<T>>] {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  useEffect(() => {
    let timeoutId: number;
    if (isEqual(internalValue, callWhen)) {
      timeoutId = window.setTimeout(
        () => {
          callback?.(internalValue);
        },
        delayTimeMs);
    }
    return () => clearTimeout(timeoutId);
  }, [internalValue, callWhen, delayTimeMs, callback]);

  return [internalValue, setInternalValue];
}