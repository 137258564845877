import React from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import Page from "shared/components/layout/page/Page";
import { useAppSelector } from "store/store";

interface IRequireAuthProps {
  roles: string[] | undefined,
  noViewPermissionMessage?: React.ReactNode,
  /** An optional props to pass children */
  children?: React.ReactNode,
}

const RequireAuth: React.FC<IRequireAuthProps> = ({
  roles,
  noViewPermissionMessage,
  children
}) => {
  const {
    activeUserProfile: {
      roleName
    },
    loadOperation,

  } = useAppSelector(store => store.auth);

  if (roles === undefined
    || roles?.length === 0
    || roles?.includes(roleName)) {
    return (
      <>
        {children}
      </>
    );
  }
  return (
    <>
      {loadOperation?.isWorking
        ? <Spinner />
        : <Page>
          <Banner type={BannerType.error} >
            {noViewPermissionMessage || "You do not have permission to view this area."}
          </Banner>
        </Page>}
    </>
  )
};

export default RequireAuth;