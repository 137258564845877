import React from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal from "shared/components/layout/modal/Modal";
import { editPlan, setEditPlanModal } from "store/audit-plan-approvals/AuditPlanApprovalsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IPlanChildDimension, IPlanParentDimension, IPlanRequirementDimension } from "types/auditPlanningTypes";
import AuditPlansEditTable from "./AuditPlansEditTable";
import "./EditPlanModal.scoped.scss";
import EditCLMPartialPlanModal from "./edit-clm-plan-modal/EditCLMPartialPlanModal";

interface IEditPlanProps {
  /**
   * Specifies if the modal is open or not. If not open, it does not render.
   */
  isOpen: boolean,
  /**
   * The details of the parent dimension for the plan.
   */
  parentDimension?: IPlanParentDimension,
  /**
   * The details of the parent dimension for the plan.
   */
  childDimension?: IPlanChildDimension,
  /**
   * The details of the child dimension for the plan.
   */
  requirementDimension?: IPlanRequirementDimension,
  /**
   * The details of the requirement dimension for the plan.
   */
}

const EditPlanModal: React.FC<IEditPlanProps> = ({
  isOpen,
  parentDimension,
  childDimension,
  requirementDimension,
}) => {
  const isEditingCLMPartial = useAppSelector(store => store.auditPlanApprovals.planApprovalEditModal.isEditingCLMPartial);
  const planItems = useAppSelector(store => store.auditPlanApprovals.planApprovalEditModal.items);
  const operation = useAppSelector(store => store.auditPlanApprovals.planApprovalEditModal.operation);
  const dispatch = useAppDispatch();

  if (operation
    && !planItems.length) {
    return null;
  }

  if (isEditingCLMPartial
    && isOpen) {
    return (
      <EditCLMPartialPlanModal
        isOpen={isOpen}
        planData={planItems[0]}
      />
    );
  }

  const onCancel = () => dispatch(setEditPlanModal({ isOpen: false, }));
  const onSave = () => dispatch(editPlan());

  return (
    <Modal
      isOpen={isOpen}
      header={"Change Audit Plan"}
      buttons={[{
        key: "cancel",
        text: "Cancel",
        className: "secondary",
        onClick: onCancel,
      }, {
        key: "editPlan",
        text: "Save",
        className: "primary",
        onClick: onSave,
      }]}
    >
      <FlexCol>
        <p>
          Would you like to EDIT the Audit Plan Details?
        </p>
        <AuditPlansEditTable />
        <table>
          <thead>
            <tr>
              <th>Requirement</th>
              <th>Sub Geo Unit</th>
              <th>Facility</th>
              <th>Business View (Divisions)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{requirementDimension?.text}</td>
              <td>{parentDimension?.text ?? "--"}</td>
              <td>{childDimension?.text ?? "--"}</td>
              <td>--</td>
            </tr>
          </tbody>
        </table>
      </FlexCol>
    </Modal>
  );
};

export default EditPlanModal;