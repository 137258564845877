import { Action } from "@reduxjs/toolkit";
import TemplatesApi from "api/auditing/templates/TemplatesApi";
import AuditPlanningApi from "api/planning/AuditPlanningApi";
import SearchAuditorsApi from "api/users/SearchAuditorsApi";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { setToSelectedItemsForCreating } from "shared/utilities/hierarchyUtilities";
import { updatePlansToLinkToAudit } from "store/audit-planning-shared/AuditPlanningSlice";
import { RootState } from "store/store";
import { IAuditType } from "types/auditMasterDataTypes";
import {
    IPlanTemplateHierarchyInfo
} from "types/auditPlanningTypes";
import { AuditorSearchTypes, ITemplate } from "types/auditingTypes";
import { ICalendarWeek } from "types/masterDataTypes";
import { IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";
import {
    ICreateAuditPlanContextInfo,
    checkSelfAuditor,
    finishCreateAudit,
    finishloadPlanTemplateChildren,
    loadPlanTemplateChildren,
    setCheckSelfAuditorOp,
    setCreateAuditModal,
    startCreateAudit
} from "./AuditPlanResultsSlice";

export default function* auditPlanResultsSagas() {
  yield all([
    startCreateAuditAsync(),
    checkSelfAuditorAsync(),
    loadPlanTemplateChildrenAsync(),
  ]);
}

function* startCreateAuditAsync() {
  yield takeEvery(startCreateAudit, function* (action: Action) {
    if (!startCreateAudit.match(action)) {
      return;
    }

    try {

      if (!action.payload.planIds.length) {
        throw new Error("No plans are selected.");
      }

      const selectedWeek: ICalendarWeek = yield select((store: RootState) => store.auditPlanResults.createAuditModal.week);

      const newAuditId: number = yield call(AuditPlanningApi.createAuditFromPlans,
        action.payload.planIds,
        new Date(action.payload.startDate),
        new Date(action.payload.endDate),
        action.payload.leadAuditor);

      // Update the plans in the state to mark them linked to the audit.
      yield put(updatePlansToLinkToAudit({
        planIds: action.payload.planIds,
        auditId: newAuditId,
        leader: action.payload.leadAuditor,
        week: selectedWeek,
      }));

      yield put(finishCreateAudit({
        isWorking: false,
      }));

      if (action.payload.afterAuditCreation) {
        action.payload.afterAuditCreation(newAuditId);
      }

      yield put(showSuccessToast("Audit created successfully."));

    } catch (err) {
      const errMsg = getResponseErrorMessage(err);

      yield put(finishCreateAudit({
        isWorking: false,
        errorMessage: errMsg,
      }));

      yield put(showErrorToast(errMsg));
    }
  });
}

function* checkSelfAuditorAsync() {
  yield takeLatest(checkSelfAuditor, function* (action) {
    if (!checkSelfAuditor.match(action)) {
      return;
    }

    const currUser: IAzureADUser = yield select((store: RootState) => store.auth.currentUser);
    const auditType: IAuditType | undefined = yield select((store: RootState) => store.auditPlanResults.createAuditModal.auditType);
    const plansInModal = action.payload;

    if (!plansInModal.length
      || plansInModal.some(x => !!x.plan.leadAuditorEmail)
      || !auditType) {
      // Not enough information to lookup user
      // or at least one plan already has a leader.

      // Kill off the loading operation.
      yield put(setCheckSelfAuditorOp(undefined));
      return;
    }

    if (auditType?.name === '1st Party - Official') {
      // Query the server to find if the current user is a Lead Auditor.
      // If so, set them as the currently selected leader.

      // Set operation to show loading.
      yield put(setCheckSelfAuditorOp({
        isWorking: true,
      }));

      // Query API to search for self.
      const searchResults: IAzureADUser[] = yield call(SearchAuditorsApi.searchAuditors,
        currUser.email,
        auditType.id,
        AuditorSearchTypes.LeadAuditor);

      // Put the first result into the selected auditor of the modal.
      yield put(setCreateAuditModal({
        selectedAuditor: searchResults.length
          ? searchResults[0]
          : undefined,
      }));
    } else {
      // Set the current user as the currently selected leader.
      yield put(setCreateAuditModal({
        selectedAuditor: currUser,
      }));
    }

    // Kill off the loading operation.
    yield put(setCheckSelfAuditorOp(undefined));
  });
}

function* loadPlanTemplateChildrenAsync() {
  yield takeLatest(loadPlanTemplateChildren, function* (action) {
    try {

      const templateFilter: ITemplate[] = [];
      let availableTemplates: IDetailedTemplate[] = [];
      const selectedDetailedTemplateItems: (IDetailedTemplate | IDetailedTemplateChildren)[] = [];

      // Load the plan data from the backend.
      const planTemplateHierarchyInfo: IPlanTemplateHierarchyInfo = yield call(AuditPlanningApi.getPlanTemplateHierarchyInfo, action.payload.planIds);
      const isFromAuditTemplate: boolean = action.payload.isFromAuditTemplate;
      const templateIds: number[] = action.payload.requirementIds;

      if (isFromAuditTemplate) {
        templateFilter.push({
          id: templateIds[0],
          isDeleted: false,
          name: "",
          ownerGroupId: undefined
        });

        availableTemplates = yield call(TemplatesApi.getDetailedTemplates, {
          ownerGroups: undefined,
          auditTopics: undefined,
          templateIdFilter: templateFilter,
          includeDeleted: false,
        });

        // Define to apply as selected only included items, if there are not item should select all mandatory items
        const shouldApplyOnlySelectedChildren = planTemplateHierarchyInfo.selectedProfileStandardIds.length > 0;

        // Set as selected all mandatory child
        availableTemplates.forEach(templateItem => {
          setToSelectedItemsForCreating(selectedDetailedTemplateItems, templateItem.children, planTemplateHierarchyInfo.selectedProfileStandardIds, shouldApplyOnlySelectedChildren, true);
        });
      }

      const planContextInfo: ICreateAuditPlanContextInfo = {
        availableTemplates: availableTemplates.length > 0
          ? availableTemplates[0].children
          : [],
        selectedDetailedTemplateItems,
        planTemplateHierarchyInfo,
      };

      yield put(finishloadPlanTemplateChildren({
        isWorking: false,
        data: planContextInfo,
      }));

    } catch (err) {
      yield put(finishloadPlanTemplateChildren({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}
