import { groupBy } from "lodash";
import { BusinessViewType, IBusinessView } from "types/masterDataTypes";

/**
 * Groups and sorts BusinessViews for display.
 * @param items BVs to group and sort.
 * @returns An array of groupings, each with a title, items, and pre-mapped labels.
 */
export function getGroupedBusinessViews(items?: IBusinessView[]): IGroupedBusinessViews[] {
  if (!items) {
    return [];
  }

  return Object.entries(groupBy(items, x => x.type))
    .map((group): IGroupedBusinessViews => ({
      type: group[1][0].type,
      title: bvTypeLabelMappings[group[1][0].type].singular,
      pluralTitle: bvTypeLabelMappings[group[1][0].type].plural,
      items: group[1],
      labels: group[1].map(x => `${x.code} - ${x.name}`).sort((a, b) => a < b ? -1 : 1),
    })).sort((a, b) => bvSortOrder[a.type] < bvSortOrder[b.type] ? -1 : 1);
}

const bvSortOrder: Record<BusinessViewType, number> = {
  "Division": 1,
  "BusinessLine": 2,
  "SubBusinessLine": 3,
  "Support": 4,
};

const bvTypeLabelMappings: Record<BusinessViewType, { singular: string, plural: string; }> = {
  "Division": { singular: "Division", plural: "Divisions" },
  "BusinessLine": { singular: "Business Line", plural: "Business Lines" },
  "SubBusinessLine": { singular: "Sub-Business Line", plural: "Sub-Business Lines" },
  "Support": { singular: "Support", plural: "Supports" },
};

export interface IGroupedBusinessViews {
  /** The BusinessView.type property that was used to group by. */
  type: BusinessViewType,
  /** The title of this group. Corresponds to "Function Category", "Function" or "Sub-Function". */
  title: string,
  /** The title of this group. Corresponds to "Function Categories", "Functions" or "Sub-Functions". */
  pluralTitle: string,
  /** The items constituting this group. */
  items: IBusinessView[],
  /** Each item, pre-sorted, and mapped into the format `CODE - NAME`. */
  labels: string[],
}