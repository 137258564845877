import { PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { IUserPlanningView } from "types/audit-planning-shared/UserPlanningViews";

export interface IUserPlanningViewsState {
  userPlanningViews: IUserPlanningView[],
  currentPlanningView?: IUserPlanningView,

  saveViewModal: IUserPlanningViewsSaveUserViewModal,
  isDeleteModalOpen: boolean,

  savePlanningViewsOp?: IOperation<void>,
  loadPlanningViewsOp?: IOperation<IUserPlanningView[]>,
  loadPlanningViewValuesOp?: IOperation<void>,
  deletePlanningViewsOp?: IOperation<IUserPlanningView>,
}

interface IUserPlanningViewsSaveUserViewModal {
  isOpen: boolean,
  saveType: "save" | "saveAs",
  viewName: string,
}

export const initialUserPlanningViewsState: IUserPlanningViewsState = {
  userPlanningViews: [],
  currentPlanningView: undefined,

  saveViewModal: {
    isOpen: false,
    saveType: "save",
    viewName: "",
  },
  isDeleteModalOpen: false,

  savePlanningViewsOp: undefined,
  loadPlanningViewsOp: undefined,
  deletePlanningViewsOp: undefined,
}

export const userPlanningViewsReducers = {
  /** Sets the properties of the Save View Modal. */
  setSaveViewModal: (state: IUserPlanningViewsState, action: PayloadAction<Partial<IUserPlanningViewsSaveUserViewModal>>) => {
    Object.assign(state.saveViewModal, action.payload);
  },

  /** Starts the process to save (update or create) a user planning view. */
  savePlanningView: (state: IUserPlanningViewsState, __: PayloadAction<{
    viewName: string,
    saveType: "save" | "saveAs",
  }>) => {
    state.savePlanningViewsOp = {
      isWorking: true,
    };
  },

  /** Finish the process to save (update or create) a user planning view. */
  finishSavePlanningView: (state: IUserPlanningViewsState, action: PayloadAction<IOperation<IUserPlanningView>>) => {
    state.savePlanningViewsOp = undefined;

    if (action.payload.errorMessage
      || !action.payload.data) {
      return;
    }

    state.saveViewModal.isOpen = false;
    state.currentPlanningView = action.payload.data;

    if (state.userPlanningViews.find(x => x.id === action.payload.data?.id)) {
      return;
    }

    state.userPlanningViews.push(action.payload.data);
    state.userPlanningViews.sort((a, b) => a.name < b.name ? -1 : 1);
  },

  /** Starts the process to load planning views. */
  loadPlanningViews: (state: IUserPlanningViewsState) => {
    state.loadPlanningViewsOp = {
      isWorking: true,
    };
  },

  /** Finish the process to load planning views. */
  finishLoadPlanningViews: (state: IUserPlanningViewsState, action: PayloadAction<IOperation<IUserPlanningView[]>>) => {
    if (action.payload.errorMessage) {
      state.loadPlanningViewsOp = action.payload;
      return;
    }

    state.userPlanningViews = action.payload.data ?? [];
    state.loadPlanningViewsOp = undefined;
  },

  /** Set the current planning views and start the process of loading the planning view values. */
  setCurrentPlanningView: (state: IUserPlanningViewsState, action: PayloadAction<{
    planningView: IUserPlanningView | undefined,
    page?: "FacilityAttributes" | "PlanApprovals" | "PlanResults",
  }>) => {
    state.currentPlanningView = action.payload.planningView;

    if (action.payload.planningView) {
      state.loadPlanningViewValuesOp = {
        isWorking: true,
      };
    }
  },

  /** Finish the process to load planning view values. */
  finishLoadPlanningViewValues: (state: IUserPlanningViewsState) => {
    state.loadPlanningViewValuesOp = undefined;
  },

  /** Set the delete modal is open. */
  setIsDeleteModalOpen: (state: IUserPlanningViewsState, action: PayloadAction<boolean>) => {
    state.isDeleteModalOpen = action.payload;
  },

  /** Starts the process to delete planning view. */
  deletePlanningView: (state: IUserPlanningViewsState, _: PayloadAction<{ userPlanningViewId: number, }>) => {
    state.deletePlanningViewsOp = {
      isWorking: true,
    };
  },

  /** Finish the process to delete planning view. */
  finishDeletePlanningView: (state: IUserPlanningViewsState, action: PayloadAction<IOperation<number>>) => {
    state.deletePlanningViewsOp = undefined;

    if (action.payload.errorMessage
      || !action.payload.data) {
      return;
    }

    state.isDeleteModalOpen = false;

    if (state.currentPlanningView?.id === action.payload.data) {
      state.currentPlanningView = undefined;
    }

    state.userPlanningViews = state.userPlanningViews.filter(x => x.id !== action.payload.data!);
  },
};