import React from 'react';
import "./FlowLayout.scoped.scss";

interface IFlowLayoutProps {
  header?: JSX.Element,
  footer?: React.ReactNode,
  classNames?: {
    layout?: string,
    header?: string,
    body?: string,
    footer?: string,
  },
  /** An optional props to pass children */
  children?: React.ReactNode,
}

const FlowLayout: React.FC<IFlowLayoutProps> = ({ children, header, footer, classNames }) =>
  <div className={`flow-layout ${classNames?.layout || ""}`}>
    {header &&
      <div className={`flow-header ${classNames?.header || ""}`}>
        {header}
      </div>
    }
    <div className={`flow-body ${classNames?.body || ""}`}>
      {children}
    </div>
    {footer &&
      <div className={`flow-footer ${classNames?.footer || ""}`}>
        {footer}
      </div>
    }
  </div>;

export default FlowLayout;
