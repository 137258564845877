import React, { useCallback, useState } from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Modal, { ModalSizes } from "shared/components/layout/modal/Modal";
import { assignAuditorByAssociations, setAssignAuditorByAssociationsModal } from "store/audit-summary/AuditSummarySlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { IAuditInfo, IAuditQuestion } from "types/auditingTypes";
import AuditTopicTabContent from "./tab-content/AuditTopicTabContent";
import BusinessFunctionTabContent from "./tab-content/BusinessFunctionTabContent";
import BusinessTeamTabContent from "./tab-content/BusinessTeamTabContent";
import BusinessViewTabContent from "./tab-content/BusinessViewTabContent";
import OtherAssociationsTabContent from "./tab-content/OtherAssociationsTabContent";
import AssocTabs, { AssocTabKeys } from "./tabs/AssocTabs";

const AuditorAssociationModal: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<AssocTabKeys>(AssocTabKeys.auditTopics);
  const {
    selectedAuditTopics,
    selectedBusinessFunctions,
    selectedBusinessTeams,
    selectedBusinessViews,
    selectedOtherAssociations,
  } = useAppSelector(store => store.auditSummary.assignAuditorByAssociationsModal);

  const dispatch = useAppDispatch();

  const isOnAddDisabled = !selectedAuditTopics.length
    && !selectedBusinessFunctions.length
    && !selectedBusinessTeams.length
    && !selectedBusinessViews.length
    && !selectedOtherAssociations.length;

  const onCloseModal = () => dispatch(setAssignAuditorByAssociationsModal({
    isOpen: false,
  }));

  const onAdd = useCallback(() => {
    dispatch(assignAuditorByAssociations());
  }, [dispatch]);

  return (
    <Modal
      isOpen={true}
      header="Add Associations"
      showCloseButton={true}
      onCloseButtonClicked={onCloseModal}
      buttons={[{
        key: "cancel",
        text: "Cancel",
        className: "secondary",
        onClick: onCloseModal,
      }, {
        key: "add",
        text: "Add",
        className: "primary",
        disabled: isOnAddDisabled,
        onClick: onAdd,
      }]}
      size={ModalSizes.maximumHeight}
      width={1000}
    >
      <FlexCol>
        <AssocTabs
          currentTabKey={currentTab}
          onChange={setCurrentTab}
        />

        {getTabContent(<AuditTopicTabContent />, AssocTabKeys.auditTopics, currentTab)}
        {getTabContent(<BusinessTeamTabContent />, AssocTabKeys.businessTeams, currentTab)}
        {getTabContent(<BusinessFunctionTabContent />, AssocTabKeys.businessFunctions, currentTab)}
        {getTabContent(<BusinessViewTabContent />, AssocTabKeys.businessViews, currentTab)}
        {getTabContent(<OtherAssociationsTabContent />, AssocTabKeys.otherAssociations, currentTab)}
      </FlexCol>
    </Modal>
  );
};

export default AuditorAssociationModal;

function getTabContent(node: React.ReactNode, tab: AssocTabKeys, currentTab: AssocTabKeys) {
  return (
    <div style={tab === currentTab
      ? undefined
      : { display: "none" }}
    >
      {node}
    </div>
  );
}

export function findGlobalAssocQuestionsInAudit(questions: IAuditQuestion[], audit: IAuditInfo) {
  return questions.filter(question => isQuestionGlobalAssociation(question, audit));
}

export function isQuestionGlobalAssociation(question: IAuditQuestion, audit: IAuditInfo) {
  return !question
    .subTopics
    .some(x => audit.auditTopics.map(z => z.id).includes(x.id))
    && !question
      .countries
      .some(x => audit.countries.map(z => z.id).includes(x.id))
    && !question
      .businessViews
      .some(x => audit.businessViews.map(z => z.id).includes(x.id))
    && !question
      .businessTeams
      .some(x => audit.businessTeams.map(z => z.id).includes(x.id))
    && !question
      .businessFunctions
      .some(x => audit.businessFunctions.map(z => z.id).includes(x.id));
}