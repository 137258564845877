import React from "react";
import TabSelection from "shared/components/layout/tab-selection/TabSelection";
import "./AssocTabs.scoped.scss";

export enum AssocTabKeys {
  auditTopics = "auditTopics",
  businessTeams = "businessTeams",
  businessViews = "businessViews",
  businessFunctions = "businessFunctions",
  otherAssociations = "otherAssociations",
}

interface IAssocTabsProps {
  currentTabKey: AssocTabKeys,
  onChange: (newTab: AssocTabKeys) => void,
}

const AssocTabs: React.FC<IAssocTabsProps> = ({
  currentTabKey,
  onChange,
}: IAssocTabsProps) => {


  return (
    <div
      className="assoc-tab-holder"
    >
      <TabSelection
        showDividers={false}
        onTabClicked={key => onChange(key as AssocTabKeys)}
        tabs={[
          getTab(AssocTabKeys.auditTopics, "Topic/Sub-Topic", currentTabKey),
          getTab(AssocTabKeys.businessTeams, "Business Team (Basin Hierarchy)", currentTabKey),
          getTab(AssocTabKeys.businessViews, "Business View (Division)", currentTabKey),
          getTab(AssocTabKeys.businessFunctions, "Business Function (Function)", currentTabKey),
          getTab(AssocTabKeys.otherAssociations, "Other associations", currentTabKey),
        ]}
      />
    </div>
  );
};

export default AssocTabs;

function getTab(key: AssocTabKeys, text: string, currentTabKey: AssocTabKeys) {
  return {
    key: key,
    node: text,
    isSelected: currentTabKey === key,
  };
}