import React from "react";
import { Link } from "react-router-dom";
import { IAppInfo } from "shared/types/appInfoTypes";
import logoIcon from "../../../media/icons/logo-icon.svg";
import logoDivider from "../../../media/logo-divider.svg";
import "./LogoTitle.scoped.scss";

interface ILogoTitleProps {
  /** The name of the site to show. */
  title: string,
  /** The url to visit when the user clicks the title. */
  titleUrl: string,
  /** Info about the current app version. Specifically uses the env property and compares
   * against (tolowercase) "prod". If not "prod", the environment will be shown between
   * the logo and the site name and a message will be shown with a link to the prod instance
   * between the logo area and the user area.
   */
  appInfo?: IAppInfo,
}

const LogoTitle: React.FC<ILogoTitleProps> = ({
  title,
  titleUrl,
  appInfo,
}: ILogoTitleProps) => {
  const showNonProd = appInfo?.env
    && appInfo.env.toLowerCase() !== "prod";

  return (
    <div
      className="logo-row"
    >
      <a
        href="https://www.slb.com"
        target="_blank"
        rel="noreferrer"
        className="slb-link"
      >
        <img
          alt=""
          src={logoIcon}
          className="logo-icon"
        />
      </a>

      <img
        alt="|"
        src={logoDivider}
        className="logo-divider"
      />

      QI

      {showNonProd && (
        <>
          <img
            alt="|"
            src={logoDivider}
            className="logo-divider"
          />

          <span
            className="environment"
          >
            {appInfo?.env?.toUpperCase()}
          </span>
        </>
      )}

      <img
        alt="|"
        src={logoDivider}
        className="logo-divider"
      />

      <Link
        className="site-name"
        to={titleUrl}
      >
        {title}
      </Link>
    </div>
  );
};

export default LogoTitle;