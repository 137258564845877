import QuestionApi from "api/question/QuestionApi";
import React, { useRef, useState } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IBulkFile } from "shared/types/bulkFileTypes";
import { downloadBlob, validateFileSize } from "shared/utilities/fileUtilities";
import {
  closeBulkUploadQuestionsModal,
  startBulkUploadQuestionsByApi
} from "store/admin/questions/bulk-upload-questions/BulkUploadQuestionsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { v4 as uuidv4 } from "uuid";
import FileManagerGrid from "./FileManagerGrid";
import './BulkUploadQuestionsModal.scoped.scss';

const allowedFileTypes = [
  ".xls",
  ".xlsx",
  ".xlsb",
  ".xlsm",
  ".csv",
];

const maxFileSizeMB = 50;

interface IBulkUploadQuestionsModalProps {
  onComplete?(): void,
  onClose?(): void,
}

const BulkUploadQuestionsModal: React.FC<IBulkUploadQuestionsModalProps> = ({
  onComplete,
  onClose,
}) => {

  const uploadOp = useAppSelector(store => store.bulkUploadQuestions.uploadOp);
  const dispatch = useAppDispatch();
  const [fileError, setFileError] = useState("");
  const [filesLoaded, setFilesLoaded] = useState<IBulkFile[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);

  const onSaveClicked = () => {

    if (!filesLoaded.length) {
      const err = "At least 1 file is required.";
      setFileError(err);
      dispatch(showErrorToast(err));
      return;
    }

    if (filesLoaded.length > 5) {
      const err = "Maximum 5 files can be uploaded.";
      setFileError(err);
      dispatch(showErrorToast(err));
      return;
    }

    if (fileRef?.current?.files) {
      for (let i = 0; i < fileRef.current.files.length; i++) {
        if (!validateFileSize(fileRef.current.files[i], maxFileSizeMB)) {
          const err = `There are very large files. Please choose a file size less than or equal to ${maxFileSizeMB} MB`;
          setFileError(err);
          dispatch(showErrorToast(err));
          return;
        }
      }

      // Finally, get files and execute bulk upload
      const files: File[] = Array.from(fileRef.current.files)
        .filter(f => filesLoaded.findIndex(bf => bf.name === f.name) !== -1);

      executeBulkImportQuestions(files);
    }
  }

  const onDeleteFile = (key: string) => {
    setFileError("");
    setFilesLoaded([...filesLoaded.filter(f => f.key !== key)]);
    if (fileRef?.current?.files) {
      const files: File[] = Array.from(fileRef.current.files);
      const indexFile = files.findIndex(f => f.name === key);
      files.splice(indexFile, 1);
    }

    if (filesLoaded.length === 1 && fileRef.current) {
      fileRef.current.value = "";
    }
  }

  const onCloseButtonClicked = () => {
    dispatch(closeBulkUploadQuestionsModal());
    onClose && onClose();
  }

  const onFileChosen = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files?.length) {
      return;
    }

    const files = Array.from(e.currentTarget.files);
    if (files) {
      const listFiles: IBulkFile[] = [];
      for (let i = 0; i < files.length; i++) {
        listFiles.push({ key: uuidv4(), name: files[i].name })
      }
      setFilesLoaded(listFiles);
      setFileError("");
    }
  }

  const executeBulkImportQuestions = async (files: File[]) => {
    try {
      dispatch(startBulkUploadQuestionsByApi());
      const blob: Blob = await QuestionApi.bulkUploadQuestions(files);

      dispatch(showSuccessToast("Bulk upload processed."));
      downloadBlob(blob, `bulk-upload-logs.xlsx`);
      onComplete && onComplete();
      onClose && onClose();
    }
    catch (err) {
      dispatch(showErrorToast("An error ocurred during the processing bulk upload questions."));
    }
    dispatch(closeBulkUploadQuestionsModal());
  }

  return (
    <Modal
      header="Bulk Upload Questions"
      isOpen={true}
      buttons={[{
        className: "secondary",
        text: "Cancel",
        key: "CANCEL",
        onClick: onCloseButtonClicked,
      }, {
        className: "primary",
        text: "Upload",
        key: "UPLOAD",
        disabled: !!fileError,
        onClick: onSaveClicked,
      }]}
    >

      <div style={{ border: "1px dashed #000", padding: "12px" }}>
        <label className='filePicker' htmlFor="filePicker" >
          ADD FILES
        </label>
        {filesLoaded.length === 1 && <span style={{ fontWeight: "bold" }}> 1 file loaded</span>}
        {filesLoaded.length > 1 && <span style={{ fontWeight: "bold" }}> {filesLoaded.length} files loaded</span>}
        <input
          id="filePicker"
          style={{ visibility: "hidden" }}
          type="file"
          accept={allowedFileTypes.join(",")}
          onChange={onFileChosen}
          ref={fileRef}
          className={fileError ? "error" : ""}
          multiple={true}
        />
      </div>
      {!!fileError &&
        <span
          className="too-large"
        >
          {fileError}
        </span>
      }
      <FileManagerGrid files={filesLoaded} allowDelete={true} onElementDeleted={(key: string) => { onDeleteFile(key) }} />
      {uploadOp?.isWorking && (
        <ModalSpinner />
      )}
    </Modal>
  );
};

export default BulkUploadQuestionsModal;
