import { formatAuditTopic } from "api/masterdata/AuditTopicApi";
import { authGetJson, authPostJson, authPutJson } from "auth/authFetches";
import config from "config";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { formatSaveAuditTopicRequest } from "./formatters/auditTopicFormatters";

class AuditTopicApi {
  /** Gets a single auditTopic from the API by its Id. */
  public async getAuditTopicDetails(id: number, abortSignal?: AbortSignal): Promise<IAuditTopic> {
    const response = await authGetJson(`${config.endpoints.baseUrl}/auditTopics/${id}`,
      abortSignal);
    await throwIfResponseError(response);
    const respObj = await response.json();
    return formatAuditTopic(respObj);
  }

  /** Marks the specified auditTopic as inactive. */
  public async revertStatus(id: number,
    abortSignal?: AbortSignal): Promise<IAuditTopic> {
    const response = await authPutJson(`${config.endpoints.baseUrl}/auditTopics/${id}/revertStatus`,
      abortSignal);

    await throwIfResponseError(response);
    const respObj = await response.json();
    return formatAuditTopic(respObj);
  }

  /**
   * Creates a new auditTopic (if `auditTopic.Id` is 0) or update an existing auditTopic.
   * @param auditTopic
   * @param abortSignal
   * @returns The Id of the newly created auditTopic or auditTopic version.
   */
  public async saveAuditTopic(auditTopic: Partial<IAuditTopic>,
    abortSignal?: AbortSignal): Promise<number> {
    const requestObj = formatSaveAuditTopicRequest(auditTopic);

    // If the auditTopic has no id number, consider it a creation. Else, update.
    const response = !auditTopic.id
      ? await authPostJson(`${config.endpoints.baseUrl}/auditTopics`,
        requestObj,
        abortSignal)
      : await authPutJson(`${config.endpoints.baseUrl}/auditTopics/${auditTopic.id}`,
        requestObj,
        abortSignal);

    await throwIfResponseError(response);
    return await response.json();
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuditTopicApi();
