import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { AuditStatuses, IEvidenceLinkItem } from "types/auditingTypes";

export interface IQuestionEvidenceState {
  isModalVisible: boolean,
  isManageEvidenceModalVisible: boolean,
  currentEvidence?: IManageEvidenceItem,
  questionEvidenceData?: IQuestionEvidenceData,
  loadOp?: IOperation<IQuestionEvidenceData>,
  uploadOp?: IOperation<IEvidenceLinkItem>,
  deleteOp?: IOperation<IEvidenceLinkItem>,
  viewEvidenceOp?: IOperation<IEvidenceLinkItem>,
}

const initialState: IQuestionEvidenceState = {
  isModalVisible: false,
  isManageEvidenceModalVisible: false,
  currentEvidence: undefined,
  questionEvidenceData: undefined,
  loadOp: undefined,
  uploadOp: undefined,
  deleteOp: undefined,
  viewEvidenceOp: undefined,
};

export const questionEvidenceSlice = createSlice({
  name: "question-evidence",
  initialState,
  reducers: {
    setEvidenceData: (state, action: PayloadAction<IQuestionEvidenceData | undefined>) => {
      state.questionEvidenceData = action.payload;
    },
    openModal: (state, _: Action) => {
      state.isModalVisible = true;
    },
    closeModal: (state, _: Action) => {
      state.isModalVisible = false;
    },
    openManageEvidenceModal: (state, action: PayloadAction<IManageEvidenceItem | undefined>) => {
      state.isManageEvidenceModalVisible = true;
      state.currentEvidence = action.payload;
    },
    closeManageEvidenceModal: (state, _: Action) => {
      state.isManageEvidenceModalVisible = false;
      state.currentEvidence = undefined;
    },
    updateEvidence: (state, _: PayloadAction<IUpdateEvidencePayload>) => {
      state.uploadOp = {
        isWorking: true,
      };
    },
    finishUploadingEvidence: (state, action: PayloadAction<IOperation<IFinishUploadingEvidencePayload>>) => {
      state.uploadOp = undefined;

      if (action.payload.errorMessage
        || !action.payload.data) {
        return;
      }

      // Add it to the modal list.
      if (state.questionEvidenceData?.auditQuestionId === action.payload.data.auditQuestionId) {
        state.questionEvidenceData.evidence.push(action.payload.data.item);
      }

      state.isManageEvidenceModalVisible = false;
    },
    finishUpdatingEvidence: (state, action: PayloadAction<IOperation<IUpdateEvidencePayload>>) => {
      state.uploadOp = undefined;

      if (action.payload.errorMessage
        || !action.payload.data) {
        return;
      }

      const existingItem = state.questionEvidenceData?.evidence
        .find(x => x.info === action.payload.data?.filename);

      if (existingItem) {
        existingItem.description = action.payload.data.description;
      }

      state.isManageEvidenceModalVisible = false;
    },
    deleteEvidence: (state, _: PayloadAction<IDeleteEvidencePayload>) => {
      state.deleteOp = {
        isWorking: true,
      };
    },
    finishDeletingEvidence: (state, action: PayloadAction<IOperation<IDeleteEvidencePayload>>) => {
      state.deleteOp = undefined;

      if (action.payload.errorMessage
        || !state.questionEvidenceData?.evidence) {
        return;
      }

      state.questionEvidenceData.evidence =
        state.questionEvidenceData.evidence.filter(item => item.info !== action.payload.data?.filename);
    },
    viewEvidence: (state, _: PayloadAction<IViewQuestionEvidencePayload>) => {
      state.viewEvidenceOp = {
        isWorking: true,
      };
    },
    finishViewingEvidence: (state) => {
      state.viewEvidenceOp = undefined;
    },
  },
});

export const {
  setEvidenceData,
  openModal,
  closeModal,
  openManageEvidenceModal,
  closeManageEvidenceModal,
  updateEvidence,
  finishUploadingEvidence,
  finishUpdatingEvidence,
  deleteEvidence,
  finishDeletingEvidence,
  viewEvidence,
  finishViewingEvidence,
} = questionEvidenceSlice.actions;

export interface IQuestionEvidenceData {
  questionId: number,
  auditQuestionId: number,
  auditId: number,
  questionNumber: number,
  evidence: IEvidenceLinkItem[],
}

export interface IUploadEvidencePayload {
  auditQuestionId: number,
  auditId: number,
  file: File,
  description: string,
}

export interface IUpdateEvidencePayload {
  auditQuestionId: number,
  auditId: number,
  description: string,
  filename: string,
}

export interface IFinishUploadingEvidencePayload {
  auditQuestionId: number,
  item: IEvidenceLinkItem,
}

export interface IDeleteEvidencePayload {
  auditQuestionId: number,
  auditId: number,
  filename: string,
}

export interface IViewQuestionEvidencePayload {
  auditQuestionId: number,
  auditId: number,
  evidenceId: number,
}

export interface IManageEvidenceItem {
  description: string,
  filename?: string,
  auditStatus: AuditStatuses,
}