import TemplatesApi from "api/auditing/templates/TemplatesApi";
import React from "react";
import NonReduxShowPicker from "shared/components/controls/pickers/non-redux-show-picker/NonReduxShowPicker";
import { ICommonPickerProps, PickerMode } from "shared/components/controls/pickers/pickerTypes";
import { IAuditTopic } from "types/auditMasterDataTypes";
import { ITemplate } from "types/auditingTypes";
import { IAuditTemplatesFilters, IDetailedTemplate, IDetailedTemplateChildren } from "types/templateApiTypes";


interface ITemplatePickerProps {

  masterDataId?: number,
  /** Optional. Determines the picker's mode. Default = Picker. */
  renderMode?: PickerMode,
  /** Flag to determine if the selection is required or not. */
  isRequired?: boolean,
  /** Optional. Determines if only the top level items are selectable. Default = true. */
  isOnlyTopLevelSelectable?: boolean,
  /** Optional. When items are selected, the trees will be traversed and all items in the tree will be split into a list of templates
   * and a list of auditTopics and sent to this callback. */
  onApplySplit?: (appliedData: ITemplatePickerAppliedItemsSplit) => void,

  topicTemplate?: ITemplate[] | IAuditTopic[],
}

interface ITemplatePickerAppliedItemsSplit {
  templates: ITemplate[],
  auditTopics: IAuditTopic[],
}

const TemplateTooltipContents: React.FC<ITemplatePickerProps & ICommonPickerProps<ITemplate | IDetailedTemplate | IDetailedTemplateChildren>> = ({
  selectedItems,
  allowMultiselect = false,
  isRequired,
  isItemDisabledMapper,
  masterDataId,
  isOnlyTopLevelSelectable = true,

}) => {
  const renderItem = (item: ITemplate | IDetailedTemplate | IDetailedTemplateChildren) => item.name;

  const templateData: ITemplate[] = [{
    id: masterDataId!,
    name: '',
    isDeleted: false,
    ownerGroupId: undefined,
  }]

  const filters: IAuditTemplatesFilters = { auditTopics: undefined, ownerGroups: undefined, templateIdFilter: templateData, includeDeleted: false };
  const loadItems = async (_: string | undefined, abortSignal: AbortSignal) =>
    await TemplatesApi.getDetailedTemplates(filters, abortSignal);

  const internalIsDisabledMapper = (item: ITemplate | IDetailedTemplate | IDetailedTemplateChildren,
    ancestorPath: (ITemplate | IDetailedTemplate | IDetailedTemplateChildren)[]) => {
    if (isItemDisabledMapper && isItemDisabledMapper(item, ancestorPath)) {
      return true;
    }

    return isOnlyTopLevelSelectable
      ? ancestorPath.length > 0
      : false;
  };

  return (
    <NonReduxShowPicker
      preserveSuggestedItems={false}
      selectedItems={selectedItems}
      renderListItem={item => item.name}
      onSelectionChanged={() => { }}
      keyMapper={(item) => item.id}
      childMapper={(item) => (item as IDetailedTemplate).children || []}
      title="Templates"
      isRequired={isRequired}
      onLoadItems={loadItems}
      searchOptions={{
        show: false,
        behavior: "async",
        asyncMinChars: -1,
        filterItem: (item, search) => renderItem(item).toLowerCase().includes(search.toLowerCase()),
      }}
      allowMultiSelect={allowMultiselect}
      isDisabledMapper={internalIsDisabledMapper}
      expandedKeyIds={[masterDataId!]}
    />
  )
};

export default TemplateTooltipContents;