import AuditsApi from "api/auditing/AuditsApi";
import IModalEvidenceItem from "components/evidence/IEvidenceItem";
import { EvidenceListModalModes, getEvidenceTableColumn } from "components/evidence/list/EvidenceListModal";
import ManageEvidenceItemModal from "components/evidence/manage/ManageEvidenceItemModal";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import attIcon from "shared/media/dls/attachment.svg";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IOperation } from "shared/types/operationTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { removeQuestionEvidence, setQuestionEvidenceItem } from "store/audit/AuditSlice";
import { useAppSelector } from "store/store";
import { EditRestriction } from "types/auditPageAuthTypes";
import { AuditStatuses, IAuditQuestion, IEvidenceLinkItem } from "types/auditingTypes";
import "./EvidenceCard.scoped.scss";

interface IEvidenceCardProps {
  question: IAuditQuestion,
  auditId: number,
  auditStatus: AuditStatuses,
}

const EvidenceCard: React.FC<IEvidenceCardProps> = ({
  question,
  auditId,
  auditStatus,
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();
  const currentUserEmail = useAppSelector(store => store.auth.currentUser.email);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);
  const [loadListOp, setLoadListOp] = useState<IOperation<IModalEvidenceItem[]>>({
    isWorking: false,
  });
  const [manageModalData, setManageModalData] = useState<{
    editingId: number | undefined,
    isOpen: boolean,
  }>({
    editingId: undefined,
    isOpen: false,
  });

  const userOwnsQuestion = question.auditorEmail?.toLowerCase() === currentUserEmail.toLowerCase();
  const isEditable = !question.responses.some(x => x.isOutOfScope)
    && auditPageAuth.editRestriction === EditRestriction.EditAll;

  const allowEdit = isEditable
    || (auditPageAuth.editRestriction === EditRestriction.EditOwn
      && userOwnsQuestion);

  const onSelectionChanged = (evidenceId: number, isSelected: boolean) => {
    if (isSelected && selectedIds.indexOf(evidenceId) === -1) {
      setSelectedIds(selectedIds.concat(evidenceId));
    } else if (!isSelected && selectedIds.indexOf(evidenceId) > -1) {
      setSelectedIds(selectedIds.filter(x => x !== evidenceId));
    }
  };

  const getEvidenceItems = useCallback(async (_: AbortSignal) => {
    return question.evidence.map((item): IModalEvidenceItem => ({
      filename: item.type === "Evidence" ? item.info : undefined,
      link: item.type === "Link" ? item.info : undefined,
      id: item.id,
      notes: item.description,
      type: item.type,
    }));
  }, [question.evidence]);

  const modalItemToEvidenceItem = (item: IModalEvidenceItem): IEvidenceLinkItem => {
    return {
      auditStatus,
      description: item.notes,
      info: item.filename ? item.filename : item.link!,
      id: item.id,
      type: item.type,
    } as IEvidenceLinkItem;
  }

  const onItemCreated = (item: IEvidenceLinkItem) => {
    dispatch(setQuestionEvidenceItem({ item, auditQuestionId: question.auditQuestionId }));
  };

  const onItemUpdated = (item: IEvidenceLinkItem) => {
    dispatch(setQuestionEvidenceItem({ item, auditQuestionId: question.auditQuestionId }));
  };


  const deleteItem = async (deleteId: number) => {
    try {
      const item = loadListOp.data?.find(x => x.id === deleteId);

      if (!item) {
        throw new Error(`Unknown id: ${deleteId}.`);
      }

      setIsDeleting(true);

      await deleteEvidenceItem?.(item);
      onItemDeleted(modalItemToEvidenceItem(item));

      dispatch(showSuccessToast("Evidence deleted successfully."));

      setLoadListOp({
        ...loadListOp,
        data: loadListOp.data?.filter(x => x.id !== deleteId),
      });
      setConfirmDeleteId(undefined);
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      setIsDeleting(false);

    }
  };

  const onItemDeleted = (item: IEvidenceLinkItem) => {
    dispatch(removeQuestionEvidence({
      auditQuestionId: question.auditQuestionId,
      filename: item.info,
    }));
  };

  const deleteEvidenceItem = async (item: IModalEvidenceItem): Promise<void> => {
    const controller = new AbortController();

    if (item.type === "Evidence" && item.filename !== undefined) {

      await AuditsApi.deleteQuestionEvidence(auditId, question.auditQuestionId, item.id, controller.signal);

    } else {

      await AuditsApi.deleteQuestionLink(item.id, controller.signal);

    }

    onItemDeleted(modalItemToEvidenceItem(item));

  };

  const saveEvidenceItem = async (item: IModalEvidenceItem, file?: File): Promise<number> => {
    if (item.id) {
      if (item.type === "Evidence") {
        await AuditsApi.updateQuestionEvidence(auditId, question.auditQuestionId, item.id, item.notes);

        onItemUpdated(modalItemToEvidenceItem(item));
        return item.id;
      } else {
        await AuditsApi.saveQuestionLink(item.id, auditId, question.auditQuestionId, item.notes, item.link!);

        onItemUpdated(modalItemToEvidenceItem(item));
        return item.id;
      }
    } else {

      if (item.type === "Evidence") {
        if (!file) {

          throw new Error("A file is required to upload evidence.");
        }

        const newEvidenceItem = await AuditsApi.uploadQuestionEvidence(auditId, question.auditQuestionId, item.notes, file);

        onItemCreated(newEvidenceItem);
        return newEvidenceItem.id;
      } else {

        const newEvidenceItem = await AuditsApi.saveQuestionLink(undefined, auditId, question.auditQuestionId, item.notes, item.link!);

        onItemCreated(newEvidenceItem);

        return newEvidenceItem.id;
      }
    }
  };


  const onLinkClicked = async (link: string) => {
    window.open(link);
  };

  const onItemSaved = (item: IModalEvidenceItem) => {
    const list = cloneDeep(loadListOp.data || []);
    const existingItem = list.find(x => x.id === item.id);

    if (existingItem) {
      // Update the existing item if it's in the list.
      Object.assign(existingItem, item);
    } else {
      // Add the new item since it's not in the list.
      list.push(item);
    }

    // Update the list in the state.
    setLoadListOp({
      ...loadListOp,
      data: list,
    });
  };

  const getActionItemEvidenceFileBlobUrl = async (item: IModalEvidenceItem): Promise<string> => {
    return await AuditsApi.getQuestionEvidenceUrl(auditId,
      question.auditQuestionId,
      item.id);
  };

  const onDownloadClicked = async (id: number) => {
    try {
      const item = loadListOp.data?.find(x => x.id === id);

      if (!item) {
        throw new Error(`Unknown id: ${id}.`);
      }

      setIsDownloading(true);
      const url = await getActionItemEvidenceFileBlobUrl?.(item);
      window.open(url);
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
    } finally {
      setIsDownloading(false);
    }
  };
  
  const evidenceStatus = (id: number) => {
    return question.evidence.find(x => x.id === id)?.auditStatus;
  };

  useEffect(() => {
    let aborted = false;
    let abortController = new AbortController();

    const getItems = async () => {
      if (aborted) {
        return;
      }

      setLoadListOp({
        isWorking: true,
      });

      try {
        const items = await getEvidenceItems(abortController.signal);

        if (aborted) {
          return;
        }

        setLoadListOp({
          isWorking: false,
          data: items,
        });
      } catch (err) {
        if (aborted) {
          return;
        }

        setLoadListOp({
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        });
      }
    };

    getItems();

    return () => {
      // Tell the async request to abort.
      aborted = true;
      abortController.abort();
    };
  }, [getEvidenceItems, setLoadListOp]);

  let displayComponent: React.ReactNode | undefined;

  if (loadListOp.isWorking) {
    // The request to the server for evidence is still active.
    displayComponent = <Spinner />;
  } else if (loadListOp.errorMessage) {
    // The request to the server for evidence has failed.
    displayComponent = (
      <Banner
        type={BannerType.error}
      >
        {loadListOp.errorMessage}
      </Banner>
    );
  } else if (!loadListOp.data
    || !loadListOp.data.length) {
    // The request to the server for evidence completed but there are no
    // evidence items available to show.
    displayComponent = (
      <Banner
        type={BannerType.info}
      >
        There are no existing evidence items.
      </Banner>
    );
  } else {
    // The request to the server for evidence completed and there are
    // evidence items available to show.
    displayComponent = (
      <Table
        className="evidence-table"
        columns={getEvidenceTableColumn(
          selectedIds,
          EvidenceListModalModes.Management,
          allowEdit,
          allowEdit,
          true,
          onSelectionChanged,
          (evidenceItem) => evidenceStatus(evidenceItem.id) === auditStatus
            && (allowEdit
              || userOwnsQuestion),
          (evidenceItem) => setConfirmDeleteId(evidenceItem),
          (evidenceItem) => onDownloadClicked(evidenceItem),
          (evidenceItem) => setManageModalData({
            editingId: evidenceItem,
            isOpen: true,
          }),
          onLinkClicked,
        )
        }
        data={loadListOp.data}
        keyProp="id"
        theme={TableThemes.compact}
        showHeader={true}
      />
    );
  }

  const displayConfirmDeleteEvidenceMessage = (): React.ReactNode => <>
    <div>
      Are you sure you want to delete this evidence item?
      {(() => {
        const item = loadListOp
          .data
          ?.find(x => x.id === confirmDeleteId);

        if (item) {
          return (
            <div>
              <br />
              <strong>{item.notes}</strong>
              <div>{item.filename}</div>
            </div>
          )
        }
      })()}
    </div>
  </>

  return (
    <div>
      <Card
        showHeader={true}
        className="evidence-card"
        cardStateId="questionEvidenceCard"
        defaultCollapsedState={false}
        headerElement={
          <FlexRow
            className="header-row"
          >
            <span className='evidence-title'>{"Evidences"}</span>

            <FlexRow
              className="right-row"
            >
              <label className="evidences">{`${question.evidence.length || 0} evidence${question.evidence.length !== 1 ? "s" : ""}`}</label>
              <button
                className={`secondary evidence-button ${!allowEdit ? 'disabled' : ''}`}
                onClick={() => setManageModalData({
                  editingId: undefined,
                  isOpen: true,
                })}
                disabled={!allowEdit}
              >
                <img
                  alt=""
                  src={attIcon}
                  className="icon-small"
                />
                UPLOAD EVIDENCE
              </button>
            </FlexRow>
          </FlexRow>}
      >
        <FlexCol>
          <div>
            {displayComponent}
          </div>
        </FlexCol>
      </Card>

      {manageModalData.isOpen && (
        <ManageEvidenceItemModal
          existingEvidenceFilenames={loadListOp.data?.map(x => x.filename!) || []}
          managedEvidence={loadListOp.data?.find(x => x.id === manageModalData.editingId)}
          onClose={() => setManageModalData({ editingId: undefined, isOpen: false })}
          saveItem={auditId ? saveEvidenceItem : async () => { return 0 }}
          onItemSaved={onItemSaved}
        />
      )}

      {confirmDeleteId !== undefined && (
        <ConfirmModal
          header="Delete Evidence"
          message={displayConfirmDeleteEvidenceMessage()}
          isWorking={isDeleting}
          onNoClicked={() => setConfirmDeleteId(undefined)}
          onYesClicked={() => deleteItem(confirmDeleteId)}
        />
      )}

      {(isDeleting
        || isDownloading) && (
          <ModalSpinner />
        )}
    </div>
  );
};


export default EvidenceCard;
