import UrlRoutes from "components/routing/UrlRoutes";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/components/controls/buttons/button/Button";
import SplitButton, { ISplitButtonItem } from "shared/components/controls/split-button/SplitButton";
import FlexRow from "shared/components/layout/flex/FlexRow";
import deleteIcon from "shared/media/dls/delete.svg";
import saveAsIcon from "shared/media/dls/save-as.svg";
import saveIcon from "shared/media/dls/save.svg";
import "./SearchPageFooter.scoped.scss";

interface ISearchPageFooter {
  id: number | undefined,
  onSaveFilters(): void,
  onClearFilters(): void,
  onApplyFilters(): void,
  onUpdateFilters(): void,
  onDeleteFilters(): void,
  isDeleteViewDisabled: boolean,
}

const SearchPageFooter: React.FC<ISearchPageFooter> = ({
  id,
  onSaveFilters,
  onClearFilters,
  onApplyFilters,
  onUpdateFilters,
  onDeleteFilters,
  isDeleteViewDisabled,
}) => {
  const navigate = useNavigate();

  const splitButtonItems: ISplitButtonItem[] = [
    {
      id: 'SearchPageFooter-Save',
      text: 'Save',
      imgUrl: saveIcon,
      imgPlacement: "right",
    },
    {
      id: 'SearchPageFooter-SaveAs',
      text: 'Save As',
      imgUrl: saveAsIcon,
      imgPlacement: "right",
    },
    {
      id: 'SearchPageFooter-Delete',
      text: 'Delete',
      imgUrl: deleteIcon,
      imgPlacement: "right",
      isDisabled: isDeleteViewDisabled,
    }];

  return (
    <FlexRow>
      <Button
        buttonType="secondary"
        onClick={() => navigate(UrlRoutes.Search.urlTemplate)}
      >
        Cancel
      </Button>

      <span className="separator"></span>

      <span>
        <SplitButton
          buttonType="secondary"
          mode="dropdown"
          display="My Searches"
          onItemClick={itemId => {
            switch (itemId) {
              case 'SearchPageFooter-Save':
                if (id !== undefined && id > 0) {
                  onUpdateFilters();
                } else {
                  onSaveFilters();
                }
                break;
              case 'SearchPageFooter-SaveAs':
                onSaveFilters();
                break;
              case 'SearchPageFooter-Delete':
                onDeleteFilters();
                break;
              default:
                break;
            }
          }}
          items={splitButtonItems}
        />
      </span>

      <Button
        buttonType="secondary"
        onClick={onClearFilters}
      >
        Clear Filters
      </Button>

      <Button
        buttonType="primary"
        onClick={onApplyFilters}
      >
        Apply Filters
      </Button>
    </FlexRow>
  );
};

export default SearchPageFooter;
