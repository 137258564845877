import AuditsApi from "api/auditing/AuditsApi";
import MasterDataApi from "api/masterdata/MasterDataApi";
import { cloneDeep } from "lodash";
import { Action } from "redux";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { handleActionItemChanged, loadScores, setQuestion } from "store/audit/AuditSlice";
import { RootState } from "store/store";
import { IAuditInfo, IAuditQuestion, IAuditQuestionResponse } from "types/auditingTypes";
import { IApi, IQMSElement } from "types/auditMasterDataTypes";
import { clearSpecificSelectedQuestions, finishLoadQmsResultsItems, finishSubmitUpdatedResponses, loadQmsResultsItems, submitUpdatedResponses } from "./AuditNonConformancesSlice";

export default function* auditNonConformancesSagas() {
  yield all([
    submitUpdatedResponsesAsync(),
    handleActionItemChangedAsync(),
    loadQmsResultsItemsAsync(),
  ]);
}

function* submitUpdatedResponsesAsync() {
  yield takeLatest(submitUpdatedResponses, function* (action: Action) {
    if (!submitUpdatedResponses.match(action)) {
      return;
    }

    try {
      const updatedResponses: IAuditQuestionResponse[] = yield call(AuditsApi.updateNonConformanceResponse, action.payload);
      yield put(finishSubmitUpdatedResponses());
      yield put(clearSpecificSelectedQuestions(action.payload.auditQuestionIds));
      yield put(showSuccessToast("Selected response(s) updated successfully!"));

      // Reload the Current scores now that one of the question responses has changed.
      yield put(loadScores({
        auditId: action.payload.auditId,
        includeByAuditStatus: true,
      }));

      // Update the question in the audit slice if it is loaded.
      const audit: IAuditInfo = yield select((store: RootState) => store.audit.audit);
      const storeQuestions: IAuditQuestion[] = yield select((store: RootState) => store.audit.questions);

      if (!storeQuestions.length
        || !audit
        || audit.id !== action.payload.auditId) {
        // Audit or questions are not loaded!
        return;
      }

      for (let i = 0; i < updatedResponses.length; i++) {
        const newResponse = updatedResponses[i];

        const question = cloneDeep(storeQuestions.find(x => x.auditQuestionId === newResponse.auditQuestionId));

        if (question) {
          const responseIx = question.responses.findIndex(x => x.auditStatus === audit.status);

          if (responseIx > -1) {
            question.responses[responseIx] = newResponse;
          } else {
            question.responses.push(newResponse);
          }

          yield put(setQuestion({
            question,
            alsoUpdateOriginal: true,
          }));
        }
      }
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishSubmitUpdatedResponses());
      yield put(clearSpecificSelectedQuestions(action.payload.auditQuestionIds));
    }
  });
}

function* handleActionItemChangedAsync() {
  yield takeEvery(handleActionItemChanged, function* (action) {
    if (!handleActionItemChanged.match(action)) {
      return;
    }

    if (action.payload.type !== "Created") {
      return;
    }

    let auditQuestionIds = action
      .payload
      .actionItem
      .links
      .filter(x => x.type === "AuditQuestion")
      .map(x => x.linkId);

    if (!auditQuestionIds.length) {
      return;
    }

    yield put(clearSpecificSelectedQuestions(auditQuestionIds.map(x => Number(x))));
  });
}

function* loadQmsResultsItemsAsync() {
  yield takeLatest(loadQmsResultsItems, function* (action) {
    if (!loadQmsResultsItems.match(action)) {
      return;
    }

    try {
      const [
        qmsElements,
        apis,
      ]: [IQMSElement[], IApi[]] = yield all([
        call(MasterDataApi.getQMSElements),
        call(MasterDataApi.getAPIs)
      ]);

      yield put(finishLoadQmsResultsItems({
        isWorking: false,
        data: {
          qmsElements,
          apis,
        },
      }));
    } catch (err) {
      yield put(finishLoadQmsResultsItems({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}