import React from "react";
import { IAuditee } from "types/auditingTypes";
import removeIcon from "shared/media/dls/remove.svg";
import { userToString } from "shared/utilities/userUtilities";

interface IAuditeeProps {
  auditee: IAuditee,
  isEnabledToEdit: boolean,
  onRemoveClick?: (email: string) => void,
}

const AuditeeRow: React.FC<IAuditeeProps> = ({
  auditee,
  isEnabledToEdit,
  onRemoveClick,
}) => {
  return (
    <tr>
      <td
        className={`name ${auditee.isLeader ? "leader" : ""}`}
      >
        {isEnabledToEdit && (
          <img
            src={removeIcon}
            alt="Remove"
            className="remove-button icon-small"
            onClick={onRemoveClick
              ? () => onRemoveClick(auditee.email)
              : undefined
            }
          />
        )}
        {userToString(auditee)}
      </td>
    </tr>
  );
};

export default AuditeeRow;