import React from "react";
import { useDispatch } from "react-redux";
import Button from "shared/components/controls/buttons/button/Button";
import { setSelectedPlanIds } from "store/audit-plan-calendar/AuditPlanCalendarSlice";
import { useAppSelector } from "store/store";

const ClearSelectionButton: React.FC = () => {
  const dispatch = useDispatch();
  const selectedPlanIds = useAppSelector(store => store.auditPlanCalendar.selectedPlanIds);

  return (
    <Button
      buttonType="secondary"
      isDisabled={!selectedPlanIds.length}
      id="clear-audit-plan-calendar-selections"
      onClick={() => dispatch(setSelectedPlanIds([]))}
    >
      Clear Selection
    </Button>
  );
};

export default ClearSelectionButton;