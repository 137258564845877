import rightArrowIcon from "shared/media/dls/arrow-right-3.svg";
import React from "react";
import "./ViewEvidenceButton.scoped.scss";
import pluralize from "shared/utilities/pluralize";
import Button from "shared/components/controls/buttons/button/Button";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";

export interface IViewEvidenceButtonProps {
  evidenceCount: number,
  isPrimary?: boolean,
  onClick(): void,
}

const ViewEvidenceButton: React.FC<IViewEvidenceButtonProps> = ({
  evidenceCount,
  isPrimary,
  onClick,
}) =>
  <LabeledControl
    label={`Closure Evidence (${evidenceCount} ${pluralize(evidenceCount, "item", "items")})`}
  >
    <Button
      buttonType={isPrimary ? "primary" : "secondary"}
      onClick={onClick}
      img={rightArrowIcon}
    >
      View &amp; Add Attachments
    </Button>
  </LabeledControl>;

export default ViewEvidenceButton;