import React from "react";
import removeIcon from "shared/media/dls/remove.svg";
import "./QITag.scoped.scss";

interface IQITagProps {
  display: React.ReactNode,
  showRemoveButton?: boolean,
  onRemoveClick?: () => void,
  removeButtonTooltip?: string,
  isDisabled?: boolean,
}

const QITag: React.FC<IQITagProps> = ({
  display,
  showRemoveButton = false,
  onRemoveClick,
  removeButtonTooltip = "Delete",
  isDisabled = false,
}: IQITagProps) => {
  return (
    <div
      className={`tag ${!isDisabled && showRemoveButton ? "has-remove-button" : ""}`}
    >
      {!isDisabled
        && showRemoveButton
        && (
          <div
            className="minus-button"
            title={removeButtonTooltip}
            onClick={onRemoveClick}
          >
            <img
              src={removeIcon}
              alt={removeButtonTooltip}
              className="remove-button icon-small"
            />
          </div>
        )}

      <div
        className="content"
      >
        {display}
      </div>
    </div>
  );
};

export default QITag;