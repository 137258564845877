import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import pickerHandlers from "shared/store/picker/pickerReducerHandlers";
import { IOperation } from "shared/types/operationTypes";
import { IPickerState } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { IActionItemPriority, IActionItemType } from "types/actionItemTypes";
import { IIdNameObject } from "types/commonTypes";

export enum ManageActionItemPickerKeys {
  users = "users",
}

export interface IManageActionItemState {
  causalFactors: IOperation<IIdNameObject[]>,
  priorities: IOperation<IActionItemPriority[]>,
  types: IOperation<IActionItemType[]>,
  pickerData: {
    users: IPickerState<IAzureADUser>,
  },
}

const initialState: IManageActionItemState = {
  causalFactors: {
    isWorking: false,
  },
  priorities: {
    isWorking: false,
  },
  types: {
    isWorking: false,
  },
  pickerData: {
    users: {
      key: ManageActionItemPickerKeys.users,
      isOpen: false,
      items: [],
      selectedItems: [],
    },
  },
};

export const manageActionItemSlice = createSlice({
  name: "manage-action-item",
  initialState,
  reducers: {
    ...pickerHandlers,
    loadCausalFactors: (state, _: PayloadAction<string | undefined>) => {
      state.causalFactors.isWorking = true;
    },
    loadPriorities: (state, _: PayloadAction<string | undefined>) => {
      state.priorities.isWorking = true;
    },
    loadTypes: (state, _: PayloadAction<string | undefined>) => {
      state.types.isWorking = true;
    },
    finishLoadingCausalFactors: (state, action: PayloadAction<IOperation<IIdNameObject[]>>) => {
      state.causalFactors = action.payload;
    },
    finishLoadingPriorities: (state, action: PayloadAction<IOperation<IActionItemPriority[]>>) => {
      state.priorities = action.payload;
    },
    finishLoadingTypes: (state, action: PayloadAction<IOperation<IActionItemType[]>>) => {
      state.types = action.payload;
    },
  },
});

export const {
  loadCausalFactors,
  loadPriorities,
  loadTypes,
  finishLoadingCausalFactors,
  finishLoadingPriorities,
  finishLoadingTypes,
  // Picker handlers.
  loadPickerItems,
  closePicker,
  openPicker,
  setPickerError,
  setPickerItems,
  setSelectedPickerItems,
} = manageActionItemSlice.actions;