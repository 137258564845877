import { IUserPlanningViewValueSaveDto } from "api/auditPlanning/types/UserPlanningViewDtos";
import { MetaDataTypes } from "types/masterDataTypes";
import { IAuditPlanningFilters } from "../reducers/planningFiltersReducers";

export function convertAppliedPlanningFiltersToSaveDtos(appliedFilters: IAuditPlanningFilters): IUserPlanningViewValueSaveDto[] {
  let filtersToSave: IUserPlanningViewValueSaveDto[] = [];

  appliedFilters.perspectives.forEach(x => {
    filtersToSave.push(convertToDto(MetaDataTypes.Perspective, x.id.toString(), false));
  });

  appliedFilters.businessTeams.forEach(x => {
    filtersToSave.push(convertToDto(MetaDataTypes.BusinessTeam, x.id.toString(), true));
  });

  appliedFilters.perspectiveXAxes.forEach(x => {
    filtersToSave.push(convertToDto(MetaDataTypes.PerspectiveXAxes, x.id.toString(), false));
  });

  appliedFilters.facilities.forEach(x => {
    filtersToSave.push(convertToDto(MetaDataTypes.Facility, x.id.toString(), true));
  });

  appliedFilters.businessViews.forEach(x => {
    filtersToSave.push(convertToDto(MetaDataTypes.BusinessView, x.id.toString(), true));
  });

  if (appliedFilters.reviewStatus) {
    filtersToSave.push(convertToDto("reviewStatus", appliedFilters.reviewStatus.toString(), false));
  }

  appliedFilters.reviewedBy.forEach(x => {
    filtersToSave.push(convertToDto("reviewedBy", JSON.stringify(x), false));
  });

  if (appliedFilters.includeDeletedAttributes) {
    filtersToSave.push(convertToDto("includeDeletedAttributes", appliedFilters.includeDeletedAttributes.toString(), false));
  }

  if (appliedFilters.planStatus) {
    filtersToSave.push(convertToDto("planStatuses", appliedFilters.planStatus, false));
  }

  if (appliedFilters.auditType) {
    filtersToSave.push(convertToDto(MetaDataTypes.AuditType, appliedFilters.auditType.id.toString(), true));
  }

  if (appliedFilters.planYear > 0) {
    filtersToSave.push(convertToDto("planYear", appliedFilters.planYear.toString(), false));
  }

  appliedFilters.leadAuditors.forEach(x => {
    filtersToSave.push(convertToDto("leadAuditors", JSON.stringify(x), false));
  });

  appliedFilters.calendarAuditTypes.forEach(x => {
    filtersToSave.push(convertToDto(MetaDataTypes.AuditType, x.id.toString(), true));
  });

  appliedFilters.auditCalendarStatuses.forEach(x => {
    filtersToSave.push(convertToDto("auditCalendarStatuses", x.toString(), false));
  });

  appliedFilters.complianceResults.forEach(x => {
    filtersToSave.push(convertToDto("complianceResults", x.toString(), false));
  });

  if (appliedFilters.unassigned) {
    filtersToSave.push(convertToDto("unassigned", appliedFilters.unassigned.toString(), false));
  }

  return filtersToSave;
}

function convertToDto(valueType: string, value: string, isMasterDataType: boolean,): IUserPlanningViewValueSaveDto {
  return {
    valueType: valueType,
    value: value,
    isMasterDataType: isMasterDataType,
  };
}