import ChecklistMappingsPage from "components/legacy/checklist-mappings/ChecklistMappingsPage";
import LegacyNodeToQoFMapping from "components/legacy/node-mapping/LegacyNodeToQoFMapping";
import React from "react";
import { Route } from "react-router-dom";
import UrlRoutes from "../UrlRoutes";

const LegacyRoutes: React.FC = () =>
  <>
    <Route
      path={UrlRoutes.LegacyNodeMapping.urlTemplate}
      element={<LegacyNodeToQoFMapping />}
    />
    <Route
      path={UrlRoutes.LegacyChecklistMappings.urlTemplate}
      element={<ChecklistMappingsPage />}
    />
  </>;

export default LegacyRoutes;