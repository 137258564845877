import { PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import { IOperation } from "shared/types/operationTypes";
import { IAnswer } from "types/auditMasterDataTypes";
import { IAuditQuestion, IEvidenceLinkItem } from "types/auditingTypes";
import { IAuditState } from "../AuditSlice";

export interface IAuditQuestionState {
  loadQuestionsOp?: IOperation<IAuditQuestion[]>,
  questions: IAuditQuestion[],
  originalQuestions: IAuditQuestion[],
  answers: IAnswer[],
  areQuestionsDirty: boolean,
  focusedGuidance?: {
    questionNumber: number,
    guidance: string,
  },
  evidenceModal: {
    isOpen: boolean,
    auditQuestionId: number | undefined,
  },
}

export const initialAuditQuestionState: IAuditQuestionState = {
  loadQuestionsOp: undefined,
  questions: [],
  originalQuestions: [],
  answers: [],
  areQuestionsDirty: false,
  focusedGuidance: undefined,
  evidenceModal: {
    isOpen: false,
    auditQuestionId: 0,
  },
}

const questionReducers = {
  /** Loads the questions for the specified audit id. */
  loadQuestions: (state: IAuditState, _: PayloadAction<number>) => {
    state.loadQuestionsOp = {
      isWorking: true,
    };
  },

  /** Sets the specified question operation info into the state. */
  finishLoadQuestions: (state: IAuditState, action: PayloadAction<IOperation<IAuditQuestion[]>>) => {
    const {
      errorMessage,
      data,
    } = action.payload;

    if (errorMessage
      || !data) {
      state.loadQuestionsOp = action.payload;
      return;
    }

    state.loadQuestionsOp = undefined;

    state.questions = data;
    state.originalQuestions = cloneDeep(data);
    state.areQuestionsDirty = false;
  },

  /** Assigns the specified email to the specified questions. */
  assignAuditorToQuestions: (state: IAuditState, action: PayloadAction<{ auditQuestionIds: number[], auditorEmail: string | undefined }>) => {
    const {
      auditQuestionIds,
      auditorEmail,
    } = action.payload;

    state
      .questions
      .filter(x => auditQuestionIds.indexOf(x.auditQuestionId) > -1
        && x.responses.length === 0)
      .forEach(x => x.auditorEmail = auditorEmail);

    computeQuestionsAreDirty(state);
  },

  /** Sets the focused guidance information. If the focused guidance should be hidden, send undefined as the payload. */
  setFocusedGuidance: (state: IAuditState, action: PayloadAction<{
    questionNumber: number,
    guidance: string,
  } | undefined>) => {
    state.focusedGuidance = action.payload;
  },

  /** Sets the question evidence modal. */
  setEvidenceModal: (state: IAuditState, action: PayloadAction<Partial<{
    isOpen: boolean,
    auditQuestionId: number,
  }>>) => {
    Object.assign(state.evidenceModal, action.payload);
  },

  /** Sets the evidence item in a question. */
  setQuestionEvidenceItem: (state: IAuditState, action: PayloadAction<{
    item: IEvidenceLinkItem,
    auditQuestionId: number,
  }>) => {
    const question = state.questions.find(x => x.auditQuestionId === action.payload.auditQuestionId);

    if (!question) {
      return;
    }

    const existingIx = question.evidence.findIndex(x => x.id === action.payload.item.id);

    if (existingIx === -1) {
      question.evidence.push(action.payload.item);
    } else {
      question.evidence.splice(existingIx, 1, action.payload.item);
    }

    // Deletion is instant so also update the original question.
    const originalQuestionIx = state.originalQuestions.findIndex(x => x.auditQuestionId === action.payload.auditQuestionId);
    if (originalQuestionIx > -1) {
      state.originalQuestions[originalQuestionIx] = cloneDeep(question);
    }
  },

  /** Removes the specified question evidence item. */
  removeQuestionEvidence: (state: IAuditState, action: PayloadAction<{
    auditQuestionId: number,
    filename: string,
  }>) => {
    const question = state.questions.find(x => x.auditQuestionId === action.payload.auditQuestionId);

    if (!question) {
      return;
    }

    question.evidence = question
      .evidence
      .filter(x => x.info !== action.payload.filename);

    // Deletion is instant so also update the original question.
    const origQuestion = state.originalQuestions.find(x => x.auditQuestionId === action.payload.auditQuestionId);

    if (origQuestion) {
      origQuestion.evidence = cloneDeep(question.evidence);
    }
  },

  /** Updates a specified audit question. If an internal object or array is updated, send an entire clone in the place of its parent or object. */
  setQuestion: (state: IAuditState, action: PayloadAction<{
    question: Partial<IAuditQuestion>,
    alsoUpdateOriginal: boolean,
  }>) => {
    const auditQuestionId = action.payload.question.auditQuestionId;
    if (!auditQuestionId) {
      return;
    }

    const ix = state.questions.findIndex(x => x.auditQuestionId === auditQuestionId);
    if (ix > -1) {
      Object.assign(state.questions[ix], action.payload.question);
    }

    if (action.payload.alsoUpdateOriginal) {
      const ix = state.originalQuestions.findIndex(x => x.auditQuestionId === auditQuestionId);
      if (ix > -1) {
        Object.assign(state.originalQuestions[ix], action.payload.question);
      }
    }

    computeQuestionsAreDirty(state);
  }
};

export default questionReducers;

function computeQuestionsAreDirty(state: IAuditState) {
  state.areQuestionsDirty = !isEqual(state.questions, state.originalQuestions);
  return state.areQuestionsDirty;
}