import { IRenderCellArgs } from "components/audits/planning/planning-grid/PlanningGrid";
import React from "react";
import { useDispatch } from "react-redux";
import TooltipElement from "shared/components/common/tooltip/TooltipElement";
import addIcon from "shared/media/dls/add.svg";
import editIcon from "shared/media/dls/edit-1.svg";
import checkIcon from "shared/media/icons/audit-planning/final-plan-approved-plan-missing.svg";
import { setCenterAuditAttributeModal, setConfirmRemoveOrEditAttributeModal, setCreateAttributeModal } from "store/facility-attributes/FacilityAttributesSlice";
import { useAppSelector } from "store/store";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { isAttributeReviewed } from "../../attributesUtils";
import { IPlanProfile, IPlanProfileAttribute } from "../../facilityAttributeTypes";
import "./FacilityAttributesGridCell.scoped.scss";
import AttributeTooltipContents from "./tooltip-contents/AttributeTooltipContents";

interface IFacilityAttributesGridCellProps {
  cellArgs: IRenderCellArgs,
  profile?: IPlanProfile,
  attribute?: IPlanProfileAttribute,
  isEnabled: boolean,
  subGeoUnitId: number,
}

const FacilityAttributesGridCell: React.FC<IFacilityAttributesGridCellProps> = ({
  cellArgs,
  profile,
  attribute,
  isEnabled,
  subGeoUnitId,
}: IFacilityAttributesGridCellProps) => {
  const ownerGroup = useAppSelector(store => store.facilityAttributes.ownerGroup);

  const dispatch = useDispatch();
  const icon = attribute
    && isAttributeReviewed(attribute)
    ? (
      <img
        src={checkIcon}
        alt="Reviewed"
        className="icon-medium reviewed-icon"
      />
    ) : undefined;


  const editOrRemoveModalButton = (
    attribute
      && isEnabled
      ? (
        <img
          src={editIcon}
          alt="Edit"
          className="icon-medium edit-icon"
        />
      ) : undefined
  );

  const addButton = (
    (!attribute
      || attribute.deleted)
      && isEnabled
      ? (
        <img
          src={addIcon}
          alt="Add"
          className="icon-medium add-icon"
        />
      ) : undefined
  );

  const onCellClick = () => {
    switch (ownerGroup.scoringSystem) {
      case AuditScoringSystems.QMS:
      case AuditScoringSystems.HSE:
        if (!attribute
          || attribute.deleted) {
          dispatch(setCreateAttributeModal({
            isOpen: true,
            requirement: cellArgs.requirement,
            parentRow: cellArgs.parentRow,
            row: cellArgs.row,
            profileId: profile?.id,
            subGeoUnitId,
          }));
        } else {
          dispatch(setConfirmRemoveOrEditAttributeModal({
            isOpen: true,
            requirement: cellArgs.requirement,
            row: cellArgs.row,
            attribute: attribute,
            onEditCLMQMSClick: undefined,
            scoringSystem: ownerGroup.scoringSystem,
          }));
        }
        break;

      case AuditScoringSystems.CLM:
        if (!attribute
          || attribute.deleted) {
          dispatch(setCenterAuditAttributeModal({
            isOpen: true,
            showModal: false,
            profileId: profile?.id,
            profileStandardId: attribute?.id,
            requirement: cellArgs.requirement,
            parentRow: cellArgs.parentRow,
            row: cellArgs.row,
            subGeoUnitId,
          }));
        } else {
          dispatch(setConfirmRemoveOrEditAttributeModal({
            isOpen: true,
            requirement: cellArgs.requirement,
            row: cellArgs.row,
            attribute: attribute,
            onEditCLMQMSClick: {
              isOpen: true,
              showModal: false,
              profileId: profile?.id,
              profileStandardId: attribute?.id,
              requirement: cellArgs.requirement,
              parentRow: cellArgs.parentRow,
              row: cellArgs.row,
              subGeoUnitId,
            },
            scoringSystem: ownerGroup.scoringSystem,
          }));
        }
        break;

      default:
        break;
    }
  };

  let divClassNames = [
    "cell-div",
    isEnabled ? "clickable" : "",
    attribute ? "has-attribute" : "",
    "is-plannable",
    addButton ? "addable" : "",
    !attribute?.deleted && editOrRemoveModalButton
      ? "removable"
      : "",
    attribute?.deleted ? "deleted" : "",
  ].filter(x => x).join(' ');

  if (!cellArgs.row.isPlannable) {
    return null;
  }

  return (
    <TooltipElement
      isDisabled={!attribute && !isEnabled}
      tooltipContent={
        <>
          {!attribute
            ? <span>Click to add new requirement.</span>
            : (
              <AttributeTooltipContents
                attribute={attribute}
                canRemoveAttribute={isEnabled}
                canAddAttribute={!!addButton}
              />
            )}
        </>
      }
    >
      <div
        className={divClassNames}
        onClick={isEnabled
          ? onCellClick
          : undefined
        }
      >
        {icon}

        {editOrRemoveModalButton}

        {addButton}
      </div>
    </TooltipElement>
  );
};

export default FacilityAttributesGridCell;