import AuditsApi from "api/auditing/AuditsApi";
import { Action } from "redux";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { setAudit } from "store/audit/AuditSlice";
import { RootState } from "store/store";
import { IAuditEvidenceItem, IAuditInfo, IAuditReport } from "types/auditingTypes";
import { IAuditReportState, ITopicCommentModalData, closedCommentsModal, finishGenerateAuditReport, finishLoadingReportData, finishSaveAuditComments, finishSaveTopicComments, finishSendAuditNotifications, generateAuditReport, loadReportData, saveAuditComments, saveTopicComments, sendAuditNotifications, toggleNotifyTeamModal } from "./AuditReportSlice";
import { resetState } from './attachment-modal/AttachmentModalSlice';

export default function* auditReportSaga() {
  yield all([
    loadReportDataAsync(),
    saveAuditCommentAsync(),
    saveTopicCommentAsync(),
    generateAuditReportAsync(),
    sendAuditNotificationsAsync(),
  ]);
}

function* loadReportDataAsync() {
  yield takeLatest(loadReportData, function* (action: Action) {
    if (!loadReportData.match(action)) {
      return;
    }

    try {
      yield put(resetState())

      let data: IAuditReport = yield call(AuditsApi.getReport, action.payload);
      
      yield put(finishLoadingReportData({
        isWorking: false,
        data,
      }));
    } catch (err) {
      yield put(finishLoadingReportData({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }

  });
}

function* saveAuditCommentAsync() {
  yield takeEvery(saveAuditComments, function* (action: Action) {
    if (!saveAuditComments.match(action)) {
      return;
    }

    try {

      const report: IAuditReport | undefined = yield select((store: RootState) => store.auditReport.report);
      if (!report) {
        throw new Error("No report is loaded.");
      }
      if (!report.auditComment) {
        throw new Error("No comments to be saved.");
      }
      yield call(AuditsApi.saveAuditComment,
        {
          auditId: report.id,
          comments: report.auditComment,
        });

      yield put(showSuccessToast("Audit comments saved successfully."));

      yield put(finishSaveAuditComments());

    } catch (err) {
      let errorMessage = `Failed to save audit comments changes: ${getResponseErrorMessage(err)}`;
      yield put(showErrorToast(errorMessage));

      yield put(finishSaveAuditComments());
    }
  });
}

function* saveTopicCommentAsync() {
  yield takeEvery(saveTopicComments, function* (action: Action) {
    if (!saveTopicComments.match(action)) {
      return;
    }
    try {
      const auditdata: IAuditReportState = yield select((store: RootState) => store.auditReport);
      const auditId: number = auditdata.report?.id ?? 0;
      const topicComments: ITopicCommentModalData = auditdata.topicCommentModal;
      yield call(AuditsApi.saveTopicComment, auditId, topicComments);

      yield put(showSuccessToast("Topic comments saved successfully."));

      yield put(finishSaveTopicComments({
        isWorking: false,
      }));
      yield put(closedCommentsModal(false));

    } catch (err) {
      let errorMessage = `Failed to save topic comments changes: ${getResponseErrorMessage(err)}`;
      yield put(showErrorToast(errorMessage));

      yield put(finishSaveTopicComments({
        isWorking: false,
        errorMessage: errorMessage,
      }));
    }
  });
}

function* generateAuditReportAsync() {
  yield takeLatest(generateAuditReport, function* (action: Action) {
    if (!generateAuditReport.match(action)) {
      return;
    }

    try {
      let response: {
        evidenceUrl: string,
        createdEvidence: IAuditEvidenceItem,
      } = yield call(AuditsApi.generateAuditReport,
        action.payload.auditId,
        action.payload.reportData);

      // After generating the report, add the created evidence item immediately to the audit's
      // list of evidence item. This is requires to that the Audit Steps display can be updated
      // immediately.
      const audit: IAuditInfo | undefined = yield select((store: RootState) => store.audit.audit);

      if (audit) {
        yield put(setAudit({
          audit: {
            evidence: audit.evidence.concat(response.createdEvidence),
          },
          alsoSetOriginal: true,
        }));
      }

      const anchorDownload = document.createElement("a");
      anchorDownload.href = response.evidenceUrl;
      anchorDownload.click();
      yield put(showSuccessToast("Audit Report generated successfully."));
    } catch (err) {
      yield put(showErrorToast(`Failed to generate Audit Report: ${getResponseErrorMessage(err)}`));
    } finally {
      yield put(finishGenerateAuditReport());
    }
  });
}

function* sendAuditNotificationsAsync() {
  yield takeLatest(sendAuditNotifications, function* (action) {
    try {
      yield call(AuditsApi.notifyTeam,
        action.payload.auditId,
        action.payload.recipients);

      yield put(toggleNotifyTeamModal(false));

      yield put(showSuccessToast("Audit notifications sent successfully."));
    } catch (err) {
      yield put(showErrorToast(`Failed to send audit notifications: ${getResponseErrorMessage(err)}`));
    } finally {
      yield put(finishSendAuditNotifications());
    }
  });
}