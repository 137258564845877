import React, { useCallback, useEffect, useState } from "react";
import arrowIcon from "shared/media/dls/arrow-down-2.svg";
import { IOperation } from "shared/types/operationTypes";
import { IPickerItem } from "shared/types/pickerTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { IHierarchyItem, getHierarchyItemAncestorPath } from "shared/utilities/hierarchyUtilities";
import useAsyncEffect from "shared/utilities/hooks/useAsyncEffect";
import { convertToPickerItem, fromPickerItem } from "shared/utilities/pickerUtiilities";
import { INonReduxPickerProps as OldPickerProps } from "../../picker/Picker";
import PickerModal from "../../picker/modal/PickerModal";
import "./NonReduxPicker.scoped.scss";

type INonReduxPickerProps<T> = OldPickerProps<T> & {
  /**
   * Optional. If specified, the picker will render itself using this function. The returned React.ReactNode should
   * call the provided `openPicker` function when clicked to open the picker.
   */
  onRenderPicker?: (openPicker: () => void) => React.ReactNode,
  isDisabled?: boolean,
  className?: string,
  tooltip?: string,
  selectedItems: T[],
  maxSelectedItemsVisible?: number,
  renderSelectedItem: (item: T) => string,
  placeholder?: string,
  onApply: (selectedItems: T[]) => void,
  keyMapper: (item: T) => string | number,
  childrenMapper?: (parentItem: T, allItems: T[]) => T[],
  itemSorter?: (item1: T, item2: T) => number,
  searchOptions?: {
    /**
      * Receives one of the picker items and a search string and must return whether or not it should be visible.
      * If this is not specified, the picker will attempt to filter by the item's text property.
      */
    filterItem?: (a: T, filterValue: string) => boolean,
  },
  onLoadItems: (searchValue: string | undefined, abortSignal: AbortSignal) => Promise<T[]>,
  onLoadSuggestedItems?: (abortSignal: AbortSignal) => Promise<T[]>,
  /** Optional. If specified, each item will call this function to determine if it should be disabled or not. */
  isDisabledMapper?: (item: T, ancestorPath: T[], isSelectedItem?: boolean) => boolean,
};

const NonReduxPicker = <T,>(props: INonReduxPickerProps<T>) => {
  const {
    onRenderPicker,
    isDisabled,
    className,
    tooltip,
    selectedItems,
    maxSelectedItemsVisible,
    renderSelectedItem,
    placeholder,
    onApply,
    keyMapper,
    childrenMapper,
    onLoadItems,
    onLoadSuggestedItems,
    showSuggestedItems,
    isDisabledMapper,
  } = props;

  const [expandedKeys, setExpandedKeys] = useState<(string | number)[]>([]);
  const [loadOperation, setLoadOperation] = useState<IOperation<T> | undefined>(undefined);
  const [loadSuggestionsOperation, setLoadSuggestionsOperation] = useState<IOperation<T> | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [availableItems, setAvailableItems] = useState<T[]>([]);
  const [suggestedItems, setSuggestedItems] = useState<T[]>([]);
  const [localSelectedItems, setLocalSelectedItems] = useState<T[]>(selectedItems);
  const [searchValue, setSearchValue] = useState("");

  const searchBehavior = props.searchOptions?.behavior;
  const asyncSearchMinChars = props.searchOptions?.asyncMinChars;
  const asyncSearchDelay = props.searchOptions?.asyncSearchDelay;

  const onModalOpen = useCallback(() => setSearchValue(""), [setSearchValue]);

  useEffect(() => {
    setLocalSelectedItems(selectedItems);
  }, [selectedItems, isOpen]);

  useAsyncEffect(async (aborted, abortSignal) => {
    if (!showSuggestedItems
      || !isOpen
      || !onLoadSuggestedItems) {
      return;
    }

    try {
      setLoadSuggestionsOperation({
        isWorking: true,
      });

      const items = await onLoadSuggestedItems(abortSignal);
      if (aborted) {
        return;
      }

      setSuggestedItems(items);
      setLoadSuggestionsOperation(undefined);
    } catch (err) {
      if (aborted) {
        return;
      }

      setLoadSuggestionsOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      });
    }
  }, [isOpen, setLoadSuggestionsOperation, setSuggestedItems, showSuggestedItems]);

  useAsyncEffect(async (aborted, abortSignal) => {
    if (!isOpen
      || searchBehavior === "async") {
      return;
    }

    try {
      setLoadOperation({
        isWorking: true,
      });
      const items = await onLoadItems(searchValue, abortSignal);
      if (aborted) {
        return;
      }

      setAvailableItems(items);
      setLoadOperation(undefined);
    } catch (err) {
      if (aborted) {
        return;
      }

      const errMsg = getResponseErrorMessage(err);

      if (errMsg.trim() === "The user aborted a request.") {
        return;
      }

      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      });
    }
  }, [isOpen, searchBehavior, searchValue, setLoadOperation, setAvailableItems]);

  useAsyncEffect(async (aborted, abortSignal) => {
    if (!isOpen
      || searchBehavior !== "async"
      || !asyncSearchMinChars
      || searchValue.trim().length < asyncSearchMinChars) {
      return;
    }

    // Wait for a small delay.
    await new Promise(resolve => setTimeout(resolve, asyncSearchDelay ?? 1000));

    if (abortSignal.aborted) {
      return;
    }

    try {
      setLoadOperation({
        isWorking: true,
      });

      const items = await onLoadItems(searchValue, abortSignal);

      if (abortSignal.aborted) {
        return;
      }

      setAvailableItems(items);
      setLoadOperation(undefined);
    } catch (err) {
      if (abortSignal.aborted) {
        return;
      }

      const errMsg = getResponseErrorMessage(err);

      if (errMsg.trim() === "The operation was aborted.") {
        return;
      }

      setLoadOperation({
        isWorking: false,
        errorMessage: errMsg,
      });
    }
  }, [isOpen, searchValue, searchBehavior, asyncSearchMinChars, asyncSearchDelay, setLoadOperation, setAvailableItems]);

  const renderSelectedItems = () => {
    if (maxSelectedItemsVisible !== undefined
      && selectedItems.length > maxSelectedItemsVisible) {
      return `${selectedItems.length} selected item${selectedItems.length !== 1
        ? "s"
        : ""}`;
    }

    return selectedItems
      .map(renderSelectedItem)
      .sort((a, b) => a < b
        ? -1
        : 1)
      .join('; ');
  };

  const toPickerItem = (item: T, includeChildren?: boolean, allItems?: T[]): IPickerItem<T> => {
    return convertToPickerItem(item, expandedKeys, keyMapper, childrenMapper, includeChildren, allItems);
  };

  const onClose = () => setIsOpen(false);

  const mapLocalSelectedItemsToPickerItems = (): IPickerItem<T>[] => {
    const selectedPickerItems = localSelectedItems.map(item => {
      const pickerItem = toPickerItem(item);

      if (item
        && typeof item === 'object'
        && 'children' in item
        && 'id' in item
        && props.displayMode === 'tree') {
        pickerItem.ancestorPath = getHierarchyItemAncestorPath((item as unknown as IHierarchyItem).id as (number | string),
          availableItems as unknown as IHierarchyItem[]) as unknown as T[];
      }

      return pickerItem;
    });

    return selectedPickerItems;
  };

  return (
    <>
      {onRenderPicker !== undefined
        ? onRenderPicker(() => setIsOpen(true))
        : (
          <div
            className={`input picker ${isDisabled
              ? "disabled"
              : ""
              } ${className === undefined
                ? ""
                : className
              }`}
            title={tooltip}
            onClick={isDisabled
              ? undefined
              : () => setIsOpen(true)
            }
          >
            <div
              className={`labels ${!selectedItems.length
                ? "placeholder"
                : ""}`
              }
            >
              {selectedItems.length
                ? renderSelectedItems()
                : (placeholder || "Select")
              }
            </div>
            <img
              src={arrowIcon}
              alt=""
              className="icon-small"
            />
          </div>
        )}

      {isOpen && (
        <PickerModal
          localSelectedItems={mapLocalSelectedItemsToPickerItems()}
          setLocalSelectedItems={items => setLocalSelectedItems(items.map(fromPickerItem))}
          pickerProps={props}
          searchValue={searchValue}
          onOpen={onModalOpen}
          setSearchValue={setSearchValue}
          availableItems={availableItems.map(x => toPickerItem(x, true, availableItems))}
          itemSorter={props.itemSorter
            ? (a, b) => props.itemSorter!(fromPickerItem(a), fromPickerItem(b))
            : undefined
          }
          suggestedItems={suggestedItems.map(x => toPickerItem(x))}
          filterItem={props.searchOptions?.filterItem
            ? (item, searchValue) => { return props.searchOptions?.filterItem?.(fromPickerItem(item), searchValue) || false; }
            : undefined
          }
          isLoading={loadOperation?.isWorking}
          loadError={loadOperation?.errorMessage}
          isLoadingSuggestions={loadSuggestionsOperation?.isWorking}
          loadSuggestionsError={loadSuggestionsOperation?.errorMessage}
          onClose={onClose}
          onCancel={() => {
            setLocalSelectedItems([...selectedItems]);
            onClose();
          }}
          onApply={(selItems: IPickerItem<T>[]) => {
            onApply(selItems.map(fromPickerItem));
            onClose();
          }}
          onItemExpanded={(item: IPickerItem<T>, _?: (string | number)[]) => {
            setExpandedKeys(expandedKeys.concat(item.key));
          }}
          onItemCollapsed={(item: IPickerItem<T>, _?: (string | number)[]) => {
            setExpandedKeys(expandedKeys.filter(x => x !== item.key));
          }}
          isDisabledMapper={isDisabledMapper}
        />
      )}
    </>
  );
};

export default NonReduxPicker;