import { IUserPlanningViewValue } from "types/audit-planning-shared/UserPlanningViews";

export function formatDetailsPlanningViewResponse(obj: any): IUserPlanningViewValue[] {
  return Array.isArray(obj)
    ? obj.map(formatUserPlanningViewValue)
    : [];
}

export function formatUserPlanningViewValue(obj: any): IUserPlanningViewValue {
  return {
    id: Number(obj.id),
    userPlanningViewId: Number(obj.savedPlanningViewId),
    valueType: String(obj.valueType),
    value: String(obj.value),
    isMasterDataType: Boolean(obj.isMasterDataType),
    referencedObject: obj.referencedObject,
  };
}