import React from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Spinner from "shared/components/common/spinner/Spinner";
import Card from "shared/components/layout/card/Card";
import { IOperation } from "shared/types/operationTypes";
import { useAppSelector } from "store/store";
import { IAnswer } from "types/auditMasterDataTypes";
import { AuditScoringSystems } from "types/auditPlanningTypes";
import { AuditStatuses, IAuditQuestion } from "types/auditingTypes";
import SummaryBlock, { ISummaryBlockItem } from "../../common/SummaryBlock";
import { IComplianceScore, calcComplianceScore } from "../auditScoreCalculator";
import { getResponseFromQuestion, isQuestionMissingAnswer } from "../auditUtilities";
import ComplianceResult from "../compliance-result/ComplianceResult";
import "./AuditOverview.scoped.scss";

interface IAuditOverviewProps {
  /** Determines if the compliance score info should be shown. */
  showComplianceScore?: boolean,
  /** Determines if the question completion info should be shown. */
  showQuestionCompletionPercent?: boolean,
  /** Determines if the question counts should be shown. */
  showQuestionCounts?: boolean,
  /** The list of audit questions from which to generate the overview. */
  questions: IAuditQuestion[],
  /** The audit's current status. */
  auditStatus: AuditStatuses,
  /** An operation representing the data for this card being loaded. */
  loadingOp?: IOperation<any>,
}

const AuditOverview: React.FC<IAuditOverviewProps> = ({
  showComplianceScore = true,
  showQuestionCompletionPercent = true,
  showQuestionCounts = true,
  questions,
  auditStatus,
  loadingOp,
}) => {
  const auditScoringSystem = useAppSelector(store => store.audit.questions?.[0]?.scoringSystem);
  const allAnswers = useAppSelector(store => store.audit.answers);
  const availableAnswers = allAnswers.filter(x => x.scoringSystem === auditScoringSystem);

  if (!showComplianceScore
    && !showQuestionCompletionPercent
    && !showQuestionCounts) {
    return null;
  }

  return (
    <Card
      className="overview-card"
    >
      <div className="audit-overview">
        <h4>Audit Overview</h4>

        <div className="blocks">
          {loadingOp?.isWorking
            ? <Spinner className="icon-medium" />
            : loadingOp?.errorMessage
              ? (
                <Banner
                  type={BannerType.error}
                >
                  {loadingOp.errorMessage}
                </Banner>
              ) : (() => {
                const questionCountsBlock = showQuestionCounts
                  && getQuestionCountsBlock(questions, auditStatus, availableAnswers);

                const questionCompletionBlock = showQuestionCompletionPercent
                  && getQuestionCompletionBlock(questions, auditStatus);

                const complianceScoreBlock = showComplianceScore
                  && getComplianceBlocks(questions, auditStatus, auditScoringSystem as AuditScoringSystems);

                return (
                  <>
                    {questionCountsBlock}
                    {questionCompletionBlock}
                    {complianceScoreBlock}
                  </>
                );
              })()}
        </div>
      </div>
    </Card>
  );
};

export default AuditOverview;

function filterByAnswer(question: IAuditQuestion, answer: string, auditStatus: AuditStatuses) {
  return getResponseFromQuestion(question, auditStatus)?.answer === answer;
}

function getQuestionCountsBlock(questions: IAuditQuestion[], auditStatus: AuditStatuses, availableAnswers: IAnswer[]) {
  const answerGroups = availableAnswers.map(ans => ({
    answer: ans,
    count: questions.filter(x => filterByAnswer(x, ans.code, auditStatus)).length,
  }));

  let unansweredCount = questions.length;
  answerGroups.forEach(x => unansweredCount -= x.count);

  const summaryBlockItems: ISummaryBlockItem[] = [{
    key: "total",
    label: "Total Quest.",
    node: questions.length,
  }];

  answerGroups.forEach(x => summaryBlockItems.push({
    key: x.answer.key,
    label: x.answer.code,
    tooltip: x.answer.name,
    node: x.count,
  }));

  summaryBlockItems.push({
    key: "unanswered",
    label: "Unanswered",
    node: unansweredCount,
  });

  return (
    <SummaryBlock
      className="question-counts"
      items={summaryBlockItems}
    />
  );
}

function getQuestionCompletionBlock(questions: IAuditQuestion[], auditStatus: AuditStatuses) {
  const answeredCount = questions
    .filter(x => !isQuestionMissingAnswer(x, auditStatus)).length;

  return (
    <SummaryBlock
      className="question-completion"
      items={[
        {
          key: "completionPercent",
          label: "Question Completion %",
          node: questions.length === 0
            ? "-"
            : Math.floor(answeredCount / questions.length * 100).toString() + "%",
        },
      ]}
    />
  );
}

function getComplianceBlocks(questions: IAuditQuestion[],
  auditStatus: AuditStatuses,
  auditScoringSystem: AuditScoringSystems) {
  let compliance: IComplianceScore | undefined = undefined;
  const unansweredQuestions = questions.filter(x => isQuestionMissingAnswer(x, auditStatus));

  if (!unansweredQuestions.length) {
    compliance = calcComplianceScore(questions
      .map(x => ({
        category: x.category,
        answer: getResponseFromQuestion(x, auditStatus)?.answer,
        isLto: x.licenseToOperate,
      })), auditScoringSystem);
  }

  return (
    <>
      <SummaryBlock
        className="compliance-score"
        items={[{
          key: "compScore",
          label: "Compliance Score",
          node: compliance?.overallPercentage === undefined
            ? "-"
            : `${Math.floor(compliance.overallPercentage * 100)}%`,
        },
        ]}
      />

      {auditScoringSystem === AuditScoringSystems.CLM &&
        <>
          <SummaryBlock
            className="compliance-score"
            items={[{
              key: "cat1Score",
              label: "CAT 1 Score",
              node: compliance?.cat1Percentage === undefined
                ? "-"
                : `${Math.floor(compliance.cat1Percentage * 100)}%`,
            },
            ]}
          />

          <SummaryBlock
            className="compliance-score"
            items={[{
              key: "cat2Score",
              label: "CAT 2 Score",
              node: compliance?.cat2Percentage === undefined
                ? "-"
                : `${Math.floor(compliance.cat2Percentage * 100)}%`,
            },
            ]}
          />
        </>
      }

      <SummaryBlock
        className="compliance"
        items={[{
          key: "comp",
          label: "Compliance",
          node: compliance?.overallCompliance
            ? (
              <ComplianceResult
                complianceResult={compliance.overallCompliance}
              />
            ) : "-"
        }]}
      />
    </>
  );
}