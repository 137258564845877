import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import Button from "shared/components/controls/buttons/button/Button";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditTopicPicker from "shared/components/controls/pickers/AuditTopicPicker";
import AzureUserPicker from "shared/components/controls/pickers/AzureUserPicker";
import BusinessFunctionPicker from "shared/components/controls/pickers/BusinessFunctionPicker";
import BusinessTeamPicker from "shared/components/controls/pickers/BusinessTeamPicker";
import BusinessViewPicker from "shared/components/controls/pickers/BusinessViewPicker";
import CountryPicker from "shared/components/controls/pickers/CountryPicker";
import OwnerGroupPicker from "shared/components/controls/pickers/OwnerGroupPicker";
import QISelect from "shared/components/controls/select/QISelect";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import { IProfileMetaData } from "shared/types/userProfileTypes";
import { isNotUndefined } from "shared/utilities/typeCheck";
import { userToString } from "shared/utilities/userUtilities";
import { useAppSelector } from "store/store";
import { addMetaData, removeMetaData, setProfileManagerValue, setValue, startDeleteProfile, startSaveProfile, toggleDeleteConfirmation } from "store/user-management/UserManagementSlice";
import { Roles } from "types/authTypes";
import { MetaDataTypes } from "types/masterDataTypes";
import "./ProfileManagerModal.scoped.scss";
import ProfileMetaRow from "./meta-row/ProfileMetaRow";

const availableMetaTypes = [
  "Business Team (Basin Hierarchy)",
  "Business View (Divisions)",
  "Business Function (Function)",
  "Owner Groups",
  "Topics/SubTopics",
  "Countries",
];

/** A modal that handles all user interaction for creating/editing/deleting a user profile. */
const ProfileManagerModal: React.FC = () => {
  const dispatch = useDispatch();
  const isDeleteConfirmationOpen = useAppSelector(store => store.userManagement.isDeleteConfirmationOpen);
  const managerValues = useAppSelector(store => store.userManagement.managerValues);
  const [selMetaType, setSelMetaType] = useState<string>(availableMetaTypes[0]);

  const isNewProfile = !managerValues.profileId;

  const modalButtons = [
    managerValues.profileId
      ? {
        className: "red",
        key: "Delete",
        text: "Delete",
        onClick: () => dispatch(toggleDeleteConfirmation(true)),
      } : undefined,
    {
      className: "secondary",
      key: "Cancel",
      text: "Cancel",
      onClick: () => dispatch(setValue({ isManagerOpen: false, })),
    }, {
      className: "primary",
      key: "Save",
      text: "Save",
      onClick: () => dispatch(startSaveProfile()),
    }].filter(isNotUndefined);

  const renderBusinessTeamButton = (openPicker: () => void, text: string) => {
    return (<Button
      buttonType="primary"
      onClick={openPicker}
    >
      + {text}
    </Button>
    );
  };

  return (
    <Modal
      isOpen={true}
      header={managerValues.profileId
        ? "Update Profile"
        : "New Profile"
      }
      buttons={modalButtons}
    >
      <FlexCol
        className="manager-column"
      >
        {isNewProfile && (
          <LabeledControl
            label="User"
          >
            <AzureUserPicker
              onApply={items => dispatch(setProfileManagerValue({ user: items?.[0] }))}
              selectedItems={managerValues.user
                ? [managerValues.user]
                : []
              }
            />
          </LabeledControl>
        )}

        {!isNewProfile
          && managerValues.user && (
            <>
              <LabeledControl
                label="User"
              >
                {userToString(managerValues.user)}
              </LabeledControl>
            </>
          )}

        <LabeledControl
          label="Module"
        >
          <input
            type="text"
            readOnly={true}
            disabled={true}
            value={managerValues.module}
          />
        </LabeledControl>

        <LabeledControl
          label="Role"
        >
          <QISelect
            selectMode="single"
            items={Object
              .entries(Roles)
              .filter(x => x[1] !== "DefaultUser").map(x => ({
                id: x[1],
                text: x[0],
              }))}
            selectedItemIds={managerValues.role
              ? [managerValues.role.toString()]
              : []
            }
            onChange={selectedItems => dispatch(setProfileManagerValue({
              role: selectedItems[0]?.id.toString() as Roles,
            }))
            }
            allowClearButton={false}
          />
        </LabeledControl>

        <Banner
          type={BannerType.info}
        >
          Use the following fields to assign restrictions. If none are specified, the user will be unrestricted
          and able to edit data located anywhere.
          <br />
          &nbsp;&nbsp;For example, if the user is assigned one BASIN, they will lose edit permission for all other
          basins and be restricted to only the assigned BASIN. This holds true for each grouping of meta
          data.
        </Banner>

        <LabeledControl
          label="Add Profile Metadata"
        >
          <FlexRow>
            <QISelect
              selectMode="single"
              items={availableMetaTypes.map(x => ({
                id: x,
                text: x,
              }))}
              selectedItemIds={[selMetaType]}
              onChange={selectedItems => setSelMetaType(selectedItems.length
                ? selectedItems[0].id.toString()
                : selMetaType)
              }
              allowClearButton={false}
            />

            {selMetaType === "Business Team (Basin Hierarchy)" && (
              <BusinessTeamPicker
                onApply={items => dispatch(addMetaData(items.map(x => ({
                  description: x.name,
                  masterDataName: x.name,
                  masterDataCode: x.code,
                  masterDataId: x.id,
                  type: MetaDataTypes.BusinessTeam,
                }))))}
                selectedItems={[]}
                includeHidden={true}
                allowMultiselect
                onRenderPicker={openPicker => renderBusinessTeamButton(openPicker, selMetaType)}
              />
            )}

            {selMetaType === "Business View (Divisions)" && (
              <BusinessViewPicker
                onApply={items => dispatch(addMetaData(items.map(x => ({
                  description: x.name,
                  masterDataName: x.name,
                  masterDataCode: x.code,
                  masterDataId: x.id,
                  type: MetaDataTypes.BusinessView,
                }))))}
                selectedItems={[]}
                allowMultiselect
                onRenderPicker={openPicker => renderBusinessTeamButton(openPicker, selMetaType)}
              />
            )}

            {selMetaType === "Business Function (Function)" && (
              <BusinessFunctionPicker
                onApply={items => dispatch(addMetaData(items.map(x => ({
                  description: x.name,
                  masterDataName: x.name,
                  masterDataCode: x.code,
                  masterDataId: x.id,
                  type: MetaDataTypes.BusinessFunction,
                }))))}
                selectedItems={[]}
                allowMultiselect
                onRenderPicker={openPicker => renderBusinessTeamButton(openPicker, selMetaType)}
              />
            )}

            {selMetaType === "Owner Groups" && (
              <OwnerGroupPicker
                onApply={items => dispatch(addMetaData(items.map(x => ({
                  description: x.name,
                  masterDataName: x.name,
                  masterDataCode: "",
                  masterDataId: x.id,
                  type: MetaDataTypes.OwnerGroup,
                }))))}
                selectedItems={[]}
                allowMultiselect
                onRenderPicker={openPicker => renderBusinessTeamButton(openPicker, "Owner Group")}
              />
            )}

            {selMetaType === "Topics/SubTopics" && (
              <AuditTopicPicker
                onApply={items => dispatch(addMetaData(items.map(x => ({
                  description: x.name,
                  masterDataName: x.name,
                  masterDataCode: "",
                  masterDataId: x.id,
                  type: MetaDataTypes.AuditTopic,
                }))))}
                selectedItems={[]}
                allowMultiselect
                onRenderPicker={openPicker => renderBusinessTeamButton(openPicker, selMetaType)}
              />
            )}

            {selMetaType === "Countries" && (
              <CountryPicker
                onApply={items => dispatch(addMetaData(items.map(x => ({
                  description: x.name,
                  masterDataName: x.name,
                  masterDataCode: "",
                  masterDataId: x.id,
                  type: MetaDataTypes.Country,
                }))))}
                selectedItems={[]}
                allowMultiselect
                onRenderPicker={openPicker => renderBusinessTeamButton(openPicker, selMetaType)}
              />
            )}
          </FlexRow>
        </LabeledControl>

        <LabeledControl
          label="Profile Metadata"
        >
          <FlexCol
            className="profile-meta-list"
          >
            {!!managerValues.metaData?.length
              ? (
                <>
                  {managerValues
                    .metaData
                    .slice()
                    .sort(sortProfileMeta)
                    .map(m => (
                      <ProfileMetaRow
                        metaItem={m}
                        onRemove={m => dispatch(removeMetaData({
                          id: m.masterDataId,
                          type: m.type,
                        }))}
                        key={`${m.type}|${m.masterDataId.toString()}`}
                      />
                    ))}
                </>
              )
              : "No metadata."
            }
          </FlexCol>
        </LabeledControl>
      </FlexCol>

      {isDeleteConfirmationOpen && (
        <ConfirmModal
          header="Confirm Delete"
          message="Are you sure you want to delete this profile? This operation cannot be undone."
          onNoClicked={() => dispatch(toggleDeleteConfirmation(false))}
          onYesClicked={() => {
            dispatch(toggleDeleteConfirmation(false));

            dispatch(startDeleteProfile({
              email: managerValues.user?.email || "",
              profileId: managerValues.profileId || 0,
            }));
          }}
        />
      )}
    </Modal>
  );
};

export default ProfileManagerModal;

function sortProfileMeta(a: IProfileMetaData, b: IProfileMetaData): number {
  if (a.type < b.type) {
    return -1;
  } else if (a.type > b.type) {
    return 1;
  }

  let aLabel = a.masterDataCode === a.masterDataName
    ? a.masterDataName
    : `${a.masterDataCode} - ${a.masterDataName}`;

  let bLabel = b.masterDataCode === b.masterDataName
    ? b.masterDataName
    : `${b.masterDataCode} - ${b.masterDataName}`;

  return aLabel < bLabel
    ? -1
    : 1;
}