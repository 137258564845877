import { useEffect, useMemo, useState } from "react";
import Button from "shared/components/controls/buttons/button/Button";
import CircleImageButton from "shared/components/controls/buttons/circle-img-button/CircleImageButton";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import Card from "shared/components/layout/card/Card";
import FlexRow from "shared/components/layout/flex/FlexRow";
import DataGrid from "shared/components/layout/grid/DataGrid";
import deleteIcon from "shared/media/dls/delete.svg";
import { findQuestionsAssignedToFinding } from "shared/utilities/findingUtilities";
import { openFindingModal, setActionItemListModal } from "store/audit/AuditSlice";
import { FindingLinkType, FindingModalModes, IFinding } from "store/audit/reducers/findingReducers";
import { refreshGrid } from "store/grid/GridSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemLinkTypes, ActionItemStatuses } from "types/actionItemTypes";
import { IAuditQuestion } from "types/auditingTypes";
import FindingsButton from "../nonconformances/non-compliant-questions/findings-button/FindingsButton";
import ViewQuestionModal from "../../../shared/components/modals/question-modal/ViewQuestionModal";
import "./FindingsCard.scoped.scss";

interface IFindingsCardProps {
  topicId: number,
  title: string,
  questions: IAuditQuestion[],
}

const FindingsCard: React.FC<IFindingsCardProps> = ({
  topicId,
  title,
  questions,
}) => {
  const allQuestions = useAppSelector(store => store.audit.questions);
  const findingTypes = useAppSelector(store => store.audit.findingTypes);
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const allFindings = useAppSelector(store => store.audit.findings);
  const status = useAppSelector(store => store.audit.audit?.status);
  const [inspectedQuestions, setInspectedQuestions] = useState<IAuditQuestion[]>([]);

  const dispatch = useAppDispatch();

  // Only recompute the list of findings to display when the questions list changes.
  const findingRows: IFindingRow[] = useMemo(() => {
    // Find only the findings links to questions from this topic.
    const auditQuestionIds = questions.map(x => x.auditQuestionId);
    const thisTopicFindings = allFindings
      .filter(x => x.links.some(l => l.linkType === FindingLinkType.AuditQuestion
        && auditQuestionIds.some(q => q === l.linkId)));

    return thisTopicFindings.map((finding): IFindingRow => {
      const actions = actionItems
        .filter(x => x.links.some(l => l.type === "AuditFinding"
          && l.linkId.toString() === finding.id?.toString()));

      const closedCount = actions
        .filter(x => x.status === ActionItemStatuses.Closed)
        .length;

      const parentQuestions = findQuestionsAssignedToFinding(finding, allQuestions);

      return {
        id: Number(finding.id),
        parentQuestions: parentQuestions,
        finding: finding,
        justification: finding.justification ?? "",
        type: findingTypes.find(x => x.id === finding.findingTypeId)?.name ?? "--",
        closedActionCount: closedCount,
        totalActionCount: actions.length,
        parentIds: parentQuestions
          .map(x => x.questionNumber)
          .join(','),
      };
    });
  }, [allFindings, findingTypes, actionItems, questions, allQuestions]);

  useEffect(() => {
    // Whenever the grid rows change in any way, need to tell the grid to refresh.
    dispatch(refreshGrid({ gridId: `auditFindingsTable_${topicId}` }));
  }, [findingRows, topicId, dispatch]);

  return (
    <Card
      showHeader={true}
      cardStateId={`auditFindings_${topicId}`}
      defaultCollapsedState={false}
      headerElement={(
        <FlexRow
          className="finding-display"
        >
          <div
            className="title"
          >
            {title}
          </div>

          <LabeledControl
            label="# of Findings:"
            isLight={true}
          >
            {findingRows.length} findings
          </LabeledControl>
        </FlexRow>
      )}
    >
      <DataGrid
        id={`auditFindingsTable_${topicId}`}
        className="with-button-content"
        dataSource="array"
        dataArray={findingRows}
        allowPaging={false}
        gridColumns={[{
          uid: "findingId",
          field: "id",
          headerText: "Finding Id",
          width: 125,
          type: "number",
          template: (row: IFindingRow) =>
            <FindingsButton
              buttonText={`#${row.id}`}
              hasFinding
              onClick={() => {
                if (row.parentQuestions.length) {
                  const answerCode = row.parentQuestions[0]
                    .responses
                    .find(x => x.auditStatus === status)
                    ?.answer;

                  if (answerCode) {
                    dispatch(openFindingModal({
                      answerCode,
                      finding: row.finding,
                      mode: FindingModalModes.EditCreate,
                      autoSelectedLinksForRemoval: row.finding.links,
                    }));
                  }
                }
              }}
            />,
        }, {
          uid: "findingType",
          field: "type",
          headerText: "Finding Type",
          width: 200,
          type: "string",
        }, {
          uid: "justification",
          field: "justification",
          headerText: "Justification Notes",
          width: "250*",
          type: "string",
        }, {
          uid: "parent",
          field: "parentIds",
          headerText: "Parent",
          width: 150,
          type: "string",
          template: (row: IFindingRow) => {
            if (row.parentQuestions.length === 0) {
              return "--";
            } else if (row.parentQuestions.length === 1) {
              const parentQuestion = row.parentQuestions[0];

              return parentQuestion
                ? (
                  <Button
                    buttonType="link"
                    onClick={() => setInspectedQuestions([parentQuestion])}
                  >
                    #{parentQuestion.questionNumber}
                  </Button>
                ) : `${row.finding.links[0].linkType} ${row.finding.links[0].linkId}`;
            } else {
              return (
                <Button
                  buttonType="link"
                  allCaps={false}
                  onClick={() => setInspectedQuestions(row.parentQuestions)}
                >
                  {row.parentQuestions.length} questions
                </Button>
              );
            }
          },
        }, {
          uid: "closedActionItems",
          field: "closedActionItems",
          headerText: "# Closed Action Items",
          width: 100,
          type: "string",
          allowFiltering: false,
          allowSorting: false,
          template: (row: IFindingRow) => {
            return (
              <Button
                buttonType="link"
                onClick={() => dispatch(setActionItemListModal({
                  linkOptions: {
                    linkId: row.id,
                    linkType: ActionItemLinkTypes.AuditFinding,
                    parentDisplay: `Finding #${row.id}`,
                  },
                  isOpen: true,
                }))}
              >
                {row.closedActionCount}/{row.totalActionCount}
              </Button>
            );
          }
        }, {
          uid: "removeFindings",
          field: "removeFindings",
          type: "unbound",
          headerText: " ",
          width: 100,
          allowFiltering: false,
          allowSorting: false,
          template: (row: IFindingRow) =>
            <FlexRow
              className="delete-finding-row-button"
            >
              <CircleImageButton
                alt="Delete"
                icon={deleteIcon}
                type="secondary"
                iconSize="small"
                onClick={() => {
                  if (row.parentQuestions.length) {
                    const answerCode = row.parentQuestions[0]
                      .responses
                      .find(x => x.auditStatus === status)
                      ?.answer;

                    if (answerCode) {
                      dispatch(openFindingModal({
                        answerCode,
                        finding: row.finding,
                        mode: FindingModalModes.Unlink,
                        autoSelectedLinksForRemoval: row.finding.links,
                      }));
                    }
                  }
                }}
                tooltip="Remove finding"
              />
            </FlexRow>
        }]}
      />

      {inspectedQuestions.length > 0 &&
        <ViewQuestionModal
          questions={inspectedQuestions}
          onClose={() => setInspectedQuestions([])}
        />
      }
    </Card>
  );
};

export default FindingsCard;

interface IFindingRow {
  id: number,
  finding: IFinding,
  type: string,
  justification: string,
  parentQuestions: IAuditQuestion[],
  closedActionCount: number,
  totalActionCount: number,
  parentIds: string,
}