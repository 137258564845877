import React from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import PlusButton from "shared/components/controls/buttons/circle-img-button/plus-btn/PlusButton";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import AuditTopicPicker from "shared/components/controls/pickers/AuditTopicPicker";
import BusinessFunctionPicker from "shared/components/controls/pickers/BusinessFunctionPicker";
import BusinessTeamPicker from "shared/components/controls/pickers/BusinessTeamPicker";
import BusinessViewPicker from "shared/components/controls/pickers/BusinessViewPicker";
import CountryPicker from "shared/components/controls/pickers/CountryPicker";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import { IRestrictedMetaData } from "shared/types/userProfileTypes";
import { useAppDispatch } from "store/store";
import { AuditTopicSearchTypes, IAuditTopic } from "types/auditMasterDataTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView, ICountry, MetaDataTypes } from "types/masterDataTypes";
import { IQuestion } from "types/questionTypes";
import QuestionIdDisplay from "../question-id-display/QuestionIdDisplay";
import { getQuestionMetaItems, itemToCodeName, onItemDeselected, onPickerApply } from "../sharedAssociationHelpers";
import SplitMetaPicker from "../split-meta-picker/SplitMetaPicker";
import "./QuestionAdditionalAssociationsCard.scoped.scss";

interface IQuestionAdditionalAssociationsCardProps {
  isDisabled: boolean,
  question: Partial<IQuestion>,
  /** If provided, pickers will be restricted to these items. */
  derivedMetaRestrictions: IRestrictedMetaData[] | undefined,
}

const QuestionAdditionalAssociationsCard: React.FC<IQuestionAdditionalAssociationsCardProps> = ({
  isDisabled,
  question,
  derivedMetaRestrictions,
}: IQuestionAdditionalAssociationsCardProps) => {
  const dispatch = useAppDispatch();

  const trueBusinessTeamMetaData = getQuestionMetaItems<IBusinessTeam>(question.metaData,
    MetaDataTypes.BusinessTeam,
    false);
  const trueBusinessViewMetaData = getQuestionMetaItems<IBusinessView>(question.metaData,
    MetaDataTypes.BusinessView,
    false);
  const trueBusinessFunctionMetaData = getQuestionMetaItems<IBusinessFunction>(question.metaData,
    MetaDataTypes.BusinessFunction,
    false);
  const trueCountryMetaData = getQuestionMetaItems<ICountry>(question.metaData,
    MetaDataTypes.Country,
    false);

  const renderPlusButton = (openPicker: () => void) => {
    return (<PlusButton onClick={openPicker}></PlusButton>);
  };

  return (
    <Card
      title="Additional Associations"
      cardStateId="manage-question-question-additional-associations"
      headerElement={
        <QuestionIdDisplay
          question={question}
          invisible={true}
        />
      }
    >
      <FlexCol>
        <Banner
          type={BannerType.info}
        >
          Additional Associations are used to allow a global question to be used in other audits without losing its global question status.
          Associations in this card show in which audit dimensions the question will be included.
          This data does not grant permissions to edit the question.
          Any editor can change the Additional Associations in a question for those dimensions permitted by their user profile, even if they do not have permission to edit the question itself.
        </Banner>

        <div className="additional-associations-grid">
          <div className="topics">
            <SplitMetaPicker<IAuditTopic>
              isDisabled={isDisabled}
              columns={[{
                key: "Subtopic",
                label: "Sub-Topics",
              }]}
              selectedItems={getQuestionMetaItems<IAuditTopic>(question.metaData,
                MetaDataTypes.AuditTopic,
                true).filter(x => x.level === 2)
              }
              mapItemToColumnKey={item =>
                item.level === 1
                  ? "Topic"
                  : "Subtopic"
              }
              itemSorter={(item1, item2) => (item1.name || "") < (item2.name || "") ? -1 : 1}
              onItemDeselected={item => {
                if (item.level === 2) {
                  onItemDeselected(item, item.id, MetaDataTypes.AuditTopic, true, dispatch)
                }
              }}
              renderListItem={item => item.name}
              keyMapper={item => item.id}
              customMetaRestrictionChecker={(item, meta) => {
                if (meta.some(x => x.type === MetaDataTypes.OwnerGroup
                  && x.id === item.ownerGroupId)) {
                  return true;
                }

                const type = item.level === 1 || item.level === 2 ? MetaDataTypes.AuditTopic : "";

                if (!type) {
                  return false;
                }

                return meta.some(x => x.type === type
                  && x.id === item.id);
              }}
              userProfileMetaRestrictions={
                derivedMetaRestrictions
                  ?.filter(x => x.type === MetaDataTypes.OwnerGroup
                    || x.type === MetaDataTypes.AuditTopic) || []
              }
              picker={
                <AuditTopicPicker
                  onApply={items => {
                    const selItems = items.filter(x => x.level === 2);

                    onPickerApply(selItems,
                      question.metaData,
                      MetaDataTypes.AuditTopic,
                      true,
                      dispatch);
                  }}
                  selectedItems={getQuestionMetaItems<IAuditTopic>(question.metaData,
                    MetaDataTypes.AuditTopic,
                    true).filter(x => x.level === 2)
                  }
                  allowMultiselect={true}
                  onRenderPicker={openPicker => renderPlusButton(openPicker)}
                  showSuggestions={true}
                  userProfileMetaRestrictions={derivedMetaRestrictions}
                  // For each item, it the item is already in the "true" meta data list, it cannot be
                  // selected here. Additionally, if the item is a TOPIC, it is disabled.
                  isItemDisabledMapper={item => trueBusinessTeamMetaData.some(x => x.id === item.id)
                    || item.level === 1
                  }
                  auditTopicSearch={question.auditGroup?.id
                    ? { auditTopicSearchType: AuditTopicSearchTypes.AuditGroup, auditTopicSearchIds: [question.auditGroup?.id] }
                    : undefined}
                />
              }
            />
          </div>

          <div className="basin">
            <SplitMetaPicker<IBusinessTeam>
              isDisabled={isDisabled}
              columns={[{
                key: "Basin",
                label: "Basins",
              }, {
                key: "GeoUnit",
                label: "Geo Units",
              }, {
                key: "SubGeoUnit",
                label: "Sub-Geo Units",
              }]}
              selectedItems={getQuestionMetaItems<IBusinessTeam>(question.metaData,
                MetaDataTypes.BusinessTeam,
                true)}
              mapItemToColumnKey={item => item.type}
              itemSorter={(item1, item2) => itemToCodeName(item1) < itemToCodeName(item2) ? -1 : 1}
              onItemDeselected={item => onItemDeselected(item, item.id, MetaDataTypes.BusinessTeam, true, dispatch)}
              renderListItem={item => itemToCodeName(item)}
              keyMapper={item => item.id}
              metaTypeMapper={_ => MetaDataTypes.BusinessTeam}
              userProfileMetaRestrictions={
                derivedMetaRestrictions?.filter(x => x.type === MetaDataTypes.BusinessTeam) || []
              }
              picker={
                <BusinessTeamPicker
                  onApply={items => onPickerApply(items,
                    question.metaData,
                    MetaDataTypes.BusinessTeam,
                    true,
                    dispatch)}
                  selectedItems={getQuestionMetaItems(question.metaData,
                    MetaDataTypes.BusinessTeam,
                    true)}
                  allowMultiselect={true}
                  onRenderPicker={openPicker => renderPlusButton(openPicker)}
                  showSuggestions={true}
                  disallowedTypes={["GeoLevel1"]}
                  isDisabled={isDisabled}
                  userProfileMetaRestrictions={derivedMetaRestrictions}
                  // For each item, it the item is already in the "true" meta data list, it cannot be
                  // selected here.
                  isItemDisabledMapper={item => trueBusinessTeamMetaData.some(x => x.id === item.id)}
                />
              }
            />
          </div>

          <div className="division">
            <SplitMetaPicker<IBusinessView>
              isDisabled={isDisabled}
              columns={[{
                key: "Division",
                label: "Divisions",
              }, {
                key: "BusinessLine",
                label: "Business Lines",
              }, {
                key: "SubBusinessLine",
                label: "Sub-Business Lines",
              }, {
                key: "Support",
                label: "Business Supports",
              },]}
              selectedItems={getQuestionMetaItems<IBusinessView>(question.metaData,
                MetaDataTypes.BusinessView,
                true)}
              mapItemToColumnKey={item => item.type}
              itemSorter={(item1, item2) => itemToCodeName(item1) < itemToCodeName(item2) ? -1 : 1}
              onItemDeselected={item => onItemDeselected(item, item.id, MetaDataTypes.BusinessView, false, dispatch)}
              renderListItem={item => itemToCodeName(item)}
              keyMapper={item => item.id}
              metaTypeMapper={_ => MetaDataTypes.BusinessView}
              userProfileMetaRestrictions={
                derivedMetaRestrictions?.filter(x => x.type === MetaDataTypes.BusinessView) || []
              }
              picker={
                <BusinessViewPicker
                  onApply={items => onPickerApply(items,
                    question.metaData,
                    MetaDataTypes.BusinessView,
                    true,
                    dispatch)
                  }
                  selectedItems={getQuestionMetaItems(question.metaData,
                    MetaDataTypes.BusinessView,
                    true)}
                  allowMultiselect={true}
                  onRenderPicker={openPicker => renderPlusButton(openPicker)}
                  showSuggestions={true}
                  isDisabled={isDisabled}
                  userProfileMetaRestrictions={derivedMetaRestrictions}
                  // For each item, it the item is already in the "true" meta data list, it cannot be
                  // selected here.
                  isItemDisabledMapper={item => trueBusinessViewMetaData.some(x => x.id === item.id)}
                />
              }
            />
          </div>

          <div className="function">
            <SplitMetaPicker<IBusinessFunction>
              isDisabled={isDisabled}
              columns={[{
                key: "Function",
                label: "Function",
              }, {
                key: "SubFunction",
                label: "SubFunction",
              },]}
              selectedItems={getQuestionMetaItems<IBusinessFunction>(question.metaData,
                MetaDataTypes.BusinessFunction,
                true)}
              mapItemToColumnKey={item => item.type}
              itemSorter={(item1, item2) => itemToCodeName(item1) < itemToCodeName(item2) ? -1 : 1}
              onItemDeselected={item => onItemDeselected(item, item.id, MetaDataTypes.BusinessFunction, true, dispatch)}
              renderListItem={item => itemToCodeName(item)}
              keyMapper={item => item.id}
              metaTypeMapper={_ => MetaDataTypes.BusinessFunction}
              userProfileMetaRestrictions={
                derivedMetaRestrictions?.filter(x => x.type === MetaDataTypes.BusinessFunction) || []
              }
              picker={
                <BusinessFunctionPicker
                  onApply={items => onPickerApply(items,
                    question.metaData,
                    MetaDataTypes.BusinessFunction,
                    true,
                    dispatch)}
                  selectedItems={getQuestionMetaItems<IBusinessFunction>(question.metaData,
                    MetaDataTypes.BusinessFunction,
                    true)}
                  allowMultiselect={true}
                  onRenderPicker={openPicker => renderPlusButton(openPicker)}
                  showSuggestions={true}
                  disallowedTypes={["FunctionCategory"]}
                  isDisabled={isDisabled}
                  userProfileMetaRestrictions={derivedMetaRestrictions}
                  // For each item, it the item is already in the "true" meta data list, it cannot be
                  // selected here.
                  isItemDisabledMapper={item => trueBusinessFunctionMetaData.some(x => x.id === item.id)}
                />
              }
            />
          </div>

          <div className="country">
            <LabeledControl
              label="Country"
            >
              <CountryPicker
                onApply={items => onPickerApply(items,
                  question.metaData,
                  MetaDataTypes.Country,
                  true,
                  dispatch)}
                selectedItems={getQuestionMetaItems<ICountry>(question.metaData,
                  MetaDataTypes.Country,
                  true)}
                allowMultiselect={true}
                showSuggestions={true}
                isDisabled={isDisabled}
                userProfileMetaRestrictions={derivedMetaRestrictions}
                // For each item, it the item is already in the "true" meta data list, it cannot be
                // selected here.
                isItemDisabledMapper={item => trueCountryMetaData.some(x => x.id === item.id)}
              />
            </LabeledControl>
          </div>
        </div>
      </FlexCol>
    </Card>
  );
};

export default QuestionAdditionalAssociationsCard;