import MasterDataApi from "api/masterdata/MasterDataApi";
import React, { useCallback } from "react";
import { IQMSElement } from "types/auditMasterDataTypes";
import NonReduxPickerDropdown from "./non-redux-dropdown-picker/NonReduxDropdownPicker";
import NonReduxPicker from "./non-redux-picker/NonReduxPicker";
import { ICommonPickerProps, PickerMode } from "./pickerTypes";

interface IQMSElementPickerProps {
  /** Optional. Determines the picker's mode. Default = Picker. */
  renderMode?: PickerMode,
}

const QMSElementPicker: React.FC<IQMSElementPickerProps & ICommonPickerProps<IQMSElement>> = ({
  onApply,
  selectedItems,
  allowMultiselect = false,
  isDisabled = false,
  renderMode = "picker",
}) => {
  const renderItem = (item: IQMSElement) => item.name;

  const loadItems = useCallback(async (_: string | undefined, abortSignal: AbortSignal) =>
    await MasterDataApi.getQMSElements(undefined, abortSignal),
    []);

  if (renderMode === "picker") {
    return (
      <NonReduxPicker<IQMSElement>
        itemSorter={(a, b) => a.name < b.name ? -1 : 1}
        keyMapper={x => x.id}
        onApply={onApply}
        onLoadItems={loadItems}
        renderSelectedItem={renderItem}
        selectedItems={selectedItems}
        title="Quality Management System Sub-Element"
        allowMultiSelect={allowMultiselect}
        displayMode="list"
        renderListItem={item => item.name}
        searchOptions={{
          filterItem: (item, search) => item.name.toLowerCase().includes(search.toLowerCase()),
        }}
        isDisabled={isDisabled}
      />
    );
  } else if (renderMode === "dropdown") {
    return (
      <NonReduxPickerDropdown<IQMSElement>
        itemFormatter={renderItem}
        keyMapper={item => item.id}
        onLoadItems={loadItems}
        onSelectionChanged={onApply}
        selectedItems={selectedItems}
        isDisabled={isDisabled}
        placeholder="Select"
        selectMode={allowMultiselect ? "multiple" : "single"}
      />
    );
  } else {
    return <>Render mode "{renderMode}" is not supported.</>;
  }
};

export default QMSElementPicker;