import { Action } from "@reduxjs/toolkit";
import AdminPlanningPeriodsApi from "api/admin/planning-administration/AdminPlanningPeriodsApi";
import AuditPlanningConfigApi from "api/admin/planning-administration/AuditPlanningConfigApi";
import { formatUpsertPeriodsRequest } from "api/admin/planning-administration/formatters/adminPlanningAdministrationFormatters";
import MasterDataApi from "api/masterdata/MasterDataApi";
import { isEqual } from "lodash";
import { all, call, CallEffect, put, select, takeLatest } from "redux-saga/effects";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IAdminPlanningAdministrationPeriod, PeriodMode, PlanningPeriodsType } from "types/adminPlanningAdministration";
import { IAuditPlanningConfig } from "types/auditPlanningTypes";
import { IBusinessTeam } from "types/masterDataTypes";
import { finishLoadPeriods, finishUpsertPeriods, IAdminFacilityAttributesState, loadPeriods, upsertPeriods } from "./AdminFacilityAttributesSlice";

export default function* adminFacilityAttributeSagas() {
  yield all([
    loadPeriodsAsync(),
    upsertPeriodsAsync(),
  ])
}

function* loadPeriodsAsync() {
  yield takeLatest(loadPeriods, function* (action: Action) {
    if (!loadPeriods.match(action)) {
      return;
    }

    const configSearch = ["Mode", "DefaultStart", "DefaultEnd", "AutoDate"]
      .map(x => ({ type: action.payload, name: x }));

    try {
      const apiData: [
        IAdminPlanningAdministrationPeriod[],
        IBusinessTeam[],
        IAuditPlanningConfig[],
      ] = yield all([
        call(AdminPlanningPeriodsApi.getPeriods, action.payload),
        call(MasterDataApi.getBusinessTeams),
        call(AuditPlanningConfigApi.getConfigs, configSearch),
      ]);

      const [
        planningPeriods,
        businessTeams,
        configItems,
      ] = apiData;

      yield put(finishLoadPeriods({
        isWorking: false,
        data: {
          planningPeriods,
          businessTeams: businessTeams,
          configItems,
        },
      }));
    } catch (err) {
      yield put(finishLoadPeriods({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* upsertPeriodsAsync() {
  yield takeLatest(upsertPeriods, function* (action: Action) {
    if (!upsertPeriods.match(action)) {
      return;
    }

    const state: IAdminFacilityAttributesState = yield select((store: RootState) => store.adminFacilityAttributes);

    if (!state.isDirty) {
      yield put(finishUpsertPeriods({
        isWorking: false,
      }));
    }

    try {
      const {
        mode,
        originalMode,
        originalPeriods,
        periods,
        autoDate,
        originalAutoDate,
      } = state;

      const changedPeriods = periods
        .filter(period => !isEqual(period, originalPeriods.find(x => x.businessTeamId === period.businessTeamId)));

      const results: [
        CallEffect,
        IAdminPlanningAdministrationPeriod[],
        IAdminPlanningAdministrationPeriod[],
        CallEffect,
      ] = yield all([
        call(saveMode, mode, originalMode, action.payload),
        call(createPeriods, changedPeriods, action.payload),
        call(updatePeriods, changedPeriods, action.payload),
        call(saveAutoDate, autoDate, originalAutoDate, action.payload),
      ]);

  const createdPeriods = results[1];
  const updatedPeriods = results[2];

  yield put(finishUpsertPeriods({
    isWorking: false,
    data: [...createdPeriods, ...updatedPeriods],
  }));
} catch (err) {
  yield put(finishUpsertPeriods({
    isWorking: false,
    errorMessage: getResponseErrorMessage(err),
  }));
}
  });
}

function* saveMode(mode: PeriodMode, originalMode: PeriodMode, type: PlanningPeriodsType) {
  if (mode !== originalMode) {
    yield call(AuditPlanningConfigApi.updateConfig, {
      name: "Mode",
      type,
      value: mode,
    });
  }
}

function* saveAutoDate(autoDate: number | undefined, originalAutoDate: number | undefined, type: PlanningPeriodsType) {
  if (autoDate !== originalAutoDate
    && autoDate) {
    let autoDateDate = new Date(autoDate);

    yield call(AuditPlanningConfigApi.updateConfig, {
      name: "AutoDate",
      type,
      value: autoDateDate.getDate() + "/" + (autoDateDate.getMonth() + 1),
    });
  }
}

function* createPeriods(changedPeriods: IAdminPlanningAdministrationPeriod[], type: PlanningPeriodsType) {
  // For each of the businessTeam periods that doesn't already exist on the server,
  // create it on the server and receive back the item that was created.
  if (changedPeriods.some(x => x.id === 0)) {
    const createdItems: IAdminPlanningAdministrationPeriod[] =
      yield all(changedPeriods
        .filter(x => x.id === 0)
        .map(item => call(AdminPlanningPeriodsApi.createPeriod,
          formatUpsertPeriodsRequest(item, type))));

    return createdItems;
  }

  return [];
}

function* updatePeriods(changedPeriods: IAdminPlanningAdministrationPeriod[], type: PlanningPeriodsType) {
  // For each of the businessTeam periods that were already on the server, update them
  // and receive back the item that was updated.
  if (changedPeriods.some(x => x.id !== 0)) {
    const updatedItems: IAdminPlanningAdministrationPeriod[] =
      yield all(changedPeriods
        .filter(x => x.id !== 0)
        .map(x => call(AdminPlanningPeriodsApi.updatePeriod,
          formatUpsertPeriodsRequest(x, type))));

    return updatedItems;
  }

  return [];
}
