import AuditScoresApi from "api/auditing/AuditScoresApi";
import { call, put, takeLatest } from "redux-saga/effects";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { IAllAuditScoreResults } from "types/audit-scores/auditScoreTypes";
import { finishLoadScores, loadScores } from "../AuditSlice";

export function* loadScoresAsync() {
  yield takeLatest(loadScores, function* (action) {
    if (!loadScores.match(action)) {
      return;
    }

    try {
      const scores: IAllAuditScoreResults = yield call(AuditScoresApi.getAuditScores, action.payload);

      yield put(finishLoadScores({
        isWorking: false,
        data: scores,
      }));
    } catch (err) {
      yield put(finishLoadScores({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}
