import { ActionCreatorWithPayload, PayloadAction } from "@reduxjs/toolkit";
import MasterDataApi from "api/masterdata/MasterDataApi";
import SearchAuditorsApi from "api/users/SearchAuditorsApi";
import { call, put } from "redux-saga/effects";
import { ILoadPickerItemsAction, ILoadSuggestedPickerItemsAction, ISetPickerErrorAction, ISetPickerItemsAction, ISetPickerSelectedItemsAction, ISetSuggestedPickerItemsAction } from "shared/store/picker/pickerReducerHandlers";
import { showErrorToast } from "shared/store/toast/ToastSlice";
import { IPickerItem } from "shared/types/pickerTypes";
import { IAzureADUser } from "shared/types/userProfileTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { IAuditType } from "types/auditMasterDataTypes";
import { AuditorSearchTypes } from "types/auditingTypes";
import { IBusinessFunction, IBusinessTeam, IBusinessView } from "types/masterDataTypes";
import { loadAndSetSuggestedPickerItems } from "./commonSagas";

export function* commonLoadPickerItemsAsync(action: PayloadAction<ILoadPickerItemsAction>,
  setPickerItems: ActionCreatorWithPayload<ISetPickerItemsAction<any>, string>,
  setSelectedPickerItems: ActionCreatorWithPayload<ISetPickerSelectedItemsAction<any>, string>,
  setPickerError: ActionCreatorWithPayload<ISetPickerErrorAction, string>,
  isFirstPartyAudit?: boolean) {
  const {
    pickerKey,
    searchValue,
  } = action.payload;

  try {
    switch (pickerKey) {
      // Audit Types.
      case "auditTypes":
      case "calendarAuditTypes":
        yield retrieveAndPutPickerData(MasterDataApi.getAuditTypes,
          (item): IPickerItem<IAuditType> => ({
            key: item.id,
            disabled: false,
            text: item.name,
          }),
          setPickerItems,
          setSelectedPickerItems,
          setPickerError,
          pickerKey,
          false);
        break;

      // BusinessViews.
      case "businessViews":
        yield retrieveAndPutPickerData(MasterDataApi.getBusinessViews,
          (item): IPickerItem<IBusinessView> => mapBusinessViewToPickerItem(item),
          setPickerItems,
          setSelectedPickerItems,
          setPickerError,
          pickerKey,
          false,
          searchValue);
        break;

      // Lead Auditors.
      case "leadAuditors":
        yield retrieveAuditors(pickerKey,
          searchValue || "",
          isFirstPartyAudit === true,
          AuditorSearchTypes.LeadAuditor,
          setPickerItems,
          setPickerError);
        break;

      // Auditors.
      case "auditors":
        yield retrieveAuditors(pickerKey,
          searchValue || "",
          isFirstPartyAudit === true,
          AuditorSearchTypes.Auditor,
          setPickerItems,
          setPickerError);
        break;

      // All Azure AD Users.
      case "users":
        yield retrieveAuditors(pickerKey,
          searchValue || "",
          isFirstPartyAudit === false,
          AuditorSearchTypes.All,
          setPickerItems,
          setPickerError);
        break;

      // BusinessTeams.
      case "businessTeams":
        // Auto expand global ops.
        const globalOpsId = 84;

        yield retrieveAndPutPickerData(() => MasterDataApi.getBusinessTeams(),
          (item): IPickerItem<IBusinessTeam> => {
            const pickerItem = mapBusinessTeamToPickerItem(item);
            pickerItem.isExpanded = item.id === globalOpsId;
            return pickerItem;
          },
          setPickerItems,
          setSelectedPickerItems,
          setPickerError,
          pickerKey,
          false,
          searchValue);
        break;
      default:
        throw new Error(`Picker '${pickerKey}' has no associated saga for loading items!`);
    }
  } catch (err) {
    yield put(setPickerError({
      pickerKey: pickerKey,
      errorMessage: getResponseErrorMessage(err),
      stopLoading: true,
    }));
  }
}

function* retrieveAndPutPickerData<T>(apiMethod: (searchValue?: string | undefined) => Promise<T[]>,
  itemMapper: (item: T) => IPickerItem<T>,
  setPickerItems: ActionCreatorWithPayload<ISetPickerItemsAction<any>, string>,
  setSelectedPickerItems: ActionCreatorWithPayload<ISetPickerSelectedItemsAction<any>, string>,
  setPickerError: ActionCreatorWithPayload<ISetPickerErrorAction, string>,
  pickerKey: string,
  selectFirstIfOnlyOne: boolean,
  searchValue?: string) {
  const rawItems: T[] = yield call(apiMethod, searchValue);
  const items = rawItems.map(itemMapper);
  yield put(setPickerItems({
    pickerKey,
    items,
  }));

  if (items.length === 1
    && selectFirstIfOnlyOne) {
    yield put(setSelectedPickerItems({
      pickerKey,
      selectedItems: items,
    }));
  }

  yield put(setPickerError({
    pickerKey,
    errorMessage: "",
    stopLoading: true,
  }));
}

export function* commonLoadSuggestedPickerItemsAsync(action: PayloadAction<ILoadSuggestedPickerItemsAction>,
  setSuggestedPickerItems: ActionCreatorWithPayload<ISetSuggestedPickerItemsAction<any>, string>,
  isFirstPartyAudit?: boolean) {
  const {
    pickerKey,
  } = action.payload;

  switch (pickerKey) {
    // BusinessTeam.
    case "businessTeams":
      yield call<any>(loadAndSetSuggestedPickerItems,
        () => MasterDataApi.getSuggestedBusinessTeams(),
        (item: IBusinessTeam): IPickerItem<IBusinessTeam> => ({
          key: item.id,
          item: item,
        }),
        pickerKey,
        setSuggestedPickerItems);
      break;

    case "leadAuditors":
      yield call(getSuggestedLeadAuditors,
        isFirstPartyAudit,
        action.payload.pickerKey,
        setSuggestedPickerItems);
      break;

    // BusinessView.
    case "businessViews":
      yield call<any>(loadAndSetSuggestedPickerItems,
        () => MasterDataApi.getSuggestedBusinessViews(),
        (item: IBusinessView): IPickerItem<IBusinessView> => ({
          key: item.id,
          item: item,
        }),
        pickerKey,
        setSuggestedPickerItems);
      break;
    default:
      yield put(showErrorToast(`No handler defined for loadSuggestedItems with key ${action.payload.pickerKey}.`));
  }
}

export function mapBusinessViewToPickerItem(item: IBusinessView): IPickerItem<IBusinessView> {
  return {
    key: item.id,
    disabled: false,
    item: item,
    children: item.children.map(child => mapBusinessViewToPickerItem(child)),
  };
}

export function mapBusinessFunctionToPickerItem(item: IBusinessFunction): IPickerItem<IBusinessFunction> {
  return {
    key: item.id,
    disabled: false,
    item: item,
    children: item.children.map(child => mapBusinessFunctionToPickerItem(child)),
  };
}

export function mapBusinessTeamToPickerItem(item: IBusinessTeam): IPickerItem<IBusinessTeam> {
  return {
    key: item.id,
    disabled: false,
    item: item,
    children: item.children.map(child => mapBusinessTeamToPickerItem(child)),
  };
}

export function* retrieveAuditors(pickerKey: string,
  searchValue: string,
  isFirstPartyAudit: boolean,
  auditorSearchType: AuditorSearchTypes,
  setPickerItems: ActionCreatorWithPayload<ISetPickerItemsAction<any>, string>,
  setPickerError: ActionCreatorWithPayload<ISetPickerErrorAction, string>,) {
  const rawItems: IAzureADUser[] = yield call(SearchAuditorsApi.searchAuditors,
    searchValue,
    isFirstPartyAudit ? 2 : 1,
    auditorSearchType);

  const items = rawItems.map((item): IPickerItem<IAzureADUser> => ({
    key: item.email,
    disabled: false,
    item: item,
  }));

  yield put(setPickerItems({
    pickerKey,
    items,
  }));

  yield put(setPickerError({
    pickerKey,
    errorMessage: "",
    stopLoading: true,
  }));
}

export function* getSuggestedLeadAuditors(isFirstPartyAudit: boolean | undefined,
  pickerKey: string,
  setSuggestedPickerItems: ActionCreatorWithPayload<ISetSuggestedPickerItemsAction<any>, string>,
) {
  if (isFirstPartyAudit === undefined) {
    yield put(setSuggestedPickerItems({
      pickerKey,
      suggestedItems: [],
    }));
    return;
  }

  // Call the helper function to load the suggestions and put them into redux.
  yield call<any>(loadAndSetSuggestedPickerItems,
    () => SearchAuditorsApi.getAuditorSuggestions(isFirstPartyAudit ? 2 : 1, AuditorSearchTypes.LeadAuditor),
    (user: IAzureADUser): IPickerItem<IAzureADUser> => ({
      key: user.email,
      item: user,
    }),
    pickerKey,
    setSuggestedPickerItems);
}
