import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import "./SummaryBlock.scoped.scss";

interface ISummaryBlockProps {
  items: ISummaryBlockItem[],
  className?: string,
}

export interface ISummaryBlockItem {
  key: string,
  label: string,
  tooltip?: string,
  className?: string,
  node: React.ReactNode,
}

const SummaryBlock: React.FC<ISummaryBlockProps> = ({
  items,
  className
}) =>
  <div
    className={`summary-block ${className || ""}`}
  >
    {items.map(item => (
      <LabeledControl
        isLight={true}
        key={item.key}
        label={item.label}
        className={item.className || ""}
        labelExtraClass="label-description"
        tooltip={item.tooltip}
        labelContentExtraClass="label-content"
      >
        {item.node}
      </LabeledControl>
    ))}
  </div>;

export default SummaryBlock;