import React from "react";
import collapseIcon from "shared/media/dls/collapse.svg";
import expandIcon from "shared/media/dls/expand-2.svg";
import { IPickerItem } from "shared/types/pickerTypes";
import { isNotUndefined } from "shared/utilities/typeCheck";
import PickerItem from "./PickerItem";
import "./PickerItemRow.scoped.scss";

interface IPickerItemRowProps<T> {
  showChildren: boolean,
  item: IPickerItem<T>,
  allowMultiSelect?: boolean,
  displayMode: "list" | "tree",
  ancestryPath?: IPickerItem<T>[],
  renderItem?: (item: T) => string | React.ReactNode,
  onItemSelected(item: IPickerItem<T>): void,
  onItemDeselected(item: IPickerItem<T>): void,
  onItemExpanded?(item: IPickerItem<T>, ancestryPath?: (string | number)[]): void,
  onItemCollapsed?(item: IPickerItem<T>, ancestryPath?: (string | number)[]): void,
  isSelected: boolean,
  /** Optional. If specified, each item will call this function to determine if it should be enabled or not. The ancestor path is the list of all parent items above the item. */
  isDisabledMapper?: (item: T, ancestorPath: T[]) => boolean,
  notShowPlusMinusSign?: boolean,
  renderTotal?: (item: T) => string | React.ReactNode,
  showOnlySelectedItems?: boolean,
  hiddenSelectOption?: boolean,
  /** Optional. If true and the display mode is 'list', branches of the hierarchy that have no selectable items will be pruned. Default = false. */
  hideUnselectableBranches?: boolean,
  hasSelectableChild?: boolean | undefined,
}

const PickerItemRow = <T,>({
  showChildren,
  item,
  allowMultiSelect,
  displayMode,
  ancestryPath,
  renderItem,
  onItemSelected,
  onItemDeselected,
  onItemCollapsed,
  onItemExpanded,
  isSelected,
  isDisabledMapper,
  notShowPlusMinusSign,
  renderTotal,
  showOnlySelectedItems,
  hiddenSelectOption,
  hideUnselectableBranches = false,
  hasSelectableChild,
}: IPickerItemRowProps<T>) => {
  return (
    <div
      className={`picker-item-row`}
    >
      {showChildren
        && (notShowPlusMinusSign === undefined
          || notShowPlusMinusSign === false)
        && (!hideUnselectableBranches
          || hasSelectableChild) && (
          <img
            className="icon-small toggle-button"
            src={item.isExpanded
              ? collapseIcon
              : expandIcon
            }
            alt={item.isExpanded
              ? "-"
              : "+"
            }
            onClick={() => item.isExpanded
              ? onItemCollapsed?.(item, ancestryPath?.map(x => x.key))
              : onItemExpanded?.(item, ancestryPath?.map(x => x.key))
            }
          />
        )}

      {!showChildren
        && displayMode === "tree"
        && !notShowPlusMinusSign
        && (!hideUnselectableBranches
          || hasSelectableChild) && (
          <span
            className="icon-small toggle-button-placeholder"
          >
          </span>
        )}

      <PickerItem
        key={item.key}
        item={item}
        isSelected={isSelected}
        renderItem={renderItem}
        type={allowMultiSelect ? "checkbox" : "radio"}
        onSelect={() => onItemSelected(item)}
        onDeselect={() => onItemDeselected(item)}
        isDisabled={!isDisabledMapper
          || !item.item
          ? false
          : isDisabledMapper(item.item, ancestryPath?.map(x => x.item).filter(isNotUndefined) || [])
        }
        renderTotal={renderTotal}
        showOnlySelectedItems={showOnlySelectedItems}
        hiddenSelectOption={hiddenSelectOption}
      />
    </div>
  );
};

export default PickerItemRow;
