import React, { useState } from "react";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import "./DetailText.scoped.scss";

interface IDetailTextProps {
  /** Text to show. */
  text: string,
  /** Number of character to show before becoming clickable. Default = 97. */
  maxTextLength?: number,
  /** The event to fire when clicked. */
  onClick?(): void,
  /** Determines if the default modal should be shown instead of using a custom click handler from onClick. */
  showModalOnClick?: boolean,
  /** Determines if the text should be clickable even if it doesn't exceed the max length. */
  isAlwaysClickable?: boolean,
  /** The data to show in the default modal. */
  modalData?: IModalState,

  /** Add a class to label */
  className?: string
}

interface IModalState {
  header?: React.ReactNode,
  content?: React.ReactNode,
}

const DetailText: React.FC<IDetailTextProps> = ({
  text,
  maxTextLength,
  onClick,
  showModalOnClick,
  isAlwaysClickable,
  modalData,
  className,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const maxVisible = maxTextLength === undefined
    || maxTextLength <= 0
    ? 97
    : maxTextLength;

  const displayText = text.length > maxVisible
    ? text.substring(0, maxVisible) + "..."
    : (text || "-");

  const toolTip = text.length > maxVisible
    ? text
    : undefined;

  return (
    <>
      <span
        title={toolTip}
        className={`${toolTip
          || isAlwaysClickable
          ? "hover-info"
          : ""} ${className ? className : ''}`
        }
        onClick={toolTip
          || isAlwaysClickable
          ? (
            showModalOnClick
              ? () => {
                setIsModalVisible(true);
              }
              : onClick
          )
          : undefined
        }
      >
        {displayText}
      </span>
      {isModalVisible && (
        <ConfirmModal
          header={modalData?.header}
          message={modalData?.content || text}
          noButtonOptions={{
            show: false,
          }}
          yesButtonOptions={{
            text: "OK",
          }}
          onYesClicked={() => {
            setIsModalVisible(false);
          }}
          showCloseButton={true}
          onCloseButtonClicked={() => {
            setIsModalVisible(false);
          }}
        />
      )}
    </>
  )
};

export default DetailText;
