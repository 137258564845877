import React from "react";
import Card from "shared/components/layout/card/Card";
import FlexRow from "shared/components/layout/flex/FlexRow";
import ActionDateRangeEntry from "./entries/ActionDateRangeEntry";
import ActionFreeTextEntry from "./entries/ActionFreeTextEntry";
import ActionId from "./entries/ActionId";
import ActionItemPriority from "./entries/ActionItemPriority";
import ActionItemStatus from "./entries/ActionItemStatus";
import ActionItemType from "./entries/ActionItemType";
import AuditType from "./entries/AuditType";
import AssignedTo from "./entries/AssignedTo";
import BusinessViewItems from "./entries/BusinessViewItems";
import CausalFactor from "./entries/CausalFactor";
import Countries from "./entries/Countries";
import CreatedBy from "./entries/CreatedBy";
import Facilities from "./entries/Facilities";
import BusinessFunctions from "./entries/BusinessFunctions";
import LeadAuditor from "./entries/LeadAuditor";
import MobileSites from "./entries/MobileSites";
import ParentType from "./entries/ParentType";
import BusinessTeams from "./entries/BusinessTeams";
import Validated from "./entries/Validated";
import "./SearchActionsFilters.scoped.scss";

const SearchActionsFilters: React.FC = () =>
  <>
    <ActionId />

    <Card>
      <FlexRow
        className="filters"
      >
        <ActionDateRangeEntry
          startPropName="dueDateStart"
          endPropName="dueDateEnd"
          label="Due Date"
        />
        <ActionItemType />
        <AuditType />

        <ActionFreeTextEntry
          label="Action Required"
          propName="actionRequired"
          placeholder="Action Required"
        />
        <AssignedTo />
        <CreatedBy />
        
        <ActionItemStatus />
        <Facilities />
        <Countries />
        
        <BusinessViewItems />
        <BusinessTeams />
        <BusinessFunctions />

        <MobileSites />
      </FlexRow>
    </Card>

    <Card>
      <FlexRow
        className="filters"
      >
        <ParentType />
        <ActionFreeTextEntry
          label="Parent Id"
          propName="parentId"
          placeholder="Parent Id"
        />
        <ActionFreeTextEntry
          label="Parent Name"
          propName="parentName"
          placeholder="Parent Name"
        />

        <LeadAuditor />
        <ActionItemPriority />
        <Validated />

        <ActionDateRangeEntry
          startPropName="creationDateStart"
          endPropName="creationDateEnd"
          label="Creation Date"
        />
        <ActionDateRangeEntry
          startPropName="closureDateStart"
          endPropName="closureDateEnd"
          label="Closed Date"
        />
        <CausalFactor />
      </FlexRow>
    </Card>
  </>;

export default SearchActionsFilters;