import { getActionItemTableColumns } from "components/actions/list/ActionItemsList";
import { ManageActionItemModalActions } from "components/actions/manage/ManageActionItemModal";
import { useDispatch } from "react-redux";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Table, { TableThemes } from "shared/components/layout/table/Table";
import addreport from "shared/media/dls/add-to-report.svg";
import { findActionItemsByParentItems } from "shared/utilities/actionItemUtilities";
import { setManageActionItemModal } from "store/audit/AuditSlice";
import { useAppSelector } from "store/store";
import { ActionItemLinkTypes } from "types/actionItemTypes";
import { EditRestriction } from "types/auditPageAuthTypes";
import { IAuditQuestion } from "types/auditingTypes";
import "./RwpCard.scoped.scss";

interface IRwpCardProps {
  question: IAuditQuestion,
}

const RwpCard: React.FC<IRwpCardProps> = ({
  question,
}) => {
  const userEmailLowered = useAppSelector(store => store.auth.currentUser.email.toLowerCase());
  const dispatch = useDispatch();
  const actionItems = useAppSelector(store => store.audit.actionItems);
  const auditPageAuth = useAppSelector(store => store.auditPageRestriction.auditPageAuth);

  const currentUserProfile = useAppSelector(store => store.auth.activeUserProfile);
  const currentUser = useAppSelector(store => store.auth.currentUser);

  const questionActionItems = findActionItemsByParentItems(actionItems, [{
    linkType: ActionItemLinkTypes.AuditQuestion,
    linkId: question.auditQuestionId,
  }]);
  const userOwnsQuestion = question.auditorEmail?.toLowerCase() === currentUser.email.toLowerCase();
  const isEditable = !question.responses.some(x => x.isOutOfScope)
    && auditPageAuth.editRestriction === EditRestriction.EditAll;
  const allowEdit = isEditable
    || (auditPageAuth.editRestriction === EditRestriction.EditOwn
      && userOwnsQuestion);

  let displayComponent: React.ReactNode | undefined;

  if (!questionActionItems.length) {
    displayComponent = (
      <Banner
        type={BannerType.info}
      >
        There are no action items.
      </Banner>
    );
  } else {
    displayComponent = (
      <Table
        keyProp="id"
        className="action-items-table"
        columns={getActionItemTableColumns(true,
          (item) => dispatch(setManageActionItemModal({
            isOpen: true,
            actionItemId: item.id,
            actionToPerformOnLoad: undefined,
          })),
          userEmailLowered,
          currentUser,
          currentUserProfile,
          allowEdit,
          auditPageAuth,
          (item) => dispatch(setManageActionItemModal({
            isOpen: true,
            actionItemId: item.id,
            actionToPerformOnLoad: {
              action: ManageActionItemModalActions.Unlink,
              args: item.links
                ? {
                  type: "UNLINK_ARGS",
                  linksToUnlink: item.links.filter(x => x.type === "AuditQuestion"
                    && x.linkId === question.auditQuestionId)
                    .map(x => ({
                      linkId: x.linkId.toString(),
                      type: x.type,
                    })),
                } : undefined,
            },
          })),
        )}
        data={questionActionItems}
        theme={TableThemes.compact}
      />
    );
  };

  return (
    <Card
      showHeader={true}
      className="rwp-card"
      cardStateId="rwpCard"
      defaultCollapsedState={false}
      headerElement={
        <FlexRow
          className="header-row"
        >
          <span className="rwp-card-title">{"Remedial Work Plan"}</span>

          <FlexRow
            className="right-row"
          >
            <label className="actions">{`${questionActionItems.length || 0} action${questionActionItems.length !== 1 ? "s" : ""}`}</label>

            <button
              className={`secondary rwp-button ${!allowEdit ? 'disabled' : ''}`}
              onClick={() => dispatch(setManageActionItemModal({
                isOpen: true,
                actionItemId: undefined,
                linkOptions: [{
                  linkId: question.auditQuestionId,
                  linkType: ActionItemLinkTypes.AuditQuestion,
                  parentDisplay: `Question #${question.questionNumber}`,
                }],
              }))}
              disabled={!allowEdit}
            >
              <img
                alt=""
                src={addreport}
                className="icon-small"
              />
              CREATE NEW ACTION ITEM
            </button>
          </FlexRow>
        </FlexRow>
      }
    >
      <FlexCol>
        <div>
          {displayComponent}
        </div>
      </FlexCol>
    </Card>
  );
};

export default RwpCard;