import React from "react";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import QISelect from "shared/components/controls/select/QISelect";
import { setSearchPropValue } from "store/search/actions/SearchActionsSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { ActionItemStatuses } from "types/actionItemTypes";

const ActionItemStatus: React.FC = () => {
  const statuses = useAppSelector(store => store.searchActions.statuses);
  const dispatch = useAppDispatch();

  const items = Object.keys(ActionItemStatuses).map(x => ({
    id: x,
    text: x,
  }));

  return (
    <LabeledControl
      label="Status"
    >
      <QISelect
        items={items}
        selectedItemIds={statuses.map(x => x.toString())}
        onChange={selectedItems => {
          dispatch(setSearchPropValue({
            statuses: selectedItems.map(item => ActionItemStatuses[item.id as keyof typeof ActionItemStatuses]),
          }));
        }}
        allowFilter
        allowSelectAll
        selectMode="multi"
      />
    </LabeledControl>
  );
};

export default ActionItemStatus;