import React from "react";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import { IPlanProfileAttributeInfo } from "types/auditPlanningTypes";
import { MetaDataTypes } from "types/masterDataTypes";

interface IAttributeInfoDetailsDisplayProps {
  attributeInfo: IPlanProfileAttributeInfo,
  type: MetaDataTypes,
}

const AttributeInfoDetailsDisplay: React.FC<IAttributeInfoDetailsDisplayProps> = ({
  attributeInfo,
  type,
}: IAttributeInfoDetailsDisplayProps) => {
  return (
    <FlexRow className="extra-meta-columns">
      <FlexCol className="no-gap">
        <b>Business View</b>
        {
          attributeInfo.extraBusinessViews.length > 0
            ? (
              <>
                {attributeInfo.extraBusinessViews.map(i =>
                  <span key={i.name}>{i.name}</span>
                )}
              </>
            ) : <span>-</span>
        }
      </FlexCol>

      <FlexCol className="no-gap">
        <b>Business Function</b>
        {attributeInfo.extraBusinessFunctions.length > 0
          ? (
            <>
              {attributeInfo.extraBusinessFunctions.map(i =>
                <span key={i.name}>{i.name}</span>
              )}
            </>
          ) : <span>-</span>
        }
      </FlexCol>

      {type === MetaDataTypes.AuditTopic &&
        <FlexCol className="no-gap">
          <b>Topics/Subtopics</b>
          {attributeInfo.extraSubTopics.length > 0
            ? (
              <>
                {attributeInfo.extraSubTopics.map(i =>
                  <span key={i.name}>{i.name}</span>
                )}
              </>
            ) : <span>-</span>
          }

        </FlexCol>
      }
    </FlexRow>
  );
};

export default AttributeInfoDetailsDisplay;
