import React from "react";
import Button from "shared/components/controls/buttons/button/Button";
import CloseIcon from "shared/media/dls/close.svg";
import FullscreenIcon from "shared/media/dls/expand-1.svg";
import { SchedulerVisibility, setSchedulerVisibility } from "store/audit-plan-calendar/AuditPlanCalendarSlice";
import { useAppDispatch, useAppSelector } from "store/store";

const MaxMinSchedulerButton: React.FC = () => {
  const schedulerVisibility = useAppSelector(store => store.auditPlanCalendar.schedulerVisibility);
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(setSchedulerVisibility(schedulerVisibility === SchedulerVisibility.normal
      ? SchedulerVisibility.maximized
      : SchedulerVisibility.normal
    ));
  };

  return (
    <Button
      buttonType="secondary"
      imgPlacement="right"
      img={schedulerVisibility === SchedulerVisibility.normal
        ? FullscreenIcon
        : CloseIcon
      }
      onClick={onClick}
    />
  );
};

export default MaxMinSchedulerButton;
